import React, { useState, useEffect, useRef } from "react";
import { scheduleColumnsName, DEFAULT_MODE, DEFAULT_TOAST_MESSAGE } from '../utils/activeScheduleUtils'
import SiteLoader from "../../../common/SiteLoader";
import { apiProvider } from "../../../../services/api/provider";
import KendoGrid from '../../../common/kendo/KendoGrid'
import {ViewScheduleWrapper} from '../ViewSchedule/ViewScheduleWrapper'
import {StartStopPopup} from "../ActiveSchedulePopup";
import ToastMessage from "../../../common/ToastMessage";
import { extractTripIDs } from '../utils/helperFunctions'
import { NoDataScreen } from "../NoDataScreen";

export const FutureSchedule = () => {
  const [futureSchedule, setFutureSchedule] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [mode, setMode] = useState(DEFAULT_MODE);
  const [toastMsg, setToastMsg] = useState(DEFAULT_TOAST_MESSAGE);
  const childRef = useRef();

  const fetchSchedule = async () => {
    try {
      setShowLoader(true);
      const response = await apiProvider.getAll('track/react/v1/allgeo/activeSchedule/fetchScheduleData?search_type=all&fetch=future');
      if (response.status === 200) {
        setShowLoader(false);
        setFutureSchedule(response.data);
      }
    } catch (error) {
      console.error(error);
      setShowLoader(false);
    }
  };

  useEffect(() => {
    fetchSchedule();
  }, []);

  useEffect(() => {
    if (toastMsg.msg && toastMsg.type === "msgSuccess") {
      if (childRef.current) {
        childRef.current.refreshData()
      }
      setShowLoader(false)
    }
  }, [toastMsg])

  const actionHandler = (type, data) => {
    setMode({
      title: `${type.charAt(0).toUpperCase() + type.slice(1)} Schedule`,
      type: type,
      data: data,
      hasPrev: false,
    });
  };

  const deleteSchedules = async (data) => {
    const tripId = extractTripIDs(data);
    try {
      setShowLoader(true);
      const response = await apiProvider.remove(`track/react/v1/allgeo/activeSchedule/deleteSchedule?tripId=${tripId}`);
      setShowLoader(false);
      return response;
    } catch (error) {
      console.error(error);
      setShowLoader(false);
    }
  };

  return (
    <div>
      <br />
      {futureSchedule?.length > 0 ? (
        <KendoGrid
          gridData={futureSchedule}
          setGridData={setFutureSchedule}
          columnData={scheduleColumnsName}
          accessLevel={{ childMenu: 3 }}
          ref={childRef}
          getAPiUrl={'track/react/v1/allgeo/activeSchedule/fetchScheduleData?search_type=all&fetch=future'}
          lockActionColumn={true}
          module="futureSchedule"
          actionColumnHandler={actionHandler}
          deleteItem={deleteSchedules}
          bulkDelete={deleteSchedules}
          otherData={{
            actionColumnBtns: ['view', 'start', 'stop', 'delete'],
          }}
        />
      ) :
        (
          <NoDataScreen  scheduleType ="Future"/>
        )
      }

      {mode.type && (mode.type === 'view' || mode.type === 'start') && (
        <>
          {mode.type === 'view' && <ViewScheduleWrapper selectedSchedule={mode.data} mode={mode} setMode={setMode} />}
          {mode.type === 'start' && <StartStopPopup selectedSchedule={mode.data} mode={mode} setMode={setMode} setShowLoader={setShowLoader} setToastMsg={setToastMsg} />}
        </>
      )}

      <SiteLoader isActive={showLoader} />
      {toastMsg.msg && (
        <ToastMessage
          cssClass={toastMsg.type}
          message={toastMsg.msg}
          onClose={() => setToastMsg({ msg: "", type: "" })}
        />
      )}
      <div className={`overlay${(mode.type === 'view' || mode.type === 'start') ? ' active' : ''}`} />
    </div>
  );
}