import React, { useEffect, useState } from 'react';
import { useDocumentTitle } from '../../hooks/setDocumentTitle';
import { apiProvider } from '../../services/api/provider';
import Header from '../layout/Header';
import Company from '../../assets/images/icon-company-active.svg'
import Schedule from '../../assets/images/icon-schedule-active.svg';
import Monitor from '../../assets/images/icon-monitor-active.svg';
import iconEmployee from '../../assets/images/icon-employee-active.svg';
import iconGroup from '../../assets/images/icon-group-active.svg';
import iconActive from '../../assets/images/icon-site-active.svg';
import iconTask from '../../assets/images/icon-task-active.svg';
import iconReport from '../../assets/images/icon-reports-active.svg';
import iconMessage from '../../assets/images/icon-message-active.svg';
import iconWork from '../../assets/images/icon-work-order-active.svg';
import iconAutomation from '../../assets/images/icon-automation-rule-active.svg';
import iconBuild from '../../assets/images/icon-build-reports-active.svg';
import iconEdit from "../../assets/images/icon-edit-report-active.svg";
import iconSchedule from '../../assets/images/icon-schedule-active.svg'
import { getAccountLabel } from '../../utils/getAccountLabel';
import useGeneralSettings from '../../hooks/useGeneralSettings';



export const HomePage = () => {
    const [isDivVisible, setDivVisible] = useState(true);
    const [isOnBoarding, setIsOnBoarding] = useState(true);
    const [getOnBoarding,setGetOnBoarding] = useState()

    useDocumentTitle("Home - allGeo");

    const workorderLabel = getAccountLabel('workorder');
    const groupLabel = getAccountLabel('group');
    const employeeLabel = getAccountLabel('employee');
    const jobsiteLabel = getAccountLabel('jobsite');
    const taskLabel = getAccountLabel('task');
    useEffect(() => {
        const onBoardingStatus = localStorage.getItem('isOnBoarding');
        setIsOnBoarding(onBoardingStatus === "true"); // Convert the string to boolean
        getOnBoardingValue()
    }, []);

    const getOnBoardingValue = async () => {
        try {
            const response = await apiProvider.getAll('track/react/account/generalSetting')
            setGetOnBoarding(response.onboardingfilled)
        } catch (error) {
            console.log(error);
        }
    }
    

    return (
        <>
            <div className='mainContentWrap'>
                <Header title={'Home'} />
                <div className="mainContent homeContent2">
                    <div className="contentArea">
                        <div className="headingWithBadge">
                            <h2>Welcome to allGeo</h2>
                            {isDivVisible && getOnBoarding ? (
                                <div className="badgeBlk active">
                                    Use our quick <strong><a href={`${process.env.REACT_APP_API_URL}/track/mgt?page=homePage.v1&showOnboardForm=true`} target="_blank">onboarding widget</a></strong> to setup your account in under 10 mins
                                    <span className="closeMessage" onClick={() => setDivVisible(false)}>Close</span>
                                </div>
                            ) : null}
                            {/* {isDivVisible && isOnBoarding ? <div className="badgeBlk active">Use our quick <strong><a href={`${process.env.REACT_APP_API_URL}/mgt?page=homePage.v1&showOnboardForm=true`} target="_blank">onboarding widget</a></strong> to setup your account in under 10 mins <span className="closeMessage" onClick={() => setDivVisible(false)}>Close</span></div>:""} */}
                        </div>
                        <div className="subjectWrap">
                            <h2>Quick Setup Links</h2>
                            <ul>
                                <li>
                                    <div className="subjectHeading"><img src={Company} alt="" /></div>
                                    <ul className="helpList">
                                        <li>
                                            <a href={`${process.env.REACT_APP_API_URL}/company/employees`} className="articleName" target="_blank">
                                                Setup {employeeLabel}
                                                <img src={iconEmployee} alt='' />
                                            </a>

                                        </li>
                                        <li>
                                            <a href={`${process.env.REACT_APP_API_URL}/company/groups`} className="articleName" target="_blank" >
                                                Setup {groupLabel}
                                                <img src={iconGroup} alt="" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href={`${process.env.REACT_APP_API_URL}/company/jobsites`} className="articleName" target="_blank" >
                                                Setup Geofence {jobsiteLabel}
                                                <img src={iconActive} alt="" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href={`${process.env.REACT_APP_API_URL}/company/tasks`} className="articleName" target="_blank" >
                                                Setup {taskLabel}
                                                <img src={iconTask} alt="" />
                                            </a>
                                        </li>

                                    </ul>

                                </li>
                                <li>
                                    <div className="subjectHeading"><img src={Schedule} alt="" /></div>
                                    <ul className="helpList">
                                        <li>
                                            <a href={`${process.env.REACT_APP_API_URL}/track/mgt?page=mastertrip.info.v1`} className="articleName" target="_blank" >
                                                Schedule Connectors
                                                <img src={iconReport} alt="" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href={`${process.env.REACT_APP_API_URL}/track/mgt?page=phonevalidation.v1&q=5135167`} className="articleName" target="_blank" >
                                                Schedule Messages
                                                <img src={iconMessage} alt="" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href={`${process.env.REACT_APP_API_URL}/monitor/locate-schedule`} className="articleName" target="_blank" >
                                                Schedule Tracking
                                                <img src={iconActive} alt="" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href={`${process.env.REACT_APP_API_URL}/track/mgt?page=workorder.summary.v1`} className="articleName" target="_blank" >
                                                Schedule {workorderLabel}
                                                <img src={iconWork} alt="" />
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <div className="subjectHeading"><img src={Monitor} alt="" /></div>
                                    <ul className="helpList">
                                        <li>
                                            <a href={`${process.env.REACT_APP_API_URL}/monitor/monitor-employees`} className="articleName" target="_blank" >
                                                Monitor {employeeLabel}
                                                <img src={iconEmployee} alt="" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href={`${process.env.REACT_APP_API_URL}/schedule/automation-rules`} className="articleName" target="_blank" >
                                                Automation Rules
                                                <img src={iconAutomation} alt="" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href={`${process.env.REACT_APP_API_URL}/reports`} className="articleName" target="_blank" >
                                                Monitor Messages
                                                <img src={iconMessage} alt="" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href={`${process.env.REACT_APP_API_URL}/ngui/dashboard`} className="articleName" target="_blank" >
                                                Analytics
                                                <img src={iconReport} alt="" />
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <div className="subjectHeading"><img src={iconReport} alt="" /></div>
                                    <ul className="helpList">
                                        <li>
                                            <a href={`${process.env.REACT_APP_API_URL}/reports`} className="articleName" target="_blank" >
                                                Run Reports
                                                <img src={iconWork} alt="" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href={`${process.env.REACT_APP_API_URL}/track/mgt?page=menu.rpt.buildReports.v1&tab=buildreports`} className="articleName" target="_blank" >
                                                Build Reports
                                                <img src={iconBuild} alt="" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href={`${process.env.REACT_APP_API_URL}/reports?tab=editReports`} className="articleName" target="_blank" >
                                                Edit Reports
                                                <img src={iconEdit} alt="" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href={`${process.env.REACT_APP_API_URL}/reports?tab=scheduleReports`} className="articleName" target="_blank" >
                                                Schedule Reports
                                                <img src={iconSchedule} alt="" />
                                            </a>
                                        </li>
                                    </ul>
                                </li>

                            </ul>
                        </div>
                        <h2>Explore our Product Videos</h2>
                        <div className='subjectwrap'>
                            <ul className="videoList">
                                <li>
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/6qwNBDsz-9Q" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                    <div className="videoName">Fill out Onboarding Form
                                    </div>
                                </li>
                                <li>
                                    <iframe src="https://www.youtube.com/embed/RlitIf59is8" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                    <div className="videoName">Add {employeeLabel}</div>
                                </li>
                                <li>
                                    <iframe src="https://www.youtube.com/embed/qRTUmK7Nf3M" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                    <div className="videoName">Add Geofence {jobsiteLabel}</div>
                                </li>
                                <li>
                                    <iframe src="https://www.youtube.com/embed/e7fo0BwO9Zg" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                    <div className="videoName">Setup Location Tracking</div>
                                </li>
                                <li>
                                    <iframe src="https://www.youtube.com/embed/UKFtuvl547A" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                    <div className="videoName">View Location Tracking Reports</div>
                                </li>
                                <li>
                                    <iframe src="https://www.youtube.com/embed/MhyhucNIwko" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                    <div className="videoName">View Time Clocking Reports</div>
                                </li>
                                <li>
                                    <iframe src="https://www.youtube.com/embed/0PiiTWxZ6TE" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                    <div className="videoName">Add & Assign {taskLabel}</div>
                                </li>
                                <li>
                                    <iframe src="https://www.youtube.com/embed/EMaiZ9zH8IA" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                    <div className="videoName">Approve Time Card Modification Requests</div>
                                </li>
                                <li>
                                    <iframe src="https://www.youtube.com/embed/V18ygKxxmwM" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                    <div className="videoName">Create {groupLabel} & Assign {employeeLabel}</div>
                                </li>
                                <li>
                                    <iframe src="https://www.youtube.com/embed/HfspZ6tMIvQ" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                    <div className="videoName">Create {workorderLabel} in Calendar UI</div>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
