import React, { useEffect, useRef, useState } from 'react';
import { useDocumentTitle } from '../../../hooks/setDocumentTitle';
import Header from "../../layout/Header";
import KendoGrid from '../../common/kendo/KendoGrid'
import { getAccessLevel } from '../../common/functions'
import { apiProvider } from '../../../services/api/provider';
import { employeeListColumns } from './employeeUtils'
import SiteLoader from '../../common/SiteLoader';
import AddNewPopup from './AddNewPopup';
import ToastMessage from '../../common/ToastMessage';
import ViewEmployee from './ViewEmployee';
import EmployeeSettings from './EmployeeSettings'
import FileUploadFail from '../../common/FileUploadFail';
import ActionPopup from './ActionPopup';
import { getAccountLabel } from '../../../utils';
import { G2ReviewPopup } from '../../common/G2ReviewPopup/G2ReviewPopup';
import { ALLOWED_ACCOUNTS_FOR_G2_REVIEW } from '../../common/G2ReviewPopup/G2Utils/constants';
import { CompanyEmplyoeeSaveTemplate } from './EmplyoeeColumns/CompanyEmplyoeeSaveTemplate';
// import { employeeListColumns } from './employeeUtils';
const Employees = () => {
    const employeeLabel = getAccountLabel('employee') || 'Employee';

    useDocumentTitle(`${employeeLabel} - allGeo`);

    const [employeeList, setEmployeeList] = useState(null)
    const [showLoader, setShowLoader] = useState(false);
    const [showAddNewPopup, setShowAddNewPopup] = useState(false);
    const employeeHeader = `Manage ${employeeLabel}`

    const [mode, setMode] = useState({
        title: `${employeeHeader}`,
        type: 'main',
        data: null,
        hasPrev: false
    })
    const [toastMsg, setToastMsg] = useState({
        msg: "",
        type: "msgSuccess"
    })
    const [timeZone, setTimeZone] = useState("")
    const [accessLevel, setAccessLevel] = useState({});
    const [nomenclature, setNomenclature] = useState({})
    const [actionPopup, setActionPopup] = useState({
        show: false,
        data: null,
        msg: ""
    })
    const [columnList,setColumnList]= useState(employeeListColumns)
    const [kendoDataState, setKendoDataState] = useState()
    useEffect(() => {
        getAccessLevel("company", "employees").then((response) => {
            setAccessLevel(response);
        });
    }, []);

    const getEmployeeList = async () => {
        try {
            setShowLoader(true)
            const response = await apiProvider.getAll('/track/mobile/v1/allgeo/getEmployeeList?operationType=Desktop');
            employeeListColumns[1].title = response.nomenclature.device_title + ' ID';
            employeeListColumns[2].title = response.nomenclature.device_title + ' First Name';
            employeeListColumns[3].title = response.nomenclature.device_title + ' Last Name';
            employeeListColumns[4].title = response.nomenclature.device_title + ' Description';
            employeeListColumns[6].title = response.nomenclature.device_group_title;
            employeeListColumns[8].title = response.nomenclature.device_title + ' Status';
            setEmployeeList(response.data)
            setNomenclature(response.nomenclature)
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    const updateEmployeeList = async data => {

        const payload = data.map(d => ({
            phoneNumber: d.phoneNumber,
            employeeFirstName: d.firstName,
            employeeLastName: d.lastName,
            employeeId: d.employeeId,
            employeeDescription: d.employeeDescription,
            status: d.status,
            timeZone: d.timeZone
        }))

        try {
            localStorage.removeItem('formIsDirty')
            const response = await apiProvider.put('/track/mobile/v1/allgeo/updateEmployeeList', payload)
            return response;
        } catch (error) {
            console.log(error);
        }
    }

    const deleteAnEmployee = async data => {
        try {
            setShowLoader(true)
            const response = await apiProvider.remove(`/track/mobile/v1/allgeo/devices/${data.phoneNumber}`)
            return response;
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    const deleteMultipleEmployee = async data => {
        try {
            setShowLoader(true)
            const response = await apiProvider.removeObject("/track/mobile/v1/allgeo/devices/delete", data)
            return response
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    const getTimeZone = async () => {
        try {
            const response = await apiProvider.getAll('track/react/account/generalSetting')
            setTimeZone(response.basic_details.timezone)
        } catch (error) {
            console.log(error);
        }
    }

    const actionHandler = (type, data) => {

        switch (type) {
            case "view":
                setMode({
                    title: `${employeeLabel} Information`,
                    type: 'view',
                    data: data,
                    hasPrev: false
                })
                break;
            case "edit":
                setMode({
                    title: `Edit ${employeeLabel} Information`,
                    type: 'edit',
                    data: data,
                    hasPrev: false
                })
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        getEmployeeList()
        getTimeZone()
    }, [])

    useEffect(() => {
        if (toastMsg.msg && toastMsg.type === "msgSuccess") {
            childRef.current.refreshData()
        }
    }, [toastMsg])

    const childRef = useRef()

    const [showG2Popup,setShowG2Popup] = useState(false);
    
    
    const sysAdminG2Enbled = JSON.parse(localStorage.getItem('sysAdminG2Enabled'));
    const storedItem = localStorage.getItem('hasG2Reviewed');
    const hasG2Reviewed = (storedItem !== 'undefined' && storedItem !== null) ? JSON.parse(storedItem) : null;
    let isAdminLogin = JSON.parse(localStorage.getItem('isAdminLogin'));
    const doNotShowG2 = JSON.parse(localStorage.getItem('g2ReviewInactive'));
    const showG2Review = !isAdminLogin && !hasG2Reviewed && !doNotShowG2 && showG2Popup && sysAdminG2Enbled;

console.log(columnList,"columnListcolumnList")
    return (
        <>
            <div className="mainContentWrap">
                <Header title={mode.title} />
                {showG2Review && <G2ReviewPopup showPopup={showG2Popup} setShowPopup={setShowG2Popup} />}
                {["main", "uploadError"].includes(mode.type) && <div className="mainContent" id="mainContent">
                {/* <CompanyEmplyoeeSaveTemplate setColumnList={setColumnList} setShowLoader={setShowLoader} setToastMsg={setToastMsg} kendoDataState={kendoDataState} /> */}
                    {employeeList &&
                        <KendoGrid
                            gridData={employeeList}
                            setGridData={setEmployeeList}
                            columnData={columnList}
                            selectRowOnClick={false}
                            accessLevel={accessLevel}
                            ref={childRef}
                            module={"employees"}
                            getAPiUrl={"/track/mobile/v1/allgeo/getEmployeeList?operationType=Desktop"}
                            openPopup={() => setShowAddNewPopup(true)}
                            updateItem={updateEmployeeList}
                            deleteItem={deleteAnEmployee}
                            lockActionColumn={true}
                            actionColumnHandler={actionHandler}
                            bulkDelete={deleteMultipleEmployee}
                            otherData={{
                                exportFileName: employeeLabel,
                                columnList:columnList,
                                setColumnList:setColumnList,
                                setKendoDataState : setKendoDataState,
                                templateName:"companyEmployees",
                                hideMenuCloumns:false
                            }}
                        />
                    }
                    {showAddNewPopup && <AddNewPopup setShowG2Popup ={ setShowG2Popup } timeZone={timeZone} setToastMsg={setToastMsg} setShowLoader={setShowLoader} closePopup={() => setShowAddNewPopup(false)} nomenclature={nomenclature} setActionPopup={setActionPopup} setMode={setMode} />}
                    {actionPopup.show && <ActionPopup actionPopup={actionPopup} setActionPopup={setActionPopup} setToastMsg={setToastMsg} setShowLoader={setShowLoader}  />}
                </div>}
                {mode.type === 'view' &&
                    <ViewEmployee empData={mode.data} setMode={setMode} setToastMsg={setToastMsg} deleteAnEmployee={deleteAnEmployee} accessLevel={accessLevel.childMenu} nomenclature={nomenclature} />
                }
                {mode.type === 'edit' &&
                    <EmployeeSettings employeeId={mode.data.phoneNumber} mode={mode} setMode={setMode} />
                }
                {mode.type === 'uploadError' &&
                    <FileUploadFail data={mode.data} closePopup={() => setMode({ ...mode, type: 'main', data: null, hasPrev: false })} setToastMsg={setToastMsg} setShowLoader={setShowLoader} />
                }

            </div>
            {toastMsg.msg && <ToastMessage
                cssClass={toastMsg.type}
                message={toastMsg.msg}
                onClose={() => setToastMsg({ msg: "", type: "" })}
            />}
            <div className={`overlay ${(showAddNewPopup || actionPopup.show || mode.type === 'uploadError') ? 'active' : ''}`} />
            <SiteLoader isActive={showLoader} />
        </>
    )
}
export default Employees;
