import React, { useEffect, useMemo, useState } from 'react'
import { DatePicker, TimePicker } from "@progress/kendo-react-dateinputs";
import { useForm } from 'react-hook-form';
import { DropdownList } from '../../common/dropdown';
import { AddRecordformatTime } from '../../common/utils';
import { customDateFormat } from './editReportUtils';
import { apiProvider } from '../../../services/api/provider';
import { Checkbox } from '@progress/kendo-react-inputs';

const defaultValues = {
    deviceName: '',
    deviceID: '',
    startStageName: '',
    startTCSentDate: new Date(),
    startTCSentTime: new Date('2023/01/01 09:00:00'),
    endStageName: '',
    endTCSentDate: new Date(),
    endTCSentTime: new Date('2023/01/01 17:00:00'),
    customerName: '',
    jobName: '',
    managerNotes: '',
}

const requiredFields = ['deviceName', 'deviceID', 'startStageName', 'startTCSentDate', 'startTCSentTime', 'endStageName', 'endTCSentDate', 'endTCSentTime', 'customerName', 'jobName', 'jobID', 'remarks', 'managerNotes']

const mandatoryFields = ['deviceName', 'deviceID', 'startStageName', 'endStageName', 'managerNotes']

const RenderColumns = ({ rowId, field, otherData, setValue, rows, setRows, watch }) => {

    const { dropdownData } = otherData
    const { timeFormat, dateFormat } = dropdownData

    const accountTimeFormat = timeFormat?.toLowerCase() === 'standard' ? 'hh:mm:ss a' : 'HH:mm:ss'

    switch (field) {
        case "select":
            return <Checkbox
                style={{
                    borderColor: 'lightgrey',
                    marginLeft: 4
                }}
                onChange={e => {
                    const tempRows = rows
                    const indToBeDeleted = tempRows.findIndex(row => row.rowID === rowId)

                    if (indToBeDeleted > -1) {
                        tempRows[indToBeDeleted].selected = e.value

                        setRows(tempRows)
                    }

                }}
            />
        case "deviceName":
            return <DropdownList
                list={dropdownData["employeeNames"].filter(d => d.value.toLowerCase() !== 'all')}
                textField='text'
                onChange={e => {

                    const employeeName = e.target.value.id

                    const employees = dropdownData["employees"]

                    const selectedEmployee = employees.find(emp => emp.name === employeeName && emp.id !== watch(`${rowId}.deviceID`))

                    setValue(`${rowId}.${field}`, e.target.value.id, { shouldDirty: true })
                    setValue(`${rowId}.deviceID`, selectedEmployee?.id || '', { shouldDirty: true })
                }}
                filterable={true}
                defaultValue={watch(`${rowId}.${field}`)}
            />
        case "deviceID":
            return <DropdownList
                list={dropdownData["employees"].map(emp => ({ value: emp.id, text: emp.id })).filter(d => d.value !== 'all')}
                textField='text'
                onChange={e => {
                    const phoneNumber = e.target.value.id

                    const employees = dropdownData["employees"]

                    const selectedEmployee = employees.find(emp => emp.id === phoneNumber)

                    setValue(`${rowId}.${field}`, e.target.value.id, { shouldDirty: true })
                    setValue(`${rowId}.deviceName`, selectedEmployee?.name || '', { shouldDirty: true })
                }}
                filterable={true}
                defaultValue={watch(`${rowId}.${field}`)}
            />
        case "startStageName":
            return <DropdownList
                list={dropdownData["stageName"]}
                textField='text'

                onChange={e => setValue(`${rowId}.${field}`, e.target.value.id, { shouldDirty: true })}
            />
        case "startTCSentDate":
            return <DatePicker
                defaultValue={new Date()}
                onChange={e => {
                    setValue(`${rowId}.${field}`, e.value, { shouldDirty: true })
                }}
                format={dateFormat}
            />
        case "startTCSentTime":
            return <TimePicker
                defaultValue={new Date('2023/01/01 09:00:00')}
                onChange={e => {
                    setValue(`${rowId}.${field}`, e.value, { shouldDirty: true })
                }}
                format={accountTimeFormat}
            />
        case "endStageName":
            return <DropdownList
                list={dropdownData["stageName"]}
                textField='text'

                onChange={e => setValue(`${rowId}.${field}`, e.target.value.id, { shouldDirty: true })}
            />
        case "endTCSentDate":
            return <DatePicker
                defaultValue={new Date()}
                onChange={e => {
                    setValue(`${rowId}.${field}`, e.value, { shouldDirty: true })
                }}
                format={dateFormat}
            />
        case "endTCSentTime":
            return <TimePicker
                defaultValue={new Date('2023/01/01 17:00:00')}
                onChange={e => {
                    setValue(`${rowId}.${field}`, e.value, { shouldDirty: true })
                }}
                format={accountTimeFormat}
            />
        case "customerName":
            return <DropdownList
                list={dropdownData["jobsites"]}
                textField='text'

                onChange={e => setValue(`${rowId}.${field}`, e.target.value.id, { shouldDirty: true })}
                filterable={true}
            />
        case "jobName":
            return <DropdownList
                list={dropdownData["tasks"]}
                textField='text'

                onChange={e => {
                    const taskName = e.target.value.id

                    const taskAll = dropdownData['taskAll']
                    const selectedTask = taskAll?.find(taskAll => taskAll?.name === taskName && taskAll?.taskID !== watch(`${rowId}.jobID`))
                    setValue(`${rowId}.${field}`, e.target.value.id, { shouldDirty: true });
                    setValue(`${rowId}.jobID`, selectedTask?.taskID || '', { shouldDirty: true });

                }}
                filterable={true}
                defaultValue={watch(`${rowId}.${field}`)}
            />
        case "jobID":
            return <DropdownList
                list={dropdownData["tasksIDs"]}
                textField='text'

                onChange={e => {
                    const taskID = e.target.value.id
                    const taskAll = dropdownData['taskAll']
                    const selectedTask = taskAll?.find(taskAll => taskAll?.taskID === taskID)

                    setValue(`${rowId}.${field}`, e.target.value.id, { shouldDirty: true })
                    setValue(`${rowId}.jobName`, selectedTask?.name || '', { shouldDirty: true })

                }}
                filterable={true}
                defaultValue={watch(`${rowId}.${field}`)}
            />

        case "remarks":
            return <input
                type='text'
                onChange={e => setValue(`${rowId}.${field}`, e.target.value, { shouldDirty: true })}
            />
        case "managerNotes":
            return <input
                type='text'
                onChange={e => setValue(`${rowId}.${field}`, e.target.value, { shouldDirty: true })}
            />
        default:
            return null
    }

}


const AddNewRecord = ({ addAPI = '', columns = [], closePopup, otherData, setToastMsg, setShowLoader }) => {


    const { handleSubmit, setValue, getValues, watch, reset } = useForm({ mode: "all" });

    const [rows, setRows] = useState([
        {
            rowID: 1,
            ...defaultValues
        }
    ])

    const columnData = useMemo(() => {
        let tempColumnData = requiredFields.map(field => {
            const column = columns.find(col => col.field === field)
            if (column) {
                return {
                    ...column,
                    className: column.field === 'deviceName' ? 'fixColumn' : ''
                }
            }

            return null
        }).filter(col => col)

        tempColumnData = [{ field: 'select', width: 10, className: 'selectCol' }, ...tempColumnData]

        return tempColumnData

    }, [columns])

    const getMandatoryColumnTitle = field => {
        const colData = columnData.filter(col => col.mandatory).find(mCol => mCol.field === field)

        if (colData) return colData.title

        return ''
    }

    
    const onSubmit = async data => {
        try {
            setShowLoader(true)

            let unfilledMandatoryfields = []

            const validRowIDs = rows.map(row => row.rowID)

            const payload = Object.keys(data).filter(rowID => validRowIDs.includes(+rowID)).map(key => {
                const fields = data[key]
                const invalidFields = Object.keys(fields).filter(field => mandatoryFields.includes(field) && fields[field]?.trim()?.length === 0)

                if (invalidFields.length > 0) {
                    unfilledMandatoryfields = invalidFields;
                }

                const accountTimeFormat = otherData.timeFormat?.toLowerCase() === 'standard' ? 'hh:mm:ss a' : 'HH:mm:ss'

                return {
                    deviceID: fields.deviceID,
                    jobName: fields.jobName,
                    jobID: fields.jobID,
                    customerName: fields.customerName,
                    managerNotes: fields.managerNotes,
                    remarks: fields.remarks,
                    startStageName: fields.startStageName,
                    endStageName: fields.endStageName,
                    startTempCol: customDateFormat(fields.startTCSentDate, "yyyy/MM/dd", otherData.dateFormat) + " " + AddRecordformatTime(fields.startTCSentTime, accountTimeFormat.replace('a', 'A')),
                    endTempCol: customDateFormat(fields.endTCSentDate, "yyyy/MM/dd", otherData.dateFormat) + " " + AddRecordformatTime(fields.endTCSentTime, accountTimeFormat.replace('a', 'A')),
                }
            })


            if (unfilledMandatoryfields.length > 0) {
                setShowLoader(false)

                const fieldsText = unfilledMandatoryfields.map((field, index) => (
                    <span key={field}>
                        <strong>{getMandatoryColumnTitle(field)}</strong>
                        {index < unfilledMandatoryfields.length - 1 && ", "}
                    </span>
                ));

                setToastMsg({
                    msg: <>
                        Please enter the mandatory field(s): {fieldsText} to proceed.
                    </>, type: "msgError"
                })

                return
            }

            const response = await apiProvider.post(addAPI, payload.filter(rowData => rowData !== null))

            if (+response.StatusCode === 200) {
                setToastMsg({ msg: "Timeclock Entries has been added successfully", type: "msgSuccess" })
                closePopup()
            } else {
                setToastMsg({ msg: response.message, type: "msgError" })
            }
        } catch (error) {
            console.log(error);
        }
    }

    const addNewRow = (evt) => {
        evt.preventDefault()

        const lastRow = rows.length > 0 ? getValues(`${rows[rows.length - 1].rowID}`) : []

        const unfilledMandatoryfields = Object.keys(lastRow).filter(field => mandatoryFields.includes(field) && lastRow[field]?.trim()?.length === 0)

        if (unfilledMandatoryfields.length > 0) {

            const fieldsText = unfilledMandatoryfields.map((field, index) => (
                <span key={field}>
                    <strong>{getMandatoryColumnTitle(field)}</strong>
                    {index < unfilledMandatoryfields.length - 1 && ", "}
                </span>
            ));

            setToastMsg({
                msg: <>
                    Please enter the mandatory field(s): {fieldsText} to proceed.
                </>, type: "msgError"
            })



            return
        }

        setValue(`${rows.length + 1}`, { ...defaultValues })
        setRows([...rows, { rowID: rows.length + 1, ...defaultValues }])

    }

    const deleteRow = evt => {
        evt.preventDefault()

        setRows(rows.filter(row => !row.selected))

    }

    useEffect(() => {
        reset({
            1: { ...defaultValues }
        })
    }, [])

    return (
        <>
            <div className="popupWrap bigPopup active">
                <form onSubmit={handleSubmit(onSubmit)} >
                    <div className="closePopup" onClick={closePopup} >Close</div>
                    <div className="popupHeading">Add Manual Records - Advance Timeclock </div>
                    <div className="popupContent">
                        <div className='displayFlex flexJBetween items-center'>
                            <div>Enter the information to add your timeclock entries (<span style={{ color: 'red' }}> *</span> are mandatory )</div>
                            <div className='displayFlex' >
                                <div>
                                    <button onClick={addNewRow} className='btnStyle btn2 btnAdd' >Add New Row</button>
                                </div>
                                <div>
                                    <button onClick={deleteRow} className='btnStyle btn2 btnDelete ml-10'>Delete Row</button>
                                </div>
                            </div>
                        </div>
                        <div className="popupTabCont">
                            <div className="empSelectBlk">
                                <div className="empListTbl tbl2 wideTbl removePadding">
                                    <table cellPadding="0" cellSpacing="0">
                                        <tbody>
                                            <tr>
                                                {
                                                    columnData?.map((col) => (
                                                        <th key={col.field} className={col.className || ''} >
                                                            {col.title}{mandatoryFields.includes(col.field) && <span style={{ color: 'red' }} > *</span>}
                                                        </th>
                                                    ))
                                                }
                                            </tr>
                                            {
                                                rows.map((row) => (
                                                    <tr key={row.rowID} >
                                                        {
                                                            columnData.map((col) => (
                                                                <td key={col.field} style={{ minWidth: col.width }} className={col.className || ''}>
                                                                    <RenderColumns
                                                                        rowId={row.rowID}
                                                                        field={col.field}
                                                                        reset={reset}
                                                                        setValue={setValue}
                                                                        otherData={otherData}
                                                                        rows={rows}
                                                                        setRows={setRows}
                                                                        watch={watch}
                                                                    />
                                                                </td>
                                                            ))
                                                        }
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="popupBtnWrap flexJCenter">
                        <button disabled={rows.length === 0} className="btnStyle okBtn" type='submit' >Done</button>
                    </div>
                </form>
            </div>
            <div className='overlay active' />
        </>
    )
}

export default AddNewRecord