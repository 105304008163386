export const moduleTypes = {
    settings: "settings",
    groups: "groups",
    employee: "employees",
    jobSite: "jobSite",
    managers: "managers",
    tasks: "tasks",
    forms: "forms",
    billing: "billing",
    locateScheduler: "locateScheduler",
    rulesScheduler: "rulesScheduler",
    reportScheduler: "reportScheduler",
    editReports: "editReports",
    runNBuildReports: "runNBuildReports",
    monitorEmployees: "monitorEmployees",
    auditReports: 'auditReports',
    timeClockReport:'timeClockReports',
    workOrders : 'workOrders',
    buildReports:'buildReports'
}

export const isDisabled = (permissionType, accessLevel, selectedState = {}) => {
    const level = accessLevel.childMenu;

    if (typeof level === 'number' && level === 0) return true;

    switch (permissionType) {
        case "add":
            return level < 3;
        case "view":
            return level < 1;
        case "edit":
            return level < 2;
        case "delete:single":
            return level < 3;
        case "delete:multi":
            return level < 3 ? true : Object.keys(selectedState).filter(k => selectedState[k]).length < 2;
        default:
            return true;
    }
}

export const getUniqueIds = (module, subModule = "") => {
    switch (module) {
        case moduleTypes.settings:
            if (subModule === ":codes") return "code:accountID:referenceID"
            return "payerServiceInfoId"
        case moduleTypes.groups:
            return "groupID"
        case moduleTypes.employee:
            return "phoneNumber"
        case moduleTypes.managers:
            return "managerID"
        case moduleTypes.jobSite:
            return "txnId"
        case moduleTypes.tasks:
            return "ID"
        case moduleTypes.forms:
            return "formId"
        case moduleTypes.locateScheduler:
            return "scheduleId"
        case moduleTypes.reportScheduler:
            return "scheduleId"
        default:
            return ""
    }
}

export const saveLastUpdatedIds = (module, subModule, updatedData) => {
    const storageKey = module + subModule;

    const updatedDataIds = updatedData.map(d => {
        if (getUniqueIds(module, subModule).includes(":")) {
            const compositePrimaryKey = getUniqueIds(module, subModule).split(":")

            return compositePrimaryKey.map(key => d[key]).join(",")
        }

        return d[getUniqueIds(module, subModule)]
    });

    sessionStorage.setItem(storageKey, JSON.stringify(updatedDataIds))
}

export const removeLastUpdatedIds = (module, subModule, deletedData) => {
    const storageKey = module + subModule;
    const updatedIDs = sessionStorage.getItem(storageKey)
    const isArray = deletedData.length ? true : false

    if (updatedIDs) {
        const savedUniqueIds = JSON.parse(updatedIDs)
        const uniqueIds = getUniqueIds(module, subModule)

        let deletedIds = []

        if (isArray) {
            if (module === moduleTypes.settings) {
                if (uniqueIds.includes(":")) {
                    const compositePrimaryKey = uniqueIds.split(":")

                    deletedIds = deletedData.map(d => compositePrimaryKey.map(key => d[key]).join(","))
                } else {
                    deletedIds = deletedData.map(d => d[uniqueIds])
                }
            } else {
                deletedIds = deletedData
            }

        } else {
            if (module === moduleTypes.settings) {
                if (uniqueIds.includes(":")) {
                    const compositePrimaryKey = uniqueIds.split(":")

                    deletedIds = [compositePrimaryKey.map(key => deletedData[key]).join(",")]
                } else {
                    deletedIds = [deletedData[uniqueIds]]
                }
            } else {
                deletedIds = [deletedData[uniqueIds]]
            }
        }

        const leftIds = savedUniqueIds.filter(d => !deletedIds.includes(d))

        sessionStorage.setItem(storageKey, JSON.stringify(leftIds))
    }
}

export const getSubModule = (module, getApiUrl) => {
    if (module === moduleTypes.settings)
        return ":" + getApiUrl.split("/")[getApiUrl.split("/").length - 1]
    return ""
}

export const secondsToHHMMSS = (sec) => {
    const isSecEnable = JSON.parse(localStorage.getItem("secondsEnable"))
    let sec_num = parseInt(sec, 10);
    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    let seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours < 10) { hours = "0" + hours; }
    if (minutes < 10) { minutes = "0" + minutes; }
    if (seconds < 10) { seconds = "0" + seconds; }
    return isSecEnable ? hours + ':' + minutes + ':' + seconds : hours + ':' + minutes
}


export const secondsToHoursAndHundredths=(seconds)=> {
    const isSecondsFormatEnabled = JSON.parse(localStorage.getItem("secondsEnable"));
    try {
        const hours = Math.floor(seconds / 3600);
        let minutes = 0;

        if (!isSecondsFormatEnabled) {
            minutes = (Math.floor(seconds / 60) % 60) / 60;
        } else {
            minutes = seconds / 60 / 60;
        }

        const formattedHours = String(hours).padStart(2, '0');
        let minutesStringFormat = String(minutes);

        if (minutesStringFormat.includes(".")) {
            minutesStringFormat = minutesStringFormat.substring(minutesStringFormat.indexOf(".") + 1);
        }

        let result = "";

        if (minutesStringFormat.startsWith("99")) {
            result = minutesStringFormat.substring(0, 2);
        } else {
            minutes = parseFloat(minutes.toFixed(2));
            result = minutes.toString().split(".")[1];
        }

        const formattedTime = `${formattedHours}.${result}`;
        return parseFloat(formattedTime);
    } catch (e) {
        console.error(e.toString());
    }
    return 0;
}

const flattenNestedItems = (data) => {
    let items = [];

    data.forEach((item) => {
        if (item.items) {
            items.push(...flattenNestedItems(item.items));
        } else {
            items.push(item);
        }
    });

    return items;
};

export const exportToCSV = (exportToExcelRef, columnFormatMap, downloadRef, fileName = 'Report', minutesFormat) => {
    const { workbookOptions, props: { data } } = exportToExcelRef.current;

    let txnIds = null

    if (sessionStorage.getItem('AdvanceTimeClockReport:includeTxnIds')) {
        if (data.length > 0) {
            const groupedState = data[0].items ? true : false

            if (groupedState) {
                const flattenData = flattenNestedItems(data)

                txnIds = flattenData.map(d => d.txnID)
            } else {
                txnIds = data.map(d => d.txnID)
            }

        }
    }

    const options = workbookOptions();
    const rows = options.sheets[0].rows;
    const headers = rows[0].cells.filter(cell => typeof cell.value !== 'undefined');

    const indexFormatMap = {};

    headers.forEach((cell, ind) => {
        const columnName = cell.value;
        if (columnFormatMap && columnFormatMap[columnName]) {
            indexFormatMap[ind] = minutesFormat;
        }
    });

    let dataRows = [];

    rows.forEach((row) => {

        if (txnIds) {

            if (row.type === 'header') {
                row.cells = [...row.cells, { value: 'Transaction ID' }]
            }

            if (row.type === 'data') {
                row.cells = [...row.cells, { value: txnIds[0] }]
                txnIds.splice(0, 1)
            }
        }

        const csvFormattedString = row.cells
            .filter(cell => typeof cell.value !== 'undefined')
            .map((cell, ind) => {
                if (row.type === "data") {
                    const format = indexFormatMap[ind];
                    if (format === 'minutes') {
                        return secondsToHHMMSS(cell.value);
                    }
                    if (format === 'hundredths') {
                        return secondsToHoursAndHundredths(cell.value);
                    }
                }
                if (cell.value === null || cell.value === NaN) {
                    return ""
                }
                return `"${cell.value}"`;
            }).join(",");

        dataRows.push(csvFormattedString);
    });

    dataRows = dataRows.join("\n");

    const csvContent = [];
    csvContent.push(dataRows);

    const csvString = csvContent.join("\n");
    const blob = new Blob([csvString], { type: 'text/csv' });
    const downloadURL = window.URL.createObjectURL(blob);
    const anchorElement = downloadRef.current;

    anchorElement.href = downloadURL;
    anchorElement.download = fileName + ".csv";
    anchorElement.click();
    window.URL.revokeObjectURL(downloadURL);
}

export const customExportFunction = (gridRef, exportRef, reportData) => {

    const { _data: gridData } = gridRef
    const { workbookOptions, save } = exportRef
    const options = workbookOptions()

    const groupFooterRowsMap = {}

    //Column format Map is the key value pair of Column title and its format
    const { columnFormatMap, aggregateText, column, minutesFormat } = reportData

    if (columnFormatMap) {

        const rows = options.sheets[0].rows;
        const headers = rows[0].cells

        const fieldColumnIndexMap = {}
        let startMessageAttachmentIndex;
        let endMessageAttachmentIndex;
        let attachmentIndexArrayStart = []
        let attachmentIndexArrayEnd = [];
        const insertedStartColumns = [];
        const insertedEndColumns = []


        column.map(col => {
            const colInd = headers.findIndex(h => h.value === col.title)

            if (colInd > -1) {
                fieldColumnIndexMap[col.field] = colInd
            }

            startMessageAttachmentIndex = fieldColumnIndexMap.startMessageAttachment
            endMessageAttachmentIndex = fieldColumnIndexMap.endMessageAttachment
        })

        const indexFormatMap = {}

        headers.forEach((cell, ind) => {
            if (columnFormatMap[cell.value]) {
                indexFormatMap[ind] = columnFormatMap[cell.value]
            }
        })

        rows?.forEach((row) => {
            let colInd

            if (row.type === "data") {
                row.cells.forEach((cell, ind) => {

                    if (ind === startMessageAttachmentIndex && cell.value) {
                        const urlArray = cell.value.split("#") || []
                        const urlArrayLength = urlArray.length;

                        if (urlArrayLength > 1) {
                            urlArray.forEach((inner_url, index) => {

                                if (index == 0) {
                                    const formula = `=HYPERLINK("${inner_url}", "Attachment Link")`;
                                    cell.formula = formula;
                                    cell.color = "[Blue]";

                                } else {

                                    // Check if the column has already been inserted in this row
                                    if (!insertedStartColumns.includes(`Start Message Attachment Link ${index}`)) {
                                        rows[0].cells.push({
                                            background: "#7a7a7a",
                                            colSpan: 1,
                                            color: "#fff",
                                            firstCell: true,
                                            rowSpan: 1,
                                            value: `Start Message Attachment Link ${index}`
                                        });

                                        insertedStartColumns.push(`Start Message Attachment Link ${index}`);
                                    }

                                    colInd = rows[0].cells.findIndex(
                                        (h) => h.value === `Start Message Attachment Link ${index}`
                                    );

                                    // Check if colInd is not already in attachmentIndexArrayEnd
                                    if (attachmentIndexArrayStart.indexOf(colInd) === -1) {
                                        attachmentIndexArrayStart.push(colInd)
                                    }

                                    row.cells[colInd] = {
                                        value: inner_url
                                    }
                                }

                            })

                        } else {
                            const formula = `=HYPERLINK("${cell.value}", "Attachment Link")`;
                            cell.formula = formula;
                            cell.color = "[Blue]";

                        }
                    }

                })
            }
        });


        rows?.forEach((row) => {
            let colInd
            if (row.type === "data") {
                row.cells.forEach((cell, ind) => {

                    if (ind === endMessageAttachmentIndex && cell.value) {
                        const urlArray = cell.value.split("#") || []
                        const urlArrayLength = urlArray.length;

                        if (urlArrayLength > 1) {
                            urlArray.forEach((inner_url, index) => {
                                if (index == 0) {
                                    const formula = `=HYPERLINK("${inner_url}", "Attachment Link")`;
                                    cell.formula = formula;
                                    cell.color = "[Blue]";

                                } else {
                                    if (!insertedEndColumns.includes(`End Message Attachment Link ${index}`)) {
                                        rows[0].cells.push({
                                            background: "#7a7a7a",
                                            colSpan: 1,
                                            color: "#fff",
                                            firstCell: true,
                                            rowSpan: 1,
                                            value: `End Message Attachment Link ${index}`
                                        });
                                        insertedEndColumns.push(`End Message Attachment Link ${index}`);
                                    }
                                    colInd = rows[0].cells.findIndex(
                                        (h) => h.value === `End Message Attachment Link ${index}`
                                    );

                                    // Check if colInd is not already in attachmentIndexArrayEnd
                                    if (attachmentIndexArrayEnd.indexOf(colInd) === -1) {
                                        attachmentIndexArrayEnd.push(colInd);
                                    }

                                    row.cells[colInd] = {
                                        value: inner_url
                                    }
                                }

                            })

                        } else {
                            const formula = `=HYPERLINK("${cell.value}", "Attachment Link")`;
                            cell.formula = formula;
                            cell.color = "[Blue]";

                        }

                    }

                })
            }
        });

        rows?.forEach((row) => {
            if (row.type === "data") {
                row.cells.forEach((cell, ind) => {

                    if (attachmentIndexArrayStart.includes(ind) || attachmentIndexArrayEnd.includes(ind)) {
                        const formula = `=HYPERLINK("${cell.value}", "Attachment Link")`;
                        cell.formula = formula;
                        cell.color = "[Blue]";
                    }
                })
            }
        });


        if (Object.keys(indexFormatMap).length > 0) {
            rows.forEach((row) => {
                if (row.type === "data") {
                    row.cells.forEach((cell, ind) => {
                        if (indexFormatMap[ind] && minutesFormat == "minutes") {
                            console.log("minutes format")
                            cell.value = secondsToHHMMSS(cell.value)
                        }
                        if (indexFormatMap[ind] && minutesFormat == "hundredths") {
                            cell.value = secondsToHoursAndHundredths(cell.value)
                        }
                    })
                }
            });
        }

        gridData.map((row, ind) => {

            if (row.rowType === 'groupFooter') {

                const footerRowData = { type: 'group-footer', cells: headers.map(() => ({ value: '' })) }

                Object.keys(row.dataItem.aggregates).map(aggregate => {

                    let cellValue = row.dataItem.aggregates[aggregate].sum || row.dataItem.aggregates[aggregate].count || 0

                    if (indexFormatMap[fieldColumnIndexMap[aggregate]] && indexFormatMap[fieldColumnIndexMap[aggregate]] === 'hh:mm:ss') {
                        cellValue = secondsToHHMMSS(cellValue)
                    }

                    footerRowData.cells[fieldColumnIndexMap[aggregate]] = {
                        background: "#dfdfdf",
                        color: "#333",
                        value: aggregateText[aggregate] + ": " + cellValue
                    }
                })

                groupFooterRowsMap[ind + 1] = footerRowData
            }
        })

    }

    //Adding Group footer row
    const modifiedRows = []

    if (Object.keys(groupFooterRowsMap).length > 0) {
        const tempRows = [...options.sheets[0].rows]

        let i = 0;

        while (modifiedRows.length !== tempRows.length + Object.keys(groupFooterRowsMap).length) {

            if (groupFooterRowsMap[modifiedRows.length]) {
                modifiedRows.push(groupFooterRowsMap[modifiedRows.length])
                continue
            } else {
                modifiedRows.push(tempRows[i])
                i++;
            }
        }

    }

    if (modifiedRows.length > 0) {
        options.sheets[0].rows = modifiedRows
    }

    save(options)
}