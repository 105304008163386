import React, { useEffect, useRef, useState } from 'react'
import Header from '../../layout/Header'
import { apiProvider } from '../../../services/api/provider'
import KendoGrid from '../../common/kendo/KendoGrid'
import { useDocumentTitle } from '../../../hooks/setDocumentTitle';
import { getAccessLevel } from '../../common/functions';
import AddNewPopup from './AddNewPopup';
import ViewJobSite from './ViewJobSite';
import EditJobSite from './EditJobSite';
import SiteLoader from '../../common/SiteLoader';
import { jobSiteColumns, units, SELECTED_JOBSITES_KEY } from './jobSitesUtils';
import RadiusPopup from './RadiusPopup';
import AssignTask from './AssignTask';
import ToastMessage from '../../common/ToastMessage';
import FileUploadFail from '../../common/FileUploadFail';
import AssignEmployeePopup from './AssignEmployeePopup/AssignEmployeePopup';
import { getAccountLabel } from '../../../utils';
import { G2ReviewPopup } from '../../common/G2ReviewPopup/G2ReviewPopup';
import { ALLOWED_ACCOUNTS_FOR_G2_REVIEW} from '../../common/G2ReviewPopup/G2Utils/constants';

const JobSites = () => {
    const jobsiteLabel = getAccountLabel('jobsite') || "Jobsite"

    useDocumentTitle(`${jobsiteLabel} - allGeo`)

    const [showLoader, setShowLoader] = useState(false);
    const [showAddNewPopup, setShowAddNewPopup] = useState(false);
    const [jobSites, setJobSites] = useState(null)
    const [accessLevel, setAccessLevel] = useState({});
    const [radiusUnit, setRadiusUnit] = useState("");
    const [mode, setMode] = useState({
        title: `Manage ${jobsiteLabel}`,
        type: 'main',
        data: null,
        hasPrev: false
    })

    const [radiusModal, setRadiusModal] = useState({
        show: false,
        data: null,
    })

    const [assignTaskModal, setAssignTaskModal] = useState({
        show: false,
        data: null
    });

    const [assignEmployees, setAssignEmployeeModal] = useState({
        show: false,
        data: null
    })

    console.log(assignEmployees, 'assignEmployees');
    console.log(assignEmployees.data, 'assign employees data');

    const [skills, setSkills] = useState([])
    const [earningCode, setEarningCode] = useState([])
    const [timeZone, setTimeZone] = useState("")


    const [toastMsg, setToastMsg] = useState({
        msg: "",
        type: "msgSuccess"
    })
    const [activeList, setActiveList] = useState("Yes")
    const [searchText, setSearchText] = useState("")
    const [isUpdated, setIsUpdated] = useState(false)

    const childRef = useRef()

    useEffect(() => {
        getAccessLevel("company", "job_sites").then((response) => {
            setAccessLevel(response);
        });
    }, []);

    const getJobSiteList = async () => {
        try {
            return await apiProvider.getAll('/track/mobile/v2/allgeo/getCustomers?active=Yes')
        } catch (error) {
            console.log(error);
        }
    }

    const getSkills = async () => {
        try {
            return await apiProvider.getAll('track/react/account/getEmployeeSkills')
        } catch (error) {
            console.log(error);
        }
    }

    const getEarningCodeData = async () => {
        try {
            return await apiProvider.getAll('track/mobile/v1/allgeo/earning/codes')
        } catch (error) {
            console.log(error);
        }
    }

    const fetchData = async () => {
        try {
            setShowLoader(true)
            const response = await Promise.all([getJobSiteList(), getSkills(), getEarningCodeData()])

            setJobSites(response[0].data);
            setRadiusUnit(response[0].radiusUnits)
            if (response[0].data.length > 0) {
                jobSiteColumns[6].title = `Radius (${response[0].radiusUnits})`
            }
            setSkills(response[1].map(d => ({
                text: d,
                value: d
            })))
            setEarningCode(response[2].map(e => ({
                text: e.code,
                value: e.code
            })))
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    const getTimeZone = async () => {
        try {
            const response = await apiProvider.getAll('track/react/account/generalSetting')
            setTimeZone(response.basic_details.timezone)
        } catch (error) {
            console.log(error);
        }
    }

    const updateJobSites = async data => {

        try {
            const payload = data.map(d => ({
                ...d,
                radius: +d.geozone.zoneRadius,
                active: true,
                active: (d.isActive == "Active" || d.isActive == "true") ? true : false,
            }))

            const minValue = units[data[0].geozone.radiusUnit.toLowerCase()] === 'm' ? 1 : 4;

            const negativeRadiusCheck = payload.filter(d => d.geozoneType === "Circle").map(d => d.radius).some(radius => radius < minValue)

            if (negativeRadiusCheck) {

                setToastMsg({
                    type: 'msgError',
                    msg: `The radius cannot be less than ${minValue} ${data[0].geozone.radiusUnit.toLowerCase() === "meters" ? "meter" : "feet"}. Please enter a significant value.`
                })
                return {}
            }

            localStorage.removeItem('formIsDirty')

            return await apiProvider.put("/track/mobile/v1/allgeo/updateCustomers", payload)

        } catch (error) {
            console.log(error);
        }
    }

    const deleteMultipleJobSite = async data => {
        const payload = data.length ? data : [data.txnId]

        try {
            setShowLoader(true)

            const response = await apiProvider.removeObject("/track/mobile/v1/allgeo/customers/delete", payload)
            response.message = data.length ? "The selected records have been deleted successfully" : `${data.name} deleted Successfully`;

            return response;
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    const actionHandler = (type, data) => {
        const jobsiteLabel = getAccountLabel('jobsite');
        switch (type) {
            case "view":
                setMode({
                    title: `View ${jobsiteLabel} Information`,
                    type: 'view',
                    data: data,
                    hasPrev: false
                })
                break;
            case "edit":
                setMode({
                    title: `Edit ${jobsiteLabel} Information`,
                    type: 'edit',
                    data: data,
                    hasPrev: false
                })
                break;
            default:
                break;
        }
    }

    const openMap = data => {

        const selectedJobSites = data.map(d => d.txnId)
        sessionStorage.setItem(SELECTED_JOBSITES_KEY, JSON.stringify({selectedJobSites, activeList}))

        window.open(`jobsites/map-view`, "_blank")
    }

    const openPopup = (type, data) => {
        switch (type) {
            case "addNew":
                setShowAddNewPopup(true)
                break;
            case "mapBtn":
                openMap(data)
                break;
            case "radiusBtn":
                setRadiusModal({
                    show: true,
                    data
                })
                break;
            case "assignTask":
                setShowLoader(true)
                setAssignTaskModal({
                    show: true,
                    data
                })
                break;
            case "assignEmployees":
                setShowLoader(true);
                setAssignEmployeeModal({
                    show: true,
                    data
                })
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        fetchData()
        getTimeZone()
    }, [])

    useEffect(() => {
        if (toastMsg.msg) {
            if (toastMsg.type === "msgSuccess" && childRef.current) {
                childRef.current.refreshData()
            } else if (!childRef.current) {
                setIsUpdated(true)
            }
            setShowLoader(false)
        }
    }, [toastMsg])

    const [showG2Popup,setShowG2Popup] = useState(false);
    
    const sysAdminG2Enbled = JSON.parse(localStorage.getItem('sysAdminG2Enabled')); 
    const storedItem = localStorage.getItem('hasG2Reviewed');
    const hasG2Reviewed = (storedItem !== 'undefined' && storedItem !== null) ? JSON.parse(storedItem) : null;
    let isAdminLogin = JSON.parse(localStorage.getItem('isAdminLogin'));
    const doNotShowG2 = JSON.parse(localStorage.getItem('g2ReviewInactive'));
    const showG2Review = !isAdminLogin && !hasG2Reviewed && !doNotShowG2 && showG2Popup && sysAdminG2Enbled;


    return (
        <>
            <div className="mainContentWrap">
                <Header title={mode.title} />
                {showG2Review && <G2ReviewPopup showPopup={showG2Popup} setShowPopup={setShowG2Popup}/>}
                <div className="mainContent" id="mainContent">
                    {["main", "uploadError"].includes(mode.type) &&
                        <>
                            {jobSites &&
                                <KendoGrid
                                    gridData={jobSites}
                                    setGridData={setJobSites}
                                    columnData={jobSiteColumns}
                                    accessLevel={accessLevel}
                                    ref={childRef}
                                    module={"jobSite"}
                                    getAPiUrl={"/track/mobile/v2/allgeo/getCustomers"}
                                    requestParams={{ active: activeList, showNearByEmployees: "No" }}
                                    openPopup={openPopup}
                                    updateItem={updateJobSites}
                                    deleteItem={deleteMultipleJobSite}
                                    lockActionColumn={true}
                                    actionColumnHandler={actionHandler}
                                    bulkDelete={deleteMultipleJobSite}
                                    otherData={{
                                        skills,
                                        earningCode,
                                        defaultSortedColumns: [{ field: 'creation', dir: 'desc' }],
                                        dropDownData: activeList,
                                        setDropDownData: setActiveList,
                                        searchText,
                                        setSearchText,
                                        isUpdated,
                                        setIsUpdated,
                                        columnFieldToRefreshList: "near_employees",
                                        exportFileName: jobsiteLabel
                                    }}
                                />
                            }
                            {radiusModal.show && <RadiusPopup data={radiusModal.data} setRadiusModal={setRadiusModal} setToastMsg={setToastMsg} setShowLoader={setShowLoader} />}
                            {assignTaskModal.show && <AssignTask data={assignTaskModal.data} setAssignTaskModal={setAssignTaskModal} setToastMsg={setToastMsg} setShowLoader={setShowLoader} />}
                        </>}
                    {mode.type === "view" &&
                        <ViewJobSite siteId={mode.data.txnId} mode={mode} setMode={setMode} setToastMsg={setToastMsg} accessLevel={accessLevel.childMenu} />
                    }
                    {mode.type === 'edit' &&
                        <EditJobSite siteId={mode.data.txnId} setToastMsg={setToastMsg} mode={mode} setMode={setMode} setShowLoader={setShowLoader} />
                    }
                    {showAddNewPopup &&
                        <AddNewPopup setShowG2Popup={setShowG2Popup} timeZone={timeZone} radiusUnit={radiusUnit} setToastMsg={setToastMsg} setShowLoader={setShowLoader} closePopup={() => setShowAddNewPopup(false)} setMode={setMode} />
                    }
                    {assignEmployees.show &&
                        <AssignEmployeePopup data={assignEmployees.data} setAssignEmployeeModal={setAssignEmployeeModal} setToastMsg={setToastMsg} setShowLoader={setShowLoader}/>
                    }
                    {mode.type === 'uploadError' && <FileUploadFail data={mode.data} closePopup={() => setMode({type: 'main', data: null, hasPrev: false})} setToastMsg={setToastMsg} setShowLoader={setShowLoader} />}
                </div>
            </div>
            {toastMsg.msg && <ToastMessage
                cssClass={toastMsg.type}
                message={toastMsg.msg}
                onClose={() => setToastMsg({ msg: "", type: "" })}
            />}
            <div className={`overlay ${(assignEmployees.show || radiusModal.show || assignTaskModal.show || showAddNewPopup || mode.type === 'uploadError') ? 'active' : ''}`} />
            <SiteLoader isActive={showLoader} />
        </>
    )
}

export default JobSites
