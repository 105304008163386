import React from "react";
import { DatePicker, TimePicker } from "@progress/kendo-react-dateinputs";
import "./expandViewPopup.css";
import DownArrow from "../../../../assets/images/downarrow.svg";
import { MapViewData } from "./MapViewData";

export const ExpandViewPopup = ({
  closePopup,
  handlePinned,
  handleUnPinned,
  iframeRef,
  breadcrumbData,
  tabToggle
}) => {
  return (
    <div>
      <div className="overlay2"></div>
      <div className="parentBox">
        <MapViewData
          handlePinned={handlePinned}
          handleUnPinned={handleUnPinned}
          closePopup={closePopup}
          iframeRef={iframeRef}
          breadcrumbData={breadcrumbData}
          tabToggle={tabToggle}
        />
        <div className="closebtn">
          <button onClick={closePopup} className="buttondiv">
            Close
          </button>
        </div>
      </div>
    </div>
  );
};
