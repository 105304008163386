import React, { useState, useEffect, useRef } from 'react'
import SiteLoader from '../../common/SiteLoader';
import { apiProvider } from '../../../services/api/provider';
import ToastMessage from '../../common/ToastMessage';
import { earningCodeColumns } from './settingsUtils';
import KendoGrid from '../../common/kendo/KendoGrid';
import { Prompt } from 'react-router';
import { ConfirmPopup, InfoPopup } from "../../common/PopUp";
import { getAccountLabel } from '../../../utils';

const SettingsPayroll = ({ formState, accessLevel }) => {

    const [showLoader, setShowLoader] = useState(false);
    const [employeeWageTypes, setEmployeeWageTypes] = useState([]);
    const [employeeSkills, setEmployeeSkills] = useState([]);
    const [isDirty, setIsDirty] = useState(false)
    const days = [
        {
            value: -1,
            label: "Select"
        },
        {
            value: 0,
            label: "Sunday"
        },
        {
            value: 1,
            label: "Monday"
        },
        {
            value: 2,
            label: "Tuesday"
        },
        {
            value: 3,
            label: "Wednesday"
        },
        {
            value: 4,
            label: "Thursday"
        },
        {
            value: 5,
            label: "Friday"
        },
        {
            value: 6,
            label: "Saturday"
        },
    ];
    const [payrollData, setPayrollData] = useState({
        start_date: "",
        settlement_duration: 1,
        interval: 0,
        duration: "",
        payroll_id: "",
        employee_wage_types: employeeWageTypes.toString(),
        company_code: "",
        regular_hours: "",
        regular_minutes: "",
        overtime_hours: "",
        overtime_minutes: "",
        weekly_regular_time: 0,
        weekly_over_time: 0,
        weekly_pay_period_start: "",
        weekly_pay_period_end: "",
        regular_earning_code: "",
        overtime_earning_Code: "",
        doble_overtime_earning_code: "",
        mileage_earning_code: "",
        employee_skills: "",
        round_off_minutes: "",
        include_mutli_day_span_hours: false,
        include_off_shift_hours: false,
        insert_mid_night_event: false,
        service_hours_type: '',
        service_hours_source: '',
        overtime_multiplier: 1,
        doubleOvertime_multiplier: 1
    })
    const [defaultData, setDefaultData] = useState(null)
    const [isUpdateSuccessful, setIsUpdateSuccessful] = useState(null);
    const [accountUpdateMsg, setAccountUpdateMsg] = useState('');
    const [msgClass, setMsgClass] = useState('msgError');
    const [earningCodeData, setEarningCodeData] = useState(null)
    const [payrollDuration, setPayrollDuartion] = useState('')
    const [activeClasses, setActiveClasses] = useState({
        regularTime: '',
        weeklyRegular: '',
        overTime: '',
        weeklyOverTime: ''
    })
    const [showDurationTooltip, setShowDurationTooltip] = useState(false);
    const [payrollDurationUpdated, setPayrollDuartionUpdated] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [infoPopup, setInfoPopup] = useState({ show: false, msg: '' })

    const employeeWageInputRef = useRef()
    const employeeSkillInputRef = useRef()

    useEffect(() => {
        fetchData()
    }, [])

    const updateAccountPayrollDetails = async () => {
        try {
            setShowLoader(true)

            const response = await apiProvider.put('track/react/account/updatePayroll', payrollData)

            if (response.status === 200) {
                fetchData();
                getPayrollDuration(payrollData.start_date?.split("-").join("/"), payrollData.interval, false)
                setMsgClass('msgSuccess');
                setAccountUpdateMsg('The changes have been saved');
                setIsUpdateSuccessful(true);
            }

        } catch (error) {
            setShowLoader(false)
            console.log(error);
        }
    }

    const getPayrollDuration = async (date, interval, showPopup) => {
        try {
            const response = await apiProvider.getAll(`track/react/account/payrollDuration?payrollDate=${date}&payrollInterval=${interval}`)
            setPayrollDuartion(response.data)
            if (payrollDurationUpdated && showPopup) {
                setShowDurationTooltip(true)
                await delay(2000)
                setShowDurationTooltip(false)
            }

        } catch (error) {
            console.log(error);
        }
    }

    const fetchData = async () => {
        try {
            setShowLoader(true)
            let promises = [];
            promises.push(apiProvider.getAll('track/react/account/payroll'))
            promises.push(apiProvider.getAll('track/mobile/v1/allgeo/earning/codes'))

            const result = await Promise.all(promises)

            if (result[0].employee_wage_types.trim().length > 0) {
                setEmployeeWageTypes(result[0].employee_wage_types.split(","))
            }
            if (result[0].employee_skills.trim().length > 0) {
                setEmployeeSkills(result[0].employee_skills.split(","))
            }

            if (result.length > 1) {
                setPayrollData(result[0])
                setDefaultData(result[0])
                setEarningCodeData(result[1])
            }

        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    const formCancelEvent = () => {
        if (isDirty) {
            setShowDialog(true)
        }
    }

    const onSuccess = () => {
        setShowDialog(false);
        fetchData();
    }

    const onFaliure = () => {
        setShowDialog(false);
    }

    const handleRemoveWageType = data => {
        const filteredArr = employeeWageTypes.filter(d => d !== data)

        setEmployeeWageTypes(filteredArr)
        setPayrollData({
            ...payrollData,
            employee_wage_types: filteredArr.toString()
        })
    }

    const handleRemoveSkill = skill => {
        const filteredArr = employeeSkills.filter(sk => sk !== skill)

        setEmployeeSkills(filteredArr)
        setPayrollData({
            ...payrollData,
            employee_skills: filteredArr.toString()
        })
    }

    const handleWageTypeInput = () => {
        const value = employeeWageInputRef.current?.value;

        if (value.trim().length > 0 && employeeWageTypes.findIndex(type => type.toLowerCase() === value.trim().toLowerCase()) === -1) {
            const updatedArr = [...employeeWageTypes, value.trim()]
            setEmployeeWageTypes(updatedArr)
            setPayrollData({
                ...payrollData,
                employee_wage_types: updatedArr.toString()
            })
        }

        if (value) employeeWageInputRef.current.value = ""

    }

    const handleSkillInput = () => {

        const value = employeeSkillInputRef.current?.value;

        if (value.trim().length > 0 && employeeSkills.findIndex(type => type.toLowerCase() === value.trim().toLowerCase()) === -1) {
            const updatedArr = [...employeeSkills, value.trim()]
            setEmployeeSkills(updatedArr)
            setPayrollData({
                ...payrollData,
                employee_skills: updatedArr.toString()
            })
        }

        if (value) employeeSkillInputRef.current.value = ""

    }

    const checkFormState = () => {

        for (let key in defaultData) {
            if (payrollData[key] !== defaultData[key]) {
                return true;
            }
        }

        return false;
    }

    const deleteItem = async item => {

        const payload = item.length ? item : [item]

        try {
            setShowLoader(true)
            const res = await apiProvider.removeObject("track/mobile/v1/allgeo/earning/delete", payload)

            return {
                status: res.StatusCode,
                message: res.StatusCode === 200 ? item.length ? "The selected records have been deleted successfully" : `${item.description} deleted Successfully` : res.ErrorMsg
            }

        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }

    }

    const createGridData = async dataArr => {
        try {
            const res = await apiProvider.post('track/mobile/v1/allgeo/earning/create', dataArr)
            return {
                status: res.StatusCode,
                message: res.StatusCode === 200 ? "Earningcode created successfully" : res.ErrorMsg
            };
        } catch (error) {
            console.log(error);
        }
    }

    const childRef = useRef()

    const updateGridData = async dataArr => {
        try {
            localStorage.removeItem('formIsDirty')
            const res = await apiProvider.post('track/mobile/v1/allgeo/earning/update', dataArr)
            return {
                status: res.StatusCode,
                message: res.StatusCode === 200 ? "Earningcode updated successfully" : res.ErrorMsg
            };
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (payrollData.start_date) {
            getPayrollDuration(payrollData.start_date?.split("-").join("/"), payrollData.interval, true)
            setPayrollDuartionUpdated(true)
        }
    }, [payrollData.start_date, payrollData.interval])

    useEffect(() => {
        if (defaultData) {
            setIsDirty(checkFormState())
        }
        if (isDirty) {
            localStorage.setItem('formIsDirty', isDirty)
        }
    }, [defaultData, payrollData])

    useEffect(() => {
        if (accessLevel.childMenu && accessLevel.childMenu > 1) {
            formState(isDirty)
        }
        if (isDirty) {
            localStorage.setItem('formIsDirty', isDirty)
        }
    }, [isDirty])

    const delay = ms => {
        return new Promise(res => setTimeout(res, ms))
    }

    const handleHourEntry = (e, field) => {
        let { value } = e.target;

        if (value.startsWith("0")) {
            value = value.slice(1);
        }

        if (value.length <= 2 && +value >= 0 && +value <= 24) {

            setPayrollData({
                ...payrollData,
                [field]: +value
            })
        }

    }

    const handleWeeklyHourEntry = (e, field) => {
        let { value } = e.target;

        if (value.startsWith("0")) {
            value = value.slice(1);
        }

        if (+value >= 0 && +value <= 168) {

            setPayrollData({
                ...payrollData,
                [field]: +value
            })
        }

    }

    const handleMinuteEntry = (e, field) => {
        let { value } = e.target;

        if (value.startsWith("0")) {
            value = value.slice(1);
        }

        if (value.length <= 2 && +value >= 0 && +value < 60) {

            setPayrollData({
                ...payrollData,
                [field]: +value
            })
        }

    }

    const checkWeeklyPayPeriod = (startDate, endDate) => {

        if (+startDate === -1 || +endDate === -1) return true;

        const msg = 'Please set a 7-day pay period duration.(Eg: Sun-Sat, Mon-Sun)'

        if (startDate < endDate) {
            if (startDate - endDate === -6) return true
            else {
                setInfoPopup({ show: true, msg })
                return false
            }
        } else if (startDate > endDate) {
            if (startDate - endDate === 1) return true
            else {
                setInfoPopup({ show: true, msg })
                return false
            }
        }

        setInfoPopup({ show: true, msg })
        return false

    }

    const handleInputChangeForOverTime = (e, fieldName) => {
        const { value } = e.target;

        if (/^\d*\.?\d{0,2}$/.test(value)) {
            setPayrollData({
                ...payrollData,
                [fieldName]: value
            });
        }
    };

    const employeeLabel = getAccountLabel('employee')

    return (
        <>
            <Prompt
                when={isDirty}
                message='The changes are still unsaved. Are you sure you want to leave?'
            />
            <section>
                <h3>Payroll Profile</h3>
                <div className="supportedTxt">Update your account’s payroll specific details including payroll duration, wage types and {employeeLabel} skills.</div>
                <div className="innerWrap">
                    <div className="rowBlk">
                        <div className="colBlk6 fldWrap">
                            <label>Company Code</label>
                            <input
                                type="text"
                                value={payrollData.company_code}
                                onChange={e => setPayrollData({
                                    ...payrollData,
                                    company_code: e.target.value
                                })}
                            />
                        </div>
                        <div className="colBlk3">
                            <div className="fldWrap">
                                <label>Weekly Pay Period</label>
                                <select
                                    onChange={e => {
                                        if (checkWeeklyPayPeriod(+e.target.value, +payrollData.weekly_pay_period_end))
                                            setPayrollData({
                                                ...payrollData,
                                                weekly_pay_period_start: +e.target.value
                                            })
                                    }}

                                >
                                    {
                                        days.map((day, ind) => (
                                            <option key={ind} value={day.value}>{day.label}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="colBlk3">
                            <div className="fldWrap">
                                <label>To</label>
                                <select
                                    onChange={e => {
                                        if (checkWeeklyPayPeriod(+payrollData.weekly_pay_period_start, +e.target.value))
                                            setPayrollData({
                                                ...payrollData,
                                                weekly_pay_period_end: +e.target.value
                                            })
                                    }
                                    }
                                    value={payrollData.weekly_pay_period_end}
                                >
                                    {
                                        days.map((day, ind) => (
                                            <option key={ind} value={day.value}>{day.label}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="rowBlk">
                        <div className="colBlk6 fldWrap">
                            <label>Payroll ID</label>
                            <input
                                type="text"
                                value={payrollData.payroll_id}
                                onChange={e => setPayrollData({
                                    ...payrollData,
                                    payroll_id: e.target.value
                                })}
                            />
                        </div>
                        <div className="colBlk3 fldWrap">
                            <label>Payroll Start Date</label>
                            <input
                                type="date"
                                value={payrollData.start_date.split("/").join("-")}
                                onChange={e => setPayrollData({
                                    ...payrollData,
                                    start_date: e.target.value
                                })}
                            />
                        </div>
                    </div>
                    <div className="rowBlk">
                        <div className="colBlk6 fldWrap">
                            <label className="withHelpTxt">{employeeLabel} Wage Types <span className="helpTip">? <span>Example: Hourly, Daily, Weekly, Biweekly, etc</span></span></label>
                            <div className="chipsList">
                                <ul>
                                    {
                                        employeeWageTypes.map((data, ind) => (
                                            <li key={ind}>{data} <span className="deleteItem" onClick={() => handleRemoveWageType(data)}>x</span></li>
                                        ))
                                    }
                                </ul>
                                <input
                                    type="text"
                                    placeholder="+ Add Wage Type"
                                    ref={employeeWageInputRef}
                                    onKeyPress={evt => {
                                        if (evt.key === "Enter") {
                                            handleWageTypeInput()
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className="colBlk6">
                            <div className='fldWrap'>
                                <label>Payroll Interval</label>
                                <div className="customControls capsules">
                                    <div className="customRadio">
                                        <input
                                            type="radio"
                                            id="weekly"
                                            name="interval"
                                            onClick={() => setPayrollData({
                                                ...payrollData,
                                                interval: 0
                                            })}
                                            checked={payrollData.interval === 0}
                                        />
                                        <label htmlFor="weekly">Weekly</label>
                                    </div>
                                    <div className="customRadio">
                                        <input
                                            type="radio"
                                            id="twoWeeks"
                                            name="interval"
                                            onClick={() => setPayrollData({
                                                ...payrollData,
                                                interval: 1
                                            })}
                                            checked={payrollData.interval === 1}
                                        />
                                        <label htmlFor="twoWeeks">Every 2 Weeks</label>
                                    </div>
                                    <div className="customRadio">
                                        <input
                                            type="radio"
                                            id="monthly"
                                            name="interval"
                                            onClick={() => setPayrollData({
                                                ...payrollData,
                                                interval: 2
                                            })}
                                            checked={payrollData.interval === 2}
                                        />
                                        <label htmlFor="monthly">Monthly</label>
                                    </div>
                                    <div className="customRadio">
                                        <input
                                            type="radio"
                                            id="annual"
                                            name="interval"
                                            onClick={() => setPayrollData({
                                                ...payrollData,
                                                interval: 3
                                            })}
                                            checked={payrollData.interval === 3}
                                        />
                                        <label htmlFor="annual">Annual</label>
                                    </div>
                                </div>
                            </div>
                            <div className="fldWrap">
                                <label>Payroll Duration</label>
                                <div className='highlightedValue'>
                                    <p>{payrollDuration}</p>
                                    {showDurationTooltip &&
                                        <span className="copyClipboard" style={{
                                            display: 'inline-block'
                                        }}>Payroll duration updated.</span>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="rowBlk">
                        <div className="colBlk6 fldWrap">
                            <label className="withHelpTxt">{employeeLabel} Skills <span className="helpTip">? <span>Example: Language, Technical skills, etc</span></span></label>
                            <div className="chipsList">
                                <ul>
                                    {
                                        employeeSkills.map((skill, ind) => (
                                            <li key={ind}>{skill} <span className="deleteItem" onClick={() => handleRemoveSkill(skill)}>x</span></li>
                                        ))
                                    }
                                </ul>
                                <input type="text"
                                    placeholder={`+ Add ${employeeLabel} Skills`}
                                    ref={employeeSkillInputRef}
                                    onKeyPress={evt => {
                                        if (evt.key === "Enter") {
                                            handleSkillInput()
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className='colBlk3'>
                            <div className="fldWrap">
                                <label>Payroll Settlement Duration</label>
                                <div className="withInputSub">
                                    <input
                                        type="text"
                                        value={payrollData.settlement_duration}
                                        maxLength={2}
                                        onChange={e => {
                                            const { value } = e.target;
                                            if (!isNaN(value) && Number(value) < 16) {
                                                setPayrollData({ ...payrollData, settlement_duration: Number(value) })
                                            }
                                        }}
                                        onBlur={() => {
                                            if (payrollData.settlement_duration === 0) {
                                                setPayrollData({ ...payrollData, settlement_duration: 1 })
                                            }
                                        }}
                                    />
                                    <span>days</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <h3>Payroll Hours and Codes</h3>
                <div className="supportedTxt">Manage & Update your account’s payroll hours here.</div>
                <div className="innerWrap">
                    <label>Hours Interval</label>
                    <div className="customControls capsules bottomGap1">
                        <div className="customRadio">
                            <input
                                type="radio"
                                id="dailyInterval"
                                name="HoursInterval"
                                checked={payrollData.service_hours_type === "Daily"}
                                onClick={() => {
                                    setPayrollData({
                                        ...payrollData,
                                        service_hours_type: "Daily"
                                    })
                                }}
                            />
                            <label htmlFor="dailyInterval">Daily</label>
                        </div>
                        <div className="customRadio">
                            <input
                                type="radio"
                                id="weeklyInterval"
                                name="HoursInterval"
                                checked={payrollData.service_hours_type === "Weekly"}
                                onClick={() => {
                                    setPayrollData({
                                        ...payrollData,
                                        service_hours_type: "Weekly"
                                    })
                                }}
                            />
                            <label htmlFor="weeklyInterval">Weekly</label>
                        </div>
                        <div className="customRadio">
                            <input
                                type="radio"
                                id="noInterval"
                                name="HoursInterval"
                                checked={payrollData.service_hours_type === "None"}
                                onClick={() => {
                                    setPayrollData({
                                        ...payrollData,
                                        service_hours_type: "None"
                                    })
                                }}
                            />
                            <label htmlFor="noInterval">None</label>
                        </div>
                    </div>
                    <div className="rowBlk">
                        <div className="colBlk6">
                            <div className="sectionSubHeading">Regular Hours</div>
                            <div className="rowBlk">
                                <div className={`colBlk6${payrollData.service_hours_type === "Daily" ? '' : ' blkInactive'}`}>
                                    <div className="fldWrap">
                                        <label>Daily</label>
                                        <div
                                            className={`numberFldWrap ${activeClasses.regularTime}`}
                                        >
                                            <input
                                                type="number"
                                                value={payrollData.regular_hours.toString().slice(0, 2)}
                                                min={0}
                                                max={24}
                                                onKeyDown={e => {
                                                    if (e.key === ".") e.preventDefault();
                                                }}
                                                onChange={e => handleHourEntry(e, "regular_hours")}
                                                onFocus={() => setActiveClasses({
                                                    regularTime: 'active',
                                                    weeklyRegular: '',
                                                    overTime: '',
                                                    weeklyOverTime: ''
                                                })}
                                                onBlur={() => setActiveClasses({
                                                    ...activeClasses,
                                                    regularTime: ''
                                                })}
                                            />
                                            <span>hrs</span>
                                            <span className="timeSep">:</span>
                                            <input
                                                type="number"
                                                min={0}
                                                max={59}
                                                value={payrollData.regular_minutes.toString().slice(0, 2)}
                                                onKeyDown={e => {
                                                    if (e.key === ".") e.preventDefault();
                                                }}
                                                onChange={e => handleMinuteEntry(e, "regular_minutes")}
                                                onFocus={() => setActiveClasses({
                                                    regularTime: 'active',
                                                    weeklyRegular: '',
                                                    overTime: '',
                                                    weeklyOverTime: ''
                                                })}
                                                onBlur={() => setActiveClasses({
                                                    ...activeClasses,
                                                    regularTime: ''
                                                })}
                                            />
                                            <span>mins</span>
                                        </div>
                                    </div>
                                </div>
                                <div className={`colBlk6${payrollData.service_hours_type === "Weekly" ? '' : ' blkInactive'}`}>
                                    <div className="fldWrap">
                                        <label>Weekly</label>
                                        <div className={`numberFldWrap smlFld ${activeClasses.weeklyRegular}`}>
                                            <input type="number"
                                                min={0}
                                                onKeyDown={e => {
                                                    if (e.key === ".") e.preventDefault();
                                                }}
                                                value={payrollData.weekly_regular_time.toString().slice(0, 3)}
                                                onChange={e => handleWeeklyHourEntry(e, "weekly_regular_time")}
                                                onBlur={() => setActiveClasses({
                                                    ...activeClasses,
                                                    weeklyRegular: ''
                                                })}
                                                onFocus={() => setActiveClasses({
                                                    regularTime: '',
                                                    weeklyRegular: 'active',
                                                    overTime: '',
                                                    weeklyOverTime: ''
                                                })}
                                            />
                                            <span>hrs</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="rowBlk">
                                <div className="colBlk6">
                                    <div className="fldWrap">
                                        <label>Regular Earning code</label>
                                        <input
                                            type="text"
                                            value={payrollData.regular_earning_code}
                                            onChange={e => setPayrollData({
                                                ...payrollData,
                                                regular_earning_code: e.target.value
                                            })}
                                            className='smlFld'
                                        />
                                    </div>
                                </div>
                                <div className="colBlk6">
                                    <div className="fldWrap">
                                        <label>Mileage Earning code</label>
                                        <input
                                            type="text"
                                            value={payrollData.mileage_earning_code}
                                            onChange={e => setPayrollData({
                                                ...payrollData,
                                                mileage_earning_code: e.target.value
                                            })}
                                            className='smlFld'
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="rowBlk">
                                <div className="colBlk6">
                                    <div className="fldWrap">
                                        <label>Service Hours Source</label>
                                        <select value={payrollData.service_hours_source} onChange={(e) => setPayrollData({ ...payrollData, service_hours_source: e.target.value })}>
                                            <option value="App">App</option>
                                            <option value="Device" >Device</option>
                                            <option value="Account">Account</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="colBlk6">
                            <div className="sectionSubHeading">Overtime Hours</div>
                            <div className="rowBlk">
                                <div className={`colBlk6${payrollData.service_hours_type === "Daily" ? '' : ' blkInactive'}`}>
                                    <div className="fldWrap">
                                        <label>Daily</label>
                                        <div className={`numberFldWrap ${activeClasses.overTime}`}>
                                            <input
                                                type="number"
                                                min={0}
                                                max={24}
                                                onKeyDown={e => {
                                                    if (e.key === ".") e.preventDefault();
                                                }}
                                                value={payrollData.overtime_hours.toString().slice(0, 2)}
                                                onChange={e => handleHourEntry(e, "overtime_hours")}
                                                onBlur={() => setActiveClasses({
                                                    ...activeClasses,
                                                    overTime: ''
                                                })}
                                                onFocus={() => setActiveClasses({
                                                    regularTime: '',
                                                    weeklyRegular: '',
                                                    overTime: 'active',
                                                    weeklyOverTime: ''
                                                })}
                                            />
                                            <span>hrs</span>
                                            <span className="timeSep">:</span>
                                            <input
                                                type="number"
                                                min={0}
                                                max={59}
                                                onKeyDown={e => {
                                                    if (e.key === ".") e.preventDefault();
                                                }}
                                                value={payrollData.overtime_minutes.toString().slice(0, 2)}
                                                onChange={e => handleMinuteEntry(e, "overtime_minutes")}
                                                onBlur={() => setActiveClasses({
                                                    ...activeClasses,
                                                    overTime: ''
                                                })}
                                                onFocus={() => setActiveClasses({
                                                    regularTime: '',
                                                    weeklyRegular: '',
                                                    overTime: 'active',
                                                    weeklyOverTime: ''
                                                })}
                                            />
                                            <span>mins</span>
                                        </div>
                                    </div>
                                </div>
                                <div className={`colBlk6${payrollData.service_hours_type === "Weekly" ? '' : ' blkInactive'}`}>
                                    <div className="fldWrap"

                                    >
                                        <label>Weekly</label>
                                        <div className={`numberFldWrap smlFld ${activeClasses.weeklyOverTime}`}>
                                            <input
                                                type="number"
                                                min={0}
                                                onKeyDown={e => {
                                                    if (e.key === ".") e.preventDefault();
                                                }}
                                                value={payrollData.weekly_over_time.toString().slice(0, 3)}
                                                onChange={e => handleWeeklyHourEntry(e, "weekly_over_time")}
                                                onBlur={() => setActiveClasses({
                                                    ...activeClasses,
                                                    weeklyOverTime: ''
                                                })}
                                                onFocus={() => setActiveClasses({
                                                    regularTime: '',
                                                    weeklyRegular: '',
                                                    overTime: '',
                                                    weeklyOverTime: 'active'
                                                })}
                                            />
                                            <span>hrs</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="rowBlk">
                                <div className="colBlk6">
                                    <div className="fldWrap">
                                        <label>OT Earning code</label>
                                        <input
                                            type="text"
                                            value={payrollData.overtime_earning_Code}
                                            onChange={e => setPayrollData({
                                                ...payrollData,
                                                overtime_earning_Code: e.target.value
                                            })}
                                            className='smlFld'
                                        />
                                    </div>
                                </div>
                                <div className="colBlk6">
                                    <div className="fldWrap">
                                        <label>Double OT Earning code</label>
                                        <input
                                            type="text"
                                            value={payrollData.doble_overtime_earning_code}
                                            onChange={e => setPayrollData({
                                                ...payrollData,
                                                doble_overtime_earning_code: e.target.value
                                            })}
                                            className='smlFld'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="rowBlk">
                                <div className="colBlk6">
                                    <div className="fldWrap">
                                        <label>OT Multiplier</label>
                                        <input
                                            type="number"
                                            value={payrollData.overtime_multiplier}
                                            onChange={e => handleInputChangeForOverTime(e, 'overtime_multiplier')}
                                            className='smlFld'
                                        />
                                    </div>
                                </div>
                                <div className="colBlk6">
                                    <div className="fldWrap">
                                        <label>Double OT Multiplier</label>
                                        <input
                                            type="Number"
                                            value={payrollData.doubleOvertime_multiplier}
                                            onChange={e => handleInputChangeForOverTime(e, 'doubleOvertime_multiplier')}
                                            className='smlFld'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <h3>Reporting</h3>
                <div className="supportedTxt">Manage Reports related settings here.</div>
                <div className="innerWrap">
                    <div className="rowBlk">
                        <div className="colBlk3 fldWrap">
                            <label className='withHelpTxt'>Include Off Shift Hrs? <span className="helpTip">?<span>Includes off-shift hours to over time calculation</span></span></label>
                            <div className="customCheck yesNo">
                                <input
                                    type="checkbox"
                                    id="OffShift"
                                    checked={payrollData.include_off_shift_hours}
                                    onChange={() => {
                                        setPayrollData({
                                            ...payrollData,
                                            include_off_shift_hours: !payrollData.include_off_shift_hours
                                        })
                                    }}
                                />
                                <label className="customCheckLabel" htmlFor="OffShift">yesNo</label>
                            </div>
                        </div>
                        <div className="colBlk3 fldWrap">
                            <label className='withHelpTxt'>Include Multi Day Span Hrs? <span className="helpTip">?<span>Includes multi day span hours</span></span></label>
                            <div className="customCheck yesNo">
                                <input
                                    type="checkbox"
                                    id="MutliDay"
                                    checked={payrollData.include_mutli_day_span_hours}
                                    onChange={() => {
                                        setPayrollData({
                                            ...payrollData,
                                            include_mutli_day_span_hours: !payrollData.include_mutli_day_span_hours
                                        })
                                    }}
                                />
                                <label className="customCheckLabel" htmlFor="MutliDay">yesNo</label>
                            </div>
                        </div>
                        <div className="colBlk3 fldWrap">
                            <label className="withHelpTxt">Insert Midnight Event? <span className="helpTip">? <span> Inserts time-clock events at midnight for the multi-day span employees</span></span></label>
                            <div className="customCheck yesNo">
                                <input
                                    type="checkbox"
                                    id="MidnightEvent"
                                    checked={payrollData.insert_mid_night_event}
                                    onChange={() => {
                                        setPayrollData({
                                            ...payrollData,
                                            insert_mid_night_event: !payrollData.insert_mid_night_event
                                        })
                                    }}
                                />
                                <label className="customCheckLabel" htmlFor="MidnightEvent">yesNo</label>
                            </div>
                        </div>
                    </div>
                    <div className='rowBlk'>
                        <div className="colBlk3 fldWrap">
                            <label>Round Off Minutes</label>
                            <div className="withInputSub smlFld">
                                <input
                                    type="text"
                                    value={payrollData.round_off_minutes}
                                    onChange={e => {
                                        const { value } = e.target;
                                        if (!isNaN(value) && +value < 61) {
                                            setPayrollData({
                                                ...payrollData,
                                                round_off_minutes: +value
                                            })
                                        }
                                    }}
                                />
                                <span>mins</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <h3>Earning Codes</h3>
                <div className="supportedTxt">Manage all your company’s earning codes here.</div>
                {earningCodeData &&
                    <KendoGrid
                        gridData={earningCodeData}
                        columnData={earningCodeColumns}
                        createItem={createGridData}
                        updateItem={updateGridData}
                        deleteItem={deleteItem}
                        bulkDelete={deleteItem}
                        accessLevel={accessLevel}
                        getAPiUrl={"track/mobile/v1/allgeo/earning/codes"}
                        ref={childRef}
                        setGridData={setEarningCodeData}
                        module="settings"
                        lockActionColumn={true}
                        otherData={{
                            actionColumnBtns: ['delete'],
                            isDirty: val => formState(val),
                            exportFileName: "Earning Codes"
                        }}
                    />
                }
            </section>
            {accessLevel.childMenu && accessLevel.childMenu > 1 && <div className="mainBtnWrap">
                <a className='cursorPointer' onClick={formCancelEvent}>Cancel</a>
                <button className="btnStyle" onClick={() => {
                    localStorage.removeItem('formIsDirty')
                    childRef.current.saveData().then(res => {
                        if (res) {
                            updateAccountPayrollDetails()
                        }
                    })
                }}>SAVE</button>
            </div>}
            <div className={`overlay ${infoPopup.show ? 'active' : ''}`} />
            <SiteLoader isActive={showLoader} />
            {
                isUpdateSuccessful &&
                <ToastMessage cssClass={msgClass} message={accountUpdateMsg}
                    onClose={() => { setIsUpdateSuccessful(false); }}
                />
            }
            <ConfirmPopup
                showDialog={showDialog}
                message={"The changes are still unsaved. Are you sure you want to leave?"}
                confirmNavigation={onSuccess}
                cancelNavigation={onFaliure}
            />
            {infoPopup.show && <InfoPopup
                msg={infoPopup.msg}
                onClick={() => setInfoPopup({ show: false, msg: '' })}
            />}
        </>
    )
}

export default SettingsPayroll