import React, { useState } from 'react'
import { apiProvider } from '../../../services/api/provider'
import { units } from './jobSitesUtils'

const RadiusPopup = ({ data, setRadiusModal, setToastMsg, setShowLoader }) => {
    const minValue = units[data[0].geozone.radiusUnit.toLowerCase()] === 'm' ? 1 : 4;

    const [updatedRadiusData, setUpdatedRadiusData] = useState({
        updatedRadius: minValue,
        sign: "inc"
    })

    const handleRadiusUpdate = async () => {
        if (data) {

            try {
                setShowLoader(true)
                const sign = updatedRadiusData.sign === "inc" ? 1 : -1;

                const payload = data.map(d => ({
                    ...d,
                    geozone: {
                        ...d.geozone,
                        zoneRadius: d.geozoneType === "Circle" ? d.radius + (sign * +updatedRadiusData.updatedRadius) : 0
                    },
                    radius: d.geozoneType === "Circle" ? d.radius + (sign * +updatedRadiusData.updatedRadius) : 0,
                    active: (d.isActive == "Active" || d.isActive == "true") ? true : false,
                }))

                const negativeRadiusCheck = payload.filter(d => d.geozoneType === "Circle").map(d => d.radius).some(radius => radius < minValue)

                if (negativeRadiusCheck) {

                    setToastMsg({
                        type: 'msgError',
                        msg: `The radius cannot be less than ${minValue} ${data[0].geozone.radiusUnit.toLowerCase() === "meters" ? "meter" : "feet"}. Please enter a significant value.`
                    })
                    return
                }

                const response = await apiProvider.put("/track/mobile/v1/allgeo/updateCustomers", payload)
                if (response.status === 200) {
                    setToastMsg({
                        type: 'msgSuccess',
                        msg: response.message
                    })
                    closeRadiusPopup()
                }
            } catch (error) {
                console.log(error);
            } finally {
                setShowLoader(false)
            }
        }
    }

    const closeRadiusPopup = () => {
        setRadiusModal({
            show: false,
            data: null
        })
    }

    return (
        <div className="popupWrap smlPopup active">
            <div className="closePopup" onClick={closeRadiusPopup}>Close</div>
            <div className="popupHeading">Update Radius</div>
            <div className="popupContent">
                <div className="popupInnerHeading">Adjust Radius by</div>
                <div className="popupTabCont">
                    <div className="rowBlk">
                        <div className="colBlk6 fldWrap">
                            <label>Select</label>
                            <select
                                onChange={e => setUpdatedRadiusData({
                                    ...updatedRadiusData,
                                    sign: e.target.value
                                })}
                                value={updatedRadiusData.sign}
                            >
                                <option value={"inc"}>Increase Radius</option>
                                <option value={"dec"}>Decrease Radius</option>
                            </select>
                        </div>
                        <div className="colBlk6 fldWrap">
                            <label>by</label>
                            <div className="withInputSub">
                                <input
                                    type="text"
                                    value={updatedRadiusData.updatedRadius}
                                    maxLength={30}
                                    onChange={e => {
                                        const { value } = e.target
                                        const numPattern = new RegExp('^[0-9]*$')
                                        const isNum = numPattern.test(value)

                                        if (isNum) {
                                            setUpdatedRadiusData({
                                                ...updatedRadiusData,
                                                updatedRadius: value
                                            })
                                        }
                                    }}
                                />
                                <span>
                                    {
                                        data.length > 0
                                            ?
                                            units[data[0].geozone.radiusUnit.toLowerCase()]
                                            :
                                            ""
                                    }
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="popupBtnWrap flexJCenter">
                <a onClick={closeRadiusPopup} >Cancel</a>
                <button
                    className="btnStyle okBtn"
                    onClick={handleRadiusUpdate}
                    disabled={!updatedRadiusData.updatedRadius}
                >SAVE</button>
            </div>
        </div>
    )
}

export default RadiusPopup