import React, { Fragment, useEffect, useState } from "react";
import { apiProvider } from '../../../services/api/provider';
import { useForm } from "react-hook-form";
import SiteLoader from '../../common/SiteLoader';
import { Prompt } from "react-router-dom";
import { getAccountLabel } from "../../../utils";
import { Tooltip } from '@progress/kendo-react-tooltip';
import InfoIcon from '../../../assets/images/icon-info-2.svg';

export default function EmployeeGeneral(props) {

    const { register, handleSubmit, getValues, setValue, reset, formState: { errors, isDirty, isValid } } = useForm({ mode: "all" });
    const [employeeGeneralValue, setEmployeeGeneralValue] = useState({});
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        if (Object.keys(props.data).length > 0) {
            setEmployeeGeneralValue(props.data);
        }
    }, [props.data]);

    useEffect(() => {
        props.formState(isDirty);
        if (isDirty) {
            localStorage.setItem('formIsDirty', isDirty)
        }
    }, [isDirty]);

    useEffect(() => {
        if (Object.keys(employeeGeneralValue).length > 0) {

            reset({
                ...employeeGeneralValue,
                active: employeeGeneralValue.active === 'Yes',
                notifications: employeeGeneralValue.notifications === "Yes"
            })

        }
    }, [employeeGeneralValue]);

    const onSubmit = (data) => {
        data.distance = data.distance ? data.distance.toString() : '0';
        data.active = data.active ? "Yes" : "No";
        data.notifications = data.notifications ? "Yes" : "No";
        setShowLoader(true);
        apiProvider.put('track/mobile/v1/allgeo/updateEmpGeneralTab/' + props.employeeId, data).then((response) => {
            if (response.status && response.status == 200) {
                props.setToastMsg({ msg: response.message, type: 'msgSuccess' })
                props.refreshAllTabdata()
            } else {
                props.setToastMsg({ msg: response.message, type: 'msgError' })
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setShowLoader(false);
            props.formState(false);
            localStorage.removeItem('formIsDirty')
        });
    }

    const onChangeJobSite = (event, type) => {
        if (type == 'home') {
            setValue('homeSite', event.target.value, { shouldDirty: true });
        }
        if (type == 'office') {
            setValue('officeSite', event.target.value, { shouldDirty: true });
        }
        let homeSite = getValues('homeSite');
        let officeSite = getValues('officeSite');
        let phoneNumber = getValues('phoneNumber');

        if (homeSite && officeSite) {
            let resource = '/track/react/device/homeToOfficeDistance?deviceId=' + phoneNumber + '&homeSite=' + homeSite + '&officeSite=' + officeSite;
            apiProvider.getAll(resource).then((response) => {
                if (response.status === 200 && response.message.toLowerCase() === 'success') {
                    props.setToastMsg({ msg: 'The distance between Home site & Office site is ' + response.data, type: 'msgInfo' })

                }
            }).catch((error) => {
                console.log(error);
            });
        }
    }

    const employeeLabel = getAccountLabel('employee') || 'Employee';

    return (
        <Fragment>
            <Prompt
                when={isDirty}
                message='The changes are still unsaved. Are you sure you want to leave?'
            />
            <form onSubmit={handleSubmit(onSubmit)} id="settingsForm">
                <section>
                    <h3>{employeeLabel} Information</h3>
                    <div className="supportedTxt">You can check and update the employee’s general information here.</div>
                    <div className="innerWrap">
                        <div className="rowBlk">
                            <div className="colBlk6 fldWrap">
                                <label>{props.nomenclature.device_title} ID</label>
                                <input type="text" {...register('employeeId')} />
                            </div>
                            <div className="colBlk3 fldWrap">
                                <span style={{display: 'flex'}}>
                                <label>Is this an active {props.nomenclature.device_title}?</label>
                                <Tooltip position='top' anchorElement="target">
                                    <img title='Disabling this will suspend the device' alt="Info Icon" src={InfoIcon} style={{margin: '2px 0 0 4px', cursor: 'pointer'}}/>
                                </Tooltip>
                                </span>
                                <div className="customCheck yesNo">
                                    <input type="checkbox" id="activeEmployee" {...register('active')} />
                                    <label className="customCheckLabel" htmlFor="activeEmployee">yesNo</label>
                                </div>
                            </div>
                            <div className="colBlk3 fldWrap">
                                <span style={{display: 'flex'}}>
                                <label>Enable Notification?</label>
                                    <Tooltip position='top' anchorElement="target">
                                        <img title='Disabling this will not trigger any rule based notifications' alt="Info Icon" src={InfoIcon} style={{margin: '2px 0 0 4px', cursor: 'pointer'}}/>
                                    </Tooltip>
                                </span>
                                <div className="customCheck yesNo">
                                    <input type="checkbox" id="enableNotification" {...register('notifications')} />
                                    <label className="customCheckLabel" htmlFor="enableNotification">yesNo</label>
                                </div>
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className="colBlk6 fldWrap">
                                <label>{props.nomenclature.device_title} Description</label>
                                <input type="text" {...register('employeeDescription')} />
                            </div>
                            {/* <div className="colBlk6 fldWrap">
                                <label>Short Nam</label>
                                <input type="text" {...register('employeeShortName')} />
                            </div> */}
                            <div className="colBlk3 fldWrap">
                                <label>{employeeLabel} First Name</label>
                                <input type="text" {...register('employeeFirstName')} />
                            </div>
                            <div className="colBlk3 fldWrap">
                                <label>{employeeLabel} Last Name</label>
                                <input type="text" {...register('employeeLastName')} />
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className={`colBlk6 fldWrap ${errors.smsEmail ? 'errFld' : ''}`}>
                                <label>SMS Email Address</label>
                                <input type="text" {...register('smsEmail', { pattern: /\S+@\S+\.\S+/ })} />
                                {errors.smsEmail && <div className="fldMsg">Please enter a valid email</div>}
                            </div>
                            <div className={`colBlk6 fldWrap ${errors.emailNotified ? 'errFld' : ''}`}>
                                <label>Email Notified</label>
                                <input type="text" {...register('emailNotified', { pattern: /\S+@\S+\.\S+/ })} />
                                {errors.emailNotified && <div className="fldMsg">Please enter a valid email</div>}
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className="colBlk3 fldWrap">
                                <label>Timezone</label>
                                <select {...register('timeZone')}>
                                    <option value="US/Hawaii">US/Hawaii</option>
                                    <option value="US/Alaska">US/Alaska</option>
                                    <option value="US/Pacific">US/Pacific</option>
                                    <option value="US/Arizona">US/Arizona</option>
                                    <option value="US/Mountain">US/Mountain</option>
                                    <option value="US/Central">US/Central</option>
                                    <option value="US/Eastern">US/Eastern</option>
                                    <option value="Canada/Pacific">Canada/Pacific</option>
                                    <option value="Canada/Mountain">Canada/Mountain</option>
                                    <option value="Canada/Central">Canada/Central</option>
                                    <option value="Canada/Eastern">Canada/Eastern</option>
                                    <option value="Canada/Atlantic">Canada/Atlantic</option>
                                    <option value="Mexico/BajaNorte">Mexico/BajaNorte</option>
                                    <option value="Mexico/BajaSur">Mexico/BajaSur</option>
                                    <option value="Mexico/General">Mexico/General</option>
                                    <option value="Europe/Athens">Europe/Athens</option>
                                    <option value="Europe/Berlin">Europe/Berlin</option>
                                    <option value="Europe/Dublin">Europe/Dublin</option>
                                    <option value="Europe/Helsinki">Europe/Helsinki</option>
                                    <option value="Europe/Kiev">Europe/Kiev</option>
                                    <option value="Europe/Lisbon">Europe/Lisbon</option>
                                    <option value="Europe/London">Europe/London</option>
                                    <option value="Europe/Madrid">Europe/Madrid</option>
                                    <option value="Europe/Moscow">Europe/Moscow</option>
                                    <option value="Europe/Oslo">Europe/Oslo</option>
                                    <option value="Europe/Paris">Europe/Paris</option>
                                    <option value="Europe/Rome">Europe/Rome</option>
                                    <option value="Europe/Stockholm">Europe/Stockholm</option>
                                    <option value="Europe/Zurich">Europe/Zurich</option>
                                    <option value="Pacific/Auckland">Pacific/Auckland</option>
                                    <option value="Pacific/Chatham">Pacific/Chatham</option>
                                    {/* <option value="GMT">GMT</option> */}
                                    <option value="GMT+00:00">GMT+00:00</option>
                                    <option value="GMT+01:00">GMT+01:00</option>
                                    <option value="GMT+02:00">GMT+02:00</option>
                                    <option value="GMT+03:00">GMT+03:00</option>
                                    <option value="GMT+04:00">GMT+04:00</option>
                                    <option value="GMT+05:00">GMT+05:00</option>
                                    <option value="GMT+05:30">GMT+05:30</option>
                                    <option value="GMT+06:00">GMT+06:00</option>
                                    <option value="GMT+07:00">GMT+07:00</option>
                                    <option value="GMT+08:00">GMT+08:00</option>
                                    <option value="GMT+09:00">GMT+09:00</option>
                                    <option value="GMT+10:00">GMT+10:00</option>
                                    <option value="GMT+11:00">GMT+11:00</option>
                                    <option value="GMT+12:00">GMT+12:00</option>
                                    <option value="GMT+13:00">GMT+13:00</option>
                                    <option value="GMT+14:00">GMT+14:00</option>
                                    <option value="GMT-01:00">GMT-01:00</option>
                                    <option value="GMT-02:00">GMT-02:00</option>
                                    <option value="GMT-03:00">GMT-03:00</option>
                                    <option value="GMT-04:00">GMT-04:00</option>
                                    <option value="GMT-05:00">GMT-05:00</option>
                                    <option value="GMT-06:00">GMT-06:00</option>
                                    <option value="GMT-07:00">GMT-07:00</option>
                                    <option value="GMT-08:00">GMT-08:00</option>
                                    <option value="GMT-09:00">GMT-09:00</option>
                                    <option value="GMT-10:00">GMT-10:00</option>
                                    <option value="GMT-11:00">GMT-11:00</option>
                                    <option value="GMT-12:00">GMT-12:00</option>
                                </select>
                            </div>
                            <div className="colBlk6 leftGap3 fldWrap">
                                <label>Vehicle ID</label>
                                <input type="text" {...register('vehicleId')} />
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className="colBlk6 fldWrap">
                                <label>Office Site</label>
                                <select {...register('officeSite')} onChange={(event) => { onChangeJobSite(event, 'office') }}>
                                    {
                                        employeeGeneralValue.officeSiteOptions && employeeGeneralValue.officeSiteOptions.length > 0 ?
                                            employeeGeneralValue.officeSiteOptions.map((item) => {
                                                return <option key={item.siteId} value={item.siteId}>{item.siteName}</option>
                                            }) : ''
                                    }
                                </select>
                            </div>
                            <div className="colBlk6 fldWrap">
                                <label>Home Site</label>
                                <select {...register('homeSite')} onChange={(event) => { onChangeJobSite(event, 'home') }}>
                                    {
                                        employeeGeneralValue.homeSiteOptions && employeeGeneralValue.homeSiteOptions.length > 0 ?
                                            employeeGeneralValue.homeSiteOptions.map((item) => {
                                                return <option key={item.siteId} value={item.siteId}>{item.siteName}</option>
                                            }) : ''
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className="colBlk3 fldWrap">
                                <label>Distance</label>
                                <div className="withInputSub">
                                    <input type="text" {...register('distance')} />
                                    <span>miles</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <h3>Healthcare Information</h3>
                    <div className="supportedTxt">Update your account information including who should receive notification emails and your preferred timezone</div>
                    <div className="innerWrap">
                        <div className="rowBlk">
                            <div className="colBlk6 fldWrap">
                                <label>Max Caseload</label>
                                <input type="text" {...register('maxCaseload')} />
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className="colBlk6 fldWrap">
                                <label>Max Community Home Members</label>
                                <input type="text" {...register('maxCommunityHomeMembers')} />
                            </div>
                            <div className="colBlk6 fldWrap">
                                <label>Max Nursing Home Members	</label>
                                <input type="text" {...register('maxNursingHomeMembers')} />
                            </div>
                        </div>
                    </div>
                </section>
                {props.accessLevel.childMenu && props.accessLevel.childMenu > 1 && <div className="mainBtnWrap">
                    <a className="cursorPointer" onClick={props.formCancel}>Cancel</a>
                    <button disabled={!isDirty || !isValid} className="btnStyle">SAVE</button>
                </div>}
            </form>
            <SiteLoader isActive={showLoader} />
        </Fragment>
    )
}
