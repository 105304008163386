import React, { useState } from "react";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import "./locationtable.css";
import LocationIcon from "../../../../assets/images/location.svg";
import { CurrentLocationModal } from "./CurrentLocationModal";
import { getAccountLabel } from "../../../../utils";
import { TooltipKendo } from "../../common/Tooltip";

export const LocationTable = ({
  employeeData,
  fetchEmployeeAppData,
  setToastMsg,
}) => {
  const [showExpanMore, setShowExpandMore] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState({});

  const getLocationCell = (props) => {
    const { dataItem, onClickExpandMore } = props;

    return (
      <td>
        {dataItem["employeeID"] && (
          <img
            onClick={() => {
              console.log(dataItem["locationStatus"], "testttt");
              onClickExpandMore(dataItem["locationStatus"]);
              setShowExpandMore(true);
            }}
            src={LocationIcon}
            alt="Location Icon"
            className="locationIcon"
          />
        )}
      </td>
    );
  };
  const truncateText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text?.substring(0, maxLength) + "...";
    }else if(!text){
      return <span style={{color:"#DC2B30"}}>Location Not Found</span>
    }
    
    else {
      return text;
    }
  };
  const employeeLabel = getAccountLabel("employee");

  const grid = (
    <Grid className="tablediv" data={employeeData?.data}>
      <Column
        field="employeeName"
        title={`${employeeLabel} Name`}
        // width="100%"
      />
      <Column
        field="locationStatus.lastLoaction"
        title="Last Location"
        // width="100%"
        cell={(props) => (
          <td
            title={
              props?.dataItem?.locationStatus?.lastEventAddress?.length > 29
                ? props?.dataItem?.locationStatus?.lastEventAddress
                : ""
            }
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              textAlign: "left",
              cursor: props?.dataItem?.locationStatus?.lastEventAddress?.length >29?"pointer":"",
              // width: '100%'
            }}
          >
            <TooltipKendo>
              {truncateText(
                props?.dataItem?.locationStatus?.lastEventAddress,
                29
              )}
            </TooltipKendo>
          </td>
        )}
      />
      <Column
        field="employeeID"
        title="Get Location"
        cell={(props) =>
          getLocationCell({
            ...props,
            onClickExpandMore: (lastLocation) => {
              setSelectedLocation({ ...props });
              console.log("Last Location:", lastLocation);
            },
          })
        }
        // width="100%"
      />
    </Grid>
  );

  return (
    <div>
      {grid}

      {!!showExpanMore && (
        <CurrentLocationModal
          fetchEmployeeAppData={fetchEmployeeAppData}
          selectedLocation={selectedLocation}
          closePopup={() => setShowExpandMore(false)}
          setToastMsg={setToastMsg}
        />
      )}
    </div>
  );
};
