import { useEffect, useState } from 'react'
import { apiProvider } from '../services/api/provider'

const useGeneralSettings = () => {
    const [generalSettings, setGeneralSettings] = useState(null)

    const loadGeneralSettings = async () => {
        try {
            const response = await apiProvider.getAll('track/react/account/generalSetting')
            setGeneralSettings(response)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        loadGeneralSettings()
    }, [])

    if (!generalSettings) return {}

    return {
        dateDisplayFormat: generalSettings?.report_units?.date_display_format,
        timeFormat: generalSettings?.report_units?.datetime?.toLowerCase() === 'standard' ? 'hh:mm:ss a' : 'HH:mm:ss',
        timezone: generalSettings?.basic_details?.timezone,
        nomenclature: generalSettings?.nomenclature,
        reportUnits: generalSettings?.report_units,
    }
}

export default useGeneralSettings