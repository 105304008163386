
import { isDisabled } from "../../kendo/kendoUtils";

export const BulkDeleteBtnWO = ({ onClick, accessLevel, selectedState, data }) => {

    const isTxnIDNull = data?.some(d => selectedState[d.id] && d.txnID === null);
    const isDeleteDisabled = isTxnIDNull || isDisabled('delete:multi', accessLevel, selectedState);
    const buttonClassName = isDeleteDisabled ? "work_btn_style btn6 dlt_disbl" : "work_btn_style btn5 del_btn";

    return (
        <button
            onClick={onClick}
            className={` tooltip_del ${buttonClassName}`}
            disabled={isDeleteDisabled}
        >
            <span>Delete Selected Item</span>
        </button>
    );
}