import React from "react";
import { MonitorNavigationMapLocationCard } from ".";

export const MonitorMapLocation = ({
  handlePinned,
  handleUnPinned,
  employeeData,
  fetchEmployeeAppData,
  setToastMsg,
  allEmployeeList,
  breadcrumbData,
  setBreadcrumbData,
}) => {
  return (
    <>
      <MonitorNavigationMapLocationCard
        allEmployeeList={allEmployeeList}
        handlePinned={handlePinned}
        handleUnPinned={handleUnPinned}
        breadcrumbData={breadcrumbData}
        setBreadcrumbData={setBreadcrumbData}
        fetchEmployeeAppData={fetchEmployeeAppData}
        employeeData={employeeData}
        setToastMsg={setToastMsg}
      />
    </>
  );
};
