import React, { useState, useContext, useEffect } from "react";
import LeftContent from "./LeftContent";
import useInput from '../../hooks/useInput';
import { Link, useHistory } from "react-router-dom";
import AuthContext from '../../store/auth-context';
import { apiProvider } from '../../services/api/provider';
import ToastMessage from '../common/ToastMessage';
import GoogleLoginComponent from './GoogleLoginComponent';
import { useDocumentTitle } from '../../hooks/setDocumentTitle';

const LoginAccountId = (props) => {
  useDocumentTitle("Login - allGeo");
  const [passwordFieldType, setPasswordFieldType] = useState('password');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoginError, setIsLoginError] = useState(false);
  const [loginErrorMsg, setLoginErrorMsg] = useState('');
  const [rememberMe, setRememberMe] = useState(false);

  const authCtx = useContext(AuthContext);
  const history = useHistory();
  const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  useEffect(() => {
    try {
      let remember = JSON.parse(localStorage.getItem('remember_acid'));
      if (remember) {
        setRememberMe(true);
        if (typeof remember === 'object') {
          setDefaultValueAccountId(remember.accountId);
          setDefaultValueUsername(remember.username)
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, []);


  const changeFieldType = () => {
    passwordFieldType === 'password' ? setPasswordFieldType('text') : setPasswordFieldType('password')
  };

  let formIsValid = false;

  const isNotEmpty = (val) => {
    return val.trim() !== '';
  }
  const {
    value: enteredAccountId,
    isValid: enteredAccountIdIsValid,
    hasError: enteredAccountIdHasError,
    valueChangeHandler: enteredAccountIdChangedHandler,
    inputBlurHandler: enteredAccountIdBlurHandler,
    inputFocusHandler: enteredAccountIdFocusHandler,
    isFocused: accountIdIsFocused,
    reset: enteredAccountIdInput,
    setDefault: setDefaultValueAccountId,
  } = useInput(isNotEmpty);
  let accountIdInputClasses = enteredAccountIdHasError ? 'errorFld' : accountIdIsFocused ? 'active' : '';

  const {
    value: enteredUserName,
    isValid: enteredUserNameIsValid,
    hasError: enteredUserNameHasError,
    valueChangeHandler: enteredUserNameChangedHandler,
    inputBlurHandler: enteredUserNameBlurHandler,
    inputFocusHandler: enteredUserNameFocusHandler,
    isFocused: userNameFocused,
    reset: enteredUserNameInput,
    setDefault: setDefaultValueUsername
  } = useInput(isNotEmpty);
  let userNameInputClasses = enteredUserNameHasError ? 'errorFld' : userNameFocused ? 'active' : '';


  const {
    value: enteredPassword,
    isValid: enteredPasswordIsValid,
    hasError: enteredPasswordHasError,
    valueChangeHandler: enteredPasswordChangedHandler,
    inputBlurHandler: enteredPasswordBlurHandler,
    inputFocusHandler: enteredPasswordFocusHandler,
    isFocused: passwordFocused,
    reset: enteredPasswordInput
  } = useInput(isNotEmpty);
  let passwordInputClasses = enteredPasswordHasError ? 'errorFld' : passwordFocused ? 'active' : '';


  if (enteredAccountIdIsValid && enteredUserNameIsValid && enteredPasswordIsValid) {
    formIsValid = true;
  }


  const loginWithAccountHandler = (event) => {
    event.preventDefault();

    if (!formIsValid || isLoading) {
      return;
    }

    if (rememberMe) {
      const formData = {
        accountId: enteredAccountId,
        username: enteredUserName
      }
      localStorage.setItem('remember_acid', JSON.stringify(formData));
    } else {
      localStorage.removeItem('remember_acid');
    }

    let loginForm = {
      accountId: enteredAccountId,
      userId: enteredUserName,
      password: enteredPassword,
      source: "REACT_USER"
    };

    props.setUserID({
      "accountID": enteredAccountId,
      "userID": enteredUserName
    })
    props.setAccountID(enteredAccountId);
    localStorage.setItem('enteredUserName', enteredUserName)
    const otpEnabledPayload = {
      "accountID": enteredAccountId,
      "userID": enteredUserName
    }
    const testOtpEnabledPayload = {
      "accountID": "qa",
      "userID": 'tenzingmanager'
    }

    setIsLoading(true);
    
    apiProvider.post('track/react/v1/2fa/is2FaEnabled', otpEnabledPayload).then((res) => {
      try {
        if(res.status === 200 && res.response == "true" || res.response == true) {
          props.setContactPhoneNumber(res.contact_phone)
          props.setIs2FAEnabled(true);
        }
      }
      catch(err) {
        console.error(err)
      }
    })
    apiProvider.post('track/react/v1/login', loginForm).then((response) => {
      setIsLoading(false);
      localStorage.setItem('landingPage', response.landingPage)
      console.log("homepage response login",response)
      switch (response.status) {
        case 200:
          localStorage.setItem('isNewMonitorPage',response?.isNewMonitorPage)

          localStorage.setItem('employeeAppData',response?.monitorCards?.employeeAppData)
          localStorage.setItem('mapAndLocation',response?.monitorCards?.mapAndLocation)
          localStorage.setItem('milesData',response?.monitorCards?.milesData)
          localStorage.setItem('notificationsAndAlerts',response?.monitorCards?.notificationsAndAlerts)
          localStorage.setItem('timeClockData',response?.monitorCards?.timeClockData)
          localStorage.setItem('timeClockReports',response?.monitorCards?.timeClockReports)

          const expirationTime = new Date(new Date().getTime() + +response.expiry);
          authCtx.login(response.token, response.token_en, expirationTime.toISOString(), response.is_parent, response.accountId, response.userName, response.isBannerEnabled, response.bannerText, response.userSecurityType, response.actSecurityType, response.landingPage, response.hasG2Reviewed,response.isNewMonitorPage);
          localStorage.setItem('lastLoggedInForm', 'accountId');
          localStorage.setItem('isOnBoarding',response.isOnBoarding)
          history.replace('/');
          break;
        case 401:
          handelAuthErrorMsg(response.message);
          break;
        case 45:
          history.replace('/trial-account-expired');
          break;
        default:
          handelAuthErrorMsg(response.message);
      }


    }).catch(err => console.log(err));


  }

  const handelAuthErrorMsg = (serverMessage) => {
    let msg = <>{serverMessage}</>;
    setIsLoginError(true);
    if (serverMessage == 'Invalid Credentials') {
      msg = <>Credentials are invalid. If you don't remember your password, <Link to='/reset-password'>reset it now.</Link></>;
    }
    setLoginErrorMsg(msg);
  }

  const checkBoxChangeHandler = (event) => {
    event.target.checked ? setRememberMe(true) : setRememberMe(false);
  }

  let signUpUrl = '../sign-up-freetrial-field-service-management-apps';
  if(process.env.REACT_APP_API_URL === 'https://app.allgeo.com') {
    signUpUrl = 'https://www.allgeo.com/sign-up-freetrial-field-service-management-apps'
  }

  return (
    <div className="userPageWrapper">
      <LeftContent><p>Don't have an account? <a href={signUpUrl}>Start your free trial</a></p></LeftContent>
      <div className="rightContent">
        <h2>Customer Login</h2>
        <form onSubmit={loginWithAccountHandler}>
          <div className={`fldWrap accountIdFld fldWithIcon ${accountIdInputClasses}`}>
            <input type="text" placeholder="Account ID"
              onChange={enteredAccountIdChangedHandler}
              value={enteredAccountId}
              id="account_id" name="account_id"
              onBlur={enteredAccountIdBlurHandler}
              onFocus={enteredAccountIdFocusHandler}
            />
            <span className="fldIcon">Icon</span>
          </div>
          <div className={`fldWrap usernameFld fldWithIcon ${userNameInputClasses}`}>
            <input type="text" placeholder="Username"
              onChange={enteredUserNameChangedHandler}
              value={enteredUserName}
              id="user_name" name="user_name"
              onBlur={enteredUserNameBlurHandler}
              onFocus={enteredUserNameFocusHandler}
            />
            <span className="fldIcon">Icon</span>
          </div>
          {
            isIOS &&
            <div className={`fldWrap withShowPassword passwordFld fldWithIcon ${passwordInputClasses}`}>
              <input type="text" placeholder="Password"
                onChange={enteredPasswordChangedHandler}
                value={enteredPassword}
                id="password" name="password"
                onBlur={enteredPasswordBlurHandler}
                onFocus={enteredPasswordFocusHandler}
                className={`${passwordFieldType == 'password' ? 'password-mask' : ''}`}
              />
              <span className="fldIcon">Icon</span>
              {passwordFieldType === 'password' && <span onClick={changeFieldType} className="showPassword">Show</span>}
              {passwordFieldType === 'text' && <span onClick={changeFieldType} className="hidePassword">Show</span>}
            </div>
          }
          {
            !isIOS &&
            <div className={`fldWrap withShowPassword passwordFld fldWithIcon ${passwordInputClasses}`}>
              <input type={passwordFieldType} placeholder="Password"
                onChange={enteredPasswordChangedHandler}
                value={enteredPassword}
                id="password" name="password"
                onBlur={enteredPasswordBlurHandler}
                onFocus={enteredPasswordFocusHandler}
              />
              <span className="fldIcon">Icon</span>
              {passwordFieldType === 'password' && <span onClick={changeFieldType} className="showPassword">Show</span>}
              {passwordFieldType === 'text' && <span onClick={changeFieldType} className="hidePassword">Show</span>}
            </div>
          }


          <div className="customCheck">
            <input type="checkbox" id="customCheck" onChange={e => checkBoxChangeHandler(e)} checked={rememberMe} />
            <label className="customCheckLabel" htmlFor="customCheck">
              Remember me
            </label>
          </div>
          <button className="btnStyle" disabled={!formIsValid || isLoading}>
            LOGIN
          </button>

          <Link to="/reset-password">Forgot your password?</Link>
          <div className="orSeparator">
            <span>OR</span>
          </div>
          <button onClick={props.toggleButtonOnClick} className="btnStyle btn1 iconBtn emailID">
            Login with Email
          </button>
          <GoogleLoginComponent />
        </form>
      </div>
      {
        isLoginError && <ToastMessage cssClass="msgError" message={loginErrorMsg} onClose={() => { setIsLoginError(false); setLoginErrorMsg(''); }} />
      }

    </div>
  );
}

export default LoginAccountId;
