export function extractTripIDs(data) {
    if (Array.isArray(data)) {
        return data.map(item => item.trip).join(',');
    } else if (data && typeof data === 'object') {
        return data.trip;
    } else {
        return '';
    }
}

export const convertDateString = (dateString) => {
    const year = dateString.getFullYear();
    const month = (dateString.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 because months are zero-based
    const day = dateString.getDate().toString().padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate
}
