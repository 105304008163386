import React, { useState, useEffect, Fragment } from "react";
import { useDocumentTitle } from '../../../hooks/setDocumentTitle';
import Header from "../../layout/Header";
import { getAccessLevel } from '../../common/functions';
import BillingSettings from './BillingSettings';
import BillingInvoice from './BillingInvoice';

export default function CompanyBilling() { 

    useDocumentTitle("Billing - allGeo");
    const [baseModule, setBaseModule] = useState('invoice');
    const [accessLevel, setAccessLevel] = useState({});

    useEffect(() => {
        getAccessLevel("company", "settings").then((response) => {
          setAccessLevel(response);
        });

    }, []);

    

    const tabMenuHandler = (module) => {
        if (module === 'settings') {
            let url = `${process.env.REACT_APP_API_URL}/track/mgt?page=profile.info.v1&q=${Math.floor(Math.random() * 9999999)}`
            window.location.href = url;
        } else {
            setBaseModule(module);
        }
          
      }

    return (
        <Fragment>
            <div className="mainContentWrap">
                <Header title="Billing" />
                <div className="mainContent" id="mainContent">
                    <ul className="rightInnerNav">
                        <li><a onClick={() => tabMenuHandler('invoice')} className={baseModule === 'invoice' ? 'active' : ''}>Invoices</a></li>
                        <li><a onClick={() => tabMenuHandler('settings')} className={baseModule === 'settings' ? 'active' : ''}>Settings</a></li>
                    </ul>
                    {baseModule === 'invoice' && <BillingInvoice accessLevel={accessLevel} baseModule={baseModule} />}
                    { /*baseModule === 'settings' && <BillingSettings accessLevel={accessLevel} baseModule={baseModule} /> */}
                </div>
            </div>
        </Fragment>
    )

}