import React, { useState, useEffect } from 'react'
import { apiProvider } from '../../../services/api/provider'
import SiteLoader from '../../common/SiteLoader'
import { days } from './employeeUtils'
import { getAccountLabel } from '../../../utils'

const EMPTY_DATA_TEXT = '-'

const employeeLabel = getAccountLabel('employee') || 'Employee';

const General = ({ isActive, data, onClick, nomenclature }) => (
  <li className={`${isActive ? 'active' : ''}`}>
    <div className="accHeading" onClick={onClick}>General</div>
    <div className="accContent">
      <div className="sectionSubHeading">Account Information</div>
      <div className="rowBlk">
        <div className="colBlk4 fldWrap">
          <label>Phone Number</label>
          <p>{data.phoneNumber}</p>
        </div>
        <div className="colBlk4 fldWrap">
          <label>{nomenclature.device_title} Description</label>
          <p>{data.employeeDescription}</p>
        </div>
        <div className="colBlk4 fldWrap">
          <label>Status</label>
          <p className={`${data.active === "Yes" ? 'txtActive' : 'txtSuspend'}`}>{data.active === "Yes" ? "Active" : "Suspended"}</p>
        </div>
      </div>
      <div className="rowBlk">
        <div className="colBlk4 fldWrap">
          <label>Notifications</label>
          <p>{data.notifications === "Yes" ? "Enabled" : "Disabled"}</p>
        </div>
        <div className="colBlk4 fldWrap">
          <label>Email notified</label>
          <p>{data.emailNotified}</p>
        </div>
        <div className="colBlk4 fldWrap">
          <label>Timezone</label>
          <p>{data.timeZone}</p>
        </div>
      </div>
      <div className="rowBlk">
        <div className="colBlk4 fldWrap">
          <label>SMS Email Addres</label>
          <p>{data.smsEmail}</p>
        </div>
        {/* <div className="colBlk4 fldWrap">
          <label>Short Name</label>
          <p>{data.employeeShortName}</p>
        </div> */}
        <div className="colBlk4 fldWrap">
          <label>{employeeLabel} First Name</label>
          <p>{data.employeeFirstName}</p>
        </div>
        <div className="colBlk4 fldWrap">
          <label>{employeeLabel} Last Name</label>
          <p>{data.employeeLastName}</p>
        </div>
        <div className="colBlk4 fldWrap">
          <label>Vehicle ID</label>
          <p>{data.vehicleId}</p>
        </div>
      </div>
      <div className="rowBlk">
        <div className="colBlk4 fldWrap">
          <label>Office Site</label>
          <p>
            {
              data.officeSiteOptions.filter(o => o.siteId === data.officeSite).length > 0
                ?
                data.officeSiteOptions.filter(o => o.siteId === data.officeSite)[0].siteName
                :
                EMPTY_DATA_TEXT
            }
          </p>
        </div>
        <div className="colBlk4 fldWrap">
          <label>Home Site</label>
          <p>
            {
              data.homeSiteOptions.filter(h => h.siteId === data.homeSite).length > 0
                ?
                data.homeSiteOptions.filter(h => h.siteId === data.homeSite)[0].siteName
                :
                EMPTY_DATA_TEXT
            }
          </p>
        </div>
        <div className="colBlk4 fldWrap">
          <label>Distance</label>
          <p>{data.distance} miles</p>
        </div>
      </div>
      <hr />
      <div className="sectionSubHeading">Healthcare Information</div>
      <div className="rowBlk">
        <div className="colBlk4 fldWrap">
          <label>Max Caseload</label>
          <p>{data.maxCaseload}</p>
        </div>
        <div className="colBlk4 fldWrap">
          <label>Max Community Home Members</label>
          <p>{data.maxCommunityHomeMembers}</p>
        </div>
        <div className="colBlk4 fldWrap">
          <label>Max Nursing Home Members</label>
          <p>{data.maxNursingHomeMembers}</p>
        </div>
      </div>
    </div>
  </li>
)

const Payroll = ({ isActive, data, onClick, nomenclature }) => (
  <li className={`${isActive ? 'active' : ''}`}>
    <div className="accHeading" onClick={onClick}>Payroll </div>
    <div className="accContent">
      <div className="sectionSubHeading">Payroll Details</div>
      <div className="rowBlk">
        <div className="colBlk3 fldWrap">
          <label>Company Code</label>
          <p>{data.companyCode}</p>
        </div>
        <div className="colBlk3 fldWrap">
          <label>Batch ID</label>
          <p>{data.batchId}</p>
        </div>
        <div className="colBlk3 fldWrap">
          <label>{nomenclature.device_title} Wage Type</label>
          <p>{data.employeeWageTypes}</p>
        </div>
        <div className="colBlk3 fldWrap">
          <label>Pay className</label>
          <p>{data.payClass}</p>
        </div>
      </div>
      <div className='rowBlk'>
        <div className="colBlk3 fldWrap">
          <label>Job Start Date</label>
          <p>{data.jobStartDate}</p>
        </div>
        <div className="colBlk3 fldWrap">
          <label>Termination Date</label>
          <p>{data.terminationDate}</p>
        </div>
      </div>
      <hr />
      <div className="rowBlk">
        <div className="colBlk6">
          <div className="sectionSubHeading">Regular Hours</div>
          <div className="rowBlk">
            <div className="colBlk6 fldWrap">
              <label>Regular Time</label>
              <p>{data.dailyRegularHrs} hrs {data.dailyRegularMinutes} mins</p>
            </div>
            <div className="colBlk6 fldWrap">
              <label>Weekly Regular Time</label>
              <p>{data.weeklyRegularHrs} hrs</p>
            </div>
          </div>
          <div className="rowBlk">
            <div className="colBlk6 fldWrap">
              <label>Weekly Pay Period</label>
              <p>{(data.weeklyPayPeriodFrom && data.weeklyPayPeriodFrom !== EMPTY_DATA_TEXT)
                ?
                `${days[+data.weeklyPayPeriodFrom + 1].label} To ${days[+data.weeklyPayPeriodTo + 1].label}`
                :
                EMPTY_DATA_TEXT
              }</p>
            </div>
            <div className="colBlk6 fldWrap">
              <label>Weekly Regular</label>
              <p>{data.weeklyRegularHrs} hrs</p>
            </div>
          </div>
          <div className="rowBlk">
            <div className="colBlk6 fldWrap">
              <label>Regular Earning code</label>
              <p>{data.reguerEarningCode}</p>
            </div>
            <div className="colBlk6 fldWrap">
              <label>Mileage Earning Code</label>
              <p>{data.mileageEarningcode}</p>
            </div>
          </div>
        </div>
        <div className="colBlk6">
          <div className="sectionSubHeading">Overtime Hours</div>
          <div className="rowBlk">
            <div className="colBlk6 fldWrap">
              <label>Over Time</label>
              <p>{data.dailyOverTimeHrs} hrs {data.dailyOverTimeMinutes} mins</p>
            </div>
            <div className="colBlk6 fldWrap">
              <label>Weekly Over Time</label>
              <p>{data.weeklyOverTimeHrs} hrs</p>
            </div>
          </div>
          <div className="rowBlk">
            <div className="colBlk6 fldWrap">
              <label>OT Earning code:</label>
              <p>{data.otEarningCode}</p>
            </div>
            <div className="colBlk6 fldWrap">
              <label>Double OT Earning code:</label>
              <p>{data.doubleOTEarningCode}</p>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="sectionSubHeading">Other Information</div>
      <div className="rowBlk">
        <div className="colBlk3 fldWrap">
          <label>Position ID</label>
          <p>{data.positionId}</p>
        </div>
        <div className="colBlk3 fldWrap">
          <label>Associate ID</label>
          <p>{data.associateID}</p>
        </div>
        <div className="colBlk3 fldWrap">
          <label>SSN</label>
          <p>{data.ssnNumber}</p>
        </div>
        <div className="colBlk3 fldWrap">
          <label>Earning Code</label>
          <p>{data.earningCode}</p>
        </div>
      </div>
      <div className="rowBlk">
        <div className="colBlk3 fldWrap">
          <label>Hire Date</label>
          <p>{data.hireDate}</p>
        </div>
        <div className="colBlk3 fldWrap">
          <label>Skill</label>
          <p>{data.skills}</p>
        </div>
        <div className="colBlk3 fldWrap">
          <label>Payroll File #</label>
          <p>{data.payrollFileNumber}</p>
        </div>
        <div className="colBlk3 fldWrap">
          <label>Job Title</label>
          <p>{data.jobTitle}</p>
        </div>
      </div>
      <div className="rowBlk">
        <div className="colBlk3 fldWrap">
          <label>Manager Name</label>
          <p>{data.managerName}</p>
        </div>
        <div className="colBlk3 fldWrap">
          <label>Manager ID</label>
          <p>{data.managerId}</p>
        </div>
      </div>
      <table cellPadding="0" cellSpacing="0" className="tblStyle tblSml">
        <tbody>
          <tr>
            <th></th>
            <th>Description</th>
            <th>Code</th>
          </tr>
          <tr>
            <td>Benefits Eligibility Class</td>
            <td><strong>{data.benefitsEligibilityClassCodeDesc}</strong></td>
            <td><strong>{data.benefitsEligibilityClassCode}</strong></td>
          </tr>
          <tr>
            <td>Business Unit</td>
            <td><strong>{data.businessUnitCodeDesc}</strong></td>
            <td><strong>{data.businessUnitCode}</strong></td>
          </tr>
          <tr>
            <td>Home Cost Number</td>
            <td><strong>{data.homecostNumberCodeDesc}</strong></td>
            <td><strong>{data.homeCostNumberCode}</strong></td>
          </tr>
          <tr>
            <td>Department</td>
            <td><strong>{data.departmentCodeDesc}</strong></td>
            <td><strong>{data.departmentCode}</strong></td>
          </tr>
          <tr>
            <td>Location</td>
            <td><strong>{data.locationCodeDesc}</strong></td>
            <td><strong>{data.locationCode}</strong></td>
          </tr>
          <tr>
            <td>Status Flag</td>
            <td><strong>{data.statusFlagCodeDesc}</strong></td>
            <td><strong>{data.statusFlagCode}</strong></td>
          </tr>
        </tbody>
      </table>
      <div className="rowBlk">
        <div className="colBlk3 fldWrap">
          <label>PayData/Payroll(ADP)</label>
          <p>{data.payDataPayrollADPEnabled}</p>
        </div>
        <div className="colBlk3 fldWrap">
          <label>Time And Attendance(ADP)</label>
          <p>{data.timeAndAttendanceADPEnabled}</p>
        </div>
      </div>
      <div className="rowBlk">
        <div className="colBlk3 fldWrap">
          <label>Contract Status</label>
          <p>{data.contractStatus}</p>
        </div>
        <div className="colBlk3 fldWrap">
          <label>Hourly Rate</label>
          <p>${data.hourlyrate}</p>
        </div>
        <div className="colBlk3 fldWrap">
          <label>Work Assignment ID</label>
          <p>{data.workAssignmentID}</p>
        </div>
        <div className="colBlk3 fldWrap">
          <label>Payroll Group Code</label>
          <p>{data.payrollGroupCode}</p>
        </div>
      </div>
    </div>
  </li>
)

const Device = ({ isActive, data, onClick, copyToClipBoard, nomenclature, getPushPinImageByName, showToolTip }) => {
  const groupLabel = getAccountLabel('group');
  return (
    <li className={`${isActive ? 'active' : ''}`}>
      <div className="accHeading" onClick={onClick}>Device</div>
      <div className="accContent">
        <div className="deviceInfoCont">
          <div className="deviceInfoLeft">
            <div className="sectionSubHeading">{nomenclature.device_title} Information</div>
            <div className="rowBlk">
              <div className="colBlk6 fldWrap">
                <label>Phone {groupLabel} Pushpin ID</label>
                <div className="selectedPushpin">
                  <img src={getPushPinImageByName(data.pushpinId)} alt="" />
                </div>
              </div>
              <div className="colBlk6 fldWrap">
                <label>{nomenclature.device_title} Color</label>
                <div className="chooseColor">
                  <div className="selectedColor" style={{ backgroundColor: data.deviceColor ? data.deviceColor : 'white' }}>Color
                  </div>
                </div>
              </div>
            </div>
            <div className="rowBlk">
              <div className="colBlk4 fldWrap">
                <label>SIM {nomenclature.device_title} Prefix</label>
                <p>{data.simPhonePrefix}</p>
              </div>
              <div className="colBlk4 fldWrap">
                <label>{nomenclature.device_title} Type</label>
                <p>{data.phoneType}</p>
              </div>
              <div className="colBlk4 fldWrap">
                <label>Landline?</label>
                <p>{data.landLine}</p>
              </div>
            </div>
            <div className="rowBlk">
              <div className="colBlk4 fldWrap">
                <label>Landline Address</label>
                <p>{data.landlineAddress}</p>
              </div>
              <div className="colBlk4 fldWrap">
                <label>Default Language</label>
                <p>{data.defaultAppLanguage}</p>
              </div>
            </div>
            <hr />
            <div className="sectionSubHeading">Application Details</div>
            <div className="rowBlk">
              <div className="colBlk3 fldWrap">
                <label>App Version</label>
                <p>{data.appVersion}</p>
              </div>
              <div className="colBlk3 fldWrap">
                <label>App ID</label>
                <p>{data.appID}</p>
              </div>
              <div className="colBlk3 fldWrap">
                <label>SDK Version</label>
                <p>{data.sdkVersion}</p>
              </div>
              <div className="colBlk3 fldWrap">
                <label>Software Version</label>
                <p>{data.softwareVersion}</p>
              </div>
            </div>
            <hr />
            <div className="sectionSubHeading">{employeeLabel} Privacy</div>
            <div className="rowBlk">
              <div className="colBlk6 fldWrap">
                <label>Location Opt-in</label>
                <p>{data.LocationOptIn}</p>
                <div><em>Last updated: {data.LocationOptInLastupdatedTime}</em></div>
              </div>
              <div className="colBlk6 fldWrap">
                <label>SMS Opt-in</label>
                <p>{data.smsOptIn}</p>
                <div><em>Last updated: {data.smsOptInLastupdatedTime}</em></div>
              </div>
            </div>
          </div>
          <div className="deviceInfoRight">
            <div className="infoHeading">{nomenclature.device_title} Details</div>
            <div className="highlightedFldWrap">
              <div className="fldWrap">
                <label>Sim {nomenclature.device_title} Number</label>
                <div className="highlightedValue">
                  <span>{data.simPhoneNumber}</span>
                  <span className="copyIcon" onClick={() => copyToClipBoard(data.simPhoneNumber)} >Copy</span>
                  <span className="copyClipboard" style={{ display: showToolTip ? 'block' : 'none' }}>Copied to the clipboard!</span>
                </div>
              </div>
              <div className="fldWrap">
                <label>Carrier</label>
                <div className="highlightedValue">{data.carrier}</div>
              </div>
              <div className="fldWrap">
                <label>Battery Level</label>
                <div className={`batteryIndicator ${data.batteryLevel > 20 ? '' : 'lowBattery'}`}><span><em
                  style={{ width: `${data.batteryLevel}%` }}>Battery</em></span>{data.batteryLevel}%</div>
              </div>
              <div className="fldWrap">
                <label>Charging</label>
                <div className="highlightedValue">{data.charging}</div>
              </div>
              <div className="fldWrap">
                <label>Model</label>
                <div className="highlightedValue">{data.model}</div>
              </div>
              <div className="fldWrap">
                <label>Manufacturer</label>
                <div className="highlightedValue">{data.manufacturer}</div>
              </div>
              <div className="fldWrap">
                <label>Heart Beat</label>
                <div className="highlightedValue">{data.heartbeat}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  )
}

const Organization = ({ isActive, data, onClick, nomenclature }) => (
  <li className={`${isActive ? 'active' : ''}`}>
    <div className="accHeading" onClick={onClick}>Organization</div>
    <div className="accContent">
      <div className="sectionSubHeading">Phone {nomenclature.device_group_title} Membership</div>
      <p>Member is included in the following groups</p>
      <ul className="blockCheckbox withoutCheck">
        {data.groups.map((g, ind) => (
          <li key={ind}>
            <div className="customCheck">
              <label className="customCheckLabel" htmlFor="name1"><span><em className="groupColor"
                style={{ backgroundColor: g.colour !== '#FFFFFF' ? g.colour : '' }}>G</em>{g.groupDescription}</span></label>
            </div>
          </li>
        ))
        }
      </ul>
    </div>
  </li>
)

const ViewEmployee = ({ empData, setMode, setToastMsg, deleteAnEmployee, accessLevel, nomenclature }) => {

  const [showLoader, setShowLoader] = useState(false);
  const [activeSections, setActiveSections] = useState({
    general: true,
    payroll: false,
    device: false,
    organization: false
  })
  const [employeeData, setEmployeeData] = useState(null)
  const [deletePopup, setDeletePopup] = useState(false)
  const [showToolTip, setShowToolTip] = useState(false)
  const [pushPin, setPushPin] = useState([]);

  const copyToClipBoard = (value) => {
    navigator.clipboard.writeText(value);

    setShowToolTip(true)
    if (!showToolTip) setTimeout(() => setShowToolTip(false), 4000)
  }

  const fetchEmployeeData = async () => {
    try {
      setShowLoader(true)
      const response = await Promise.all([apiProvider.getAll(`/track/mobile/v1/allgeo/employee/${empData.phoneNumber}`), apiProvider.getAll('track/react/account/getPushpins')])

      if (response[0].status === 200) {
        const data = response[0].info
        data.general = replaceEmptyString(data.general)
        data.payrole = replaceEmptyString(data.payrole)
        data.device = replaceEmptyString(data.device)

        setEmployeeData(data)
      }
      if (response[1].status === 200) {
        setPushPin(response[1].data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setShowLoader(false)
    }
  }

  const replaceEmptyString = (obj = {}) => {

    try {
      const modifiedObj = {}

      Object.keys(obj).map(key => {
        modifiedObj[key] = typeof obj[key] === 'string' ? (obj[key] || EMPTY_DATA_TEXT) : obj[key]
      })

      return modifiedObj;
    } catch (error) {
      console.log(error);
      return {}
    }

  }

  useEffect(() => {
    fetchEmployeeData()
  }, [])

  const getPushPinImageByName = (pushPinName) => {

    let pin = pushPin.find(obj => {
      return obj.name == pushPinName
    });

    if (pin) {
      return process.env.REACT_APP_API_URL + '/' + pin.image;
    } else {
      return '';
    }
  }

  const DeletePopup = () => (
    <div className="popupWrap msgPopup deleteMsg active">
      <div className="msgPopupCont">
        <div className="msgIcon msgDelete">Delete</div>
        <div className="msgContent">
          <div>Are you sure you want to delete the selected record?</div>
        </div>
      </div>
      <div className="popupMsgBtn">
        <a className="okBtn cursorPointer" onClick={() => setDeletePopup(false)}>Cancel</a>
        <button className="btnStyle okBtn" onClick={async () => {
          const res = await deleteAnEmployee(empData)
          if (res.status === 200) {
            setDeletePopup(false)
            setMode({
              title: "Manage Employees",
              type: 'main',
              data: null,
              hasPrev: false
            })
            setToastMsg({
              msg: res.message,
              type: "msgSuccess"
            })
          }
        }}>Yes</button>
      </div>
    </div>
  )

  return (
    <>
      <div className="mainContent" id="mainContent">
        <div className="viewTopSection">
          <div className="headingCtrl">
            <a className={`editGrp${accessLevel < 2 ? ' disabled' : ''}`}
              onClick={() => setMode({
                title: "Edit Employee Information",
                type: 'edit',
                data: empData,
                hasPrev: true
              })}>Edit {employeeLabel}</a>
            <a
              className={`deleteGrp${accessLevel < 3 ? ' disabled' : ''}`}
              onClick={() => setDeletePopup(true)}>Delete {employeeLabel}</a>
          </div>
          <div className="viewInfo"><span className="infoAvatar">{empData.employeeName.slice(0, 1)}</span>{empData.employeeName}</div>
          <div className="highlightedFldWrap">
            <div className="fldWrap">
              <label>{employeeLabel} Number</label>
              <div className="highlightedValue">
                <span>{employeeData?.general.employeeId}</span>
              </div>
            </div>
            <div className="fldWrap">
              <label>Creation Date & Time</label>
              <div className="highlightedValue">
                <span>{employeeData?.general.creationDateTime}</span>
              </div>
            </div>
            <div className="fldWrap">
              <label>Last Alert Time</label>
              <div className="highlightedValue">
                <span>{employeeData?.general.lastNotifyTime}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="innerWrap">
          {employeeData && <ul className="accordionList">
            <General
              isActive={activeSections.general}
              onClick={() => setActiveSections({
                ...activeSections,
                general: !activeSections.general
              })}
              data={employeeData.general}
              nomenclature={nomenclature}
            />
            <Payroll
              isActive={activeSections.payroll}
              onClick={() => setActiveSections({
                ...activeSections,
                payroll: !activeSections.payroll
              })}
              data={employeeData.payrole}
              nomenclature={nomenclature}
            />
            <Device
              isActive={activeSections.device}
              onClick={() => setActiveSections({
                ...activeSections,
                device: !activeSections.device
              })}
              data={employeeData.device}
              copyToClipBoard={copyToClipBoard}
              nomenclature={nomenclature}
              getPushPinImageByName={getPushPinImageByName}
              showToolTip={showToolTip}
            />
            <Organization
              isActive={activeSections.organization}
              onClick={() => setActiveSections({
                ...activeSections,
                organization: !activeSections.organization
              })}
              data={employeeData.phoneGroupMembership}
              nomenclature={nomenclature}
            />
          </ul>}
        </div>
        <div className="mainBtnWrap">
          <button className="btnStyle" onClick={() => setMode({
            title: "Manage Employees",
            type: 'main',
            data: null,
            hasPrev: false
          })}>Back</button>
        </div>
      </div>
      {deletePopup && <DeletePopup />}
      <div className={`overlay${deletePopup ? ' active' : ''}`} />
      <SiteLoader isActive={showLoader} />
    </>
  )
}

export default ViewEmployee
