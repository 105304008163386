import React, { useEffect, useState } from 'react'
import { DropDownList } from '@progress/kendo-react-dropdowns'
import { filterBy } from '@progress/kendo-data-query';

const DropdownList = ({ list = [], textField = 'label', valueField = 'value', filterable = false, onChange, defaultValue = '' }) => {
    const [data, setData] = useState([]);
    const [value, setValue] = useState(defaultValue)

    const filterData = (filter) => {
        const data = list.map(d => ({
            id: d[valueField],
            [textField]: d[textField]
        }))
        
        return filterBy(data, filter);
    };

    const filterChange = (event) => {
        setData(filterData(event.filter));
    };

    useEffect(() => { 
        if (list.length > 0) {
            setData(list.map(d => ({
                id: d[valueField],
                [textField]: d[textField]
            })))
        }
        setValue(defaultValue);
    }, [list, defaultValue])

    const handleChange = e => {
        setValue(e.value.id)
        onChange(e)
    }

    return (
        <DropDownList
            data={data}
            value={data.find(d => d.id && d.id === value) || null}
            textField={textField}
            filterable={filterable}
            onFilterChange={filterChange}
            onChange={handleChange}
        />
    )
}

export default DropdownList