import React, { Fragment, useEffect, useState } from 'react'
import { apiProvider } from '../../../services/api/provider';

const CopyRulesDetails = ({ data, setCopyRulesPopup, setToastMsg, setShowLoader }) => {

    const [isFormComplete, setIsFormComplete] = useState(false)
    const [newRuleId, setNewRuleId] = useState('')

    const closePopup = () => {
        setCopyRulesPopup({
            show: false,
            data: null
        })
    }

    useEffect(() => {
        if (newRuleId.length > 0) {
            setIsFormComplete(true);
        } else {
            setIsFormComplete(false);
        }
    }, [newRuleId]);

    const copyRule = async () => {

        const payload = {
            "newRuleID": newRuleId,
            "ruleID": data.ruleID,
        }

        try {
            setShowLoader(true);
            const response = await apiProvider.post('/track/mobile/v1/allgeo/rule/copy', payload);
            if (response.status === 200) {
                closePopup()
                setToastMsg({ msg: `Rule template copied successfully.`, type: "msgSuccess" });
            } else {
                setToastMsg({
                    type: 'msgError',
                    msg: response.message
                })
            }
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    return (
        <Fragment>
            <div className="popupWrap smlPopup withAutoComplete active">
                <div className="closePopup" onClick={closePopup} >Close</div>
                <div className="popupHeading">Copy Rule Template</div>
                <div className="popupContent">
                    <p>Please enter a name for the rule</p>
                    <div className="fldWrap">
                        <label>Rule Name</label>
                        <input
                            maxLength={32}
                            minLength={1}
                            type="text"
                            value={newRuleId}
                            autoFocus
                            onChange={(e) => setNewRuleId(e.target.value.replace(/[^0-9a-zA-Z:_-]/g, ''))}
                        />
                    </div>
                </div>
                <div className="popupBtnWrap flexJCenter">
                    <button className="btnStyle okBtn" onClick={copyRule} disabled={!isFormComplete}>Add</button>
                </div>
            </div>
            <div className="overlay active"></div>
        </Fragment>
    )
}
export default CopyRulesDetails