import userLogo from "../../assets/images/allGeo-logo.svg";
const TrialAccountExpired = () => {
    return (
        <div className="accountWrap">
            <h1 className="siteLogo">
                <a href="/login">
                    <img src={userLogo} alt="allGeo" />
                </a>
            </h1>
            <div className="accountInnerBlk">
                <div className="accountInnerContent">
                    <div className="innerMsgIcon">Info</div>
                    <div className="innerMsg">
                        <h2>Your trial has expired!</h2>
                        <p>Rest assured, your data remains untouched.</p>
                        <p className="subHeading">To keep enjoying allGeo:</p>
                    </div>
                </div>
                <div className="btnWrap">
                    <a href="../pricing" className="btnStyle">Upgrade Now!</a>
                    <span className="orClass">OR</span>
                    <a href="mailto:support@abaq.us" target="_blank">Talk to Sales</a>
                </div>
            </div>
        </div>
    )
}
export default TrialAccountExpired;