import React, { Fragment, useEffect, useState } from "react";
import { apiProvider } from '../../../services/api/provider';
import { useForm } from "react-hook-form";
import SiteLoader from '../../common/SiteLoader';
import $ from "jquery";
import AutoCompleteDropdown from "../../common/dropdown/AutoCompleteDropdown";
import { Prompt } from "react-router-dom";
import { AutoComplete } from '@progress/kendo-react-dropdowns'
import { getAccountLabel } from "../../../utils";


export default function EmployeeDevice(props) {

    const { register, watch, handleSubmit, getValues, setValue, reset, formState: { isDirty, isValid } } = useForm({ mode: "all" });
    const [employeeDeviceValue, setEmployeeDeviceValue] = useState({});
    const [showLoader, setShowLoader] = useState(false);
    const [pushPin, setPushPin] = useState([]);
    const [pushPinImage, setPushpinImage] = useState('');
    const [deviceColorInput, setDeviceColorInput] = useState('#fff');
    const [languageOptions, setLanguageOptions] = useState([])
    const phoneTypeOptions = ['Machine', 'User', 'Company/App'];
    const [nonEmptySiteNames, setNonEmptySiteNames] = useState();
    const resetLandlineAddress = watch("resetLandlineAddress");
    // var nonEmptySiteNames
    useEffect(() => {
        if (Object.keys(props.data).length > 0) {
            const nonEmptySiteNames = props.data.landlineAddressOptions
                .filter(site => site.siteName !== "")
                .map(site => (site.siteName));

            setNonEmptySiteNames(nonEmptySiteNames)
            if (props.data.landlineAddress) {
                // setSelectedLandLineAddress(props.data.landlineAddress)
            }

            setEmployeeDeviceValue(props.data);
            setLanguageOptions(props.data.allowedAppLanguages?.split(","))
            apiProvider.getAll('track/react/account/getPushpins').then((response) => {
                if (response.status == 200 && response.message.toLowerCase() == 'success') {
                    setPushPin(response.data);
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    }, [props.data, employeeDeviceValue]);

    useEffect(() => {
        props.formState(isDirty);
        if (isDirty) {
            localStorage.setItem('formIsDirty', isDirty)
        }
    }, [isDirty]);

    useEffect(() => {

        if (Object.keys(employeeDeviceValue).length > 0) {
            reset({
                ...employeeDeviceValue,
                landLine: employeeDeviceValue.landLine === "Yes",
                smsOptIn: employeeDeviceValue.smsOptIn === "Yes",
                resetLandlineAddress: employeeDeviceValue.resetLandlineAddress === "Yes"
            })

            setDeviceColorInput(employeeDeviceValue.deviceColor);
        }
    }, [employeeDeviceValue]);
    
    useEffect(() => {
        if (resetLandlineAddress) {
          setValue('landlineAddress', '');
        }
      }, [resetLandlineAddress, setValue]);
    

    useEffect(() => {
        setPushpinImage(getPushPinImageByName(employeeDeviceValue.pushpinId));
    }, [Object.keys(employeeDeviceValue).length > 0, pushPin.length > 0]);

    const onSubmit = (data) => {

        if ((data.landLine == false || !data.landLine)) {
            data.landLine = 'No'
            // setSelectedLandLineAddress("");
        } else {
            data.landLine = 'Yes'
            // data.landlineAddress = selectedLandlineAddress
        }

        // data.landLine = (data.landLine == false || !data.landLine) ? 'No' : 'Yes';
        data.smsOptIn = (data.smsOptIn == false || !data.smsOptIn) ? 'No' : 'Yes';
        data.resetLandlineAddress = (data.resetLandlineAddress == false || !data.resetLandlineAddress) ? 'No' : 'Yes';
        setShowLoader(true);

        apiProvider.put('/track/mobile/v1/allgeo/updateEmpDeviceTab/' + props.employeeId, data).then((response) => {
            if (response.status && response.status == 200) {
                props.setToastMsg({ msg: response.message, type: 'msgSuccess' })
                props.refreshAllTabdata()
            } else {
                props.setToastMsg({ msg: response.message, type: 'msgError' })
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setShowLoader(false);
            props.formState(false);
            localStorage.removeItem('formIsDirty')
        });
    }

    const copyToClipBoard = (fieldName) => {
        let fieldValue = getValues(fieldName);
        navigator.clipboard.writeText(fieldValue);
    }

    const changePushPinName = (pushPinName) => {
        setValue('pushpinId', pushPinName, { shouldDirty: true });
        $('.pushpinControl').removeClass("active");
        setPushpinImage(getPushPinImageByName(pushPinName));
    }

    const getPushPinImageByName = (pushPinName) => {

        let pin = pushPin.find(obj => {
            return obj.name == pushPinName
        });

        if (pin) {
            return process.env.REACT_APP_API_URL + '/' + pin.image;
        } else {
            return '';
        }
    }

    $(".copyIcon").on("click", function () {
        $(this).siblings('.copyClipboard').show().delay(4000).fadeOut();
    });

    $(".changePushpin").on("click", function () {
        $('.pushpinControl').removeClass("active");
        $(this).parent('.pushpinControl').addClass("active");
    });

    $(document).on("click", function (e) {
        let container = $('.pushpinControl');
        if (!container.is(e.target) && container.has(e.target).length === 0) {
            $('.pushpinControl').removeClass("active");
        }
    });

    const employeeLabel = getAccountLabel('employee');
    const groupLabel = getAccountLabel('group');
    return (

        <Fragment>
            <Prompt
                when={isDirty}
                message='The changes are still unsaved. Are you sure you want to leave?'
            />
            <form onSubmit={handleSubmit(onSubmit)} id="settingsForm">
                <section>
                    <h3>{props.nomenclature.device_title} Information</h3>
                    <div className="supportedTxt">
                        You can check and update the employee’s device specific information here.
                    </div>
                    <div className="innerWrap">
                        <div className="deviceInfoCont">
                            <div className="deviceInfoLeft">
                                <div className="rowBlk">
                                    <div className="colBlk6 fldWrap">
                                        <label>Phone {groupLabel} Pushpin ID</label>
                                        <div className="selectedPushpin">
                                            <img src={pushPinImage} alt="" />
                                        </div>
                                        <div className="pushpinControl">
                                            <input type="hidden" {...register("pushpinId")} />
                                            <a className="changePushpin">
                                                Change
                                            </a>

                                            <div className="pushpinWrap">
                                                <ul>
                                                    {
                                                        pushPin.map((pin, index) => {
                                                            return <li key={`pushpin${index}`} onClick={() => { changePushPinName(pin.name) }}>
                                                                <img key={`pushpin-image-${index}`} src={process.env.REACT_APP_API_URL + '/' + pin.image} alt="" />
                                                                <span>{pin.name}</span>
                                                            </li>
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="colBlk6 fldWrap">
                                        <label>{props.nomenclature.device_title} Color</label>
                                        <div className="chooseColor">
                                            <div className="selectedColor" style={{ backgroundColor: deviceColorInput }}>Color</div>
                                            <input
                                                type="color"
                                                className="colorInput"
                                                id="editColor"
                                                value={deviceColorInput}
                                                onChange={(e) => {
                                                    setDeviceColorInput(e.target.value);
                                                    setValue('deviceColor', deviceColorInput, { shouldDirty: true })
                                                }}
                                            />
                                            <label htmlFor="editColor" className="editColor">Edit Color</label>
                                        </div>
                                        <input type="hidden" {...register('deviceColor')} />
                                    </div>
                                </div>
                                <div className="rowBlk">
                                    <div className="colBlk6 fldWrap">
                                        <label>SIM {props.nomenclature.device_title} Prefix</label>
                                        <input type="text" {...register('simPhonePrefix')} maxLength={5} />
                                    </div>
                                    <div className="colBlk6 fldWrap">
                                        <label>{props.nomenclature.device_title} Type</label>
                                        <select {...register('phoneType')}>
                                            {
                                                phoneTypeOptions.map((types) => {
                                                    return <option key={types} value={types}>{types}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="rowBlk">
                                    <div className="colBlk3 fldWrap">
                                        <label>Landline?</label>
                                        <div className="customCheck yesNo">
                                            <input type="checkbox" id="landline" {...register('landLine')} />
                                            <label className="customCheckLabel" htmlFor="landline">
                                                yesNo
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {watch("landLine") &&
                                    <div className="rowBlk">
                                        <div className="colBlk6 fldWrap">
                                        <label>Landline Address</label>
                                        <select 
                                                {...register('landlineAddress')}
                                                // onChange={(e) => {
                                                //     const inputValue = e.target.value;
                                                //     setSelectedLandLineAddress(inputValue);  
                                                // }}
                                            >
                                                {nonEmptySiteNames.map((address) => (
                                                    <option key={address} value={address}>{address}</option>
                                                ))}
                                            </select>

                                            {/* <AutoComplete
                                                data={nonEmptySiteNames}
                                                placeholder="Select jobsite"
                                                defaultValue={selectedLandlineAddress}
                                                onChange={(e) => {
                                                    const inputValue = e.target.value;
                                                    setSelectedLandLineAddress(inputValue);
                                                        setValue('selectedLandlineAddress', selectedLandlineAddress, { shouldDirty: true })
                                                }}
                                            /> */}
                                        </div>
                                        <div className="colBlk6 fldWrap">
                                            <label>Reset Landline Address</label>
                                            <div className="customCheck yesNo">
                                                <input type="checkbox" id="resetLandline" {...register("resetLandlineAddress")} />
                                                <label className="customCheckLabel" htmlFor="resetLandline">yesNo</label>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="rowBlk">
                                    <div className="colBlk6 fldWrap">
                                        <label>Select a Default Language</label>
                                        <select {...register('defaultAppLanguage')}>
                                            {languageOptions.map((e) =>
                                                (<option value={e}>{e}</option>)
                                            )}
                                        </select>
                                    </div>
                                </div>

                            </div>
                            <div className="deviceInfoRight">
                                <div className="infoHeading">{props.nomenclature.device_title} Details</div>
                                <div className="highlightedFldWrap">
                                    <div className="fldWrap">
                                        <label>Sim {props.nomenclature.device_title} Number</label>
                                        <div className="highlightedValue">
                                            <span>{employeeDeviceValue.simPhoneNumber}</span>
                                            <input type="hidden" {...register('simPhoneNumber')} />
                                            <span onClick={() => { copyToClipBoard('simPhoneNumber') }} className="copyIcon">Copy</span>
                                            <span className="copyClipboard">Copied to the clipboard!</span>
                                        </div>
                                    </div>
                                    <div className="fldWrap">
                                        <label>Carrier</label>
                                        <div className="highlightedValue">{employeeDeviceValue.carrier}</div>
                                    </div>
                                    <div className="fldWrap">
                                        <label>Battery Level</label>
                                        <div className={`batteryIndicator ${employeeDeviceValue.batteryLevel <= 20 ? 'lowBattery' : ''}`}>
                                            <span>
                                                <em style={{ width: employeeDeviceValue.batteryLevel + '%' }}>Battery</em>
                                            </span>
                                            {employeeDeviceValue.batteryLevel}%
                                        </div>
                                        {/* If the value is > 20% remove the class lowBattery */}
                                    </div>
                                    <div className="fldWrap">
                                        <label>Charging</label>
                                        <div className="highlightedValue">{employeeDeviceValue.charging}</div>
                                    </div>
                                    <div className="fldWrap">
                                        <label>Model</label>
                                        <div className="highlightedValue">{employeeDeviceValue.model}</div>
                                    </div>
                                    <div className="fldWrap">
                                        <label>Manufacturer</label>
                                        <div className="highlightedValue">{employeeDeviceValue.manufacturer}</div>
                                    </div>
                                    <div className="fldWrap">
                                        <label>Heart Beat</label>
                                        <div className="highlightedValue">{employeeDeviceValue.heartbeat}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <h3>Application Details</h3>
                    <div className="supportedTxt">
                        Customize your account to use terminology relevant to your industry or
                        business
                    </div>
                    <ul className="appDetailBlk">
                        <li>
                            <label>App Version</label>
                            <div>{employeeDeviceValue.appVersion}</div>
                        </li>
                        <li>
                            <label>App ID</label>
                            <div>{employeeDeviceValue.appID}</div>
                        </li>
                        <li>
                            <label>SDK Version</label>
                            <div>{employeeDeviceValue.sdkVersion}</div>
                        </li>
                        <li>
                            <label>Software Version</label>
                            <div>{employeeDeviceValue.softwareVersion}</div>
                        </li>
                    </ul>
                </section>
                <section>
                    <h3>{employeeLabel} Privacy</h3>
                    <div className="supportedTxt">
                        Customize your account to use terminology relevant to your industry or
                        business
                    </div>
                    <div className="innerWrap">
                        <div className="rowBlk">
                            <div className="colBlk6">
                                <div className="innerBlk">
                                    <label>
                                        Location Opt-in - <strong>{employeeDeviceValue.LocationOptIn}</strong>
                                    </label>
                                    <p>
                                        <em>Last updated: {employeeDeviceValue.LocationOptInLastupdatedTime}</em>
                                    </p>
                                    <p>By signing up with allGeo, you have agreed to share your location. Please read the <a
                                        href="https://www.abaq.us/allgeo-terms.html" target="_blank">Terms of Use</a> for more details.</p>
                                </div>
                            </div>
                            <div className="colBlk6">
                                <label className="lblColor1">SMS Opt-in</label>
                                <div className="customCheck yesNo">
                                    <input type="checkbox" id="smsOptin" {...register('smsOptIn')} />
                                    <label className="customCheckLabel" htmlFor="smsOptin">
                                        yesNo
                                    </label>
                                </div>
                                <p>
                                    <em>Last updated: {employeeDeviceValue.smsOptInLastupdatedTime}</em>
                                </p>
                                <p><a target='_blank' href="https://www.abaq.us/allgeo-terms.html">allGeo Terms of Use</a></p>
                            </div>
                        </div>
                    </div>
                </section>
                {props.accessLevel.childMenu && props.accessLevel.childMenu > 1 && <div className="mainBtnWrap">
                    <a className="cursorPointer" onClick={props.formCancel}>Cancel</a>
                    <button disabled={!isDirty || !isValid} className="btnStyle">SAVE</button>
                </div>}
            </form>
            <SiteLoader isActive={showLoader} />
        </Fragment>

    )
}