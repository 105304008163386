import React, { Fragment, useEffect, useState, useRef } from "react";
import { apiProvider } from '../../../services/api/provider';
import { useForm } from "react-hook-form";
import { Prompt } from 'react-router';
import ToastMessage from '../../common/ToastMessage';
import SiteLoader from '../../common/SiteLoader';
import $ from "jquery";
import KendoGrid from '../../common/kendo/KendoGrid';
import { payerServiceColumns, states } from "./settingsUtils";
import { ConfirmPopup } from "../../common/PopUp";
import { Checkbox } from "@progress/kendo-react-inputs";

const username = localStorage.getItem('userName')

export default function SettingsGeneral(props) {

    const { register, handleSubmit, watch, reset, getValues, setValue, formState: { errors, isDirty } } = useForm({ mode: "all" });
    const [generalSettingsValue, setGeneralSettingsValue] = useState({});

    const [isUpdateSuccessful, setIsUpdateSuccessful] = useState(null);
    const [accountUpdateMsg, setAccountUpdateMsg] = useState('');
    const [msgClass, setMsgClass] = useState('msgError');
    const [showLoader, setShowLoader] = useState(false);
    const [payerList, setPayerList] = useState(null);
    const [isEvvFieldsRequired, setIsEvvFieldsRequired] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [renewal, setRenewal] = useState();
    const childRef = useRef()


    const hasDuplicates = (nomenclature) => {
        const duplicateFields = [];
        const valueCounts = {};

        for (const key in nomenclature) {
            const value = nomenclature[key]?.trim();
            if (!value) continue;

            if (valueCounts[value]) {
                valueCounts[value].push(key);
            } else {
                valueCounts[value] = [key];
            }
        }

        for (const value in valueCounts) {
            if (valueCounts[value].length > 1) {
                duplicateFields.push(...valueCounts[value]);
            }
        }

        return duplicateFields;
    };



    const onSubmit = (data) => {
        data.report_units.secondFormatOpted = isChecked;
        const accountLabelDefaults = {
            device_title: "Employee",
            device_group_title: "Group",
            workOrder_title: "Work Order",
            job_title: "Jobsite",
            task_title: "Task"
        };


        data.nomenclature = Object.keys(accountLabelDefaults).reduce((result, key) => {
            result[key] = data.nomenclature[key] !== '' ? data.nomenclature[key] : accountLabelDefaults[key];
            return result;
        }, {});


        childRef.current?.saveData().then(res => {
            if (res) {
                setShowLoader(true);
                apiProvider.put('track/react/account/updateGeneralSetting', data).then((response) => {
                    if (response.status === 200) {
                        setMsgClass('msgSuccess');
                        setAccountUpdateMsg(response.message);
                        setIsUpdateSuccessful(true);
                        localStorage.setItem('accountLabel', JSON.stringify(data.nomenclature))
                        localStorage.setItem('sysAdminG2Enabled', JSON.stringify(data?.report_units?.g2ReviewEnabled))
                    }
                }).catch((err) => {
                    console.log(err);
                    setAccountUpdateMsg('Network error! unable to update account');
                    setIsUpdateSuccessful(true);
                }).finally(() => {
                    setShowLoader(true)
                    localStorage.removeItem('formIsDirty')
                });
            }
        })

    }

    useEffect(() => {
        if (isUpdateSuccessful === true || isUpdateSuccessful === null) {
            fetchData();
        }
    }, [isUpdateSuccessful]);

    const fetchData = () => {
        setShowLoader(true);
        apiProvider.getAll('track/react/account/generalSetting').then((response) => {
            if (response) {
                response.report_units.speed = '' + response.report_units.speed;
                response.report_units.distance = '' + response.report_units.distance;
                response.report_units.latlog_format = '' + response.report_units.latlog_format;
                response.report_units.temperature = '' + response.report_units.temperature;
                setGeneralSettingsValue(response);
                setIsChecked(response.report_units.secondFormatOpted)
            }
            apiProvider.getAll('track/mobile/v1/allgeo/evv/payer').then(res => {
                if (res) {
                    setPayerList(res)
                }
            })
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setShowLoader(false);
        });
    }

    // effect runs when user state is updated
    useEffect(() => {
        // reset form with user data
        reset(generalSettingsValue);
    }, [generalSettingsValue]);

    useEffect(() => {
        // reset form with user data
        if (props.accessLevel.childMenu && props.accessLevel.childMenu > 1) {
            props.formState(isDirty)
        }
        if (isDirty) {
            localStorage.setItem('formIsDirty', isDirty)
        }
    }, [isDirty]);

    const getPaymentRenewal = async () => {
        try {
            setShowLoader(true)
            const response = await apiProvider.getAll('/track/mobile//v1/allgeo/payment/renewal')
            if (response.status === 200) {
                if (response.subscription) {
                    setRenewal(response.subscription[0])
                }
            }

        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    useEffect(() => {
        getPaymentRenewal()
    }, [])

    const setPrimaryContact = () => {
        const primaryContact = getValues("basic_details.contact_email");
        setValue('basic_details.notify_email', primaryContact, { shouldDirty: true });
    };
    const handleDefaultAccountLabels = () => {
        setValue("nomenclature.device_title", 'Employee', { shouldDirty: true });
        setValue("nomenclature.device_group_title", "Group", { shouldDirty: true });
        setValue("nomenclature.workOrder_title", "Work Order", { shouldDirty: true });
        setValue("nomenclature.job_title", "Jobsite", { shouldDirty: true });
        setValue("nomenclature.task_title", "Task", { shouldDirty: true });
    }

    const copyToClipBoard = (fieldName) => {
        let fieldValue = getValues(fieldName);
        navigator.clipboard.writeText(fieldValue);
    }

    const formCancelEvent = () => {
        if (isDirty) {
            setShowDialog(true)
        }
    }

    const onSuccess = () => {
        setShowDialog(false);
        fetchData();
    }

    const onFaliure = () => {
        setShowDialog(false);
    }

    $(".copyIcon").on("click", function () {
        $(this).siblings('.copyClipboard').show().delay(4000).fadeOut();;
    });

    const deleteItem = async item => {

        const payload = item.length ? item : [item]

        try {
            setShowLoader(true)
            const res = await apiProvider.removeObject("track/mobile/v1/allgeo/evv/payer/delete", payload)

            return {
                status: res.StatusCode,
                message: res.StatusCode === 200 ? item.length ? "The selected records have been deleted successfully" : `${item.description} deleted Successfully` : res.ErrorMsg
            }
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    const createGridData = async dataArr => {
        try {
            const res = await apiProvider.post('track/mobile/v1/allgeo/evv/payer/create', dataArr)
            return {
                status: res.StatusCode,
                message: res.StatusCode === 200 ? "Payer created successfully" : res.ErrorMsg
            };
        } catch (error) {
            console.log(error);
        }
    }

    const updateGridData = async dataArr => {
        try {
            localStorage.removeItem('formIsDirty')
            const res = await apiProvider.post('track/mobile/v1/allgeo/evv/payer/update', dataArr)
            return {
                status: res.StatusCode,
                message: res.StatusCode === 200 ? "Payer Updated successfully" : res.ErrorMsg
            };
        } catch (error) {
            console.log(error);
        }
    }

    const handleEvvCheck = () => {
        const entity_ID = getValues("basic_details.entity_ID")
        const entity_Medicaid_ID = getValues("basic_details.entity_Medicaid_ID")
        const sandata_user_ID = getValues("basic_details.sandata_user_ID")
        const sandata_password = getValues("basic_details.sandata_password")

        if (
            entity_ID.trim().length > 0 ||
            entity_Medicaid_ID.trim().length > 0 ||
            sandata_user_ID.trim().length > 0 ||
            sandata_password.trim().length > 0
        ) {
            setIsEvvFieldsRequired(true)
        } else {
            setIsEvvFieldsRequired(false)
        }
    }

    const handleCheckboxChange = (event) => {
        // this function is used to capture that checkbox is selected or not 
        setIsChecked(event.target.value);
    };

    const userName = localStorage.getItem("userName")



    const nomenclatureValues = watch('nomenclature');
    if (nomenclatureValues) delete nomenclatureValues['address_title']

    const duplicateFields = hasDuplicates(nomenclatureValues);


    const shouldDisableButton =
        duplicateFields.includes('device_title') ||
        duplicateFields.includes('job_title') ||
        duplicateFields.includes('device_group_title') ||
        duplicateFields.includes('workOrder_title') ||
        duplicateFields.includes('task_title');

    return (
        <Fragment>

            <Prompt
                when={isDirty}
                message='The changes are still unsaved. Are you sure you want to leave?'
            />
            <form onSubmit={handleSubmit(onSubmit)} id="settingsForm">
                <section>
                    <h3>Account Information</h3>
                    <div className="supportedTxt">Update your account information including who should receive notification
                        emails and your preferred timezone</div>
                    <div className="innerWrap">
                        <div className="highlightedFldWrap">
                            <div className="fldWrap">
                                <label>Account Name</label>
                                <div className="highlightedValue">
                                    <span>{generalSettingsValue.name} ({generalSettingsValue.basic_details?.type})</span>
                                    <input readOnly type="hidden" {...register('name', { required: true })} />
                                    <span onClick={() => { copyToClipBoard('name') }} className="copyIcon">Copy</span>
                                    <span className="copyClipboard">Copied to the clipboard!</span>
                                </div>
                            </div>
                            <div className="fldWrap">
                                <label>Account ID</label>
                                <div className="highlightedValue">
                                    <span>{generalSettingsValue.id}</span>
                                    <input readOnly type="hidden" {...register('id')} />
                                    <span onClick={() => { copyToClipBoard('id') }} className="copyIcon">Copy</span>
                                    <span className="copyClipboard">Copied to the clipboard!</span>
                                </div>
                            </div>
                            <div className="fldWrap">
                                <label>Long / Short Code</label>
                                <div className="highlightedValue">
                                    <span>{generalSettingsValue.basic_details?.longcode}</span>
                                    <input readOnly type="hidden" {...register('basic_details.longcode')} />
                                    <span onClick={() => { copyToClipBoard('basic_details.longcode') }} className="copyIcon">Copy</span>
                                    <span className="copyClipboard">Copied to the clipboard!</span>
                                </div>
                            </div>
                            <div className="fldWrap">
                                <label>Username</label>
                                <div className="highlightedValue">
                                    <span>{username}</span>
                                    <input readOnly type="hidden" value={username} />
                                    <span onClick={() => { navigator.clipboard.writeText(username) }} className="copyIcon">Copy</span>
                                    <span className="copyClipboard">Copied to the clipboard!</span>
                                </div>
                            </div>
                            <div className="fldWrap">
                                <label>Subscription Term</label>
                                <div className="highlightedValue">
                                    <span>{renewal ? renewal.interval : "N/A"}</span>
                                </div>
                            </div>
                            <div className="fldWrap">
                                <label>Renewal Date</label>
                                <div className="highlightedValue">
                                    <span>{renewal ? renewal.invoiceEnd : "N/A"}</span>
                                </div>
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className="colBlk6 fldWrap">
                                <label>Organization Name (Company, Department, etc.)</label>
                                <input type="text" {...register("basic_details.description")} />
                            </div>
                            <div className="colBlk6 fldWrap">
                                <label>Default Login User ID</label>
                                <input type="text" {...register("basic_details.default_user")} readOnly onFocus={e => e.target.removeAttribute('readonly')} />
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className="colBlk6 fldWrap">
                                <label>Account Primary Contact</label>
                                <input type="text" {...register("basic_details.contact_name")} />
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className={`colBlk6 fldWrap ${errors.basic_details?.contact_email ? 'errFld' : ''}`}>
                                <label>Primary Contact Email</label>
                                <input type="text" {...register("basic_details.contact_email", { pattern: /\S+@\S+\.\S+/ })} />
                                {errors.basic_details?.contact_email && <div className="fldMsg">Please enter a valid email</div>}
                            </div>
                            <div className={`colBlk6 fldWrap ${errors.basic_details?.contact_number ? 'errFld' : ''}`}>
                                <label>Primary Contact Phone</label>
                                <input type="text"
                                    {...register("basic_details.contact_number", { pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/ })}
                                />
                                {errors.basic_details?.contact_number && <div className="fldMsg">Please enter a valid phone number</div>}
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className={`colBlk6 fldWrap ${errors.basic_details?.notify_email ? 'errFld' : ''}`}>
                                <label className="withAction">Notifications Email
                                    <span className="actionTxt" onClick={setPrimaryContact}>Same as Primary Contact</span></label>
                                <input type="email"  {...register("basic_details.notify_email", { pattern: /\S+@\S+\.\S+/ })} />
                                {errors.basic_details?.notify_email && <div className="fldMsg">Please enter a valid email</div>}
                            </div>
                            <div className="colBlk6 fldWrap">
                                <label>Account Timezone</label>
                                <select {...register("basic_details.timezone")}>
                                    <option value="US/Hawaii">US/Hawaii</option>
                                    <option value="US/Alaska">US/Alaska</option>
                                    <option value="US/Pacific">US/Pacific</option>
                                    <option value="US/Arizona">US/Arizona</option>
                                    <option value="US/Mountain">US/Mountain</option>
                                    <option value="US/Central">US/Central</option>
                                    <option value="US/Eastern">US/Eastern</option>
                                    <option value="Canada/Pacific">Canada/Pacific</option>
                                    <option value="Canada/Mountain">Canada/Mountain</option>
                                    <option value="Canada/Central">Canada/Central</option>
                                    <option value="Canada/Eastern">Canada/Eastern</option>
                                    <option value="Canada/Atlantic">Canada/Atlantic</option>
                                    <option value="Mexico/BajaNorte">Mexico/BajaNorte</option>
                                    <option value="Mexico/BajaSur">Mexico/BajaSur</option>
                                    <option value="Mexico/General">Mexico/General</option>
                                    <option value="Europe/Athens">Europe/Athens</option>
                                    <option value="Europe/Berlin">Europe/Berlin</option>
                                    <option value="Europe/Dublin">Europe/Dublin</option>
                                    <option value="Europe/Helsinki">Europe/Helsinki</option>
                                    <option value="Europe/Kiev">Europe/Kiev</option>
                                    <option value="Europe/Lisbon">Europe/Lisbon</option>
                                    <option value="Europe/London">Europe/London</option>
                                    <option value="Europe/Madrid">Europe/Madrid</option>
                                    <option value="Europe/Moscow">Europe/Moscow</option>
                                    <option value="Europe/Oslo">Europe/Oslo</option>
                                    <option value="Europe/Paris">Europe/Paris</option>
                                    <option value="Europe/Rome">Europe/Rome</option>
                                    <option value="Europe/Stockholm">Europe/Stockholm</option>
                                    <option value="Europe/Zurich">Europe/Zurich</option>
                                    <option value="Pacific/Auckland">Pacific/Auckland</option>
                                    <option value="Pacific/Chatham">Pacific/Chatham</option>
                                    <option value="GMT">GMT</option>
                                    <option value="GMT+00:00">GMT+00:00</option>
                                    <option value="GMT+01:00">GMT+01:00</option>
                                    <option value="GMT+02:00">GMT+02:00</option>
                                    <option value="GMT+03:00">GMT+03:00</option>
                                    <option value="GMT+04:00">GMT+04:00</option>
                                    <option value="GMT+05:00">GMT+05:00</option>
                                    <option value="GMT+05:30">GMT+05:30</option>
                                    <option value="GMT+06:00">GMT+06:00</option>
                                    <option value="GMT+07:00">GMT+07:00</option>
                                    <option value="GMT+08:00">GMT+08:00</option>
                                    <option value="GMT+09:00">GMT+09:00</option>
                                    <option value="GMT+10:00">GMT+10:00</option>
                                    <option value="GMT+11:00">GMT+11:00</option>
                                    <option value="GMT+12:00">GMT+12:00</option>
                                    <option value="GMT+13:00">GMT+13:00</option>
                                    <option value="GMT+14:00">GMT+14:00</option>
                                    <option value="GMT-01:00">GMT-01:00</option>
                                    <option value="GMT-02:00">GMT-02:00</option>
                                    <option value="GMT-03:00">GMT-03:00</option>
                                    <option value="GMT-04:00">GMT-04:00</option>
                                    <option value="GMT-05:00">GMT-05:00</option>
                                    <option value="GMT-06:00">GMT-06:00</option>
                                    <option value="GMT-07:00">GMT-07:00</option>
                                    <option value="GMT-08:00">GMT-08:00</option>
                                    <option value="GMT-09:00">GMT-09:00</option>
                                    <option value="GMT-10:00">GMT-10:00</option>
                                    <option value="GMT-11:00">GMT-11:00</option>
                                    <option value="GMT-12:00">GMT-12:00</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <h3>Reporting Units</h3>
                    <div className="supportedTxt">Define the reporting units for your account including kinematics
                        parameters</div>
                    <div className="innerWrap">
                        <div className="rowBlk">
                            <div className="colBlk6 fldWrap">
                                <label>Speed</label>
                                <div className="customControls capsules">
                                    <div className="customRadio">
                                        <input type="radio" id="mph" value="0" {...register("report_units.speed")} />
                                        <label htmlFor="mph">mph</label>
                                    </div>
                                    <div className="customRadio">
                                        <input type="radio" id="kmh" value="1" {...register("report_units.speed")} />
                                        <label htmlFor="kmh">km/h</label>
                                    </div>
                                    <div className="customRadio">
                                        <input type="radio" id="knots" value="2" {...register("report_units.speed")} />
                                        <label htmlFor="knots">knots</label>
                                    </div>
                                </div>
                            </div>
                            <div className="colBlk6 fldWrap">
                                <label>Distance</label>
                                <div className="customControls capsules">
                                    <div className="customRadio">
                                        <input type="radio" id="miles" value="0" {...register("report_units.distance")} />
                                        <label htmlFor="miles">Miles</label>
                                    </div>
                                    <div className="customRadio">
                                        <input type="radio" id="km" value="1" {...register("report_units.distance")} />
                                        <label htmlFor="km">Km</label>
                                    </div>
                                    <div className="customRadio">
                                        <input type="radio" id="nm" value="2" {...register("report_units.distance")} />
                                        <label htmlFor="nm">Nm</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className="colBlk6 fldWrap">
                                <label>Lat/Lon Format</label>
                                <div className="customControls capsules">
                                    <div className="customRadio">
                                        <input type="radio" id="degrees" value="0" {...register("report_units.latlog_format")} />
                                        <label htmlFor="degrees">Degrees</label>
                                    </div>
                                    <div className="customRadio">
                                        <input type="radio" id="degMin" value="1" {...register("report_units.latlog_format")} />
                                        <label htmlFor="degMin">Deg:Min</label>
                                    </div>
                                    <div className="customRadio">
                                        <input type="radio" id="degMinSec" value="2" {...register("report_units.latlog_format")} />
                                        <label htmlFor="degMinSec">Deg:Min:Sec</label>
                                    </div>
                                </div>
                            </div>
                            <div className="colBlk6 fldWrap">
                                <label>Minute</label>
                                <div className="customControls capsules checkBoxMargin">
                                    <div className="customRadio">
                                        <input type="radio" id="minutes" value="minutes" {...register("report_units.minutes")}
                                        />
                                        <label htmlFor="minutes">minutes</label>
                                    </div>
                                    <div className="customRadio">
                                        <input type="radio" id="hundredths" value="hundredths" {...register("report_units.minutes")}
                                        />
                                        <label htmlFor="hundredths">hundredths</label>
                                    </div>
                                </div>
                                <span>
                                    <Checkbox label={"Count Seconds towards Service Hours"} checked={isChecked} onChange={handleCheckboxChange} />
                                </span>
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className="colBlk6 fldWrap">
                                <label>Date Time</label>
                                <div className="customControls capsules">
                                    <div className="customRadio">
                                        <input type="radio" id="standard" value="Standard" {...register("report_units.datetime")} />
                                        <label htmlFor="standard">Standard</label>
                                    </div>
                                    <div className="customRadio">
                                        <input type="radio" id="military" value="Military" {...register("report_units.datetime")} />
                                        <label htmlFor="military">Military</label>
                                    </div>
                                </div>
                            </div>
                            <div className="colBlk6 fldWrap">
                                <label>Temperature</label>
                                <div className="customControls capsules">
                                    <div className="customRadio">
                                        <input type="radio" id="f" value="0" {...register("report_units.temperature")} />
                                        <label htmlFor="f">F</label>
                                    </div>
                                    <div className="customRadio">
                                        <input type="radio" id="c" value="1" {...register("report_units.temperature")} />
                                        <label htmlFor="c">C</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className="colBlk12 fldWrap">
                                <label>Date Format</label>
                                <div className="customControls">
                                    <div className="customRadio">
                                        <input type="radio" id="ymd" value="yyyy/MM/dd"  {...register("report_units.date_display_format")} />
                                        <label htmlFor="ymd">YYYY/MM/DD<span>(2022/01/01)</span></label>
                                    </div>
                                    <div className="customRadio">
                                        <input type="radio" id="mdy" value="MM/dd/yyyy" {...register("report_units.date_display_format")} />
                                        <label htmlFor="mdy">MM/DD/YYYY<span>(01/01/2022)</span></label>
                                    </div>
                                    <div className="customRadio">
                                        <input type="radio" id="dmy" value="dd/MM/yyyy" {...register("report_units.date_display_format")} />
                                        <label htmlFor="dmy">DD/MM/YYYY<span>(01/01/2022)</span></label>
                                    </div>
                                    <div className="customRadio">
                                        <input type="radio" id="y-m-d" value="yyyy-MM-dd" {...register("report_units.date_display_format")} />
                                        <label htmlFor="y-m-d">YYYY-MM-DD<span>(2022-01-01)</span></label>
                                    </div>
                                    <div className="customRadio">
                                        <input type="radio" id="m-d-y" value="MM-dd-yyyy" {...register("report_units.date_display_format")} />
                                        <label htmlFor="m-d-y">MM-DD-YYYY<span>(01-01-2022)</span></label>
                                    </div>
                                    <div className="customRadio">
                                        <input type="radio" id="d-m-y" value="dd-MM-yyyy" {...register("report_units.date_display_format")} />
                                        <label htmlFor="d-m-y">DD-MM-YYYY<span>(01-01-2022)</span></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className="colBlk6 fldWrap">
                                <label>Report Time Zone</label>
                                <div className="customControls capsules">
                                    <div className="customRadio">
                                        <input type="radio" id="account" value="Account" {...register("report_units.report_timezone")} />
                                        <label htmlFor="account">Account</label>
                                    </div>
                                    <div className="customRadio">
                                        <input type="radio" id="device" value="Device" {...register("report_units.report_timezone")} />
                                        <label htmlFor="device">Device</label>
                                    </div>
                                    <div className="customRadio">
                                        <input type="radio" id="location" value="Location" {...register("report_units.report_timezone")} />
                                        <label htmlFor="location">Location</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <h3>EVV</h3>
                    <div className="supportedTxt">Update your account information including who should receive notification emails and your preferred timezone</div>
                    <div className="innerWrap">
                        <div className="rowBlk">
                            <div className="colBlk6 fldWrap">
                                <label>Business Entity ID</label>
                                <input type="text" {...register("basic_details.entity_ID", { onChange: () => handleEvvCheck(), required: isEvvFieldsRequired })} />
                            </div>
                            <div className="colBlk6 fldWrap">
                                <label>User ID</label>
                                <input type="text" {...register("basic_details.sandata_user_ID", { onChange: () => handleEvvCheck(), required: isEvvFieldsRequired })} />
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className="colBlk6 fldWrap">
                                <label>Business Entity Medicaid ID</label>
                                <input type="text" {...register("basic_details.entity_Medicaid_ID", { onChange: () => handleEvvCheck(), required: isEvvFieldsRequired })} />
                            </div>
                            <div className="colBlk6 fldWrap">
                                <label>Password</label>
                                <input type="password" {...register("basic_details.sandata_password", { onChange: () => handleEvvCheck(), required: isEvvFieldsRequired })} readOnly onFocus={e => e.target.removeAttribute('readonly')} />
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className="colBlk3 fldWrap">
                                <label>State</label>
                                <select {...register("basic_details.sandata_state_code")}>
                                    {
                                        states.map((state, ind) => (
                                            <option key={ind} value={state.value}>{state.label}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                    </div>

                </section>
                {props.accessLevel.childMenu && props.accessLevel.childMenu > 1 && <div className="mainBtnWrap">
                    <a onClick={formCancelEvent}>Cancel</a>
                    <button className="btnStyle" disabled={shouldDisableButton}>SAVE</button>
                </div>}
            </form>
            <div className="sectionSubHeading topGap1">Payer Service Information</div>
            {payerList &&
                <KendoGrid
                    gridData={payerList}
                    columnData={payerServiceColumns}
                    createItem={createGridData}
                    updateItem={updateGridData}
                    deleteItem={deleteItem}
                    bulkDelete={deleteItem}
                    accessLevel={props.accessLevel}
                    getAPiUrl={"track/mobile/v1/allgeo/evv/payer"}
                    ref={childRef}
                    setGridData={setPayerList}
                    module="settings"
                    lockActionColumn={true}
                    otherData={{
                        actionColumnBtns: ['delete'],
                        isDirty: val => props.formState(val),
                        exportFileName: "Payer Service"
                    }}
                />
            }
            {
                isUpdateSuccessful &&
                <ToastMessage cssClass={msgClass} message={accountUpdateMsg}
                    onClose={() => { setIsUpdateSuccessful(false); }}
                />
            }
            <SiteLoader isActive={showLoader} />
            <ConfirmPopup
                showDialog={showDialog}
                message={"The changes are still unsaved. Are you sure you want to leave?"}
                confirmNavigation={onSuccess}
                cancelNavigation={onFaliure}
            />
        </Fragment>
    )
}