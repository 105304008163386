import React, { useEffect, useRef, useState } from "react";
import "./notificationAlert.css";
import ExpandIcon from "../../../../assets/images/expand-more.svg";
import NotificationIcon from "../../../../assets/images/Notifications and alerts.svg";
import SendButton from "../../../../assets/images/send-button.svg";
import RefreshButton from "../../../../assets/images/refresh-button.svg";
import InboundIcon from "../../../../assets/images/inbound-messages.svg";
import OnboundIcon from "../../../../assets/images/outbound-messages.svg";
import { SendMessageModal } from "./SendMessageModal";
import { ExpandAlertsAndNotificationsModal } from "./ExpandAlertsAndNotificationsModal";
import { NotificationAlertsView } from "./NotificationAlertsView";
import { getAlertsAndNotificationData } from "../../utils";
import SiteLoader from "../../../../components/common/SiteLoader";
import ToastMessage from "../../../../components/common/ToastMessage";
import { TooltipKendo } from "../Tooltip";

export const NotificationAlert = ({
  handlePinned,
  handleUnPinned,
  currentTimeAndDateAlertsNoti,
  setCurrentTimeAndDateAlertsNoti,
  notificationAndAlertMetaData,
  alertNotificationData,
  msgTemplates,
  employeeData,
  setShowMainLoader,
  setAlertNotificationData,
  showLoaderNotification,
  height = "300px",
  setNotificationAndAlertMetaData,
  timeClockData=[],
  fromTimeClock=false
}) => {
  const [sendMessageModal, setSendMessageModal] = useState(false);
  const [showExpanMore, setShowExpanMore] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const parentRef = useRef(null);
// console.log(timeClockData.stageInfoList.length,"timeClockData")
  // const [ notificationAndAlertsData, setNotificationAndAlertsData] = useState();
  const [toastMsg, setToastMsg] = useState({
    msg: "",
    type: "msgSuccess",
  });

  const localTime = currentTimeAndDateAlertsNoti?.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  });
  const localDate = currentTimeAndDateAlertsNoti?.getDate();
  const localMonth = currentTimeAndDateAlertsNoti?.getMonth() + 1;
  const localYear = currentTimeAndDateAlertsNoti?.getFullYear();
  console.log(showLoader, "showLoadershowLoader");

  let fromRefresh;

  const handleRefreshNotificationData = () => {
    notificationAndAlertMetaData.page = 1;
    getAlertsAndNotificationData(
      setCurrentTimeAndDateAlertsNoti,
      setNotificationAndAlertMetaData,
      notificationAndAlertMetaData,
      setAlertNotificationData,
      setShowLoader,
      true,
      notificationAndAlertMetaData
    );
  };

  const refreshHandler = () => {
    console.log("Refresh Miles Data");
    document
      .querySelector("#scrollableDiv .infinite-scroll-component")
      .scrollTo(0, 0);
    setCurrentTimeAndDateAlertsNoti(new Date());
    getAlertsAndNotificationData(
      setCurrentTimeAndDateAlertsNoti,
      setNotificationAndAlertMetaData
    );
    setShowLoader(true);
    handleRefreshNotificationData();
  };
  useEffect(() => {
    if (parentRef.current) {
      const parentHeight = parentRef.current.offsetHeight-20;
      console.log("Parent div height:", parentHeight);
    }
  }, [parentRef.current]);

  return (
    <>
      <div className="notificationParentContainer kendoTblWrap">
        {(showLoaderNotification || showLoader) && (
          <SiteLoader isActive={showLoaderNotification || showLoader} />
        )}
        <div className="notificationHeadingContainer">
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ marginRight: "4px" }}>
              <img style={{ paddingTop: 2 }} src={NotificationIcon} alt="" />
            </span>
            <p>Notifications and Alerts </p>
          </div>

          <div className="head-box">
            <img
              onClick={() => setSendMessageModal(true)}
              style={{ cursor: "pointer" }}
              src={SendButton}
              alt="Send Button"
            />

            <TooltipKendo>
              <img
                onClick={() => setShowExpanMore(true)}
                style={{ cursor: "pointer" }}
                src={ExpandIcon}
                alt="Expand Icon"
                title="Expand View"
              />
            </TooltipKendo>
            <TooltipKendo>
              <img
                style={{ cursor: "pointer" }}
                src={RefreshButton}
                alt="Refresh Button"
                onClick={refreshHandler}
                title="Refresh"
              />
            </TooltipKendo>
          </div>
        </div>
        <div
          style={{
            borderLeft: "none",
            marginLeft: 0,
            paddingLeft: 0,
            marginBottom: 16.5,
          }}
          className="refreshtext"
        >
          Last Refreshed:
          <span>{`${localMonth}/${localDate}/${localYear}`}</span>
          <span>{localTime}</span>
        </div>
        <div ref={parentRef}  className="parent-border">
          <NotificationAlertsView
            height={timeClockData?.stageInfoList?.length>4?271.5:height}
            handlePinned={handlePinned}
            handleUnPinned={handleUnPinned}
            notificationData={alertNotificationData}
            infiniteScrollFun={() => infiniteScrollFun()}
            hasMore={false}
            // height={height}
            setCurrentTimeAndDateAlertsNoti={setCurrentTimeAndDateAlertsNoti}
            notificationAndAlertMetaData={notificationAndAlertMetaData}
            setAlertNotificationData={setAlertNotificationData}
            setNotificationAndAlertMetaData={setNotificationAndAlertMetaData}
            timeClockDataLength={timeClockData?.stageInfoList?.length}
            fromTimeClock={fromTimeClock}
          />
        </div>
        {!!sendMessageModal && (
          <SendMessageModal
            closePopup={() => setSendMessageModal(false)}
            msgTemplates={msgTemplates}
            employeeData={employeeData}
            setShowMainLoader={setShowMainLoader}
            setToastMsg={setToastMsg}
            handleRefreshNotificationData={handleRefreshNotificationData}
          />
        )}
        {!!showExpanMore && (
          <ExpandAlertsAndNotificationsModal
            closePopup={() => setShowExpanMore(false)}
            notificationData={alertNotificationData}
            handlePinned={handlePinned}
            handleUnPinned={handleUnPinned}
            hasMore={true}
            height={height}
            setCurrentTimeAndDateAlertsNoti={setCurrentTimeAndDateAlertsNoti}
            notificationAndAlertMetaData={notificationAndAlertMetaData}
            setAlertNotificationData={setAlertNotificationData}
            setNotificationAndAlertMetaData={setNotificationAndAlertMetaData}
          />
        )}
        {toastMsg.msg && (
          <ToastMessage
            cssClass={toastMsg.type}
            message={toastMsg.msg}
            onClose={() => setToastMsg({ msg: "", type: "" })}
          />
        )}
      </div>
    </>
  );
};
