import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AuthContext from '../../store/auth-context';
import SiteLoader from '../common/SiteLoader';
import axios from 'axios';
const AfterLogin = () => {
    const [tokenEn, setTokenEn] = useState(decodeURIComponent(localStorage.getItem('tokenEn')));
    const authCtx = useContext(AuthContext);
    const history = useHistory();
    const BASE_URL = process.env.REACT_APP_API_URL;
    const resource = 'track/react/v1/childaccounts';
    const menuAPIResource = '/track/react/account/getMenuList';
    const [showLoader, setShowLoader] = useState(false);

    const handleRedirect = () => {
        window.location.href = process.env.REACT_APP_LOGIN_REDIRECT;
    };

    const handleFormSubmit = () => {
        setTimeout(() => {
            localStorage.setItem('revisit', 'true');
            document.getElementById("serverLogin").submit();
        }, 1000);
    };

    useEffect(async () => {
        if (tokenEn && tokenEn !== 'null') {
            setShowLoader(true);
            await delay(1000);
            let ifParent = localStorage.getItem('isParent');
            if (ifParent == 'true') {
                // get the parent child data and menu items
                getChildParentAndMenu(true);
            } else {
                //get the menu items only
                getChildParentAndMenu(false);
            }
        }


    }, [tokenEn]);

    const getChildParentAndMenu = (isParent = false) => {
        if (tokenEn && tokenEn !== 'null') {
            console.log('getChildParentAndMenu');
            const config = {
                headers: {
                    'Authorization': 'Bearer' + localStorage.getItem('token'),
                    'Content-Type': 'application/json',
                }
            }
            let promises = [];
            //allways call the menu api
            promises.push(axios.get(`${BASE_URL}/${menuAPIResource}`, config));

            // if parent account then call the parent api
            if (isParent) {
                promises.push(axios.get(`${BASE_URL}/${resource}`, config));
            }


            Promise.all(promises).then((response) => {
                console.log(response);
                let menuResponse = response[0];
                if (menuResponse.status === 200) {
                    localStorage.setItem('menuItems', JSON.stringify(menuResponse.data.data));
                } else {
                    history.replace('/logout');
                }

                if (promises.length === 2) {
                    let parentResponse = response[1];
                    if (parentResponse.status === 200) {
                        localStorage.setItem('chieldAccounts', JSON.stringify(parentResponse.data));
                    } else {
                        history.replace('/logout');
                    }
                }

            }).catch((err) => {
                console.log(err);
                history.replace('/logout');
            }).finally(() => {
                setShowLoader(false);
                handleLoginForwarding();
            });
        }
    };

    const handleLoginForwarding = () => {
        if (authCtx.isLoggedIn) {
            setTokenEn(decodeURIComponent(localStorage.getItem('tokenEn')));
            if (!localStorage.getItem('revisit') || localStorage.getItem('revisit') == 'false') {
                handleFormSubmit();
            } else {
                handleRedirect();
            }
        } else {
            history.replace('/login');
        }
    };

    const delay = (delay) => {
        return new Promise(function (resolve) {
            setTimeout(resolve, delay);
        });
    };

    return (
        <>
            <form method="post" action={process.env.REACT_APP_LOGIN_REDIRECT} id="serverLogin">
                <input type="hidden" name="loginToken" value={tokenEn} />
            </form>
            <SiteLoader isActive={showLoader} />
        </>
    )
}
export default AfterLogin;