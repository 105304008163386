import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import UploadIcon from '../../../assets/images/icon-upload.svg'
import sampleXLSX from './job-site-sample.xlsx';
import { apiProvider } from '../../../services/api/provider';
import { units, revUnitConversionRate } from './jobSitesUtils';
import { getAccountLabel } from '../../../utils';

const IndividualMenu = (props) => {

    const [data, setData] = useState({
        name: "",
        address: ""
    })

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (data.name !== "" && data.address !== "") {
                props.setIsFormComplete(true)
            } else {
                props.setIsFormComplete(false)
            }
        }, 1500)

        return () => clearTimeout(delayDebounceFn)
    }, [data])

    const changeGeoZone = (id) => {
        let dataSelected = props.jobSiteList.filter((ele) => ele.txnId === id);
        if (dataSelected.length > 0) {
            props.setSelectedJobSite(dataSelected)
        }
    }

    return (

        <div className="popupTabCont">
            <div className="rowBlk">
                <div className="colBlk6">
                    <div className="rowBlk">
                        <div className="colBlk6 fldWrap">
                            <label>Site Name</label>
                            <input type="text" maxLength={100} {...props.register('siteName')} onChange={(e) => setData({ ...data, name: e.target.value })} />
                        </div>
                        <div className="colBlk6 fldWrap">
                            <label>Active</label>
                            <div className="customCheck yesNo">
                                <input type="checkbox" id="StatusActive" {...props.register('status', { value: true })} />
                                <label className="customCheckLabel" htmlFor="StatusActive">yesNo</label>
                            </div>
                        </div>
                    </div>
                    <div className="rowBlk">
                        <div className="colBlk6 fldWrap">
                            <label>Geozone Type</label>
                            <div className="customControls capsules">
                                <div className="customRadio" >
                                    <input type="radio" id="Circle" name="speed" value="Circle" {...props.register('geoZoneType')}
                                        checked={props.geoZone === 'Circle'}
                                        onClick={() => {
                                            if (props.geoZone !== 'Circle') {
                                                props.setGeoZone('Circle');
                                                let rad = Math.floor(100 * revUnitConversionRate[props.radiusUnit?.toLowerCase()])
                                                props.register("radius", { value: rad });
                                            }
                                        }} />
                                    <label htmlFor="Circle">Circle</label>
                                </div>
                                <div className="customRadio" >
                                    <input type="radio" id="Polygon" name="speed" value="Polygon" {...props.register('geoZoneType')}
                                        checked={props.geoZone === 'Polygon'}
                                        onClick={() => {
                                            if (props.geoZone !== 'Polygon') {
                                                props.setGeoZone('Polygon');
                                                props.unregister("radius");
                                            }
                                        }} />
                                    <label htmlFor="Polygon">Polygon</label>
                                </div>
                            </div>
                        </div>
                        {props.geoZone === 'Circle' &&
                            <div className="colBlk6 fldWrap">
                                <label>Radius</label>
                                <div className="withInputSub">
                                    <input type="text" {...props.register('radius', {
                                        value: units[props.radiusUnit?.toLowerCase()] === "m" ? 1 : 4,
                                        onChange: (e) => props.setValue('radius', (e.target.value).replace(/[^0-9.]/g, ''), { shouldDirty: false })
                                    })} />
                                    <span>{units[props.radiusUnit?.toLowerCase()]}</span>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="colBlk6">
                    <div className="fldWrap">
                        <label>Address</label>
                        <textarea placeholder="Enter Address Here" maxLength={255} {...props.register('address')} onChange={(e) => setData({ ...data, address: e.target.value })}></textarea>
                    </div>
                </div>
            </div>
            <div className={`collapsibleSection ${props.advanceSetupExpand && 'active'}`}>
                <span onClick={() => props.setAdvanceSetupExpand(!props.advanceSetupExpand)}>Advanced Setup</span>
            </div>
            {props.advanceSetupExpand &&
                <Fragment>
                    <div className="rowBlk">
                        <div className="colBlk3 fldWrap">
                            <label>Longitude</label>
                            <input type="text" maxLength={10} {...props.register('longitude')} />
                        </div>
                        <div className="colBlk3 fldWrap">
                            <label>Latitude</label>
                            <input type="text" maxLength={10} {...props.register('latitude')} />
                        </div>
                        <div className="colBlk6 fldWrap">
                            <label className="withHelpTxt">Site ID <span className="helpTip">? <span>This is the Client Medicaid ID for EVV setup</span></span></label>
                            <input type="text" maxLength={100} {...props.register('siteID')} />
                        </div>
                    </div>
                    <div className="rowBlk">
                        <div className="colBlk6 fldWrap">
                            <label>Site Description</label>
                            <input type="text" maxLength={100} {...props.register('siteDescription')} />
                        </div>
                        <div className="colBlk6 fldWrap">
                            <label className="withHelpTxt">Source ID <span className="helpTip">? <span>This is the Client Payer ID for EVV setup</span></span></label>
                            <input type="text" maxLength={255} {...props.register('sourceId')} />
                        </div>
                    </div>
                    <div className="rowBlk">
                        <div className="colBlk6 fldWrap">
                            <label>Customer Type</label>
                            <input type="text" maxLength={50} {...props.register('customerType')} />
                        </div>
                        <div className="colBlk6 fldWrap">
                            <label className="withHelpTxt">Reference ID <span className="helpTip">? <span>This is the Client Identifier for EVV setup</span></span></label>
                            <input type="text" maxLength={50} {...props.register('referenceId')} />
                        </div>
                    </div>
                    <div className="rowBlk">
                        <div className={`colBlk6 fldWrap ${props.errors.email ? 'errFld' : ''}`}>
                            <label>Contact Email</label>
                            <input type="email" maxLength={255} {...props.register('email', { pattern: /\S+@\S+\.\S+/ })} />
                            {props.errors.email && <div className="fldMsg">Please enter a valid email</div>}
                        </div>
                        <div className="colBlk6 fldWrap">
                            <label>Phone Number</label>
                            <input type="text"
                                maxLength={255}
                                {...props.register("phoneNumber", {
                                    onChange: (e) => props.setValue('phoneNumber', (e.target.value).replace(/[a-zA-Z]/g, ''), { shouldDirty: false })
                                })} />
                        </div>
                    </div>
                    <div className="rowBlk">
                        <div className="colBlk3 fldWrap">
                            <label>Risk</label>
                            <select {...props.register("risk")}>
                                <option>Select</option>
                                <option>Low</option>
                                <option>Medium</option>
                                <option>High</option>
                            </select>
                        </div>
                        <div className="colBlk3 fldWrap">
                            <label>Site Type</label>
                            <select {...props.register("siteType")}>
                                <option>Select</option>
                                <option>Nursing_Home</option>
                                <option>Community</option>
                            </select>
                        </div>
                        <div className="colBlk6 fldWrap">
                            <label>Landline Number</label>
                            <input type="text"
                                maxLength={255}
                                {...props.register("landLineNumber", {
                                    onChange: (e) => props.setValue('landLineNumber', (e.target.value).replace(/[a-zA-Z]/g, ''), { shouldDirty: false })
                                })} />
                        </div>
                    </div>
                    <div className="rowBlk">
                        <div className="colBlk6 fldWrap">
                            <label>Claim Frequency</label>
                            <input type="text" maxLength={255} {...props.register('claimFrequency')} />
                        </div>
                        <div className="colBlk6 fldWrap">
                            <label>User Description</label>
                            <input type="text" maxLength={255} {...props.register('userDescription')} />
                        </div>
                    </div>
                    <div className="rowBlk">
                        <div className="colBlk6 fldWrap">
                            <label>Skills</label>
                            <select {...props.register('skills')}>
                                {
                                    props.skillsData.map((t, ind) => (
                                        <option key={ind} value={t} >{t}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className={`colBlk${(props.addressType === "Geozone" && props.jobSiteList.length > 0) ? 3 : 6} fldWrap`}>
                            <label>Address</label>
                            <div className="customControls">
                                <div className="customRadio">
                                    <input type="radio" id="PostalAddress" name="address" value="Postal Address" {...props.register("addressType")}
                                        checked={props.addressType === "Postal Address"}
                                        onClick={() => {
                                            if (props.addressType !== "Postal Address") {
                                                props.setAddressType('Postal Address');
                                                props.setSelectedJobSite([])
                                            }
                                        }} />
                                    <label htmlFor="PostalAddress">Postal Address</label>
                                </div>
                                <div className="customRadio">
                                    <input type="radio" id="Geozone" name="address" value="Geozone" {...props.register("addressType")}
                                        checked={props.addressType === "Geozone"}
                                        onClick={() => {
                                            if (props.addressType !== 'Geozone') {
                                                props.setAddressType('Geozone');
                                            }
                                        }} />
                                    <label htmlFor="Geozone">Geozone</label>
                                </div>
                            </div>
                        </div>
                        {
                            props.jobSiteList.length > 0 && props.addressType === "Geozone" &&
                            <div className="colBlk3 fldWrap">
                                <label>Select Geozone</label>
                                <select
                                    defaultValue={'Select'}
                                    onChange={(e) => changeGeoZone(e.target.value)}
                                >
                                    {
                                        [{ name: 'Select', txnId: '' }, ...props.jobSiteList].map((t, ind) => (
                                            <option key={ind} value={t.txnId}>{t.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        }
                    </div>
                    <div className="rowBlk">
                        <div className="colBlk6 fldWrap">
                            <label>Ext System</label>
                            <input type="text" maxLength={10} {...props.register("extSystem")} />
                        </div>
                        <div className="colBlk3 fldWrap">
                            <label>Earning Code</label>
                            <select {...props.register("earningCode")}>
                                {
                                    props.earningData.map((t, ind) => (
                                        <option key={ind} value={t.code} >{t.code}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="colBlk3 fldWrap">
                            <label>Rate card</label>
                            <input type="text" maxLength={45} {...props.register('rateCard')} />
                        </div>
                    </div>
                </Fragment>
            }
        </div>

    )

}

const MultipleMenu = (props) => {

    return (

        <div className="popupTabCont alignCenter">
            <div className="downloadBlk">Download {props.jobsiteLabel} details <a className='cursorPointer' href={sampleXLSX} download={`${props.jobsiteLabel} sample`}>sample file here</a></div>
            {!props.file ? <div className="dragDrop"
                onDragOver={e => {
                    e.preventDefault()
                }}
                onDrop={e => {
                    e.preventDefault()
                    props.handleFileUpload(e)
                }}
            >
                <p><img src={UploadIcon} alt="Upload" /></p>
                <div>Drag and drop or browse to choose a file</div>
                <p>CSV and XLSX file extensions are allowed</p>
                {props.error && <div className="errMsg">File type unsupported.</div>}
                <input type="file" onChange={props.handleFileUpload} accept=".csv" />
            </div>
                :
                <div className="fileDetail">
                    <div className="fileName">{props.file.name}</div>
                    <span>{props.handleFileSize()}</span>
                    <em className="fileDelete" onClick={() => { props.setFile(null); props.setIsFormComplete(false) }} >Delete</em>
                </div>
            }
        </div>

    )

}

const AddNewPopup = (props) => {

    const { register, handleSubmit, reset, setValue, unregister, formState: { errors } } = useForm({ mode: "all" });
    const [activeMenu, setActiveMenu] = useState('single');
    const [isFormComplete, setIsFormComplete] = useState(false);
    const [geoZone, setGeoZone] = useState('Circle');
    const [file, setFile] = useState(null);
    const [error, setError] = useState(false);
    const [advanceSetupExpand, setAdvanceSetupExpand] = useState(false);
    const [earningData, setEarningData] = useState([]);
    const [skillsData, setSkillsData] = useState([]);
    const [jobSiteList, setJobSiteList] = useState([]);
    const [addressType, setAddressType] = useState('Postal Address');
    const [selectedJobSite, setSelectedJobSite] = useState([]);


    useEffect(() => {
        fetchEarningData();
        fetchSkillsData();
        fetchAllJobSites();
        setValue('radius', Math.floor(100 * revUnitConversionRate[props.radiusUnit?.toLowerCase()]), { shouldDirty: false });
    }, [])

    const fetchEarningData = () => {
        apiProvider.getAll('track/mobile/v1/allgeo/earning/codes').then((response) => {
            setEarningData([{ code: 'Select' }, ...response])
        }).catch((err) => {
            console.log(err);
        });
    };

    const fetchSkillsData = () => {
        apiProvider.getAll('track/react/account/getEmployeeSkills').then((response) => {
            setSkillsData(['Select', ...response])
        }).catch((ex) => {
            console.log(ex);
        });
    };

    const fetchAllJobSites = () => {

        apiProvider.getAll('/track/mobile/v2/allgeo/getCustomers?active=Yes').then((response) => {
            setJobSiteList(response.data)
        }).catch((error) => {
            console.log(error);
        });

    };

    const toggleMenu = menu => {
        if (menu === "multiple") {
            reset()
        } else {
            if (file) setFile(null)
        }
        setIsFormComplete(false)
        setActiveMenu(menu)
    }

    const onSubmit = async (data) => {

        const minValue = units[props.radiusUnit?.toLowerCase()] === "m" ? 1 : 4

        try {
            props.setShowLoader(true);

            if (activeMenu === 'single') {
                if (data?.radius <= minValue) {

                    props.setToastMsg({ msg: `The radius cannot be less than ${minValue} ${props.radiusUnit?.toLowerCase()}. Please enter a significant value.`, type: "" });

                } else {

                    const payload = [{
                        "name": data.siteName,
                        "address": selectedJobSite.length > 0 ? selectedJobSite[0].address : data.address,
                        "geozoneType": data.geoZoneType,
                        "radius": data?.radius ? data.radius : 0,
                        "near_employees": "",
                        "modified": "",
                        "creation": "",
                        "additional_info": {
                            "latitude": selectedJobSite.length > 0 ? selectedJobSite[0].additional_info.latitude : data?.latitude ? data.latitude : "",
                            "longitude": selectedJobSite.length > 0 ? selectedJobSite[0].additional_info.longitude : data?.longitude ? data.longitude : "",
                            "siteId": data?.siteID ? data.siteID : "",
                            "siteName": data?.siteDescription ? data.siteDescription : "",
                            "sourceId": data?.sourceId ? data.sourceId : "",
                            "customerType": data?.customerType ? data.customerType : null,
                            "referenceId": data?.referenceId ? data.referenceId : "",
                            "email": data?.email ? data.email : "",
                            "phoneNumber": data?.phoneNumber ? data.phoneNumber : "",
                            "landLineNumber": data?.landLineNumber ? data.landLineNumber : "",
                            "risk": data?.risk ? data.risk : null,
                            "siteType": data?.siteType ? data.siteType : "",
                            "claim_freqeuncy": data?.claimFrequency ? data.claimFrequency : "",
                            "user_description": data?.userDescription ? data.userDescription : null,
                            "skills": data?.skills ? data.skills : "",
                            "addressType": data.addressType,
                            "extSystem": data?.extSystem ? data.extSystem : "",
                            "earningCode": data?.earningCode ? data.earningCode : "",
                            "rateCard": data?.rateCard ? data.rateCard : "",
                            "priority": ""
                        },
                        "evv_info": {
                            "client_first_name": "",
                            "client_last_name": "",
                            "service_code": "",
                            "stateId": "",
                            "jurisdictionId": "",
                            "authorization": "",
                            "timezone": props.timeZone,
                            "startDate": "",
                            "endDate": "",
                            "client_medicaidId": "",
                            "client_payerID": "",
                            "client_idendifier": "",
                            "payer_information": "",
                            "clientid": ""
                        },
                        "active": true
                    }]

                    const response = await apiProvider.post("track/mobile/v1/allgeo/createCustomers", payload)
                    if (response.status === 200) {
                        props.setToastMsg({ msg: `${data.siteName} has been added successfully`, type: "msgSuccess" })
                        props.closePopup()
                        setTimeout(() => {
                            props.setShowG2Popup(true)
                        }, 2000)
                    } else {
                        props.setShowLoader(false)
                        props.setToastMsg({ msg: response.message, type: "msgError" })
                    }
                }

            } else {              

                let formData = new FormData()
                formData.append('file', file)

                const response = await apiProvider.formPost("track/mobile/v1/allgeo/uploadCustomers", formData)

                if (response.status === 200) {
                    props.setToastMsg({ msg: 'Bulk addition of multiple Job-sites is completed successfully', type: "msgSuccess" })
                    props.closePopup()
                } else if (+response.status === 422) {
                    const { knownColumns, unknownColumn } = response;

                    props.setMode({
                        type: 'uploadError',
                        data: {
                            file,
                            validColumns: knownColumns,
                            misMatchedColumns: unknownColumn,
                            validationError: false,
                            uploadUrl: "track/mobile/v1/allgeo/uploadCustomers"
                        },
                        hasPrev: false
                    })

                    props.closePopup()

                } else {
                    props.setShowLoader(false)
                    props.setToastMsg({ msg: response.message, type: "msgError" })
                }
            }
        } catch (er) {
            console.log(er.message);
        } finally {
            props.setShowLoader(false)
        }
    }

    const handleFileUpload = e => {
        setError(false)
        const allowedFiletypes = ['.csv', '.xlsx', '.xls', 'text/csv', 'text/xlsx', 'application/csv', 'text/comma-separated-values', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']

        if (e.dataTransfer) {
            const file = e.dataTransfer.files[0];

            if (allowedFiletypes.includes(file.type)) {
                setIsFormComplete(true)
                setFile(file)
            } else {
                setError(true)
            }
        } else {
            const file = e.target.files[0];

            if (allowedFiletypes.includes(file.type)) {
                setIsFormComplete(true)
                setFile(file)
            } else {
                setError(true)
            }
        }
    }

    const handleFileSize = () => {

        if (file) {
            let unit = 'b';
            let size = file.size;

            if (size >= 1024 * 1024) {
                size = size / Math.pow(1024, 2);
                unit = 'mb';
            } else if (size >= 1024) {
                size = size / 1024;
                unit = 'kb';
            }

            return `${(Math.round(size * 100) / 100)} ${unit}`;
        }
    }

    const jobsiteLabel = getAccountLabel('jobsite');

    return (
        <div className={`popupWrap ${activeMenu === 'single' ? 'medPopup' : 'smlPopup'} active`}>
            <div className="closePopup" onClick={props.closePopup}>Close</div>
            <div className="popupHeading">Add New {jobsiteLabel}</div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="popupContent">
                    <div className="popupTabMenu">
                        <ul>
                            <li className={`${activeMenu === 'single' ? 'active' : ''}`} onClick={() => toggleMenu("single")}>Individual</li>
                            <li className={`${activeMenu === 'multiple' ? 'active' : ''}`} onClick={() => toggleMenu("multiple")}>Multiple</li>
                        </ul>
                    </div>
                    {
                        activeMenu === 'single'
                            ?
                            <IndividualMenu
                                register={register}
                                setValue={setValue}
                                errors={errors}
                                skillsData={skillsData}
                                earningData={earningData}
                                jobSiteList={jobSiteList}
                                unregister={unregister}
                                addressType={addressType}
                                setAddressType={setAddressType}
                                setSelectedJobSite={setSelectedJobSite}
                                geoZone={geoZone}
                                setGeoZone={setGeoZone}
                                advanceSetupExpand={advanceSetupExpand}
                                setAdvanceSetupExpand={setAdvanceSetupExpand}
                                radiusUnit={props.radiusUnit}
                                isFormComplete={isFormComplete}
                                setIsFormComplete={setIsFormComplete}
                            />
                            : <MultipleMenu
                                file={file}
                                setFile={setFile}
                                handleFileSize={handleFileSize}
                                handleFileUpload={handleFileUpload}
                                error={error}
                                setIsFormComplete={setIsFormComplete}
                                jobsiteLabel={jobsiteLabel}
                            />
                    }
                </div>
                <div className="popupBtnWrap flexJCenter">
                    <button className="btnStyle okBtn" disabled={!isFormComplete}>Add</button>
                </div>
            </form>
        </div>
    )
}

export default AddNewPopup