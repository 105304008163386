import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react'
import { GoogleMap, Marker, Circle, Polygon } from '@react-google-maps/api'
import PolygonGeozone from './PolygonGeozone'
import CircleGeozone from './CircleGeozone'

const defaultOptions = {
    strokeOpacity: 0.5,
    strokeWeight: 3,
    clickable: false,
    draggable: false,
    // editable: false,
    visible: true
}

const closeOptions = {
    ...defaultOptions,
    zIndex: 3,
    fillOpacity: 0.05,
    strokeColor: '#8BC34A'
}

const middleOptions = {
    ...defaultOptions,
    zIndex: 2,
    fillOpacity: 0.05,
    strokeColor: '#FBC02D'
}

const farOptions = {
    ...defaultOptions,
    zIndex: 1,
    fillOpacity: 0.05,
    strokeColor: 'blue'
}

const MARKER_COLOR = [
    "http://labs.google.com/ridefinder/images/mm_20_blue.png",
    'http://labs.google.com/ridefinder/images/mm_20_gray.png',
    'http://labs.google.com/ridefinder/images/mm_20_green.png',
    'http://labs.google.com/ridefinder/images/mm_20_red.png',
    "http://labs.google.com/ridefinder/images/mm_20_blue.png",
    'http://labs.google.com/ridefinder/images/mm_20_gray.png',
    'http://labs.google.com/ridefinder/images/mm_20_green.png',
    'http://labs.google.com/ridefinder/images/mm_20_red.png'
];

const geoZoneTypes = {
    CIRCLE: 'Circle',
    POLYGON: 'Polygon'
}

const modeType = {
    VIEW: 'view',
    EDIT: 'edit',
    MAP_VIEW: 'mapView'
}

const Map = ({ mode, geoZoneType, zoneRadius, latLngArray, height, zoneColor, setCursor, jobSiteData, setJobSiteData, multipleCirclesLatLngArray = [], multiplePolygonLatLngArray = [] }) => {

    const [map, setMap] = useState(null)
    const [center, setCenter] = useState({
        lat: 0,
        lng: 0
    })

    const [radius, setRadius] = useState(0) //in meters

    const [path, setPath] = useState([])
    const [zoom, setZoom] = useState(5)

    const circleRef = useRef(null)
    const polygonRef = useRef(null);
    const listenersRef = useRef([]);

    const bounds = useMemo(() => new window.google.maps.LatLngBounds(), []);

    // Call setPath with new edited path
    const onEdit = useCallback(() => {
        let newCoordinates = []
        if (polygonRef.current) {
            const nextPath = polygonRef.current
                .getPath()
                .getArray()
                .map(latLng => {
                    return { lat: latLng.lat(), lng: latLng.lng() };
                });
            setPath(nextPath);
            for (let i = 0; i < nextPath.length; i++) {
                newCoordinates.push({
                    lat: `trackMapZoneLatitude_${i}`,
                    latValue: nextPath[i].lat,
                    lon: `trackMapZoneLongitude_${i}`,
                    lonValue: nextPath[i].lng
                })
            }

            let updatedJobSiteData = {
                ...jobSiteData,
                geozone: {
                    ...jobSiteData.geozone,
                    latlanList: [
                        ...newCoordinates
                    ]
                },
                isChanged: true
            };
            setJobSiteData(updatedJobSiteData)
        }
    }, [setPath]);

    // Bind refs to current Polygon and listeners
    const onLoad = useCallback(
        polygon => {
            polygonRef.current = polygon;
            const path = polygon.getPath();
            listenersRef.current.push(
                path.addListener("set_at", onEdit),
                path.addListener("insert_at", onEdit),
                path.addListener("remove_at", onEdit)
            );
        },
        [onEdit]
    );

    // Clean up refs
    const onUnmount = useCallback(() => {
        listenersRef.current.forEach(lis => lis.remove());
        polygonRef.current = null;
    }, []);

    const handleMarkerDrag = useCallback((e, jobSiteDataParam) => {

        const { lat, lng } = e.latLng;

        setCenter({
            lat: lat(),
            lng: lng()
        })

        let newCoordinates = [];
        newCoordinates.push({
            lat: `trackMapZoneLatitude_0`,
            latValue: lat(),
            lon: `trackMapZoneLongitude_0`,
            lonValue: lng()
        })

        let updatedJobSiteData = {
            ...jobSiteDataParam,
            geozone: {
                ...jobSiteDataParam.geozone,
                latlanList: [
                    ...newCoordinates
                ]
            },
            isChanged: true
        };

        setJobSiteData(updatedJobSiteData)
    }, [])

    const getAllCoordinateCenter = useCallback(path => {
        const pos = {
            lat: 0,
            lng: 0
        }

        pos.lat = path.reduce((sum, curr) => sum + curr.lat, 0) / path.length;
        pos.lng = path.reduce((sum, curr) => sum + curr.lng, 0) / path.length;

        return pos
    }, [path])

    const handlePolygonMarkerDrag = useCallback((e, ind) => {
        const { lat, lng } = e.latLng;
        const updatedPath = path.map((p, i) => {
            if (i === ind) return {
                lat: lat(),
                lng: lng()
            }
            return p
        })
        setCenter(getAllCoordinateCenter(updatedPath));
        setPath(updatedPath);
        let updatedCoord = jobSiteData.geozone.latlanList.map((ele, index) => {
            if (index === ind) {
                return Object.assign({}, ele, { 'latValue': lat(), 'lonValue': lng() })
            }
            return ele
        })

        let updatedJobSiteData = {
            ...jobSiteData,
            geozone: {
                ...jobSiteData.geozone,
                latlanList: [
                    ...updatedCoord
                ]
            },
            isChanged: true
        };
        setJobSiteData(updatedJobSiteData)
        setCenter(getAllCoordinateCenter(updatedPath))
        setPath(updatedPath)
    }, [path])

    function haversine_distance(mk1, mk2) {
        // Radius of the Earth in miles is 3958.9
        const R = 6371.0710 // Radius of the Earth in kiloMeter
        const rlat1 = mk1.lat * (Math.PI / 180); // Convert degrees to radians
        const rlat2 = mk2.lat * (Math.PI / 180); // Convert degrees to radians
        const difflat = rlat2 - rlat1; // Radian difference (latitudes)
        const difflon = (mk2.lng - mk1.lng) * (Math.PI / 180); // Radian difference (longitudes)

        return 2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2) + Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)));
    }

    const getPolygonCoords = point => {
        const lastPoint = center
        const currPoint = point;

        const deltaLat = currPoint.lat() - lastPoint.lat;
        const deltaLng = currPoint.lng() - lastPoint.lng;

        const updatedPath = []
        let newCoordinates = []

        for (let i = 0; i < path.length; i++) {
            const markerPoint = path[i];

            const newPoint = new google.maps.LatLng({
                lat: markerPoint.lat + deltaLat,
                lng: markerPoint.lng + deltaLng
            })

            updatedPath.push({
                lat: newPoint.lat(),
                lng: newPoint.lng()
            })

            newCoordinates.push({
                lat: `trackMapZoneLatitude_${i}`,
                latValue: newPoint.lat(),
                lon: `trackMapZoneLongitude_${i}`,
                lonValue: newPoint.lng()
            })
        }

        setCenter(getAllCoordinateCenter(updatedPath));
        setPath(updatedPath);

        let updatedJobSiteData = {
            ...jobSiteData,
            geozone: {
                ...jobSiteData.geozone,
                latlanList: [
                    ...newCoordinates
                ]
            },
            isChanged: true
        };
        setJobSiteData(updatedJobSiteData)
    }

    const getRadiusMarkerCoord = useCallback((deg = 90) => {

        //Coordinates at distance radius from center of circle at 90 deg angle from North
        const radiusMarkerCoord = google.maps.geometry.spherical.computeOffset(center, radius, deg)

        return { lat: radiusMarkerCoord.lat(), lng: radiusMarkerCoord.lng() }
    }, [radius, center])

    useEffect(() => {
        if (zoneRadius > 0) {
            setRadius(+zoneRadius)
        }
    }, [zoneRadius])

    useEffect(() => {
        if (latLngArray.length > 2) {
            setPath(latLngArray)
        }
    }, [latLngArray])

    const onMapLoad = useCallback((map) => {
        setMap(map)
    }, [])

    useEffect(() => {
        if (map) {
            if (latLngArray.length === 1) {
                //creating a boundry for circle geoZone
                bounds.extend(getRadiusMarkerCoord(0))
                bounds.extend(getRadiusMarkerCoord(90))
                bounds.extend(getRadiusMarkerCoord(180))
                bounds.extend(getRadiusMarkerCoord(270))
            } else {
                latLngArray.forEach(pos => {
                    bounds.extend(pos)
                })
            }
            map.fitBounds(bounds)
        }
    }, [radius, map])

    useEffect(() => {
        if (mode !== modeType.MAP_VIEW) {
            setRadius(+zoneRadius)
            setCenter(latLngArray.length === 1 ? latLngArray[0] : getAllCoordinateCenter(latLngArray))
            if (latLngArray.length > 2) {
                setPath(latLngArray)
            }
        } else {
            setCenter(getAllCoordinateCenter(latLngArray))

            if (zoneRadius) {
                setRadius(+zoneRadius)
            }
        }
    }, [])

    return (
        <>
            <GoogleMap
                zoom={zoom}
                center={center}
                mapContainerStyle={{
                    width: '100%',
                    height: height
                }}
                onMouseMove={(e) => {
                    if (mode === modeType.EDIT) {
                        setCursor(`${(e.latLng.lat()).toFixed(5)}, ${(e.latLng.lng()).toFixed(5)}`)
                    }
                }}
                onLoad={onMapLoad}
            >
                {mode !== modeType.MAP_VIEW && <Marker                       // Map Center Marker
                    icon={MARKER_COLOR[0]}
                    position={center}
                    draggable={mode === 'edit' ? true : false}
                    onDragEnd={e => {
                        switch (geoZoneType) {
                            case geoZoneTypes.CIRCLE:
                                handleMarkerDrag(e, jobSiteData)
                                break;
                            case geoZoneTypes.POLYGON:
                                getPolygonCoords(e.latLng)
                                break
                            default:
                                break;
                        }
                    }}
                />}

                {mode !== modeType.MAP_VIEW &&
                    <Marker                       // Map Center Marker
                        icon={MARKER_COLOR[0]}
                        position={center}
                        draggable={mode === modeType.EDIT ? true : false}
                        onDragEnd={e => {
                            switch (geoZoneType) {
                                case geoZoneTypes.CIRCLE:
                                    handleMarkerDrag(e, jobSiteData)
                                    break;
                                case geoZoneTypes.POLYGON:
                                    getPolygonCoords(e.latLng)
                                    break
                                default:
                                    break;
                            }
                        }}
                    />
                }

                {geoZoneType === geoZoneTypes.CIRCLE &&
                    <>
                        <Circle
                            center={center}
                            ref={circleRef}
                            radius={radius}
                            options={{ ...farOptions, strokeColor: zoneColor, fillColor: zoneColor }}
                        />
                        {mode === modeType.EDIT && <Marker
                            position={getRadiusMarkerCoord()}
                            icon={MARKER_COLOR[1]}
                            draggable={true}
                            onDragEnd={e => {
                                const pos = {
                                    lat: e.latLng.lat(),
                                    lng: e.latLng.lng()
                                }
                                const d = haversine_distance(center, pos)
                                let updatedJobSiteData = {
                                    ...jobSiteData,
                                    geozone: {
                                        ...jobSiteData.geozone,
                                        zoneRadius: Math.ceil(d * 1000)
                                    },
                                    isChanged: true
                                };
                                setJobSiteData(updatedJobSiteData)
                            }}
                        />}
                    </>
                }
                {geoZoneType === geoZoneTypes.POLYGON &&
                    <>
                        <Polygon
                            editable={mode === modeType.EDIT ? true : false}
                            path={path}
                            onMouseUp={onEdit}
                            onLoad={onLoad}
                            onUnmount={onUnmount}
                            options={{
                                strokeColor: zoneColor,
                                strokeWeight: 2,
                                strokeOpacity: 1,
                                fillOpacity: 0.1
                            }}
                        />
                        {mode == modeType.EDIT &&
                            path.map((coord, ind) => (
                                <Marker
                                    position={coord}
                                    key={ind}
                                    icon={MARKER_COLOR[ind]}
                                    draggable={mode === modeType.EDIT ? true : false}
                                    onDragEnd={(e) => {
                                        handlePolygonMarkerDrag(e, ind);
                                    }}
                                    onRightClick={() => {
                                        if (mode !== modeType.EDIT) return
                                        const ind = path.findIndex(d => d === coord)
                                        if (ind > -1 && path.length > 3) {
                                            setPath(path.filter((_, i) => i !== ind))

                                            let updatedJobSiteData = {
                                                ...jobSiteData,
                                                geozone: {
                                                    ...jobSiteData.geozone,
                                                    latlanList: [
                                                        ...jobSiteData.geozone.latlanList
                                                    ]
                                                },
                                                isChanged: true
                                            };
                                            updatedJobSiteData.geozone.latlanList.splice(ind, 1);
                                            updatedJobSiteData.geozone.latlanList.forEach((ele, ind) => {
                                                let latName = ele.lat.split('_');
                                                let lonName = ele.lon.split('_');
                                                ele.lat = `${latName[0]}_${ind}`;
                                                ele.lon = `${lonName[0]}_${ind}`
                                            })
                                            setJobSiteData(updatedJobSiteData)
                                        }
                                    }}
                                />
                            ))
                        }
                    </>
                }
                {mode === modeType.MAP_VIEW &&
                    <>
                        {
                            multipleCirclesLatLngArray.map((circle, ind) => (
                                <CircleGeozone
                                    key={ind}
                                    center={circle.latlanList[0]}
                                    radius={circle.zoneRadius}
                                    options={farOptions}
                                    icon={MARKER_COLOR[0]}
                                    custName={circle.custName}
                                    geozoneID={circle.geozoneID}
                                    address={circle.address}
                                />
                            ))
                        }
                        {
                            multiplePolygonLatLngArray.map((polygon, ind) => (
                                <PolygonGeozone
                                    key={ind}
                                    path={polygon.latlanList}
                                    blueMarker={MARKER_COLOR[0]}
                                    custName={polygon.custName}
                                    geozoneID={polygon.geozoneID}
                                    address={polygon.address}
                                />
                            ))
                        }
                    </>
                }
            </GoogleMap>
        </>
    )
}

export default Map