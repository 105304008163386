import React from 'react';
import { getAccountLabel } from '../../../utils/getAccountLabel'

const TextPopup = ({ accountId, closePopup, openAddPopup,fromMonitorNew=false }) => {
    const employeeLabel = getAccountLabel('employee');
    return (
        <>
    {!!fromMonitorNew&&( <div className={`overlayemployees active`}></div>)}
            <div className="popupWrap smlPopup active">
                <div className="closePopup" onClick={closePopup} >Close</div>
                <div className="popupHeading"><span>Hi <strong>{accountId}</strong></span></div>
                <div className="popupContent">
                    <div className="popupTabCont">
                        <div className="alignCenter">
                            <p>Onboarding your team is the first step<br />
                                towards setting up your account</p>
                            <p>Let’s start by</p>
                        </div>
                    </div>
                </div>
                <div class="popupBtnWrap flexJCenter">
                    <button className="btnStyle" onClick={()=>{openAddPopup();closePopup()}}>Adding {employeeLabel}</button>
                </div>
            </div>
        </>
    )
}

export default TextPopup