import React, { useState } from "react";
import "./milesdata.css";
import ExpandIcon from "../../../../assets/images/expand-more.svg";
import RefreshButton from "../../../../assets/images/refresh-button.svg";
import MilesIcon from "../../../../assets/images/MILES DATA.svg";

import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { ExpandMilesDataModal } from "./ExpandMilesDataModal";
import SiteLoader from "../../../../components/common/SiteLoader";
import { getAccountLabel } from "../../../../utils";
import { TooltipKendo } from "../../common/Tooltip";

export const MilesData = ({
  handlePinned,
  handleUnPinned,
  getMilesData,
  currentTimeAndDateMiles,
  setCurrentTimeAndDateMiles,
  milesData,
  setMilesPaginateMetaData,
  milesBackupData,
  setMilesBackupData,
  setMilesData,
  milesPaginateMetaData,
}) => {
  const [showExpanMore, setShowExpanMore] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  console.log(milesData, "milesData");
  const employeeLabel = getAccountLabel("employee");

  const grid = (
    <Grid className="milestablediv outermilestable" data={milesData?.data}>
      <Column field="employeeName" title={`${employeeLabel} Name`} />
      <Column field="miles" title="Miles Covered" />
    </Grid>
  );

  const refreshMilesDate = () => {
    console.log("Refresh Miles Data");
    setCurrentTimeAndDateMiles(new Date());
    getMilesData(
      setCurrentTimeAndDateMiles,
      setMilesData,
      milesPaginateMetaData
    );
    setShowLoader(true);
    setTimeout(() => {
      setShowLoader(false);
    }, 1000);
  };
  const localTime = currentTimeAndDateMiles?.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  });
  const localDate = currentTimeAndDateMiles?.getDate();
  const localMonth = currentTimeAndDateMiles?.getMonth() + 1;
  const localYear = currentTimeAndDateMiles?.getFullYear();

  return (
    <div className="milesParentContainer card-padding kendoTblWrap">
      {showLoader && <SiteLoader isActive={showLoader} />}
      <div className="milesHeadingContainer">
        {/* <div style={{display:"flex"}}> */}

        {/* <div style={{marginRight:"6px" }}>
            <img src={MilesIcon} alt="" />
          </div>
          <p>
          Miles Data 
          </p> */}
        {/* </div> */}
        <p className="milesheadtag">
          <span style={{ marginRight: "6px", display: "flex" }}>
            <img src={MilesIcon} alt="" />
          </span>
          <span>Miles Data</span>
          <span className="refreshtext">
            Last Refreshed:
            <span>{`${localMonth}/${localDate}/${localYear}`}</span>
            <span>{localTime}</span>
          </span>
        </p>
        <div className="miles-head-box">
          <TooltipKendo>
            <img
              onClick={() => setShowExpanMore(true)}
              style={{ cursor: "pointer" }}
              src={ExpandIcon}
              alt="Expand Icon"
              title="Expand View"
            />
          </TooltipKendo>
          <TooltipKendo>
            <img
              style={{ cursor: "pointer" }}
              src={RefreshButton}
              alt="Refresh Button"
              role="button"
              onClick={refreshMilesDate}
              title="Refresh"
            />
          </TooltipKendo>
        </div>
      </div>
      <div className="milesCalculate">
        <div>
          <p>
            <span className="bigcount">{milesData?.totalMilesdriven}</span>
            {/* <span className="updowncount">+2</span> */}
          </p>
          <p className="calculatetext">Total Miles Driven</p>
        </div>
        <div>
          <p>
            <span className="bigcount">{milesData?.avgMilesDriven}</span>
            {/* <span className="updowncount">-1</span> */}
          </p>
          <p className="calculatetext">Average Miles Driven</p>
        </div>
        <div>
          <p>
            {/* <span className="bigcount">95</span>
            <span className="updowncount">+8</span> */}
          </p>
          {/* <p className="calculatetext">Most Miles Driven</p> */}
        </div>
      </div>
      <div>
        <p className="milesheadtag">{employeeLabel} Miles</p>
        {grid}
      </div>
      {!!showExpanMore && (
        <ExpandMilesDataModal
          handlePinned={handlePinned}
          handleUnPinned={handleUnPinned}
          milesData={milesData}
          closePopup={() => setShowExpanMore(false)}
          setMilesPaginateMetaData={setMilesPaginateMetaData}
          milesBackupData={milesBackupData}
          setMilesBackupData={setMilesBackupData}
          showExpanMore={showExpanMore}
          milesPaginateMetaData={milesPaginateMetaData}
          currentTimeAndDateMiles={currentTimeAndDateMiles}
        />
      )}
    </div>
  );
};
