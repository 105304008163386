import React from "react";
import { EmployeeAppData } from "./EmployeeAndAppData";
import { NotificationAlert } from "../common/NotificationAndAlerts";
import { MonitorMapLocation } from "./MapAndLocation";
import { MilesData } from "./MilesData/MilesData";
import { TimeClockData, TimeClockReport } from "../TimeClock";

export const MileageAndTracking = ({
  layoutItems,
  employeeData,
  handlePinned,
  handleUnPinned,
  setEmployeePaginateMetaData,
  employeePaginateMetaData,
  getMilesData,
  milesData,
  setMilesData,
  currentTimeAndDateAppEmp,
  setCurrentTimeAndDateAppEmp,
  currentTimeAndDateMiles,
  setCurrentTimeAndDateMiles,
  currentTimeAndDateAlertsNoti,
  setCurrentTimeAndDateAlertsNoti,
  setMilesPaginateMetaData,
  notificationAndAlertMetaData,
  alertNotificationData,
  msgTemplates,
  setShowLoader,
  setAlertNotificationData,
  setShowLoaderNotification,
  showLoaderNotification,
  accessLevel,
  fetchEmployeeAppData,
  setToastMsg,
  allEmployeeList,
  breadcrumbData,
  setBreadcrumbData,
  milesBackupData,
  setMilesBackupData,
  setEmployeeTilesData,
  setNotificationAndAlertMetaData,
  milesPaginateMetaData,
  employeeLabel,

  setCurrentTimeAndDateTimeClock,
  currentTimeAndDateTimeClock,
  timeClockData,
  setShowExpanMore,
  handleGetTimeClockReport,
  setTimeClockData,
  showLoader,
  setParams,
  params
}) => {
  console.log(employeeLabel, "layoutItemslayoutItems");
  return layoutItems.map((item) => {
    if (!item.show) {
      return null;
    }

    let text = item?.text?.includes("App Status")
      ? `${employeeLabel} App Status`
      : item?.text;

    switch (text) {
      case `${employeeLabel} App Status`:
        return (
          <EmployeeAppData
            employeeData={employeeData}
            setEmployeePaginateMetaData={setEmployeePaginateMetaData}
            employeePaginateMetaData={employeePaginateMetaData}
            currentTimeAndDate={currentTimeAndDateAppEmp}
            setCurrentTimeAndDate={setCurrentTimeAndDateAppEmp}
            setShowExpanMore={setShowExpanMore}
            setEmployeeTilesData={setEmployeeTilesData}
          />
        );
      case "Map and Location":
        return (
          <MonitorMapLocation
            key={item.id}
            handlePinned={handlePinned}
            handleUnPinned={handleUnPinned}
            employeeData={employeeData}
            fetchEmployeeAppData={fetchEmployeeAppData}
            setToastMsg={setToastMsg}
            allEmployeeList={allEmployeeList}
            breadcrumbData={breadcrumbData}
            setBreadcrumbData={setBreadcrumbData}
          />
        );
      case "Notifications and Alerts":
        return (
          <NotificationAlert
            key={item.id}
            handlePinned={handlePinned}
            handleUnPinned={handleUnPinned}
            currentTimeAndDateAlertsNoti={currentTimeAndDateAlertsNoti}
            setCurrentTimeAndDateAlertsNoti={setCurrentTimeAndDateAlertsNoti}
            notificationAndAlertMetaData={notificationAndAlertMetaData}
            alertNotificationData={alertNotificationData}
            msgTemplates={msgTemplates}
            employeeData={allEmployeeList}
            setShowMainLoader={setShowLoader}
            setAlertNotificationData={setAlertNotificationData}
            setShowLoaderNotification={setShowLoaderNotification}
            showLoaderNotification={showLoaderNotification}
            accessLevel={accessLevel}
            setNotificationAndAlertMetaData={setNotificationAndAlertMetaData}
          />
        );
      case "Miles Data":
        return (
          <MilesData
            getMilesData={getMilesData}
            key={item.id}
            handlePinned={handlePinned}
            handleUnPinned={handleUnPinned}
            milesData={milesData}
            setMilesData={setMilesData}
            currentTimeAndDateMiles={currentTimeAndDateMiles}
            setCurrentTimeAndDateMiles={setCurrentTimeAndDateMiles}
            setMilesPaginateMetaData={setMilesPaginateMetaData}
            milesBackupData={milesBackupData}
            setMilesBackupData={setMilesBackupData}
            milesPaginateMetaData={milesPaginateMetaData}
          />
        );
        case "Time Clock Data":
        return(
          <TimeClockData
          currentTimeAndDateTimeClock={currentTimeAndDateTimeClock}
          setCurrentTimeAndDateTimeClock={
            setCurrentTimeAndDateTimeClock
          }
          setShowExpanMore={setShowExpanMore}
          timeClockData={timeClockData}
          handleGetTimeClockReport={handleGetTimeClockReport}
          setShowLoader={setShowLoader}
          showLoader={showLoader}
        />
        );
        case "Time Clock":
        return(
          <TimeClockReport
          setShowLoader={setShowLoader}
          showLoader={showLoader}
          timeClockData={timeClockData}
          setTimeClockData={setTimeClockData}
          handleGetTimeClockReport={handleGetTimeClockReport}
          setParams={setParams}
          params={params}
        />
        );
      default:
        return null;
    }
  });
};
