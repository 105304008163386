import React from "react"
import { TimePicker } from "@progress/kendo-react-dateinputs"
import { CustomPopup } from "../../../common/PopUp"
import { reportRanges, assignTypes, reportTypes } from "../reportSchedulerUtils"
import { timeZones } from "../../../common/utils"
import MultiSelectDropdown from "../../../common/dropdown/MultiSelectDropdown"
import { getAccountLabel } from "../../../../utils/getAccountLabel"

const Form1 = ({ register, setValue, watch, reportForData, reportTypeData, setToastMsg, timeFormat, screenType }) => {

    const handleTimeChange = (type, event) => {
        const startTime = watch('reportFromTime').getTime()
        const endTime = watch('reportToTime').getTime()
        const msg = 'Start time must be less than end time'

        if (type === 0) {
            if (event.value.getTime() > endTime) {
                setToastMsg({ type: "", msg })
                return
            }

            setValue("reportFromTime", event.value, { shouldDirty: true })
        } else {
            if (startTime > event.value.getTime()) {
                setToastMsg({ type: "", msg })
                return
            }

            setValue("reportToTime", event.value, { shouldDirty: true })
        }
    }

    const selectReportsToSend = e => {
        setValue('selectedReport', e.value, { shouldDirty: true })
    }

    const groupLabel = getAccountLabel('group');
    const employeeLabel = getAccountLabel('employee');

    return <>
        <div className="popupContent twoColPopContent">
            <div className="rowBlk">
                <div className="colBlk6 fldWrap">
                    <label className="withHelpTxt">Scheduler ID</label>
                    <input type="text" readOnly={screenType === 'edit'} {...register('scheduleId', { onChange: (e) => setValue('scheduleId', (e.target.value).replace(/[^a-zA-Z0-9_-]/gi, "")) })} />
                </div>
                <div className="colBlk6 fldWrap">
                    <label>Active</label>
                    <div className="customCheck yesNo">
                        <input type="checkbox" id="StatusActive" {...register('active')} />
                        <label className="customCheckLabel" htmlFor="StatusActive">yesNo</label>
                    </div>
                </div>
            </div>
            <div className="twoColBlk">
                <div className="leftCol">Report Type</div>
                <div className="rightCol">
                    <div className="highlightedSection">
                        <label>Type of Report</label>
                        <div className="customControls fldWrap">
                            <div className="customRadio">
                                <input type="radio" id="Standard" name="ReportType" value={reportTypes.STANDARD} {...register('reportType', { onChange: () => setValue('selectedReport', []) })} />
                                <label htmlFor="Standard">Standard</label>
                            </div>
                            <div className="customRadio">
                                <input type="radio" id="Custom" name="ReportType" value={reportTypes.CUSTOM} {...register('reportType')} />
                                <label htmlFor="Custom">Custom</label>
                            </div>
                        </div>
                        <div className="fldWrap">
                            <label>Reports to Send</label>
                            <div>
                                {reportTypeData
                                    &&
                                    (watch('reportType') == reportTypes.CUSTOM
                                        ?
                                        <MultiSelectDropdown
                                            data={reportTypeData[reportTypes.CUSTOM]}
                                            value={watch('selectedReport')}
                                            onChange={selectReportsToSend}
                                        />
                                        :
                                        <MultiSelectDropdown
                                            data={reportTypeData[reportTypes.STANDARD]}
                                            value={watch('selectedReport')}
                                            onChange={selectReportsToSend}
                                        />)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="twoColBlk">
                <div className="leftCol">This report will list</div>
                <div className="rightCol">
                    <div className="highlightedSection">
                        <div className="customControls fldWrap">
                            <div className="customRadio">
                                <input type="radio" id="Group" value={assignTypes.GROUP} name="reportList" {...register('assignType')} />
                                <label htmlFor="Group">{groupLabel}</label>
                            </div>
                            <div className="customRadio">
                                <input type="radio" id="Employee" value={assignTypes.EMPLOYEE} name="reportList" {...register('assignType')} />
                                <label htmlFor="Employee">{employeeLabel}</label>
                            </div>
                        </div>
                        <div className="fldWrap">
                            <label>Select {watch('assignType') === assignTypes.GROUP ? groupLabel : employeeLabel}</label>
                            {
                                watch('assignType') === assignTypes.GROUP ?
                                    <select className="fldWidthMed" {...register('scheduleForGrp')} value={watch('scheduleForGrp')}>
                                        {
                                            reportForData?.groups?.map((grp, ind) => (
                                                <option key={ind} value={grp.value} >{grp.label}</option>
                                            ))
                                        }
                                    </select>
                                    :
                                    <select className="fldWidthMed" {...register('scheduleForEmp')} value={watch('scheduleForEmp')} >
                                        {
                                            reportForData?.employees?.map((emp, ind) => (
                                                <option key={ind} value={emp.value} >{emp.label}</option>
                                            ))
                                        }
                                    </select>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="twoColBlk">
                <div className="leftCol">Range of the report</div>
                <div className="rightCol">
                    <div className="highlightedSection">
                        <label>Report Range</label>
                        <div className="customControls fldWrap">
                            <div className="customRadio">
                                <input type="radio" id="TimeRange" name="ReportRange" value={reportRanges.TIME_RANGE} {...register('reportRange')} />
                                <label htmlFor="TimeRange">Time Range</label>
                            </div>
                            <div className="customRadio">
                                <input type="radio" id="Hours" name="ReportRange" value={reportRanges.HOURS} {...register('reportRange')} />
                                <label htmlFor="Hours">Hours</label>
                            </div>
                            <div className="customRadio">
                                <input type="radio" id="Days" name="ReportRange" value={reportRanges.DAYS} {...register('reportRange')} />
                                <label htmlFor="Days">Days</label>
                            </div>
                        </div>
                        {
                            watch('reportRange') === reportRanges.TIME_RANGE &&
                            <div className="rowBlk">
                                <div className="colBlk4">
                                    <div className="fldWrap">
                                        <label>Start Time</label>
                                        <div>
                                            <TimePicker
                                                popup={CustomPopup}
                                                value={watch('reportFromTime')}
                                                onChange={(e) => handleTimeChange(0, e)}
                                                format={timeFormat}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="colBlk4">
                                    <div className="fldWrap">
                                        <label>End Time</label>
                                        <div>
                                            <TimePicker
                                                popup={CustomPopup}
                                                value={watch('reportToTime')}
                                                onChange={(e) => handleTimeChange(1, e)}
                                                format={timeFormat}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="colBlk4">
                                    <div className="fldWrap">
                                        <label>Select Timezone</label>
                                        <select {...register('timezone')} >
                                            {
                                                timeZones.map((t, ind) => (
                                                    <option key={ind} value={t.value} >{t.label}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            watch('reportRange') === reportRanges.HOURS &&
                            <div className="fldWithSubTxt">
                                <span>Last</span>
                                <input type="number" min={1} className="numberFld" {...register('reportLastHrs')} />
                                <span>hours</span>
                            </div>
                        }

                        {
                            watch('reportRange') === reportRanges.DAYS &&
                            <div className="fldWithSubTxt">
                                <span>Last</span>
                                <input type="number" min={1} className="numberFld" {...register('reportLastDays')} />
                                <span>days</span>
                            </div>
                        }

                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Form1