import React from "react";
import { PinnedLayoutBox } from "../PinnedLayout/PinnedLayoutBox";
import NotificationIcon from "../../../../assets/images/Notifications and alerts.svg";
import { NotificationAlertsView } from "./NotificationAlertsView";
import PinFilledIcon from "../../../../assets/images/pin-fill-icon.svg";
// import { notificationData } from "../../utils";

export const NotificationAlertPinnedView = ({
  handlePinned,
  handleUnPinned,
  notificationData,
  setCurrentTimeAndDateAlertsNoti,
  notificationAndAlertMetaData,
  setAlertNotificationData,
  setNotificationAndAlertMetaData,
}) => {
  return (
    <PinnedLayoutBox>
      <div className="employeeHeadingContainer">
        <p style={{display:"flex"}} className="employeeheadtag">
        <span style={{marginRight:6 }}>
            <img style={{paddingTop:2}} src={NotificationIcon} alt="" />
          </span>
          Alerts and Notifications
          <span className="refreshtext">
            Last Refreshed:
            <span>12:12:24</span>
            <span>08:00:00 AM</span>
          </span>
        </p>
        <div className="employee-head-box">
          <img
            style={{ cursor: "pointer" }}
            onClick={handleUnPinned}
            src={PinFilledIcon}
            alt=""
          />
        </div>
      </div>
      <div className="employeeparentBox pinnedbox parent-border">
        <NotificationAlertsView
          notificationData={notificationData}
          setCurrentTimeAndDateAlertsNoti={setCurrentTimeAndDateAlertsNoti}
          notificationAndAlertMetaData={notificationAndAlertMetaData}
          setAlertNotificationData={setAlertNotificationData}
          setNotificationAndAlertMetaData={setNotificationAndAlertMetaData}
        />
      </div>
    </PinnedLayoutBox>
  );
};
