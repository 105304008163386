import React from 'react';
import { DropDownList } from "@progress/kendo-react-dropdowns";

export const AddDeleteRows = ({ group, selectedGroup, handleGroupDropDownChange, orderType, handleOrderTypeChange, addNewRow, deleteRow }) => {
    return (
        <div className="line_control">
            <div className="control_left">
                <div className="field_wrap">
                    <label>Created For</label>
                    <span>
                        <DropDownList
                            data={group}
                            textField="label"
                            id='big_field'
                            dataItemKey="value"
                            value={selectedGroup}
                            onChange={handleGroupDropDownChange}
                        />
                    </span>
                </div>
                <div className="field_wrap">
                    <label>Type</label>
                    <div className="custom_fields">
                        {['recurring', 'non-recurring'].map((type) => (
                            <div key={type} className="radio_button">
                                <input
                                    type="radio"
                                    id={type}
                                    name="orderType"
                                    value={type}
                                    checked={orderType === type}
                                    onChange={handleOrderTypeChange}
                                />
                                <label htmlFor={type}>{type.charAt(0).toUpperCase() + type.slice(1)}</label>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="control_right">
                <div className="field_wrap">
                    <div className="button_wrapper">
                        <button
                            className={`modal_btn_style okBtn btn_add ${selectedGroup.value !== "employee" ? "btn_add_disbl modal_btn_disable" : ""}`}
                            disabled={selectedGroup.value !== "employee"}
                            onClick={addNewRow}
                        >
                            Add New
                        </button>
                        <button className="modal_btn_style btn4 btn_del" onClick={deleteRow}>Delete</button>
                    </div>
                </div>
            </div>
        </div>
    );
};