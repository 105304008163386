import React, { Fragment, useRef, useEffect, useState } from 'react'
import { apiProvider } from '../../../../services/api/provider'
import KendoGrid from '../../../common/kendo/KendoGrid'
import { distanceAuditColumns, NO_RECORD_TEXT } from '../editReportUtils';
import { formatDate } from '@progress/kendo-intl';

const params = {
    EditTabValue: 'distance_audit',
    date_fr: formatDate(new Date(), 'yyyy/MM/dd'),
    date_to: formatDate(new Date(), 'yyyy/MM/dd'),
    reportFor: "all",
    actual_date: false
}

const DistanceAudit = ({ columnName, setShowLoader, accessLevel, dropdownData, setColumnList, columnList, setKendoDataState }) => {
    const globalStartDate = sessionStorage.getItem('EditReport:startDate')
    const globalEndDate = sessionStorage.getItem('EditReport:endDate')
    const reportForValues = sessionStorage.getItem('EditReport:reportFor')

    if (globalStartDate && globalEndDate) {
        params.date_fr = globalStartDate
        params.date_to = globalEndDate
        params.reportFor = reportForValues || ''
    }
    
    const [distanceAuditData, setDistanceAuditData] = useState(null)

    const childRef = useRef()

    const getDistanceAuditReports = async () => {
        try {
            setShowLoader(true)
            const response = await apiProvider.getAll('/track/mobile/v1/allgeo/loadDistanceAuditData', params)

            setDistanceAuditData(response)
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    const deleteReport = async data => {

        const payload = data.length ? data : [data.txnID]

        try {
            setShowLoader(true)
            const response = await apiProvider.removeObject('/track/mobile/v1/allgeo/deleteDistanceAudit', payload)
            if (data.length)
                response.message = "The selected records have been deleted successfully";
            else
                response.message = `Selected Report is deleted`

            return {
                status: response.StatusCode,
                message: response.message,
            };
        } catch (error) {
            console.log(error);
            throw new Error(error)
        } finally {
            setShowLoader(false)
        }
    }

    useEffect(() => {
        const updatedEditTimeClockColumns = distanceAuditColumns.map(item => {
            if (Object.keys(columnName).includes(item.field)) {
                return {
                    ...item,
                    title: columnName[item.field] || item.title
                };
            }
            return item;
        });

        setColumnList(updatedEditTimeClockColumns);

        getDistanceAuditReports()
    }, [])

    return (
        <Fragment>
            {
                distanceAuditData &&
                <KendoGrid
                    gridData={distanceAuditData}
                    setGridData={setDistanceAuditData}
                    columnData={columnList}
                    accessLevel={accessLevel}
                    ref={childRef}
                    module={"editReports"}
                    getAPiUrl={'/track/mobile/v1/allgeo/loadDistanceAuditData'}
                    requestParams={params}
                    deleteItem={deleteReport}
                    lockActionColumn={true}
                    otherData={{
                        actionColumnBtns: ['delete'],
                        subPage: "distanceAudit",
                        enableExportToPDF: true,
                        hideSaveCancelChanges: true,
                        ...dropdownData,
                        noRecordText: NO_RECORD_TEXT,
                        showExportDropdown: true,
                        setColumnList: setColumnList,
                        columnList: columnList,
                        setKendoDataState: setKendoDataState,
                        hideMenuCloumns: true
                    }}
                />
            }
        </Fragment>
    )
}

export default DistanceAudit