import { apiProvider } from "../../../../services/api/provider";
import { getGroupsData } from "../../../../components/common/apiFunctions";

export const handleFetchTimeclockSettings = async (
  setJobStage,
  setTimeclockStage,
  setGroups,
  setDropdowndata
) => {
  try {
    const jobStage = await apiProvider.getAll(
      "track/react/account/timeclockSettings"
    );
    setJobStage(jobStage);

    const groups = await getGroupsData();
    setGroups(groups);

    const options = [
      { label: "Active Devices", value: "all:all" },
      { label: "Tracking: Active", value: "Tracking:Active" },
      { label: "Tracking: Inactive", value: "Tracking:Inactive" },
      { label: "Suspended Devices", value: "ActiveStatus:Suspended" },
    ];
    
    const jobStageOptions = jobStage
      ?.filter((job) => job?.stage_name?.trim()?.length > 0)
      ?.map((job) => ({
        value: `JobStatus:${job.stage_id}`,
        label: job.stage_name,
      }));

    setTimeclockStage(jobStageOptions);

    const dropdownOptions = [
      ...options,
      { label: "Job Status: ", value: "", disabled: true },
      ...jobStageOptions,
      { label: "Groups: ", value: "", disabled: true },
      ...groups?.map((g) => ({ value: `Group:${g.value}`, label: g.label })),
    ];

    setDropdowndata(dropdownOptions);
  } catch (error) {
    console.error("Error fetching employee app data:", error);
  }
};
