import React, { useState, useEffect, useCallback } from 'react';
import { apiProvider } from '../services/api/provider';
import axios from 'axios';

let logoutTimer;

const AuthContext = React.createContext({
  token: '',
  isLoggedIn: false,
  login: (token, tokenEn, expirationTime, isParent) => { },
  childlogin: (token, tokenEn, expirationTime, isParent) => { },
  logout: () => { }
});

const calculateRemainingTime = (expirationTime) => {
  const currentTime = new Date().getTime();
  const adjExpirationTime = new Date(expirationTime).getTime();

  const remainingDuration = adjExpirationTime - currentTime;

  return remainingDuration;
};

const retrieveStoredToken = () => {
  const storedToken = localStorage.getItem('token');
  const storedTokenEn = localStorage.getItem('tokenEn');
  const storedExpirationDate = localStorage.getItem('expirationTime');

  const remainingTime = calculateRemainingTime(storedExpirationDate);

  if (remainingTime <= 3600000) { // less than 1 hour
    localStorage.removeItem('token');
    localStorage.removeItem('expirationTime');
    localStorage.removeItem('serversession');
    return null;
  }

  return {
    token: storedToken,
    tokenEn: storedTokenEn,
    duration: remainingTime,
  };
};

export const AuthContextProvider = (props) => {

  const tokenData = retrieveStoredToken();
  let initialToken;
  let initialTokenEn;
  if (tokenData) {
    initialToken = tokenData.token;
    initialTokenEn = tokenData.tokenEn;
  }

  const [token, setToken] = useState(initialToken);
  const [tokenEn, setTokenEn] = useState('');

  const userIsLoggedIn = !!token;

  const logoutHandler = useCallback(() => {
    setToken(null);
    localStorage.removeItem('token');
    localStorage.removeItem('tokenEn');
    localStorage.removeItem('expirationTime');
    localStorage.removeItem('revisit');
    localStorage.removeItem('chieldAccounts');
    localStorage.removeItem('menuItems');
    localStorage.removeItem('isParent');
    localStorage.removeItem('accountId');
    localStorage.removeItem('userName');
    localStorage.removeItem('parent');
    localStorage.removeItem('isBannerEnabled');
    localStorage.removeItem('bannerText')
    localStorage.removeItem('childAccountId')
    localStorage.removeItem('actSecurityType')
    localStorage.removeItem('userSecurityType')
    localStorage.removeItem('landingPage')
    localStorage.removeItem('childAccountsStored')
    localStorage.removeItem('serversession');
    localStorage.removeItem('accountLabel')
    localStorage.removeItem('serversession')
    localStorage.removeItem('isAdminLogin')
    localStorage.removeItem('g2ReviewInactive')
    localStorage.removeItem('hasG2Reviewed')
    localStorage.removeItem('isNewMonitorPage')
    localStorage.removeItem('employeeAppData')
    localStorage.removeItem('mapAndLocation')
    localStorage.removeItem('milesData')
    localStorage.removeItem('notificationsAndAlerts')
    localStorage.removeItem('timeClockData')
    localStorage.removeItem('timeClockReports')

    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
  }, []);

  const loginHandler = async (token, tokenEn, expirationTime, isParent, accountId, userName, isBannerEnabled, bannerText, userSecurityType, actSecurityType, landingPage, hasG2Reviewed,isNewMonitorPage) => {
    setToken(token);
    setTokenEn(tokenEn);
    localStorage.setItem('token', token);
    localStorage.setItem('tokenEn', tokenEn);
    localStorage.setItem('expirationTime', expirationTime);
    localStorage.setItem('isParent', isParent);
    localStorage.setItem('accountId', accountId);
    localStorage.setItem('userName', userName);
    localStorage.setItem('isBannerEnabled', isBannerEnabled);
    localStorage.setItem('bannerText', bannerText),
    localStorage.setItem('userSecurityType',userSecurityType),
    localStorage.setItem('actSecurityType',actSecurityType),
    localStorage.setItem('landingPage', landingPage)
    localStorage.setItem('hasG2Reviewed', hasG2Reviewed)
    // localStorage.setItem('isNewMonitorPage',isNewMonitorPage)

    let obj = {
      'token': token,
      'tokenEn': tokenEn,
      'expiry': expirationTime,
      'accountId': accountId,
      'userName': userName
    };
    localStorage.setItem('parent', JSON.stringify(obj));
    try {
      const axiosResponse = await axios.get(`${process.env.REACT_APP_API_URL}/track/react/account/generalSetting`, {
        headers: {
          Authorization: `Bearer${token}`, 
          'Content-Type': 'application/json',
        },
      });
    
      localStorage.setItem('accountLabel', JSON.stringify(axiosResponse.data.nomenclature));
      console.log(axiosResponse.data.report_units.g2ReviewEnabled, 'full')
      console.log(axiosResponse.data.report_units, 'half')

      localStorage.setItem('sysAdminG2Enabled', JSON.stringify(axiosResponse.data.report_units.g2ReviewEnabled))
    } catch (error) {
      console.error(error);
    }

    const getChildAccount = async () => {
      const fromLocalStorage = localStorage.getItem("chieldAccounts");
      if (fromLocalStorage) return fromLocalStorage;
      else if (isParent) {
        try {
          const response = await apiProvider.getAll(
            "track/react/v1/childaccounts"
          );

          if (response) {
            console.log("Child Account Response", response);
            return response;
          }
        } catch (error) {
          console.error("Error fetching child accounts:", error);
        }
      }
    };
    
    let childAccount = await getChildAccount();
    const isChildAccountsStored = localStorage.getItem("childAccountsStored");

    if (!isChildAccountsStored) {
      if (childAccount) {
        localStorage.setItem("chieldAccounts", JSON.stringify(childAccount));
        localStorage.setItem("childAccountsStored", "true");
      }
    }

    const remainingTime = calculateRemainingTime(expirationTime);

    logoutTimer = setTimeout(logoutHandler, remainingTime);
  };

  const childloginHandler = (token, tokenEn, expirationTime, accountId) => {
    setToken(token);
    setTokenEn(tokenEn);
    localStorage.setItem('token', token);
    localStorage.setItem('tokenEn', tokenEn);
    localStorage.setItem('expirationTime', expirationTime);
    localStorage.setItem('accountId', accountId);
    const remainingTime = calculateRemainingTime(expirationTime);
    logoutTimer = setTimeout(logoutHandler, remainingTime);
  };

  useEffect(() => {
    if (tokenData) {
      logoutTimer = setTimeout(logoutHandler, tokenData.duration);
    }
  }, [tokenData, logoutHandler]);

  const contextValue = {
    token: token,
    isLoggedIn: userIsLoggedIn,
    tokenEn: tokenEn,
    login: loginHandler,
    logout: logoutHandler,
    childlogin: childloginHandler
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {/* passing the value */}
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
