import React, { useState, useEffect } from "react";
import SettingIcon from "../../../assets/images/setting.svg";
import DragIndicator from "../../../assets/images/drag-indicator.svg";

export const ChangePageLayout = ({
  layoutItems, setLayoutItems,employeeLabel
}) => {
  useEffect(() => {
    localStorage.setItem("items", JSON.stringify(layoutItems));
  }, [layoutItems]);

  const handleDragStart = (event, index) => {
    event.dataTransfer.setData("text/plain", index);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event, newIndex) => {
    const oldIndex = parseInt(event.dataTransfer.getData("text/plain"));
    if (oldIndex !== newIndex) {
      const newItems = [...layoutItems];
      const draggedItem = newItems.splice(oldIndex, 1)[0];
      newItems.splice(newIndex, 0, draggedItem);
      setLayoutItems(newItems);
    }
  };

  const handleSave = () => {
    localStorage.setItem("items", JSON.stringify(layoutItems));
    console.log("Order saved:", layoutItems);
  };

  return (
    <div className="drag-dropdown-parent-box">
      <div className="monitorsecondbox">
        <img src={SettingIcon} alt="" />
        <span>Change Page Layout</span>
      </div>
      <div className="drag-dropdown">
        <ul>
          {layoutItems.map((item, index) => {
            if (!item.show) {
              return null;
            }

            let text = item.text.includes('App Status')?`${employeeLabel} App Status`:item.text;
            
            return <div  key={`layout-item-${item.id}`}>
              <li
                key={item.id}
                draggable={true}
                onDragStart={(event) => handleDragStart(event, index)}
                onDragOver={handleDragOver}
                onDrop={(event) => handleDrop(event, index)}
              >
                <img src={DragIndicator} role="presentation" />
                
                {text}
              </li>
            </div>
          })}
        </ul>
        {/* <button
          className="locationbuttondiv drag-save-btn"
          onClick={handleSave}
        >
          Save
        </button> */}
      </div>
    </div>
  );
};
