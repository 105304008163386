import React, { useState } from 'react'
import { apiProvider } from '../../../../services/api/provider'
import { base64ToBlob } from '../../../common/functions';
import useGeneralSettings from '../../../../hooks/useGeneralSettings';

const Email = ({ selectedReport, gridRef, setShowLoader, closePopup, setToastMsg, errorDetails, deviceAudit, auditReport, type }) => {


    const [email, setEmail] = useState('')
    const isValidEmail = /\S+@\S+\.\S+/.test(email)
    const { reportUnits } = useGeneralSettings();

    const sendReport = async () => {
        if (type === "errorDetailReport") {
            const columnHeadings = [
                "Time",
                "Service",
                "task",
                "Status",
                "Request",
                "Details",
                "Comment",
                "Correlation"
            ];
            try {
                setShowLoader(true)
                const csvContent = `${columnHeadings.join(',')}\n` + errorDetails.map(row => Object.values(row).join(',')).join('\n');
                const errorDetailsBlob = new Blob([csvContent], { type: 'text/csv' });
                const formData = new FormData()
                formData.append('file', errorDetailsBlob, 'error_details.csv')
                const response = await apiProvider.formPost(`/track/mobile/v1/allgeo/email?emailIds=${email}`, formData)
                if (+response.status === 200) {
                    setToastMsg({ msg: 'Error details sent successfully', type: "msgSuccess" })
                    closePopup()
                } else {
                    console.log("error")
                    setToastMsg({ msg: response.message, type: "msgError" })
                }
            } catch (error) {
                console.log(error);
            } finally {
                setShowLoader(false)
            }
        }
        if (type === "auditReport") {
            try {
                const calculateTotal = (data) => {
                    let totalCreated = 0;
                    let totalDeleted = 0;
                    let totalNet = 0;

                    data.forEach(row => {
                        totalCreated += parseInt(row.created || 0);
                        totalDeleted += parseInt(row.deleted || 0);
                        totalNet += parseInt(row.net || 0);
                    });

                    return ['Total', '', totalCreated, totalDeleted, totalNet, ''];
                }

                const arrayToCsv = (array) => {
                    if (!array || !array.length) return '';

                    const headers = Object.keys(array[0]);
                    const rows = array.map(obj => headers.map(header => obj[header]));

                    // Calculate total
                    let totalCreated = 0;
                    let totalDeleted = 0;
                    let totalNet = 0;
                    array.forEach(row => {
                        totalCreated += parseInt(row.created || 0);
                        totalDeleted += parseInt(row.deleted || 0);
                        totalNet += parseInt(row.net || 0);
                    });

                    // Convert rows to CSV format
                    const csvContent = [headers.join(','), ...rows.map(row => row.join(','))].join('\n');

                    return csvContent;
                }
                const deviceAuditCsv = arrayToCsv(deviceAudit);
                const auditReportCsv = arrayToCsv(auditReport);
                // Calculate totals
                const totalRow = calculateTotal(deviceAudit);

                // Add total row to deviceAuditCsv
                const deviceAuditWithTotal = `${deviceAuditCsv}\n${totalRow.join(',')}`;

                // Combine CSV data
                const combinedCsvContent = `Device Audit\n${deviceAuditWithTotal}\n\nAudit Report\n${auditReportCsv}`;

                // Create a blob from the combined CSV data
                const blob = new Blob([combinedCsvContent], { type: 'text/csv' });
                const reportName = "deviceAuditReport";
                const formData = new FormData()
                formData.append('file', blob, `${reportName}.csv`)

                const response = await apiProvider.formPost(`/track/mobile/v1/allgeo/email?emailIds=${email}`, formData)

                if (+response.status === 200) {
                    setToastMsg({ msg: 'Mail sent successfully', type: "msgSuccess" })
                    closePopup()
                } else {
                    setToastMsg({ msg: response.message, type: "msgError" })
                }

            } catch (error) {
                console.log(error);
            } finally {
                setShowLoader(false)
            }
        }
        else {
            try {
                setShowLoader(true)
                const { toDataURL } = gridRef.exportToExcelRef.current
                const data = gridRef.exportToExcelRef.current.props.data;
                function convertServiceSeconds(data, reportUnits) {
                    function processItems(items) {
                        if (!Array.isArray(items)) return;
                        items.forEach(item => {
                            if (item?.serviceSeconds !== undefined) {
                                const totalSeconds = item.serviceSeconds;

                                if (reportUnits?.minutes === 'hundredths') {
                                    const hours = (totalSeconds / 3600).toFixed(2);
                                    item.serviceSeconds = hours;
                                } else {
                                    const hours = Math.floor(totalSeconds / 3600);
                                    const minutes = Math.floor((totalSeconds % 3600) / 60);
                                    const seconds = totalSeconds % 60;
                                    item.serviceSeconds = `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
                                }
                            }
                            if (item?.elapsedTimeSec !== undefined) {
                                const totalSeconds = item.elapsedTimeSec;
                                if (reportUnits?.minutes === 'hundredths') {
                                    const hours = (totalSeconds / 3600).toFixed(2);
                                    item.elapsedTimeSec = hours;
                                } else {
                                    const hours = Math.floor(totalSeconds / 3600);
                                    const minutes = Math.floor((totalSeconds % 3600) / 60);
                                    const seconds = totalSeconds % 60;
                                    item.elapsedTimeSec = `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
                                }
                            }
                            if (item?.totalServiceSeconds !== undefined) {
                                const totalSeconds = item.totalServiceSeconds;
                                if (reportUnits?.minutes === 'hundredths') {
                                    const hours = (totalSeconds / 3600).toFixed(2);
                                    item.totalServiceSeconds = hours;
                                } else {
                                    const hours = Math.floor(totalSeconds / 3600);
                                    const minutes = Math.floor((totalSeconds % 3600) / 60);
                                    const seconds = totalSeconds % 60;
                                    item.totalServiceSeconds = `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
                                }
                            }
                            if (item?.totoalServiceHours !== undefined) {
                                const totalSeconds = item.totoalServiceHours;
                                if (reportUnits?.minutes === 'hundredths') {
                                    const hours = (totalSeconds / 3600).toFixed(2);
                                    item.totoalServiceHours = hours;
                                } else {
                                    const hours = Math.floor(totalSeconds / 3600);
                                    const minutes = Math.floor((totalSeconds % 3600) / 60);
                                    const seconds = totalSeconds % 60;
                                    item.totoalServiceHours = `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
                                }
                            }
                            if (item?.totalPunchServiceTime !== undefined) {
                                const totalSeconds = item.totalPunchServiceTime;
                                if (reportUnits?.minutes === 'hundredths') {
                                    const hours = (totalSeconds / 3600).toFixed(2);
                                    item.totalPunchServiceTime = hours;
                                } else {
                                    const hours = Math.floor(totalSeconds / 3600);
                                    const minutes = Math.floor((totalSeconds % 3600) / 60);
                                    const seconds = totalSeconds % 60;
                                    item.totalPunchServiceTime = `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
                                }
                            }
                            if (item?.doubleOverTimeHours !== undefined) {
                                const totalSeconds = item.doubleOverTimeHours;
                                if (reportUnits?.minutes === 'hundredths') {
                                    const hours = (totalSeconds / 3600).toFixed(2);
                                    item.doubleOverTimeHours = hours;
                                } else {
                                    const hours = Math.floor(totalSeconds / 3600);
                                    const minutes = Math.floor((totalSeconds % 3600) / 60);
                                    const seconds = totalSeconds % 60;
                                    item.doubleOverTimeHours = `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
                                }
                            }
                            if (item?.overTimeHours !== undefined) {
                                const totalSeconds = item.overTimeHours;
                                if (reportUnits?.minutes === 'hundredths') {
                                    const hours = (totalSeconds / 3600).toFixed(2);
                                    item.overTimeHours = hours;
                                } else {
                                    const hours = Math.floor(totalSeconds / 3600);
                                    const minutes = Math.floor((totalSeconds % 3600) / 60);
                                    const seconds = totalSeconds % 60;
                                    item.overTimeHours = `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
                                }
                            }
                            if (item?.regularServiceHours !== undefined) {
                                const totalSeconds = item.regularServiceHours;
                                if (reportUnits?.minutes === 'hundredths') {
                                    const hours = (totalSeconds / 3600).toFixed(2);
                                    item.regularServiceHours = hours;
                                } else {
                                    const hours = Math.floor(totalSeconds / 3600);
                                    const minutes = Math.floor((totalSeconds % 3600) / 60);
                                    const seconds = totalSeconds % 60;
                                    item.regularServiceHours = `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
                                }
                            }
                            if (Array.isArray(item?.items)) {
                                processItems(item.items);
                            }
                        });
                    }
                    processItems(data);
                }
                convertServiceSeconds(data, reportUnits);
                const dataURL = await toDataURL()
                const base64 = dataURL.split(";base64,")[1]
                const blob = base64ToBlob(base64, 'text/csv')
                const reportName = selectedReport.title;
                const formData = new FormData()
                formData.append('file', blob, `${reportName}.xlsx`)
                const response = await apiProvider.formPost(`/track/mobile/v1/allgeo/email?emailIds=${email}`, formData)
                if (+response.status === 200) {
                    setToastMsg({ msg: 'Mail sent successfully', type: "msgSuccess" })
                    closePopup()
                } else {
                    setToastMsg({ msg: response.message, type: "msgError" })
                }

            } catch (error) {
                console.log(error);
            } finally {
                setShowLoader(false)
            }
        }
    }

    return (
        <>
            <div className="popupWrap smlPopup active">
                <div className="closePopup" onClick={closePopup} >Close</div>
                <div className="popupHeading">Email Report</div>
                <div className="popupContent">
                    <div className={`fldWrap ${email.length > 0 && !isValidEmail ? 'errFld' : ''}`}>
                        <label>Send report to</label>
                        <input autoFocus type="text" placeholder="Enter email address" value={email} onChange={e => setEmail(e.target.value)} />
                    </div>
                </div>
                <div className="popupBtnWrap flexJCenter">
                    <button className="btnStyle" onClick={sendReport} disabled={!isValidEmail} >Send Report</button>
                </div>
            </div>
        </>
    )
}

export default Email