import React from 'react'
import './NoDataScreen.css'
import errorIcon from '../../../../assets/images/no-record-available.svg';

export const NoDataScreen = ({ scheduleType }) => {
    return (
        <div className="no-data-container">
            <img src={errorIcon} className="no-data-icon" />
            <div className="no-data-message">No {scheduleType} Schedules available for your account</div>
        </div>

    )
}
