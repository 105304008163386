import React, { useEffect, useState } from "react";
import "./timeClockData.css";
import ExpandIcon from "../../../../assets/images/expand-more.svg";
import RefreshButton from "../../../../assets/images/refresh-button.svg";
import SiteLoader from "../../../../components/common/SiteLoader";
import TimeClockDataIcon from "../../../../assets/images/Layer_1.svg";
import UserIcon from "../../../../assets/images/Users.svg";
import { TooltipKendo } from "../../common/Tooltip";
export const TimeClockData = ({
  setCurrentTimeAndDateTimeClock,
  currentTimeAndDateTimeClock,
  timeClockData,
  setShowExpanMore,
  handleGetTimeClockReport,
}) => {
  const [showLoader, setShowLoader] = useState(false);
  const [timeClockList, setTimeClockList] = useState([]);
  const [newTimeClockData, setNewTimeClockData] = useState([]);

  useEffect(() => {
    // handle getting timeclock data here and pass setCurrentTimeAndDateTimeClock when the api is called
    setCurrentTimeAndDateTimeClock(new Date());
  }, []);

  const refreshHandler = () => {
    setCurrentTimeAndDateTimeClock(new Date());
    setShowLoader(true);
    handleGetTimeClockReport(setShowLoader);
  };

  const localTime = currentTimeAndDateTimeClock?.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  });
  const localDate = currentTimeAndDateTimeClock?.getDate();
  const localMonth = currentTimeAndDateTimeClock?.getMonth() + 1;
  const localYear = currentTimeAndDateTimeClock?.getFullYear();

  const splitIntoChunks = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const chunks = splitIntoChunks(timeClockList, 4);

  let dayNotStarted = {
    count: timeClockData?.NotClockedIn,
    stageName: "Day Not Started",
    stageId: 0,
  };

  useEffect(() => {
    if (!!timeClockData) {
      setTimeClockList(
        [dayNotStarted, ...timeClockData.stageInfoList]?.map((item, index) => ({
          ...item,
          mode: index % 2 === 0 ? "even" : "odd",
        }))
      );
    }
  }, [timeClockData]);

  useEffect(() => {
    setNewTimeClockData(chunks);
  }, [timeClockList]);
  return (
    <div className="timeclockParentContainer kendoTblWrap">
      {showLoader && <SiteLoader isActive={showLoader} />}
      <div className="timeclockHeadingContainer">
        <p style={{ display: "flex" }} className="timeclockheadtag">
          <span style={{ marginRight: "6px" }}>
            <img src={TimeClockDataIcon} alt="" />
          </span>
          Time Clock Data
        </p>

        <div className="timeclock-head-box">
           <TooltipKendo>
              <img
                onClick={() => setShowExpanMore(true)}
                style={{ cursor: "pointer" }}
                src={ExpandIcon}
                alt="Expand Icon"
                title="Expand View"
              />
            </TooltipKendo>
            <TooltipKendo>
              <img
                style={{ cursor: "pointer" }}
                src={RefreshButton}
                alt="Refresh Button"
                 onClick={refreshHandler}
                title="Refresh"
                role="button"
              />
            </TooltipKendo>
        </div>
      </div>
      <div>
        <span style={{ marginBottom: "16.5px" }} className="refreshtext-timeclockdata">
          Last Refreshed:
          <span>{`${localMonth}/${localDate}/${localYear}`}</span>
          <span>{localTime}</span>
        </span>
      </div>
      <div
        style={{ paddingRight: newTimeClockData.length > 2 ? 5 : 0 }}
        className="timeClockCalculate timeclockscroll"
      >
        {newTimeClockData.map((chunk, chunkIndex) => (
          <div className={`parent-row width-${chunk.length}`} key={chunkIndex}>
            {chunk.map((data, index) => (
              <React.Fragment key={index}>
                <div className="inner-count-div">
                  <span className="newbigcount">{data?.count}</span>
                  <span className="user-text">
                    <img src={UserIcon} alt="" />{" "}
                    {data?.count > 1 ? "users" : "user"}
                  </span>
                  <span
                    className={`chip-text ${
                      data?.stageName === "SOS" ? "sos-border" : data?.mode
                    }`}
                  >
                    {data?.stageName}
                  </span>
                </div>
                {index + 1 !== chunk.length && (
                  <div
                    style={{
                      borderRight: "1px solid #CBE0EA",
                      width: 1,
                      height: 80,
                    }}
                  ></div>
                )}
              </React.Fragment>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
