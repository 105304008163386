import React, { useState, useEffect } from 'react'
import { apiProvider } from '../../../../services/api/provider';
import { useForm } from 'react-hook-form';
import { addWorkOrderColumnsNonRecurring, addWorkOrderColumnsRecurring } from '../utils/workOrderUtils';
import { defaultTrack, defaultTimeZone, defaultValues, defaultRows, csvErrorMsg, mandatoryFieldErrorMsg, addOnGroupData, invalidDateErrorMsg, deleteBtnErrorMsg } from './constant';
import { preparePayload, handleDeleteRow, checkDateValidity, checkMandatoryFields } from './helperFunctions'
import { BulkUploadWorkOrder } from './BulkUploadWorkOrder'
import { RenderColumns } from './RenderColumns'
import { ModalTabs } from './ModalTabs'
import { AddDeleteRows } from './AddDeleteRows'
import { getAccountLabel } from '../../../../utils';

export const AddWorkOrder = (props) => {
    const { setShowLoader, woPopUp, setToastMsg } = props;
    const employeeLabel = getAccountLabel('employee');
    const [activeTab, setActiveTab] = useState('manual');
    const [file, setFile] = useState(null)
    const [orderType, setOrderType] = useState('recurring');
    const [group, setGroup] = useState();
    const [selectedGroup, setSelectedGroup] = useState({ label: employeeLabel, value: "employee" });
    const [deviceOfGroup, setDeviceOfGroup] = useState();
    const { handleSubmit, setValue, getValues, watch, reset } = useForm({ mode: "all" });
    const [jobNameList, setJobNameList] = useState([]);
    const [jobSiteList, setJobSiteList] = useState([]);
    const [rows, setRows] = useState(defaultRows);

    const getGroupsData = async () => {
        try {
            const grpResult = await apiProvider.getAll('track/mobile/v1/allgeo/getGroups?includeAll=true')
            let grp = (grpResult.groups).sort((a, b) => (a.groupDesc).toLowerCase() > (b.groupDesc).toLowerCase() ? 1 : -1)
                .filter(grp => grp.groupDesc)
                .map(grp => ({ label: grp.groupDesc === 'all' ? 'All' : grp.groupDesc, value: grp.groupID }))

            setGroup([...addOnGroupData, ...grp])
            return grp
        } catch (error) {
            console.log(error);
        }
    }

    const getDeviceData = async () => {
        try {
            const payload = selectedGroup.value === "employee" ? "all" : selectedGroup.value
            const response = await apiProvider.getAll(`track/react/v1/allgeo/getDevicesForGroup?group=${payload}`)
            if (response.status === 200) {
                let filteredData;
                if (selectedGroup.value === "all" || selectedGroup.value === "unassigned") {
                    filteredData = response.data.filter(device => device.deviceID !== "all" && device.deviceID !== "none");
                    setDeviceOfGroup(filteredData)
                } else {
                    filteredData = response.data
                    setDeviceOfGroup(filteredData)
                }

                const responseLength = response.data.length

                reset()
                if (responseLength && selectedGroup.value !== "employee") {

                    const newRows = filteredData.map((item, index) => {
                        setValue(`${index + 1}.deviceDesc`, item);
                        setValue(`${index + 1}.deviceID`, item.deviceID);
                        setValue(`${index + 1}.locatable`, defaultTrack);
                        setValue(`${index + 1}.timezone`, defaultTimeZone);
                        setValue(`${index + 1}.workOrderNumber`, defaultValues.workOrderNumber);

                        return {
                            rowID: index + 1,
                            deviceID: item.deviceID,
                            deviceDesc: item.deviceDesc,
                            ...defaultValues
                        };

                    })

                    setRows(newRows)
                } else {
                    setRows(defaultRows)
                    reset()
                }
            }

        } catch (error) {
            console.log(error);
        }
    }

    const getDropdownData = async () => {
        try {

            const response = await apiProvider.getAll('/track/mobile/v1/allgeo/getEditWorkOrderDropdowns',)
            if (response.status === 200) {
                setJobNameList(response.data.jobNameList.map(e => ({ label: e, value: e })));
                setJobSiteList(response.data.jobSiteList.map(e => ({ label: e, value: e })));
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getGroupsData()
        getDropdownData()
    }, [])

    useEffect(() => {
        getDeviceData();
    }, [selectedGroup])

    const handleTabClick = (tab, event) => {
        event.preventDefault();
        setActiveTab(tab);
    };

    const handleFileUpload = e => {
        if (e.dataTransfer) {
            const uploadedFile = e.dataTransfer.files[0];
            setFile(uploadedFile)
        } else {
            const uploadedFile = e.target.files[0];
            setFile(uploadedFile)
        }
    }

    const handleOrderTypeChange = (event) => setOrderType(event.target.value);
    const handleGroupDropDownChange = (e) => setSelectedGroup(e.target.value);
    const closeWOPopUp = () => woPopUp(false);

    const onSubmit = async data => {
        try {
            if (activeTab === 'manual') {
                await handleSubmitManual(data);
            } else {
                await handleSubmitBulkUpload();
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleSubmitManual = async data => {
        const payLoad = (await preparePayload(data, orderType))
            .filter((element) => element.workOrderNumber);

        if (payLoad.length) {
            const showToast = checkMandatoryFields(payLoad, orderType, setToastMsg, mandatoryFieldErrorMsg);
            const hasInvalidDates = checkDateValidity(payLoad, setToastMsg, invalidDateErrorMsg);
            if (showToast || hasInvalidDates) return;
            setShowLoader(true);
            const grpResult = await apiProvider.post('track/react/v1/allgeo/addWorkorder', payLoad);
            handleResponse(grpResult);
        } else {
            setToastMsg(mandatoryFieldErrorMsg);
        }
    }

    const handleResponse = grpResult => {
        setShowLoader(false);
        if (grpResult.status === 200) {
            woPopUp(false);
            setToastMsg({
                msg: grpResult.message,
                type: "msgSuccess"
            });
        } else {
            setToastMsg({
                msg: grpResult.message,
                type: "msgError"
            });
        }
    }

    const handleSubmitBulkUpload = async () => {
        if (!file) {
            setToastMsg(csvErrorMsg);
            return;
        }
        setShowLoader(true);
        const formData = new FormData();
        formData.append('file', file);
        const grpResult = await apiProvider.post('track/react/v1/allgeo/bulkUploadWorkorders', formData);
        handleResponse(grpResult);
    }


    const addNewRow = (evt) => {
        evt.preventDefault()
        setValue(`${rows.length + 1}`, { ...defaultValues })
        setRows([...rows, { rowID: rows.length + 1, ...defaultValues }])
    }

    const deleteRow = evt => {
        evt.preventDefault();
        handleDeleteRow(rows, setValue, setRows, setToastMsg, deleteBtnErrorMsg);
    }

    const handleActionButton = (rowId) => {
        const updatedData = rows.filter(item => item.rowID !== rowId);
        setValue(`${rowId}`, {})
        setRows(updatedData)
    }

    const workorderLabel = getAccountLabel('workorder');
    return (
        <>
            <div className="popup_wrapper large_modal active">
                <div className="modal_header">
                    <div className="modal_close" onClick={closeWOPopUp}>Close</div>
                    <div className="main_heading">Upload {workorderLabel}</div>
                </div>
                <div className="modal_content">
                    <ModalTabs activeTab={activeTab} handleTabClick={handleTabClick} />
                    { //conditonal rendring start form here 
                        activeTab === 'manual' ?
                            <div>
                                <AddDeleteRows
                                    group={group}
                                    selectedGroup={selectedGroup}
                                    handleGroupDropDownChange={handleGroupDropDownChange}
                                    orderType={orderType}
                                    handleOrderTypeChange={handleOrderTypeChange}
                                    addNewRow={addNewRow}
                                    deleteRow={deleteRow}
                                />
                                <div className="emp_table_block">
                                    <div className="list_table tbl_style">
                                        <table>
                                            <thead>
                                                <tr>
                                                    {(orderType === 'recurring' ? addWorkOrderColumnsRecurring : addWorkOrderColumnsNonRecurring)?.map((col) => (
                                                        <th key={col.field}>
                                                            {col.title}{col.sign && <span style={{ color: 'red' }}>*</span>}
                                                        </th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {rows.map((row) => (
                                                    <tr key={row.rowID}>
                                                        {(orderType === 'recurring' ? addWorkOrderColumnsRecurring : addWorkOrderColumnsNonRecurring)?.map((col) => (
                                                            <td key={col.field} className={col.className || ''}>
                                                                <RenderColumns
                                                                    rowId={row.rowID}
                                                                    field={col.field}
                                                                    reset={reset}
                                                                    setValue={setValue}
                                                                    rows={rows}
                                                                    setRows={setRows}
                                                                    watch={watch}
                                                                    getValues={getValues}
                                                                    deviceOfGroup={deviceOfGroup}
                                                                    jobNameList={jobNameList}
                                                                    jobSiteList={jobSiteList}
                                                                    handleActionButton={handleActionButton}
                                                                />
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            :
                            <BulkUploadWorkOrder file={file} setFile={setFile} handleFileUpload={handleFileUpload} />
                    }

                </div>
                <div className="modal_buttons flexJEnd">
                    <button className="button_style btn2" onClick={closeWOPopUp}>Cancel</button>
                    <button className="button_style okBtn" onClick={handleSubmit(onSubmit)}>Save</button>
                </div>
            </div>
        </>
    )
}