import React, { useState, useEffect } from "react";
import './StartStopPopup.css'
import { DEFAULT_MODE } from '../utils/activeScheduleUtils';
import { apiProvider } from "../../../../services/api/provider";

export const StartStopPopup = ({ mode, setMode, setShowLoader, setToastMsg }) => {
    const [isAlert, setIsAlert] = useState(false);

    useEffect(() => {
        const checkStatus = () => {
            if (mode.type === "start" && mode.data.status === "Started") {
                handleToastMsg("The schedule is already started");
            } else if (mode.type === "stop") {
                if (mode.data.status === "Not Started") {
                    handleToastMsg("The schedule is yet to start");
                } else if (mode.data.status === "Stopped") {
                    handleToastMsg("The schedule is already stopped");
                } else {
                    setIsAlert(true);
                }
            } else {
                setIsAlert(true);
            }
        };

        checkStatus();
    }, [mode]);

    const handleToastMsg = (message, type = "msgError") => {
        setToastMsg({ msg: message, type: type });
        setMode(DEFAULT_MODE);
    };

    const onCancelClick = () => {
        setMode(DEFAULT_MODE);
    };

    const onSuccessClick = async () => {
        try {
            setShowLoader(true);
            const response = await apiProvider.getAll(`track/react/v1/allgeo/activeSchedule/startOrStopSchedule?tripId=${mode.data.trip}&action=${mode.type}`);
            const message = response.message;
            handleToastMsg(message, response.status === 200 ? "msgSuccess" : "msgError");
            setShowLoader(false);
        } catch (error) {
            console.log(error);
            setShowLoader(false);
        }
    };

    const iconClass = mode.type === "start" ? " msgIcon msg_start" : " msgIcon msg_stop";

    return (
        <>
            {isAlert && (
                <div className="popupWrap msgPopup  active">
                    <div className="msgPopupCont">
                        <div className={iconClass}>Delete</div>
                        <div className="msgContent">
                            <div>{`Are you sure you want to ${mode.type} this Schedule?`}</div>
                        </div>
                    </div>
                    <div className="popupMsgBtn">
                        <a className="okBtn cursorPointer" onClick={onCancelClick}>
                            Cancel
                        </a>
                        <button className="btnStyle okBtn" onClick={onSuccessClick}>
                            Yes
                        </button>
                    </div>
                </div>
            )}
        </>
    );
}
