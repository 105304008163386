import { useState, useEffect, useMemo } from 'react';
import UploadIcon from '../../../assets/images/icon-upload.svg';
import { apiProvider } from '../../../services/api/provider'
import FileUploadFail from '../../common/FileUploadFail';
import { getAccessLevel } from '../../common/functions';

import { EvvClientXlsx, EvvEmployeeXlsx } from './evvDataUploadCSVs';
import { DeductionCodesXlsx, EarningCodesXlsx, EmployeeSample, JobTemplateXlsx, PayrollProfileXlsx, TaskDetailsXlsx } from './dataUploadCSVs';
import { getAccountLabel } from '../../../utils';

const employeeLabel = getAccountLabel('employee');
const jobsiteLabel = getAccountLabel('jobsite');
const taskLabel = getAccountLabel('task');
const groupLabel = getAccountLabel('group');


const uploadHubTabMap = [
    { label: employeeLabel, value: 'employee', id: 'Employees', aclMenuItem: 'employees', downloadText: employeeLabel, sample: EmployeeSample, sampleFileName: `${employeeLabel}-phone-numbers`, cvvSample: EvvEmployeeXlsx, cvvSampleFileName: `evv_${employeeLabel}-profile` },
    { label: jobsiteLabel, value: 'jobSite', id: 'JobSites', aclMenuItem: 'job_sites', downloadText: jobsiteLabel, sample: JobTemplateXlsx, sampleFileName: `${jobsiteLabel}-template`, cvvSample: EvvClientXlsx, cvvSampleFileName: 'evv_clients_upload' },
    { label: taskLabel, value: 'task', id: 'Task', aclMenuItem: 'tasks', downloadText: taskLabel, sample: TaskDetailsXlsx, sampleFileName: `${taskLabel}_details` },
    { label: 'Payroll Profile', value: 'payrollProfile', id: 'PayrollProfile', aclMenuItem: 'settings', downloadText: 'Payroll Profile', sample: PayrollProfileXlsx, sampleFileName: 'payroll-profile' },
    { label: 'Earning Codes', value: 'earningCodes', id: 'EarningCodes', aclMenuItem: 'settings', downloadText: 'Earning Code', sample: EarningCodesXlsx, sampleFileName: 'earning_codes' },
    { label: 'Deduction Codes', value: 'deductionCodes', id: 'DeductionCodes', aclMenuItem: 'settings', downloadText: 'Deduction Code', sample: DeductionCodesXlsx, sampleFileName: 'deduction_codes' }
]

const aclSubMenu = ['employees', 'job_sites', 'tasks']

const UploadHub = (props) => {

    const [selectedTab, setSelectedTab] = useState({});
    const [file, setFile] = useState(null);
    const [error, setError] = useState(false);
    const [isFormComplete, setIsFormComplete] = useState(false);
    const [createEmployee, setCreateEmployee] = useState(false);
    const [fileUploadError, setFileUploadError] = useState({
        show: false,
        data: null
    })
    const [uploadHubTabMapWithAcl, setUploadHubTabMapWithAcl] = useState([]);
    const [showCreateEmployeeCheckbox, setShowCreaeEmployeeCheckbox] = useState(true)

    useEffect(async () => {
        const employeeAccessLevel = await getAccessLevel("company", "employees")
        const monitorAccessLevel = await getAccessLevel("monitor", "")

        if (employeeAccessLevel.childMenu < 3 && monitorAccessLevel.parentMenu < 3) {
            setShowCreaeEmployeeCheckbox(false)
        }

        let subMenuItemAclMapping = [];
        let menuAclMapping = JSON.parse(localStorage.getItem('menuItems'))
        menuAclMapping.forEach((ele) => {
            if (ele.submenu.length > 0) {
                subMenuItemAclMapping = [...subMenuItemAclMapping, ...ele.submenu].filter((el) => aclSubMenu.includes(el.name))
            }
        })
        let data = uploadHubTabMap.map((ele) => {
            let aclData = subMenuItemAclMapping.filter((el) => el.name === ele.aclMenuItem)[0];

            if (!aclData) return { ...ele, disabled: false }

            if (aclData.accessLevel < 3) {
                return { ...ele, disabled: true }
            } else {
                return { ...ele, disabled: false }
            }
        })

        const accessibleData = data.filter(d => !d.disabled)

        setSelectedTab(accessibleData.length > 0 ? accessibleData[0] : {})
        setUploadHubTabMapWithAcl(data)
    }, [])

    const changeTab = (type) => {
        if (type !== selectedTab.value) {
            let data = uploadHubTabMap.filter((ele) => ele.value === type);
            setSelectedTab(data[0])
            setFile(null);
            setError(false)
            setCreateEmployee(false);
            setIsFormComplete(false);
        }
    }

    const handleFileSize = () => {

        if (file) {
            let unit = 'b';
            let size = file.size;

            if (size >= 1024 * 1024) {
                size = size / Math.pow(1024, 2);
                unit = 'mb';
            } else if (size >= 1024) {
                size = size / 1024;
                unit = 'kb';
            }

            return `${(Math.round(size * 100) / 100)} ${unit}`;
        }
    }

    const handleFileUpload = e => {
        setError(false)
        const allowedFiletypes = ['.csv', '.xlsx', '.xls', 'text/csv', 'text/xlsx', 'application/csv', 'text/comma-separated-values', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']

        if (e.dataTransfer) {
            const uploadedFile = e.dataTransfer.files[0];

            if (allowedFiletypes.includes(uploadedFile.type)) {
                setIsFormComplete(true)
                setFile(uploadedFile)
            } else {
                setError(true)
            }
        } else {
            const uploadedFile = e.target.files[0];

            if (allowedFiletypes.includes(uploadedFile.type)) {
                setIsFormComplete(true)
                setFile(uploadedFile)
            } else {
                setError(true)
            }
        }
    }

    const uploadForm = async () => {

        try {

            props.setShowLoader(true)

            let formData = new FormData();
            console.log(file)
            formData.append('file', file);

            let apiUrl = ""

            switch (selectedTab.value) {
                case 'employee':
                    apiUrl = 'track/mobile/v1/allgeo/uploadEmployees';
                    break;
                case 'dailyScheule':
                    apiUrl = 'track/mobile/v1/allgeo/uploadEmployees';
                    break;
                case 'jobSite':
                    apiUrl = 'track/mobile/v1/allgeo/uploadCustomers';
                    break;
                case 'task':
                    apiUrl = 'track/mobile/v1/allgeo/uploadTask';
                    break;
                case 'uspsSchedule':
                    apiUrl = 'track/mobile/v1/allgeo/uploadEmployees';
                    break;
                case 'multiSegmentSchedule':
                    apiUrl = 'track/mobile/v1/allgeo/segmentSchedule';
                    break;
                case 'dailyOrRecurringWorkOrder':
                    apiUrl = 'track/mobile/v1/allgeo/workOrder';
                    break;
                case 'payrollProfile':
                    apiUrl = `track/mobile/v1/allgeo/payroll?createEmployee=${createEmployee}`;
                    break;
                case 'earningCodes':
                    apiUrl = 'track/mobile/v1/allgeo/codes?cmd=earningCode';
                    break;
                case 'deductionCodes':
                    apiUrl = 'track/mobile/v1/allgeo/codes?cmd=deductionCode';
                    break;
            }

            const response = await apiProvider.formPost(apiUrl, formData)
            if (response.status === 200) {
                setFile(null);
                setIsFormComplete(false);
                props.setToastMsg({
                    msg: response.message,
                    type: "msgSuccess"
                })
            } else if (+response.status === 422) {
                const { knownColumns, unknownColumn } = response;

                setFileUploadError({
                    show: true,
                    data: {
                        file,
                        validColumns: knownColumns,
                        misMatchedColumns: unknownColumn,
                        validationError: false,
                        uploadUrl: apiUrl
                    }
                })
            } else {
                setFileUploadError({
                    show: true,
                    data: {
                        file,
                        validationError: true,
                        errorMsg: response.message,
                        uploadUrl: apiUrl
                    }
                })
            }

        } catch (error) {
            console.log(error)
        } finally {
            props.setShowLoader(false)
        }
    }

    const isUploadSuccessfull = success => {
        if (success) {
            setFile(null);
            setIsFormComplete(false);
        }
    }

    const isAnyCategoryAccessible = useMemo(() => {
        return uploadHubTabMapWithAcl.filter(ele => !ele.disabled).length > 0
    }, [uploadHubTabMapWithAcl])


    return (
        <>
            <section>
                <h3>Upload a File</h3>
                <div className="innerWrap2">
                    <div className="twoColBlk">
                        <div className="leftCol">Select a category of the file you want to upload.</div>
                        <div className="rightCol">
                            <div className="highlightedSection">
                                <div className="customControls ">
                                    {uploadHubTabMapWithAcl.length > 0 && uploadHubTabMapWithAcl.filter(ele => !ele.disabled).map((ele, index) => (
                                        <div className="customRadio" key={index}>
                                            <input type="radio" id={ele.id} name="uploadFile" checked={selectedTab.value === ele.value} onChange={() => changeTab(ele.value)} />
                                            <label htmlFor={ele.id}>{ele.label}</label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    {(selectedTab.value !== 'uspsSchedule' && selectedTab.value !== 'multiSegmentSchedule') &&
                        <div className="twoColBlk">
                            <div className="leftCol">Need a guide for the files you need to upload? Download a sample from the list.</div>
                            <div className="rightCol">
                                <div className="highlightedSection">
                                    <div className="multipleDownload" >
                                        {isAnyCategoryAccessible &&
                                            <>
                                                <div className="downloadBlk">Download sample <a href={selectedTab.sample} download={selectedTab.sampleFileName}>{selectedTab.downloadText} sample file here</a></div>
                                                {(selectedTab.value === uploadHubTabMap[0].value || selectedTab.value === uploadHubTabMap[1].value) &&
                                                    <div className="downloadBlk">Download sample <a href={selectedTab.cvvSample} download={selectedTab.cvvSampleFileName}>EVV {selectedTab.downloadText} sample file here</a></div>}
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {isAnyCategoryAccessible && <div className="twoColBlk">
                        <div className="leftCol">Upload your files here</div>
                        <div className="rightCol">
                            <div className="highlightedSection">
                                {!file
                                    ?
                                    <div className="dragDrop"
                                        onDragOver={e => {
                                            e.preventDefault()
                                        }}
                                        onDrop={e => {
                                            e.preventDefault()
                                            handleFileUpload(e)
                                        }}>
                                        <p><img src={UploadIcon} alt="Upload" /></p>
                                        <div>Drag and drop or browse to choose a file</div>
                                        <p>Only CSV & XLSX file extension is allowed.</p>
                                        {error && <div className="errMsg">File type unsupported.</div>}
                                        <input type="file" onChange={handleFileUpload} accept=".csv" />
                                    </div>
                                    :
                                    <div className="fileDetail">
                                        <div className="fileName">{file?.name}</div>
                                        <span>{handleFileSize()}</span>
                                        <em className="fileDelete" onClick={() => { setFile(null); setIsFormComplete(false) }} >Delete</em>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    }
                    <div className="btnWrap flexJEnd topGap1">
                        {
                            showCreateEmployeeCheckbox && selectedTab.value === uploadHubTabMap[3].value &&
                            <div className="customCheck">
                                <input type="checkbox" id="customCheck1" checked={createEmployee} onChange={() => setCreateEmployee(!createEmployee)} />
                                <label className="customCheckLabel" htmlFor="customCheck1">Create {employeeLabel} (if not exist)</label>
                            </div>
                        }
                    </div>
                </div>
            </section >
            <div className="mainBtnWrap">
                <button className="btnStyle" disabled={props?.accessLevel < 3 ? true : !isFormComplete} onClick={uploadForm}>Upload</button>
            </div>
            {fileUploadError.show &&
                <FileUploadFail data={fileUploadError.data} closePopup={() => setFileUploadError({ show: false, data: null })} setToastMsg={props.setToastMsg} setShowLoader={props.setShowLoader} isUploadSuccessfull={isUploadSuccessfull} fileType={file?.name}/>
            }
            <div className={`overlay ${fileUploadError.show ? 'active' : ''}`} />
        </>
    )
}

export default UploadHub