import React, { useState } from 'react'
import { AutoComplete } from '@progress/kendo-react-dropdowns'
import { filterBy } from "@progress/kendo-data-query";

const AutoCompleteDropdown = ({ options, selectedOption = "", selectionKey = "value", setValue, placeholder = "+000" }) => {

  const [state, setState] = useState({
    data: options.map(d => d.label),
    selectedValue: selectedOption
  })

  const filterData = (value) => {
    const data = options.map(d => d.label);
    const filter = {
      value: value,
      operator: "contains",
      ignoreCase: true,
    };
    return value ? filterBy(data, filter) : data;
  };

  const onChange = evt => {
    const { value } = evt.target;
    let valueWithConstraints = value.replace(/[^0-9+]/g, '')
    const selectedOption = options.find(op => op.label === valueWithConstraints)

    
    if (selectedOption) {
      setValue(selectedOption[selectionKey])
    } else {
      setValue(valueWithConstraints)
    }

    setState({
      data: filterData(valueWithConstraints),
      selectedValue: valueWithConstraints
    })
  }

  return (
    <AutoComplete
      data={state.data}
      value={state.selectedValue}
      onChange={onChange}
      placeholder={placeholder}
    />
  )
}

export default AutoCompleteDropdown