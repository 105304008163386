import { getAccountLabel } from "../../../../utils"

const workorderLabel = getAccountLabel('workorder');
const employeeLabel = getAccountLabel('employee');
const jobsiteLabel = getAccountLabel('jobsite');
const taskLabel = getAccountLabel('task');

export const workOrderColumns = [

    {
        title: `${workorderLabel} ID`,
        field: "Title",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,

    },
    {
        title: "Phone #",
        field: "OwnerID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,

    },
    {
        title: "Phone Name",
        field: "DeviceName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,

    },
    {
        title: "Status",
        field: "Status",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,

    },
    {
        title: "Start Date",
        field: "Start",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,

    },

    {
        title: "End Date",
        field: "End",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,

    },
    {
        title: "Customer Name",
        field: "SiteName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,

    }, {
        title: taskLabel,
        field: "JobName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,

    }, {
        title: "Message",
        field: "Description",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,

    }, {
        title: "Rate",
        field: "BillingRate",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,
    },
]


export const addWorkOrderColumnsNonRecurring = [
    {
        title: "",
        field: "select",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,

    },
    {
        title: `${workorderLabel} Id`,
        field: "workOrderNumber",
        editable: false,
        // width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,
        sign:true

    },
    {
        title: `${employeeLabel} Name`,
        field: "deviceDesc",
        editable: false,
        // width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,
        sign:true

    },
    {
        title: "Device",
        field: "deviceID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,

    },
    {
        title: "Start Date",
        field: "startDate",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,
        sign:true

    },

    {
        title: "End Date",
        field: "endDate",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,
        sign:true
    },
    {
        title: "Start Time",
        field: "startTime",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,
        sign:true

    },
    {
        title: "Stop Time",
        field: "endTime",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,
        sign:true

    },
    {
        title: "Time-zone",
        field: "timezone",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,

    },
    {
        title: "Track Location",
        field: "locatable",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,

    },
    {
        title: jobsiteLabel,
        field: "customerName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,

    },
    {
        title: taskLabel,
        field: "taskName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,

    }, {
        title: "Form",
        field: "formName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,

    },


    {
        title: "Message",
        field: "message",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,

    }, {
        title: "Rate",
        field: "billingRate",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,
    },
    {
        title: "Action",
        field: "action",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        className : "action_dlt_btn"


    },

]


export const addWorkOrderColumnsRecurring = [
    {
        title: "",
        field: "select",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,

    },
    {
        title: `${workorderLabel} Id`,
        field: "workOrderNumber",
        editable: false,
        // width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,
        sign:true
    },
    {
        title: `${employeeLabel} Name`,
        field: "deviceDesc",
        editable: false,
        // width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,
        sign:true

    },
    {
        title: "Device",
        field: "deviceID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,

    },
    {
        title: "Start Time",
        field: "startTime",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,
        sign:true

    },
    {
        title: "Stop Time",
        field: "endTime",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,
        sign:true

    },
    {
        title: "Days",
        field: "trackingDay",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,

    },

    {
        title: "Time-zone",
        field: "timezone",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,

    },
    {
        title: "Track Location",
        field: "locatable",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,

    },
    {
        title: jobsiteLabel,
        field: "customerName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,

    },
    {
        title: taskLabel,
        field: "taskName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,

    }, {
        title: "Form",
        field: "formName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,

    },


    {
        title: "Message",
        field: "message",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,

    }, {
        title: "Rate",
        field: "billingRate",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,
    },
    {
        title: "Action",
        field: "action",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        // className : "action_dlt_btn"

    },

]


export const DEFAULT_MODE = {
    title: "Work Order",
    type: "main",
    data: null,
    hasPrev: false,
}

export const DELETE_CONFIRMATION_MESSAGE = {
    confirmationText: "Are you sure you want to delete this record?",
    deletionOptionsText: "Do you want to delete only this event occurrence or the whole series?"
};

