import React, { useState } from "react";
import LeftContent from "../login/LeftContent";
import usePasswordValidation from "../../hooks/usePasswordValidation";
import { useLocation, Link, useHistory } from 'react-router-dom';
import { apiProvider } from '../../services/api/provider';
import ToastMessage from '../common/ToastMessage';
import ResetPasswordSuccess from '../resetPassword/ResetPasswordSuccess';
import { useDocumentTitle } from '../../hooks/setDocumentTitle';
import { Buffer } from "buffer";

const CreatePassword = () => {
  useDocumentTitle("Create Password - allGeo");
  const search = useLocation().search;
  let accountId = new URLSearchParams(search).get('accountId');
  let userId = new URLSearchParams(search).get('userId');
  let loginToken = new URLSearchParams(search).get('loginToken');
  let useStandardValidationType = true;
  let isLoading = false;
  let formIsValid = false;
  const history = useHistory();

  if (accountId) {
    accountId = Buffer.from(accountId, 'base64').toString();
  }
  if (userId) {
    userId = Buffer.from(userId, 'base64').toString();
  }
  if (loginToken) {
    loginToken = Buffer.from(loginToken, 'base64').toString();
    let tokenArr = loginToken.split('');
    let firstNum = parseInt(tokenArr[0]);
    if (firstNum > 1) {
      useStandardValidationType = false;
    }
  }
  

  const [password, setPassword] = useState({password: "",confirmPassword: ""});
  const [passwordClassName, setPasswordClassName] = useState('');
  const [cnfPasswordClassName, setCnfPasswordClassName] = useState('');
  const [passwordFieldType, setPasswordFieldType] = useState('password');

  const [isLoginError, setIsLoginError] = useState(false);
  const [loginErrorMsg, setLoginErrorMsg] = useState('');
  const [isRestPasswordSuccess, setRestPasswordSuccess] = useState(false);
  
  const [
    validLength,
    hasNumber,
    upperCase,
    lowerCase,
    match,
    specialChar,
    accountIdMatch,
    userIdMatch
    ] = usePasswordValidation({
      firstPassword: password.password,
      secondPassword: password.confirmPassword,
      requiredLength: 8,
      standard: useStandardValidationType,
      accountId: accountId,
      userId: userId
    });
  
  const passwordChanged = (event) => {
      setPassword({ ...password, password: event.target.value });
  };
  const confirmPasswordChanged = (event) => {
      setPassword({ ...password, confirmPassword: event.target.value });
  };

  const passwordFocusHandler = (event) => {
    setPasswordClassName('active');
  }

  const passwordBlurHandler = (event) => {
    !event.target.value ? setPasswordClassName('errorFld') : setPasswordClassName('');
  }

  const confirmPasswordFocusHandler = (event) => {
    setCnfPasswordClassName('active');
  }

  const confirmPasswordBlurHandler = (event) => {
    if (!event.target.value || !match) {
      setCnfPasswordClassName('errorFld');
    } else {
      setCnfPasswordClassName('');
    }
  }

  const changeFieldType = () =>{ passwordFieldType === 'password' ? setPasswordFieldType('text') : setPasswordFieldType('password') };

  if (validLength && hasNumber && specialChar && match && loginToken && accountId && userId) {
    if (useStandardValidationType) {
      formIsValid = true;
    } else {
      if (upperCase && lowerCase && accountIdMatch && userIdMatch) {
        formIsValid = true;
      } else {
        formIsValid = false;
      }
    }  
  } else {
    formIsValid = false;
  }

  const changePasswordHandler = (event) => {
    event.preventDefault();
    if (!formIsValid) {
      return
    }
    
    let changePasswordForm = {
      loginToken: loginToken,
      password: password.password
    };

    isLoading = true;
    apiProvider.post('track/react/v1/createpassword',changePasswordForm).then((response) => {
      isLoading = false;
      switch(response.status) {
        case 200:
          handelSuccessEvent(response)
          break;
        case 401:
          handelErrorMsg(response.message);
          break;
        case 45:
          history.replace('/trial-account-expired');
          break;
        default:
          handelErrorMsg('Network Error! Please try after some time.');
      }
    })
  }

  const handelErrorMsg = (serverMessage) => {
    let msg = serverMessage;
    setIsLoginError(true);
    if (serverMessage == 'Invalid Credentials') {
      msg = <>Credentials are invalid. If you don't remember your password, <Link to='/reset-password'>reset it now.</Link></>;
    }
    setLoginErrorMsg(msg);
  }

  const handelSuccessEvent = (res) => {
    if (res.message === 'Password Changed Successfully!') {
      setRestPasswordSuccess(true);
    } else if (res.message === 'Password Set Successfully!') {
      setRestPasswordSuccess(true);
    } else {
      handelErrorMsg(res.message);
    }
  }
  
  return (
    <div className="userPageWrapper">
      <LeftContent></LeftContent>
      <div className="rightContent">
        {
          !isRestPasswordSuccess &&
          <>
            <form  onSubmit={changePasswordHandler}>
              <h2>Create Your Password</h2>
              <div className={`fldWrap withShowPassword passwordFld fldWithIcon ${passwordClassName}`}>
                <input type={passwordFieldType}
                  onChange={passwordChanged}
                  placeholder="New Password"
                  onFocus={passwordFocusHandler}
                  onBlur={passwordBlurHandler}
                />
                <span className="fldIcon">Icon</span>
                {passwordFieldType === 'password' && <span onClick={changeFieldType} className="showPassword">Show</span>}
                {passwordFieldType === 'text' && <span onClick={changeFieldType} className="hidePassword">Show</span>}
              </div>
              <div className={`fldWrap passwordFld fldWithIcon ${cnfPasswordClassName}`}>
                <input type="password"
                  onChange={confirmPasswordChanged}
                  placeholder="Confirm Password"
                  onFocus={confirmPasswordFocusHandler}
                  onBlur={confirmPasswordBlurHandler}
                />
                <span className="fldIcon">Icon</span>
                {match && <span className="fldOk">Ok</span>}
              </div>
              <button className="btnStyle" disabled={!formIsValid || isLoading }>
                SUBMIT
              </button>
              <ul className="checkListing">
                <li className={validLength ? 'active' : ''}>8 characters long</li>
                <li className={hasNumber ? 'active' : ''}>Minimum of one number</li>
                <li className={specialChar ? 'active' : ''}>Minimum of one special character</li>
                {!useStandardValidationType &&
                  <><li className={lowerCase ? 'active' : ''}>Minimum of one lowercase character</li>
                    <li className={upperCase ? 'active' : ''}>Minimum of one uppercase character</li>
                    <li className={accountIdMatch ? 'active' : ''}>Should not have company name or an abbreviation</li>
                    <li className={userIdMatch ? 'active' : ''}>Should not have words found in the user's name</li></>
                }
                <li className={match ? 'active' : ''}>Password & confirm password matched</li>
              </ul>
            </form>
          </>
        }
        {
          isRestPasswordSuccess &&
          <ResetPasswordSuccess title="Password Set Successfully!">
            <p>You can now sign in with your chosen password. </p>
          </ResetPasswordSuccess>
        }
      </div>
      {
        isLoginError && <ToastMessage cssClass="msgError" message={loginErrorMsg} onClose={() => { setIsLoginError(false); setLoginErrorMsg(''); }}/>
      }
    </div>
  );
}

export default CreatePassword;
