import React, { useMemo, useCallback } from 'react'
import { Polygon, Marker } from '@react-google-maps/api'

const PolygonGeozone = ({ path, blueMarker, custName, geozoneID, address }) => {

  const getPolygonCenter = useCallback(path => {
    const pos = {
      lat: 0,
      lng: 0
    }

    pos.lat = path.reduce((sum, curr) => sum + curr.lat, 0) / path.length;
    pos.lng = path.reduce((sum, curr) => sum + curr.lng, 0) / path.length;

    return pos
  }, [])

  const polygonCenter = useMemo(() => getPolygonCenter(path), [])

  return (
    <>
      <Polygon
        path={path}
        options={{
          strokeColor: 'blue',
          strokeWeight: 2,
          strokeOpacity: 1,
          fillOpacity: 0.1
        }}
      />
      <Marker
        position={polygonCenter}
        icon={blueMarker}
        title={`CustomerName: ${custName}, Geozone ID: ${geozoneID}, Latitude: ${polygonCenter.lat}, Longitude: ${polygonCenter.lng}, Address: ${address}`}
      />
    </>
  )
}

export default PolygonGeozone