import React, { useState, useEffect } from 'react'
import { apiProvider } from '../../../services/api/provider';
import SiteLoader from '../../common/SiteLoader';
import ToastMessage from '../../common/ToastMessage';
import { Prompt } from 'react-router';
import { ConfirmPopup } from "../../common/PopUp";
import { getAccountLabel } from '../../../utils';

const SettingsSecurity = ({ formState, accessLevel }) => {

    const [showLoader, setShowLoader] = useState(false);
    const [privacyData, setPrivacyData] = useState({
        privacy_level: '',
        two_factor_authentication: false,
        location_opted: false,
        location_opted_date: null,
        sms_opted: false,
        sms_opted_date: null,
        un_verified_users: []
    })
    const [defaultData, setDefaultData] = useState(null)
    const [showPopup, setShowPopup] = useState(false)
    const [showEnablePopup, setShowEnablePopup] = useState(false)
    const [showDisablePopup, setShowDisablePopup] = useState(false)
    const [isUpdateSuccessful, setIsUpdateSuccessful] = useState(null);
    const [accountUpdateMsg, setAccountUpdateMsg] = useState('');
    const [msgClass, setMsgClass] = useState('msgError');
    const [isDirty, setIsDirty] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const privacyLevel = {
        Standard: "Standard",
        High: "High"
    }
    const accountName = localStorage.getItem("accountId")

    const handlePrivacyLevel = privacyLevel => {
        setPrivacyData({
            ...privacyData,
            privacy_level: privacyLevel
        })
    }

    const handleTwoFactorAuthentication = () => {
        if (!privacyData.two_factor_authentication) {
            if (privacyData.un_verified_users.length > 0) {
                setShowPopup(true)
            } else {
                setShowEnablePopup(true)
            }
        } else {
            setShowDisablePopup(true)
        }
    }

    const handleSmsOpt = () => {
        setPrivacyData({
            ...privacyData,
            sms_opted: !privacyData.sms_opted
        })
    }

    const getAccountPrivacyDetails = async () => {
        setShowLoader(true)
        try {
            const response = await apiProvider.getAll('track/react/account/privacy')
            setPrivacyData(response)
            setDefaultData(response)
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    useEffect(() => {
        getAccountPrivacyDetails()
    }, [])

    const closePopup = () => setShowPopup(false)

    const TwoFactorPopup = () => (
        <div className="popupWrap twoFactorPopup active">
            <div className="closePopup" onClick={closePopup}>Close</div>
            <div className="popupHeading">Two-Factor Authentication</div>
            <div className="popupContent">
                <p>Some of the managers do not have an email id or mobile number added/verified.</p>
                <div className="tblFixedHeading">
                    <table cellPadding="0" cellSpacing="0" className="tblStyle">
                        <tbody>
                            <tr>
                                <th>Manager Name</th>
                                <th>Email ID</th>
                                <th>Contact Number</th>
                            </tr>
                            {
                                privacyData.un_verified_users.map((user, ind) => (
                                    <tr key={ind}>
                                        <td>{user.contactname}</td>
                                        <td>{user.contactemail}</td>
                                        <td>{user.contactphone}</td>
                                    </tr>
                                ))
                            }
                        </tbody></table>
                </div>
                <div className="noteBlk">
                    <div>Note:</div>
                    <ul>
                        <li>Please ensure all managers have email ID associated to their profile.</li>
                        <li>OTP via SMS will be used from your message quota.</li>
                        <li>Managers who do not have phone number added/verified will be receiving OTP through email. <a target="_blank" href='https://help.allgeo.com/en/articles/4147522-verify-manager-s-phone-number'>Learn how</a></li>
                    </ul>
                </div>
            </div>
            <div className="popupBtnWrap">
                <a target="_blank" href="https://help.allgeo.com/en/articles/4147392-why-is-two-factor-authentication-important">No, I need more info</a>
                <button className="btnStyle okBtn" onClick={() => {
                    closePopup()
                    setShowEnablePopup(true)
                }}>Okay, I understand</button>
            </div>
        </div>
    )

    const TwoFactorEnablePopup = () => (
        <div className="popupWrap msgPopup active">
            <div className="msgPopupCont">
                <div className="msgIcon msgLock">Done</div>
                <div className="msgContent">
                <div>Two Factor Authentication is now enabled for {accountName}!</div>
                    <p>OTP via SMS will be used from your message quota.</p>
                </div>
            </div>
            <div className="popupMsgBtn">
                <button className="btnStyle okBtn" onClick={() => {
                    setPrivacyData({
                        ...privacyData,
                        two_factor_authentication: true
                    })
                    setShowEnablePopup(false)
                }}>Close</button>
            </div>
        </div>
    )

    const TwoFactorDisablePopup = () => (
        <div className="popupWrap msgPopup active">
            <div className="msgPopupCont">
                <div className="msgIcon msgUnlock">Done</div>
                <div className="msgContent">
                    <div>Two Factor Authentication is disabled for {accountName}!</div>
                    <p>Now manager will login with user name and password only. There will be no OTP asked as second step of login</p>
                </div>
            </div>
            <div className="popupMsgBtn">
                <button className="btnStyle okBtn" onClick={() => {
                    setPrivacyData({
                        ...privacyData,
                        two_factor_authentication: false
                    })
                    setShowDisablePopup(false)
                }}>Close</button>
            </div>
        </div>
    )

    const updateAccountPrivacyDetails = async () => {
        setShowLoader(true)
        try {

            const response = await apiProvider.put('track/react/account/updatePrivacy', {
                privacy_level: privacyData.privacy_level,
                two_factor_authentication: privacyData.two_factor_authentication,
                sms_opted: privacyData.sms_opted
            })

            if (response.status === 200) {
                getAccountPrivacyDetails()
                setMsgClass('msgSuccess');
                setAccountUpdateMsg('The changes have been saved');
                setIsUpdateSuccessful(true);
            }

        } catch (error) {
            console.log(error);
            setShowLoader(false);
        }
    }

    const formCancelEvent = () => {
        if (isDirty) {
            setShowDialog(true)
        }
    }

    const onSuccess = () => {
        setShowDialog(false);
        fetchData();
    }

    const onFaliure = () => {
        setShowDialog(false);
    }

    const checkFormState = () => {

        for (let key in defaultData) {
            if (privacyData[key] !== defaultData[key]) {
                return true;
            }
        }

        return false;
    }

    useEffect(() => {
        if (defaultData) {
            setIsDirty(checkFormState())
        }
        if (isDirty) {
            localStorage.setItem('formIsDirty', isDirty)
        }
    }, [defaultData, privacyData])

    useEffect(() => {
        if (accessLevel.childMenu && accessLevel.childMenu > 1) {
            formState(isDirty)
        }
        if (isDirty) {
            localStorage.setItem('formIsDirty', isDirty)
        }
    }, [isDirty])

    const employeeLabel = getAccountLabel('employee');

    return (
        <>
            <Prompt
                when={isDirty}
                message='The changes are still unsaved. Are you sure you want to leave?'
            />
            <section>
                <h3>Security Settings</h3>
                <div className="supportedTxt">Choose your required level of account security.</div>
                <table cellPadding="0" cellSpacing="0" className="securityTbl">
                    <tbody>
                        <tr>
                            <th></th>
                            <th className={`setOption2${privacyData.privacy_level === privacyLevel.Standard ? ' active' : ''}`} onClick={() => handlePrivacyLevel(privacyLevel.Standard)}>
                                <div className="optionHeading">Standard Security</div>
                            </th>
                            <th className={`setOption3${privacyData.privacy_level === privacyLevel.High ? ' active' : ''}`} onClick={() => handlePrivacyLevel(privacyLevel.High)}>
                                <div className="optionHeading">High Security</div>
                            </th>
                        </tr>
                        <tr>
                            <td>Password Requirement</td>
                            <td className={`setOption2${privacyData.privacy_level === privacyLevel.Standard ? ' active' : ''}`} onClick={() => handlePrivacyLevel(privacyLevel.Standard)}>
                                <ul>
                                    <li>Minimum 8 characters</li>
                                    <li>At least one number</li>
                                    <li>At least one special character</li>
                                </ul>
                            </td>
                            <td className={`setOption3${privacyData.privacy_level === privacyLevel.High ? ' active' : ''}`} onClick={() => handlePrivacyLevel(privacyLevel.High)}>
                                <ul>
                                    <li>Minimum 8 characters</li>
                                    <li>At least one number</li>
                                    <li>At least one special character</li>
                                    <li>At least one lower case character</li>
                                    <li>At lease one upper case character</li>
                                    <li>Should not contain company name or abbreviation</li>
                                    <li>Should not contain user's name</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>Password Reuse</td>
                            <td className={`setOption2${privacyData.privacy_level === privacyLevel.Standard ? ' active' : ''}`} onClick={() => handlePrivacyLevel(privacyLevel.Standard)}>No restrictions</td>
                            <td className={`setOption3${privacyData.privacy_level === privacyLevel.High ? ' active' : ''}`} onClick={() => handlePrivacyLevel(privacyLevel.High)}>No previous password allowed</td>
                        </tr>
                        <tr>
                            <td>Password Expiry</td>
                            <td className={`setOption2${privacyData.privacy_level === privacyLevel.Standard ? ' active' : ''}`} onClick={() => handlePrivacyLevel(privacyLevel.Standard)}>Never expires</td>
                            <td className={`setOption3${privacyData.privacy_level === privacyLevel.High ? ' active' : ''}`} onClick={() => handlePrivacyLevel(privacyLevel.High)}>Every 90 days</td>
                        </tr>
                        <tr>
                            <td>Block Users</td>
                            <td className={`setOption2${privacyData.privacy_level === privacyLevel.Standard ? ' active' : ''}`} onClick={() => handlePrivacyLevel(privacyLevel.Standard)}>Block user for 10 mins after 5 incorrect attempts</td>
                            <td className={`setOption3${privacyData.privacy_level === privacyLevel.High ? ' active' : ''}`} onClick={() => handlePrivacyLevel(privacyLevel.High)}>Block user for 10 mins after 5 incorrect attempts</td>
                        </tr>
                        <tr>
                            <td>Login Expiry Duration</td>
                            <td className={`setOption2${privacyData.privacy_level === privacyLevel.Standard ? ' active' : ''}`} onClick={() => handlePrivacyLevel(privacyLevel.Standard)}>6 hours</td>
                            <td className={`setOption3${privacyData.privacy_level === privacyLevel.High ? ' active' : ''}`} onClick={() => handlePrivacyLevel(privacyLevel.High)}>30 minutes</td>
                        </tr>
                    </tbody>
                </table>
            </section>
            <section>
                <h3>Enable Two-Factor Authentication</h3>
                <div className="supportedTxt">Enable Two-Factor Authentication for stronger security</div>
                <div className="customCheck yesNo">
                    <input type="checkbox" id="towFactor" checked={privacyData.two_factor_authentication} onChange={handleTwoFactorAuthentication} />
                    <label className="customCheckLabel" htmlFor="towFactor">yesNo</label>
                </div>
            </section>
            <section>
                <h3>{employeeLabel} Privacy</h3>
                <div className="supportedTxt">Manage information related to opt-ins here</div>
                <div className="rowBlk">
                    <div className="colBlk6">
                        <div className="innerBlk">
                            <label className="lblColor1">Location Opt-in - {privacyData.location_opted ? 'Active' : 'In Active'}</label>
                            <p><em>{privacyData.location_opted_date}</em></p>
                            <p>By signing up with allGeo, you have agreed to share your location. Please read the <a
                                href="https://www.abaq.us/allgeo-terms.html" target="_blank">Terms of Use</a> for more details.</p>
                        </div>
                    </div>
                    <div className="colBlk6">
                        <label className="lblColor1">SMS Opt-in</label>
                        <div className="customCheck yesNo">
                            <input type="checkbox" id="smsOptin" checked={privacyData.sms_opted} onChange={handleSmsOpt} />
                            <label className="customCheckLabel" htmlFor="smsOptin">yesNo</label>
                        </div>
                        <p><em>{privacyData.sms_opted_date}</em></p>
                        <p><a target='_blank' href="https://www.abaq.us/allgeo-terms.html">allGeo Terms of Use</a></p>
                    </div>
                </div>
            </section>
            <div className={`overlay${(showPopup || showEnablePopup || showDisablePopup) ? ' active' : ''}`} />
            {accessLevel.childMenu && accessLevel.childMenu > 1 && <div className="mainBtnWrap">
                <a onClick={formCancelEvent}>Cancel</a>
                <button className="btnStyle" onClick={() => { localStorage.removeItem('formIsDirty'); updateAccountPrivacyDetails() }}>SAVE</button>
            </div>}
            {showPopup && <TwoFactorPopup />}
            {showEnablePopup && <TwoFactorEnablePopup />}
            {showDisablePopup && <TwoFactorDisablePopup />}
            {
                isUpdateSuccessful &&
                <ToastMessage cssClass={msgClass} message={accountUpdateMsg}
                    onClose={() => { setIsUpdateSuccessful(false); }}
                />
            }
            <ConfirmPopup
                showDialog={showDialog}
                message={"The changes are still unsaved. Are you sure you want to leave?"}
                confirmNavigation={onSuccess}
                cancelNavigation={onFaliure}
            />
            <SiteLoader isActive={showLoader} />
        </>
    )
}

export default SettingsSecurity