import React, { useEffect, useState } from "react";
import "./expandEmployeesAppDataModal.css";

import PinIcon from "../../../../../assets/images/pin-icon.svg";
import TabIcon from "../../../../../assets/images/newtab-icon.svg";
import GreySuspend from "../../../../../assets/images/greySuspend.svg";
import GreyDelete from "../../../../../assets/images/greyDelete.svg";

import SearchIcon from "../../../../../assets/images/search-icon.svg";
import DownArrow from "../../../../../assets/images/downarrow.svg";
import DeleteIcon from "../../../../../assets/images/delete-icon.svg";
import AddIconMonitor from "../../../../../assets/images/add-icon-monitor.svg";
import AddIconMonitorDisable from "../../../../../assets/images/add-icon-disable.svg";

import NotAllowed from "../../../../../assets/images/not-allowed-icon.svg";
import { EmployeesAppDataTable } from "../EmployeesAppDataTable";
import ToastMessage from "../../../../../components/common/ToastMessage";
import SiteLoader from "../../../../../components/common/SiteLoader";
import { getAccountLabel } from "../../../../../utils";
import DeleteSuspendModal from "../../../common/NotificationAndAlerts/DeleteSuspendModal";
import { defaultMode, handleFetchEmployeeAppData } from "../../../utils";
import { TooltipKendo } from "../../../common/Tooltip";
import { isDisabled } from "../../../../../components/common/kendo/kendoUtils";
import ActiveIcon from "../../../../../assets/images/kendo-activate.svg";
import greyActiveIcon from "../../../../../assets/images/grey-check.svg";

import RefreshButton from "../../../../../assets/images/refresh-button.svg";
export const ExpandEmployeesAppDataModal = ({
  closePopup,
  handlePinned,
  employeeData,
  setEmployeePaginateMetaData,
  employeePaginateMetaData,
  dropdowndata,
  deletePopup,
  setDeletePopup,
  handleSuccess,
  closeDeletePopup,
  setSelectedDeleteItem,
  openDeletePopup,
  suspendPopup,
  openSuspendPopup,
  closeSuspendPopup,
  handleSuspendEmployees,
  selectedDeleteItem,
  refreshEmployeeData,
  accessLevel,
  setEmployeeData,
  fetchEmployeeAppData,
  setShowChangeStatusModal,
  openActivePopup,
  activePopup,
  handleActiveEmployees,
  employeeBackupData,
  setEmployeeBackupData,
  search,
  setSearch,
  setEmployeePaginateMetaDataPrevious,
  setCurrentTimeAndDateAppEmp,
  setShowAddNewPopup,
  showLoader, 
  setShowLoader,
  TAB_INDEX,
  tabIndex,
  showExpanMore,
  // refreshData,
  // setRefreshData
}) => {
  // const [showLoader, setShowLoader] = useState(false);
  const [mode, setMode] = useState(defaultMode);
  const employeeLabel = getAccountLabel("employee");


  const [toastMsg, setToastMsg] = useState({
    msg: "",
    type: "msgSuccess",
  });

  const [isOpen, setIsOpen] = useState(false);
  const togglePopover = (isHoverIn) => {
    setIsOpen(isHoverIn);
  };
  const [page, setPage] = React.useState({
    skip: 0,
    take: 25,
  });

  const handleOptionChange = (event) => {
    const [firstParam, secondParam] = event.target.value.split(":");

    setEmployeePaginateMetaData((prevState) => ({
      ...prevState,
      queryParams: {
        ...prevState.queryParams,
        searchBy: firstParam,
        searchValue: secondParam,
      },
      expandView:showExpanMore 
    }));
    setSearch("");
  };

  const handleSearch = (evt, prevSearch = "") => {
    const searchText =
      prevSearch.trim().length > 0 ? prevSearch : evt.target.value;

    setSearch(searchText);
    // setSearch(searchText);
    // if (otherData?.setSearchText) otherData?.setSearchText(searchText);
    const newData = employeeData?.data
      ?.map((d, ind) => {
        return {
          ...d,
          id: ind + 1,
        };
      })
      .filter((item) => {
        let match = false;
        for (const property in item) {
          if (
            item[property] &&
            item[property]
              .toString()
              .toLocaleLowerCase()
              .indexOf(searchText.toLocaleLowerCase()) >= 0
          ) {
            match = true;
          }
        }
        return match;
      });

    setEmployeeBackupData(newData);
    // setEmployeeData(newData)
    // setData(newData);
    // let clearedPagerDataState = { ...dataState, take: dataState.take, skip: 0 };
    // const newDataState = processWithGroups(newData, clearedPagerDataState);
    // setResultState(newDataState);
    // setDataState(clearedPagerDataState);
  };

  useEffect(() => {
    

    fetchEmployeeAppData(false,false,showExpanMore);
    setEmployeePaginateMetaDataPrevious(employeePaginateMetaData);
    
  }, [employeePaginateMetaData]);

  useEffect(() => {
    setSearch("");
  }, []);

  // const refreshEmployee = () => {
  //   handleFetchEmployeeAppData(
  //     {
  //       ...employeePaginateMetaData,
  //       queryParams: {
  //         ...employeePaginateMetaData.queryParams,
  //         searchBy: "all",
  //         searchValue: "all",
  //       },
  //     },
  //     setEmployeeData,
  //     setShowLoader,
  //     setCurrentTimeAndDateAppEmp
  //   );
  //   console.log("==============>",employeePaginateMetaData)
  //   setEmployeePaginateMetaDataPrevious(employeePaginateMetaData)
  // };

  console.log(!Array.isArray(selectedDeleteItem), "selectedDeleteItemzzzzzzz");

  return (
    <div>
      <div className="overlayemployee"></div>
      <div className="employeeparentBox">
        <div>
          <h2 className="employeemodalheadingtag">
            <div>
              {/* <span style={{marginRight:"4px"}}>
              <img src={EmployeeAppDataIcon} alt="" />
            </span> */}
             {employeeLabel} App Status
              {/* <TooltipKendo>
                <img
                  src={InfoIcon}
                  style={{ marginLeft: -4, cursor: "pointer" }}
                  onMouseEnter={() => togglePopover(true)}
                  onMouseLeave={() => togglePopover(false)}
                  title="Page shows only present day’s data.
                Please use Reports section for more details."
                />
              </TooltipKendo> */}
            </div>
            <div>
              <TooltipKendo>
                <img
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    window.open("/monitor/employee-app-data");
                  }}
                  src={TabIcon}
                  alt=""
                  title="Open In New Tab"
                />
              </TooltipKendo>
              <TooltipKendo>
                <img
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handlePinned("Employee and App Data");
                    closePopup();
                  }}
                  src={PinIcon}
                  alt=""
                  title="Pin to Monitor Page"
                />
              </TooltipKendo>
            </div>
          </h2>
        </div>

        {
          <>
            {" "}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
                padding: "16px 22px 0px",
              }}
            >
              <div className="employeeiconflexbox">
                <div className="employeemodalsearch">
                  <label htmlFor="">Search</label>
                  <div style={{ position: "relative" }}>
                    <img
                      style={{ position: "absolute", top: 10, left: 10 }}
                      src={SearchIcon}
                      alt=""
                    />
                    <input
                      style={{
                        backgroundColor: !!search && "var(--ag-color-014)",
                      }}
                      type="text"
                      name=""
                      id=""
                      placeholder="Search"
                      value={search}
                      onChange={handleSearch}
                    />
                  </div>
                </div>
                <div className="employeemodalsearch">
                  <label htmlFor="">Filter</label>
                  <div className="employeeinnerBox">
                    <select
                      style={{
                        backgroundColor:
                          employeePaginateMetaData?.queryParams?.searchBy !==
                            "all" && "var(--ag-color-014)",
                      }}
                      value={
                        employeePaginateMetaData?.queryParams?.searchBy +
                        ":" +
                        employeePaginateMetaData?.queryParams?.searchValue
                      }
                      onChange={handleOptionChange}
                    >
                      {dropdowndata.map((option, index) => (
                        <option
                          disabled={option.disabled}
                          key={index}
                          value={option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <img src={DownArrow} className="arrowicon2" />
                  </div>
                </div>
                <div className="" style={{ marginTop: 23.5 }}>
                  <div className="">
                    <TooltipKendo>
                      <img
                        title="Refresh"
                        style={{ cursor: "pointer", width: 31 }}
                        src={RefreshButton}
                        alt="Refresh Button"
                        role="button"
                        onClick={refreshEmployeeData}
                      />
                    </TooltipKendo>
                  </div>
                </div>
              </div>
              <div className="employeeiconflexbox2">
                {employeePaginateMetaData?.queryParams?.searchValue ===
                  "Suspended" && (
                  <TooltipKendo>
                    <button
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                        backgroundColor: "transparent",
                        border:
                          isDisabled("edit", accessLevel) ||
                          selectedDeleteItem.length <= 1
                            ? "none"
                            : "1px solid #00BD9D",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "4px",
                      }}
                      disabled={
                        isDisabled("edit", accessLevel) ||
                        selectedDeleteItem.length <= 1 ||
                        !Array.isArray(selectedDeleteItem)
                      }
                      onClick={() => openActivePopup()}
                    >
                      <img
                        className={
                          isDisabled("edit", accessLevel) ||
                          selectedDeleteItem.length <= 1 ||
                          !Array.isArray(selectedDeleteItem)
                            ? "disabled_field"
                            : ""
                        }
                        title="Bulk Activate"
                        style={{ cursor: "pointer", width: 30.5, padding: 2 }}
                        src={
                          isDisabled("edit", accessLevel) ||
                          selectedDeleteItem.length <= 1 ||
                          !Array.isArray(selectedDeleteItem)
                            ? greyActiveIcon
                            : ActiveIcon
                        }
                        alt=""
                      />
                    </button>
                  </TooltipKendo>
                )}
                <TooltipKendo>
                  <button
                    style={{ border: "none", backgroundColor: "transparent" }}
                    disabled={isDisabled("add", accessLevel)}
                    onClick={() => setShowAddNewPopup(true)}
                  >
                    <img
                      className={
                        isDisabled("add", accessLevel) ? "disabled_field" : ""
                      }
                      title="Add Employee"
                      style={{
                        height: "32px !important",
                        width: "32px !important",
                        cursor: "pointer",
                      }}
                      src={
                        isDisabled("add", accessLevel)
                          ? AddIconMonitorDisable
                          : AddIconMonitor
                      }
                      alt=""
                    />
                  </button>
                </TooltipKendo>
                {employeePaginateMetaData?.queryParams?.searchValue !==
                  "Suspended" && (
                  <TooltipKendo>
                    <button
                      style={{ border: "none", backgroundColor: "transparent" }}
                      disabled={
                        isDisabled("edit", accessLevel) ||
                        selectedDeleteItem.length <= 1 ||
                        !Array.isArray(selectedDeleteItem)
                      }
                      onClick={() => openSuspendPopup("multiple")}
                    >
                      <img
                        className={
                          isDisabled("edit", accessLevel) ||
                          selectedDeleteItem.length <= 1 ||
                          !Array.isArray(selectedDeleteItem)
                            ? "disabled_field"
                            : ""
                        }
                        title="Bulk Suspend"
                        style={{ cursor: "pointer" }}
                        // onClick={() => openSuspendPopup("multiple")}
                        src={
                          isDisabled("edit", accessLevel) ||
                          selectedDeleteItem.length <= 1 ||
                          !Array.isArray(selectedDeleteItem)
                            ? GreySuspend
                            : NotAllowed
                        }
                        alt=""
                      />
                    </button>
                  </TooltipKendo>
                )}

                <TooltipKendo>
                  <button
                    style={{ border: "none", backgroundColor: "transparent" }}
                    disabled={
                      isDisabled("add", accessLevel) ||
                      selectedDeleteItem.length <= 1 ||
                      !Array.isArray(selectedDeleteItem)
                    }
                    onClick={() => openDeletePopup("multiple")}
                  >
                    <img
                      className={
                        isDisabled("add", accessLevel) ||
                        selectedDeleteItem.length <= 1 ||
                        !Array.isArray(selectedDeleteItem)
                          ? "disabled_field"
                          : ""
                      }
                      title="Bulk Delete"
                      style={{ cursor: "pointer" }}
                      // onClick={() => openDeletePopup("multiple")}
                      src={
                        isDisabled("add", accessLevel) ||
                        selectedDeleteItem.length <= 1 ||
                        !Array.isArray(selectedDeleteItem)
                          ? GreyDelete
                          : DeleteIcon
                      }
                      alt=""
                    />
                  </button>
                </TooltipKendo>
              </div>
            </div>
            <EmployeesAppDataTable
              setShowChangeStatusModal={setShowChangeStatusModal}
              employeeData={employeeData}
              deletePopup={deletePopup}
              setDeletePopup={setDeletePopup}
              handleSuccess={handleSuccess}
              closeDeletePopup={closeDeletePopup}
              setSelectedDeleteItem={setSelectedDeleteItem}
              openDeletePopup={openDeletePopup}
              openSuspendPopup={openSuspendPopup}
              refreshEmployeeData={refreshEmployeeData}
              accessLevel={accessLevel}
              setEmployeeData={setEmployeeData}
              setEmployeePaginateMetaData={setEmployeePaginateMetaData}
              employeePaginateMetaData={employeePaginateMetaData}
              selectedDeleteItem={selectedDeleteItem}
              employeeBackupData={employeeBackupData}
              openActivePopup={openActivePopup}
              searchField={search}
              // refreshData={refreshData}
              // setRefreshData={setRefreshData}
            />
          </>
        }
        {deletePopup.open && (
          <DeleteSuspendModal
            heading={"Delete"}
            msg={
              deletePopup.case === "single"
                ? "Are you sure you want to delete this record?"
                : "Are you sure you want to delete the selected records?"
            }
            closePopup={closeDeletePopup}
            onSuccess={() => handleSuccess()}
          />
        )}
        {console.log(selectedDeleteItem, "selectedDeleteItem")}

        {suspendPopup.open && (
          <DeleteSuspendModal
            heading={"Suspend"}
            msg={
              suspendPopup.case === "single"
                ? "Are you sure you want to suspend this"
                : "Are you sure you want to suspend the selected record?"
            }
            closePopup={closeSuspendPopup}
            onSuccess={() => handleSuspendEmployees()}
            selectedDeleteItem={selectedDeleteItem}
          />
        )}

        {activePopup.open && (
          <DeleteSuspendModal
            heading={"Activate"}
            msg={
              activePopup.case === "single"
                ? "Are you sure you want to activate this record?"
                : "Are you sure you want to activate the selected records?"
            }
            closePopup={closeSuspendPopup}
            onSuccess={() => handleActiveEmployees()}
          />
        )}

        {/* <div className="employeemodalpagination">
          <LocalizationProvider language="en">
            <Pager
              skip={page.skip}
              take={page.take}
              total={employeeData?.totalRecordCount}
              buttonCount={3}
              info={true}
              type="numeric"
              pageSizes={[25, 50, 100]}
              previousNext={true}
              onPageChange={handlePageChange}
            />
          </LocalizationProvider>
        </div> */}
        <div className="closebtn">
          <button
            onClick={() => {
              closePopup();
              setEmployeeBackupData(employeeData?.data);
            }}
            className="locationbuttondiv"
          >
            Close
          </button>
        </div>
      </div>

      {/* {showAddNewPopup && <AddNewPopup setShowG2Popup={setShowG2Popup} timeZone={timezone} setToastMsg={setToastMsg} setShowLoader={setShowLoader} closePopup={() => setShowAddNewPopup(false)} nomenclature={nomenclature} setActionPopup={setActionPopup} setMode={setMode} />} */}

      {/* {showAddNewPopup && (
        <AddNewEmployeeModal
          timeZone={timezone}
          setToastMsg={setToastMsg}
          setShowLoader={setShowLoader}
          closePopup={() => setShowAddNewPopup(false)}
          nomenclature={nomenclature}
          setActionPopup={setActionPopup}
          setMode={setMode}
          fetchEmployeeAppData={fetchEmployeeAppData}
        />
      )} */}
      {/* {mode.type === 'jobChange' && <JobStatus timeclockStage={timeclockStage} data={mode.data} closePopup={() => setMode({ ...defaultMode })} setToastMsg={setToastMsg} setShowLoader={setShowLoader} />} */}
      {/* {mode.type === "uploadError" && (
        <FileUploadFail
          data={mode.data}
          closePopup={() =>
            setMode({ ...mode, type: "main", data: null, hasPrev: false })
          }
          setToastMsg={setToastMsg}
          setShowLoader={setShowLoader}
        />
      )} */}
      {toastMsg.msg && (
        <ToastMessage
          cssClass={toastMsg.type}
          message={toastMsg.msg}
          onClose={() => setToastMsg({ msg: "", type: "" })}
        />
      )}


      <div
        style={{
          width: 200,
          position: "absolute",
          left: "40%",
          transform: "translateX(-50%)",
          top: -65,
          background: "white",
          zIndex: 99,
          borderRadius: 5,
          boxShadow: "0px 0px 10px lightgray",
          display: isOpen ? "block" : "none",
        }}
      >
        <div
          style={{ padding: "10px" }}
          dangerouslySetInnerHTML={{
            __html: "data1",
          }}
          Data
        />
      </div>
    </div>
  );
};
