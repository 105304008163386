import React, { useState, Fragment, useEffect } from 'react';
import Header from '../layout/Header';
import { useDocumentTitle } from '../../hooks/setDocumentTitle';
import RunReports from './RunReports/RunReports';
import RunReportTable from './RunReports/RunReportTable';
import EditReport from './EditReport/EditReport';
import ReportScheduler from './ReportScheduler/ReportScheduler';
import { useHistory, useLocation } from 'react-router-dom';
import { getAccessLevel } from '../common/functions';
import BuildReports from './BuildReports/BuildReports';

sessionStorage.removeItem('EditReport:startDate')
sessionStorage.removeItem('EditReport:endDate')
sessionStorage.removeItem('EditReport:reportFor')
sessionStorage.removeItem('AdvanceTimeClockReport:includeTxnIds')

const ReportModuleWrapper = () => {
    useDocumentTitle("Report - allGeo");
    let isAdmin = localStorage.getItem('isAdminUser')


    const history = useHistory()
    const location = useLocation()

    const previousTab = location.search.includes("&") ? "" : location.search.split("=")[1]

    const [modeOuter, setModeOuter] = useState({
        title: "Reports Dashboard",
        type: 'main',
        data: null,
        hasPrev: false
    });

    const [activeTab, setActiveTab] = useState(previousTab || 'runReports');
    const [showDialog, setShowDialog] = useState(false);
    const [clickedTab, setClickedTab] = useState('')
    const [reportAccessLevels, setReportAccesslevels] = useState({
        'buildReports': true,
        'editReports': true,
        'scheduleReports': true
    })

    const handleTabChange = tab => {
        setClickedTab(tab)
        history.push(`${location.pathname}?tab=${tab}`)
        const isFormDirty = localStorage.getItem('formIsDirty')

        if (isFormDirty) {
            setShowDialog(true)
            return
        }

        setActiveTab(tab)
    }

    const handleLinkReportClick = (e, reportType) => {
        const isFormDirty = localStorage.getItem('formIsDirty')

        isFormDirty && e.preventDefault()
        setClickedTab(reportType)

        if (isFormDirty) {
            setShowDialog(true)
            return
        }
    }

    const getReportsAccesslevels = async () => {
        try {
            const response = await Promise.all([
                getAccessLevel('reports', 'build_reports'),
                getAccessLevel('reports', 'edit_reports'),
                getAccessLevel('reports', 'schedule_report')
            ])


            if (activeTab !== 'runReports' &&
                ((activeTab === 'editReports' && +response[1].childMenu === 0) ||
                    (activeTab === 'scheduleReports' && +response[2].childMenu === 0)
                )
            ) {
                history.push(`${location.pathname}?tab=runReports`)
            } else {
                setReportAccesslevels({
                    'buildReports': response[0].childMenu > 0,
                    'editReports': response[1].childMenu > 0,
                    'scheduleReports': response[2].childMenu > 0
                })
            }

        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getReportsAccesslevels()
    }, [])

    return (
        <Fragment>
            <div className='mainContentWrap'>
                <Header title={modeOuter.title} />
                <div class="helpArticleLink"><a href="https://help.allgeo.com/en/articles/4417965-adding-employee-s" target="_blank" class="helpLink">View Help Article</a></div>
                <div className='mainContent' id='mainContent' style={{ marginTop: "20px" }}>
                    <ul className="rightInnerNav editreportrightInnerNav">
                        <li><a onClick={() => handleTabChange('runReports')} className={`${(activeTab === 'runReports' || activeTab === 'reportTable') && 'active'}`}>Run Reports</a></li>
                        {reportAccessLevels.editReports && <li><a onClick={e => handleTabChange('editReports')} className={`${activeTab === 'editReports' && 'active'}`} >Edit reports</a></li>}
                        {reportAccessLevels.scheduleReports && <li><a onClick={() => handleTabChange('scheduleReports')} className={`${activeTab === 'scheduleReports' && 'active'}`}>Schedule reports</a></li>
                        }
                        {
                            reportAccessLevels.buildReports && <li><a onClick={() => handleTabChange('buildReport')} className={`${activeTab === 'buildReport' && 'active'}`} >Build reports</a></li>
                        }
                        {/* {reportAccessLevels.buildReports && <li><a onClick={e => handleLinkReportClick(e, 'buildReport')} className={`${activeTab === 'buildReport' && 'active'}`} href={`${process.env.REACT_APP_API_URL}/track/mgt?page=menu.rpt.buildReports.v1&tab=buildreports`}>Build reports</a></li>} */}
                    </ul>
                    {activeTab === 'runReports' && <RunReports />}
                    {activeTab === 'editReports' && reportAccessLevels.editReports && <EditReport />}
                    {activeTab === 'scheduleReports' && reportAccessLevels.scheduleReports && <ReportScheduler />}
                    {activeTab === 'reportTable' && <RunReportTable />}
                    {activeTab === 'buildReport' && <BuildReports />}
                </div>
            </div>
        </Fragment>
    )
}

export default ReportModuleWrapper