import React, { useState } from "react"
import QRCode from "./QRCode"
import JSZip from "jszip";
import { saveAs } from 'file-saver'
import { jsPDF } from 'jspdf'
import autoTable from 'jspdf-autotable';
import InfoPopup from '../../common/PopUp/InfoPopup'

const zip = new JSZip()

const doc = new jsPDF('p', 'pt', 'a4');

const MAX_CODES_ALLOWED = 200;

const README_CONTENT = `
<html>
    <head></head>
    <body
        style="background-color: #1F8CEB;color: #fff;text-align: center;margin-top: 50px;font-family: Open Sans !important;">
        <h3>Know the files you just downloaded, and how best to use them, here..</h3> <br><br>1. For individual printing of
        QR Codes we have them saved under the QR CODES folder. <br><br> 2. For Bulk printing and QR Content Reference we
        have created a QR SUMMARY pdf file. <br><br>
        <h3>Thank You!</h3>
    </body>
</html>
`

const QRGenerator = ({ accessLevel }) => {

    const [qrCodes, setQrCodes] = useState([
        {
            id: 1,
            title: 'QR-01',
            value: ''
        },
        {
            id: 2,
            title: 'QR-02',
            value: ''
        },
        {
            id: 3,
            title: 'QR-03',
            value: ''
        },
    ]
    )
    const [qrCount, setQRCount] = useState(0)
    const [infoPopup, setInfoPopup] = useState({
        msg: '',
        show: false
    })

    const createZipFile = async () => {
        try {

            if (qrCount === 0) {
                setInfoPopup({
                    msg: 'No QR Code(s) available for download. Please enter text in the required fields to generate QR code',
                    show: true
                })
                return
            }

            //Readme HTML file
            zip.file('README.html', README_CONTENT)

            //Creating an image folder inside zip file
            const img = zip.folder('QR Codes')

            const qrCodeImages = []

            //adding all the qrcodes in png format
            qrCodes.forEach(({ id, title, value }) => {
                if (title?.trim().length > 0 && value?.trim().length > 0) {
                    const codeID = id + ":" + title;

                    const qrCodeURL = document.getElementById(codeID)
                        .toDataURL("image/png")
                        .replace("image/png", "image/octet-stream");

                    let base64 = qrCodeURL;
                    const index = base64.indexOf(",");
                    if (index !== -1) {
                        base64 = base64.substring(index + 1, base64.length);
                    }

                    const imgUrl = document.getElementById(codeID).toDataURL("image/png")
                    qrCodeImages.push(imgUrl)

                    img.file(`${title}.png`, base64, { base64: true })
                }
            })

            //Creating Content for PDF file
            doc.setFontSize(10)

            doc.text(`Company | ${qrCount} QR Code Generated | Date and Time: ${new Date().toString()}`, 40, 25)

            const tableRows = qrCodes.filter(codes => codes.value.length > 0).map(codes => {
                return [codes.title, codes.value, '']
            })

            //Creating Table for QRCodes
            autoTable(doc, {
                head: [['QR ID', 'Content', 'QR Image']],
                body: tableRows,
                startY: 40,
                bodyStyles: {
                    minCellHeight: 120,
                    cellPadding: 10,
                    fillColor: '#f8f8f8'
                },
                didDrawCell: data => {
                    if (data.column.index === 2 && data.cell.section === 'body') {
                        const dim = data.cell.height - data.cell.padding('vertical');
                        const textPos = data.cell.getTextPos();
                        const rowIndex = data.row.index;

                        doc.addImage(qrCodeImages[rowIndex], textPos.x, textPos.y, dim, dim);
                    }
                }
            })

            //QR Code Summary Pdf file
            zip.file('QR Summary.pdf', doc.output('blob'))

            setInfoPopup({
                msg: 'Thanks for downloading QR Code.',
                show: true
            })

            zip.generateAsync({ type: "blob" }).then(function (file) {
                saveAs(file, "allGeo-QR-Codes.zip");
            });

        } catch (error) {
            console.log(error);
        }
    }

    const handleChange = (e, ind, inputType) => {
        const { value } = e.target;
        const arr = [...qrCodes]
        arr[ind][inputType] = value
        setQrCodes(arr)
        if (inputType === 'value') {
            setQRCount(arr.filter(code => code.value).length)
        }
    }

    const addNewCode = ind => {
        if (qrCodes.length < MAX_CODES_ALLOWED && ind === qrCodes.length - 1) {
            const codeId = qrCodes[ind].id + 1;

            const newCode = {
                id: codeId,
                title: `QR-${codeId < 10 ? '0' : ''}${codeId}`,
                value: ''
            }

            const updatedCodes = [...qrCodes, newCode]
            setQrCodes(updatedCodes)
        }
    }

    const deleteCode = ind => {

        const updatedArr = qrCodes.filter((_, id) => id !== ind)

        setQRCount(updatedArr.filter(code => code.value).length)
        setQrCodes(updatedArr)
    }

    return (
        <>
            <section>
                <div className="innerWrap">
                    <div className="sectionHeading">
                        <div>Total QR Codes Generated: <strong>{qrCount}</strong></div>
                        <button className="btnStyle" onClick={createZipFile} disabled={accessLevel < 2} >Download</button>
                    </div>
                    <div className="qrTbl">
                        <div className="qrTblHeading">
                            <div>QR ID</div>
                            <div className="qrCodeCol">QR Code</div>
                        </div>
                        <ul>
                            {
                                qrCodes.map((code, ind) => (
                                    <li key={ind} >
                                        <div className="inputControl">
                                            <input type="text" value={code.title} onChange={e => handleChange(e, ind, 'title')} disabled={accessLevel < 2} />
                                            <textarea onFocus={() => addNewCode(ind)} placeholder="Embed your message here" value={code.value} onChange={e => handleChange(e, ind, 'value')} disabled={accessLevel < 2}></textarea>
                                        </div>
                                        {
                                            code.value
                                                ?
                                                <QRCode
                                                    value={code.value}
                                                    codeID={code.id + ":" + code.title}
                                                />
                                                :
                                                <div className="qrCodeCol">QR Code</div>
                                        }
                                        {ind > 2 && <div onClick={() => deleteCode(ind)} className="qrDelete">Delete</div>}

                                    </li>
                                ))
                            }

                        </ul>
                    </div>
                    <div className="infoTxt">Keep Generating, We will add rows automatically.</div>
                </div>
            </section>
            <div className={`overlay ${(infoPopup.show) ? 'active' : ''}`} />
            {infoPopup.show && <InfoPopup
                msg={infoPopup.msg}
                onClick={() => setInfoPopup({ msg: '', show: false })}
            />}
        </>
    )
}

export default QRGenerator