import { useState } from 'react';

const useInput = (validateValue) => {
  const [enteredValue, setEnteredValue] = useState('');
  const [isTouched, setIsTouched] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const valueIsValid = validateValue(enteredValue);
  const hasError = !valueIsValid && isTouched;

  const valueChangeHandler = (event) => {
    //event.target.setAttribute('autocomplete', 'off');
    setEnteredValue(event.target.value);
  };

  const inputBlurHandler = (event) => {
    //event.target.setAttribute('autocomplete', 'off');
    setIsTouched(true);
    setIsFocused(false);
  };

  const inputFocusHandler = (event) => {
    //event.target.setAttribute('autocomplete', 'off');
    //event.target.removeAttribute('readonly');
    setIsFocused(true);
    setIsTouched(false);
  }

  const reset = () => {
    setEnteredValue('');
    setIsTouched(false);
  };

  const setDefault = (val) => {
    setEnteredValue(val);
    setIsTouched(false);
  }

  return {
    value: enteredValue,
    isValid: valueIsValid,
    hasError,
    isFocused: isFocused,
    valueChangeHandler,
    inputBlurHandler,
    inputFocusHandler,
    reset,
    setDefault
  };
};

export default useInput;