export const getInitialStateFromProps = (WOdata) => {
    return {
        Title: WOdata.Title,
        DeviceName: WOdata.DeviceName,
        Locatable: WOdata.Locatable,
        IsAllDay: WOdata.IsAllDay,
        Description: WOdata.Description,
        SiteName: WOdata.SiteName,
        JobName: WOdata.JobName,
        End: WOdata.End,
        Start: WOdata.Start,
        BillingRate: WOdata.BillingRate,
        GroupName: WOdata.GroupName,
        MasterTripNo: WOdata.MasterTripNo,
        OwnerID: WOdata.OwnerID,
        RecurrenceException: WOdata.RecurrenceException,
        RecurrenceID: WOdata.RecurrenceID,
        Status: WOdata.Status,
        TaskID: WOdata.TaskID,
        expanded: WOdata.expanded,
        id: WOdata.id,
        selected: WOdata.selected,
    };
}

export const getInitialRecuurenceRule = (WOdata, setRepeatOption, setRepeatDaily, setRepeatWeekly, setSelectedOption, setSelectedDays) => {

    let parts;
    const recurrenceRule = {};

    const recurrenceRuleString = WOdata.RecurrenceRule;
    if (recurrenceRuleString) {
        parts = recurrenceRuleString.split(";");
    }

    parts?.forEach(part => {
        const [key, value] = part.split("=");
        recurrenceRule[key] = value;
    });

    if (recurrenceRule.FREQ === "DAILY") {
        setRepeatOption("DAILY");
        if (recurrenceRule.INTERVAL) {
            setRepeatDaily({
                ...setRepeatDaily,
                days: recurrenceRule.INTERVAL
            });
        }
        if (recurrenceRule.COUNT) {
            setRepeatDaily({
                ...setRepeatDaily,
                ocr: recurrenceRule.COUNT
            });
            setSelectedOption("After");
        }
        if (recurrenceRule.UNTIL) {
            setRepeatDaily({
                ...setRepeatDaily,
                endsOn: recurrenceRule.UNTIL
            });
            setSelectedOption("On");
        }
    }

    if (recurrenceRule.FREQ === "WEEKLY") {
        setRepeatOption("WEEKLY");

        if (recurrenceRule.INTERVAL) {
            setRepeatWeekly({
                ...setRepeatWeekly,
                weeks: recurrenceRule.INTERVAL
            });
        } else {
            setRepeatWeekly({
                ...setRepeatWeekly,
                weeks: 1
            });
        }
        if (recurrenceRule.COUNT) {
            setRepeatWeekly({
                ...setRepeatWeekly,
                ocr: recurrenceRule.COUNT
            });
            setSelectedOption("After");
        }
        if (recurrenceRule.UNTIL) {
            setRepeatWeekly({
                ...setRepeatWeekly,
                endsOn: recurrenceRule.UNTIL
            });
            setSelectedOption("On");
        }
        if (recurrenceRule.DAYS) {
            let daysString = recurrenceRule.DAYS;
            setSelectedDays(daysString.split(','));
        }
    }

}

export const getFormattedDateTime = (dateObject) => {
    const date = dateObject.getFullYear() + "/" + (dateObject.getMonth() + 1) + "/" + dateObject.getDate();
    const time = dateObject.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        second: '2-digit',
        hour12: false
    });
    return date + " " + time;
}


export const generateDailyRecurrenceRule = (selectedOption, repeatOption, repeatDaily) => {
    if (selectedOption === "Never") {
        return `FREQ=${repeatOption};INTERVAL=${repeatDaily.days}`;
    } else if (selectedOption === "On") {
        return repeatDaily.endsOn ?
            `FREQ=${repeatOption};INTERVAL=${repeatDaily.days};UNTIL=${repeatDaily.endsOn}` :
            `FREQ=${repeatOption};INTERVAL=${repeatDaily.days}`;
    } else if (selectedOption === "After") {
        return repeatDaily.ocr ?
            `FREQ=${repeatOption};INTERVAL=${repeatDaily.days};COUNT=${repeatDaily.ocr}` :
            `FREQ=${repeatOption};INTERVAL=${repeatDaily.days}`;
    }
    return "";
};

export const generateWeeklyRecurrenceRule = (selectedOption, repeatOption, repeatWeekly) => {
    if (selectedOption === "On") {
        return repeatWeekly.endsOn ?
            `FREQ=${repeatOption};INTERVAL=${repeatWeekly.weeks};UNTIL=${repeatWeekly.endsOn}` :
            `FREQ=${repeatOption};INTERVAL=${repeatWeekly.weeks}`;
    } else if (selectedOption === "After") {
        return repeatWeekly.ocr ?
            `FREQ=${repeatOption};INTERVAL=${repeatWeekly.weeks};COUNT=${repeatWeekly.ocr}` :
            `FREQ=${repeatOption};INTERVAL=${repeatWeekly.weeks}`;
    }
    return "";
};

export const getDeviceIDByDeviceName = (empWithId, deviceName) => {
    for (let i = 0; i < empWithId.length; i++) {
        if (empWithId[i].DeviceName.toLowerCase() === deviceName.toLowerCase()) {
            return empWithId[i].DeviceID;
        }
    }
    return null;
}