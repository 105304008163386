import React from 'react';
import { DateTimePicker } from '@progress/kendo-react-dateinputs'

export const DailyOptions = ({
    selectedOption,
    repeatDaily,
    setSelectedOption,
    setRepeatDaily,
    setIsAnyChangeInField
}) => {
    return (
        <>
            <div className="col_two">
                <label>Repeat Every</label>
                <div className="with_input_sub">
                    <input
                        type="text"
                        value={repeatDaily.days}
                        onChange={(e) => {
                            setRepeatDaily({
                                ...repeatDaily,
                                days: e.target.value
                            });
                            setIsAnyChangeInField(true);
                        }}
                    />
                    <span>Days</span>
                </div>
            </div>
            <div className="col_six">
                <label>Ends</label>
                <div className="one_control">
                    <select
                        className="smlFld"
                        value={selectedOption}
                        onChange={(e) => {
                            setSelectedOption(e.target.value);
                            setIsAnyChangeInField(true);
                        }}
                    >
                        <option value="Never">Never</option>
                        <option value="On">On</option>
                        <option value="After">After</option>
                    </select>
                    {selectedOption === 'On' && (
                        <DateTimePicker
                            placeholder="Select Ends On Date"
                            defaultValue={repeatDaily.endsOn ? new Date(repeatDaily.endsOn) : ""}
                            format="dd-MM-yyyy hh:mm:ss a"
                            onChange={(e) => {
                                const isoString = e.target.value.toISOString();
                                const formattedString = isoString.replace(/[-:]/g, '').replace('T', 'T').slice(0, 15).concat('Z');

                                setRepeatDaily({
                                    ...repeatDaily,
                                    endsOn: formattedString
                                });
                                setIsAnyChangeInField(true);
                            }}
                        />
                    )}
                    {selectedOption === 'After' && (
                        <div className="with_input_aftr">
                            <input
                                type="text"
                                value={repeatDaily.ocr}
                                onChange={(e) => {
                                    setRepeatDaily({
                                        ...repeatDaily,
                                        ocr: e.target.value
                                    });
                                    setIsAnyChangeInField(true);
                                }}
                            />
                            <span>ocr</span>
                        </div>
                    )}
                </div>
            </div>
        </>
    );

};

