import React from "react";
import InboundIcon from "../../../../assets/images/inbound-messages.svg";
import OnboundIcon from "../../../../assets/images/outbound-messages.svg";
import { TooltipKendo } from "../Tooltip";
import { NoData } from "../NoData";
import InfiniteScroll from "react-infinite-scroll-component";
import { getAlertsAndNotificationData } from "../../utils";
export const NotificationAlertsView = ({
  notificationData,
  handlePinned,
  handleUnPinned,
  setCurrentTimeAndDateAlertsNoti,
  notificationAndAlertMetaData,
  setAlertNotificationData,
  hasMore,
  height=320,
  setNotificationAndAlertMetaData,
  timeClockDataLength=0,
  fromTimeClock=false
  
}) => {
  const infiniteScrollFun = () =>{
    
    notificationAndAlertMetaData.page+=1 
    getAlertsAndNotificationData(
      setCurrentTimeAndDateAlertsNoti,
      setNotificationAndAlertMetaData,
      notificationAndAlertMetaData,
      setAlertNotificationData,
      false,
    );
  }
  
  return (
    <div  >
      <div  id="scrollableDiv" className="notificationContentContainer">
        <InfiniteScroll
        dataLength={notificationData.length}
        next={()=>infiniteScrollFun()}
        loader={<h4 style={{paddingBottom:"12px"}}>Loading....</h4>}
        hasMore={notificationAndAlertMetaData.totalrecords!==notificationAndAlertMetaData.paginatedRecords}
        scrollableTarget="scrollableDiv"
        height={height}
        style={{paddingBottom:20}}
        
        >
          {notificationData.length < 1 && <NoData />}
          {!!notificationData &&
            notificationData?.map((notification) => (
              <div key={notification?.id} className="notificationFlex">
                <TooltipKendo>
                  <div 
                    style={{ cursor: "pointer",width:16,height:16 }}
                  
                  >

                  <img
                    title={
                      notification?.incoming
                      ? "Inbound Message"
                      : "Outbound Message"
                    }
                    style={{ cursor: "pointer",width:16,height:16 }}
                    src={notification?.incoming ? OnboundIcon : InboundIcon}
                    alt="Icon"
                    />
                  </div>
                </TooltipKendo>
                {!notification?.break && (
                  <div style={{ display: "flex" }}>
                    <div className="profileimage">
                      {notification?.deviceName?.[0]?.split(" ")?.[1]?.charAt(0)
                        ? notification?.deviceName?.[0]
                            ?.split(" ")?.[0]
                            ?.charAt(0) +
                          notification?.deviceName?.[0]
                            ?.split(" ")?.[1]
                            ?.charAt(0)
                        : notification?.deviceName?.[0]
                            ?.split(" ")?.[0]
                            ?.charAt(0)}
                    </div>

                    <TooltipKendo>
                      {notification?.deviceName?.length > 1 && (
                        <div
                          title={notification?.deviceName}
                          className="profileimage2"
                        >
                          +{notification?.deviceName?.length - 1}
                        </div>
                      )}
                    </TooltipKendo>
                  </div>
                )}
                <div className="profileBox">
                  <div>
                    <span
                      className={
                        notification?.message !== "SOS"
                          ? "profilename2"
                          : "profilename"
                      }
                    >
                      {notification?.deviceName?.length > 1
                        ? `${notification?.deviceName[0]} and ${
                            notification?.deviceName?.length - 1
                          } others status is`
                        : `${notification?.deviceName?.[0]}'s status is`}
                    </span>
                    {!!notification?.messageAll && (
                      <span
                        style={{
                          textTransform:
                            notification?.message == "hos"
                              ? "uppercase"
                              : "capitalize",
                        }}
                        className={notification?.message !== "SOS"?"inbox":"sosbox"}
                      >
                        {notification?.messageAll}
                      </span>
                    )}
                  </div>
                  <div className="timecontent">
                    {notification?.notificationTime}
                  </div>
                </div>
                {/* <div className="yellowcircle"></div> */}
              </div>
            ))}
        </InfiniteScroll>
      </div>
    </div>
  );
};
