import React, { Fragment } from "react"

const ConfirmPopup2nd = (props) => {
    return (
        <Fragment>
            <div className={`popupWrap msgPopup popupWrap2nd ${props.showDialog ? 'active' : ''}`}>
                <div className="msgPopupCont">
                    <div className="msgIcon">Info</div>
                    <div className="msgContent">
                        <div>{props.message}</div>
                    </div>
                </div>
                <div className="popupMsgBtn">
                    <a href={void (0)} className="okBtn" onClick={props.cancelNavigation}>Cancel</a>
                    <button className="btnStyle okBtn" onClick={props.confirmNavigation}>Yes</button>
                </div>
            </div>
            <div className={`overlay2nd${props.showDialog ? ' active' : ''}`} />
        </Fragment>
    )
}

export default ConfirmPopup2nd;