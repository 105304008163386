import React from 'react';

export const ActiveScheduleHeaders = ({ refreshList, otherData, handleSearch, search }) => {
    return (
        <div className="left_inner_header">
            <div className="search_field active">
                <label htmlFor="search">Search</label>
                <input
                    placeholder={otherData.searchPlaceholder || 'Search'}
                    value={search}
                    onChange={handleSearch}
                />
            </div>

            <button
                className="btn_style_schedule btn2 refresh_btn tooltip_cal_sm"
                onClick={()=>{refreshList()}}
            >
                <span>Refresh</span>
            </button>
        </div>
    );
};

