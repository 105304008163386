import { apiProvider } from '../../services/api/provider';
import { useHistory, NavLink, useLocation } from "react-router-dom";
import RefreshIcon from '../../assets/images/refresh.svg'
import React, { useEffect, useState, useContext } from "react";
import AuthContext from '../../store/auth-context';
import { getAccessLevel } from '../common/functions';
import { helpCenterLinks } from './SiteNavigation';
import { Tooltip } from '@progress/kendo-react-tooltip';
import axios from 'axios';
import InfoIcon from "../../assets/images/info-icon.svg";
import { TooltipKendo } from '../../pages/Monitor/common/Tooltip';
import './Header.css'

const Header = (props) => {
    const history = useHistory();
    const authCtx = useContext(AuthContext);

    const [childAccounts, setChildAccounts] = useState([]);
    const [hasChildAccount, setHasChildAccount] = useState(false);
    const [userName, setUserName] = useState('');
    const [accountId, setAccountId] = useState('');
    const [avater, setAvater] = useState('');
    const [masterAccount, setMasterAccount] = useState({});
    const [menuAccessLevels, setMenuAccessLevels] = useState({})

    const childAccountID = localStorage.getItem('childAccountId')
    const [isOpen, setIsOpen] = useState(false);
    const [moniter, setMoniter] = useState(true);

    const logoutRedirect = () => {
        history.replace('/logout');
    }

    const isNewMonitorPage =location.pathname==="/monitor/new-monitor-employees"||location.pathname==="/scheduler";
    const isRelevantPage = props.title === "Monitor" || props.title === "Scheduler";


    useEffect(() => {
        if (location.pathname === '/monitor/new-monitor-employees') {
            setMoniter(true);
        } 
        else if (location.pathname === '/scheduler/work-order') {
            setMoniter(false);
        }
    }, [location.pathname]);

    const handleMonitorClick = () => {
        setMoniter(true);
        history.push(`/monitor/new-monitor-employees`)
    };

    const handleSchedulerClick = () => {
        setMoniter(false);
        history.push(`/scheduler`)
    };


    const getMenuAccessLevel = async () => {
        try {
            const promises = []
            promises.push(getAccessLevel('company', 'settings'))
            promises.push(getAccessLevel('company', 'change_password'))
            promises.push(getAccessLevel('company', 'billing'))

            const response = await Promise.all(promises)

            setMenuAccessLevels({
                settings: response[0],
                changePassword: response[1],
                billing: response[2]
            })
        } catch (err) {
            console.log(err)
        }
    }

    const locationHook = useLocation();
    const isHomePage = locationHook.pathname === '/home';

    useEffect(async () => {
        let isParent = localStorage.getItem('isParent');
        let userName = localStorage.getItem('userName');
        let accountId = localStorage.getItem('accountId');
        let masterAccount = localStorage.getItem('parent');
        let avatarUrl = '';

        if (accountId) {
            setAccountId(accountId)
        }
        if (userName) {
            setUserName(userName);
            avatarUrl = 'https://ui-avatars.com/api/?name=' + userName + '&background=fecc0e&color=fff&rounded=false&bold=true&length=2&font-size=.5';
            setAvater(avatarUrl);
        }
        if (isParent == 'true') {
            setHasChildAccount(true);
        }

        if (masterAccount) {
            setMasterAccount(JSON.parse(masterAccount));
        }

        const getChildAccount = async () => {
            const fromLocalStorage = localStorage.getItem("chieldAccounts");
            if (fromLocalStorage) return fromLocalStorage;
            else if (isParent) {
                try {
                    const response = await apiProvider.getAll(
                        "track/react/v1/childaccounts"
                    );

                    if (response) {
                        console.log("Child Account Response", response);
                        return response;
                    }
                } catch (error) {
                    console.error("Error fetching child accounts:", error);
                }
            }
        };

        let childAccount = await getChildAccount();
        const isChildAccountsStored = localStorage.getItem("childAccountsStored");

        if (!isChildAccountsStored) {
            if (childAccount) {
                localStorage.setItem("chieldAccounts", JSON.stringify(childAccount));
                localStorage.setItem("childAccountsStored", "true");
            }
        }
        const getCleanToken = (token) => {
            try {
                const decodedToken = decodeURIComponent(token);
                const cleanedToken = encodeURIComponent(decodedToken);
                return cleanedToken;
            } catch (error) {
                console.error("Error cleaning token:", error);
            }
        }
        const isServerSessionActive = localStorage.getItem('serversession');

        const tokenEn = localStorage.getItem('tokenEn');
        const cleanToken = getCleanToken(tokenEn);
        const serverLoginPayload = {
            "loginToken": cleanToken,
            "application": "webapp",
            "source": "react"
        }
        if (!isServerSessionActive) {
            try {
                const axiosResponse = await apiProvider.post(`track/Track`, serverLoginPayload);
                if (axiosResponse.status == "200") localStorage.setItem('serversession', true);
                else console.error("Failed to login to server");
            } catch (error) {
                console.error(error);
            }
        }

        if (childAccount) {
            try {
                if (typeof childAccount == 'string')
                    setChildAccounts(JSON.parse(childAccount))
                else
                    setChildAccounts(childAccount)
            } catch (error) {
                console.log(error);
            }
        }

        if (!isParent || isParent == 'undefined') {

            apiProvider.getAll('track/react/v1/logout').then((res) => {
                if (res.status === 200) {
                    logoutRedirect()
                }
            })
        }
        if ((isParent === 'true' && !childAccount) || (isParent === 'true' && childAccount == 'undefined')) {
            apiProvider.getAll('track/react/v1/logout').then((res) => {
                if (res.status === 200) {
                    logoutRedirect();
                }
            })
        }
        getMenuAccessLevel()
    }, []);

    const logoutHandler = (event) => {
        let isFormDirty = localStorage.getItem('formIsDirty')
        event.preventDefault();
        if (!isFormDirty) {
            apiProvider.getAll('track/react/v1/logout').then((res) => {
                if (res.status === 200) {
                    history.replace('/logout');
                }
            })
        } else {
            history.replace('/logout');
        }
    }
    const togglePopover = (isHoverIn) => {
        setIsOpen(isHoverIn);
    };
    const switchToChildAccount = async (childObj) => {
        try {
            const axiosResponse = await axios.get(`${process.env.REACT_APP_API_URL}/track/react/account/generalSetting`, {
                headers: {
                    Authorization: `Bearer${childObj.token}`,
                    'Content-Type': 'application/json',
                },
            });
            localStorage.setItem('isNewMonitorPage',axiosResponse?.data?.newMonitorPage)


            localStorage.setItem('employeeAppData',axiosResponse?.data?.monitorCards?.employeeAppData)
            localStorage.setItem('mapAndLocation',axiosResponse?.data?.monitorCards?.mapAndLocation)
            localStorage.setItem('milesData',axiosResponse?.data?.monitorCards?.milesData)
            localStorage.setItem('notificationsAndAlerts',axiosResponse?.data?.monitorCards?.notificationsAndAlerts)
            localStorage.setItem('timeClockData',axiosResponse?.data?.monitorCards?.timeClockData)
            localStorage.setItem('timeClockReports',axiosResponse?.data?.monitorCards?.timeClockReports)

            localStorage.setItem('accountLabel', JSON.stringify(axiosResponse?.data?.nomenclature));
            localStorage.setItem('sysAdminG2Enabled', JSON.stringify(axiosResponse?.data?.report_units?.g2ReviewEnabled))

            console.log(window.location.href===`${process.env.REACT_APP_API_URL}/monitor/monitor-employees`,"window.location.href")
            if(window.location.href===`${process.env.REACT_APP_API_URL}/monitor/new-monitor-employees`||window.location.href===`${process.env.REACT_APP_API_URL}/monitor/monitor-employees`||window.location.href===`${process.env.REACT_APP_API_URL}/scheduler`||window.location.href===`${process.env.REACT_APP_API_URL}/monitor/locate-schedule`){

                debugger
                history.push(`${!!axiosResponse?.data?.newMonitorPage ?  `/monitor/new-monitor-employees` : "/monitor/monitor-employees"}`)
              }

        } catch (err) {
            console.error(err)
        }
        const expirationTime = new Date(new Date().getTime() + +childObj.expiry);
        localStorage.setItem('updateMenuItems', JSON.stringify(true))
        authCtx.childlogin(childObj.token, childObj.en_token, expirationTime.toISOString(), childObj.accountId, childObj.userName);
        setTimeout(() => {
            window.location.reload(true);
        }, 1000)

        try {
            localStorage.setItem('childAccountId', childObj.accountId)
            apiProvider.getSingle(`/track/accountSessionSet?childAccount=${childObj.accountId}`)
        } catch (error) {
            console.log(error)
        }

    }

    const switchToParentAccount = async (parentAccountData = '', isAllAccount = false) => {
        if (parentAccountData) {
            try {
                const axiosResponse = await axios.get(`${process.env.REACT_APP_API_URL}/track/react/account/generalSetting`, {
                    headers: {
                        Authorization: `Bearer${parentAccountData.token}`,
                        'Content-Type': 'application/json',
                    },
                });
                localStorage.setItem('isNewMonitorPage',axiosResponse?.data?.newMonitorPage)

                localStorage.setItem('employeeAppData',axiosResponse?.data?.monitorCards?.employeeAppData)
                localStorage.setItem('mapAndLocation',axiosResponse?.data?.monitorCards?.mapAndLocation)
                localStorage.setItem('milesData',axiosResponse?.data?.monitorCards?.milesData)
                localStorage.setItem('notificationsAndAlerts',axiosResponse?.data?.monitorCards?.notificationsAndAlerts)
                localStorage.setItem('timeClockData',axiosResponse?.data?.monitorCards?.timeClockData)
                localStorage.setItem('timeClockReports',axiosResponse?.data?.monitorCards?.timeClockReports)

                localStorage.setItem('accountLabel', JSON.stringify(axiosResponse?.data?.nomenclature));
                localStorage.setItem('sysAdminG2Enabled', JSON.stringify(axiosResponse?.data?.report_units?.g2ReviewEnabled));

            console.log(window.location.href,"window.location.href")

                if(window.location.href===`${process.env.REACT_APP_API_URL}/monitor/new-monitor-employees`||window.location.href===`${process.env.REACT_APP_API_URL}/monitor/monitor-employees`||window.location.href===`${process.env.REACT_APP_API_URL}/scheduler`||window.location.href===`${process.env.REACT_APP_API_URL}/monitor/locate-schedule`){
                    history.push(`${!!axiosResponse?.data?.newMonitorPage ?  "/monitor/new-monitor-employees" : "/monitor/monitor-employees"}`)
                  }
                
            } catch (err) {
                console.error(err)
            }
            const isBannerEnabled = localStorage.getItem("isBannerEnabled") ? JSON.parse(localStorage.getItem("isBannerEnabled")) : false;
            const bannerText = localStorage.getItem("bannerText");
            localStorage.setItem('updateMenuItems', JSON.stringify(true))
            authCtx.login(parentAccountData.token, parentAccountData.token_en, parentAccountData.expiry, true, parentAccountData.accountId, parentAccountData.userName, isBannerEnabled, bannerText);
            setTimeout(() => {
                window.location.reload(true);
            }, 1000)
        }
        try {

            if (isAllAccount) {
                localStorage.setItem('childAccountId', 'all')
            } else {
                localStorage.setItem('childAccountId', parentAccountData.accountId)
            }

            apiProvider.getSingle(`/track/accountSessionSet?childAccount=${isAllAccount ? 'all' : parentAccountData.accountId}`)
        } catch (error) {
            console.log(error)

        }
    }

    return (

        <div className="headerBar" id='header_section'>
            {
                isNewMonitorPage && isRelevantPage ?

                    <div className='moniterHeader'>
                        <div onClick={handleMonitorClick} className={`moniterHeaderItem ${moniter ? "active" : null}`}>
                            <div className='image' ></div>
                            <span>Monitor</span>
                        </div>
                        <div className='moniterHeaderinfo'>
                            <TooltipKendo>
                                <img title='Page shows only present day’s data.
                                Please use Reports section for more details.' src={InfoIcon} />
                            </TooltipKendo>
                        </div>
                        <div onClick={handleSchedulerClick} className={`moniterHeaderItem ${!moniter ? "active" : null}`}>
                            <div className='image' ></div>
                            <span>Scheduler</span>
                        </div>
                    </div>

                    :

                    <h2>{props.title}
                        {
                            props.showHelpToolTip &&
                            <div className='helpTipRight withHelpTxt1'>
                                ?
                                <span>
                                    <a href={props.helpToolTipUrl} target='_blank' rel='noopener noreferrer' className="helpLink" >
                                        View Help Article
                                    </a>
                                </span>
                            </div>
                        }
                        {
                            props.showRefreshIcon &&
                            <span onClick={props.refreshAction}><img src={RefreshIcon} alt="refresh" /></span>
                        }
                    </h2>
            }

            {!isHomePage && <NavLink className="homeBtn" to="/home">
                Home
            </NavLink>}
            <div className="userInfo">
                <span className="userAvatar">
                    <img src={avater} />
                </span> {accountId}
                <div className="userMenu">
                    <ul>
                        <li className='managerName'>{userName}</li>
                        <li className={childAccountID !== 'all' && accountId == masterAccount.accountId ? 'active' : ''}>
                            {
                                hasChildAccount ?
                                    <a onClick={() => switchToParentAccount(masterAccount)} className="userParent cursorPointer">
                                        <span className="userAvatar" id='user_avatar'><img src={avater} /></span> {
                                            masterAccount.accountId ? masterAccount.accountId : ''
                                        }
                                    </a> :
                                    <a onClick={() => switchToParentAccount()} className="userParent cursorPointer">
                                        <span className="userAvatar" id='user_avatar'><img src={avater} /></span> {
                                            masterAccount.accountId ? masterAccount.accountId : ''
                                        }
                                    </a>
                            }
                        </li>
                        {
                            hasChildAccount && Array.isArray(childAccounts) && childAccounts.length > 0 ?
                                childAccounts.map((childAccount, index) => (
                                    <li key={index} className={accountId === childAccount.accountId ? 'active' : ''}>
                                        <a onClick={() => switchToChildAccount(childAccount)} className="iconTrial userIcon userChild cursorPointer">
                                            {childAccount.accountId}
                                        </a>
                                    </li>
                                )) : null
                        }
                        {
                            hasChildAccount ?
                                <li className={`${childAccountID === 'all' ? 'active' : ''}`} ><a onClick={() => switchToParentAccount(masterAccount, true)} className="iconAllAccount userIcon userChild cursorPointer">All Account</a></li> : ''
                        }

                        {menuAccessLevels.settings?.childMenu > 0 && <li>
                            <NavLink to="/company/settings" className="iconSettings userIcon" activeclassname="active">Settings</NavLink>
                            <ul>
                                <li>
                                    <a href={`${process.env.REACT_APP_API_URL}/track/mgt?page=mysetup.info.v1&q=${Math.floor(Math.random() * 9999999)}`}>My Onboarding</a>
                                </li>
                            </ul>
                        </li>}
                        {/* menuAccessLevels.billing?.childMenu > 0 && <li>
                            <NavLink to="/company/billing" className="iconBilling userIcon" activeclassname="active">Billing</NavLink>
                        </li> */}
                        {menuAccessLevels.changePassword?.childMenu > 0 && <li>
                            <a href={`${process.env.REACT_APP_API_URL}/track/mgt?page=passwd.v1&q=${Math.floor(Math.random() * 9999999)}`} className="iconPassword userIcon cursorPointer" activeclassname="active">Change Password</a>
                        </li>}
                        <li><a onClick={logoutHandler} className="iconLogout userIcon cursorPointer">Logout</a></li>
                    </ul>
                </div>
            </div>

        </div>
    )
}
export default Header;