import React from 'react';

export const ModalTabs = ({ activeTab, handleTabClick }) => {
    return (
        <ul className="modal_inner_tabs">
            <li className='manual_tab'>
                <a href="/" className={activeTab === 'manual' ? 'active' : ''}
                    onClick={(e) => handleTabClick('manual', e)}>
                    Manual Upload
                </a>
            </li>
            <li className='bulk_tab '>
                <a href="/" onClick={(e) => handleTabClick('bulk', e)} className={activeTab === 'bulk' ? 'active' : ''}>
                    Bulk Upload
                </a>
            </li>
        </ul>
    );
};

export default ModalTabs;
