import React, { Fragment, useState, useEffect } from "react"
import LoginAccountId from "./LoginAccountId";
import LoginEmail from "./LoginEmail";

export default function Login({setContactPhoneNumber, setIs2FAEnabled, setis2FAChecked, setUserID, setAccountID}) {

  const [loginFormDisplay, setloginFormDisplay] = useState('accountId');
  useEffect(() => {
    localStorage?.clear();
  }, [])
  
  useEffect(() => {
    console.log('******* INSIDE LOGIN COMPONENT **********')
    let lastLoggedInForm = localStorage.getItem('lastLoggedInForm');
    if(!lastLoggedInForm || lastLoggedInForm === 'accountId'){
      setloginFormDisplay('accountId');

    } else {
      setloginFormDisplay('email');
    }
    localStorage.removeItem('formIsDirty')
  },[]);

  const toggleShowHide = () => {
    if(loginFormDisplay === 'accountId'){
      setloginFormDisplay('email');
    } else {
      setloginFormDisplay('accountId');
    }
  };

  
  return (
    <Fragment>
      {loginFormDisplay === 'email' ? <LoginEmail toggleButtonOnClick={toggleShowHide} /> : 
        <LoginAccountId setAccountID={setAccountID} setContactPhoneNumber={setContactPhoneNumber} setUserID={setUserID} toggleButtonOnClick={toggleShowHide} setIs2FAEnabled={setIs2FAEnabled} setis2FAChecked={setis2FAChecked}/> 
      }
    </Fragment>
  );
  
}