import React, { useState, useEffect } from 'react'
import { getAccountLabel } from '../../../utils'

const colorPallete = ["red", "cornflowerblue", "dodgerblue", "yellow"]

const EmployeeTable = ({ data }) => {

    const [page, setPage] = useState({
        skip: 0,
        take: 10
    })

    const [employeesData, setEmployeesdata] = useState([])
    const [employeesBackupData, setEmployeesBackupData] = useState([])
    const [searchText, setSearchText] = useState('')

    const handlePageChange = (evt, type) => {
        const val = +evt.target.value

        if (type === 'skip') {
            setPage({
                ...page,
                skip: page.take * val,
            })
        } else {
            setPage({
                ...page,
                skip: 0,
                take: val
            })
        }

    }

    const handleSearch = (evt) => {

        const searchText = evt.target.value;

        setSearchText(searchText)
        setEmployeesdata(employeesBackupData.filter(d => {
            return d.devID.toString().toLowerCase().includes(searchText.toLowerCase()) ||
                d.desc.toLowerCase().includes(searchText.toLowerCase())
        }))

    }

    const handleSort = (evt) => {

        sortData(evt, employeesData, setEmployeesdata)
        sortData(evt, employeesData, setEmployeesBackupData)
    }

    const sortData = (evt, data, setData) => {
        const [field, order] = evt.target.value.split(":")
        setData([...data.sort((a, b) => sortBy(a, b, field, order))])
    }

    const sortBy = (a, b, field, order) => {
        if (a[field].toString().toLowerCase() > b[field].toString().toLowerCase()) {
            return order === "Asc" ? 1 : -1;
        } else if (a[field].toString().toLowerCase() < b[field].toString().toLowerCase()) {
            return order === "Asc" ? -1 : 1;;
        } else return 0
    }

    useEffect(() => {
        setEmployeesdata(data?.map(d => {
            return {
                ...d,
                color: colorPallete[Math.floor(colorPallete.length * Math.random())]
            }
        }))
        setEmployeesBackupData(data.map(d => {
            return {
                ...d,
                color: colorPallete[Math.floor(colorPallete.length * Math.random())]
            }
        }))
    }, [data])

    const employeeLabel = getAccountLabel('employee');
    
    return (
        <div>
            <div className={`empSelectBlk ${employeesBackupData.length === 0 ? 'addedEmp' : ''}`}>
                <div className="empSelectCtrl">
                    <input
                        type="search"
                        placeholder={`Search by ${employeeLabel} ID or Name`}
                        value={searchText}
                        onChange={evt => handleSearch(evt)}
                        disabled={employeesBackupData.length === 0}
                    />
                    <select
                        placeholder='Sort by'
                        onChange={evt => handleSort(evt)}
                        disabled={employeesBackupData.length === 0}
                    >
                        <option disabled selected hidden>Sort By</option>
                        <option value="desc:Asc">{employeeLabel} Name (A-Z)</option>
                        <option value="desc:Desc">{employeeLabel} Name (Z-A)</option>
                        <option value="devID:Asc">{employeeLabel} Id (Ascending)</option>
                        <option value="devID:Desc">{employeeLabel} Id (Descending)</option>
                    </select>
                </div>
                <div className="empListTbl">
                    <table cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr>
                                <th>{employeeLabel} Name</th>
                                <th>{employeeLabel} ID</th>
                                <th className="empActionCol"></th>
                            </tr>
                            {
                                employeesData.slice(page.skip, page.take + page.skip).map((d, ind) => (
                                    <tr key={ind}>
                                        <td><span className="empAvtr" style={{ backgroundColor: d.color }}>{d.desc?.slice(0, 1).toUpperCase()}</span> {d.desc}</td>
                                        <td>{d.devID}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                <div className="empSelectFoot">
                    <span>There are a total of <strong>{employeesData.length} {employeeLabel}</strong></span>
                </div>
            </div>
            <div className="paginationWrap">
                <div className="countPerPage">
                    <span>Show:</span>
                    <select
                        onChange={evt => handlePageChange(evt, "take")}
                        disabled={employeesBackupData.length === 0}
                    >
                        <option>10</option>
                        <option>20</option>
                        <option>50</option>
                        <option>100</option>
                    </select>
                </div>
                <div className="showingPage">
                    <span>Showing {Math.ceil((page.skip + 1) / page.take) || 1}-{Math.ceil(employeesData.length / page.take) || 1}</span>
                    <select
                        onChange={evt => handlePageChange(evt, "skip")}
                        disabled={employeesBackupData.length === 0}
                    >
                        {
                            [...Array(Math.ceil(employeesData.length / page.take))].map((_, ind) => (
                                <option key={ind} value={ind} >{ind + 1}</option>
                            ))
                        }
                    </select>
                </div>
            </div>
        </div>
    )
}

export default EmployeeTable