import React from 'react'

const FileUploadErrorMsg = ({ closeErrorPopup, errorMsg = 'We have encountered some errors in the upload file. Kindly check and make corrections.' }) => {
    return (
        <div className="popupWrap msgPopup msgError active">
            <div className="msgPopupCont">
                <div className="msgIcon errIcon">Icon</div>
                <div className="msgContent">
                    <div>{errorMsg}</div>
                </div>
            </div>
            <div className="popupMsgBtn">
                <a className="okBtn" onClick={closeErrorPopup} >Cancel</a>
                {/* <button className="btnStyle okBtn">See Details</button> */}
            </div>
        </div>
    )
}

export default FileUploadErrorMsg