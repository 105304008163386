import React from "react";
import { PinnedLayoutBox } from "../../common/PinnedLayout/PinnedLayoutBox";
import { MapViewData } from "./MapViewData";

export const MapLocationPinned = ({ handlePinned, handleUnPinned,breadcrumbData }) => {
  return (
    <PinnedLayoutBox>
       <MapViewData
          handlePinned={handlePinned}
          handleUnPinned={handleUnPinned}
          // closePopup={closePopup}
          // iframeRef={iframeRef}
          breadcrumbData={breadcrumbData}
          fromPinned={true}
        />
    </PinnedLayoutBox>
  );
};
