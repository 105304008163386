import React from "react";
import { PinnedLayoutBox } from "../../common/PinnedLayout/PinnedLayoutBox";
import { MilesDataViewTable } from "./MilesDataViewTable";

export const MilesDataPinned = ({
  handlePinned,
  handleUnPinned,
  setMilesPaginateMetaData,
  milesData,
  milesBackupData,
  setMilesBackupData,
}) => {
  return (
    <PinnedLayoutBox>
      <MilesDataViewTable
        milesBackupData={milesBackupData}
        setMilesBackupData={setMilesBackupData}
        setMilesPaginateMetaData={setMilesPaginateMetaData}
        handlePinned={handlePinned}
        handleUnPinned={handleUnPinned}
        fromPinned={true}
        milesData={milesData}
      />
    </PinnedLayoutBox>
  );
};
