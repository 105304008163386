import { apiProvider } from "../../../../services/api/provider";
import { handleFetchEmployeeAppData } from "../handleFetchEmployeeAppData";

export const handleDeleteEmployee = async (
  deletePopup,
  selectedDeleteItem,
  closeDeleteSuspendPopup,
  setDeletePopup,
  employeePaginateMetaData,
  setEmployeeData,
  setShowLoader,
  setToastMsg,
  setCurrentTimeAndDateAppEmp,
  setSelectedDeleteItem
) => {
  try {
    setShowLoader(true);
    let response;
    if (deletePopup.case === "single") {
      response = await apiProvider.remove(
        `track/mobile/v1/allgeo/devices/${selectedDeleteItem}`
      );
    }
    if (deletePopup.case === "multiple") {
      response = await apiProvider.removeObject(
        `track/mobile/v1/allgeo/devices/delete`,
        selectedDeleteItem
      );
    }
    handleFetchEmployeeAppData(
      employeePaginateMetaData,
      setEmployeeData,
      setShowLoader,
      setCurrentTimeAndDateAppEmp
    );
    setSelectedDeleteItem([])
    setToastMsg({
      msg: response?.message,
      type: "msgSuccess",
    });
    return response;
  } catch (error) {
    console.log(error);
    setToastMsg({
      msg: error.message,
      type: "msgError",
    });
  } finally {
    setShowLoader(false);
    closeDeleteSuspendPopup();
    // setDeletePopup({ open: false, case: "single" });
  }
};
