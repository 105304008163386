import React, { useState, useEffect } from "react";
import KendoGrid from '../../common/kendo/KendoGrid';
import { apiProvider } from '../../../services/api/provider';
import ToastMessage from '../../common/ToastMessage';
import SiteLoader from "../../common/SiteLoader";
import BillingPopUp from "./BillingPopUp";

export default function BillingInvoice(props) { 

    const [invoiceList, setInvoiceList] = useState(null)
    const [toastMsg, setToastMsg] = useState({
        msg: "",
        type: "msgSuccess"
    });
    const [selectedBillingLink, setSelectedBillingLink] = useState('');
    const [showLoader, setShowLoader] = useState(false);

    const getInvoiceList = async () => {
        try {
            setShowLoader(true);
            const response = await apiProvider.getAll('/track/mobile/v1/allgeo/charge')
            setInvoiceList(response.charges)
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    useEffect(() => {
        getInvoiceList()
    }, []);

    const payerServiceColumns = [
        {
            title: "Invoice Description",
            field: "chargeDesc",
            editable: false,
            width: 200,
            editor: "text",
            show: true,
            filter: "text",
            customCell: true,
            mandatory: true
        },
        {
            title: "Transaction ID",
            field: "invoiceId",
            editable: false,
            width: 200,
            editor: "text",
            columnMenu: false,
            show: true,
            dropdownCell: false,
            mandatory: false
        },
        {
            title: "Charge ID",
            field: "chargeId",
            editable: false,
            width: 100,
            editor: "text",
            columnMenu: false,
            show: false,
            filter: "text",
            mandatory: true
        },
        {
            title: "Charge Amount (USD)",
            field: "chargeAmount",
            editable: false,
            width: 150,
            editor: "text",
            show: true,
            filter: "text",
            mandatory: true
        },
        {
            title: "Status",
            field: "status",
            editable: false,
            width: 150,
            editor: "text",
            show: true,
            filter: "text",
            mandatory: true
        },
        {
            title: "Charge Date",
            field: "chargeDate",
            editable: false,
            width: 150,
            editor: "text",
            show: true,
            filter: "text",
            mandatory: true
        },
        
    ];

    const actionHandler = (type, data) => {
        
        if (data.invoiceURL) {
            if (data.invoiceURL != '') {
                window.open(data.invoiceURL, '_blank');
            } else {
                setToastMsg({ msg: "Invoice is not available.", type: "msgError" });
            }
        } else {
            setToastMsg({ msg: "Invoice is not available.", type: "msgError" });
        }
        
    }

    const openPopup = (data) => {
        
        if (!data.invoiceURL) {
            setToastMsg({ msg: "Invoice is not available.", type: "msgError" });
        } else {
            let params = `scrollbars=yes,resizable=yes,status=no,location=no,toolbar=no,menubar=no,width=800,height=600,left=500,top=200`;
            open(data.invoiceURL, 'AllGeo', params);
        }
    }

    const closePopUp = () => {
        setSelectedBillingLink('');
    }

    return (
        <>
            <div className="sectionSubHeading topGap1">Invoices</div>

            { 
                invoiceList &&
                 <KendoGrid
                    gridData={invoiceList}
                    columnData={payerServiceColumns}
                    accessLevel={props.accessLevel}
                    setGridData={setInvoiceList}
                    module="billing"
                    actionColumnHandler={actionHandler}
                    lockActionColumn={true}
                    openPopup = {openPopup}
                    otherData={{
                        actionColumnBtns: ['view'],
                        disableGlobalSearch: true,
                        hideSaveCancelChanges: true,
                        hideCheckBox: true,
                        exportFileName: "Billing"
                    }}
                    
                /> 
            }

            {toastMsg.msg && <ToastMessage
                cssClass={toastMsg.type}
                message={toastMsg.msg}
                onClose={() => setToastMsg({ msg: "", type: "" })}
            />}
            <SiteLoader isActive={showLoader} />
            {
                selectedBillingLink != '' && <BillingPopUp closePopUp={closePopUp} invoiceURL={selectedBillingLink}/>
            }
            
        </>
    )

}