import React, { Fragment } from "react";
import SiteNavigation from "./SiteNavigation";
import Banner from "../common/Banner";


const MainLayout = (props) => {
    return (
        <Fragment>
             <Banner />
            <div className="mainWrapper">
                <SiteNavigation />
                {props.children}
            </div>

        </Fragment>
    )
}
export default MainLayout;