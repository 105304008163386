import { convertDateString, convertTimeString } from '../utils/helperFunctions'


export const preparePayload = async (data, orderType) => {
    return Object.values(data).map(item => {
        item.startDate && (item.startDate = convertDateString(item.startDate));
        item.endDate && (item.endDate = convertDateString(item.endDate));
        item.startTime && (item.startTime = convertTimeString(item.startTime));
        item.endTime && (item.endTime = convertTimeString(item.endTime));
        item.taskName && (item.taskName = item.taskName.label);
        item.deviceDesc && (item.deviceDesc = item.deviceDesc.deviceDesc);
        item.customerName && (item.customerName = item.customerName.label);
        item.trackingDay && item.trackingDay.length && (item.trackingDay = item.trackingDay.map(item => item.id).join('|'));
        item.locatable ? (item.locatable = item.locatable.label) : item.locatable = "Yes";
        item.timezone ? (item.timezone = item.timezone.label) : item.timezone = "GMT+05:30";
        item.workOrderType = orderType;
        return item;
    }).filter(obj => Object.keys(obj).length > 1);
}

export const handleDeleteRow = (rows, setValue, setRows, setToastMsg, deleteBtnErrorMsg) => {
    const selectedIndexes = rows.reduce((acc, row, index) => {
        if (row.selected) {
            acc.push(index);
        }
        return acc;
    }, []);

    const selectedIndexeslength = selectedIndexes.length;

    if (selectedIndexeslength >= 2) {
        selectedIndexes.forEach(index => {
            setValue(`${index + 1}`, {});
        });
        const updatedRows = rows.filter(row => !row.selected);
        setRows(updatedRows);
    } else {
        setToastMsg(deleteBtnErrorMsg);
    }
};


export const checkDateValidity = (payLoad, setToastMsg, invalidDateErrorMsg) => {
    let hasInvalidDates = false;
    payLoad.forEach(obj => {
        const start = new Date(obj.startDate);
        const end = new Date(obj.endDate);

        if (end < start) {
            hasInvalidDates = true;
        }
    });

    if (hasInvalidDates) {
        setToastMsg(invalidDateErrorMsg);
    }
    return hasInvalidDates;
};

export const checkMandatoryFields = (payLoad, orderType, setToastMsg, mandatoryFieldErrorMsg) => {
    let showToast = false;
    payLoad.forEach(obj => {
        const { startTime, endTime, deviceDesc, workOrderNumber, startDate, endDate } = obj;
        if (orderType === "recurring") {
            if (!startTime || !endTime || !deviceDesc || !workOrderNumber) {
                showToast = true;
                return;
            }
        } else {
            if (!startTime || !endTime || !deviceDesc || !workOrderNumber || !endDate || !startDate) {
                showToast = true;
                return;
            }
        }
    });

    if (showToast) {
        setToastMsg(mandatoryFieldErrorMsg);
    }
    return showToast;
};
