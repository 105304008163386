import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { apiProvider } from '../../../services/api/provider';
import { DateTimePicker, DatePicker, TimePicker } from "@progress/kendo-react-dateinputs";
import { Tooltip } from '@progress/kendo-react-tooltip';
import { timeZones, weekConfig, dateTimeExtractor, generateWeekRepeatString, checkKeyDown } from './locateScheduleUtils';
import CustomPopup from './CustomPopup';
import { getAccountLabel } from '../../../utils/getAccountLabel';

const moment = window.moment;

const CopyLocateScheduler = (props) => {

    const { register, handleSubmit, setValue, watch, getValues, setError, formState: { errors } } = useForm({ mode: "all" });

    const [formData, setFormData] = useState({
        'locateFor': 'Group',
        'scheduleType': 'Recurring',
        'repeat': 'Daily',
        'track': 'Continuously',
        'ends': 'On'
    });

    const [weeksConfig, setWeeksConfig] = useState([]);
    const [schedulerDetails, setSchedulerDetails] = useState({});
    const [isFormComplete, setIsFormComplete] = useState(false);

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        if (Object.keys(schedulerDetails).length > 0) {
            setValue('status', schedulerDetails?.active, { shouldDirty: false });
            setValue('locateFor', schedulerDetails?.assignType === "Group" ? schedulerDetails?.assignType : "Employee", { shouldDirty: false });
            setValue('scheduleFor', schedulerDetails?.scheduleFor, { shouldDirty: false });
            setValue('scheduleType', schedulerDetails?.scheduleType === "recurring" ? "Recurring" : "nonRecurring", { shouldDirty: false });
            setValue('repeat', schedulerDetails?.repeatType === "daily" ? "Daily" : "Weekly", { shouldDirty: false });
            schedulerDetails?.repeatType === "daily"
                ? setValue('days', schedulerDetails?.repeatValue, { shouldDirty: false })
                : setValue('weeks', schedulerDetails?.repeatValue, { shouldDirty: false });
            setValue('timezone', schedulerDetails?.timezone, { shouldDirty: false });
            setValue('track', schedulerDetails?.runType === "continues" ? "Continuously" : "Manual", { shouldDirty: false });
            schedulerDetails?.runType === "continues" && setValue('frequency', schedulerDetails?.trackFrequency, { shouldDirty: false });
            setValue('frequency', schedulerDetails?.trackFrequency, { shouldDirty: false })
            setValue('trackingDays', schedulerDetails?.trackingDays)
            schedulerDetails?.newEndDate === "never"
                ? setValue('ends', 'Never', { shouldDirty: false })
                : setValue('ends', 'On', { shouldDirty: false })

            setFormData({
                'locateFor': schedulerDetails?.assignType === "Group" ? schedulerDetails?.assignType : "Employee",
                'scheduleType': schedulerDetails?.scheduleType === "recurring" ? "Recurring" : "nonRecurring",
                'repeat': schedulerDetails?.repeatType === "daily" ? "Daily" : "Weekly",
                'track': schedulerDetails?.runType === "continues" ? "Continuously" : "Manual",
                'ends': schedulerDetails?.newEndDate === "never" ? 'Never' : "On"
            })

            if (schedulerDetails?.repeat === "weekly") {

                let weeksData = schedulerDetails?.trackingDays.split('|');

                let updatedWeekConfig = weekConfig
                    .map((ele) => { return { ...ele, selected: false } })
                    .map((ele) => {
                        if (weeksData.includes(ele.day)) {
                            return { ...ele, selected: true }
                        } else {
                            return ele
                        }
                    })

                setWeeksConfig(updatedWeekConfig);

            } else {
                setWeeksConfig(weekConfig);
            }
            if (schedulerDetails?.scheduleType === "recurring") {
                if (schedulerDetails?.repeatType === "weekly") {
                    if (schedulerDetails?.trackingDays !== '') {
                        setIsFormComplete(true)
                    } else {
                        setIsFormComplete(false)
                    }
                } else {
                    setIsFormComplete(true)
                }
            }

        }
    }, [schedulerDetails])

    useEffect(() => {
        const subscribe = watch(data => {

            let type;
            if (typeof data.scheduleType === 'string') {
                type = data.scheduleType
            } else {
                if (data.scheduleType) {
                    type = 'Recurring'
                } else {
                    type = 'nonRecurring'
                }
            }

            setFormData({
                'locateFor': data.locateFor,
                'scheduleType': type,
                'repeat': data.repeat,
                'track': data.track,
                'ends': data.ends
            })

            if (data?.scheduleId !== "") {
                if (data.scheduleType === true || data.scheduleType === "Recurring") {
                    if (data.repeat === "Weekly" && getValues("trackingDays") === "") {
                        setIsFormComplete(false)
                    } else {
                        setIsFormComplete(true)
                    }
                } else {
                    setIsFormComplete(true)
                }
            } else {
                setIsFormComplete(false)
            }

        })

        return () => subscribe.unsubscribe()
    }, [watch])

    const fetchData = async () => {
        try {
            props.setShowLoader(true)
            const response = await getScheduleData()
            if (response.status === 200 && response.message.toLowerCase() === 'success') {
                if (response.data.length > 0) {
                    let schedulerDetails = response.data[0];
                    let updatedSchedulerDetails = {
                        ...schedulerDetails,
                        newStartDate: schedulerDetails.scheduleType === 'recurring' ? schedulerDetails.scheduleStartOn : schedulerDetails.startDate,
                        newEndDate: schedulerDetails.scheduleType === 'recurring' ? schedulerDetails.scheduleEndOn : schedulerDetails.endDate,
                        repeat: schedulerDetails.scheduleType === 'recurring' ? schedulerDetails.repeatType : 'daily',
                        repeatType: schedulerDetails.scheduleType === 'recurring' ? schedulerDetails.repeatType : 'daily',
                        locateForBackup: {
                            type: schedulerDetails.assignType,
                            isValueChange: false,
                            valueGrp: schedulerDetails.assignType === "Group" ? schedulerDetails.scheduleFor : props.locateForData.groups[0].groupID,
                            valueEmp: schedulerDetails.assignType === "Device" ? schedulerDetails.scheduleFor : props.locateForData.employees[0].id,
                        }
                    }
                    setSchedulerDetails(updatedSchedulerDetails)
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            props.setShowLoader(false)
        }
    }

    const getScheduleData = async () => {
        try {
            return await apiProvider.getSingle(`/track/mobile/v1/allgeo/schedule?scheduleId=${props.scheduleId}&type=Locate`)
        } catch (error) {
            console.log(error);
        }
    }

    const handleDateTimeChange = (type, event) => {
        if (event.value) {
            if (type === 0) {
                setSchedulerDetails({
                    ...schedulerDetails,
                    newstartDate: moment(event.value).format('YYYY/MM/DD'),
                    startTime: moment(event.value).format('HH:mm:ss')
                })
                setValue("startDateTime", event.value)
            } else {
                setSchedulerDetails({
                    ...schedulerDetails,
                    newEndDate: moment(event.value).format('YYYY/MM/DD'),
                    endTime: moment(event.value).format('HH:mm:ss')
                })
                setValue("endDateTime", event.value)
            }
        }
    }

    const handleDateChange = (type, event) => {
        if (event.value) {
            if (type === 0) {
                setSchedulerDetails({
                    ...schedulerDetails,
                    newStartDate: moment(event.value).format('YYYY/MM/DD'),
                })
                setValue("startDate", moment(event.value).format('YYYY/MM/DD'))
            } else {
                setSchedulerDetails({
                    ...schedulerDetails,
                    newEndDate: moment(event.value).format('YYYY/MM/DD'),
                })
                setValue("endDate", moment(event.value).format('YYYY/MM/DD'))
            }
        }
    }

    const handleTimeChange = (type, event) => {
        if (event.value) {
            if (type === 0) {
                setSchedulerDetails({
                    ...schedulerDetails,
                    startTime: moment(event.value).format('HH:mm:ss')
                })
                setValue("startTime", moment(event.value).format('HH:mm:ss'))
            } else {
                setSchedulerDetails({
                    ...schedulerDetails,
                    endTime: moment(event.value).format('HH:mm:ss')
                })
                setValue("endTime", moment(event.value).format('HH:mm:ss'))
            }
        }
    }

    const setDateRange = (type, timestamp) => {
        if (timestamp) {
            if (new Date() > new Date(timestamp)) {
                return new Date(timestamp)
            } else {
                return new Date()
            }
        }
    }

    const selectDayOfWeek = (param) => {
        let updatedWeekConfig = weeksConfig.map((ele) => {
            if (ele.day === param) {
                ele.selected = !ele.selected
                return ele
            }
            return ele
        });
        setValue('trackingDays', generateWeekRepeatString(updatedWeekConfig))
        setSchedulerDetails({ ...schedulerDetails, trackingDays: generateWeekRepeatString(updatedWeekConfig) });
    }

    const onSubmit = async (data) => {

        let startDate, endDate, startTime, endTime, startDateTime, endDateTime;

        if (data.startDate) {
            startDate = data.startDate
        } else {
            startDate = schedulerDetails?.newStartDate
        }

        if (data.endDate) {
            endDate = data.endDate
        } else {
            endDate = schedulerDetails?.newEndDate
        }

        if (data.startTime) {
            startTime = data.startTime
        } else {
            startTime = schedulerDetails?.startTime
        }

        if (data.endTime) {
            endTime = data.endTime
        } else {
            endTime = schedulerDetails?.endTime
        }

        if (data.startDateTime) {
            startDateTime = data.startDateTime
        } else {
            startDateTime = moment(`${schedulerDetails?.newStartDate} ${schedulerDetails?.startTime}`)
        }

        if (data.endDateTime) {
            endDateTime = data.endDateTime
        } else {
            endDateTime = moment(`${schedulerDetails?.newEndDate} ${schedulerDetails?.endTime}`)
        }

        if (
            formData.scheduleType === "Recurring"
            && formData.ends === "On"
            && moment(`${startDate} ${startTime}`).isAfter(`${endDate} ${endTime}`)
        ) {
            props.setToastMsg({ msg: 'Start date should be before end date.', type: "" })
        } else if (formData.scheduleType !== "Recurring" && moment(startDateTime).isAfter(endDateTime)) {
            props.setToastMsg({ msg: 'Start date & time should be before end date & time.', type: "" })
        } else {

            try {
                props.setShowLoader(true);

                const payload = [{

                    "type": "Locate",
                    "scheduleId": data.scheduleId,
                    "active": data.status,
                    "assignType": formData.locateFor === "Group" ? "Group" : "Device",
                    "scheduleFor": data.scheduleFor,
                    "scheduleType": formData.scheduleType === "Recurring" ? "recurring" : "non-recurring",
                    "repeatType": formData.scheduleType === "Recurring" ? (formData.repeat).toLowerCase() : "",
                    "repeatValue": "",
                    "trackingDays": formData.scheduleType === "Recurring" && formData.repeat === "Weekly" ? generateWeekRepeatString(weeksConfig) : "",
                    "scheduleStartOn": formData.scheduleType === "Recurring"
                        ? data?.startDate
                            ? data.startDate
                            : schedulerDetails?.scheduleStartOn
                        : "",
                    "startDate": formData.scheduleType !== "Recurring"
                        ? data?.startDateTime
                            ? dateTimeExtractor(data.startDateTime).date
                            : schedulerDetails?.startDate
                        : "",
                    "scheduleEndOn": formData.ends === "On" ?
                        formData.scheduleType === "Recurring"
                            ? data?.endDate
                                ? data.endDate
                                : schedulerDetails?.scheduleEndOn
                            : ""
                        : "never",
                    "endDate": formData.scheduleType !== "Recurring"
                        ? data?.endDateTime
                            ? dateTimeExtractor(data.endDateTime).date
                            : schedulerDetails?.endDate
                        : "",
                    "startTime": formData.scheduleType === "Recurring"
                        ? data?.startTime
                            ? data.startTime
                            : schedulerDetails?.startTime
                        : data?.startDateTime
                            ? dateTimeExtractor(data.startDateTime).time
                            : schedulerDetails?.startTime,
                    "endTime": formData.scheduleType === "Recurring"
                        ? data?.endTime
                            ? data.endTime
                            : schedulerDetails?.endTime
                        : data?.endDateTime
                            ? dateTimeExtractor(data.endDateTime).time
                            : schedulerDetails?.endTime,
                    "timezone": data.timezone,
                    "runType": formData.track === "Continuously" ? "continues" : "manual",
                    "trackFrequency": formData.track === "Continuously" ? data.frequency : "",
                }]

                const response = await apiProvider.post("track/mobile/v1/allgeo/schedule/create", payload)
                if (response.status === 200) {
                    props.setToastMsg({ msg: `Schedule ${data.scheduleId} has been successfully created.`, type: "msgSuccess" })
                    props.setMode({
                        title: "Monitor",
                        type: 'main',
                        data: null,
                        hasPrev: false
                    })
                } else if (response.status === 409) {
                    setError(response.field, true)
                } else if (response.status === 105) {
                    props.setShowLoader(false)
                    props.setToastMsg({ msg: response.message, type: "" })
                } else {
                    props.setShowLoader(false)
                    props.setToastMsg({ msg: response.message, type: "msgError" })
                }

            } catch (er) {
                console.log(er.message);
            } finally {
                props.setShowLoader(false)
            }
        }

    }

    const employeeLabel = getAccountLabel('employee');
    const groupLabel = getAccountLabel('group');

    return (
        <div className="popupWrap medPopup withAutoComplete active">
            <div className="closePopup" onClick={props.closePopup}>Close</div>
            <div className="popupHeading">Copy Tracking Scheduler</div>
            <form onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => checkKeyDown(e)}>
                <div className="popupContent twoColPopContent" style={{fontSize : "0.75rem"}} >
                    <div className="rowBlk">
                        <div className={`colBlk6 fldWrap${errors.scheduleId ? ' errFld' : ''}`}>
                            <label className="withHelpTxt">Scheduler ID</label>
                            <input type="text" className='monitorCustomSelectFontSize' maxLength={255} {...register('scheduleId', { onChange: (e) => setValue('scheduleId', (e.target.value).replace(/[^a-zA-Z0-9_-]/gi, "")) })} />
                            {errors.scheduleId && <span className="fldMsg">schedule ID already exists</span>}
                        </div>
                        <div className="colBlk6 fldWrap">
                            <label>Active</label>
                            <div className="customCheck yesNo">
                                <input type="checkbox" id="StatusActive" {...register('status', { onChange: (e) => setSchedulerDetails({ ...schedulerDetails, active: !schedulerDetails.active }) })} />
                                <label className="customCheckLabel" htmlFor="StatusActive">yesNo</label>
                            </div>
                        </div>
                    </div>
                    <div className="twoColBlk">
                        <div className="leftCol">This track is for</div>
                        <div className="rightCol">
                            <div className="highlightedSection">
                                <div className="customControls fldWrap">
                                    <div className="customRadio">
                                        <input type="radio" id="Group" name="locateFor" value="Group" checked={formData.locateFor === "Group"}
                                            onChange={() => {
                                                setSchedulerDetails({ ...schedulerDetails, assignType: "Group", scheduleFor: schedulerDetails.locateForBackup.valueGrp })
                                                setValue('scheduleFor', schedulerDetails.locateForBackup.valueGrp);
                                            }} />
                                        <label htmlFor="Group">{groupLabel}</label>
                                    </div>
                                    <div className="customRadio">
                                        <input type="radio" id="Employee" name="locateFor" value="Employee" checked={formData.locateFor === "Employee"}
                                            onChange={() => {
                                                setSchedulerDetails({ ...schedulerDetails, assignType: "Device", scheduleFor: schedulerDetails.locateForBackup.valueEmp })
                                                setValue('scheduleFor', schedulerDetails.locateForBackup.valueEmp, { shouldDirty: true });
                                            }} />
                                        <label htmlFor="Employee">{employeeLabel}</label>
                                    </div>
                                </div>
                                {
                                    (formData.locateFor)?.toLowerCase() === "group" &&
                                    <div className="fldWrap">
                                        <label>Select {groupLabel}</label>
                                        <select className="fldWidthMed monitorCustomSelectFontSize" {...register('scheduleFor', {
                                            onChange: (e) => {
                                                setSchedulerDetails({ ...schedulerDetails, scheduleFor: e.target.value, locateForBackup: { ...schedulerDetails.locateForBackup, valueGrp: e.target.value } })
                                            }
                                        })}>
                                            {
                                                props.locateForData.groups.map((t, ind) => (
                                                    <option key={ind} value={t.value}>{t.label}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                }
                                {
                                    (formData.locateFor)?.toLowerCase() === "employee" &&
                                    <div className="fldWrap">
                                        <label>Select {employeeLabel}</label>
                                        <select className="fldWidthMed monitorCustomSelectFontSize" {...register('scheduleFor', {
                                            onChange: (e) => {
                                                setSchedulerDetails({ ...schedulerDetails, scheduleFor: e.target.value, locateForBackup: { ...schedulerDetails.locateForBackup, valueEmp: e.target.value } })
                                            }
                                        })}>
                                            {
                                                props.locateForData.employees.map((t, ind) => (
                                                    <option key={ind} value={t.value}>{t.label}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="twoColBlk">
                        <div className="leftCol">Track Settings</div>
                        <div className="rightCol">
                            <div className="highlightedSection">
                                {(formData.scheduleType)?.toLowerCase() !== "recurring" &&
                                    <div className="rowBlk">
                                        <div className="colBlk4">
                                            <div className="fldWrap">
                                                <label>Start Day &amp; Time</label>
                                                {
                                                    <DateTimePicker
                                                        popup={CustomPopup}
                                                        placeholder={"Start date & Time"}
                                                        value={new Date(`${schedulerDetails.newStartDate} ${schedulerDetails.startTime}`)}
                                                        onChange={(e) => handleDateTimeChange(0, e)}
                                                        format={`${props.dateFormat} ${props.timeFormat}`}
                                                        min={setDateRange(0, schedulerDetails?.startDate)}
                                                    />
                                                }
                                            </div>
                                        </div>
                                        <div className="colBlk4">
                                            <div className="fldWrap">
                                                <label>End Day &amp; Time</label>
                                                {
                                                    <DateTimePicker
                                                        popup={CustomPopup}
                                                        placeholder={"End date & Time"}
                                                        value={new Date(`${schedulerDetails.newEndDate} ${schedulerDetails.endTime}`)}
                                                        onChange={(e) => handleDateTimeChange(1, e)}
                                                        format={`${props.dateFormat} ${props.timeFormat}`}
                                                        min={setDateRange(1, schedulerDetails?.endDate)}
                                                    />
                                                }
                                            </div>
                                        </div>
                                        <div className="colBlk4">
                                            <div className="fldWrap">
                                                <label>Select Timezone</label>
                                                <select name="timezone" defaultValue={schedulerDetails?.timezone} {...register("timezone", {
                                                    onChange: (e) => setSchedulerDetails({ ...schedulerDetails, timezone: e.target.value })
                                                })}>{
                                                        [{ value: "Account", label: "Account" }, { value: "Device", label: "Device" }, ...timeZones].map((t, ind) => (
                                                            <option key={ind} value={t.value}>{t.label}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {(formData.scheduleType)?.toLowerCase() === "recurring" &&
                                    <div className="fldWrap">
                                        <label>Start Date</label>
                                        <div className="medFld">
                                            <DatePicker
                                                popup={CustomPopup}
                                                placeholder={"Start date"}
                                                value={new Date(schedulerDetails.newStartDate)}
                                                onChange={(e) => handleDateChange(0, e)}
                                                format={`${props.dateFormat}`}
                                                min={setDateRange(0, schedulerDetails.scheduleStartOn)}
                                            />
                                        </div>
                                    </div>
                                }
                                <div className="customCheck">
                                    <input type="checkbox" id="Recurring" checked={formData?.scheduleType === 'Recurring'} value={'Recurring'} {...register('scheduleType', {
                                        onChange: (e) => {
                                            let type
                                            if (e.target.checked) {
                                                type = 'Recurring'
                                            } else {
                                                type = 'nonRecurring'
                                            }
                                            setValue('scheduleType', type);
                                            setSchedulerDetails({
                                                ...schedulerDetails,
                                                scheduleType: type === 'Recurring' ? 'recurring' : 'non-recurring',
                                                newEndDate: (type === 'nonRecurring' && schedulerDetails.newEndDate === "never")
                                                    ? moment() > moment(schedulerDetails.newStartDate).add(2, 'days')
                                                        ? moment().format('YYYY/MM/DD')
                                                        : moment(schedulerDetails.newStartDate).add(2, 'days').format('YYYY/MM/DD')
                                                    : schedulerDetails.newEndDate
                                            })
                                        }
                                    })} />
                                    <label className="customCheckLabel" htmlFor="Recurring">Recurring</label>
                                </div>
                                {(formData.scheduleType)?.toLowerCase() === "recurring" &&
                                    <div className="singleLineFld">
                                        <label>Repeat</label>
                                        <select className="smlFld monitorCustomSelectFontSize" {...register("repeat", {
                                            onChange: (e) => {
                                                setValue('repeat', e.target.value);
                                                setSchedulerDetails({
                                                    ...schedulerDetails,
                                                    trackingDays: ((e.target.value === "Weekly") && schedulerDetails?.repeat === "daily") ? '' : schedulerDetails?.trackingDays,
                                                    repeatType: (e.target.value === "Daily") ? 'daily' : 'weekly'
                                                })
                                            }
                                        })}>
                                            {["Daily", "Weekly"].map((t, ind) => (<option key={ind} value={t}>{t}</option>))}
                                        </select>
                                    </div>
                                }
                                {((formData.scheduleType)?.toLowerCase() === "recurring" && (formData.repeat)?.toLowerCase() === "weekly")
                                    &&
                                    <div className="fldWrap">
                                        <label>Select Days</label>
                                        <div className="weekDays">
                                            {
                                                weeksConfig.map((t, ind) => (
                                                    <span key={ind} value={t.day}
                                                        className={t.selected ? 'active' : ''}
                                                        onClick={(e) => selectDayOfWeek(t.day)}>
                                                        {t.label}
                                                    </span>
                                                ))
                                            }
                                        </div>
                                    </div>
                                }
                                {(formData.scheduleType)?.toLowerCase() === "recurring" &&
                                    <div className="singleLineFld">
                                        <label>Ends</label>
                                        <select className="smlFld monitorCustomSelectFontSize" {...register("ends",
                                            {
                                                onChange: (e) => {
                                                    setFormData({ ...formData, ends: e.target.value })
                                                    if (e.target.value === 'On' && schedulerDetails.newEndDate === 'never') {
                                                        setSchedulerDetails({
                                                            ...schedulerDetails,
                                                            newEndDate: moment() > moment(schedulerDetails.newStartDate).add(2, 'days') ? moment().format('YYYY/MM/DD') : moment(schedulerDetails.newStartDate).add(2, 'days').format('YYYY/MM/DD'),
                                                        })
                                                    }

                                                    if (e.target.value === 'Never') {
                                                        setSchedulerDetails({
                                                            ...schedulerDetails,
                                                            newEndDate: 'never',
                                                        })
                                                    }
                                                }
                                            })}>
                                            {["On", "Never"].map((t, ind) => (<option key={ind} value={t}>{t}</option>))}
                                        </select>
                                        {(formData.ends)?.toLowerCase() === "on" &&
                                            <div className="medFld">
                                                <DatePicker
                                                    popup={CustomPopup}
                                                    placeholder={"End date"}
                                                    value={new Date(schedulerDetails.newEndDate)}
                                                    onChange={(e) => handleDateChange(1, e)}
                                                    format={`${props.dateFormat}`}
                                                    min={setDateRange(1, schedulerDetails.scheduleEndOn)}
                                                />
                                            </div>
                                        }
                                    </div>
                                }
                                {(formData.scheduleType)?.toLowerCase() === "recurring" &&
                                    <div className="singleLineFld">
                                        <label>Track from</label>
                                        <div className="smlFld2">
                                            <TimePicker
                                                popup={CustomPopup}
                                                value={new Date(`2022/02/28 ${schedulerDetails.startTime}`)}
                                                onChange={(e) => handleTimeChange(0, e)}
                                                format={`${props.timeFormat}`}
                                            />
                                        </div>
                                        <label>to</label>
                                        <div className="smlFld2">
                                            <TimePicker
                                                popup={CustomPopup}
                                                value={new Date(`2022/02/28 ${schedulerDetails.endTime}`)}
                                                onChange={(e) => handleTimeChange(1, e)}
                                                format={`${props.timeFormat}`}
                                            />
                                        </div>
                                        <select className="medFld monitorCustomSelectFontSize" name="timezone" defaultValue={schedulerDetails?.timezone} {...register("timezone", {
                                            onChange: (e) => setSchedulerDetails({ ...schedulerDetails, timezone: e.target.value })
                                        })}>
                                            {
                                                [{ value: "Account", label: "Account" }, { value: "Device", label: "Device" }, ...timeZones].map((t, ind) => (
                                                    <option key={ind} value={t.value}>{t.label}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                }
                                <div className="fldWrap">
                                    <div className="customControls">
                                        <div className="customRadio">
                                            <input type="radio" id="Continuously" name="Track" value="Continuously" checked={formData.track === 'Continuously'}
                                                onChange={(e) => {
                                                    setSchedulerDetails({ ...schedulerDetails, runType: 'continues' })
                                                    setValue('track', e.target.value);
                                                }}
                                            />
                                            <label htmlFor="Continuously">Continuously</label>
                                        </div>
                                        <div className="customRadio">
                                            <input type="radio" id="Manual" name="Track" value="Manual" checked={formData.track === 'Manual'}
                                                onChange={(e) => {
                                                    setSchedulerDetails({ ...schedulerDetails, runType: 'manual' })
                                                    setValue('track', e.target.value);
                                                }} />
                                            <label htmlFor="Manual" className="withHelpTxt">Manual
                                                <Tooltip position="right"><span className="helpTip" title="Location is tracked when the manager checks the location from the dashboard or when employee updates location from mobile app">?</span></Tooltip>
                                            </label>
                                        </div>
                                    </div>
                                    {
                                        (formData.track)?.toLowerCase() === "continuously"
                                        &&
                                        <div className="singleLineFld">
                                            <label>At what frequency do you want to track?</label>
                                            <div className="fldWithSubTxt">
                                                <span>Every</span>
                                                <input type="number"
                                                    min={1}
                                                    max={60}
                                                    className="numberFld monitorCustomSelectFontSize"
                                                    {...register('frequency')} />
                                                <span>mins</span>
                                            </div>
                                        </div>

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="popupBtnWrap flexJCenter">
                    <button type="submit" className="btnStyle doneStep" disabled={!isFormComplete}>Done</button>
                </div>
            </form>
        </div>
    )
}

export default CopyLocateScheduler