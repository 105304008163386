import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import ChangeJob from "../../../../assets/images/change-job.svg";
import NotAllowedInner from "../../../../assets/images/not-allow-inner.svg";
import DeleteInner from "../../../../assets/images/delete-inner.svg";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
  getSelectedState,
} from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import { TooltipKendo } from "../../common/Tooltip";
import { getAccountLabel } from "../../../../utils";
import { isDisabled } from "../../../../components/common/kendo/kendoUtils";
import KendoGridTable from "../../TimeClock/KendoTable/KendoGridTable";
import { monitorEmployeesAppDataColumns } from "../../utils/monitorsUtils/monitorsUtils";
const DATA_ITEM_KEY = "employeeID";
const idGetter = getter(DATA_ITEM_KEY);
const SELECTED_FIELD = "selected";

export const EmployeesAppDataTable = ({
  setShowChangeStatusModal,
  employeeData,
  setSelectedDeleteItem,
  openDeletePopup,
  openSuspendPopup,
  accessLevel,
  setEmployeeData,
  setEmployeePaginateMetaData,
  employeePaginateMetaData,
  selectedDeleteItem,
  fromPinned,
  employeeBackupData,
  openActivePopup,
  searchField,
  // refreshData,
  // setRefreshData
}) => {
  const [dataState, setDataState] = React.useState(
    employeeData?.data?.map((dataItem) =>
      Object.assign(
        {
          selected: false,
        },
        dataItem
      )
    )
  );

  console.log(dataState, "dataStatedataState");
  const childRef = useRef();
  const childAccountID = localStorage.getItem("childAccountId");

  const allAccountColumn = {
    title: "AccountID",
    field: "deviceDetails.accountID",
    editable: false,
    width: 70,
    editor: "text",
    columnMenu: false,
    show: true,
    filter: "text",
  };

  const [columns, setColumns] = useState(
    childAccountID === "all"
      ? [allAccountColumn, ...monitorEmployeesAppDataColumns]
      : monitorEmployeesAppDataColumns
  );

  const [dropdowndata, setDropdowndata] = useState([]);

  const [toastMsg, setToastMsg] = useState({
    msg: "",
    type: "msgSuccess",
  });

  const [selectedState, setSelectedState] = React.useState({});
  const anchorRefs = useRef([]);

  const [showLoader, setShowLoader] = useState(false);
  const employeeLabel = getAccountLabel("employee");

  console.log(columns, "allAccountColumnallAccountColumn");
  // const onSelectionChange = React.useCallback(
  //   (event) => {
  //     const newSelectedState = getSelectedState({
  //       event,
  //       selectedState: selectedState,
  //       dataItemKey: DATA_ITEM_KEY,
  //     });
  //     setSelectedState(newSelectedState);

  //     // setSelectedDeleteItem(Object.keys(newSelectedState)?.filter((keys) =>selectedState[keys]))
  //   },
  //   [selectedState]
  // );

  // const onHeaderSelectionChange = React.useCallback((event) => {
  //   console.log(event, "eventeventevent");
  //   const checkboxElement = event.syntheticEvent.target;
  //   const checked = checkboxElement.checked;
  //   const newSelectedState = {};
  //   event.dataItems.forEach((item) => {
  //     newSelectedState[idGetter(item)] = checked;
  //   });
  //   setSelectedState(newSelectedState);

  // }, []);

  console.log(selectedState, "selectedSSSS");

  useEffect(() => {
    if (toastMsg.msg) {
      if (toastMsg.type === "msgSuccess" && childRef.current) {
        childRef.current.refreshData();
      }
      setShowLoader(false);
    }
  }, [toastMsg]);

  useEffect(() => {
    setDataState(
      employeeData?.data?.map((dataItem) =>
        Object.assign(
          {
            selected: false,
          },
          dataItem
        )
      )
    );
  }, [employeeData]);

  useEffect(() => {
    setSelectedDeleteItem(
      Object.keys(selectedState)?.filter((keys) => selectedState[keys])
    );
  }, [selectedState]);
  const togglePopover = (isHoverIn, index) => {
    setIsOpen(isHoverIn && (index === 0 ? true : index));
  };
  console.log(selectedDeleteItem, "selectedDeleteItem");
  const StatusChip = ({ dataItem, dataIndex }) => {
    const getStatusClass = (status) => {
      switch (status) {
        case "Active":
          return "table-active";
        case "Inactive":
          return "table-inactive";
        case "App Not Installed":
          return "table-notinstall";
        default:
          return "table-chip-all";
      }
    };

    const [isOpen, setIsOpen] = useState(false);

    const statusClass = getStatusClass(dataItem.appDataStatus.appStatus);
    return (
      <td
        onMouseLeave={() => togglePopover(false, dataIndex)}
        style={{ overflow: "unset" }}
      >
        <div style={{ position: "relative", width: "fit-content" }}>
          <span
            className={`employeemodaltable statusbtn ${statusClass}`}
            ref={(el) => (anchorRefs.current[dataIndex] = el)}
          >
            {dataItem.appDataStatus.appStatus}
          </span>
          {dataItem.appDataStatus.appStatusContent && (
            <span
              onMouseEnter={() => togglePopover(true, dataIndex)}
              style={{
                fontSize: 10,
                background: "#ccc",
                borderRadius: "50%",
                padding: 4,
                marginLeft: 4,
                height: "14px",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              ?
            </span>
          )}
          <div
            id="openPopover"
            style={{
              width: 200,
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              top: 20,
              background: "white",
              zIndex: 99,
              borderRadius: 5,
              boxShadow: "0px 0px 10px lightgray",
              display: isOpen ? "block" : "none",
            }}
          >
            <div
              // onMouseLeave={() => togglePopover(false, dataIndex)}
              style={{ padding: "10px" }}
              dangerouslySetInnerHTML={{
                __html: dataItem.appDataStatus.appStatusContent,
              }}
            />
          </div>
        </div>
      </td>
    );
  };

  // const grid = (
  //   <Grid
  //     className="employeemodaltablediv"
  //     data={dataState?.map((item) => ({
  //       ...item,
  //       [SELECTED_FIELD]: selectedState[idGetter(item)],
  //     }))}
  //     dataItemKey={DATA_ITEM_KEY}
  //     selectedField={SELECTED_FIELD}
  //     selectable={{
  //       enabled: true,
  //       drag: false,
  //       cell: false,
  //       mode: "multiple",
  //     }}
  //     onSelectionChange={onSelectionChange}
  //     onHeaderSelectionChange={onHeaderSelectionChange}
  //   >
  //     <Column
  //       headerSelectionValue={
  //         dataState?.findIndex((item) => !selectedState[idGetter(item)]) === -1
  //       }
  //       field={SELECTED_FIELD}
  //       width="50px"
  //     />
  //     <Column field="employeeName" title={`${employeeLabel} Name`} />
  //     <Column field="employeeID" title="Employee ID" />
  //     <Column
  //       field="appDataStatus.appStatus"
  //       title="App Status"
  //       cell={(props) => <StatusChip {...props} />}
  //     />
  //     <Column field="appDataStatus.lastappActiveTime" title="Last Active" />
  //     <Column field="timeClockStatus.lastTimeclockStageName" title="Job Status" />
  //     <Column
  //       field="Actions"
  //       title="Actions"
  //       cell={(props) => (
  //         <td
  //           style={{
  //             textAlign: "center",
  //             display: "flex",
  //             alignItems: "center",
  //             justifyContent: "center",
  //           }}
  //         >
  //           <button
  //             style={{ border: "none", backgroundColor: "transparent" }}
  //             disabled={isDisabled("edit", accessLevel)}
  //             onClick={() => {
  //               setShowChangeStatusModal(true);
  //               setSelectedDeleteItem(props.dataItem.employeeID);
  //             }}
  //             >
  //             <TooltipKendo>
  //             <img
  //               className={isDisabled("edit", accessLevel)?'disabled_field':''}
  //               title="Change Status"
  //               src={ChangeJob}
  //               alt=""
  //             />
  //           </TooltipKendo>
  //             </button>
  //           <button
  //             style={{ border: "none", backgroundColor: "transparent" }}
  //             disabled={isDisabled("edit", accessLevel)}
  //             onClick={() => {
  //               openSuspendPopup("single");
  //               setSelectedDeleteItem([props.dataItem.employeeID]);
  //             }}
  //           >
  //           <img
  //             className={isDisabled("edit", accessLevel)?'disabled_field':''}
  //             style={{ marginLeft: 8}}
  //             src={NotAllowedInner}
  //             alt=""

  //           />
  //           </button>
  //           <button
  //             style={{ border: "none", backgroundColor: "transparent" }}
  //             disabled={isDisabled("edit", accessLevel)}
  //             onClick={() => {
  //               openDeletePopup("single");
  //               setSelectedDeleteItem([props.dataItem.employeeID]);
  //             }}
  //           >
  //             <img
  //             className={isDisabled("add", accessLevel)?'disabled_field':''}
  //               style={{ marginLeft: 8 }}
  //               src={DeleteInner}
  //               alt=""
  //             />
  //           </button>
  //         </td>
  //       )}
  //     />
  //   </Grid>
  // );

  const actionHandler = (type, data) => {
    setMode({
      ...mode,
      type,
      data,
    });
  };
  const queryParams = {
    page: 1,
    perPage: 25,
    totalDataCount: 0,
    searchBy: "all",
    searchValue: "",
    parentAcctID: JSON.parse(localStorage.getItem("parent"))?.accountId || "",
    childAccountID:
      localStorage.getItem("childAccountId") ||
      localStorage.getItem("accountId"),
  };
  // const lastappActiveTime = columns?.find(
  //   (e) => e.field == "lastappActiveTime"
  // )?.title;

  console.log(employeeData, "employeeData");

  // useLayoutEffect(()=>{

  // },[childAccountID])
  return (
    <div style={{ overflow: "auto" }}>
      {/* {grid} */}
      {
        <KendoGridTable
          fromMonitorV2={true}
          gridData={employeeBackupData}
          setGridData={setEmployeeData}
          columnData={columns}
          accessLevel={accessLevel}
          ref={childRef}
          module={"employee-app-data"}
          getAPiUrl={"/track/react/v1/allgeo/monitor/EmployeeData"}
          requestParams={queryParams}
          // openPopup={openPopup}
          // deleteItem={deleteDevices}
          lockActionColumn={true}
          filterable={false}
          groupable={false}
          actionColumnHandler={actionHandler}
          // bulkDelete={deleteMultipleEmployee}
          otherData={{
            hideSaveCancelChanges: true,

            actionColumnBtns: ["jobChange", "suspend", "delete"],

            lockActionColumn: true,
            showExportDropdown: true,

            exportFileName: "Employee App Data",
            // dropdownList: dropdowndata,
            disableGlobalSearch: true,

            dropDownData: "all:all",
            // searchPlaceholder: 'Search Name or Phone#'
          }}
          dataObject={employeeData}
          setParams={setEmployeePaginateMetaData}
          params={employeePaginateMetaData}
          openSuspendPopup={openSuspendPopup}
          setSelectedDeleteItem={setSelectedDeleteItem}
          setShowChangeStatusModal={setShowChangeStatusModal}
          openDeletePopup={openDeletePopup}
          togglePopover={togglePopover}
          setSelectedBulkState={setSelectedState}
          selectedBulkState={selectedState}
          fromPinned={fromPinned}
          employeeBackupData={employeeBackupData}
          openActivePopup={openActivePopup}
          searchField={searchField}
          dataItemKey={"employeeID"}
          // refreshData={refreshData}
          // setRefreshData={setRefreshData}
        />
      }
    </div>
  );
};
