import React, { useState, useRef, useEffect } from 'react'
import { apiProvider } from '../../../services/api/provider'
import AssignedUnassignedTable from './AssignedUnassignedTable'
import { getAccountLabel } from '../../../utils'

const CreateNewGroup = ({ formData, closePopup, closeParentPopup, setToastMsg, setShowLoader, setShowG2Popup }) => {

    const [data, setData] = useState([])

    const fetchData = async () => {
        try {
            setShowLoader(true)
            const response = await apiProvider.getAll(`/track/mobile/v1/allgeo/getUnassignedEmployees`);
            setData(response.unassignedEmployee)
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    const childRef = useRef()
    const groupLabel = getAccountLabel('group');

    return (
        <>
            <div className="popupWrap active">
                <div className="closePopup" onClick={closePopup}>Close</div>
                <div className="popupHeading">Create New Phone {groupLabel}</div>
                <div className="popupContent">
                    <AssignedUnassignedTable
                        formData={formData}
                        unassgnData={data}
                        ref={childRef}
                        closePopup={() => {
                            closePopup(true)
                            closeParentPopup()
                        }}
                        setToastMsg={setToastMsg}
                        setShowG2Popup={setShowG2Popup}
                    />
                </div>
                <div className="popupBtnWrap">
                    <a className='cursorPointer' onClick={closePopup} >Go Back</a>
                    <button className="btnStyle doneStep" onClick={() =>{setShowLoader(true); childRef.current.saveData()}}>Done</button>
                </div>
            </div>
        </>
    )
}

export default CreateNewGroup