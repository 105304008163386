//  *******############################  CUSTOM REPORTS COLUMN NAMES #########################************
import { getAccountLabel } from "../../../../utils"

const jobsiteLabel = getAccountLabel('jobsite')
const employeeLabel = getAccountLabel('employee')
const groupLabel = getAccountLabel('group')

export const ADPPaydataTempCostColumnName = [
    {
        title: `Co Code`,
        field: "coCode",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Batch ID",
        field: "batchId",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "File #",
        field: "fileNumber",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Temp Cost Number",
        field: "tempCostNumber",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Reg Hours",
        field: "regHours",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "O/T Hours",
        field: "otHours",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Hours 3 Code",
        field: "hours3Code",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Hours 3 Amount",
        field: "hours3Amount",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "Hours 4 Code",
        field: "hours4Code",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Hours 4 Amount",
        field: "hours4Amount",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "Earnings 3 Code",
        field: "earning3Code",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "Earnings 3 Amount",
        field: "earning3Amount",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Earnings 4 Code",
        field: "earning4Code",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "Earnings 4 Amount",
        field: "earning4Amount",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
]

export const ADPPaydataStandardColumnName = [
    {
        title: `Company Code`,
        field: "companyCode",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Batch ID",
        field: "batchId",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "File #",
        field: "fileNumber",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Payroll Name",
        field: "payrollName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Home State",
        field: "homeState",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Rate",
        field: "rate",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Temp State Code",
        field: "tempStateCode",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Total Hours",
        field: "totalHours",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Reg Hours",
        field: "regHours",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "O/T Hours",
        field: "otHours",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Memo Code",
        field: "memoCode",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Memo Amount",
        field: "memoAmount",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Hours 3 Code",
        field: "hours3Code",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Hours 3 Amount",
        field: "hours3Amount",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "Earnings 3 Code",
        field: "earning3Code",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "Earnings 3 Amount",
        field: "earning3Amount",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Hours 3 Code",
        field: "hours3Code2",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Hours 3 Amount",
        field: "hours3Amount2",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "Hours 3 Code",
        field: "hours3Code3",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Hours 3 Amount",
        field: "hours3Amount3",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "Hours 3 Code",
        field: "hours3Code4",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Hours 3 Amount",
        field: "hours3Amount4",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "Hours 3 Code",
        field: "hours3Code5",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Hours 3 Amount",
        field: "hours3Amount5",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "Hours 3 Code",
        field: "hours3Code6",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Hours 3 Amount",
        field: "hours3Amount6",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    }, {
        title: "Hours 3 Code",
        field: "hours3Code7",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Hours 3 Amount",
        field: "hours3Amount7",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "Adjust Ded Code",
        field: "adjustDedCode",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Adjust Ded Amount",
        field: "adjustDedCode2",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "Adjust Ded Code",
        field: "adjustDedCode3",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Adjust Ded Amount",
        field: "adjustDedCode4",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
]

export const ADPPaydataMemoMilesColumnName = [
    {
        title: `Co Code`,
        field: "coCode",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Batch ID",
        field: "batchId",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "File #",
        field: "fileNumber",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Temp Cost Number",
        field: "tempCostNumber",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Memo Code",
        field: "memoCode",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Memo Amount",
        field: "memoAmount",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
]

export const ADPPaydataMemoMilesOldColumnName = [
    {
        title: `Co Code`,
        field: "coCode",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Batch ID",
        field: "batchId",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "File #",
        field: "fileNumber",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Temp Cost Number",
        field: "tempCostNumber",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Memo Code",
        field: "memoCode",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Memo Amount",
        field: "memoAmount",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
]

export const ADPPaydataImportGridColumnName = [
    {
        title: `Co Code`,
        field: "coCode",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Batch ID",
        field: "batchId",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "File #",
        field: "fileNumber",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Tax Frequency",
        field: "taxFrequency",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Temp Dept",
        field: "taxDept",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Temp Rate",
        field: "taxRate",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Reg Hours",
        field: "regHours",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "O/T Hours",
        field: "otHours",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Hours 3 Code",
        field: "hours3Code",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Hours 3 Amount",
        field: "hours3Amount",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "Hours 4 Code",
        field: "hours4Code",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Hours 4 Amount",
        field: "hours4Amount",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "Earnings 3 Code",
        field: "earning3Code",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "Earnings 3 Amount",
        field: "earning3Amount",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Earnings 4 Code",
        field: "earning4Code",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "Earnings 4 Amount",
        field: "earning4Amount",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Cancel Pay",
        field: "cancelPay",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
]

export const ADPPaydataCustomCAColumnName = [
    {
        title: `Pay ${groupLabel}`,
        field: "pay",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Worksheet Name",
        field: "worksheetName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Position ID",
        field: "positionID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Pay Check #",
        field: "payCheck",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Regular Hours",
        field: "regularHours",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `${employeeLabel} Name`,
        field: "name",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Cost Center",
        field: "costCenter",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Hourly Rate",
        field: "hourlyRate",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Overtime Hours",
        field: "overtimeHours",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Hours Policy",
        field: "hoursPolicy",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "Hours Value",
        field: "hoursValue",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
]


export const ADPPaydataCustomWeeklyReport = [
    {
        title: `Pay ${groupLabel}`,
        field: "payGroup",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Worksheet Name",
        field: "worksheetName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Position ID",
        field: "positionID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `${employeeLabel} Name`,
        field: "employeeName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Hourly Rate",
        field: "hourlyRate",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Total Hours",
        field: "totalHours",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Regular Hours",
        field: "regularHours",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Overtime Hours",
        field: "overtimeHours",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Doubleovertime Hours",
        field: "doubleovertimeHours",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Regular Hours Amount",
        field: "regularHoursAmount",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "Overtime Hours Amount",
        field: "overtimeHoursAmount",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Doubleovertime Hours Amount",
        field: "doubleovertimeHoursAmount",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
]

export const BlairDuronReport = [
    {
        title: `Co Code`,
        field: "companyCode",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Batch ID",
        field: "batchId",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "File #",
        field: "fileNumber",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Tax Frequency",
        field: "taxFrequency",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Temp Cost number",
        field: "tempCost",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Temp Rate",
        field: "tempRate",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Reg Hours",
        field: "regHours",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "O/T Hours",
        field: "otHours",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
]

export const AssistServicesLLCReport = [
    {
        title: `Co Code`,
        field: "companyCode",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Batch ID",
        field: "batchId",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "File #",
        field: "fileNumber",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Reg Hours",
        field: "regHours",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "O/T Hours",
        field: "assist_overTimeHours",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Hours 3 Code",
        field: "travelRegularHours3Code",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },{
        title: "Hours 3 Amount",
        field: "travelRegHours",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },{
        title: "Hours 3 Code",
        field: "travelOvertimeHours3Code",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },{
        title: "Hours 3 Amount",
        field: "travelOverTimeHours",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },{
        title: "Earnings 3 Code",
        field: "tempLeadHours3Code",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },{
        title: "Earnings 3 Amount",
        field: "tempLeadRegHours",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },{
        title: "Adjust Ded Code",
        field: "adjustDedCode",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },{
        title: "Adjust Ded Amount",
        field: "perDiemCount",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
]
//  ************############################  CUSTOM REPORTS CONSTANTS NAMES #########################*******************


export const MEMO_MILES_DESCRIPTION = 'Co Code,Batch ID,File #,Temp Cost Number,Memo Code,Memo Amount'

export const STANDARD_DESCRIPTION = 'Company Code, Batch ID,File #, Payroll Name, Home State, Rate, Temp State Code, Total Hours, Reg Hours, O/T Hours, Memo Code, Memo Amount, Hours 3 Code, Hours 3 Amount,Earnings 3 Code,Earnings 3 Amount,Hours 3 Code,Hours 3 Amount,Hours 3 Code'

export const TEMP_COST_DESCRIPTION = 'Co Code,Batch ID,File #,Temp Cost Number,Reg Hours,O/T Hours,Hours 3 Code,Hours 3 Amount,Hours 4 Code,Hours 4 Amount,Earnings 3 Code,Earnings 3 Amount,Earnings 4 Code,Earnings 4 Amount'

export const MEMO_MILES_OLD_DESCRIPTION = 'Co Code,Batch ID,File #,Temp Cost Number,Memo Code,Memo Amount'

export const IMPORT_GRID_DESCRIPTION = 'Co Code, Batch ID,File #, Tax Frequency, Temp Dept, Temp Rate, Reg Hours, O/T Hours, Hours 3 Code, Hours 3 Amount,Hours 4 Code,Hours 4 Amount,Earnings 3 Code,Earnings 3 Amount,Earnings 4 Code,Earnings 4 Amount,Cancel Pay'

export const CUSTOM_CA_DESCRIPTION = `Pay ${groupLabel},Worksheet Name,Position ID,Pay Check #,Regular Hours,${employeeLabel} Name,Cost Center,Hourly Rate,Overtime Hours,Hours Policy,Hours Value`

export const CUSTOM_WEEKLY_REPORT_DESCRIPTION = `Pay ${groupLabel},Worksheet Name,Position ID,${employeeLabel} Name,Hourly Rate,Total Hours,Regular Hours,Overtime Hours,Doubleovertime Hours,Regular Hours Amount,Overtime Hours Amount,Doubleovertime Hours Amount`

export const BLAIR_DURON_REPORT_DESCRIPTION = 'Co Code,Batch ID,File #,Tax Frequency,Temp Cost number,Temp Rate,Reg Hours,O/T Hours'

export const ASSIST_SERVICES_LLC_REPORT_DESCRIPTION = 'Co Code,Batch ID,File #,Reg Hours,O/T Hours,Hours 3 Code,Hours 3 Amount,Hours 3 Code,Hours 3 Amount,Earnings 3 Code,Earnings 3 Amount,Adjust Ded Code,Adjust Ded Amount'


export const DROPDOWN_DESCRIPTION = {
    "standard": STANDARD_DESCRIPTION,
    "paydataImportTempCostNumber": TEMP_COST_DESCRIPTION,
    "paydataImportMemo": MEMO_MILES_DESCRIPTION,
    "paydataImportGrid": IMPORT_GRID_DESCRIPTION,
    "paydataCustomCA": CUSTOM_CA_DESCRIPTION,
    "paydataImportMemoV1": MEMO_MILES_OLD_DESCRIPTION,
    "paydataCustomWeekly": CUSTOM_WEEKLY_REPORT_DESCRIPTION,
    "blairduronpaydata" : BLAIR_DURON_REPORT_DESCRIPTION,
    "assistservicepaydatareport" : ASSIST_SERVICES_LLC_REPORT_DESCRIPTION
};



export const DROPDOWN_COLUMN_MAPPING = {
    "standard": {
        column: ADPPaydataStandardColumnName,
    },
    "paydataImportTempCostNumber": {
        column: ADPPaydataTempCostColumnName,
    },
    "paydataImportMemo": {
        column: ADPPaydataMemoMilesColumnName,
    },
    "paydataImportGrid": {
        column: ADPPaydataImportGridColumnName,
    },
    "paydataCustomCA": {
        column: ADPPaydataCustomCAColumnName,
        api: 'track/mobile/v1/allgeo/adpPaydataCustomCAJSON',
    },
    "paydataImportMemoV1": {
        column: ADPPaydataMemoMilesOldColumnName,
    },
    "paydataCustomWeekly": {
        column: ADPPaydataCustomWeeklyReport,
        api: 'track/mobile/v1/allgeo/populateAdpPaydataWeeklyJson',
    },
    "blairduronpaydata" : {
        column: BlairDuronReport,
        api: 'track/mobile/v1/allgeo/adpPayDataTaskReport',
    },
    "assistservicepaydatareport" : {
        column: AssistServicesLLCReport,
        api: 'track/mobile/v1/allgeo/assistServicesPaydataReport',
    }
};
// export const ADP_PAYLOAD_DROPDOWN = [
//     { label: "Standard", value: "standard" },
//     { label: "Paydata with Temp Cost", value: "paydataImportTempCostNumber" },
//     { label: "Paydata with Memo (for Miles)", value: "paydataImportMemo" },
//     { label: "Paydata Import Grid ", value: "paydataImportGrid" },
//     { label: "Paydata Custom CA", value: "paydataCustomCA" },
//     { label: "Paydata with Memo (for miles old)", value: "paydataImportMemoV1" }, 
//     { label: "Paydata Custom Weekly Report", value: "paydataCustomWeekly" },
// ]


export const ADP_PAYLOAD_DROPDOWN = [
    { label: "Paydata Custom Weekly Report", value: "paydataCustomWeekly" },
]


export const sosDetailedReportColumnName = [
    {
        title: `Account Name`,
        field: "accountName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "SOS DateTime",
        field: "sosDateTime",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Resolve Date Time",
        field: "resolveDateTime",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Elapsed Time",
        field: "elapsedTime",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "User Name",
        field: "userName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Phone #",
        field: "phoneNumber",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Location",
        field: "location",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "TxN Type",
        field: "txnType",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Source",
        field: "source",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "Emergency No",
        field: "emergencyNo",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "Emergency Address",
        field: "emergencyAddress",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
]

export const ADPEarningCodePayrollReportColumnName = [
    {
        title: `Co Code`,
        field: "coCode",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Batch ID",
        field: "batchId",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "File #",
        field: "fileNumber",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Temp Cost Number",
        field: "temcostNumber",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Temp Rate",
        field: "tempRate",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Reg Hours",
        field: "regHours",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "O/T Hours",
        field: "otHours",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Hours 3 Code",
        field: "hours3code",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Hours 3 Amount",
        field: "hours3amount",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "Earnings 3 Code",
        field: "earning3code",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "Earnings 3 Amount",
        field: "earning3amount",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
]

export const SOS_DETAIL_REPORT_DESCRIPTION = 'Account Name,SOS DateTime,Resolve Date Time,Elapsed Time,User Name,Phone #,Location,TxN Type,Source,Emergency No,Emergency Address'

export const EARNING_CODE_PAYROLL_DESCRIPTION = 'Co Code,Batch ID,File #,Temp Cost Number,Temp Rate,Reg Hours,O/T Hours,Hours 3 Code,Hours 3 Amount,Earnings 3 Code,Earnings 3 Amount'

export const ADPTimeCardReportColumnName = [
    {
        title: `DeviceID`,
        field: "deviceId",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Date",
        field: "date",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Duration",
        field: "duration",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Service Hours",
        field: "serviceHours",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Pay Code",
        field: "paycode",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
]

export const ActiveDeviceReportColumnName = [
    {
        title: `Phone#`,
        field: "Phone#",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "# of Active Days",
        field: "#_of_Active_Days",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
]

export const ADP_TIMECARD_REPORT_DESCRIPTION = 'DeviceID,Date,Duration,Service Hours,Pay Code'
export const ACTIVE_DEVICE_REPORT_DESCRIPTION = 'Phone#,Date,# of Active Days'


export const firstInLastOutColumnName = [
    {
        title: `${employeeLabel}#`,
        field: "deviceId",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `${employeeLabel} Name`,
        field: "employeeName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `Start ${jobsiteLabel}`,
        field: "startJobsite",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Start Address",
        field: "startAddress",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Service Date",
        field: "serviceDate",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Service Start Time",
        field: "serviceStartTime",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Service End Time",
        field: "serviceEndTime",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Service Hours",
        field: "serviceHours",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Distance",
        field: "distance",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: `End ${jobsiteLabel}`,
        field: "endJobsite",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "End Address",
        field: "endAddress",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
]



export const FIRSTIN_LASTOUT_REPORT_DESCRIPTION = `${employeeLabel}#,${employeeLabel} Name,Start Jobiste,Start Address,Service Date,Service Start Time,Service End Time,Service Hours,Distance,End Jobiste,Date,End Address`


export const ADMIN_ADP_PAYLOAD_DROPDOWN = [
    { label: "Standard", value: "standard" },
    { label: "Paydata with Temp Cost", value: "paydataImportTempCostNumber" },
    { label: "Paydata with Memo (for Miles)", value: "paydataImportMemo" },
    { label: "Paydata Import Grid ", value: "paydataImportGrid" },
    { label: "Paydata Custom CA", value: "paydataCustomCA" },
    { label: "Paydata with Memo (for miles old)", value: "paydataImportMemoV1" },
    { label: "Paydata Custom Weekly Report", value: "paydataCustomWeekly" },
]

export const FILTER_TYPE = {
    GROUP_ONLY: "grouponly",
    EMP_ONLY: "emponly",
    BOTH: "both",
    NONE : "none"
}

export const PARC_REPORT_DESCRIPTION = "CO CODE, BATCH ID, FILE #, BATCH DESCRIPTION, Name, Rate, Rate Code, Temp Rate, Regular Hours, Regular Earnings,MISC-TAX, Pay #, Tax Frequency"
