import React, { useState, useEffect, useRef } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { Checkbox } from '@progress/kendo-react-inputs';
import './SaveTemplate.css';
import DragIndicator from '../../../assets/images/Drag_Indicator.svg'
import Settingsicon from '../../../assets/images/Settings.svg'

import { editTimeClockColumns, milesColumns, advanceTimeClockColumns, approvalAuditcolumns, distanceAuditColumns } from '../EditReport/editReportUtils'
import { apiProvider } from '../../.././services/api/provider';

const templateColumnMapping = (tempName) => {
    switch (tempName) {
        case "editTimeclock":
            return editTimeClockColumns;
        case "editMile":
            return milesColumns;
        case "advanceTimeclock":
            return advanceTimeClockColumns;
        case "auditReport":
            return approvalAuditcolumns;
        case "distanceAudit":
            return distanceAuditColumns;
        default:
            // Handle cases where tempName does not match any of the cases
            console.warn(`Unknown template name: ${tempName}`);
            return [];
    }
};

const RESET_DATA_STATE = {
    "filter": undefined,
    "sort": [],
    "skip": 0,
    "take": 1000,
    "group": []
}


export const SaveTemplate = ({ columnList, setColumnList, templateName, setShowLoader, setToastMsg, kendoDataState }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [data, setData] = useState([...columnList]);
    const [draggedItem, setDraggedItem] = useState(null);
    const dropdownRef = useRef(null);
    const itemsPerPage = 10;
    const [selectAll, setSelectAll] = useState(false);

    const saveTemplateApi = async (payload, isreset) => {
        try {
            setShowLoader(true)
            const response = await apiProvider.post(`/track/mobile/v1/allgeo/saveEditReportTemplate?templateName=${templateName}`, payload)
            if (response.StatusCode === 200) {
                if (isreset) {
                    setCurrentPage(0);
                    setColumnList(templateColumnMapping(templateName))
                    setToastMsg({ msg: 'Reset Template Data Sucessfully', type: 'msgSuccess' })
                } else {
                    setColumnList(data)
                    setToastMsg({ msg: response.SucessMsg, type: 'msgSuccess' })
                }
            }
            else {
                setToastMsg({ msg: response.ErrorMsg, type: 'msgError' })
            }
        } catch (error) {
            console.log(error)
        } finally {
            setShowLoader(false)
            handleCloseDropdown()
        }
    }

    useEffect(() => {
        const columnListDeepCopy = JSON.parse(JSON.stringify([...columnList]))
        setData(columnListDeepCopy);
        handleCloseDropdown()
    }, [columnList])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                typeof dropdownRef.current.contains === 'function' &&
                !dropdownRef.current.contains(event.target) &&
                anchorEl &&
                !anchorEl.contains(event.target)
            ) {
                handleCloseDropdown()
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef, anchorEl]);


    const handleClick = (event) => {

        if (anchorEl) {
            setAnchorEl(null);
        } else {
            const columnListDeepCopy = JSON.parse(JSON.stringify([...columnList]))
            setData(columnListDeepCopy);
            setAnchorEl(event.currentTarget);

            const allSelected = columnListDeepCopy.every(item => item.show);
            setSelectAll(allSelected);
        }

    };

    const moveItem = (from, to) => {
        const updatedData = [...data];
        const [movedItem] = updatedData.splice(from, 1);
        updatedData.splice(to, 0, movedItem);
        setData(updatedData);
    };

    const toggleSelect = (index) => {
        const updatedData = [...data];
        updatedData[index].show = !updatedData[index].show;

        // If no items are selected, ensure "deviceName" remains selected
        if (!updatedData.some(item => item.show)) {
            const deviceNameIndex = updatedData.findIndex(item => item.field === 'deviceName');
            if (deviceNameIndex !== -1) {
                updatedData[deviceNameIndex].show = true;
            }
        }

        const allSelected = updatedData.every(item => item.show);
        setSelectAll(allSelected);
        setData(updatedData);
    };

    const handlePageChange = (pageIndex) => {
        setCurrentPage(pageIndex);
    };

    const handleSave = () => {
        const payload = {
            columns: data,
            dataState: kendoDataState
        }
        saveTemplateApi(payload, false);
    };

    const handleReset = () => {
        const payload = {
            columns: templateColumnMapping(templateName),
            dataState: RESET_DATA_STATE
        }
        saveTemplateApi(payload, true);
    };

    const handleDragStart = (index) => {
        setDraggedItem(index);
    };

    const handleDragEnd = () => {
        setDraggedItem(null);
    };

    const handleDrop = (index) => {
        if (draggedItem !== null) {
            moveItem(draggedItem, currentPage * itemsPerPage + index);
            setDraggedItem(null);
        }
    };

    const handlePageDrop = (pageIndex) => {
        if (draggedItem !== null) {
            const newPageIndex = pageIndex * itemsPerPage;
            moveItem(draggedItem, newPageIndex);
            setCurrentPage(pageIndex);
            setDraggedItem(null);
        }
    };

    const handleCloseDropdown = () => {
        setAnchorEl(null);
        setCurrentPage(0);
        setSelectAll(false);
    }

    const toggleSelectAll = () => {
        let newSelectAll = !selectAll;
        let updatedData = data.map(item => ({ ...item, show: newSelectAll }));

        // If all items are deselected, ensure "deviceName" remains selected
        if (!newSelectAll) {
            const deviceNameIndex = updatedData.findIndex(item => item.field === 'deviceName');
            if (deviceNameIndex !== -1) {
                updatedData[deviceNameIndex].show = true;
            }
        }

        setSelectAll(updatedData.every(item => item.show));
        setData(updatedData);
    };


    const displayedItems = data.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);
    const pageCount = Math.ceil(data.length / itemsPerPage);

    return (
        <div className="app-container">
            <Button primary onClick={handleClick} ref={dropdownRef}>
                <img src={Settingsicon} alt="Settings Icon" className="settings-icon" />Customize Columns
            </Button>
            {anchorEl && (
                <div className="dropdown-container" ref={dropdownRef}>
                    <div className={`dropdown-item ${selectAll ? 'selected' : ''}`}>
                        <span className="icon">
                            <img src={DragIndicator} alt="Drag Indicator" />
                        </span>
                        <Checkbox
                            checked={selectAll}
                            onChange={toggleSelectAll}
                        />
                        <span className="item-text">Select All</span>
                    </div>
                    {displayedItems.map((item, index) => (
                        <div
                            key={index}
                            className={`dropdown-item ${item.show ? 'selected' : ''}`}
                            draggable
                            onDragStart={() => handleDragStart(currentPage * itemsPerPage + index)}
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={() => handleDrop(index)}
                            onDragEnd={handleDragEnd}
                        >
                            <span className="icon">
                                <img src={DragIndicator} alt="Drag Indicator" />
                            </span>
                            <Checkbox
                                checked={item.show}
                                onChange={() => toggleSelect(currentPage * itemsPerPage + index)}
                            />
                            <span className="item-text">{item.title}</span>
                        </div>
                    ))}
                    <div className="pagination">
                        {[...Array(pageCount)].map((_, pageIndex) => (
                            <div
                                key={pageIndex}
                                className={`page-indicator ${pageIndex === currentPage ? 'active' : ''}`}
                                onClick={() => handlePageChange(pageIndex)}
                                onDragOver={(e) => e.preventDefault()}
                                onDrop={() => handlePageDrop(pageIndex)}
                            ></div>
                        ))}
                    </div>
                    <div className="dropdown-buttons">
                        <Button onClick={handleReset}>Reset</Button>
                        <Button primary onClick={handleSave}>Save</Button>
                    </div>
                </div>
            )}
        </div>
    );

};