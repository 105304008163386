import * as React from "react";
import {
  GridColumnMenuSort,
  GridColumnMenuFilter,
  GridColumnMenuItemGroup,
  GridColumnMenuItem,
  GridColumnMenuItemContent,
} from "@progress/kendo-react-grid";
import { classNames } from "@progress/kendo-react-common";

export const ColumnMenu = (props) => {
  const [columns, setColumns] = React.useState(props.columns);
  const [columnsExpanded, setColumnsExpanded] = React.useState(false);
  const [filterExpanded, setFilterExpanded] = React.useState(false);
  const [selectAll, setSelectAll] = React.useState(false);

  let updatedProps = { ...props }; // {} || []
  if (Array.isArray(updatedProps.filter) && updatedProps.filter.length === 0 || typeof updatedProps.filter !== 'undefined' && Object.keys(updatedProps.filter).length === 0 && updatedProps.filter.constructor === Object) {
    updatedProps.filter = null;
  }

  const onToggleColumn = (id) => {
    setColumns(prevColumns => {
      if (id === "select-all") {
        const allSelected = prevColumns.slice(1).every((column) => column.show);
        const newColumns = prevColumns.map((column, idx) => ({
          ...column,
          show: idx === 0 || !allSelected,
        }));
        setSelectAll(!allSelected);
        return newColumns;
      } else {
        const newColumns = prevColumns.map((column, idx) =>
          idx === id ? { ...column, show: !column.show } : column
        );
        const allButFirstSelected = newColumns.slice(1).every((column) => column.show);
        setSelectAll(allButFirstSelected);
        return newColumns;
      }
    });
  };
  
  

  const handleColumnToggle = (state) => {
    updatedProps.onColumnLockToggle(updatedProps.column.field || "", state);

    if (updatedProps.onCloseMenu) {
      updatedProps.onCloseMenu();
    }
  };

  const onReset = (event) => {
    event.preventDefault();
    const newColumns = updatedProps.columns.map((col) => {
      return {
        ...col,
        show: true,
      };
    });
    setColumns(newColumns);
    updatedProps.onColumnsSubmit(newColumns);

    if (updatedProps.onCloseMenu) {
      updatedProps.onCloseMenu();
    }
  };

  const onSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }

    updatedProps.onColumnsSubmit(columns);

    if (updatedProps.onCloseMenu) {
      updatedProps.onCloseMenu();
    }
  };

  const onMenuItemClick = () => {
    const value = !columnsExpanded;
    setColumnsExpanded(value);
    setFilterExpanded(value ? false : filterExpanded);
  };

  const onFilterExpandChange = (value) => {
    setFilterExpanded(value);
    setColumnsExpanded(value ? false : columnsExpanded);
  };

  const oneVisibleColumn = columns.filter((c) => c.show).length === 1;
  return (
    <div>
      <GridColumnMenuSort {...updatedProps} style={{ backgroundColor: "blue" }} />
      <GridColumnMenuFilter
        {...updatedProps}
        onExpandChange={onFilterExpandChange}
        expanded={filterExpanded}
      />
      <GridColumnMenuItemGroup>
        {!updatedProps?.hideMenuCloumns&&(<GridColumnMenuItem
          title={"Columns"}
          iconClass={"k-i-columns"}
          onClick={onMenuItemClick}
        />)}
        <GridColumnMenuItemContent show={columnsExpanded}>
          <div className={"k-column-list-wrapper"}>
            <form onSubmit={onSubmit} onReset={onReset}>
              <div className={"k-column-list"}>
                <div className={"k-column-list-item"}>
                  <span>
                    <input
                      id={`column-visiblity-show-all`}
                      className="k-checkbox k-checkbox-md k-rounded-md"
                      type="checkbox"
                      readOnly={true}
                      checked={selectAll}
                      onClick={() => onToggleColumn("select-all")}
                    />
                    <label
                      htmlFor={`column-visiblity-show-all`}
                      className="k-checkbox-label"
                      style={{ userSelect: "none" }}
                    >
                      Select All
                    </label>
                  </span>
                </div>
                {columns.map((column, idx) => (
                  <div key={idx} className={"k-column-list-item"}>
                    <span>
                      <input
                        id={`column-visiblity-show-${idx}`}
                        className="k-checkbox k-checkbox-md k-rounded-md"
                        type="checkbox"
                        readOnly={true}
                        disabled={column.show && oneVisibleColumn}
                        checked={column.show}
                        onClick={() => {
                          onToggleColumn(idx);
                        }}
                      />
                      <label
                        htmlFor={`column-visiblity-show-${idx}`}
                        className="k-checkbox-label"
                        style={{
                          userSelect: "none",
                        }}
                      >
                        {column.title}
                      </label>
                    </span>
                  </div>
                ))}
              </div>
              <div className={"k-actions k-hstack k-justify-content-stretch"}>
                <button
                  type={"reset"}
                  className={
                    "k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                  }
                >
                  Reset
                </button>
                <button
                  className={
                    "k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                  }
                >
                  SAVE
                </button>
              </div>
            </form>
          </div>
        </GridColumnMenuItemContent>
        <GridColumnMenuItemContent show={true}>
          <div className={"k-column-list-wrapper"}>
            <div className={"k-column-list"}>
              <div
                className={classNames("k-column-list-item", {
                  "k-disabled": updatedProps.locked,
                })}
                onClick={() => handleColumnToggle(true)}
              >
                <span className="k-icon k-i-lock" /> Lock Column
              </div>
              <div
                className={classNames("k-column-list-item", {
                  "k-disabled": !updatedProps.locked,
                })}
                onClick={() => handleColumnToggle(false)}
              >
                <span className="k-icon k-i-unlock" /> Unlock Column
              </div>
            </div>
          </div>
        </GridColumnMenuItemContent>
      </GridColumnMenuItemGroup>
    </div>
  );
};
