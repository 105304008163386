import React, { useEffect, useRef, useState } from "react";
import "./monitorNavigationCard.css";
import ExpandIcon from "../../../../assets/images/expand-more.svg";
import MapLocationIcon from "../../../../assets/images/Map and Location.svg";
import AddIconMonitorDisable from "../../../../assets/images/add-icon-disable.svg";
import { Breadcrumb } from "./Breadcrumb";
import { ExpandViewPopup } from "./ExpandViewPopup";
import { LocationTable } from "./LocationTable";
import { TooltipKendo } from "../../common/Tooltip";
import useGeneralSettings from "../../../../hooks/useGeneralSettings";
import { getAccessLevel } from "../../../../components/common/functions";

import TabIcon from "../../../../assets/images/newtab-icon.svg";
let iFrameUrl = null;
let iFrameUrlOndemand  = null;
export const MonitorNavigationMapLocationCard = ({
  handlePinned,
  handleUnPinned,
  employeeData,
  fetchEmployeeAppData,
  setToastMsg,
  allEmployeeList,
  breadcrumbData,
  setBreadcrumbData,
}) => {
  const { timezone } = useGeneralSettings();

  const [showExpanMore, setShowExpandMore] = useState(false);
  const [isMapDisabled, setIsMapDisabled] = useState(true);
  console.log(timezone, "timezonetimezone");
  const iframeRef = useRef(null);
  const [tabToggle, setTabtoggle] = useState(1);
  const handleToggle = (val) => {
    setTabtoggle(val);
  };

  const handleMapExpandButtonClick = () => {
    handleMapExpandButtonClick();
    setShowExpandMore(true);
  };


  useEffect(() => {
    getAccessLevel("reports", "").then((response) => {
      response.childMenu = response.parentMenu;
      setIsMapDisabled(response?.parentMenu===0);
    });
    // handleGetTimeClockReport()
  }, []);


  const handleMapNewTap = () =>{

  }

  useEffect(() => {
    setBreadcrumbData({
      device: allEmployeeList[0]?.employeeId,
      date_tz: timezone,
      datepicker_from1: new Date(),
      timepicker_from: new Date("2023/01/01 00:00:00"),
      datepicker_to1: new Date(),
      timepicker_to: new Date("2023/01/01 23:59:00"),
    });
  }, [timezone, allEmployeeList]);
  // console.log(timezone,"timezonetimezone")
  useEffect(()=>{
    iFrameUrl = `${
      process.env.REACT_APP_API_URL
    }/track/mgt?page=manageteaminfoV1${tabToggle===2?'&phoneList='+breadcrumbData.device:""
    }&datepicker_from1=${moment(breadcrumbData?.datepicker_from1).format(
      "YYYY/MM/DD"
    )}&timepicker_from=${moment(breadcrumbData.timepicker_from).format(
      "HH:mm:ss"
    )}&datepicker_to1=${moment(breadcrumbData.datepicker_to1).format(
      "YYYY/MM/DD"
    )}&timepicker_to=${moment(breadcrumbData.timepicker_to).format(
      "HH:mm:ss"
    )}&date_tz=${encodeURIComponent(breadcrumbData.date_tz)}&showLocationMap=yes`;
  
    iFrameUrlOndemand = `${
      process.env.REACT_APP_API_URL
    }/track/mgt?page=manageteaminfoV1`;
  },[breadcrumbData,tabToggle])

  return (
    <div className="parentContainer">
      <div className="headingContainer">
        <div style={{display:"flex"}}>
          <span style={{ marginRight: "6px" }}>
            <img style={{paddingTop:1}} src={MapLocationIcon} alt="" />
          </span>
          <span>Map and Location</span>
        </div>
        <TooltipKendo>
            <img
              title={`${!isMapDisabled?'Open In New Tab':''}`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                // handleMapExpandButtonClick();
                // setShowExpandMore(true);
              
              const form = document.getElementById("map_input_sub_form");
              form.phoneList.value = tabToggle===2?breadcrumbData.device:""

              !isMapDisabled&&form.submit();
              }}
              src={ExpandIcon}
              className={`${isMapDisabled ? 'disabled_field_table':"cursor_pointer"}`}

            />
     
        </TooltipKendo>
      </div>
      <div className="tabContainer">
        <p
          onClick={() => handleToggle(1)}
          className={tabToggle === 1 && "active"}
        >
          On Demand Location
        </p>
      {!isMapDisabled&&(  <p
          onClick={() => handleToggle(2)}
          className={tabToggle === 2 && "active"}
        >
          Breadcrumb
        </p>)}
      </div>
      {tabToggle === 1 && (
        <LocationTable
          fetchEmployeeAppData={fetchEmployeeAppData}
          employeeData={employeeData}
          setToastMsg={setToastMsg}
        />
      )}
      {tabToggle === 2 && (
        <Breadcrumb
          handlePinned={handlePinned}
          handleUnPinned={handleUnPinned}
          showExpanMore={showExpanMore}
          setShowExpandMore={setShowExpandMore}
          allEmployeeList={allEmployeeList}
          breadcrumbData={breadcrumbData}
          setBreadcrumbData={setBreadcrumbData}
          iframeRef={iframeRef}
        />
      )}
      {showExpanMore && (
        <ExpandViewPopup
          breadcrumbData={breadcrumbData}
          handlePinned={handlePinned}
          handleUnPinned={handleUnPinned}
          closePopup={() => setShowExpandMore(false)}
          iframeRef={iframeRef}
          tabToggle={tabToggle}
        />
      )}
      <form
        id="map_input_sub_form"
        method="post"
        target="_blank"
        action={tabToggle===2?iFrameUrl:iFrameUrlOndemand}
        onSubmit={(e) => e.preventDefault()}
      >
        <input type="hidden" name="phoneList" value="" />
        <input type="hidden" name="datepicker_from1" value="" />
        <input type="hidden" name="timepicker_from" value="" />
        <input type="hidden" name="datepicker_to1" value="" />
        <input type="hidden" name="timepicker_to" value="" />
        <input type="hidden" name="date_tz" value="" />
        <input type="hidden" name="showLocationMap" value="" />
      </form>
    </div>
  );
};
