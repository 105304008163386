import React, { useState, useEffect, Fragment } from "react";
import SettingsGeneral from './SettingsGeneral';
import SettingsMobileApp from "./SettingsMobileApp";
import SettingsSecurity from "./SettingsSecurity";
import SettingsPayroll from "./SettingsPayroll";
import SettingsLabels from "./SettingsLabels";
import { useDocumentTitle } from '../../../hooks/setDocumentTitle';
import Header from "../../layout/Header";
import { getAccessLevel } from '../../common/functions';
import { ConfirmPopup } from "../../common/PopUp";


export default function CompanySettings() {

  useDocumentTitle("Settings - allGeo");
  const [baseModule, setBaseModule] = useState('general');
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [accessLevel, setAccessLevel] = useState({});
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    getAccessLevel("company", "settings").then((response) => {
      setAccessLevel(response);
    });
  }, []);

  const tabMenuHandler = (module) => {
    if (!formIsDirty) {
      setBaseModule(module);
    } else {
      localStorage.setItem('modulePath', module)
      setShowDialog(true)
    }
  }

  const onSuccess = () => {
    setShowDialog(false)
    setBaseModule(localStorage.getItem('modulePath'));
    localStorage.removeItem('modulePath')
    setFormIsDirty(false);
  }

  const onFaliure = () => {
    setShowDialog(false)
  }

  const generalSettingsState = (formIsDirtyState) => {
    setFormIsDirty(formIsDirtyState);
  }

  return (
    <Fragment>
      <div className="mainContentWrap">
        <Header title="Settings" />
        <div className="mainContent" id="mainContent">
          <ul className="rightInnerNav">
            <li><a onClick={() => tabMenuHandler('general')} className={baseModule === 'general' ? 'active' : ''}>General</a></li>
            <li><a onClick={() => tabMenuHandler('app')} className={baseModule === 'app' ? 'active' : ''}>Mobile App</a></li>
            <li><a onClick={() => tabMenuHandler('security')} className={baseModule === 'security' ? 'active' : ''}>Security & Privacy</a></li>
            <li><a onClick={() => tabMenuHandler('payroll')} className={baseModule === 'payroll' ? 'active' : ''}>Payroll</a></li>
            <li><a onClick={() => tabMenuHandler('labels')} className={baseModule === 'labels' ? 'active' : ''}>Labels</a></li>
          </ul>

          {baseModule === 'general' && <SettingsGeneral formState={generalSettingsState} accessLevel={accessLevel} baseModule={baseModule} />}
          {baseModule === 'app' && <SettingsMobileApp formState={generalSettingsState} accessLevel={accessLevel} />}
          {baseModule === 'security' && <SettingsSecurity formState={generalSettingsState} accessLevel={accessLevel} />}
          {baseModule === 'payroll' && <SettingsPayroll formState={generalSettingsState} accessLevel={accessLevel} />}
          {baseModule === 'labels' && <SettingsLabels formState={generalSettingsState} accessLevel={accessLevel}/>}
        
        </div>
      </div>
      <ConfirmPopup
        showDialog={showDialog}
        message={"The changes are still unsaved. Are you sure you want to leave?"}
        confirmNavigation={onSuccess}
        cancelNavigation={onFaliure}
      />
    </Fragment>
  );
}
