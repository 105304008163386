import { apiProvider } from "../../../../services/api/provider";

export const handleFetchAllEmployeeList = async (setAllEmployeeList) => {
  const childAccountID = localStorage.getItem("childAccountId");

    try {
        // setShowLoader(true)
        const resp =await apiProvider.getAll(
            `/track/mobile/v1/allgeo/getEmployeeList?operationType=Desktop&isAllAccount=${childAccountID === "all"}`
            
          )
          setAllEmployeeList(resp.data)

           console.log(resp,"ressss")
        // return resp.data; // Return the fetched data
        // setTimeclockReportsData(resp.data)
        // setCurrentTimeAndDateMiles(new Date())
  
    } catch (err) {
        console.error("Error fetching Miles Data", err);
        throw err; // Re-throw the error to be caught by the caller
    }finally{
        // setShowLoader(false)
    }
  }