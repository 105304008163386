import React from 'react'

const DeletePopup = ({ msg = '', onSuccess, closePopup }) => {

    return (
        <>
            <div className="popupWrap msgPopup deleteMsg active">
                <div className="msgPopupCont">
                    <div className="msgIcon msgDelete">Delete</div>
                    <div className="msgContent">
                        <div>
                            {
                                msg.trim().length > 0
                                    ?
                                    msg
                                    :
                                    "Are you sure you want to delete the selected record?"
                            }
                        </div>
                    </div>
                </div>
                <div className="popupMsgBtn">
                    <a className="okBtn cursorPointer" onClick={closePopup}>Cancel</a>
                    <button
                        className="btnStyle okBtn"
                        onClick={onSuccess}
                    >Yes</button>
                </div>
            </div>
        </>
    )
}

export default DeletePopup
