import React, { useEffect, useRef, useState } from "react";
import "./notificationAndAlertsNewtab.css";
import { NotificationAlertsView } from "../NotificationAlertsView";
import { getAlertsAndNotificationData } from "../../../utils";

const NotiAlertqueryParams = {
  searchBy: "all",
  searchValue: "all",
  parentAcctID: JSON.parse(localStorage.getItem("parent"))?.accountId || "",
  childAccountID:
    localStorage.getItem("childAccountId") || localStorage.getItem("accountId"),
};

export const NotificationAndAlertsNewtab = () => {
  const parentRef = useRef(null);
  const moment = window.moment;
  const last1Day = moment().subtract(1, "days");

  const [alertNotificationData, setAlertNotificationData] = useState([]);
  const [currentTimeAndDateAlertsNoti, setCurrentTimeAndDateAlertsNoti] =
    useState();
  const [notificationAndAlertMetaData, setNotificationAndAlertMetaData] =
    useState({
      page: 1,
      perPage: 25,
      totalDataCount: 0,
      fromDate: last1Day.format("YYYY/MM/DD"),
      toDate: moment().format("YYYY/MM/DD"),
      queryParams: NotiAlertqueryParams,
    });

  useEffect(() => {
    getAlertsAndNotificationData(
      setCurrentTimeAndDateAlertsNoti,
      setNotificationAndAlertMetaData,
      notificationAndAlertMetaData,
      setAlertNotificationData
    );
  }, []);

  useEffect(() => {
    if (parentRef.current) {
      const parentHeight = parentRef.current.offsetHeight;
      console.log("Parent div height:", parentHeight);
    }
  }, [parentRef.current]);
  return (
    <div className="notificationNewTab">
      <div className="employeeHeadingContainer">
        <p className="employeeheadtag">
          Alerts and Notifications
          <span className="refreshtext">
            Last Refreshed:
            <span>12:12:24</span>
            <span>08:00:00 AM</span>
          </span>
        </p>
      </div>
      {console.log(parentRef?.current?.offsetHeight,"parentRef?.current?.offsetHeight")}
          {/* <div style={{margin:"10px 10px 10px 13px"}} className="expand-parent-border"> */}
      <div  ref={parentRef} className="employeeparentBox pinnedbox new-tab-parent-border">
        <NotificationAlertsView
          height={parentRef?.current?.offsetHeight}
          notificationData={alertNotificationData}
          setCurrentTimeAndDateAlertsNoti={setCurrentTimeAndDateAlertsNoti}
          notificationAndAlertMetaData={notificationAndAlertMetaData}
          setAlertNotificationData={setAlertNotificationData}
          setNotificationAndAlertMetaData={setNotificationAndAlertMetaData}
        />
      </div>
    </div>
  );
};
