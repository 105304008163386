import React, { useState, useEffect } from 'react';
import { apiProvider } from "../../../../services/api/provider";
import { extractTaskIDs } from '../utils/helperFunctions';
import {DELETE_CONFIRMATION_MESSAGE} from '../utils/workOrderUtils'
import { getAccountLabel } from '../../../../utils';

export const WorkOrderDelete = ({ WOdata, setShow, setDeleteModalOpen, setShowLoader, setToastMsg }) => {
    const [deleteShow, setDeleteShow] = useState(false);
    const workorderLabel = getAccountLabel('workorder');

    useEffect(() => {
        setDeleteShow(!!WOdata.TaskID);
    }, [WOdata]);

    const deleteWorkOrder = async (deletionType, data) => {
        const payload = Array.isArray(data) ? data : [data];
        const taskId = extractTaskIDs(data);

        try {
            setShowLoader(true);
            const response = await apiProvider.post(`track/react/v1/allgeo/deleteMasterWorkorder?deletionType=${deletionType}&inactiveWorkorderIds=${taskId}`, payload);
            if (response.status === 200) {
                setToastMsg({
                    msg: `${workorderLabel} Deleted Succesfully`,
                    type: "msgSuccess"
                })
                setShow(false);
            }
            return response;
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false);
        }
    };

    return (
        <>
            {deleteShow && (
                <div className="popup_wrapper msg_popup del_msg active">
                    <div className="sm_modal" onClick={() => setDeleteModalOpen(false)}>
                        <div className="msg_del_icon">Delete</div>
                        <div className="msg_content">
                            <div>
                                <strong>{DELETE_CONFIRMATION_MESSAGE.confirmationText}</strong>
                                <p>{DELETE_CONFIRMATION_MESSAGE.deletionOptionsText}</p>
                            </div>
                            <button className="close_msg_btn" ></button>
                        </div>
                    </div>
                    <div className="sm_popup_btn">
                        <button className="button_style btn2" onClick={() => deleteWorkOrder('recurring', WOdata)}>Delete Series</button>
                        <button className="button_style okBtn" onClick={() => deleteWorkOrder('current', WOdata)}>Delete Current Occurrence</button>
                    </div>
                </div>
            )}
            {deleteShow && <div className="overlay active"></div>}
        </>
    );
};
