import React, { useEffect, useState } from 'react'
import { DateTimePicker } from '@progress/kendo-react-dateinputs'
import { apiProvider } from '../../../../services/api/provider'
import { DropdownList } from '../../../common/dropdown'
import SiteLoader from "../../../common/SiteLoader"
import { ConfirmPopup } from "../../../common/PopUp";
import { getInitialStateFromProps, getInitialRecuurenceRule, getFormattedDateTime, generateDailyRecurrenceRule, generateWeeklyRecurrenceRule, getDeviceIDByDeviceName } from './helperFunctions'
import { REPEAT_WEEKLY, REPEAT_DAILY } from './constant'
import { DEFAULT_MODE } from '../utils/workOrderUtils'
import { WeekDaySelector } from './WeekDaySelector'
import { WeeklyRecurrenceOptions } from './WeeklyRecurrenceOptions'
import { DailyOptions } from './DailyOptions'
import { getAccountLabel } from '../../../../utils'

export const WorkOrderEdit = ({ WOdata, setMode, closeEdit, setToastMsg }) => {
    const [showDialog, setShowDialog] = useState(false);
    const [isAnyChangeInField, setIsAnyChangeInField] = useState(false)
    const [repeatOption, setRepeatOption] = useState('NEVER');
    const [selectedOption, setSelectedOption] = useState('On');
    const [allEmp, setAllEmp] = useState([])
    const [params, setParams] = useState(getInitialStateFromProps(WOdata));
    const [jobNameList, setJobNameList] = useState([]);
    const [jobSiteList, setJobSiteList] = useState([]);
    const [repeatDaily, setRepeatDaily] = useState(REPEAT_DAILY)
    const [repeatWeekly, setRepeatWeekly] = useState(REPEAT_WEEKLY)
    const [selectedDays, setSelectedDays] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [empWithId, setEmpWithId] = useState();
    const workorderLabel = getAccountLabel('workorder');
    const jobsiteLabel = getAccountLabel('jobsite');
    const employeeLabel = getAccountLabel('employee');

    const getEmployee = async () => {
        try {
            const response = await apiProvider.getAll('track/mobile/v1/allgeo/getEmployeeGroup');
            if (response.status === 200) {
                if (Array.isArray(response.data)) {
                    setAllEmp(response.data.map(e => ({ label: e.DeviceName, value: e.DeviceName })));
                    setEmpWithId(response.data)
                } else {
                    console.error('Response data is not an array:', response.data);
                }
            }
            return [];
        } catch (error) {
            console.error(error);
        }
    };

    const getDropdownData = async () => {
        try {

            const response = await apiProvider.getAll('/track/mobile/v1/allgeo/getEditWorkOrderDropdowns',)
            if (response.status === 200) {
                setJobNameList(response.data.jobNameList.map(e => ({ label: e, value: e })));
                setJobSiteList(response.data.jobSiteList.map(e => ({ label: e, value: e })));
            }
        } catch (error) {
            console.log(error);
        }
    }

    const submitEdit = async () => {
        try {
            const recurrenceRule = generateRecurrenceRule();
            const PARAM = {
                ...params,
                RecurrenceRule: recurrenceRule,
                OwnerID: getDeviceIDByDeviceName(empWithId, params.DeviceName),
                DeviceName: WOdata.DeviceName
            };

            setShowLoader(true);
            const response = await apiProvider.post('track/react/v1/allgeo/upsertCalendarWorkorder?action=update', [PARAM]);
            setShowLoader(false);

            if (response.status === 200) {
                setToastMsg({
                    // msg: `${workorderLabel} Updated Successfully`,
                    msg: response.message,
                    type: "msgSuccess"
                });
                closeWorkOrderEditModal();
            }
        } catch (error) {
            console.error(error);
            setShowLoader(false);
        }
    };

    const generateRecurrenceRule = () => {
        let recurrenceRule = "";

        if (repeatOption === "DAILY") {
            recurrenceRule = generateDailyRecurrenceRule(selectedOption, repeatOption, repeatDaily);
        } else if (repeatOption === "WEEKLY") {
            recurrenceRule = generateWeeklyRecurrenceRule(selectedOption, repeatOption, repeatWeekly);
        }

        if (selectedDays.length) {
            const days = selectedDays.toString();
            recurrenceRule += `;BYDAY=${days}`;
        }

        return recurrenceRule;
    };

    useEffect(() => {
        getDropdownData();
        getEmployee();
        getInitialRecuurenceRule(WOdata, setRepeatOption, setRepeatDaily, setRepeatWeekly, setSelectedOption, setSelectedDays);
    }, [])

    const handleDayClick = (day) => {
        let newSelectedDays;
        if (selectedDays.includes(day)) {
            newSelectedDays = selectedDays.filter((selectedDay) => selectedDay !== day);
        } else {
            newSelectedDays = [...selectedDays, day];
        }
        setSelectedDays(newSelectedDays);
    };

    const isDayActive = (day) => selectedDays.includes(day);

    const closeWorkOrderEditModal = () => {
        if (closeEdit) {
            closeEdit()
        } else {
            setMode(DEFAULT_MODE)
        }
    }

    const closeModal = () => {
        if (isAnyChangeInField) {
            setShowDialog(true)
        } else {
            closeWorkOrderEditModal()
        }
    }
    
    return (
        <div className="md_modal_wrap med_popup active">
            <div className="modal_head_sec">
                <span>Edit {workorderLabel}</span>
                <div className="heading_btn_wrap">
                    <button className="close_wrk" onClick={closeModal}></button>
                </div>
            </div>
            <div className="modal_content_sec">
                <div className="row_wrk_blk">
                    <div className="col_six">
                        <label>{workorderLabel} ID</label>
                        <input type="text" value={params.Title}
                            readOnly
                        />
                    </div>
                    <div className="col_six">
                        <label>Select {employeeLabel}</label>
                        <DropdownList
                            list={allEmp}
                            filterable={true}
                            onChange={(e) => {
                                setParams({
                                    ...params,
                                    DeviceName: e.target.value.id
                                });
                                setIsAnyChangeInField(true);
                            }}
                            defaultValue={params.DeviceName}
                        />
                    </div>
                </div>
                <div className="row_wrk_blk">
                    <div className="col_three">
                        <label>Locate</label>
                        <div className="customize_toggle toggle_enable">
                            <input
                                type="checkbox"
                                id="StatusLocate"
                                checked={params.Locatable === "Yes"}
                                onChange={() => {
                                    setParams(prevParams => ({
                                        ...prevParams,
                                        Locatable: prevParams.Locatable === "Yes" ? "No" : "Yes"
                                    }));
                                    setIsAnyChangeInField(true);
                                }}
                            />
                            <label className="customize_label" htmlFor="StatusLocate"></label>
                        </div>

                    </div>
                    <div className="col_three">
                        <label>All Day Event</label>
                        <div className="customize_toggle toggle_enable">
                            <input
                                type="checkbox"
                                id="StatusAllDayEvent"
                                checked={params.IsAllDay === "Yes"}
                                onChange={() => {
                                    setParams(prevParams => ({
                                        ...prevParams,
                                        IsAllDay: prevParams.IsAllDay === "Yes" ? "No" : "Yes"
                                    }));
                                    setIsAnyChangeInField(true);
                                }}
                            />
                            <label className="customize_label" htmlFor="StatusAllDayEvent"></label>
                        </div>
                    </div>
                    <div className="col_three calendar_icon" >
                        <label>Start Day & Time</label>
                        {
                            <DateTimePicker
                                defaultValue={new Date(params.Start)}
                                format={"dd-MM-yyyy hh:mm:ss a"}
                                onChange={(e) => {
                                    setParams({
                                        ...params,
                                        Start: getFormattedDateTime(e)
                                    })
                                    setIsAnyChangeInField(true);
                                }}
                            />
                        }
                    </div>
                    <div className="col_three calendar_icon">
                        <label>End Day & Time</label>
                        {
                            <DateTimePicker
                                defaultValue={new Date(params.End)}
                                format={"dd-MM-yyyy hh:mm:ss a"}
                                onChange={(e) => {
                                    setParams({
                                        ...params,
                                        End: getFormattedDateTime(e)
                                    })
                                    setIsAnyChangeInField(true);
                                }}
                            />
                        }
                    </div>
                </div>
                <div className='hr_line' />
                <div className="row_wrk_blk">
                    <div className="col_four">
                        <label>Repeat</label>
                        <div className="custom_fields">
                            <div className="radio_button">
                                <input
                                    type="radio"
                                    id="NEVER"
                                    name="Repeat"
                                    checked={repeatOption === 'NEVER'}
                                    onChange={() => {
                                        setRepeatOption('NEVER');
                                        setRepeatDaily(REPEAT_DAILY)
                                        setRepeatWeekly(REPEAT_WEEKLY)
                                    }}
                                />
                                <label htmlFor="NEVER">Never</label>
                            </div>
                            <div className="radio_button">
                                <input
                                    type="radio"
                                    id="DAILY"
                                    name="Repeat"
                                    checked={repeatOption === 'DAILY'}
                                    onChange={() => {
                                        setRepeatOption('DAILY');
                                        setRepeatWeekly(REPEAT_WEEKLY)
                                        setIsAnyChangeInField(true);
                                    }}
                                />
                                <label htmlFor="DAILY">Daily</label>
                            </div>
                            <div className="radio_button">
                                <input
                                    type="radio"
                                    id="WEEKLY"
                                    name="Repeat"
                                    checked={repeatOption === 'WEEKLY'}
                                    onChange={() => {
                                        setRepeatOption('WEEKLY')
                                        setIsAnyChangeInField(true);
                                    }}

                                />
                                <label htmlFor="WEEKLY">Weekly</label>
                            </div>
                        </div>
                    </div>
                    {repeatOption === 'DAILY' && (
                        <>
                            <DailyOptions
                                selectedOption={selectedOption}
                                repeatDaily={repeatDaily}
                                setSelectedOption={setSelectedOption}
                                setRepeatDaily={setRepeatDaily}
                                setIsAnyChangeInField={setIsAnyChangeInField}
                            />
                        </>
                    )}
                    {repeatOption == "WEEKLY" && (
                        <>
                            <div className="col_two">
                                <label>Repeat Every</label>
                                <div className="with_input_sub">
                                    <input type="text" value={repeatWeekly.weeks}
                                        onChange={(e) => {
                                            setRepeatWeekly({
                                                ...repeatWeekly,
                                                weeks: e.target.value
                                            })
                                            setIsAnyChangeInField(true);
                                        }} />
                                    <span>Wks</span>
                                </div>
                            </div>

                            <WeekDaySelector isDayActive={isDayActive} handleDayClick={handleDayClick} />
                        </>
                    )}

                </div>
                {repeatOption == "WEEKLY" && (
                    <>
                        <WeeklyRecurrenceOptions
                            selectedOption={selectedOption}
                            repeatWeekly={repeatWeekly}
                            setRepeatWeekly={setRepeatWeekly}
                            setIsAnyChangeInField={setIsAnyChangeInField}
                            setSelectedOption={setSelectedOption}
                        />
                    </>
                )}
                <div className='hr_line' />
                <div className="row_wrk_blk">
                    <div className="col_six">
                        <div><label>{jobsiteLabel}</label></div>
                        <DropdownList
                            list={jobSiteList}
                            filterable={true}
                            onChange={(e) => {
                                setParams({
                                    ...params,
                                    SiteName: e.target.value.id
                                })
                                setIsAnyChangeInField(true);
                            }}
                            defaultValue={params.SiteName}
                        />
                    </div>
                    <div className="col_six">
                        <label>Job Name</label>
                        <DropdownList
                            list={jobNameList}
                            filterable={true}
                            onChange={(e) => {
                                setParams({
                                    ...params,
                                    JobName: e.target.value.id
                                })
                                setIsAnyChangeInField(true);
                            }}
                            defaultValue={params.JobName}
                        />
                    </div>
                </div>
                <div className="row_wrk_blk">
                    <div className="col_twelve msg_field">
                        <label htmlFor="message">Message</label>
                        <textarea name="Enter Message" id="message" cols="30" rows="10"
                            placeholder="Enter Message"
                            value={params.Description}
                            onChange={(e) => {
                                setParams({
                                    ...params,
                                    Description: e.target.value
                                })
                                setIsAnyChangeInField(true);
                            }}
                        ></textarea>
                    </div>
                </div>
            </div>
            <div className="modal_btn_sec">
                <button className="mdl_btn btn2"
                    onClick={closeModal}>Cancel</button>
                <button className="mdl_btn okBtn" onClick={submitEdit}>Save</button>
            </div>

            <SiteLoader isActive={showLoader} />
            <ConfirmPopup
                showDialog={showDialog}
                message={"The changes are still unsaved. Are you sure you want to leave?"}
                confirmNavigation={closeWorkOrderEditModal}
                cancelNavigation={() => {
                    setShowDialog(false)
                }}
            />

        </div>
    )
}