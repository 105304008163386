import React, { useEffect, useState } from 'react'
import UploadIcon from '../../../assets/images/icon-upload.svg'
import { useForm } from 'react-hook-form'
import { timeZones } from './employeeUtils'
import { apiProvider } from '../../../services/api/provider'
import sampleCSV from './employee-sample.csv'
import sampleXLSX from './employee-sample.xlsx'
import { getAccountLabel } from '../../../utils';
import { G2ReviewPopup as G2Review } from '../../common/G2ReviewPopup';

const IndividualMenu = ({ timeZone, groups, phoneValidationError, nomenclature, handlePhoneValidation, register, employeeLabel, setValue }) => (
    <div className="popupTabCont">
        <div className="fldWrap">
            <label className="withHelpTxt">Name</label>
            <input type="text" {...register("employeeName")} maxLength={60}/>
        </div>
        <div className={`fldWrap${phoneValidationError ? ' errFld' : ''}`}>
            <label className="withHelpTxt">{employeeLabel} Phone Number</label>
                    <input type="text"
                     maxLength={15} 
                     {...register("phoneNumber", {
                     onChange: (e) => {
                         const formattedPhoneNumber = e.target.value.replace(/\D/g, '');
                         setValue('phoneNumber', formattedPhoneNumber, { shouldDirty: false });
                     }
                 })} />
            {phoneValidationError && <div className="fldMsg">Please Enter a valid phone number</div>}
        </div>
        <div className="rowBlk">
            <div className="colBlk6 fldWrap">
                <label>Time Zone</label>
                <select {...register("timeZone")}>
                    <option selected value={timeZone}>{timeZone}</option>
                    {
                        timeZones.filter(t => t?.value?.toLowerCase() !== timeZone?.toLowerCase())?.map((t, ind) => (
                            <option key={ind} value={t?.value} >{t?.label}</option>
                        ))
                    }
                </select>
            </div>
            <div className="colBlk6 fldWrap">
                <label>{nomenclature?.device_group_title}</label>
                <select {...register("group")}>
                    <option selected value={"None"}>None</option>
                    {
                        groups.filter(g => g.value !== "None").map((g, ind) => (
                            <option key={ind} value={g.value} >{g.label}</option>
                        ))
                    }
                </select>
            </div>
        </div>
        <div className="customCheck topGap1">
            <div className="withHelpTxt">
                <input type="checkbox" id="customCheck1" {...register("terms")} />
                <label className="customCheckLabel" htmlFor="customCheck1">Accept service terms?</label>
                <a href="https://www.abaq.us/allgeo-terms.html" target={"_blank"}>View service terms</a>
            </div>
        </div>
        <div className="customCheck">
            <input type="checkbox" id="customCheck2" {...register("optIn")} />
            <label className="customCheckLabel" htmlFor="customCheck2">Opt-in &amp; send App download link</label>
        </div>
    </div >
)

const employeeLabel = getAccountLabel('employee');

const MultipleMenu = ({ handleFileUpload, handleFileSize, file, setFile, setIsFormComplete, error }) => (
    <div className="popupTabCont alignCenter">
        <div className="downloadBlk">Download {employeeLabel} details <a className='cursorPointer' href={sampleXLSX} download={`${employeeLabel}-sample`}>sample file here</a></div>
        {!file ? <div className="dragDrop"
            onDragOver={e => {
                e.preventDefault()
            }}
            onDrop={e => {
                e.preventDefault()
                handleFileUpload(e)
            }}
        >
            <p><img src={UploadIcon} alt="Upload" /></p>
            <div>Drag and drop or browse to choose a file</div>
            <p>CSV and XLSX file extensions are allowed</p>
            {error && <div className="errMsg">File type unsupported.</div>}
            <input type="file" onChange={handleFileUpload} accept=".csv" />
        </div>
            :
            <div className="fileDetail">
                <div className="fileName">{file.name}</div>
                <span>{handleFileSize()}</span>
                <em className="fileDelete" onClick={() => { setFile(null); setIsFormComplete(false) }} >Delete</em>
            </div>
        }
    </div>
)

const addNewPopup = ({ setShowG2Popup, closePopup, setToastMsg, setShowLoader, timeZone, nomenclature, setActionPopup, setMode,fetchEmployeeAppData,fromMonitorNew,showAddNewPopup=false }) => {

    const { register, handleSubmit, watch, reset, setValue, formState: { errors, touchedFields } } = useForm()
    const [activeMenu, setActiveMenu] = useState('single')
    const [isFormComplete, setIsFormComplete] = useState(false)
    const [groups, setGroups] = useState([])
    const [file, setFile] = useState(null)
    const [error, setError] = useState(false)
    const [phoneValidationError, setPhoneValidationError] = useState(false)

    const toggleMenu = menu => {
        if (menu === "multiple") {
            reset()
        } else {
            if (file) setFile(null)
        }
        setIsFormComplete(false)
        setActiveMenu(menu)
    }

    const onSubmit = async data => {
        try {
            setShowLoader(true)

            if (activeMenu === 'single') {

                if (data.phoneNumber.length < 1) {
                    setPhoneValidationError(true)
                    return
                }

                const payload = {
                    phoneNumber: data.phoneNumber,
                    employeeName: data.employeeName,
                    employeeDescription: "",
                    group: data.group,
                    timeZone: data.timeZone,
                    optIn: "Yes"
                }

                const response = await apiProvider.post("track/mobile/v1/allgeo/addEmployee", payload)
                if (response.status === 200) {
                    setToastMsg({
                        msg: response.message,
                        type: "msgSuccess"
                    })
                    closePopup();
                    fromMonitorNew&&fetchEmployeeAppData()
                    setTimeout(() => {
                        setShowG2Popup(true)
                    }, 5000)
                } else if (response.status === 300) {
                    setActionPopup({
                        show: true,
                        data: payload,
                        msg: response.message
                    })
                    closePopup()
                } else {
                    setToastMsg({
                        msg: response.message,
                        type: "msgError"
                    })
                }
            } else {

                let formData = new FormData()
                formData.append('file', file)

                const response = await apiProvider.formPost("/track/mobile/v1/allgeo/uploadEmployees", formData)

                if (response.status === 200) {
                    setToastMsg({
                        msg: response.message,
                        type: "msgSuccess"
                    })
                    closePopup()
                } else if (+response.status === 422) {
                    const { knownColumns, unknownColumn } = response;

                    setMode({
                        type: 'uploadError',
                        data: {
                            file,
                            validColumns: knownColumns,
                            misMatchedColumns: unknownColumn,
                            validationError: false,
                            uploadUrl: "/track/mobile/v1/allgeo/uploadEmployees"
                        },
                        hasPrev: false
                    })

                    closePopup()

                } else {
                    setToastMsg({
                        msg: response.message,
                        type: "msgError"
                    })
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    const fetchData = async () => {
        try {

            const response = await apiProvider.getAll('/track/mobile/v1/allgeo/getAllGroups')

            setGroups(response.groups.map(g => ({
                value: g.groupId,
                label: g.groupId
            })))

        } catch (error) {
            console.log(error);
        }
    }

    const handleFileUpload = e => {
        setError(false)
        const allowedFiletypes = ['.csv', '.xlsx', '.xls', 'text/csv', 'text/xlsx', 'application/csv', 'text/comma-separated-values', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']

        if (e.dataTransfer) {
            const file = e.dataTransfer.files[0];

            if (allowedFiletypes.includes(file.type)) {
                setIsFormComplete(true)
                setFile(file)
            } else {
                setError(true)
            }
        } else {
            const file = e.target.files[0] || e.target.files[1] || e.target.files[2];

            if (allowedFiletypes.includes(file.type)) {
                setIsFormComplete(true)
                setFile(file)
            } else {
                setError(true)
            }
        }
    }

    const handleFileSize = () => {

        if (file) {
            let unit = 'b';
            let size = file.size;

            if (size >= 1024 * 1024) {
                size = size / Math.pow(1024, 2);
                unit = 'mb';
            } else if (size >= 1024) {
                size = size / 1024;
                unit = 'kb';
            }
               
            return `${(Math.round(size * 100) / 100)} ${unit}`;
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        const subscribe = watch(data => {
            const isEmpty = Object.keys(data).some(d => !data[d])
            setIsFormComplete(!isEmpty)
        })

        return () => subscribe.unsubscribe()
    }, [watch])

    const handlePhoneValidation = e => {
        const { value } = e.target;

        if (isNaN(value)) {
            setValue("phoneNumber", value.slice(0, value.length - 1))
        } else if (value.length > 0) {
            setValue("phoneNumber", value)
            setPhoneValidationError(false)
        }
    }

    const employeeLabel = getAccountLabel('employee');
    const addNewEmpHeader = `Add New ${employeeLabel}`

    return (<>
     {fromMonitorNew&&( <div className={`overlayemployees ${showAddNewPopup?'active':''}`}></div>)}
        <div className="popupWrap smlPopup active">

            <div className="closePopup" onClick={closePopup}>Close</div>
            <div className="popupHeading">{addNewEmpHeader}</div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="popupContent">
                    <div className="popupTabMenu">
                        <ul>
                            <li className={`${activeMenu === 'single' ? 'active' : ''}`} onClick={() => toggleMenu("single")}>Individual</li>
                            <li className={`${activeMenu === 'multiple' ? 'active' : ''}`} onClick={() => toggleMenu("multiple")}>Multiple</li>
                        </ul>
                    </div>

                    {
                        activeMenu === 'single'
                            ?
                            <IndividualMenu
                                timeZone={timeZone}
                                groups={groups}
                                phoneValidationError={phoneValidationError}
                                handlePhoneValidation={handlePhoneValidation}
                                nomenclature={nomenclature}
                                register={register}
                                employeeLabel={employeeLabel}
                                setValue={setValue}
                            />
                            :
                            <MultipleMenu
                                file={file}
                                setFile={setFile}
                                handleFileSize={handleFileSize}
                                handleFileUpload={handleFileUpload}
                                error={error}
                                setIsFormComplete={setIsFormComplete}
                            />
                    }
                </div>
                <div className="popupBtnWrap flexJCenter">
                    <button className="btnStyle okBtn" disabled={!isFormComplete}>Add</button>
                </div>
            </form>
        </div>
        </>
    )
}

export default addNewPopup