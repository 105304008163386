import React, { useState, useEffect } from 'react'
import { apiProvider } from '../../../services/api/provider'
import EmployeeTable from './EmployeeTable'
import { getAccountLabel } from '../../../utils'

const ViewGroup = ({ groupData, setMode, accessLevel, setShowLoader }) => {

    const [data, setData] = useState([])
    const encodedGroupId = encodeURIComponent(groupData.groupID);

    const fetchEmployeeData = async () => {
        try {
            setShowLoader(true)
            const response = await apiProvider.getAll(`/track/mobile/v1/allgeo/getAssignedEmployees?groupId=${encodedGroupId}`);
            setData(response.assignedEmployees)
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    useEffect(() => {
        fetchEmployeeData()
    }, [])

    const closePopup = () => {
        setMode({
            type: '',
            data: null,
            hasPrev: false
        })
    }
    const groupLabel = getAccountLabel('group') || 'Group';
    const employeeLabel = getAccountLabel('employee') || "Employee"

    return (
        <div className="popupWrap viewPopupWrap active">
            <div className="popupHeading noClose">
                <span>View {groupLabel}</span>
                <div className="headingCtrl">
                    <a className={`editGrp${accessLevel < 2 ? ' disabled' : ''}`} onClick={() => setMode({
                        type: 'edit',
                        data: groupData,
                        hasPrev: true
                    })}>Edit {groupLabel}</a>
                    <a className={`deleteGrp${accessLevel < 3 ? ' disabled' : ''}`} onClick={() => setMode({
                        type: "delete",
                        data: groupData,
                        hasPrev: true
                    })}>Delete {groupLabel}</a>
                </div>
            </div>
            <div className="popupContent viewPopup">
                <div className="viewLeft">
                    <div className="fldWrap">
                        <label>{employeeLabel} {groupLabel} Name</label>
                        <div className="fldValue">{groupData.groupDesc !== "" ? groupData.groupDesc : "-"}</div>
                    </div>
                    <div className="fldWrap">
                        <label>{employeeLabel} {groupLabel} ID</label>
                        <div className="fldValue">{groupData.groupID !== "" ? groupData.groupID : "-"}</div>
                    </div>
                    <div className="fldWrap">
                        <label>{employeeLabel} Count</label>
                        <div className="fldValue">{data.length}</div>
                    </div>
                    <div className="fldWrap">
                        <label>Assigned Color</label>
                        <div className="chooseColor">
                            <div className="selectedColor" style={{ backgroundColor: groupData.colour }}>Color</div>
                        </div>
                    </div>
                </div>
                <div className="viewRight">
                    <label>Assigned {employeeLabel}</label>
                    <EmployeeTable data={data} />
                </div>
            </div>
            <div className="popupBtnWrap">
                <a className="btnStyle doneStep cursorPointer" onClick={closePopup}>Done</a>
            </div>
        </div>
    )
}

export default ViewGroup