import React, { Fragment, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AuthContext from '../../store/auth-context';

const AfterLogout = () => {
    const history = useHistory();
    const authCtx = useContext(AuthContext);
    useEffect(() => {
        console.log('allgeo debug point --> afterlogin component entry')
        if (authCtx.isLoggedIn) {
            authCtx.logout();
            localStorage.removeItem('isAdminUser')
            window.location.replace(`${process.env.REACT_APP_API_URL}/login`);
        } else {
            history.replace('/');
        }

        return () => console.log('allgeo debug point --> component unmount')
    }, []);
    return (
        <Fragment>
        </Fragment>
    );
}
export default AfterLogout;