import React, { useState, useRef } from "react";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import {ViewScheduleWrapper} from '../ViewSchedule/ViewScheduleWrapper'
import { apiProvider } from "../../../../services/api/provider";
import { convertDateString, extractTripIDs } from '../utils/helperFunctions'
import { scheduleColumnsName, SCHEDULE_TYPE, DEFAULT_SCHEDULE_TYPE, DEFAULT_MODE, DEFAULT_TOAST_MESSAGE, ERROR_MESSAGE } from '../utils/activeScheduleUtils'
import KendoGrid from '../../../common/kendo/KendoGrid'
import SiteLoader from "../../../common/SiteLoader";
import ToastMessage from "../../../common/ToastMessage";
import './ScheduleHistory.css'

export const ScheduleHistory = () => {
    const [completedSchedule, setCompletedSchedule] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [showLoader, setShowLoader] = useState(false);
    const [formattedStartDate, setFormattedStartDate] = useState(convertDateString(startDate));
    const [formattedEndDate, setFormattedEndDate] = useState(convertDateString(endDate));
    const [mode, setMode] = useState(DEFAULT_MODE);
    const [scheduleTypeValue, setScheduleTypeValue] = useState(DEFAULT_SCHEDULE_TYPE);
    const [toastMsg, setToastMsg] = useState(DEFAULT_TOAST_MESSAGE);
    const childRef = useRef();


    const fetchSchedule = async () => {
        try {
            if (scheduleTypeValue.value === "default") {
                setToastMsg(ERROR_MESSAGE)
            } else {
                setShowLoader(true);
                const response = await apiProvider.getAll(`track/react/v1/allgeo/activeSchedule/fetchRangeScheduleData?fromDate=${formattedStartDate}&toDate=${formattedEndDate}&fetch=${scheduleTypeValue.value}`);
                setCompletedSchedule([])
                if (response.status === 200) {
                    setCompletedSchedule(response.data);
                }
                setShowLoader(false);
            }
        } catch (error) {
            console.error(error);
            setShowLoader(false);
        }
    }

    const actionHandler = (type, data) => {
        setMode({
            title: type === 'view' ? "View Schedule" : "Delete Schedule",
            type: type,
            data: data,
            hasPrev: false,
        });
    };

    const deleteSchedules = async (data) => {
        const tripId = extractTripIDs(data);
        try {
            setShowLoader(true);
            const response = await apiProvider.remove(`track/react/v1/allgeo/activeSchedule/deleteSchedule?tripId=${tripId}`);
            setShowLoader(false);
            return response;
        } catch (error) {
            console.error(error);
            setShowLoader(false);
        }
    };

    return (
        <>
            <br />
            <div className="inner_btn_sec">
                <div className="tab_row_block">
                    <div className="col_blk_four">
                        <label>From</label>
                        <DatePicker
                            value={startDate}
                            onChange={(e) => {
                                if (e.target.value.getTime() <= endDate.getTime()) {
                                    setStartDate(e.target.value);
                                    setFormattedStartDate(convertDateString(e.target.value));
                                }
                            }}
                        />
                    </div>
                    <div className="col_blk_four">
                        <label>To</label>
                        <DatePicker
                            value={endDate}
                            onChange={(e) => {
                                if (e.target.value.getTime() >= startDate.getTime()) {
                                                                        setEndDate(e.target.value);
                                    setFormattedEndDate(convertDateString(e.target.value));
                                }
                            }}
                        />
                    </div>
                    <div className="col_blk_four dropdown_fld">
                        <label>Type</label>
                        <DropDownList
                            data={SCHEDULE_TYPE}
                            textField="label"
                            value={scheduleTypeValue}
                            onChange={(e) => {
                                setScheduleTypeValue(e.target.value);
                            }}
                        />
                    </div>
                </div>
                <div className="tab_row_block history_btn">
                    <button className="schdl_btn_style" onClick={fetchSchedule}>Get Schedule History</button>
                </div>
            </div>
            <div className="active_schedule_section">
                {completedSchedule?.length > 0 && (
                    <KendoGrid
                        gridData={completedSchedule}
                        setGridData={setCompletedSchedule}
                        columnData={scheduleColumnsName}
                        accessLevel={{ childMenu: 3 }}
                        ref={childRef}
                        getAPiUrl={`track/react/v1/allgeo/activeSchedule/fetchRangeScheduleData?fromDate=${formattedStartDate}&toDate=${formattedEndDate}&fetch=${scheduleTypeValue.value}`}
                        lockActionColumn={true}
                        module="completedSchedule"
                        actionColumnHandler={actionHandler}
                        deleteItem={deleteSchedules}
                        bulkDelete={deleteSchedules}
                        otherData={{ actionColumnBtns: ['view', 'start', 'stop', 'delete'] }}
                    />
                )}
                <SiteLoader isActive={showLoader} />
                {mode.type === 'view' && <ViewScheduleWrapper selectedSchedule={mode.data} mode={mode} setMode={setMode} />}
                {toastMsg.msg && (
                    <ToastMessage
                        cssClass={toastMsg.type}
                        message={toastMsg.msg}
                        onClose={() => setToastMsg({ msg: "", type: "" })}
                    />
                )}
                <div className={`overlay${mode.type === 'view' ? ' active' : ''}`} />
            </div>
        </>
    );
}