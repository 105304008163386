import React, { useEffect, useRef, useState } from 'react'
import { apiProvider } from '../../../services/api/provider'

const defaultTimer = 30;

const VerifyOTP = ({ data, setOtpPopup, setToastMsg, setShowLoader }) => {

    const [otp, setOtp] = useState(["", "", "", "", "", ""])
    const [timer, setTimer] = useState(defaultTimer)
    const [intervalID, setIntervalID] = useState(null)

    const inp1Ref = useRef(null)
    const inp2Ref = useRef(null)
    const inp3Ref = useRef(null)
    const inp4Ref = useRef(null)
    const inp5Ref = useRef(null)

    const closePopup = () => {
        setOtpPopup({
            show: false,
            data: null
        })
    }

    const startTimer = () => {
        const id = setInterval(() => {
            setTimer(time => time - 1)
        }, 1000);
        setIntervalID(id)
    }

    const sendOtp = async () => {
        try {
            const payload = {
                managerID: data.managerID,
                contactPhone: data.contactPhone
            }
            startTimer()
            const response = await apiProvider.post('/track/mobile/v1/allgeo/manager/2fa?command=verifyphone', payload)
            
            if (response.status !== 200) {
                setToastMsg({
                    msg: response.message,
                    type: "msgError"
                })
            }
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    const verifyOtp = async () => {
        try {
            setShowLoader(true)
            const payload = {
                managerID: data.managerID,
                contactPhone: data.contactPhone,
                otp: otp.join("")
            }
            
            const response = await apiProvider.post('/track/mobile/v1/allgeo/manager/2fa?command=verifyOTP', payload)
            
            if (response.status === 200) {
                setToastMsg({
                    msg: response.message,
                    type: "msgSuccess"
                })
            } else {
                setToastMsg({
                    msg: "The entered OTP is invalid. Please try again",
                    type: "msgError"
                })
            }
            closePopup()
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    const handleOtp = (e, ind) => {
        const { value } = e.target

        if (isNaN(value)) {
            const tempOtp = [...otp]
            tempOtp[ind] = ""
            setOtp(tempOtp)
        } else {
            const tempOtp = [...otp]
            tempOtp[ind] = value.length === 0 ? "" : +value;
            setOtp(tempOtp)
            if (value.length > 0) {
                switch (ind) {
                    case 0:
                        inp1Ref.current?.focus()
                        break;
                    case 1:
                        inp2Ref.current?.focus()
                        break;
                    case 2:
                        inp3Ref.current?.focus()
                        break;
                    case 3:
                        inp4Ref.current?.focus()
                        break;
                    case 4:
                        inp5Ref.current?.focus()
                        break;
                    default:
                        break;
                }
            }
        }

    }

    const resendOtp = async () => {
        try {
            const response = await apiProvider.post('/track/mobile/v1/allgeo/manager/2fa?command=resendOTP', {
                managerID: data.managerID
            })

            if (response.status === 200) {
                setTimer(defaultTimer)
                startTimer()
            } else {
                setToastMsg({
                    msg: response.message,
                    type: "msgError"
                })
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        sendOtp()
        return () => clearInterval(intervalID)
    }, [])

    useEffect(() => {
        if (timer <= 0) {
            clearInterval(intervalID)
            setIntervalID(null)
        }
    }, [timer])

    return (
        <>
            <div className="popupWrap smlPopup active">
                <div className="closePopup" onClick={closePopup}>Close</div>
                <div className="popupHeading">Verify number</div>
                <div className="popupContent alignCenter">
                    <div className="popupInnerHeading">We need to verify your number</div>
                    <p>OTP via SMS will be used from your message quota.</p>
                    <div className="popupInnerHighlight">
                        <div>Enter 6 digit OTP sent to <strong>{data.contactPhone}</strong>.</div>
                        <div className="otpFldWrap">
                            <input
                                type="text"
                                maxLength={1}
                                onChange={e => handleOtp(e, 0)}
                                value={otp[0]}
                                autoFocus={true}
                            />
                            <input
                                type="text"
                                maxLength={1}
                                onChange={e => handleOtp(e, 1)}
                                value={otp[1]}
                                ref={inp1Ref}
                            />
                            <input
                                type="text"
                                maxLength={1}
                                onChange={e => handleOtp(e, 2)}
                                value={otp[2]}
                                ref={inp2Ref}
                            />
                            <input
                                type="text"
                                maxLength={1}
                                onChange={e => handleOtp(e, 3)}
                                value={otp[3]}
                                ref={inp3Ref}
                            />
                            <input
                                type="text"
                                maxLength={1}
                                onChange={e => handleOtp(e, 4)}
                                value={otp[4]}
                                ref={inp4Ref}
                            />
                            <input
                                type="text"
                                maxLength={1}
                                onChange={e => handleOtp(e, 5)}
                                value={otp[5]}
                                ref={inp5Ref}
                            />
                        </div>
                        {
                            timer > 0
                                ?
                                <p>Resend OTP in {timer} seconds</p>
                                :
                                <a onClick={resendOtp}>Resend OTP</a>
                        }
                    </div>
                </div>
                <div className="popupBtnWrap flexJCenter">
                    <button
                        disabled={otp.join("").length < 6}
                        className="btnStyle okBtn"
                        onClick={verifyOtp}
                    >Verify</button>
                </div>
            </div>
        </>
    )
}

export default VerifyOTP