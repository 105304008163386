import React from 'react'

const EmpSelect = ({ searchValue, setSearchValue, selectAll, handleSelectAllChange, uniqueDeviceNames, filteredEmp, selectedEmployees, handleEmployeeSelection ,employeeLabel }) => {
    return (
        <>
            <div className="emp_container">
                <div className="emp_select_head">
                    <div className="sub_head">{employeeLabel}</div>
                    <input
                        type="search"
                        placeholder="Search..."
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                    />
                </div>
                <div className="emp_list_sec">
                    <div className="custom_checkbox select_all">
                        <input
                            type="checkbox"
                            id="selectAll"
                            checked={selectAll}
                            onChange={handleSelectAllChange}
                        />
                        <label className="customize_label" htmlFor="selectAll">
                            All
                        </label>
                    </div>
                    <ul>
                        {uniqueDeviceNames.map((deviceName) => {
                            const employee = filteredEmp.find(
                                (e) => e.DeviceName === deviceName
                            );
                            return (
                                <li className="custom_checkbox" key={deviceName}>
                                    <input
                                        type="checkbox"
                                        id={deviceName}
                                        checked={selectedEmployees.some(
                                            (employee) => employee.DeviceName === deviceName
                                        )}
                                        onChange={() => handleEmployeeSelection(employee)}
                                    />
                                    <label
                                        className="customize_label"
                                        htmlFor={deviceName}
                                    >
                                        <span
                                            className="emp_color"
                                            style={{
                                                backgroundColor: `${employee.DeviceColor}`,
                                            }}
                                        ></span>
                                        {deviceName}
                                    </label>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </>
    )
}

export default EmpSelect