import React from 'react';
import userLogo from "../../assets/images/allGeo-logo.svg";

const LeftContent = (props) =>{
    let homePageUrl = '/';
    if(process.env.REACT_APP_API_URL === 'https://app.allgeo.com') {
        homePageUrl = 'https://www.allgeo.com';
    } 
    return (
        <div className="leftContent">
            <h1 className="siteLogo">
                <a href={homePageUrl}>
                    <img src={userLogo} alt="allGeo" />
                </a>
            </h1>
            {props.children}
        </div>
    )
}
export default LeftContent;