import React, { useState, useEffect } from 'react';
import { apiProvider } from '../../../services/api/provider';
import { getWeekDayString } from './locateScheduleUtils';

const moment = window.moment;

const ViewLocateScheduler = (props) => {

    const [schedulerDetails, setSchedulerDetails] = useState({});

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            props.setShowLoader(true)
            const response = await getScheduleData()
            if (response.status === 200 && response.message.toLowerCase() === 'success') {
                if (response.data.length > 0) {
                    setSchedulerDetails(response.data[0])
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            props.setShowLoader(false)
        }
    }

    const getScheduleData = async () => {
        try {
            return await apiProvider.getSingle(`/track/mobile/v1/allgeo/schedule?scheduleId=${props.scheduleId}&type=Locate`)
        } catch (error) {
            console.log(error);
        }
    }

    const getLocateForData = (params) => {
        if (params) {
            if ((schedulerDetails?.scheduleFor).toLowerCase() === 'all') {
                return 'All'
            } else {
                return (
                    params === "Group"
                        ? props.locateForData.groups.filter((ele) => ele.value === schedulerDetails?.scheduleFor)[0]?.label
                        : props.locateForData.employees.filter((ele) => ele.value === schedulerDetails?.scheduleFor)[0]?.label
                )
            }
        }
    }

    return (
        <div className="popupWrap smlMedPopup active">
            <div className="popupHeading noClose">
                <span>View Tracking Scheduler</span>
                <div className="headingCtrl">
                    <a href={void (0)} className={`editGrp${props.accessLevel < 2 ? ' disabled' : ''}`}
                        onClick={() => {
                            props.setShowLoader(true)
                            props.setMode({
                                title: "Monitor",
                                type: 'edit',
                                data: props.mode.data,
                                hasPrev: true
                            })
                        }}>Edit Scheduler</a>
                    <a href={void (0)} className={`deleteGrp${props.accessLevel < 3 ? ' disabled' : ''}`}
                        onClick={() => {
                            props.setMode({
                                title: "Monitor",
                                type: "delete",
                                data: props.mode.data,
                                hasPrev: true
                            })
                        }}>Delete Scheduler</a>
                </div>
            </div>
            <div className="popupContent">
                <div className="popupTabCont" style={{fontSize : "0.75rem"}}>
                    <div className="towColView">
                        <div className="fldWrap">
                            <label className="scheduleViewLabelFontSize">Scheduler ID</label>
                            <p>{schedulerDetails?.scheduleId}</p>
                        </div>
                        <div className="fldWrap">
                            <label className="scheduleViewLabelFontSize">Status</label>
                            <p className="txtActive">{schedulerDetails?.active ? "Active" : "Inactive"}</p>
                        </div>
                        <div className="fldWrap">
                            <label className="scheduleViewLabelFontSize">This track is for</label>
                            <p> {getLocateForData(schedulerDetails?.assignType)}
                            </p>
                        </div>
                    </div>
                    <hr />
                    <div className="sectionSubHeading">Track Settings</div>
                    <div className="towColView">
                        <div className="fldWrap">
                            <label className="scheduleViewLabelFontSize">Start Day</label>
                            <p> {schedulerDetails?.scheduleType === "recurring" ? moment(schedulerDetails.scheduleStartOn).format(`${props.dateFormat}`) : moment(schedulerDetails.startDate).format(`${props.dateFormat}`)} </p>
                        </div>
                        <div className="fldWrap">
                            <label className="scheduleViewLabelFontSize">Schedule Type</label>
                            <p>{schedulerDetails?.scheduleType === "recurring" ? "Recurring" : "Non-Recurring"}</p>
                        </div>
                        {
                            schedulerDetails?.scheduleType === "recurring" &&
                            <div className="fldWrap">
                                <label className="scheduleViewLabelFontSize">Repeat</label>
                                <p> {schedulerDetails?.repeatType === "daily" ? "Daily" : "Weekly"} </p>
                            </div>
                        }
                        {/*
                            schedulerDetails?.scheduleType === "recurring" &&
                            <div className="fldWrap">
                                <label>Repeat Every</label>
                                <p> {`${schedulerDetails?.repeatValue} ${schedulerDetails?.repeatType === "daily" ? "day" : "week"}`} </p>
                            </div>
                        */}
                        {
                            schedulerDetails?.scheduleType === "recurring" && schedulerDetails?.repeatType !== "daily" &&
                            <div className="fldWrap">
                                <label className="scheduleViewLabelFontSize">Selected Days</label>
                                <p>{getWeekDayString(schedulerDetails?.trackingDays)}</p>
                            </div>
                        }
                        <div className="fldWrap">
                            <label className="scheduleViewLabelFontSize">Ends</label>
                            {
                                schedulerDetails?.scheduleEndOn !== 'never'
                                    ? <p>On {schedulerDetails?.scheduleType === "recurring" ? moment(schedulerDetails.scheduleEndOn).format(`${props.dateFormat}`) : moment(schedulerDetails.endDate).format(`${props.dateFormat}`)}</p>
                                    : <p>Never</p>
                            }
                        </div>
                        <div className="fldWrap">
                            <label className="scheduleViewLabelFontSize">Track from</label>
                            <p>{moment(`2022-01-01 ${schedulerDetails.startTime}`).format(`${props.timeFormat}`)} to {moment(`2022-01-01 ${schedulerDetails.endTime}`).format(`${props.timeFormat}`)}</p>
                        </div>
                        <div className="fldWrap">
                            <label className="scheduleViewLabelFontSize">Timezone</label>
                            <p>{schedulerDetails?.timezone}</p>
                        </div>
                        <div className="fldWrap">
                            <label className="scheduleViewLabelFontSize">Track</label>
                            <p>{schedulerDetails?.runType === "continues" ? "Continuously" : "Manual"}</p>
                        </div>
                        {
                            schedulerDetails?.runType === "continues" &&
                            <div className="fldWrap">
                                <label className="scheduleViewLabelFontSize">Track frequency every</label>
                                <p>{schedulerDetails?.trackFrequency} minutes</p>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="popupBtnWrap flexJCenter">
                <a href={void (0)} className="btnStyle doneStep" onClick={() => {
                    props.setMode({
                        title: "Monitor",
                        type: "main",
                        data: null,
                        hasPrev: false
                    })
                }}>Done</a>
            </div>
        </div>
    )
}

export default ViewLocateScheduler