const moment = window.moment;

export const PINNED_BOX = {
  ALERTS_AND_NOTIFICATIONS: "Alerts and Notifications",
  MILES_DATA: "Miles Data",
  MAP_AND_LOCATION: "Map and Location",
  EMPLOYEE_AND_APP_DATA: "Employee and App Data",
};

export const TAB_INDEX = {
  MILEAGE_AND_TRACKING: '/monitor/new-monitor-employees',
  TIME_CLOCK: 'time-clock',
  SCHEDULE: 'schedule',
};

export const countries = [
  "Albania",
  "Andorra",
  "Armenia",
  "Austria",
  "Azerbaijan",
  "Belarus",
  "Belgium",
  "Bosnia & Herzegovina",
  "Bulgaria",
  "Croatia",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Estonia",
  "Finland",
  "France",
  "Georgia",
  "Germany",
  "Greece",
  "Hungary",
  "Iceland",
  "Ireland",
  "Italy",
  "Kosovo",
  "Latvia",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macedonia",
  "Malta",
  "Moldova",
  "Monaco",
  "Montenegro",
  "Netherlands",
  "Norway",
  "Poland",
  "Portugal",
  "Romania",
  "Russia",
  "San Marino",
  "Serbia",
  "Slovakia",
  "Slovenia",
  "Spain",
  "Sweden",
  "Switzerland",
  "Turkey",
  "Ukraine",
  "United Kingdom",
  "Vatican City",
];
