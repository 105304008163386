import React, { useEffect, useState } from "react";
import "./expandMilesDataModal.css";
import { MilesDataViewTable } from "./MilesDataViewTable";

export const ExpandMilesDataModal = ({
  closePopup,
  milesData,
  handlePinned,
  handleUnPinned,
  setMilesPaginateMetaData,
  milesBackupData,
  setMilesBackupData,
  showExpanMore,
  milesPaginateMetaData,
  currentTimeAndDateMiles
}) => {


  useEffect(()=>{
    return()=>{
      setMilesPaginateMetaData({...milesPaginateMetaData,page: 1, perPage: 50,paginationFlag:false })
    }
    },[])

  return (
    <div>
      <div className="overlaymiles"></div>
      <div className="milesmodalparentBox">
        <MilesDataViewTable
          milesBackupData={milesBackupData}
          setMilesBackupData={setMilesBackupData}
          closePopup={closePopup}
          setMilesPaginateMetaData={setMilesPaginateMetaData}
          milesData={milesData}
          handlePinned={handlePinned}
          handleUnPinned={handleUnPinned}
          showExpanMore={showExpanMore}
          milesPaginateMetaData={milesPaginateMetaData}
          currentTimeAndDateMiles={currentTimeAndDateMiles}
        />
        <div className="closebtn">
          <button onClick={closePopup} className="milesmodalbuttondiv">
            Close
          </button>
        </div>
      </div>
    </div>
  );
};
