import React, { useEffect, useState, useCallback, useRef } from "react";
import SiteLoader from "../../../common/SiteLoader";
import { apiProvider } from "../../../../services/api/provider";

import { Scheduler, DayView, WeekView, WorkWeekView, MonthView, AgendaView } from "@progress/kendo-react-scheduler";
import { guid } from "@progress/kendo-react-common";
import { getBaseData } from "./getBaseData/getBaseData"
import { getEmp } from "./getEmpData/getEmpData";
import { schedulerDataTransformer } from "./schedulerDataTransformer/schedulerDataTransformer";
import { deleteItems, masterDelete, updateWO } from "./utils/utils";
import EmpSelect from "./EmpSelectionComponent/EmpSelect";
import { getAccountLabel } from "../../../../utils";
import ToastMessage from "../../../common/ToastMessage";

export const WorkOrderCalander = (props) => {
  const [emp, setEmp] = useState([]);
  const [view, setView] = React.useState("day");
  const [showLoader, setShowLoader] = useState(false);
  const [baseData, setbaseData] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [selectAll, setSelectAll] = useState(true);
  const [orientation, setOrientation] = useState("vertical");
  const [toastMsg, setToastMsg] = useState({
    msg: "",
    type: "msgSuccess"
  });

  const [deletePayload, setDeletePayload] = useState();
  const baseDataRef = useRef([]);

  useEffect(() => {
    baseDataRef.current = baseData;
  }, [baseData]);


  const handleViewChange = React.useCallback(
    (event) => {
      setView(event.value);
    },
    [setView]
  );



  const handleEmployeeSelection = (deviceName) => {
    // Check if the deviceName is already selected
    if (selectedEmployees.includes(deviceName)) {
      // If selected, remove it
      setSelectedEmployees((prevSelected) =>
        prevSelected.filter((selected) => selected !== deviceName)
      );
    } else {
      // If not selected, add it
      setSelectedEmployees((prevSelected) => [...prevSelected, deviceName]);
    }
  };

  const handleSelectAllChange = () => {
    // If currently all employees are selected, unselect all
    if (selectAll) {
      setSelectedEmployees([]);
    } else {
      // If not all employees are selected, select all
      setSelectedEmployees(filteredEmp);
    }

    // Toggle the selectAll state
    setSelectAll((prevSelectAll) => !prevSelectAll);
  };

  const filteredEmp = emp.filter((e) =>
    e.DeviceName.toLowerCase().includes(searchValue.toLowerCase())
  );

  const uniqueDeviceNames = [...new Set(filteredEmp.map((e) => e.DeviceName))];
  const today = new Date();
  const todayUTC = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()));
  const transformedData = schedulerDataTransformer({ baseData })

  let filteredData = transformedData.filter(item => {
    const matchingEmployee = selectedEmployees.find(employee => employee.DeviceName === item.deviceName);
    return matchingEmployee !== undefined; // Returns true if there is a match, false otherwise
  });

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}/${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
    return formattedDate;
  };

  const handleDataChange =
    useCallback(
      async ({ created, updated, deleted }) => {
        console.log(created, updated, deleted)
        const updateFiltered = updated.map((e) => filteredData.find((data) => data.id == e.id))

        const updatedString = JSON.stringify(updated, (key, value) => {
          if (key === 'recurrenceExceptions' || key === "OccurrenceId") {
            return undefined; // Exclude recurrenceExceptions from the comparison
          }
          return value;
        });

        const updateFilteredString = JSON.stringify(updateFiltered);

        const capitalizeFirstLetter = (str) => {
          return str.charAt(0).toUpperCase() + str.slice(1);
        };

        const formatItem = (item) => {
          const formattedItem = {
            ...item,
            start: formatDate(item.start),
            end: formatDate(item.end),
          };

          // Capitalize the first letter of each key
          for (const key in formattedItem) {
            if (Object.prototype.hasOwnProperty.call(formattedItem, key)) {
              const capitalizedKey = capitalizeFirstLetter(key);
              formattedItem[capitalizedKey] = formattedItem[key];
              if (capitalizedKey !== key) {
                delete formattedItem[key];
              }

              // Capitalize the first letter of string values
              const value = formattedItem[capitalizedKey];
              if (typeof value === 'string') {
                formattedItem[capitalizedKey] =
                  value.charAt(0).toUpperCase() + value.slice(1);
              }
            }
          }
          return formattedItem;
        };

        const updatedItems = updated.map(formatItem);
        const deletedItems = deleted.map(formatItem);

        setbaseData((oldData) =>
          oldData
            .filter((item) => deletedItems.find((current) => current.taskID === item.taskID) === undefined)
            .map((item) => updatedItems.find((current) => current.taskID === item.taskID) || item)
            .concat(
              created.map((item) =>
                Object.assign({}, formatItem(item), {
                  taskId: guid(),
                })
              )
            )
        );

        if (deletedItems.length > 0) {
          deleteItems({ deletedItems, setbaseData, setShowLoader, getBaseData })
        }
        if (updatedItems.length > 0) {
          if (updatedString === updateFilteredString && created.length === 0) {
            masterDelete({ updatedItems, setbaseData, setShowLoader, getBaseData })
          }
          if (updatedString === updateFilteredString && created.length > 0 && updated.length > 0) {
            updateWO({ updatedItems, setbaseData, setShowLoader, getBaseData })
          }

          if (updatedString != updateFilteredString && updated.length > 0 && updateFiltered[0] === undefined) {
            try {
              // return
              const response = await apiProvider.post(
                "/track/react/v1/allgeo/upsertCalendarWorkorder?action=delete",
                deletePayload
              );
              if (response.status === 200) {
                await getBaseData({ setbaseData, setShowLoader });
              }
            } catch (error) {
              console.error("Error updating calendar work order:", error);
            }
          }

          if (updatedString != updateFilteredString && updated.length > 0) {
            console.log("coming in update")
            updateWO({ updatedItems, setbaseData, setShowLoader, getBaseData })
          }
        }
      },
      [setbaseData, filteredData]
    );

  useEffect(() => {
    // When the 'emp' state is updated, set all employees initially
    if (emp.length > 0) {
      setSelectedEmployees([...emp]);
    }
  }, [emp]);

  const employeeLabel = getAccountLabel('employee');
  useEffect(() => {
    getEmp({ setEmp, setShowLoader });
    getBaseData({ setbaseData, setShowLoader });

    // Add event listener after the scheduler is rendered
    document.addEventListener('click', (handleClickEvent));

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickEvent);
    };
  }, []);
  const handleClickEvent = (event) => {
    const schedulerEvent = event.target.closest('.k-event');
    if (schedulerEvent) {
      const labelElement = schedulerEvent.querySelector('.k-event-template');
      if (labelElement) {
        const labelValue = labelElement.textContent.trim();
        const lastText = labelValue.split(':').pop().trim();
        setDeletePayload(baseDataRef.current.filter((e) => e.Title === lastText));
      }
    }
  };

  return (
    <div className="schedule_work_section">
      <div className="scheduler_container">
        {/* for only view */}
        {props.accessLevel.childMenu === 1 &&
          <Scheduler
            data={filteredData}
            onDataChange={handleDataChange}
            defaultDate={todayUTC}
            view={view}
            onViewChange={handleViewChange}
            group={{
              resources: ["Persons"],
              orientation,
            }}
            resources={[
              {
                name: "Persons",
                data: selectedEmployees.map((employee, index) => ({
                  text: employee.DeviceName,
                  value: employee.DeviceID,
                  color: employee.DeviceColor, // Assuming you have a DeviceColor property
                })),
                field: "personId",
                valueField: "value",
                textField: "text",
                colorField: "color",
              },
            ]}
          >
            <DayView
              startTime={"12:00"}
              endTime={"12:00"}
              workDayStart={"08:00"}
              workDayEnd={"18:00"}
            />
            <WeekView />
            <WorkWeekView />
            <MonthView />
            <AgendaView />
          </Scheduler>
        }
        {/* for view and edit */}
        {props.accessLevel.childMenu === 2 &&
          <Scheduler
            data={filteredData}
            onDataChange={handleDataChange}
            defaultDate={todayUTC}
            view={view}
            onViewChange={handleViewChange}
            editable={{
              add: true,
              remove: false,
              drag: false,
              resize: false,
              edit: true,
            }}
            group={{
              resources: ["Persons"],
              orientation,
            }}
            resources={[
              {
                name: "Persons",
                data: selectedEmployees.map((employee, index) => ({
                  text: employee.DeviceName,
                  value: employee.DeviceID,
                  color: employee.DeviceColor, // Assuming you have a DeviceColor property
                })),
                field: "personId",
                valueField: "value",
                textField: "text",
                colorField: "color",
              },
            ]}
          >
            <DayView
              startTime={"12:00"}
              endTime={"12:00"}
              workDayStart={"08:00"}
              workDayEnd={"18:00"}
            />
            <WeekView />
            <WorkWeekView />
            <MonthView />
            <AgendaView />
          </Scheduler>
        }
        {/* for view , edit and delete */}
        {props.accessLevel.childMenu === 3 &&
          <Scheduler
            data={filteredData}
            onDataChange={handleDataChange}
            defaultDate={todayUTC}
            view={view}
            onViewChange={handleViewChange}
            editable={{
              add: true,
              remove: true,
              drag: false,
              resize: false,
              edit: true,
            }}
            group={{
              resources: ["Persons"],
              orientation,
            }}
            resources={[
              {
                name: "Persons",
                data: selectedEmployees.map((employee, index) => ({
                  text: employee.DeviceName,
                  value: employee.DeviceID,
                  color: employee.DeviceColor, // Assuming you have a DeviceColor property
                })),
                field: "personId",
                valueField: "value",
                textField: "text",
                colorField: "color",
              },
            ]}
          >
            <DayView
              startTime={"12:00"}
              endTime={"12:00"}
              workDayStart={"08:00"}
              workDayEnd={"18:00"}
            />
            <WeekView />
            <WorkWeekView />
            <MonthView />
            <AgendaView />
          </Scheduler>
        }
      </div>
      {/* Employee Selector Component */}
      <EmpSelect searchValue={searchValue} setSearchValue={setSearchValue} selectAll={selectAll} handleSelectAllChange={handleSelectAllChange} uniqueDeviceNames={uniqueDeviceNames} filteredEmp={filteredEmp} selectedEmployees={selectedEmployees} handleEmployeeSelection={handleEmployeeSelection} employeeLabel={employeeLabel} />
      <SiteLoader isActive={showLoader} />
      {toastMsg.msg && <ToastMessage
        cssClass={toastMsg.type}
        message={toastMsg.msg}
        onClose={() => setToastMsg({ msg: "", type: "" })}
      />}
    </div>
  );
};