import React, { useState, useEffect } from "react"
import { apiProvider } from "../../../services/api/provider";
import ManagerNotesConfirmPopup from "./ManagerNotesConfirmPopup";
import { customDateFormat } from "./editReportUtils";

export const ManagerNotesPopup = ({ data, createData = [], closePopup, setToastMsg, setShowLoader, reportType = '', api = '', successMsg = '', report = 'timeclock', dropdownData = {} }) => {
    const [managerNotes, setManagerNotes] = useState('');
    const [confirmed, setConfirmed] = useState(true);

    useEffect(() => {
        const isAnyManagerNoteEmpty = data.some(record => !record.managerNotes)

        if (!isAnyManagerNoteEmpty) {
            setConfirmed(false);
        }
    }, [data]);

    const createTimeClockData = async dataArr => {
        const payload = [...dataArr].map(fields => ({
            deviceID: fields.deviceID,
            deviceName: fields.deviceName,
            stageName: fields.stageName,
            tempcol: customDateFormat(fields.tcSentDate, 'yyyy/MM/dd', dropdownData.dateFormat) + " " + fields.tcSentTime,
            customerName: fields.customerName,
            jobName: fields.jobName,
            distanceTravelled: fields.distanceTravelled,
            managerNotes: fields.managerNotes
        }))

        try {
            await apiProvider.post('/track/api/v1/createTimeClock', payload)
        } catch (error) {
            console.log(error);
        }
    }

    const createAdvanceTimeClockData = async data => {
        const payload = [...data].map(fields => ({
            deviceID: fields.deviceID,
            jobName: fields.jobName,
            customerName: fields.customerName,
            distanceTravelled: fields.distanceTravelled,
            managerNotes: fields.managerNotes,
            startStageName: fields.startStageName,
            endStageName: fields.endStageName,
            startTempCol: customDateFormat(fields.startTCSentDate, "yyyy/MM/dd", dropdownData.dateFormat) + " " + fields.startTCSentTime,
            endTempCol: customDateFormat(fields.endTCSentDate, "yyyy/MM/dd", dropdownData.dateFormat) + " " + fields.endTCSentTime,
        }))
        try {
            await apiProvider.post('track/mobile/v1/allgeo/createAdvanceTimeclock', payload)
        } catch (error) {
            console.log(error);
        }
    }

    const updateReport = async () => {


        try {
            setShowLoader(true)

            if (createData.length > 0) {
                if (reportType === 'advanceTimeClock') {
                    await createAdvanceTimeClockData(createData)
                } else {
                    await createTimeClockData(createData)
                }
            }

            const payload = data.map(d => ({
                ...d,
                managerNotes: confirmed ? managerNotes : d.managerNotes
            }))

            const response = await apiProvider.post(api, payload)

            if (+response.StatusCode === 200) {
                localStorage.removeItem("selectedTaskName")
                localStorage.removeItem("selectedTaskID")
                localStorage.removeItem("selectedTaskdetailed")
                setToastMsg({ msg: successMsg, type: "msgSuccess" })
                closePopup()
            } else {
                setToastMsg({ msg: response.message, type: "msgError" })
            }
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    const onCancel = () => {
        closePopup();
    };
    const onCancelupdate = () => {
        updateReport()
    };



    return (
        <>
            {confirmed ? (<div className="popupWrap smlPopup active">
                <div className="popupHeading">Manager Notes</div>
                <div className="popupContent">
                    <p>Please add your notes to confirm the changes to {report}:</p>
                    <input type="text" placeholder="Add manager notes" autoFocus value={managerNotes} onChange={e => setManagerNotes(e.target.value)} />
                </div>
                <div className="popupBtnWrap flexJCenter">
                    <button className="btnStyle" disabled={managerNotes.trim().length === 0} onClick={updateReport}>Save</button>
                    <button className="btnStyle btn2" onClick={closePopup} >Cancel</button>
                </div>
            </div>) : typeof (confirmed) === "boolean" ? <ManagerNotesConfirmPopup onConfirm={() => setConfirmed(true)} onCancel={onCancel} onCancelupdate={onCancelupdate} /> : null}

            <div className="overlay active" />
        </>
    )
}