import React, { useState, useEffect } from "react";
import { apiProvider } from "../../../../services/api/provider";
import { getAccountLabel } from "../../../../utils";

const AssignEmployee = ({
  data,
  setAssignEmployeeModal,
  setToastMsg,
  setShowLoader,
}) => {
  const [employeeList, setEmployeeList] = useState([]);
  const [backUpData, setBackupData] = useState([]);
  const [search, setSearch] = useState("");
  const [allEmployeeSelection, setAllEmployeeSelection] = useState(false);

  const closeModal = () => {
    setAssignEmployeeModal({
      show: false,
      data: null,
    });
  };

  const handleSearch = (evt) => {
    const searchText = evt.target.value;

    setSearch(searchText);
    setEmployeeList(
      backUpData.filter((d) => {
        return d.deviceName
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase());
      })
    );
  };

  const getEmployeeList = async () => {
    try {
      setShowLoader(true)
      const response = await apiProvider.getAll(
        `/track/mobile/v1/allgeo/getEmployees?extCustIds=${data
          .map((d) => d.txnId)
          .toString()}`
      );

      const employeeList = response.data.filter(
        (employeeList) => employeeList.deviceID !== "all"
      );

      setEmployeeList(employeeList);
      setBackupData(employeeList);
    } catch (error) {
      console.log(error);
    } finally {
      setShowLoader(false);
    }
  };

  const handleSelection = (deviceID, isAllEmployeeSelected = false) => {
    let selectedItemsCount = 0;

    setEmployeeList(
      employeeList.map((t) => {
        if (t.deviceID === deviceID) {
          return {
            ...t,
            selected: t.selected === "yes" ? "no" : "yes",
          };
        }

        if (t.selected === "yes") selectedItemsCount++;

        return {
          ...t,
          selected:
            deviceID == "all"
              ? isAllEmployeeSelected
                ? "yes"
                : "no"
              : t.selected,
        };
      })
    );

    setBackupData(
      backUpData.map((t) => {
        if (t.deviceID === deviceID) {
          return {
            ...t,
            selected: t.selected === "yes" ? "no" : "yes",
          };
        }

        return {
          ...t,
          selected:
            deviceID == "all"
              ? isAllEmployeeSelected
                ? "yes"
                : "no"
              : t.selected,
        };
      })
    );

    if (deviceID !== "all" && allEmployeeSelection) {
      setAllEmployeeSelection(false);
    } else if (selectedItemsCount === employeeList.length - 1) {
      setAllEmployeeSelection(true);
    }
  };

  const assignEmployee = async () => {
    try {
      setShowLoader(true);
      const payload = {};
      data.forEach((item) => {
        payload[item.txnId] = employeeList
          .filter((employee) => employee.selected === "yes")
          .map((employee) => employee.deviceID);
      });

      const response = await apiProvider.put(
        "/track/mobile/v1/allgeo/assignEmployees",
        payload
      );
      setToastMsg({
        type: "msgSuccess",
        msg: "Employee assignment updated",
      });
      if (response.status === 200) {
        closeModal();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getNumberOfSelectedEmployee = () => {
    return employeeList.filter((t) => t.selected === "yes").length;
  };

  const handleAllEmployeeSelection = () => {
    handleSelection("all", !allEmployeeSelection);
    setAllEmployeeSelection(!allEmployeeSelection);
  };

  useEffect(() => {
    getEmployeeList();
  }, []);

  const employeeLabel = getAccountLabel('employee');
  const jobsiteLabel = getAccountLabel('jobsite');


  return (
    <>
      <div className="popupWrap smlMedPopup active">
        <div className="closePopup" onClick={closeModal}>
          Close
        </div>
        <div className="popupHeading">
          <span>Assign {employeeLabel}</span>
        </div>
        <div className="popupContent viewPopup">
          <div className="viewLeft">
            <label>Selected {jobsiteLabel}</label>
            <ul className="selectedList">
              {data.map((d, ind) => (
                <li key={ind}>{d.name}</li>
              ))}
            </ul>
          </div>
          <div className="viewRight">
            <div className="fldWrap">
              <label>Select {employeeLabel}</label>
              <div className="customControls">
                <div className="customCheck">
                  <input
                    type="checkbox"
                    id="AllEmployees"
                    name="SelectEmployee"
                    checked={allEmployeeSelection}
                    onChange={() => handleAllEmployeeSelection()}
                  />
                  <label htmlFor="AllEmployees">All {employeeLabel}</label>
                </div>
              </div>
            </div>
            <label>{employeeLabel} Assignment</label>
            <div className="empSelectBlk">
              <div className="empSelectCtrl">
                <input
                  type="search"
                  placeholder={`Search by ${employeeLabel} Name`}
                  value={search}
                  onChange={handleSearch}
                />
              </div>
              <div className="empListTbl">
                <table cellPadding="0" cellSpacing="0">
                  <tbody>
                    <tr>
                      <th>{employeeLabel} Name</th>
                      <th>{employeeLabel} ID</th>
                      <td></td>
                    </tr>
                    {employeeList.map((t, ind) => (
                      <tr key={ind}>
                        <td>{t.deviceName}</td>
                        <td>{t.deviceID}</td>
                        <td className="empActionCol">
                          <div className="customCheck noTxt">
                            <input
                              type="checkbox"
                              id={`${t.deviceID || t.txnID}`}
                              checked={t.selected === "yes"}
                              onChange={() => handleSelection(t.deviceID)}
                            />
                            <label
                              className="customCheckLabel"
                              htmlFor={`${t.deviceID || t.txnID}`}
                            >
                              {t.deviceName}
                            </label>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="empSelectFoot">
                <span>
                  There are a total of{" "}
                  <strong>{getNumberOfSelectedEmployee()} {employeeLabel}</strong>{" "}
                  selected
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="popupBtnWrap">
          <a
            className={`btnStyle doneStep ${
              getNumberOfSelectedEmployee()
            }`}
            onClick={assignEmployee}
          >
            Assign
          </a>
        </div>
      </div>
    </>
  );
};

export default AssignEmployee;
