import React, { useContext, useState, useEffect } from 'react';
import { Route, useLocation, useHistory } from 'react-router-dom';
import { componentMap } from './utils';
import useRoute from '../hooks/useRoute';
import SiteLoader from '../components/common/SiteLoader';
import PasswordValidationPopup from '../components/common/PopUp/PasswordValidationPopup';
import AuthContext from "../store/auth-context";
import { apiProvider } from '../services/api/provider';
import { MIN_TIMESTAMP_FOR_NEW_ACCOUNT } from "../components/common/utils";

const customParentMenuNames = {
    'schedule_template': 'monitor'
}

const customRouteNames = {
    'job_sites': 'jobsites',
    'automation_rules': 'automation-rules',
    'schedule_template': 'locate-schedule',
    'connector': 'connectors',
    'active_schedules' : 'active-schedule',
    'work_orders' : 'work-order',
}

const customRouteData = [
    {
        name: 'home',
        path: '/home',
        Component: componentMap['home'],
    },
    
]

const parentMenuWithNoSubmenu = ['reports']
const parentMonitorMenuWithNoSubmenu = ['monitor']

const Routes = () => {

    const authCtx = useContext(AuthContext);

    const { menuItems } = useRoute()
    const { pathname, search } = useLocation()
    const { replace } = useHistory()
    const [routes, setRoutes] = useState([])
    const [passwordPopUp, setPasswordPopUp] = useState(false)

    useEffect(() => {
        if (!authCtx.isLoggedIn) {
            localStorage.setItem('isAdminUser', false)
            window.Intercom("boot", {
                app_id: process.env.REACT_APP_INTERCOM_APP_ID
            });
        } else {

            apiProvider.getAll('track/react/account/getIntercomData').then((response) => {
                localStorage.setItem('isAdminUser', response.admin_user)
                localStorage.setItem('isNewUser', response.accountCreationTime > MIN_TIMESTAMP_FOR_NEW_ACCOUNT)
                //logic for pop-up for password validation
                let actSecurityType = localStorage.getItem("actSecurityType")
                let userSecurityType = localStorage.getItem('userSecurityType')
                let isAdminUser = localStorage.getItem("isAdminUser")


                if (actSecurityType > userSecurityType) {
                    if (isAdminUser == "false") {
                        setPasswordPopUp(true)
                    }
                }

                // heap analytics
                if (!response.admin_user) {
                    let intercomData = { ...response };

                    let heapObject = {
                        'user_name': (intercomData.user_name === null || intercomData.user_name === "" || intercomData.user_name === undefined) ? intercomData.userId : intercomData.user_name,
                        'account_type': intercomData.account_type,
                        'use_case': intercomData.use_case,
                        'status': intercomData.status,
                        'email': intercomData.email,
                        'company_id': intercomData.id,
                        'device_count': intercomData.device_count,
                        'jobsite_count': intercomData.jobsite_count,
                        'total_Forms': intercomData.total_Forms,
                        'ui_switchcount': intercomData.ui_switchcount,
                        'ui_version': intercomData.ui_version
                    };

                    try {
                        heap.identify(intercomData.userId);
                        heap.addUserProperties(heapObject);
                        console.log('heap analytics loaded with additional properties');
                    } catch (error) {
                        console.log(error);
                    }

                }
                // intercom
                if (!response.hasOwnProperty('status')) {
                    if (!response.admin_user) {
                        let intercomData = { ...response };
                        delete intercomData.email;
                        delete intercomData.created_at;
                        window.Intercom("boot", {
                            company: intercomData,
                            app_id: process.env.REACT_APP_INTERCOM_APP_ID,
                            email: response.email,
                            created_at: response.created_at
                        });
                    } else {
                        window.Intercom("boot", {
                            app_id: process.env.REACT_APP_INTERCOM_APP_ID
                        });
                    }
                }
            }).catch((err) => {
                console.error(err)
            })
        }
    }, [authCtx])


    useEffect(() => {

        try {
            if (menuItems?.length > 0) {

                const menuComponents = menuItems.map(menu => {

                    const { name, submenu } = menu;

                    if (parentMenuWithNoSubmenu.includes(name)) {
                        return {
                            name: name.toLowerCase(),
                            path: `/${name.toLowerCase()}`,
                            Component: componentMap[name]
                        }
                    }
                   
                    if (name.toLowerCase() === 'monitor' && submenu?.length === 0) {
                        return {
                            menu: name.toLowerCase(),
                            submenu: [{
                                name: 'monitor-employees',
                                path: '/monitor/monitor-employees',
                                Component: componentMap['monitor-employees']
                            }]
                        }
                    }

                    if (parentMonitorMenuWithNoSubmenu.includes(name)) {
                   
                        return {
                            name: name.toLowerCase(),
                            path: `/${name.toLowerCase()}`,
                            Component: componentMap[name]
                        }
                    }


                    if (name.toLowerCase() === 'dashboard' && submenu?.length === 0) {
                        return {
                            menu: name.toLowerCase(),
                            submenu: [{
                                name: 'dashboard',
                                path: '/dashboard',
                                Component: componentMap['dashboard']
                            }]
                        }
                    }

                    return {
                        menu: name.toLowerCase(),
                        submenu: submenu.filter(menuItems => menuItems.accessLevel > 0).map(menuItems => {

                            let parentMenuName = name.toLowerCase();
                            let submenuName = menuItems.name.toLowerCase()

                            if (customParentMenuNames[submenuName]) {
                                parentMenuName = customParentMenuNames[submenuName]
                            }

                            if (customRouteNames[submenuName]) {
                                submenuName = customRouteNames[submenuName]
                            }

                            return {
                                name: submenuName,
                                path: `/${parentMenuName}/${submenuName}`,
                                Component: componentMap[submenuName]
                            }
                        }).filter(menuItems => menuItems.Component)
                    }
                })
                let componentData = []

                menuComponents.map(menu => {
                    const { submenu } = menu;

                    if (submenu) {
                        submenu.forEach(menu => {
                            componentData.push(menu)
                        })
                    } else {
                        componentData.push(menu)
                    }

                })

                let showNewMonitor = JSON.parse(localStorage.getItem('isNewMonitorPage'))
                if (!!showNewMonitor) {
                    componentData.push({
                      name: 'monitor',
                      path: '/monitor/new-monitor-employees',
                      Component: componentMap['monitor'],
                    },{
                        name: 'scheduler',
                        path: '/scheduler',
                        Component: componentMap['scheduler'],
                    });
                    
                  }


                componentData = [...componentData, ...customRouteData]

                console.log(componentData,"componentData")
                const pathNames = componentData.map(route => route.path)
                setRoutes([...componentData])

                if (!pathNames.includes(pathname)) {
                    replace('/unauthorized')
                }
                // if(pathNames.includes('/monitor')){
                //     if(!JSON.parse(localStorage.getItem('isMonitorTracking')) && search === "?tab=mileage-and-tracking"){
                //         console.log(JSON.parse(localStorage.getItem('isMonitorTracking')),'isMonitorTracking')
                //        replace('/unauthorized')
                //     }
                //     if(!JSON.parse(localStorage.getItem('isMonitorTimeClock')) && search === "?tab=time-clock"){
                //         console.log(JSON.parse(localStorage.getItem('isMonitorTimeClock')),'isMonitorTimeClock')
                //         replace('/unauthorized')
                //     }
                // }
            }
        } catch (error) {
            console.log(error);
        }
    }, [menuItems])

    return (
        <>
            {routes.map(route => {
                const { Component, name, path } = route;

                return (
                    <Route
                        key={name}
                        path={path}
                        render={props => <Component key={props.location.key} {...props} />}
                        exact
                    />
                )
            })}
            {
                passwordPopUp && <PasswordValidationPopup show={passwordPopUp} />
            }

            <SiteLoader isActive={menuItems?.length === 0} />
        </>
    )
}

export default Routes