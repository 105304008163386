import React,{ Fragment } from "react"

const ConfirmPopup = (props) => {
    return (
        <Fragment>
            <div className={`popupWrap msgPopup ${props.showDialog ? 'active' : ''}`}>
                <div className="msgPopupCont">
                    <div className="msgIcon">Info</div>
                    <div className="msgContent">
                        <div>{props.message}</div>
                    </div>
                </div>
                <div className="popupMsgBtn">
                    <a href={void (0)} className="okBtn" onClick={props.cancelNavigation}>Cancel</a>
                    <button className="btnStyle okBtn yesBtn" onClick={props.confirmNavigation}>Yes</button>
                </div>
            </div>
            <div className={`overlay${props.showDialog ? ' active' : ''}`} />
        </Fragment>
    )
}

export default ConfirmPopup;