import React, { Fragment, useEffect, useState } from "react";
import { apiProvider } from '../../../services/api/provider';
import SiteLoader from '../../common/SiteLoader';
import { Prompt } from "react-router-dom";

export default function EmployeeOrganization(props) {

    const [employeeOrgValue, setEmployeeOrgValue] = useState([]);
    const [employeeSelectedValue, setEmployeeSelectedValue] = useState([]);
    const [formIsDarty, setFormIsDarty] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        let selected = [];
        if (Object.keys(props.data.groups).length > 0) {
            setEmployeeOrgValue(props.data.groups);
            props.data.groups.map((item) => {
                item.selected == 'Yes' ? selected.push(item.groupId) : '';
            });
            if (selected.length > 0) {
                setEmployeeSelectedValue(selected);
            }
        }
    }, [props.data]);

    const handleChange = (event) => {
        setFormIsDarty(true);
        if (event.target.checked === true) {
            employeeSelectedValue.push(event.target.value);
            setEmployeeSelectedValue(employeeSelectedValue);
        }

        if (event.target.checked === false) {
            let removedArr = employeeSelectedValue.filter(e => e !== event.target.value);
            setEmployeeSelectedValue(removedArr);
        }
    }

    const handleFormSubmit = () => {
        setShowLoader(true);
        apiProvider.put('track/mobile/v1/allgeo/updateEmpOrganizationTab/' + props.employeeId, { groups: employeeSelectedValue }).then((response) => {
            setFormIsDarty(false);
            if (response.status && response.status == 200) {
                props.refreshAllTabdata()
                props.setToastMsg({ msg: response.message, type: 'msgSuccess' })
            } else {
                props.setToastMsg({ msg: response.message, type: 'msgError' })
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setShowLoader(false);
            props.formState(false);
            localStorage.removeItem('formIsDirty')
        });
    }

    useEffect(() => {
        props.formState(formIsDarty);
        if (formIsDarty) {
            localStorage.setItem('formIsDirty', formIsDarty)
        }
    }, [formIsDarty]);

    return (
        <Fragment>
            <Prompt
                when={formIsDarty}
                message='The changes are still unsaved. Are you sure you want to leave?'
            />
            <section>
                <h3>Organization</h3>
                <div className="supportedTxt">
                    Check and update employee’s assignments to group(s) here.
                </div>
                <div className="innerWrap">
                    <div className="sectionSubHeading topGap1">Phone {props.nomenclature.device_group_title} Membership</div>
                    <ul className="blockCheckbox">
                        {
                            employeeOrgValue.map((item) => {
                                return <li key={item.groupId}>
                                    <div className="customCheck">
                                        <input onChange={handleChange} type="checkbox" value={item.groupId} id={item.groupId} defaultChecked={item.selected === 'Yes'} />
                                        <label className="customCheckLabel" htmlFor={item.groupId}>
                                            <span>
                                                <em className="groupColor" style={{ background: item.colour }}>
                                                    G
                                                </em>
                                                {item.groupDescription}
                                            </span>
                                        </label>
                                    </div>
                                </li>
                            })
                        }


                    </ul>
                </div>
            </section>
            <div className="mainBtnWrap">
                <a className="cursorPointer" onClick={props.formCancel}>Cancel</a>
                <button disabled={!formIsDarty} onClick={handleFormSubmit} className="btnStyle">SAVE</button>
            </div>
            <SiteLoader isActive={showLoader} />
        </Fragment>
    )
}