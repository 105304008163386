export const convertDateString = (dateString) => {
    const year = dateString.getFullYear();
    const month = (dateString.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 because months are zero-based
    const day = dateString.getDate().toString().padStart(2, '0');

    const formattedDate = `${year}/${month}/${day}`;

    return formattedDate
}

export const convertTimeString = (timeString) => {
    const date = new Date(timeString);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const amOrPm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = ((hours % 12) || 12).toString().padStart(2, '0'); // Convert to 12-hour format with leading zero

    const formattedTime = `${formattedHours}:${minutes.toString().padStart(2, '0')} ${amOrPm}`;
    return formattedTime
}

export function extractTaskIDs(data) {
    if (Array.isArray(data)) {
      return data.map(item => item.TaskID).join(',');
    } else if (data && typeof data === 'object') {
      return data.TaskID;
    } else {
      return '';
    }
  }