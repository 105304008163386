import { Fragment, useState, useEffect } from 'react';
import { ConfirmPopup2nd } from '../../common/PopUp';
import { apiProvider } from '../../../services/api/provider';

const EditForm = (props) => {

    const [showDialog, setShowDialog] = useState(false);
    const [formInformation, setFormInformation] = useState({
        formName: '',
        formStatus: false,
        isDirty: false,
        formNameFieldError: false
    });

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = () => {

        props.setShowLoader(true);

        apiProvider.getAll(`/track/mobile/v1/allgeo/viewform?ID=${props.formId}`).then((response) => {
            if (response.status === 200 && response.message.toLowerCase() === 'success') {
                setFormInformation({
                    formName: response.formName,
                    formStatus: response.active,
                    isDirty: false,
                    formNameFieldError: false
                })
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            props.setShowLoader(false);
        });

    }

    const onSuccess = () => {
        setShowDialog(false)
        props.setMode({
            title: "Manage Forms",
            type: 'main',
            data: null,
            hasPrev: false
        })
    }

    const onFaliure = () => {
        setShowDialog(false);
    }

    const changeFormInformation = (e) => {

        const name = e.target.name;
        const value = e.target.value;

        if (name === 'formStatus') {
            setFormInformation({ ...formInformation, [name]: !formInformation.formStatus, isDirty: true })
        } else {
            setFormInformation({ ...formInformation, [name]: value.replace(/[^a-zA-Z0-9 _-]/gi, ''), isDirty: true })
        }

    }

    const submit = async () => {

        try {
            props.setShowLoader(true)

            let payload = [
                {
                    "ID": props.mode.data?.formId,
                    "active": formInformation?.formStatus,
                    "formName": formInformation?.formName
                }
            ]

            const response = await apiProvider.put("/track/mobile/v1/allgeo/forms", payload)

            if (response.status === 200) {
                props.setToastMsg({ msg: "Form updated successfully", type: "msgSuccess" });
                props.setMode({
                    title: "Manage Forms",
                    type: "main",
                    data: null,
                    hasPrev: false
                })
            } else {
                props.setToastMsg({
                    msg: response.message,
                    type: "msgError"
                })
            }

        } catch (error) {
            console.log(error);
        } finally {
            props.setShowLoader(false)
        }
    }

    return (
        <Fragment>
            <div className="popupWrap smlPopup active">
                <div className="closePopup" onClick={() => {
                    if (formInformation.isDirty) {
                        setShowDialog(true);
                    } else {
                        props.setMode({
                            title: "Manage Forms",
                            type: 'main',
                            data: null,
                            hasPrev: false
                        })
                    }
                }}>Close</div>
                <div className="popupHeading">Edit Form</div>
                <div className="popupContent">
                    <div className="rowBlk">
                        <div className={`colBlk6 fldWrap ${formInformation.formNameFieldError && errFld}`}>
                            <label>Form Name</label>
                            <input type="text" name="formName" maxLength={50} value={formInformation.formName} onChange={(e) => changeFormInformation(e)} />
                            {formInformation.formNameFieldError && <span className="fldMsg">Form Name already exists</span>}
                        </div>
                        <div className="colBlk6 fldWrap">
                            <label>Active</label>
                            <div className="customCheck yesNo">
                                <input type="checkbox" id="activeStatus" name="formStatus" checked={formInformation.formStatus} onChange={(e) => changeFormInformation(e)} />
                                <label className="customCheckLabel" htmlFor="activeStatus">yesNo</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="popupBtnWrap flexJCenter">
                    <button className="btnStyle okBtn" disabled={!formInformation?.isDirty} onClick={(e) => submit()}>SAVE</button>
                </div>
            </div>
            <ConfirmPopup2nd
                showDialog={showDialog}
                message={"The changes are still unsaved. Are you sure you want to leave?"}
                confirmNavigation={onSuccess}
                cancelNavigation={onFaliure}
            />
        </Fragment>
    )
}

export default EditForm