import React from 'react'

export const WeekDaySelector = ({ isDayActive, handleDayClick }) => {
    return (
        <div className="col_six">
            <label>Select Days</label>
            <div className="week_days">
                <span id="1" className={isDayActive('MO') ? 'active' : ''} onClick={() => handleDayClick('MO')}>
                    M
                </span>
                <span id="2" className={isDayActive('TU') ? 'active' : ''} onClick={() => handleDayClick('TU')}>
                    T
                </span>
                <span className={isDayActive('WE') ? 'active' : ''} onClick={() => handleDayClick('WE')}>
                    W
                </span>
                <span className={isDayActive('TH') ? 'active' : ''} onClick={() => handleDayClick('TH')}>
                    T
                </span>
                <span className={isDayActive('FR') ? 'active' : ''} onClick={() => handleDayClick('FR')}>
                    F
                </span>
                <span className={isDayActive('SA') ? 'active' : ''} onClick={() => handleDayClick('SA')}>
                    S
                </span>
                <span className={isDayActive('SU') ? 'active' : ''} onClick={() => handleDayClick('SU')}>
                    S
                </span>
            </div>
        </div>
    );
}
