//General Tab

export const payerServiceColumns = [
    {
        title: "State Code",
        field: "stateCode",
        editable: true,
        width: 200,
        editor: "text",
        orderIndex: 1,
        columnMenu: false,
        show: true,
        dropdownCell: true,
        mandatory: true
    },
    {
        title: "Payer ID",
        field: "payerID",
        editable: true,
        width: 200,
        editor: "text",
        orderIndex: 2,
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true
    },
    {
        title: "Payer Program",
        field: "payerProgram",
        editable: true,
        width: 200,
        editor: "text",
        orderIndex: 3,
        show: true,
        filter: "text",
        mandatory: true
    },
    {
        title: "Procedure Code",
        field: "procedureCode",
        editable: true,
        width: 200,
        editor: "text",
        orderIndex: 4,
        show: true,
        filter: "text",
        mandatory: true
    },
    {
        title: "Modifier 1",
        field: "modifier1",
        editable: true,
        width: 200,
        editor: "text",
        orderIndex: 5,
        show: true,
        filter: "text",
        mandatory: false
    },
    {
        title: "Modifier 2",
        field: "modifier2",
        editable: true,
        width: 200,
        editor: "text",
        orderIndex: 6,
        show: true,
        filter: "text",
        mandatory: false
    },
    {
        title: "Modifier 3",
        field: "modifier3",
        editable: true,
        width: 300,
        editor: "text",
        orderIndex: 7,
        show: true,
        filter: "text",
        mandatory: false
    },
    {
        title: "Modifier 4",
        field: "modifier4",
        editable: true,
        width: 300,
        editor: "text",
        orderIndex: 8,
        show: true,
        filter: "text",
        mandatory: false
    },
    {
        title: "Description",
        field: "description",
        editable: true,
        width: 200,
        editor: "text",
        orderIndex: 9,
        show: true,
        filter: "text",
        mandatory: true
    },
    {
        title: "Created Date",
        field: "creationTime",
        editable: false,
        width: 200,
        editor: "text",
        orderIndex: 10,
        show: true,
        filter: "text",
        mandatory: false
    },
    {
        title: "Last Updated Date",
        field: "lastUpdateTime",
        editable: false,
        width: 200,
        editor: "text",
        orderIndex: 11,
        show: true,
        filter: "text",
        mandatory: false
    },
]

export const states = [
    {
        label: "Delaware",
        value: "DE"
    },
    {
        label: "Hawaii",
        value: "HI"
    },
    {
        label: "Texas",
        value: "TX"
    },
    {
        label: "Massachusetts",
        value: "MA"
    },
    {
        label: "Maryland",
        value: "MD"
    },
    {
        label: "Iowa",
        value: "IA"
    },
    {
        label: "Maine",
        value: "ME"
    },
    {
        label: "Idaho",
        value: "ID"
    },
    {
        label: "Michigan",
        value: "MI"
    },
    {
        label: "Utah",
        value: "UT"
    },
    {
        label: "Minnesota",
        value: "MN"
    },
    {
        label: "Missouri",
        value: "MO"
    },
    {
        label: "Illinois",
        value: "IL"
    },
    {
        label: "Indiana",
        value: "IN"
    },
    {
        label: "Mississippi",
        value: "MS"
    },
    {
        label: "Montana",
        value: "MT"
    },
    {
        label: "Alaska",
        value: "AK"
    },
    {
        label: "Alabama",
        value: "AL"
    },
    {
        label: "Virginia",
        value: "VA"
    },
    {
        label: "Arkansas",
        value: "AR"
    },
    {
        label: "North Carolina",
        value: "NC"
    },
    {
        label: "North Dakota",
        value: "ND"
    },
    {
        label: "Nebraska",
        value: "NE"
    },
    {
        label: "Rhode Island",
        value: "RI"
    },
    {
        label: "Arizona",
        value: "AZ"
    },
    {
        label: "New Hampshire",
        value: "NH"
    },
    {
        label: "New Jersey",
        value: "NJ"
    },
    {
        label: "Vermont",
        value: "VT"
    },
    {
        label: "New Mexico",
        value: "NM"
    },
    {
        label: "Florida",
        value: "FL"
    },
    {
        label: "Nevada",
        value: "NV"
    },
    {
        label: "Washington",
        value: "WA"
    },
    {
        label: "New York",
        value: "NY"
    },
    {
        label: "South Carolina",
        value: "SC"
    },
    {
        label: "South Dakota",
        value: "SD"
    },
    {
        label: "Wisconsin",
        value: "WI"
    },
    {
        label: "Ohio",
        value: "OH"
    },
    {
        label: "Georgia",
        value: "GA"
    },
    {
        label: "Oklahoma",
        value: "OK"
    },
    {
        label: "California",
        value: "CA"
    },
    {
        label: "West Virginia",
        value: "WV"
    },
    {
        label: "Wyoming",
        value: "WY"
    },
    {
        label: "Oregon",
        value: "OR"
    },
    {
        label: "Kansas",
        value: "KS"
    },
    {
        label: "Colorado",
        value: "CO"
    },
    {
        label: "Kentucky",
        value: "KY"
    },
    {
        label: "Connecticut",
        value: "CT"
    },
    {
        label: "Pennsylvania",
        value: "PA"
    },
    {
        label: "Louisiana",
        value: "LA"
    },
    {
        label: "Tennessee",
        value: "TN"
    },
    {
        label: "None",
        value: "None"
    },
    {
        label: "District Of Columbia",
        value: "DC"
    }
]

// Payroll Tab

export const earningCodeColumns = [
    {
        title: "Code",
        field: "code",
        editable: true,
        width: 200,
        editor: "text",
        orderIndex: 1,
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true
    },
    {
        title: "Description",
        field: "description",
        editable: true,
        width: 200,
        editor: "text",
        orderIndex: 2,
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true
    },
    {
        title: "Service",
        field: "service",
        editable: true,
        width: 200,
        editor: "text",
        orderIndex: 3,
        show: true,
        filter: "text",
        dropdownCell: true
    },
    {
        title: "Source",
        field: "source",
        editable: true,
        width: 200,
        editor: "text",
        orderIndex: 4,
        show: true,
        filter: "text",
        dropdownCell: true
    },
    {
        title: "Tax Treatment",
        field: "generic1",
        editable: true,
        width: 200,
        editor: "text",
        orderIndex: 5,
        show: true,
        filter: "text"
    },
    {
        title: "Calculation",
        field: "generic2",
        editable: true,
        width: 200,
        editor: "text",
        orderIndex: 6,
        show: true,
        filter: "text"
    },
    {
        title: "Type",
        field: "generic3",
        editable: true,
        width: 200,
        editor: "text",
        orderIndex: 7,
        show: true,
        filter: "text"
    },
    {
        title: "Reference#",
        field: "referenceID",
        editable: true,
        width: 200,
        editor: "text",
        orderIndex: 8,
        show: true,
        filter: "text",
        mandatory: true
    },
    {
        title: "Rate($)",
        field: "rate",
        editable: true,
        width: 200,
        editor: "text",
        orderIndex: 9,
        show: true,
        filter: "text"
    },
    {
        title: "Created Date",
        field: "creationTime",
        editable: false,
        width: 300,
        editor: "text",
        orderIndex: 10,
        show: true,
        filter: "text"
    },
    {
        title: "Last Updated Date",
        field: "lastUpdateTime",
        editable: false,
        width: 300,
        editor: "text",
        orderIndex: 11,
        show: true,
        filter: "text"
    }
]
