import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { apiProvider } from '../../../../services/api/provider'
import { getDefaultDate, reportTypes, reportRanges, assignTypes, reportMedium, formatDate, formatTime } from '../reportSchedulerUtils';
import AddForm1 from './AddForm1';
import AddForm2 from './AddForm2'

const AddNewPopup = ({ closePopup, reportForData, accountInfo, setShowLoader, setToastMsg, reports }) => {

    const { register, watch, handleSubmit, setValue, getValues, reset, formState: { errors } } = useForm({ mode: "all" });

    const [formPage, setFormPage] = useState(1)

    const onSubmit = async data => {
        try {
            setShowLoader(true)
            const payload = {
                ...data,
                selectedReport: data.selectedReport.map(rep => rep.id),
                scheduleFor: data.assignType === assignTypes.GROUP ? data.scheduleForGrp : data.scheduleForEmp,
                reportFromTime: formatTime(data.reportFromTime),
                reportToTime: formatTime(data.reportToTime),
                reportLastHrs: data.reportRange === reportRanges.DAYS ? data.reportLastDays : data.reportLastHrs,
                scheduleStartOn: formatDate(data.scheduleStartOn),
                scheduleEndOn: data.scheduleCheck !== 'never' ? formatDate(data.scheduleEndOn) : 'never',
                startTime: formatTime(data.startTime),
                type: "Report",
                accountNotificationEmail: data.reportOption !== 'email' ? false : data.accountNotificationEmail,
                managerNotificationEmail: data.reportOption !== 'email' ? false : data.managerNotificationEmail,
                deviceNotificationEmail: data.reportOption !== 'email' ? false : data.deviceNotificationEmail,
                reportSendToEmails: data.reportOption !== 'email' ? "" : data.reportSendToEmails
            }
            delete payload.scheduleCheck
            delete payload.reportLastDays
            delete payload.scheduleForGrp
            delete payload.scheduleForEmp

            const response = await apiProvider.post('/track/mobile/v1/allgeo/schedule/create', [payload])

            if (response.status === 200) {
                setToastMsg({ msg: `Schedule ${payload.scheduleId} has been successfully created.`, type: "msgSuccess" })
                closePopup()
            } else {
                setToastMsg({ msg: response.message, type: "msgError" })
            }

        } catch (err) {
            console.log(err);
        } finally {
            setShowLoader(false)
        }
    }

    useEffect(() => {
        reset({
            scheduleId: "",
            reportType: reportTypes.STANDARD,
            assignType: assignTypes.GROUP,
            scheduleForGrp: "all",
            scheduleForEmp: "all",
            active: true,
            selectedReport: [],
            reportRange: reportRanges.TIME_RANGE,
            reportFromTime: getDefaultDate(0, 9),
            reportToTime: getDefaultDate(0, 17),
            reportLastHrs: 8,
            reportLastDays: 2,
            attachmentType: 'CSV',
            scheduleCheck: 'never',
            scheduleStartOn: getDefaultDate(0),
            scheduleEndOn: getDefaultDate(1),
            startTime: getDefaultDate(0),
            reportOption: reportMedium.EMAIL,
            timezone: accountInfo.timezone,
            accountNotificationEmail: true
        })
    }, [])

    return (
        <div className="popupWrap medPopup active">
            <div className="closePopup" onClick={closePopup} >Close</div>
            <div className="popupHeading">Add New Report Scheduler</div>
            <form onSubmit={handleSubmit(onSubmit)} >
                {
                    formPage === 1
                        ?
                        <AddForm1
                            register={register}
                            getValues={getValues}
                            setValue={setValue}
                            watch={watch}
                            reportForData={reportForData}
                            reportTypeData={reports}
                            setToastMsg={setToastMsg}
                            dateFormat={accountInfo.dateFormat}
                            timeFormat={accountInfo.timeFormat}
                        />
                        :
                        <AddForm2
                            register={register}
                            setFormPage={setFormPage}
                            watch={watch}
                            getValues={getValues}
                            setValue={setValue}
                            accountInfo={accountInfo}
                            setToastMsg={setToastMsg}
                            dateFormat={accountInfo.dateFormat}
                            timeFormat={accountInfo.timeFormat}
                            errors={errors}
                        />
                }
            </form>
            {formPage === 1 && <div className="popupBtnWrap flexJCenter">
                <button
                    className="btnStyle okBtn"
                    disabled={watch('scheduleId')?.trim().length === 0 || watch('selectedReport')?.length === 0}
                    onClick={() => setFormPage(2)} >
                    Next
                </button>
            </div>}
        </div>
    )
}

export default AddNewPopup