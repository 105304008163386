import React, { useState, useEffect } from "react";
import { getG2Data } from "./G2Utils/getG2Data";
import { G2Review } from "./G2Review";
import { apiProvider } from "../../../services/api/provider";
import G2Logo from '../../../assets/images/g2-logo-red.svg'

export const G2ReviewPopup = ({ showPopup, setShowPopup }) => {
  const [showG2ReviewPage, setShowG2ReviewPage] = useState(false);
  const [g2State, setG2State] = useState("");
  const [userEmail, setUserEmail] = useState('');

  const g2ReviewSubmitHandler = async () => {
    const userName = localStorage.getItem("enteredUserName");
    try {
      const g2Response = await apiProvider.getAll(
        `track/react/v1/allgeo/g2/markReviewed?userID=${userName}`
      );
      const managerAllResponse = await apiProvider.getAll('/track/mobile/v1/allgeo/manager');
      const manager = managerAllResponse.managers.find(manager => manager.managerID.toLowerCase() === userName.toLowerCase());
    
    if (manager) {
      setUserEmail(manager.contactEmail);
    }
      setG2State(g2Response.g2State);
    } catch (err) {
      console.error("Error on marking the G2 Review", err);
    } finally {
      localStorage.setItem("g2ReviewInactive", true);
    }
    
    setShowG2ReviewPage(true);
  };
  const g2CancelHandler = () => {
    setShowPopup(false);
    localStorage.setItem("g2ReviewInactive", true);
  };
  return (
    <>
      <div
        className={`popupWrap smlPopup2 ${showPopup ? "active" : ""}`}
        style={{ padding: "24px", textAlign: "center" }}
      >
        <div
          className="closePopup"
          onClick={g2CancelHandler}
          style={{ top: "-5px", right: "-5px" }}
        >
          Close
        </div>
        {!showG2ReviewPage && (
          <>
            <div>
              <img src={G2Logo} alt="allGeo" style={{height: '90px', width:'90px'}}/>
              <h1>Tell us what you think.</h1>
              <p style={{ marginBottom: "12px" }}>
                We'd love for you to review us on G2! To continue, your email
                address will be shared with G2 to verify review authenticity.
                Before choosing to submit a review, you can review G2's&nbsp;
                <a
                  href="https://legal.g2.com/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  Privacy Policy
                </a>
                &nbsp;and&nbsp;
                <a
                  href="https://legal.g2.com/terms-of-use"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  Terms of Use
                </a>
                .
              </p>
              <div style={{ fontSize: "12px" }}>
                <p>Approximate time to complete: &lt; 5 minutes</p>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="popupBtnWrap">
                <button
                  className="btnStyle btn2"
                  style={{ padding: "5px 30px" }}
                  onClick={g2CancelHandler}
                >
                  Not Now
                </button>
              </div>
              <div className="popupBtnWrap ">
                <button className="btnStyle" onClick={g2ReviewSubmitHandler}>
                  Write a Review
                </button>
              </div>
            </div>
          </>
        )}
        <div className="msgPopupCont">
          {showG2ReviewPage && <G2Review state={g2State} userEmail={userEmail}/>}
        </div>
      </div>
      <div className={`overlay${showPopup ? " active" : ""}`} />
    </>
  );
};
