import React, { useRef } from "react";
import "./changeStatusModal.css";
import CloseIcon from "../../../../../assets/images/closeIcon.svg";
import { apiProvider } from "../../../../../services/api/provider";

export const ChangeStatusModal = ({
  closePopup,
  timeclockStage,
  employeeData,
  selectedDeleteItem,
  refreshEmployeeData,setToastMsg,setShowLoader
}) => {
  const timeclockRef = useRef(null);
  let data = employeeData.data.find(
    (item) => item.employeeID === selectedDeleteItem
  );

  const changeJobStage = async () => {
    setShowLoader(true)
    closePopup();
    try {
      const stageID = +timeclockRef.current?.value?.split(":")[1];
      const response = await apiProvider.put(
        `track/react/v1/allgeo/monitor/updateHOSStage?deviceId=${selectedDeleteItem}&changedHosStageId=${stageID}`
      );

      if (response.status === 200) {
        refreshEmployeeData()
        setToastMsg({ msg: `Job Status updated successfully.`, type: "msgSuccess" })
      } else {
        setToastMsg({ msg: response.message, type: "msgError" })
      }
    } catch (error) {
      console.log(error);
    }finally{
      setTimeout(() => {
        
        setShowLoader(false)
      }, 2000);
    }
  };
  return (
    <div>
      <div className="overlay2"></div>

      <div className="statusparentBox">
        <div className="headingParentBox">
          <h2 className="statusheadingtag">Change Job Status</h2>
          <img style={{cursor:"pointer"}} onClick={closePopup} src={CloseIcon} alt="" />
        </div>
        <div className="statusmodalbox">
          <div>
            <p>Device ID</p>
            <p>{data?.employeeID}</p>
          </div>
          <div>
            <p>Current Stage</p>
            <p>{data?.timeClockStatus?.[0]?.lastTimeclockStageName}</p>
          </div>
        </div>
        <div className="statusModalSelectBox">
          <p>Change Shift To</p>
          <div className="">
            <select ref={timeclockRef}>
              {timeclockStage?.map((stage) => (
                <option key={stage?.value} value={stage?.value}>
                  {stage?.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="closebtn">
          <button onClick={changeJobStage} className="statusbuttondiv">
            Change
          </button>
        </div>
      </div>
    </div>
  );
};
