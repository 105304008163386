import React from "react";

import { useDocumentTitle } from "../../hooks/setDocumentTitle";
import Header from "../../components/layout/Header";
import MainLayout from "../../components/layout/MainLayout";
import { SummaryCards } from "./SummaryCards";

export const Dashboard = () => {
  useDocumentTitle("Dashboard - allGeo");
  const dashboardHelpUrl = "https://help.allgeo.com/en/articles/7213523-reports-dashboard-run-reports";
  return (
    <div style={{width: '100%'}}>
      <MainLayout style={{width: '100%'}}>
        <div className="mainContentWrap" style={{ overflow: "hidden", width: "100%" }}>
          <Header title="Dashboard"/>
          <SummaryCards />
        </div>
      </MainLayout>
    </div>
  );
};
