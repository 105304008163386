import { getAccountLabel } from "../../../utils"

export const units = {
    "meters": "m",
    "meter": "m",
    "feet": "ft",
    "foot": "ft",
    "kilometers": "km",
    "kilometer": "km",
    "miles": "mi",
    "mile": "mi",
    "yards": "yd",
    "yard": "yd"
}

export const timeZones = [
    {
        value: "US/Hawaii",
        label: "US/Hawaii"
    },
    {
        value: "US/Alaska",
        label: "US/Alaska"
    },
    {
        value: "US/Pacific",
        label: "US/Pacific"
    },
    {
        value: "US/Arizona",
        label: "US/Arizona"
    },
    {
        value: "US/Mountain",
        label: "US/Mountain"
    },
    {
        value: "US/Central",
        label: "US/Central"
    },
    {
        value: "US/Eastern",
        label: "US/Eastern"
    },
    {
        value: "Canada/Pacific",
        label: "Canada/Pacific"
    },
    {
        value: "Canada/Mountain",
        label: "Canada/Mountain"
    },
    {
        value: "Canada/Central",
        label: "Canada/Central"
    },
    {
        value: "Canada/Eastern",
        label: "Canada/Eastern"
    },
    {
        value: "Canada/Atlantic",
        label: "Canada/Atlantic"
    },
    {
        value: "Mexico/BajaNorte",
        label: "Mexico/BajaNorte"
    },
    {
        value: "Mexico/BajaSur",
        label: "Mexico/BajaSur"
    },
    {
        value: "Mexico/General",
        label: "Mexico/General"
    },
    {
        value: "Europe/Athens",
        label: "Europe/Athens"
    },
    {
        value: "Europe/Berlin",
        label: "Europe/Berlin"
    },
    {
        value: "Europe/Dublin",
        label: "Europe/Dublin"
    },
    {
        value: "Europe/Helsinki",
        label: "Europe/Helsinki"
    },
    {
        value: "Europe/Kiev",
        label: "Europe/Kiev"
    },
    {
        value: "Europe/Lisbon",
        label: "Europe/Lisbon"
    },
    {
        value: "Europe/London",
        label: "Europe/London"
    },
    {
        value: "Europe/Madrid",
        label: "Europe/Madrid"
    },
    {
        value: "Europe/Moscow",
        label: "Europe/Moscow"
    },
    {
        value: "Europe/Oslo",
        label: "Europe/Oslo"
    },
    {
        value: "Europe/Paris",
        label: "Europe/Paris"
    },
    {
        value: "Europe/Rome",
        label: "Europe/Rome"
    },
    {
        value: "Europe/Stockholm",
        label: "Europe/Stockholm"
    },
    {
        value: "Europe/Zurich",
        label: "Europe/Zurich"
    },
    {
        value: "Pacific/Auckland",
        label: "Pacific/Auckland"
    },
    {
        value: "Pacific/Chatham",
        label: "Pacific/Chatham"
    },
    {
        value: "GMT",
        label: "GMT"
    },
    {
        value: "GMT+00:00",
        label: "GMT+00:00"
    },
    {
        value: "GMT+01:00",
        label: "GMT+01:00"
    },
    {
        value: "GMT+02:00",
        label: "GMT+02:00"
    },
    {
        value: "GMT+03:00",
        label: "GMT+03:00"
    },
    {
        value: "GMT+04:00",
        label: "GMT+04:00"
    },
    {
        value: "GMT+05:00",
        label: "GMT+05:00"
    },
    {
        value: "GMT+05:30",
        label: "GMT+05:30"
    },
    {
        value: "GMT+06:00",
        label: "GMT+06:00"
    },
    {
        value: "GMT+07:00",
        label: "GMT+07:00"
    },
    {
        value: "GMT+08:00",
        label: "GMT+08:00"
    },
    {
        value: "GMT+09:00",
        label: "GMT+09:00"
    },
    {
        value: "GMT+10:00",
        label: "GMT+10:00"
    },
    {
        value: "GMT+11:00",
        label: "GMT+11:00"
    },
    {
        value: "GMT+12:00",
        label: "GMT+12:00"
    },
    {
        value: "GMT+13:00",
        label: "GMT+13:00"
    },
    {
        value: "GMT+14:00",
        label: "GMT+14:00"
    },
    {
        value: "GMT-01:00",
        label: "GMT-01:00"
    },
    {
        value: "GMT-02:00",
        label: "GMT-02:00"
    },
    {
        value: "GMT-03:00",
        label: "GMT-03:00"
    },
    {
        value: "GMT-04:00",
        label: "GMT-04:00"
    },
    {
        value: "GMT-05:00",
        label: "GMT-05:00"
    },
    {
        value: "GMT-06:00",
        label: "GMT-06:00"
    },
    {
        value: "GMT-07:00",
        label: "GMT-07:00"
    },
    {
        value: "GMT-08:00",
        label: "GMT-08:00"
    },
    {
        value: "GMT-09:00",
        label: "GMT-09:00"
    },
    {
        value: "GMT-10:00",
        label: "GMT-10:00"
    },
    {
        value: "GMT-11:00",
        label: "GMT-11:00"
    },
    {
        value: "GMT-12:00",
        label: "GMT-12:00"
    },

]

export const colorArray = [
    { label: 'Default', value: "" },
    { label: 'Black', value: "#000000" },
    { label: 'Brown', value: "#a52a2a" },
    { label: 'Red', value: "#dd0000" },
    { label: 'Orange', value: "#ffa500" },
    { label: 'Yellow', value: "#ffd700" },
    { label: 'Green', value: "#00cc00" },
    { label: 'Blue', value: "#0000ee" },
    { label: 'Purple', value: "#9400d3" },
    { label: 'Grey', value: "#505050" },
    { label: 'White', value: "#ffffff" },
    { label: 'Cyan', value: "#00ffff" },
    { label: 'Pink', value: "#ff1493" }
]

export const unitConversionRate = {
    "meters": 1,
    "meter": 1,
    "feet": 0.3048,
    "foot": 0.3048,
    "kilometers": 1000,
    "kilometer": 1000,
    "miles": 1609.34,
    "mile": 1609.34,
    "yards": 0.9144,
    "yard": 0.9144
}

export const revUnitConversionRate = {
    "meters": 1,
    "meter": 1,
    "feet": 3.28084,
    "foot": 3.28084,
    "kilometers": 0.001,
    "kilometer": 0.001,
    "miles": 0.00062,
    "mile": 0.00062,
    "yards": 1.09,
    "yard": 1.09
}

export const zoneColorCondition = (attr) => {
    if (attr === "" || attr === null) {
        return '#0000FF'
    }
    else {
        return attr
    }
}

export const getLatLngList = list => {
    const latLanList = list.map(coord => ({
        lat: +coord.latValue,
        lng: +coord.lonValue
    })).filter(coord => coord.lat != 0 && coord.lng != 0)

    if (latLanList.length === 0) {
        return [{ lat: 0, lng: 0 }]
    }

    return latLanList
}

const employeeLabel = getAccountLabel('employee');
const taskLabel = getAccountLabel('task');

export const jobSiteColumns = [
    {
        title: "Name",
        field: "name",
        editable: true,
        width: 200,
        editor: "text",
        orderIndex: 1,
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true
    },
    {
        title: "Address",
        field: "address",
        editable: true,
        width: 400,
        editor: "text",
        orderIndex: 2,
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: `Assigned ${taskLabel}`,
        field: "assignedTasks",
        editable: false,
        width: 200,
        editor: "text",
        orderIndex: 3,
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: false
    },
    {
        title: `Assigned ${employeeLabel}`,
        field: "assignedEmployees",
        editable: false,
        width: 200,
        editor: "text",
        orderIndex: 3,
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: false
    },
    {
        title: `Nearby ${employeeLabel}`,
        field: "near_employees",
        editable: false,
        width: 200,
        editor: "text",
        orderIndex: 4,
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Geozone Type",
        field: "geozoneType",
        editable: false,
        width: 200,
        editor: "text",
        orderIndex: 5,
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Radius",
        field: "geozone.zoneRadius",
        editable: true,
        width: 200,
        editor: "text",
        orderIndex: 6,
        columnMenu: false,
        show: true,
        filter: "text",
        pattern: /^\d*$/,
        patternFailMsg: "Please Enter a Numeric Value"
    },
    {
        title: "Created Date",
        field: "creation",
        editable: false,
        width: 200,
        editor: "text",
        orderIndex: 7,
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Rate Card",
        field: "additional_info.rateCard",
        editable: true,
        width: 200,
        editor: "text",
        orderIndex: 8,
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Site ID",
        field: "additional_info.siteId",
        editable: true,
        width: 200,
        editor: "text",
        orderIndex: 9,
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Description",
        field: "additional_info.user_description",
        editable: true,
        width: 200,
        editor: "text",
        orderIndex: 9,
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Site Description",
        field: "additional_info.siteName",
        editable: true,
        width: 200,
        editor: "text",
        orderIndex: 10,
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Source ID",
        field: "additional_info.sourceId",
        editable: true,
        width: 200,
        editor: "text",
        orderIndex: 11,
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Reference ID",
        field: "additional_info.referenceId",
        editable: true,
        width: 200,
        editor: "text",
        orderIndex: 12,
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Customer Type",
        field: "additional_info.customerType",
        editable: false,
        width: 200,
        editor: "text",
        orderIndex: 13,
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Contact email",
        field: "additional_info.email",
        editable: true,
        width: 200,
        editor: "text",
        orderIndex: 14,
        columnMenu: false,
        show: false,
        filter: "text",
        pattern: /\S+@\S+\.\S+/,
        patternFailMsg: 'Invalid Email'
    },
    {
        title: "Phone #",
        field: "additional_info.phoneNumber",
        editable: true,
        width: 200,
        editor: "text",
        orderIndex: 15,
        columnMenu: false,
        show: false,
        filter: "text",
        pattern: /^\d*$/,
        patternFailMsg: "Please Enter a Numeric Value"
    },
    {
        title: "Earning Code",
        field: "additional_info.earningCode",
        editable: true,
        width: 200,
        editor: "text",
        orderIndex: 16,
        dropdownCell: true,
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Risk",
        field: "additional_info.risk",
        editable: true,
        width: 200,
        editor: "text",
        orderIndex: 17,
        columnMenu: false,
        dropdownCell: true,
        show: false,
        filter: "text"
    },
    {
        title: "Site Type",
        field: "additional_info.siteType",
        editable: true,
        width: 200,
        editor: "text",
        orderIndex: 18,
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Service Code",
        field: "evv_info.service_code",
        editable: true,
        width: 200,
        editor: "text",
        orderIndex: 19,
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "State ID",
        field: "evv_info.stateId",
        editable: true,
        width: 200,
        editor: "text",
        orderIndex: 20,
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Authorization",
        field: "evv_info.authorizarion",
        editable: true,
        width: 200,
        editor: "text",
        orderIndex: 21,
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Client First Name",
        field: "evv_info.client_first_name",
        editable: true,
        width: 200,
        editor: "text",
        orderIndex: 22,
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Client Last Name",
        field: "evv_info.client_last_name",
        editable: true,
        width: 200,
        editor: "text",
        orderIndex: 23,
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Claim Frequency",
        field: "additional_info.claim_freqeuncy",
        editable: false,
        width: 200,
        editor: "text",
        orderIndex: 24,
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Landline #",
        field: "additional_info.landLineNumber",
        editable: true,
        width: 200,
        editor: "text",
        orderIndex: 25,
        columnMenu: false,
        show: false,
        filter: "text",
        pattern: /^\d*$/,
        patternFailMsg: "Please Enter a Numeric Value"
    },
    {
        title: "Skills",
        field: "additional_info.skills",
        editable: true,
        width: 200,
        editor: "text",
        orderIndex: 26,
        dropdownCell: true,
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Ext System",
        field: "additional_info.extSystem",
        editable: true,
        width: 200,
        editor: "text",
        orderIndex: 27,
        columnMenu: false,
        show: false,
        filter: "text"
    },
    
]

export const SELECTED_JOBSITES_KEY = "jobsiteIDS"
