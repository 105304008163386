import React, { useState } from 'react'
import DistanceAudit from './DistanceAudit';
import AuditReport from './AuditReport';

const AuditReportsWrapper = ({ columnName, dropdownData, setShowLoader, accessLevel, toastMsg, setToastMsg, setTemplateName, setColumnList, columnList, setKendoDataState }) => {

    const [selectedReport, setSelectedReport] = useState('pendingApproval')

    const handlePendingChange = () => {
        setSelectedReport('pendingApproval')
        setTemplateName('auditReport')

    }
    const handleMilesChange = () => {
        setSelectedReport('auditMiles')
        setTemplateName('distanceAudit')
    }

    return (
        <>
            <div className="customControls fldWrap">
                <div className="customRadio">
                    <input type="radio" id="pendingApproval" name="ReportType" defaultChecked value={selectedReport} onChange={handlePendingChange} />
                    <label htmlFor="pendingApproval">Pending Approval</label>
                </div>
                <div className="customRadio">
                    <input type="radio" id="milesAudit" name="ReportType" value={selectedReport} onChange={handleMilesChange} />
                    <label htmlFor="milesAudit">Miles Audit</label>
                </div>
            </div>

            {selectedReport === 'pendingApproval' &&
                <AuditReport columnName={columnName} dropdownData={dropdownData} setShowLoader={setShowLoader} accessLevel={accessLevel} toastMsg={toastMsg} setToastMsg={setToastMsg} setColumnList={setColumnList} columnList={columnList} setKendoDataState={setKendoDataState} />
            }

            {selectedReport === 'auditMiles' &&
                <DistanceAudit columnName={columnName} dropdownData={dropdownData} setShowLoader={setShowLoader} accessLevel={accessLevel} setColumnList={setColumnList} columnList={columnList} setKendoDataState={setKendoDataState} />
            }

        </>
    )
}

export default AuditReportsWrapper