import React, { useEffect } from 'react'
import { apiProvider } from '../../../services/api/provider'
import loader from '../../../assets/images/loader.png'

const LocateEmployeePopup = ({ data, closePopup, setToastMsg }) => {

    const locateEmployee = async () => {
        try {
            const payload = [data.deviceId]

            const response = await apiProvider.post('track/react/v1/allgeo/monitor/LocateEmployees', payload)

            if (+response.status === 200) {
                setTimeout(() => {
                    setToastMsg({ msg: response.message, type: "msgSuccess", refresh: false })
                    closePopup()
                }, 30000)
            } else {
                setToastMsg({ msg: response.message, type: "msgError" })
                closePopup()
            }

        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        locateEmployee()
    }, [])

    return (
        <div className="popupWrap smlPopup active">
            <div className="closePopup" onClick={closePopup} >Close</div>
            <div className="popupHeading">On-demand Location</div>
            <div className="popupContent">
                <div className="popupTabCont">
                    <div className="loaderSection">
                        <span className="loadAnimation">
                            <img src={loader} alt="loading" />
                        </span>
                    </div>
                    <div className="alignCenter">
                        We are retrieving the location. Please wait for 30 seconds.
                    </div>
                </div>
            </div>
            <div className="popupBtnWrap flexJCenter">
                <button className="btnStyle" onClick={closePopup} >Close</button>
            </div>
        </div>
    )
}

export default LocateEmployeePopup