import React from 'react'

const WorkOrderDeletePopup = ({ onDeleteSeries, onDeleteCurrent, closePopup }) => {

    return (
        <>
            <div className="popup_wrapper msg_popup del_msg active">
                <div className="sm_modal"  onClick={closePopup}>
                    <div className="msg_del_icon">Delete</div>
                    <div className="msg_content">
                        <div><strong>Are you sure you want to delete this record?</strong>
                            <p>Do you want to delete only this event occurance or the whole series?</p>
                        </div>
                        <button className="close_msg_btn" onClick={closePopup}></button>
                    </div>
                </div>
                <div className="sm_popup_btn">
                    <button className="button_style btn2" onClick={onDeleteSeries}>Delete Series</button>
                    <button className="button_style okBtn" onClick={onDeleteCurrent}>Delete Current Occurence</button>
                </div>
            </div>
        </>
    )
}

export default WorkOrderDeletePopup