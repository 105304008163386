import React from "react";

export const ViewJobsitesAuthorization = ({
  managerDetails,
}) => {
  return (
    <>
      <div className="accHeading">Jobsite Authorization Settings</div>
      <div className="accContent">
        <div className="sectionSubHeading">Authorized Employee Jobsites</div>
        <p>Manager is authorized in the following jobsites</p>
        <ul className="blockCheckbox withoutCheck">
          {managerDetails?.customerList &&
            managerDetails?.customerList.map(
              (ele, index) =>
                managerDetails?.authorizedCustomers.includes(
                  ele.customerID
                ) && (
                  <li key={index}>
                    <div className="customCheck">
                      <label className="customCheckLabel" htmlFor="name1">
                        <span>
                          <em
                            className="groupColor"
                            style={{ background: ele.colour }}
                          >
                            G
                          </em>
                          {ele.customerName === undefined ||
                          ele.customerName === ""
                            ? ele.customerID
                            : ele.customerName}
                        </span>
                      </label>
                    </div>
                  </li>
                )
            )}
        </ul>
      </div>
    </>
  );
};
