import React, { useState } from 'react';
import './SaveTemplatePopup.css'
import icon from '../../../../assets/images/template_icon_buildreport.svg'
import { apiProvider } from '../../../../services/api/provider';
import { reportTableAggregatesText, advancedReportTableAggregatesText } from '../buildReportsutils'
import { trackingReportTableAggregatesText } from '../subReports/TrackingReport/utils'


export const SaveTemplatePopup = ({ setPopUp, dataState, columns, subModule, setMsgClass, setToastMsg, setShowLoader }) => {
    const [templatetName, setTemplateName] = useState()
    const handleClose = () => {
        setPopUp(false);
    };

    const getAggregatetext = (moduleName) => {
        const apiUrls = {
            standardTime: reportTableAggregatesText,
            advanceTimeClockBuildReports: advancedReportTableAggregatesText,
            messageReport: '',
            trackingReport: trackingReportTableAggregatesText,
            WOPerformaceReport: '',
        };

        return apiUrls[moduleName] || 'Invalid module name';
    }

    const submitTemplate = async () => {
        let payload = {
            masterTemplateID: subModule,
            templateName: templatetName,
            templateOptions: {
                columns: columns,
                dataState: dataState,
                aggregatesText: getAggregatetext(subModule),
            }
        }

        if(subModule === 'WOPerformaceReport') {
            payload.templateOptions.masterTemplateType = sessionStorage.getItem('workorderType');
        }
        if (subModule === 'advanceTimeClockBuildReports' || subModule === 'standardTime') {
            const StageId = sessionStorage.getItem('stageid');
            payload.masterTemplateType = StageId;
            payload.templateOptions.masterTemplateType = StageId
        }
        try {
            const response = await apiProvider.post(`track/mobile/v1/allgeo/saveReportTemplate?templateVersition=V3`, payload)
            if (response.StatusCode === 200) {
                setMsgClass("msgSuccess")
                setToastMsg(response.SucessMsg);
            } else {
                setMsgClass("msgError")
                setToastMsg(response.ErrorMsg);
            }

        } catch (error) {
            console.error("API Error:", error);
        } finally {
            handleClose()
        }
    };

    return (
        <>
            <div className=" popupNewClass popupWrap smlPopup active">
                <div className="closePopup" onClick={handleClose} >Close</div>
                <div className="popupHeading">Save Report</div>
                <div className="popupContent">
                    <div className='fldWrap'>
                        <img src={icon} alt="" />
                        <div className='rightside'>
                            <label className='rLevel'>Define name for Report</label>
                            <input autoFocus type="text" value={templatetName} onChange={(e) => setTemplateName(e.target.value)} placeholder="Enter report name" />
                        </div>
                    </div>
                </div>
                <div className="popupBtnWrap">
                    <button className="btnCancle" onClick={handleClose}>Cancel</button>
                    <button className={`btnSave ${templatetName ? '' : 'disabled'}`} onClick={submitTemplate}>Save Report</button>
                </div>
            </div>

            <div className={`overlay active `} />
        </>
    )
}
