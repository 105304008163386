import React, { Fragment, useEffect, useState } from 'react';
import $ from "jquery";
import { useForm } from "react-hook-form";
import { apiProvider } from '../../../services/api/provider';
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { timeZones } from './rulesUtils';
import { ConfirmPopup } from '../../common/PopUp';
import { getAccountLabel } from '../../../utils';

const moment = window.moment;

const RulesEdit = (props) => {

    const { register, handleSubmit, watch, setValue, getValues, reset, formState: { errors, isValid, isDirty } } = useForm({ mode: "all" });
    const [ruleDetails, setRuleDetails] = useState(null);

    const [sectionExpand, setSectionExpand] = useState({
        emailNotify: true, smsNotify: true, inAppNotify: true, webhookNotify: true,
        gcmNotify: true, ivrNotify: true, empEmailNotify: true, ivrAudio:true
    });

    const [statusCodeOption, setStatusCodeOption] = useState([{ key: '', val: '' }]);
    const [deviceMapOption, setDeviceMapOption] = useState([]);
    const [groupMapOption, setGroupMapOption] = useState([]);
    const [showEmployeeId, setShowEmployeeId] = useState(false);
    const [showGroupId, setShowGroupId] = useState(false);
    const [serviceDays, setServiceDays] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [scheduleDetails, setScheduleDetails] = useState({
        isCron: false,
        showEndDateTime: false
    });
    const isAdmin = localStorage.getItem('isAdminUser') || 'false';
    const loggedInAccountId = localStorage.getItem('accountId') || '';
    const watchFrequency = watch("schedule.freq");
    const [scheduleDropDownList, setScheduleDropDownList] = useState([]);
    const [scheduleChange, setScheduleChange] = useState('');
    const [showDialog, setShowDialog] = useState(false);

    const toggleSection = (sectionType) => {
        const selectedSection = sectionExpand[sectionType]
        let sections = { ...sectionExpand };
        sections[sectionType] = !selectedSection;
        let listenersVal = getValues('listener');
        listenersVal[sectionType] = sections[sectionType];
        setValue('listener', listenersVal, { shouldDirty: false });
        setSectionExpand(sections)
    }

    const onListenerChange = (type, event) => {
        let sections = { ...sectionExpand };
        sections[type] = event.target.checked;
        setSectionExpand(sections);
    }

    $(".copyIcon").on("click", function () {
        navigator.clipboard.writeText(props.rulesId);
        $(this).siblings('.copyClipboard').show().delay(4000).fadeOut();
    });

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (isAdmin == 'true' && watchFrequency) {
            fetchCoRelatedDropdown();
        }
    }, [isAdmin, watchFrequency]);

    const fetchCoRelatedDropdown = () => {
        props.setShowLoader(true);
        apiProvider.getAll('track/mobile/v1/ruleScheduleFreq?accountId=' + loggedInAccountId + '&freq=' + watchFrequency).then((response) => {
            setScheduleDropDownList(response);

            if (watch('initialFreq') === +watch('schedule.freq')) {
                if (watch('initialTripId')) {
                    setValue('schedule.tripID', watch('initialTripId'))
                }
            }

        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            props.setShowLoader(false);
        });
    }

    const fetchData = () => {
        props.setShowLoader(true);
        apiProvider.getSingle('/track/mobile/v1/allgeo/rule', props.rulesId).then((response) => {
            if (response.status === 200 && response.message.toLowerCase() === 'success') {

                let sections = { ...sectionExpand };
                let listener = response.rules[0].listener;  

                for (let key in listener) {
                    if (listener.hasOwnProperty(key)) {

                        if (listener[key] == 'Yes') {
                            response.rules[0].listener[key] = true;
                            sections[key] = true;
                        } else {
                            response.rules[0].listener[key] = false;
                            sections[key] = false;
                        }
                    }
                }

                let statusCodeOption = [];
                Object.entries(response.rules[0].codesMap).forEach(entry => {
                    const [key, value] = entry;
                    statusCodeOption.push({ key: key, val: value });
                })
                setStatusCodeOption(statusCodeOption);

                let deviceMapOption = [];
                Object.entries(response.rules[0].deviceMap).forEach(entry => {
                    const [key, value] = entry;
                    deviceMapOption.push({ key: key, val: value });
                })
                setDeviceMapOption(deviceMapOption);

                let groupMapOption = [];
                Object.entries(response.rules[0].groupMap).forEach(entry => {
                    const [key, value] = entry;
                    groupMapOption.push({ key: key, val: value });
                })
                setGroupMapOption(groupMapOption);

                if (response.rules[0].device !== "-" && response.rules[0].group === "-") {
                    setShowEmployeeId(true)
                } else {
                    setShowGroupId(true)
                }

                if (response.rules[0].schedule.startDate && response.rules[0].schedule.startDate != '') {
                    setStartDate(new Date(response.rules[0].schedule.startDate.split('/').join('-')));
                }
                if (response.rules[0].schedule.endDate && response.rules[0].schedule.endDate != '') {
                    setEndDate(response.rules[0].schedule.endDate === 'never' ? null : new Date(response.rules[0].schedule.endDate.split('/').join('-')));
                }

                setServiceDays(response.rules[0].schedule.serviceDays);
                setSectionExpand(sections);

                let rulesDetails = response.rules[0];

                let updatedRulesDetails = {
                    ...rulesDetails,
                    notifyTypeMsgMapList: rulesDetails.notifyTypeMsgMapList.map((ele) => {
                        return { ...ele, msg: rulesDetails.NotificationsTypes[ele.key] }
                    })
                }

                if (response.rules[0].schedule.endDate == 'never' || response.rules[0].schedule.endDate == '') {
                    updatedRulesDetails = {
                        ...updatedRulesDetails,
                        schedule: {
                            ...updatedRulesDetails.schedule,
                            showEndDateTime: false
                        }
                    }
                } else {
                    updatedRulesDetails = {
                        ...updatedRulesDetails,
                        schedule: {
                            ...updatedRulesDetails.schedule,
                            showEndDateTime: true
                        }
                    }
                }
                setValue('disableOption', true)
                setRuleDetails(updatedRulesDetails);

            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            props.setShowLoader(false);
        });

    };

    useEffect(() => {

        if (ruleDetails && Object.keys(ruleDetails).length > 0) {
            setValue('selector', ruleDetails.selector, { shouldDirty: false });
            setValue('cronRule', ruleDetails.cronRule === 'Yes' || watch('cronRule') === true ? true : false, { shouldDirty: false });
            setValue('active', ruleDetails.active == 'Yes' ? true : false, { shouldDirty: false });
            setValue('desc', ruleDetails.desc, { shouldDirty: false });
            setValue('listener', ruleDetails.listener, { shouldDirty: false });
            setValue('ivr', ruleDetails.ivr, { shouldDirty: false });
            setValue('gcm', ruleDetails.gcm, { shouldDirty: false });
            setValue('sms', ruleDetails.sms, { shouldDirty: false });
            setValue('webhook', ruleDetails.webhook, { shouldDirty: false });
            setValue('inApp', ruleDetails.inApp, { shouldDirty: false });
            setValue('empEmail', ruleDetails.empEmail, { shouldDirty: false });
            setValue('email', ruleDetails.email, { shouldDirty: false });
            setValue('code', ruleDetails.code, { shouldDirty: false });
            setValue('device', ruleDetails.device, { shouldDirty: false });
            setValue('group', ruleDetails.group === '-' ? '*' : ruleDetails.group, { shouldDirty: false });
            setValue('checkedOption', ruleDetails.device === '-' ? 'group' : 'device', { shouldDirty: false });
            setValue('schedule', ruleDetails.schedule, { shouldDirty: false });
            setValue('schedule.fromHr', ruleDetails.schedule.fromHr || 1, { shouldDirty: false });
            setValue('schedule.fromMin', ruleDetails.schedule.fromMin || 0, { shouldDirty: false });
            setValue('schedule.toMin', ruleDetails.schedule.toMin || 0, { shouldDirty: false });
            setValue('schedule.toHr', ruleDetails.schedule.toHr || 2, { shouldDirty: false });
            setValue('endDateSelection', ruleDetails?.schedule?.showEndDateTime === true ? 'on' : 'never', { shouldDirty: false });
            setValue('ruleID', ruleDetails.ruleID, { shouldDirty: false });

            if (!watch('initialTripId')) {
                setValue('initialTripId', ruleDetails.schedule.tripID)
                setValue('initialFreq', ruleDetails.schedule.freq)
            } else {
                if (watch('initialFreq') === +watch('schedule.freq')) {
                    setValue('schedule.tripID', watch('initialTripId'))
                } else {
                    setValue('schedule.tripID', ruleDetails.schedule.tripID)
                }
            }

        }

    }, [ruleDetails])

    useEffect(() => {
        const subscribe = watch(data => {
            setScheduleDetails({
                isCron: data.cronRule,
                showEndDateTime: data.endDateSelection === 'never' ? false : true
            })
        })
        return () => subscribe.unsubscribe()
    }, [watch])

    const handleEmployeeChange = (e) => {
        let val = e.target.value;

        if (val === 'group') {
            setShowGroupId(true);
            setShowEmployeeId(false);
        }
        if (val === 'device') {
            setShowEmployeeId(true);
            setShowGroupId(false);
        }

    }

    const onSubmit = async (data) => {

        //change the listener values
        let listener = data.listener;
        for (let key in listener) {
            if (listener.hasOwnProperty(key)) {

                if (listener[key] === true) {
                    data.listener[key] = 'Yes';
                } else {
                    data.listener[key] = 'No';
                }
            }
        }

        //change group values
        if (data.checkedOption == 'group') {
            data.device = '-';
        } else {
            data.group = '-';
        }

        //set start and end date
        if (startDate) {
            let validateDate = checkValidDate(startDate);
            if (validateDate) {
                let dt = new Date(startDate);
                let userTimezoneOffset = dt.getTimezoneOffset() * 60000;
                let parsedDate = new Date(dt.getTime() - userTimezoneOffset);
                data.schedule.startDate = parsedDate.toISOString().split('T')[0];
                data.schedule.startDate = data.schedule.startDate.split('-').join('/');
            }
        }

        if (endDate) {
            let validateDate = checkValidDate(endDate);
            if (validateDate) {
                let dt = new Date(endDate);
                let userTimezoneOffset = dt.getTimezoneOffset() * 60000;
                let parsedDate = new Date(dt.getTime() - userTimezoneOffset);
                data.schedule.endDate = parsedDate.toISOString().split('T')[0];
                data.schedule.endDate = data.schedule.endDate.split('-').join('/');
            }
        }
        data.cronRule = data.cronRule === true ? 'Yes' : 'No';
        data.active = data.active === true ? 'Yes' : 'No';



        if (data.endDateSelection == 'never') {
            data.schedule.endDate = 'never';
        }

        data.schedule.freq = Number(data.schedule.freq);

        data.schedule.fromHr = data.schedule.fromHr + "" || "00"
        data.schedule.fromMin = data.schedule.fromMin || "00"

        data.schedule.toHr = data.schedule.toHr + "" || "00"
        data.schedule.toMin = data.schedule.toMin || "00"

        const invalidTime = data.schedule.fromHr === "00" && data.schedule.toHr === "00" && data.schedule.fromMin === "00" && data.schedule.toMin === "00";

        if (data.cronRule === 'Yes' && data.schedule.serviceDays.every((ele) => ele.selected === false)) {
            props.setToastMsg({ msg: "At least one day needs to be selected", type: "" });
        } else if (data.cronRule === 'Yes' && invalidTime) {
            props.setToastMsg({ msg: "Start time or end time cannot be zero", type: "" });
        } else if (data.cronRule === 'Yes' && (moment(data.schedule.startDate).isAfter(data.schedule.endDate))) {
            props.setToastMsg({ msg: "Start Date can not be ahead of end date", type: "" });
        } else {
            props.setShowLoader(true);
            try {
                const response = await apiProvider.put("track/mobile/v1/allgeo/rule", [data]);
                props.setShowLoader(false);
                if (response.status === 200) {
                    props.setMode({
                        title: "Schedule",
                        type: 'main',
                        data: null,
                        hasPrev: false
                    })
                    props.setToastMsg({ msg: "The changes have been saved successfully", type: "msgSuccess" });
                } else {
                    props.setToastMsg({ msg: "Could not able to save the changes.", type: "msgError" });
                }
            } catch (error) {
                console.log(error);
                props.setShowLoader(false);
            }
        }
    };

    const formCancelEvent = () => {
        if (isDirty) {
            setShowDialog(true)
        } else {
            props.setMode({
                title: "Schedule",
                type: 'main',
                data: props.mode.data,
                hasPrev: false
            })
        }
    };

    const handleServiceDayChange = (daySelection) => {
        let newServiceDays = [];
        serviceDays.forEach(day => {
            if (day.day == daySelection) {
                day.selected = !day.selected
            }
            newServiceDays.push(day);
        });
        setServiceDays(newServiceDays);
        let scheduleVal = getValues('schedule');
        scheduleVal.serviceDays = newServiceDays;
        setValue('schedule', scheduleVal, { shouldDirty: true });
    };

    const handleStartTimeChange = (event) => {
        setStartDate(event.target.value);
    };

    const handleEndTimeChange = (event) => {
        setEndDate(event.target.value);
    };

    const checkValidDate = (dateVal) => {
        if (dateVal) {
            const timestamp = Date.parse(dateVal);
            return !isNaN(timestamp);
        } else {
            return false;
        }
    };

    const handleEndDateSettings = (val) => {
        setValue('endDateSelection', val);
    }

    const changeSchedule = (e) => {
        setValue('disableOption', false)
        setScheduleChange(e.target.value);
    }

    useEffect(() => {
        if (scheduleChange != '') {
            fetchCoRelatedSchedule();
        }
    }, [scheduleChange]);

    const fetchCoRelatedSchedule = () => {

        props.setShowLoader(true);
        apiProvider.getAll('track/mobile/v1/ruleScheduleInfo?accountId=' + loggedInAccountId + '&tripID=' + scheduleChange).then((response) => {

            if (response.StatusCode == 200) {
                if (response.schedule.startDate && response.schedule.startDate != '') {
                    setStartDate(new Date(response.schedule.startDate.split('/').join('-')));
                }
                if (response.schedule.endDate && response.schedule.endDate != '') {
                    setEndDate(response.schedule.endDate == 'never' ? null : new Date(response.schedule.endDate.split('/').join('-')));
                }
                setServiceDays(response.schedule.serviceDays);
                if (response.schedule.endDate == 'never') {
                    setRuleDetails({
                        ...ruleDetails,
                        schedule: {
                            ...response.schedule,
                            showEndDateTime: false
                        }
                    })
                } else {
                    setRuleDetails({
                        ...ruleDetails,
                        schedule: {
                            ...response.schedule,
                            showEndDateTime: true
                        }
                    })
                }
                setValue('schedule', response.schedule, { shouldDirty: true });
                setValue('schedule.fromHr', response.schedule.fromHr, { shouldDirty: true });
                setValue('schedule.fromMin', response.schedule.fromMin, { shouldDirty: true });
                setValue('schedule.toMin', response.schedule.toMin, { shouldDirty: true });
                setValue('schedule.toHr', response.schedule.toHr, { shouldDirty: true });
                setValue('schedule.timezone', response.schedule.timezone, { shouldDirty: true });
                setValue('schedule.tripID', response.schedule.tripID, { shouldDirty: true });
                setValue('schedule.tripNo', response.schedule.tripNo, { shouldDirty: true });
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            props.setShowLoader(false);
        });
    }

    const onSuccess = () => {
        setShowDialog(false)
        props.setMode({
            title: "Schedule",
            type: 'main',
            data: props.mode.data,
            hasPrev: false
        })
    }

    const onFaliure = () => {
        setShowDialog(false)
        props.setShowLoader(true);
        reset();
        fetchData();
    }

    const isTimeValid = () => {
        return Number(watch("schedule.fromHr")) < Number(watch("schedule.toHr")) ||
            (Number(watch("schedule.fromHr")) === Number(watch("schedule.toHr")) && Number(watch("schedule.fromMin")) < Number(watch("schedule.toMin")))
    }

    function handleIvrAudioChange(event) {
        setValue('ivr.ivrRecording', event.target.checked);
      }

    const employeeLabel = getAccountLabel('employee');
    const groupLabel = getAccountLabel('group');

    return (
        <Fragment>
            <form onSubmit={handleSubmit(onSubmit)} id="settingsForm">
                <section>
                    <h3>Schedule Information</h3>
                    <div className="supportedTxt">
                        Update your account information including who should receive notification
                        emails and your preferred timezone
                    </div>
                    <div className="innerWrap">
                        <div className="highlightedFldWrap">
                            <div className="fldWrap">
                                <label>Rule ID</label>
                                <div className="highlightedValue">
                                    <span>{props.rulesId}</span>
                                    <span className="copyIcon">Copy</span>
                                    <span className="copyClipboard">Copied to the clipboard!</span>
                                </div>
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className="colBlk4 fldWrap">
                                <label>Time-based Rule</label>
                                <div className="customCheck yesNo">
                                    <input type="checkbox" id="cronRule" {...register('cronRule')} />
                                    <label className="customCheckLabel" htmlFor="cronRule">
                                        yesNo
                                    </label>
                                </div>
                            </div>
                            <div className="colBlk4 fldWrap">
                                <label>Active</label>
                                <div className="customCheck yesNo">
                                    <input type="checkbox" id="activeEmployee" {...register('active')} />
                                    <label className="customCheckLabel" htmlFor="activeEmployee">
                                        yesNo
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className="colBlk6 fldWrap">
                                <label>Description</label>
                                <div className="withInputSub">
                                    <input type="text" {...register('desc')} />
                                </div>
                            </div>
                            {
                                scheduleDetails.isCron &&
                                <div className="colBlk4 fldWrap">
                                    <label>Timezone</label>
                                    <select {...register('schedule.timezone')} >
                                        {
                                            [{ value: "Account", label: "Account" }, { value: "Device", label: "Device" }, ...timeZones].map((t, ind) => (
                                                <option key={ind} value={t.value}>{t.label}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            }
                        </div>
                        {
                            scheduleDetails.isCron &&
                            <Fragment>
                                <div className="rowBlk">
                                    <div className="colBlk3">
                                        <div className="fldWrap">
                                            <label>Start Date</label>
                                            <div>
                                                <DatePicker
                                                    value={startDate}
                                                    format="yyyy/MM/dd"
                                                    weekNumber={true}
                                                    onChange={handleStartTimeChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="colBlk4 leftGap3 fldWrap">
                                        <label>Ends</label>
                                        <div className="singleLineFld">
                                            <select {...register('endDateSelection')} className="smlFld" onChange={e => handleEndDateSettings(e.target.value)}>

                                                <option value="on">On</option>
                                                <option value="never">Never</option>
                                            </select>
                                            {
                                                scheduleDetails.showEndDateTime &&
                                                <DatePicker
                                                    value={endDate}
                                                    format="yyyy/MM/dd"
                                                    weekNumber={true}
                                                    onChange={handleEndTimeChange}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="rowBlk" id="cronrulesDiv">
                                    <div className="colBlk12">
                                        <div className="fldWrap">
                                            <label>Selected Days</label>
                                            <div className="weekDays">
                                                {
                                                    serviceDays.map((item, index) => {
                                                        return <span key={item.day} onClick={() => { handleServiceDayChange(item.day) }} className={item.selected ? 'active' : ''} >{item.day.charAt(0)}</span>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="rowBlk">
                                    <div className="colBlk3">
                                        <div className="fldWrap">
                                            <label>Start Time</label>
                                            <div className="numberFldWrap">
                                                <input type="number" min="0" max="24"
                                                    {...register('schedule.fromHr', {
                                                        validate: isTimeValid
                                                    })}
                                                />
                                                <span>hrs</span>
                                                <span className="timeSep">:</span>
                                                <input type="number" min="0" max="59" {...register('schedule.fromMin', {
                                                    validate: isTimeValid
                                                })} />
                                                <span>mins</span>
                                            </div>
                                            {!isTimeValid()
                                                && (
                                                    <div className="fldMsg">
                                                        Start time must be lesser than End time
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                    <div className="colBlk3">
                                        <div className="fldWrap">
                                            <label>End Time</label>
                                            <div className="numberFldWrap">
                                                <input type="number" min="0" max="24" {...register('schedule.toHr', {
                                                    validate: isTimeValid
                                                })} />
                                                <span>hrs</span>
                                                <span className="timeSep">:</span>
                                                <input type="number" min="0" max="60" {...register('schedule.toMin', {
                                                    validate: isTimeValid
                                                })} />
                                                <span>mins</span>
                                            </div>
                                            {!isTimeValid() && (
                                                <div className="fldMsg">
                                                    End time must be greater than Start time
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="rowBlk">
                                    <div className="colBlk3">
                                        <div className="fldWrap">
                                            <label>Frequency </label>
                                            <div className="fldWithSubTxt">
                                                <span>Every</span>
                                                <select className="smlFld" {...register('schedule.freq', { onChange: () => setValue('disableOption', false) })}>
                                                    <option value="300">5 Minutes</option>
                                                    <option value="900"> 15 Minutes </option>
                                                    <option value="1800"> 30 Minutes </option>
                                                    <option value="3600"> 1 Hour </option>
                                                    <option value="7200"> 2 Hour </option>
                                                    <option value="14400"> 4 Hour </option>
                                                </select>
                                                <span>mins</span>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        (isAdmin == 'true') &&
                                        <div className="colBlk4">
                                            <div className="fldWrap">
                                                <label>Schedule </label>
                                                <select className="smlFld" onChange={changeSchedule} value={watch('schedule.tripID')}  >
                                                    {
                                                        scheduleDropDownList.map((listItem) => {
                                                            return <option disabled={watch('disableOption') && listItem.optionSel} value={listItem.tripId} key={listItem.tripId}> {listItem.tripNo}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </Fragment>
                        }
                    </div>
                </section>
                <section>
                    <h3>{employeeLabel} / {groupLabel} Code Selection</h3>
                    <div className="innerWrap">
                        <div className="rowBlk">
                            <div className="colBlk12 fldWrap">
                                <div className="customControls">
                                    <div className="customRadio">
                                        <input
                                            type="radio"
                                            id="employeesID"
                                            value="device"
                                            {...register('checkedOption')}
                                            onChange={handleEmployeeChange}
                                            checked={showEmployeeId}

                                        />
                                        <label htmlFor="employeesID">{employeeLabel} ID</label>
                                    </div>

                                    <div className="customRadio">
                                        <input type="radio"
                                            id="groupid"
                                            value="group"
                                            {...register('checkedOption')}
                                            onChange={handleEmployeeChange}
                                            checked={showGroupId}

                                        />
                                        <label htmlFor="groupid">{groupLabel} ID</label>
                                    </div>
                                </div>
                            </div>
                            {
                                showEmployeeId &&
                                <div className="colBlk4 fldWrap" id="employeesDiv">
                                    <label>{employeeLabel} ID</label>
                                    <select {...register('device')}>
                                        {
                                            deviceMapOption.map((entry, ind) => (
                                                entry.key !== '-'
                                                    ? <option key={entry.key} value={entry.key}>{entry.val}</option>
                                                    : ''
                                            ))
                                        }
                                    </select>
                                </div>
                            }
                            {
                                showGroupId &&
                                <div className="colBlk4 fldWrap">
                                    <label>{groupLabel} ID</label>
                                    <select {...register('group')}>
                                        {
                                            groupMapOption.map((entry, ind) => (
                                                entry.key !== '-'
                                                    ? <option key={entry.key} value={entry.key}>{entry.val}</option>
                                                    : ''
                                            ))
                                        }
                                    </select>
                                </div>
                            }
                            <div className="colBlk4 fldWrap">
                                <label>Status Code</label>
                                <select {...register('code')}>
                                    {
                                        statusCodeOption.map((entry, ind) => (
                                            <option key={entry.key} value={entry.key}>{entry.val}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <h3>Rule Settings</h3>
                    <div className="innerWrap">
                        <div className="fldWrap">
                            <label>Rule Selector</label>
                            <input type="text" {...register('selector')} />
                        </div>
                        <div className="fldWrap">
                            <label>Trigger Action</label>
                        </div>
                        <div className="rowBlk">
                            <div className="colBlk3 customCheck">
                                <input type="checkbox" id="emailNotify" onClick={(e) => onListenerChange('emailNotify', e)} {...register('listener.emailNotify')} />
                                <label htmlFor="emailNotify">Email</label>
                            </div>
                            <div className="colBlk3 customCheck">
                                <input type="checkbox" id="employeemail" onClick={(e) => onListenerChange('empEmailNotify', e)} {...register('listener.empEmailNotify')} />
                                <label htmlFor="employeemail">{employeeLabel} Email</label>
                            </div>
                            <div className="colBlk3 customCheck">
                                <input type="checkbox" id="inapp" onClick={(e) => onListenerChange('inAppNotify', e)} {...register('listener.inAppNotify')} />
                                <label htmlFor="inapp">InApp</label>
                            </div>
                            <div className="colBlk3 customCheck">
                                <input type="checkbox" id="webhooks" onClick={(e) => onListenerChange('webhookNotify', e)} {...register('listener.webhookNotify')} />
                                <label htmlFor="webhooks">Webhooks</label>
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className="colBlk3 customCheck">
                                <input type="checkbox" id="sms" onClick={(e) => onListenerChange('smsNotify', e)} {...register('listener.smsNotify')} />
                                <label htmlFor="sms">SMS</label>
                            </div>
                            <div className="colBlk3 customCheck">
                                <input type="checkbox" id="gcm" onClick={(e) => onListenerChange('gcmNotify', e)} {...register('listener.gcmNotify')} />
                                <label htmlFor="gcm">GCM</label>
                            </div>
                            <div className="colBlk3 customCheck">
                                <input type="checkbox" id="ivr" name="group" onClick={(e) => toggleSection('ivrNotify')} {...register('listener.ivrNotify')} />
                                <label htmlFor="ivr">IVR</label>
                            </div>
                        </div>
                        <ul className="accordionList topGap1">
                            <li id="email" className={`${sectionExpand.emailNotify && 'active'}`} >
                                <div className="accHeading" onClick={() => toggleSection('emailNotify')}>Email Settings</div>
                                <div className="accContent">
                                    <div className="innerWrap">
                                        <div className="rowBlk">
                                            <div className={`colBlk6 fldWrap${errors.email ? ' errFld' : ''}`}>
                                                <label>Notification Email</label>
                                                <input type="text" {...register('email.email', {
                                                    pattern: /\S+@\S+\.\S+/
                                                })} />
                                                {errors.email && <div className="fldMsg">Please enter a valid email</div>}
                                            </div>
                                            <div className="colBlk6 fldWrap">
                                                <label>Email Subject</label>
                                                <input type="text" {...register('email.subj')} />
                                            </div>
                                        </div>
                                        <div className="rowBlk">
                                            <div className="colBlk6 fldWrap">
                                                <label>Email Message</label>
                                                <textarea {...register('email.body')} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li id="employee" className={`${sectionExpand.empEmailNotify && 'active'}`} >
                                <div className="accHeading" onClick={() => toggleSection('empEmailNotify')}>{employeeLabel} Email Settings</div>
                                <div className="accContent">
                                    <div className="innerWrap">
                                        <div className="rowBlk">
                                            <div className={`colBlk6 fldWrap${errors.empEmail ? ' errFld' : ''}`}>
                                                <label>Notification Email</label>
                                                <input type="text" {...register('empEmail.email', {
                                                    pattern: /\S+@\S+\.\S+/
                                                })} />
                                                {errors.empEmail && <div className="fldMsg">Please enter a valid email</div>}
                                            </div>
                                            <div className="colBlk6 fldWrap">
                                                <label>Email Subject</label>
                                                <input type="text" {...register('empEmail.subj')} />
                                            </div>
                                        </div>
                                        <div className="rowBlk">
                                            <div className="colBlk6 fldWrap">
                                                <label>Email Message</label>
                                                <textarea {...register('empEmail.body')} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li id="inApp" className={`${sectionExpand.inAppNotify && 'active'}`}>
                                <div className="accHeading" onClick={() => toggleSection('inAppNotify')}>InApp Settings</div>
                                <div className="accContent">
                                    <div className="innerWrap">
                                        <div className="rowBlk">
                                            <div className="colBlk6 fldWrap">
                                                <label>InApp Phone Number</label>
                                                <input type="text" {...register('inApp.phone')} />
                                            </div>
                                            <div className="colBlk6 fldWrap">
                                                <label>InApp Message</label>
                                                <textarea {...register('inApp.msg')} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li id="webHook" className={`${sectionExpand.webhookNotify && 'active'}`} >
                                <div className="accHeading" onClick={() => toggleSection('webhookNotify')}>Webhook Settings</div>
                                <div className="accContent">
                                    <div className="innerWrap">
                                        <div className="rowBlk">
                                            <div className="colBlk6 fldWrap">
                                                <label>Webhook URL</label>
                                                <input type="text" {...register('webhook.webhookUrl')} />
                                            </div>
                                            <div className="colBlk6 fldWrap">
                                                <label>Webhook Data</label>
                                                <input type="text" {...register('webhook.msg')} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li id="sms" className={`${sectionExpand.smsNotify && 'active'}`} >
                                <div className="accHeading" onClick={() => toggleSection('smsNotify')}>SMS Settings</div>
                                <div className="accContent">
                                    <div className="innerWrap">
                                        <div className="rowBlk">
                                            <div className="colBlk6 fldWrap">
                                                <label>SMS Phone Number</label>
                                                <input type="text" {...register('sms.phone')} />
                                            </div>
                                            <div className="colBlk6 fldWrap">
                                                <label>SMS Message</label>
                                                <textarea {...register('sms.msg')} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li id="gcm" className={`${sectionExpand.gcmNotify && 'active'}`} >
                                <div className="accHeading" onClick={() => toggleSection('gcmNotify')}>GCM Settings</div>
                                <div className="accContent">
                                    <div className="innerWrap">
                                        <div className="rowBlk">
                                            <div className="colBlk6 fldWrap">
                                                <label>GCM Phone Number</label>
                                                <input type="text" {...register('gcm.phone')} />
                                            </div>
                                            <div className="colBlk6 fldWrap">
                                                <label>Message Title</label>
                                                <input type="text" {...register('gcm.title')} />
                                            </div>
                                        </div>
                                        <div className="rowBlk">
                                            <div className="colBlk6 fldWrap">
                                                <label>Notification Type</label>
                                                <select {...register('gcm.type', {
                                                    onChange: (e) => {
                                                        setValue('gcm.msg', ruleDetails?.notifyTypeMsgMapList.filter((ele) => ele.key === e.target.value)[0].desc)
                                                    }
                                                })}>
                                                    {
                                                        ruleDetails?.notifyTypeMsgMapList.map((t, ind) => (
                                                            <option key={ind} value={t.key}>{t.msg}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <div className="colBlk6 fldWrap">
                                                <label>GCM Message</label>
                                                <textarea {...register('gcm.msg')} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li id="ivr" className={`${sectionExpand.ivrNotify && 'active'}`} >
                                <div className="accHeading" onClick={() => toggleSection('ivrNotify')}>IVR Settings</div>
                                <div className="accContent">
                                    <div className="innerWrap">
                                        <div className="rowBlk">
                                            <div className="colBlk6 fldWrap">
                                                <label>IVR Phone Number</label>
                                                <input type="text" {...register('ivr.phone')} />
                                            </div>
                                            <div className="colBlk6 fldWrap">
                                                <label>Message Script Type</label>
                                                <select {...register('ivr.type')}>
                                                    <option value="Custom">Custom Script</option>
                                                    <option value="Default">Default Script</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="rowBlk">
                                            <div className="colBlk6 fldWrap">
                                                <label>IVR Message</label>
                                                <textarea {...register('ivr.msg')} />
                                            </div>
                                            <div className="colBlk6 customCheck">
                                                <input type="checkbox" id="ivraudio" {...register('ivr.ivrRecording')}  name="group" onChange={handleIvrAudioChange} />
                                                <label htmlFor="ivraudio">Record IVR Audio</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </section>
                <div className="mainBtnWrap">
                    <a onClick={formCancelEvent}>Cancel</a>
                    <button className="btnStyle" disabled={!isValid}>Save</button>
                </div>
            </form>
            <ConfirmPopup
                showDialog={showDialog}
                message={"The changes are still unsaved. Are you sure you want to leave?"}
                confirmNavigation={onSuccess}
                cancelNavigation={onFaliure}
            />
        </Fragment>
    )
}

export default RulesEdit