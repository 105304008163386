import React, { useState, useEffect } from "react";
import { Tooltip } from "@progress/kendo-react-tooltip";
// import './c.css'
export const TooltipKendo = ({
  title = "",
  tooltipText,
  children,
  margin = 0,
}) => {
  return (
    <span className="displayFlex">
      <Tooltip
        anchorElement="target"
        parentTitle={true}
        tooltipStyle={{ borderRadius: "6px", fontSize: 12, lineHeight: "20px" }}
        position="bottom"
        // style={{whiteSpace:"pre"}}
      >
        <span className="displayFlex">{children}</span>
      </Tooltip>
    </span>
  );
};
