import React from "react";

const DeleteSuspendModal = ({
  msg = "",
  onSuccess,
  closePopup,
  heading,
  selectedDeleteItem = "",
}) => {
  return (
    <div>
        <div className="overlayemployee"></div>
      <div
        className={`popupWrap msgPopup ${
          heading === "Suspend" || heading === "Delete"
            ? "suspendMsg"
            : "successMsg"
        } active`}
      >
        <div className="msgPopupCont">
          <div
            className={`msgIcon ${
              heading === "Suspend" || heading === "Delete"
                ? "msgSuspend"
                : "msgActivate"
            }`}
          >
            Suspend
          </div>
          <div className="msgContent">
            {heading === "Suspend"||heading === "Activate"&&
            msg.trim().length > 0 &&
            selectedDeleteItem?.employeeName ? (
              <>
                {msg}
                {selectedDeleteItem?.employeeName&&<p style={{fontWeight:600}}>{"Device :" + selectedDeleteItem?.employeeName}</p>}
              </>
            ) : (
             msg
            )}
          </div>
        </div>
        <div className="popupMsgBtn">
          <a onClick={closePopup} className="okBtn">
            Cancel
          </a>
          <button className="btnStyle okBtn" onClick={onSuccess}>
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteSuspendModal;
