import React, { useState, Fragment, useRef, useEffect } from 'react';
import KendoGrid from '../../common/kendo/KendoGrid';
import { getAccessLevel } from '../../common/functions';
import { monitorEmployeesColumns, titleMap } from './monitorUtils'
import { apiProvider } from '../../../services/api/provider';
import SiteLoader from '../../common/SiteLoader';
import AddNewPopup from '../../company/Employees/AddNewPopup'
import ActionPopup from '../../company/Employees/ActionPopup';
import FileUploadFail from '../../common/FileUploadFail';
import JobStatus from './JobStatus';
import ActivateSuspend from './ActivateSuspend';
import MessagePopup from './MessagePopup';
import { RenameDevice } from './RenameDevice';
import ToastMessage from '../../common/ToastMessage';
import useGeneralSettings from '../../../hooks/useGeneralSettings';
import { getGroupsData } from '../../common/apiFunctions';
import OptinPopup from './OptinPopup';
import LocateEmployeePopup from './LocateEmployeePopup';
import TextPopup from "./TextPopup";
import { getMenuItems } from "../../common/functions"
import { getAccountLabel } from '../../../utils';
import { G2ReviewPopup } from '../../common/G2ReviewPopup/G2ReviewPopup';
import { ALLOWED_ACCOUNTS_FOR_G2_REVIEW } from '../../common/G2ReviewPopup/G2Utils/constants'

const employeeLabel = getAccountLabel('employee');
const groupLabel = getAccountLabel('group');


const defaultMode = {
  title: `Monitor ${employeeLabel}`,
  type: 'main',
  data: null,
  hasPrev: false
}



const MonitorEmployees = () => {

  const childAccountID = localStorage.getItem('childAccountId')

  const { timezone, nomenclature } = useGeneralSettings()

  const [monitorEmployeesData, setMonitorEmployeesData] = useState(null)
  const [accessLevel, setAccessLevel] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [showAddNewPopup, setShowAddNewPopup] = useState(false);
  const [showtextPopup, setShowTextPopup] = useState(false);
  const [mode, setMode] = useState(defaultMode)
  const [toastMsg, setToastMsg] = useState({
    msg: "",
    type: "msgSuccess"
  })
  const [actionPopup, setActionPopup] = useState({
    show: false,
    data: null,
    msg: ""
  })
  const [dropdowndata, setDropdowndata] = useState([])
  const [timeclockStage, setTimeclockStage] = useState([])
  const [msgTemplates, setMsgTemplates] = useState([])
  const [columns, setColumns] = useState(monitorEmployeesColumns)

  const childRef = useRef()

  const fetchData = async () => {
    try {
      setShowLoader(true)
      const queryParams = {
        searchBy: "all",
        searchValue: "all",
        parentAcctID: JSON.parse(localStorage.getItem('parent'))?.accountId || '',
        childAccountID: localStorage.getItem('childAccountId') || localStorage.getItem('accountId')
      }
      const response = await apiProvider.post('/track/react/v1/allgeo/monitor', queryParams)
      const generalSettingRes = await apiProvider.getAll('track/react/account/generalSetting')

      const groupLabel = getAccountLabel('group');
      const employeeLabel = getAccountLabel('employee');

      const devicedescIndex = columns.findIndex(column => column.field === "devicedesc");
      const deviceIdIndex = columns.findIndex(column => column.field === "deviceId");

    
      if (devicedescIndex !== -1 || deviceIdIndex !== -1) {
        const updatedColumns = [...columns]; 
      
        if (devicedescIndex !== -1) {
          updatedColumns[devicedescIndex] = {
            ...updatedColumns[devicedescIndex],
            title: `${employeeLabel} Name and ${groupLabel}`,
          };
        }
      
        if (deviceIdIndex !== -1) {
          updatedColumns[deviceIdIndex] = {
            ...updatedColumns[deviceIdIndex],
            title: `${employeeLabel} Number`,
          };
        }
      
        setColumns(updatedColumns);
      }

      titleMap.devicedesc = `${employeeLabel} Name and ${groupLabel}`;
      titleMap.deviceId = `${employeeLabel} Number`;

      const groups = await getGroupsData()
      let jobStage = await apiProvider.getAll('track/react/account/timeclockSettings')
      const messageTemplateResponse = await apiProvider.getAll('/track/react/v1/allgeo/getMessageTemplates');

      const messageTemplates = {}

      messageTemplateResponse?.data?.forEach(template => {
        const key = Object.keys(template)[0]
        const value = template[key]
        messageTemplates[value] = key
      })

      setMsgTemplates(messageTemplates)

      jobStage = jobStage?.filter(job => job.stage_name?.trim().length > 0).map(job => ({ value: `JobStatus:${job.stage_id}`, label: job.stage_name }))
      setTimeclockStage(jobStage)

      const options = [
        { label: 'Active Devices', value: 'all:all' },
        { label: 'Tracking: Active', value: 'Tracking:Active' },
        { label: 'Tracking: Inactive', value: 'Tracking:Inactive' },
        { label: 'Suspended Devices', value: 'ActiveStatus:Suspended' },
      ]

      setDropdowndata([
        ...options,
        { label: 'Job Status: ', value: '', disabled: true },
        ...jobStage,
        { label: 'Groups: ', value: '', disabled: true },
        ...groups.map(g => ({ value: `Group:${g.value}`, label: g.label })),
      ])

      if (childAccountID === 'all') {

        const allAccountColumn = {
          title: "AccountID",
          field: "paccountId",
          editable: false,
          width: 130,
          editor: "text",
          columnMenu: false,
          show: true,
          filter: "text",
        }

        setColumns([allAccountColumn, ...columns])
      }

      if (response?.status === 200) {
        if (response?.data?.length === 0) {
          let {isSuspendedDevicePresent} = response
          let menuItems = await getMenuItems();
          const monitorAccesslevel = menuItems.find(item => item.name === "monitor") ? menuItems.find(item => item.name === "monitor").accessLevel : null;
          setMonitorEmployeesData([]);
          if (monitorAccesslevel == 3 && !isSuspendedDevicePresent ) {
            setShowTextPopup(true);
          }
        } else {

          response.data.forEach((item) => {
            // adding  scheduleStatus key in response it is uesed in filtering schedule status column in table
            if (item.trackingStatus.toLowerCase() === 'yes') {
              item.scheduleStatus = `Active` + item.scheduleTime
            } else {
              item.scheduleStatus = `Inactive`
            }
          })
         
          setMonitorEmployeesData(response.data);
        }
      } else {
        setMonitorEmployeesData([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setShowLoader(false)
    }
  }

  const actionHandler = (type, data) => {
    setMode({
      ...mode,
      type,
      data
    })
  }

  const openPopup = (key, val) => {
    switch (key) {
      case "addNew":
        setShowAddNewPopup(true);
        break;
      case "map":
        setMode({
          ...mode,
          type: 'map',
          data: val
        })
        break;
      case "message":
        setMode({
          ...mode,
          type: 'message',
          data: val
        })
        break;
      case "renameDevice":
        setMode({
          ...mode,
          type: 'renameDevice',
          data: val
        })
        break;
      case "bulkSuspend":
        setMode({
          ...mode,
          type: 'suspend',
          data: val
        })
        break;
      case "bulkActivate":
        setMode({
          ...mode,
          type: 'activate',
          data: val
        })
        break;
      case "optIn":
        setMode({
          ...mode,
          type: 'optIn',
          data: val
        })
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    getAccessLevel('monitor', '').then((response) => {
      response.childMenu = response?.parentMenu
      setAccessLevel(response);
    });

    fetchData()
  }, [])

  const deleteDevices = async data => {
    try {
      const response = await apiProvider.remove(`/track/mobile/v1/allgeo/devices/${data.deviceId}`)
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  const deleteMultipleEmployee = async data => {
    try {
      setShowLoader(true)
      const response = await apiProvider.removeObject("/track/mobile/v1/allgeo/devices/delete", data)
      return response
    } catch (error) {
      console.log(error);
    } finally {
      setShowLoader(false)
    }
  }

  useEffect(() => {
    if (toastMsg.msg) {
      if (toastMsg.type === "msgSuccess" && childRef.current) {
        childRef.current.refreshData()
      }
      setShowLoader(false)
    }
  }, [toastMsg])

  const queryParams = {
    searchBy: "all",
    searchValue: "all",
    parentAcctID: JSON.parse(localStorage.getItem('parent'))?.accountId || '',
    childAccountID: localStorage.getItem('childAccountId') || localStorage.getItem('accountId')
  }

  const [showG2Popup, setShowG2Popup] = useState(false);

  const sysAdminG2Enbled = JSON.parse(localStorage.getItem('sysAdminG2Enabled')); 
  const storedItem = localStorage.getItem('hasG2Reviewed');
  const hasG2Reviewed = (storedItem !== 'undefined' && storedItem !== null) ? JSON.parse(storedItem) : null;
  let isAdminLogin = JSON.parse(localStorage.getItem('isAdminLogin'));
  const doNotShowG2 = JSON.parse(localStorage.getItem('g2ReviewInactive'));
  const showG2Review = !isAdminLogin && !hasG2Reviewed && !doNotShowG2 && showG2Popup && sysAdminG2Enbled;

  return (
    <Fragment>
      {showG2Review && <G2ReviewPopup showPopup={showG2Popup} setShowPopup={setShowG2Popup}/>}
      {
        monitorEmployeesData &&
        <KendoGrid
          gridData={monitorEmployeesData}
          setGridData={setMonitorEmployeesData}
          columnData={columns}
          accessLevel={accessLevel}
          ref={childRef}
          module={"monitorEmployees"}
          getAPiUrl={'/track/react/v1/allgeo/monitor'}
          requestParams={queryParams}
          openPopup={openPopup}
          deleteItem={deleteDevices}
          lockActionColumn={true}
          filterable={false}
          groupable={false}
          actionColumnHandler={actionHandler}
          bulkDelete={deleteMultipleEmployee}
          otherData={{
            hideSaveCancelChanges: true,
            disableExportToExcel: true,
            actionColumnBtns: ['jobChange', 'locate', 'suspend', 'delete'],
            dropdownList: dropdowndata,
            dropDownData: "all:all",
            searchPlaceholder: 'Search Name or Phone#'
          }}
        />
      }
      {showtextPopup && <TextPopup accountId={queryParams.childAccountID} closePopup={() => setShowTextPopup(false)} openAddPopup={() => setShowAddNewPopup(true)} />}
      {showAddNewPopup && <AddNewPopup setShowG2Popup={setShowG2Popup} timeZone={timezone} setToastMsg={setToastMsg} setShowLoader={setShowLoader} closePopup={() => setShowAddNewPopup(false)} nomenclature={nomenclature} setActionPopup={setActionPopup} setMode={setMode} />}
      {actionPopup.show && <ActionPopup actionPopup={actionPopup} setActionPopup={setActionPopup} setToastMsg={setToastMsg} setShowLoader={setShowLoader} />}
      {mode.type === 'uploadError' &&
        <FileUploadFail data={mode.data} closePopup={() => setMode({ ...mode, type: 'main', data: null, hasPrev: false })} setToastMsg={setToastMsg} setShowLoader={setShowLoader} />
      }

      {mode.type === 'jobChange' && <JobStatus timeclockStage={timeclockStage} data={mode.data} closePopup={() => setMode({ ...defaultMode })} setToastMsg={setToastMsg} setShowLoader={setShowLoader} />}
      {['suspend', 'activate'].includes(mode.type) && <ActivateSuspend type={mode.type} data={mode.data} closePopup={() => setMode({ ...defaultMode })} setToastMsg={setToastMsg} setShowLoader={setShowLoader} />}
      {mode.type === 'message' && <MessagePopup data={mode.data} messageTemplates={msgTemplates} closePopup={() => setMode({ ...defaultMode })} setToastMsg={setToastMsg} setShowLoader={setShowLoader} />}
      {mode.type === 'renameDevice' && <RenameDevice data={mode.data} closePopup={() => setMode({ ...defaultMode })} setToastMsg={setToastMsg} setShowLoader={setShowLoader} />}
      {mode.type === 'optIn' && <OptinPopup data={mode.data} closePopup={() => setMode({ ...defaultMode })} setToastMsg={setToastMsg} setShowLoader={setShowLoader} />}
      {mode.type === 'locate' && <LocateEmployeePopup data={mode.data} closePopup={() => setMode({ ...defaultMode })} setToastMsg={setToastMsg} setShowLoader={setShowLoader} />}

      {toastMsg.msg && <ToastMessage
        cssClass={toastMsg.type}
        message={toastMsg.msg}
        onClose={() => setToastMsg({ msg: "", type: "" })}
      />}
      <div className={`overlay ${(showtextPopup || showAddNewPopup || actionPopup.show || mode.type !== 'main') ? 'active' : ''}`} />
      <SiteLoader isActive={showLoader} />
    </Fragment>
  )
}

export default MonitorEmployees