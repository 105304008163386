import React from 'react'
import KendoGrid from '../../../common/kendo/KendoGrid'

const ConnectorScheduler = () => {

    return (
        <section>
            <div className="sectionSubHeading">Scheduled Connectors</div>
            <div>
                {
                    true &&
                    <KendoGrid
                        gridData={[]}
                        // setGridData={}
                        columnData={[]}
                        accessLevel={{}}
                        // ref={childRef}
                        module={"connectors"}
                        getAPiUrl={'/track/mobile/v1/allgeo/forms'}
                        // requestParams={{ active: true }}
                        // openPopup={openPopup}
                        // updateItem={updateForms}
                        // deleteItem={deleteForms}
                        lockActionColumn={true}
                        // actionColumnHandler={actionHandler}
                        // bulkDelete={deleteForms}
                        // otherData={{
                        //     disableExportToExcel: true
                        // }}
                    />
                }
            </div>
        </section>
    )
}

export default ConnectorScheduler