import React, { useState, useEffect, Fragment } from "react";
import { apiProvider } from '../../../services/api/provider';
import $ from "jquery";
import DeletePopup from "../../common/PopUp/DeletePopup";
import { getAccountLabel } from "../../../utils/getAccountLabel";

export default function RulesView(props) {

    const [ruleDetails, setRuleDetails] = useState(null);
    const [sectionExpand, setSectionExpand] = useState({
        location: true,
        employee: true,
        settings: true,
    });
    const [deletePopup, setDeletePopup] = useState(false);

    const deleteRules = async () => {

        const payload = [props.rulesId]

        try {
            props.setShowLoader(true)
            const response = await apiProvider.removeObject('/track/mobile/v1/allgeo/rule', payload)
            if (response.status === 200) {
                setDeletePopup(false)
                props.setMode({
                    title: "Schedule",
                    type: 'main',
                    data: null,
                    hasPrev: false
                })
                props.setToastMsg({
                    msg: response.message,
                    type: "msgSuccess"
                })
            } else {
                props.setToastMsg({
                    msg: response.message,
                    type: "msgError"
                })
            }
        } catch (error) {
            console.log(error);
        } finally {
            props.setShowLoader(false)
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        props.setShowLoader(true);

        apiProvider.getSingle('/track/mobile/v1/allgeo/rule', props.rulesId).then((response) => {
            if (response.status === 200 && response.message.toLowerCase() === 'success') {
                setRuleDetails(response.rules[0])
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            props.setShowLoader(false);
        });

    };

    const toggleSection = (sectionType) => {
        const selectedSection = sectionExpand[sectionType]
        let sections = { ...sectionExpand };
        sections[sectionType] = !selectedSection;
        setSectionExpand(sections)
    }

    $(".copyIcon").on("click", function () {
        navigator.clipboard.writeText(props.rulesId);
        $(this).siblings('.copyClipboard').show().delay(4000).fadeOut();
    });

    const employeeLabel = getAccountLabel('employee');
    const groupLabel = getAccountLabel('group');

    return (
        <Fragment>
            <div className="viewTopSection">
                <div className="headingCtrl">

                    <a className={`editGrp${props.accessLevel < 2 ? ' disabled' : ''}`}
                        onClick={() => props.setMode({
                            title: "Edit Rules Information",
                            type: 'edit',
                            data: { ruleID: props.rulesId },
                            hasPrev: true
                        })}>Edit Rules</a>

                    <a className={`deleteGrp${props.accessLevel < 3 ? ' disabled' : ''}`}
                        onClick={() => setDeletePopup(true)}>Delete Rules</a>
                </div>
                <div className="highlightedFldWrap">
                    <div className="fldWrap">
                        <label>Rules ID</label>
                        <div className="highlightedValue">
                            <span>{props.rulesId}</span>
                            <span className="copyIcon">Copy</span>
                            <span className="copyClipboard">Copied to the clipboard!</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="innerWrap">
                <ul className="accordionList">
                    <li id="location" className={`${sectionExpand.location && 'active'}`} >
                        <div className="accHeading" onClick={() => toggleSection('location')}>Schedule Information</div>
                        <div className="accContent">
                            <div className="rowBlk">
                                <div className="colBlk4 fldWrap">
                                    <label>Is Cron Rule</label>
                                    <p>{ruleDetails?.cronRule ? ruleDetails?.cronRule : ''}</p>
                                </div>
                                <div className="colBlk4 fldWrap">
                                    <label>Status</label>
                                    <p className={ruleDetails?.active === 'active' ? "txtActive" : ''} >{ruleDetails?.active}</p>
                                </div>
                                <div className="colBlk4 fldWrap">
                                    <label>Description</label>
                                    <p>{ruleDetails?.desc}</p>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li id="employee" className={`${sectionExpand.employee && 'active'}`} >
                        <div className="accHeading" onClick={() => toggleSection('employee')}>{employeeLabel} / {groupLabel} Code Selection</div>
                        <div className="accContent">
                            <div className="rowBlk">
                                <div className="colBlk4 fldWrap">
                                    {
                                        ruleDetails?.device !== "-" && ruleDetails?.group === '-'
                                            ?
                                            <>
                                                <label>{employeeLabel} ID</label>
                                                <p>{ruleDetails?.deviceMap[ruleDetails?.device]}</p>
                                            </>
                                            :
                                            <>
                                                <label>{groupLabel} ID</label>
                                                <p>{ruleDetails?.groupMap[ruleDetails?.group]}</p>
                                            </>
                                    }
                                </div>
                                <div className="colBlk4 fldWrap">
                                    <label>Status Code</label>
                                    <p>
                                        {
                                            ruleDetails?.codesMap &&
                                            Object.keys(ruleDetails.codesMap).map((key) => {
                                                return key == ruleDetails.code ? <span key={key}>{ruleDetails.codesMap[key]}</span> : ''
                                            })
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li id="settings" className={`${sectionExpand.settings && 'active'}`} >
                        <div className="accHeading" onClick={() => toggleSection('settings')}>Rule Settings</div>
                        <div className="accContent">
                            <div className="rowBlk">
                                <div className="colBlk4 fldWrap">
                                    <label>Rule Selector</label>
                                    <p>{ruleDetails?.selector}</p>
                                </div>
                            </div>
                            <hr />
                            <div className="sectionSubHeading">Email Settings</div>
                            <div className="rowBlk">
                                <div className="colBlk4 fldWrap">
                                    <label>Notification Email</label>
                                    <p>{ruleDetails?.email?.email}</p>
                                </div>
                                <div className="colBlk4 fldWrap">
                                    <label>Email Subject</label>
                                    <p>{ruleDetails?.email?.subj}</p>
                                </div>
                                <div className="colBlk4 fldWrap">
                                    <label>Email Message</label>
                                    <p>{ruleDetails?.email?.body}</p>
                                </div>
                            </div>
                            <hr />
                            <div className="sectionSubHeading">{employeeLabel} Email Settings</div>
                            <div className="rowBlk">
                                <div className="colBlk4 fldWrap">
                                    <label>Notification Email</label>
                                    <p>{ruleDetails?.empEmail?.email}</p>
                                </div>
                                <div className="colBlk4 fldWrap">
                                    <label>Email Subject</label>
                                    <p>{ruleDetails?.empEmail?.subj}</p>
                                </div>
                                <div className="colBlk4 fldWrap">
                                    <label>Email Message</label>
                                    <p>{ruleDetails?.empEmail?.body}</p>
                                </div>
                            </div>
                            <hr />
                            <div className="sectionSubHeading">InApp Settings</div>
                            <div className="rowBlk">
                                <div className="colBlk6 fldWrap">
                                    <label>InApp Phone Number</label>
                                    <p>{ruleDetails?.inApp?.phone}</p>
                                </div>
                                <div className="colBlk6 fldWrap">
                                    <label>InApp Message</label>
                                    <p>{ruleDetails?.inApp?.msg}</p>
                                </div>
                            </div>
                            <hr />
                            <div className="sectionSubHeading">Webhook Settings</div>
                            <div className="rowBlk">
                                <div className="colBlk6 fldWrap">
                                    <label>Webhook URL</label>
                                    <p>{ruleDetails?.webhook?.webhookUrl}</p>
                                </div>
                                <div className="colBlk6 fldWrap">
                                    <label>Webhook Data</label>
                                    <p>{ruleDetails?.webhook?.msg}</p>
                                </div>
                            </div>
                            <hr />
                            <div className="sectionSubHeading">SMS Settings</div>
                            <div className="rowBlk">
                                <div className="colBlk6 fldWrap">
                                    <label>SMS Phone Number</label>
                                    <p>{ruleDetails?.sms?.phone}</p>
                                </div>
                                <div className="colBlk6 fldWrap">
                                    <label>SMS Message</label>
                                    <p>{ruleDetails?.sms?.msg}</p>
                                </div>
                            </div>
                            <hr />
                            <div className="sectionSubHeading">GCM Settings</div>
                            <div className="rowBlk">
                                <div className="colBlk6 fldWrap">
                                    <label>GCM Phone Number</label>
                                    <p>{ruleDetails?.gcm?.phone}</p>
                                </div>
                                <div className="colBlk6 fldWrap">
                                    <label>Message Title</label>
                                    <p>{ruleDetails?.gcm?.title}</p>
                                </div>
                            </div>
                            <div className="rowBlk">
                                <div className="colBlk6 fldWrap">
                                    <label>Notification Type</label>
                                    <p>{ruleDetails?.gcm?.type}</p>
                                </div>
                                <div className="colBlk6 fldWrap">
                                    <label>GCM Message</label>
                                    <p>{ruleDetails?.gcm?.msg}</p>
                                </div>
                            </div>
                            <hr />
                            <div className="sectionSubHeading">IVR Settings</div>
                            <div className="rowBlk">
                                <div className="colBlk4 fldWrap">
                                    <label>IVR Phone Number</label>
                                    <p>{ruleDetails?.ivr?.phone}</p>
                                </div>
                                <div className="colBlk4 fldWrap">
                                    <label>Message Script Type</label>
                                    <p>{ruleDetails?.ivr?.type}</p>
                                </div>
                                <div className="colBlk4 fldWrap">
                                    <label>IVR Message</label>
                                    <p>{ruleDetails?.ivr?.msg}</p>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="mainBtnWrap">
                <button className="btnStyle" onClick={() => props.setMode({
                    title: "Schedule",
                    type: 'main',
                    data: null,
                    hasPrev: false
                })}>Back</button>
            </div>
            <div className={`overlay${deletePopup ? ' active' : ''}`} />
            {  
                deletePopup &&
                <DeletePopup
                    onSuccess={deleteRules}
                    closePopup={() => setDeletePopup(false)}
                />
            }
        </Fragment>

    )
}