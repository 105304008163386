import React, {
  useState,
  useCallback,
  useRef,
  useImperativeHandle,
  forwardRef,
  useMemo,
  useEffect,
} from "react";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
  getSelectedState,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import {
  setExpandedState,
  setGroupIds,
} from "@progress/kendo-react-data-tools";
import { getter } from "@progress/kendo-react-common";
// import { ColumnMenu } from './ColumnMenu';
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import { GridPDFExport } from "@progress/kendo-react-pdf";

import { Prompt } from "react-router-dom";

import { formatDate } from "@progress/kendo-intl";

import { apiProvider } from "../../../../services/api/provider";
import ToastMessage from "../../../../components/common/ToastMessage";
import SiteLoader from "../../../../components/common/SiteLoader";
import ChangeJob from "../../../../assets/images/change-job.svg";
import GreyChangeJob from "../../../../assets/images/grey-chang-job.svg";
import NotAllowedInner from "../../../../assets/images/not-allow-inner.svg";
import NotAllowedInnerGrey from "../../../../assets/images/greySuspend.svg";

import ActiveIcon from "../../../../assets/images/kendo-activate.svg";
import greyActiveIcon from "../../../../assets/images/grey-check.svg";

import DeleteInner from "../../../../assets/images/delete-inner.svg";
import GreyDelete from "../../../../assets/images/greyDelete.svg";

import {
  customRowRender,
  IdCell,
  EditCell,
  handleCustomCells,
  cellRender,
  CustomHeaderCell,
} from "../../../../components/common/kendo/CustomCells";
import {
  InfoPopup,
  DeletePopup,
  WorkOrderDeletePopup,
} from "../../../../components/common/PopUp";
import { handleHeaderActionJSX } from "../../../../components/common/kendo/KendoGridHeaders";
import {
  customExportFunction,
  exportToCSV,
  moduleTypes,
  isDisabled,
} from "../../../../components/common/kendo/kendoUtils";
import { saveChanges } from "../../../../components/common/kendo/kendoGridUtils/saveChanges/saveChanges";
import { cancelChanges } from "../../../../components/common/kendo/kendoGridUtils/cancleChanges/cancleChanges";
import { ActiveScheduleHeaders } from "../../../../components/schedule/ActiveSchedules/ActiveScheduleHeaders";
import { getAccountLabel } from "../../../../utils";
import { ColumnMenu } from "../../../../components/common/kendo/ColumnMenu";
import { DropDownCell } from "../../../../components/common/kendo/DropDownCell";
import {
  ExportDropdown,
  ExportIconDropdown,
} from "../../../../components/common/kendo/HeaderComponents";
import { TooltipKendo } from "../../common/Tooltip";
import TimeClockReportIcon from "../../../../assets/images/iconoir_reports.svg";
import RefreshButton from "../../../../assets/images/refresh-button.svg";
const EDIT_FIELD = "inEdit";
const SELECTED_FIELD = "selected";

const initialDataState = {
  skip: 0,
  take: 50,
};

const processWithGroups = (data, dataState, aggregates = null) => {
  const newDataState = process(data, dataState);

  const groups = dataState.group;

  if (groups && aggregates) {
    groups.map((group) => (group.aggregates = aggregates));
  }

  dataState.group = groups;
  setGroupIds({ data: newDataState.data, group: dataState.group });

  return newDataState;
};

const moment = window.moment;

const actionColumnMinWidth = 60;
// let newData = [];
const KendoGridTable = (
  {
    deleteSeries,
    deleteCurrent,
    gridData,
    columnData,
    deleteItem,
    bulkDelete,
    updateItem,
    createItem,
    accessLevel,
    getAPiUrl,
    setGridData,
    module = "",
    subModule = "",
    openPopup,
    lockActionColumn = false,
    actionColumnHandler,
    requestParams = {},
    otherData = {},
    filterable = true,
    groupable = true,
    setParams,
    params,
    fromMonitorV2,
    openSuspendPopup,
    setSelectedDeleteItem,
    setShowChangeStatusModal,
    openDeletePopup,
    togglePopover,
    setSelectedBulkState,
    selectedBulkState,
    dataObject,
    fromPinned,
    openActivePopup,
    searchField,
    dataItemKey,
    // refreshData,
    // setRefreshData
  },
  ref
) => {
  const DATA_ITEM_KEY = dataItemKey;
  const idGetter = getter(DATA_ITEM_KEY);

  const [editID, setEditID] = useState(null);
  const [data, setData] = useState();

  const [newData, setNewData] = useState([]);

  const [selectedView, setSelectedView] = useState("day");
  const [columns, setColumns] = useState(columnData);

  const [editField, setEditField] = useState(undefined);
  const [dataState, setDataState] = useState({
    ...initialDataState,
    sort: otherData.defaultSortedColumns ? otherData.defaultSortedColumns : [],
    group: otherData.defaultGroupedColumns
      ? otherData.defaultGroupedColumns
      : [],
  });
  const [resultState, setResultState] = useState();

  const [collapsedState, setCollapsedState] = useState([]);
  const [changes, setChanges] = useState(false);
  const [search, setSearch] = useState("");
  const [group, setGroup] = useState(
    otherData.defaultGroupedColumns ? otherData.defaultGroupedColumns : []
  );
  const [selectedState, setSelectedState] = useState(selectedBulkState);
  const [dirty, setDirty] = useState({});
  const [createdData, setCreatedData] = useState([]);
  const [updatedData, setUpdatedData] = useState([]);
  const [mandatoryCols] = useState(
    columnData.filter((col) => col.mandatory).map((col) => col.field)
  );
  const [toastMsg, setToastMsg] = useState("");
  const [backupData, setBackupData] = useState(data);
  const [msgClass, setMsgClass] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [deletePopup, setDeletePopup] = useState({
    show: false,
    data: null,
    type: "",
  });
  const [infoPopup, setInfoPopup] = useState({
    show: false,
    msg: "",
  });
  const [headerDropdown, setHeaderDropdown] = useState(
    otherData.dropDownData ? otherData.dropDownData : ""
  );
  const [reqParams, setReqParams] = useState(requestParams);
  const [cellValidationValue, setCellValidationValue] = useState(null);
  const [cancelChangesPopup, setCancelChangesPopup] = useState(false);

  const anchorRefs = useRef([]);

  const actionColumnBtns = useMemo(
    () =>
      otherData.actionColumnBtns
        ? otherData.actionColumnBtns
        : ["view", "edit", "delete"],
    []
  );
  const validationMapping = useMemo(() => {
    const lengthMap = {};
    const patternMap = {};

    columnData.forEach((col) => {
      if (col.editable) lengthMap[col.field] = col.maxLength || 255;

      if (col.pattern)
        patternMap[col.field] = {
          pattern: col.pattern,
          patternFailMsg: col.patternFailMsg,
        };
    });

    return {
      lengthMap,
      patternMap,
    };
  }, []);

  const onColumnsSubmit = (columnsState) => {
    const { filter } = dataState;
    if (group.length > 0) {
      const hiddenColumns = columnsState
        .filter((col) => !col.show)
        .map((col) => col.field);
      const isThereAnyGroupedColumnsWhichIsHidden = group.filter((col) =>
        hiddenColumns.includes(col.field)
      );
      if (isThereAnyGroupedColumnsWhichIsHidden.length > 0) {
        setMsgClass("");
        setToastMsg("Grouped Columns cannot be hidden.");
        return;
      }
    }

    if (filter) {
      const { filters } = filter;

      if (filters.length > 0) {
        const visibleColumnFilters = filters.filter((filter) => {
          const matchedCol = columnsState.find(
            (col) => col.field === filter.field
          );

          return !(matchedCol && !matchedCol.show);
        });

        const filteredDataState = {
          ...dataState,
          ["filter"]:
            visibleColumnFilters.length === 0
              ? undefined
              : {
                  ...filter,
                  ["filters"]: visibleColumnFilters,
                },
        };

        setResultState(
          processWithGroups(
            data?.map((item) => ({
              ...item,
              [SELECTED_FIELD]: selectedState?.[idGetter(item)],
            })),
            filteredDataState
          )
        );

        setDataState(filteredDataState);
      }
    }

    setColumns(columnsState);
    const columnFieldToRefreshList = otherData.columnFieldToRefreshList
      ? otherData.columnFieldToRefreshList
      : "";
    const requiredColumn = columnsState.find(
      (col) => col.field === columnFieldToRefreshList
    );

    if (requiredColumn?.show) {
      const params = {
        ...reqParams,
        showNearByEmployees: "Yes",
      };
      setReqParams(params);
      refreshList(params);
    }
  };

  const _export = useRef();
  const pdfExportRef = useRef(null);
  const gridRef = useRef(null);
  const csvDownloadRef = useRef(null);
  useEffect(() => {
    if (!!gridData)
      setData(
        gridData?.map((d, ind) => {
          return {
            ...d,
            // id: (params?.pageNo - 1) * params?.pageSize + ind + 1 ,
          };
        })
      );
    setSelectedState([]);
    module === "employee-app-data" && setSelectedDeleteItem([]);
  }, [gridData]);
  console.log(selectedState, "selectedStateselectedState");
  useEffect(() => {
    if (!!data)
      setResultState(
        processWithGroups(
          data,
          dataState,
          otherData.aggregates ? otherData.aggregates : null
        )
      );
  }, [data]);
  console.log("resultStateresultState", resultState);

  useEffect(() => {
    if (otherData.searchText) {
      handleSearch(null, otherData.searchText);
    }
    if (otherData.isUpdated) {
      refreshList();
      otherData.setIsUpdated(false);
    }

    const handleKeyboardEvents = (evt) => {
      if (evt.code === "Escape") {
        closeEdit();
      }
    };

    window.addEventListener("keydown", handleKeyboardEvents);
    return () => {
      window.removeEventListener("keydown", handleKeyboardEvents);
    };
  }, []);

  useEffect(() => {
    if (otherData.isDirty) {
      otherData.isDirty(Object.keys(dirty).length > 0);
    }
    if (Object.keys(dirty).length > 0) {
      localStorage.setItem("formIsDirty", true);
    }
  }, [dirty]);

  useEffect(() => {
    if (!!otherData.aggregates) {
      setResultState(
        processWithGroups(data, { ...dataState }, otherData.aggregates)
      );
    }
  }, [otherData]);

  // useEffect(()=>{
  //   // setBackupData(gridData)
  //   setResultState(gridData)
  // },[gridData])

  const excelExport = () => {
    if (_export.current) {
      _export.current.save();
    }
  };

  const pdfExport = () => {
    if (pdfExportRef.current !== null) {
      pdfExportRef.current.save();
    }
  };

  useImperativeHandle(ref, () => ({
    saveData: () => saveChanges(),
    refreshData: () => refreshList(),
    exportToExcel: () => excelExport(),
    exportToPdf: () => pdfExport(),
    exportToExcelRef: _export,
    gridRef: gridRef,
  }));

  const refreshList = async (params = {}, modifiedDataState = null) => {
    try {
      setShowLoader(true);
      setSearch("");
      let response;
      response = await apiProvider.getAll(
        getAPiUrl,
        Object.keys(params).length > 0 ? params : reqParams
      );
      let newData = [];

      if (response.data) {
        if (otherData.booleanField) {
          newData = response?.data?.map((data, ind) => ({
            ...data,
            id: ind + 1,
            [otherData.booleanField]: data[otherData.booleanField]
              ? "Active"
              : "Inactive",
            nextRunDateTime:
              data.nextRunDateTime === ""
                ? ""
                : moment(data.nextRunDateTime).format(
                    `${response.dateFormat.replace(
                      /[d]/gi,
                      "D"
                    )} ${response.timeFormat.replace(/[a]/gi, "A")}`
                  ),
          }));
        }else if(response?.data?.data){
          newData = response?.data?.data?.map((data, ind) => ({
            ...data,
            id: ind + 1,
          }))
        }  
        else {
          newData = response?.data?.map((data, ind) => ({
            ...data,
            id: ind + 1,
          }));
        }
        console.log(newData,"nnnn")
      } else {
        let responseKey = "";
        switch (module) {
          case moduleTypes.groups:
            responseKey = "groups";
            break;
          case moduleTypes.managers:
            responseKey = "managers";
            break;
          case moduleTypes.tasks:
            responseKey = "tasks";
            break;
          case moduleTypes.forms:
            responseKey = "forms";
            break;
          case moduleTypes.billing:
            responseKey = "billing";
            break;
          case moduleTypes.locateScheduler:
            responseKey = "schedule";
            break;
          case moduleTypes.rulesScheduler:
            responseKey = "rules";
            break;
          case moduleTypes.monitorEmployees:
            responseKey = "list";
            break;
          default:
            break;
        }
        if (
          [
            moduleTypes.settings,
            moduleTypes.editReports,
            moduleTypes.runNBuildReports,
          ].includes(module)
        ) {
          newData = response.map((data, ind) => ({ ...data, id: ind + 1 }));
        } else {
          if (otherData.booleanField) {
            response[responseKey].map((data, ind) => ({
              ...data,
              id: ind + 1,
              [otherData.booleanField]: data[otherData.booleanField]
                ? "Active"
                : "Inactive",
            }));
          } else {
            newData = response[responseKey].map((data, ind) => ({
              ...data,
              id: ind + 1,
            }));
          }
        }
      }

      if (modifiedDataState) {
        setDataState(modifiedDataState);
      }

      // setGridData(newData.map((d) => ({ ...d })));
      setBackupData(newData);
      setData(newData);
      setResultState(
        processWithGroups(newData, modifiedDataState || dataState)
      );
      setCreatedData([]);
      setUpdatedData([]);
      setDirty({});
      setEditID(null);
      setSelectedState({});
      setChanges(false);
      localStorage.removeItem("formIsDirty");
      console.log(newData, "newData");
    } catch (error) {
      console.log(error);
    } finally {
      setShowLoader(false);
    }
  };

  const getMandatoryColumnTitle = (field) => {
    const colData = columnData
      .filter((col) => col.mandatory)
      .find((mCol) => mCol.field === field);

    if (colData) return colData.title;

    return "";
  };

  const onCancleHandler = () => {
    cancelChanges({ dirty, setCancelChangesPopup, resetData, setChanges });
  };

  const resetData = () => {
    const defaultData = gridData.map((d, ind) => {
      delete d.dirty;

      return {
        ...d,
        id: ind + 1,
      };
    });
    setBackupData(defaultData);
    setData(defaultData);
    setResultState(processWithGroups(defaultData, dataState));
    setCreatedData([]);
    setUpdatedData([]);
    setSelectedState({});
    setSelectedDeleteItem([]);
    setDirty({});
    setToastMsg("");
    localStorage.removeItem("formIsDirty");
  };

  const addRecord = () => {
    if (columnData.length > 0) {
      if (data.length > 0) {
        let unfilledMandatoryCols = [];

        if (module === moduleTypes.editReports) {
          createdData.map((createdRecord) => {
            unfilledMandatoryCols = mandatoryCols
              .filter((fld) => createdRecord[fld].trim().length === 0)
              .map((fld) => fld);
          });
        } else {
          unfilledMandatoryCols = mandatoryCols
            .filter((fld) => data[0][fld].trim().length === 0)
            .map((fld) => fld);
        }

        if (unfilledMandatoryCols.length === 0) {
          setChanges(true);
          const d = {};
          columnData.forEach((col) => {
            d[col.field] = col.defaultValue ? col.defaultValue : "";
          });

          const updatedData = [
            { id: data.length + 1, created: true, ...d },
            ...data,
          ].map((d, ind) => ({ ...d, id: ind + 1 }));

          const newDataState = processWithGroups(updatedData, dataState);

          setCreatedData([{ id: 1, created: true, ...d }, ...createdData]);
          setResultState(newDataState);
          setBackupData(updatedData);
          setData(updatedData);
          setEditID(d[DATA_ITEM_KEY]);
        } else {
          setMsgClass("");
          setToastMsg(
            `Please enter the mandatory ${
              unfilledMandatoryCols.length === 1 ? "field" : "fields"
            } ` +
              unfilledMandatoryCols
                .map((field) => {
                  return " " + getMandatoryColumnTitle(field);
                })
                .toString() +
              " to proceed."
          );
        }
      } else {
        setChanges(true);
        let d = {};
        columnData.forEach((col) => {
          d[col.field] = col.defaultValue ? col.defaultValue : "";
        });

        const updatedData = [
          { id: data.total + 1, created: true, ...d },
          ...data,
        ].map((d, ind) => ({ ...d, id: ind + 1 }));

        const newDataState = processWithGroups(updatedData, dataState);

        setCreatedData([{ id: 1, created: true, ...d }, ...createdData]);
        setResultState(newDataState);
        setBackupData(updatedData);
        setData(updatedData);
        setEditID(d[DATA_ITEM_KEY]);
      }
    }
  };

  const onRowClick = (event) => {
    const id = event.dataItem[DATA_ITEM_KEY];
    setEditID(id);

    const dirtyItems = data.filter((d) => d.dirty).map((d) => d[DATA_ITEM_KEY]);
    const dirtyItemsState = {};

    dirtyItems.forEach((item) => (dirtyItemsState[item] = true));

    setSelectedState({
      ...dirtyItemsState,
      [id]: true,
    });
  };

  const handleSearch = (evt, prevSearch = "") => {
    const searchText =
      prevSearch.trim().length > 0 ? prevSearch : evt.target.value;

    setSearch(searchText);
    if (otherData.setSearchText) otherData.setSearchText(searchText);
    const newData = gridData
      .map((d, ind) => {
        return {
          ...d,
          id: ind + 1,
        };
      })
      .filter((item) => {
        let match = false;
        for (const property in item) {
          if (
            item[property] &&
            item[property]
              .toString()
              .toLocaleLowerCase()
              .indexOf(searchText.toLocaleLowerCase()) >= 0
          ) {
            match = true;
          }
        }
        return match;
      });
    setData(newData);
    let clearedPagerDataState = { ...dataState, take: dataState.take, skip: 0 };
    const newDataState = processWithGroups(newData, clearedPagerDataState);
    setResultState(newDataState);
    setDataState(clearedPagerDataState);
  };

  const setSelectedValue = (data) => {
    return data?.map((item) => {
      if (item.items) {
        return {
          ...item,
          items: setSelectedValue(item.items),
        };
      } else {
        return {
          ...item,
          ["selected"]: selectedState?.[idGetter(item)],
        };
      }
    });
  };

  const onSelectionChange = useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      const dirtyItems = data
        .filter((d) => d.dirty)
        .map((d) => d[DATA_ITEM_KEY]);
      const dirtyItemsState = {};

      dirtyItems.forEach((item) => (dirtyItemsState[item] = true));

      setSelectedState({
        ...dirtyItemsState,
        ...newSelectedState,
      });
      setSelectedBulkState({
        ...dirtyItemsState,
        ...newSelectedState,
      });
      setUpdatedData(data.filter((d) => !d.created && newSelectedState[d.id]));
    },
    [selectedState, data, selectedBulkState]
  );
  const onHeaderSelectionChange = useCallback((event) => {
    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    const newSelectedState = {};
    event.dataItems.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
    });
    setSelectedState(newSelectedState);
    setSelectedBulkState(newSelectedState);
    setSelectedDeleteItem(
      Object.keys(newSelectedState)?.filter((keys) => selectedState?.[keys])
    );
  }, []);

  const getNumberOfItems = (data) => {
    let count = 0;
    data.forEach((item) => {
      if (item.items) {
        count = count + getNumberOfItems(item.items);
      } else {
        count++;
      }
    });
    return count;
  };

  const getNumberOfSelectedItems = (data) => {
    let count = 0;
    data?.forEach((item) => {
      if (item.items) {
        count = count + getNumberOfSelectedItems(item.items);
      } else {
        count = count + (item.selected == true ? 1 : 0);
      }
    });
    return count;
  };

  const checkHeaderSelectionValue = () => {
    let selectedItems = getNumberOfSelectedItems(newData);
    return newData?.length > 0 && selectedItems == getNumberOfItems(newData);
  };

  const onExpandChange = useCallback(
    (event) => {
      const item = event.dataItem;
      if (item.groupId) {
        const newCollapsedIds = !event.value
          ? [...collapsedState, item.groupId]
          : collapsedState.filter((groupId) => groupId !== item.groupId);
        setCollapsedState(newCollapsedIds);
      }
    },
    [collapsedState]
  );

  let startIndex = (params?.pageNo - 1) * params.pageSize + 1;
  const onDataStateChange = useCallback(
    (evt) => {
      const { skip, take } = evt.dataState;

      // set(employeeData?.data.slice(skip, skip + take));
      const pageNumber = Math.floor(skip / take) + 1;
      if (params.pageNo !== pageNumber || params.pageSize !== take) {
        setParams({
          ...params,
          pageNo: pageNumber,
          pageSize: take,
        });
      }
      setDataState(evt.dataState);

      // setParams({
      //   ...params,
      //   page: pageNumber,
      //   perPage: take,
      // });

      if (evt.dataState.group.length > 1) {
        const fields = evt.dataState.group.map((d) => d.field);
        const duplicateFieldFound = fields.some((g, ind) => {
          const ix = fields.indexOf(g);
          return ix !== ind;
        });

        if (duplicateFieldFound) return;
      }

      if (otherData.aggregates) {
        const grp = evt.dataState.group.map((g) => ({
          ...g,
          aggregates: otherData.aggregates,
        }));

        evt.dataState.group = grp;
      }

      setGroup(evt.dataState.group);

      setResultState(
        processWithGroups(
          data.map((item) => ({
            ...item,
            [SELECTED_FIELD]: selectedState[idGetter(item)],
          })),
          { ...evt.dataState, skip: 0 }
        )
      );
      // setResultState(
      //   processWithGroups(
      //     data?.map((item) => ({
      //       ...item,
      //       [SELECTED_FIELD]: selectedState?.[idGetter(item)],
      //     })),
      //     evt.dataState
      //   )
      // );
      setDataState({ ...evt.dataState, skip: skip, take: take });
    },
    [data, gridData]
  );

  useEffect(() => {
    if (!!resultState || !!data)
      setNewData(
        setExpandedState({
          data: setSelectedValue(
            resultState?.data.length > 0 ? resultState?.data : data
          ),
          collapsedIds: collapsedState,
        })
      );
  }, [resultState, selectedState, data]);

  const bulkDeleteAction = (itemID) => {
    const filteredData = data.filter((d) => selectedState?.[d.id]);
    const dataToBeDeleted = itemID
      ? filteredData.map((d) => d[itemID])
      : filteredData;

    setDeletePopup({
      show: true,
      data: dataToBeDeleted,
      type: "multiple",
    });
  };

  const isValidCell = (field, value) => {
    const { patternMap } = validationMapping;

    if (patternMap[field]) {
      const { pattern, patternFailMsg } = patternMap[field];

      if (!pattern.test(value)) {
        setCellValidationValue({
          [field]: value,
        });
        setMsgClass("msgError");
        setToastMsg(patternFailMsg);

        return false;
      }
    }

    if (cellValidationValue !== null) setCellValidationValue(null);

    return true;
  };

  const handleBlur = (e, dataValue, cellField, dataItem) => {
    const value = dataValue;

    if (
      typeof value === "string" &&
      value.trim().length === 0 &&
      mandatoryCols.includes(cellField)
    ) {
      e.target.focus();
      setMsgClass("");

      let infoMsg =
        "This is a mandatory field. Please enter a value to proceed.";

      if (!dataItem[cellField] || dataItem[cellField].trim().length > 0) {
        infoMsg = "This is a mandatory field. It cannot be left empty.";
      }

      setToastMsg(infoMsg);
    } else {
      if (cellValidationValue !== null) setCellValidationValue(null);

      let dataItemValue = "";
      if (cellField.includes(".")) {
        const nestedFields = cellField.split(".");
        dataItemValue = dataItem[nestedFields[0]][nestedFields[1]];
      } else {
        dataItemValue = dataItem[cellField];
      }

      if (value !== dataItemValue && isValidCell(cellField, value))
        saveGridData(value, cellField, dataItem);
    }
  };

  const onSave = (dataValue, cellField, dataItem) => {
    if (isValidCell(cellField, dataValue)) {
      const existingData =
        JSON.parse(localStorage.getItem("selectedTaskdetailed")) || [];
      const newData = { task: dataValue, id: dataItem.id };
      existingData.push(newData);
      // Save the updated data back to localStorageif
      localStorage.setItem(
        "selectedTaskdetailed",
        JSON.stringify(existingData)
      );
      saveGridData(dataValue, cellField, dataItem);
    }
    if (isValidCell(cellField, dataValue))
      saveGridData(dataValue, cellField, dataItem);
  };

  const handleDropDownChange = (e) => {
    const { value, field, dataItem } = e;
    const { dropdownDependency } = otherData;

    if (dropdownDependency && Object.keys(dropdownDependency).includes(field)) {
      if (dropdownDependency[field].data) {
        const valueToBeMapped = dropdownDependency[field].data.find(
          (d) => d.key === value
        );

        if (valueToBeMapped) {
          saveGridData(
            valueToBeMapped.value,
            dropdownDependency[field].fieldToBeMapped,
            dataItem
          );
        }
      } else {
        saveGridData("", dropdownDependency[field].fieldToBeMapped, dataItem);
      }
    }
    saveGridData(value, field, dataItem);
  };

  const saveGridData = (value, field, dataItem) => {
    if (value.trim().length === 0 && mandatoryCols.includes(field)) return;

    let newDirty = { ...dirty };
    newDirty[dataItem[DATA_ITEM_KEY]] = {
      ...dirty[dataItem[DATA_ITEM_KEY]],
      [field]: true,
    };

    let newData;

    if (field.includes(".")) {
      const nestedFields = field.split(".");

      newData = data?.map((item) => {
        if (item[DATA_ITEM_KEY] === dataItem[DATA_ITEM_KEY]) {
          const newObj = {
            ...dataItem[nestedFields[0]],
            [nestedFields[1]]: value,
          };
          item[nestedFields[0]] = newObj;
          item.dirty = newDirty[item[DATA_ITEM_KEY]];
        }
        return item;
      });
    } else {
      newData = data?.map((item) => {
        if (item[DATA_ITEM_KEY] === dataItem[DATA_ITEM_KEY]) {
          item[field] = value;
          item.dirty = newDirty[item[DATA_ITEM_KEY]];
        }
        return item;
      });
    }

    setDirty(newDirty);

    setUpdatedData(
      newData.filter((d) => !d.created && (d.dirty || d[EDIT_FIELD]))
    );
    setCreatedData(newData.filter((d) => d.created));
    setData(newData);
    setBackupData(newData);
    const newDataState = processWithGroups(
      newData.map((item) => ({
        ...item,
      })),
      dataState
    );
    setResultState(newDataState);
    setChanges(true);
    setEditField(null);
    setEditID(null);
  };

  const closeEdit = () => {
    setEditField(null);
    setEditID(null);
  };

  const handleCellDropdown = (column) => {
    return (props) => (
      <DropDownCell
        {...props}
        disabled={isDisabled("edit", accessLevel, selectedState)}
        onChange={handleDropDownChange}
        field={column.field}
        filterable={column.dropdownFilter}
        editField={editField}
        editID={editID}
        otherData={otherData}
      />
    );
  };

  const handleCellFieldSelection = (field) => {
    if (field !== editField) {
      setEditField(field);
    }
  };

  const InlineEditCell = (col) => {
    return (props) => (
      <EditCell
        {...props}
        editID={editID}
        editField={editField}
        editable={accessLevel.childMenu < 2 ? false : col.editable}
        onClick={handleCellFieldSelection}
        onBlur={handleBlur}
        onSave={onSave}
        validationMapping={validationMapping}
        cellValidationValue={cellValidationValue}
        otherData={otherData}
        columnData={col}
      />
    );
  };

  const handlePageSizes = useCallback(
    (length) => {
      const defaultPageSize = [
        25, 50, 100, 250, 1000, 1500, 2000, 3000, 4000, 5000,
      ];
      const dataLength = length;

      if (dataLength > 5000) {
        const extraLength = dataLength % 1000;
        const finalValue = dataLength - extraLength;

        if (extraLength > 500) {
          defaultPageSize.push(finalValue + 1000);
        } else {
          defaultPageSize.push(finalValue + 500);
        }
      }

      return defaultPageSize;
    },
    [data]
  );

  const handleColumnLockToggle = (columnField, state) => {
    const newColumns = columns.map((column) => {
      if (column.field === columnField) {
        column.locked = state;
      }

      return column;
    });
    setColumns(newColumns);
  };

  const customGroupBy = async (
    fieldsToGroup = [],
    columnsToShow = [],
    fieldsToRemove = []
  ) => {
    const fieldsToGroupBy = fieldsToGroup.map((field) => ({
      field,
      aggregates: otherData.aggregates || {},
    }));

    let groupedFields =
      fieldsToGroupBy.length > 0
        ? [...group, ...fieldsToGroupBy]
        : [...group, ...fieldsToGroupBy].filter(
            (obj) => !fieldsToRemove.includes(obj.field)
          );

    groupedFields = groupedFields.filter(
      (obj, ind) =>
        groupedFields.findIndex((dObj) => dObj.field === obj.field) === ind
    );

    setColumns(
      columns.map((col) => ({
        ...col,
        show:
          fieldsToGroup.length > 0
            ? columnsToShow.includes(col.field)
              ? true
              : col.show
            : columnsToShow.includes(col.field)
            ? false
            : col.show,
      }))
    );

    setGroup(groupedFields);

    const modifiedDataState = {
      ...dataState,
      group: groupedFields,
    };

    setResultState(
      processWithGroups(
        data?.map((item) => ({
          ...item,
          [SELECTED_FIELD]: selectedState?.[idGetter(item)],
        })),
        modifiedDataState,
        otherData.aggregates || {}
      )
    );
    setDataState(modifiedDataState);

    const params = {
      ...reqParams,
      weekView: "weekView",
    };

    if (fieldsToGroupBy.length === 0) {
      delete params.weekView;
    }
    setReqParams(params);
    refreshList(params, modifiedDataState);
  };

  const onColumnsReorder = (evt) => {
    const columnsOrderMap = {};

    evt.columns.map((col) => {
      columnsOrderMap[col.field] = col.orderIndex;
    });

    setColumns(
      columns.map((col) => ({
        ...col,
        orderIndex: columnsOrderMap[col.field],
      }))
    );
  };

  const onClickHandler = () => {
    saveChanges({
      module,
      moduleTypes,
      data,
      updatedData,
      createdData,
      getMandatoryColumnTitle,
      mandatoryCols,
      setToastMsg,
      setMsgClass,
      setShowLoader,
      updateItem,
      createItem,
      refreshList,
      setSelectedState,
      setEditID,
      setChanges,
      setCreatedData,
      setUpdatedData,
    });
  };

  useEffect(() => {
    if (selectedView === "day") {
      const dayWeekViewColumns = columns.filter(
        (e) => !(e.field === "dayName" || e.field === "weekName")
      );
      setColumns(dayWeekViewColumns);
    } else if (selectedView === "week") {
      setColumns(columnData);
    }
  }, [selectedView]);
  useEffect(() => {
    let filterInputs = document.querySelectorAll(".k-input");
    if (filterInputs.length > 0) {
      filterInputs.forEach((el) => {
        el.setAttribute("placeholder", "Search");
      });
    }
  }, []);
  // useEffect(()=>{
  //   if(refreshData){
  //     setRefreshData(false)
  //     setDataState((prev)=>({...prev,skip:0}))
  //   }
  //   // refreshData={refreshData}
  //   //         setRefreshData={setRefreshData}
  // debugger
  // },[refreshData])
  return (
    <>
      <div
        style={{ maxWidth: fromPinned ? "calc(100vw - 36px)" :module === "timeClockReports"?"calc(100vw - 149px)": "" }}
        className="kendoTblWrap headerVisible"
      >
        <ExcelExport
          ref={_export}
          data={newData}
          group={dataState.group}
          fileName={otherData.exportFileName || module}
        >
          {columns.map(
            (col, ind) =>
              col.show && (
                <ExcelExportColumn
                  key={ind}
                  field={col.field}
                  title={col.title}
                  width={col.width}
                  groupFooterCellOptions={{ color: "red" }}
                />
              )
          )}
        </ExcelExport>
        <Grid
          style={{
            borderRadius: 8,
            margin:
              fromMonitorV2 && !fromPinned
                ? 20
                : fromMonitorV2 && !!fromPinned
                ? "20px 0 0 0"
                : "0 0 0 0",
            minHeight: "calc(100vh - 230px)",
            maxHeight: "calc(100vh - 220px)",
          }}
          ref={gridRef}
          className={"kendoGridHeight"}
          pageable={{ pageSizes: handlePageSizes(newData?.length) }}
          sortable={{
            allowUnsort: true,
            mode: "multiple",
          }}
          filterable={filterable}
          total={
            !!searchField ||
            !!search ||
            !!dataState?.filter?.filters?.[0]?.filters?.[0]?.value
              ? newData.length
              : dataObject?.recordsCount ?? dataObject?.totalRecordCount
          }
          data={newData}
          onDataStateChange={onDataStateChange}
          {...dataState}
          onExpandChange={onExpandChange}
          expandField="expanded"
          groupable={{
            enabled: groupable,
            footer: otherData.enableFooterDisplay ? "visible" : "none",
          }}
          group={group}
          selectable={{
            enabled: true,
            drag: false,
            cell: false,
            mode: "multiple",
          }}
          editField={EDIT_FIELD}
          dataItemKey={DATA_ITEM_KEY}
          onRowClick={onRowClick}
          selectedField={SELECTED_FIELD}
          onSelectionChange={onSelectionChange}
          onHeaderSelectionChange={onHeaderSelectionChange}
          reorderable={true}
          resizable={true}
          onColumnReorder={onColumnsReorder}
          cellRender={cellRender}
          rowRender={(tr, props) =>
            otherData.customRow ? customRowRender(tr, props, otherData) : tr
          }
        >
          {module === "timeClockReports" && (
            <GridToolbar>
              <div className="parent-time-clock-header">
                <div className="left_inner_header time-clock-haeder">
                  <div className="search_field active">
                    <p
                      style={{ marginBottom: 10, display: "flex" }}
                      className="timeclockheadtag"
                    >
                      <span style={{ marginRight: "6px" }}>
                        <img src={TimeClockReportIcon} alt="" />
                      </span>
                       Time Clock 
                    </p>

                    <input
                      className="timeClockSerchBox"
                      placeholder={otherData.searchPlaceholder || "Search"}
                      value={search}
                      onChange={handleSearch}
                    />
                  </div>

                  {/* <button
                    className="btn_style_schedule btn2 refresh_btn tooltip_cal_sm"
                    onClick={() => {
                      refreshList();
                    }}
                  >
                    <span>Refresh</span>
                  </button> */}

                  <TooltipKendo position="top">
                    <img
                      className="btn_style_schedule btn2 refresh_btn tooltip_cal_sm"
                      style={{ cursor: "pointer" }}
                      src={RefreshButton}
                      alt="Refresh Button"
                      onClick={() => {
                        refreshList();
                      }}
                      title="Refresh"
                      role="button"
                    />
                  </TooltipKendo>
                </div>

                <div
                  className={`kendoHeader ${
                    toastMsg && !msgClass ? "headerPull" : ""
                  }`}
                >
                  <div className="kHeaderLeft">
                    {handleHeaderActionJSX(
                      module,
                      accessLevel,
                      selectedState,
                      addRecord,
                      bulkDeleteAction,
                      refreshList,
                      openPopup,
                      otherData,
                      reqParams,
                      setReqParams,
                      updateItem,
                      setInfoPopup,
                      setMsgClass,
                      setToastMsg,
                      headerDropdown,
                      setHeaderDropdown,
                      data,
                      setShowLoader,
                      customGroupBy,
                      changes,
                      onClickHandler,
                      cancelChanges,
                      selectedView,
                      setSelectedView
                    )}
                  </div>
                  <div className="kHeaderRight">
                    <div className="kHeaderRTop">
                      {otherData.showExportDropdown ? (
                        <ExportIconDropdown
                          onClick={(type) => {
                            switch (type) {
                              case "xl":
                                customExportFunction(
                                  gridRef.current,
                                  _export.current,
                                  {
                                    columnFormatMap:
                                      otherData.columnFormatMap || {},
                                    aggregateText: otherData.aggregateText,
                                    column: columns,
                                    minutesFormat: otherData.minutesFormat,
                                  }
                                );
                                break;
                              case "pdf":
                                pdfExportRef.current?.save();
                                break;
                              case "csv":
                                exportToCSV(
                                  _export,
                                  otherData.columnFormatMap || {},
                                  csvDownloadRef,
                                  module,
                                  otherData.minutesFormat
                                );
                                break;
                            }
                          }}
                          accessLevel={accessLevel}
                        />
                      ) : (
                        <>
                          {otherData.enableExportToPDF && (
                            <button
                              title="Export PDF"
                              className="btnStyle btn2 pdfBtn"
                              onClick={pdfExport}
                            >
                              Export to PDF
                            </button>
                          )}
                          {!otherData.disableExportToExcel && (
                            <button
                              title="Export Excel"
                              className="btnStyle btn2 xlBtn"
                              onClick={excelExport}
                            >
                              Export to Excel
                            </button>
                          )}
                        </>
                      )}

                      {/* <form id="map_input_sub_form" method="post" target="_blank" action={`${process.env.REACT_APP_API_URL}/mgt?page=manageteaminfoV1`} onSubmit={e => e.preventDefault()}><input type="hidden" name="phoneList" value="0987,1,1017772,2025550330,2025550331"><input type="hidden" name="groupList" value="all"></form> */}

                      {!otherData.disableGlobalSearch && (
                        <div className="searchFld">
                          <input
                            placeholder={
                              otherData.searchPlaceholder || "Search"
                            }
                            value={search}
                            onChange={handleSearch}
                          />
                        </div>
                      )}
                    </div>
                    <div className="kHeaderRBot">
                      {otherData.hideSaveCancelChanges ? null : (
                        <>
                          <button
                            title="Save Changes"
                            className="btnStyle btn2 btnSave"
                            onClick={onClickHandler}
                            disabled={
                              accessLevel.childMenu
                                ? accessLevel.childMenu < 2 || !changes
                                : !changes
                            }
                          >
                            Save Changes
                          </button>
                          <button
                            title="Cancel Changes"
                            className="btnStyle btn2 btnCancel"
                            onClick={onCancleHandler}
                            disabled={!changes}
                          >
                            Cancel Changes
                          </button>
                        </>
                      )}
                    </div>
                    {module === moduleTypes.runNBuildReports ? (
                      <div className="kHeaderRBot">
                        <button
                          title="Download"
                          className="btnStyle btn2 btnDownload"
                          onClick={() => openPopup("download")}
                        >
                          Download
                        </button>
                        <button
                          title="Email"
                          className="btnStyle btn2 btnEmail"
                          onClick={() => openPopup("email")}
                        >
                          Email
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </GridToolbar>
          )}

          {otherData.noRecordText && (
            <GridNoRecords>
              <div className="tblNoData">{otherData.noRecordText}</div>
            </GridNoRecords>
          )}
          {/* logic to lock the selected column only for editReports */}
          {!otherData.hideCheckBox && module === moduleTypes.editReports ? (
            <Column
              field={SELECTED_FIELD}
              width="50px"
              headerSelectionValue={checkHeaderSelectionValue()}
              filterable={false}
              groupable={false}
              orderIndex={0}
              reorderable={false}
              resizable={false}
              locked={true}
            />
          ) : module === moduleTypes.timeClockReport ? (
            <></>
          ) : (
            <Column
              field={SELECTED_FIELD}
              width="30px"
              headerSelectionValue={checkHeaderSelectionValue()}
              filterable={false}
              groupable={false}
              orderIndex={0}
              reorderable={false}
              resizable={false}
              style={{ border: "1px solid #dcdcdc" }}
            />
          )}

          <Column
            title="#"
            field="seqNo"
            width="50px"
            editable={false}
            filterable={false}
            groupable={false}
            sortable={false}
            cell={IdCell}
            orderIndex={0}
            reorderable={false}
            resizable={false}
          />

          {columns.map(
            (col, ind) =>
              col.show && (
                <Column
                  key={ind}
                  field={col.field}
                  title={col.title}
                  editable={accessLevel.childMenu < 2 ? false : col.editable}
                  width={col.width}
                  editor={col.editor}
                  filter={col.filter}
                  locked={col.locked}
                  format={col.format}
                  orderIndex={col.orderIndex}
                  cellOptions={
                    col.field === "elapsedTimeSec" && {
                      format: "hh:mm:ss",
                    }
                  }
                  columnMenu={(props) => (
                    <ColumnMenu
                      {...props}
                      columns={columns}
                      onColumnsSubmit={onColumnsSubmit}
                      onColumnLockToggle={handleColumnLockToggle}
                      locked={col.locked || false}
                    />
                  )}
                  cell={
                    col.dropdownCell
                      ? handleCellDropdown(col)
                      : col.customCell
                      ? handleCustomCells(
                          col.field,
                          openPopup,
                          handleCellFieldSelection,
                          editID,
                          editField,
                          onSave,
                          accessLevel.childMenu < 2 ? false : col.editable,
                          otherData
                        )
                      : InlineEditCell(col)
                  }
                  headerCell={
                    col.headerCell
                      ? (props) =>
                          CustomHeaderCell(
                            props,
                            col.headerCell,
                            dataState.sort
                          )
                      : null
                  }
                />
              )
          )}
          {/* {actionColumnBtns.length > 0 && (
            <Column
            
              field="action"
              orderIndex={columns.length + 1}
              title="Action"
              headerClassName="actionColHeader"
              width={
                20 * (actionColumnBtns.length + 1) > actionColumnMinWidth
                  ? 20 * (actionColumnBtns.length + 1)
                  : actionColumnMinWidth
              }
              cell={(props) =>
                renderActionCell(
                  props,
                  accessLevel,
                  selectedState,
                  actionColumnBtns,
                  actionColumnHandler,
                  setDeletePopup,
                  module,
                  fromMonitorV2
                )
              }
              groupable={false}
              filterable={false}
              sortable={false}
              locked={lockActionColumn}
            />
          )} */}

          {actionColumnBtns.length > 0 && (
            <Column
              field="Actions"
              className=""
              title="Actions"
              // locked={true}
              groupable={false}
              filterable={false}
              sortable={false}
              width={"50px"}
              cell={(props) => (
                <td
                  style={{
                    textAlign: "center",

                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <button
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                        cursor: "pointer",
                      }}
                      disabled={isDisabled("edit", accessLevel)}
                      onClick={() => {
                        setShowChangeStatusModal(true);
                        setSelectedDeleteItem(props.dataItem.employeeID);
                      }}
                    >
                      <TooltipKendo>
                        <img
                          style={{ width: "18px", height: 18 }}
                          className={
                            isDisabled("edit", accessLevel)
                              ? "disabled_field_table"
                              : ""
                          }
                          title="Change Job Status"
                          src={
                            isDisabled("edit", accessLevel)
                              ? GreyChangeJob
                              : ChangeJob
                          }
                          alt=""
                        />
                      </TooltipKendo>
                    </button>
                    {params?.queryParams?.searchValue === "Suspended" && (
                      <button
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                          cursor: "pointer",
                        }}
                        disabled={isDisabled("edit", accessLevel)}
                        onClick={() => {
                          openActivePopup("single");
                          setSelectedDeleteItem(props.dataItem);
                          // setSelectedSuspendDevice(props.dataItem)
                        }}
                        title="Activate"
                      >
                        <TooltipKendo>
                          <img
                            className={
                              isDisabled("edit", accessLevel)
                                ? "disabled_field_table"
                                : ""
                            }
                            style={{ marginLeft: 7, height: 16, width: 16 }}
                            src={
                              isDisabled("edit", accessLevel)
                                ? greyActiveIcon
                                : ActiveIcon
                            }
                            alt=""
                          />
                        </TooltipKendo>
                      </button>
                    )}

                    {params?.queryParams?.searchValue !== "Suspended" && (
                      <button
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                          cursor: "pointer",
                        }}
                        disabled={isDisabled("edit", accessLevel)}
                        onClick={() => {
                          openSuspendPopup("single");
                          setSelectedDeleteItem(props.dataItem);
                          // setSelectedSuspendDevice(props.dataItem)
                        }}
                        title="Suspend"
                      >
                        <TooltipKendo>
                          <img
                            className={
                              isDisabled("edit", accessLevel)
                                ? "disabled_field_table"
                                : ""
                            }
                            style={{ marginLeft: 7, width: 18, height: 18 }}
                            src={
                              isDisabled("edit", accessLevel)
                                ? NotAllowedInnerGrey
                                : NotAllowedInner
                            }
                            alt=""
                          />
                        </TooltipKendo>
                      </button>
                    )}
                    <button
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                        cursor: "pointer",
                      }}
                      disabled={isDisabled("add", accessLevel)}
                      onClick={() => {
                        openDeletePopup("single");
                        setSelectedDeleteItem([props.dataItem.employeeID]);
                      }}
                    >
                      <TooltipKendo>
                        <img
                          className={
                            isDisabled("add", accessLevel)
                              ? "disabled_field_table"
                              : ""
                          }
                          style={{ marginLeft: 8, width: 18, height: 18 }}
                          src={
                            isDisabled("add", accessLevel)
                              ? GreyDelete
                              : DeleteInner
                          }
                          alt=""
                          title="Delete"
                        />
                      </TooltipKendo>
                    </button>
                  </div>
                </td>
              )}
            />
          )}
        </Grid>

        <PDFGrid />
        <SiteLoader isActive={showLoader} />
      </div>

      {toastMsg && (
        <ToastMessage
          cssClass={msgClass}
          message={toastMsg}
          onClose={() => setToastMsg("")}
          hideOverlay={true}
        />
      )}

      <div
        className={`overlay ${
          deletePopup.show || infoPopup.show ? "active" : ""
        }`}
      />
      <a hidden ref={csvDownloadRef} />
    </>
  );

  function PDFGrid() {
    return (
      <GridPDFExport
        ref={(pdfRef) => (pdfExportRef.current = pdfRef)}
        fileName={otherData.exportFileName || module}
      >
        <Grid
          className={"kendoGridHeight"}
          data={newData}
          group={group}
          groupable={{
            enabled: groupable,
            footer: otherData.enableFooterDisplay ? "visible" : "none",
          }}
        >
          {columns.map(
            (col, ind) =>
              col.show && (
                <Column
                  key={ind}
                  field={col.field}
                  title={col.title}
                  editable={accessLevel.childMenu < 2 ? false : col.editable}
                  width={col.width}
                  editor={col.editor}
                  filter={col.filter}
                  columnMenu={(props) => (
                    <ColumnMenu
                      {...props}
                      columns={columns}
                      onColumnsSubmit={onColumnsSubmit}
                      onColumnLockToggle={handleColumnLockToggle}
                      locked={col.locked || false}
                    />
                  )}
                  cell={
                    col.dropdownCell
                      ? handleCellDropdown(col)
                      : col.customCell
                      ? handleCustomCells(
                          col.field,
                          openPopup,
                          handleCellFieldSelection,
                          editID,
                          editField,
                          onSave,
                          accessLevel.childMenu < 2 ? false : col.editable,
                          otherData,
                          togglePopover
                        )
                      : InlineEditCell(col)
                  }
                />
              )
          )}
        </Grid>
      </GridPDFExport>
    );
  }
};

export default forwardRef(KendoGridTable);
