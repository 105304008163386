import { apiProvider } from "../../../../../services/api/provider";

export const getBaseData = async ({ setbaseData, setShowLoader }) => {
    const param = {
      calendarViewSelect: "",
      calendarFilterSelect: "",
      calendarDateSelect: "",
      calendarActionSelect: "",
    };
    try {
      setShowLoader(true);
      const response = await apiProvider.post(
        "/track/mobile/v1/allgeo/loadCalendarWorkorders",
        param
      );
      if (response.status === 200) {
        setbaseData(response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setShowLoader(false);
    }
  };