import React from 'react';
import ViewIcon from '../../../assets/images/icon-view-kendo.svg';
import EditIcon from '../../../assets/images/icon-edit-kendo.svg';
import CopyIcon from '../../../assets/images/icon-copy.svg';
import DeleteIcon from '../../../assets/images/icon-delete-kendo.svg';
import JobChangeIcon from '../../../assets/images/icon-job-change-kendo.svg'
import LocateIcon from '../../../assets/images/icon-locate-kendo.svg'
import SuspendIcon from '../../../assets/images/suspend-option.svg'
import ActivateIcon from '../../../assets/images/kendo-activate.svg'
import { isDisabled, moduleTypes } from './kendoUtils';
import { Tooltip } from '@progress/kendo-react-tooltip';
import DisableStart from '../../../assets/images/play-disable.svg'
import EnableStart from '../../../assets/images/play-enable.svg'
import DisableStop from '../../../assets/images/stop-disable.svg'
import EnableStop from '../../../assets/images/stop-enable.svg'

const ActionColumnBtns = props => {
    const { accessLevel, selectedState, actionColumnBtns, actionColumnHandler, setDeletePopup, dataItem, currentModuleName } = props;
    return (
        <ul className='kendoActionsCol'>
            {module !== moduleTypes.billing && actionColumnBtns.includes('view') && <li
                className={isDisabled('view', accessLevel, selectedState) ? 'disabled' : ''}
                onClick={() => actionColumnHandler("view", props.dataItem)}>
                <Tooltip position='top' >
                    <img title='View' alt="View Icon" src={ViewIcon} />
                </Tooltip>
            </li>}
            {(module === moduleTypes.billing && props.dataItem.invoiceURL) && actionColumnBtns.includes('view') && <li
                className={isDisabled('view', accessLevel, selectedState) ? 'disabled' : ''}
                onClick={() => actionColumnHandler("view", props.dataItem)}><img src={ViewIcon} />
            </li>}
            {actionColumnBtns.includes('edit') && <li
                className={isDisabled('edit', accessLevel, selectedState) ? 'disabled' : ''}
                onClick={() => actionColumnHandler("edit", props.dataItem)}>
                <Tooltip position='top' >
                    <img title='Edit' src={EditIcon} />
                </Tooltip>
            </li>}
            {actionColumnBtns.includes('start') && <li
                className={currentModuleName === 'completedSchedule' ? 'disabled' : ''}
                onClick={() => actionColumnHandler("start", props.dataItem)}>
                <Tooltip position='top' >
                    <img title='Start' alt="Start Icon" src={currentModuleName === 'completedSchedule' ? DisableStart : EnableStart} />
                </Tooltip>
            </li>}
            {actionColumnBtns.includes('stop') && <li
                className={currentModuleName === 'futureSchedule' || currentModuleName === 'completedSchedule' ? 'disabled' : ''}
                onClick={() => actionColumnHandler("stop", props.dataItem)}>
                <Tooltip position='top' >
                    <img title='Stop' alt="Stop Icon" src={currentModuleName === 'futureSchedule' || currentModuleName === 'completedSchedule' ? DisableStop : EnableStop} />
                </Tooltip>
            </li>}
            {actionColumnBtns.includes('jobChange') &&
                <li
                    className={isDisabled('edit', accessLevel, selectedState) ? 'disabled' : ''}
                    onClick={() => actionColumnHandler("jobChange", props.dataItem)}
                >
                    <Tooltip position='top' >
                        <img title='Change Job Status' src={JobChangeIcon} />
                    </Tooltip>
                </li>
            }
            {actionColumnBtns.includes('locate') &&
                <li
                    className={isDisabled('edit', accessLevel, selectedState) ? 'disabled' : ''}
                    onClick={() => actionColumnHandler("locate", props.dataItem)}
                >
                    <Tooltip position='top' >
                        <img title='Locate' src={LocateIcon} />
                    </Tooltip>
                </li>
            }
            {actionColumnBtns.includes('suspend') &&
                <li
                    className={isDisabled('edit', accessLevel, selectedState) ? 'disabled' : ''}
                    onClick={() => actionColumnHandler(dataItem.activeStatus ? "suspend" : "activate", props.dataItem)}
                >
                    <Tooltip position='top' >
                        <img title={`${dataItem.activeStatus ? 'Suspend' : 'Activate'}`} src={dataItem.activeStatus ? SuspendIcon : ActivateIcon} />
                    </Tooltip>
                </li>
            }
            {actionColumnBtns.includes('copy') && <li
                className={isDisabled('add', accessLevel, selectedState) ? 'disabled' : ''}
                onClick={() => actionColumnHandler("copy", props.dataItem)}><img src={CopyIcon} />
            </li>}
            {actionColumnBtns.includes('delete') && <li
                className={isDisabled('delete:single', accessLevel, selectedState) || dataItem.txnID === null ? 'disabled' : ''}
                onClick={() => {
                    setDeletePopup({
                        show: true,
                        data: props.dataItem,
                        type: 'single'
                    })
                }}>
                <Tooltip position='top' >
                    <img title='Delete' alt="Delete Icon" src={DeleteIcon} />
                </Tooltip>
            </li>}
        </ul>
    )
}

const ActionCell = props => {

    if (props.rowType === "groupHeader") {
        return null;
    }
    const { accessLevel, selectedState, actionColumnBtns, actionColumnHandler, setDeletePopup, currentModuleName } = props;

    return (
        <td
            style={props.style} // this applies styles that lock the column at a specific position
            className={props.className} // this adds classes needed for locked columns
            colSpan={props.colSpan}
            role={"gridcell"}
            aria-colindex={props.ariaColumnIndex}
            aria-selected={props.isSelected}
        >
            <ActionColumnBtns
                {...props}
                accessLevel={accessLevel}
                selectedState={selectedState}
                actionColumnBtns={actionColumnBtns}
                actionColumnHandler={actionColumnHandler}
                setDeletePopup={setDeletePopup}
                currentModuleName={currentModuleName}
            />
        </td>
    )
}

const renderActionCell = (props, accessLevel, selectedState, actionColumnBtns, actionColumnHandler, setDeletePopup, module) => {
    if (props.rowType === "groupHeader" || props.rowType === "groupFooter") {
        return null;
    }

    return (
        <ActionCell
            {...props}
            accessLevel={accessLevel}
            selectedState={selectedState}
            actionColumnBtns={actionColumnBtns}
            actionColumnHandler={actionColumnHandler}
            setDeletePopup={setDeletePopup}
            currentModuleName={module}
        />
    )
}

export default renderActionCell