import React, { useEffect, useState, useRef } from 'react';
import DeviceAuditHeader from "./DeviceAuditHeader";
import { apiProvider } from '../../../../services/api/provider';
import { formatDate } from '../../../common/utils';
import * as XLSX from 'xlsx';
const dateFormat = 'YYYY/MM/DD';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import SiteLoader from '../../../common/SiteLoader';

export const DeviceAuditReport = ({ auditData, selectedReport, reportConfig, reportForData, orgParams }) => {
    const [auditReport, setAuditReport] = useState(auditData?.auditReport || []);
    const [deviceAudit, setDeviceAudit] = useState(auditData?.deviceAudit || []);
    const [searchTerm, setSearchTerm] = useState('');
    const [popUp, setPopUp] = useState(null);
    const [showLoader, setShowLoader] = useState(false);
    const childRef = useRef();
    const startDate = new Date(orgParams.date_fr);
    const endDate = new Date(orgParams.date_to);

    startDate.setHours(0, 0, 0)
    endDate.setHours(23, 59, 0)

    const [generalSettingsData, setGeneralSettingsData] = useState({
        dateDisplayFormat: 'dd/MM/yyyy',
        timeFormat: 'hh:mm:ss a',
        timezone: 'GMT+05:30'
    })

    const deviceAuditRef = useRef(null);
    const auditReportRef = useRef(null);

    const [params, setParams] = useState({
        date_fr: startDate,
        date_to: endDate,
        reportFor: 'all',
        tzStr: orgParams.tzStr,
        device_tz_selected: "",
        offsetStr: "",
        limit: "",
        advance: "no",
        adpOption: orgParams.adpOption===""?"all":orgParams.adpOption,
        accountSelected: localStorage.getItem("accountId"),
        action: orgParams.action===""?"all":orgParams.action,
        isV3Report: true,
    })

    const refreshFunc = async () => {
        setShowLoader(true);
        try {
            const queryParams = {
                ...params,
                date_fr: `${formatDate(params.date_fr, dateFormat)}/${params.date_fr.toTimeString().slice(0, 5)}`,
                date_to: `${formatDate(params.date_to, dateFormat)}/${params.date_to.toTimeString().slice(0, 5)}`,
            };
            const response = await apiProvider.getAll(`/track/mobile/v1/allgeo/deviceAuditEmployeeBasedJSON`, queryParams);
            setAuditReport(response.auditReport);
            setDeviceAudit(response.deviceAudit);
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false);
        }
    };

    useEffect(() => {
        refreshFunc();
    }, []);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredDeviceAudit = deviceAudit.filter(item =>
        Object.values(item).some(value =>
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    const filteredAuditReport = auditReport.filter(item =>
        Object.values(item).some(value =>
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    const handleExportToExcel = () => {
        const wb = XLSX.utils.book_new();
        const headerMapping = {
            'termsCondition': 'term and condition',
        };
        const auditReportCopy = auditReport.map(item => {
            const newItem = {};
            Object.keys(item).forEach(key => {
                newItem[headerMapping[key] || key] = item[key];
            });
            return newItem;
        });

        const auditReportWS = XLSX.utils.json_to_sheet(auditReportCopy);
        const deviceAuditCopy = [...deviceAudit];
        const totals = {
            created: deviceAuditCopy.reduce((acc, item) => acc + parseInt(item.created), 0),
            deleted: deviceAuditCopy.reduce((acc, item) => acc + parseInt(item.deleted), 0),
            net: deviceAuditCopy.reduce((acc, item) => acc + parseInt(item.net), 0),
        };
        deviceAuditCopy.push({
            date: 'Total',
            beginCount: '',
            created: totals.created,
            deleted: totals.deleted,
            net: totals.net,
            endCount: '',
        });
        const deviceAuditWS = XLSX.utils.json_to_sheet(deviceAuditCopy);
        Object.keys(auditReportCopy[0]).forEach((key, index) => {
            const headingCell = XLSX.utils.encode_cell({ r: 0, c: index });
            auditReportWS[headingCell].s = { font: { bold: true }, fill: { bgColor: { rgb: "88888888" } } };
        });
        Object.keys(deviceAuditCopy[0]).forEach((key, index) => {
            const headingCell = XLSX.utils.encode_cell({ r: 0, c: index });
            deviceAuditWS[headingCell].s = { font: { bold: true }, fill: { bgColor: { rgb: "88888888" } } };
        });

        XLSX.utils.book_append_sheet(wb, auditReportWS, 'Audit Report');
        XLSX.utils.book_append_sheet(wb, deviceAuditWS, 'Device Audit');

        XLSX.writeFile(wb, 'audit_data.xlsx');
    };

    const handleExportToPDF = () => {
        const pdf = new jsPDF('p', 'pt', 'letter');
        const lineHeight = 10

        const renderDeviceAuditTableToPDF = () => {
            html2canvas(deviceAuditRef.current, { scale: 2, logging: true, windowWidth: document.body.scrollWidth, windowHeight: document.body.scrollHeight }).then(canvas => {
                const imgData = canvas.toDataURL('image/jpeg', 1.0);
                pdf.addImage(imgData, 'JPEG', 10, 10 + 3 * lineHeight, 592, 150);
                pdf.addPage();
                renderAuditReportTableToPDF();
            });
        };

        const renderAuditReportTableToPDF = () => {
            html2canvas(auditReportRef.current, { scale: 2, logging: true, windowWidth: document.body.scrollWidth, windowHeight: document.body.scrollHeight }).then(canvas => {
                const imgData = canvas.toDataURL('image/jpeg', 1.0);
                pdf.addImage(imgData, 'JPEG', 10, 10 + 3 * lineHeight, 592, 150);
                savePDF();
            });
        };
        const savePDF = () => {
            pdf.save('audit_data.pdf');
        };
        renderDeviceAuditTableToPDF();
    };

    return (
        <div>
            <DeviceAuditHeader generalSettingsData={generalSettingsData} params={params} setParams={setParams} selectedReport={selectedReport} reportConfig={reportConfig} reportForData={reportForData} refreshFunc={refreshFunc} handleSearchChange={handleSearchChange} searchTerm={searchTerm} handleExportToExcel={handleExportToExcel} handleExportToPDF={handleExportToPDF}
                gridRef={childRef.current} deviceAudit={deviceAudit} auditReport={auditReport} setShowLoader={setShowLoader}
            />
            <div className='DeviceAudit' ref={deviceAuditRef}>
                <h4><b>DEVICE AUDIT</b></h4>
                <table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Begin Count</th>
                            <th>Created</th>
                            <th>Deleted</th>
                            <th>Net</th>
                            <th>End Count</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredDeviceAudit.length > 0 ? (
                            filteredDeviceAudit.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.date}</td>
                                    <td>{item.beginCount}</td>
                                    <td>{item.created}</td>
                                    <td>{item.deleted}</td>
                                    <td>{item.net}</td>
                                    <td>{item.endCount}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="7">
                                    <p className="noData">No data found</p>
                                </td>
                            </tr>
                        )}
                        {filteredDeviceAudit.length > 0 && (
                            <tr>
                                <td></td>
                                <td>Total</td>
                                <td></td>
                                <td>
                                    {filteredDeviceAudit.reduce((acc, item) => acc + parseInt(item.created), 0)}
                                </td>
                                <td>
                                    {filteredDeviceAudit.reduce((acc, item) => acc + parseInt(item.deleted), 0)}
                                </td>
                                <td>
                                    {filteredDeviceAudit.reduce((acc, item) => acc + parseInt(item.net), 0)}
                                </td>
                                <td></td>
                            </tr>
                        )}
                    </tbody>
                </table>


            </div>
            <div className='DeviceAudit' ref={auditReportRef}>
                <h4><b>DEVICE AUDIT DATA</b></h4>
                <table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Account ID</th>
                            <th>Device ID</th>
                            <th>Name</th>
                            <th>Action</th>
                            <th>Terms & Condtions</th>
                            <th>Privacy</th>
                            <th>SMS Terms</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredAuditReport.length > 0 ? filteredAuditReport.map((item, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.accountId}</td>
                                <td>{item.deviceId}</td>
                                <td>{item.name}</td>
                                <td>{item.action}</td>
                                <td>{item.termsCondition}</td>
                                <td>{item.privacy}</td>
                                <td>{item.smsTerms}</td>
                                <td>{item.date}</td>
                            </tr>
                        )) : (
                            <tr>
                                <td colSpan="9">
                                    <p className='noData'>No data found</p>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <SiteLoader isActive={showLoader} />
        </div>
    )
}
