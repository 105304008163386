import React, { useState, useEffect, useRef, Fragment } from 'react';
import KendoGrid from '../../common/kendo/KendoGrid';
import Header from '../../layout/Header';
import { apiProvider } from '../../../services/api/provider';
import { getAccessLevel } from '../../common/functions';
import { managerColumns } from './managersUtils';
import SiteLoader from '../../common/SiteLoader';
import { useDocumentTitle } from '../../../hooks/setDocumentTitle';
import VerifyOTP from './VerifyOTP';
import CopyManagerDetails from './CopyManagerDetails';
import ViewManager from './ViewManager';
import EditManager from './EditManager';
import AddNewPopup from './AddNewPopup';
import ToastMessage from '../../common/ToastMessage';

const Managers = () => {
    useDocumentTitle("Managers - allGeo")

    const [mode, setMode] = useState({
        title: "Managers",
        type: 'main',
        data: null,
        hasPrev: false
    });

    const [managers, setManagers] = useState(null)
    const [accessLevel, setAccessLevel] = useState({});
    const [showLoader, setShowLoader] = useState(false);
    const [showAddNewPopup, setShowAddNewPopup] = useState(false);
    const [mobileManage, setMobileManage] = useState(false);
    const [otpPopup, setOtpPopup] = useState({
        show: false,
        data: null
    });

    const [copyManagerPopup, setCopyManagerPopup] = useState({
        show: false,
        data: null
    });

    const [toastMsg, setToastMsg] = useState({
        msg: "",
        type: "msgSuccess"
    });
    const [isUpdated, setIsUpdated] = useState(false)


    useEffect(() => {
        getAllManagers()
        getAccessLevel("company", "managers").then((response) => {
            setAccessLevel(response);
        });
    }, []);

    const getAllManagers = async () => {
        try {
            setShowLoader(true)
            const response = await apiProvider.getAll('/track/mobile/v1/allgeo/manager')
            setManagers(response.managers)
            setMobileManage(response.mobileManage);
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    const updateManagers = async data => {
        try {
            localStorage.removeItem('formIsDirty')
            return await apiProvider.put("/track/mobile/v1/allgeo/manager", data)
        } catch (error) {
            console.log(error);
        }
    }

    const deleteManagers = async data => {

        const payload = data.length ? data : [data.managerID]

        try {
            setShowLoader(true)
            const response = await apiProvider.removeObject('/track/mobile/v1/allgeo/manager', payload)
            if (data.length)
                response.message = "The selected records have been deleted successfully";

            return response;
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    const changeOTPPreference = async (managerID, otpPreference) => {
        try {
            const payload = {
                managerID,
                otpPreference
            }

            const response = await apiProvider.post('/track/mobile/v1/allgeo/manager/2fa?command=changeOTPPreference', payload)

            if (response.status === 200) {
                setToastMsg({
                    type: 'msgSuccess',
                    msg: response.message
                })
            } else {
                setToastMsg({
                    type: 'msgError',
                    msg: response.message
                })
            }
        } catch (error) {
            console.log(error);
        }
    }

    const actionHandler = (type, data) => {
        switch (type) {
            case "view":
                setMode({
                    title: "View Managers Information",
                    type: 'view',
                    data: data,
                    hasPrev: false
                })
                break;
            case "edit":
                setMode({
                    title: "Edit Managers",
                    type: 'edit',
                    data: data,
                    hasPrev: false
                })
                break;
            case "copy":
                setCopyManagerPopup({
                    show: true,
                    data
                })
                break;
            default:
                break;
        }
    }

    const openPopup = (type, data) => {
        switch (type) {
            case "addNew":
                setShowAddNewPopup(true)
                break;
            case "otp":
                setOtpPopup({
                    show: true,
                    data
                })
                break;
            case "sms":
                if (data.phoneVerified) {
                    changeOTPPreference(data.managerID, type)
                } else {
                    setToastMsg({
                        type: "",
                        msg: "Please verify manager's Phone Number to receive OTP via SMS"
                    })
                }
                break;
            case "email":
                if (data.contactEmail.trim().length > 0) {
                    changeOTPPreference(data.managerID, type)
                } else {
                    setToastMsg({
                        type: "",
                        msg: "Please add manager's Email ID to receive OTP via Email"
                    })
                }
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        if (toastMsg.msg && toastMsg.type === "msgSuccess") {
            if (childRef.current) {
                childRef.current.refreshData()
            } else if (!childRef.current) {
                setIsUpdated(true)
            }
            setShowLoader(false)
        }
    }, [toastMsg])

    const childRef = useRef()

    return (
        <>
            <div className='mainContentWrap'>
                <Header title={mode.title} />
                <div className='mainContent' id='mainContent'>
                    <Fragment>
                        {mode.type === 'main' &&
                            <Fragment>
                                {
                                    managers &&
                                    <KendoGrid
                                        gridData={managers}
                                        setGridData={setManagers}
                                        columnData={managerColumns}
                                        accessLevel={accessLevel}
                                        ref={childRef}
                                        module={"managers"}
                                        getAPiUrl={"/track/mobile/v1/allgeo/manager"}
                                        openPopup={openPopup}
                                        updateItem={updateManagers}
                                        deleteItem={deleteManagers}
                                        lockActionColumn={true}
                                        actionColumnHandler={actionHandler}
                                        bulkDelete={deleteManagers}
                                        otherData={{
                                            defaultSortedColumns: [{ field: 'creationTime', dir: 'desc' }],
                                            actionColumnBtns: ['view', 'edit', 'copy', 'delete'],
                                            isUpdated,
                                            setIsUpdated,
                                            exportFileName: "Managers"
                                        }}
                                    />
                                }
                                {otpPopup.show && <VerifyOTP data={otpPopup.data} setOtpPopup={setOtpPopup} setToastMsg={setToastMsg} setShowLoader={setShowLoader} />}
                                {copyManagerPopup.show && <CopyManagerDetails mobileManage={mobileManage} data={copyManagerPopup.data} setCopyManagerPopup={setCopyManagerPopup} setToastMsg={setToastMsg} setShowLoader={setShowLoader} />}

                            </Fragment>
                        }
                        {(mode.type === 'view') &&
                            <ViewManager managerId={mode.data.managerID} setShowLoader={setShowLoader} setMode={setMode} setToastMsg={setToastMsg} accessLevel={accessLevel.childMenu} />
                        }
                        {(mode.type === 'edit') &&
                            <EditManager mobileManage={mobileManage} managerId={mode.data.managerID} setShowLoader={setShowLoader} setMode={setMode} setToastMsg={setToastMsg} mode={mode} />
                        }
                        {showAddNewPopup && <AddNewPopup mobileManage={mobileManage} setShowLoader={setShowLoader} setToastMsg={setToastMsg} closePopup={() => setShowAddNewPopup(false)} />}
                    </Fragment>
                </div>
            </div>
            {toastMsg.msg && <ToastMessage
                cssClass={toastMsg.type}
                message={toastMsg.msg}
                onClose={() => setToastMsg({ msg: "", type: "" })}
            />}
            <div className={`overlay ${(otpPopup.show || copyManagerPopup.show || showAddNewPopup) ? 'active' : ''}`} />
            <SiteLoader isActive={showLoader} />
        </>
    )
}

export default Managers