import React, { useEffect, useState } from "react";
import { apiProvider } from '../../../services/api/provider';
import SiteLoader from '../../common/SiteLoader';
import { ConfirmPopup } from "../../common/PopUp";
import ToastMessage from '../../common/ToastMessage';
import { set } from "react-hook-form";

const SettingsLabels = (props) => {
    const [showLoader, setShowLoader] = useState(false);
    const [accountData, setAccountData] = useState({});
    const [accountLabel, setAccountLabel] = useState({});
    const [initialData, setInitialData] = useState({});
    const [isModified, setIsModified] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [defaultName, setDefaultName] = useState({});
    const [updatedHeaderName, setUpdatedHeaderName] = useState({});
    const [duplicateFields, setDuplicateFields] = useState([]);
    const [reportDuplicateFields, setReportDuplicateFields] = useState([]);
    const [isUpdateSuccessful, setIsUpdateSuccessful] = useState(null);
    const [msgClass, setMsgClass] = useState('msgError');
    const [message, setMessage] = useState('');

    const defaultKeys = [
        "Task Id", "Employee Email", "Employee ID", "Employee Name", "Emp First Name",
        "Emp Last Name", "Emp Rate", "End TaskId", "End Task", "Group", "JobSite ID",
        "Start TaskId", "Start Task", "Task", "Task Id", "Task Rate", "Wo Start Date",
        "WorkOrder End Time", "WorkOrder Message", "WorkOrder ID", "Workorder Assigned",
        "Workorder Allotted Duration", "WorkOrder Rate", "Workorder No", "WorkOrder Start Time",
        "Task Reference"
    ];

    const defaultLabels = {
        device_title: "Employee",
        device_group_title: "Group",
        workOrder_title: "Work Order",
        job_title: "Jobsite",
        task_title: "Task"
    };

    const fetchData = async () => {
        setShowLoader(true);
        try {
            const response = await apiProvider.getAll('track/react/account/generalSetting');
            if (response) {
                setAccountData(response);
                const { address_title, ...nomenclature } = response.nomenclature;
                setAccountLabel(nomenclature);
                setInitialData(nomenclature); // Save initial data to compare changes
            }
        } catch (error) {
            console.error(error);
        } finally {
            setShowLoader(false);
        }
    };

    const getDefaultHeaderName = async () => {
        try {
            setShowLoader(true);
            const response = await apiProvider.getAll('track/mobile/v1/allgeo/getDefaultReportColumnNames');
            setDefaultName(response);
        } catch (error) {
            console.error("defaultName Error:", error);
        } finally {
            setShowLoader(false);
        }
    };

    const getUpdatedHeaderName = async () => {
        try {
            const response = await apiProvider.getAll('track/mobile/v1/allgeo/getEditReportColumnNames');
            setUpdatedHeaderName(response);
        } catch (error) {
            console.error("defaultName Error:", error);
        }
    };

    useEffect(() => {
        fetchData();
        getDefaultHeaderName();
        getUpdatedHeaderName();
    }, []);

    useEffect(() => {
        if (props.accessLevel.childMenu && props.accessLevel.childMenu > 1) {
            props.formState(isModified)
        }
        if (isModified) {
            localStorage.setItem('formIsDirty', isModified)
        }
    }, [isModified]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log("name",name)
        // if()
        const updatedLabel = { ...accountLabel, [name]: value };

        setAccountLabel(updatedLabel);
        setIsModified(true);

        // Check for duplicates
        const values = Object.values(updatedLabel);
        const duplicates = values.filter((item, index) => values.indexOf(item) !== index && item !== '');
        const duplicateKeys = Object.keys(updatedLabel).filter(key => duplicates.includes(updatedLabel[key]));
        setDuplicateFields(duplicateKeys);
    };

    useEffect(() => {
        if (duplicateFields.length > 1) {
            setIsModified(false)
        }
        if (reportDuplicateFields.length > 1) {
            setIsModified(false)
        }
    }, [duplicateFields,reportDuplicateFields])

    const handleReportChange = (key, value) => {
        console.log("key name:", key, ",value name:", value)
        if (key === "message") {
            setUpdatedHeaderName(prevState => ({
                ...prevState,
                messageV3: value,
                v3Msg:value,
                [key]: value
            }));
        }
        else if (key === "qrScan") {
            setUpdatedHeaderName(prevState => ({
                ...prevState,
                v3QrScan: value,
                [key]: value
            }));
        }
        // else if (key === "task") {
        //     setUpdatedHeaderName(prevState => ({
        //         ...prevState,
        //         Task: value,
        //         [key]: value
        //     }));
        // }
        else if (key === "picture") {
            setUpdatedHeaderName(prevState => ({
                ...prevState,
                v3Picture: value,
                [key]: value
            }));
        }
        else if (key === "dayName") {
            setUpdatedHeaderName(prevState => ({
                ...prevState,
                day: value,
                [key]: value
            }));
        }
        else if (key === "formData") {
            setUpdatedHeaderName(prevState => ({
                ...prevState,
                v3FormData: value,
                [key]: value
            }));
        }
        else {
            setUpdatedHeaderName(prevState => ({
                ...prevState,
                [key]: value
            }));
        }
        setIsModified(true);

    };

    useEffect(() => {
        reportDuplicate()

    }, [updatedHeaderName])

    //for reports duplicate function
    const reportDuplicate = () => {
        const filteredUpdatedHeaderName = Object.fromEntries(
            Object.entries(updatedHeaderName).filter(([key, value]) => key !== 'tcsentDate'  && key !== "endMsg" && key !== "v3Msg" && key !== "day"  && key !== 'tcsentTime' && key !== 'messageV3' && key !== 'Task' && key !== 'v3QrScan' && key !== 'v3Task' && key !== 'v3Picture' && key !== 'v3FormData')
        );

        const values = Object.values(filteredUpdatedHeaderName);
        const duplicates = values.filter((item, index) => values.indexOf(item) !== index && item !== '');
        const duplicateKeys = Object.keys(updatedHeaderName).filter(key => duplicates.includes(updatedHeaderName[key]));
        setReportDuplicateFields(duplicateKeys)
    }

    const handleSave = async () => {
        const { address_title, ...nomenclature } = accountLabel; // Exclude address_title
        const payload = {
            ...accountData,
            nomenclature,
            report_units: {
                ...accountData.report_units,
                distance: accountData.report_units.distance.toString(),
                speed: accountData.report_units.speed.toString(),
                temperature: accountData.report_units.temperature.toString(),
                latlog_format: accountData.report_units.latlog_format.toString(),
            }
        };
        localStorage.setItem('accountLabel', JSON.stringify(nomenclature));

        if (payload) {
            try {
                let res = await apiProvider.put('track/react/account/updateGeneralSetting', payload);
                if (res.status === 200) {
                    setIsUpdateSuccessful(true)
                    setMsgClass("msgSuccess")
                    setMessage("The changes have been saved")
                    fetchData();
                    setIsModified(false);
                }
                if (res.status != 200) {
                    setIsUpdateSuccessful(true)
                    setMsgClass("msgError")
                    setMessage("error")
                }
            } catch (error) {
                console.error("Error saving data", error);
            }
        }

        if (updatedHeaderName) {
            try {
                setShowLoader(true);
                let res = await apiProvider.post('/track/mobile/v1/allgeo/saveEditReportColumnNames', updatedHeaderName);
                if (res.StatusCode === 200) {
                    setIsUpdateSuccessful(true)
                    setMsgClass("msgSuccess")
                    setMessage("The changes have been saved")
                }
                if (res.StatusCode != 200) {
                    setIsUpdateSuccessful(true)
                    setMsgClass("msgError")
                    setMessage("error")
                }
            } catch (error) {
                console.error("defaultName Error:", error);
            } finally {
                setShowLoader(false);
            }
        }

    };

    const handleCancel = () => {
        if (isModified) {
            setShowDialog(true);
        }
    };

    const onSuccess = () => {
        setShowDialog(false);
        fetchData();
        getDefaultHeaderName();
        getUpdatedHeaderName();
    };

    const onFailure = () => {
        setShowDialog(false);
    };

    const handleSetToDefault = () => {
        setAccountLabel(defaultLabels);
        setIsModified(true);
    };

    const handleReportReset = async () => {
        try {
            setShowLoader(true);
            const response = await apiProvider.post('/track/mobile/v1/allgeo/saveEditReportColumnNames', defaultName);
            if (response.StatusCode === 200) {
                setIsUpdateSuccessful(true)
                setMsgClass("msgSuccess")
                setMessage("Report labels set to default")
                setUpdatedHeaderName(defaultName);
            }
            if (response.StatusCode != 200) {
                setIsUpdateSuccessful(true)
                setMsgClass("msgError")
                setMessage("error")
            }

        } catch (error) {
            console.error("defaultName Error:", error);
        } finally {
            setShowLoader(false);
        }
    }

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid var(--ag-color-04)', textAlign: 'center' }}>
                <h3>Account Labels</h3>
                <div className="colBlk6" style={{ margin: '0px', display: 'flex', justifyContent: 'end' }}>
                    {props.accessLevel.childMenu && props.accessLevel.childMenu > 1 &&
                        <div className="btnStyle btn2" role="button" disabled={!isModified} onClick={handleSetToDefault}>
                            Set to Default
                        </div>
                    }
                </div>
            </div>
            <div className="supportedTxt">Customize your account to use terminology relevant to your industry or business</div>
            <div className="innerWrap">
                <div className="rowBlk">
                    <div className="colBlk6 fldWrap">
                        <label>Employee Label (eg: Employee, Nurse, etc.)</label>
                        <input
                            maxLength="15"
                            type="text"
                            name="device_title"
                            value={accountLabel.device_title || ''}
                            onChange={handleChange}
                            style={{ borderColor: duplicateFields.includes('device_title') ? 'red' : '' }}
                        />
                    </div>
                    <div className="colBlk6 fldWrap">
                        <label>Group Label (eg: Group, Forum, Caregiver, etc.)</label>
                        <input
                            maxLength="15"
                            type="text"
                            name="device_group_title"
                            value={accountLabel.device_group_title || ''}
                            onChange={handleChange}
                            style={{ borderColor: duplicateFields.includes('device_group_title') ? 'red' : '' }}
                        />
                    </div>
                </div>
                <div className="rowBlk">
                    <div className="colBlk6 fldWrap">
                        <label>Work Order Label (eg: Work Order, Job, Task, etc.)</label>
                        <input
                            maxLength="15"
                            type="text"
                            name="workOrder_title"
                            value={accountLabel.workOrder_title || ''}
                            onChange={handleChange}
                            style={{ borderColor: duplicateFields.includes('workOrder_title') ? 'red' : '' }}
                        />
                    </div>
                    <div className="colBlk6 fldWrap">
                        <label>Jobsite Label (eg: Location, Site, etc.)</label>
                        <input
                            maxLength="15"
                            type="text"
                            name="job_title"
                            value={accountLabel.job_title || ''}
                            onChange={handleChange}
                            style={{ borderColor: duplicateFields.includes('job_title') ? 'red' : '' }}
                        />
                    </div>
                </div>
                <div className="rowBlk">
                    <div className="colBlk6 fldWrap">
                        <label>Task Label (eg: Task, Phase, Work, etc.)</label>
                        <input
                            maxLength="15"
                            type="text"
                            name="task_title"
                            value={accountLabel.task_title || ''}
                            onChange={handleChange}
                            style={{ borderColor: duplicateFields.includes('task_title') ? 'red' : '' }}
                        />
                    </div>
                </div>
                {props.accessLevel.childMenu && props.accessLevel.childMenu > 1 && <div className="mainBtnWrap">
                    <a onClick={handleCancel}>Cancel</a>
                    <button className="btnStyle" onClick={handleSave} disabled={!isModified}>SAVE</button>
                </div>}
            </div>
            {/* Report label section */}
            <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid var(--ag-color-04)', textAlign: 'center', marginTop: '10px' }}>
                <h3>Reports Labels</h3>
                {props.accessLevel.childMenu && props.accessLevel.childMenu > 1 &&
                    <div className="colBlk6" style={{ margin: '0px', display: 'flex', justifyContent: 'end' }}>
                        <div className="btnStyle btn2" role="button" onClick={handleReportReset}>
                            Set to Default
                        </div>
                    </div>
                }
            </div>
            <div className="innerWrap">
                <div className="rowBlk" style={{ marginTop: "20px" }}>
                    {/* filtered the key which are duplicate */}
                    {defaultName && Object.entries(defaultName)
                        .filter(([key, value]) => key !== 'tcsentDate' && key !== "endMsg" && key !== "v3Msg" && key !== "day"  && key !== 'tcsentTime' && key !== 'messageV3' && key !== 'Task' && key !== 'v3QrScan' && key !== 'v3Task' && key !== 'v3Picture' && key !== 'v3FormData')
                        .map(([key, value]) => (
                            <div className="colBlk6 fldWrap" key={key}>
                                <label htmlFor={key}>{value}</label>
                                <input
                                    type="text"
                                    id={key}
                                    placeholder={value}
                                    value={updatedHeaderName[key] || ""}
                                    onChange={e => handleReportChange(key, e.target.value)}
                                    disabled={defaultKeys.includes(value)}
                                    style={{ borderColor: reportDuplicateFields.includes(key) ? 'red' : '' }}
                                />
                            </div>
                        ))}
                </div>
            </div>
            {
                isUpdateSuccessful &&
                <ToastMessage cssClass={msgClass} message={message}
                    onClose={() => { setIsUpdateSuccessful(false); }}
                />
            }
            <SiteLoader isActive={showLoader} />
            <ConfirmPopup
                showDialog={showDialog}
                message={"The changes are still unsaved. Are you sure you want to leave?"}
                confirmNavigation={onSuccess}
                cancelNavigation={onFailure}
            />
        </>
    );
};

export default SettingsLabels;
