import React, {useState} from "react";
import Header from "../../layout/Header";
import './active-schedule.css'
import { CurrentSchedule } from "./CurrentSchedule";
import {FutureSchedule} from "./FutureSchedule";
import {ScheduleHistory} from "./ScheduleHistory";
import { useDocumentTitle } from '../../../hooks/setDocumentTitle';

export const ActiveSchedulesWrapper = ()=>{
    useDocumentTitle("Active Schedules | allGeo");
    const [selectedTab, setSelectedTab] = useState('current')

    const handleTabClick = (tab, event) => {
        event.preventDefault();
        setSelectedTab(tab);
    };
    return (
        <div className="mainContentWrap">
            <Header title="Active Schedules" />
            <div className="mainContent">
                <ul className="inner_switch_tabs">
                    <li className={`left_tab`}>
                        <a href="/" className={` ${selectedTab === 'current' ? 'active' : ''}`} onClick={(e) => handleTabClick('current', e)}>Current Schedule</a>
                    </li>
                    <li className={`mid_tab`}>
                        <a href="/" className={` ${selectedTab === 'future' ? 'active' : ''}`} onClick={(e) => handleTabClick('future', e)}>Future Schedule</a>
                    </li>
                    <li className={`right_tab`}>
                        <a href="/" className={` ${selectedTab === 'history' ? 'active' : ''}`} onClick={(e) => handleTabClick('history', e)}>Schedule History</a>
                    </li>
                </ul>

                {
                    selectedTab === 'current' && <CurrentSchedule />
                }
                {
                    selectedTab === 'future' && <FutureSchedule />
                }
                {
                    selectedTab === 'history' && <ScheduleHistory />
                }
            </div>
        </div>
    )
}