import React from "react";
import webLogo from "../assets/images/logo-web.svg";
import { NavLink } from "react-router-dom"

export default function SiteLogo() {
  return (
    <h1>
      <NavLink to="/home">
      <img src={webLogo} alt="allGeo" /> 
      </NavLink>
    </h1>
  );
}
