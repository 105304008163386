export const DeductionCodes = require('./deduction_codes.csv');
export const DeductionCodesXlsx = require('./deduction_codes_xls.xlsx');
export const EarningCodes = require('./earning_codes.csv');
export const EarningCodesXlsx = require('./earning_codes_xlsx.xlsx');
//earning code and EmployeePhoneNumber is pending
export const EmployeePhoneNumber = require('./employee-phone-numbers.csv');
export const EmployeeSample = require('./employee-sample_xls.xlsx')
export const JobTemplate = require('./job-template.csv');
export const JobTemplateXlsx = require('./job-template_xls.xlsx');
export const PayrollProfile = require('./payroll-profile.csv');
export const PayrollProfileXlsx = require('./payroll-profile_xls.xlsx');
export const SampleWorkorder = require('./sample_workorder_template.csv');
export const ScheduleDetails = require('./schedule_details.csv');
export const TaskDetails = require('./task_details.csv');
export const TaskDetailsXlsx = require('./task_details_xls.xlsx');
