import React, { useState } from "react";
import LeftContent from "../login/LeftContent";
import { Link, useHistory } from "react-router-dom";
import useInput from '../../hooks/useInput';
import ToastMessage from '../common/ToastMessage';
import { apiProvider } from '../../services/api/provider';
import ResetPasswordSuccess from './ResetPasswordSuccess';
import { useDocumentTitle } from '../../hooks/setDocumentTitle';

const ResetPassword = () => {
  let formIsValid = false;
  useDocumentTitle("Reset Password - allGeo");
  const [isLoginError, setIsLoginError] = useState(false);
  const [loginErrorMsg, setLoginErrorMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isRestPasswordSuccess, setRestPasswordSuccess] = useState(false);
  const history = useHistory();

  const isNotEmpty = (val) => {
    return val.trim() !== '';
  }
  const {
    value: enteredEmail,
    isValid: enteredEmailIsValid,
    hasError: enteredEmailHasError,
    valueChangeHandler: enteredEmailChangedHandler,
    inputBlurHandler: enteredEmailBlurHandler,
    inputFocusHandler: enteredEmailFocusHandler,
    isFocused: emailIsFocused,
    reset: resetEmail,
  } = useInput(value => value.trim() !== '' && /\S+@\S+\.\S+/.test(value.trim()));
  let emailInputClasses = enteredEmailHasError ? 'errorFld' : emailIsFocused ? 'active' : '';

  const {
    value: enteredAccountId,
    isValid: enteredAccountIdIsValid,
    hasError: enteredAccountIdHasError,
    valueChangeHandler: enteredAccountIdChangedHandler,
    inputBlurHandler: enteredAccountIdBlurHandler,
    inputFocusHandler: enteredAccountIdFocusHandler,
    isFocused: accountIdIsFocused,
    reset: resetAccountId,
    setDefault: setDefaultValueAccountId,
  } = useInput(isNotEmpty);

  let accountIdInputClasses = enteredAccountIdHasError ? 'errorFld' : accountIdIsFocused ? 'active' : '';
  
  const {
    value: enteredUserName,
    isValid: enteredUserNameIsValid,
    hasError: enteredUserNameHasError,
    valueChangeHandler: enteredUserNameChangedHandler,
    inputBlurHandler: enteredUserNameBlurHandler,
    inputFocusHandler: enteredUserNameFocusHandler,
    isFocused: userNameFocused,
    reset: resetAccountName
  } = useInput(isNotEmpty);
  let userNameInputClasses = enteredUserNameHasError ? 'errorFld' : userNameFocused ? 'active' : '';

  /* form validation logic*/
  if (enteredAccountIdIsValid && enteredUserNameIsValid && enteredEmailIsValid) {
    formIsValid = false; //if all fields are filled up then invalid
  } else if (!enteredAccountIdIsValid && !enteredUserNameIsValid && !enteredEmailIsValid) {
    formIsValid = false; //if no fields are filled up then invalid
  } else if (enteredEmailIsValid && (enteredAccountIdIsValid || enteredUserNameIsValid)) {
    formIsValid = false; // if email is valid with any of the ac info then invalid
  } else if (!enteredEmailIsValid && (!enteredAccountIdIsValid || !enteredUserNameIsValid)) {
    formIsValid = false; // if email is invalid and any of the ac info is missing then invalid 
  } else {
    formIsValid = true;
  }

  const forgotPasswordHandler = (event) => { 
    event.preventDefault();
    if (!formIsValid || isLoading) {
      return;
    } else {
      if (enteredUserName && enteredAccountId && enteredEmail) {
        handelErrorMsg('Please provide either email address or account id and user name.')
      } else {

        let resetPasswordForm = {};

        if (enteredUserName && enteredAccountId) {
          resetPasswordForm.accountId = enteredAccountId.trim();
          resetPasswordForm.userId = enteredUserName.trim();
        }
        if (enteredEmail) {
          resetPasswordForm.emailId = enteredEmail.trim();
        }

        setIsLoading(true);
        apiProvider.post('track/react/v1/resetpassword',resetPasswordForm).then((response) => {
          setIsLoading(false);
          switch(response.status) {
            case 200:
              handelSuccessEvent(response);
              break;
            case 401:
              handelErrorMsg(response.message);
              break;
            case 45:
              history.replace('/trial-account-expired');
              break;
            default:
              handelErrorMsg('Network Error! Please try after some time.');
          }
        });
      }
    }
    
  };

  const handelErrorMsg = (serverMessage) => {
    let msg = serverMessage;
    setIsLoginError(true);
    if (serverMessage == 'Invalid Credentials') {
      msg = <>Credentials are invalid. If you don't remember your password, <Link to='/reset-password'>reset it now.</Link></>;
    }
    setLoginErrorMsg(msg);
  }

  const handelSuccessEvent = (res) => {
    setRestPasswordSuccess(true);
  }

  const handleEmailKeyUp = () => {
    resetAccountName();
    resetAccountId();
  }

  const handleCommonKeyUp = () => {
    
      resetEmail();
    
  }


  return (
    <div className="userPageWrapper">
      <LeftContent></LeftContent>
      <div className="rightContent">
        {
          !isRestPasswordSuccess && 
          <>
            <h2>Reset Password</h2>
            <form onSubmit={forgotPasswordHandler}>
            <p>To receive a Reset Password link, please enter either:</p>
            <div className={`fldWrap emailFld fldWithIcon ${emailInputClasses}`}>
              <input type="text" placeholder="Email" 
                onFocus={enteredEmailFocusHandler}
                onChange={enteredEmailChangedHandler}
                name="email" id="email" 
                value={enteredEmail}
                  onBlur={enteredEmailBlurHandler}
                  onKeyUp={handleEmailKeyUp}
              />
              <span className="fldIcon">Icon</span>
            </div>
            <div className="orSeparator">
              <span>OR</span>
            </div>
            <div className={`fldWrap accountIdFld fldWithIcon ${accountIdInputClasses}`}>
                <input type="text" placeholder="Account ID"
                  onChange={enteredAccountIdChangedHandler} 
                  value={enteredAccountId} 
                  id="account_id" name="account_id"
                  onBlur={enteredAccountIdBlurHandler}
                  onFocus={enteredAccountIdFocusHandler}
                  onKeyUp={handleCommonKeyUp}
                />
              <span className="fldIcon">Icon</span>
            </div>
            <div className={`fldWrap usernameFld fldWithIcon ${userNameInputClasses}`}>
                <input type="text" placeholder="Username" 
                  onChange={enteredUserNameChangedHandler} 
                  value={enteredUserName} 
                  id="user_name" name="user_name"
                  onBlur={enteredUserNameBlurHandler}
                  onFocus={enteredUserNameFocusHandler}
                  onKeyUp={handleCommonKeyUp}
                />
              <span className="fldIcon">Icon</span>
            </div>
            <button className="btnStyle" disabled={!formIsValid || isLoading}>
              SUBMIT
            </button>
            <Link to="/login">Return to Login</Link>
            </form>
          </>
          
        }
        
        {
          isRestPasswordSuccess &&
          <ResetPasswordSuccess title="Reset Password Link Sent!">
            <p>The instructions to reset your password have been sent. Kindly check your inbox. </p>
          </ResetPasswordSuccess>
        }
      </div>
      {
        isLoginError && <ToastMessage cssClass="msgError" message={loginErrorMsg} onClose={() => { setIsLoginError(false); setLoginErrorMsg(''); }}/>
      }
    </div>
  );
}

export default ResetPassword;
