import React, { useState } from "react";
import { DatePicker, TimePicker } from "@progress/kendo-react-dateinputs";
import { CustomPopup } from "../../../common/PopUp";
import { getDefaultDate, repeatType, weekDaysConfig, reportMedium, getFutureDate, MyPopup, CustomTooltip } from "../reportSchedulerUtils";
import { timeZones } from "../../../common/utils";

const AddForm2 = ({ register, setFormPage, watch, setValue, setToastMsg, dateFormat, timeFormat, errors }) => {

    const [weekConfig, setWeekConfig] = useState(weekDaysConfig.map((day, ind) => ({ ...day, selected: ind === 0 })));

    const handleDateChange = (type, event) => {
        const startTime = watch('scheduleStartOn').getTime()
        const endTime = watch('scheduleEndOn').getTime()
        const scheduleCheck = watch('scheduleCheck')
        const msg = 'Start date must be less than end date'

        if (type === 0) {
            if (scheduleCheck === 'on' && event.value.getTime() > endTime) {
                setToastMsg({ type: "", msg })
                return
            }

            setValue("scheduleStartOn", event.value, { shouldDirty: true })

            if (scheduleCheck === 'never') {
                setValue('scheduleEndOn', getFutureDate(event.value))
            }

        } else {
            if (startTime > event.value.getTime()) {
                setToastMsg({ type: "", msg })
                return
            }

            setValue("scheduleEndOn", event.value, { shouldDirty: true })
        }
    }

    const handleWeekDaysSelection = ind => {
        const tempWeek = [...weekConfig]

        tempWeek[ind].selected = !tempWeek[ind].selected

        setWeekConfig(tempWeek)
        setValue('trackingDays', tempWeek.filter(day => day.selected).map(day => day.value).join('|'))
    }

    const handleTimeChange = (event) => {
        setValue('startTime', event.value, { shouldDirty: true })
    }

    return (
        <>
            <div className="popupContent twoColPopContent">
                <div className="twoColBlk">
                    <div className="leftCol">Schedule Settings</div>
                    <div className="rightCol">
                        <div className="highlightedSection">
                            <div className="fldWrap">
                                <label>Start Date</label>
                                <div className="medFld">
                                    <DatePicker
                                        popup={MyPopup}
                                        placeholder={"Start date"}
                                        value={watch('scheduleStartOn')}
                                        onChange={(e) => handleDateChange(0, e)}
                                        format={dateFormat}
                                        min={getDefaultDate(0)}
                                    />
                                </div>
                            </div>
                            <div className="singleLineFld">
                                <label>Repeat</label>
                                <select className="smlFld" {...register('repeatType')}>
                                    <option value={repeatType.DAILY} >Daily</option>
                                    <option value={repeatType.WEEKLY} >Weekly</option>
                                </select>
                            </div>
                            {watch('repeatType') === repeatType.WEEKLY
                                && <div className="fldWrap">
                                    <label>Select Days</label>
                                    <div className="weekDays">
                                        {
                                            weekConfig.map((day, ind) => (
                                                <span
                                                    key={ind}
                                                    className={day.selected ? 'active' : ''}
                                                    onClick={() => handleWeekDaysSelection(ind)}
                                                >
                                                    {day.label}
                                                </span>
                                            ))
                                        }
                                    </div>
                                </div>}
                            <div className="singleLineFld">
                                <label>Ends</label>
                                <select className="smlFld" {...register('scheduleCheck')}>
                                    <option value={'on'} >On</option>
                                    <option value={'never'} >Never</option>
                                </select>
                                {watch('scheduleCheck') === 'on'
                                    &&
                                    <div className="medFld">
                                        <DatePicker
                                            popup={CustomPopup}
                                            placeholder={"End date"}
                                            value={watch('scheduleEndOn')}
                                            onChange={(e) => handleDateChange(1, e)}
                                            format={dateFormat}
                                            min={getDefaultDate(0)}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="twoColBlk">
                    <div className="leftCol">When do you want to receive the report?</div>
                    <div className="rightCol">
                        <div className="highlightedSection">
                            <div className="rowBlk">
                                <div className="colBlk3">
                                    <div className="fldWrap">
                                        <label>Select Time</label>
                                        <TimePicker
                                            popup={CustomPopup}
                                            value={watch('startTime')}
                                            onChange={(e) => handleTimeChange(e)}
                                            format={timeFormat}
                                            className="medFld"
                                        />
                                    </div>
                                </div>
                                <div className="colBlk4">
                                    <div className="fldWrap">
                                        <label>Select Timezone</label>
                                        <select {...register('timezone')} >
                                            {
                                                timeZones.map((t, ind) => (
                                                    <option key={ind} value={t.value} >{t.label}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="twoColBlk">
                    <div className="leftCol">Report Settings</div>
                    <div className="rightCol">
                        <div className="highlightedSection">
                            <label>Report Medium</label>
                            <div className="customControls fldWrap">
                                <div className="customRadio">
                                    <input type="radio" id="Email" name="ReportMedium" value={reportMedium.EMAIL} {...register('reportOption')} />
                                    <label htmlFor="Email">Email</label>
                                </div>
                                <div className="customRadio">
                                    <input type="radio" id="FTP" name="ReportMedium" value={reportMedium.FTP} {...register('reportOption')} />
                                    <label htmlFor="FTP">FTP</label>
                                </div>
                                <div className="customRadio">
                                    <input type="radio" id="Google" name="ReportMedium" value={reportMedium.GOOGLE} {...register('reportOption')} />
                                    <label htmlFor="Google">Google</label>
                                </div>
                            </div>
                            <div className="fldWrap">
                                <label>Report Format</label>
                                <select className="fldWidthMed" {...register('attachmentType')}>
                                    <option value="CSV">CSV</option>
                                    <option value="PDF">PDF</option>
                                    <option value="XLS">XLS</option>
                                    <option value="HTML">HTML</option>
                                </select>
                            </div>
                            {watch('reportOption') === 'email' &&
                                <>
                                    <label>Notifications Email</label>
                                    <div className="customControls fldWrap">
                                        <div className="customRadio">
                                            <input type="checkbox" id="Account" name="NotificationsEmail" {...register('accountNotificationEmail')} />
                                            <label htmlFor="Account" className="withHelpTxt">Account
                                                <CustomTooltip><span className="helpTip" title="Will show the default account email" >?</span></CustomTooltip>
                                            </label>
                                        </div>
                                        <div className="customRadio">
                                            <input type="checkbox" id="Manager" name="NotificationsEmail" {...register('managerNotificationEmail')} />
                                            <label htmlFor="Manager">Manager</label>
                                        </div>
                                        <div className="customRadio">
                                            <input type="checkbox" id="Device" name="NotificationsEmail" {...register('deviceNotificationEmail')} />
                                            <label htmlFor="Device">Device</label>
                                        </div>
                                    </div>
                                    <div className={`fldWrap ${errors.reportSendToEmails ? 'errFld' : ''}`}>
                                        <label>Additional Email</label>
                                        <input type="text" {...register("reportSendToEmails", { pattern: /\S+@\S+\.\S+/ })} />
                                        {errors.reportSendToEmails && <div className="fldMsg">Please enter a valid email</div>}
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="popupBtnWrap flexJCenter">
                <a className='cursorPointer' onClick={() => setFormPage(1)} >Go Back</a>
                <button className="btnStyle doneStep" disabled={watch('repeatType') === repeatType.WEEKLY && watch('trackingDays')?.trim().length === 0} >
                    Done
                </button>
            </div>
        </>
    )
}

export default AddForm2