import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { apiProvider } from '../../../services/api/provider';
import { DateTimePicker, DatePicker, TimePicker } from "@progress/kendo-react-dateinputs";
import { Tooltip } from '@progress/kendo-react-tooltip';
import CustomPopup from './CustomPopup';
import {
    timeZones, weekConfig, dateTimeExtractor,
    generateWeekRepeatString, getDefaultDateTimeForNonRecurringSchedule, getDefaultDateTimeForRecurringSchedule,
    getMinDateLimit, checkKeyDown
} from './locateScheduleUtils';
import { getAccountLabel } from '../../../utils';

const moment = window.moment;

const AddNewPopup = (props) => {
    
    const { register, handleSubmit, setValue, getValues, setError, watch, formState: { errors } } = useForm(
        {
            mode: "all",
            defaultValues: {
                'locateFor': 'Group',
                'scheduleType': 'Recurring',
                'repeat': 'Daily',
                'track': 'Continuously',
                'status': true,
                'scheduleFor' : 'all'
            }
        }
        );
        
    const [formData, setFormData] = useState({
        'locateFor': 'Group',
        'scheduleType': 'Recurring',
        'repeat': 'Daily',
        'track': 'Continuously',
        'ends': 'On'
    })

    const [weeksConfig, setWeeksConfig] = useState([]);
    const [isFormComplete, setIsFormComplete] = useState(false);

    useEffect(() => {
        let updatedWeekConfig = weekConfig.map((ele) => { return { ...ele, selected: false } })
        setWeeksConfig(updatedWeekConfig)
    }, [])

    useEffect(() => {
        const subscribe = watch(data => {

            let type;
            if (typeof data.scheduleType === 'string') {
                type = data.scheduleType
            } else {
                if (data.scheduleType) {
                    type = 'Recurring'
                } else {
                    type = 'nonRecurring'
                }
            }

            setFormData({
                'locateFor': data.locateFor,
                'scheduleType': type,
                'repeat': data.repeat,
                'track': data.track,
                'ends': data.ends
            })

            if (data?.scheduleId !== "") {
                if (data.scheduleType === true || data.scheduleType === "Recurring") {
                    if (data.repeat === "Weekly" && (getValues("trackingDays") === "" || getValues("trackingDays") === undefined)) {
                        setIsFormComplete(false)
                    } else {
                        setIsFormComplete(true)
                    }
                } else {
                    setIsFormComplete(true)
                }
            } else {
                setIsFormComplete(false)
            }
        })

        return () => subscribe.unsubscribe()
    }, [watch])

    const selectDayOfWeek = (param) => {
        let updatedWeekConfig = weeksConfig.map((ele) => {
            if (ele.day === param) {
                ele.selected = !ele.selected
                return ele
            }
            return ele
        });

        if (updatedWeekConfig.some((ele) => ele.selected) && getValues("scheduleId")) {
            setIsFormComplete(true)
        } else {
            setIsFormComplete(false)
        }

        setValue('trackingDays', generateWeekRepeatString(updatedWeekConfig))

        setWeeksConfig(updatedWeekConfig);
    }

    const handleDateTimeChange = (type, event) => {
        if (type === 0) {
            setValue("startDateTime", event.value)
        } else {
            setValue("endDateTime", event.value)
        }
    }

    const handleTimeChange = (type, event) => {
        if (type === 0) {
            setValue("startTime", moment(event.value).format('HH:mm'))
        } else {
            setValue("endTime", moment(event.value).format('HH:mm'))
        }
    }

    const handleDateChange = (type, event) => {
        if (type === 0) {
            setValue("startDate", moment(event.value).format('YYYY/MM/DD'))
        } else {
            setValue("endDate", moment(event.value).format('YYYY/MM/DD'))
        }
    }

    const getScheduleForData = () => {
        if (formData?.locateFor === "Group") {
            return props.locateForData.groups[0].value
        } else {
            return props.locateForData.employees[0].value
        }
    }

    const onSubmit = async (data) => {

        let startDate, endDate, startTime, endTime, startDateTime, endDateTime;

        if (data.startDate) {
            startDate = data.startDate
        } else {
            startDate = dateTimeExtractor(getDefaultDateTimeForRecurringSchedule(0)).date
        }

        if (data.endDate) {
            endDate = data.endDate
        } else {
            endDate = dateTimeExtractor(getDefaultDateTimeForRecurringSchedule(1)).date
        }

        if (data.startTime) {
            startTime = data.startTime
        } else {
            startTime = dateTimeExtractor(getDefaultDateTimeForRecurringSchedule(0)).time
        }

        if (data.endTime) {
            endTime = data.endTime
        } else {
            endTime = dateTimeExtractor(getDefaultDateTimeForRecurringSchedule(1)).time
        }

        if (data.startDateTime) {
            startDateTime = data.startDateTime
        } else {
            startDateTime = moment(getDefaultDateTimeForNonRecurringSchedule(0))
        }

        if (data.endDateTime) {
            endDateTime = data.endDateTime
        } else {
            endDateTime = moment(getDefaultDateTimeForNonRecurringSchedule(1))
        }

        if (
            formData.scheduleType === "Recurring"
            && formData.ends === "On"
            && moment(`${startDate} ${startTime}`).isAfter(`${endDate} ${endTime}`)
        ) {
            props.setToastMsg({ msg: 'Start date should be before end date.', type: "" })
        } else if (formData.scheduleType !== "Recurring" && moment(startDateTime).isAfter(endDateTime)) {
            props.setToastMsg({ msg: 'Start date & time should be before end date & time.', type: "" })
        } else {

            try {
                props.setShowLoader(true);

                const payload = [{

                    "type": "Locate",
                    "scheduleId": data.scheduleId,
                    "active": data.status,
                    "assignType": formData.locateFor === "Group" ? "Group" : "Device",
                    "scheduleFor": data?.scheduleFor ? data.scheduleFor : getScheduleForData(),
                    "scheduleType": formData.scheduleType === "Recurring" ? "recurring" : "non-recurring",
                    "repeatType": formData.scheduleType === "Recurring" ? (formData.repeat).toLowerCase() : "",
                    "repeatValue": "",
                    "trackingDays": formData.scheduleType === "Recurring" && formData.repeat === "Weekly" ? generateWeekRepeatString(weeksConfig) : "",
                    "scheduleStartOn": formData.scheduleType === "Recurring"
                        ? data?.startDate
                            ? data.startDate
                            : dateTimeExtractor(getDefaultDateTimeForRecurringSchedule(0)).date
                        : "",
                    "scheduleEndOn": formData.ends === "On" ?
                        formData.scheduleType === "Recurring"
                            ? data?.endDate
                                ? data.endDate
                                : dateTimeExtractor(getDefaultDateTimeForRecurringSchedule(1)).date
                            : ""
                        : "Never",
                    "startDate": formData.scheduleType !== "Recurring"
                        ? data?.startDateTime
                            ? dateTimeExtractor(data.startDateTime).date
                            : dateTimeExtractor(getDefaultDateTimeForNonRecurringSchedule(0)).date
                        : "",
                    "endDate": formData.scheduleType !== "Recurring"
                        ? data?.endDateTime
                            ? dateTimeExtractor(data.endDateTime).date
                            : dateTimeExtractor(getDefaultDateTimeForNonRecurringSchedule(1)).date
                        : "",
                    "startTime": formData.scheduleType === "Recurring"
                        ? data?.startTime
                            ? data.startTime
                            : dateTimeExtractor(getDefaultDateTimeForRecurringSchedule(0)).time
                        : data?.startDateTime
                            ? dateTimeExtractor(data.startDateTime).time
                            : dateTimeExtractor(getDefaultDateTimeForRecurringSchedule(0)).time,
                    "endTime": formData.scheduleType === "Recurring"
                        ? data?.endTime
                            ? data.endTime
                            : dateTimeExtractor(getDefaultDateTimeForRecurringSchedule(1)).time
                        : data?.endDateTime
                            ? dateTimeExtractor(data.endDateTime).time
                            : dateTimeExtractor(getDefaultDateTimeForRecurringSchedule(1)).time,
                    "timezone": data.timezone,
                    "runType": formData.track === "Continuously" ? "continues" : "manual",
                    "trackFrequency": formData.track === "Continuously" ? data.frequency : "",
                }]

                const response = await apiProvider.post("track/mobile/v1/allgeo/schedule/create", payload)
                if (response.status === 200) {
                    props.setToastMsg({ msg: `Schedule ${data.scheduleId} has been successfully created.`, type: "msgSuccess" })
                    props.closePopup()
                } else if (response.status === 409) {
                    setError(response.field, true)
                } else {
                    props.setShowLoader(false)
                    props.setToastMsg({ msg: response.message, type: "msgError" })
                }

            } catch (er) {
                console.log(er.message);
            } finally {
                props.setShowLoader(false)
            }
        }

    };

    const employeeLabel = getAccountLabel('employee');
    const groupLabel = getAccountLabel('group');


    return (
        <div className="popupWrap medPopup withAutoComplete active">
            <div className="closePopup" onClick={props.closePopup}>Close</div>
            <div className="popupHeading">Add New Tracking Scheduler</div>
            <form onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => checkKeyDown(e)}>
                <div className="popupContent twoColPopContent" style={{fontSize : "0.75rem"}}>
                    <div className="rowBlk">
                        <div className={`colBlk6 fldWrap${errors.scheduleId ? ' errFld' : ''}`}>
                            <label className="withHelpTxt">Scheduler ID</label>
                            <input type="text" maxLength={255} {...register('scheduleId', { onChange: (e) => setValue('scheduleId', (e.target.value).replace(/[^a-zA-Z0-9_-]/gi, "")) })} />
                            {errors.scheduleId && <span className="fldMsg">schedule ID already exists</span>}
                        </div>
                        <div className="colBlk6 fldWrap">
                            <label>Active</label>
                            <div className="customCheck yesNo">
                                <input type="checkbox" id="StatusActive" {...register('status')} />
                                <label className="customCheckLabel" htmlFor="StatusActive">yesNo</label>
                            </div>
                        </div>
                    </div>
                    <div className="twoColBlk">
                        <div className="leftCol">This track is for</div>
                        <div className="rightCol">
                            <div className="highlightedSection">
                                <div className="customControls fldWrap">
                                    <div className="customRadio">
                                        <input type="radio" id="Group" name="locateFor" value="Group" {...register('locateFor', {
                                            onChange: (e) => { if (e.target.value !== "Group") { setValue('locateFor', e.target.value) } }
                                        })} />
                                        <label htmlFor="Group">{groupLabel}</label>
                                    </div>
                                    <div className="customRadio">
                                        <input type="radio" id="Employee" name="locateFor" value="Employee" {...register('locateFor', {
                                            onChange: (e) => { if (e.target.value !== "Employee") { setValue('locateFor', e.target.value) } }
                                        })} />
                                        <label htmlFor="Employee">{employeeLabel}</label>
                                    </div>
                                </div>
                                {
                                    (formData.locateFor)?.toLowerCase() === "group" &&
                                    <div className="fldWrap">
                                        <label>Select {groupLabel}</label>
                                        <select className="fldWidthMed monitorCustomSelectFontSize" value={getValues().scheduleFor} onChange={(e) => setValue('scheduleFor', e.target.value)}>
                                            {
                                                props.locateForData.groups.map((t, ind) => (
                                                    <option key={ind} value={t.value}>{t.label}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                }
                                {
                                    (formData.locateFor)?.toLowerCase() === "employee" &&
                                    <div className="fldWrap">
                                        <label>Select {employeeLabel}</label>
                                        <select className="fldWidthMed monitorCustomSelectFontSize" value={getValues().scheduleFor}  onChange={(e) => setValue('scheduleFor', e.target.value)}>
                                            {
                                                props.locateForData.employees.map((t, ind) => (
                                                    <option key={ind} value={t.value}>{t.label}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="twoColBlk">
                        <div className="leftCol">Track Settings</div>
                        <div className="rightCol">
                            <div className="highlightedSection">
                                {(formData.scheduleType)?.toLowerCase() !== "recurring" &&
                                    <div className="rowBlk">
                                        <div className="colBlk4">
                                            <div className="fldWrap">
                                                <label>Start Day &amp; Time</label>
                                                {
                                                    <DateTimePicker
                                                        popup={CustomPopup}
                                                        placeholder={"Start date & Time"}
                                                        defaultValue={getDefaultDateTimeForNonRecurringSchedule(0)}
                                                        onChange={(e) => handleDateTimeChange(0, e)}
                                                        format={`${props.dateFormat} ${props.timeFormat}`}
                                                        min={getMinDateLimit()}
                                                    />
                                                }
                                            </div>
                                        </div>
                                        <div className="colBlk4">
                                            <div className="fldWrap">
                                                <label>End Day &amp; Time</label>
                                                {
                                                    <DateTimePicker
                                                        popup={CustomPopup}
                                                        placeholder={"End date & Time"}
                                                        defaultValue={getDefaultDateTimeForNonRecurringSchedule(1)}
                                                        onChange={(e) => handleDateTimeChange(1, e)}
                                                        format={`${props.dateFormat} ${props.timeFormat}`}
                                                        min={getMinDateLimit()}
                                                    />
                                                }
                                            </div>
                                        </div>
                                        <div className="colBlk4">
                                            <div className="fldWrap">
                                                <label>Select Timezone</label>
                                                <select name="timezone monitorCustomSelectFontSize" defaultValue={props.timeZone} {...register("timezone", { value: props.timeZone })}>
                                                    {
                                                        [{ value: "Account", label: "Account" }, { value: "Device", label: "Device" }, ...timeZones].map((t, ind) => (
                                                            <option key={ind} value={t.value}>{t.label}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {(formData.scheduleType)?.toLowerCase() === "recurring" &&
                                    <div className="fldWrap">
                                        <label>Start Date</label>
                                        <div className="medFld">
                                            <DatePicker
                                                popup={CustomPopup}
                                                placeholder={"Start date"}
                                                defaultValue={getDefaultDateTimeForRecurringSchedule(0)}
                                                onChange={(e) => handleDateChange(0, e)}
                                                format={`${props.dateFormat}`}
                                                min={getDefaultDateTimeForRecurringSchedule(0)}
                                            />
                                        </div>
                                    </div>
                                }
                                <div className="customCheck">
                                    <input type="checkbox" id="Recurring" checked={formData?.scheduleType === 'Recurring'} value={'Recurring'} {...register('scheduleType', {
                                        onChange: (e) => { e.target.checked ? setValue('scheduleType', 'Recurring') : setValue('scheduleType', 'nonRecurring') }
                                    })} />
                                    <label className="customCheckLabel" htmlFor="Recurring">Recurring</label>
                                </div>
                                {(formData.scheduleType)?.toLowerCase() === "recurring" &&
                                    <div className="singleLineFld">
                                        <label>Repeat</label>
                                        <select className="smlFld monitorCustomSelectFontSize"  {...register("repeat", { onChange: (e) => { setValue('repeat', e.target.value) } })}>
                                            {["Daily", "Weekly"].map((t, ind) => (<option key={ind} value={t}>{t}</option>))}
                                        </select>
                                    </div>
                                }
                                {((formData.scheduleType)?.toLowerCase() === "recurring" && (formData.repeat)?.toLowerCase() === "weekly")
                                    &&
                                    <div className="fldWrap">
                                        <label>Select Days</label>
                                        <div className="weekDays">
                                            {
                                                weeksConfig.map((t, ind) => (
                                                    <span key={ind} value={t.day}
                                                        className={t.selected ? 'active' : ''}
                                                        onClick={(e) => selectDayOfWeek(t.day)}>
                                                        {t.label}
                                                    </span>
                                                ))
                                            }
                                        </div>
                                    </div>
                                }
                                {(formData.scheduleType)?.toLowerCase() === "recurring" &&
                                    <div className="singleLineFld">
                                        <label>Ends</label>
                                        <select className="smlFld monitorCustomSelectFontSize"   {...register("ends")}>
                                            <option>On</option>
                                            <option>Never</option>
                                        </select>
                                        {(formData.ends)?.toLowerCase() === "on" &&
                                            <div className="medFld">
                                                <DatePicker
                                                    popup={CustomPopup}
                                                    placeholder={"End date"}
                                                    defaultValue={getDefaultDateTimeForRecurringSchedule(1)}
                                                    onChange={(e) => handleDateChange(1, e)}
                                                    format={`${props.dateFormat}`}
                                                    min={getDefaultDateTimeForRecurringSchedule(0)}
                                                />
                                            </div>
                                        }
                                    </div>
                                }
                                {(formData.scheduleType)?.toLowerCase() === "recurring" &&
                                    <div className="singleLineFld">
                                        <label>Track from</label>
                                        <div className="smlFld2">
                                            <TimePicker
                                                popup={CustomPopup}
                                                defaultValue={getDefaultDateTimeForRecurringSchedule(0)}
                                                onChange={(e) => handleTimeChange(0, e)}
                                                format={`${props.timeFormat}`}
                                            />
                                        </div>
                                        <label style={{width: '20px', marginLeft: '32px'}}>to</label>
                                        <div className="smlFld2" style={{marginLeft: '0px'}}>
                                            <TimePicker
                                                popup={CustomPopup}
                                                defaultValue={getDefaultDateTimeForRecurringSchedule(1)}
                                                onChange={(e) => handleTimeChange(1, e)}
                                                format={`${props.timeFormat}`}
                                            />
                                        </div>
                                        <select className="medFld monitorCustomSelectFontSize" name="timezone" defaultValue={props.timeZone} {...register("timezone", { value: props.timeZone })}>
                                            {
                                                [{ value: "Account", label: "Account" }, { value: "Device", label: "Device" }, ...timeZones].map((t, ind) => (
                                                    <option key={ind} value={t.value}>{t.label}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                }
                                <div className="fldWrap">
                                    <div className="customControls">
                                        <div className="customRadio">
                                            <input type="radio" id="Continuously" name="Track" value="Continuously" {...register('track', {
                                                onChange: (e) => { if (e.target.value !== "Continuously") { setValue('track', e.target.value) } }
                                            })} />
                                            <label htmlFor="Continuously">Continuously</label>
                                        </div>
                                        <div className="customRadio">
                                            <input type="radio" id="Manual" name="Track" value="Manual" {...register('track', {
                                                onChange: (e) => { if (e.target.value !== "Manual") { setValue('track', e.target.value) } }
                                            })} />
                                            <label htmlFor="Manual" className="withHelpTxt">Manual
                                                <Tooltip position="right"><span className="helpTip" title="Location is tracked when the manager checks the location from the dashboard or when employee updates location from mobile app">?</span></Tooltip>
                                            </label>
                                        </div>
                                    </div>
                                    {
                                        (formData.track)?.toLowerCase() === "continuously"
                                        &&
                                        <div className="singleLineFld">
                                            <label>At what frequency do you want to track?</label>
                                            <div className="fldWithSubTxt">
                                                <span>Every</span>
                                                <input type="number" 
                                                    min={1}
                                                    max={60}
                                                    className="numberFld monitorCustomSelectFontSize"
                                                    {...register('frequency', { value: 15 })} />
                                                <span>mins</span>
                                            </div>
                                        </div>

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="popupBtnWrap flexJCenter">
                    <button type="submit" className="btnStyle doneStep" disabled={!isFormComplete}>Done</button>
                </div>
            </form>
        </div>
    )
}

export default AddNewPopup