import React from "react";
import { Link } from "react-router-dom";
import LeftContent from "./login/LeftContent";

export default function PageNotFound() {

  return (
    <div className="userPageWrapper notFound">
      <LeftContent></LeftContent>
      <div className="rightContent">
        <h2>404</h2>
        <p>The page you are looking for does not exist.</p>
        <Link to="/">Back to Home</Link>
      </div>
    </div>
  );
}
