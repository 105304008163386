import React, { Fragment, useRef, useEffect, useState } from "react";
import Header from "../../layout/Header";
import { apiProvider } from '../../../services/api/provider';
import SiteLoader from "../../common/SiteLoader";
import { formatDate } from '@progress/kendo-intl';
import KendoGrid from '../../common/kendo/KendoGrid';
import { workOrderColumns, DEFAULT_MODE } from "./utils/workOrderUtils";
import { getAccessLevel } from '../../common/functions';
import { WorkOrderEdit } from './EditWorkOrder';
import { WorkOrderView } from './ViewWorkOrder';
import { WorkOrderCalander } from './WorkOrderCalenderView';
import { AddWorkOrder } from './AddNewWorkOrder';
import ToastMessage from "../../common/ToastMessage";
import { extractTaskIDs } from './utils/helperFunctions';
import { WorkOrderConfirmPopUp } from "./WorkOrderPopup";
import { useDocumentTitle } from '../../../hooks/setDocumentTitle';
import '../../../assets/css/work-order.css'
import { getAccountLabel } from "../../../utils";

export const WorkOrderList = () => {
  const workorderLabel = getAccountLabel('workorder')
  useDocumentTitle(`${workorderLabel} | allGeo`);
  const [workOrderList, setWorkOrderList] = useState();
  const [accessLevel, setAccessLevel] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [addWOPopUp, setAddWOPopUp] = useState(false);
  const [showCalender, setShowCalender] = useState(false);
  const [editConfirm, setEditConfirm] = useState(false)
  const [mode, setMode] = useState(DEFAULT_MODE);
  const [toastMsg, setToastMsg] = useState({
    msg: "",
    type: "msgSuccess"
  });

  const params = {
    start: formatDate(new Date(), "yyyy/MM/dd"),
    end: formatDate(new Date(), "yyyy/MM/dd"),
  };

  const getWorkOrderReports = async () => {
    try {
      sessionStorage.setItem('WO:startDate', params.start)
      sessionStorage.setItem('WO:endDate', params.end)
      setShowLoader(true);
      const response = await apiProvider.getAll(
        "/track/react/v1/allgeo/loadAllWorkorders",
        params
      );
      if (response.status === 200) {
        setWorkOrderList(response.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setShowLoader(false);
    }
  };

  const actionHandler = (type, data) => {
    switch (type) {
      case "view":
        setMode({
          title: "View WO",
          type: "view",
          data: data,
          hasPrev: false,
        });
        break;
      case "edit":
        setEditConfirm(true)
        setMode({
          title: "Edit WO",
          type: "edit",
          data: data,
          hasPrev: false,
        });
        break;
      case "delete":
        setMode({
          title: "Delete WO",
          type: "delete",
          data: data,
          hasPrev: false,
        });
        break;
      default:
        break;
    }
  };

  const deleteCurrentWorkOrder = async (data) => {
    const taskId = extractTaskIDs(data)
    const payload = data.length ? data : [data];
    try {
      setShowLoader(true);
      const response = await apiProvider.post(
        `track/react/v1/allgeo/deleteMasterWorkorder?deletionType=current&inactiveWorkorderIds=${taskId}`,
        payload
      );
      setShowLoader(false);
      return response;
    } catch (error) {
      console.error(error);
      setShowLoader(false);
    }
  };

  const deleteSeriesWorkOrder = async (data) => {
    const taskId = extractTaskIDs(data)
    const payload = data.length ? data : [data];
    try {
      setShowLoader(true);
      const response = await apiProvider.post(
        `track/react/v1/allgeo/deleteMasterWorkorder?deletionType=recurring&inactiveWorkorderIds=${taskId}`,
        payload
      );
      setShowLoader(false);
      return response;
    } catch (error) {
      console.error(error);
      setShowLoader(false);
    }
  };

  const childRef = useRef();

  useEffect(() => {
    // Initial data load
    getAccessLevel("Schedule", "work_orders").then((response) => {
      setAccessLevel(response);
      getWorkOrderReports();
    });
  }, []);

  useEffect(() => {
    if (toastMsg.msg && toastMsg.type === "msgSuccess") {
      if (childRef.current) {
        childRef.current.refreshData()
      }
      setShowLoader(false)
    }
  }, [toastMsg])

  return (
    <div className="main_content_wrapper">
      <Header title={workorderLabel} />
      <div className="main_container">
        <section>
          <div className="schedule_work_section">
            <div className="work_order_table">
              <div className="header_bar">
                <div className="view_calendar">
                  <div className="customize_toggle toggle_disable">
                    <input
                      type="checkbox"
                      id="showCalender"
                      checked={showCalender}
                      onChange={() => setShowCalender(!showCalender)}
                    />
                    <label className="customize_label" htmlFor="showCalender">yesNo</label>
                  </div>
                  <div className="side_heading">Calender View</div>
                </div>
              </div>
              {showCalender ? (
                <WorkOrderCalander accessLevel={accessLevel} />
              ) : (
                workOrderList && (
                  <KendoGrid
                    gridData={workOrderList}
                    columnData={workOrderColumns}
                    accessLevel={accessLevel}
                    setGridData={setWorkOrderList}
                    module="workOrders"
                    actionColumnHandler={actionHandler}
                    ref={childRef}
                    getAPiUrl={"/track/react/v1/allgeo/loadAllWorkorders"}
                    lockActionColumn={true}
                    deleteCurrent={deleteCurrentWorkOrder}
                    deleteSeries={deleteSeriesWorkOrder}
                    otherData={{
                      actionColumnBtns: ['view', 'edit', 'delete'],
                      exportFileName: `${workorderLabel}`
                    }}
                    openPopup={() => setAddWOPopUp(true)}
                  />
                )
              )}

              {mode.type === 'edit' && (
                <Fragment>
                  {
                    mode.data?.RecurrenceRule === "" || undefined ?
                      <WorkOrderEdit WOdata={mode.data} mode={mode} setMode={setMode} setToastMsg={setToastMsg} />
                      : editConfirm && <WorkOrderConfirmPopUp WOdata={mode.data} mode={mode} setMode={setMode} setToastMsg={setToastMsg} setEditConfirm={setEditConfirm} />
                  }
                </Fragment>
              )}
              {mode.type === 'view' && (
                <Fragment>
                  <WorkOrderView WOdata={mode.data} mode={mode} setMode={setMode} setShowLoader={setShowLoader} setToastMsg={setToastMsg} />
                </Fragment>
              )}
              {addWOPopUp ? <Fragment>
                <AddWorkOrder setShowLoader={setShowLoader} woPopUp={setAddWOPopUp} setToastMsg={setToastMsg} />
              </Fragment>
                : ""
              }
            </div>
          </div>
        </section>
      </div>
      <SiteLoader isActive={showLoader} />
      {mode.type === 'edit' || addWOPopUp ? <div className="overlay active"></div> : ""}

      {toastMsg.msg && <ToastMessage
        cssClass={toastMsg.type}
        message={toastMsg.msg}
        onClose={() => setToastMsg({ msg: "", type: "" })}
      />}

    </div>
  );
};
