import { useState, useEffect } from "react";
import { apiProvider } from '../../../services/api/provider';

const ViewForm = (props) => {

    const [formDetails, setFormDetails] = useState({});

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        props.setShowLoader(true);

        apiProvider.getAll(`/track/mobile/v1/allgeo/viewform?ID=${props.formId}`).then((response) => {
            if (response.status === 200 && response.message.toLowerCase() === 'success') {
                setFormDetails({ 'formName': response.formName, 'status': response.active, 'formFields': response.formFields, 'dynamic': response.dynamic })
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            props.setShowLoader(false);
        });
    }
    return (

        <div className="popupWrap smlMedPopup active">
            <div className="popupHeading noClose">
                <span>View Form</span>
                <div className="headingCtrl">
                    <a href={void (0)} className={`editGrp${props.accessLevel < 2 ? ' disabled' : ''}`}
                        onClick={() => {
                            props.setShowLoader(true)
                            props.setMode({
                                title: "Manage Forms",
                                type: 'edit',
                                data: props.mode.data,
                                hasPrev: true
                            })
                        }}>Edit Form</a>
                    <a href={void (0)} className={`deleteGrp${props.accessLevel < 3 ? ' disabled' : ''}`}
                        onClick={() => {
                            props.setMode({
                                title: "Manage Forms",
                                type: "delete",
                                data: props.mode.data,
                                hasPrev: true
                            })
                        }}>Delete Form</a>
                </div>
            </div>
            <div className="popupContent viewPopup">
                <div className="viewLeft">
                    <div className="fldWrap">
                        <label>Form Name</label>
                        <p>{formDetails?.formName}</p>
                    </div>
                    <div className="fldWrap">
                        <label>Status</label>
                        <p className="primaryColor">{formDetails?.status ? 'Active' : 'Inactive'}</p>
                    </div>
                    <div className="fldWrap">
                        <label>Form Type</label>
                        <p className="primaryColor">{formDetails?.dynamic ? 'Dynamic' : 'Static'}</p>
                    </div>
                </div>
                <div className="viewRight">
                    <label>Form Elements</label>
                    <div className="empSelectBlk">
                        <div className="empListTbl tbl2">
                            <table cellPadding="0" cellSpacing="0">
                                <tbody>
                                    <tr>
                                        <th>Field Name</th>
                                        <th>Data Type</th>
                                    </tr>
                                    {formDetails?.formFields?.map((ele, index) => (
                                        <tr key={index}>
                                            <td>{ele.colName}</td>
                                            <td>{ele.fieldType}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="popupBtnWrap">
                <a href={void (0)} className="btnStyle doneStep" onClick={() => {
                    props.setMode({
                        title: "Manage Forms",
                        type: "main",
                        data: null,
                        hasPrev: false
                    })
                }}>Done</a>
            </div>
        </div>

    )
}

export default ViewForm