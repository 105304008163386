import React from 'react'
import { GoogleLogin } from 'react-google-login'
import axios from 'axios';

const GoogleLoginComponent = () => {

    const handleConnection = async (googleData) => {
        console.log(googleData);
        let tokenId = decodeURIComponent(googleData.code) || "";

        const accountId = localStorage.getItem('accountId')


        if ((tokenId && tokenId !== "") && accountId) {
            let data = { authToken: tokenId };

            axios.post(process.env.REACT_APP_GOOGLE_LOGIN_API + "?supportReqAccountId=" + accountId.trim(), data, { headers: { "Content-Type": "application/json" } })
                .then((response) => {
                    console.log(response);
                    let responseData = response.data;
                    if (responseData.Status && responseData.Status.toLowerCase() == 'success' && responseData.StatusCode == 200) {
                        if (responseData.SucessMsg.toLowerCase() == 'ok') {
                            localStorage.setItem('isNewMonitorPage',responseData?.isNewMonitorPage)

                            localStorage.setItem('employeeAppData',responseData?.monitorCards?.employeeAppData)
                            localStorage.setItem('mapAndLocation',responseData?.monitorCards?.mapAndLocation)
                            localStorage.setItem('milesData',responseData?.monitorCards?.milesData)
                            localStorage.setItem('notificationsAndAlerts',responseData?.monitorCards?.notificationsAndAlerts)
                            localStorage.setItem('timeClockData',responseData?.monitorCards?.timeClockData)
                            localStorage.setItem('timeClockReports',responseData?.monitorCards?.timeClockReports)

                            const expirationTime = new Date(
                                new Date().getTime() + +response.data.expiry
                            );
                            authCtx.login(
                                responseData.token,
                                responseData.token_en,
                                expirationTime.toISOString(),
                                responseData.is_parent,
                                responseData.accountId,
                                responseData.userName,
                                responseData.isBannerEnabled, 
                                responseData.bannerText,
                                responseData.userSecurityType, 
                                responseData.actSecurityType,
                                responseData.landingPage,
                                responseData.hasG2Reviewed,
                            );
                            history.replace("/");
                        }
                    } else {
                        if (response.status == 45) {
                            history.replace('/trial-account-expired');
                        } else {
                            handelAuthErrorMsg(response.data.SucessMsg);
                        }
                    }
                }).catch((error) => { console.log(error); handelAuthErrorMsg("Unable to login."); });
        }
    };

    const handelAuthErrorMsg = (serverMessage) => {
        let msg = <>{serverMessage}</>;
        // setIsLoginError(true);
        if (serverMessage == "Invalid Credentials") {
            msg = (
                <>
                    Credentials are invalid. If you don't remember your password,{" "}
                    <Link to="/reset-password">reset it now.</Link>
                </>
            );
        }
        if (serverMessage == "Trial Account Expired" || serverMessage == "Account associated with the email is Expired") {

            const line1Style = { 'marginBottom': '0', 'lineHeight': '10px' };
            const line2Style = { 'marginBottom': '0' };
            msg = <><p style={line1Style}>Your free trial account has expired. <a href="../pricing">Upgrade now</a>.</p> <p style={line2Style}>Feel free to reach us at <a href="mailto:support@abaq.us">support@abaq.us</a> for product or trial related queries.</p></>;
        }
        // setLoginErrorMsg(msg);
    };


    return (
        <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            render={(renderProps) => (
                <button
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    className="btnStyle btn2 iconBtn googleLogin"
                >
                    Login with Google
                </button>
            )}
            buttonText="Login with Google"
            onSuccess={handleConnection}
            onFailure={() => console.log('failure')}
            onScriptLoadFailure={() => console.log('Error')}
            // disabled={isConnected}
            accessType="offline"
            responseType="code"
            cookiePolicy={"single_host_origin"}
        />
    )
}

export default GoogleLoginComponent