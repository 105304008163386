export const formColumns = [
    {
        title: "Form Name",
        field: "formName",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true
    },
    {
        title: "Links",
        field: "link",
        editable: false,
        width: 200,
        editor: "text",
        customCell: true,
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Status",
        field: "active",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Type",
        field: "formType",
        editable: false,
        width: 200,
        editor: "text",
        customCell: true,
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Upload Date",
        field: "creationTime",
        editable: false,
        width: 200,
        editor: "text",
        customCell: true,
        columnMenu: false,
        show: true,
        filter: "text"
    }
]