import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react'
import { apiProvider } from '../../../services/api/provider';
import { getAccountLabel } from '../../../utils';

const initialDataState = {
    skip1: 0,
    take1: 10,
    skip2: 0,
    take2: 10
};

const colorPallete = ["red", "cornflowerblue", "dodgerblue", "yellow"]

const AssignedUnassignedTable = ({ formData, updatedData, unassgnData, assgnData, closePopup, setToastMsg, setShowG2Popup }, ref) => {

    const [unassignedEmployeesData, setUnassignedEmployeesData] = useState([])
    const [assignedEmployeesData, setAssignedEmployeesData] = useState([])
    const [unassignedSearch, setUnassignedSearch] = useState("")
    const [assignedSearch, setAssignedSearch] = useState("")
    const [unassignedBackupData, setUnassignedBackupData] = useState([])
    const [assignedBackupData, setAssignedBackupData] = useState([])
    const [sortUnassgn, setSortUnassgn] = useState(null)
    const [sortAssgn, setSortAssgn] = useState(null)
    const [page, setPage] = useState(initialDataState)
    const [tableDataChangeState, setTableDataChangeState] = useState(false)

    useEffect(() => {
        if (unassgnData) {
            setUnassignedEmployeesData(unassgnData.map(d => {
                return {
                    ...d,
                    color: colorPallete[Math.floor(colorPallete.length * Math.random())]
                }
            }))
            setUnassignedBackupData(unassgnData.map(d => {
                return {
                    ...d,
                    color: colorPallete[Math.floor(colorPallete.length * Math.random())]
                }
            }))
        }
    }, [unassgnData])

    useEffect(() => {
        if (assgnData) {
            setAssignedEmployeesData(assgnData.map((d, ind) => {
                return {
                    ...d,
                    color: colorPallete[Math.floor(colorPallete.length * Math.random())],
                    removedId: ind
                }
            }))
            setAssignedBackupData(assgnData.map((d, ind) => {
                return {
                    ...d,
                    color: colorPallete[Math.floor(colorPallete.length * Math.random())],
                    removedId: ind
                }
            }))
        }
    }, [assgnData])

    useImperativeHandle(ref, () => ({
        saveData: () => createGroup(),
        updateData: () => updateGroup(),
        isTableDataDirty: () => tableDataChangeState
    }))

    const createGroup = async () => {
        try {
            const payload = {
                groupId: formData.groupId,
                groupName: formData.groupName,
                assignEmployees: assignedEmployeesData.map(d => d.devID.toString()),
                colour: formData.color
            }

            const response = await apiProvider.post(`/track/mobile/v1/allgeo/createGroup`, payload)

            if (response.status === 200) {
                closePopup()
                setToastMsg(response.message)
                setTimeout(() => {
                    setShowG2Popup(true)
                }, 2000)
            }

        } catch (error) {
            console.log(error);
        }
    }

    const updateGroup = async () => {
        try {

            const payload = {
                groupId: updatedData.groupId,
                groupName: updatedData.groupName.trim().length > 0 ? updatedData.groupName : updatedData.groupId,
                assignEmployees: assignedEmployeesData.map(d => d.devID.toString()),
                unassignEmployees: unassignedEmployeesData.map(d => d.devID.toString()),
                colour: updatedData.colour
            }

            return await apiProvider.post("/track/mobile/v1/allgeo/updateGroup", payload)

        } catch (error) {
            console.log(error);
        }
    }

    const handleSearch = (evt, type) => {

        const searchText = evt.target.value;

        if (type === "un") {
            setUnassignedSearch(searchText)
            setUnassignedEmployeesData(unassignedBackupData.filter(d => {
                return d.devID.toString().toLowerCase().includes(searchText.toLowerCase()) ||
                    d.desc.toLowerCase().includes(searchText.toLowerCase())
            }))
        } else {
            setAssignedSearch(searchText)
            setAssignedEmployeesData(assignedBackupData.filter(d => {
                return d.devID.toString().toLowerCase().includes(searchText.toLowerCase()) ||
                    d.desc.toLowerCase().includes(searchText.toLowerCase())
            }))
        }

        setPage({
            ...initialDataState,
            take1: page.take1,
            take2: page.take2
        })
    }

    const handleAssignEmployee = (emp, ind) => {
        setTableDataChangeState(true)
        setUnassignedEmployeesData(unassignedEmployeesData.filter(d => d.devID !== emp.devID))
        setUnassignedBackupData(unassignedBackupData.filter(d => d.devID !== emp.devID))

        if (sortAssgn) {
            sortData(sortAssgn, [...assignedEmployeesData, { ...emp, removedId: ind }], setAssignedEmployeesData)
            sortData(sortAssgn, [...assignedBackupData, { ...emp, removedId: ind }], setAssignedBackupData)
        } else {
            setAssignedEmployeesData([...assignedEmployeesData, { ...emp, removedId: ind }])
            setAssignedBackupData([...assignedBackupData, { ...emp, removedId: ind }])
        }
    }

    const handleRemoveEmployee = rEmp => {
        setTableDataChangeState(true)
        const removedData = assignedEmployeesData.filter(emp => emp.devID === rEmp.devID)

        setAssignedEmployeesData(assignedEmployeesData.filter(emp => emp.devID !== rEmp.devID))
        setAssignedBackupData(assignedBackupData.filter(emp => emp.devID !== rEmp.devID))

        if (sortUnassgn) {
            sortData(sortUnassgn, [...unassignedEmployeesData, ...removedData].map(d => {
                return {
                    desc: d.desc,
                    devID: d.devID,
                    color: d.color
                }
            })
                , setUnassignedEmployeesData)
            sortData(sortUnassgn, [...unassignedBackupData, ...removedData].map(d => {
                return {
                    desc: d.desc,
                    devID: d.devID,
                    color: d.color
                }
            })
                , setUnassignedBackupData)
        } else {
            setUnassignedEmployeesData(insert(unassignedEmployeesData, rEmp.removedId, removedData).map(d => {
                return {
                    desc: d.desc,
                    devID: d.devID,
                    color: d.color
                }
            }))
            setUnassignedBackupData(insert(unassignedBackupData, rEmp.removedId, removedData).map(d => {
                return {
                    desc: d.desc,
                    devID: d.devID,
                    color: d.color
                }
            }))
        }

    }

    const handleSort = (evt, type) => {

        if (type === "un") {
            setSortUnassgn(evt)

            sortData(evt, unassignedEmployeesData, setUnassignedEmployeesData)
            sortData(evt, unassignedBackupData, setUnassignedBackupData)
        } else {
            setSortAssgn(evt)

            sortData(evt, assignedEmployeesData, setAssignedEmployeesData)
            sortData(evt, assignedBackupData, setAssignedBackupData)
        }

    }

    const sortData = (evt, data, setData) => {
        const [field, order] = evt.target.value.split(":")
        setData([...data.sort((a, b) => sortBy(a, b, field, order))])
    }

    const sortBy = (a, b, field, order) => {
        
        if (field === "devID") {
            const valA = +a[field];
            const valB = +b[field];

            if (valA > valB) return order === "Asc" ? 1 : -1;
            else if (valA < valB) return order === "Asc" ? -1 : 1;
            else return 0; 
        }

        if (a[field].toString().toLowerCase() > b[field].toString().toLowerCase()) {
            return order === "Asc" ? 1 : -1;
        } else if (a[field].toString().toLowerCase() < b[field].toString().toLowerCase()) {
            return order === "Asc" ? -1 : 1;
        } else return 0
    }

    function insert(arr, index, items) {
        return [
            ...arr.slice(0, index),
            ...items,
            ...arr.slice(index)
        ];
    }

    const handlePageChange = (evt, type, empType) => {
        const val = +evt.target.value

        if (empType === "unassgn") {
            if (type === 'skip') {
                setPage({
                    ...page,
                    skip1: page.take1 * val,
                })
            } else {
                setPage({
                    ...page,
                    skip1: 0,
                    take1: val
                })
            }
        } else {
            if (type === 'skip') {
                setPage({
                    ...page,
                    skip2: page.take2 * val,
                })
            } else {
                setPage({
                    ...page,
                    skip2: 0,
                    take2: val
                })
            }

        }
    }
    const employeeLabel = getAccountLabel('employee');
    const groupLabel = getAccountLabel('group');
    return (
        <>
            <div className="rowBlk">
                <div className="colBlk6">
                    <div className="sectionSubHeading">Assign {employeeLabel}</div>
                    <div className="supportedTxt">Select on the list below the {employeeLabel} you want to add to this {groupLabel}.</div>
                    <div className={`empSelectBlk ${unassignedBackupData.length > 0 ? '' : 'addedEmp'}`}>
                        <div className="empSelectCtrl">
                            <input
                                type="search"
                                placeholder={`Search by ${employeeLabel} ID or Name`}
                                value={unassignedSearch}
                                onChange={evt => handleSearch(evt, "un")}
                                disabled={unassignedBackupData.length === 0}
                            />
                            <select
                                placeholder='Sort by'
                                onChange={evt => handleSort(evt, "un")}
                                disabled={unassignedBackupData.length === 0}
                            >
                                <option disabled selected hidden>Sort By</option>
                                <option value="desc:Asc">{employeeLabel} Name (A-Z)</option>
                                <option value="desc:Desc">{employeeLabel} Name (Z-A)</option>
                                <option value="devID:Asc">{employeeLabel} Id (Ascending)</option>
                                <option value="devID:Desc">{employeeLabel} Id (Descending)</option>
                            </select>
                        </div>
                        <div className="empListTbl">
                            <table cellPadding="0" cellSpacing="0">
                                <tbody>
                                    <tr>
                                        <th>{employeeLabel} Name</th>
                                        <th>{employeeLabel} ID</th>
                                        <th className="empActionCol"></th>
                                    </tr>
                                    {
                                        unassignedEmployeesData.slice(page.skip1, page.take1 + page.skip1).map((d, ind) => (
                                            <tr key={ind}>
                                                <td><span className="empAvtr" style={{ backgroundColor: d.color }}>{d.desc?.slice(0, 1).toUpperCase()}</span> {d.desc}</td>
                                                <td>{d.devID}</td>
                                                <td><span className="addRemoveBtn" onClick={() => handleAssignEmployee(d, ind)}>add/remove</span></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="empSelectFoot">
                            <span>There are a total of <strong>{unassignedEmployeesData.length} {employeeLabel}</strong></span>
                            <a className={`addAll${unassignedBackupData.length === 0 ? ' disabled' : ''}`} onClick={e => {
                                e.preventDefault();
                                if (unassignedEmployeesData.length > 0) {
                                    setTableDataChangeState(true)

                                    setUnassignedEmployeesData([])
                                    if (unassignedSearch.trim().length > 0) {
                                        setUnassignedBackupData(unassignedBackupData.filter(emp => !unassignedEmployeesData.includes(emp)))
                                    } else {
                                        setUnassignedBackupData([])
                                    }

                                    if (sortAssgn) {
                                        sortData(sortAssgn, [...assignedEmployeesData, ...unassignedEmployeesData.map((d, ind) => ({ ...d, removedId: ind }))], setAssignedEmployeesData)
                                        sortData(sortAssgn, [...assignedBackupData, ...unassignedEmployeesData.map((d, ind) => ({ ...d, removedId: ind }))], setAssignedBackupData)
                                    } else {
                                        setAssignedEmployeesData([...assignedEmployeesData, ...unassignedEmployeesData.map((d, ind) => ({ ...d, removedId: ind }))])
                                        setAssignedBackupData([...assignedBackupData, ...unassignedEmployeesData.map((d, ind) => ({ ...d, removedId: ind }))])
                                    }
                                }
                            }}>+ Add All</a>
                        </div>
                    </div>
                    <div className="paginationWrap">
                        <div className="countPerPage">
                            <span>Show:</span>
                            <select
                                onChange={evt => handlePageChange(evt, "take", "unassgn")}
                                disabled={unassignedBackupData.length === 0}
                            >
                                <option>10</option>
                                <option>20</option>
                                <option>50</option>
                                <option>100</option>
                            </select>
                        </div>
                        <div className="showingPage">
                            <span>Showing {Math.ceil((page.skip1 + 1) / page.take1) || 1}-{Math.ceil(unassignedEmployeesData.length / page.take1) || 1}</span>
                            <select
                                onChange={evt => handlePageChange(evt, "skip", "unassgn")}
                                disabled={unassignedBackupData.length === 0}
                            >
                                {
                                    [...Array(Math.ceil(unassignedEmployeesData.length / page.take1))].map((_, ind) => (
                                        <option key={ind} value={ind} >{ind + 1}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                </div>
                <div className="colBlk6">
                    <div className="sectionSubHeading">Assigned {employeeLabel}</div>
                    <div className="supportedTxt">This is the list of {employeeLabel} added to this {groupLabel}.</div>
                    <div className={`empSelectBlk ${assignedBackupData.length > 0 ? '' : 'addedEmp'}`}>
                        <div className="empSelectCtrl">
                            <input
                                type="search"
                                placeholder={`Search by ${employeeLabel} ID or Name`}
                                value={assignedSearch}
                                onChange={evt => handleSearch(evt, "assgn")}
                                disabled={assignedBackupData.length === 0}
                            />
                            <select
                                placeholder='Sort by'
                                onChange={evt => handleSort(evt, "assgn")}
                                disabled={assignedBackupData.length === 0}
                            >
                                <option disabled selected hidden>Sort By</option>
                                <option value="desc:Asc">{employeeLabel} Name (A-Z)</option>
                                <option value="desc:Desc">{employeeLabel} Name (Z-A)</option>
                                <option value="devID:Asc">{employeeLabel} Id (Ascending)</option>
                                <option value="devID:Desc">{employeeLabel} Id (Descending)</option>
                            </select>
                        </div>
                        <div className="empListTbl">
                            <table cellPadding="0" cellSpacing="0">
                                <tbody>
                                    <tr>
                                        <th>{employeeLabel} Name</th>
                                        <th>{employeeLabel} ID</th>
                                    </tr>
                                    {
                                        assignedEmployeesData.slice(page.skip2, page.take2 + page.skip2).map((d, ind) => (
                                            <tr key={ind}>
                                                <td><span className="empAvtr" style={{ backgroundColor: d.color }}>{d.desc.slice(0, 1).toUpperCase()}</span> {d.desc}</td>
                                                <td>{d.devID}</td>
                                                <td><span className="addRemoveBtn addedItem" onClick={() => handleRemoveEmployee(d)}>add/remove</span></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="empSelectFoot">
                            <span>You added <strong>{assignedEmployeesData.length} {employeeLabel}</strong> in this {groupLabel}.</span>
                            <a
                                className={`removeAll${assignedBackupData.length === 0 ? ' disabled' : ''}`}
                                onClick={e => {
                                    e.preventDefault();
                                    if (assignedEmployeesData.length > 0) {
                                        setTableDataChangeState(true)

                                        setAssignedEmployeesData([])
                                        if (assignedSearch.trim().length > 0) {
                                            setAssignedBackupData(assignedBackupData.filter(emp => !assignedEmployeesData.includes(emp)))
                                        } else {
                                            setAssignedBackupData([])
                                        }

                                        if (sortUnassgn) {
                                            sortData(sortUnassgn, [...unassignedEmployeesData, ...assignedEmployeesData], setUnassignedEmployeesData)
                                            sortData(sortUnassgn, [...unassignedBackupData, ...assignedEmployeesData], setUnassignedBackupData)
                                        } else {
                                            setUnassignedEmployeesData([...unassignedEmployeesData, ...assignedEmployeesData])
                                            setUnassignedBackupData([...unassignedBackupData, ...assignedEmployeesData])
                                        }
                                    }
                                }}>Remove All</a>
                        </div>
                    </div>
                    <div className="paginationWrap">
                        <div className="countPerPage">
                            <span>Show:</span>
                            <select
                                onChange={evt => handlePageChange(evt, "take", "assgn")}
                                disabled={assignedBackupData.length === 0}
                            >
                                <option>10</option>
                                <option>20</option>
                                <option>50</option>
                                <option>100</option>
                            </select>
                        </div>
                        <div className="showingPage">
                            <span>Showing {Math.ceil((page.skip2 + 1) / page.take2) || 1}-{Math.ceil(assignedEmployeesData.length / page.take2) || 1}</span>
                            <select
                                onChange={evt => handlePageChange(evt, "skip", "assgn")}
                                disabled={assignedBackupData.length === 0}
                            >
                                {
                                    [...Array(Math.ceil(assignedEmployeesData.length / page.take2))].map((_, ind) => (
                                        <option key={ind} value={ind} >{ind + 1}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default forwardRef(AssignedUnassignedTable)