export const timeZones = [
    {
        value: "US/Hawaii",
        label: "US/Hawaii"
    },
    {
        value: "US/Alaska",
        label: "US/Alaska"
    },
    {
        value: "US/Pacific",
        label: "US/Pacific"
    },
    {
        value: "US/Arizona",
        label: "US/Arizona"
    },
    {
        value: "US/Mountain",
        label: "US/Mountain"
    },
    {
        value: "US/Central",
        label: "US/Central"
    },
    {
        value: "US/Eastern",
        label: "US/Eastern"
    },
    {
        value: "Canada/Pacific",
        label: "Canada/Pacific"
    },
    {
        value: "Canada/Mountain",
        label: "Canada/Mountain"
    },
    {
        value: "Canada/Central",
        label: "Canada/Central"
    },
    {
        value: "Canada/Eastern",
        label: "Canada/Eastern"
    },
    {
        value: "Canada/Atlantic",
        label: "Canada/Atlantic"
    },
    {
        value: "Mexico/BajaNorte",
        label: "Mexico/BajaNorte"
    },
    {
        value: "Mexico/BajaSur",
        label: "Mexico/BajaSur"
    },
    {
        value: "Mexico/General",
        label: "Mexico/General"
    },
    {
        value: "Europe/Athens",
        label: "Europe/Athens"
    },
    {
        value: "Europe/Berlin",
        label: "Europe/Berlin"
    },
    {
        value: "Europe/Dublin",
        label: "Europe/Dublin"
    },
    {
        value: "Europe/Helsinki",
        label: "Europe/Helsinki"
    },
    {
        value: "Europe/Kiev",
        label: "Europe/Kiev"
    },
    {
        value: "Europe/Lisbon",
        label: "Europe/Lisbon"
    },
    {
        value: "Europe/London",
        label: "Europe/London"
    },
    {
        value: "Europe/Madrid",
        label: "Europe/Madrid"
    },
    {
        value: "Europe/Moscow",
        label: "Europe/Moscow"
    },
    {
        value: "Europe/Oslo",
        label: "Europe/Oslo"
    },
    {
        value: "Europe/Paris",
        label: "Europe/Paris"
    },
    {
        value: "Europe/Rome",
        label: "Europe/Rome"
    },
    {
        value: "Europe/Stockholm",
        label: "Europe/Stockholm"
    },
    {
        value: "Europe/Zurich",
        label: "Europe/Zurich"
    },
    {
        value: "Pacific/Auckland",
        label: "Pacific/Auckland"
    },
    {
        value: "Pacific/Chatham",
        label: "Pacific/Chatham"
    },
    {
        value: "GMT",
        label: "GMT"
    },
    {
        value: "GMT+00:00",
        label: "GMT+00:00"
    },
    {
        value: "GMT+01:00",
        label: "GMT+01:00"
    },
    {
        value: "GMT+02:00",
        label: "GMT+02:00"
    },
    {
        value: "GMT+03:00",
        label: "GMT+03:00"
    },
    {
        value: "GMT+04:00",
        label: "GMT+04:00"
    },
    {
        value: "GMT+05:00",
        label: "GMT+05:00"
    },
    {
        value: "GMT+05:30",
        label: "GMT+05:30"
    },
    {
        value: "GMT+06:00",
        label: "GMT+06:00"
    },
    {
        value: "GMT+07:00",
        label: "GMT+07:00"
    },
    {
        value: "GMT+08:00",
        label: "GMT+08:00"
    },
    {
        value: "GMT+09:00",
        label: "GMT+09:00"
    },
    {
        value: "GMT+10:00",
        label: "GMT+10:00"
    },
    {
        value: "GMT+11:00",
        label: "GMT+11:00"
    },
    {
        value: "GMT+12:00",
        label: "GMT+12:00"
    },
    {
        value: "GMT+13:00",
        label: "GMT+13:00"
    },
    {
        value: "GMT+14:00",
        label: "GMT+14:00"
    },
    {
        value: "GMT-01:00",
        label: "GMT-01:00"
    },
    {
        value: "GMT-02:00",
        label: "GMT-02:00"
    },
    {
        value: "GMT-03:00",
        label: "GMT-03:00"
    },
    {
        value: "GMT-04:00",
        label: "GMT-04:00"
    },
    {
        value: "GMT-05:00",
        label: "GMT-05:00"
    },
    {
        value: "GMT-06:00",
        label: "GMT-06:00"
    },
    {
        value: "GMT-07:00",
        label: "GMT-07:00"
    },
    {
        value: "GMT-08:00",
        label: "GMT-08:00"
    },
    {
        value: "GMT-09:00",
        label: "GMT-09:00"
    },
    {
        value: "GMT-10:00",
        label: "GMT-10:00"
    },
    {
        value: "GMT-11:00",
        label: "GMT-11:00"
    },
    {
        value: "GMT-12:00",
        label: "GMT-12:00"
    },

]

export const rulesColumns = [
    {
        title: "Rule ID",
        field: "ruleID",
        editable: false,
        width: 200,
        editor: "text",
        orderIndex: 1,
        show: true,
        filter: "text",
        customCell: false,
        mandatory: true
    },
    {
        title: "Description",
        field: "ruleDesc",
        editable: false,
        width: 200,
        editor: "text",
        orderIndex: 2,
        columnMenu: false,
        show: true,
        dropdownCell: false,
        mandatory: false
    },
    {
        title: "Selector",
        field: "selector",
        editable: false,
        width: 300,
        editor: "text",
        orderIndex: 3,
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true
    },
    {
        title: "Assigned Phone(s)",
        field: "assignedEnt",
        editable: false,
        width: 200,
        editor: "text",
        orderIndex: 4,
        show: true,
        filter: "text",
        mandatory: true
    },
    {
        title: "Scheduled",
        field: "ruleTag",
        editable: false,
        width: 200,
        editor: "text",
        orderIndex: 5,
        show: true,
        filter: "text",
        mandatory: true
    },
    {
        title: "Alert",
        field: "notification",
        editable: false,
        width: 200,
        editor: "text",
        orderIndex: 6,
        show: false,
        filter: "text",
        mandatory: true
    },
    {
        title: "Alert Recipient",
        field: "recipient",
        editable: false,
        width: 200,
        editor: "text",
        orderIndex: 7,
        show: false,
        filter: "text",
        mandatory: true
    },
    {
        title: "Active",
        field: "active",
        editable: false,
        width: 200,
        editor: "text",
        orderIndex: 8,
        show: true,
        filter: "text",
        mandatory: true
    },
    {
        title: "Created On",
        field: "creationTime",
        editable: false,
        width: 250,
        editor: "text",
        orderIndex: 9,
        show: true,
        filter: "text",
        mandatory: true
    }  
];