import React, { useState, useContext, useEffect, useRef } from "react";
import LeftContent from "./LeftContent";
import { Link, useHistory } from "react-router-dom";
import AuthContext from '../../store/auth-context';
import ToastMessage from '../common/ToastMessage';
import { useDocumentTitle } from '../../hooks/setDocumentTitle';
import { GoogleLogin } from "react-google-login";
import axios from "axios";

const AdminLogin = () => {
    useEffect(() => {
        localStorage?.clear();
    }, [])
    useDocumentTitle("Admin Login - allGeo");
    const [isLoginError, setIsLoginError] = useState(false);
    const [loginErrorMsg, setLoginErrorMsg] = useState("");
    const [accountId, setAccountId] = useState("");
    const [buttonDisable, setButtonDisable] = useState(true);


    const authCtx = useContext(AuthContext);
    const history = useHistory();

    const handleLogin = async (googleData) => {
        console.log(googleData);
        let tokenId = decodeURIComponent(googleData.code) || "";
        if ((tokenId && tokenId !== "") && accountId) {
            let data = { authToken: tokenId , source:"REACT_SSO"};
            axios.post(process.env.REACT_APP_GOOGLE_LOGIN_API + "?supportReqAccountId=" + accountId.trim(), data, { headers: { "Content-Type": "application/json" } })
                .then((response) => {
                    console.log(response);
                    let responseData = response.data;
                    if (responseData.Status && responseData.Status.toLowerCase() == 'success' && responseData.StatusCode == 200) {
                        if (responseData.SucessMsg.toLowerCase() == 'ok') {
                            const expirationTime = new Date(
                                new Date().getTime() + +response.data.expiry
                            );
                            localStorage.setItem('isNewMonitorPage',responseData?.isNewMonitorPage)

                            localStorage.setItem('employeeAppData',responseData?.monitorCards?.employeeAppData)
                            localStorage.setItem('mapAndLocation',responseData?.monitorCards?.mapAndLocation)
                            localStorage.setItem('milesData',responseData?.monitorCards?.milesData)
                            localStorage.setItem('notificationsAndAlerts',responseData?.monitorCards?.notificationsAndAlerts)
                            localStorage.setItem('timeClockData',responseData?.monitorCards?.timeClockData)
                            localStorage.setItem('timeClockReports',responseData?.monitorCards?.timeClockReports)

                            authCtx.login(
                                responseData.token,
                                responseData.token_en,
                                expirationTime.toISOString(),
                                responseData.is_parent,
                                responseData.accountId,
                                responseData.userName,
                                responseData.isBannerEnabled, 
                                responseData.bannerText,
                                responseData.userSecurityType, 
                                responseData.actSecurityType,
                                responseData.landingPage,
                                responseData.hasG2Reviewed,
                            );
                            localStorage.setItem('isAdminLogin', true);
                            localStorage.setItem('isOnBoarding',response.isOnBoarding)
                            history.replace("/");
                        }
                    } else {
                        if (response.status == 45) {
                            history.replace('/trial-account-expired');
                        } else {
                            handelAuthErrorMsg(response.data.SucessMsg);
                        }
                    }
                }).catch((error) => { console.log(error); handelAuthErrorMsg("Unable to login."); });
        }
    };

    const handleFailure = async (data) => {
        console.log(data);
    }

    const scriptFailed = () => {
        console.log('script failed');
    }

    const accountIdHandler = (event) => {
        setAccountId(event.target.value);
    }

    useEffect(() => {
        if (accountId) {
            setButtonDisable(false);
        } else {
            setButtonDisable(true);
        }
    }, [accountId]);

    const handelAuthErrorMsg = (serverMessage) => {
        let msg = <>{serverMessage}</>;
        setIsLoginError(true);
        if (serverMessage == "Invalid Credentials") {
            msg = (
                <>
                    Credentials are invalid. If you don't remember your password,{" "}
                    <Link to="/reset-password">reset it now.</Link>
                </>
            );
        }
        if (serverMessage == "Trial Account Expired" || serverMessage == "Account associated with the email is Expired") {

            const line1Style = { 'marginBottom': '0', 'lineHeight': '10px' };
            const line2Style = { 'marginBottom': '0' };
            msg = <><p style={line1Style}>Your free trial account has expired. <a href="../pricing">Upgrade now</a>.</p> <p style={line2Style}>Feel free to reach us at <a href="mailto:support@abaq.us">support@abaq.us</a> for product or trial related queries.</p></>;
        }
        setLoginErrorMsg(msg);
    };

    const loginBtnRef = useRef(null)

    return (
        <div className="userPageWrapper">
            <LeftContent></LeftContent>
            <div className="rightContent">
                <h2>Admin Login</h2>
                <div className="fldWrap accountIdFld fldWithIcon">
                    <input type="text"
                        onChange={accountIdHandler}
                        value={accountId}
                        placeholder="Account ID"
                        onKeyDown={e => {
                            if (e.key !== "Enter") return;

                            if (!buttonDisable) {
                                if (loginBtnRef.current) loginBtnRef.current.focus()
                            }
                        }}
                    />
                    <span className="fldIcon">Icon</span>
                </div>
                {<GoogleLogin
                    clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                    render={(renderProps) => (
                        <button
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}
                            className="btnStyle btn2 iconBtn googleLogin"
                            ref={loginBtnRef}
                        >
                            Login with Google
                        </button>
                    )}
                    buttonText="Login with Google"
                    onSuccess={handleLogin}
                    onFailure={handleFailure}
                    onScriptLoadFailure={scriptFailed}
                    disabled={buttonDisable}
                    accessType="offline"
                    responseType="code"
                    cookiePolicy={"single_host_origin"}
                />}

                {isLoginError && (
                    <ToastMessage
                        cssClass="msgError"
                        message={loginErrorMsg}
                        onClose={() => {
                            setIsLoginError(false);
                            setLoginErrorMsg("");
                        }}
                    />
                )}
            </div>
        </div>
    )
}
export default AdminLogin;