import React, { useState } from "react";
import { isDisabled } from "./kendoUtils";
import { Tooltip } from "@progress/kendo-react-tooltip";
import SaveKendoState from '../../../assets/images/save-kendo-state.svg'
import EditColumns from '../../../assets/images/edit-column.svg'
import exportFile from "../../../assets/images/export-file.svg"
import saveTemplate from "../../../assets/images/Save-template.svg"

import { ConfirmPopup } from "../PopUp";
import ExportIcon from "../../../assets/images/export-icon.svg";
import EditHeadersPopUp from "../../report/BuildReports/BuildReportsPopUps/EditHeadersPopUp";
import { SaveTemplatePopup } from "../../report/BuildReports/BuildReportsPopUps/SaveTemplatePopup";
import { TooltipKendo } from "../../../pages/Monitor/common/Tooltip";

export const RefreshBtn = ({ onClick, accessLevel }) => (
    <button
        title="Refresh"
        className="btnStyle btn2 btnRefresh"
        onClick={onClick}
        disabled={isDisabled('view', accessLevel)}
    >
        Refresh
    </button>
)

export const AddNewBtn = ({ onClick, accessLevel }) => (
    <button
        title="Add New"
        className="btnStyle btn2 btnAdd"
        onClick={onClick}
        disabled={isDisabled('add', accessLevel)}
    >
        Add New
    </button>
)

export const BulkDeleteBtn = ({ onClick, accessLevel, selectedState, data }) => {

    const isTxnIDNull = data?.some(d => selectedState[d.id] && d.txnID === null);

    return (
        <button
            disabled={isTxnIDNull || isDisabled('delete:multi', accessLevel, selectedState)}
            title="Bulk Delete"
            className="btnStyle btn2 btnDelete"
            onClick={onClick}
        >
            Bulk Delete
        </button>

    );
}

export const StatusDropdown = ({ dropDownValue, onChange, dropDownData = [], accessLevel, label = "Show Status", width = 100, height = 32 }) => (
    <>
        <span className="mr-5" >{label}</span>
        <select
            value={dropDownValue}
            onChange={onChange}
            disabled={isDisabled('view', accessLevel)}
            style={{ width,height }}
        >
            {
                dropDownData.map((d, ind) => (
                    <option className={`${d.disabled ? 'optionBreak' : ''}`} disabled={d.disabled} key={ind} value={d.value} >{d.label}</option>
                ))
            }
        </select>
    </>
)

export const ActiveInactiveBtn = ({ active, onClick, accessLevel }) => (
    <button
        className={`btnStyle btn2 ${!active ? 'btnActivate' : 'btnInactivate'}`}
        onClick={onClick}
        disabled={isDisabled('edit', accessLevel)}
    >
        {!active ? "Activate" : "In-activate"}
    </button>
)

export const OTPPreferenceHandler = ({ onSMSClick, onOTPClick, accessLevel }) => {

    const [active, setActive] = useState(false)

    return (
        <button
            className={`btnStyle btn2 btnOtpSettings${active ? ' active' : ''}`}
            onClick={() => setActive(!active)}
            disabled={isDisabled('edit', accessLevel)}
        >
            Select OTP Medium
            <div className="otpOptions">
                <div className="customControls capsules">
                    <div className="customRadio">
                        <input
                            type="radio"
                            id="SMS"
                            name="otpMedium"
                            onClick={onSMSClick}
                        />
                        <label htmlFor="SMS">SMS</label>
                    </div>
                    <div className="customRadio">
                        <input
                            type="radio"
                            id="Email"
                            name="otpMedium"
                            onClick={onOTPClick}
                        />
                        <label htmlFor="Email">Email</label>
                    </div>
                </div>
            </div>
        </button>
    )
}

export const CustomCheckBox = ({ accessLevel, checked, onChange, label = '' }) => (
    <div className="customCheck">
        <input
            type="checkbox"
            id="customCheck2"
            disabled={isDisabled('edit', accessLevel)}
            checked={checked}
            onChange={onChange}
        />
        <label className="customCheckLabel" htmlFor="customCheck2">{label}</label>
    </div>
)

export const AddNewDropdown = ({
    accessLevel,
    onClick,
    resetData,
    setChanges
}) => {

    const [showDropdown, setShowDropdown] = useState(false);

    const [formDirty, setFormDirty] = useState(false);
    const [showConfirmPopUp, setShowConfirmPopUp] = useState(false);


    const onSuccess = () => {
        setFormDirty(false)
        setShowDropdown(!showDropdown);
        setShowConfirmPopUp(!showConfirmPopUp);
        resetData();
        setChanges(false)
    };

    const onFailure = () => {
        setFormDirty(false)
        setShowConfirmPopUp(!showConfirmPopUp);
        setChanges(true)
    };

    const onClickAddNewHandler = () => {
        const dirty = localStorage.getItem('formIsDirty')
        if (dirty !== null && dirty === "true") {
            setFormDirty(true);
            setShowConfirmPopUp(true);
        } else {
            setShowDropdown(!showDropdown);
        }
    };

    return (
        <div>
            <button
                onBlur={() => setShowDropdown(false)}
                onClick={onClickAddNewHandler}
                className={`btnStyle btn2 btnAdd haveDropdown ${showDropdown ? 'active' : ''}`}
                disabled={isDisabled('add', accessLevel)}
            >
                Add New
                <div className="dropdownBlk">
                    <ul>
                        <li onClick={() => onClick('manual')}>Manually</li>
                        <li onClick={() => onClick('bulk')}>Bulk Upload</li>
                    </ul>
                </div>
            </button>
            {showConfirmPopUp && (
                <ConfirmPopup
                    showDialog={showConfirmPopUp}
                    message={'The changes are still unsaved. Are you sure you want to leave?'}
                    confirmNavigation={onSuccess}
                    cancelNavigation={onFailure}
                />
            )}
        </div>
    );
};

export const ExportDropdown = ({ accessLevel, onClick, module }) => {

    const [showDropdown, setShowDropdown] = useState(false)

    return (
        <button
            onBlur={() => setShowDropdown(false)}
            onClick={() => setShowDropdown(!showDropdown)}
            className={`btnStyle btn2 noIcon haveDropdown ${showDropdown ? 'active' : ''}`}
            disabled={isDisabled('view', accessLevel)}
        >
            Export
            <div className="dropdownBlk">
                <ul className="haveIcon">
                    <li onClick={() => onClick('xl')} className="xlBtn">Excel</li>
                    <li onClick={() => onClick('pdf')} className="pdfBtn">PDF</li>
                    <li onClick={() => onClick('csv')} className="csvBtn">CSV</li>
                </ul>
            </div>
        </button>
    )
}


export const ExportIconDropdown = ({ accessLevel, onClick }) => {

    const [showDropdown, setShowDropdown] = useState(false)

    return (
        <button
            className={`btnStyle btn2 noIcon haveDropdown export-no-icon ${showDropdown ? 'active' : ''}`}
            disabled={isDisabled('view', accessLevel)}>

        <TooltipKendo position="top">
        <img style={{
            borderRadius: "4px",
            border: "1px solid #25A3D6",
            background: "white",padding:"6px"}}
            onBlur={() => setShowDropdown(false)}
            onClick={() => setShowDropdown(!showDropdown)} 
            src={ExportIcon} 
            title="Export"
            alt="" />
        </TooltipKendo>
            <div className="dropdownBlk">
                <ul className="haveIcon">
                    <li onClick={() => onClick('xl')} className="xlBtn">Excel</li>
                    <li onClick={() => onClick('pdf')} className="pdfBtn">PDF</li>
                    <li onClick={() => onClick('csv')} className="csvBtn">CSV</li>
                </ul>
            </div>
        </button>
    )
}

export const BulkSuspendBtn = ({ onClick, accessLevel, selectedState }) => (
    <button
        disabled={isDisabled('edit', accessLevel, selectedState)}
        title="Bulk Suspend"
        className="btnStyle btn2 btnSuspend"
        onClick={onClick}
    >
        Bulk Suspend
    </button>
)

export const MessageBtn = ({ onClick, accessLevel }) => (
    <button
        title="Message"
        className="btnStyle btn2 btnMsg"
        onClick={onClick}
        disabled={isDisabled('edit', accessLevel)}
    >
        Message
    </button>
)

export const WeekDayViewDropdown = ({ accessLevel, selectedView, setSelectedView, resetData, onClick, setChanges }) => {

    const [showDropdown, setShowDropdown] = useState(false)
    const [showConfirmPopUp, setShowConfirmPopUp] = useState(false);
    const [formDirty, setFormDirty] = useState(false);

    const onSuccess = () => {
        setFormDirty(false)
        setShowDropdown(!showDropdown);
        setShowConfirmPopUp(!showConfirmPopUp);
        resetData();
        setChanges(false)
    };

    const onFailure = () => {
        setFormDirty(false)
        setShowDropdown(false);
        setShowConfirmPopUp(!showConfirmPopUp);
        setChanges(true)
    };

    const checkFormIsDirty = () => {
        const dirty = localStorage.getItem('formIsDirty')
        if (dirty !== null && dirty === "true") {
            setFormDirty(true);
            setShowConfirmPopUp(true);
        } else {
            setShowDropdown(!showDropdown)
        }
    };

    return (
        <div>
            <button
                onBlur={() => setShowDropdown(false)}
                onClick={() => checkFormIsDirty()} // Check if the form is dirty before toggling the view
                className={`btnStyle btn2 haveDropdown ${showDropdown ? 'active' : ''}`}
                style={{ paddingLeft: 8 }}
                disabled={isDisabled('view', accessLevel)}
            >
                {selectedView === 'day' ? 'Day View' : 'Week View'}
                <div className="dropdownBlk">
                    <ul>
                        <li
                            onClick={() => {
                                setSelectedView(selectedView === 'day' ? 'week' : 'day')
                                onClick(selectedView === 'day' ? 'week' : 'day')
                            }}
                        >
                            {selectedView === 'day' ? 'Week View' : 'Day View'}
                        </li>
                    </ul>
                </div>
            </button>
            {showConfirmPopUp && (
                <ConfirmPopup
                    showDialog={showConfirmPopUp}
                    message={'The changes are still unsaved. Are you sure you want to leave?'}
                    confirmNavigation={onSuccess}
                    cancelNavigation={onFailure}
                />
            )}
        </div>
    );
}

export const RejectBtn = ({ onClick, accessLevel }) => (
    <button
        title="Message"
        className="btnStyle btn2"
        onClick={onClick}
        style={{ paddingLeft: 8 }}
        disabled={isDisabled('edit', accessLevel)}
    >
        Reject
    </button>
)

export const SaveGridStateBtn = ({ onClick, accessLevel }) => (
    <Tooltip position="bottom" anchorElement="target" >
        <button
            className="btnStyle btn2"
            title='Save Table State'
            onClick={onClick}
            disabled={(accessLevel.childMenu ? accessLevel.childMenu < 2 : false)}
            style={{
                padding: 0,
                paddingTop: 1
            }}
        >
            <img title="Save Table State" src={SaveKendoState} />
        </button>
    </Tooltip>
)


export const ResetGridStateBtn = ({ onClick, accessLevel }) => (
    <Tooltip position="bottom" anchorElement="target" >
        <button
            className="btnStyle btn2"
            title='Reset Table State'
            onClick={onClick}
            disabled={(accessLevel.childMenu ? accessLevel.childMenu < 2 : false)}
            style={{
                padding: '2px 8px'
            }}
        >
            Reset
        </button>
    </Tooltip>
)


export const ExportButton = ({ accessLevel, onClick }) => {

    const [showDropdown, setShowDropdown] = useState(false)

    return (

        <button
            onBlur={() => setShowDropdown(false)}
            onClick={() => setShowDropdown(!showDropdown)}
            className={`btnStyle btn2 noIcon BRhaveDropdown ${showDropdown ? 'active' : ''}`}
            disabled={isDisabled('view', accessLevel)}
            style={{ height: "32px", marginTop: "22px" }}
        >
            <Tooltip position='bottom' >
                <img title="Export to Excel, PDF" src={exportFile} style={{ marginTop: "3px" }} />
            </Tooltip>
            <div className="dropdownBlk">
                <ul>
                    <li onClick={() => onClick('xl')}>Export as Excel</li>
                    <li className="separator"></li>
                    <li onClick={() => onClick('pdf')}>Export as PDF</li>
                </ul>
            </div>
        </button>
    )
}

export const SaveTemplate = ({ dataState, columns, subModule, setMsgClass, setToastMsg, setShowLoader }) => {
    const [popUp, setPopUp] = useState(false)
    const handleClick = () => {
        setPopUp(!popUp);
    };
    return (
        <>
            <button className="saveTemplateButton" onClick={handleClick}>
                <Tooltip position='bottom'>
                    <div className="buttonContent">
                        <img title="Save Reports" src={saveTemplate} alt="Save Reports" />
                        <span>Save Reports</span>
                    </div>
                </Tooltip>
            </button>
            {popUp && <SaveTemplatePopup setPopUp={setPopUp} dataState={dataState} columns={columns} subModule={subModule} setMsgClass={setMsgClass} setToastMsg={setToastMsg} setShowLoader={setShowLoader} />}
        </>
    )
}
