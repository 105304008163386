import { Fragment, useState, useEffect } from "react";
import { apiProvider } from '../../../services/api/provider';
import DeletePopup from "../../common/PopUp/DeletePopup";
import { ViewJobsitesAuthorization } from "./ViewJobsitesAuthorization/ViewJobsitesAuthorization";

import $ from "jquery";

const ViewManager = (props) => {

    const [managerDetails, setManagersDetails] = useState(null);
    const [sectionExpand, setSectionExpand] = useState({
        managerInfo: true,
        groupSettings: true,
        loginSettings: true,
        accessLevelSettings: true,
        userAccessControl: true,
        jobsiteSettings: true
    });
    const [deletePopup, setDeletePopup] = useState(false)

    const deleteManagers = async () => {

        const payload = [managerDetails?.managerID]

        try {
            props.setShowLoader(true)
            const response = await apiProvider.removeObject('/track/mobile/v1/allgeo/manager', payload)
            if (response.status === 200) {
                setDeletePopup(false)
                props.setMode({
                    title: "Managers",
                    type: 'main',
                    data: null,
                    hasPrev: false
                })
                props.setToastMsg({
                    msg: response.message,
                    type: "msgSuccess"
                })
            } else {
                props.setToastMsg({
                    msg: response.message,
                    type: "msgError"
                })
            }
        } catch (error) {
            console.log(error);
        } finally {
            props.setShowLoader(false)
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        props.setShowLoader(true);

        apiProvider.getSingle('track/mobile/v1/allgeo/manager', props.managerId).then((response) => {
            if (response.status === 200 && response.message.toLowerCase() === 'success') {
                response.managers[0].thumbnailChar = (response.managers[0].managerDesc).split('')[0]
                setManagersDetails(response.managers[0])
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            props.setShowLoader(false);
        });

    };

    const toggleSection = (sectionType) => {
        const selectedSection = sectionExpand[sectionType]
        let sections = { ...sectionExpand };
        sections[sectionType] = !selectedSection;
        setSectionExpand(sections)
    }

    $(".copyIcon").on("click", function () {
        navigator.clipboard.writeText(managerDetails?.managerID);
        $(this).siblings('.copyClipboard').show().delay(4000).fadeOut();
    });

    return (

        managerDetails && <Fragment>
            <div className="viewTopSection">
                <div className="headingCtrl">
                    <a className={`editGrp${props.accessLevel < 2 ? ' disabled' : ''}`}
                        onClick={() => props.setMode({
                            title: "Edit Managers",
                            type: 'edit',
                            data: { managerID: props.managerId },
                            hasPrev: true
                        })}>Edit Manager</a>
                    <a className={`deleteGrp${props.accessLevel < 3 ? ' disabled' : ''}`}
                        onClick={() => setDeletePopup(true)}>Delete Manager</a>
                </div>
                <div className="viewInfo"><span className="infoAvatar">{managerDetails?.thumbnailChar}</span> {managerDetails?.managerDesc}</div>
                <div className="highlightedFldWrap">
                    <div className="fldWrap">
                        <label>Manager ID</label>
                        <div className="highlightedValue">
                            <span>{managerDetails?.managerID}</span>
                            <span className="copyIcon">Copy</span>
                            <span className="copyClipboard">Copied to the clipboard!</span>
                        </div>
                    </div>
                    <div className="fldWrap">
                        <label>Creation Date & Time</label>
                        <div className="highlightedValue">
                            <span>{managerDetails?.creationTime}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="innerWrap">
                <ul className="accordionList" >
                    <li id="managerInfo" className={`${sectionExpand.managerInfo && 'active'}`} onClick={() => toggleSection('managerInfo')}>
                        <div className="accHeading">Manager Information</div>
                        <div className="accContent">
                            <div className="rowBlk">
                                <div className="colBlk4 fldWrap">
                                    <label>Manager Description</label>
                                    <p>{managerDetails?.managerDesc !== "" ? managerDetails?.managerDesc : "-"}</p>
                                </div>
                                <div className="colBlk4 fldWrap">
                                    <label>Contact Name</label>
                                    <p>{managerDetails?.contactName !== "" ? managerDetails?.contactName : "-"}</p>
                                </div>
                                <div className="colBlk4 fldWrap">
                                    <label>Status</label>
                                    <p className={`${managerDetails?.active === 'Yes' && 'txtActive'}`}>{managerDetails?.active === 'Yes' ? 'Active' : 'Inactive'}</p>
                                </div>
                            </div>
                            <div className="rowBlk">
                                {
                                    (localStorage.getItem('userName')).toLowerCase() === 'admin' && <div className="colBlk4 fldWrap">
                                        <label>Password</label>
                                        <p>{managerDetails?.pwd}</p>
                                    </div>
                                }
                                <div className="colBlk4 fldWrap">
                                    <label>Phone</label>
                                    <p>{managerDetails?.phoneVerified ? 'Enabled' : 'Disabled'}</p>
                                </div>
                                <div className="colBlk4 fldWrap">
                                    <label>Contact Email</label>
                                    <p>{managerDetails?.contactEmail !== "" ? managerDetails?.contactEmail : "-"}</p>
                                </div>
                                {
                                    (localStorage.getItem('userName')).toLowerCase() !== 'admin' && <div className="colBlk4 fldWrap">
                                        <label>Timezone</label>
                                        <p>{managerDetails?.timezone !== "" ? managerDetails?.timezone : "-"}</p>
                                    </div>
                                }
                            </div>
                            <div className="rowBlk">
                                {
                                    (localStorage.getItem('userName')).toLowerCase() === 'admin' && <div className="colBlk4 fldWrap">
                                        <label>Timezone</label>
                                        <p>{managerDetails?.timezone !== "" ? managerDetails?.timezone : "-"}</p>
                                    </div>
                                }
                                <div className="colBlk4 fldWrap">
                                    <label>Notify Email</label>
                                    <p>{managerDetails?.notifyEmail !== "" ? managerDetails?.notifyEmail : "-"}</p>
                                </div>
                                <div className="colBlk4 fldWrap">
                                    <label>OTP Medium</label>
                                    <p>{managerDetails?.otpMedium !== "" ? managerDetails?.otpMedium : "-"}</p>
                                </div>
                                {/* <div className="colBlk4 fldWrap">
                                    <label>Vehicle ID</label>
                                    <p>{managerDetails?.contactPhone !== "" ? managerDetails?.contactPhone : "-"}</p>
                                </div> */}
                            </div>
                            <div className="rowBlk">
                                <div className="colBlk4 fldWrap">
                                    <label>Will this manager be managing employees using web application?</label>
                                    <p>{managerDetails?.webManage === true ? "Yes" : "No"}</p>
                                </div>
                                <div className="colBlk4 fldWrap">
                                    <label>Will this manager be tracked for time and location?</label>
                                    <p>{managerDetails?.trackManager === true ? "Yes" : "No"}</p>
                                </div>
                                <div className="colBlk4 fldWrap">
                                    <label>Will this manager be managing employees using mobile application?</label>
                                    <p>{managerDetails?.mobileManage === true ? "Yes" : "No"}</p>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li id="groupSettings" className={`${sectionExpand.groupSettings && 'active'}`} onClick={() => toggleSection('groupSettings')}>
                        <div className="accHeading">Group Authorization Settings</div>
                        <div className="accContent">
                            <div className="sectionSubHeading">Authorized Employee Groups</div>
                            <p>Manager is authorized in the following groups</p>
                            <ul className="blockCheckbox withoutCheck">
                                {managerDetails?.groupList && managerDetails?.groupList.map((ele, index) => (
                                    managerDetails?.authorizedGroups.includes(ele.groupId) &&
                                    <li key={index}>
                                        <div className="customCheck">
                                            <label className="customCheckLabel" htmlFor="name1"><span><em className="groupColor"
                                                style={{ background: ele.colour }}>G</em>{ele.groupName === undefined || ele.groupName === "" ? ele.groupId : ele.groupName}</span></label>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </li>
                    <li id="jobsiteSettings" className={`${sectionExpand.jobsiteSettings && 'active'}`} onClick={() => toggleSection('jobsiteSettings')}>
                        <ViewJobsitesAuthorization managerDetails={managerDetails}/>
                    </li>
                    <li id="loginSettings" className={`${sectionExpand.loginSettings && 'active'}`} onClick={() => toggleSection('loginSettings')}>
                        <div className="accHeading">Login Page Settings</div>
                        <div className="accContent">
                            <div className="rowBlk">
                                <div className="colBlk4 fldWrap">
                                    <label>First Login Page</label>
                                    <p>{managerDetails?.firstLoginPage !== "" ? managerDetails?.loginPageList[managerDetails?.firstLoginPage] : "-"}</p>
                                </div>
                                <div className="colBlk4 fldWrap">
                                    <label>Preferred Driver</label>
                                    <p>{managerDetails?.preferredDeviceID !== "" ? managerDetails?.preferredDeviceID : "-"}</p>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li id="accessLevelSettings" className={`${sectionExpand.accessLevelSettings && 'active'}`} onClick={() => toggleSection('accessLevelSettings')}>
                        <div className="accHeading">Access Level Settings</div>
                        <div className="accContent">
                            <div className="rowBlk">
                                <div className="colBlk4 fldWrap">
                                    <label>Maximum Access Level</label>
                                    <p>{managerDetails?.maxAclList[managerDetails?.maxAcl]}</p>
                                </div>
                                <div className="colBlk4 fldWrap">
                                    <label>Device ID Hidden?</label>
                                    <p>{managerDetails.hideDeviceID ? 'Yes' : 'No'}</p>
                                </div>
                                <div className="colBlk4 fldWrap">
                                    <label>Manager Account Unlocked?</label>
                                    <p>{managerDetails.locked ? 'Yes' : 'No'}</p>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li id="userAccessControl" className={`${sectionExpand.userAccessControl && 'active'}`} onClick={() => toggleSection('userAccessControl')}>
                        <div className="accHeading">User Access Control</div>
                        <div className="accContent">
                            <div className="rowBlk">
                                <div className="colBlk6">
                                    <div className="sectionSubHeading">Web Access Control</div>
                                    <table cellPadding="0" cellSpacing="0" className="tblStyle accessTbl">
                                        <tbody>
                                            <tr>
                                                <th>Entity</th>
                                                <th>View</th>
                                                <th>Edit</th>
                                                <th>Add/Delete</th>
                                            </tr>
                                            {managerDetails?.aclList && managerDetails?.aclList.map((ele, ind) => (
                                                <tr key={`web_${ind}`}>
                                                    <td>{ele.desc}</td>
                                                    <td>{ele.assigned >= 1 && <span className="checkedOk">Ok</span>}</td>
                                                    <td>{ele.assigned >= 2 && <span className="checkedOk">Ok</span>}</td>
                                                    <td>{ele.assigned === 3 && <span className="checkedOk">Ok</span>}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="colBlk6">
                                    <div className="sectionSubHeading">API Access Control</div>
                                    <table cellPadding="0" cellSpacing="0" className="tblStyle accessTbl">
                                        <tbody>
                                            <tr>
                                                <th>Entity</th>
                                                <th>View</th>
                                                <th>Edit</th>
                                                <th>Add/Delete</th>
                                            </tr>
                                            {managerDetails?.aclList && managerDetails?.aclList.map((ele, ind) => (
                                                <tr key={`api_${ind}`}>
                                                    <td>{ele.desc}</td>
                                                    <td>{ele.api_assigned >= 1 && <span className="checkedOk">Ok</span>}</td>
                                                    <td>{ele.api_assigned >= 2 && <span className="checkedOk">Ok</span>}</td>
                                                    <td>{ele.api_assigned === 3 && <span className="checkedOk">Ok</span>}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="mainBtnWrap">
                <button className="btnStyle" onClick={() => props.setMode({
                    title: "Managers",
                    type: 'main',
                    data: null,
                    hasPrev: false
                })}>Back</button>
            </div>
            <div className={`overlay${deletePopup ? ' active' : ''}`} />
            {
                deletePopup &&
                <DeletePopup
                    onSuccess={deleteManagers}
                    closePopup={() => setDeletePopup(false)}
                />
            }
        </Fragment>
    )
}

export default ViewManager