import axios from 'axios';
import { handleResponse, handleError } from './response';

// Define your api url from any source.
// Pulling from your .env file when on the server or from localhost when locally
const BASE_URL = process.env.REACT_APP_API_URL;
const config = {
  headers: {
    'Authorization': 'Bearer' + localStorage.getItem('token'),
    'Content-Type': 'application/json',
  }
}

/** @param {string} resource */
const getAll = (resource, params = {}) => {
  const config = {
    headers: {
      'Authorization': 'Bearer' + localStorage.getItem('token'),
      'Content-Type': 'application/json',
    }
  }
  return axios
    .get(`${BASE_URL}/${resource}`, {
      ...config,
      params
    })
    .then(handleResponse)
    .catch(handleError);
};

/** @param {string} resource */
/** @param {string} id */
const getSingle = (resource, id) => {
  let url;

  if (id) {
    url = `${BASE_URL}/${resource}/${id}`
  } else {
    url = `${BASE_URL}/${resource}`
  }

  return axios
    .get(url, config)
    .then(handleResponse)
    .catch(handleError);
};

/** @param {string} resource */
/** @param {object} model */
const post = (resource, model) => {
  const config = {
    headers: {
      'Authorization': 'Bearer' + localStorage.getItem('token'),
      'Content-Type': 'application/json',
    }
  }
  
  return axios
    .post(`${BASE_URL}/${resource}`, model, config)
    .then(handleResponse)
    .catch(handleError);
};

/** @param {string} resource */
/** @param {object} model */
const formPost = (resource, model) => {
  return axios
    .post(`${BASE_URL}/${resource}`, model, {
      headers: {
        'Authorization': 'Bearer' + localStorage.getItem('token'),
        'Content-Type': 'multipart/form-data',
      }

    })
    .then(handleResponse)
    .catch(handleError);
};

/** @param {string} resource */
/** @param {object} model */
const put = (resource, model) => {
  const config = {
    headers: {
      'Authorization': 'Bearer' + localStorage.getItem('token'),
      'Content-Type': 'application/json',
    }
  }
  
  return axios
    .put(`${BASE_URL}/${resource}`, model, config)
    .then(handleResponse)
    .catch(handleError);
};

/** @param {string} resource */
/** @param {object} model */
const patch = (resource, model) => {
  return axios
    .patch(`${BASE_URL}/${resource}`, model, config)
    .then(handleResponse)
    .catch(handleError);
};

/** @param {string} resource */
/** @param {string} id */
const remove = (resource) => {
  const config = {
    headers: {
      'Authorization': 'Bearer' + localStorage.getItem('token'),
      'Content-Type': 'application/json',
    }
  }
  return axios
    .delete(`${BASE_URL}/${resource}`, config)
    .then(handleResponse)
    .catch(handleError);
};

/** @param {string} resource */
const removeObject = (resource, data) => {
  const config = {
    headers: {
      'Authorization': 'Bearer' + localStorage.getItem('token'),
      'Content-Type': 'application/json',
    }
  }
  return axios
    .delete(`${BASE_URL}/${resource}`, {
      ...config,
      data: JSON.stringify(data)

    })
    .then(handleResponse)
    .catch(handleError);
};

const update = (resource, model) => {

  const requestOptions = {
    method: 'UPDATE',
    headers: config.headers,
    body: JSON.stringify(model)
  };

  return fetch(`${BASE_URL}/${resource}`, requestOptions)
    .then(handleResponse)
    .catch(handleError);
}

export const apiProvider = {
  getAll,
  getSingle,
  post,
  formPost,
  put,
  patch,
  remove,
  removeObject,
  update
};