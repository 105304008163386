import React from "react"

const InfoPopup = ({ msg, onClick }) => {
    return (
    <div className="popupWrap msgPopup active">
        <div className="msgPopupCont">
            <div className="msgIcon">Info</div>
            <div className="msgContent">
                <div>{msg}</div>
            </div>
        </div>
        <div className="popupMsgBtn">
            <button className="btnStyle okBtn" onClick={onClick}>Ok</button>
        </div>
    </div>
)}

export default InfoPopup