import { apiProvider } from "../../../../services/api/provider";

export const handleGetMsgTemplates = async (setMsgTemplates) => {
  try {
    const messageTemplateResponse = await apiProvider.getAll(
      "track/react/v1/allgeo/getMessageTemplates"
    );

    const messageTemplates = {};

    messageTemplateResponse?.data?.forEach((template) => {
      const key = Object.keys(template)[0];
      const value = template[key];
      messageTemplates[value] = key;
    });

    setMsgTemplates(messageTemplates);
  } catch (err) {
    console.error("Error fetching Notifications Data", err);
    return [];
  }
};

export const handleSendMessage = async (
  setCurrentTimeAndDate,
  notificationAndAlertMetaData,
  setNotificationAndAlertsData,
  setMsgTemplates
) => {
  try {
    const messageTemplateResponse = await apiProvider.getAll(
      "track/react/v1/allgeo/getMessageTemplates"
    );

    const messageTemplates = {};

    messageTemplateResponse?.data?.forEach((template) => {
      const key = Object.keys(template)[0];
      const value = template[key];
      messageTemplates[value] = key;
    });

    setMsgTemplates(messageTemplates);
  } catch (err) {
    console.error("Error fetching Notifications Data", err);
    return [];
  }
};
