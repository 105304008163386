import React, { useRef } from "react"
import { apiProvider } from '../../../services/api/provider'

const JobStatus = ({ data, timeclockStage, closePopup, setToastMsg, setShowLoader }) => {
    const timeclockRef = useRef(null)

    const changeJobStage = async () => {
        try {
            setShowLoader(true)
            
            const stageID = +timeclockRef.current?.value?.split(":")[1]
            const response = await apiProvider.put(`track/react/v1/allgeo/monitor/updateHOSStage?deviceId=${data.deviceId}&changedHosStageId=${stageID}`)
            
            if (response.status === 200) {
                setToastMsg({ msg: `Job Status updated successfully.`, type: "msgSuccess" })
                closePopup()
            } else {
                setToastMsg({ msg: response.message, type: "msgError" })
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="popupWrap smlPopup active">
            <div className="closePopup" onClick={closePopup} >Close</div>
            <div className="popupHeading">Change Job Status</div>
            <div className="popupContent">
                <div className="rowBlk">
                    <div className="colBlk6 fldWrap">
                        <label>Device ID</label>
                        <p>{data.deviceId}</p>
                    </div>
                    <div className="colBlk6 fldWrap">
                        <label>Current Stage</label>
                        <p>{data.lastTimeClockStage}</p>
                    </div>
                </div>
                <div className="fldWrap">
                    <label>Change Stage To</label>
                    <select ref={timeclockRef} >
                        {
                            timeclockStage.map(stage => (
                                <option key={stage.value} value={stage.value} >{stage.label}</option>
                            ))
                        }
                    </select>
                </div>

            </div>
            <div className="popupBtnWrap flexJCenter">
                <button className="btnStyle okBtn" onClick={changeJobStage} >Change</button>
            </div>
        </div>
    )
}

export default JobStatus