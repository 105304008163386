import React, { Fragment, useEffect } from "react";

const ToastMessage = (props) => {

    useEffect(() => {
        if (props.cssClass === 'msgSuccess' || props.delay) {
            const id = setTimeout(() => {
                props.onClose()
            }, props.delay || 3000)

            return () => clearTimeout(id)
        }
    }, [])

    return (
        <Fragment>
            <div className={`floatingMessage ${props.cssClass}`}>
                {
                    typeof props.message === 'string'
                        ?
                        props.message.includes("\n")
                            ?
                            props.message.split("\n").map((text, ind) => (
                                <div key={ind}>
                                    {text}
                                </div>
                            ))
                            :
                            props.message
                        :
                        props.message
                }
                <div className="closeMessage" onClick={props.onClose}>Close</div>
            </div>
            <div className="overlayTBlank active" onClick={props.onClose}></div>
        </Fragment>
    );
}
export default ToastMessage;