import { getAccountLabel } from "../../../../utils";

export const trackLocation = [
    {
        value: "No",
        label: "No"
    },
];

export const defaultTrack = {
    value: "Yes",
    label: "Yes"
};

export const defaultTimeZone = {
    value: "GMT+05:30",
    label: "GMT+05:30"
};

export const sports = [
    { text: "Monday", id: "mon" },
    { text: "Tuesday", id: "tue" },
    { text: "Wednesday", id: "wed" },
    { text: "Thursday", id: "thu" },
    { text: "Friday", id: "fri" },
    { text: "Saturday", id: "sat" },
    { text: "Sunday", id: "sun" },
];

export const defaultValues = {
    workOrderNumber: '',
    deviceDesc: '',
    deviceID: '',
    startDate: new Date(),
    endDate: new Date(),
    startTime: '',
    endTime: new Date(),
    trackingDay: new Date(),
    timezone: '',
    locatable: 'Yes',
    customerName: '',
    taskName: '',
    formName: '',
    message: '',
    workOrderType: '',
    billingRate: '',
};

export const csvErrorMsg = {
    msg: "Please Select CSV/XLSX file to Proceed",
    type: "msgError"
}

export const mandatoryFieldErrorMsg = {
    msg: "Please Fill all mandatory Details to Proceed",
    type: "msgError"
}

export const invalidDateErrorMsg = {
    msg: "Start date should be before end date.",
    type: "msgError"
}

export const deleteBtnErrorMsg = {
    msg: "Please select two or more rows to delete",
    type: "msgError"
}

const employeeLabel = getAccountLabel('employee')
export const addOnGroupData = [
    {
        label: employeeLabel,
        value: "employee"
    },
    {
        label: "Available/Unassigned",
        value: "unassigned"
    },
];
export const defaultRows = Array.from({ length: 5 }, (_, index) => ({
    rowID: index + 1,
    ...defaultValues
}));
