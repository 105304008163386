import { formatDate } from '@progress/kendo-intl';


export const trackingReportColumns = [
    {
        title: `Device ID`,
        field: "deviceID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true
    },
    {
        title: "Device Name",
        field: "deviceName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,
    },
    {
        title: "Txn Date",
        field: "txnDate",
        editable: false,
        width: 200,
        editor: "text",
        customCell: true,
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true
    },
    {
        title: "Time",
        field: "txnTime",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Lat/Log",
        field: "latlong",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell:true,
        mandatory: true,
        maxLength: 5000
    },
    {
        title: "Address",
        field: "address",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Site Status-Test",
        field: "status",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Accuracy",
        field: "accuracy",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Trip Number",
        field: "tripNumber",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Device Method",
        field: "deviceMethod",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Event Type",
        field: "eventType",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Battery Level",
        field: "batteryLevel",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Hours",
        field: "elapsedTimeSec",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
]

export const PARAMS = {
    date_fr: formatDate(new Date(), 'yyyy/MM/dd'),
    date_to: formatDate(new Date(), 'yyyy/MM/dd'),
    device_tz_selected: "Account",
}


export const trackingReportTableAggregates = [
    {
        field: "elapsedTimeSec",
        aggregate: "sum"
    },
];

export const trackingReportTableAggregatesText = {
    "elapsedTimeSec": "Total Hours",
}