import React from 'react'

const ManagerNotesConfirmPopup = ({onConfirm, onCancel, onCancelupdate}) => {
    return (
        <>
            <div className="popupWrap smlPopup active popupV2">
                <div className="closePopup" onClick={onCancel}>Close</div>
                <div className="popupHeading">Manager Notes</div>
                <div className="popupContent alignCenter">
                    <p>Do you want to update the Manager Notes?</p>
                </div>
                <div className="popupBtnWrap flexJCenter">
                    <button className="btnStyle" onClick={onConfirm}>Yes</button>
                    <button className="btnStyle btn2" onClick={onCancelupdate}>No</button>
                </div>
            </div>
        </>
    )
}

export default ManagerNotesConfirmPopup