import React, { Fragment, useRef, useEffect, useState } from 'react'
import { apiProvider } from '../../../../services/api/provider'
import KendoGrid from '../../../common/kendo/KendoGrid'
import { advanceTimeClockColumns, NO_RECORD_TEXT, reportTableAggregatesText, customDateFormat, reportTableAggregates } from '../editReportUtils';
import { ManagerNotesPopup } from '../ManagerNotesPopup';
import { formatDate } from '@progress/kendo-intl';
import AddNewRecord from '../AddNewRecord';
import BulkUploadPopup from '../BulkUploadPopup';

const params = {
    date_fr: formatDate(new Date(), 'yyyy/MM/dd'),
    date_to: formatDate(new Date(), 'yyyy/MM/dd'),
    reportFor: "all",
    offsetStr: "",
    tzStr: "",
    limit: "",
    timeclockSource: "manual",
    reportSource: "EditRecord",
    showDistance: "Yes",
    stageid: "99,99"
}

const AdvanceTimeClock = ({ columnName, dropdownData, setShowLoader, toastMsg, setToastMsg, accessLevel, setColumnList, columnList, setKendoDataState }) => {

    const globalStartDate = sessionStorage.getItem('EditReport:startDate')
    const globalEndDate = sessionStorage.getItem('EditReport:endDate')
    const reportForValues = sessionStorage.getItem('EditReport:reportFor')


    if (globalStartDate && globalEndDate) {
        params.date_fr = globalStartDate
        params.date_to = globalEndDate
        params.reportFor = reportForValues || ''
    }

    const [advanceTimeClockData, setAdvanceTimeClockData] = useState(null)
    const [managerNotesPopup, setManagerNotesPopup] = useState({
        show: false,
        data: null,
        createData: null
    })
    const [showAddNewRecordPopup, setShowAddNewRecordPopup] = useState(false)
    const [showBulkUploadPopup, setShowBulkUploadPopup] = useState(false)

    const childRef = useRef()
    // const taskData = JSON.parse(dropdownData.taskAll)

    const getTimeClockReports = async () => {
        try {
            setShowLoader(true)
            const response = await apiProvider.getAll('/track/mobile/v1/allgeo/evvcustomtimeclockreport', params)
            setAdvanceTimeClockData(response)
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    const createReportData = async data => {
        const payload = [...data]?.map(fields => ({
            deviceID: fields.deviceID,
            jobName: fields.jobName,
            customerName: fields.customerName,
            distanceTravelled: fields.distanceTravelled,
            managerNotes: fields.managerNotes,
            remarks: fields.remarks,
            startStageName: fields.startStageName,
            endStageName: fields.endStageName,
            startNotes: fields.startNotes,
            endNotes: fields.endNotes,
            startTempCol: customDateFormat(fields.startTCSentDate, "yyyy/MM/dd", dropdownData.dateFormat) + " " + fields.startTCSentTime,
            endTempCol: customDateFormat(fields.endTCSentDate, "yyyy/MM/dd", dropdownData.dateFormat) + " " + fields.endTCSentTime,
        }))
        try {
            const res = await apiProvider.post('track/mobile/v1/allgeo/createAdvanceTimeclock', payload)
            return {
                status: res.StatusCode,
                message: res.StatusCode === 200 ? "Report created successfully" : res.ErrorMsg
            };
        } catch (error) {
            console.log(error);
        }
    }

    const updateTimeClock = async data => {
        const payload = [...data]?.filter(d => d.txnID)?.map(fields => {
            let taskNameEdited = localStorage.getItem('taskName')
            let taskIDEdited = localStorage.getItem('taskID')
            const getAllTaskId = JSON.parse(localStorage.getItem("taskIdMap"))
            const selectedTaskdetailed = JSON.parse(localStorage.getItem("selectedTaskdetailed"))
            let jobNameText = "";
            
            selectedTaskdetailed?.forEach((e) => {
                if (e.id === fields.id) {
                    const foundTask = getAllTaskId?.find((item) => item.Id === e.task);
               
                    if (foundTask) {
                        jobNameText = foundTask.Name;
                    } else {
                       
                        jobNameText = fields.jobName;
                    }
                }
            });

            let startTime = fields.startTCSentTime.split(" ")
            let endTime = fields.endTCSentTime.split(" ")

            if (startTime.length > 2) {
                startTime = startTime[0] + " " + startTime[1]
            } else {
                startTime = startTime[0]
            }

            if (endTime.length > 2) {
                endTime = endTime[0] + " " + endTime[1]
            } else {
                endTime = endTime[0]
            }

            const map = JSON.parse(localStorage.getItem("Map")) || [];
            const mapEntry = map?.find(entry => entry.id === fields.txnID);

            return {
                accountID: fields.accountID,
                deviceID: fields.deviceID,
                jobName: jobNameText,//sending jobname all the time 
                customerName: fields.customerName,
                distanceTravelled: fields.distanceTravelled,
                oldDistanceTravelled: fields.oldDistanceTravelled,
                managerNotes: fields.managerNotes,
                startMessage: fields.startMessage,
                endtMessage: fields.endtMessage,
                trade: fields.trade,
                earningCode: fields.earningCode,
                startTCSentDate: fields.startTCSentDate,
                startTCSentTime: fields.startTCSentTime,
                endTCSentDate: fields.endTCSentDate,
                endTCSentTime: fields.endTCSentTime,
                remarks: fields.remarks,
                startMessage: fields.startMessage,
                endtMessage: fields.endMessage,
                trade: fields.trade,
                startStageName: fields.startStageName,
                endStageName: fields.endStageName,
                startNotes: fields.startNotes,
                endNotes: fields.endNotes,
                startTempCol: customDateFormat(fields.startTCSentDate, "yyyy/MM/dd", dropdownData.dateFormat) + " " + startTime,
                endTempCol: customDateFormat(fields.endTCSentDate, "yyyy/MM/dd", dropdownData.dateFormat) + " " + endTime,
                startTxnID: fields.startTxnID,
                endTxnID: fields.endTxnID,
                statusFlagCode: fields.statusFlagCode,
                statusFlagCodeDesc: fields.statusFlagCodeDesc,
                ...(mapEntry && {
                    endDateClicked: mapEntry.endDateClicked,
                    endTimeClicked: mapEntry.endTimeClicked
                }),
                taskIDEdited: taskIDEdited ? taskNameEdited : "false",
                taskNameEdited: taskNameEdited ? taskNameEdited : "false",
                hideMenuCloumns: true
            }
        })

        const recordWithoutTxnIDs = data.filter(d => !d.txnID)

        if (data.length > 0) {
            setManagerNotesPopup({
                show: true,
                data: payload,
                createData: recordWithoutTxnIDs
            })
            return {}
        }

        try {
            if (recordWithoutTxnIDs.length > 0) {
                await createReportData(recordWithoutTxnIDs)
            }

            const response = await apiProvider.post('/track/mobile/v1/allgeo/updateAdvanceTimeclock', payload)
            //removing map
            localStorage.removeItem("Map")
            localStorage.removeItem("taskID")
            localStorage.removeItem("taskName")
            localStorage.removeItem("selectedTaskdetailed")



            return {
                status: response.StatusCode,
                message: "Time Clock Report Updated Successfully"
            }
        } catch (error) {
            console.log(error);
        }
    }

    const deleteReport = async data => {

        const payload = data.length ? data?.map(d => ({
            accountID: d.accountID,
            deviceID: d.deviceID,
            startTxnID: d.startTxnID,
            endTxnID: d.endTxnID
        })) : [{
            accountID: data.accountID,
            deviceID: data.deviceID,
            startTxnID: data.startTxnID,
            endTxnID: data.endTxnID
        }]

        try {
            setShowLoader(true)
            const response = await apiProvider.post('/track/mobile/v1/allgeo/deleteAdvanceTimeclock', payload)
            if (data.length)
                response.message = "The selected records have been deleted successfully";
            else
                response.message = `Selected Report is deleted`

            return {
                status: response.StatusCode,
                message: response.message,
            }
        } catch (error) {
            console.log(error);
            throw new Error(error)
        } finally {
            setShowLoader(false)
        }
    }

    const openPopup = (type, data) => {

        if (type === 'manual') {
            setShowAddNewRecordPopup(true)
        }

        if (type === 'bulk') {
            setShowBulkUploadPopup(true)
        }

        if (type === "latlog") {
            window.open(`https://maps.google.com/maps?q=${data.latlog}`, "_blank")
        }
    }

    useEffect(() => {
        getTimeClockReports()

        // replacing static column data from api data that we getting from props
        // advanceTimeClockColumns.forEach(item => {
        //     if (Object.keys(columnName).includes(item.field)) {
        //         item.title = columnName[item.field] || item.title
        //     }
        // })

        const updatedEditTimeClockColumns = advanceTimeClockColumns.map(item => {
            if (Object.keys(columnName).includes(item.field)) {
                return {
                    ...item,
                    title: columnName[item.field] || item.title
                };
            }
            return item;
        });

        setColumnList(updatedEditTimeClockColumns);

        return () => {
            // Removing included TxnId check
            sessionStorage.removeItem('AdvanceTimeClockReport:includeTxnIds')
        }
    }, [])

    useEffect(() => {
        if (toastMsg.msg && toastMsg.type === "msgSuccess") {
            if (childRef.current) {
                childRef.current.refreshData()
            }
            setShowLoader(false)
        }
    }, [toastMsg])

    const totalHourworkedTitle = advanceTimeClockColumns?.find(e => e.field == "totoalServiceHours").title
    const totalServiceHours = advanceTimeClockColumns?.find(e => e.field == "serviceSeconds").title
    return (
        <Fragment>
            {
                advanceTimeClockData &&
                <KendoGrid
                    gridData={advanceTimeClockData}
                    setGridData={setAdvanceTimeClockData}
                    columnData={columnList}
                    accessLevel={accessLevel}
                    ref={childRef}
                    module={"editReports"}
                    getAPiUrl={'/track/mobile/v1/allgeo/evvcustomtimeclockreport'}
                    requestParams={params}
                    openPopup={openPopup}
                    createItem={createReportData}
                    deleteItem={deleteReport}
                    lockActionColumn={true}
                    updateItem={updateTimeClock}
                    bulkDelete={deleteReport}
                    otherData={{
                        report: "evv",
                        actionColumnBtns: ['delete'],
                        enableExportToPDF: true,
                        subPage: "advanceTimeclock",
                        ...dropdownData,
                        enableFooterDisplay: true,
                        dropdownDependency: {
                            "deviceName": {
                                fieldToBeMapped: "deviceID",
                                data: dropdownData.employees?.map(emp => ({
                                    key: emp.name,
                                    value: emp.employeeID
                                }))
                            },
                            "deviceID": {
                                fieldToBeMapped: "deviceName",
                                data: dropdownData.employees?.map(emp => ({
                                    key: emp.employeeID,
                                    value: emp.name
                                }))
                            },
                        },
                        showExportDropdown: true,
                        aggregates: reportTableAggregates,
                        aggregateText: reportTableAggregatesText,
                        noRecordText: NO_RECORD_TEXT,
                        columnFormatMap: {
                            [totalServiceHours]: "hh:mm:ss",
                            [totalHourworkedTitle]: "hh:mm:ss"
                        },
                        setColumnList: setColumnList,
                        columnList: columnList,
                        setKendoDataState: setKendoDataState,
                        hideMenuCloumns: true
                    }}
                />
            }
            {managerNotesPopup.show &&
                <ManagerNotesPopup
                    data={managerNotesPopup.data}
                    setShowLoader={setShowLoader}
                    setToastMsg={setToastMsg}
                    api={'/track/mobile/v1/allgeo/updateAdvanceTimeclock'}
                    successMsg={"Time Clock Report Updated Successfully"}
                    closePopup={() => setManagerNotesPopup({ show: false, data: null, createData: null })}
                    reportType={'advanceTimeClock'}
                    createData={managerNotesPopup.createData}
                    dropdownData={dropdownData}
                />
            }

            {
                showAddNewRecordPopup &&
                <AddNewRecord
                    addAPI='track/mobile/v1/allgeo/createAdvanceTimeclock'
                    columns={advanceTimeClockColumns}
                    otherData={{ dropdownData }}
                    closePopup={() => setShowAddNewRecordPopup(false)}
                    setToastMsg={setToastMsg}
                    setShowLoader={setShowLoader}
                />

            }

            {showBulkUploadPopup &&
                <BulkUploadPopup closePopup={() => setShowBulkUploadPopup(false)} setShowLoader={setShowLoader} setToastMsg={setToastMsg} />
            }
        </Fragment>
    )
}

export default AdvanceTimeClock