import React, { useEffect, useState } from 'react'
import { apiProvider } from '../../../../services/api/provider';
import { connectorMap, otherConnectorsMap } from '../connectorUtils';
import SiteLoader from '../../../common/SiteLoader';
import GoogleLoginComponent from '../../../common/GoogleLoginComponent';

const QBLogin = async () => {
    let imageLink = document.createElement('link');
    imageLink.rel = 'preload';
    imageLink.href = 'https://appcenter.intuit.com/Content/IA/connect_to_quickbooks.png?cache_bust=' + Date.now();
    imageLink.as = 'image';
    document.getElementsByTagName('head')[0].appendChild(imageLink);

    let cssLink = document.createElement('link');
    cssLink.rel = 'stylesheet';
    cssLink.href = 'https://appcenter.intuit.com/Content/IA/intuit.ipp.anywhere.css?cache_bust=' + Date.now();
    document.getElementsByTagName('head')[0].appendChild(cssLink);

    let script1 = document.createElement('script')
    script1.src = "https://appcenter.intuit.com/Content/IA/intuit.ipp.anywhere.js?cache_bust=" + Date.now();
    script1.type = "text/javascript"
    script1.onload = () => {
        let script2 = document.createElement('script')
        script2.src = "https://appcenter.intuit.com/Content/IA/intuit.ipp.anywhere.js?cache_bust=" + Date.now();
        script2.type = "text/javascript"
        script2.onload = () => {
            let script3 = document.createElement('script')
            script3.innerHTML = `
                intuit.ipp.anywhere.setup({
                    grantUrl: '${process.env.REACT_APP_API_URL}/track/v2/qb/RequestV2OAuthToken.htm',
                    menuProxy: '${process.env.REACT_APP_API_URL}/track/BlueDotMenu.htm',
                    datasources: {
                        quickbooks : true,
                        payments : true
                    },
                    paymentOptions:{
                        intuitReferred : true
                    }
                });
            `
            script3.type = "text/javascript"
            document.getElementsByTagName("head")[0].appendChild(script3)
        }
        document.getElementsByTagName("head")[0].appendChild(script2)
    }
    document.getElementsByTagName("head")[0].appendChild(script1)
}


const ConnectorList = (props) => {

    useEffect(async () => {
        await QBLogin();
    }, [])

    const [connectors, setConnectors] = useState([])
    const [showLoader, setShowLoader] = useState(false)
    const [GustoSignUpLink, setGustoSignUpLink] = useState()

    const getConnectList = async () => {
        try {
            setShowLoader(true)
            const response = await apiProvider.getAll('/track/mobile/v1/allgeo/connector')
            setGustoSignUpLink(response.connectors.gusto.signUpLink)

            setConnectors(Object.keys(response.connectors).map(key => {
                const connector = response.connectors[key];

                if (connectorMap[key]) {

                    const { connectorName, connectorDescription, imageSource, signUpLink } = connectorMap[key]
                    return {
                        connectorName,
                        connectorDescription,
                        isConnected: connector.connected,
                        connectUrl: (key == "deel" || key == "whenIwork" || key == "gusto") ? connector.connectUrl : signUpLink,
                        connectedUrl: connector.connectedUrl,
                        display: connector.display,
                        acl: connector.acl ? connector.acl : "",
                        imageSource
                    }
                }

            }).filter(data => data))

        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    useEffect(() => {
        getConnectList()
    }, [])

    const openUrl = (url, isConnected, connectorName = '') => {
        if (url === null) {
            if (connectorName === "HHA") {
                alert('Please fill Agency Tax ID in the Settings screen')
            }
            else (alert(`Please contact support@abaq.us to enable the feature '${connectorName}'`))
            return
        }

        if (!isConnected) {
            let parameters = 'location=1,width=800,height=650';
            parameters += ',left=' + (window.screen.width - 800) / 2 + ',top=' + (window.screen.height - 750) / 2;
            window.open(url, '_blank', parameters)
        }
    }

    const handleConnectorClick = (isConnected, connectedUrl) => {
        if (!isConnected) return

        window.open(connectedUrl, '_blank')
    }


    return (
        <>
            <section>
                <h3>Integrations</h3>
                <div className="supportedTxt">Supercharge your workflow and connect the tool you use everyday.</div>
                <ul className="connectorList">
                    {
                        connectors.map(connector => {
                            const { connectorName, connectorDescription, imageSource, isConnected, connectUrl, connectedUrl, acl } = connector

                            return (
                                <li key={connectorName} className={`${isConnected ? 'cursorPointer' : ''}`} onClick={() => handleConnectorClick(isConnected, connectedUrl)} >
                                    <div className="connectorTop">
                                        <div className="connectorLogo"><img src={imageSource} alt={connectorName} /></div>
                                        {
                                            connectorName === "ADP" && acl == 1 && isConnected ?
                                                <button onClick={() => window.alert("You do not have permission to perform this operation. Please contact support@abaq.us for more information.")} className={`btnStyle btn2 btnConnected`}>Connected</button>
                                                : connectorName == "Quickbooks" ? isConnected ?
                                                    <div className='disconnectIntuit'>
                                                        <button onClick={() => window.open(`${process.env.REACT_APP_API_URL}/track/SendHOSData.htm`, '_blank')} className="btnStyle btn2" style={{ padding: '4px 8px' }}>Manage QuickBooks</button>
                                                        <button onClick={() => window.location.href = `${process.env.REACT_APP_API_URL}/track/V2qblogout.htm`} className="btnStyle btn2" style={{ padding: '4px 8px' }}>Disconnect QuickBooks</button>
                                                    </div> :
                                                    (
                                                        <>
                                                            <div id="ippConnectToIntuit" dangerouslySetInnerHTML={{ __html: "<ipp:connectToIntuit></ipp:connectToIntuit>" }} />
                                                        </>

                                                    )
                                                    : <button onClick={() => openUrl(connectUrl, isConnected, connectorName)} className={`btnStyle btn2 ${isConnected ? 'btnConnected' : ''}`}>{isConnected ? 'Connected' : 'Connect'}</button>
                                        }

                                    </div>
                                    <div className="connectorName">{connectorName}</div>
                                    <p>{connectorDescription}</p>
                                    {connector.connectorName === "Gusto" && <p>Dont Have Gusto Account? <a href={GustoSignUpLink} target='_blank'>SignUp</a> here</p>}
                                </li>
                            )
                        })
                    }
                </ul>
            </section>
            <section>
                <h3>Other Connectors</h3>
                <div className="supportedTxt">Supercharge your workflow and connect the tool you use everyday.</div>
                <ul className="connectorList">
                    {
                        Object.keys(otherConnectorsMap).map(key => {
                            const { connectorName, connectorDescription, imageSource, isConnected = false, signUpLink } = otherConnectorsMap[key]

                            if (connectorName === 'Google') {
                                return (
                                    <li key={connectorName} className={`${isConnected ? 'cursorPointer' : ''}`} >
                                        <div className="connectorTop">
                                            <div className="connectorLogo"><img src={imageSource} alt={connectorName} /></div>
                                            <GoogleLoginComponent />
                                        </div>
                                        <div className="connectorName">{connectorName}</div>
                                        <p>{connectorDescription}</p>
                                    </li>
                                )
                            }

                            return (
                                <li key={connectorName} className={`${isConnected ? 'cursorPointer' : ''}`} >
                                    <div className="connectorTop">
                                        <div className="connectorLogo"><img src={imageSource} alt={connectorName} /></div>
                                        <button onClick={() => openUrl(signUpLink, isConnected, connectorName)} className={`btnStyle btn2 ${isConnected ? 'btnConnected' : ''}`}>{isConnected ? 'Connected' : 'Connect'}</button>
                                    </div>
                                    <div className="connectorName">{connectorName}</div>
                                    <p>{connectorDescription}</p>
                                </li>
                            )
                        })
                    }
                </ul>
            </section>
            <div className="mainBtnWrap">
                To learn more, please contact <a href="mailto:support@abaq.us" target="_blank">support@abaq.us</a>.
            </div>
            <SiteLoader isActive={showLoader} />
        </>
    )
}

export default ConnectorList