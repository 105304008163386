import React from "react";
import { apiProvider } from "../../../services/api/provider";


const ActivateSuspendDevices = ({ type, data, closePopup, setToastMsg, setShowLoader }) => {
    const isMultipleSuspendType = typeof data.length === 'number'

    const suspendOrActivateDevices = async () => {
        try {
            setShowLoader(true)
            const deviceList = isMultipleSuspendType ? data.map(d => d.deviceId) : [data.deviceId]
            const deviceStatus = type === 'activate'
            localStorage.removeItem('formIsDirty')

            const response = await apiProvider.put(`track/react/v1/allgeo/monitor/updateDeviceStatus?status=${deviceStatus}`, deviceList)
            
            if (response.status === 200) {
                setToastMsg({ msg: response.message, type: "msgSuccess" })
                closePopup()
            } else {
                setToastMsg({ msg: response.message, type: "msgError" })
            }

        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    return (
        <div className={`popupWrap msgPopup ${type === 'suspend' ? 'suspendMsg' : 'successMsg'} active`}>
            <div className="msgPopupCont">
                <div className={`msgIcon ${type === 'suspend' ? 'msgSuspend' : 'msgActivate'}`}>Suspend</div>
                <div className="msgContent">
                    {
                        isMultipleSuspendType
                            ?
                            <div>Are you sure you want to {type} the selected records?</div>
                            :
                            <div>Are you sure you want to {type} <strong> Device: {data.devicedesc}</strong></div>
                    }
                </div>
            </div>
            <div className="popupMsgBtn">
                <a onClick={closePopup} className="okBtn">Cancel</a>
                <button className="btnStyle okBtn" onClick={suspendOrActivateDevices} >Yes</button>
            </div>
        </div>
    )
}

export default ActivateSuspendDevices;