import React, { useState, useEffect } from "react";
import { getAccessLevel } from '../../common/functions';
import EmployeeGeneral from "./EmployeeGeneral";
import EmployeePayRoll from "./EmployeePayroll";
import EmployeeDevice from "./EmployeeDevice";
import EmployeeOrganization from "./EmployeeOrganization";
import { apiProvider } from '../../../services/api/provider';
import SiteLoader from '../../common/SiteLoader';
import { ConfirmPopup } from "../../common/PopUp";
import ToastMessage from '../../common/ToastMessage';
import $ from "jquery";
import { getAccountLabel } from "../../../utils";

const EmployeeSettings = (props) => {

    const [baseModule, setBaseModule] = useState('general');
    const [formIsDirty, setFormIsDirty] = useState(false);
    const [employeeId] = useState(props.employeeId);
    const [generalTabData, setGeneralTabData] = useState({});
    const [payroleTabData, setPayRollTabData] = useState({});
    const [deviceTabData, setDeviceTabData] = useState({});
    const [orgTabData, setOrgTabData] = useState({});
    const [nomenclature, setNomenclature] = useState({})
    const [showLoader, setShowLoader] = useState(true);
    const [accessLevel, setAccessLevel] = useState({});
    const [formUpdateTrigger, setFormUpdateTrigger] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [showDialogFromCancel, setShowDialogFromCancel] = useState(false);
    const [toastMsg, setToastMsg] = useState({
        msg: "",
        type: "msgSuccess"
    });

    const employeeLabel = getAccountLabel('employee') || 'Employee';


    useEffect(() => {
        apiProvider.getSingle('/track/mobile/v1/allgeo/employee', employeeId).then((response) => {
            if (response.status === 200 && response.message.toLowerCase() === 'success') {
                setGeneralTabData(response.info.general);
                setPayRollTabData(response.info.payrole);
                setDeviceTabData(response.info.device);
                setOrgTabData(response.info.phoneGroupMembership);
                setNomenclature(response.info.nomenclature)
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setShowLoader(false);
        });
    }, [formUpdateTrigger]);

    useEffect(() => {
        getAccessLevel("company", "employees").then((response) => {
            setAccessLevel(response);
        });
    }, []);

    const tabMenuHandler = (module) => {
        if (!formIsDirty) {
            setBaseModule(module);
        } else {
            localStorage.setItem('modulePath', module)
            setShowDialog(true)
        }
    }

    const generalSettingsState = (formIsDirtyState) => {
        setFormIsDirty(formIsDirtyState);
    }

    const formCancelHandler = () => {

        if (!formIsDirty) {
            if (props.mode.hasPrev) {
                props.setMode({
                    title: `${employeeLabel} Information`,
                    type: 'view',
                    data: props.mode.data,
                    hasPrev: false
                })
            } else {
                props.setMode({
                    title: "Manage Employees",
                    type: 'main',
                    data: null,
                    hasPrev: false
                })
            }
        } else {
            setShowDialogFromCancel(true)
        }
    }

    const onSuccess = () => {
        setShowDialog(false)
        setBaseModule(localStorage.getItem('modulePath'));
        localStorage.removeItem('modulePath')
        setFormIsDirty(false);
    }

    const onFaliure = () => {
        setShowDialog(false);
        setShowDialogFromCancel(false);
    }

    const onSuccessFormCancel = () => {
        setShowDialogFromCancel(false)
        if (props.mode.hasPrev) {
            props.setMode({
                title: `${employeeLabel} Information`,
                type: 'view',
                data: props.mode.data,
                hasPrev: false
            })
        } else {
            props.setMode({
                title: "Manage Employees",
                type: 'main',
                data: null,
                hasPrev: false
            })
        }
    }

    const copyToClipBoard = (value) => {
        navigator.clipboard.writeText(value);
    }

    $(".copyIcon").on("click", function () {
        $(this).siblings('.copyClipboard').show().delay(4000).fadeOut();;
    });

    return (

        <>
            <div className="mainContent" id="mainContent">
                <ul className="rightInnerNav">
                    <li><a onClick={() => tabMenuHandler('general')} className={baseModule === 'general' ? 'active' : ''}>General</a></li>
                    <li><a onClick={() => tabMenuHandler('payroll')} className={baseModule === 'payroll' ? 'active' : ''}>Payroll</a></li>
                    <li><a onClick={() => tabMenuHandler('device')} className={baseModule === 'device' ? 'active' : ''}>Device</a></li>
                    <li><a onClick={() => tabMenuHandler('organization')} className={baseModule === 'organization' ? 'active' : ''}>Organization</a></li>
                </ul>
                <div className="innerWrap">
                    <div className="highlightedFldWrap">
                        <div className="fldWrap">
                            <label>{employeeLabel} Name</label>
                            <div className="highlightedValue">
                                <span>{generalTabData.employeeShortName}</span>
                            </div>
                        </div>
                        <div className="fldWrap">
                            <label>{employeeLabel} Number</label>
                            <div className="highlightedValue">
                                <span>{generalTabData.phoneNumber}</span>
                                <span onClick={() => { copyToClipBoard(generalTabData.phoneNumber) }} className="copyIcon">Copy</span>
                                <span className="copyClipboard">Copied to the clipboard!</span>
                            </div>
                        </div>
                        <div className="fldWrap">
                            <label>Creation Date &amp; Time</label>
                            <div className="highlightedValue">
                                <span>{generalTabData.creationDateTime}</span>
                            </div>
                        </div>
                        <div className="fldWrap">
                            <label>Last Alert Time</label>
                            <div className="highlightedValue">
                                <span>{generalTabData.lastNotifyTime}</span>
                            </div>
                        </div>
                    </div>
                </div>
                {baseModule === 'general' &&
                    <EmployeeGeneral
                        formCancel={formCancelHandler}
                        data={generalTabData}
                        employeeId={employeeId}
                        formState={generalSettingsState}
                        accessLevel={accessLevel}
                        refreshAllTabdata={() => setFormUpdateTrigger(!formUpdateTrigger)}
                        nomenclature={nomenclature}
                        setToastMsg={setToastMsg}
                    />
                }
                {baseModule === 'payroll' &&
                    <EmployeePayRoll
                        formCancel={formCancelHandler}
                        data={payroleTabData}
                        employeeId={employeeId}
                        formState={generalSettingsState}
                        accessLevel={accessLevel}
                        refreshAllTabdata={() => setFormUpdateTrigger(!formUpdateTrigger)}
                        nomenclature={nomenclature}
                        setToastMsg={setToastMsg}
                    />
                }
                {baseModule === 'device' &&
                    <EmployeeDevice
                        formCancel={formCancelHandler}
                        data={deviceTabData}
                        employeeId={employeeId} formState={generalSettingsState}
                        accessLevel={accessLevel}
                        refreshAllTabdata={() => setFormUpdateTrigger(!formUpdateTrigger)}
                        nomenclature={nomenclature}
                        setToastMsg={setToastMsg}
                    />}
                {baseModule === 'organization' &&
                    <EmployeeOrganization
                        formCancel={formCancelHandler}
                        data={orgTabData}
                        employeeId={employeeId}
                        formState={generalSettingsState}
                        accessLevel={accessLevel}
                        refreshAllTabdata={() => setFormUpdateTrigger(!formUpdateTrigger)}
                        nomenclature={nomenclature}
                        setToastMsg={setToastMsg}
                    />
                }

            </div>
            <ConfirmPopup
                showDialog={showDialog}
                message={"The changes are still unsaved. Are you sure you want to leave?"}
                confirmNavigation={onSuccess}
                cancelNavigation={onFaliure}
            />
            <ConfirmPopup
                showDialog={showDialogFromCancel}
                message={"The changes are still unsaved. Are you sure you want to leave?"}
                confirmNavigation={onSuccessFormCancel}
                cancelNavigation={onFaliure}
            />
            {
                toastMsg.msg && <ToastMessage
                    cssClass={toastMsg.type}
                    message={toastMsg.msg}
                    onClose={() => setToastMsg({ msg: "", type: "" })}
                />
            }
            <SiteLoader isActive={showLoader} />
        </>
    )
}
export default EmployeeSettings;