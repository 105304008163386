import React from 'react'
import NonRecurringSample from '../../SampleCSVFiles/non_recurring_sample_workorder_template.xlsx'
import RecurringSample from '../../SampleCSVFiles/recurring_sample_workorder_template.xlsx'
import uploadImage from '../../../../../assets/images/upload-icon-new.svg'
import { getAccountLabel } from '../../../../../utils'

export const BulkUploadWorkOrder = ({ file, setFile, handleFileUpload }) => {
    const workorderLabel = getAccountLabel('workorder')
    return (
        <div>
            <div className="download_links">
                Click here to download <a href={RecurringSample} download={`recurring_sample_${workorderLabel}_template.xlsx`}> Recurring {workorderLabel} </a> sample file.
            </div>
            <div className="download_links">
                Click here to download <a href={NonRecurringSample} download={`non_recurring_sample_${workorderLabel}_template.xlsx`}> Non - Recurring {workorderLabel} </a> sample file.
            </div>
            <div className="alignCenter">
                <div className="drag_field"
                    onDragOver={e => e.preventDefault()}
                    onDrop={e => { e.preventDefault(); handleFileUpload(e); }}
                >
                    <p><img src={uploadImage} alt="Upload" /></p>
                    <div>Drag and drop files or <span className="browse_link">Browse</span></div>
                    <input type="file" onChange={handleFileUpload} accept=".csv, .xlsx, .xls" />
                </div>
            </div>
            {file?.name &&
                <p className="upload_text">File Uploaded: <span className="file_type">{file?.name}</span>
                    <button className="image_button tooltip_del_modal" onClick={() => setFile(null)}>
                        <span>Delete Uploaded File</span>
                    </button>
                </p>
            }
        </div>
    )
}
