import React, { useState } from 'react'
import FileUpload from '../../common/FileUpload'
import sampleCSV from './editReports-Sample.xlsx'
import sampleCSV2 from './editReports-sample2.csv'
import sampleCSVWithTxnId from './editReports-SampleWithTxnID.csv'
import { apiProvider } from '../../../services/api/provider'
import FileUploadFail from '../../common/FileUploadFail'
import { getAccountLabel } from '../../../utils'
import { readFile } from '../../common/functions'

const BulkUploadPopup = ({ closePopup, setToastMsg, setShowLoader }) => {

    const isTxnIdIncluded = sessionStorage.getItem('AdvanceTimeClockReport:includeTxnIds')

    const [file, setFile] = useState(null)
    const [fileUploadErrorData, setFileUploadErrorData] = useState(null)
    const [action, setAction] = useState({
        create: true,
        update: false,
        delete: false
    })
    const [selectedSample, setSelectedSample] = useState("sample1")

    const uploadFile = async () => {
        try {

            setShowLoader(true)
        //    const wrongFile= await readFile(file);
        //    if(wrongFile){
        //     setToastMsg({
        //         msg: "The file structure does not match expected rows and columns.",
        //         type: "msgError"
        //     })
        //     return;
        //    }
            const actions = Object.keys(action).filter(actionType => action[actionType]).join(',')
            const formData = new FormData()
            formData.append('file', file)

            let response
            if (selectedSample === "sample1") {
                response = await apiProvider.formPost(`/track/mobile/v1/allgeo/uploadAdvanceTimeclock?action=${actions}`, formData)
            }
            if (selectedSample === "sample2") {
                response = await apiProvider.post(`track/mobile/v1/allgeo/uploadTimeclockCSV2`, formData)
            }
    
            if (response.status === 200) {
                setToastMsg({
                    msg: 'Timeclock Entries has been added successfully',
                    type: "msgSuccess"
                })
                closePopup()
            }
            else if (response.status === 400) {
                setToastMsg({
                    msg: response.message,
                    type: "msgError"
                })
                closePopup()
            }
            else if (response?.failed?.length === 0) {
                setToastMsg({
                    msg: 'Timeclock Entries has been added successfully',
                    type: "msgSuccess"
                })
                closePopup()
            }
            else if (response?.failed?.length > 0) {
                console.log(response.failed.map(e => e))
                setToastMsg({
                    msg: response.failed.join('\n'),
                    type: "msgError"
                })
                closePopup()
            }
            else if (+response.status === 422) {
                
                const { knownColumns, unknownColumn } = response;
                {
                    selectedSample === "sample1" ? setFileUploadErrorData({
                        file,
                        validColumns: knownColumns,
                        misMatchedColumns: unknownColumn,
                        validationError: false,
                        uploadUrl: "/track/mobile/v1/allgeo/uploadAdvanceTimeclock?action=create",
                        selectedSample
                    })
                        :
                        setFileUploadErrorData({
                            file,
                            validColumns:knownColumns,
                            misMatchedColumns: unknownColumn,
                            validationError: false,
                            uploadUrl: "/track/mobile/v1/allgeo/uploadTimeclockCSV2",
                            selectedSample
                        })
                }


            }
            else {
                setToastMsg({
                    msg: response.ErrorMsg,
                    type: "msgError"
                })
            }
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    const handleActionChange = actionType => {
        setAction({
            ...action,
            [actionType]: !action[actionType]
        })
    }

    const taskLabel = getAccountLabel('task')

    return (
        <>
            {
                !fileUploadErrorData ?
                    <div className="popupWrap smlPopup active">
                        <div className="closePopup" onClick={closePopup} >Close</div>
                        <div className="popupHeading">Bulk Upload</div>
                        <div className="popupContent">
                            <FileUpload
                                headerText={`Download time clock details`}
                                getFile={uploadedFile => setFile(uploadedFile)}
                                setIsFormComplete={() => { }}
                                csvFile={isTxnIdIncluded ? sampleCSVWithTxnId : sampleCSV}
                                csvFile2={sampleCSV2}
                                csvFileName={"EditReport"}
                                setSelectedSample={setSelectedSample}
                                selectedSample={selectedSample}
                            />
                        </div>
                        <div className="popupBtnWrap flexJCenter">
                            <button className="btnStyle okBtn" disabled={!file || Object.keys(action).filter(key => action[key]).length === 0} onClick={uploadFile} >Upload</button>
                        </div>
                    </div>
                    :
                    <FileUploadFail
                        data={fileUploadErrorData}
                        closePopup={() => setFileUploadErrorData(null)}
                        setShowLoader={setShowLoader}
                        setToastMsg={setToastMsg}
                    />
            }
            <div className='overlay active' />
        </>
    )
}

export default BulkUploadPopup