import React, { useEffect, useRef, useState } from "react";
import "./timeClockReport.css";
// import ExpandIcon from "../../../../assets/images/expand-more.svg";
// import RefreshButton from "../../../../assets/images/refresh-button.svg";
// import {
//   Grid,
//   GridColumn as Column,
// } from "@progress/kendo-react-grid";

import {
  timeClockReportsColumn,
  titleMap,
} from "../../../../components/monitor/MonitorEmployees/monitorUtils";
import { getAccessLevel } from "../../../../components/common/functions";
import SiteLoader from "../../../../components/common/SiteLoader";
import KendoGridTable from "../KendoTable/KendoGridTable";

export const TimeClockReport = ({
  timeClockData,
  setTimeClockData,
  setShowLoader,
  showLoader,
  handleGetTimeClockReport,
  setParams,
  params,
  PINNED_BOX,
  pinnedBlock
}) => {
  const childAccountID = localStorage.getItem("childAccountId");
  const moment = window.moment;

  const allAccountColumn = {
    title: "AccountID",
    field: "deviceDetails.accountID",
    editable: false,
    width: 70,
    editor: "text",
    columnMenu: false,
    show: true,
    filter: "text",
  };
  const [columns, setColumns] = useState(timeClockReportsColumn);
  const [accessLevel, setAccessLevel] = useState({});
  const [toastMsg, setToastMsg] = useState({
    msg: "",
    type: "msgSuccess",
  });
  const last1Day = moment().subtract(1, "days");

  const queryParams = {
    pageNo: 1,
    pageSize: 50,
    date_fr: last1Day.format("YYYY/MM/DD"),
    date_to: moment().format("YYYY/MM/DD"),
    isAllAccount:childAccountID === "all"
  };

  const childRef = useRef();

  useEffect(() => {
    getAccessLevel("monitor", "").then((response) => {
      // if (childAccountID === 'all') {
      //   response.childMenu = 1
      // } else {
      // }
      response.childMenu = response.parentMenu;
      setAccessLevel(response);
    });
    // handleGetTimeClockReport()
  }, []);

  useEffect(() => {
    if (toastMsg.msg) {
      if (toastMsg.type === "msgSuccess" && childRef.current) {
        childRef.current.refreshData();
      }
      setShowLoader(false);
    }
  }, [toastMsg]);

  const actionHandler = (type, data) => {
    setMode({
      ...mode,
      type,
      data,
    });
  };

  const totalHourworkedTitle = columns?.find(
    (e) => e.field == "totoalServiceHours"
  )?.title;
  const totalServiceHours = columns?.find(
    (e) => e.field == "serviceSeconds"
  )?.title;
  // console.log(PINNED_BOX,pinnedBlock, "timeClockData?.data");
  return (
    <div style={{minWidth:"100%"}}>
      {
        <KendoGridTable
          gridData={timeClockData?.data}
          dataObject={timeClockData}
          setGridData={setTimeClockData}
          columnData={columns}
          accessLevel={accessLevel}
          ref={childRef}
          module={"timeClockReports"}
          getAPiUrl={"/track/mobile/v1/allgeo/monitor/timeClockReport"}
          requestParams={queryParams}
          filterable={false}
          groupable={false}
          actionColumnHandler={actionHandler}
          otherData={{
            hideSaveCancelChanges: true,
            actionColumnBtns: [],
            showExportDropdown: true,
            hideCheckBox: true,
            exportFileName: "Timeclock Report",
            disableGlobalSearch: true,
            columnFormatMap: {
              [totalServiceHours]: "hh:mm:ss",
              [totalHourworkedTitle]: "hh:mm:ss",
            },
            dropDownData: "all:all",
            booleanField:false
          }}
          setParams={setParams}
          params={{...params,isAllAccount:childAccountID === "all"}}
          dataItemKey={"_id"}
        />
      }

      {/* <div className="employeeHeadingContainer">
        <p className="employeeheadtag">Time Clock Reports</p>
      </div>
      <div className="employeeparentBox pinnedbox">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <div className="employeeiconflexbox">
            <div
              className="employeemodalsearch"
              style={{ flexDirection: "row", gap: 12 }}
            >
              <div style={{ position: "relative" }}>
                <img
                  style={{ position: "absolute", top: 10, left: 10 }}
                  src={SearchIcon}
                  alt=""
                />
                <input type="text" name="" id="" placeholder="All Device" />
              </div>
              <img
                style={{ cursor: "pointer", width: "32px" }}
                src={RefreshButton}
                alt="Refresh Button"
              />
            </div>
          </div>
          <div className="employeeiconflexbox2">
            <img src={ExportIcon} alt="" />
          </div>
        </div>
        <div
          className="timeclockreporttableparent"
          style={{ overflow: "auto" }}
        >
          {grid}
        </div>
        <div className="employeemodalpagination">
          <LocalizationProvider language="en">
            <Pager
              skip={page.skip}
              take={page.take}
              total={sampleProducts.length}
              buttonCount={3}
              info={true}
              type="numeric"
              pageSizes={[5, 10, 20]}
              previousNext={true}
              onPageChange={handlePageChange}
            />
          </LocalizationProvider>
        </div>
      </div> */}
    </div>
  );
};
