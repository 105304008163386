import React, { useState, useEffect } from 'react'
import { apiProvider } from '../../../services/api/provider'
import { getAccountLabel } from '../../../utils'

const AssignTask = ({ data, setAssignTaskModal, setToastMsg, setShowLoader }) => {

    const [tasks, setTasks] = useState([])
    const [backUpData, setBackupData] = useState([])
    const [search, setSearch] = useState("")
    const [allTaskSelection, setAllTaskSelection] = useState(false)

    const closeModal = () => {
        setAssignTaskModal({
            show: false,
            data: null
        })
    }

    const handleSearch = (evt) => {

        const searchText = evt.target.value;

        setSearch(searchText)
        setTasks(backUpData.filter(d => {
            return d.taskName.toString().toLowerCase().includes(searchText.toLowerCase())
        }))

    }

    const getTask = async () => {
        try {
            const response = await apiProvider.getAll(`/track/mobile/v1/allgeo/getTasks?extCustIds=${data.map(d => d.txnId).toString()}`)

            const taskList = response.data.filter(task => task.taskID !== 'all')

            setTasks(taskList)
            setBackupData(taskList)
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    const handleSelection = (taskID, isAllTaskSelected = false) => {

        let selectedItemsCount = 0

        setTasks(tasks.map(t => {
            if (t.taskID === taskID) {
                return {
                    ...t,
                    selected: t.selected === "yes" ? "no" : "yes"
                }
            }

            if (t.selected === 'yes') selectedItemsCount++

            return {
                ...t,
                selected: taskID == 'all' ? isAllTaskSelected ? 'yes' : 'no' : t.selected
            }
        }))

        setBackupData(backUpData.map(t => {
            if (t.taskID === taskID) {
                return {
                    ...t,
                    selected: t.selected === "yes" ? "no" : "yes"
                }
            }

            return {
                ...t,
                selected: taskID == 'all' ? isAllTaskSelected ? 'yes' : 'no' : t.selected
            }
        }))

        if (taskID !== 'all' && allTaskSelection) {
            setAllTaskSelection(false)
        } else if (selectedItemsCount === tasks.length - 1) {
            setAllTaskSelection(true)
        }
    }

    const assignTask = async () => {
        try {
            setShowLoader(true)
            const payload = {
                extCustIds: data.map(d => d.txnId),
                taskIds: tasks.filter(t => t.selected === "yes").map(t => t.txnID)
            }

            const response = await apiProvider.put('track/mobile/v1/allgeo/assignTasks', payload)

            setToastMsg({
                type: 'msgSuccess',
                msg: response.message
            })
            if (response.status === 200) {
                closeModal()
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getNumberOfSelectedTask = () => {
        return tasks.filter(t => t.selected === "yes").length
    }

    const handleAllTaskSelection = () => {
        handleSelection('all', !allTaskSelection)
        setAllTaskSelection(!allTaskSelection)
    }

    useEffect(() => {
        getTask()
    }, [])

    const taskLabel = getAccountLabel('task');
    const jobsiteLabel = getAccountLabel('jobsite');

    return (
        <>
            <div className="popupWrap smlMedPopup active">
                <div className="closePopup" onClick={closeModal}>Close</div>
                <div className="popupHeading">
                    <span>Assign {taskLabel}</span>
                </div>
                <div className="popupContent viewPopup">
                    <div className="viewLeft">
                        <label>Selected {jobsiteLabel}</label>
                        <ul className="selectedList">
                            {
                                data.map((d, ind) => (
                                    <li key={ind}>
                                        {d.name}
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    <div className="viewRight">
                        <div className="fldWrap">
                            <label>Select {taskLabel}</label>
                            <div className="customControls">
                                <div className="customCheck">
                                    <input type="checkbox" id="AllTasks" name="SelectTask" checked={allTaskSelection} onChange={() => handleAllTaskSelection()} />
                                    <label htmlFor="AllTasks">All {taskLabel}</label>
                                </div>
                            </div>
                        </div>
                        <label>{taskLabel} Assignment</label>
                        <div className="empSelectBlk">
                            <div className="empSelectCtrl">
                                <input
                                    type="search"
                                    placeholder={`Search by ${taskLabel}`}
                                    value={search}
                                    onChange={handleSearch}
                                />
                            </div>
                            <div className="empListTbl">
                                <table cellPadding="0" cellSpacing="0">
                                    <tbody>
                                        <tr>
                                            <th>{taskLabel} Name</th>
                                            <th>{taskLabel} ID</th>
                                            <td></td>
                                        </tr>
                                        {
                                            tasks.map((t, ind) => (
                                                <tr key={ind}>
                                                    <td>{t.taskName}</td>
                                                    <td>{t.taskID}</td>
                                                    <td className="empActionCol">
                                                        <div className="customCheck noTxt">
                                                            <input type="checkbox" id={`${t.taskID || t.txnID}`} checked={t.selected === "yes"} onChange={() => handleSelection(t.taskID)} />
                                                            <label className="customCheckLabel" htmlFor={`${t.taskID || t.txnID}`}>{t.taskName}</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        }

                                    </tbody></table>
                            </div>
                            <div className="empSelectFoot">
                                <span>There are a total of <strong>{getNumberOfSelectedTask()} {taskLabel}</strong> selected</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="popupBtnWrap">
                    <a className={`btnStyle doneStep`} onClick={assignTask}>Assign</a>
                </div>
            </div>
        </>
    )
}

export default AssignTask