import React, { useState, useEffect, useRef } from 'react'
import Header from '../../layout/Header'
import { apiProvider } from '../../../services/api/provider';
import SiteLoader from '../../common/SiteLoader';
import AddNewPopup from './AddNewPopup';
import ViewGroup from './ViewGroup';
import EditGroup from './EditGroup';
import ToastMessage from '../../common/ToastMessage';
import { getAccessLevel } from '../../common/functions'
import { useDocumentTitle } from '../../../hooks/setDocumentTitle'
import KendoGrid from '../../common/kendo/KendoGrid';
import { groupColumns } from './groupUtils';
import { getAccountLabel } from '../../../utils';
import  { G2ReviewPopup } from '../../common/G2ReviewPopup/G2ReviewPopup';
import { ALLOWED_ACCOUNTS_FOR_G2_REVIEW} from '../../common/G2ReviewPopup/G2Utils/constants';

const Groups = () => {
    const groupLabel = getAccountLabel('group') || 'Groups';

    useDocumentTitle(`${groupLabel} - allGeo`)

    const [showLoader, setShowLoader] = useState(false);
    const [groups, setGroups] = useState(null)
    const [showAddNewPopup, setShowAddNewPopup] = useState(false)
    const [mode, setMode] = useState({
        type: "",
        data: null,
        hasPrev: false
    })
    const [toastMsg, setToastMsg] = useState("")
    const [accessLevel, setAccessLevel] = useState({});

    useEffect(() => {
        getAccessLevel("company", "groups").then((response) => {
            setAccessLevel(response);
        });
    }, []);

    useEffect(() => {
        fetchGroupsData()
    }, [])

    const fetchGroupsData = async () => {
        try {
            setShowLoader(true)
            const response = await apiProvider.getAll('/track/mobile/v1/allgeo/getGroups')
            setGroups(response.groups)
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    const deleteGroup = async data => {

        const payload = data.length ? data : [data.groupID]

        try {
            setShowLoader(true)
            const response = await apiProvider.removeObject('/track/mobile/v1/allgeo/deleteGroup', payload)

            if (response.status === 200) {
                if (data.length)
                    response.message = "The selected records have been deleted successfully";
                else
                    response.message = `${data.groupDesc || data.groupID} is deleted`

                setMode({
                    type: "",
                    data: null,
                    hasPrev: false
                })
                if (mode.type === 'delete') setToastMsg(response.message)
            }

            return response

        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    const childRef = useRef(null)

    useEffect(() => {
        if (toastMsg) {
            if (childRef.current) {
                childRef.current.refreshData()
            }
            setShowLoader(false)
        }
    }, [toastMsg])

    const DeletePopup = () => (
        <div className="popupWrap msgPopup deleteMsg active">
            <div className="msgPopupCont">
                <div className="msgIcon msgDelete">Delete</div>
                <div className="msgContent">
                    <div>Are you sure you want to delete <strong>{mode?.data?.groupDesc ? mode?.data?.groupDesc : mode?.data?.groupID}?</strong></div>
                </div>
            </div>
            <div className="popupMsgBtn">
                <a className='okBtn cursorPointer' onClick={() => setMode({
                    type: mode.hasPrev ? "view" : "",
                    data: mode.hasPrev ? mode.data : null,
                    hasPrev: false
                })}>Cancel</a>
                <button disabled={showLoader} className="btnStyle okBtn" onClick={() => deleteGroup(mode?.data)}>Yes</button>
            </div>
        </div>
    )

    const actionHandler = (type, data) => {

        switch (type) {
            case "view":
                setShowLoader(true)
                setMode({
                    type: 'view',
                    data: data,
                    hasPrev: false
                })
                break;
            case "edit":
                setMode({
                    type: 'edit',
                    data: data,
                    hasPrev: false
                })
                break;
            default:
                break;
        }
    }

    const [showG2Popup,setShowG2Popup] = useState(false);

    const sysAdminG2Enbled = JSON.parse(localStorage.getItem('sysAdminG2Enabled')); 
    const storedItem = localStorage.getItem('hasG2Reviewed');
    const hasG2Reviewed = (storedItem !== 'undefined' && storedItem !== null) ? JSON.parse(storedItem) : null;
    let isAdminLogin = JSON.parse(localStorage.getItem('isAdminLogin'));
    const doNotShowG2 = JSON.parse(localStorage.getItem('g2ReviewInactive'));
    const showG2Review = !isAdminLogin && !hasG2Reviewed && !doNotShowG2 && showG2Popup && sysAdminG2Enbled;


    return (
        <>
            <div className="mainContentWrap">
                <Header title={groupLabel} />
                {showG2Review && <G2ReviewPopup showPopup={showG2Popup} setShowPopup={setShowG2Popup}/>}
                {
                    mode.type === 'edit'
                        ?
                        <EditGroup groupData={mode.data} mode={mode} setMode={setMode} setToastMsg={setToastMsg} setShowLoader={setShowLoader} />
                        :
                        <div className="mainContent" id="mainContent">
                            {groups &&
                                <KendoGrid
                                    gridData={groups}
                                    setGridData={setGroups}
                                    columnData={groupColumns}
                                    accessLevel={accessLevel}
                                    ref={childRef}
                                    module={"groups"}
                                    getAPiUrl={"/track/mobile/v1/allgeo/getGroups"}
                                    openPopup={() => setShowAddNewPopup(true)}
                                    deleteItem={deleteGroup}
                                    bulkDelete={deleteGroup}
                                    lockActionColumn={true}
                                    actionColumnHandler={actionHandler}
                                    otherData={{
                                        hideSaveCancelChanges: true,
                                        exportFileName: groupLabel
                                    }}
                                />
                            }
                            {showAddNewPopup && <AddNewPopup setShowG2Popup={setShowG2Popup} closePopup={() => setShowAddNewPopup(false)} setToastMsg={setToastMsg} setShowLoader={setShowLoader} />}
                            {mode.type === 'view' && <ViewGroup groupData={mode.data} setMode={setMode} accessLevel={accessLevel.childMenu} setShowLoader={setShowLoader} />}
                        </div>
                }
            </div>
            {mode.type === 'delete' && <DeletePopup />}
            <div className={`overlay${(showAddNewPopup || (mode.type.length > 0 && mode.type !== 'edit')) ? ' active' : ''}`} />
            {toastMsg && <ToastMessage
                cssClass={"msgSuccess"}
                message={toastMsg}
                onClose={() => setToastMsg("")}
            />}
            <SiteLoader isActive={showLoader} />
        </>
    )
}

export default Groups