import React, { useEffect } from "react";
import "./currentLocationModal.css";
import LocationIcon from "../../../../assets/images/locationicon.svg";
import { apiProvider } from "../../../../services/api/provider";
import loader from '../../../../assets/images/loader.png'

export const CurrentLocationModal = ({ selectedLocation, closePopup,fetchEmployeeAppData,setToastMsg }) => {

console.log(selectedLocation,"selectedLocationselectedLocation")

  const locateEmployee = async () => {
    try {
        const payload = [selectedLocation.dataItem.employeeID]

        const response = await apiProvider.post('track/react/v1/allgeo/monitor/LocateEmployees', payload)

        if (+response.status === 200) {
            setTimeout(() => {
                setToastMsg({ msg: response.message, type: "msgSuccess", refresh: false })
                fetchEmployeeAppData()
                closePopup()
            }, 30000)
        } else {
            setToastMsg({ msg: response.message, type: "msgError" })
            closePopup()
        }

    } catch (error) {
        console.log(error);
    }
}

useEffect(() => {
    locateEmployee()
    
}, [])

  return (
    <div>
      <div className="overlay2"></div>
      <div className="locationparentBox">
        <div>
          <h2 className="locationheadingtag">Current Location</h2>
        </div>
        <div className="popupContent">
                <div className="popupTabCont">
                    <div className="loaderSection">
                        <span className="loadAnimation">
                            <img src={loader} alt="loading" />
                        </span>
                    </div>
                    <div className="alignCenter">
                        We are retrieving the location. Please wait for 30 seconds.
                    </div>
                </div>
            </div>
        <div className="closebtn">
          <button onClick={closePopup} className="locationbuttondiv">
            Close
          </button>
        </div>
      </div>
    </div>
  );
};
