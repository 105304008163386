import React, { useEffect, useState } from 'react';
import { WorkOrderEdit } from '../EditWorkOrder';
import { WorkOrderDelete } from '../WorkOrderPopup';
import { ViewWorkOrderData } from './ViewModalData';
import { getAccountLabel } from '../../../../utils';

export const WorkOrderView = ({ WOdata, mode, setShowLoader, setToastMsg }) => {
    const [show, setShow] = useState(!!WOdata.TaskID);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    useEffect(() => {
        setShow(!!WOdata.TaskID);
    }, [WOdata]);

    const openEdit = () => {
        setEditModalOpen(true);
    };

    const closeEdit = () => {
        setEditModalOpen(false);
        setShow(false);
    };

    const workorderLabel = getAccountLabel('workorder');

    return (
        <>
            {show && (
                <>
                    <div className="md_modal_wrap med_popup active">
                        <div className="modal_head_sec">
                            <span>View {workorderLabel}</span>
                            <div className="heading_btn_wrap">
                                <button className="edit_wrk tooltip_mdl" onClick={openEdit}>
                                    <span>Edit {workorderLabel}</span>
                                </button>
                                {(mode.type === 'edit' || editModalOpen) && (
                                    <WorkOrderEdit
                                        WOdata={WOdata}
                                        closeEdit={closeEdit}
                                        setToastMsg={setToastMsg}
                                    />
                                )}
                                <button
                                    className="delete_wrk tooltip_mdl"
                                    onClick={() => setDeleteModalOpen(true)}
                                >
                                    <span>Delete {workorderLabel}</span>
                                </button>
                                {(mode.type === 'delete' || deleteModalOpen) && (
                                    <WorkOrderDelete
                                        WOdata={WOdata}
                                        closeEdit={closeEdit}
                                        setDeleteModalOpen={setDeleteModalOpen}
                                        setShow={setShow}
                                        setShowLoader={setShowLoader}
                                        setToastMsg={setToastMsg}
                                    />
                                )}
                                <button className="close_wrk" onClick={() => setShow(false)}></button>
                            </div>
                        </div>
                        <ViewWorkOrderData WOdata={WOdata} />
                        <div className="modal_btn_sec">
                            <button className="mdl_btn btn2" onClick={() => setShow(false)}>
                                Cancel
                            </button>
                        </div>
                    </div>
                    <div className="overlay active"></div>
                </>
            )}
        </>
    );
};
