import { useState, Fragment, useRef, useEffect } from "react";
import KendoGrid from '../../../common/kendo/KendoGrid';
import { milesColumns, reportTableAggregates, reportTableAggregatesText, NO_RECORD_TEXT, customDateFormat } from "../editReportUtils";
import { apiProvider } from "../../../../services/api/provider";
import { ManagerNotesPopup } from "../ManagerNotesPopup";
import { formatDate } from "@progress/kendo-intl";

const params = {
    date_fr: formatDate(new Date(), 'yyyy/MM/dd'),
    date_to: formatDate(new Date(), 'yyyy/MM/dd'),
    reportFor: "all",
    tzStr: "",
    timeclockSource: "manual",
    reportSource: "editReports"
}

const EditMile = ({ columnName, dropdownData, setShowLoader, toastMsg, setToastMsg, accessLevel, setColumnList, columnList, setKendoDataState }) => {
    const globalStartDate = sessionStorage.getItem('EditReport:startDate')
    const globalEndDate = sessionStorage.getItem('EditReport:endDate')
    const reportForValues = sessionStorage.getItem('EditReport:reportFor')
    const userName = localStorage.getItem('userName')

    if (globalStartDate && globalEndDate) {
        params.date_fr = globalStartDate
        params.date_to = globalEndDate
        params.reportFor = reportForValues || ''
    }

    const [milesData, setMilesData] = useState(null);
    const [managerNotesPopup, setManagerNotesPopup] = useState({
        show: false,
        data: null
    })

    const childRef = useRef(null);

    const fetchData = async () => {
        try {
            setShowLoader(true)
            const response = await apiProvider.getAll('track/mobile/v1/allgeo/loadDistanceData', params)
            setMilesData(response)

        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    const updateDistance = async data => {
        const payload = [...data].map(fields => ({
            accountID: fields.accountID,
            deviceID: fields.deviceID,
            tempcol: customDateFormat(fields.tcSentDate, "yyyy/MM/dd", dropdownData.dateFormat),
            distanceTravelled: fields.distanceTravelled,
            googleDistance: +fields.googleDistance,
            txnID: fields.txnID,
            userId: userName,
            jobName: fields.jobName,
            customerName: fields.customerName,
            managerNotes: fields.managerNotes
        }))

        if (data.length > 0) {
            setManagerNotesPopup({
                show: true,
                data: payload
            })
            return {}
        }

        try {
            const response = await apiProvider.post('/track/api/v1/updateDistance', payload)
            return {
                status: response.StatusCode,
                message: "Edit Miles Updated Successfully"
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchData()
        // replacing static column data from api data that we getting from props
        // milesColumns.forEach(item => {
        //     if(Object.keys(columnName).includes(item.field)){
        //         item.title = columnName[item.field] || item.title
        //     }
        // })

        const updatedEditTimeClockColumns = milesColumns.map(item => {
            if (Object.keys(columnName).includes(item.field)) {
                return {
                    ...item,
                    title: columnName[item.field] || item.title
                };
            }
            return item;
        });

        setColumnList(updatedEditTimeClockColumns);
    }, [])

    useEffect(() => {
        if (toastMsg.msg && toastMsg.type === "msgSuccess") {
            if (childRef.current) {
                childRef.current.refreshData()
            }
            setShowLoader(false)
        }
    }, [toastMsg])

    return (
        <Fragment>

            {milesData &&
                <KendoGrid
                    gridData={milesData}
                    setGridData={setMilesData}
                    columnData={columnList}
                    accessLevel={accessLevel}
                    ref={childRef}
                    module={"editReports"}
                    getAPiUrl={'track/mobile/v1/allgeo/loadDistanceData'}
                    requestParams={params}
                    lockActionColumn={true}
                    updateItem={updateDistance}
                    otherData={{
                        actionColumnBtns: [],
                        enableExportToPDF: true,
                        subPage: "editMile",
                        ...dropdownData,
                        enableFooterDisplay: true,
                        aggregates: reportTableAggregates,
                        aggregateText: reportTableAggregatesText,
                        noRecordText: NO_RECORD_TEXT,
                        showExportDropdown: true,
                        setColumnList: setColumnList,
                        columnList: columnList,
                        setKendoDataState: setKendoDataState,
                        hideMenuCloumns: true
                    }}
                />
            }

            {managerNotesPopup.show &&
                <ManagerNotesPopup
                    data={managerNotesPopup.data}
                    report={'mileage'}
                    setShowLoader={setShowLoader}
                    setToastMsg={setToastMsg}
                    api={'/track/api/v1/updateDistance'}
                    successMsg={"Edit Miles Report Updated Successfully"}
                    closePopup={() => setManagerNotesPopup({ show: false, data: null })}
                />
            }
        </Fragment>
    )
}

export default EditMile