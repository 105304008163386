export const getAccountLabel = (field) => {
    const accountLabelsJSON = localStorage.getItem('accountLabel');
    const accountLabels = accountLabelsJSON ? JSON.parse(accountLabelsJSON) : null;

    if(field == 'employee') {
        return accountLabels?.device_title || 'Employee';
    } else if (field == 'group') {
        return accountLabels?.device_group_title || 'Group';
    } else if (field == 'jobsite') {
        return accountLabels?.job_title || 'Jobsite';
    } else if (field == 'workorder') {
        return accountLabels?.workOrder_title || 'Work Order';
    } else if (field == 'task') {
        return accountLabels?.task_title || 'Task';
    }
}