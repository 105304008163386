import { apiProvider } from "../../../../services/api/provider";

export const sendOTPData = async (
    enteredOtp,
    history,
    userID,
    setis2FAChecked,
    setOtp,
    setErrorMessage,
    setIsVerified
  ) => {
    const OTPPayload = {
      accountID: userID?.accountID,
      userID: userID?.userID,
      otp: enteredOtp,
    };
    let response = await apiProvider.post(
      "/track/react/v1/2fa/submitOtp",
      OTPPayload
    );
  
    if (response.status == 200) {
      setis2FAChecked(true);
      setIsVerified(true);
      const landingPage = localStorage.getItem("landingPage");
      if (landingPage) {
        if (landingPage == "menu.rpt.devDetail") {
          history.replace("reports");
        } else if (landingPage == "dev.info") {
          history.replace("company/employees");
        } else if (landingPage == "homePage") {
          history.replace("dashboard"); 
        } else if (landingPage == "phonelocate"&&JSON.parse(localStorage.getItem('isNewMonitorPage'))) {
          history.replace("/monitor/new-monitor-employees");
        } else if (landingPage == "phonelocate"&&!JSON.parse(localStorage.getItem('isNewMonitorPage'))) {
          history.replace("/monitor/monitor-employees");
        } else if (landingPage == "home") {
          history.replace("/home");
        } 
        else if (landingPage == 'mastertrip.info') {
          history.replace("/schedule/work-order")
        }
      } else {
        JSON.parse(localStorage.getItem('isNewMonitorPage'))?history.replace("/monitor/new-monitor-employees"): history.replace("/monitor/monitor-employees");
      }
      // history.replace("after/login");
    } else {
      setOtp(["", "", "", "", "", ""]);
      document.querySelectorAll(".input").forEach((input) => {
        input.classList.add("invalid");
      });
      setErrorMessage("Incorrect Code. Please enter the correct OTP.");
    }
  };