    import React from "react";
import { apiProvider } from '../../../services/api/provider';

const ActionPopup = ({ actionPopup, setActionPopup, setShowLoader, setToastMsg,fromNewMonitor=false,fetchEmployeeAppData }) => (
    <>
    
    {fromNewMonitor&&( <div className={`overlayemployees ${actionPopup?'active':''}`}></div>)}

    <div className="popupWrap msgPopup active">
        <div className="msgPopupCont">
            <div className="msgIcon">Info</div>
            <div className="msgContent">
                <div>{actionPopup.msg}</div>
            </div>
        </div>
        <div className="popupMsgBtn">
            <a className="okBtn" onClick={() => setActionPopup({ show: false, data: null, msg: "" })}>Cancel</a>
            <button className="btnStyle okBtn" onClick={async () => {
                try {
                    setShowLoader(true)
                    const response = await apiProvider.post("track/mobile/v1/allgeo/addEmployee?addBack=yes", actionPopup.data)
                    if (response.status === 200) {
                        setToastMsg({
                            msg: response.message,
                            type: "msgSuccess"
                        })
                        
                        fromNewMonitor&&fetchEmployeeAppData()
                        setActionPopup({ show: false, data: null, msg: "" })
                    }
                } catch (error) {
                    console.log(error);
                } finally {
                    setShowLoader(false)
                }
            }}>Yes</button>
        </div>
    </div>
    </>
)

export default ActionPopup