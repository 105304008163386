import { Fragment, useState, useEffect, useMemo } from "react";
import { apiProvider } from '../../../services/api/provider';
import { getAccountLabel } from "../../../utils";

const ViewTask = (props) => {

    const [taskDetails, setTaskDetails] = useState({});
    const payerServiceInfo = useMemo(() => {
        if (taskDetails.payerServiceInfoId) {
            const { payerData } = props;

            const payerServiceInfoData = payerData.find(payer => payer.payerServiceInfoId === taskDetails.payerServiceInfoId);

            if (!payerServiceInfoData) return "-"

            return payerServiceInfoData.description
        }

        return "-"
    }, [taskDetails])

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        props.setShowLoader(true);

        apiProvider.getSingle('track/mobile/v1/allgeo/task', props.taskId).then((response) => {
            if (response.status === 200 && response.message.toLowerCase() === 'success') {
                if (response.tasks.length > 0) {
                    setTaskDetails(response.tasks[0])
                }
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            props.setShowLoader(false);
        });

    };

    const taskLabel = getAccountLabel('task');

    return (
        taskDetails && <Fragment>
            <div className="popupWrap smlPopup active">
                <div className="popupHeading noClose">
                    <span>View {taskLabel}</span>
                    <div className="headingCtrl">
                        <a href={void (0)} className={`editGrp${props.accessLevel < 2 ? ' disabled' : ''}`}
                            onClick={() => {
                                props.setShowLoader(true)
                                props.setMode({
                                    title: "Manage Tasks",
                                    type: 'edit',
                                    data: props.mode.data,
                                    hasPrev: true
                                })
                            }}>Edit {taskLabel}</a>
                        <a href={void (0)} className={`deleteGrp${props.accessLevel < 3 ? ' disabled' : ''}`}
                            onClick={() => {
                                props.setMode({
                                    title: "Manage Tasks",
                                    type: "delete",
                                    data: props.mode.data,
                                    hasPrev: true
                                })
                            }}>Delete {taskLabel}</a>
                    </div>
                </div>
                <div className="popupContent">
                    <div className="popupTabCont">
                        <div className="rowBlk">
                            <div className="colBlk6 fldWrap">
                                <label>{taskLabel} ID</label>
                                <p>{taskDetails?.taskID !== "" ? taskDetails?.taskID : "-"}</p>
                            </div>
                            <div className="colBlk6 fldWrap">
                                <label>{taskLabel} Name</label>
                                <p>{taskDetails?.name !== "" ? taskDetails?.name : "-"}</p>
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className="colBlk6 fldWrap">
                                <label>Reference ID</label>
                                <p>{taskDetails?.refID !== "" ? taskDetails?.refID : "-"}</p>
                            </div>
                            <div className="colBlk6 fldWrap">
                                <label>Source ID</label>
                                <p>{taskDetails?.srcID !== "" ? taskDetails?.srcID : "-"}</p>
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className="colBlk6 fldWrap">
                                <label>Company ID</label>
                                <p>{taskDetails?.companyID !== "" ? taskDetails?.companyID : "-"}</p>
                            </div>
                            <div className="colBlk6 fldWrap">
                                <label>Ext System</label>
                                <p>{taskDetails?.extSystem !== "" ? taskDetails?.extSystem : "-"}</p>
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className="colBlk6 fldWrap">
                                <label>Earning Code</label>
                                <p>{taskDetails?.earningCode !== "" ? taskDetails?.earningCode : "-"}</p>
                            </div>
                            <div className="colBlk6 fldWrap">
                                <label>Billing Rate</label>
                                <p>{taskDetails?.billingRate !== "" ? taskDetails?.billingRate : "-"}</p>
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className="colBlk6 fldWrap">
                                <label>Payer/Insurer Information</label>
                                <p>{payerServiceInfo}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="popupBtnWrap flexJCenter">
                    <button className="btnStyle okBtn" onClick={() => {
                        props.setMode({
                            title: "Manage Tasks",
                            type: "main",
                            data: null,
                            hasPrev: false
                        })
                    }}>Done</button>
                </div>
            </div>
        </Fragment>
    )
}

export default ViewTask