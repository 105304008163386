import React, { useEffect, useState, useRef } from 'react';
import { apiProvider } from '../../../../services/api/provider';
import { standardTimeClock } from "../buildReportsutils";
import { formatDate } from '@progress/kendo-intl';
import KendoGrid from '../../../common/kendo/KendoGrid';
import SiteLoader from "../../../common/SiteLoader";
import { reportTableAggregates, reportTableAggregatesText } from "../buildReportsutils"

const StandardTimeClock = ({ genSetting }) => {
    const [columns, setColumns] = useState(standardTimeClock);
    const [timeClockData, setTimeClockData] = useState();
    const [showLoader, setShowLoader] = useState(false);
    const [params, setParams] = useState(
        {
            date_fr: formatDate(new Date(), 'yyyy/MM/dd'),
            date_to: formatDate(new Date(), 'yyyy/MM/dd'),
            reportFor: "",
            offsetStr: "",
            tzStr: "",
            limit: "",
            device_tz_selected: "",
            stageid: "none,none",
        }
    )

    const childRef = useRef()

    const getReport = async () => {
        try {
            setShowLoader(true);
            setParams({ ...params, device_tz_selected: genSetting?.report_timezone });
            const response = await apiProvider.getAll('/track/mobile/v1/allgeo/timeclockstandardreport', params);
            setTimeClockData(response);
        } catch (error) {
            console.error("API Error:", error);
        } finally {
            setShowLoader(false);
        }
    };

    useEffect(() => {
        getReport();
    }, [genSetting]);

    const totalServiceSeconds = standardTimeClock?.find(e => e.field == "totalServiceSeconds").title
    const serviceSeconds = standardTimeClock?.find(e => e.field == "serviceSeconds").title

    return (
        <>
            {timeClockData &&
                <KendoGrid
                    gridData={timeClockData}
                    setGridData={setTimeClockData}
                    columnData={columns}
                    module={"buildReports"}
                    subModule='standardTime'
                    getAPiUrl={'/track/mobile/v1/allgeo/timeclockstandardreport'}
                    requestParams={params}
                    supportParams={params.stageid}
                    accessLevel={{ childMenu: 1 }}
                    ref={childRef}
                    otherData={{
                        actionColumnBtns: [],
                        showExportDropdown: true,
                        enableFooterDisplay: true,
                        aggregates: reportTableAggregates,
                        aggregateText: reportTableAggregatesText,
                        minutesFormat: genSetting?.minutes,
                        dateFormat: genSetting?.date_display_format,
                        columnFormatMap: {
                            [totalServiceSeconds]: "hh:mm:ss",
                            [serviceSeconds]: "hh:mm:ss"
                        }
                    }}
                />
            }
            <SiteLoader isActive={showLoader} />
        </>
    )
}

export default StandardTimeClock