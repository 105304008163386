import React, { Fragment, useEffect, useState } from 'react'
import { apiProvider } from '../../../services/api/provider'


const AddNewRule = ({ closePopup, setToastMsg, setShowLoader }) => {


    const [isFormComplete, setIsFormComplete] = useState(false);
    const [ruleValue, setRuleValue] = useState("");

    useEffect(() => {
        if (ruleValue.length > 0) {
            setIsFormComplete(true);
        } else {
            setIsFormComplete(false);
        }
    }, [ruleValue]);

    const createRule = async () => {

        try {
            setShowLoader(true);
            let data = {
                "ruleID": ruleValue
            }
            const response = await apiProvider.post("/track/mobile/v1/allgeo/rule", data)

            if (response.status === 200) {
                setToastMsg({
                    msg: `${ruleValue} has been added successfully`,
                    type: "msgSuccess"
                })
                closePopup()
            } else {
                setToastMsg({
                    msg: response.message,
                    type: "msgError"
                })
            }
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }

    }


    return (
        <Fragment>
            <div className="popupWrap smlPopup active">
                <div className="closePopup" onClick={closePopup}>Close</div>
                <div className="popupHeading">Add New Rule</div>
                <div className="popupContent">
                    <div className="rowBlk">
                        <div className="colBlk12 fldWrap">
                            <label>Rule Name</label>
                            <input type="text" name="ruleName" maxLength={32} minLength={1}
                                onChange={(e) => setRuleValue(e.target.value.replace(/[^0-9a-zA-Z:_-]/g, ''))}
                                value={ruleValue}
                                autoFocus
                            />
                        </div>
                    </div>
                </div>
                <div className="popupBtnWrap flexJCenter">
                    <button className="btnStyle okBtn" onClick={createRule} disabled={!isFormComplete}>Add</button>
                </div>
            </div>
            <div className="overlay active"></div>
        </Fragment>
    )
}

export default AddNewRule