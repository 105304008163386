import React, { useState, useEffect, useContext } from "react";
import "./TwoFactorLogin.css";
import AllGeoLogo from "../../../assets/images/allgeo.svg";
import { apiProvider } from "../../../services/api/provider";
import { Link, useHistory } from "react-router-dom";
import AuthContext from "../../../store/auth-context";
import { sendOTPData } from "./sendOTPData";
import { maskPhoneNumber } from "./maskPhoneNumber/maskPhoneNumber";

export const TwoFactorLogin = ({
  contactPhoneNumber,
  setis2FAChecked,
  userID,
}) => {
  let maskedNumber = contactPhoneNumber && maskPhoneNumber(contactPhoneNumber);
  const phoneNumber = maskedNumber || "your number.";
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [resetTime, setResetTime] = useState(180);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isResendVisible, setIsResendVisible] = useState(true);
  const [isVerified, setIsVerified] = useState(false);

   const handleSendOTP = async () => {
    setIsResendDisabled(false);
    setIsResendVisible(false);
    setResetTime(180);
    const sendOTPPayload = userID;

    let response = await apiProvider.post(
      "/track/react/v1/2fa/sendOtp",
      sendOTPPayload
    );
    console.log(response, "Send OTP Response");
  };


  const history = useHistory();
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!isVerified) {
        event.returnValue = "Changes are not saved and you will need to login again.";
        history.replace("/logout");
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isVerified]);
  useEffect(() => {
    handleSendOTP();
    return () => {};
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setResetTime((prevTime) => {
        if (prevTime > 0) {
          return prevTime - 1;
        } else {
          setIsResendDisabled(true);
          setIsResendVisible(true);
          clearInterval(timer);
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [isResendVisible]); 

  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    setErrorMessage("");
    if (value === "") {
      if (index > 0) {
        document.getElementById(`otp-input-${index - 1}`).focus();
      }

      newOtp[index] = "";
    } else if (!isNaN(value)) {
      newOtp[index] = value;

      if (index < otp.length - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }

    document.querySelectorAll(".input").forEach((input) => {
      input.classList.remove("invalid");
    });

    setOtp(newOtp);
  };

  const handleOTPSubmission = () => {
    const enteredOtp = otp.join("");
    console.log("Entered OTP:", enteredOtp);
    sendOTPData(
      enteredOtp,
      history,
      userID,
      setis2FAChecked,
      setOtp,
      setErrorMessage,
      setIsVerified,
    );
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const isOtpFilled = otp.every((digit) => digit !== "");

  return (
    <>
      <div className="two-factor-wrapper">
        <div className="two-factor-logo">
          <img src={AllGeoLogo} alt="allGeo" />
        </div>
        <h2>Enter Verification Code</h2>
        <p>{`Please Enter the code sent to ${phoneNumber}`}</p>
        <p>Verification Code</p>
        <div className="otp-inputfield">
          {otp.map((digit, index) => (
            <input
              key={index}
              type="tel"
              maxLength="1"
              className="input"
              id={`otp-input-${index}`}
              value={digit}
              onChange={(e) => handleOtpChange(index, e.target.value)}
            />
          ))}
        </div>
        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
        {isResendVisible && (
          <span
            className="reset-link"
            onClick={() => {handleSendOTP()}}
            role="button"
            tabIndex={0}
            style={{ marginRight: "8px", cursor: isResendVisible ? "pointer" : "default", }}
          >
            Resend Code
          </span>
        )}
        {!isResendDisabled && !isResendVisible && (
          <span>
            <span style={{ color: "#25A3D6" }}>Resend Code</span> in{" "}
            {formatTime(resetTime)} second
          </span>
        )}

        <button
          onClick={handleOTPSubmission}
          disabled={!isOtpFilled}
          style={{
            backgroundColor: isOtpFilled ? "#25A3D6" : "#ADD8E6",
            cursor: isOtpFilled ? "pointer" : "not-allowed",
          }}
        >
          Verify and Proceed
        </button>
      </div>
    </>
  );
};
