import { useEffect, useState } from "react";
import { useForm } from 'react-hook-form'
import { dialCodes, formatCountryCode } from './managersUtils';
import { apiProvider } from '../../../services/api/provider';
import AutoCompleteDropdown from "../../common/dropdown/AutoCompleteDropdown";

const AddNewPopup = (props) => {

    const { register, handleSubmit, watch, setValue, formState } = useForm({ mode: "all" });
    const [isFormComplete, setIsFormComplete] = useState(false);

    useEffect(() => {
        let fields;

        const subscribe = watch(data => {

            if (props.mobileManage || data.trackManager) {
                fields = ["managerId", "userEmail", "dialCode", "phoneNumber"]
            } else {
                fields = ["managerId", "userEmail"]
            }

            const isEmpty = fields.some(d => !data[d])
            setIsFormComplete(!isEmpty)
        })

        return () => subscribe.unsubscribe()
    }, [watch])

    const onSubmit = async data => {
        try {
            props.setShowLoader(true);

            let payload = {
                "contactName": data.managerId,
                "contactEmail": data.userEmail,
                "contactPhone": (props.mobileManage || data.trackManager) ? (formatCountryCode(data.dialCode).concat(data.phoneNumber)) : "",
                "trackManager": data.trackManager,
                "mobileManage": props.mobileManage
            }

            const response = await apiProvider.post("track/mobile/v1/allgeo/manager", payload)
            if (response.status === 200) {
                props.setToastMsg({ msg: `The manager has been added successfully .The password link is sent to ${data.userEmail}.`, type: "msgSuccess" });
                props.closePopup()
            } else {
                props.setShowLoader(false)
                props.setToastMsg({ msg: response.message, type: "msgError" })
            }

        } catch (error) {
            console.log(error);
        } finally {
            props.setShowLoader(false)
        }
    }

    return (
        <div className="popupWrap smlPopup withAutoComplete active">
            <div className="closePopup" onClick={props.closePopup}>Close</div>
            <div className="popupHeading">Create a New Manager</div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="popupContent">
                    <div className="fldWrap">
                        <label>Manager ID</label>
                        <input maxLength={32} type="text" {...register("managerId", {
                            onChange: (e) => setValue("managerId", (e.target.value).replace(/[^a-zA-Z0-9]/gi, ''), { shouldDirty: false })
                        })} />
                    </div>
                    <div className={`fldWrap ${formState.errors.userEmail ? 'errFld' : ''}`}>
                        <label>User Email</label>
                        <input maxLength={64} type="text" {...register("userEmail", { pattern: /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/ })} />
                        {formState.errors.userEmail && <div className="fldMsg">Please enter a valid email</div>}
                    </div>
                    <div className={`fldWrap`}>
                        <label>Phone Number</label>
                        <div className="phoneNumberWrap">
                            <AutoCompleteDropdown
                                options={dialCodes.map(d => ({ label: d.dial_code, value: d.name }))}
                                selectedOption={""}
                                selectionKey={"label"}
                                setValue={val => setValue('dialCode', val, { shouldDirty: true })}
                            />
                            <input type="text"
                                maxLength={15} // Maximum length phone number
                                {...register("phoneNumber", {
                                    onChange: (e) => {
                                        const formattedPhoneNumber = e.target.value.replace(/\D/g, ''); // non-numeric characters
                                        setValue('phoneNumber', formattedPhoneNumber, { shouldDirty: false });
                                    }
                                })} />
                        </div>
                        {formState.errors.phoneNumber && <div className="fldMsg">Please enter a valid phone number</div>}
                        <div className="smlNoteTxt">Manager will receive all the SMS and Voice communication on this number</div>
                    </div>
                    <div className="checkOptionInline">
                        <label>Will this manager be managing employees using web application?</label>
                        <div className="customCheck yesNo">
                            <input type="checkbox" id="webApplication" checked readOnly />
                            <label className="customCheckLabel" htmlFor="webApplication">yesNo</label>
                        </div>
                    </div>
                    <div className="checkOptionInline">
                        <label>Will this manager be managing employees using mobile application?</label>
                        <div className="customCheck yesNo">
                            <input type="checkbox" id="mobileApplication" checked={props.mobileManage} readOnly />
                            <label className="customCheckLabel" htmlFor="mobileApplication">yesNo</label>
                        </div>
                    </div>
                    <div className="checkOptionInline">
                        <label>Will this manager be tracked for time and location?</label>
                        <div className="customCheck yesNo">
                            <input type="checkbox" id="timeLocation" {...register("trackManager", { value: false })} />
                            <label className="customCheckLabel" htmlFor="timeLocation">yesNo</label>
                        </div>
                    </div>
                </div>
                <div className="popupBtnWrap flexJCenter">
                    <button className="btnStyle okBtn" disabled={!isFormComplete}>Add</button>
                </div>
            </form>
        </div>
    )
}

export default AddNewPopup