import React from 'react'

const CancelChangesPopup = ({ showDialog = false, message = '', onConfirm, onCancel }) => {
    return (
        <>
            <div className={`popupWrap msgPopup popupWrap2nd ${showDialog ? 'active' : ''}`}>
                <div className="msgPopupCont">
                    <div className="msgIcon">Info</div>
                    <div className="msgContent">
                        <div>{message}</div>
                    </div>
                </div>
                <div className="popupMsgBtn">
                    <a href={void (0)} className="okBtn" onClick={onConfirm}>Save</a>
                    <button className="btnStyle okBtn" onClick={onCancel}>Cancel Submission</button>
                </div>
            </div>
            <div className={`overlay2nd${showDialog ? ' active' : ''}`} />
        </>
    )
}

export default CancelChangesPopup