import React, { useEffect, useState } from "react";
import InboundIcon from "../../../../assets/images/inbound-messages.svg";
import OnboundIcon from "../../../../assets/images/outbound-messages.svg";
import PinIcon from "../../../../assets/images/pin-icon.svg";
import TabIcon from "../../../../assets/images/newtab-icon.svg";
import NotificationIcon from "../../../../assets/images/Notifications and alerts.svg";
import "./expandAlertsAndNotificationsModal.css";
import { NotificationAlertsView } from "./NotificationAlertsView";
import InfoIcon from "../../../../assets/images/info-icon.svg";
import { TooltipKendo } from "../Tooltip";

export const ExpandAlertsAndNotificationsModal = ({
  closePopup,
  notificationData,
  handlePinned,
  handleUnPinned,
  hasMore,
  height,
  setCurrentTimeAndDateAlertsNoti,
  notificationAndAlertMetaData,
  setAlertNotificationData,
  setNotificationAndAlertMetaData

}) => {
  useEffect(() => {
    document.documentElement.style.overflowY = "hidden";
    return () => {
      document.documentElement.style.overflowY = "auto";
    };
  }, []);
  const [isOpen, setIsOpen] = useState(false);
  const togglePopover = (isHoverIn) => {
    setIsOpen(isHoverIn);
  };
  return (
    <div>
      <div className="overlaynotification"></div>
      <div className="expandnotiparentBox">
        <div>
          <h2 className="expandnotiheadingtag">
            <div>
            {/* <span style={{display:"flex" }}>
            <img style={{paddingTop:2}} src={NotificationIcon} alt="" />
          </span> */}
              Alerts and Notifications
              <TooltipKendo>
                <img
                  title="Page shows only present day’s data.
                  Please use Reports section for more details."
                  src={InfoIcon}
                  style={{ marginLeft: -4, cursor: "pointer" }}
                  onMouseEnter={() => togglePopover(true)}
                  onMouseLeave={() => togglePopover(false)}
                />
              </TooltipKendo>
            </div>
            <div>
              <TooltipKendo>
                <img
                  style={{ cursor: "pointer" }}
                  onClick={() => window.open("/monitor/notification-alerts")}
                  src={TabIcon}
                  alt=""
                  title="Open In New Tab"
                />
              </TooltipKendo>
              <TooltipKendo>
                <img
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    closePopup();
                    handlePinned("Alerts and Notifications");
                  }}
                  src={PinIcon}
                  alt=""
                  title="Pin to Monitor Page"
                />
              </TooltipKendo>
            </div>
          </h2>
          <div style={{margin:"10px 10px 10px 13px"}} className="expand-parent-border">
            <NotificationAlertsView
              hasMore={true}
              height={400}
              notificationData={notificationData}
              setCurrentTimeAndDateAlertsNoti={setCurrentTimeAndDateAlertsNoti}
              notificationAndAlertMetaData={notificationAndAlertMetaData}
              setAlertNotificationData={setAlertNotificationData}
        setNotificationAndAlertMetaData={setNotificationAndAlertMetaData}

            />
          </div>
        </div>

        <div className="closebtn">
          <button onClick={closePopup} className="expandnotibuttondiv">
            Close
          </button>
        </div>
      </div>
    </div>
  );
};
