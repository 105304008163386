import axios from 'axios';
import { apiProvider } from '../../services/api/provider';
import { Buffer } from 'buffer';
import * as XLSX from 'xlsx';
import Papa from 'papaparse'
export const getMenuItems = async () => {
    const BASE_URL = process.env.REACT_APP_API_URL;
    const menuAPIResource = '/track/react/account/getMenuList';
    const config = {
        headers: {
            'Authorization': 'Bearer' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
        }
    }
    let MENU_ITEMS = {};
    let menuString = localStorage.getItem('menuItems');
    if (menuString && menuString != 'undefined') {
        try {
            MENU_ITEMS = JSON.parse(localStorage.getItem("menuItems"));
            return MENU_ITEMS;
        } catch (error) {
            console.log(error);
            return [];
        }
    } else {

        return await axios.get(`${BASE_URL}/${menuAPIResource}`, config).then((response) => {
            if (response.data.data) {
                localStorage.setItem('menuItems', JSON.stringify(response.data.data));
                return response.data.data;
            } else {
                return [];
            }
        }).catch((error) => {
            console.log(error);
            return [];
        });
    }
}

export const getAccessLevel = async (parentMenu, childMenu) => {
    let menuItems = await getMenuItems();
    if (menuItems && menuItems.length > 0) {
        const parentMenuItem = menuItems?.find(menu => menu.name.toLowerCase() === parentMenu.toLowerCase())
        const accessLevel = {
            parentMenu: null,
            childMenu: null
        }
        if (parentMenuItem) {

            accessLevel.parentMenu = parentMenuItem.accessLevel;

            if (parentMenuItem.submenu.length > 0) {
                const submenuItem = parentMenuItem.submenu.find(menu => menu.name.toLowerCase() === childMenu.toLowerCase())

                if (submenuItem) {
                    accessLevel.childMenu = submenuItem.accessLevel
                }
            }
        }

        return accessLevel;
    } 
    else {

        await apiProvider.getAll('track/react/v1/logout').then((res) => {
            if (res.status === 200) {
                window.location.href = '/logout';
            }
        })
    }

}

const base64EncodeUnicode = (str) => {
    let utf8Bytes = encodeURIComponent(str).replace(
        /%([0-9A-F]{2})/g,
        function (match, p1) {
            return String.fromCharCode(parseInt(`0x${p1}`));
        }
    );

    return btoa(utf8Bytes);
};

export const decodeQueryStringData = (b64Encoded) => {
    // First, we convert the raw bytes into percent encodings, and then decode the UTF-8 encoded string.
    return JSON.parse(Buffer.from(b64Encoded, "base64").toString());
};

export const getEncodedData = (data) => {
    return base64EncodeUnicode(JSON.stringify(data));
};

export const getFileData = file => {
    return new Promise(res => {
        const fileReader = new FileReader();
        const fileData = [];
        fileReader.onload = ({target}) => {
            const data = fileReader.result;
            if (file.name.endsWith('.csv')) {
                let header;
                let dataCsv=[]
                Papa.parse(target.result,{
                    header:true,
                    skipEmptyLines:true,
                    step: result => {
                        if (result.errors.length > 0) {
                        }
                        const headers = result.meta.fields;
                        header= headers;
                        const row = result.data;
                        dataCsv.push(Object.values(row));
                        const rowKeys = Object.keys(row).filter(t=>t!=="")               
                        // if (rowKeys.length !== headers.length) {
                        //     flag=true;
                        // }
                    },
                    complete () {
                        dataCsv.unshift(header)
                      res(dataCsv)
                    },
                    error (err) {
                    //   flag = true;
                     console.log("data error in parse",err)
                    }
                })
            } 
            else if (file.name.endsWith('.xlsx')) {
                const workbook = XLSX.read(data, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                fileData.push(XLSX.utils.sheet_to_json(sheet, { header: 1 }));
            } else {
                // Unsupported file format
                res(null);
                return;
            }
            res(fileData);
        };

        if (file.name.endsWith('.csv')) {
            fileReader.readAsText(file);
        } else if (file.name.endsWith('.xlsx')) {
            fileReader.readAsBinaryString(file);
        } else {
            // Unsupported file format
            res(null);
        }
    });
};


export const base64ToBlob = (base64String, contentType) => {
    const uInt8Array = Buffer.from(base64String, 'base64');

    return new Blob([uInt8Array], { type: contentType })
}

export async function readFile(file) {
    
   return new Promise(res=>{
        let flag=false;
        const reader = new FileReader();
        if (file.name.endsWith('.csv')) {
            reader.readAsText(file);
            reader.onload = async function({target}) {
              Papa.parse(target.result,{
                  header:true,
                  skipEmptyLines:true,
                  step: result => {
                      if (result.errors.length > 0) {
                          flag = true
                      }
                      const headers = result.meta.fields;
                      const row = result.data;
                      const rowKeys = Object.keys(row).filter(t=>t!=="")
                      if (rowKeys.length !== headers.length) {
                          flag=true;
                      }
                  },
                  complete () {
                    res(flag)
                  },
                  error (err) {
                    flag = true;
                   console.log("data error in parse",err)
                  }
                
              })
              
          };
        } else if (file.name.endsWith('.xlsx')) {
              reader.readAsArrayBuffer(file);
              reader.onload = (event) => {
                const data = new Uint8Array(event.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                let headers = jsonData[0];
                jsonData.slice(1).forEach(row => {
                    if(row.length !== headers.length) flag = true
                });
                res(flag)
              };
        } else {
            console.error('Unsupported file type. Please upload a CSV or XLSX file.');
        }
    }) 
  }