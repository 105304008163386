import React, { useState, useEffect, useRef } from "react";
import { apiProvider } from "../../../services/api/provider";
import { getAccountLabel } from "../../../utils/getAccountLabel";

const MessagePopup = ({ data, messageTemplates, closePopup, setToastMsg, setShowLoader }) => {

    const [messageType, setMessageType] = useState('inApp')
    const [selectedTemplate, setSelectedTemplate] = useState('None')
    const [templateMsg, setTemplateMsg] = useState('')

    const disabled = templateMsg?.trim().length === 0

    const selectTemplate = e => {

        const { value } = e.target

        setSelectedTemplate(value)
        setTemplateMsg(value === 'None' ? '' : value)
    }

    const sendMessage = async () => {
        try {
            setShowLoader(true)

            const deviceIds = data.map(d => d.deviceId)

            const payload = {
                devices: deviceIds,
                type: messageType,
                message: templateMsg,
                messageTitle: messageTemplates[selectedTemplate]
            }

            const response = await apiProvider.post(`track/react/v1/allgeo/monitor/sendSMS`, payload)
            const employeeLabel = getAccountLabel('employee');
            alert(`Message sent to ${data.length} ${employeeLabel}.`)

            if (response.status === 200) {
                closePopup()
                setToastMsg({ msg: response.message, type: "msgSuccess" })
            }
            else {
                setToastMsg({ msg: response.message, type: "msgError" })
                closePopup()
            }
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    return (
        <div className="popupWrap smlPopup active">
            <div className="closePopup" onClick={closePopup} >Close</div>
            <div className="popupHeading">Send Message</div>
            <div className="popupContent">
                <div className="fldWrap">
                    <label>Channel</label>
                    <div className="customControls">
                        <div className="customRadio">
                            <input type="radio" id="SMS" name="Channel" value={messageType} onClick={() => setMessageType('sms')} />
                            <label htmlFor="SMS">SMS</label>
                        </div>
                        <div className="customRadio">
                            <input type="radio" id="InApp" name="Channel" defaultChecked value={messageType} onClick={() => setMessageType('inApp')} />
                            <label htmlFor="InApp">In App</label>
                        </div>
                    </div>
                </div>
                <div className="fldWrap">
                    <label>Select Template</label>
                    <select onChange={selectTemplate}>
                        <option key={'none'} value={'None'} >None</option>
                        {
                            Object.keys(messageTemplates).map(templateKey => (
                                <option key={templateKey} value={templateKey} >{messageTemplates[templateKey]}</option>
                            ))
                        }
                    </select>
                </div>
                <div className="fldWrap">
                    <label>Message</label>
                    <textarea placeholder="Type Our Own Message" value={templateMsg} onChange={e => {
                        setTemplateMsg(e.target.value)
                    }} ></textarea>
                    <div>Devices Selected: <strong>{data.length}</strong></div>
                </div>
            </div>
            <div className="popupBtnWrap flexJCenter">
                <button className="btnStyle okBtn" onClick={sendMessage} disabled={disabled}>Send</button>
            </div>
        </div>
    )
}

export default MessagePopup;
