import React, { useState } from 'react';
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import { DropdownList } from '../../../common/dropdown';
import { RefreshBtn } from "../../../common/kendo/HeaderComponents";
import Download from "../../RunReports/Popups/Download"
import Email from '../Popups/Email';
import ToastMessage from '../../../common/ToastMessage';

const DeviceAuditHeader = ({ generalSettingsData, params, setParams, reportConfig, reportForData, refreshFunc, handleSearchChange, searchTerm, handleExportToExcel, handleExportToPDF, handleExportToCSV, auditReport, deviceAudit, setShowLoader }) => {
  const [showDownloadPopup, setShowDownloadPopup] = useState(false);
  const [showEmailPopup, setShowEmailPopup] = useState(false);
  const [toastMsg, setToastMsg] = useState({
    msg: "",
    type: "msgSuccess"
  });

  const openDownloadPopup = () => {
    setShowDownloadPopup(true);
  };
  const closeDownloadPopup = () => {
    setShowDownloadPopup(false);
  };
  return (
    <>
      <div className='audit-header'>
        <div className='row'>
          <div className='col'>
            <div class="date-time-container">
              <label>Start Date & Time</label>
              <div class="date-time-picker" style={{marginLeft:"-11px"}}>
                <DateTimePicker
                  placeholder={"Start Date & Time"}
                  value={params.date_fr}
                  onChange={(e) => {
                    if (e.value) {
                      if (e.value.getTime() > params.date_to.getTime()) return;

                      setParams({
                        ...params,
                        date_fr: e.value
                      });
                    }
                  }}
                  format={`${generalSettingsData.dateDisplayFormat || 'MM/dd/yyyy'} ${generalSettingsData.timeFormat || 'hh:mm:ss a'}`}
                />
              </div>
              <label style={{ marginLeft: "-10px"}}>End Date & Time</label>
              <div class="date-time-picker-end" style={{marginLeft:"-11px"}}>
                <DateTimePicker
                  placeholder={"End Date & Time"}
                  value={params.date_to}
                  onChange={(e) => {
                    if (e.value) {
                      if (e.value.getTime() < params.date_fr.getTime()) return;

                      setParams({
                        ...params,
                        date_to: e.value
                      });
                    }
                  }}
                  format={`${generalSettingsData.dateDisplayFormat || 'MM/dd/yyyy'} ${generalSettingsData.timeFormat || 'hh:mm:ss a'}`}
                />
              </div>
              <span className='refresh-btn' style={{ marginLeft: "-5px" }}> <RefreshBtn accessLevel={1} onClick={refreshFunc} /></span>
            </div>
          </div>
          <div className='col'>
            <div className="audit_search_field active">
              <input placeholder="search" value={searchTerm} onChange={handleSearchChange} />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            {/* <label style={{ marginTop: '6px' }}>Select Group</label>
            <div class="group-selection">
              <DropdownList
                list={reportConfig.option === 'group' ? reportForData.groups : reportForData.employees}
                filterable={true}
                onChange={(e) => { setParams({ ...params, reportFor: e.value.id }) }}
                defaultValue={params.reportFor}
              />
            </div> */}
          </div>
          <div className='col'>
            <div className="kHeaderRBot">
              <button
                style={{ marginTop: "16px" , fontSize: "0.75rem",padding: "3px 8px 2px 24px" }}
                title="Download"
                className="btnStyle btn2 btnDownload"
                onClick={openDownloadPopup}
              >
                Download
              </button>
              <button
              style={{fontSize: "0.75rem",padding: "3px 8px 2px 24px"}}
                title="Email"
                className="btnStyle btn2 btnEmail"
                onClick={() => setShowEmailPopup(true)}
              >
                Email
              </button>
            </div>
          </div>
        </div>
      </div>
      {showDownloadPopup &&
        <Download closePopup={closeDownloadPopup} report={"auditReport"} handleExportToExcel={handleExportToExcel} handleExportToPDF={handleExportToPDF} handleExportToCSV={handleExportToCSV} />}
      {showEmailPopup &&
        <Email
          deviceAudit={deviceAudit}
          auditReport={auditReport}
          type={"auditReport"}
          setShowLoader={setShowLoader}
          closePopup={() => setShowEmailPopup(false)}
          setToastMsg={setToastMsg}
        />
      }
      {toastMsg.msg && <ToastMessage
        cssClass={toastMsg.type}
        message={toastMsg.msg}
        onClose={() => setToastMsg({ msg: "", type: "" })}
      />}
      <div className={`overlay ${showDownloadPopup || showEmailPopup ? 'active' : null}`} />
    </>
  )
}

export default DeviceAuditHeader