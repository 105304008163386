import React from 'react';
import siteLoader from "../../assets/images/loader.png";

const SiteLoader = (props) => (
    <div className={`fullLoader ${props.isActive ? 'active' : ''}`}>
        <span className="loadAnimation">
            <img src={siteLoader} alt="loading" />
        </span>
    </div>
)

export default SiteLoader;