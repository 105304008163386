import React, { Fragment, useEffect, useState } from 'react'
import Map from '../../common/map';
import { apiProvider } from '../../../services/api/provider';
import { zoneColorCondition, unitConversionRate, getLatLngList } from './jobSitesUtils';
import SiteLoader from '../../common/SiteLoader'
import { getAccountLabel } from '../../../utils';

const ViewJobSite = ({ mode, setMode, siteId, setToastMsg, accessLevel }) => {

    const [activeList, setActiveList] = useState({
        jobSite: true,
        evv: false
    })
    const [jobSiteData, setJobSiteData] = useState(null)
    const [deletePopup, setDeletePopup] = useState(false)
    const [showLoader, setShowLoader] = useState(true);
    const [payerInfo, setPayerInfo] = useState({});

    const fetchData = () => {

        apiProvider.getSingle('track/mobile/v1/allgeo/getCustomer', siteId).then((response) => {
            if (response.status === 200 && response.message.toLowerCase() === 'success') {
                setJobSiteData(response.data)

                if (response.data.sandataEnabled) {
                    apiProvider.getAll('track/mobile/v1/allgeo/evv/payer').then(res => {
                        if (res) {
                            if (response.data.evv_info.payer_information !== "None") {
                                const payerdata = res.find(p => p.payerServiceInfoId === response.data.evv_info.payer_information)
                                setPayerInfo(payerdata)
                            }
                        }
                    })
                }
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setShowLoader(false);
        });
    };

    const deleteAJobSite = async data => {

        try {
            setShowLoader(true)
            return await apiProvider.removeObject("/track/mobile/v1/allgeo/customers/delete", [data.txnId])
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    const DeletePopup = () => (
        <div className="popupWrap msgPopup deleteMsg active">
            <div className="msgPopupCont">
                <div className="msgIcon msgDelete">Delete</div>
                <div className="msgContent">
                    <div>Are you sure you want to delete the selected record?</div>
                </div>
            </div>
            <div className="popupMsgBtn">
                <a className="okBtn cursorPointer" onClick={() => setDeletePopup(false)}>Cancel</a>
                <button className="btnStyle okBtn" onClick={async () => {
                    const res = await deleteAJobSite(jobSiteData)
                    if (res.status === 200) {
                        setDeletePopup(false)
                        setMode({
                            title: "Manage Job/Client Sites",
                            type: 'main',
                            data: null,
                            hasPrev: false
                        })
                        setToastMsg({
                            msg: `${jobSiteData.name} deleted Successfully`,
                            type: "msgSuccess"
                        })
                    }
                }}>Yes</button>
            </div>
        </div>
    )

    useEffect(() => {
        fetchData()
    }, [mode])

    const jobsiteLabel = getAccountLabel('jobsite');

    return (
        <Fragment>
            {jobSiteData && <>
                <div className="viewTopSection">
                    <div className="headingCtrl">
                        <a
                            className={`editGrp${accessLevel < 2 ? ' disabled' : ''}`}
                            onClick={() => setMode({
                                title: `Edit ${jobsiteLabel} Information`,
                                type: 'edit',
                                data: jobSiteData,
                                hasPrev: true
                            })}
                        >Edit {jobsiteLabel}</a>
                        <a
                            className={`deleteGrp${accessLevel < 3 ? ' disabled' : ''}`}
                            onClick={() => setDeletePopup(true)}
                        >Delete {jobsiteLabel}</a>
                    </div>
                    <div className="viewInfo">{jobsiteLabel} Information</div>
                    <div className="viewSectionWrap">
                        <div className="viewSectionLeft">
                            <div className="fldWrap">
                                <label>Site Name</label>
                                <p>{jobSiteData.name}</p>
                            </div>
                            <div className="fldWrap">
                                <label>Geozone Type</label>
                                <p>{jobSiteData.geozoneType}</p>
                            </div>
                            <div className="fldWrap">
                                <label>Status</label>
                                <p className="txtActive">{jobSiteData.active ? 'Active' : 'Inactive'}</p>
                            </div>
                            <div className="fldWrap">
                                <label>Radius</label>
                                <p>{jobSiteData.geozone.zoneRadius} {jobSiteData.geozone.radiusUnit}</p>
                            </div>
                            <div className="fldWrap">
                                <label>Address</label>
                                <p>{jobSiteData.address}</p>
                            </div>
                        </div>
                        <div className="viewSectionRight">
                            <Map
                                mode={'view'}
                                geoZoneType={jobSiteData.geozoneType}
                                latLngArray={getLatLngList(jobSiteData.geozone.latlanList)}
                                zoneRadius={parseInt(jobSiteData.geozone.zoneRadius) * unitConversionRate[jobSiteData.geozone.radiusUnit.toLowerCase()]}
                                radiusUnit={jobSiteData.geozone.radiusUnit}
                                mapHeight={"100%"}
                                zoneColor={zoneColorCondition(jobSiteData.geozone.zonecolor)}
                                jobSiteData={jobSiteData}
                                setJobSiteData={setJobSiteData}
                            />
                        </div>
                    </div>
                </div>
                <div className="innerWrap">
                    <ul className="accordionList">
                        <li className={activeList.jobSite ? 'active' : ''}>
                            <div className="accHeading" onClick={() => setActiveList({
                                ...activeList,
                                jobSite: !activeList.jobSite
                            })}>Additional {jobsiteLabel} Information</div>
                            <div className="accContent">
                                <div className="rowBlk">
                                    <div className="colBlk3 fldWrap">
                                        <label>Longitude</label>
                                        <p>{jobSiteData.additional_info.longitude !== "" ? Number(jobSiteData.additional_info.longitude).toFixed(5) : "-"}</p>
                                    </div>
                                    <div className="colBlk3 fldWrap">
                                        <label>Latitude</label>
                                        <p>{jobSiteData.additional_info.latitude !== "" ? Number(jobSiteData.additional_info.latitude).toFixed(5) : "-"}</p>
                                    </div>
                                    <div className="colBlk3 fldWrap">
                                        <label>Site ID</label>
                                        <p>{jobSiteData.additional_info.siteId !== "" ? jobSiteData.additional_info.siteId : "-"}</p>
                                    </div>
                                    <div className="colBlk3 fldWrap">
                                        <label>Site Description</label>
                                        <p>{jobSiteData.additional_info.siteName !== "" ? jobSiteData.additional_info.siteName : "-"}</p>
                                    </div>
                                </div>
                                <div className="rowBlk">
                                    <div className="colBlk3 fldWrap">
                                        <label>Source ID</label>
                                        <p>{jobSiteData.additional_info.sourceId !== "" ? jobSiteData.additional_info.sourceId : "-"}</p>
                                    </div>
                                    <div className="colBlk3 fldWrap">
                                        <label>Customer Type</label>
                                        <p>{jobSiteData.additional_info.customerType !== "" ? jobSiteData.additional_info.customerType : "-"}</p>
                                    </div>
                                    <div className="colBlk3 fldWrap">
                                        <label>Reference ID</label>
                                        <p>{jobSiteData.additional_info.referenceId !== "" ? jobSiteData.additional_info.referenceId : "-"}</p>
                                    </div>
                                    <div className="colBlk3 fldWrap">
                                        <label>Contact Email</label>
                                        <p>{jobSiteData.additional_info.email !== "" ? jobSiteData.additional_info.email : "-"}</p>
                                    </div>
                                </div>
                                <div className="rowBlk">
                                    <div className="colBlk3 fldWrap">
                                        <label>Phone Number</label>
                                        <p>{jobSiteData.additional_info.phoneNumber !== "" ? jobSiteData.additional_info.phoneNumber : "-"}</p>
                                    </div>
                                    <div className="colBlk3 fldWrap">
                                        <label>Risk</label>
                                        <p>{jobSiteData.additional_info.risk !== "" ? jobSiteData.additional_info.risk : "-"}</p>
                                    </div>
                                    <div className="colBlk3 fldWrap">
                                        <label>Site Type</label>
                                        <p>{jobSiteData.additional_info.siteType !== "" ? jobSiteData.additional_info.siteType : "-"}</p>
                                    </div>
                                    <div className="colBlk3 fldWrap">
                                        <label>Landline Number</label>
                                        <p>{jobSiteData.additional_info.landLineNumber !== "" ? jobSiteData.additional_info.landLineNumber : "-"}</p>
                                    </div>
                                </div>
                                <div className="rowBlk">
                                    <div className="colBlk3 fldWrap">
                                        <label>Claim Frequency</label>
                                        <p>{jobSiteData.additional_info.claim_freqeuncy !== "" ? jobSiteData.additional_info.claim_freqeuncy : "-"}</p>
                                    </div>
                                    <div className="colBlk3 fldWrap">
                                        <label>User Description</label>
                                        <p>{jobSiteData.additional_info.user_description !== "" ? jobSiteData.additional_info.user_description : "-"}</p>
                                    </div>
                                    <div className="colBlk3 fldWrap">
                                        <label>Skills</label>
                                        <p>{jobSiteData.additional_info.skills !== "" ? jobSiteData.additional_info.skills : "-"}</p>
                                    </div>
                                    <div className="colBlk3 fldWrap">
                                        <label>Address</label>
                                        <p>{jobSiteData.additional_info.addressType !== "" ? jobSiteData.additional_info.addressType : "-"}</p>
                                    </div>
                                </div>
                                <div className="rowBlk">
                                    <div className="colBlk3 fldWrap">
                                        <label>Ext System</label>
                                        <p>{jobSiteData.additional_info.extSystem !== "" ? jobSiteData.additional_info.extSystem : "-"}</p>
                                    </div>
                                    <div className="colBlk3 fldWrap">
                                        <label>Earning Code</label>
                                        <p>{jobSiteData.additional_info.earningCode !== "" ? jobSiteData.additional_info.earningCode : "-"}</p>
                                    </div>
                                    <div className="colBlk3 fldWrap">
                                        <label>Rate card</label>
                                        <p>{jobSiteData.additional_info.rateCard !== "" ? jobSiteData.additional_info.rateCard : "-"}</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        {jobSiteData.sandataEnabled && <li className={activeList.evv ? 'active' : ''}>
                            <div className="accHeading" onClick={() => setActiveList({
                                ...activeList,
                                evv: !activeList.evv
                            })}>EVV Extension</div>
                            <div className="accContent">
                                <div className="rowBlk">
                                    <div className="colBlk3 fldWrap">
                                        <label>Client First Name</label>
                                        <p>{jobSiteData.evv_info.client_first_name !== "" ? jobSiteData.evv_info.client_first_name : "-"}</p>
                                    </div>
                                    <div className="colBlk3 fldWrap">
                                        <label>Client Last Name</label>
                                        <p>{jobSiteData.evv_info.client_last_name !== "" ? jobSiteData.evv_info.client_last_name : "-"}</p>
                                    </div>
                                    <div className="colBlk3 fldWrap">
                                        <label>Service Code</label>
                                        <p>{jobSiteData.evv_info.service_code !== "" ? jobSiteData.evv_info.service_code : "-"}</p>
                                    </div>
                                    <div className="colBlk3 fldWrap">
                                        <label>Timezone</label>
                                        <p>{jobSiteData.evv_info.timezone !== "" ? jobSiteData.evv_info.timezone : "-"}</p>
                                    </div>
                                </div>
                                <div className="rowBlk">
                                    <div className="colBlk3 fldWrap">
                                        <label>State ID</label>
                                        <p>{jobSiteData.evv_info.stateId !== "" ? jobSiteData.evv_info.stateId : "-"}</p>
                                    </div>
                                    <div className="colBlk3 fldWrap">
                                        <label>Jurisdiction ID</label>
                                        <p>{jobSiteData.evv_info.jurisdictionId !== "" ? jobSiteData.evv_info.jurisdictionId : "-"}</p>
                                    </div>
                                    <div className="colBlk3 fldWrap">
                                        <label>Authorization</label>
                                        <p>{jobSiteData.evv_info.authorization !== "" ? jobSiteData.evv_info.authorization : "-"}</p>
                                    </div>
                                    <div className="colBlk3 fldWrap">
                                        <label>Client Medicaid ID</label>
                                        <p>{jobSiteData.evv_info.client_medicaidId !== "" ? jobSiteData.evv_info.client_medicaidId : "-"}</p>
                                    </div>
                                </div>
                                <div className="rowBlk">
                                    <div className="colBlk3 fldWrap">
                                        <label>Effective Start Date</label>
                                        <p>{jobSiteData.evv_info.startDate !== "" ? jobSiteData.evv_info.startDate : "-"}</p>
                                    </div>
                                    <div className="colBlk3 fldWrap">
                                        <label>Effective End Date</label>
                                        <p>{jobSiteData.evv_info.endDate !== "" ? jobSiteData.evv_info.endDate : "-"}</p>
                                    </div>
                                    <div className="colBlk3 fldWrap">
                                        <label>Client Payer ID</label>
                                        <p>{jobSiteData.evv_info.client_payerID !== "" ? jobSiteData.evv_info.client_payerID : "-"}</p>
                                    </div>
                                    <div className="colBlk3 fldWrap">
                                        <label>Client Identifier</label>
                                        <p>{jobSiteData.evv_info.client_idendifier !== "" ? jobSiteData.evv_info.client_idendifier : "-"}</p>
                                    </div>
                                </div>
                                <div className="rowBlk">
                                    <div className="colBlk3 fldWrap">
                                        <label>Payer Information</label>
                                        <p>{Object.keys(payerInfo).length > 0 && payerInfo.description !== "" ? payerInfo.description : "-"}</p>
                                    </div>
                                    <div className="colBlk3 fldWrap">
                                        <label>Client ID</label>
                                        <p>{jobSiteData.evv_info.clientid !== "" ? jobSiteData.evv_info.clientid : "-"}</p>
                                    </div>
                                </div>
                                <div className="rowBlk">
                                    <div className="colBlk3 fldWrap">
                                        <label>Payer ID</label>
                                        <p>{Object.keys(payerInfo).length > 0 && payerInfo.payerID !== "" ? payerInfo.payerID : "-"}</p>
                                    </div>
                                    <div className="colBlk3 fldWrap">
                                        <label>Payer Program</label>
                                        <p>{Object.keys(payerInfo).length > 0 && payerInfo.payerProgram !== "" ? payerInfo.payerProgram : "-"}</p>
                                    </div>
                                    <div className="colBlk3 fldWrap">
                                        <label>Procedure Code</label>
                                        <p>{Object.keys(payerInfo).length > 0 && payerInfo.procedureCode !== "" ? payerInfo.procedureCode : "-"}</p>
                                    </div>
                                    <div className="colBlk3 fldWrap">
                                        <label>Modifier 1</label>
                                        <p>{Object.keys(payerInfo).length > 0 && payerInfo.modifier1 !== "" ? payerInfo.modifier1 : "-"}</p>
                                    </div>
                                </div>
                                <div className="rowBlk">
                                    <div className="colBlk3 fldWrap">
                                        <label>Modifier 2</label>
                                        <p>{Object.keys(payerInfo).length > 0 && payerInfo.modifier2 !== "" ? payerInfo.modifier2 : "-"}</p>
                                    </div>
                                    <div className="colBlk3 fldWrap">
                                        <label>Modifier 3</label>
                                        <p>{Object.keys(payerInfo).length > 0 && payerInfo.modifier3 !== "" ? payerInfo.modifier3 : "-"}</p>
                                    </div>
                                    <div className="colBlk3 fldWrap">
                                        <label>Modifier 4</label>
                                        <p>{Object.keys(payerInfo).length > 0 && payerInfo.modifier4 !== "" ? payerInfo.modifier4 : "-"}</p>
                                    </div>
                                </div>
                            </div>
                        </li>}
                    </ul>
                </div>
                <div className="mainBtnWrap">
                    <button className="btnStyle" onClick={() => setMode({
                        title: "Manage Job/Client Sites",
                        type: 'main',
                        data: null,
                        hasPrev: false
                    })}>Back</button>
                </div>
                {deletePopup && <DeletePopup />}
                <div className={`overlay${deletePopup ? ' active' : ''}`} />
            </>}
            <SiteLoader isActive={showLoader} />
        </Fragment>
    )
}

export default ViewJobSite