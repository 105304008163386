import React from 'react'
import { useLoadScript } from '@react-google-maps/api'
import Map from './Map'

const libraries = ['places', 'geometry']

const index = ({ mode, geoZoneType, zoneRadius, radiusUnit, latLngArray, mapHeight, zoneColor, setCursor, jobSiteData, setJobSiteData, multipleCirclesLatLngArray = [], multiplePolygonLatLngArray = [] }) => {

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        libraries: libraries
    })

    if (!isLoaded) return <div>Loading...</div>

    return <Map
        mode={mode}
        geoZoneType={geoZoneType}
        latLngArray={latLngArray}
        zoneRadius={zoneRadius}
        radiusUnit={radiusUnit}
        height={mapHeight}
        zoneColor={zoneColor}
        setCursor={setCursor}
        jobSiteData={jobSiteData}
        setJobSiteData={setJobSiteData}
        multipleCirclesLatLngArray={multipleCirclesLatLngArray}
        multiplePolygonLatLngArray={multiplePolygonLatLngArray}
    />
}

export default index