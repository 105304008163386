import { Tooltip } from "@progress/kendo-react-tooltip";
import { CustomPopup } from "../../common/PopUp";

const moment = window.moment;

export const timeZones = [
    {
        value: "US/Hawaii",
        label: "US/Hawaii"
    },
    {
        value: "US/Alaska",
        label: "US/Alaska"
    },
    {
        value: "US/Pacific",
        label: "US/Pacific"
    },
    {
        value: "US/Arizona",
        label: "US/Arizona"
    },
    {
        value: "US/Mountain",
        label: "US/Mountain"
    },
    {
        value: "US/Central",
        label: "US/Central"
    },
    {
        value: "US/Eastern",
        label: "US/Eastern"
    },
    {
        value: "Canada/Pacific",
        label: "Canada/Pacific"
    },
    {
        value: "Canada/Mountain",
        label: "Canada/Mountain"
    },
    {
        value: "Canada/Central",
        label: "Canada/Central"
    },
    {
        value: "Canada/Eastern",
        label: "Canada/Eastern"
    },
    {
        value: "Canada/Atlantic",
        label: "Canada/Atlantic"
    },
    {
        value: "Mexico/BajaNorte",
        label: "Mexico/BajaNorte"
    },
    {
        value: "Mexico/BajaSur",
        label: "Mexico/BajaSur"
    },
    {
        value: "Mexico/General",
        label: "Mexico/General"
    },
    {
        value: "Europe/Athens",
        label: "Europe/Athens"
    },
    {
        value: "Europe/Berlin",
        label: "Europe/Berlin"
    },
    {
        value: "Europe/Dublin",
        label: "Europe/Dublin"
    },
    {
        value: "Europe/Helsinki",
        label: "Europe/Helsinki"
    },
    {
        value: "Europe/Kiev",
        label: "Europe/Kiev"
    },
    {
        value: "Europe/Lisbon",
        label: "Europe/Lisbon"
    },
    {
        value: "Europe/London",
        label: "Europe/London"
    },
    {
        value: "Europe/Madrid",
        label: "Europe/Madrid"
    },
    {
        value: "Europe/Moscow",
        label: "Europe/Moscow"
    },
    {
        value: "Europe/Oslo",
        label: "Europe/Oslo"
    },
    {
        value: "Europe/Paris",
        label: "Europe/Paris"
    },
    {
        value: "Europe/Rome",
        label: "Europe/Rome"
    },
    {
        value: "Europe/Stockholm",
        label: "Europe/Stockholm"
    },
    {
        value: "Europe/Zurich",
        label: "Europe/Zurich"
    },
    {
        value: "Pacific/Auckland",
        label: "Pacific/Auckland"
    },
    {
        value: "Pacific/Chatham",
        label: "Pacific/Chatham"
    },
    {
        value: "GMT",
        label: "GMT"
    },
    {
        value: "GMT+00:00",
        label: "GMT+00:00"
    },
    {
        value: "GMT+01:00",
        label: "GMT+01:00"
    },
    {
        value: "GMT+02:00",
        label: "GMT+02:00"
    },
    {
        value: "GMT+03:00",
        label: "GMT+03:00"
    },
    {
        value: "GMT+04:00",
        label: "GMT+04:00"
    },
    {
        value: "GMT+05:00",
        label: "GMT+05:00"
    },
    {
        value: "GMT+05:30",
        label: "GMT+05:30"
    },
    {
        value: "GMT+06:00",
        label: "GMT+06:00"
    },
    {
        value: "GMT+07:00",
        label: "GMT+07:00"
    },
    {
        value: "GMT+08:00",
        label: "GMT+08:00"
    },
    {
        value: "GMT+09:00",
        label: "GMT+09:00"
    },
    {
        value: "GMT+10:00",
        label: "GMT+10:00"
    },
    {
        value: "GMT+11:00",
        label: "GMT+11:00"
    },
    {
        value: "GMT+12:00",
        label: "GMT+12:00"
    },
    {
        value: "GMT+13:00",
        label: "GMT+13:00"
    },
    {
        value: "GMT+14:00",
        label: "GMT+14:00"
    },
    {
        value: "GMT-01:00",
        label: "GMT-01:00"
    },
    {
        value: "GMT-02:00",
        label: "GMT-02:00"
    },
    {
        value: "GMT-03:00",
        label: "GMT-03:00"
    },
    {
        value: "GMT-04:00",
        label: "GMT-04:00"
    },
    {
        value: "GMT-05:00",
        label: "GMT-05:00"
    },
    {
        value: "GMT-06:00",
        label: "GMT-06:00"
    },
    {
        value: "GMT-07:00",
        label: "GMT-07:00"
    },
    {
        value: "GMT-08:00",
        label: "GMT-08:00"
    },
    {
        value: "GMT-09:00",
        label: "GMT-09:00"
    },
    {
        value: "GMT-10:00",
        label: "GMT-10:00"
    },
    {
        value: "GMT-11:00",
        label: "GMT-11:00"
    },
    {
        value: "GMT-12:00",
        label: "GMT-12:00"
    },

]

export const getWeekDayString = (str) => {
    if (!str) return "";
    let returnText = "";
    let weeksData = str.split('|');

    const weeksConfig = [
        { label: 'Sun', value: 'Sunday' },
        { label: 'Mon', value: 'Monday' },
        { label: 'Tue', value: 'Tuesday' },
        { label: 'Wed', value: 'Wednesday' },
        { label: 'Thu', value: 'Thursday' },
        { label: 'Fri', value: 'Friday' },
        { label: 'Sat', value: 'Saturday' },
    ];

    weeksConfig.forEach((ele) => {
        if (weeksData.includes(ele.label)) {
            returnText += `${ele.value}, `
        }
    })

    return returnText.trim().slice(0, -1)
}

export const reportSchedulerColumns = [
    {
        title: "Schedule ID",
        field: "scheduleId",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Repeats On",
        field: "repeatType",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Next Run",
        field: "nextRunDateTime",
        editable: false,
        width: 240,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Report For",
        field: "assignTo",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Status",
        field: "active",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Running Status",
        field: "runningStatus",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
]

export const getDefaultDate = (type, defaultHours) => {
    if (type === 0) {
        const date = new Date()

        if (defaultHours) {
            date.setHours(defaultHours, 0, 0, 0)
        }

        return date
    }

    const today = new Date()
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)

    if (!defaultHours)
        tomorrow.setHours(tomorrow.getHours() + 1)
    else tomorrow.setHours(defaultHours, 0, 0, 0)

    return tomorrow

}

export const getFutureDate = (date, numOfDays = 1) => {
    const dateIns = new Date(date);

    const futureDate = new Date(dateIns)
    futureDate.setDate(futureDate.getDate() + numOfDays)
    futureDate.setHours(futureDate.getHours() + numOfDays)

    return futureDate
}

export const getMinDate = timestamp => {
    const today = new Date();
    const givenDate = new Date(timestamp)

    return today > givenDate ? givenDate : today
}

export const formatDate = (date, format = 'YYYY/MM/DD') => moment(date).format(format)

export const formatTime = (value, format = 'HH:mm:ss') => moment(value).format(format)

export const isBefore = (start, end) => moment(start).isBefore(end)

export const weekDaysConfig = [
    { label: 'S', value: 'Sun', selected: false },
    { label: 'M', value: 'Mon', selected: false },
    { label: 'T', value: 'Tue', selected: false },
    { label: 'W', value: 'Wed', selected: false },
    { label: 'T', value: 'Thu', selected: false },
    { label: 'F', value: 'Fri', selected: false },
    { label: 'S', value: 'Sat', selected: false },
]

export const reportTypes = {
    STANDARD: 'standard',
    CUSTOM: 'custom'
}

export const reportRanges = {
    TIME_RANGE: "TimeRange",
    HOURS: "Hours",
    DAYS: "Days"
}

export const reportMedium = {
    EMAIL: "email",
    FTP: "ftp",
    GOOGLE: "google"
}

export const assignTypes = {
    GROUP: 'Group',
    EMPLOYEE: 'Device'
}

export const repeatType = {
    DAILY: 'daily',
    WEEKLY: 'weekly'
}

export const MyPopup = props => <CustomPopup {...props} anchorAlign={{ horizontal: 'center', vertical: 'top' }} />

export const CustomTooltip = props => <Tooltip position="right" style={{ zIndex: 1000 }} >{props.children}</Tooltip>