import React, { useState, useEffect } from "react";
import { apiProvider } from "../../../../../services/api/provider";
import SiteLoader from "../../../../common/SiteLoader";
import {ViewCurrentSchedule} from "../ViewCurrentSchedule";
import {ViewScheduleData} from "../ViewScheduleData";
import {DEFAULT_MODE} from "../../utils/activeScheduleUtils";
import './ViewScheduleWrapper.css';

export const ViewScheduleWrapper = ({ selectedSchedule, setMode }) => {

  const [showLoader, setShowLoader] = useState(false);
  const [scheduleData, setScheduleData] = useState();
  const [selectedView, setSelectedView] = useState('current');
  const [dailyScheduleData, setDailyScheduleData] = useState();
  const [dailyScheduleColumns, setDailyScheduleColumns] = useState();

  const fetchScheduleData = async () => {
    try {
      setShowLoader(true)
      const response = await apiProvider.getAll(`track/react/v1/allgeo/activeSchedule/viewSchedule?tripId=${selectedSchedule.trip}`)
      if (response.status === 200) {
        setShowLoader(false)
        setScheduleData(response.data.currentSchedule)
        setDailyScheduleData(response.data.dailyScheduleData)
        setDailyScheduleColumns(response.data.columnMap)
      }
    } catch (error) {
      console.log(error);
      setShowLoader(false)
    }
  }

  useEffect(() => {
    fetchScheduleData();
  }, [])

  const handleTabClick = (tab, event) => {
    event.preventDefault();
    setSelectedView(tab);
  };

  const closeModal = () => {
    setMode(DEFAULT_MODE)
  }

  return (
    <>
      <div className="lg_modal_wrap lg_popup active">
        <div className="modal_head_sec">
          <span>View Schedule</span>
        </div>
        <div className="modal_content_sec">
          <ul className="inner_mdl_tabs">
            <li className={`left_tab`}>
              <a href="/" className={` ${selectedView === 'current' ? 'active' : ''}`} onClick={(e) => handleTabClick('current', e)}>Current Schedule</a>
            </li>
            <li className={`right_tab`}>
              <a href="/" className={` ${selectedView === 'daily' ? 'active' : ''}`} onClick={(e) => handleTabClick('daily', e)}>Daily Schedule Data</a>
            </li>
          </ul>
          {
            selectedView === 'current' ?
              <ViewCurrentSchedule scheduleData={scheduleData} />
              :
              <ViewScheduleData scheduleData={dailyScheduleData} columns={dailyScheduleColumns} />
          }
        </div>
        <div className="modal_btn_sec">
          <button className="mdl_btn" onClick={closeModal}>Close</button>
        </div>
        <SiteLoader isActive={showLoader} />
      </div>
    </>
  )
}