import React, { useState } from 'react'
import Header from '../../layout/Header'
import ConnectorList from './ConnectorList/ConnectorList'
import ConnectorScheduler from './ConnectorScheduler/ConnectorScheduler'
import { useDocumentTitle } from '../../../hooks'

const Connectors = () => {

    useDocumentTitle('Connectors - allGeo')

    const [activeTab, setActiveTab] = useState('connectorList')

    const handleTabChange = tab => {
        setActiveTab(tab)
    }

    return (
        <>
            <div className='mainContentWrap'>
                <Header title={'Web Connectors & APIs'} />
                <div className='mainContent' id='mainContent'>
                    <ul className="rightInnerNav">
                        <li><a className={`${activeTab === 'connectorList' ? 'active' : ''}`} onClick={() => handleTabChange('connectorList')}>CONNECTORS LIST</a></li>
                        {/* <li><a className={`${activeTab === 'connectorScheduler' ? 'active' : ''}`} onClick={() => handleTabChange('connectorScheduler')} >CONNECTOR SCHEDULER</a></li> */}
                    </ul>
                    {activeTab === 'connectorList' && <ConnectorList/>}
                    {activeTab === 'connectorScheduler' && <ConnectorScheduler/>}
                </div>
                {}
            </div>
        </ >
    )
}

export default Connectors