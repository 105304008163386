import React, { useEffect, useState } from "react";
import "./employeeAppData.css";
import ExpandIcon from "../../../../assets/images/expand-more.svg";
import RefreshButton from "../../../../assets/images/refresh-button.svg";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import PinIcon from "../../../../assets/images/pin-fill-icon.svg";
import SearchIcon from "../../../../assets/images/search-icon.svg";
import DownArrow from "../../../../assets/images/downarrow.svg";
import TabIcon from "../../../../assets/images/newtab-icon.svg";
import DeleteIcon from "../../../../assets/images/delete-icon.svg";
import AddIconMonitor from "../../../../assets/images/add-icon-monitor.svg";
import NotAllowed from "../../../../assets/images/not-allowed-icon.svg";
import GreySuspend from "../../../../assets/images/greySuspend.svg";
import GreyDelete from "../../../../assets/images/greyDelete.svg";
import EmployeeAppDataIcon from "../../../../assets/images/Employee and App Data.svg";
import { EmployeesAppDataTable } from "./EmployeesAppDataTable";
import { PinnedLayoutBox } from "../../common/PinnedLayout/PinnedLayoutBox";
import DeleteSuspendModal from "../../common/NotificationAndAlerts/DeleteSuspendModal";
import { getAccountLabel } from "../../../../utils";
import { TooltipKendo } from "../../common/Tooltip";
import AddNewEmployeeModal from "./Modals/AddNewEmployeeModal";
import ToastMessage from "../../../../components/common/ToastMessage";
import useGeneralSettings from "../../../../hooks/useGeneralSettings";
import { isDisabled } from "../../../../components/common/kendo/kendoUtils";
import { defaultMode } from "../../utils";
import ActiveIcon from "../../../../assets/images/kendo-activate.svg";
import AddNewPopup from "../../../../components/company/Employees/AddNewPopup";
import ActionPopup from "../../../../components/company/Employees/ActionPopup";
import AddIconMonitorDisable from "../../../../assets/images/add-icon-disable.svg";
import greyActiveIcon from "../../../../assets/images/grey-check.svg";
import SiteLoader from "../../../../components/common/SiteLoader";

export const PinnedComponent = ({
  handleUnPinned,
  employeeData,
  closeDeletePopup,
  handleDeleteEmployees,
  setDeletePopup,
  deletePopup,
  setShowChangeStatusModal,
  setSelectedDeleteItem,
  openDeletePopup,
  setEmployeePaginateMetaData,
  employeePaginateMetaData,
  dropdowndata,
  openSuspendPopup,
  suspendPopup,
  closeSuspendPopup,
  handleSuspendEmployees,
  currentTimeAndDate,
  setCurrentTimeAndDate,
  accessLevel,
  setEmployeeData,
  fromPinned,
  showLoader,
  fetchEmployeeAppData,
  showChangeStatusModal,
  activePopup,
  openActivePopup,
  handleActiveEmployees,
  employeeBackupData,
  setEmployeeBackupData,
  selectedDeleteItem,
  search,
  setSearch,
  employeePaginateMetaDataPrevious,
  setEmployeePaginateMetaDataPrevious,
  setShowAddNewPopup,
  // refreshData,
  // setRefreshData
}) => {

  const { timezone, nomenclature } = useGeneralSettings();
  const [toastMsg, setToastMsg] = useState({
    msg: "",
    type: "msgSuccess",
  });
  console.log(showLoader,"TAB_INDEXTAB_INDEX")

  const handleOptionChange = (event) => {
    const [firstParam, secondParam] = event.target.value.split(":");
    setSearch("");
    setEmployeePaginateMetaData((prevState) => ({
      ...prevState,
      queryParams: {
        ...prevState.queryParams,
        searchBy: firstParam,
        searchValue: secondParam,
      },
    }));
  };

  const handleSearch = (evt, prevSearch = "") => {
    const searchText =
      prevSearch.trim().length > 0 ? prevSearch : evt.target.value;

    setSearch(searchText);
 
    const newData = employeeData?.data
      ?.map((d, ind) => {
        return {
          ...d,
          id: ind + 1,
        };
      })
      .filter((item) => {
        let match = false;
        for (const property in item) {
          if (
            item[property] &&
            item[property]
              .toString()
              .toLocaleLowerCase()
              .indexOf(searchText.toLocaleLowerCase()) >= 0
          ) {
            match = true;
          }
        }
        return match;
      });

    console.log(newData, "newData");
    setEmployeeBackupData(newData);

  };

  const employeeLabel = getAccountLabel("employee");
  const localTime = currentTimeAndDate?.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  });
  const localDate = currentTimeAndDate?.getDate();
  const localMonth = currentTimeAndDate?.getMonth() + 1;
  const localYear = currentTimeAndDate?.getFullYear();

  useEffect(() => {
    if (employeePaginateMetaDataPrevious !== employeePaginateMetaData) {
      fetchEmployeeAppData(false,false,true);
    }
  }, [employeePaginateMetaData]);

  console.log(employeeBackupData, "employes");

  return (
    <PinnedLayoutBox>
      <div className="employeeHeadingContainer">
        <p style={{ display: "flex" }} className="employeeheadtag">
          <span style={{ marginRight: "6px" }}>
            <img src={EmployeeAppDataIcon} alt="" />
          </span>
          {employeeLabel} App Status
          <span className="refreshtext">
            Last Refreshed:
            <span>{`${localMonth}/${localDate}/${localYear}`}</span>
            <span>{localTime}</span>
          </span>
        </p>
        <div className="employee-head-box">
          <img
            style={{ cursor: "pointer" }}
            onClick={() => handleUnPinned()}
            src={PinIcon}
            alt=""
          />
        </div>
      </div>
      <div className="employeeparentBox pinnedbox">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <div className="employeeiconflexbox">
            <div className="employeemodalsearch">
              <label htmlFor="">Search</label>
              <div style={{ position: "relative" }}>
                <img
                  style={{ position: "absolute", top: 10, left: 10 }}
                  src={SearchIcon}
                  alt=""
                />
                <input
                  type="text"
                  name=""
                  id=""
                  placeholder="Search"
                  value={search}
                  onChange={handleSearch}
                />
              </div>
            </div>
            <div className="employeemodalsearch">
              <label htmlFor="">Filter</label>
              <div className="employeeinnerBox">
                <select
                  value={
                    employeePaginateMetaData?.queryParams?.searchBy +
                    ":" +
                    employeePaginateMetaData?.queryParams?.searchValue
                  }
                  onChange={handleOptionChange}
                >
                  {dropdowndata.map((option, index) => (
                    <option
                      disabled={option.disabled}
                      key={index}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </select>
                <img src={DownArrow} className="arrowicon2" />
              </div>
            </div>
            <div className="" style={{ marginTop: 23.5 }}>
              <div className="">
                <TooltipKendo>
                  <img
                    title="Refresh"
                    style={{ cursor: "pointer", width: 31 }}
                    src={RefreshButton}
                    alt="Refresh Button"
                    role="button"
                    onClick={fetchEmployeeAppData}
                  />
                </TooltipKendo>
              </div>
            </div>
          </div>
          <div className="employeeiconflexbox2">
                {employeePaginateMetaData?.queryParams?.searchValue ===
                  "Suspended" && (
                  <TooltipKendo>
                    <button
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                        backgroundColor: "transparent",
                        border:
                          isDisabled("edit", accessLevel) ||
                          selectedDeleteItem.length <= 1
                            ? "none"
                            : "1px solid #00BD9D",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "4px",
                      }}
                      disabled={
                        isDisabled("edit", accessLevel) ||
                        selectedDeleteItem.length <= 1 ||
                        !Array.isArray(selectedDeleteItem)
                      }
                      onClick={() => openActivePopup()}
                    >
                      <img
                        className={
                          isDisabled("edit", accessLevel) ||
                          selectedDeleteItem.length <= 1 ||
                          !Array.isArray(selectedDeleteItem)
                            ? "disabled_field"
                            : ""
                        }
                        title="Bulk Activate"
                        style={{ cursor: "pointer", width: 30.5, padding: 2 }}
                        src={
                          isDisabled("edit", accessLevel) ||
                          selectedDeleteItem.length <= 1 ||
                          !Array.isArray(selectedDeleteItem)
                            ? greyActiveIcon
                            : ActiveIcon
                        }
                        alt=""
                      />
                    </button>
                  </TooltipKendo>
                )}
                <TooltipKendo>
                  <button
                    style={{ border: "none", backgroundColor: "transparent" }}
                    disabled={isDisabled("add", accessLevel)}
                    onClick={() => setShowAddNewPopup(true)}
                  >
                    <img
                      className={
                        isDisabled("add", accessLevel) ? "disabled_field" : ""
                      }
                      title="Add Employee"
                      style={{
                        height: "32px !important",
                        width: "32px !important",
                        cursor: "pointer",
                      }}
                      src={
                        isDisabled("add", accessLevel)
                          ? AddIconMonitorDisable
                          : AddIconMonitor
                      }
                      alt=""
                    />
                  </button>
                </TooltipKendo>
                {employeePaginateMetaData?.queryParams?.searchValue !==
                  "Suspended" && (
                  <TooltipKendo>
                    <button
                      style={{ border: "none", backgroundColor: "transparent" }}
                      disabled={
                        isDisabled("edit", accessLevel) ||
                        selectedDeleteItem.length <= 1 ||
                        !Array.isArray(selectedDeleteItem)
                      }
                      onClick={() => openSuspendPopup("multiple")}
                    >
                      <img
                        className={
                          isDisabled("edit", accessLevel) ||
                          selectedDeleteItem.length <= 1 ||
                          !Array.isArray(selectedDeleteItem)
                            ? "disabled_field"
                            : ""
                        }
                        title="Bulk Suspend"
                        style={{ cursor: "pointer" }}
                        // onClick={() => openSuspendPopup("multiple")}
                        src={
                          isDisabled("edit", accessLevel) ||
                          selectedDeleteItem.length <= 1 ||
                          !Array.isArray(selectedDeleteItem)
                            ? GreySuspend
                            : NotAllowed
                        }
                        alt=""
                      />
                    </button>
                  </TooltipKendo>
                )}

                <TooltipKendo>
                  <button
                    style={{ border: "none", backgroundColor: "transparent" }}
                    disabled={
                      isDisabled("add", accessLevel) ||
                      selectedDeleteItem.length <= 1 ||
                      !Array.isArray(selectedDeleteItem)
                    }
                    onClick={() => openDeletePopup("multiple")}
                  >
                    <img
                      className={
                        isDisabled("add", accessLevel) ||
                        selectedDeleteItem.length <= 1 ||
                        !Array.isArray(selectedDeleteItem)
                          ? "disabled_field"
                          : ""
                      }
                      title="Bulk Delete"
                      style={{ cursor: "pointer" }}
                      // onClick={() => openDeletePopup("multiple")}
                      src={
                        isDisabled("add", accessLevel) ||
                        selectedDeleteItem.length <= 1 ||
                        !Array.isArray(selectedDeleteItem)
                          ? GreyDelete
                          : DeleteIcon
                      }
                      alt=""
                    />
                  </button>
                </TooltipKendo>
              </div>
        </div>
        <EmployeesAppDataTable
          setShowChangeStatusModal={setShowChangeStatusModal}
          employeeData={employeeData}
          deletePopup={deletePopup}
          setDeletePopup={setDeletePopup}
          handleSuccess={handleDeleteEmployees}
          closeDeletePopup={closeDeletePopup}
          setSelectedDeleteItem={setSelectedDeleteItem}
          openDeletePopup={openDeletePopup}
          openSuspendPopup={openSuspendPopup}
          suspendPopup={suspendPopup}
          closeSuspendPopup={closeSuspendPopup}
          handleSuspendEmployees={handleSuspendEmployees}
          accessLevel={accessLevel}
          setEmployeePaginateMetaData={setEmployeePaginateMetaData}
          employeePaginateMetaData={employeePaginateMetaData}
          setEmployeeData={setEmployeeData}
          fromPinned={fromPinned}
          employeeBackupData={employeeBackupData}
          searchField={search}
          openActivePopup={openActivePopup}
          // refreshData={refreshData}
          // setRefreshData={setRefreshData}
        />
        {deletePopup.open && (
          <DeleteSuspendModal
            heading={"Delete"}
            msg={
              deletePopup.case === "single"
                ? "Are you sure you want to delete this record?"
                : "Are you sure you want to delete the selected records?"
            }
            closePopup={closeDeletePopup}
            onSuccess={() => handleDeleteEmployees()}
          />
        )}

        {suspendPopup.open && (
          <DeleteSuspendModal
            heading={"Suspend"}
            msg={
              suspendPopup.case === "single"
                ? "Are you sure you want to suspend this record?"
                : "Are you sure you want to suspend the selected records?"
            }
            closePopup={closeSuspendPopup}
            onSuccess={() => handleSuspendEmployees()}
            selectedDeleteItem={selectedDeleteItem}
          />
        )}

        {activePopup.open && (
          <DeleteSuspendModal
            heading={"Activate"}
            msg={
              activePopup.case === "single"
                ? "Are you sure you want to active this record?"
                : "Are you sure you want to activate the selected records?"
            }
            closePopup={closeSuspendPopup}
            onSuccess={() => handleActiveEmployees()}
            selectedDeleteItem={selectedDeleteItem}
          />
        )}

        {toastMsg.msg && (
          <ToastMessage
            cssClass={toastMsg.type}
            message={toastMsg.msg}
            onClose={() => setToastMsg({ msg: "", type: "" })}
          />
        )}
      </div>
    </PinnedLayoutBox>
  );
};
