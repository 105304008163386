import { CompanySettings, Groups, Employees, JobSites, Managers, Tasks, Tools, Forms, CompanyBilling, Connectors } from '../components/company'
import { ReportModuleWrapper } from '../components/report'
import { MonitorWrapper, LocateSchedulers } from '../components/monitor'
import { RulesList,WorkOrderList,ActiveSchedulesWrapper } from '../components/schedule'
import { HomePage } from '../components/home/HomePage'
import { Dashboard } from '../pages'
import { Monitor as MonitorV2 } from "../pages/Monitor";


export const componentMap = {
    'settings': CompanySettings,
    'groups': Groups,
    'employees': Employees,
    'jobsites': JobSites,
    'managers': Managers,
    'forms': Forms,
    'tasks': Tasks,
    'tools': Tools,
    'billing': CompanyBilling,
    'automation-rules': RulesList,
    'reports': ReportModuleWrapper,
    'locate-schedule': MonitorWrapper,
    'monitor':MonitorV2,
    'monitor-employees': MonitorWrapper,
    'connectors': Connectors,
    'active-schedule' : ActiveSchedulesWrapper,
    'home': HomePage,
    'work-order' : WorkOrderList,
    'dashboard' : Dashboard,
    'scheduler' : LocateSchedulers
}

export const routeMapCheck = Object.keys(componentMap).map(key => ({
    key,
    show: false
}))