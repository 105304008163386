import React, { useState } from 'react'
import { apiProvider } from '../../../services/api/provider';
import CreateNewGroup from './CreateNewGroup';
import { getAccountLabel } from '../../../utils';

const AddNewPopup = ({ setShowG2Popup, closePopup, setToastMsg, setShowLoader }) => {

    const [groupId, setGroupId] = useState('')
    const [groupName, setGroupName] = useState('')
    const [errorMsg, setErrorMsg] = useState('')
    const [color, setColor] = useState('#FFFFFF')
    const [showCreateNewPopup, setShowCreateNewPopup] = useState(false)

    const checkExistingGroup = async () => {

        try {
            setShowLoader(true)
            if (groupId.trim().length === 0){
                setErrorMsg('Group ID is required')
                return;
            }
            const response = await apiProvider.getAll(`track/mobile/v1/allgeo/isGroupExists?groupId=${encodeURIComponent(groupId)}`)
            if (response.status === 200) {

                if (response.isExists) {
                    setErrorMsg('Group ID already exists');
                } else {
                    setShowCreateNewPopup(true)
                }

            }
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    const groupLabel = getAccountLabel('group');
    const employeeLabel = getAccountLabel('employee');


    return (
        <>
            <div className="popupWrap smlPopup active">
                <div className="closePopup" onClick={closePopup}>Close</div>
                <div className="popupHeading">Add New {groupLabel}</div>
                <div className="popupContent">
                    <div className={`fldWrap ${errorMsg ? 'errFld' : ''}`}>
                        <label>Phone {groupLabel} ID</label>
                        <input
                            type="text"
                            value={groupId}
                            onChange={e => {
                                setErrorMsg('')
                                setGroupId(e.target.value)
                            }}
                            maxLength={32}
                        />
                        {errorMsg &&
                            <div className="fldMsg">{errorMsg}</div>
                        }
                    </div>
                    <div className="fldWrap">
                        <label>{employeeLabel} {groupLabel} Name</label>
                        <input
                            type="text"
                            value={groupName}
                            onChange={e => setGroupName(e.target.value)}
                            maxLength={32}
                        />
                    </div>
                    <div className="fldWrap">
                        <label>Assign a Color</label>
                        <div className="chooseColor">
                            <div className="selectedColor" style={{ backgroundColor: color }}>Color</div>
                            <input
                                type="color"
                                className="colorInput"
                                id="editColor"
                                onChange={e => setColor(e.target.value)}
                                value={color}
                            />
                            <label htmlFor="editColor" className="editColor">Edit Color</label>
                        </div>
                    </div>
                </div>
                <div className="popupBtnWrap">
                    <button className="btnStyle createNextStep" onClick={checkExistingGroup}>Next</button>
                </div>
            </div>
            {showCreateNewPopup &&
                <CreateNewGroup
                    formData={{
                        groupId,
                        groupName: groupName.trim().length === 0 ? groupId : groupName,
                        color,
                    }}
                    closePopup={() => setShowCreateNewPopup(false)}
                    closeParentPopup={closePopup}
                    setToastMsg={setToastMsg}
                    setShowLoader={setShowLoader}
                    setShowG2Popup={setShowG2Popup}
                />}
        </>
    )
}

export default AddNewPopup