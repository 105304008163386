import { Checkbox } from '@progress/kendo-react-inputs';
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { DatePicker, TimePicker } from "@progress/kendo-react-dateinputs";
import { timeZones } from '../../../common/utils';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { trackLocation, defaultTrack, defaultTimeZone, sports } from './constant';



export const RenderColumns = ({ rowId, field, setValue, rows, setRows, watch, getValues, deviceOfGroup, jobNameList, jobSiteList, handleActionButton }) => {

    const handleChange = e => {
        if (field === 'deviceDesc') {
            setValue(`${rowId}.${field}`, e.target.value)
            setValue(`${rowId}.deviceID`, e.target.value.deviceID)
        }
        else {
            setValue(`${rowId}.${field}`, e.target.value)
        }
    };


    switch (field) {
        case "select":
            return <Checkbox
                style={{
                    borderColor: 'lightgrey',
                    marginLeft: 4,
                    height: 20,
                    width: 14,
                    borderRadius: 2
                }}
                onChange={e => {
                    const tempRows = rows;
                    const indToBeDeleted = tempRows.findIndex(row => row.rowID === rowId);

                    if (indToBeDeleted > -1) {
                        tempRows[indToBeDeleted].selected = e.value;
                        setRows(tempRows);
                    }
                }}
            />;

        case 'workOrderNumber':
        case 'formName':
        case 'message':
        case 'billingRate':
            return <input
                type="text"
                onChange={handleChange}
                defaultValue={getValues(`${rowId}.${field}`)}
            />;

        case 'deviceDesc':
            return <DropDownList
                data={deviceOfGroup}
                textField="deviceDesc"
                onChange={handleChange}
                defaultValue={watch(`${rowId}.${field}`)}
            />;

        case 'deviceID':
            return <input
                type="text"
                defaultValue={watch(`${rowId}.${field}`)}
                readOnly
            />;

        case 'locatable':
            return <DropDownList
                data={trackLocation}
                textField="label"
                dataItemKey="value"
                defaultItem={defaultTrack}
                onChange={handleChange}
                defaultValue={getValues(`${rowId}.${field}`)}
            />;

        case 'customerName':
            return <DropDownList
                data={jobSiteList}
                textField="label"
                dataItemKey="value"
                onChange={handleChange}
                defaultValue={getValues(`${rowId}.${field}`)}
            />;

        case 'taskName':
            return <DropDownList
                data={jobNameList}
                textField="label"
                dataItemKey="value"
                onChange={handleChange}
                defaultValue={getValues(`${rowId}.${field}`)}
            />;

        case 'startDate':
            return <DatePicker
                defaultValue={getValues(`${rowId}.${field}`)}
                onChange={handleChange}
                format="dd-MM-yyyy"
                placeholder="Select Start Date"
            />;
        case 'endDate':
            return <DatePicker
                defaultValue={getValues(`${rowId}.${field}`)}
                onChange={handleChange}
                format="dd-MM-yyyy"
                placeholder="Select End Date"
            />;

        case 'timezone':
            return <DropDownList
                data={timeZones}
                textField="label"
                id='big_field'
                defaultItem={defaultTimeZone}
                onChange={handleChange}
                defaultValue={getValues(`${rowId}.${field}`)}
            />;

        case 'startTime':
        case 'endTime':
            return <TimePicker
                onChange={handleChange}
                placeholder={field === 'startTime' ? "Select Start Time" : "Select End Time"}
                defaultValue={getValues(`${rowId}.${field}`)}
            />;

        case 'trackingDay':
            return <MultiSelect
                data={sports}
                onChange={handleChange}
                textField="text"
                dataItemKey="id"
                placeholder="Please select ..."
            />;

        case 'action':
            return <div className="action_dlt_btn"
                onClick={(e) => {
                    handleActionButton(rowId)
                }}
            >
            </div>;

        default:
            return null;
    }
}