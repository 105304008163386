import React, { useState, useEffect } from 'react';
import UploadIcon from '../../../assets/images/icon-upload.svg';
import { apiProvider } from '../../../services/api/provider';

const AddNewPopup = ({ closePopup, setToastMsg, setShowLoader }) => {

    const [file, setFile] = useState(null);
    const [isFormComplete, setIsFormComplete] = useState(false);
    const [error, setError] = useState(false);
    const [formInformation, setFormInformation] = useState({
        formName: '',
        formStatus: true
    });
    const [formType, setFormType] = useState('static');
    const [dynamicformtype, setDynamicformtype] = useState('manager');

    useEffect(() => {

        if (formInformation?.formName !== '' && file) {
            setIsFormComplete(true)
        } else {
            setIsFormComplete(false)
        }

    }, [formInformation?.formName, file])

    const changeFormInformation = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        if (name === 'formStatus') {
            setFormInformation({ ...formInformation, [name]: !formInformation.formStatus })
        } else {
            setFormInformation({ ...formInformation, [name]: value.replace(/[^a-zA-Z0-9 _-]/gi, '') })
        }

    }

    const handleFileSize = () => {

        if (file) {
            let unit = 'b';
            let size = file.size;

            if (size >= 1024 * 1024) {
                size = size / Math.pow(1024, 2);
                unit = 'mb';
            } else if (size >= 1024) {
                size = size / 1024;
                unit = 'kb';
            }

            return `${(Math.round(size * 100) / 100)} ${unit}`;
        }
    }

    const handleFileUpload = e => {
        setError(false)
        const allowedFiletypes = ['.html', 'text/html']

        if (e.dataTransfer) {
            const uploadedFile = e.dataTransfer.files[0];

            if (allowedFiletypes.includes(uploadedFile.type)) {
                setFile(uploadedFile)
            } else {
                setError(true)
            }
        } else {
            const uploadedFile = e.target.files[0];
            if (allowedFiletypes.includes(uploadedFile.type)) {
                setFile(uploadedFile)
            } else {
                setError(true)
            }
        }
    }

    const submit = async () => {
        try {
            setShowLoader(true)

            let formData = new FormData()
            formData.append('file', file);
            formData.append('active', formInformation.formStatus);
            formData.append('formname', formInformation.formName);
            formData.append('formtype', formType);
            if (formType === "dynamic") {
                formData.append('dynamicformtype', dynamicformtype);
            }



            const response = await apiProvider.formPost("/track/mobile/v1/allgeo/forms", formData)

            if (response.status === 200) {
                setToastMsg({
                    msg: `${formInformation.formName} has been added successfully`,
                    type: "msgSuccess"
                })
                closePopup()
            } else {
                setToastMsg({
                    msg: response.message,
                    type: "msgError"
                })
            }
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }


    }


    return (
        <div className="popupWrap smlPopup active">
            <div className="closePopup" onClick={closePopup}>Close</div>
            <div className="popupHeading">Add New Form</div>
            <div className="popupContent">
                <div className="rowBlk">
                    <div className="colBlk6 fldWrap">
                        <label>Form Name</label>
                        <input type="text" name="formName" maxLength={50} value={formInformation.formName} onChange={(e) => changeFormInformation(e)} />
                    </div>
                    <div className="colBlk6 fldWrap errFld">
                        <label>Active</label>
                        <div className="customCheck yesNo">
                            <input type="checkbox" name="formStatus" id="activeStatus" checked={formInformation.formStatus} onChange={(e) => changeFormInformation(e)} />
                            <label className="customCheckLabel" htmlFor="activeStatus">yesNo</label>
                        </div>
                    </div>
                </div>
                <label>Form Type</label>
                <div className="formTypeSection">
                    <div className="customControls fldWrap">
                        <div className="customRadio">
                            <input
                                type="radio"
                                id="static"
                                value="static"
                                checked={formType === 'static'}
                                onChange={() => setFormType('static')}
                            />
                            <label htmlFor="static">Static</label>
                        </div>
                        <div className="customRadio">
                            <input
                                type="radio"
                                id="dynamic"
                                value="dynamic"
                                checked={formType === 'dynamic'}
                                onChange={() => setFormType('dynamic')}
                            />
                            <label htmlFor="dynamic">Dynamic</label>
                        </div>

                    </div>
                </div>
                {formType === 'dynamic' ? <><label>User Type</label>
                    <div className="formTypeSection">
                        <div className="customControls fldWrap">
                            <div className="customRadio">
                                <input
                                    type="radio"
                                    id="manager"
                                    value="manager"
                                    checked={dynamicformtype === 'manager'}
                                    onChange={() => setDynamicformtype('manager')}
                                />
                                <label htmlFor="manager">Manager</label>
                            </div>
                            <div className="customRadio">
                                <input
                                    type="radio"
                                    id="employee"
                                    value="employee"
                                    checked={dynamicformtype === 'employee'}
                                    onChange={() => formType === 'dynamic' ? setDynamicformtype('employee') : ""}
                                />
                                <label htmlFor="employee">Employee</label>
                            </div>

                        </div>
                    </div></> : null}

                <label className="dragDropLabel">Upload Form</label>
                {!file
                    ?
                    <div className="dragDrop bottomGap1">
                        <p><img src={UploadIcon} alt="Upload" /></p>
                        <div>Drag and drop or choose a file</div>
                        <p>Only HTML file extensions are allowed.</p>
                        {error && <div className="errMsg">File type unsupported.</div>}
                        <input type="file" onChange={handleFileUpload} accept=".html" />
                    </div>
                    :
                    <div className="fileDetail">
                        <div className="fileName">{file.name}</div>
                        <span>{handleFileSize()}</span>
                        <em className="fileDelete" onClick={() => setFile(null)} >Delete</em>
                    </div>
                }
                <div className="popupInnerHighlight">
                    <div>Don't have the Form Ready?</div>
                    <div>Try our <a href={void (0)} onClick={() => window.open('https://www.allgeo.com/form-builder')}>Drag &amp; Drop Form Builder.</a></div>
                </div>
            </div>
            <div className="popupBtnWrap flexJCenter">
                <button className="btnStyle okBtn" disabled={!isFormComplete} onClick={(e) => submit()}>Add</button>
            </div>
        </div>
    )
}

export default AddNewPopup