import React, { useEffect, useState } from "react";
import { Pager } from "@progress/kendo-react-data-tools";
import { LocalizationProvider } from "@progress/kendo-react-intl";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import PinIcon from "../../../../assets/images/pin-icon.svg";
import InfoIcon from "../../../../assets/images/info-icon.svg";
import PinFilledIcon from "../../../../assets/images/pin-fill-icon.svg";
import TabIcon from "../../../../assets/images/newtab-icon.svg";
import SearchIcon from "../../../../assets/images/search-icon.svg";
import { getAccountLabel } from "../../../../utils";
import { TooltipKendo } from "../../common/Tooltip";
import MilesIcon from "../../../../assets/images/MILES DATA.svg";

export const MilesDataViewTable = ({
  milesData,
  fromPinned = false,
  handlePinned,
  handleUnPinned,
  setMilesPaginateMetaData,
  closePopup,
  fromNewTab,
  milesBackupData,
  setMilesBackupData,
  showExpanMore,
  // currentTimeAndDateMiles
}) => {
  // const [milesData, setMilesData] = useState(sampleProducts);
  const [currentTimeAndDateMiles, setCurrentTimeAndDateMiles] = useState();
  useEffect(() => {
    setCurrentTimeAndDateMiles(new Date());
  }, [])

  const employeeLabel = getAccountLabel("employee");
  const [search,setSearch]=useState("")
  const [page, setPage] = useState({
    skip: 0,
    take: 50,
  });
  console.log(milesData, "milesData");

  const grid = (
    <Grid className="milestablediv modalmilestable" data={milesBackupData}>
      <Column field="employeeName" title={`${employeeLabel} Name`} />
      <Column field="employeeID" title= {`${employeeLabel} Id`} />
      <Column field="miles" title="Total Miles" />
    </Grid>
  );

  // const handlePageChange = (event) => {
  //   const { skip, take } = event;
  //   console.log(event, "pager.pagepager.page");
  //   setPage({
  //     skip,
  //     take,
  //   });
  //   setMilesData(sampleProducts.slice(skip, skip + take));
  //   console.log(`Page Change: skip ${skip}, take ${take}`);
  //   console.log("Current Products: ", sampleProducts.slice(skip, skip + take));
  // };


  const localTime = currentTimeAndDateMiles?.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  });
  const localDate = currentTimeAndDateMiles?.getDate();
  const localMonth = currentTimeAndDateMiles?.getMonth() + 1;
  const localYear = currentTimeAndDateMiles?.getFullYear();

  const handlePageChange = (event) => {
    const { skip, take } = event;
    console.log(event, "pager.pagepager.page");
    setPage({
      skip,
      take,
    });
    // setMilesData(milesData.slice(skip, skip + take));
    const pageNumber = Math.floor(event.skip / event.take) + 1;
    setMilesPaginateMetaData({ page: pageNumber, perPage: take,paginationFlag:!!fromPinned?true:showExpanMore });
    console.log(pageNumber, take, "pageNumber");
  };

  const handleSearch = (evt, prevSearch = "") => {
    console.log(evt,"ee")
    const searchText =
      prevSearch.trim().length > 0 ? prevSearch : evt.target.value;

    setSearch(searchText);
    // if (otherData?.setSearchText) otherData?.setSearchText(searchText);
    const newData = milesData?.data
      ?.map((d, ind) => {
        return {
          ...d,
          id: ind + 1,
        };
      })
      .filter((item) => {
        let match = false;
        for (const property in item) {
          if (
            item[property] &&
            item[property]
              .toString()
              .toLocaleLowerCase()
              .indexOf(searchText.toLocaleLowerCase()) >= 0
          ) {
            match = true;
          }
        }
        return match;
      });

    console.log(newData, "newData");
    setMilesBackupData(newData);
    // setEmployeeData(newData)
    // setData(newData);
    // let clearedPagerDataState = { ...dataState, take: dataState.take, skip: 0 };
    // const newDataState = processWithGroups(newData, clearedPagerDataState);
    // setResultState(newDataState);
    // setDataState(clearedPagerDataState);
  };






  return (
    <div>
      <div>
        <h2 style={{backgroundColor:!!fromNewTab&&"white"}} className="milesmodalheadingtag">
          <p

            className="milesheadtag"
            style={{ display: "flex", alignItems: "center"}}
          >
           {!fromNewTab&&!!fromPinned&&(  <span style={{marginRight:"6px",display: "flex" }}>
            <img src={MilesIcon} alt="" />
          </span>)}
            Miles Data
            <TooltipKendo>
              <img
                title="Page shows only present day’s data.
                Please use Reports section for more details."
                src={InfoIcon}
                style={{ marginLeft: 8 }}
              />
            </TooltipKendo>
            <span className="refreshtext">
              Last Refreshed:
              <span>{`${localMonth}/${localDate}/${localYear}`}</span>
              <span>{localTime}</span>
            </span>
          </p>
          {!fromNewTab && (
            <div>
            <TooltipKendo>

              <img
                style={{ cursor: "pointer" }}
                onClick={() => window.open("/monitor/miles-data")}
                className="tabicon"
                src={TabIcon}
                alt=""
                title="Open In New Tab"
              />
              </TooltipKendo>
            <TooltipKendo>

              <img
                style={{ cursor: "pointer" }}
                onClick={() => {
                  !fromPinned && closePopup();
                  fromPinned ? handleUnPinned() : handlePinned("Miles Data");
                }}
                src={fromPinned ? PinFilledIcon : PinIcon}
                title="Pin to Monitor Page"
              />
              </TooltipKendo>
            </div>
          )}
        </h2>
      </div>
      <div className="milesmodalsearch">
        <label htmlFor="">Search</label>
        <div style={{ position: "relative" }}>
          <img
            style={{ position: "absolute", top: 10, left: 10 }}
            src={SearchIcon}
            alt=""
           
          />
          <input value={search}  onChange={handleSearch} type="text" name="" id="" placeholder="All Device" />
        </div>
      </div>
      <div>{grid}</div>
      <div className="milesmodalpagination">
        <LocalizationProvider language="en">
          {milesData && milesData.recordsCount > 0 && (
            <Pager
              skip={page.skip}
              take={page.take}
              total={
              !!search
              ? milesBackupData.length:
                milesData.recordsCount}
              buttonCount={3}
              info={true}
              type="numeric"
              pageSizes={[25, 50, 100,250,500,1000,1500,2000]}
              previousNext={true}
              onPageChange={handlePageChange}
            />
          )}
        </LocalizationProvider>
      </div>
    </div>
  );
};
