import React from 'react';
import { DateTimePicker } from '@progress/kendo-react-dateinputs'

export const WeeklyRecurrenceOptions = ({
    selectedOption,
    repeatWeekly,
    setRepeatWeekly,
    setIsAnyChangeInField,
    setSelectedOption
}) => {
    return (
        <>
            <div className="row_wrk_blk">
                <div className="col_six">
                    <label>Ends</label>
                    <div className="one_control">
                        <select
                            className="smlFld"
                            value={selectedOption}
                            onChange={(e) => {
                                setSelectedOption(e.target.value);
                                setIsAnyChangeInField(true);
                            }}
                        >
                            <option value="On">On</option>
                            <option value="After">After</option>
                        </select>
                        {selectedOption === 'On' && (
                            <DateTimePicker
                                placeholder="Select Ends On Date"
                                defaultValue={repeatWeekly.endsOn ? new Date(repeatWeekly.endsOn) : ""}
                                format={"dd-MM-yyyy hh:mm:ss a"}
                                onChange={(e) => {
                                    const isoString = e.target.value.toISOString();
                                    const formattedString = isoString.replace(/[-:]/g, '').replace('T', 'T').slice(0, 15).concat('Z');

                                    setRepeatDaily({
                                        ...repeatWeekly,
                                        endsOn: formattedString
                                    });
                                    setIsAnyChangeInField(true);
                                }}
                            />
                        )}

                        {selectedOption === 'After' && (
                            <div className="with_input_aftr">
                                <input
                                    type="text"
                                    value={repeatWeekly.ocr}
                                    onChange={(e) => {
                                        setRepeatWeekly({
                                            ...repeatWeekly,
                                            ocr: e.target.value
                                        });
                                        setIsAnyChangeInField(true);
                                    }}
                                />
                                <span>ocr</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
