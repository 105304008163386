import React, { useState } from "react"
import { apiProvider } from "../../../services/api/provider";
import { getAccountLabel } from '../../../utils/getAccountLabel';

export const RenameDevice = ({ data, closePopup, setToastMsg, setShowLoader }) => {
    const [deviceName, setDeviceName] = useState('')

    const updateDeviceDescription = async () => {
        try {
            setShowLoader(true)
            const response = await apiProvider.put(`track/react/v1/allgeo/monitor/updateDevice?deviceID=${data.deviceId}&deviceName=${deviceName}`)

            if (response.status === 200) {
                setToastMsg({ msg: `Device Name updated successfully.`, type: "msgSuccess" })
                closePopup()
            } else {
                setToastMsg({ msg: response.message, type: "msgError" })
            }
        } catch (error) {
            console.log(error);
        }
    }
    const employeeLabel = getAccountLabel('employee');
    return (
        <div className="popupWrap smlPopup active">
            <div className="closePopup" onClick={closePopup} >Close</div>
            <div className="popupHeading">Rename {employeeLabel}</div>
            <div className="popupContent">
                <div className="rowBlk">
                    <div className="colBlk6 fldWrap">
                        <label>Phone Number</label>
                        <p>{data.deviceId}</p>
                    </div>
                    <div className="colBlk6 fldWrap">
                        <label>From</label>
                        <p>{data.devicedesc}</p>
                    </div>
                </div>
                <div className="fldWrap">
                    <label>Change To</label>
                    <input type="text" autoFocus value={deviceName} onChange={e => setDeviceName(e.target.value)} />
                </div>

            </div>
            <div className="popupBtnWrap flexJCenter">
                <button className="btnStyle okBtn" disabled={deviceName.trim().length === 0} onClick={updateDeviceDescription} >Change</button>
            </div>
        </div>
    )
}