import { getAccountLabel } from "../../../utils"

const taskLabel = getAccountLabel('task');

export const taskColumns = [
    {
        title: `${taskLabel} ID`,
        field: "taskID",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,
        maxLength: 255
    },
    {
        title: "Name",
        field: "name",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,
        maxLength: 100
    },
    {
        title: "Reference ID",
        field: "refID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Company ID",
        field: "companyID",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text",
        maxLength: 50
    },
    {
        title: "Source ID",
        field: "srcID",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text",
        maxLength: 255
    },
    {
        title: "Ext System",
        field: "extSystem",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text",
        maxLength: 10
    },
    {
        title: "Billing Rate",
        field: "billingRate",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text",
        maxLength: 50
    },
    {
        title: "Created Date",
        field: "creationTime",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    }
]