import { apiProvider } from "../../../../services/api/provider";

export const getAlertsAndNotificationData = async (
  setCurrentTimeAndDate,
  setNotificationAndAlertMetaData,
  notificationAndAlertMetaData,
  setNotificationAndAlertsData,
  setShowLoader,
  fromReresh = false
) => {
  try {
    fromReresh && setShowLoader(true);
    // &fromDate=${notificationAndAlertMetaData?.fromDate}&toDate=${notificationAndAlertMetaData?.toDate}
    const response = await apiProvider.post(
      `track/react/v1/allgeo/monitor/notificationsAndAlerts?pageNo=${notificationAndAlertMetaData?.page}&pageSize=${notificationAndAlertMetaData?.perPage}&fromDate=${notificationAndAlertMetaData?.fromDate}&toDate=${notificationAndAlertMetaData?.toDate}`,
      { ...notificationAndAlertMetaData.queryParams }
    );
    setCurrentTimeAndDate(new Date());
    setNotificationAndAlertMetaData({
      ...notificationAndAlertMetaData,
      totalrecords: parseInt(response?.data?.recordsCount),
      paginatedRecords:
        notificationAndAlertMetaData.paginatedRecords +
        response?.data?.paginatedData?.length,
    });
    console.log(notificationAndAlertMetaData.paginatedRecords +
      response?.data?.paginatedData?.length,"response?.data?.paginatedData?.length")
    var groupedData = await response?.data?.paginatedData?.reduce(
      (acc, obj) => {
        let key = obj.notificationTime + obj.message;
        if (!acc[key]) {
          acc[key] = { ...obj, deviceName: [obj.deviceName], incoming: true };
        } else {
          if (Array.isArray(acc[key].deviceName)) {
            acc[key].deviceName.push(obj.deviceName);
          } else {
            acc[key].deviceName = [acc[key].deviceName, obj.deviceName];
          }
        }
        return acc;
      },
      {}
    );

    let result = Object.values(groupedData);
    // console.log(result,"resultresultresultresult");
    setNotificationAndAlertsData(
      notificationAndAlertMetaData?.page === 1
      ? result
      : (prevResult) => [...prevResult, ...result]
    );
    // console.log(notification)
    // fromReresh&&setShowLoader(false);
  } catch (err) {
    console.error("Error fetching Notifications Data", err);
    return [];
  } finally {
    fromReresh && setShowLoader(false);
  }
};

// let data = [
//   {
//     deviceID: "917353530132",
//     deviceName: "bhavya",
//     message: "Break",
//     messageAll: "Break",
//     notificationTime: "21/03/2024 07:27:13 PM GMT+05:30",
//   },
//   {
//     deviceID: "917353530134",
//     deviceName: "mohan",
//     message: "Break",
//     messageAll: "Break",
//     notificationTime: "21/03/2024 07:27:13 PM GMT+05:30",
//   },
//   {
//     deviceID: "9173533530132",
//     deviceName: "sami",
//     message: "Break",
//     messageAll: "Break",
//     notificationTime: "21/03/2024 07:27:13 PM GMT+05:30",
//   },
//   {
//     deviceID: "9173533530342",
//     deviceName: "sllsk",
//     message: "EndBreak",
//     messageAll: "EndBreak",
//     notificationTime: "21/03/2024 07:30:13 PM GMT+05:30",
//   },
//   {
//     deviceID: "9173533530338",
//     deviceName: "sams",
//     message: "EndBreak",
//     messageAll: "EndBreak",
//     notificationTime: "21/03/2024 07:30:13 PM GMT+05:30",
//   },
//   {
//     deviceID: "9173533530338",
//     deviceName: "shri",
//     message: "EndBreak",
//     messageAll: "EndBreak",
//     notificationTime: "21/03/2024 07:29:13 PM GMT+05:30",
//   },
//   {
//     deviceID: "9173533530338",
//     deviceName: "deepak",
//     message: "Arrive",
//     messageAll: "Arrive",
//     notificationTime: "21/03/2024 07:30:13 PM GMT+05:30",
//   },
// ];

// let groupedData = data.reduce((acc, obj) => {
//     let key = obj.notificationTime + obj.message;
//     if (!acc[key]) {
//         acc[key] = { ...obj, deviceName: [obj.deviceName] };
//     } else {
//         if (Array.isArray(acc[key].deviceName)) {
//             acc[key].deviceName.push(obj.deviceName);
//         } else {
//             acc[key].deviceName = [acc[key].deviceName, obj.deviceName];
//         }
//     }
//     return acc;
// }, {});

// let result = Object.values(groupedData);
// console.log(result,"resultresultresultresult");

// Group the data
// const groupedData = groupDataByDateTimeAndMessage(data);

// let notificationData = [
//   {
//     id: 1,
//     names: ["Sam Kahn"],
//     status: "SOS",
//     time: "1:30 PM",
//     incoming: false,
//     date: "28-03-2024",
//     break: false,
//   },
//   {
//     id: 2,
//     names: [
//       "Marvin Nguyen",
//       "Sam Kahn",
//       "Marvin Nguyen",
//       "Marvin Nguyen",
//       "Marvin Nguyen",
//     ],
//     status: "IN",
//     time: "1:30 PM",
//     date: "28-03-2024",
//     incoming: false,
//     break: false,
//   },
//   {
//     id: 3,
//     names: [
//       "Sam Kahn’s",
//       "Marvin Nguyen",
//       "Sam Kahn",
//       "Marvin Nguyen",
//       "Marvin Nguyen",
//       "Marvin Nguyen",
//     ],
//     status: "LUNCH",
//     date: "28-03-2024",
//     time: "1:30 PM",
//     incoming: false,
//     break: false,
//   },
//   {
//     id: 4,
//     names: ["Time for lunch Break"],
//     time: "12:25 PM",
//     incoming: true,
//     break: true,
//   },
//   {
//     id: 5,
//     names: ["Sam Kahn’s"],
//     status: "IN",
//     time: "1:30 PM",
//     incoming: false,
//     break: false,
//   },
//   {
//     id: 6,
//     names: [
//       "Sam Kahn’s",
//       "Marvin Nguyen",
//       "Sam Kahn",
//       "Marvin Nguyen",
//       "Marvin Nguyen",
//       "Marvin Nguyen",
//     ],
//     status: "LUNCH",
//     time: "1:30 PM",
//     incoming: false,
//     break: false,
//   },
//   {
//     id: 7,
//     names: ["Time for lunch Break"],
//     time: "12:25 PM",
//     incoming: true,
//     break: true,
//   },
//   {
//     id: 8,
//     names: ["Sam Kahn’s"],
//     status: "IN",
//     time: "1:30 PM",
//     incoming: false,
//     break: false,
//   },
// ];
