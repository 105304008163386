import * as React from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { states } from '../../company/Settings/settingsUtils';
import { timeZones } from '../../company/Employees/employeeUtils';
import { filterBy } from '@progress/kendo-data-query';

export const DropDownCell = props => {
    if (props.rowType === "groupHeader") {
        return null;
    }

    if (props.rowType === "groupFooter") {
        if (!Object.keys(props.dataItem.aggregates).includes(props.field)) return <td style={props.style}></td>
        const { aggregateText } = props.otherData;

        let dataSum = "N/A";

        if (props.field === 'deviceName') {
            dataSum = props.dataItem.aggregates[props.field].count
        }

        return (
            <td style={props.style} aria-colindex={props.columnIndex + 1} role={"gridcell"}>
                {aggregateText[props.field]} : {dataSum}
            </td>
        )
    }

    const data = React.useMemo(() => ({
        source: [{
            text: 'Earning Codes',
            value: 'EARNING_CODES'
        }, {
            text: 'Deduction Codes',
            value: 'DEDUCTION_CODES'
        }],
        service: [{
            text: 'ALLGEO',
            value: 'ALLGEO'
        }, {
            text: 'ADP',
            value: 'ADP'
        }
        ],
        stateCode: states.map(state => {
            return {
                text: state.value,
                value: state.value
            }
        }),
        timeZone: timeZones.map(zone => {
            return {
                text: zone.value,
                value: zone.value
            }
        }),
        timezone: timeZones.map(zone => {
            return {
                text: zone.value,
                value: zone.value
            }
        }),
        status: [{
            text: 'Active',
            value: 'Active'
        }, {
            text: 'Suspended',
            value: 'Suspended'
        }
        ],
        'additional_info.risk': [
            {
                text: 'Low',
                value: 'Low'
            },
            {
                text: 'Medium',
                value: 'Medium'
            },
            {
                text: 'High',
                value: 'High'
            }
        ],
        'additional_info.skills': props.otherData ? Object.keys(props.otherData).length > 0 ? props.otherData['skills'] : [] : [],
        'additional_info.earningCode': props.otherData ? Object.keys(props.otherData).length > 0 ? props.otherData['earningCode'] : [] : [],
        active: [
            {
                value: 'Yes',
                text: 'Yes'
            },
            {
                value: 'No',
                text: 'No'
            },
        ],
        customerName: props.otherData ? Object.keys(props.otherData).length > 0 ? props.otherData['jobsites'] : [] : [],
        jobName: props.otherData ? Object.keys(props.otherData).length > 0 ? props.otherData['tasks'] : [] : [],
        jobID: props.otherData ? Object.keys(props.otherData).length > 0 ? props.otherData['tasksIDs'] : [] : [],
        deviceID: props.otherData ? Object.keys(props.otherData).length > 0 ? props.otherData['employeesIDs'] : [] : [],
        deviceName: props.otherData ? Object.keys(props.otherData).length > 0 ? props.otherData['employeeNames'] : [] : [],
        stageName: props.otherData ? Object.keys(props.otherData).length > 0 ? props.otherData['stageName'] : [] : [],
        trade: props.otherData ? Object.keys(props.otherData).length > 0 ? props.otherData['trade'] : [] : [],
        isProcessed: [{
            text: 'Yes',
            value: 'Yes'
        }, {
            text: 'No',
            value: 'No'
        }
        ],
        startStageName: props.otherData ? Object.keys(props.otherData).length > 0 ? props.otherData['stageName'] : [] : [],
        endStageName: props.otherData ? Object.keys(props.otherData).length > 0 ? props.otherData['stageName'] : [] : [],
    }), [])

    const [localizedData, setLocalizedData] = React.useState(data[props.field] ? data[props.field] : [])

    const handleChange = e => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value.value
            });
        }
    };

    const filterData = (filter) => {
        return filterBy(data[props.field] || [], filter);
    };

    const filterChange = (event) => {
        setLocalizedData(filterData(event.filter));
    };

    const {
        dataItem
    } = props;
    const field = props.field || '';

    let dataValue = "";

    if (field.includes(".")) {
        const nestedFields = field.split(".");
        if (dataItem[nestedFields[0]]) {
            dataValue = dataItem[nestedFields[0]][nestedFields[1]]
        }
    } else {
        dataValue = dataItem[field] === null ? '' : dataItem[field];
    }

    const className = dataItem.dirty ? dataItem.dirty[field] ? ' dirty' : '' : ''

    return <td style={props.style} className={props.className + className}>
        {props.editID === props.dataItem.id ?
            <DropDownList
                disabled={props.disabled}
                style={{
                    width: "170px",
                }}
                onChange={handleChange}
                value={localizedData.find(c => c.value && c.value === dataValue)}
                data={localizedData}
                textField="text"
                filterable={props.filterable}
                onFilterChange={filterChange}
            />
            : dataValue?.toString()}
    </td>;
};