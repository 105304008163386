export const standardTimeClock = [
    {
        title: "Device Name",
        field: "deviceName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,
        locked: true
    },
    {
        title: "Device ID",
        field: "deviceID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true
    },
    {
        title: "Group",
        field: "groupName",
        editable: false,
        width: 200,
        editor: "text",
        customCell: true,
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true
    },
    {
        title: "Stage Name",
        field: "stageName",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        pattern: /^\d*\.?\d*$/,
        patternFailMsg: "Please Enter a Numeric Value",
        roundOff: true
    },
    {
        title: "Date Time",
        field: "timeClockSentTime",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,
        maxLength: 5000
    },
    {
        title: "Customer Name",
        field: "customerName",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Start Task",
        field: "jobName",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Ref ID",
        field: "refID",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Source ID",
        field: "srcID",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Billing#",
        field: "billingRate",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Lat/Log",
        field: "latlog",
        editable: true,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true,
        mandatory: true,
    },
    {
        title: "Address",
        field: "address",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Message",
        field: "messageV3",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        customCell: true,
        filter: "text"
    },
    {
        title: "Manager Note",
        field: "managerNotes",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Modified On",
        field: "modifiedOn",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Modified By",
        field: "modifiedBy",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "HOS Source",
        field: "hosSource",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Company Code",
        field: "companyCode",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Employee ID",
        field: "employeeID",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Processed",
        field: "isProcessed",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Miles Code",
        field: "milesCode",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Miles Amount",
        field: "milesAmount",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Position ID",
        field: "positionId",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Time Zone",
        field: "timeZone",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Department Code Desc",
        field: "departmentCodeDesc",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "JobSite Title",
        field: "jobTitle",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "WorkOrder No",
        field: "workorderNo",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Supervisor Note",
        field: "notes",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Date of Service Start",
        field: "serviceStartDate",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Time Service Started",
        field: "serviceStartTime",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Employee Rate",
        field: "empRate",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Customer Rate",
        field: "customerRate",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Task Rate",
        field: "taskRate",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "WorkOrder Rate",
        field: "workorderRate",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Service Time",
        field: "serviceSeconds",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Total Service Hours",
        field: "totalServiceSeconds",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Distance",
        field: "distanceTravelled",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Distance Amount",
        field: "distanceTravelledAmount",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Start Geozone Name",
        field: "startGeozoneName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
]

export const advanceTimeClock = [
    {
        title: "Billing #",
        field: "siteID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,
        // locked: true
    },
    {
        title: "Billing Name",
        field: "siteName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true
    },
    {
        title: "Service Code",
        field: "serviceCode",
        editable: false,
        width: 200,
        editor: "text",
        customCell: true,
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true
    },
    {
        title: "State ID",
        field: "stateID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Authorization",
        field: "authorization",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,
        maxLength: 5000
    },
    {
        title: "Client First Name",
        field: "clientFirstName",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Client Last Name",
        field: "clientLastName",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Date of Service Start",
        field: "serviceStartDate",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Time Service Started",
        field: "serviceStartTime",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Service Time Started",
        field: "customServiceStartTime",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Date of Service End",
        field: "serviceEndDate",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Time of Service End",
        field: "serviceEndTime",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Service Time Ended",
        field: "customServiceEndTime",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Total Hours Worked",
        field: "totoalServiceHours",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        format: "hh:mm:ss",
    },
    {
        title: "Total Service Hours",
        field: "totalServiceSeconds",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        format: "hh:mm:ss"
    },
    {
        title: "Total Units of Service",
        field: "totalUnitsOfservice",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Service Hour Type",
        field: "serviceHoursType",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Start Stage Name",
        field: "startStageName",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "End Stage Name",
        field: "endStageName",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Start Geo Location",
        field: "startGeoLocation",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "End Geo Location",
        field: "endGeoLocation",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Start Address",
        field: "startAddress",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "End Address",
        field: "endAddress",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Regular Service Hours",
        field: "regularServiceHours",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        format: "hh:mm:ss"
    },
    {
        title: "Over Time hours",
        field: "overTimeHours",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        format: "hh:mm:ss"
    },
    {
        title: "Over Time Rate",
        field: "overTimeServiceRate",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Double Over Time Hours",
        field: "doubleOverTimeHours",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        format: "hh:mm:ss"
    },
    {
        title: "Double Over Time Rate",
        field: "doubleOverTimeServiceRate",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Total Punch Hours",
        field: "totalPunchServiceTime",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        format: "hh:mm:ss"
    },
    {
        title: "Manual Entry Code",
        field: "manualEntryCode",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Manager Notes",
        field: "managerNotes",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Claim Frequency Type",
        field: "flsa",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "SSSO Provider ID",
        field: "SSSO",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Batch ID",
        field: "payrollID",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Company Code",
        field: "companyCode",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Jobsite Title",
        field: "jobTitle",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Jobsite ID",
        field: "jobSiteID",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Site Description",
        field: "jobSiteName",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Customer Name",
        field: "customerName",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Device Name",
        field: "deviceName",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Employee ID",
        field: "employeeID",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Employee Email",
        field: "employeeEmail",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Payroll Details",
        field: "payrollInfo",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Start Message",
        field: "startMessage",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Start Message Attachement",
        field: "startMessageAttachment",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        customCell: true,
        show: true,
        filter: "text"
    },
    {
        title: "End Message",
        field: "endMessage",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "End Message Attachement",
        field: "endMessageAttachment",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        customCell: true,
        show: true,
        filter: "text"
    }, {
        title: "Start Scan",
        field: "startScan",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "End Scan",
        field: "endScan",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Jobsite Start Rate",
        field: "startBillingRate",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Jobsite End Rate",
        field: "endBillingRate",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Start Task",
        field: "jobName",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "End Task",
        field: "endJobName",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Task ID",
        field: "jobId",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "End Task ID",
        field: "endJobId", //new field updated for labels
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Task Reference",
        field: "jobRefId",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Pay Period End",
        field: "payPeriodEnd",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Cost Code",
        field: "costCode",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Ref ID",
        field: "refID",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Source ID",
        field: "srcID",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Skills",
        field: "skills",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Ext Phone Number",
        field: "extPhoneNumber",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Customer Type",
        field: "customerType",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Contact Email",
        field: "extEmail",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    //
    {
        title: "Landline Number",
        field: "landlineNumber",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Ext System",
        field: "extSystem",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Rate Card",
        field: "billingRate",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "User Description",
        field: "extUserDescription",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Earning Code",
        field: "earningCode",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Miles Code",
        field: "milesCode",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Trade",
        field: "trade",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Modified On",
        field: "modifiedOn",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Modified By",
        field: "modifiedBy",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Time Zone",
        field: "timeZone",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Day",
        field: "day",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Notes",
        field: "notes",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "State",
        field: "state",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Employee First Name",
        field: "devFirstName",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Employee Last Name",
        field: "devLastName",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Group",
        field: "groupName",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Supervisors Notes",
        field: "remarks",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Work Order No",
        field: "workorderNo",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Employee Rate",
        field: "empRate",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Customer Rate",
        field: "customerRate",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Task Rate",
        field: "taskRate",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Work Order Rate",
        field: "workorderRate",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Class Code",
        field: "benefitsEligibilityClassCode",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Class Code Description",
        field: "benefitsEligibilityClassCodeDesc",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Status Flag 7 Code",
        field: "statusFlagCode",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Status Flag 7 Description",
        field: "statusFlagCodeDesc",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Assigned Shift",
        field: "AssignedShift",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Shift Rate",
        field: "ShiftRate",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Task",
        field: "Task",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Job Name",
        field: "Jobname123",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Amount",
        field: "Amount",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Name",
        field: "Name",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Distance",
        field: "distanceTravelled",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }, {
        title: "Distance Amount",
        field: "distanceTravelledAmount",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },

    {
        title: "Regular Hours",
        field: "recRegularHours",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "OTime Hours",
        field: "recOTHours",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "DOTime Hours",
        field: "recDOTHours",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Start Geozone Name",
        field: "startGeozoneName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "End Geozone Name",
        field: "endGeozoneName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
]

export const messageReport = [
    {
        title: "Phone Number",
        field: "phoneNumber",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "To Number",
        field: "toDeviceID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Address",
        field: "address",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Display Name",
        field: "displayName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Txn Type",
        field: "txnType",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Qr Scan",
        field: "v3QrScan",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        customCell: true,
        show: true,
        filter: "text",
    },
    {
        title: "Task",
        field: "v3Task",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        customCell: true,
        filter: "text",
    },
    {
        title: "Notes",
        field: "notes",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Picture",
        field: "v3Picture",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "Message",
        field: "v3Msg",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "Form Data",
        field: "v3FormData",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "Creation Time",
        field: "creationTime",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Geo Tag",
        field: "geoTag",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    // {
    //     title: "Qr Scan Link",
    //     field: "qrScanLink",
    //     editable: false,
    //     width: 200,
    //     editor: "text",
    //     columnMenu: false,
    //     show: true,
    //     filter: "text",
    //     customCell: true
    // },
    // {
    //     title: "Qr Scan Text",
    //     field: "qrScanText",
    //     editable: false,
    //     width: 200,
    //     editor: "text",
    //     columnMenu: false,
    //     show: true,
    //     filter: "text",
    //     customCell: true
    // },


]

export const WOPerformaceUtils = [
    {
        title: "Employee Name",
        field: "employeeName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "WorkOrder Assigned",
        field: "workOrderNo",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Schedule Site",
        field: "scheduledVisit",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "WorkOrder Start Date",
        field: "woDate",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "WorkOrder Start Time",
        field: "woStartTime",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "WorkOrder End Time",
        field: "woEndTime",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "WorkOrder Alloted Duration",
        field: "woServiceSeconds",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "WorkOrder Message",
        field: "woMessage",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Shift Name",
        field: "masterWorkorderName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Visisted Site",
        field: "realVisit",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Arrive Time",
        field: "arrivalTime",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Depart Time",
        field: "departureTime",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Service Duration",
        field: "serviceTimeSeconds",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "HOS Message",
        field: "hosMessage",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Variance",
        field: "variance",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "%VariancePer +/-",
        field: "variancePer",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Billing#",
        field: "siteID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Ref ID",
        field: "refID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Source ID",
        field: "srcID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
]

export const reportTableAggregates = [
    {
        field: "milesAmount",
        aggregate: "sum"
    },
    {
        field: "totalServiceSeconds",
        aggregate: "sum",
    },
    {
        field: "distanceTravelled",
        aggregate: "sum",
    },
    {
        field: "distanceTravelledAmount",
        aggregate: "sum",
    },
    {
        field: "serviceSeconds",
        aggregate: "sum",
    },
    {
        field: "woServiceSeconds",
        aggregate: "sum"
    },
    {
        field: "serviceTimeSeconds",
        aggregate: "sum"
    }

];

export const reportTableAggregatesText = {
    "milesAmount": "Total Miles Ammount",
    "totalServiceSeconds": "Total Service Hours",
    "distanceTravelled": "Total Distance travel",
    "distanceTravelledAmount": "Total Distance travelled ammount",
    "serviceSeconds": "Service Time",
    "woServiceSeconds": "Allotted Duration Total",
    "serviceTimeSeconds": "Total Service Duration"
}


export const advancedReportTableAggregates = [
    {
        field: "totoalServiceHours",
        aggregate: "sum"
    },
    {
        field: "totalServiceSeconds",
        aggregate: "sum",
    },
    {
        field: "totalUnitsOfservice",
        aggregate: "sum",
    },
    {
        field: "regularServiceHours",
        aggregate: "sum",
    },
    {
        field: "overTimeHours",
        aggregate: "sum",
    },
    {
        field: "overTimeServiceRate",
        aggregate: "sum",
    },
    {
        field: "doubleOverTimeHours",
        aggregate: "sum",
    }, {
        field: "doubleOverTimeServiceRate",
        aggregate: "sum",
    }, {
        field: "totalPunchServiceTime",
        aggregate: "sum",
    }
];

export const advancedReportTableAggregatesText = {
    "totoalServiceHours": "Total Service Worked",
    "totalServiceSeconds": "Total Service Hours Worked",
    "totalUnitsOfservice": "Total Units of Service",
    "regularServiceHours": "Total Regular Hours",
    "overTimeHours": "Total OverTime Hours",
    "overTimeServiceRate": "Total OverTime Rate",
    "doubleOverTimeHours": "Total Double OverT... Hours",
    "doubleOverTimeServiceRate": "Total Double OverT... Rate",
    "totalPunchServiceTime": "Total punch Hours",
}