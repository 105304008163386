import { getAccountLabel } from "../../../../utils"

const taskLabel = getAccountLabel('task')
const groupLabel = getAccountLabel('group')
const employeeLabel = getAccountLabel('employee');

export const HeartBeatReportColumnName = [
    {
        title: `Device Name`,
        field: "deviceName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `DeviceID`,
        field: "DeviceID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "DateTime",
        field: "DateTime",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Last App TerminationTime",
        field: "Last_App_TerminationTime",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Agent Version",
        field: "Agent_Version",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "GPS",
        field: "GPS",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Platform",
        field: "Platform",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Manufature",
        field: "Manufature",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Model",
        field: "Model",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Trip Number",
        field: "Trip_Number",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "Tracking Frequency",
        field: "Tracking_Frequency",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "Battery",
        field: "Battery",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Software Version",
        field: "Software_Version",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    }, {
        title: "UUID",
        field: "UUID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    }, {
        title: "WIFI",
        field: "WIFI",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    }, {
        title: "CellNetwork",
        field: "CellNetwork",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    }, {
        title: "Location Spoofing",
        field: "Location_Spoofing",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    }, {
        title: "Location Permission",
        field: "Location_Permission",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    }, {
        title: "Location Permission Type",
        field: "Location_Permission_Type",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    }, {
        title: "Camera Permission",
        field: "Camera_Permission",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    }, {
        title: "DozeMode",
        field: "DozeMode",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    }, {
        title: "Device GUID",
        field: "Device_GUID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    }, {
        title: "Entry Count",
        field: "Entry_Count",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    }, {
        title: "Label Count",
        field: "Label_Count",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    }, {
        title: "App Foreground",
        field: "App_Foreground",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    }, {
        title: "App Background",
        field: "App_Background",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    }, {
        title: "Current HOS Stage",
        field: "Current_HOS_Stage",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    }, {
        title: "App Logout Status",
        field: "App_Logout_Status",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "App Background Refresh Status",
        field: "App_Background_Refresh_Status",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Last System Reboot Time",
        field: "Last_System_Reboot_Time",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    }, {
        title: "Last Registration Time",
        field: "Last_Registration_Time",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    }, {
        title: "Precise Location",
        field: "Precise_Location",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    }, {
        title: "Pending Image Count",
        field: "Pending_Image_Count",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    }, {
        title: "Log Out Push Received Time",
        field: "Log_Out_Push_Received_Time ",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    }, {
        title: "Reset Customers Push Sent Time",
        field: "Reset_Customers_Push_Sent_Time",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    }, {
        title: "Reset HOS Labels Push Sent Time",
        field: "Reset_HOS_Labels_Push_Sent_Time",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    }, {
        title: `Reset ${taskLabel} Push Sent Time`,
        field: "Reset_Task_Push_Sent_Time",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    }, {
        title: "Reset Customers Push Received Time",
        field: "Reset_Customers_Push_Received_Time",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    }, {
        title: "Reset HOS Labels Push Received Time",
        field: "Reset_HOS_Labels_Push_Received_Time",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    }, {
        title: `Reset ${taskLabel} Push Received Time`,
        field: "Reset_Task_Push_Received_Time",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
]


export const DeviceListReportColumnName = [
    {
        title: `AccountID`,
        field: "AccountID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Phone Number",
        field: "Phone_Number",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Description",
        field: "Description",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `${groupLabel}`,
        field: "Groups",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Email-ID",
        field: "Email-ID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "App Version",
        field: "App_Version",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Manufacture",
        field: "Manufacture",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "Model",
        field: "Model",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },

    {
        title: "Software Version",
        field: "Software_Version",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "Device Status",
        field: "Device_Status",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Created On",
        field: "Created_On",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    }, {
        title: "Last Deleted On",
        field: "Last_Deleted_On",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    }, {
        title: "First Registration",
        field: "First_Registration",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    }, {
        title: "Activity (over last 90 days)",
        field: "Activity",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    }, {
        title: "Carrier",
        field: "Carrier",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    }, {
        title: `${employeeLabel}-ID`,
        field: "Employee",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Company Code",
        field: "Company_Code",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Company Owned",
        field: "Company_Owned",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Device Type",
        field: "Device_Type",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    }, {
        title: "TimeZone",
        field: "TimeZone",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Phone Prefix",
        field: "Phone_Prefix",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
]


export const HEART_BEAT_REPORT_DROPDOWN = [
    { label: "Latest", value: "true" },
    { label: "History", value: "false" }
]

export const HEART_BEAT_REPORT_DESCRIPTION = `DeviceID,SOS DateTime,Last App TerminationTime,Agent Version,GPS,Manufature,Model,Trip Number,Tracking Frequency,Battery,Software Version,UUID,WIFI,CellNetwork,Location Spoofing,Location Permission,Location Permission Type,Camera Permission,DozeMode,Device GUID,Entry Count,Label Count,App Foreground,App Background,Current HOS Stage,App Logout Status,App Background Refresh Status,Last System Reboot Time,Last Registration Time,Precise Location,Pending Image Count,Log Out Push Received Time,Reset Customers Push Sent Time,Reset HOS Labels Push Sent Time,Reset ${taskLabel} Push Sent Time,Reset Customers Push Received Time,Reset HOS Labels Push Received Time,Reset ${taskLabel} Push Received Time`
export const DEVICE_LIST_REPORT_DESCRIPTION = `AccountID,Phone Number,Description,${groupLabel},Email-ID,App Version,Manufacture,Model,	Software Version,Device Status,Created On,Last Deleted On,First Registration,Activity (over last 90 days),Carrier, ${employeeLabel}-ID,Company Code,Company Owned,Device Type,TimeZone,Phone Prefix`