import { apiProvider } from "../../../../services/api/provider";

export const getMilesData = async (setCurrentTimeAndDateMiles, setMilesData,milesPaginateMetaData) => {
  const childAccountID = localStorage.getItem("childAccountId");

  try {
      const resp = await apiProvider.getAll(
          `track/react/v1/allgeo/monitor/milesdata?pageNo=${milesPaginateMetaData?.page}&pageSize=${milesPaginateMetaData?.perPage}&sortBy=employeeName&sortOrder=asc&isAllAccount=${childAccountID === "all"}&paginationFlag=${milesPaginateMetaData?.paginationFlag}`
      );
     
      setMilesData(resp.data)
      setCurrentTimeAndDateMiles(new Date())

  } catch (err) {
      console.error("Error fetching Miles Data", err);
      throw err;
  }
}

