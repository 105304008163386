import { apiProvider } from "../../../../services/api/provider";
import { handleFetchEmployeeAppData } from "../handleFetchEmployeeAppData";

export const handleSuspendEmployee = async (
  selectedDeleteItem,
  closeDeletePopup,
  employeePaginateMetaData,
  setEmployeeData,
  setShowLoader,
  setToastMsg,
  setCurrentTimeAndDate,
  setSelectedDeleteItem
) => {
  try {
    setShowLoader(true);

    let response;
    response = await apiProvider.put(
      `track/react/v1/allgeo/monitor/updateDeviceStatus?status=false`,
      selectedDeleteItem?.employeeID
        ? [selectedDeleteItem?.employeeID]
        : selectedDeleteItem
    );

    setSelectedDeleteItem([]);

    setToastMsg({
      msg: response?.message,
      type: "msgSuccess",
    });
    handleFetchEmployeeAppData(
      employeePaginateMetaData,
      setEmployeeData,
      setShowLoader,
      setCurrentTimeAndDate
    );
    return response;
  } catch (error) {
    console.log(error);
    setToastMsg({
      msg: error.message,
      type: "msgError",
    });
  } finally {
    setShowLoader(false);
    closeDeletePopup();
  }

  setDeletePopup({ open: false, case: "single" });
};
