import React, { useState } from "react";
import { DatePicker, TimePicker } from "@progress/kendo-react-dateinputs";
import "./breadcrumb.css";
import DownArrow from "../../../../assets/images/downarrow.svg";
import MapView from "../../../../assets/images/map-view.png";

import { ExpandViewPopup } from "./ExpandViewPopup";
import useGeneralSettings from "../../../../hooks/useGeneralSettings";
import { timeZones } from "../../../../components/common/utils";
import { getDefaultDateTimeForRecurringSchedule } from "../../../../components/monitor/LocateSchedulers/locateScheduleUtils";

export const Breadcrumb = ({
  allEmployeeList,
  setBreadcrumbData,
  breadcrumbData,
}) => {
  const { timezone } = useGeneralSettings();
  const moment = window.moment;


  let optionList = allEmployeeList.map((emp) => {
    return {
      phoneNumber: emp?.phoneNumber,
      employeeName: emp?.employeeName,
      employeeId: emp?.employeeId,
    };
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBreadcrumbData({
      ...breadcrumbData,
      [name]: value,
    });
  };


  let iFrameUrl = `${
    process.env.REACT_APP_API_URL
  }/track/mgt?page=manageteaminfoV1&datepicker_from1=${moment(breadcrumbData?.datepicker_from1).format(
    "YYYY/MM/DD"
  )}&timepicker_from=${moment(breadcrumbData.timepicker_from).format(
    "HH:mm:ss"
  )}&datepicker_to1=${moment(breadcrumbData.datepicker_to1).format(
    "YYYY/MM/DD"
  )}&timepicker_to=${moment(breadcrumbData.timepicker_to).format(
    "HH:mm:ss"
  )}&date_tz=${encodeURIComponent(breadcrumbData.date_tz)}&showLocationMap=yes`;

  
  return (
    <div>
      <div>
        <img src={MapView} alt="" />
      </div>
      <div className="inputParentBox">
        <div className="inputBox">
          <label>Name and Phone No.</label>
          <select
            name="device"
            onChange={(e) => handleChange(e)}
            placeholder="Select"
            value={breadcrumbData.device}
          >
            {optionList?.map((option, i) => (
              <option
                value={option?.employeeId}
                key={i}
              >{`${option?.employeeName} - ${option?.phoneNumber}`}</option>
            ))}
          </select>
          <img src={DownArrow} className="arrowicon3" />
        </div>
        <div className="inputBox">
          <label>Time Zone</label>
          <select name="date_tz" onChange={(e) => handleChange(e)}>
            <option selected value={timezone}>
              {timezone}
            </option>
            {timeZones
              ?.filter(
                (t) =>
                  t.value &&
                  t.value.toLowerCase() !== (timezone || "").toLowerCase()
              )
              .map((t, ind) => (
                <option key={ind} value={t.value}>
                  {t.label}
                </option>
              ))}
          </select>
          <img src={DownArrow} className="arrowicon3" />
        </div>
      </div>
      <div className="inputParentBox">
        <div className="inputBox">
          <label>Date Range (From)</label>
          <div className="subinputBox">
            <DatePicker
              onChange={(e) => handleChange(e)}
              value={breadcrumbData?.datepicker_from1}
              name="datepicker_from1"
              defaultValue={new Date()}
              format="dd/MM/yyyy"
            />
            <TimePicker
              onChange={(e) => handleChange(e)}
              value={breadcrumbData?.timepicker_from}
              name="timepicker_from"
              format="HH:mm"
              defaultValue={new Date("2023/01/01 00:00:00")}
              placeholder="00:00:00"
            />
          </div>
        </div>
        <div className="inputBox">
          <label>Date Range (To)</label>
          <div className="subinputBox">
            <DatePicker
              onChange={(e) => handleChange(e)}
              value={breadcrumbData?.datepicker_to1}
              name="datepicker_to1"
              defaultValue={new Date()}
              format="dd/MM/yyyy"
            />
            <TimePicker
              onChange={(e) => handleChange(e)}
              value={breadcrumbData?.timepicker_to}
              name="timepicker_to"
              format={"HH:mm"}
              defaultValue={new Date("2023/01/01 23:59:00")}
              placeholder="00:00:00"
            />
          </div>
        </div>
      </div>
      <button
        className="custombtn"
        onClick={() => {
          const form = document.getElementById("map_input_sub_form");
          form.phoneList.value = breadcrumbData.device;
          form.date_tz.value = breadcrumbData.date_tz;
          form.datepicker_from1.value = moment(
            breadcrumbData?.datepicker_from1
          ).format("YYYY/MM/DD");
          form.timepicker_from.value = moment(
            breadcrumbData.timepicker_from
          ).format("HH:mm:ss");
          form.datepicker_to1.value = moment(
            breadcrumbData.datepicker_to1
          ).format("YYYY/MM/DD");
          form.timepicker_to.value = moment(
            breadcrumbData.timepicker_to
          ).format("HH:mm:ss");
          form.showLocationMap.value = "yes";
          form.submit();
          console.log(document.getElementById("map_input_sub_form"),"test=========>")
        }}
      >
        Get Breadcrumb
      </button>
      <form
        id="map_input_sub_form"
        method="post"
        target="_blank"
        action={iFrameUrl}
        onSubmit={(e) => e.preventDefault()}
      >
        <input type="hidden" name="phoneList" value="" />
        <input type="hidden" name="datepicker_from1" value="" />
        <input type="hidden" name="timepicker_from" value="" />
        <input type="hidden" name="datepicker_to1" value="" />
        <input type="hidden" name="timepicker_to" value="" />
        <input type="hidden" name="date_tz" value="" />
        <input type="hidden" name="showLocationMap" value="" />
      </form>
    </div>
  );
};
