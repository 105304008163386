import { apiProvider } from "../../../../../services/api/provider";

export async function deleteItems({ deletedItems, setbaseData, setShowLoader, getBaseData, setToastMsg }) {
    for (const deletedItem of deletedItems) {
        // Code to just change field of taskID and ownerID
        const formattedDeleteItem = {
            ...deletedItem,
            TaskID: deletedItem.TaskId,
            OwnerID: deletedItem.OwnerId
        };

        // Remove the original keys from the new object
        delete formattedDeleteItem.TaskId;
        delete formattedDeleteItem.OwnerId;
        try {
            const response = await apiProvider.post(
                'track/react/v1/allgeo/deleteMasterWorkorder?deletionType=recurring',
                [formattedDeleteItem]
            );
            if (response.status === 200) {
                await getBaseData({ setbaseData, setShowLoader });
                setToastMsg({ msg: response.message, type: "msgSuccess" })
            } else {
                setToastMsg({ msg: response.message, type: "msgError" })
            }
        } catch (error) {
            console.error("Error deleting calendar work order:", error);
        }
    }
}

export async function masterDelete({ updatedItems, setbaseData, setShowLoader, getBaseData, setToastMsg }) {
    for (const deletedItem of updatedItems) {
        //code to just change field of taskID and ownerID
        const formatedDeleteItem = {
            ...deletedItem,
            TaskID: deletedItem.TaskId,
            OwnerID: deletedItem.OwnerId
        };

        // Remove the original keys from the new object
        delete formatedDeleteItem.TaskId;
        delete formatedDeleteItem.OwnerId;
        try {
            const response = await apiProvider.post(
                `track/react/v1/allgeo/deleteMasterWorkorder?deletionType=current&inactiveWorkorderIds=${deletedItem.TaskId}`,
                [formatedDeleteItem]
            );
            if (response.status === 200) {
                await getBaseData({ setbaseData, setShowLoader }); // Refresh data after delete if needed
                setToastMsg({ msg: response.message, type: "msgSuccess" })
            } else {
                setToastMsg({ msg: response.message, type: "msgError" })
            }
        } catch (error) {
            console.error("Error deleting calendar work order:", error);
        }
    }
}

export async function updateWO({ updatedItems, setbaseData, setShowLoader, getBaseData, setToastMsg }) {
    for (const updatedItem of updatedItems) {
        try {
            const response = await apiProvider.post(
                "/track/react/v1/allgeo/upsertCalendarWorkorder?action=update",
                [updatedItem]
            );
            if (response.status === 200) {
                await getBaseData({ setbaseData, setShowLoader }); // Refresh data after update if needed
                setToastMsg({ msg: response.message, type: "msgSuccess" })
            } else {
                setToastMsg({ msg: response.message, type: "msgError" })
            }
        } catch (error) {
            console.error("Error updating calendar work order:", error);
        }
    }
}