import React, { useState, useEffect } from 'react'
import { useDocumentTitle } from '../../../hooks/setDocumentTitle'
import AllGeoLogo from '../../../assets/images/allGeo-logo.svg'
import Map from '../../common/map'
import { unitConversionRate, getLatLngList, SELECTED_JOBSITES_KEY } from './jobSitesUtils'
import { apiProvider } from '../../../services/api/provider'

const MapView = () => {
    useDocumentTitle("MapView - allGeo")

    const [circleCoordinates, setCircleCoordinates] = useState([]);
    const [polygonCoordinates, setPolygonCoordinates] = useState([]);
    const [isDataReady, setIsDataReady] = useState(false)
    const [arrayOfAllCoordinates, setArrayOfAllCoordinates] = useState([])

    const getJobSiteList = async () => {
        try {
            const { selectedJobSites, activeList } = JSON.parse(sessionStorage.getItem(SELECTED_JOBSITES_KEY))
  
            const response = await apiProvider.getAll(`/track/mobile/v1/allgeo/getCustomers?active=${activeList}`)
            
            const circle = []
            const polygon = []
            const circlesCoords = []
            const polygonCoords = []

            response.data.forEach(site => {
                if (selectedJobSites.includes(site.txnId)) {

                    if (site.geozoneType === "Circle") {

                        const siteData = {
                            latlanList: getLatLngList(site.geozone.latlanList),
                            radiusUnit: site.geozone.radiusUnit,
                            geozoneID: site.geozoneID,
                            zoneRadius: parseInt(site.geozone.zoneRadius) * unitConversionRate[site.geozone.radiusUnit.toLowerCase()],
                            address: site.address,
                            custName: site.name
                        }
                        circlesCoords.push({latLng: siteData.latlanList})
                        circle.push(siteData)
                    } else {

                        const siteData = {
                            latlanList: getLatLngList(site.geozone.latlanList),
                            geozoneID: site.geozoneID,
                            address: site.address,
                            custName: site.name
                        }
                        polygonCoords.push({latLng: siteData.latlanList})
                        polygon.push(siteData)
                    }
                }
            })
    
            const coordArr = []
    
            circlesCoords.forEach(c => {
                coordArr.push(c.latLng[0])
            })
    
            polygonCoords.forEach(p => {
                p.latLng.forEach(coord => {
                    coordArr.push(coord)
                })
            })

            setArrayOfAllCoordinates(coordArr)
            setCircleCoordinates(circle)
            setPolygonCoordinates(polygon)
            setIsDataReady(true)
            sessionStorage.removeItem(SELECTED_JOBSITES_KEY)

        } catch (error) {
            console.log(error);
        }
    }

    const getZoneRadius = () => {
        if (circleCoordinates.length === 1) {
            const jobSiteData = circleCoordinates[0]
            return parseInt(jobSiteData.zoneRadius) * unitConversionRate[jobSiteData.radiusUnit.toLowerCase()]
        }
        return undefined
    }

    useEffect(() => {
        getJobSiteList()
    }, [])

    return (
        <div className="extWindowWrap">
            <h1 className="siteLogo"><a href="/company/employees"><img src={AllGeoLogo} alt="allGeo" /></a></h1>
            <div className="viewMap">
                {
                    !isDataReady
                        ?
                        <div>Loading...</div>
                        :
                        <Map
                            mode={"mapView"}
                            latLngArray={arrayOfAllCoordinates}
                            mapHeight={"100%"}
                            multipleCirclesLatLngArray={circleCoordinates}
                            multiplePolygonLatLngArray={polygonCoordinates}
                            zoneRadius={getZoneRadius()}
                        />
                }
            </div>
        </div>
    )
}

export default MapView