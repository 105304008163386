export const schedulerDataTransformer = (props) => {
    const transformedData = props.baseData.map((item) => ({
      start: new Date(item.Start),
      end: new Date(item.End),
      taskId: item.TaskID,
      ownerId: item.OwnerID,
      title: item.Title,
      description: item.Description,
      recurrenceRule: item.RecurrenceRule,
      recurrenceException: item.RecurrenceException,
      recurrenceID: item.RecurrenceID,
      isAllDay: item.IsAllDay,
      status: item.Status,
      siteName: item.SiteName,
      jobName: item.JobName,
      groupName: item.GroupName,
      deviceName: item.DeviceName,
      masterTripNo: item.MasterTripNo,
      locatable: item.Locatable,
      billingRate: item.BillingRate,
      personId: item.OwnerID,
      id: item.TaskID,
    }));
    return transformedData
  }