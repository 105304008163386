import { apiProvider } from "../../../../services/api/provider";

export const handleFetchEmployeeAppData = async (
  employeePaginateMetaData,
  setEmployeeData,
  setShowLoader,
  setCurrentTimeAndDate,
  updateBothcardData=false,
  setEmployeeTilesData,
) => {
  setShowLoader(true);
  try {
    setCurrentTimeAndDate(new Date());
    const response = await apiProvider.post(
      `track/react/v1/allgeo/monitor/EmployeeData?pageNo=${employeePaginateMetaData?.pageNo}&pageSize=${employeePaginateMetaData?.pageSize}&sortBy=employeeName&sortOrder=asc&paginationFlag=${employeePaginateMetaData?.expandView}`,
      { ...employeePaginateMetaData.queryParams }
    );

    setEmployeeData(response?.data);
    !!updateBothcardData&&setEmployeeTilesData(response?.data)
  } catch (error) {
    console.error("Error fetching employee app data:", error);
  } finally {
    setShowLoader(false);
  }
};
