import React, {useState, useEffect} from 'react';
import { Tooltip } from "@progress/kendo-react-tooltip"
import { getAccountLabel } from "../../../utils";


const employeeLabel = getAccountLabel('employee');
const groupLabel = getAccountLabel('group');
const taskLabel = getAccountLabel('task')
const jobsiteLabel = getAccountLabel('jobsite');



export const titleMap = {
    "devicedesc": `${employeeLabel} Name and ${groupLabel}`,
    "deviceId": `${employeeLabel} Number`
}

const CustomHeaderCell = ({ title, tooltipText, field }) => {

    const [employeeLabel, setEmployeeLabel] = useState(getAccountLabel('employee'));
    const [groupLabel, setGroupLabel] = useState(getAccountLabel('group'));

    useEffect(() => {
        setEmployeeLabel(getAccountLabel('employee'));
        setGroupLabel(getAccountLabel('group'));
    }, []);

    let customTitle = titleMap[field] || title;

    const getToolTipText = () => {
        if(field === 'devicedesc') return `This column displays the ${employeeLabel} name and the ${groupLabel} he/she is assigned to. Click on the name to edit.`
        if(field === 'lastLocation') return `This column displays the last reported location of the ${employeeLabel}. Click on the address to open the same in Map view`
        if(field === 'jobStatus') return `This column displays the current Job-Status of the ${employeeLabel}`
        if(field === 'deviceId') return `This column displays the ${employeeLabel}'s phone number. Right click to display the current device details.(e.g. Battery Level, GPS/WiFi status, etc.)`
        else return tooltipText
    }

    const toolTipText = getToolTipText();
    return (
        <div className="displayFlex">
            <div>
                {customTitle}
            </div>
            <Tooltip position='top'>
                <span title={toolTipText} style={{ top: 0 }} className="helpTip">?</span>
            </Tooltip>
        </div>
    )
}



export const monitorEmployeesColumns = [
    {
        title: `${employeeLabel} Name and ${groupLabel}`,
        field: "devicedesc",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true,
        headerCell: <CustomHeaderCell
            title={`${employeeLabel} Name and ${groupLabel}`}
            tooltipText={`This column displays the ${employeeLabel} name and the ${groupLabel} he/she is assigned to. Click on the name to edit.`}
            field={"devicedesc"}
        />
    },
    {
        title: `${employeeLabel} Number`,
        field: "deviceId",
        editable: false,
        width: 150,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true,
        headerCell: <CustomHeaderCell
            title={`${employeeLabel} Number`}
            tooltipText={`This column displays the ${employeeLabel}'s phone number. Right click to display the current device details.(e.g. Battery Level, GPS/WiFi status, etc.)`}
            field={"deviceId"}
        />
    },
    {
        title: "Device Status",
        field: "deviceStatusTimeStamp",
        editable: false,
        width: 150,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "date",
        customCell: true,
        headerCell: <CustomHeaderCell
            title={"Device Status"}
            tooltipText={"This Column displays current device & schedule status. You can hover for troubleshooting tips."}
            field={"deviceStatus"}
        />
    },
    {
        title: "Location Tracking",
        field: "scheduleStatus",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "Last Location",
        field: "lastLocationTimeStamp",
        editable: false,
        width: 230,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "date",
        customCell: true,
        headerCell: <CustomHeaderCell
            title={"Last Location"}
            tooltipText={`This column displays the last reported location of the ${employeeLabel}. Click on the address to open the same in Map view`}
            field={"lastLocation"}
        />
    },
    {
        title: "Job Status",
        field: "jobStatus",
        editable: false,
        width: 230,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true,
        headerCell: <CustomHeaderCell
            title={"Job Status"}
            tooltipText={`This column displays the current Job-Status of the ${employeeLabel}`}
            field={"jobStatus"}
            
        />
    },
]

export const timeClockReportsColumn = [
    {
        title: `${employeeLabel} Name`,
        field: "employeeName",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true,
        // headerCell: <CustomHeaderCell
        //     title={`${employeeLabel} Name and ${groupLabel}`}
        //     tooltipText={`This column displays the ${employeeLabel} name and the ${groupLabel} he/she is assigned to. Click on the name to edit.`}
        //     field={"employeeName"}
        // />
    },
    {
        title: `${employeeLabel} Id`,
        field: "employeeID",
        editable: false,
        width: 150,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true,
    },
    {
        title: "Clock In Time",
        field: "clockInTime",
        editable: false,
        width: 250,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "date",
        customCell: true,
    },
    {
        title: "Clock Out Time",
        field: "clockOutTime",
        editable: false,
        width: 250,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "date",
        customCell: true,
        
        
    },
    {
        title: `${jobsiteLabel}`,
        field: "jobSiteName",
        editable: false,
        width: 250,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true,
        
    },
    {
        title:`${taskLabel} Name`,
        field: "taskName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true,
        
    },
    {
        title: "Total Hours Worked",
        field: "totalWorkingHours",
        editable: false,
        width: 150,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true,
        
    },
    {
        title: "Miles",
        field: "miles",
        editable: false,
        width: 150,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true,
        
    },
]