import React, { useRef, useState, useEffect, Fragment } from 'react';
import Header from '../../layout/Header';
import KendoGrid from '../../common/kendo/KendoGrid';
import { useDocumentTitle } from '../../../hooks/setDocumentTitle';
import { getAccessLevel } from '../../common/functions';
import { apiProvider } from '../../../services/api/provider';
import { taskColumns } from './tasksUtils'
import AddNewPopup from './AddNewPopup';
import ViewTask from './ViewTask';
import EditTask from './EditTask';
import SiteLoader from '../../common/SiteLoader';
import ToastMessage from '../../common/ToastMessage';
import DeletePopup from "../../common/PopUp/DeletePopup";
import FileUploadFail from '../../common/FileUploadFail';
import { getAccountLabel } from '../../../utils';
import { G2ReviewPopup } from '../../common/G2ReviewPopup/G2ReviewPopup';
import { ALLOWED_ACCOUNTS_FOR_G2_REVIEW } from '../../common/G2ReviewPopup/G2Utils/constants';

const Tasks = () => {
    const taskLabel = getAccountLabel('task');
    useDocumentTitle(`${taskLabel} - allGeo`)


    const [mode, setMode] = useState({
        title: `Manage ${taskLabel}`,
        type: 'main',
        data: null,
        hasPrev: false
    });

    const [accessLevel, setAccessLevel] = useState({});
    const [showAddNewPopup, setShowAddNewPopup] = useState(false)
    const [showLoader, setShowLoader] = useState(false);
    const [toastMsg, setToastMsg] = useState({
        msg: "",
        type: "msgSuccess"
    });

    const [tasks, setTasks] = useState(null)
    const [activeList, setActiveList] = useState(true)
    const [payerData, setPayerData] = useState([])

    const childRef = useRef()

    const getTaskList = async () => {

        try {
            setShowLoader(true)
            const response = await apiProvider.getAll('/track/mobile/v1/allgeo/task?active=true')
            if (response.status === 200) {
                setTasks(response.tasks)
            }
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    const updateTasks = async data => {
        try {
            localStorage.removeItem('formIsDirty')
            const response = await apiProvider.put('/track/mobile/v1/allgeo/task', data)
            if (response.status === 200) {
                setToastMsg({
                    msg: `${taskLabel} has been added successfully`,
                    type: "msgSuccess"
                })
                setTimeout(() => {
                    setShowG2Popup(true)
                }, 2000)
                closePopup()
            } else {
                setToastMsg({
                    msg: response.message,
                    type: "msgError"
                })
            }
        } catch (error) {
            console.log(error);
        }
    }

    const deleteTasks = async data => {

        const payload = data.length ? data : [data.ID]

        try {
            setShowLoader(true)
            const response = await apiProvider.removeObject('/track/mobile/v1/allgeo/task', payload)
            if (data.length)
                response.message = "The selected records have been deleted successfully";
            else
                response.message = `${data.name} is deleted`

            return response;
        } catch (error) {
            console.log(error);
            throw new Error(error)
        } finally {
            setShowLoader(false)
        }
    }

    const fetchPayerData = () => {
        apiProvider.getAll('track/mobile/v1/allgeo/evv/payer').then((response) => {
            setPayerData([{ payerID: 'Select', description: 'Select' }, ...response])
        }).catch((error) => {
            console.log(error);
        });
    };

    const actionHandler = (type, data) => {
        switch (type) {
            case "view":
                setShowLoader(true)
                setMode({
                    title: "Manage Tasks",
                    type: 'view',
                    data: data,
                    hasPrev: false
                })
                break;
            case "edit":
                setShowLoader(true)
                setMode({
                    title: "Manage Tasks",
                    type: 'edit',
                    data: data,
                    hasPrev: false
                })
                break;
            default:
                break;
        }
    }

    const openPopup = (type, _data) => {
        switch (type) {
            case "addNew":
                setShowAddNewPopup(true)
                break;
            case "view":
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        getAccessLevel("company", "tasks").then((response) => {
            setAccessLevel(response);
        });
        getTaskList()
        fetchPayerData()
    }, []);

    useEffect(() => {
        if (toastMsg.msg && toastMsg.type === "msgSuccess") {
            if (childRef.current) {
                childRef.current.refreshData()
            }
            setShowLoader(false)
        }
    }, [toastMsg])
    const [showG2Popup, setShowG2Popup] = useState(false);

    const sysAdminG2Enbled = JSON.parse(localStorage.getItem('sysAdminG2Enabled'));
    const storedItem = localStorage.getItem('hasG2Reviewed');
    const hasG2Reviewed = (storedItem !== 'undefined' && storedItem !== null) ? JSON.parse(storedItem) : null;
    let isAdminLogin = JSON.parse(localStorage.getItem('isAdminLogin'));
    const doNotShowG2 = JSON.parse(localStorage.getItem('g2ReviewInactive'));
    const showG2Review = !isAdminLogin && !hasG2Reviewed && !doNotShowG2 && showG2Popup && sysAdminG2Enbled;


    return (
        <Fragment>
            <div className='mainContentWrap'>
                <Header title={mode.title} />
                {showG2Review && <G2ReviewPopup showPopup={showG2Popup} setShowPopup={setShowG2Popup} />}
                <div className='mainContent' id='mainContent'>
                    <Fragment>
                        {
                            tasks &&
                            <KendoGrid
                                gridData={tasks}
                                setGridData={setTasks}
                                columnData={taskColumns}
                                accessLevel={accessLevel}
                                ref={childRef}
                                module={"tasks"}
                                getAPiUrl={'/track/mobile/v1/allgeo/task'}
                                requestParams={{ active: activeList }}
                                openPopup={openPopup}
                                updateItem={updateTasks}
                                deleteItem={deleteTasks}
                                lockActionColumn={true}
                                actionColumnHandler={actionHandler}
                                bulkDelete={deleteTasks}
                                otherData={{
                                    dropDownData: activeList,
                                    setDropDownData: setActiveList,
                                    exportFileName: taskLabel
                                }}
                            />
                        }
                        {showAddNewPopup && <AddNewPopup setShowG2Popup={setShowG2Popup} setMode={setMode} setShowLoader={setShowLoader} setToastMsg={setToastMsg} closePopup={() => setShowAddNewPopup(false)} payerData={payerData} />}
                        {mode.type === 'view' && <ViewTask taskId={mode.data.ID} setShowLoader={setShowLoader} setMode={setMode} mode={mode} accessLevel={accessLevel.childMenu} payerData={payerData} />}
                        {mode.type === 'edit' && <EditTask taskId={mode.data.ID} setShowLoader={setShowLoader} setMode={setMode} setToastMsg={setToastMsg} payerData={payerData} />}
                        {mode.type === 'uploadError' &&
                            <FileUploadFail data={mode.data} closePopup={() => setMode({ ...mode, type: 'main', data: null, hasPrev: false })} setToastMsg={setToastMsg} setShowLoader={setShowLoader} />
                        }
                    </Fragment>
                </div>
            </div>
            {mode.type === 'delete' &&
                <DeletePopup
                    onSuccess={async () => {
                        try {
                            const res = await deleteTasks(mode.data)
                            if (res.status === 200) {
                                setMode({
                                    title: "Manage Tasks",
                                    type: 'main',
                                    data: null,
                                    hasPrev: false
                                })
                                setToastMsg({
                                    msg: res.message,
                                    type: "msgSuccess"
                                })
                            }
                        } catch (err) {

                        }
                    }}
                    closePopup={() => setMode({
                        title: "Manage Tasks",
                        type: 'main',
                        data: null,
                        hasPrev: false
                    })}
                />}
            {toastMsg.msg && <ToastMessage
                cssClass={toastMsg.type}
                message={toastMsg.msg}
                onClose={() => setToastMsg({ msg: "", type: "" })}
            />}
            <div className={`overlay ${(showAddNewPopup || mode.type !== 'main' || mode.type === 'uploadError') ? 'active' : ''}`} />
            <SiteLoader isActive={showLoader} />
        </Fragment>
    )
}

export default Tasks