import React from "react";

export const JobsitesAuthorizationSettings = ({setManagersDetails, changeJobsitesAuthList, setJobsitesAuthorization, jobsitesAuthorization, managerDetails}) => {
    const changeCustomerList = (index) => {
        // Clone the managerDetails object to avoid mutating the original state
        const updatedManagerDetails = { ...managerDetails };
        const newCustomer = managerDetails?.customerList[index];
        const newCustomerID = newCustomer.customerID;
        const newCustomerIndex = updatedManagerDetails.authorizedCustomers.findIndex(
          (ele) => ele === newCustomerID
        );
      
        if (newCustomerIndex === -1) {
          // If the customer is not already authorized, add them
          updatedManagerDetails.authorizedCustomers = [
            ...updatedManagerDetails.authorizedCustomers,
            newCustomerID,
          ].filter((ele) => ele !== "all");
          updatedManagerDetails.isChanged = true;
        } else {
          // If the customer is already authorized, remove them
          updatedManagerDetails.authorizedCustomers = updatedManagerDetails.authorizedCustomers
            .slice(0, newCustomerIndex)
            .concat(
              updatedManagerDetails.authorizedCustomers.slice(newCustomerIndex + 1)
            );
          updatedManagerDetails.isChanged = true;
        }
      
        // Update the managerDetails state
        setManagersDetails(updatedManagerDetails);
      };
      
      
  
    return (
        <>
          <h3>Jobsites Authorization Settings</h3>
          <div className="supportedTxt">
            Select the jobsites that the manager is authorized to supervise here.
          </div>
          <div className="innerWrap">
            <div className="fldWrap">
              <label>Select Jobsites</label>
              <div className="customControls capsules">
                <div className="customRadio">
                  <input
                    type="radio"
                    id="jobsites-all"
                    name="jobsitesAuthorization" 
                    key='customer-all'
                    checked={jobsitesAuthorization === "all"}
                    onClick={() => {
                      if (jobsitesAuthorization !== "all") {
                        changeJobsitesAuthList();
                      }
                    }}
                  />
                  <label
                    htmlFor="jobsites-all"
                    id="jobsites-all"
                    key="jobsites-all"
                  >
                    All
                  </label>
                </div>
                <div className="customRadio">
                  <input
                    type="radio"
                    id="customer-specific"
                    name="jobsitesAuthorization" 
                    checked={jobsitesAuthorization !== "all"}
                    key='customer-specific'
                    onClick={() => {
                      if (jobsitesAuthorization !== "specific") {
                        setJobsitesAuthorization("specific");
                      }
                    }}
                  />
                  <label
                    htmlFor="customer-specific"
                    id="jobsites-specific"
                    key="jobsites-specific"
                  >
                    Specific
                  </label>
                </div>
              </div>
            </div>
            {jobsitesAuthorization !== "all" && (
              <ul className="blockCheckbox">
                {managerDetails?.customerList &&
                  managerDetails?.customerList.map((ele, ind) =>
                    ele.customerID !== "all" ? (
                      <li key={ele.customerID}>
                        <div className="customCheck">
                          <input
                            type="checkbox"
                            id={`cust${ind}`}
                            checked={managerDetails?.authorizedCustomers?.includes(
                              ele.customerID
                            )}
                            onChange={(e) => changeCustomerList(ind)}
                          />
                          <label
                            className="customCheckLabel"
                            htmlFor={`cust${ind}`}
                          >
                            <span>
                              <em
                                className="customerColor"
                                style={{ background: ele.colour }}
                              >
                              </em>
                              {ele.customerName === undefined ||
                              ele.customerName === ""
                                ? ele.customerID
                                : ele.customerName}
                            </span>
                          </label>
                        </div>
                      </li>
                    ) : null
                  )}
              </ul>
            )}
          </div>
        </>
      );
      
};
