import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useDocumentTitle } from '../../../hooks/setDocumentTitle';
import { apiProvider } from '../../../services/api/provider';
import SiteLoader from '../../common/SiteLoader';
import { getAccessLevel } from '../../common/functions';
import { formatDate, reportSchedulerColumns, reportTypes } from './reportSchedulerUtils';
import KendoGrid from '../../common/kendo/KendoGrid';
import AddNewPopup from './AddReport/AddNewPopup';
import ViewReportcheduler from './ViewReportScheduler';
import EditReportScheduler from './EditReport/EditReportScheduler';
import ToastMessage from '../../common/ToastMessage';
import DeletePopup from "../../common/PopUp/DeletePopup";

const ReportScheduler = () => {

  useDocumentTitle('Reports - allGeo')

  const [mode, setMode] = useState({
    title: "Report Scheduler",
    type: 'main',
    data: null,
    hasPrev: false
  });
  const [accessLevel, setAccessLevel] = useState({});
  const [showAddNewPopup, setShowAddNewPopup] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [toastMsg, setToastMsg] = useState({
    msg: "",
    type: "msgSuccess"
  });
  const [reportList, setReportList] = useState(null)
  const [activeList, setActiveList] = useState("active")
  const [accountInfo, setAccountInfo] = useState({});
  const [reportForData, setReportForData] = useState({});
  const [reports, setReports] = useState({})

  const childRef = useRef()

  const getReportData = async () => {
    try {
      setShowLoader(true)
      const response = await apiProvider.getAll('/track/mobile/v1/allgeo/schedule?type=Report')

      if (response.status === 200) {
        setReportList(response.data.map(d => {
          return {
            ...d,
            active: d.active ? "Active" : "Inactive",
            nextRunDateTime: d.nextRunDateTime === "" ? "" : formatDate(d.nextRunDateTime, `${(response.dateFormat).replace(/[d]/gi, "D")} ${(response.timeFormat).replace(/[a]/gi, "A")}`)
          }
        }))
        setAccountInfo({
          'timezone': response.timezone,
          'dateFormat': response.dateFormat,
          'timeFormat': response.timeFormat
        })
      }

    } catch (error) {
      console.log(error);
    } finally {
      setShowLoader(false)
    }
  }

  const getReportTypes = async () => {
    try {
      const response = await apiProvider.getAll('track/mobile/v1/allgeo/getReports')

      const customReportMapArr = Object.keys(response.custom).map(key => ({
        text: response.custom[key],
        id: key
      }))

      const standardReportMapArr = Object.keys(response.standard).map(key => ({
        text: response.standard[key],
        id: key
      }))

      setReports({
        [reportTypes.CUSTOM]: customReportMapArr,
        [reportTypes.STANDARD]: standardReportMapArr
      })
    } catch (error) {
      console.log(error);
    }
  }

  const getReportForData = async () => {
    try {
      const response = await Promise.all([getGroupsData(), getEmployeesData()])
      setReportForData({ groups: response[0].groups, employees: response[1].employees })
    } catch (error) {
      console.log(error);
    }
  }

  const getGroupsData = async () => {
    try {
      const grpResult = await apiProvider.getAll('track/mobile/v1/allgeo/getGroups?includeAll=true')
      let grp = (grpResult.groups).sort((a, b) => (a.groupDesc).toLowerCase() > (b.groupDesc).toLowerCase() ? 1 : -1)
        .filter(grp => grp.groupDesc)
        .map(grp => ({ label: grp.groupDesc === 'all' ? 'All' : grp.groupDesc, value: grp.groupID }))
        ;
      return { ...grpResult, groups: grp }
    } catch (error) {
      console.log(error);
    }
  }

  const getEmployeesData = async () => {
    try {
      const empResult = await apiProvider.getAll('track/mobile/v1/allgeo/devices?includeAll=true&operationType=mobile')
      let emp = (empResult.employees)
        .filter((ele, index) => {
          if (index > 0) {
            return (ele?.isActive).toLowerCase() === 'active'
          } else {
            return ele
          }
        })
        .filter(ele => ele.name.trim().length > 0)
        .sort((a, b) => (a.name).toLowerCase() > (b.name).toLowerCase() ? 1 : -1)
        .map(emp => ({ label: emp.name === 'all' ? 'All' : emp.name, value: emp.employeeID }))
      return { ...empResult, employees: emp }
    } catch (error) {
      console.log(error);
    }
  }

  const openPopup = (type, _data) => {
    switch (type) {
      case "addNew":
        setShowAddNewPopup(true)
        break;
      case "view":
        break;
      default:
        break;
    }
  }

  const actionHandler = (type, data) => {
    switch (type) {
      case "view":
        setShowLoader(true)
        setMode({
          title: "Report Scheduler",
          type: 'view',
          data,
          hasPrev: false
        })
        break;
      case "edit":
        setShowLoader(true)
        setMode({
          title: "Report Scheduler",
          type: 'edit',
          data,
          hasPrev: false
        })
        break;
      case "copy":
        setShowLoader(true)
        setMode({
          title: "Report Scheduler",
          type: 'copy',
          data,
          hasPrev: false
        })
        break;
      default:
        break;
    }
  }

  const deleteSchedule = async data => {

    let flag = false;
    let res = { type: 'info' };

    if (data.length) {
      let uniqueRunningStatus = [...new Set(data.map(item => item.runningStatus))]
      let index = uniqueRunningStatus.findIndex(ele => ele.toLowerCase() === 'started');
      if (index >= 0) {
        flag = true
      }
    } else {
      if ((data.runningStatus).toLowerCase() === 'started') {
        flag = true;
      }
    }

    if (flag) {

      if (data.length)
        res.message = "One of the selected records has already started";
      else
        res.message = `${data.scheduleId} has already started`;

      return res;

    } else {

      const payload = data.length ? [...data.map(item => item.txnId)] : [data.txnId]

      try {
        setShowLoader(true)
        const response = await apiProvider.removeObject('track/mobile/v1/allgeo/schedule/delete', payload)
        if (data.length)
          response.message = "The selected records have been deleted successfully";
        else
          response.message = `${data.scheduleId} is deleted`

        return response;
      } catch (error) {
        console.log(error);
        throw new Error(error)
      } finally {
        setShowLoader(false)
      }
    }
  }

  useEffect(() => {
    getAccessLevel("reports", "schedule_report").then((response) => {
      setAccessLevel(response);
    });
    getReportData()
    getReportForData()
    getReportTypes()
  }, [])

  useEffect(() => {
    if (toastMsg.msg) {
      if (toastMsg.type === "msgSuccess" && childRef.current) {
        childRef.current.refreshData()
      }
      setShowLoader(false)
    }
  }, [toastMsg])

  return (
    <Fragment>
      <section>
        <Fragment>
          {
            reportList &&
            <KendoGrid
              gridData={reportList}
              setGridData={setReportList}
              columnData={reportSchedulerColumns}
              accessLevel={accessLevel}
              ref={childRef}
              module={"reportScheduler"}
              getAPiUrl={'/track/mobile/v1/allgeo/schedule?type=Report'}
              requestParams={{ status: activeList }}
              openPopup={openPopup}
              deleteItem={deleteSchedule}
              lockActionColumn={true}
              actionColumnHandler={actionHandler}
              bulkDelete={deleteSchedule}
              otherData={{
                dropDownData: activeList,
                setDropDownData: setActiveList,
                hideSaveCancelChanges: true,
                actionColumnBtns: ['view', 'edit', 'copy', 'delete'],
                booleanField: "active"
              }}
            />
          }
          {
            showAddNewPopup &&
            <AddNewPopup
              closePopup={() => setShowAddNewPopup(false)}
              accountInfo={accountInfo}
              reportForData={reportForData}
              setShowLoader={setShowLoader}
              setToastMsg={setToastMsg}
              reports={reports}
            />
          }
        </Fragment>
        {
          mode.type === 'view' &&
          <ViewReportcheduler
            scheduleId={mode.data.txnId}
            dateFormat={(accountInfo.dateFormat).replace(/[d]/gi, "D")}
            timeFormat={(accountInfo.timeFormat).replace(/[a]/gi, "A")}
            setShowLoader={setShowLoader}
            setMode={setMode}
            mode={mode}
            reportForData={reportForData}
            accessLevel={accessLevel.childMenu} />
        }
        {
          (mode.type === 'edit' || mode.type === 'copy') &&
          <EditReportScheduler
            scheduleId={mode.data.txnId}
            setShowLoader={setShowLoader}
            setMode={setMode}
            setToastMsg={setToastMsg}
            accountInfo={accountInfo}
            reportForData={reportForData}
            screenType={mode.type}
          />
        }
      </section>
      {
        mode.type === 'delete' &&
        <DeletePopup
          onSuccess={async () => {
            try {
              if ((mode.data?.runningStatus).toLowerCase() !== 'started') {
                const res = await deleteSchedule(mode.data)
                if (res.status === 200) {
                  setMode({
                    title: "Report",
                    type: 'main',
                    data: null,
                    hasPrev: false
                  })
                  setToastMsg({
                    msg: res.message,
                    type: "msgSuccess"
                  })
                }
              } else {
                setToastMsg({ msg: `${mode.data.scheduleId} has alredy started`, type: "" })
              }
            } catch (err) {
              console.log(err);
            }
          }}
          closePopup={() => setMode({
            title: "Report",
            type: 'main',
            data: null,
            hasPrev: false
          })}
        />
      }
      {toastMsg.msg && <ToastMessage
        cssClass={toastMsg.type}
        message={toastMsg.msg}
        onClose={() => setToastMsg({ msg: "", type: "" })}
      />}
      <div className={`overlay ${(showAddNewPopup || mode.type !== 'main') ? 'active' : ''}`} />
      <SiteLoader isActive={showLoader} />
    </Fragment>
  )
}

export default ReportScheduler