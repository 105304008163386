import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { apiProvider } from '../../../../services/api/provider'
import { ConfirmPopup2nd } from '../../../common/PopUp';
import { reportTypes, formatDate, formatTime, getFutureDate, assignTypes, reportRanges } from '../reportSchedulerUtils';
import Form1 from './Form1';
import Form2 from './Form2';

const EditReportScheduler = ({ setMode, scheduleId, setShowLoader, setToastMsg, accountInfo, reportForData, screenType }) => {

    const { register, watch, handleSubmit, getValues, setValue, reset, formState: { isDirty, errors } } = useForm({ mode: "all" });

    const [showDialog, setShowDialog] = useState(false);
    const [formPage, setFormPage] = useState(1)
    const [reportTypeData, setReportTypeData] = useState(null)

    const getReportData = async () => {
        try {
            const response = await apiProvider.getSingle(`/track/mobile/v1/allgeo/schedule?scheduleId=${scheduleId}&type=Report`)
            const reportData = response.data[0]

            const customReportMapArr = Object.keys(reportData['customReportMap']).map(key => ({
                text: reportData['customReportMap'][key],
                id: key
            }))

            const standardReportMapArr = Object.keys(reportData['standardReportMap']).map(key => ({
                text: reportData['standardReportMap'][key],
                id: key
            }))

            const selectedReports = [...standardReportMapArr, ...customReportMapArr].filter(rep => reportData.selectedReport.includes(rep.id))

            reset({
                ...reportData,
                scheduleId: screenType === 'copy' ? "" : reportData.scheduleId,
                reportType: reportTypes.STANDARD,
                selectedReport: selectedReports.filter((rep, ind) => {
                    return ind === selectedReports.findIndex(obj => obj.id === rep.id)
                }),
                scheduleForGrp: reportData.assignType === assignTypes.EMPLOYEE ? "all" : reportData.scheduleFor,
                scheduleForEmp: reportData.assignType === assignTypes.GROUP ? "all" : reportData.scheduleFor,
                reportFromTime: new Date(`2022/02/28 ${reportData.reportFromTime}`),
                reportToTime: new Date(`2022/02/28 ${reportData.reportToTime}`),
                scheduleStartOn: new Date(reportData.scheduleStartOn),
                scheduleEndOn: reportData.scheduleEndOn !== "never" ? new Date(reportData.scheduleEndOn) : getFutureDate(reportData.scheduleStartOn),
                scheduleCheck: reportData.scheduleEndOn === "never" ? "never" : "on",
                startTime: new Date(`2022/02/28 ${reportData.startTime}`),
                reportLastHrs: reportData.reportRange === reportRanges.HOURS ? reportData.reportLastHrs: 8,
                reportLastDays: reportData.reportRange === reportRanges.DAYS ? reportData.reportLastHrs : 2,
            })

            setReportTypeData({
                [reportTypes.CUSTOM]: customReportMapArr,
                [reportTypes.STANDARD]: standardReportMapArr
            })

        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    const closePopup = () => {
        if (isDirty) {
            setShowDialog(true)
        } else {
            setMode({
                title: "Report Scheduler",
                type: 'main',
                data: null,
                hasPrev: false
            })
        }
    }

    const onSubmit = async data => {
        let response, msg;
        try {
            setShowLoader(true)

            const payload = {
                ...data,
                selectedReport: data.selectedReport.map(rep => rep.id),
                scheduleFor: data.assignType === assignTypes.GROUP ? data.scheduleForGrp : data.scheduleForEmp,
                reportFromTime: formatTime(data.reportFromTime),
                reportToTime: formatTime(data.reportToTime),
                scheduleStartOn: formatDate(data.scheduleStartOn),
                scheduleEndOn: data.scheduleCheck !== "never" ? formatDate(data.scheduleEndOn) : "never",
                startTime: formatTime(data.startTime),
                reportLastHrs: data.reportRange === reportRanges.HOURS ? data.reportLastHrs: data.reportLastDays,
                accountNotificationEmail: data.reportOption !== 'email' ? false : data.accountNotificationEmail,
                managerNotificationEmail: data.reportOption !== 'email' ? false : data.managerNotificationEmail,
                deviceNotificationEmail: data.reportOption !== 'email' ? false : data.deviceNotificationEmail,
                reportSendToEmails: data.reportOption !== 'email' ? "" : data.reportSendToEmails
            }

            delete payload.customReportMap
            delete payload.standardReportMap
            delete payload.scheduleCheck
            delete payload.scheduleForGrp
            delete payload.scheduleForEmp
            delete payload.reportLastDays

            if (screenType === 'edit') {
                response = await apiProvider.put('/track/mobile/v1/allgeo/schedule/update?type=Report', [payload]);
                msg = `Schedule has been successfully updated.`
            } else {

                delete payload.txnId

                response = await apiProvider.post('track/mobile/v1/allgeo/schedule/create', [payload]);
                msg = `Schedule ${data.scheduleId} has been successfully created.`
            }


            if (response.status === 200) {
                setToastMsg({ msg: msg, type: "msgSuccess" })
                setMode({
                    title: "Reports",
                    type: 'main',
                    data: null,
                    hasPrev: false
                })
            } else {
                setToastMsg({ msg: response.message, type: "msgError" })
            }
        } catch (err) {
            console.log(err);
        } finally {
            setShowLoader(false)
        }
    }

    const onSuccess = () => {
        setShowDialog(false)
        setMode({
            title: "Report Scheduler",
            type: 'main',
            data: null,
            hasPrev: false
        })
    }

    const onFaliure = () => {
        setShowDialog(false);
        getReportData();
    }

    useEffect(() => {
        getReportData()
    }, [])

    return (
        <>
            <div className="popupWrap medPopup active">
                <div className="closePopup" onClick={closePopup} >Close</div>
                <div className="popupHeading">{screenType === 'edit' ? 'Edit' : 'Copy'} Report Scheduler</div>
                <form onSubmit={handleSubmit(onSubmit)} >
                    {
                        formPage === 1
                            ?
                            <Form1
                                register={register}
                                getValues={getValues}
                                setValue={setValue}
                                watch={watch}
                                reportForData={reportForData}
                                reportTypeData={reportTypeData}
                                setToastMsg={setToastMsg}
                                timeFormat={accountInfo.timeFormat}
                                screenType={screenType}
                            />
                            :
                            <Form2
                                register={register}
                                setFormPage={setFormPage}
                                watch={watch}
                                getValues={getValues}
                                setValue={setValue}
                                accountInfo={accountInfo}
                                setToastMsg={setToastMsg}
                                errors={errors}
                                timeFormat={accountInfo.timeFormat}
                                dateFormat={accountInfo.dateFormat}
                            />
                    }
                </form>
                {formPage === 1 && <div className="popupBtnWrap flexJCenter">
                    <button
                        className="btnStyle okBtn"
                        disabled={watch('scheduleId')?.trim().length === 0 || watch('selectedReport')?.length === 0}
                        onClick={() => setFormPage(2)} >
                        Next
                    </button>
                </div>}
            </div>
            <ConfirmPopup2nd
                showDialog={showDialog}
                message={"The changes are still unsaved. Are you sure you want to leave?'"}
                confirmNavigation={onSuccess}
                cancelNavigation={onFaliure}
            />
        </>
    )
}

export default EditReportScheduler