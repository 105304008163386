import React from "react";
import { MapViewData } from "../MapViewData";
import './mapAndLocationNewtab.css';

export const MapAndLocationNewtab = () => {
  return (
    <div className="newTabMapPage">
      <MapViewData  />
    </div>
  );
};
