import * as React from 'react';

import { Popup } from '@progress/kendo-react-popup';

const CustomPopup = (props) => {
    return (
        <Popup
            anchorAlign={{
                horizontal: 'center',
                vertical: 'bottom',
            }}
            popupAlign={{
                horizontal: 'right',
                vertical: 'bottom',
            }}
            style={{ zIndex: 1000 }}
            {...props}
        />
    );
};

export default CustomPopup;