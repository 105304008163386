import whenIWork  from '../../../assets/images/connector-1.png'
import quickBooks  from '../../../assets/images/connector-2.png'
import googleSheets  from '../../../assets/images/connector-3.png'
import gusto  from '../../../assets/images/connector-4.png'
import adp  from '../../../assets/images/connector-5.png'
import sandata  from '../../../assets/images/connector-6.png'
import aws  from '../../../assets/images/connector-7.png'
import zapier  from '../../../assets/images/connector-8.png'
import google  from '../../../assets/images/connector-9.png'
import deel from '../../../assets/images/connector-10.svg'
import hha from '../../../assets/images/connector-11.svg'

export const connectorMap = {
    "whenIwork": {
        connectorName: "When I Work",
        connectorDescription: "When I Work is a workforce management software that helps businesses schedule and communicate with their employees. It simplifies employee scheduling, time tracking, and team communication, allowing businesses to manage their workforce more efficiently.",
        imageSource: whenIWork,
        signUpLink: 'https://login.wheniwork.com'
    },
    "qb": {
        connectorName: "Quickbooks",
        connectorDescription: "Quickbooks is a popular accounting software program that is used by small businesses to manage their finances, including invoicing, payments, and expense tracking. It is often used in conjunction with payroll software programs to streamline financial operations.",
        imageSource: quickBooks,
        signUpLink: 'https://accounts.intuit.com/app/sign-in?app_group=QBO&asset_alias=Intuit.accounting.core.qbowebapp&iux_tests=47287%3A11%3A113998'
    },
    "googleDrive": {
        connectorName: "Google Sheets",
        connectorDescription: "Google Sheets is a cloud-based spreadsheet program that allows users to create, edit, and share spreadsheets online. Sheets can also be integrated with other Google tools, such as Google Docs and Google Drive, as well as third-party applications.",
        imageSource: googleSheets,
        signUpLink: null
    },
    "gusto": {
        connectorName: "Gusto",
        connectorDescription: "Gusto is a popular payroll software program that offers a variety of features for small and medium-sized businesses, including payroll processing, benefits administration, and compliance management.",
        imageSource: gusto,
        signUpLink: "https://app.gusto-demo.com/oauth/authorize?client_id=676968893bffbdb88ebaa6d9480296e7731c8cbfe27dd7a6d5999f042db27e23&redirect_uri=https://staging.allgeo.com/track/gusto/notification/callback&response_type=code"
    },
    "adp": {
        connectorName: "ADP",
        connectorDescription: "ADP is a human capital management software that provides solutions to businesses of all sizes. Their software helps manage payroll, benefits, time and attendance, and other HR-related tasks. With ADP, employers can streamline administrative processes, reduce errors, and improve compliance.",
        imageSource: adp,
        signUpLink: 'https://login.adp.com/welcome'
    },
    "sandata": {
        connectorName: "Sandata",
        connectorDescription: "Sandata provides home care solutions that help agencies manage their workforce and clients. Their software streamlines scheduling, time tracking, billing, and more, improving efficiency and reducing administrative burden.",
        imageSource: sandata,
        signUpLink: 'https://sandata.zendesk.com/auth/v2/login/signin?return_to=https%3A%2F%2Fsandata.zendesk.com%2Fhc%2Fen-us%2Fsignin&theme=hc&locale=en-us&brand_id=360000766834&auth_origin=360000766834%2Cfalse%2Ctrue'
    },
    "hha": {
        connectorName: "HHA",
        connectorDescription: "HHA Exchange is a web-based software program that offers a variety of solutions for home healthcare agencies. It provides tools for scheduling, time and attendance tracking, billing, and more, helping agencies manage their operations more efficiently.",
        imageSource: hha,
        signUpLink: null
    },
    "deel": {
        connectorName: "deel",
        connectorDescription: "Deel is a platform for managing remote workforces, offering solutions for hiring, onboarding, payroll, benefits, and compliance. Employers can create customized contracts and pay workers in over 120 currencies, with support for regulatory requirements across different countries.",
        imageSource: deel,
        signUpLink: ''
    },
}

export const otherConnectorsMap = {
    'aws': {
        connectorName: "Amazon Web Services",
        connectorDescription: "AWS is a cloud computing platform providing computing power, storage, and databases. Businesses can scale operations and pay only for what they use. Secure, reliable, and scalable, it's a popular choice for businesses of all sizes. It also offers tools and services for developers.",
        imageSource: aws,
        signUpLink: null
    },
    'zapier': {
        connectorName: "Zapier",
        connectorDescription: "Zapier is an automation platform that allows users to connect different apps and automate tasks between them. With Zapier, businesses can streamline workflows and save time by automating repetitive tasks.",
        imageSource: zapier,
        signUpLink: null
    },
    'google': {
        connectorName: "Google",
        connectorDescription: "Google simplifies the login process by allowing users to access multiple websites and applications using their Google account. It eliminates the need for multiple usernames and passwords, improves security, and saves time.",
        imageSource: google,
        signUpLink: 'https://accounts.google.com/signin/oauth/error/v2?authError=Cg9pbnZhbGlkX3JlcXVlc3QSQVN0b3JhZ2VyZWxheSBVUkkgaXMgbm90IGFsbG93ZWQgZm9yICdOQVRJVkVfREVTS1RPUCcgY2xpZW50IHR5cGUuIJAD&client_id=90569822-go5s4a692mdfnbf9rnpe237qhknlnfve.apps.googleusercontent.com'
    },
}