import React, { useState, useCallback, useRef, useImperativeHandle, forwardRef, useMemo, useEffect } from 'react';
import { Grid, GridColumn as Column, GridToolbar, getSelectedState, GridNoRecords } from '@progress/kendo-react-grid';
import { process, filterBy } from '@progress/kendo-data-query';
import { Checkbox, RadioButton } from '@progress/kendo-react-inputs'
import { setExpandedState, setGroupIds } from '@progress/kendo-react-data-tools';
import { getter } from "@progress/kendo-react-common";
import { ColumnMenu } from './ColumnMenu';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { GridPDFExport } from '@progress/kendo-react-pdf'
import { DropDownCell } from './DropDownCell';
import { apiProvider } from '../../../services/api/provider';
import ToastMessage from '../ToastMessage';
import SiteLoader from '../SiteLoader';
import { IdCell, EditCell, handleCustomCells, cellRender, CustomHeaderCell, customRowRender } from './CustomCells';
import { Prompt } from 'react-router-dom';
import { InfoPopup, DeletePopup, WorkOrderDeletePopup } from '../PopUp';
import { handleHeaderActionJSX } from './KendoGridHeaders';
import { customExportFunction, exportToCSV, moduleTypes, isDisabled } from './kendoUtils';
import { ExportDropdown, SaveGridStateBtn, ResetGridStateBtn, ExportButton, SaveTemplate } from './HeaderComponents';
import renderActionCell from './ActionCell';
import CancelChangesPopup from './CancelChangesPopup';
import { BulkDeleteBtnWO } from "../kendo_new/kendoGridHeaders/bulkDeleteIcon"
import { formatDate } from '@progress/kendo-intl';
import { saveChanges } from "./kendoGridUtils/saveChanges/saveChanges"
import { cancelChanges } from "./kendoGridUtils/cancleChanges/cancleChanges"
import { BulkDeleteBtn } from "./HeaderComponents"
import { ActiveScheduleHeaders } from '../../schedule/ActiveSchedules/ActiveScheduleHeaders'
import { getAccountLabel } from '../../../utils';
import { Header } from '../../report/BuildReports/subReports/AdvanceTimeClock/Header';
import { savingHistoryResponseModification } from '../../report/RunReports/DebugLogsUtils/helperfunctions'
import { getAccessLevel } from "../../common/functions"

const EDIT_FIELD = "inEdit";
const DATA_ITEM_KEY = "id";
const SELECTED_FIELD = "selected";

const initialDataState = {
    skip: 0,
    take: 1000,
};

const processWithGroups = (data, dataState, aggregates = null) => {
    const newDataState = process(data, dataState);
    const groups = dataState.group;

    if (groups && aggregates) {
        groups.map((group) => (group.aggregates = aggregates));
    }

    dataState.group = groups;
    setGroupIds({ data: newDataState.data, group: dataState.group });

    return newDataState;
};

const idGetter = getter(DATA_ITEM_KEY);

const moment = window.moment;

const actionColumnMinWidth = 60;
const KendoGrid = ({ deleteSeries, supportParams, deleteCurrent, gridData, columnData, deleteItem, bulkDelete, updateItem, createItem, accessLevel, getAPiUrl, setGridData, module = "", subModule = "", openPopup, lockActionColumn = false, actionColumnHandler, requestParams = {}, otherData = {}, filterable = true, groupable = true }, ref) => {
    const [editID, setEditID] = useState(null)
    const [data, setData] = useState(gridData?.map((d, ind) => {
        return {
            ...d,
            id: ind + 1
        }
    }))
    const [selectedView, setSelectedView] = useState('day');
    const [columns, setColumns] = useState(columnData)

    const [editField, setEditField] = useState(undefined);
    const [dataState, setDataState] = useState({
        ...initialDataState,
        sort: otherData.defaultSortedColumns ? otherData.defaultSortedColumns : [],
        group: otherData.defaultGroupedColumns ? otherData.defaultGroupedColumns : [],
        filter: otherData?.filterDataState?.filter ? otherData?.filterDataState?.filter : [] 
    });
    const [resultState, setResultState] = useState(processWithGroups(data, {
        ...initialDataState,
        sort: otherData.defaultSortedColumns ? otherData.defaultSortedColumns : [],
        group: otherData.defaultGroupedColumns ? otherData.defaultGroupedColumns : [],
        filter: otherData?.filterDataState?.filter ? otherData?.filterDataState?.filter : []
    }
        ,
        otherData.aggregates ? otherData.aggregates : null)
    )
    const [collapsedState, setCollapsedState] = useState([]);
    const [changes, setChanges] = useState(false);
    const [search, setSearch] = useState("")
    const [group, setGroup] = useState(otherData.defaultGroupedColumns ? otherData.defaultGroupedColumns : [])
    const [selectedState, setSelectedState] = useState({});
    const [dirty, setDirty] = useState({});
    const [createdData, setCreatedData] = useState([])
    const [updatedData, setUpdatedData] = useState([])
    const [mandatoryCols] = useState(columnData?.filter(col => col.mandatory).map(col => col.field))
    const [toastMsg, setToastMsg] = useState('')
    const [backupData, setBackupData] = useState(data)
    const [msgClass, setMsgClass] = useState("")
    const [showLoader, setShowLoader] = useState(false);
    const [deletePopup, setDeletePopup] = useState({
        show: false,
        data: null,
        type: ''
    })
    const [infoPopup, setInfoPopup] = useState({
        show: false,
        msg: ''
    })
    const [headerDropdown, setHeaderDropdown] = useState(otherData.dropDownData ? otherData.dropDownData : "")
    const [reqParams, setReqParams] = useState(requestParams)
    const [cellValidationValue, setCellValidationValue] = useState(null)
    const [cancelChangesPopup, setCancelChangesPopup] = useState(false)
    const [manual, setManual] = useState('none');
    const [auto, setAuto] = useState('none');
    const [refreshCall, setRefeshCall] = useState(false);
    const [updatedHeaderName, setUpdatedHeaderName] = useState([]);
    const [filterData, setFilter] = useState(otherData?.filterDataState?.filter);


    const actionColumnBtns = useMemo(() => otherData.actionColumnBtns ? otherData.actionColumnBtns : ['view', 'edit', 'delete'], [])
    const validationMapping = useMemo(() => {
        const lengthMap = {}
        const patternMap = {}

        columnData.forEach(col => {
            if (col.editable)
                lengthMap[col.field] = col.maxLength || 255;

            if (col.pattern)
                patternMap[col.field] = {
                    pattern: col.pattern,
                    patternFailMsg: col.patternFailMsg
                }

        })

        return {
            lengthMap,
            patternMap
        };
    }, [])

    const onColumnsSubmit = (columnsState) => {
        const { filter } = dataState;

        if (group.length > 0) {
            const hiddenColumns = columnsState.filter(col => !col.show).map(col => col.field)
            const isThereAnyGroupedColumnsWhichIsHidden = group.filter(col => hiddenColumns.includes(col.field))
            if (isThereAnyGroupedColumnsWhichIsHidden.length > 0) {
                setMsgClass("")
                setToastMsg("Grouped Columns cannot be hidden.")
                return
            }

        }

        if (filter) {
            const { filters } = filter

            if (filters?.length > 0) {
                const visibleColumnFilters = filters.filter(filter => {
                    const matchedCol = columnsState.find(col => col.field === filter.field)

                    return !(matchedCol && !matchedCol.show)
                })

                const filteredDataState = {
                    ...dataState,
                    ["filter"]: visibleColumnFilters.length === 0 ? undefined : {
                        ...filter,
                        ["filters"]: visibleColumnFilters
                    }
                }

                setResultState(processWithGroups(data.map(item => ({
                    ...item,
                    [SELECTED_FIELD]: selectedState[idGetter(item)]
                })), filteredDataState))

                setDataState(filteredDataState)
            }
        }


        setColumns(columnsState);
        const columnFieldToRefreshList = otherData.columnFieldToRefreshList ? otherData.columnFieldToRefreshList : ""
        const requiredColumn = columnsState.find(col => col.field === columnFieldToRefreshList)

        if (requiredColumn?.show) {
            const params = {
                ...reqParams,
                showNearByEmployees: "Yes"
            }
            setReqParams(params)
            refreshList(params)
        }
    };



    const _export = useRef(null)
    const pdfExportRef = useRef(null)
    const gridRef = useRef(null)
    const csvDownloadRef = useRef(null)

    useEffect(() => {
        if (otherData.searchText) {
            handleSearch(null, otherData.searchText)
        }
        if (otherData.isUpdated) {
            refreshList()
            otherData.setIsUpdated(false)
        }

        const handleKeyboardEvents = evt => {
            if (evt.code === 'Escape') {
                closeEdit()
            }
        }

        if (moduleTypes.editReports === module||moduleTypes?.employee==="employeess") {
            getSavedGridState()
        }

        window.addEventListener('keydown', handleKeyboardEvents)

        return () => {
            window.removeEventListener('keydown', handleKeyboardEvents)
        }

    }, [])

    useEffect(() => {
        if (otherData.isDirty) {
            otherData.isDirty(Object.keys(dirty).length > 0)
        }
        if (Object.keys(dirty).length > 0) {
            localStorage.setItem('formIsDirty', true)
        }
    }, [dirty])

    useEffect(() => {
        if (otherData.aggregates) {
            setResultState(processWithGroups(data, { ...dataState }, otherData.aggregates))
        }
    }, [otherData])

    const excelExport = () => {
        if (_export.current !== null) {
            if (dataState?.group.length > 0) {
                const selectedData = data.filter(d => selectedState[d.id])

                if (selectedData.length > 0) {
                    _export.current.save(selectedData);
                } else {
                    _export.current.save();
                }
            } else {
                _export.current.save();
            }
        }
    };

    const pdfExport = () => {
        if (pdfExportRef.current !== null) {
            pdfExportRef.current.save()
        }
    };

    useImperativeHandle(ref, () => ({
        saveData: () => saveChanges(),
        refreshData: () => refreshList(),
        exportToExcel: () => excelExport(),
        exportToPdf: () => pdfExport(),
        exportToExcelRef: _export,
        gridRef: gridRef
    }))

    const fetchParcHeaders = async (params) => {
        try {
            const response = await apiProvider.getAll('track/mobile/v1/allgeo/parcPaydataCustomReportHeaders', Object.keys(params).length > 0 ? params : reqParams)
            const colname = response.map((item) => {
                return {
                    title: item.key,
                    field: item.title,
                    editable: false,
                    width: 200,
                    editor: "text",
                    columnMenu: false,
                    show: true,
                    filter: "text",
                }
            })
            setColumns(colname)
            sessionStorage.setItem("parcHeaders", JSON.stringify(colname));

        } catch (error) {
            console.error(error);
        }
    }

    const refreshList = async (params = {}, modifiedDataState = null) => {
        try {
            setShowLoader(true)
            if (getAPiUrl === 'track/mobile/v1/allgeo/parcPaydataCustomReport') {
                // this if condition is used to fetch dynamic headers for parc paydata report. 
                fetchParcHeaders(params)
            }
            let response;
            getAPiUrl === '/track/react/v1/allgeo/monitor' ?
                response = await apiProvider.post(getAPiUrl, Object.keys(params).length > 0 ? params : reqParams)
                : response = await apiProvider.getAll(getAPiUrl, Object.keys(params).length > 0 ? params : reqParams)
            let newData = []

            if (response.data) {
                if (otherData.booleanField) {
                    newData = response.data.map((data, ind) => ({
                        ...data,
                        id: ind + 1,
                        [otherData.booleanField]: data[otherData.booleanField] ? "Active" : "Inactive",
                        nextRunDateTime: data.nextRunDateTime === "" ? "" : moment(data.nextRunDateTime).format(`${(response.dateFormat).replace(/[d]/gi, "D")} ${(response.timeFormat).replace(/[a]/gi, "A")}`)
                    }))
                } else {
                    newData = response.data.map((data, ind) => ({ ...data, id: ind + 1 }))
                }
            } else {
                let responseKey = ""
                switch (module) {
                    case moduleTypes.groups:
                        responseKey = "groups"
                        break;
                    case moduleTypes.managers:
                        responseKey = "managers"
                        break;
                    case moduleTypes.tasks:
                        responseKey = "tasks"
                        break;
                    case moduleTypes.forms:
                        responseKey = "forms"
                        break;
                    case moduleTypes.billing:
                        responseKey = "billing"
                        break;
                    case moduleTypes.locateScheduler:
                        responseKey = "schedule"
                        break;
                    case moduleTypes.rulesScheduler:
                        responseKey = "rules"
                        break;
                    case moduleTypes.monitorEmployees:
                        responseKey = "list"
                        break;
                    case moduleTypes.buildReports:
                        responseKey = "buildReports"
                        break;
                    default:
                        break;
                }
                if ([moduleTypes.settings, moduleTypes.editReports, moduleTypes.runNBuildReports].includes(module)) {
                    if (getAPiUrl === "/track/mobile/v1/allgeo/getCrudTransactionAuditData") {
                        newData = savingHistoryResponseModification(response).map((data, ind) => ({ ...data, id: ind + 1 }));
                    } else {
                        newData = response.map((data, ind) => ({ ...data, id: ind + 1 }))
                    }

                } else {
                    if (otherData.booleanField) {
                        response[responseKey].map((data, ind) => ({
                            ...data,
                            id: ind + 1,
                            [otherData.booleanField]: data[otherData.booleanField] ? "Active" : "Inactive"
                        }))

                    }
                    if (moduleTypes.buildReports.includes(module)) {
                        newData = response?.map((data, ind) => ({ ...data, id: ind + 1 }))
                    }
                    else {
                        newData = response[responseKey].map((data, ind) => ({ ...data, id: ind + 1 }))
                    }
                }

            }

            if (modifiedDataState) {
                setDataState(modifiedDataState)
            }

            setSearch('')
            setGridData(newData.map(d => ({ ...d })))
            setBackupData(newData)
            setData(newData)
            setResultState(processWithGroups(newData, modifiedDataState || dataState));
            setCreatedData([])
            setUpdatedData([])
            setDirty({})
            setEditID(null)
            setSelectedState({})
            setChanges(false)
            localStorage.removeItem("formIsDirty")
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    const getSavedGridState = async () => {
        try {
            setShowLoader(true);
            const templateName = module === moduleTypes.editReports ? otherData.subPage : moduleTypes?.employee===module? otherData.templateName : module;
            const response = await apiProvider.getAll(`/track/mobile/v1/allgeo/getEditReportTemplate?templateName=${templateName}`);

            if (response.templateOptions) {
                const { columns: updatedColumns, dataState } = JSON.parse(response.templateOptions);

                // Filter out "Week" and "Day" columns from updatedColumns
                const filteredColumns = updatedColumns.filter(col => col.field !== "dayName" || col.field !== "weekName");

                // Update the column titles from columnData
                const updatedColumnState = filteredColumns.map(col => {
                    const matchingColumn = columnData.find(c => c.field === col.field);
                    if (matchingColumn) {
                        col.field = matchingColumn.field;
                    }
                    return col;
                });

                // Set the filtered and updated columns
                setColumns(updatedColumnState);
                otherData.setColumnList(updatedColumnState)
                otherData.setKendoDataState(dataState)

                setDataState({
                    ...dataState
                });

                if (dataState.group && dataState.group.length > 0) {
                    setGroup([...dataState.group]);
                }

                setResultState(processWithGroups(data, { ...dataState }, otherData.aggregates || {}));
            }
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false);
        }
    };

    const resetGridState = async () => {
        try {
            setShowLoader(true)
            const payload = {
                dataState,
                columns: columnData
            }
            const templateName = module == moduleTypes.editReports ? otherData.subPage : module;
            const response = await apiProvider.post(`/track/mobile/v1/allgeo/saveEditReportTemplate?templateName=${templateName}`, payload)

            if (response.StatusCode === 200) {
                setMsgClass("msgSuccess")
                setToastMsg("Your template has been reset!!")
            } else {
                setMsgClass("msgError")
                setToastMsg("Something went wrong!")
            }
        } catch (error) {
            console.log(error)
        } finally {
            getSavedGridState()
            setShowLoader(false)
        }
    }

    const saveGridState = async () => {
        try {
            setShowLoader(true)
            const payload = {
                dataState,
                columns
            }

            const templateName = module == moduleTypes.editReports ? otherData.subPage : module

            const response = await apiProvider.post(`/track/mobile/v1/allgeo/saveEditReportTemplate?templateName=${templateName}`, payload)

            if (response.StatusCode === 200) {
                setMsgClass("msgSuccess")
                setToastMsg(response.SucessMsg)
            } else {
                setMsgClass("msgError")
                setToastMsg("Something went wrong!")
            }
        } catch (error) {
            console.log(error)
        } finally {
            setShowLoader(false)
        }
    }

    const getMandatoryColumnTitle = field => {
        const colData = columnData.filter(col => col.mandatory).find(mCol => mCol.field === field)

        if (colData) return colData.title

        return ''
    }

    const onCancleHandler = () => {
        cancelChanges({ dirty, setCancelChangesPopup, resetData, setChanges })
    }


    const resetData = () => {
        const defaultData = gridData?.map((d, ind) => {
            delete d.dirty;

            return {
                ...d,
                id: ind + 1
            }
        })
        setBackupData(defaultData)
        setData(defaultData)
        setResultState(processWithGroups(defaultData, dataState))
        setCreatedData([])
        setUpdatedData([])
        setSelectedState({})
        setDirty({})
        setToastMsg('')
        localStorage.removeItem('formIsDirty')
    }

    const addRecord = () => {

        if (columnData.length > 0) {

            if (data.length > 0) {
                let unfilledMandatoryCols = []

                if (module === moduleTypes.editReports) {
                    createdData.map(createdRecord => {
                        unfilledMandatoryCols = mandatoryCols.filter(fld => createdRecord[fld].trim().length === 0).map(fld => fld)
                    })
                } else {
                    unfilledMandatoryCols = mandatoryCols.filter(fld => data[0][fld].trim().length === 0).map(fld => fld)
                }

                if (unfilledMandatoryCols.length === 0) {
                    setChanges(true)
                    const d = {}
                    columnData.forEach(col => {
                        d[col.field] = col.defaultValue ? col.defaultValue : "";
                    })

                    const updatedData = [{ id: data.length + 1, created: true, ...d }, ...data].map((d, ind) => ({ ...d, id: ind + 1 }))

                    const newDataState = processWithGroups(updatedData, dataState);

                    setCreatedData([{ id: 1, created: true, ...d }, ...createdData])
                    setResultState(newDataState);
                    setBackupData(updatedData)
                    setData(updatedData);
                    setEditID(d[DATA_ITEM_KEY]);
                } else {
                    setMsgClass("")
                    setToastMsg(`Please enter the mandatory ${unfilledMandatoryCols.length === 1 ? 'field' : 'fields'} ` + unfilledMandatoryCols.map(field => {
                        return " " + getMandatoryColumnTitle(field)
                    }).toString() + " to proceed.")
                }
            } else {
                setChanges(true)
                let d = {}
                columnData.forEach(col => {
                    d[col.field] = col.defaultValue ? col.defaultValue : "";
                })

                const updatedData = [{ id: data.total + 1, created: true, ...d }, ...data].map((d, ind) => ({ ...d, id: ind + 1 }))

                const newDataState = processWithGroups(updatedData, dataState);

                setCreatedData([{ id: 1, created: true, ...d }, ...createdData])
                setResultState(newDataState);
                setBackupData(updatedData)
                setData(updatedData);
                setEditID(d[DATA_ITEM_KEY]);
            }

        }


    }

    const onRowClick = event => {
        const id = event.dataItem[DATA_ITEM_KEY]
        setEditID(id);

        const dirtyItems = data.filter(d => d.dirty).map(d => d[DATA_ITEM_KEY])
        const dirtyItemsState = {}

        dirtyItems.forEach(item => dirtyItemsState[item] = true)

        setSelectedState({
            ...dirtyItemsState,
            [id]: true
        })
    }

    const handleSearch = (evt, prevSearch = "") => {
        const searchText = prevSearch.trim().length > 0 ? prevSearch : evt.target.value;

        setSearch(searchText);
        if (otherData.setSearchText) otherData.setSearchText(searchText)
        const newData = backupData.map((d, ind) => {
            return {
                ...d,
                id: ind + 1
            }
        }).filter((item) => {
            let match = false;
            for (const property in item) {
                if (item[property] &&
                    item[property]
                        .toString()
                        .toLocaleLowerCase()
                        .indexOf(searchText.toLocaleLowerCase()) >= 0
                ) {
                    match = true;
                }
            }
            return match;
        });
        setData(newData)
        let clearedPagerDataState = { ...dataState, take: dataState.take, skip: 0 };
        const newDataState = processWithGroups(newData, clearedPagerDataState)
        setResultState(newDataState);
        setDataState(clearedPagerDataState)
    }

    const setSelectedValue = (data) => {
        return data.map((item) => {
            if (item.items) {
                return {
                    ...item,
                    items: setSelectedValue(item.items),
                };
            } else {
                return {
                    ...item,
                    ['selected']: selectedState[idGetter(item)],
                };
            }
        });
    };

    const onSelectionChange = useCallback(
        (event) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });
            const dirtyItems = data.filter(d => d.dirty).map(d => d[DATA_ITEM_KEY])
            const dirtyItemsState = {}

            dirtyItems.forEach(item => dirtyItemsState[item] = true)

            setSelectedState({
                ...dirtyItemsState,
                ...newSelectedState
            });
            setUpdatedData(data.filter(d => !d.created && newSelectedState[d.id]))
        },
        [selectedState, data]
    );

    const onHeaderSelectionChange = useCallback((event) => {
        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked;
        const newSelectedState = {};
        event.dataItems.forEach((item) => {
            newSelectedState[idGetter(item)] = checked;
        });
        setSelectedState(newSelectedState);
    }, []);

    const getNumberOfItems = (data) => {
        let count = 0;
        data.forEach((item) => {
            if (item.items) {
                count = count + getNumberOfItems(item.items);
            } else {
                count++;
            }
        });
        return count;
    };

    const getNumberOfSelectedItems = (data) => {
        let count = 0;
        data.forEach((item) => {
            if (item.items) {
                count = count + getNumberOfSelectedItems(item.items);
            } else {
                count = count + (item.selected == true ? 1 : 0);
            }
        });
        return count;
    };

    const checkHeaderSelectionValue = () => {
        let selectedItems = getNumberOfSelectedItems(newData);
        return newData.length > 0 && selectedItems == getNumberOfItems(newData);
    };

    const onExpandChange = useCallback(event => {
        const item = event.dataItem;
        if (item.groupId) {
            const newCollapsedIds = !event.value ? [...collapsedState, item.groupId] : collapsedState.filter(groupId => groupId !== item.groupId);
            setCollapsedState(newCollapsedIds);
        }
    }, [collapsedState]);

    const onDataStateChange = useCallback(evt => {

        if (evt.dataState.group.length > 1) {
            const fields = evt.dataState.group.map(d => d.field)
            const duplicateFieldFound = fields.some((g, ind) => {
                const ix = fields.indexOf(g)
                return ix !== ind
            });

            if (duplicateFieldFound) return
        }

        if (otherData.aggregates) {
            const grp = evt.dataState.group.map(g => ({
                ...g,
                aggregates: otherData.aggregates
            }))

            evt.dataState.group = grp
        }

        setGroup(evt.dataState.group)

        setResultState(processWithGroups(data.map(item => ({
            ...item,
            [SELECTED_FIELD]: selectedState[idGetter(item)]

        })), evt.dataState))
        setDataState(evt.dataState)
        if (moduleTypes.editReports === module) {
            otherData.setKendoDataState(evt.dataState)
        }
    }, [data])

    const newData = setExpandedState({
        data: setSelectedValue(resultState.data).length > 0 ? setSelectedValue(resultState.data) : data,
        collapsedIds: collapsedState,
    });

    const handleDelete = async () => {
        try {
            let res;

            if (deletePopup.data.created) {
                const idToBeDeleted = deletePopup.data.id

                const updatedData = data.filter(item => item.id !== idToBeDeleted)

                setResultState(processWithGroups(updatedData, dataState));
                setCreatedData(createdData.filter(item => item.id !== idToBeDeleted))
                setBackupData(updatedData)
                setData(updatedData)

                setDeletePopup({
                    show: false,
                    data: null,
                    type: ''
                })
                return
            }

            if (deletePopup.type === 'single') {
                res = await deleteItem(deletePopup.data)

            } else {
                res = await bulkDelete(deletePopup.data)
            }

            if (res.status === 200) {

                setMsgClass("msgSuccess")
                setToastMsg(res.message)
                setDeletePopup({
                    show: false,
                    data: null,
                    type: ''
                })
                await refreshList()
            } else if (res?.type === 'info') {
                setMsgClass("")
                setToastMsg(res.message)
                setDeletePopup({
                    show: false,
                    data: null,
                    type: ''
                })
            } else {
                setMsgClass("msgError")
                setToastMsg(res.message)
                setDeletePopup({
                    show: false,
                    data: null,
                    type: ''
                })
            }


        } catch (error) {
            console.log(error);
        }
    }

    const handleDeleteCurrent = async () => {
        const workorderLabel = getAccountLabel('workorder')
        try {
            let res;
            const params = {
                start: sessionStorage.getItem("WO:startDate") ? sessionStorage.getItem("WO:startDate") : formatDate(new Date(), "yyyy/MM/dd"),
                end: sessionStorage.getItem("WO:endDate") ? sessionStorage.getItem("WO:endDate") : formatDate(new Date(), "yyyy/MM/dd"),
            };

            res = await deleteCurrent(deletePopup.data)

            if (res.status === 200) {

                setMsgClass("msgSuccess")
                setToastMsg(`${workorderLabel} is Deleted`)
                setDeletePopup({
                    show: false,
                    data: null,
                    type: ''
                })
                await refreshList(params)

            } else if (res?.type === 'info') {
                setMsgClass("")
                setToastMsg(res.message)
            } else {
                setMsgClass("msgError")
                setToastMsg(res.message)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleDeleteSeries = async () => {
        const workorderLabel = getAccountLabel('workorder')
        try {
            let res;
            const params = {
                start: sessionStorage.getItem("WO:startDate") ? sessionStorage.getItem("WO:startDate") : formatDate(new Date(), "yyyy/MM/dd"),
                end: sessionStorage.getItem("WO:endDate") ? sessionStorage.getItem("WO:endDate") : formatDate(new Date(), "yyyy/MM/dd"),
            };
            res = await deleteSeries(deletePopup.data)

            if (res.status === 200) {

                setMsgClass("msgSuccess")
                setToastMsg(`All ${workorderLabel} are Deleted`)
                setDeletePopup({
                    show: false,
                    data: null,
                    type: ''
                })
                await refreshList(params)
            } else if (res?.type === 'info') {
                setMsgClass("")
                setToastMsg(res.message)
            } else {
                setMsgClass("msgError")
                setToastMsg(res.message)
            }
        } catch (error) {
            console.log(error);
        }
    }


    const bulkDeleteAction = itemID => {
        const filteredData = data.filter(d => selectedState[d.id]);
        const dataToBeDeleted = itemID ? filteredData.map(d => d[itemID]) : filteredData;

        setDeletePopup({
            show: true,
            data: dataToBeDeleted,
            type: 'multiple'
        })
    }

    const isValidCell = (field, value) => {

        const { patternMap } = validationMapping

        if (patternMap[field]) {
            const { pattern, patternFailMsg } = patternMap[field]

            if (!pattern.test(value)) {
                setCellValidationValue({
                    [field]: value
                })
                setMsgClass('msgError')
                setToastMsg(patternFailMsg)

                return false
            }
        }

        if (cellValidationValue !== null) setCellValidationValue(null)

        return true

    }

    const handleBlur = (e, dataValue, cellField, dataItem) => {
        const value = dataValue;

        if (typeof value === 'string' && value.trim().length === 0 && mandatoryCols.includes(cellField)) {
            e.target.focus()
            setMsgClass("")

            let infoMsg = 'This is a mandatory field. Please enter a value to proceed.'

            if (!dataItem[cellField] || dataItem[cellField].trim().length > 0) {
                infoMsg = 'This is a mandatory field. It cannot be left empty.'
            }

            setToastMsg(infoMsg)
        } else {
            if (cellValidationValue !== null) setCellValidationValue(null)

            let dataItemValue = ""
            if (cellField.includes(".")) {
                const nestedFields = cellField.split(".")
                dataItemValue = dataItem[nestedFields[0]][nestedFields[1]]
            } else {
                dataItemValue = dataItem[cellField]
            }

            if (value !== dataItemValue && isValidCell(cellField, value))
                saveGridData(value, cellField, dataItem)
        }
    }

    const onSave = (dataValue, cellField, dataItem) => {
        if (isValidCell(cellField, dataValue)) {
            const existingData = JSON.parse(localStorage.getItem("selectedTaskdetailed")) || [];
            const newData = { task: dataValue, id: dataItem.id };
            existingData.push(newData);
            // Save the updated data back to localStorageif
            localStorage.setItem("selectedTaskdetailed", JSON.stringify(existingData));
            saveGridData(dataValue, cellField, dataItem)
        }
        if (isValidCell(cellField, dataValue))
            saveGridData(dataValue, cellField, dataItem)
    }

    const handleDropDownChange = e => {
        const { value, field, dataItem } = e;
        const { dropdownDependency } = otherData;

        if (dropdownDependency && Object.keys(dropdownDependency).includes(field)) {

            if (dropdownDependency[field].data) {
                const valueToBeMapped = dropdownDependency[field].data.find(d => d.key === value);

                if (valueToBeMapped) {
                    saveGridData(valueToBeMapped.value, dropdownDependency[field].fieldToBeMapped, dataItem)
                }
            } else {
                saveGridData('', dropdownDependency[field].fieldToBeMapped, dataItem)
            }
        }
        saveGridData(value, field, dataItem)
    }

    const saveGridData = (value, field, dataItem) => {
        if (value?.trim()?.length === 0 && mandatoryCols.includes(field)) return


        let newDirty = { ...dirty };
        newDirty[dataItem[DATA_ITEM_KEY]] = { ...dirty[dataItem[DATA_ITEM_KEY]], [field]: true }

        let newData;

        if (field.includes(".")) {
            const nestedFields = field.split(".")

            newData = data.map((item) => {
                if (item[DATA_ITEM_KEY] === dataItem[DATA_ITEM_KEY]) {
                    const newObj = {
                        ...dataItem[nestedFields[0]],
                        [nestedFields[1]]: value
                    }
                    item[nestedFields[0]] = newObj;
                    item.dirty = newDirty[item[DATA_ITEM_KEY]]
                }
                return item;
            });
        } else {
            newData = data.map((item) => {
                if (item[DATA_ITEM_KEY] === dataItem[DATA_ITEM_KEY]) {
                    item[field] = value;
                    item.dirty = newDirty[item[DATA_ITEM_KEY]]
                }
                return item;
            });
        }


        setDirty(newDirty);

        setUpdatedData(newData.filter(d => !d.created && (d.dirty || d[EDIT_FIELD])))
        setCreatedData(newData.filter(d => d.created))
        setData(newData);
        setBackupData(newData)
        const newDataState = processWithGroups(
            newData.map((item) => ({
                ...item
            })),
            dataState
        );
        setResultState(newDataState);
        setChanges(true);
        setEditField(null)
        setEditID(null)
    }

    const closeEdit = () => {
        setEditField(null)
        setEditID(null)
    }

    const handleCellDropdown = column => {
        return (props) => <DropDownCell {...props} disabled={isDisabled('edit', accessLevel, selectedState)} onChange={handleDropDownChange} field={column.field} filterable={column.dropdownFilter} editField={editField} editID={editID} otherData={otherData} />
    }

    const handleCellFieldSelection = (field) => {
        if (field !== editField) {
            setEditField(field)
        }
    }

    const InlineEditCell = col => {
        return (props) => <EditCell
            {...props}
            editID={editID}
            editField={editField}
            editable={accessLevel.childMenu < 2 ? false : col.editable}
            onClick={handleCellFieldSelection}
            onBlur={handleBlur}
            onSave={onSave}
            validationMapping={validationMapping}
            cellValidationValue={cellValidationValue}
            otherData={otherData}
            columnData={col}
        />
    }

    const handlePageSizes = useCallback((length) => {
        const defaultPageSize = [25, 50, 100, 250, 1000, 1500, 2000, 3000, 4000, 5000];
        const dataLength = length;

        if (dataLength > 5000) {
            const extraLength = dataLength % 1000;
            const finalValue = dataLength - extraLength;

            if (extraLength > 500) {
                defaultPageSize.push(finalValue + 1000)
            } else {
                defaultPageSize.push(finalValue + 500)
            }
        }

        return defaultPageSize;
    }, [data])

    const handleColumnLockToggle = (columnField, state) => {
        const newColumns = columns.map((column) => {
            if (column.field === columnField) {
                column.locked = state;
            }

            return column;
        });
        setColumns(newColumns)
    };

    const customGroupBy = async (fieldsToGroup = [], columnsToShow = [], fieldsToRemove = []) => {

        const fieldsToGroupBy = fieldsToGroup.map(field => ({
            field,
            aggregates: otherData.aggregates || {}
        }))

        let groupedFields = fieldsToGroupBy.length > 0 ? [...group, ...fieldsToGroupBy] : [...group, ...fieldsToGroupBy].filter(obj => !fieldsToRemove.includes(obj.field))

        groupedFields = groupedFields.filter((obj, ind) => groupedFields.findIndex(dObj => dObj.field === obj.field) === ind)

        setColumns(columns.map(col => ({
            ...col,
            show: fieldsToGroup.length > 0 ?
                columnsToShow.includes(col.field) ? true : col.show
                : columnsToShow.includes(col.field) ? false : col.show
        })))

        setGroup(groupedFields)

        const modifiedDataState = {
            ...dataState,
            group: groupedFields
        }

        setResultState(processWithGroups(data.map(item => ({
            ...item,
            [SELECTED_FIELD]: selectedState[idGetter(item)]

        })), modifiedDataState, otherData.aggregates || {}))
        setDataState(modifiedDataState)

        const params = {
            ...reqParams,
            weekView: "weekView"
        }

        if (fieldsToGroupBy.length === 0) {
            delete params.weekView
        }
        setReqParams(params)
        refreshList(params, modifiedDataState)

    }

    const onColumnsReorder = evt => {
        const columnsOrderMap = {}

        evt.columns.map(col => {
            columnsOrderMap[col.field] = col.orderIndex
        })

        setColumns(columns.map(col => ({
            ...col,
            orderIndex: columnsOrderMap[col.field]
        })))

    }

    const onClickHandler = () => {
        saveChanges({ module, moduleTypes, data, updatedData, createdData, getMandatoryColumnTitle, mandatoryCols, setToastMsg, setMsgClass, setShowLoader, updateItem, createItem, refreshList, setSelectedState, setEditID, setChanges, setCreatedData, setUpdatedData })
    }

    useEffect(() => {
        if (selectedView === "day") {
            const dayWeekViewColumns = columns.filter(e => !(e.field === "dayName" || e.field === "weekName"));
            setColumns(dayWeekViewColumns);
        }
        else if (selectedView === "week") {
            setColumns(columnData)
        }
    }, [selectedView])

    useEffect(() => {
        setReqParams(reqParams)
    }, [requestParams]);


    useEffect(() => {
        let filterInputs = document.querySelectorAll(
            ".k-input"
        );
        if (filterInputs.length > 0) {
            filterInputs.forEach((el) => {
                el.setAttribute("placeholder", "Search");
            });
        }
    }, []);

    useEffect(() => {
        sessionStorage.setItem('workorderType', `allWorkorders`);
    }, [])

    //  BELOW STATES ARE USED TO HANDLE BUILD REPORT ( ADVANCE TIME CLOCK )FILTERS 
    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();
    const [radioFilter, setRadioFilter] = useState("99,99");
    const [woRadio, setWoRadio] = useState('allWorkorders')
    const [isFirstLast, setIsFirstLast] = useState(false)

    const handleChange = (event) => {

        let updatedManual = manual;
        let updatedAuto = auto;

        if (event.target.name === "Manual" && event.value === true) {
            updatedManual = 'manual';
        } else if (event.target.name === "Manual" && event.value === false) {
            updatedManual = 'none';
        } else if (event.target.name === "Automatic" && event.value === true) {
            updatedAuto = 'auto';
        } else if (event.target.name === "Automatic" && event.value === false) {
            updatedAuto = 'none';
        }
        setManual(updatedManual);
        setAuto(updatedAuto);
        if( subModule === 'standardTime' ) {
            setReqParams({ ...reqParams, stageid: `${updatedManual},${updatedAuto}` });
            sessionStorage.setItem('stageid', `${updatedManual},${updatedAuto}`);
        }
        if (subModule === 'advanceTimeClockBuildReports') {

            if (event.target.name === 'start_time') {
                const start = event.target.value.toLocaleTimeString([], { hour12: false })
                sessionStorage.setItem('stageid', `${radioFilter},${isFirstLast},${manual},${auto},${start},${endTime}`);
                setStartTime(event.target.value.toLocaleTimeString([], { hour12: false }))
                setReqParams({ ...reqParams, stageid: `${radioFilter},${isFirstLast},${manual},${auto},${start},${endTime}` });
            } else if (event.target.name === 'end_time') {
                const end = event.target.value.toLocaleTimeString([], { hour12: false })
                sessionStorage.setItem('stageid', `${radioFilter},${isFirstLast},${manual},${auto},${startTime},${end}`);
                setEndTime(event.target.value.toLocaleTimeString([], { hour12: false }))
                setReqParams({ ...reqParams, stageid: `${radioFilter},${isFirstLast},${manual},${auto},${startTime},${end}` });
            } else if (event.target.name === "first_last") {
                setIsFirstLast(event.value)
            } else {
                const filterValue = event.value
                if (filterValue === "99,99" || filterValue === "3,0" || filterValue === "1,2") {
                    setRadioFilter(filterValue)
                } else {
                    setRadioFilter(radioFilter)
                }
            }
        }

        if (subModule === "WOPerformaceReport") {
            sessionStorage.setItem('workorderType', `${event.value}`);
            if (event.value == "allWorkorders") {
                setWoRadio("allWorkorders")
            }
            if (event.value === "uncompleted") {
                setWoRadio("uncompleted")
            }
        }
    };

    useEffect(() => {
        if (subModule === 'advanceTimeClockBuildReports') {
            setReqParams({ ...reqParams, stageid: `${radioFilter},${isFirstLast},${manual},${auto},${startTime},${endTime}` });
            refreshList({ ...reqParams, stageid: `${radioFilter},${isFirstLast},${manual},${auto},${startTime},${endTime}` });
            sessionStorage.setItem('stageid', `${radioFilter},${isFirstLast},${manual},${auto},${startTime},${endTime}`);
        }
        if (subModule === 'WOPerformaceReport') {
            setReqParams({ ...reqParams, workorderType: woRadio });
            refreshList({ ...reqParams, workorderType: woRadio });
        }
        if (subModule === "standardTime") {
            setReqParams({ ...reqParams, stageid: `${manual},${auto}` });
            refreshList({ ...reqParams, stageid: `${manual},${auto}` });
        }

    }, [manual, auto, radioFilter, isFirstLast, woRadio]);

    useEffect(() => {
        getUpdatedHeaderName()
    }, [])

    const getUpdatedHeaderName = async () => {
        try {
            const response = await apiProvider.getAll('track/mobile/v1/allgeo/getEditReportColumnNames');
            setUpdatedHeaderName(response)
        } catch (error) {
            console.error("defaultName Error:", error);
        }
    };
    
    const updatedHeaderNameLowerCase = {};
    Object.keys(updatedHeaderName).forEach(key => {
        updatedHeaderNameLowerCase[key.toLowerCase()] = updatedHeaderName[key];
    });

    columns?.forEach(item => {
        const lowerCaseField = item.field.toLowerCase();
        if (Object.keys(updatedHeaderNameLowerCase).includes(lowerCaseField)) {
            item.title = updatedHeaderNameLowerCase[lowerCaseField] || item.title;
        }
    });

    if (module !== "runNBuildReports" || otherData.isCustomReport === true) {
        //function to change all the header text through out the application
        columns.forEach(item => {
            const lowerCaseField = item.field.toLowerCase();
            if (Object.keys(updatedHeaderNameLowerCase).includes(lowerCaseField)) {
                item.title = updatedHeaderNameLowerCase[lowerCaseField] || item.title;
            }
        })
    }

    if (module === "employees") {
        let EmpLabel = getAccountLabel('employee')
        //function to change all the header text through out the application
        columns.forEach(item => {
            const lowerCaseField = item.field.toLowerCase();
            if (item.field === 'status') {
                item.title = EmpLabel + " " + "Status"
            }
            else if (Object.keys(updatedHeaderNameLowerCase).includes(lowerCaseField)) {
                item.title = updatedHeaderNameLowerCase[lowerCaseField];
            }
        })
    }

    if (otherData.reportName === 'First in - Last Out Site') {
        let EmpLabel = getAccountLabel('employee')
        //function to change all the header text through out the application
        columns.forEach(item => {
            const lowerCaseField = item.field.toLowerCase();
            if (item.field === 'deviceId') {
                item.title = EmpLabel + " " + "#"
            }
            else if (Object.keys(updatedHeaderNameLowerCase).includes(lowerCaseField)) {
                item.title = updatedHeaderNameLowerCase[lowerCaseField];
            }
        })
    }




    if (module === "employees") {
        let EmpLabel = getAccountLabel('employee')
        //function to change all the header text through out the application
        columns.forEach(item => {
            if (item.field==='status') {
                item.title = EmpLabel+" "+ "Status"
            }
            else if (Object.keys(updatedHeaderName).includes(item.field)) {
                item.title = updatedHeaderName[item.field] || item.title
            }
        })
    }

    useEffect(() => {
        if (otherData.columnList) {

            setColumns(otherData.columnList);
        }
    }, [otherData.columnList]);

    useEffect(() => {
        if (module === moduleTypes.editReports) {
            setColumns(otherData.columnList);
            otherData.setKendoDataState(dataState)
        }
    }, [otherData.columnList]);

    const [totalDataLength, setTotalDataLength] = useState(resultState.total);

    useEffect(() => {
            let filteredData = filterBy(newData, filterData);
            setTotalDataLength(filteredData.length);
    }, [newData, filterData]);

    const isCustomReport = otherData?.isCustomReport;

        // Assuming the menuItems are already stored in local storage under the key 'menuItems'
    // Get the data from local storage
    const storedMenuItems = localStorage.getItem('menuItems');

    // Parse the JSON string into an array
    const menuItems = JSON.parse(storedMenuItems);

    // Function to get access level by name
    const getAccessLevel = (menuItems, name) => {
        if (!Array.isArray(menuItems)) {
            console.error('menuItems is not an array');
            return undefined;
        }

        for (const item of menuItems) {
            if (item.name === name) {
                return item.accessLevel;
            }
            if (item.submenu && item.submenu.length > 0) {
                const result = getAccessLevel(item.submenu, name);
                if (result !== undefined) {
                    return result;
                }
            }
        }
        return undefined;
    };

    // Call the function with the data and the name "reports"
    const reportAccessLevel = getAccessLevel(menuItems, 'reports');

    return (
        <>
            <div className='kendoTblWrap headerVisible'>
                <div className='selection-filter'>
                    {module === "buildReports" && (
                        <>
                            {subModule !== "messageReport" && subModule !== "trackingReport" && subModule !== "WOPerformaceReport" && (
                                <>
                                    <Checkbox label={"Manual"} onChange={handleChange} name={'Manual'} />
                                    <Checkbox label={"Automatic"} onChange={handleChange} name={'Automatic'} />
                                </>
                            )}

                            {subModule && subModule === "WOPerformaceReport" && (
                                <>
                                    <div className="customRadio" style={{ display: "flex", alignItems: "normal" }}>
                                        <RadioButton
                                            name="all"
                                            value="allWorkorders"
                                            checked={woRadio === "allWorkorders"}
                                            label="All"
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="customRadio" style={{ display: "flex", marginBottom: "4px" }}>
                                        <RadioButton
                                            name="uncomplete"
                                            value="uncompleted"
                                            checked={woRadio === "uncompleted"}
                                            label="Uncompleted"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </>
                            )}

                            {subModule === 'advanceTimeClockBuildReports' && (
                                <Header radioFilter={radioFilter} handleChange={handleChange} />
                            )}
                        </>
                    )}

                    <ExcelExport
                        ref={_export}
                        data={newData}
                        group={dataState.group}
                        fileName={otherData.exportFileName || module}
                    >
                        {
                            columns.map((col, ind) => (
                                col.show && <ExcelExportColumn
                                    key={ind}
                                    field={col.field}
                                    title={col.title}
                                    width={col.width}
                                    groupFooterCellOptions={{ color: 'red' }}
                                />
                            ))
                        }
                    </ExcelExport>

                </div>
                    <Grid
                        ref={gridRef}
                        className={'kendoGridHeight'}
                        pageable={{ pageSizes: handlePageSizes(backupData.length) }}
                        sortable={{
                            allowUnsort: true,
                            mode: 'multiple'
                        }}
                        filterable={filterable}
                        total={resultState.total}
                        data={newData}
                        onDataStateChange={onDataStateChange}
                        {...dataState}
                        onExpandChange={onExpandChange}
                        expandField="expanded"
                        groupable={{
                            enabled: groupable,
                            footer: otherData.enableFooterDisplay ? 'visible' : 'none'
                        }}
                        group={group}
                        selectable={{
                            enabled: true,
                            drag: false,
                            cell: false,
                            mode: "multiple",
                        }}
                        editField={EDIT_FIELD}
                        dataItemKey={DATA_ITEM_KEY}
                        onRowClick={onRowClick}
                        selectedField={SELECTED_FIELD}
                        onSelectionChange={onSelectionChange}
                        onHeaderSelectionChange={onHeaderSelectionChange}
                        reorderable={true}
                        resizable={true}
                        onColumnReorder={onColumnsReorder}
                        cellRender={cellRender}
                        rowRender={(tr, props) => otherData.customRow ? customRowRender(tr, props, otherData) : tr}
                    >
                        <GridToolbar>
                            {
                                module && module === "workOrders" ?
                                    <div class="work_order_toolbar table_header">
                                        <div class="header_left">
                                            <div class="left_inner_header">

                                                <div className="search_field active"><label htmlFor="search">Search</label>
                                                    <input placeholder={otherData.searchPlaceholder || 'Search'} value={search} onChange={handleSearch} />
                                                </div>
                                                {handleHeaderActionJSX(
                                                    module,
                                                    accessLevel,
                                                    selectedState,
                                                    addRecord,
                                                    bulkDeleteAction,
                                                    refreshList,
                                                    openPopup,
                                                    otherData,
                                                    reqParams,
                                                    setReqParams,
                                                    updateItem,
                                                    setInfoPopup,
                                                    setMsgClass,
                                                    setToastMsg,
                                                    headerDropdown,
                                                    setHeaderDropdown,
                                                    data,
                                                    setShowLoader,
                                                    customGroupBy,
                                                    changes,
                                                    saveChanges,
                                                    cancelChanges,
                                                    selectedView,
                                                    setSelectedView,
                                                )}
                                            </div>
                                        </div>
                                        <div className="header_right">
                                            <div className="right_inner_header">
                                                <button className={`work_btn_style btn2 add_btn tooltip_cal_sm ${isDisabled('add', accessLevel) ? 'disabled' : ''}`}
                                                    onClick={() => {
                                                        openPopup("AddWO")
                                                    }}> <span>Add New </span></button>

                                                <button className="work_btn_style btn2 export_btn tooltip_cal_md" onClick={excelExport}>
                                                    <span>Export to Excel</span></button>

                                                <div className="vert_line"></div>
                                                <BulkDeleteBtnWO
                                                    selectedState={selectedState}
                                                    onClick={() => bulkDeleteAction(null)}
                                                    accessLevel={accessLevel}
                                                    data={data}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                    :
                                    module === 'currentSchedule' || module === 'futureSchedule' || module === 'completedSchedule' ?
                                        <div class="active_schedule_toolbar table_header">
                                            <div class="header_left">
                                                <ActiveScheduleHeaders
                                                    refreshList={refreshList}
                                                    otherData={otherData}
                                                    handleSearch={handleSearch}
                                                    search={search}
                                                />
                                            </div>
                                            <div className="header_right">
                                                <div className="right_inner_header">
                                                    <BulkDeleteBtn
                                                        selectedState={selectedState}
                                                        onClick={() => bulkDeleteAction(null)}
                                                        accessLevel={accessLevel}
                                                        data={data}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        // normal header flow start from here
                                        <div className={`kendoHeader ${toastMsg && !msgClass ? 'headerPull' : ''}`}>
                                            <div className="kHeaderLeft">
                                                {handleHeaderActionJSX(
                                                    module,
                                                    accessLevel,
                                                    selectedState,
                                                    addRecord,
                                                    bulkDeleteAction,
                                                    refreshList,
                                                    openPopup,
                                                    otherData,
                                                    reqParams,
                                                    setReqParams,
                                                    updateItem,
                                                    setInfoPopup,
                                                    setMsgClass,
                                                    setToastMsg,
                                                    headerDropdown,
                                                    setHeaderDropdown,
                                                    data,
                                                    setShowLoader,
                                                    customGroupBy,
                                                    changes,
                                                    onClickHandler,
                                                    cancelChanges,
                                                    selectedView,
                                                    setSelectedView,
                                                    search,
                                                    handleSearch
                                                )}
                                            </div>
                                            {
                                                module === "buildReports" ?
                                                    <div className="BuildReportsHeaders" style={{ textAlign: "right" }}>
                                                        {otherData.showExportDropdown ?
                                                            <ExportButton
                                                                onClick={type => {
                                                                    switch (type) {
                                                                        case "xl":
                                                                            customExportFunction(gridRef.current, _export.current, { columnFormatMap: otherData.columnFormatMap || {}, aggregateText: otherData.aggregateText, column: columns, minutesFormat: otherData.minutesFormat })
                                                                            break;
                                                                        case "pdf":
                                                                            pdfExportRef.current?.save()
                                                                            break;
                                                                        case "csv":
                                                                            exportToCSV(_export, otherData.columnFormatMap || {}, csvDownloadRef, module, otherData.minutesFormat)
                                                                            break;
                                                                    }
                                                                }}
                                                                accessLevel={accessLevel}
                                                                module={module}
                                                            /> : ""}
                                                        <div class="vertical-line" style={{ marginTop: "20px" }}></div>
                                                        <SaveTemplate dataState={dataState} columns={columns} subModule={subModule} setMsgClass={setMsgClass} setToastMsg={setToastMsg} setShowLoader={setShowLoader} />
                                                    </div>
                                                    :
                                                    <div className="kHeaderRight">
                                                        <div className="kHeaderRight">
                                                            <div className="kHeaderRTop">
                                                                {
                                                                    otherData.showExportDropdown ?
                                                                        <ExportDropdown
                                                                            onClick={type => {
                                                                                switch (type) {
                                                                                    case "xl":
                                                                                        customExportFunction(gridRef.current, _export.current, { columnFormatMap: otherData.columnFormatMap || {}, aggregateText: otherData.aggregateText, column: columns, minutesFormat: otherData.minutesFormat })
                                                                                        break;
                                                                                    case "pdf":
                                                                                        pdfExportRef.current?.save()
                                                                                        break;
                                                                                    case "csv":
                                                                                        exportToCSV(_export, otherData.columnFormatMap || {}, csvDownloadRef, module, otherData.minutesFormat)
                                                                                        break;
                                                                                }
                                                                            }}
                                                                            accessLevel={accessLevel}
                                                                        />
                                                                        :
                                                                        <>
                                                                            {otherData.enableExportToPDF && <button
                                                                                title="Export PDF"
                                                                                className="btnStyle btn2 pdfBtn"
                                                                                onClick={pdfExport}
                                                                            >
                                                                                Export to PDF
                                                                            </button>}
                                                                            {!otherData.disableExportToExcel && <button
                                                                                title="Export Excel"
                                                                                className="btnStyle btn2 xlBtn"
                                                                                onClick={excelExport}
                                                                            >
                                                                                Export to Excel
                                                                            </button>}
                                                                        </>
                                                                }
                                                                {module === moduleTypes.monitorEmployees &&
                                                                    <>
                                                                        {reportAccessLevel > 0 ? <button
                                                                            title='Map'
                                                                            className='btnStyle btn2 btnMap'
                                                                            onClick={() => {
                                                                                const groupId = headerDropdown.split(":")[1]
                                                                                const phoneList = data.filter(d => selectedState[d.id]).map(d => d.deviceId).join(",")

                                                                                const form = document.getElementById('map_input_sub_form');
                                                                                form.phoneList.value = phoneList;
                                                                                form.groupList.value = groupId;

                                                                                form.submit();
                                                                            }}
                                                                            disabled={isDisabled('view', accessLevel)}
                                                                        >
                                                                            Map
                                                                        </button>
                                                                            : ""}
                                                                        <form id="map_input_sub_form" method="post" target='_blank' action={`${process.env.REACT_APP_API_URL}/track/mgt?page=manageteaminfoV1`} onSubmit={e => e.preventDefault()}>
                                                                            <input type="hidden" name="phoneList" value="" />
                                                                            <input type="hidden" name="groupList" value="" />
                                                                        </form>
                                                                    </>
                                                                }
                                                                {!otherData.disableGlobalSearch && <div className='searchFld'>
                                                                    <input placeholder={otherData.searchPlaceholder || 'Search'} value={search} onChange={handleSearch} />
                                                                </div>}
                                                            </div>
                                                            <div className="kHeaderRBot">
                                                                {
                                                                    otherData.hideSaveCancelChanges
                                                                        ?
                                                                        null
                                                                        :
                                                                        <>
                                                                            <button
                                                                                title="Save Changes"
                                                                                className="btnStyle btn2 btnSave"
                                                                                onClick={onClickHandler}
                                                                                disabled={(accessLevel.childMenu ? (accessLevel.childMenu < 2 || !changes) : !changes)}
                                                                            >
                                                                                Save Changes
                                                                            </button>
                                                                            <button
                                                                                title="Cancel Changes"
                                                                                className="btnStyle btn2 btnCancel"
                                                                                onClick={onCancleHandler}
                                                                                disabled={!changes}
                                                                            >
                                                                                Cancel Changes
                                                                            </button>

                                                                        </>
                                                                }
                                                                {/* {module === moduleTypes.editReports &&
                                                                    (
                                                                        <>
                                                                            <SaveGridStateBtn onClick={saveGridState} accessLevel={accessLevel} />
                                                                            <ResetGridStateBtn onClick={resetGridState} accessLevel={accessLevel} />
                                                                        </>
                                                                    )
                                                                } */}
                                                            </div>

                                                            {
                                                                module === moduleTypes.runNBuildReports
                                                                    ?
                                                                    <div className="kHeaderRBot">
                                                                        <button
                                                                            title="Download"
                                                                            className="btnStyle btn2 btnDownload"
                                                                            onClick={() => openPopup('download')}
                                                                        >
                                                                            Download
                                                                        </button>
                                                                        <button
                                                                            title="Email"
                                                                            className="btnStyle btn2 btnEmail"
                                                                            onClick={() => openPopup('email')}
                                                                        >
                                                                            Email
                                                                        </button>
                                                                    </div>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                            }
                        </GridToolbar>
                        {otherData.noRecordText &&
                            <GridNoRecords>
                                <div className='tblNoData' >
                                    {otherData.noRecordText}
                                </div>
                            </GridNoRecords>
                        }
                        {/* logic to lock the selected column only for editReports */}
                        {
                            !otherData.hideCheckBox && module === moduleTypes.editReports ?
                                <Column
                                    field={SELECTED_FIELD}
                                    width="50px"
                                    headerSelectionValue={checkHeaderSelectionValue()}
                                    filterable={false}
                                    groupable={false}
                                    orderIndex={0}
                                    reorderable={false}
                                    resizable={false}
                                    locked={true}
                                /> : module === moduleTypes.timeClockReport ? <></> : <Column
                                    field={SELECTED_FIELD}
                                    width="50px"
                                    headerSelectionValue={checkHeaderSelectionValue()}
                                    filterable={false}
                                    groupable={false}
                                    orderIndex={0}
                                    reorderable={false}
                                    resizable={false}
                                />
                        }

                        <Column
                            title='#'
                            field='id'
                            width="50px"
                            editable={false}
                            filterable={false}
                            groupable={false}
                            sortable={false}
                            cell={IdCell}
                            orderIndex={0}
                            reorderable={false}
                            resizable={false}
                        />
                        {
                            columns.map((col, ind) => (
                                col.show && <Column
                                    key={ind}
                                    field={col.field}
                                    title={col.title}
                                    editable={accessLevel.childMenu < 2 ? false : col.editable}
                                    width={col.width}
                                    editor={col.editor}
                                    filter={col.filter}
                                    locked={col.locked}
                                    format={col.format}
                                    orderIndex={col.orderIndex}
                                    cellOptions={col.field === 'elapsedTimeSec' && {
                                        format: 'hh:mm:ss'
                                    }}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={columns}
                                            onColumnsSubmit={onColumnsSubmit}
                                            onColumnLockToggle={handleColumnLockToggle}
                                            locked={col.locked || false}
                                            hideMenuCloumns={otherData?.hideMenuCloumns}
                                        />
                                    )}
                                    cell={col.dropdownCell ? handleCellDropdown(col) : col.customCell ? handleCustomCells(col.field, openPopup, handleCellFieldSelection, editID, editField, onSave, accessLevel.childMenu < 2 ? false : col.editable, otherData) : InlineEditCell(col)}
                                    headerCell={col.headerCell ? props => CustomHeaderCell(props, col.headerCell, dataState.sort) : null}
                                />
                            ))
                        }
                        {actionColumnBtns.length > 0 && <Column
                            field="action"
                            orderIndex={columns.length + 1}
                            title="Action"
                            headerClassName='actionColHeader'
                            width={20 * (actionColumnBtns.length + 1) > actionColumnMinWidth ? 20 * (actionColumnBtns.length + 1) : actionColumnMinWidth}
                            cell={props => renderActionCell(props, accessLevel, selectedState, actionColumnBtns, actionColumnHandler, setDeletePopup, module)}
                            groupable={false}
                            filterable={false}
                            sortable={false}
                            locked={lockActionColumn}
                        />}

                    </Grid> 
                <PDFGrid />
                <SiteLoader isActive={showLoader} />
            </div>
            <Prompt
                when={Object.keys(dirty).length > 0}
                message='The changes are still unsaved. Are you sure you want to leave?'
            />
            {toastMsg && <ToastMessage
                cssClass={msgClass}
                message={toastMsg}
                onClose={() => setToastMsg('')}
                hideOverlay={true}
            />}
            {infoPopup.show
                &&
                <InfoPopup
                    msg={infoPopup.msg}
                    onClick={() => setInfoPopup({ show: false, msg: "" })}
                />}
            {deletePopup.show && module !== "workOrders" ?
                <DeletePopup
                    onSuccess={handleDelete}
                    closePopup={() => setDeletePopup({
                        show: false,
                        data: null,
                        type: ''
                    })}
                    msg={deletePopup.type === 'single' ? 'Are you sure you want to delete this record?' : 'Are you sure you want to delete the selected records?'}
                />
                :
                ""
            }
            {deletePopup.show && module === "workOrders" ?
                <WorkOrderDeletePopup
                    onDeleteSeries={handleDeleteSeries}
                    onDeleteCurrent={handleDeleteCurrent}
                    closePopup={() => setDeletePopup({
                        show: false,
                        data: null,
                        type: ''
                    })}
                />
                :
                ""
            }

            <CancelChangesPopup
                showDialog={cancelChangesPopup}
                message={"Are you sure you want to cancel the submission of this information?"}
                onConfirm={() => {
                    setCancelChangesPopup(false)
                    onClickHandler()
                }}
                onCancel={() => {
                    resetData()
                    setChanges(false);
                    setCancelChangesPopup(false)
                }}
            />
            <div className={`overlay ${(deletePopup.show || infoPopup.show) ? 'active' : ''}`} />
            <a hidden ref={csvDownloadRef} />
        </>
    )

    function PDFGrid() {
        return (
            <GridPDFExport ref={pdfRef => pdfExportRef.current = pdfRef} fileName={otherData.exportFileName || module} >
                <Grid
                    className={'kendoGridHeight'}
                    data={newData}
                    group={group}
                    groupable={{
                        enabled: groupable,
                        footer: otherData.enableFooterDisplay ? 'visible' : 'none'
                    }}
                >
                    {
                        columns.map((col, ind) => (
                            col.show && <Column
                                key={ind}
                                field={col.field}
                                title={col.title}
                                editable={accessLevel.childMenu < 2 ? false : col.editable}
                                width={col.width}
                                editor={col.editor}
                                filter={col.filter}
                                columnMenu={(props) => (
                                    <ColumnMenu
                                        {...props}
                                        columns={columns}
                                        onColumnsSubmit={onColumnsSubmit}
                                        onColumnLockToggle={handleColumnLockToggle}
                                        locked={col.locked || false}
                                    />
                                )}
                                cell={col.dropdownCell ? handleCellDropdown(col) : col.customCell ? handleCustomCells(col.field, openPopup, handleCellFieldSelection, editID, editField, onSave, accessLevel.childMenu < 2 ? false : col.editable, otherData) : InlineEditCell(col)}
                            />
                        ))
                    }

                </Grid>
            </GridPDFExport>
        )
    }
}

export default forwardRef(KendoGrid)