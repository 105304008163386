import { useState } from "react";
import { RefreshBtn, AddNewBtn, BulkDeleteBtn, StatusDropdown, ActiveInactiveBtn, OTPPreferenceHandler, CustomCheckBox, BulkSuspendBtn, MessageBtn, AddNewDropdown, WeekDayViewDropdown, RejectBtn } from "./HeaderComponents"
import { isDisabled, moduleTypes } from "./kendoUtils"
import { DatePicker, DateTimePicker } from "@progress/kendo-react-dateinputs";
import MultiSelectDropdown from '../dropdown/MultiSelectDropdown';
import { formatDate } from "@progress/kendo-intl";
import { DropdownList } from "../dropdown";
import { getAccountLabel } from "../../../utils";
import { FILTER_TYPE } from '../../report/RunReports/MyReportsUtils/myReportUtils'

const SettingsActions = ({ addRecord, accessLevel, bulkDelete, selectedState, data }) => (
    <div className="kHeaderLTop">
        <AddNewBtn
            onClick={addRecord}
            accessLevel={accessLevel}
        />
        <BulkDeleteBtn
            selectedState={selectedState}
            onClick={bulkDelete}
            accessLevel={accessLevel}
            data={data}
        />
    </div>
)

const GroupHeaderActions = ({ accessLevel, selectedState, refreshList, openPopup, bulkDelete, data }) => (
    <div className="kHeaderLTop">
        <RefreshBtn
            onClick={() => refreshList()}
            accessLevel={accessLevel}
        />
        <AddNewBtn
            onClick={openPopup}
            accessLevel={accessLevel}
        />
        <BulkDeleteBtn
            selectedState={selectedState}
            onClick={bulkDelete}
            accessLevel={accessLevel}
            data={data}
        />
    </div>
)

const EmployeeHeaderActions = ({ accessLevel, refreshList, openPopup, selectedState, bulkDelete, data }) => (
    <div className="kHeaderLTop">
        <RefreshBtn
            onClick={() => refreshList()}
            accessLevel={accessLevel}
        />
        <AddNewBtn
            onClick={openPopup}
            accessLevel={accessLevel}
        />
        <BulkDeleteBtn
            selectedState={selectedState}
            onClick={bulkDelete}
            accessLevel={accessLevel}
            data={data}
        />
    </div>
)

const JobSitesHeaderActions = ({
    accessLevel,
    selectedState,
    refreshList,
    dropDownValue,
    onDropDownChange,
    addNew,
    onMapClick,
    onRadiusClick,
    assignTask,
    bulkDelete,
    activateInactivate,
    data,
    assignEmployees
}) => {
    const employeeLabel = getAccountLabel('employee');
    const taskLabel = getAccountLabel('task');
    return (
        <>
            <div className="kHeaderLTop">
                <RefreshBtn
                    onClick={() => refreshList()}
                    accessLevel={accessLevel}
                />
                <StatusDropdown
                    dropDownValue={dropDownValue}
                    onChange={onDropDownChange}
                    dropDownData={[
                        { label: 'Active', value: "Yes" },
                        { label: 'Inactive', value: "No" }
                    ]}
                    accessLevel={accessLevel}
                />
            </div>
            <div className='kHeaderLBot'>
                <AddNewBtn
                    onClick={addNew}
                    accessLevel={accessLevel}
                />
                <button
                    title='Map'
                    className='btnStyle btn2 btnMap'
                    onClick={onMapClick}
                    disabled={isDisabled('view', accessLevel)}
                >
                    Map
                </button>
                <button
                    title='Radius'
                    className='btnStyle btn2 btnRadius'
                    onClick={onRadiusClick}
                    disabled={isDisabled('edit', accessLevel)}
                >
                    Radius
                </button>
                <button
                    title='Assign Tasks'
                    className='btnStyle btn2 btnAssignTasks'
                    onClick={assignTask}
                    disabled={isDisabled('edit', accessLevel)}
                >
                    Assign {taskLabel}
                </button>
                <button
                    title='Assign Employees'
                    className='btnStyle btn2 btnEmployees'
                    onClick={assignEmployees}
                    disabled={isDisabled('edit', accessLevel)}
                >
                    Assign {employeeLabel}
                </button>
                <BulkDeleteBtn
                    selectedState={selectedState}
                    onClick={bulkDelete}
                    accessLevel={accessLevel}
                    data={data}
                />
                <ActiveInactiveBtn
                    active={dropDownValue === "Yes"}
                    onClick={activateInactivate}
                    accessLevel={accessLevel}
                />
            </div>
        </>
    )
}

const ManagerHeaderActions = ({ accessLevel, selectedState, refreshList, openPopup, bulkDelete, onSMSClick, onOTPClick, data }) => (
    <>
        <div className="kHeaderLTop">
            <RefreshBtn
                onClick={() => refreshList()}
                accessLevel={accessLevel}
            />
            <AddNewBtn
                onClick={openPopup}
                accessLevel={accessLevel}
            />
            <BulkDeleteBtn
                selectedState={selectedState}
                onClick={bulkDelete}
                accessLevel={accessLevel}
                data={data}
            />
            <OTPPreferenceHandler
                onSMSClick={onSMSClick}
                onOTPClick={onOTPClick}
                accessLevel={accessLevel}
            />
        </div>
    </>
)

const TaskHeaderActions = ({ accessLevel, selectedState, refreshList, addNew, bulkDelete, dropDownValue, onDropDownChange, activateInactivate, data }) => {

    return (
        <>
            <div className="kHeaderLTop">
                <RefreshBtn
                    onClick={() => refreshList()}
                    accessLevel={accessLevel}
                />
                <StatusDropdown
                    dropDownValue={dropDownValue}
                    onChange={onDropDownChange}
                    dropDownData={[
                        { label: 'Active', value: true },
                        { label: 'Inactive', value: false }
                    ]}
                    accessLevel={accessLevel}
                />
            </div>
            <div className='kHeaderLBot'>
                <AddNewBtn
                    onClick={addNew}
                    accessLevel={accessLevel}
                />
                <BulkDeleteBtn
                    selectedState={selectedState}
                    onClick={bulkDelete}
                    accessLevel={accessLevel}
                    data={data}
                />
                <ActiveInactiveBtn
                    active={dropDownValue}
                    onClick={activateInactivate}
                    accessLevel={accessLevel}
                />
            </div>
        </>)
}

const FormsHeaderActions = ({ accessLevel, selectedState, refreshList, addNew, bulkDelete, dropDownValue, onDropDownChange, assignForms, data }) => (
    <>
        <div className="kHeaderLTop">
            <RefreshBtn
                onClick={() => refreshList()}
                accessLevel={accessLevel}
            />
            <StatusDropdown
                dropDownValue={dropDownValue}
                onChange={onDropDownChange}
                dropDownData={[
                    { label: 'Active', value: true },
                    { label: 'Inactive', value: false }
                ]}
                accessLevel={accessLevel}
            />
        </div>
        <div className='kHeaderLBot'>
            <AddNewBtn
                onClick={addNew}
                accessLevel={accessLevel}
            />
            <button
                title='Assign Tasks'
                className='btnStyle btn2 btnAssignTasks'
                onClick={assignForms}
                disabled={isDisabled('edit', accessLevel)}
            >
                Assign Forms
            </button>
            <BulkDeleteBtn
                selectedState={selectedState}
                onClick={bulkDelete}
                accessLevel={accessLevel}
                data={data}
            />

        </div>
    </>
)

export const RulesSchedulerActions = ({ accessLevel, refreshList, openPopup, selectedState, bulkDelete, data }) => (
    <div className="kHeaderLTop">
        <RefreshBtn
            onClick={() => refreshList()}
            accessLevel={accessLevel}
        />
        <AddNewBtn
            onClick={openPopup}
            accessLevel={accessLevel}
        />
        <BulkDeleteBtn
            selectedState={selectedState}
            onClick={bulkDelete}
            accessLevel={accessLevel}
            data={data}
        />
    </div>
)

export const SchedulerHeader = ({ accessLevel, selectedState, refreshList, bulkDelete, addNew, dropDownValue, onDropDownChange, data }) => (
    <>
        <div className="kHeaderLTop">
            <RefreshBtn
                onClick={() => refreshList()}
                accessLevel={accessLevel}
            />
            <StatusDropdown
                dropDownValue={dropDownValue}
                onChange={onDropDownChange}
                dropDownData={[
                    { label: 'Active', value: 'active' },
                    { label: 'Inactive', value: 'inActive' }
                ]}
                accessLevel={accessLevel}
            />
            <AddNewBtn
                onClick={addNew}
                accessLevel={accessLevel}
            />
            <BulkDeleteBtn
                selectedState={selectedState}
                onClick={bulkDelete}
                accessLevel={accessLevel}
                data={data}
            />
        </div>
    </>
)

export const EditReportHeaders = ({ accessLevel, selectedState, refreshList, dropdownData, dateFormat, defaultStartDate, defaultEndDate, addNew, bulkDelete, subPage = "editTimeclock" | "advanceTimeclock" | "distanceAudit" | "editMile", customGroupBy, onReject, changes, saveChanges, cancelChanges, data, selectedView, setSelectedView, cancelChangesPopup, setCancelChangesPopup, onClickHandler, resetData, setChanges }) => {
    const globalStartDate = sessionStorage.getItem('EditReport:startDate')
    const globalEndDate = sessionStorage.getItem('EditReport:endDate')
    const reportForValues = sessionStorage.getItem('EditReport:reportFor')?.split(",")

    const [selectedValues, setSelectedValues] = useState(reportForValues ? dropdownData.filter(data => reportForValues.includes(data.value)) : [])
    const [startDate, setStartDate] = useState(globalStartDate ? new Date(globalStartDate) : new Date(defaultStartDate))
    const [endDate, setEndDate] = useState(globalEndDate ? new Date(globalEndDate) : new Date(defaultEndDate))
    const [editedDate, setEditedDate] = useState(false)
    const [isTxnIdIncluded, setIsTxnIdIncluded] = useState(false)
    const [filterType, setFilterType] = useState('all')

    const groupLabel = getAccountLabel('group');

    return (
        <>
            <div className="kHeaderLTop">
                <div className="singleLineFld wrappableRow">
                    <div>
                        <label>Device / {groupLabel}</label>
                        <MultiSelectDropdown
                            data={dropdownData}
                            dataItemKey={'value'}
                            textField={'text'}
                            value={selectedValues}
                            onChange={e => setSelectedValues(e.value)}
                            className={"smallMultiSelect"}
                        />
                    </div>
                    <div>
                        <label>Start Date</label>
                        <DatePicker
                            placeholder={"Start date"}
                            onChange={(e) => {
                                if (e.target.value.getTime() <= endDate.getTime()) {
                                    setStartDate(e.target.value)
                                }
                            }}
                            value={startDate}
                            className={"dateFld"}
                            format={dateFormat}
                        />
                    </div>
                    <div>
                        <label>End Date</label>
                        <DatePicker
                            placeholder={"End date"}
                            onChange={(e) => {
                                if (e.target.value.getTime() >= startDate.getTime()) {
                                    setEndDate(e.target.value)
                                }
                            }}
                            value={endDate}
                            className={"dateFld"}
                            format={dateFormat}
                        />
                        {subPage === 'distanceAudit' && <CustomCheckBox
                            accessLevel={accessLevel}
                            checked={editedDate}
                            onChange={() => setEditedDate(!editedDate)}
                            label="Edited Date"
                        />
                        }
                    </div>
                    <RefreshBtn
                        onClick={() => refreshList({
                            selectedValues: selectedValues.map(val => val.value).join(","),
                            startDate: formatDate(startDate, 'yyyy/MM/dd'),
                            endDate: formatDate(endDate, 'yyyy/MM/dd'),
                            actual_date: subPage === 'distanceAudit' ? editedDate : null,
                            filterType
                        })}
                        accessLevel={accessLevel}
                    />
                </div>
            </div>
            <div className='kHeaderLBot'>
                {
                    ['editTimeclock', 'advanceTimeclock'].includes(subPage) &&
                    <>
                        {
                            subPage === 'advanceTimeclock'
                                ?
                                <AddNewDropdown
                                    onClick={type => addNew(type)}
                                    accessLevel={accessLevel}
                                    resetData={resetData}
                                    cancelChangesPopupAddNew={cancelChangesPopup}
                                    setCancelChangesPopupAddNew={setCancelChangesPopup}
                                    onClickHandler={onClickHandler}
                                    setChanges={setChanges}
                                />
                                :
                                <AddNewBtn
                                    onClick={addNew}
                                    accessLevel={accessLevel}
                                />
                        }
                        <BulkDeleteBtn
                            selectedState={selectedState}
                            onClick={bulkDelete}
                            accessLevel={accessLevel}
                            data={data}
                        />
                        <WeekDayViewDropdown
                            accessLevel={accessLevel}
                            selectedView={selectedView}
                            setSelectedView={setSelectedView}
                            resetData={resetData}
                            setChanges={setChanges}
                            onClick={view => {

                                const columnsToShow = ['weekName', 'dayName']
                                const fieldsToGroup = subPage === 'advanceTimeclock' ? ['deviceName', 'weekName', 'startTCSentDate'] : ['deviceName', 'weekName', 'tcSentDate']

                                if (view === 'week') {
                                    customGroupBy(fieldsToGroup, columnsToShow)
                                } else {
                                    customGroupBy([], columnsToShow, [...fieldsToGroup])
                                }
                            }}
                        />
                    </>
                }
                {
                    subPage === 'auditReport' &&
                    <>
                        <StatusDropdown
                            onChange={(e) => {
                                const { value } = e.target;
                                setFilterType(value)
                                refreshList({
                                    selectedValues: selectedValues.map(val => val.value).join(","),
                                    startDate: formatDate(startDate, 'yyyy/MM/dd'),
                                    endDate: formatDate(endDate, 'yyyy/MM/dd'),
                                    actual_date: subPage === 'distanceAudit' ? editedDate : null,
                                    filterType: value
                                })
                            }}
                            dropDownData={[
                                {
                                    value: 'all',
                                    label: 'All records'
                                },
                                {
                                    value: 'Requested',
                                    label: 'Change-requested records'
                                },
                                {
                                    value: 'Add_Requested',
                                    label: 'Create-requested records'
                                },
                                {
                                    value: 'Approved',
                                    label: 'Approved records'
                                },
                                {
                                    value: 'Rejected',
                                    label: 'Rejected records'
                                },
                            ]}
                            accessLevel={accessLevel}
                            label={'Filter  '}
                            width={150}
                        />
                        <button
                            title="Approve Changes"
                            className="btnStyle btn2 btnSave"
                            onClick={saveChanges}
                            disabled={(accessLevel.childMenu ? (accessLevel.childMenu < 2) : false)}
                            style={{ marginLeft: 8 }}
                        >
                            Approve Changes
                        </button>
                        <RejectBtn
                            accessLevel={accessLevel}
                            onClick={onReject}
                        />
                        <button
                            title="Cancel Changes"
                            className="btnStyle btn2 btnCancel"
                            onClick={cancelChanges}
                            disabled={!changes}
                        >
                            Cancel Changes
                        </button>
                    </>
                }
            </div>
        </>
    )
}

export const RunReportHeaders = ({ accessLevel, selectedState, refreshList, dropdownData, defaultStartDate, defaultEndDate, reportFor, subPage, data, moduleTypes, setMsgClass, setToastMsg }) => {
    const { device, groups, selectedOption, timezone, dateDisplayFormat, timeFormat, isEditHistoryReport } = dropdownData;

    const advancedOptionReports = ['time', 'workOrderPerformance']

    const [reportForSelection, setReportForSelection] = useState({
        option: selectedOption,
        value: reportFor
    })
    const { params } = JSON.parse(sessionStorage.getItem('runReports'))

    const [startDate, setStartDate] = useState(new Date(params?.date_fr || new Date()))
    const [endDate, setEndDate] = useState(new Date(params?.date_to || new Date()))
    const [advancedOption, setAdvanceOptions] = useState(false)
    const [extraParams, setExtraParams] = useState({
        stageID: ['99,99', false, 'none', 'none'],
        workorderType: 'allWorkorders'
    })

    const handleStageID = (ind, value) => {
        const { stageID } = extraParams;

        stageID[ind] = value

        setExtraParams({
            ...extraParams,
            stageID
        })
    }

    const handleRefresh = () => {
        const params = {
            date_fr: `${formatDate(startDate, 'yyyy/MM/dd')}/${startDate.toTimeString().slice(0, 5)}`,
            date_to: `${formatDate(endDate, 'yyyy/MM/dd')}/${endDate.toTimeString().slice(0, 5)}`,
            // stageid: extraParams.stageID.join(),
            reportFor: reportForSelection.value,
            [reportForSelection.option === 'group' ? 'groupID' : 'deviceID']: reportForSelection.value
        }

        refreshList(params)
    }

    const handleDropdownValue = e => {
        setReportForSelection({ ...reportForSelection, value: e.value.id })
    }

    const employeeLabel = getAccountLabel('employee');
    const groupLabel = getAccountLabel('group');

    return (
        <>
            <div className="kHeaderLTop">
                <div className="singleLineFld">
                    <label>Start Date & Time</label>

                    <DateTimePicker
                        placeholder={"Start date"}
                        onChange={(e) => {
                            if (isEditHistoryReport) {
                                const selectedDate = e.target.value;
                                if (selectedDate) {
                                    const fifteenDaysLater = new Date(selectedDate);
                                    fifteenDaysLater.setDate(fifteenDaysLater.getDate() + 15);

                                    if (!endDate || selectedDate.getTime() <= endDate.getTime()) {
                                        if (endDate && endDate.getTime() > fifteenDaysLater.getTime()) {
                                            setMsgClass("msgError")
                                            setToastMsg(`The Start Date must be within 15 days of the End Date.`)
                                        } else {
                                            setStartDate(selectedDate);
                                        }
                                    }
                                }
                            } else {
                                if (e.value && e.target.value.getTime() <= endDate.getTime()) {
                                    setStartDate(e.target.value)
                                }
                            }
                        }}
                        value={startDate}
                        className={"medFld"}
                        format={`${dateDisplayFormat} ${timeFormat}`}
                    />
                    <label style={{ marginLeft: '32px' }}>End Date & Time</label>

                    <DateTimePicker
                        placeholder={"End date"}
                        onChange={(e) => {
                            if (isEditHistoryReport) {
                                const selectedDate = e.target.value;
                                if (selectedDate) {
                                    const fifteenDaysEarlier = new Date(selectedDate);
                                    fifteenDaysEarlier.setDate(fifteenDaysEarlier.getDate() - 15);

                                    if (!startDate || selectedDate.getTime() >= startDate.getTime()) {
                                        if (startDate && startDate.getTime() < fifteenDaysEarlier.getTime()) {
                                            setMsgClass("msgError")
                                            setToastMsg(`The End Date must be within 15 days of the Start Date.`)

                                        } else {
                                            setEndDate(selectedDate);
                                        }
                                    }
                                }
                            } else {

                                if (e.value && e.target.value.getTime() >= startDate.getTime()) {
                                    setEndDate(e.target.value)
                                }
                            }

                        }}
                        value={endDate}
                        className={"medFld"}
                        format={`${dateDisplayFormat} ${timeFormat}`}
                    />
                    <RefreshBtn
                        onClick={handleRefresh}
                        accessLevel={accessLevel}
                    />
                    {/* <label>Select Timezone</label>
                    <select name="timezone" defaultValue={timezone} className="smlFld2" >
                        {
                            timeZones.map((t, ind) => (
                                <option key={ind} value={t.value}>{t.label}</option>
                            ))
                        }
                    </select> */}
                </div>
            </div>
            {dropdownData.filterType !== FILTER_TYPE.NONE && (
                <div className='kHeaderLBot'>
                    <div className="singleLineFld">
                        <label>Select</label>
                        {(!dropdownData?.filterType || dropdownData.filterType === FILTER_TYPE.BOTH) && (
                            <>
                                <div className="customRadio">
                                    <input
                                        type="radio"
                                        id="Group"
                                        name="selectGroup"
                                        checked={reportForSelection.option === 'group'}
                                        onChange={() => setReportForSelection({ option: 'group', value: "all" })}
                                    />
                                    <label htmlFor="Group">{groupLabel}</label>
                                </div>
                                <div className="customRadio">
                                    <input
                                        type="radio"
                                        id="Employee"
                                        name="selectEmployee"
                                        checked={reportForSelection.option === 'employee'}
                                        onChange={() => setReportForSelection({ option: 'employee', value: "none" })}
                                    />
                                    <label htmlFor="Employee">{employeeLabel}</label>
                                </div>
                            </>
                        )}

                        <DropdownList
                            list={reportForSelection.option === 'group' ? groups : device}
                            filterable={true}
                            onChange={handleDropdownValue}
                            defaultValue={reportForSelection.value}
                            style={{ height: '27px' }}
                        />
                    </div>
                </div>
            )}

        </>
    )
}

const MonitorEmployeesHeader = ({ accessLevel, selectedState, refreshList, dropDownData, dropDownValue, onDropDownChange, addNew, bulkDelete, bulkSuspend, bulkActivate, onMessageClick, data }) => (
    <>
        <div className="kHeaderLTop">
            <RefreshBtn
                onClick={() => refreshList()}
                accessLevel={accessLevel}
            />
            <StatusDropdown
                dropDownValue={dropDownValue}
                onChange={onDropDownChange}
                dropDownData={dropDownData}
                accessLevel={accessLevel}
                label={'Filter'}
                width={150}
            />
            <AddNewBtn
                onClick={addNew}
                accessLevel={accessLevel}
            />
            <MessageBtn
                onClick={onMessageClick}
                accessLevel={accessLevel}
            />
            {
                dropDownValue.split(":")[1] === 'Suspended'
                    ?
                    <ActiveInactiveBtn
                        accessLevel={accessLevel}
                        onClick={bulkActivate}
                        active={false}
                    />
                    :
                    <BulkSuspendBtn
                        selectedState={selectedState}
                        onClick={bulkSuspend}
                        accessLevel={accessLevel}
                    />

            }
            <BulkDeleteBtn
                selectedState={selectedState}
                onClick={bulkDelete}
                accessLevel={accessLevel}
                data={data}
            />
        </div>

    </>
)

export const WorkOrderHeader = ({ accessLevel, refreshList }) => {

    const globalStartDate = sessionStorage.getItem('WO:startDate')
    const globalEndDate = sessionStorage.getItem('WO:endDate')
    const [workOrdStartDate, setWorkOrdStartDate] = useState(globalStartDate ? new Date(globalStartDate) : new Date())
    const [workOrdEndDate, setWorkOrdEndDate] = useState(globalEndDate ? new Date(globalEndDate) : new Date())

    return (<>
        <div className="date_field active"><label for="dateFrom">Date
            Range (From)</label>

            <DatePicker
                placeholder={"Start date"}
                onChange={(e) => {
                    if (e.target.value.getTime() <= workOrdEndDate.getTime()) {
                        setWorkOrdStartDate(e.target.value)
                    }
                }}
                value={workOrdStartDate}
                className={"dateFldWrk"}
            />
        </div>
        <div className="date_field active"><label for="dateTo">Date Range
            (To)</label>
            <DatePicker
                placeholder={"End date"}
                onChange={(e) => {
                    if (e.target.value.getTime() >= workOrdStartDate.getTime()) {
                        setWorkOrdEndDate(e.target.value)
                    }
                }}
                value={workOrdEndDate}
                className={"dateFldWrk"}
            />
        </div>
        <button className="work_btn_style btn2 refresh_btn tooltip_cal_sm"
            onClick={() => {
                refreshList({
                    start: formatDate(workOrdStartDate, 'yyyy/MM/dd'),
                    end: formatDate(workOrdEndDate, 'yyyy/MM/dd'),

                })
            }}
            accessLevel={accessLevel}>
            <span>Refresh</span>
        </button>
    </>)

}

export const BuildReportHeaders = ({ refreshList, params, setReqParams, handleSearch, search, accessLevel, dateFormat }) => {

    const [startDate, setStartDate] = useState(new Date(params.date_fr))
    const [endDate, setEndDate] = useState(new Date(params.date_to))

    return (
        <>
            <div className="headerRow">
                <div className="headerColumn">
                    <div className="inputWrapper">
                        <label htmlFor="search" >Search</label>
                        <input placeholder={'Search'} value={search} onChange={handleSearch} className="build-report-search" />
                    </div>
                </div>
                <div className="headerColumn">
                    <div className="inputWrapper">
                        <label htmlFor="starts">Start Date</label>
                        <DatePicker
                            placeholder={"Start date"}
                            onChange={(e) => {
                                if (e.value && e.target.value.getTime() <= endDate.getTime()) {

                                    setStartDate(e.target.value)
                                    setReqParams({ ...params, date_fr: formatDate(e.target.value, 'yyyy/MM/dd'), })
                                }
                            }}
                            value={startDate}
                            className={"dateFld"}
                            format={dateFormat}
                        />
                    </div>
                </div>
                <div className="headerColumn">
                    <div className="inputWrapper">
                        <label htmlFor="starts">End Date</label>
                        <DatePicker
                            placeholder={"End date"}
                            onChange={(e) => {
                                if (e.value && e.target.value.getTime() >= startDate.getTime()) {

                                    setEndDate(e.target.value)
                                    setReqParams({ ...params, date_to: formatDate(e.target.value, 'yyyy/MM/dd'), })
                                }
                            }}
                            value={endDate}
                            className={"dateFld"}
                            format={dateFormat}
                        />
                    </div>
                </div>
                <div className="headerColumn">
                    <div className="inputWrapper">
                        <label style={{ marginTop: "17px" }}></label>
                        <RefreshBtn
                            onClick={() => {
                                {
                                    refreshList({
                                        ...params,
                                        date_fr: formatDate(startDate, 'yyyy/MM/dd'),
                                        date_to: formatDate(endDate, 'yyyy/MM/dd'),
                                    })
                                }
                            }}
                            accessLevel={""}
                        />
                    </div>
                </div>

            </div>
        </>
    )
}

export const handleHeaderActionJSX = (
    module,
    accessLevel,
    selectedState,
    addRecord,
    bulkDeleteAction,
    refreshList,
    openPopup,
    otherData,
    reqParams,
    setReqParams,
    updateItem,
    setInfoPopup,
    setMsgClass,
    setToastMsg,
    headerDropdown,
    setHeaderDropdown,
    data,
    setShowLoader,
    customGroupBy,
    changes,
    saveChanges,
    cancelChanges,
    selectedView,
    setSelectedView,
    search,
    handleSearch

) => {

    const groupLabel = getAccountLabel('group');
    const employeeLabel = getAccountLabel('employee');
    const jobsiteLabel = getAccountLabel('jobsite');

    switch (module) {
        case moduleTypes.settings:
            return <SettingsActions
                accessLevel={accessLevel}
                addRecord={addRecord}
                selectedState={selectedState}
                bulkDelete={() => bulkDeleteAction()}
            />
        case moduleTypes.groups:
            return <GroupHeaderActions
                accessLevel={accessLevel}
                selectedState={selectedState}
                refreshList={refreshList}
                openPopup={openPopup}
                bulkDelete={() => bulkDeleteAction('groupID')}
            />
        case moduleTypes.employee:
            return <EmployeeHeaderActions
                accessLevel={accessLevel}
                refreshList={refreshList}
                openPopup={openPopup}
                selectedState={selectedState}
                bulkDelete={() => bulkDeleteAction('phoneNumber')}
            />
        case moduleTypes.jobSite:
            return <JobSitesHeaderActions
                accessLevel={accessLevel}
                selectedState={selectedState}
                refreshList={refreshList}
                dropDownValue={headerDropdown}
                onDropDownChange={e => {
                    const { value } = e.target
                    setHeaderDropdown(value)
                    if (otherData.setDropDownData) {
                        otherData.setDropDownData(value)
                    }
                    const params = {
                        ...reqParams,
                        active: value
                    }
                    setReqParams(params)
                    refreshList(params)
                }}
                addNew={() => openPopup('addNew', [])}
                assignEmployees={() => {
                    const selectedData = data.filter(d => selectedState[d.id])
                    if (selectedData.length > 0) {
                        openPopup('assignEmployees', selectedData)
                    } else {
                        setInfoPopup({
                            show: true,
                            msg: `Please select atleast 1 or more ${jobsiteLabel} to proceed`
                        })
                    }
                }}
                onMapClick={() => {
                    const selectedData = data.filter(d => selectedState[d.id])
                    if (selectedData.length > 0) {
                        openPopup('mapBtn', selectedData)
                    } else {
                        setInfoPopup({
                            show: true,
                            msg: `Please select atleast 1 or more ${jobsiteLabel} to proceed`
                        })
                    }
                }}
                onRadiusClick={() => {
                    const selectedData = data.filter(d => selectedState[d.id])
                    if (selectedData.length > 0) {
                        openPopup('radiusBtn', selectedData)
                    } else {
                        setInfoPopup({
                            show: true,
                            msg: `Please select atleast 1 or more ${jobsiteLabel} to proceed`
                        })
                    }
                }}
                assignTask={() => {
                    const selectedData = data.filter(d => selectedState[d.id])
                    if (selectedData.length > 0) {
                        openPopup('assignTask', selectedData)
                    } else {
                        setInfoPopup({
                            show: true,
                            msg: `Please select atleast 1 or more ${jobsiteLabel} to proceed`
                        })
                    }
                }}
                bulkDelete={() => bulkDeleteAction('txnId')}
                activateInactivate={async () => {
                    const selectedData = data.filter(d => selectedState[d.id])
                    if (selectedData.length > 0) {
                        try {
                            setShowLoader(true)
                            const payload = selectedData.map(d => ({
                                ...d,
                                active: headerDropdown === "No",
                                isActive: headerDropdown === "No" ? "true" : "false"
                            }))
                            const response = await updateItem(payload)
                            if (response.status === 200) {
                                await refreshList()
                                setMsgClass("msgSuccess")
                                setToastMsg(`The selected jobsite${selectedData.length > 1 ? "s" : ''} has been ${headerDropdown === "No" ? 'activated' : 'deactivated'}`)
                            }
                        } catch (error) {
                            console.log(error);
                        } finally {
                            setShowLoader(false)
                        }
                    } else {
                        setInfoPopup({
                            show: true,
                            msg: "Please select atleast 1 or more tasks to proceed"
                        })
                    }
                }}
            />
        case moduleTypes.managers:
            return <ManagerHeaderActions
                accessLevel={accessLevel}
                selectedState={selectedState}
                refreshList={refreshList}
                openPopup={() => openPopup('addNew', [])}
                bulkDelete={() => bulkDeleteAction('managerID')}
                onSMSClick={() => {
                    const selectedData = data.filter(d => selectedState[d.id])
                    if (selectedData.length === 1) {
                        openPopup('sms', selectedData[0])
                    } else {
                        setInfoPopup({
                            show: true,
                            msg: "Please select one manager to proceed"
                        })
                    }
                }}
                onOTPClick={() => {
                    const selectedData = data.filter(d => selectedState[d.id])
                    if (selectedData.length === 1) {
                        openPopup('email', selectedData[0])
                    } else {
                        setInfoPopup({
                            show: true,
                            msg: "Please select one manager to proceed"
                        })
                    }
                }}
            />
        case moduleTypes.tasks:
            return <TaskHeaderActions
                accessLevel={accessLevel}
                selectedState={selectedState}
                refreshList={refreshList}
                addNew={() => openPopup('addNew', [])}
                bulkDelete={() => bulkDeleteAction('ID')}
                dropDownValue={headerDropdown}
                onDropDownChange={e => {
                    const { value } = e.target
                    setHeaderDropdown(value === 'true')
                    if (otherData.setDropDownData) {
                        otherData.setDropDownData(value)
                    }
                    const params = {
                        ...reqParams,
                        active: value
                    }
                    setReqParams(params)
                    refreshList(params)
                }}
                activateInactivate={async () => {
                    const selectedData = data.filter(d => selectedState[d.id])
                    if (selectedData.length > 0) {
                        try {
                            setShowLoader(true)
                            const payload = selectedData.map(d => ({
                                ...d,
                                isActive: !headerDropdown
                            }))
                            const response = await updateItem(payload)
                            if (response.status === 200) {
                                await refreshList()
                                setMsgClass("msgSuccess")
                                setToastMsg(`The selected task${selectedData.length > 1 ? "s" : ''} has been ${!headerDropdown ? 'activated' : 'deactivated'}`)
                            }
                        } catch (error) {
                            console.log(error);
                        } finally {
                            setShowLoader(false)
                        }
                    } else {
                        setInfoPopup({
                            show: true,
                            msg: "Please select atleast 1 or more tasks to proceed"
                        })
                    }
                }}
            />
        case moduleTypes.forms:
            return <FormsHeaderActions
                accessLevel={accessLevel}
                selectedState={selectedState}
                dropDownValue={headerDropdown}
                onDropDownChange={e => {
                    const { value } = e.target
                    setHeaderDropdown(value === 'true')
                    if (otherData.setDropDownData) {
                        otherData.setDropDownData(value)
                    }
                    const params = {
                        ...reqParams,
                        active: value
                    }
                    setReqParams(params)
                    refreshList(params)
                }}
                refreshList={refreshList}
                addNew={() => openPopup('addNew', [])}
                bulkDelete={() => bulkDeleteAction('formId')}
                assignForms={() => {
                    const selectedData = data.filter(d => selectedState[d.id])
                    if (selectedData.length > 0) {
                        openPopup('assignTask', selectedData)
                    } else {
                        setInfoPopup({
                            show: true,
                            msg: "Please select atleast 1 or more forms to proceed"
                        })
                    }
                }}
            />
        case moduleTypes.locateScheduler:
            return <SchedulerHeader
                accessLevel={accessLevel}
                selectedState={selectedState}
                refreshList={refreshList}
                bulkDelete={() => bulkDeleteAction()}
                addNew={() => openPopup('addNew', [])}
                dropDownValue={headerDropdown}
                onDropDownChange={e => {
                    const { value } = e.target
                    setHeaderDropdown(value)
                    if (otherData.setDropDownData) {
                        otherData.setDropDownData(value)
                    }
                    const params = {
                        ...reqParams,
                        status: value
                    }
                    setReqParams(params)
                    refreshList(params)
                }}
            />
        case moduleTypes.reportScheduler:
            return (<SchedulerHeader
                accessLevel={accessLevel}
                selectedState={selectedState}
                refreshList={refreshList}
                bulkDelete={() => bulkDeleteAction()}
                addNew={() => openPopup('addNew', [])}
                dropDownValue={headerDropdown}
                onDropDownChange={e => {
                    const { value } = e.target
                    setHeaderDropdown(value)
                    if (otherData.setDropDownData) {
                        otherData.setDropDownData(value)
                    }
                    const params = {
                        ...reqParams,
                        status: value
                    }
                    setReqParams(params)
                    refreshList(params)
                }}
            />)
        case moduleTypes.rulesScheduler:
            return <RulesSchedulerActions
                accessLevel={accessLevel}
                openPopup={openPopup}
                refreshList={refreshList}
                selectedState={selectedState}
                bulkDelete={() => bulkDeleteAction('ruleID')}
            />
        case moduleTypes.editReports:
            return <EditReportHeaders
                accessLevel={accessLevel}
                dropdownData={[{ text: `${groupLabel}`, selectable: false }, ...otherData.groups, { text: `${employeeLabel}`, selectable: false }, ...otherData.employeeDropdown]}
                dateFormat={otherData.dateFormat}
                refreshList={params => {
                    const queryParams = {
                        ...reqParams,
                        reportFor: params.selectedValues,
                        date_fr: params.startDate,
                        date_to: params.endDate,
                        actual_date: params.actual_date,
                        filterType: params.filterType
                    }

                    if (params.actual_date === null) {
                        delete queryParams.actual_date
                    }

                    sessionStorage.setItem('EditReport:startDate', params.startDate)
                    sessionStorage.setItem('EditReport:endDate', params.endDate)
                    sessionStorage.setItem('EditReport:reportFor', params.selectedValues)
                    refreshList(queryParams)
                    setReqParams(queryParams)
                }}
                bulkDelete={() => bulkDeleteAction(otherData.subPage === 'advanceTimeclock' ? null : "txnID")}
                addNew={['advanceTimeclock'].includes(otherData.subPage) ? openPopup : addRecord}
                selectedState={selectedState}
                defaultStartDate={reqParams.date_fr}
                defaultEndDate={reqParams.date_to}
                subPage={otherData.subPage}
                customGroupBy={customGroupBy}
                onReject={() => {
                    const selectedData = data.filter(d => selectedState[d.id])

                    if (selectedData.length > 0) {
                        openPopup('reject', selectedData)
                    } else {
                        setInfoPopup({
                            show: true,
                            msg: "Please select atleast one report."
                        })
                    }
                }}
                changes={changes}
                saveChanges={saveChanges}
                cancelChanges={cancelChanges}
                data={data}
                moduleTypes={moduleTypes}
                selectedView={selectedView}
                setSelectedView={setSelectedView}
            />
        case moduleTypes.runNBuildReports:
            return <RunReportHeaders
                accessLevel={accessLevel}
                dropdownData={otherData.dropdownData}
                refreshList={params => {

                    delete reqParams.groupID
                    delete reqParams.deviceID

                    const queryParams = {
                        ...reqParams,
                        ...params
                    }

                    if (queryParams.deviceID && queryParams.deviceID === 'none') {
                        setToastMsg('Please select an Employee')
                        return
                    }

                    refreshList(queryParams)
                    setReqParams(queryParams)
                }}
                selectedState={selectedState}
                defaultStartDate={reqParams.date_fr}
                defaultEndDate={reqParams.date_to}
                subPage={otherData.subPage}
                reportFor={reqParams.reportFor}
                data={data}
                moduleTypes={moduleTypes}
                setMsgClass={setMsgClass}
                setToastMsg={setToastMsg}
            />
        case moduleTypes.monitorEmployees:
            return <MonitorEmployeesHeader
                refreshList={refreshList}
                openPopup={openPopup}
                addNew={() => openPopup('addNew')}
                accessLevel={accessLevel}
                selectedState={selectedState}
                onMessageClick={() => {
                    const selectedData = data.filter(d => selectedState[d.id])

                    if (selectedData.length > 0) {
                        openPopup('message', selectedData)
                    } else {
                        setInfoPopup({
                            show: true,
                            msg: `Please select ${employeeLabel}.`
                        })
                    }
                }}
                bulkSuspend={() => {
                    const selectedData = data.filter(d => selectedState[d.id])

                    if (selectedData.length > 0) {
                        openPopup('bulkSuspend', selectedData)
                    } else {
                        setInfoPopup({
                            show: true,
                            msg: "Please select atleast 1 or more device to proceed"
                        })
                    }
                }}
                bulkActivate={() => {
                    const selectedData = data.filter(d => selectedState[d.id])

                    if (selectedData.length > 0) {
                        openPopup('bulkActivate', selectedData)
                    } else {
                        setInfoPopup({
                            show: true,
                            msg: "Please select atleast 1 or more device to proceed"
                        })
                    }
                }}
                dropDownData={otherData.dropdownList}
                dropDownValue={headerDropdown}
                onDropDownChange={e => {
                    const { value } = e.target
                    const searchBy = value.split(":")[0]
                    const searchValue = value.split(":")[1]
                    setHeaderDropdown(value)
                    if (otherData.setDropDownData) {
                        otherData.setDropDownData(value)
                    }
                    const params = {
                        ...reqParams,
                        searchBy,
                        searchValue
                    }
                    setReqParams(params)
                    refreshList(params)
                }}
                bulkDelete={() => bulkDeleteAction('deviceId')}
                data={data}
                moduleTypes={moduleTypes}
            />

        case moduleTypes.workOrders:
            return <WorkOrderHeader
                accessLevel={accessLevel}
                openPopup={openPopup}
                refreshList={params => {
                    const queryParams = {
                        start: params.start,
                        end: params.end
                    }
                    sessionStorage.setItem('WO:startDate', params.start)
                    sessionStorage.setItem('WO:endDate', params.end)
                    refreshList(queryParams)
                    setReqParams(queryParams)
                }}
                selectedState={selectedState}
                bulkDelete={() => bulkDeleteAction()}
                defaultStartDate={reqParams.start}
                defaultEndDate={reqParams.end}
            />
        case moduleTypes.buildReports:
            return <BuildReportHeaders
                accessLevel={accessLevel}
                dateFormat={otherData.dateFormat}
                refreshList={params => {
                    const queryParams = {
                        ...params,
                    }
                    refreshList(queryParams)
                    setReqParams(queryParams)
                }}
                params={reqParams}
                setReqParams={setReqParams}
                search={search}
                handleSearch={handleSearch}
            />

    }
}