import { formatDate } from "@progress/kendo-intl"
import { getAccountLabel } from "../../../utils"

const jobsiteLabel = getAccountLabel('jobsite')
const employeeLabel = getAccountLabel('employee')
const taskLabel = getAccountLabel('task')
const groupLabel = getAccountLabel('group')
const workOrderLabel = getAccountLabel('workorder')




export const editTimeClockColumns = [
    {
        title: `${employeeLabel} Name`,
        field: "deviceName",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        dropdownCell: true,
        dropdownFilter: true,
        mandatory: true,
        locked: true
    },
    {
        title: "Date",
        field: "tcSentDate",
        editable: true,
        width: 200,
        editor: "text",
        customCell: true,
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true
    },
    {
        title: "Day",
        field: "dayName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Stage Name",
        field: "stageName",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        dropdownCell: true,
        mandatory: true
    },
    {
        title: "Time",
        field: "tcSentTime",
        editable: true,
        width: 200,
        editor: "text",
        customCell: true,
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true
    },
    {
        title: `${jobsiteLabel} Name`,
        field: "customerName",
        editable: true,
        width: 200,
        editor: "text",
        dropdownCell: true,
        dropdownFilter: true,
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: `${taskLabel} Name`,
        field: "jobName",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        customCell: true,
        show: true,
        filter: "text"
    },
    {
        title: `${taskLabel} ID`,
        field: "jobId",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Manager Notes",
        field: "managerNotes",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,
        maxLength: 5000
    },
    {
        title: "Supervisor Notes",
        field: "remarks",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        maxLength: 5000
    },
    {
        title: "Distance",
        field: "distanceTravelled",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        pattern: /^\d*\.?\d*$/,
        patternFailMsg: "Please Enter a Numeric value",
        defaultValue: "0",
        roundOff: true
    },
    {
        title: "Miles Code",
        field: "milesCode",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text",
    },
    {
        title: "Miles Amount",
        field: "milesAmount",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text",
    },
    {
        title: "Message",
        field: "message",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "Phone Number",
        field: "deviceID",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        dropdownCell: true,
        dropdownFilter: true,
        mandatory: true
    },
    {
        title: `${groupLabel} Name`,
        field: "groupName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Latitude/Longitude",
        field: "latlog",
        editable: false,
        width: 200,
        editor: "text",
        customCell: true,
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Address",
        field: "address",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Source",
        field: "hosSource",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Company Code",
        field: "companyCode",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: `${employeeLabel} ID`,
        field: "employeeID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: `${workOrderLabel} ID`,
        field: "workorderNo",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Modified On",
        field: "modifiedOn",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Modified By",
        field: "modifiedBy",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Processed",
        field: "isProcessed",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        dropdownCell: true
    },
    {
        title: "Service Time",
        field: "serviceSeconds",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        format: "hh:mm:ss"
    },
    {
        title: "Week",
        field: "weekName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Start Geozone Name",
        field: "startGeozoneName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
]

export const milesColumns = [
    {
        title: "Name",
        field: "deviceName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        dropdownCell: true,
        mandatory: true,
        locked: true
    },
    {
        title: `${employeeLabel} Phone Number`,
        field: "deviceID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        dropdownCell: true,
        mandatory: true
    },
    {
        title: "Date",
        field: "tcSentDate",
        editable: true,
        width: 200,
        editor: "text",
        customCell: true,
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true
    },
    {
        title: "Distance",
        field: "googleDistance",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        pattern: /^\d*\.?\d*$/,
        patternFailMsg: "Please Enter a Numeric Value",
        roundOff: true
    },
    {
        title: "Manager Notes",
        field: "managerNotes",
        editable: true,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,
        maxLength: 5000
    },
    // {
    //     title: "Jobsite Name",
    //     field: "jobName",
    //     editable: false,
    //     width: 180,
    //     editor: "text",
    //     columnMenu: false,
    //     show: true,
    //     filter: "text"
    // },
    // {
    //     title: "Task Name",
    //     field: "runningStatus",
    //     editable: false,
    //     width: 180,
    //     editor: "text",
    //     columnMenu: false,
    //     show: true,
    //     filter: "text"
    // },
    {
        title: "Company Code",
        field: "companyCode",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: `${employeeLabel} ID`,
        field: "employeeID",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
]

export const advanceTimeClockColumns = [
    {
        title: `${employeeLabel} Name`,
        field: "deviceName",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        dropdownCell: true,
        dropdownFilter: true,
        mandatory: true,
        locked: true
    },
    {
        title: "Start Date",
        field: "startTCSentDate",
        editable: true,
        width: 200,
        editor: "text",
        customCell: true,
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true
    },
    {
        title: "Day",
        field: "dayName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Start Stage Name",
        field: "startStageName",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text",
        dropdownCell: true,
        mandatory: true
    },
    {
        title: "Start Time",
        field: "startTCSentTime",
        editable: true,
        width: 200,
        editor: "text",
        customCell: true,
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true
    },
    {
        title: `Start ${taskLabel} ID`,
        field: "jobId",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text",
    },
    {
        title: "End Stage Name",
        field: "endStageName",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text",
        dropdownCell: true,
        mandatory: true
    },
    {
        title: "End Date",
        field: "endTCSentDate",
        editable: true,
        width: 200,
        editor: "text",
        customCell: true,
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true
    },
    {
        title: "End Time",
        field: "endTCSentTime",
        editable: true,
        width: 200,
        editor: "text",
        customCell: true,
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true
    },
    {
        title: `End ${taskLabel} ID`,
        field: "endJobId",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text",
    },
    {
        title: "Start Temp Col",
        field: "startTempCol",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "End Temp Col",
        field: "endTempCol",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: `${jobsiteLabel} Name`,
        field: "customerName",
        editable: true,
        width: 200,
        editor: "text",
        dropdownCell: true,
        dropdownFilter: true,
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: `${jobsiteLabel} Site ID`,
        field: "jobSiteID",
        editable: false,
        width: 200,
        show: false,
        filter: "text",
    },
    {
        title: `${jobsiteLabel} site Description`,
        field: "jobSiteName",
        editable: false,
        width: 200,
        show: false,
        filter: "text",
    },
    {
        title: "Earning Code",
        field: "earningCode",
        editable: true,
        width: 200,
        editor: "text",
        customCell: true,
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: `${taskLabel} Name`,
        field: "jobName",
        editable: true,
        width: 200,
        editor: "text",
        customCell: true,
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: `${taskLabel} ID`,
        field: "jobID",
        editable: true,
        width: 200,
        editor: "text",
        customCell: true,
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Manager Notes",
        field: "managerNotes",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,
        maxLength: 5000
    },
    {
        title: "Supervisor Notes",
        field: "remarks",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text",
        maxLength: 5000
    },
    {
        title: "Distance",
        field: "distanceTravelled",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        pattern: /^\d*\.?\d*$/,
        patternFailMsg: "Please Enter a Numeric Value",
        defaultValue: "0",
        roundOff: true
    },
    {
        title: "Miles Code",
        field: "milesCode",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text",
    },
    {
        title: "Miles Amount",
        field: "milesAmount",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text",
    },
    {
        title: "Phone Number",
        field: "deviceID",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text",
        dropdownCell: true,
        dropdownFilter: true,
        mandatory: true
    },
    {
        title: `${employeeLabel} ID`,
        field: "employeeID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Service Time",
        field: "serviceSeconds",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text",
        format: 'hh:mm:ss'
    },
    {
        title: "Billing#",
        field: "siteID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Billing Name",
        field: "siteName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Service Code",
        field: "serviceCode",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "State ID",
        field: "stateID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Authorization#",
        field: "authorization",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "First Name",
        field: "clientFirstName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Last Name",
        field: "clientLastName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Date of Service Start",
        field: "serviceStartDate",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Time Service Started",
        field: "serviceStartTime",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Date of Service End",
        field: "serviceEndDate",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Time Service Ended",
        field: "serviceEndTime",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Total Hours Worked",
        field: "totoalServiceHours",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Total Units of Service",
        field: "totalUnitsOfservice",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Service Hour Type",
        field: "serviceHoursType",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Start Geo Location",
        field: "startGeoLocation",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "End Geo Location",
        field: "endGeoLocation",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Start Address",
        field: "startAddress",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "End Address",
        field: "endAddress",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Manual Codes",
        field: "manualEntryCode",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Claim Frequency Type",
        field: "flsa",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "SSSO Provider ID",
        field: "SSSO",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Payroll ID",
        field: "payrollID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Company Code",
        field: "companyCode",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Start Message",
        field: "startMessage",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "End Message",
        field: "endMessage",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Start Message Attachment",
        field: "startMessageAttachment",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        customCell: true,
        show: false,
        filter: "text"
    },{
        title: "End Message Attachment",
        field: "endMessageAttachment",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        customCell: true,
        show: false,
        filter: "text"
    },
    {
        title: "Start Notes",
        field: "startNotes",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "End Notes",
        field: "endNotes",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Start Scan",
        field: "startScan",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "End Scan",
        field: "endScan",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Start Rate",
        field: "startRate",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "End Rate",
        field: "endRate",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Cost Code",
        field: "costCode",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: `${workOrderLabel} ID`,
        field: "workorderNo",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Modified On",
        field: "modifiedOn",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Modified By",
        field: "modifiedBy",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Trade",
        field: "trade",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        customCell: true,
        show: false,
        filter: "text"
    },
    {
        title: "Source",
        field: "hosSource",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Week",
        field: "weekName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Regular Hours",
        field: "recRegularHours",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "OT Hours",
        field: "recOTHours",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "DOT Hours",
        field: "recDOTHours",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Status Flag 7 Code",
        field: "statusFlagCode",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Status Flag 7 Description",
        field: "statusFlagCodeDesc",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    
    {
        title: "Phone Number",
        field: "extPhoneNumber",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: " Customer Type",
        field: "customerType",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Source ID",
        field: "srcID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Reference ID",
        field: "refID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Skills",
        field: "skills",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Contact Email",
        field: "extEmail",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Landline Number",
        field: "landlineNumber",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "User Description",
        field: "extUserDescription",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Ext System",
        field: "extSystem",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Rate Card",
        field: "billingRate",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Start Geozone Name",
        field: "startGeozoneName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "End Geozone Name",
        field: "endGeozoneName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
]

export const advanceTimeClockColumnsCode = [
    {
        title: `${employeeLabel} Name`,
        field: "deviceName",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        dropdownCell: true,
        dropdownFilter: true,
        mandatory: true,
        locked: true
    },
    {
        title: `${employeeLabel} ID`,
        field: "employeeID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Start Date",
        field: "startTCSentDate",
        editable: true,
        width: 200,
        editor: "text",
        customCell: true,
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true
    },
    {
        title: "Start Stage Name",
        field: "startStageName",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        dropdownCell: true,
        mandatory: true
    },
    {
        title: "Start Time",
        field: "startTCSentTime",
        editable: true,
        width: 200,
        editor: "text",
        customCell: true,
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true
    },
    {
        title: `Start ${taskLabel} ID`,
        field: "jobId",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "End Stage Name",
        field: "endStageName",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        dropdownCell: true,
        mandatory: true
    },
    {
        title: "End Date",
        field: "endTCSentDate",
        editable: true,
        width: 200,
        editor: "text",
        customCell: true,
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true
    },
    {
        title: "End Time",
        field: "endTCSentTime",
        editable: true,
        width: 200,
        editor: "text",
        customCell: true,
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true
    },
    {
        title: `${jobsiteLabel} Name`,
        field: "customerName",
        editable: true,
        width: 200,
        editor: "text",
        dropdownCell: true,
        dropdownFilter: true,
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Earning Code",
        field: "earningCode",
        editable: true,
        width: 200,
        editor: "text",
        customCell: true,
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: `${taskLabel} Name`,
        field: "jobName",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        customCell: true,
        show: true,
        filter: "text"
    },
    {
        title: `${taskLabel} ID`,
        field: "jobID",
        editable: true,
        width: 200,
        editor: "text",
        dropdownCell: true,
        dropdownFilter: true,
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Manager Notes",
        field: "managerNotes",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true,
        maxLength: 5000
    },
    {
        title: "Supervisor Notes",
        field: "remarks",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        maxLength: 5000
    },
    {
        title: "Total Hours Worked",
        field: "totoalServiceHours",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Service Hour Type",
        field: "serviceHoursType",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Start Message",
        field: "startMessage",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        customCell: true,
        show: true,
        filter: "text"
    },
    {
        title: "End Message",
        field: "endMessage",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        customCell: true,
        show: true,
        filter: "text"
    },
    {
        title: "Start Notes",
        field: "startNotes",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "End Notes",
        field: "endNotes",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Trade",
        field: "trade",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        customCell: true,
        show: true,
        filter: "text"
    },
    {
        title: "Phone Number",
        field: "deviceID",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text",
        dropdownCell: true,
        dropdownFilter: true,
        mandatory: true
    },
    // {
    //     title: "Week",
    //     field: "weekName",
    //     editable: false,
    //     width: 200,
    //     editor: "text",
    //     columnMenu: false,
    //     show: false,
    //     filter: "text"
    // },
]

export const distanceAuditColumns = [
    {
        title: "Name",
        field: "deviceName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        locked: true
    },
    {
        title: `${employeeLabel} Phone Number`,
        field: "deviceID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Type",
        field: "serviceCode",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Updated By",
        field: "employeeID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Modified On",
        field: "timeClockSentTime",
        editable: false,
        width: 300,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Date",
        field: "tcSentDate",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Old Distance",
        field: "oldDistanceTravelled",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Updated Distance",
        field: "googleDistance",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Start Txn#",
        field: "startMessage",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Start Time",
        field: "startScan",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "End Txn#",
        field: "endMessage",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "End Time",
        field: "endScan",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Manager Notes",
        field: "managerNotes",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        maxLength: 5000
    },
    {
        title: "Remarks",
        field: "address",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }
]

export const approvalAuditcolumns = [
    {
        title: `${employeeLabel} Name`,
        field: "deviceName",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        dropdownCell: true,
        dropdownFilter: true,
        locked: true
    },
    {
        title: "Start Stage Name",
        field: "startStageName",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        dropdownCell: true,
    },
    {
        title: "Start Date",
        field: "startTCSentDate",
        editable: true,
        width: 200,
        editor: "text",
        customCell: true,
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Start Time",
        field: "startTCSentTime",
        editable: true,
        width: 200,
        editor: "text",
        customCell: true,
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "End Stage Name",
        field: "endStageName",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        dropdownCell: true,
    },
    {
        title: "End Date",
        field: "endTCSentDate",
        editable: true,
        width: 200,
        editor: "text",
        customCell: true,
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true
    },
    {
        title: "End Time",
        field: "endTCSentTime",
        editable: true,
        width: 200,
        editor: "text",
        customCell: true,
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `${jobsiteLabel} Name`,
        field: "customerName",
        editable: true,
        width: 200,
        editor: "text",
        dropdownCell: true,
        dropdownFilter: true,
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: `${taskLabel} Name`,
        field: "jobName",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        customCell: true,
        show: true,
        filter: "text"
    },
    {
        title: `${taskLabel} ID`,
        field: "jobID",
        editable: true,
        width: 200,
        editor: "text",
        dropdownCell: true,
        dropdownFilter: true,
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Benefits Eligibility Class Code",
        field: "benefitsEligibilityClassCode",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Benefits Eligibility Class Description",
        field: "benefitsEligibilityClassCodeDesc",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Status Flag 7 Code",
        field: "statusFlagCode",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "status Flag 7 Description",
        field: "statusFlagCodeDesc",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Trade",
        field: "trade",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Regular Hours",
        field: "recRegularHours",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "OTime Hours",
        field: "recOTHours",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "DOTime Hours",
        field: "recDOTHours",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Total Hours Worked",
        field: "totoalServiceHours",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Supervisor Notes",
        field: "remarks",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        maxLength: 5000
    },
    {
        title: "Message",
        field: "managerNotes",
        editable: true,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: false
    },
    {
        title: "Updated Values",
        field: "message",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Status",
        field: "hosSource",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
]

export const reportTableAggregates = [
    {
        field: "distanceTravelled",
        aggregate: "sum"
    },
    {
        field: "totoalServiceHours",
        aggregate: "sum",
    },
    {
        field: "serviceSeconds",
        aggregate: "sum",
    },
    {
        field: "totalUnitsOfservice",
        aggregate: "sum",
    },
    {
        field: "deviceName",
        aggregate: "count",
    },
    {
        field: "milesAmount",
        aggregate: "sum",
    }
];

export const reportTableAggregatesText = {
    "distanceTravelled": "Total distance",
    "totoalServiceHours": "Total Hours Worked",
    "serviceSeconds": "Total Service Hours",
    "totalUnitsOfservice": "Total Units of Service",
    "deviceName": "Count",
    "milesAmount": "Total Miles Amount"
}

export const NO_RECORD_TEXT = "No data for selected date range. Please try for different date range"

export const customDateFormat = (dateString, dateFormat, currentFormat = "") => {
    const isDDMMYYYYFormat = currentFormat.toLowerCase() === "dd-mm-yyyy" || currentFormat.toLowerCase() === "dd/mm/yyyy"

    if (isDDMMYYYYFormat) {

        const splitBy = dateString.includes('/') ? "/" : "-"
        const splittedDate = dateString.split(splitBy).map(d => {
            return Number(d)
        })

        return formatDate(new Date(splittedDate[2], splittedDate[1] - 1, splittedDate[0]), dateFormat)
    }

    return formatDate(new Date(dateString), dateFormat)
}