import React, { useState, useEffect } from "react";
import { apiProvider } from "../../../services/api/provider";
import { useHistory } from 'react-router-dom';


const iFrameStyles = {
  width: "100%",
  height: "100%",
  border: "none",
  paddingTop: "50px",
};

const date = new Date();

const day = date.getDate().toString().padStart(2, "0");
const month = (date.getMonth() + 1).toString().padStart(2, "0");
const year = date.getFullYear();

const formattedDate = `${year}-${month}-${day}`;

export const SummaryCards = () => {
  const metabaseUrl = "/track/react/v1//bidashboard/login";

  const [accessToken, setAccessToken] = useState("");
  const [redirecting, setRedirecting] = useState(false);
  const history = useHistory();
  const getAccessToken = async () => {
    try {
      const response = await apiProvider.getAll(metabaseUrl);
      setAccessToken(response?.token);
      if(!response?.token) {
        setRedirecting(true);
      }
    } catch (error) {
      console.log(error);
      setRedirecting(true);
    }
  };
  useEffect(() => {
    getAccessToken();
  }, []);

  useEffect(() => {
    if (redirecting) {
      history.replace("/home");
    }
  }, [redirecting, history]);


  return (
    <>
      <iframe
        data-testid="dashboard-iframe"
        style={iFrameStyles}
        src={`https://bi.allgeo.com/embed/dashboard/${accessToken}?start_date=${formattedDate}&end_date=${formattedDate}#bordered=false&titled=false`}
      ></iframe>
    </>
  );
};
