import React, { useRef, useState } from 'react'
import { apiProvider } from '../../../services/api/provider'

const OptinPopup = ({ data, closePopup, setToastMsg, setShowLoader }) => {

    const [locationOptin, setLocationOptin] = useState(false)
    const [smsOptin, setSmsOptin] = useState(false)

    const submit = async () => {
        try {
            setShowLoader(true)

            const actionString = [
                { key: 'locn_optin', checked: locationOptin },
                { key: 'sms_optin', checked: smsOptin }
            ].filter(val => val.checked)
                .map(val => val.key)
                .join()

            const payload = [data.deviceId]

            const response = await apiProvider.post(`track/react/v1/allgeo/monitor/optinEmployees?action=${actionString}`, payload)

            if (+response.status === 200) {
                setToastMsg({ msg: response.message, type: "msgSuccess", refresh: false })
                closePopup()
            } else {
                setToastMsg({ msg: response.message, type: "msgError" })
            }

        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="popupWrap smlPopup active">
            <div className="closePopup" onClick={closePopup} >Close</div>
            <div className="popupHeading">Optin Employees</div>
            <div className="popupContent">
                <div className="popupTabCont">
                    <div className="customCheck">
                        <div className="withHelpTxt">
                            <input type="checkbox" id="customCheck1" checked={locationOptin} onChange={() => setLocationOptin(!locationOptin)} />
                            <label className="customCheckLabel" htmlFor="customCheck1">For Location Tracking</label>
                            <a href="https://www.abaq.us/allgeo-terms.html">View service terms</a>
                        </div>
                    </div>
                    <div className="customCheck">
                        <input type="checkbox" id="customCheck2" checked={smsOptin} onChange={() => setSmsOptin(!smsOptin)} />
                        <label className="customCheckLabel" htmlFor="customCheck2">For Field Messaging</label>
                    </div>
                    <p>A text message will be sent to employee(s) to confirm.</p>
                </div>
            </div>
            <div className="popupBtnWrap flexJCenter">
                <button className="btnStyle" disabled={!(locationOptin || smsOptin)} onClick={submit}>Submit</button>
            </div>
        </div>
    )
}

export default OptinPopup