import React, { useEffect, useState } from 'react'
import { apiProvider } from '../../../services/api/provider';
import AutoCompleteDropdown from '../../common/dropdown/AutoCompleteDropdown'
import { useForm } from "react-hook-form";
import { dialCodes, formatCountryCode } from './managersUtils';

const CopyManagerDetails = ({ mobileManage, data, setCopyManagerPopup, setToastMsg, setShowLoader }) => {
    const { register, watch, handleSubmit, setValue, formState: { errors } } = useForm({ mode: "all" });

    const [isFormComplete, setIsFormComplete] = useState(false)

    const closePopup = () => {
        setCopyManagerPopup({
            show: false,
            data: null
        })
    }

    const copyManager = async formData => {

        const payload = {
            "copyableUserId": data.managerID,
            "contactName": formData.managerID,
            "contactEmail": formData.email,
            "contactPhone": (mobileManage || formData.trackManager) ? (formatCountryCode(formData.countryCode).concat(formData.contactNumber)) : "",
            "trackManager": formData.trackManager,
            "mobileManage": mobileManage
        }

        try {
            setShowLoader(true)
            const response = await apiProvider.post('/track/mobile/v1/allgeo/manager/copy', payload)
            if (response.status === 200) {
                closePopup()
                setToastMsg({ msg: `Manager copied successfully. The password link will be sent to ${formData.email}.`, type: "msgSuccess" });
            } else {
                setToastMsg({
                    type: 'msgError',
                    msg: response.message
                })
            }
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    useEffect(() => {

        let fields;

        const subscribe = watch(data => {

            if (mobileManage || data.trackManager) {
                fields = ["managerID", "email", "countryCode", "contactNumber"]
            } else {
                fields = ["managerID", "email"]
            }

            const isEmpty = fields.some(d => !data[d])
            setIsFormComplete(!isEmpty)
        })

        return () => subscribe.unsubscribe()
    }, [watch])

    return (
        <>
            <form onSubmit={handleSubmit(copyManager)}>
                <div className="popupWrap smlPopup withAutoComplete active">
                    <div className="closePopup" onClick={closePopup} >Close</div>
                    <div className="popupHeading">Copy Manager Details</div>
                    <div className="popupContent">
                        <p>Please enter the new manager’s details below. All other details for manager <strong>{data.managerDesc}</strong> will be copied. </p>
                        <div className="fldWrap">
                            <label>Manager ID</label>
                            <input maxLength={64} type="text" {...register("managerID", {
                                required: true,
                                onChange: (e) => setValue("managerID", (e.target.value).replace(/[^a-zA-Z0-9]/gi, ''), { shouldDirty: false })
                            })} />
                        </div>
                        <div className={`fldWrap${errors.email ? ' errFld' : ''}`}>
                            <label>User Email</label>
                            <input maxLength={64} type="text" {...register("email", { pattern: /\S+@\S+\.\S+/, required: true })} />
                            {errors.email && <div className="fldMsg">Please enter a valid email</div>}
                        </div>
                        <div className="fldWrap">
                            <label>Phone Number</label>
                            <div className="phoneNumberWrap">
                                <AutoCompleteDropdown
                                    options={dialCodes.map(code => ({ label: code.dial_code, value: code.dial_code }))}
                                    selectedOption={""}
                                    setValue={val => setValue("countryCode", val)}
                                />
                                <input type="number" maxLength={15} {...register("contactNumber")} />
                            </div>
                            <div className="smlNoteTxt">Manager will receive all the SMS and Voice communication on this number</div>
                        </div>
                        <div className="checkOptionInline">
                            <label>Will this manager be managing employees using web application?</label>
                            <div className="customCheck yesNo">
                                <input type="checkbox" id="webApplication" checked={true} readOnly />
                                <label className="customCheckLabel" htmlFor="webApplication">yesNo</label>
                            </div>
                        </div>
                        <div className="checkOptionInline">
                            <label>Will this manager be managing employees using mobile application?</label>
                            <div className="customCheck yesNo">
                                <input type="checkbox" id="mobileApplication" checked={mobileManage} readOnly />
                                <label className="customCheckLabel" htmlFor="mobileApplication">yesNo</label>
                            </div>
                        </div>
                        <div className="checkOptionInline">
                            <label>Will this manager be tracked for time and location?</label>
                            <div className="customCheck yesNo">
                                <input type="checkbox" id="timeLocation" {...register('trackManager')} />
                                <label className="customCheckLabel" htmlFor="timeLocation">yesNo</label>
                            </div>
                        </div>
                    </div>
                    <div className="popupBtnWrap flexJCenter">
                        <button className="btnStyle okBtn" disabled={!isFormComplete}>Add</button>
                    </div>
                </div>
            </form>
        </>
    )
}

export default CopyManagerDetails