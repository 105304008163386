export const REPEAT_DAILY = {
    days: "1",
    ends: "",
    ocr: "",
    endsOn: ""
}

export const REPEAT_WEEKLY = {
    weeks: "1",
    ends: "",
    ocr: "",
    selectedDays: "",
    endsOn: ""
}