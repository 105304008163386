import React, { useEffect, useState, useRef } from 'react';
import { apiProvider } from '../../../../../services/api/provider';
import { WOPerformaceUtils } from "../../buildReportsutils";
import { formatDate } from '@progress/kendo-intl';
import KendoGrid from '../../../../common/kendo/KendoGrid';
import SiteLoader from "../../../../common/SiteLoader";
import { reportTableAggregates, reportTableAggregatesText } from "../../buildReportsutils"

const WOPerformace = ({genSetting}) => {
    const [columns, setColumns] = useState(WOPerformaceUtils);
    const [Data, setData] = useState();
    const [showLoader, setShowLoader] = useState(false);
    const [params, setParams] = useState(
        {
            date_fr: formatDate(new Date(), 'yyyy/MM/dd'),
            date_to: formatDate(new Date(), 'yyyy/MM/dd'),
            reportFor: "",
            offsetStr: "",
            tzStr: "",
            limit: "",
            device_tz_selected: "",
            workorderType: "allWorkorders"
        }
    )

    const childRef = useRef()

    const getReport = async () => {
        try {
            setShowLoader(true);
            console.log("coming till here params", params)
            setParams({ ...params, device_tz_selected: genSetting?.report_timezone });
            const response = await apiProvider.getAll('track/mobile/v1/allgeo/workorderreport', params);
            console.log("API Response:", response);
            setData(response);
        } catch (error) {
            console.error("API Error:", error);
        } finally {
            setShowLoader(false);
        }
    };

    useEffect(() => {
        getReport();
    }, [genSetting]);

    const woServiceSeconds = WOPerformaceUtils?.find(e => e.field == "woServiceSeconds").title
    const serviceTimeSeconds = WOPerformaceUtils?.find(e=>e.field=="serviceTimeSeconds").title

    return (
        <>
            {Data &&
                <KendoGrid
                    gridData={Data}
                    setGridData={setData}
                    columnData={columns}
                    module={"buildReports"}
                    subModule={'WOPerformaceReport'}
                    getAPiUrl={'track/mobile/v1/allgeo/workorderreport'}
                    requestParams={params}
                    supportParams={params.stageid}
                    accessLevel={{ childMenu: 1 }}
                    ref={childRef}
                    otherData={{
                        actionColumnBtns: [],
                        showExportDropdown: true,
                        enableFooterDisplay: true,
                        aggregates: reportTableAggregates,
                        aggregateText: reportTableAggregatesText,
                        minutesFormat: genSetting?.minutes,
                        dateFormat: genSetting?.date_display_format,
                        columnFormatMap: {
                            [woServiceSeconds]: "hh:mm:ss",
                            [serviceTimeSeconds]:"hh:mm:ss"
                        }
                    }}
                />
            }
            <SiteLoader isActive={showLoader} />
        </>
    )
}

export default WOPerformace