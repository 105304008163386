import React, { Fragment, useEffect, useState } from 'react'
import { useDocumentTitle } from '../../../hooks/setDocumentTitle';
import { apiProvider } from '../../../services/api/provider';
import { getAccessLevel } from '../../common/functions';
import SiteLoader from '../../common/SiteLoader';
import { EditTimeClock, EditMile, AdvanceTimeClock } from './SubReports'
import { ConfirmPopup } from '../../common/PopUp';
import ToastMessage from '../../common/ToastMessage';
import AuditReportsWrapper from './SubReports/AuditReportsWrapper';
import AdvanceTimeClockCode from './SubReports/AdvanceTimeClockCode';
import { SaveTemplate } from '../SaveTemplate/SaveTemplate';

const tabs = {
    EDIT_TIME_CLOCK: 'etc',
    EDIT_MILES: 'em',
    ADVANCE_TIME_CLOCK: "atc",
    DISTANCE_AUDIT: 'da',
    ADVANCE_TIME_CLOCK_CODE: "atcc"
}

const templateMapping = {
    etc: "editTimeclock",
    em: 'editMile',
    atc: "advanceTimeclock",
    da: 'auditReport',
}

const EditReport = () => {

    useDocumentTitle('Edit Reports - allGeo')

    const [activeTab, setActiveTab] = useState(tabs.ADVANCE_TIME_CLOCK)
    const [dropdownData, setDropdownData] = useState({})
    const [showLoader, setShowLoader] = useState(false);
    const [accessLevel, setAccessLevel] = useState({});
    const [showDialog, setShowDialog] = useState(false);
    const [clickedTab, setClickedTab] = useState(tabs.ADVANCE_TIME_CLOCK)
    const [toastMsg, setToastMsg] = useState({
        msg: "",
        type: "msgSuccess"
    });

    const [reportShow, setReportShow] = useState({})
    const [columnName, setColumnName] = useState({})
    const [columnList, setColumnList] = useState([])
    const [templateName, setTemplateName] = useState(templateMapping[tabs.ADVANCE_TIME_CLOCK]);
    const [kendoDataState, setKendoDataState] = useState()


    const handleTabChange = tab => {
        setClickedTab(tab)

        const isFormDirty = localStorage.getItem('formIsDirty')

        if (isFormDirty) {
            setShowDialog(true)
            return
        }

        setActiveTab(tab)
        setTemplateName(templateMapping[tab])
    }

    const getGroupsData = async () => {
        try {
            const grpResult = await apiProvider.getAll('track/mobile/v1/allgeo/getGroups?includeAll=true')
            let grp = (grpResult.groups).sort((a, b) => (a.groupDesc).toLowerCase() > (b.groupDesc).toLowerCase() ? 1 : -1)
                .filter(grp => grp.groupDesc)
                .map(grp => ({ label: grp.groupDesc === 'all' ? 'All' : grp.groupDesc, value: grp.groupID }))

            return grp

        } catch (error) {
            console.log(error);
        }
    }

    const getEmployeesData = async () => {
        try {
            const empResult = await apiProvider.getAll('track/mobile/v1/allgeo/devices?includeAll=true&operationType=mobile')
            let emp = (empResult.employees)
                .filter((ele, index) => {
                    if (index > 0) {
                        return (ele?.isActive).toLowerCase() === 'active'
                    } else {
                        return ele
                    }
                })
                .filter(ele => ele.name.trim().length > 0)
                .sort((a, b) => (a.name).toLowerCase() > (b.name).toLowerCase() ? 1 : -1)
                .map(emp => ({ ...emp, name: emp.name === 'all' ? 'All' : emp.name }))
            return { ...empResult, employees: emp }
        } catch (error) {
            console.log(error);
        }
    }

    const getJobSiteList = async () => {
        try {
            const response = await apiProvider.getAll('/track/mobile/v2/allgeo/getCustomers?active=Yes&source=editreports')
            if (response.status === 200) {
                return response.data
            }

            return []

        } catch (error) {
            console.log(error);
        }
    }

    const getTaskList = async () => {

        try {
            const response = await apiProvider.getAll('/track/mobile/v1/allgeo/task?active=true')
            if (response.status === 200) {
                return response.tasks
            }

            return []
        } catch (error) {
            console.log(error);
        }
    }

    const fetchColumnName = async () => {
        function fillUndefinedFields(response, defaultResponse) {
            for (const key in response) {
                if (response.hasOwnProperty(key)) {
                    if (response[key] === undefined || response[key] === null) {
                        response[key] = defaultResponse[key];
                    }
                }
            }
            return response;
        }
        // api call to get all the column names from api
        try {
            const defaultResponse = await apiProvider.getAll('track/mobile/v1/allgeo/getDefaultReportColumnNames')
            const response = await apiProvider.getAll('track/mobile/v1/allgeo/getEditReportColumnNames')
            const filledResponse = fillUndefinedFields(response, defaultResponse);
            setColumnName(filledResponse)
            // return response 

        } catch (error) {
            console.log(error);
        }
    }


    const onSuccess = () => {
        localStorage.removeItem('formIsDirty')
        setShowDialog(false);
        setActiveTab(clickedTab)
    }

    const onFaliure = () => {
        setShowDialog(false);
    }

    const fetchData = async () => {
        try {
            setShowLoader(true)
            const response = await Promise.all(
                [getEmployeesData(), getGroupsData(), getJobSiteList(), getTaskList(), apiProvider.getAll('track/react/account/timeclockSettings'), apiProvider.getAll('track/react/account/generalSetting'), fetchColumnName(), apiProvider.getAll('/track/mobile/v1/allgeo/earning/codes')]
            )
            const earningCodeObject = response[2].reduce((obj, e) => {
                obj[e.name] = e.additional_info?.earningCode;
                return obj;
            }, {});

            const taskNameObject = response[2].reduce((obj, e) => {
                obj[e.name] = e.txnId;
                return obj;
            }, {});
            localStorage.setItem("secondsEnable", response[5].report_units.secondFormatOpted)

            setReportShow(response[5]?.editReportFlags)
            const DefaultCodeData = response[7]?.map(item => item.code)

            const trueKeys = Object.keys(response[5]?.editReportFlags).filter(key => response[5]?.editReportFlags[key] === true);
            response[2].unshift({ name: 'Select' })

            setDropdownData({
                employees: response[0].employees,
                employeesIDs: response[0].employees.map(emp => ({ value: emp.employeeID, text: emp.employeeID })),
                employeeNames: response[0].employees.map(emp => ({ value: emp.name, text: emp.name })),
                employeeDropdown: response[0].employees.map(emp => ({ value: emp.id, text: emp.name })),
                groups: response[1].map(grp => ({ value: grp.value, text: grp.label })),
                jobsites: response[2].map(site => ({ value: site.name, text: site.name })),
                earningCodeDropDown: earningCodeObject,
                defaultEarningCodes: DefaultCodeData,
                taskNameDropDown: taskNameObject,
                tasksIDs: response[3].map(task => ({ value: task.taskID, text: task.taskID })),
                tasks: response[3].map(task => ({ value: task.name, text: task.name })),
                taskAll: response[3],
                stageName: response[4].map(stage => ({ value: stage.stage_name, text: stage.stage_name })).filter(stage => stage.value),
                minutesFormat: response[5]?.report_units.minutes,
                dateFormat: response[5]?.report_units?.date_display_format,
                timeFormat: response[5]?.report_units?.datetime,
                timezone: response[5]?.basic_details?.timezone
            })

            // setActiveTab(tabs.ADVANCE_TIME_CLOCK)
            if (trueKeys.length > 0) {
                const firstTrueKey = trueKeys;
                if (firstTrueKey.includes('advancedEvv')) {
                    setActiveTab(tabs.ADVANCE_TIME_CLOCK);
                } else if (firstTrueKey.includes('editTimeClock')) {
                    setActiveTab(tabs.EDIT_TIME_CLOCK);
                } else if (firstTrueKey.includes('editMiles')) {
                    setActiveTab(tabs.EDIT_MILES);
                } else if (firstTrueKey.includes('audit')) {
                    setActiveTab(tabs.DISTANCE_AUDIT);
                }
            }

        } catch (error) {
            console.log(error);
            setShowLoader(false);
        }
    }

    useEffect(() => {

        getAccessLevel("reports", "edit_reports").then((response) => {
            setAccessLevel(response);
        });
        fetchData()

        return () => {
            sessionStorage.removeItem('EditReport:startDate')
            sessionStorage.removeItem('EditReport:endDate')
            sessionStorage.removeItem('EditReport:reportFor')
        }
    }, [])

    return (
        <Fragment>
            <ul className="innerSubNav" style={{ marginBottom: "-20px", marginTop: "-15px" }}>
                {reportShow.advancedEvv && (
                    <li>
                        <a className={`${activeTab === tabs.ADVANCE_TIME_CLOCK ? 'active' : ''} editIconAnchor`} onClick={() => handleTabChange(tabs.ADVANCE_TIME_CLOCK)}>
                            <span className={`editIcon ${activeTab === tabs.ADVANCE_TIME_CLOCK ? 'active' : ''}`}></span>
                            Advanced Time Clock
                        </a>
                    </li>
                )}
                {reportShow.editTimeClock && (
                    <li>
                        <a className={`${activeTab === tabs.EDIT_TIME_CLOCK ? 'active' : ''} editIconAnchor`} onClick={() => handleTabChange(tabs.EDIT_TIME_CLOCK)}>
                            <span className={`editIcon ${activeTab === tabs.EDIT_TIME_CLOCK ? 'active' : ''}`}></span>
                            Punches
                        </a>
                    </li>
                )}

                {reportShow.editMiles && (
                    <li>
                        <a className={`${activeTab === tabs.EDIT_MILES ? 'active' : ''} editIconAnchor`} onClick={() => handleTabChange(tabs.EDIT_MILES)}>
                            <span className={`editIcon ${activeTab === tabs.EDIT_MILES ? 'active' : ''}`}></span>
                            Miles
                        </a>
                    </li>
                )}
                {reportShow.audit && (
                    <li>
                        <a className={`${activeTab === tabs.DISTANCE_AUDIT ? 'active' : ''}`} onClick={() => handleTabChange(tabs.DISTANCE_AUDIT)}>
                            Audit
                        </a>
                    </li>
                )}
                {/* {reportShow.advancedEvvCode && (
                    <li>
                        <a className={`${activeTab === tabs.ADVANCE_TIME_CLOCK_CODE ? 'active' : ''} editIconAnchor`} onClick={() => handleTabChange(tabs.ADVANCE_TIME_CLOCK_CODE)}>
                            <span className={`editIcon ${activeTab === tabs.ADVANCE_TIME_CLOCK_CODE ? 'active' : ''}`}></span>
                            Advanced Time Clock (w/ Codes)
                        </a>
                    </li>
                )} */}

                <li className="save-template-container">
                    <SaveTemplate columnList={columnList} setColumnList={setColumnList} templateName={templateName} setShowLoader={setShowLoader} setToastMsg={setToastMsg} kendoDataState={kendoDataState} />
                </li>
            </ul>
            <section>
                {reportShow.editTimeClock && activeTab === tabs.EDIT_TIME_CLOCK && <EditTimeClock columnName={columnName} dropdownData={dropdownData} setShowLoader={setShowLoader} toastMsg={toastMsg} setToastMsg={setToastMsg} accessLevel={accessLevel} setColumnList={setColumnList} columnList={columnList} setKendoDataState={setKendoDataState} />}
                {reportShow.editMiles && activeTab === tabs.EDIT_MILES && <EditMile columnName={columnName} dropdownData={dropdownData} setShowLoader={setShowLoader} toastMsg={toastMsg} setToastMsg={setToastMsg} accessLevel={accessLevel} setColumnList={setColumnList} columnList={columnList} setKendoDataState={setKendoDataState} />}
                {reportShow.advancedEvv && activeTab === tabs.ADVANCE_TIME_CLOCK && <AdvanceTimeClock columnName={columnName} dropdownData={dropdownData} setShowLoader={setShowLoader} toastMsg={toastMsg} setToastMsg={setToastMsg} accessLevel={accessLevel} setColumnList={setColumnList} columnList={columnList} setKendoDataState={setKendoDataState} />}
                {reportShow.advancedEvvCode && activeTab === tabs.ADVANCE_TIME_CLOCK_CODE && <AdvanceTimeClockCode columnName={columnName} dropdownData={dropdownData} setShowLoader={setShowLoader} toastMsg={toastMsg} setToastMsg={setToastMsg} accessLevel={accessLevel} setColumnList={setColumnList} columnList={columnList} setKendoDataState={setKendoDataState} />}
                {activeTab === tabs.DISTANCE_AUDIT && <AuditReportsWrapper columnName={columnName} dropdownData={dropdownData} setShowLoader={setShowLoader} accessLevel={accessLevel} toastMsg={toastMsg} setToastMsg={setToastMsg} setTemplateName={setTemplateName} setColumnList={setColumnList} columnList={columnList} setKendoDataState={setKendoDataState} />}
            </section>
            <SiteLoader isActive={showLoader} />
            <ConfirmPopup showDialog={showDialog} message={'The changes are still unsaved. Are you sure you want to leave?'} confirmNavigation={onSuccess} cancelNavigation={onFaliure} />
            {toastMsg.msg && <ToastMessage cssClass={toastMsg.type} delay={5000} message={toastMsg.msg} onClose={() => setToastMsg({ msg: '', type: '' })} />}
        </Fragment>
    )
}

export default EditReport