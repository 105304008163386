import React, { createContext, useMemo, useState } from 'react'

const initialState = {
    menuItems: [],
    setMenuItems: menu => {}
}

const RouteContext = createContext(initialState)

export const RouteProvider = ({ children }) => {

    const [menuItems, setMenuItems] = useState([])

    const provider = useMemo(() => ({
        menuItems,
        setMenuItems
    }), [menuItems])

    return (
        <RouteContext.Provider value={provider} >
            {children}
        </RouteContext.Provider>
    )
}

export default RouteContext