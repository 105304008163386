import { useState, Fragment, useEffect } from "react";
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import { timeZones } from '../../common/utils';
import { apiProvider } from "../../../services/api/provider";
import { reportMapping } from "./runReportUtils";
import SiteLoader from "../../common/SiteLoader";
import { DropdownList } from '../../common/dropdown'
import { useHistory, useLocation } from "react-router";
import ToastMessage from '../../common/ToastMessage';
import { getAccountLabel } from "../../../utils";
import { DROPDOWN_DESCRIPTION } from './MyReportsUtils/myReportUtils'
import { DEBUG_DROPDOWN_DESCRIPTION } from './DebugLogsUtils/debugLogsUtils'
import { isReportAllowed, filterKeys, getCustomReportApiUrls, setColumnFormatMap } from "./helperFunctions/helperFunction";
import { ReportSelectorAccounts } from './ReportSelectorAccounts'
import { FILTER_TYPE } from '../RunReports/MyReportsUtils/myReportUtils'
import { ReportSelector } from './ReportSelector';
import DeletePopup from "../../common/PopUp/DeletePopup"
import { G2ReviewPopup } from "../../common/G2ReviewPopup";

const startDate = new Date()
const endDate = new Date()

startDate.setHours(0, 0, 0)
endDate.setHours(23, 59, 0)

const RunReports = () => {
    const location = useLocation()
    const history = useHistory()
    const isAdminLogin = localStorage.getItem('isAdminUser');

    const isAdminUser = localStorage.getItem('userName')?.toLowerCase() === 'admin'
    const isNewUser = localStorage.getItem('isNewUser') === 'true'
    const [availableReport, setAvailableReport] = useState()
    const [isAnyReportAllowed, setIsAnyReportAllowed] = useState(false)
    const [isBlairReportAllowed, setIsBlairReportAllowed] = useState(false)
    const [reportsList, setReportsList] = useState([])
    const [deletePopUp, setDeletePopUp] = useState()
    const [isAssistLLCReportAllowed, setIsAssistLLCReportAllowed] = useState(false)

    const [reportConfig, setReportConfig] = useState({
        type: 'location',
        report: 'type-1',
        option: 'group',
        customReportsName: ''
    });
    const [params, setParams] = useState({
        date_fr: startDate,
        date_to: endDate,
        reportFor: 'all',
        tzStr: 'GMT+05:30',
        device_tz_selected: "",
        offsetStr: "",
        limit: "",
        advance: "no",
        adpOption: "",
        accountSelected: localStorage.getItem("accountId"),
        action: 'all',
        reportType: "true"
    })
    const [showLoader, setShowLoader] = useState(false);
    const [reportForData, setReportForData] = useState({
        groups: [],
        employees: []
    });
    const [generalSettingsData, setGeneralSettingsData] = useState({
        dateDisplayFormat: 'dd/MM/yyyy',
        timeFormat: 'hh:mm:ss a',
        timezone: 'GMT+05:30'
    })
    const [toastMsg, setToastMsg] = useState({
        msg: "",
        type: "msgSuccess"
    });


    const [showG2Popup, setShowG2Popup] = useState(false);


    // const isG2PopupReport =JSON.parse(localStorage.getItem('ShowG2PopupReport')??"false")
    const sysAdminG2Enbled = JSON.parse(localStorage.getItem('sysAdminG2Enabled'));
    const storedItem = localStorage.getItem('hasG2Reviewed');
    const hasG2Reviewed = (storedItem !== 'undefined' && storedItem !== null) ? JSON.parse(storedItem) : null;
    // let isAdminLogin = JSON.parse(localStorage.getItem('isAdminLogin'));
    const doNotShowG2 = JSON.parse(localStorage.getItem('g2ReviewInactive'));
    const showG2Review = isAdminLogin === "true" ? false : true && !hasG2Reviewed && !doNotShowG2 && showG2Popup && sysAdminG2Enbled

    useEffect(() => {
        const isG2PopupReport = JSON.parse(localStorage.getItem('ShowG2PopupReport') ?? "false");
        if (isG2PopupReport) {
            setTimeout(() => {
                setShowG2Popup(true);
                localStorage.setItem('ShowG2PopupReport', false)
            }, 6000);
        }
    }, []);

    const getGroupsData = async () => {
        try {
            const grpResult = await apiProvider.getAll('track/mobile/v1/allgeo/getGroups?includeAll=true')
            let grp = (grpResult.groups).sort((a, b) => (a.groupDesc).toLowerCase() > (b.groupDesc).toLowerCase() ? 1 : -1)
                .filter(grp => grp.groupDesc)
                .map(grp => ({ label: grp.groupDesc === 'all' ? 'All' : grp.groupDesc, value: grp.groupID }))
            return { ...grpResult, groups: grp }
        } catch (error) {
            console.log(error);
        }
    }

    const getEmployeesData = async () => {
        try {
            const empResult = await apiProvider.getAll('track/mobile/v1/allgeo/list/devices')
            let emp = (empResult.employees)
                .filter((ele) => {
                    return (ele?.isActive).toLowerCase() === 'active'
                })
                .filter(ele => ele.name.trim().length > 0)
                .sort((a, b) => (a.name).toLowerCase() > (b.name).toLowerCase() ? 1 : -1)
                .map(emp => ({ label: emp.name === 'all' ? 'All' : emp.name, value: emp.id }))
            return { ...empResult, employees: emp }
        } catch (error) {
            console.log(error);
        }
    }

    const getReportForData = async () => {
        try {
            setShowLoader(true)
            const response = await Promise.all([getGroupsData(), getEmployeesData()])
            setReportForData({ groups: response[0].groups, employees: response[1].employees })
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    const getGeneralSettingsData = async () => {
        try {
            const response = await await apiProvider.getAll('track/react/account/generalSetting')
            setGeneralSettingsData({
                dateDisplayFormat: response.report_units.date_display_format,
                timeFormat: response.report_units.datetime.toLowerCase() === 'standard' ? 'hh:mm:ss a' : 'HH:mm:ss',
                timezone: response.basic_details.timezone
            })
            setParams({
                ...params,
                tzStr: response.basic_details.timezone
            })
        } catch (error) {
            console.log(error);
        }
    }

    const runReport = async () => {
        if (selectedReport.isCustomReport) {
            try {
                const response = await apiProvider.getAll(`track/mobile/v1/allgeo/getReportTemplate?templateName=${selectedReport.title}&version=v3`);

                const parseresponse = JSON.parse(response.templateData);
                const parseGridOptions = JSON.parse(parseresponse[0].gridOptions);
                selectedReport.column = parseGridOptions.columns
                selectedReport.dataState = parseGridOptions.dataState
                selectedReport.api = getCustomReportApiUrls(selectedReport.masterTemplateType)
                selectedReport.defaultGroupedColumns = parseGridOptions.dataState.group
                selectedReport.aggregateText = parseGridOptions.aggregatesText
                selectedReport.reportParams = { isV3Report: true }

                if (parseGridOptions.dataState.group.length > 0 && parseGridOptions.dataState.group[0].hasOwnProperty('aggregates')) {
                    const aggregates = parseGridOptions.dataState.group[0].aggregates;
                    selectedReport.aggregates = aggregates
                }
                if (selectedReport.masterTemplateType === "WOPerformaceReport" && parseGridOptions.hasOwnProperty('masterTemplateType')) {
                    console.log('kakak')
                    params.workorderType = parseGridOptions.masterTemplateType
                }
                if (selectedReport.masterTemplateType !== "WOPerformaceReport" && parseGridOptions.hasOwnProperty('masterTemplateType')) {
                    params.stageid = parseGridOptions.masterTemplateType
                }

                selectedReport.columnFormatMap = setColumnFormatMap(selectedReport);

            } catch (error) {
                console.log(error);
            }
        }

        if (reportConfig.option === 'employee' && params.reportFor === 'all') {
            setToastMsg({ msg: 'Please select an Employee', type: '' })
            return
        }
        const reportTableParams = {
            selectedReport,
            reportForData,
            reportConfig,
            params,
            dateDisplayFormat: generalSettingsData.dateDisplayFormat,
            timeFormat: generalSettingsData.timeFormat,
            timezone: generalSettingsData.timezone
        }

        if (selectedReport?.title === "Audit Report for API (allGeo)" && reportTableParams.params.adpOption === "") {
            reportTableParams.params.adpOption = "TIMECARDS"
        }

        sessionStorage.setItem('runReports', JSON.stringify(reportTableParams))

        history.push(`${location.pathname}?tab=reportTable`)
    }

    //here we are setting the params to fetch API
    const handleChange = evt => {
        setParams({
            ...params,
            reportFor: evt.value.id
        })
    }
    const handleDropdownChange = e => {
        const dropdownId = e.target.value.id;

        if (DROPDOWN_DESCRIPTION.hasOwnProperty(dropdownId)) {
            reportMapping[reportConfig.type][reportConfig.report].description = DROPDOWN_DESCRIPTION[dropdownId];
        }
        if (DEBUG_DROPDOWN_DESCRIPTION.hasOwnProperty(dropdownId)) {
            reportMapping[reportConfig.type][reportConfig.report].description = DEBUG_DROPDOWN_DESCRIPTION[dropdownId];
        }

        setParams({
            ...params,
            adpOption: e.target.value.id
        })
    }

    const getReportPermission = async () => {
        try {
            setShowLoader(true)
            const response = await apiProvider.getAll('track/mobile/reportsList')
            response.push("default") // pushing default to show default allowed reports
            setIsBlairReportAllowed(response.includes("blairduronpaydata"))
            setIsAssistLLCReportAllowed(response.includes("assistservicepaydatareport"))
            setAvailableReport(response)
            setShowLoader(false)
        } catch (error) {
            console.log(error);
            setShowLoader(false)
        }
    }

    const handleCloseG2PopupReport = () => {
        setShowG2Popup(false)
        localStorage.setItem('ShowG2PopupReport', false)
    }


    const selectedReport = reportMapping[reportConfig.type][reportConfig.report]

    useEffect(async () => {
        if (sessionStorage.getItem('runReports')) {
            const { reportForData, reportConfig, timezone, timeFormat, dateDisplayFormat, params } = JSON.parse(sessionStorage.getItem('runReports'))

            setParams({
                ...params,
                date_fr: new Date(params.date_fr),
                date_to: new Date(params.date_to)
            })
            setReportConfig(reportConfig)
            setReportForData(reportForData)
            setGeneralSettingsData({
                timezone,
                timeFormat,
                dateDisplayFormat
            })

            sessionStorage.removeItem('runReports')
        } else {
            getGeneralSettingsData()
            getReportForData()
        }
        const isFormDirty = JSON.parse(localStorage.getItem('formIsDirty'))

        if (isFormDirty) {
            localStorage.removeItem('formIsDirty')
        }
        getReportPermission()
        await customBuildReports()
        closePopup()
    }, [])

    useEffect(() => {
        // this use effect is use to handle adpOption payload for api. it is used in customreport and debuglogs
        if (selectedReport?.title === "Audit Report for API (allGeo)" && (params.adpOption === "" || params.adpOption === "TIMECARDS")) {
            params.adpOption = "TIMECARDS"
        } else if (selectedReport?.title === "ADP Paydata Report" && (params.adpOption === "" || params.adpOption === "standard")) {
            params.adpOption = "standard"
        } else {
            params.adpOption = ""
        }

        if (selectedReport.isEditHistoryReport) {
            // this condition is used to reset date becaue edit history report needs only 15 days date filter
            setParams((prevParams) => ({
                ...prevParams,
                date_fr: startDate,
                date_to: endDate
            }));

        }

    }, [reportConfig])

    const setParamFunc = (e) => {
        if (e.target.value === "Device") {
            setParams({ ...params, tzStr: e.target.value, device_tz_selected: "Device" })
        }
        else if (e.target.value === "Location") {
            setParams({ ...params, tzStr: e.target.value, device_tz_selected: "Location" })
        }
        else {
            setParams({ ...params, tzStr: e.target.value, device_tz_selected: "" })
        }
    }

    const employeeLabel = getAccountLabel('employee');
    const groupLabel = getAccountLabel('group');
    const workorderLabel = getAccountLabel('workorder');

    useEffect(() => {
        setIsAnyReportAllowed(isReportAllowed(reportConfig, reportMapping, availableReport));
    }, [reportConfig, availableReport]);

    const customBuildReports = async () => {
        try {
            setShowLoader(true)
            const response = await apiProvider.getAll('track/react/v1/allgeo/customTemplate')
            setReportsList(response.reports)

            reportMapping.customReport = Object.fromEntries(
                // here removing existinf custom report from objects
                Object.entries(reportMapping.customReport).filter(([key, value]) => !value.isCustomReport)
            );

            if (response.reports.length) {
                let currentTypeIndex = Object.keys(reportMapping.customReport).length;

                response.reports.forEach((item) => {
                    currentTypeIndex += 1;
                    const newTypeKey = `type-${currentTypeIndex}`;

                    reportMapping.customReport[newTypeKey] = {
                        title: item.templateName,
                        type: newTypeKey,
                        masterTemplateType: item.masterTemplateID,
                        reportapikey: "default",
                        isCustomReport: true,
                        filterType: "grouponly",
                    };

                });
            }

            setShowLoader(false)
        } catch (error) {
            console.log(error);
            setShowLoader(false)
        }
    }

    const closePopup = () => {
        setDeletePopUp(false)
    }

    const onSuccess = async () => {
        const payload = selectedReport.title
        try {
            setShowLoader(true)
            await apiProvider.remove(`/track/react/v1/allgeo/customTemplate/${payload}`)
            setToastMsg({ msg: 'Report deleted successfully ', type: 'msgSuccess' })
            setDeletePopUp(false)
            setShowLoader(false)
            customBuildReports()
        } catch (error) {
            console.log(error);
            setToastMsg({ msg: 'Report deleted successfully ', type: 'msgSuccess' })
            setShowLoader(false)
        }
    }

    return (
        <Fragment>

            <section>
                {showG2Review && <G2ReviewPopup showPopup={showG2Popup} setShowPopup={handleCloseG2PopupReport} />}
                <div className="threeBlkContainer">
                    <div className="contLeft">
                        <div className="sectionSubHeading">Select Report Type</div>
                        <ul className="typeMenu">
                            <li onClick={() => setReportConfig({ ...reportConfig, report: 'type-1', type: 'location' })} className={`${reportConfig.type === 'location' && 'active'}`}><a href={void (0)}>Location</a></li>
                            <li onClick={() => setReportConfig({ ...reportConfig, report: 'type-1', type: 'time' })} className={`${reportConfig.type === 'time' && 'active'}`}><a href={void (0)}>Time</a></li>
                            <li onClick={() => setReportConfig({ ...reportConfig, report: 'type-1', type: 'fieldData' })} className={`${reportConfig.type === 'fieldData' && 'active'}`}><a href={void (0)}>Field Data</a></li>
                            <li onClick={() => setReportConfig({ ...reportConfig, report: 'type-1', type: 'workOrder' })} className={`${reportConfig.type === 'workOrder' && 'active'}`}><a href={void (0)}>{workorderLabel}</a></li>

                            <li onClick={() => setReportConfig({ ...reportConfig, report: 'type-1', type: 'accounts' })} className={`${reportConfig.type === 'accounts' && 'active'}`}><a href={void (0)}>Account</a></li>
                            <li onClick={() => setReportConfig({ ...reportConfig, report: 'type-1', type: 'debugLogs', option: 'group' })} className={`${reportConfig.type === 'debugLogs' && 'active'}`}><a href={void (0)} >Debug Logs</a></li>
                            <li onClick={() => setReportConfig({ ...reportConfig, report: 'type-1', type: 'customReport', option: 'group' })} className={`${reportConfig.type === 'customReport' && 'active'}`}><a href={void (0)} >My Reports</a></li>

                        </ul>
                        {!isNewUser && <div className="topGap2" >
                            <a className="btnStyle btn2" href={`${process.env.REACT_APP_API_URL}/track/mgt?page=menu.rpt.grpDetail.v1`} >Go Back To Old Version</a>
                        </div>}
                    </div>

                    <div className="contMed">
                        <div className="sectionSubHeading">Select Report</div>
                        <div className="scrollContainer">
                            {
                                reportConfig.type === "accounts" ?
                                    <ReportSelectorAccounts
                                        isAnyReportAllowed={isAnyReportAllowed}
                                        reportMapping={reportMapping}
                                        reportConfig={reportConfig}
                                        setReportConfig={setReportConfig}
                                        handleDropdownChange={(e) => {
                                            setParams({
                                                ...params,
                                                reportType: e.target.value.id
                                            })
                                        }}
                                        availableReport={availableReport}
                                        setParams={setParams}
                                        params={params}
                                    />
                                    :
                                    <ReportSelector
                                        isAnyReportAllowed={isAnyReportAllowed}
                                        reportMapping={reportMapping}
                                        reportConfig={reportConfig}
                                        setReportConfig={setReportConfig}
                                        handleDropdownChange={handleDropdownChange}
                                        availableReport={availableReport}
                                        reportsList={reportsList}
                                        setDeletePopUp={setDeletePopUp}
                                        isBlairReportAllowed={isBlairReportAllowed}
                                        isAssistLLCReportAllowed={isAssistLLCReportAllowed}
                                    />
                            }

                        </div>
                    </div>
                    <div className="contRight">
                        {selectedReport?.title != "Error Detail Report" && selectedReport?.title != "Device Audit Report" && !selectedReport?.isEditHistoryReport &&
                            <>
                                {selectedReport?.filterType === FILTER_TYPE.EMP_ONLY ? <div className="sectionSubHeading">Select {employeeLabel}</div> :
                                    selectedReport === undefined || selectedReport?.filterType === FILTER_TYPE.GROUP_ONLY ? <div className="sectionSubHeading">Select {groupLabel}</div> :
                                        <div className="sectionSubHeading">Select {employeeLabel}/{groupLabel}</div>
                                }
                                <div className="innerBlk2">
                                    <div className="customControls controlHrz fldWrap">
                                        {(!selectedReport?.filterType || selectedReport?.filterType === FILTER_TYPE.BOTH) && (
                                            <div className="customRadio">
                                                {selectedReport?.filterType !== "grouponly" ?
                                                    <>
                                                        <input
                                                            type="radio"
                                                            id="Group"
                                                            name="selectEmployee"
                                                            checked={reportConfig.option === 'group'}
                                                            onChange={() => {
                                                                setReportConfig({ ...reportConfig, option: 'group' });
                                                                setParams({ ...params, reportFor: 'all' })
                                                            }}
                                                        />
                                                        <label htmlFor="Group">{groupLabel}</label>
                                                    </> : null}
                                            </div>
                                        )}
                                        {(!selectedReport?.filterType || selectedReport?.filterType === FILTER_TYPE.BOTH) && (
                                            <div className="customRadio">
                                                <input
                                                    type="radio"
                                                    id="Employee"
                                                    name="selectEmployee"
                                                    checked={reportConfig.option === 'employee'}
                                                    onChange={() => {
                                                        setReportConfig({ ...reportConfig, option: 'employee' });
                                                        setParams({ ...params, reportFor: 'all' })
                                                    }}
                                                />
                                                <label htmlFor="Employee">{employeeLabel}</label>
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <label>Select {reportConfig.option === 'group' ? groupLabel : 'Device'}</label>
                                        <DropdownList
                                            list={reportConfig.option === 'group' ? reportForData.groups : reportForData.employees}
                                            filterable={true}
                                            onChange={handleChange}
                                            defaultValue={params.reportFor}
                                        />
                                    </div>
                                </div>
                            </>
                        }
                        <div className="sectionSubHeading">Select Date Range</div>
                        <div className="innerBlk2">
                            <div className="fldWrap">
                                <label>Start Date & Time</label>
                                <DateTimePicker
                                    placeholder={"Start Date & Time"}
                                    value={params.date_fr}
                                    onChange={(e) => {

                                        if (selectedReport.isEditHistoryReport) {
                                            const selectedDate = e.value;

                                            if (selectedDate) {
                                                const fifteenDaysLater = new Date(selectedDate);
                                                fifteenDaysLater.setDate(fifteenDaysLater.getDate() + 15);

                                                if (!params.date_to || selectedDate.getTime() <= params.date_to.getTime()) {
                                                    if (params.date_to && params.date_to.getTime() > fifteenDaysLater.getTime()) {
                                                        setToastMsg({ msg: `The Start Date must be within 15 days of the End Date.`, type: "msgError" })
                                                    } else {
                                                        setParams({
                                                            ...params,
                                                            date_fr: e.value
                                                        })
                                                    }
                                                }
                                            }

                                        } else {
                                            if (e.value.getTime() > params.date_to.getTime()) return

                                            setParams({
                                                ...params,
                                                date_fr: e.value
                                            })
                                        }
                                    }}
                                    format={`${generalSettingsData.dateDisplayFormat || 'MM/dd/yyyy'} ${generalSettingsData.timeFormat || 'hh:mm:ss a'}`}
                                />
                            </div>
                            <div className="fldWrap">
                                <label>End Date & Time</label>
                                <DateTimePicker
                                    placeholder={"End Date & Time"}
                                    value={params.date_to}
                                    onChange={(e) => {

                                        if (selectedReport.isEditHistoryReport) {
                                            const selectedDate = e.value;
                                            if (selectedDate) {
                                                const fifteenDaysEarlier = new Date(selectedDate);
                                                fifteenDaysEarlier.setDate(fifteenDaysEarlier.getDate() - 15);

                                                if (!params.date_fr || selectedDate.getTime() >= params.date_fr.getTime()) {
                                                    if (params.date_fr && params.date_fr.getTime() < fifteenDaysEarlier.getTime()) {
                                                        setToastMsg({ msg: `The End Date must be within 15 days of the Start Date.`, type: "msgError" })
                                                    } else {
                                                        setParams({
                                                            ...params,
                                                            date_to: e.value
                                                        })
                                                    }
                                                }
                                            }
                                        } else {
                                            if (e.value.getTime() < params.date_fr.getTime()) return

                                            setParams({
                                                ...params,
                                                date_to: e.value
                                            })
                                        }

                                    }}
                                    format={`${generalSettingsData.dateDisplayFormat || 'MM/dd/yyyy'} ${generalSettingsData.timeFormat || 'hh:mm:ss a'}`}
                                />
                            </div>
                            <div>
                                <label>Select Timezone</label>
                                <select name="timezone" value={params.tzStr || generalSettingsData.timezone} onChange={(e) => setParamFunc(e)} >
                                    {
                                        timeZones.map((t, ind) => (
                                            <option key={ind} value={t.value}>{t.label}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="btnWrap">
                            <button className="btnStyle" onClick={runReport} disabled={!isAnyReportAllowed} >Run Report</button>
                        </div>
                    </div>
                </div>
            </section>
            {toastMsg.msg && <ToastMessage
                cssClass={toastMsg.type}
                message={toastMsg.msg}
                onClose={() => setToastMsg({ msg: "", type: "" })}
            />}
            {deletePopUp == true && <DeletePopup onSuccess={onSuccess} closePopup={closePopup} />}
            <div className={`overlay${deletePopUp ? ' active' : ''}`} />
            <SiteLoader isActive={showLoader} />
        </Fragment>
    )
}


export default RunReports