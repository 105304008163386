import React, { useState, useContext, useEffect } from "react";
import LeftContent from "./LeftContent";
import useInput from '../../hooks/useInput';
import { Link, useHistory } from "react-router-dom";
import AuthContext from '../../store/auth-context';
import { apiProvider } from '../../services/api/provider';
import ToastMessage from '../common/ToastMessage';
import GoogleLoginComponent from './GoogleLoginComponent';
import { useDocumentTitle } from '../../hooks/setDocumentTitle';

const LoginEmail = (props) => {
  useDocumentTitle("Login - allGeo");
  const history = useHistory();
  const authCtx = useContext(AuthContext);

  const [passwordFieldType, setPasswordFieldType] = useState('password');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoginError, setIsLoginError] = useState(false);
  const [loginErrorMsg, setLoginErrorMsg] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

  useEffect(() => {
    let remember = localStorage.getItem('remember_email');
    if (remember) {
      setRememberMe(true);
      setDefaultValueEmailId(remember);
    }
  }, []);


  const changeFieldType = () => { passwordFieldType === 'password' ? setPasswordFieldType('text') : setPasswordFieldType('password') };

  let formIsValid = false;

  const {
    value: enteredEmail,
    isValid: enteredEmailIsValid,
    hasError: enteredEmailHasError,
    valueChangeHandler: enteredEmailChangedHandler,
    inputBlurHandler: enteredEmailBlurHandler,
    inputFocusHandler: enteredEmailFocusHandler,
    isFocused: emailIsFocused,
    setDefault: setDefaultValueEmailId,
  } = useInput(value => value.trim() !== '' && /\S+@\S+\.\S+/.test(value.trim()));

  let emailInputClasses = '';
  if (enteredEmailHasError) {
    emailInputClasses = 'errorFld';
  } else if (emailIsFocused) {
    emailInputClasses = 'active';
  } else {
    emailInputClasses = '';
  }

  const {
    value: enteredPassword,
    isValid: enteredPasswordIsValid,
    hasError: enteredPasswordHasError,
    valueChangeHandler: enteredPasswordChangedHandler,
    inputBlurHandler: enteredPasswordBlurHandler,
    inputFocusHandler: enteredPasswordFocusHandler,
    isFocused: passwordIsFocused,
    reset: enteredPasswordInput
  } = useInput(value => value.trim() !== '');

  let passwordInputClasses = '';
  if (enteredPasswordHasError) {
    passwordInputClasses = 'errorFld';
  } else if (passwordIsFocused) {
    passwordInputClasses = 'active';
  } else {
    passwordInputClasses = '';
  }

  if (enteredEmailIsValid && enteredPasswordIsValid) {
    formIsValid = true;
  }

  const loginWithEmailHandler = (event) => {
    event.preventDefault();
    if (!formIsValid || isLoading) {
      return;
    }

    let loginForm = {
      emailId: enteredEmail,
      password: enteredPassword,
      source:"REACT_EMAIL",
    };

    if (rememberMe) {
      localStorage.setItem('remember_email', enteredEmail);
    } else {
      localStorage.removeItem('remember_email');
    }

    setIsLoading(true);
    apiProvider.post('track/react/v1/login', loginForm).then((response) => {
      setIsLoading(false);
      switch (response.status) {
        case 200:
          console.log(response);
          localStorage.setItem('isNewMonitorPage',response?.isNewMonitorPage)

          localStorage.setItem('employeeAppData',response?.monitorCards?.employeeAppData)
          localStorage.setItem('mapAndLocation',response?.monitorCards?.mapAndLocation)
          localStorage.setItem('milesData',response?.monitorCards?.milesData)
          localStorage.setItem('notificationsAndAlerts',response?.monitorCards?.notificationsAndAlerts)
          localStorage.setItem('timeClockData',response?.monitorCards?.timeClockData)
          localStorage.setItem('timeClockReports',response?.monitorCards?.timeClockReports)


          const expirationTime = new Date(new Date().getTime() + +response.expiry);
          authCtx.login(response.token, response.token_en, expirationTime.toISOString(), response.is_parent, response.accountId, response.userName, response.isBannerEnabled, response.bannerText, response.userSecurityType, response.actSecurityType);
          localStorage.setItem('lastLoggedInForm', 'email');
          localStorage.setItem('isOnBoarding',response.isOnBoarding)
          history.replace('/');
          break;
        case 401:
          handelAuthErrorMsg(response.message);
          break;
        case 45:
          history.replace('/trial-account-expired');
          break;
        default:
          handelAuthErrorMsg(response.message);
      }


    }).catch(err => console.log(err));
  }

  const handelAuthErrorMsg = (serverMessage) => {
    let msg = <>{serverMessage}</>;
    setIsLoginError(true);
    if (serverMessage == 'Invalid Credentials') {
      msg = <>Credentials are invalid. If you don't remember your password, <Link to='/reset-password'>reset it now.</Link></>;
    }
    setLoginErrorMsg(msg);
  }

  const checkBoxChangeHandler = (event) => {
    event.target.checked ? setRememberMe(true) : setRememberMe(false);
  }
  let signUpUrl = '../sign-up-freetrial-field-service-management-apps';
  if(process.env.REACT_APP_API_URL === 'https://app.allgeo.com') {
    signUpUrl = 'https://www.allgeo.com/sign-up-freetrial-field-service-management-apps'
  }

  return (
    <div className="userPageWrapper">
      <LeftContent><p>Don't have an account? <a href={signUpUrl}>Start your free trial</a></p></LeftContent>
      <div className="rightContent">
        <h2>Customer Login</h2>
        <form onSubmit={loginWithEmailHandler}>
          <div className={`fldWrap emailFld fldWithIcon ${emailInputClasses}`}>
            <input type="text" placeholder="Email"
              onFocus={enteredEmailFocusHandler}
              onChange={enteredEmailChangedHandler}
              name="email" id="email"
              value={enteredEmail}
              onBlur={enteredEmailBlurHandler}
            />
            <span className="fldIcon">Icon</span>
          </div>
          {
            isIOS &&
            <div className={`fldWrap withShowPassword passwordFld fldWithIcon ${passwordInputClasses}`}>
              <input type="text" className={`${passwordFieldType == 'password' ? 'password-mask' : ''}`} placeholder="Password"
                onFocus={enteredPasswordFocusHandler}
                onChange={enteredPasswordChangedHandler}
                name="password" id="password"
                value={enteredPassword}
                onBlur={enteredPasswordBlurHandler}
              />
              <span className="fldIcon">Icon</span>
              {passwordFieldType === 'password' && <span onClick={changeFieldType} className="showPassword">Show</span>}
              {passwordFieldType === 'text' && <span onClick={changeFieldType} className="hidePassword">Show</span>}
            </div>
          }
          {
            !isIOS &&
            <div className={`fldWrap withShowPassword passwordFld fldWithIcon ${passwordInputClasses}`}>
              <input type={passwordFieldType} placeholder="Password"
                onFocus={enteredPasswordFocusHandler}
                onChange={enteredPasswordChangedHandler}
                name="password" id="password"
                value={enteredPassword}
                onBlur={enteredPasswordBlurHandler}
              />
              <span className="fldIcon">Icon</span>
              {passwordFieldType === 'password' && <span onClick={changeFieldType} className="showPassword">Show</span>}
              {passwordFieldType === 'text' && <span onClick={changeFieldType} className="hidePassword">Show</span>}
            </div>
          }

          <div className="customCheck">
            <input type="checkbox" id="customCheck" onChange={e => checkBoxChangeHandler(e)} checked={rememberMe} />
            <label className="customCheckLabel" htmlFor="customCheck">
              Remember me
            </label>
          </div>
          <button className="btnStyle" disabled={!formIsValid || isLoading}>
            LOGIN
          </button>
          <Link to="/reset-password">Forgot your password?</Link>
          <div className="orSeparator">
            <span>OR</span>
          </div>
          <button onClick={props.toggleButtonOnClick} className="btnStyle btn3 iconBtn accountID">
            Login with Account ID
          </button>
          <GoogleLoginComponent />
        </form>
      </div>
      {
        isLoginError && <ToastMessage cssClass="msgError" message={loginErrorMsg} onClose={() => { setIsLoginError(false); setLoginErrorMsg(''); }} />
      }

    </div>
  );
}

export default LoginEmail;