import React, { useEffect, useState } from 'react'
import { MultiSelect } from '@progress/kendo-react-dropdowns'
import { filterBy } from "@progress/kendo-data-query";

const itemRender = (li, itemProps) => {

    const { dataItem: { selectable } } = itemProps

    if (typeof selectable === 'boolean' && !selectable) {
        return (
            <div className="multiSelectDivider">
                <label>{li.props.children}</label>
            </div>
        )
    }

    const itemChildren = (
        <div className="customMultiSelect">
            <label>{li.props.children}</label>
            <div className="customCheck noTxt">
                <input
                    type="checkbox"
                    name={itemProps.dataItem}
                    checked={itemProps.selected || itemProps.dataItem.selected}
                    onChange={(e) => itemProps.onClick(itemProps.index, e)}
                />
                <label className="customCheckLabel" htmlFor={li.props.children}>{li.props.children}</label>
            </div>
        </div>
    );
    return React.cloneElement(li, li.props, itemChildren);
};

const MultiSelectDropdown = ({ data, value, onChange, onBlur, dataItemKey = 'id', textField = 'text', placeholder = "Select Item", className = '' }) => {

    const [dropdownData, setDropdownData] = useState(data);

    useEffect(() => {
        setDropdownData(data)
    }, [data])

    const tagRender = (tagData, li) => {
        if (tagData.text != 'Select All') {
            return React.cloneElement(li, li.props, [
                <li key={Math.random()} className="custom-tag" style={{ "listStyleType": 'none' }}>
                    {tagData.text == 'Select All' ? 'Clear All' : tagData.text}
                </li>, li.props.children,
            ])
        }
    };

    return (

        <div className='attachmentWrap'>
            <MultiSelect
                data={dropdownData || []}
                value={value || []}
                onChange={onChange}
                itemRender={itemRender}
                tagRender={tagRender}
                dataItemKey={dataItemKey}
                textField={textField}
                autoClose={false}
                placeholder={placeholder}
                className={className}
                filterable={true}
                onBlur={onBlur}
                onFilterChange={e => {
                    const filteredData = filterBy(data, e.filter)
                    setDropdownData(filteredData)
                }}
            />
        </div>

    )
}

export default MultiSelectDropdown