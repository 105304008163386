import React, { Fragment, useRef, useEffect, useState } from 'react'
import { apiProvider } from '../../../../services/api/provider'
import KendoGrid from '../../../common/kendo/KendoGrid'
import { customDateFormat, editTimeClockColumns, NO_RECORD_TEXT, reportTableAggregatesText, reportTableAggregates } from '../editReportUtils';
import { ManagerNotesPopup } from '../ManagerNotesPopup';
import { formatDate } from '@progress/kendo-intl';

const params = {
    date_fr: formatDate(new Date(), 'yyyy/MM/dd'),
    date_to: formatDate(new Date(), 'yyyy/MM/dd'),
    reportFor: "all",
    tzStr: "",
    timeclockSource: "manual",
    reportSource: "editReports"
}

const EditTimeClock = ({ columnName, dropdownData, setShowLoader, toastMsg, setToastMsg, accessLevel, setColumnList, columnList, setKendoDataState }) => {

    const globalStartDate = sessionStorage.getItem('EditReport:startDate')
    const globalEndDate = sessionStorage.getItem('EditReport:endDate')
    const reportForValues = sessionStorage.getItem('EditReport:reportFor')
    const userId = localStorage.getItem("userName")

    if (globalStartDate && globalEndDate) {
        params.date_fr = globalStartDate
        params.date_to = globalEndDate
        params.reportFor = reportForValues || ''
    }

    const [standardTimeClockData, setStandardTimeClockData] = useState(null)
    const [managerNotesPopup, setManagerNotesPopup] = useState({
        show: false,
        data: null,
        createData: null
    })

    const childRef = useRef()

    const getTimeClockReports = async () => {
        try {
            setShowLoader(true)
            const response = await apiProvider.getAll('/track/mobile/v1/allgeo/timeclockstandardreport', params)
            setStandardTimeClockData(response)
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    const createReportData = async dataArr => {
        const payload = [...dataArr].map(fields => ({
            deviceID: fields.deviceID,
            userId: userId,
            deviceName: fields.deviceName,
            stageName: fields.stageName,
            tempcol: customDateFormat(fields.tcSentDate, 'yyyy/MM/dd', dropdownData.dateFormat) + " " + fields.tcSentTime,
            customerName: fields.customerName,
            jobName: fields.jobName,
            distanceTravelled: fields.distanceTravelled,
            managerNotes: fields.managerNotes,
            remarks: fields.remarks
        }))

        try {
            const res = await apiProvider.post('/track/api/v1/createTimeClock', payload)
            return {
                status: res.StatusCode,
                message: res.StatusCode === 200 ? "Report created successfully" : res.ErrorMsg
            };
        } catch (error) {
            console.log(error);
        }
    }

    const updateTimeClock = async data => {
        const payload = [...data].filter(d => d.txnID).map(fields => {

            let sentTime = fields.tcSentTime.split(" ")

            if (sentTime.length > 2) {
                sentTime = sentTime[0] + " " + sentTime[1]
            } else {
                sentTime = sentTime[0]
            }

            return {
                accountID: fields.accountID,
                deviceID: fields.deviceID,
                userId: userId,
                txnID: fields.txnID,
                timeClockSentTime: fields.timeClockSentTime,
                tcSentDate: fields.tcSentDate,
                tcSentTime: fields.tcSentTime,
                tempcol: customDateFormat(fields.tcSentDate, 'yyyy/MM/dd', dropdownData.dateFormat) + " " + sentTime,
                customerName: fields.customerName,
                jobName: fields.jobName,
                distanceTravelled: fields.distanceTravelled,
                oldDistanceTravelled: fields.oldDistanceTravelled,
                managerNotes: fields.managerNotes,
                remarks: fields.remarks,
                stageName: fields.stageName,
            }
        })

        const recordWithoutTxnIDs = data.filter(d => !d.txnID)

        if (data.length > 0) {
            setManagerNotesPopup({
                show: true,
                data: payload,
                createData: recordWithoutTxnIDs
            })
            return {}
        }

        try {

            if (recordWithoutTxnIDs.length > 0) {
                await createReportData(recordWithoutTxnIDs)
            }

            const response = await apiProvider.post('/track/api/v1/updateTimeClock', payload)

            return {
                status: response.StatusCode,
                message: "Edit Time Clock Report Updated Successfully"
            }
        } catch (error) {
            console.log(error);
        }
    }

    const deleteReport = async data => {

        let payload = data.length ? data : [data.txnID]
        payload = payload.filter((txnID) => txnID)
        try {
            setShowLoader(true)
            const response = await apiProvider.removeObject('/track/mobile/v1/allgeo/deleteTimeclock', payload)
            if (data.length)
                response.message = "The selected records have been deleted successfully";
            else
                response.message = `Selected Report is deleted`

            return {
                status: response.StatusCode,
                message: response.message,
            };
        } catch (error) {
            console.log(error);
            throw new Error(error)
        } finally {
            setShowLoader(false)
        }
    }

    const openPopup = (type, data) => {

        if (type === "latlog" && data[type]) {
            window.open(`https://maps.google.com/maps?q=${data.latlog}`, "_blank")
        }
    }

    useEffect(() => {
        // replacing static column data from api data that we getting from props
        // editTimeClockColumns.forEach(item => {
        //     if(Object.keys(columnName).includes(item.field)){
        //         item.title = columnName[item.field] || item.title
        //     }
        // })

        const updatedEditTimeClockColumns = editTimeClockColumns.map(item => {
            if (Object.keys(columnName).includes(item.field)) {
                return {
                    ...item,
                    title: columnName[item.field] || item.title
                };
            }
            return item;
        });

        setColumnList(updatedEditTimeClockColumns);

        getTimeClockReports()
    }, [])

    useEffect(() => {
        if (toastMsg.msg && toastMsg.type === "msgSuccess") {
            if (childRef.current) {
                childRef.current.refreshData()
            }
            setShowLoader(false)
        }
    }, [toastMsg])

    const totalServiceHours = editTimeClockColumns.find(e => e.field == "serviceSeconds").title

    return (
        <Fragment>
            {
                standardTimeClockData &&
                <KendoGrid
                    gridData={standardTimeClockData}
                    setGridData={setStandardTimeClockData}
                    columnData={columnList}
                    accessLevel={accessLevel}
                    ref={childRef}
                    module={"editReports"}
                    getAPiUrl={'/track/mobile/v1/allgeo/timeclockstandardreport'}
                    requestParams={params}
                    openPopup={openPopup}
                    createItem={createReportData}
                    deleteItem={deleteReport}
                    lockActionColumn={true}
                    updateItem={updateTimeClock}
                    bulkDelete={deleteReport}
                    otherData={{
                        actionColumnBtns: ['delete'],
                        enableExportToPDF: true,
                        subPage: "editTimeclock",
                        ...dropdownData,
                        enableFooterDisplay: true,
                        dropdownDependency: {
                            "deviceName": {
                                fieldToBeMapped: "deviceID",
                                data: dropdownData.employees?.map(emp => ({
                                    key: emp.name,
                                    value: emp.employeeID
                                }))
                            },
                            "deviceID": {
                                fieldToBeMapped: "deviceName",
                                data: dropdownData.employees?.map(emp => ({
                                    key: emp.employeeID,
                                    value: emp.name
                                }))
                            }
                        },
                        aggregates: reportTableAggregates,
                        aggregateText: reportTableAggregatesText,
                        noRecordText: NO_RECORD_TEXT,
                        showExportDropdown: true,
                        columnFormatMap: {
                            [totalServiceHours]: 'hh:mm:ss'
                        },
                        setColumnList: setColumnList,
                        columnList: columnList,
                        setKendoDataState: setKendoDataState,
                        hideMenuCloumns: true
                    }}
                />
            }
            {managerNotesPopup.show &&
                <ManagerNotesPopup
                    data={managerNotesPopup.data}
                    setShowLoader={setShowLoader}
                    setToastMsg={setToastMsg}
                    api={'/track/api/v1/updateTimeClock'}
                    successMsg={"Edit Time Clock Report Updated Successfully"}
                    closePopup={() => setManagerNotesPopup({ show: false, data: null, createData: null })}
                    reportType={'editTimeClock'}
                    createData={managerNotesPopup.createData}
                    dropdownData={dropdownData}
                />
            }
        </Fragment>
    )
}

export default EditTimeClock