import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Prompt } from 'react-router';
import $ from "jquery";

import { timeZones } from './managersUtils';
import { apiProvider } from '../../../services/api/provider';
import { ConfirmPopup } from '../../common/PopUp';
import { JobsitesAuthorizationSettings } from "./JobsitesAuthorizationSettings/JobsitesAuthorizationSettings";
import { getAccountLabel } from '../../../utils/getAccountLabel';

const loginPageItemOrderMapping = {
    "home": 0,
    "homePage": 1,
    "mastertrip.info": 2,
    "phonelocate": 3,
    "dev.info": 5,
    "menu.rpt.devDetail": 4,
}


const EMPLOYEE = "acl.admin.device";
const EMPLOYEEPAGE = "dev.info";

const MONITOR = "acl.app.empLocate";
const MONITORPAGE = "phonelocate";

const verifyAcl = (payload, aclList) => {

    const employeeAcl = aclList.find(item => item.aclName === EMPLOYEE);
    const monitorAcl = aclList.find(item => item.aclName === MONITOR);

    if(employeeAcl && employeeAcl.assigned === 0 && payload[0].firstLoginPage === EMPLOYEEPAGE) {
        payload = [{...payload[0], firstLoginPage: MONITORPAGE}]
        return payload;
    }

    if(monitorAcl && monitorAcl.assigned === 0 && payload[0].firstLoginPage === MONITORPAGE) {
        payload = [{...payload[0], firstLoginPage: EMPLOYEEPAGE}]
        return payload;
    }
    return payload
}


const EditManager = (props) => {

    const { register, handleSubmit, setValue, reset, formState: { isDirty, errors, isValid } } = useForm({ mode: "all" });
    const [managerDetails, setManagersDetails] = useState({});
    const [groupAuthorization, setGroupAuthorization] = useState('');
    const [jobsitesAuthorization, setJobsitesAuthorization] = useState('');
    const [checkboxs, setCheckBoxes] = useState({
        status1: null,
        trackManager: null,
        hideDeviceID: null,
        managerAccountLocked: null
    });
    const [passwordFieldType, setPasswordFieldType] = useState('password');
    const [password, setPassword] = useState(1);
    const [passwordRequirements, setPasswordRequirements] = useState({
        isLengthValid: false,
        hasNumber: false,
        hasSpecialCharacter: false,
        isLengthValid: false,
        hasNumber: false,
        hasSpecialCharacter: false,
        hasLowerCase: false,
        hasUpperCase: false,
        noCompanyName: false,
        noUserName: false,
    });
    const [passValidation,setPassValidation] = useState(false)
    const [accountType, setAccountType] = useState(1);
    const [companyName, setCompanyName] = useState("");
    const [userName, setUserName] = useState("");
    const [showDialog, setShowDialog] = useState(false);
    const [standardPass, setStandardPass] = useState(false);
    const [highPass, setHighPass] = useState(false);

    useEffect(() => {
        if (managerDetails.isChanged === true) {
            localStorage.setItem('formIsDirty', isDirty)
        }
    }, [managerDetails?.isChanged])

    useEffect(() => {
        if (isDirty || managerDetails.isChanged === true) {
            localStorage.setItem('formIsDirty', isDirty)
        }
    }, [isDirty, managerDetails?.isChanged])

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        props.setShowLoader(true);

        apiProvider.getSingle('track/mobile/v1/allgeo/manager', props.managerId).then((response) => {
            if (response.status === 200 && response.message.toLowerCase() === 'success') {
                setAccountType(response.managers[0].accountType);
                setCompanyName(response.managers[0].accountID);
                setUserName(response.managers[0].managerID);
                let managerData = {
                    ...response.managers[0],
                    updatedMaxAclList: Object.entries(response.managers[0].maxAclList).map(entry => ({
                        value: entry[0],
                        label: entry[1]
                    })),
                    updateLoginPageList: (() => {
                        const loginPageList = response.managers[0].loginPageList;
                
                        // Extract "home" entry
                        const homeEntry = loginPageList.home;
                
                        // Delete "home" entry from the original object
                        delete loginPageList.home;
                
                        // Create an array with "home" entry at the front and the rest of the entries
                        const updatedLoginPageList = [
                            { value: 'home', label: "Homepage", sortIndex: loginPageItemOrderMapping.home },
                            ...Object.entries(loginPageList)
                            .map(entry => ({
                                value: entry[0],
                                label: entry[1] === 'Reports' ? 'Report' : entry[1],
                                sortIndex: loginPageItemOrderMapping[entry[0]]
                            }))
                        ];
                
                        // Sort the array based on sortIndex
                        return updatedLoginPageList.sort((a, b) => a.sortIndex - b.sortIndex);
                    })(),
                    isChanged: false
                };

                setManagersDetails(managerData)
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            props.setShowLoader(false);
        });

    };

    useEffect(() => {
        if (managerDetails && Object.keys(managerDetails).length > 0) {
            setValue('managerDesc', managerDetails.managerDesc, { shouldDirty: false });
            setValue('contactName', managerDetails.contactName, { shouldDirty: false });
            setValue('contactPhone', managerDetails.contactPhone, { shouldDirty: false });
            setValue('contactEmail', managerDetails?.contactEmail, { shouldDirty: false });
            setValue('notifyEmail', managerDetails?.notifyEmail, { shouldDirty: false });
            setValue('otpMedium', managerDetails?.otpMedium, { shouldDirty: false });
            setValue('status', managerDetails?.active === 'Yes' ? true : false, { shouldDirty: false });
            setValue('trackManager', managerDetails?.trackManager, { shouldDirty: false });
            setValue('timezone', (managerDetails?.timezone === "") ? 'Select' : managerDetails?.timezone, { shouldDirty: false });
            setValue('hideDeviceID', managerDetails?.hideDeviceID, { shouldDirty: false });
            setValue('maxAcl', managerDetails?.maxAcl, { shouldDirty: false })
            setCheckBoxes({
                status1: checkboxs.status1 === null ? managerDetails?.active === 'Yes' ? true : false : checkboxs.status1,
                trackManager: checkboxs.trackManager === null ? managerDetails?.trackManager : checkboxs.trackManager,
                hideDeviceID: checkboxs.hideDeviceID === null ? managerDetails?.hideDeviceID : checkboxs.hideDeviceID,
                managerAccountLocked: (localStorage.getItem('userName')).toLowerCase() === 'admin' ? checkboxs.managerAccountLocked === null ? managerDetails?.managerAccountLocked : checkboxs.managerAccountLocked : null
            })
            if ((managerDetails?.authorizedGroups).length === 1 && managerDetails?.authorizedGroups[0] === 'all') {
                setGroupAuthorization('all')
            } else {
                setGroupAuthorization('specific')
            }
           
            if ((localStorage.getItem('userName')).toLowerCase() === 'admin') {
                setValue('pwd', managerDetails?.pwd, { shouldDirty: false });
                setValue('locked', managerDetails?.locked, { shouldDirty: false });
            }
        }
    }, [managerDetails])

    const handleChange = (e) => {
        if (Object.keys(managerDetails).length > 0) {
            /* an additional boundary was added above, as the responsible developer facing a weird problem */

            let updatedManagerDetails;

            let name = e.target.name;
            let value = e.target.value;


            if (name === 'contactPhone') {

                updatedManagerDetails = {
                    ...managerDetails,
                    [name]: value.replace(/[a-zA-Z]/g, ''),
                    isChanged: true
                }

            } if (name === 'pwd') {

                setPassValidation(true)

                const newPassword = e.target.value;

                if (newPassword.length === 0) {
                    setPassword(0)
                }

                if (accountType == 2) {
                    setHighPass(true)
                    //password criteria for High
                    const isLengthValid = newPassword.length >= 8;
                    const hasNumber = /\d/.test(newPassword);
                    const hasSpecialCharacter = /[!@#$%^&*]/.test(newPassword);
                    const hasLowerCase = /[a-z]/.test(newPassword);
                    const hasUpperCase = /[A-Z]/.test(newPassword);
                    const noCompanyName = !newPassword.includes(companyName)
                    const noUserName = !newPassword.includes(userName);

                    setPasswordRequirements({
                        isLengthValid,
                        hasNumber,
                        hasSpecialCharacter,
                        hasLowerCase,
                        hasUpperCase,
                        noCompanyName,
                        noUserName
                    });

                    updatedManagerDetails = {
                        ...managerDetails,
                        [name]: newPassword,
                        isChanged: true
                    }
                }
                else {
                    setStandardPass(true)
                    // Check password criteria for standard
                    const isLengthValid = newPassword.length >= 8;
                    const hasNumber = /\d/.test(newPassword);
                    const hasSpecialCharacter = /[!@#$%^&*]/.test(newPassword);

                    setPasswordRequirements({
                        isLengthValid,
                        hasNumber,
                        hasSpecialCharacter,
                    });

                    updatedManagerDetails = {
                        ...managerDetails,
                        [name]: newPassword,
                        isChanged: true
                    }
                }
            }
            else {

                updatedManagerDetails = {
                    ...managerDetails,
                    [name]: value,
                    isChanged: true
                }

            }

            setManagersDetails(updatedManagerDetails)
        }
    }


    const onSubmit = async (data) => {

        let managerPermission = managerDetails.aclList.filter((ele) => ele.desc === 'Monitor');
        let employeePermission = managerDetails.aclList.filter((ele) => ele.desc === 'Employee');
        let isAdmin = localStorage.getItem('isAdminUser')

        if(passValidation && isAdmin){
            let passwordRequirementsCheck = false;

            if (accountType === 2) {
                passwordRequirementsCheck =
                    passwordRequirements.isLengthValid &&
                    passwordRequirements.hasNumber &&
                    passwordRequirements.hasSpecialCharacter &&
                    passwordRequirements.hasLowerCase &&
                    passwordRequirements.hasUpperCase &&
                    passwordRequirements.noCompanyName &&
                    passwordRequirements.noUserName;
            } else if (accountType === 1) {
                passwordRequirementsCheck =
                    passwordRequirements.isLengthValid &&
                    passwordRequirements.hasNumber &&
                    passwordRequirements.hasSpecialCharacter;
            }
    
            if (!passwordRequirementsCheck) {
                props.setToastMsg({ msg: "Please fulfill all the requirements for changing the password", type: "" });
                return;
            }

        }

        if (managerPermission[0]?.assigned === 0 && employeePermission[0]?.assigned === 0) {

            props.setToastMsg({ msg: "Please provide ACL permission for either the Employee or the Monitor page under User Access Control to proceed.", type: "" });

        } else if ((data.trackManager === true || props.mobileManage === true) && data.contactPhone === "") {

            props.setToastMsg({ msg: "Phone number should not be empty", type: "" });

        }
        else {


            try {

                props.setShowLoader(true);

                let payload = [
                    {
                        "accountID": managerDetails?.accountID,
                        "managerID": managerDetails?.managerID,
                        "active": checkboxs.status1 ? "Yes" : "No",
                        "managerDesc": data.managerDesc,
                        "pwd": managerDetails?.pwd,
                        "contactName": data.contactName,
                        "contactPhone": data.contactPhone,
                        "contactEmail": data.contactEmail,
                        "timezone": data.timezone,
                        "notifyEmail": data.notifyEmail,
                        "otpMedium": (managerDetails?.otpMedium).toLowerCase(),
                        "webManage": managerDetails?.webManage,
                        "mobileManage": managerDetails?.mobileManage,
                        "trackManager": checkboxs.trackManager,
                        "authorizedGroups": managerDetails?.authorizedGroups,
                        "authorizedCustomers": managerDetails?.authorizedCustomers,
                        "firstLoginPage": managerDetails?.firstLoginPage,
                        "preferredDeviceID": managerDetails?.preferredDeviceID,
                        "maxAcl": +data.maxAcl,
                        "hideDeviceID": checkboxs.hideDeviceID,
                        "locked": (localStorage.getItem('userName')).toLowerCase() === 'admin' ? checkboxs.managerAccountLocked : managerDetails?.locked,
                        "phoneVerified": managerDetails?.phoneVerified,
                        "userRole": managerDetails?.userRole,
                        "lastLoginTime": "",
                        "aclList": managerDetails?.aclList
                    }
                ]

                const employeeAcl = managerDetails?.aclList.find(item => item.aclName === EMPLOYEE);
                const monitorAcl = managerDetails?.aclList.find(item => item.aclName === MONITOR);
                if (employeeAcl?.assigned === 0 || monitorAcl.assigned === 0) {
                    payload = verifyAcl(payload, managerDetails?.aclList)
                }

                const response = await apiProvider.put("track/mobile/v1/allgeo/manager", payload)
                if (response.status === 200) {
                    props.setToastMsg({ msg: "The changes have been saved successfully", type: "msgSuccess" });
                    reset();
                    setStandardPass(false);
                    setHighPass(false);
                    setPassword(1)
                    setPasswordFieldType("password")
                } else {
                    props.setToastMsg({ msg: response.message, type: "msgError" });
                }

            } catch (error) {
                console.log(error);
            } finally {
                localStorage.removeItem('formIsDirty')
                props.setShowLoader(false)
                fetchData()
            }
        }
    }

    const changeFirstLogin = (e) => {
        let updatedManagerDetails = {
            ...managerDetails,
            firstLoginPage: e.target.value,
            isChanged: true
        }
        setManagersDetails(updatedManagerDetails)
    }

    const copyContactEmail = () => {
        reset({ notifyEmail: '' });
        let updatedManagerDetails = {
            ...managerDetails,
            notifyEmail: managerDetails?.contactEmail,
            isChanged: true
        }
        setManagersDetails(updatedManagerDetails)
    }

    const changeAclList = (type, index, value) => {

        let updatedAclList = managerDetails.aclList.map((ele, ind) => {
            if (index === ind) {
                if (type === 'web') {
                    return Object.assign({}, ele, { assigned: value > ele.assigned ? value : value - 1 })
                } else {
                    return Object.assign({}, ele, { api_assigned: value > ele.api_assigned ? value : value - 1 })
                }
            }
            return ele
        })

        let updatedManagerDetails = {
            ...managerDetails,
            aclList: [...updatedAclList],
            isChanged: true
        }

        setManagersDetails(updatedManagerDetails)
    }

    const changeGroupList = (index) => {
        let updatedManagerDetails = {}
        let newGroup = managerDetails?.groupList[index];
        let newGroupIndex = managerDetails.authorizedGroups.findIndex((ele) => ele === newGroup.groupId)

        if (newGroupIndex === -1) {
            updatedManagerDetails = {
                ...managerDetails,
                authorizedGroups: [...managerDetails.authorizedGroups, newGroup.groupId].filter(ele => ele !== "all"),
                isChanged: true
            }
        } else {
            updatedManagerDetails = {
                ...managerDetails,
                authorizedGroups: [...managerDetails.authorizedGroups.slice(0, newGroupIndex), ...managerDetails.authorizedGroups.slice(newGroupIndex + 1)],
                isChanged: true
            }
        }

        setManagersDetails(updatedManagerDetails)

    }

    const editFormCancelEvent = () => {
        if (managerDetails.isChanged === true) {
            setShowDialog(true)
        } else {
            props.setMode({
                title: props.mode.hasPrev ? "View Managers Information" : "Managers",
                type: props.mode.hasPrev ? 'view' : 'main',
                data: props.mode.hasPrev ? { managerID: props.managerId } : null,
                hasPrev: false
            })
        }
    }

    const onSuccess = () => {
        setShowDialog(false);
        props.setMode({
            title: props.mode.hasPrev ? "View Managers Information" : "Managers",
            type: props.mode.hasPrev ? 'view' : 'main',
            data: props.mode.hasPrev ? { managerID: props.managerId } : null,
            hasPrev: false
        })
    }

    const onFaliure = () => {
        setShowDialog(false);
    }

    const handleCheckBox = (type) => {
        switch (type) {
            case 1:
                setCheckBoxes({ ...checkboxs, status1: !checkboxs.status1 })
                setManagersDetails({ ...managerDetails, isChanged: true })
                break;
            case 2:
                setCheckBoxes({ ...checkboxs, trackManager: !checkboxs.trackManager })
                setManagersDetails({ ...managerDetails, isChanged: true })
                break;
            case 3:
                setCheckBoxes({ ...checkboxs, hideDeviceID: !checkboxs.hideDeviceID })
                setManagersDetails({ ...managerDetails, isChanged: true })
                break;
            case 4:
                setCheckBoxes({ ...checkboxs, managerAccountLocked: !checkboxs.managerAccountLocked })
                setManagersDetails({ ...managerDetails, isChanged: true })
                break;
            case 5:
                setCheckBoxes({ ...checkboxs, mobileManage: !checkboxs.mobileManage })
                setManagersDetails({ ...managerDetails, isChanged: true })
                break;
            default:
                break;
        }
    }

    const changeGroupAuthList = () => {
        let updatedManagerDetails = {
            ...managerDetails,
            authorizedGroups: ["all"],
            isChanged: true
        }
        setGroupAuthorization('all')
        setManagersDetails(updatedManagerDetails)
    }

    const changeJobsitesAuthList = () => {
        let updatedManagerDetails = {
            ...managerDetails,
            authorizedCustomers: ["all"],
            isChanged: true
        }
        setJobsitesAuthorization('all')
        setManagersDetails(updatedManagerDetails)
    }

    $(".copyIcon").on("click", function () {
        navigator.clipboard.writeText(managerDetails?.managerID);
        $(this).siblings('.copyClipboard').show().delay(4000).fadeOut();
    });


    const changeFieldType = () => {
        passwordFieldType === 'password' ? setPasswordFieldType('text') : setPasswordFieldType('password')
    };

    const groupLabel = getAccountLabel('group');
    return (
        <Fragment>
            <Prompt
                when={managerDetails?.isChanged}
                message='The changes are still unsaved. Are you sure you want to leave?'
            />
            <form onSubmit={handleSubmit(onSubmit)} id="managerForm">
                <Fragment>
                    <section>
                        <h3>Manager Information</h3>
                        <div className="supportedTxt">Check and update general information related to the manager user here.</div>
                        <div className="innerWrap">
                            <div className="highlightedFldWrap">
                                <div className="fldWrap">
                                    <label>Manager ID</label>
                                    <div className="highlightedValue">
                                        <span>{managerDetails?.managerID}</span>
                                        <span className="copyIcon">Copy</span>
                                        <span className="copyClipboard">Copied to the clipboard!</span>
                                    </div>
                                </div>
                                <div className="fldWrap">
                                    <label>Creation Date & Time</label>
                                    <div className="highlightedValue">
                                        <span>{managerDetails?.creationTime}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="rowBlk">
                                <div className="colBlk6 fldWrap">
                                    <label>Manager Description</label>
                                    <input type="text" name="managerDesc" {...register('managerDesc', { onChange: (e) => handleChange(e) })} />
                                </div>
                                <div className="colBlk3 fldWrap">
                                    <label>Is this an active manager?</label>
                                    <div className="customCheck yesNo">
                                        <input type="checkbox" id="activeManager" {...register('status')} checked={checkboxs.status1} onChange={() => handleCheckBox(1)} />
                                        <label className="customCheckLabel" htmlFor="activeManager">yesNo</label>
                                    </div>
                                </div>
                            </div>
                            <div className="rowBlk">
                                <div className="colBlk6 fldWrap">
                                    <label>Contact Name</label>
                                    <input type="text" name="contactName" {...register('contactName', { onChange: (e) => handleChange(e) })} />
                                </div>
                                {
                                    (localStorage.getItem('userName')).toLowerCase() === 'admin' &&
                                    <div className='colBlk3 fldWrap'>
                                        <label className="withHelpTxt">Password
                                            {
                                                standardPass && <span className="helpTip">? <span className="helpTip1">The password must be <strong>8 characters long,</strong> must have at least <strong>one number</strong> and must have at least <strong>one special character.</strong></span></span>
                                            }
                                            {
                                                highPass && <span className="helpTip">? <span className="helpTip1">The password must be <strong>8 characters long,</strong> must have at least <strong>one number</strong> must have at least <strong>one special character.</strong> must have at least <strong>one lower case character</strong> must have at least <strong>upper case character
                                                </strong> should not contain <strong> company name or abbreviation</strong> should not contain <strong>user's name</strong></span></span>
                                            }
                                        </label>
                                        <div className="fldWrap withShowPassword passwordFld">
                                            <input type={passwordFieldType === 'password' ? "password" : ""} name="pwd" {...register('pwd', { onChange: (e) => handleChange(e) })} />
                                            {
                                                password == 0 && passwordFieldType === 'password' ? <span onClick={changeFieldType} className="showPassword">Show</span> : password == 0 && passwordFieldType === 'text' ? <span onClick={changeFieldType} className="hidePassword">Show</span> : null
                                            }
                                        </div>
                                        {standardPass &&
                                            <ul className="checkRoundListing">
                                                <li className={passwordRequirements.isLengthValid ? 'active' : ''}>8 characters long</li>
                                                <li className={passwordRequirements.hasNumber ? 'active' : ''}>Minimum of one number</li>
                                                <li className={passwordRequirements.hasSpecialCharacter ? 'active' : ''}>Minimum of one special character</li>
                                            </ul>}
                                        {highPass &&
                                            <ul className="checkRoundListing">
                                                <li className={passwordRequirements.isLengthValid ? 'active' : ''}>8 characters long</li>
                                                <li className={passwordRequirements.hasNumber ? 'active' : ''}>Minimum of one number</li>
                                                <li className={passwordRequirements.hasSpecialCharacter ? 'active' : ''}>Minimum of one special character</li>
                                                <li className={passwordRequirements.hasLowerCase ? 'active' : ''}>At least one lower case character</li>
                                                <li className={passwordRequirements.hasUpperCase ? 'active' : ''}>At least one upper case character</li>
                                                <li className={passwordRequirements.noCompanyName ? 'active' : ''}>Should not contain company name or abbreviation</li>
                                                <li className={passwordRequirements.noUserName ? 'active' : ''}>Should not contain user's name</li>
                                            </ul>
                                        }
                                    </div>
                                }
                                <div className={`colBlk${(localStorage.getItem('userName')).toLowerCase() === 'admin' ? 3 : 6} fldWrap`}>
                                    <label>Phone</label>
                                    <input type="text" name="contactPhone" maxLength={15} {...register('contactPhone', { onChange: (e) => handleChange(e) })} />
                                </div>
                            </div>
                            <div className="rowBlk">
                                <div className={`colBlk6 fldWrap ${errors.contactEmail ? 'errFld' : ''}`}>
                                    <label>Contact Email</label>
                                    <input type="text" name="contactEmail" {...register('contactEmail', { pattern: /\S+@\S+\.\S+/, onChange: (e) => handleChange(e) })} />
                                    {errors.contactEmail && <div className="fldMsg">Please enter a valid email</div>}
                                </div>
                                <div className="colBlk3 fldWrap">
                                    <label>Timezone</label>
                                    <select name="timezone" {...register("timezone", { onChange: (e) => handleChange(e) })}>
                                        {
                                            [{ label: 'Select', value: 'Select' }, ...timeZones].map((t, ind) => (
                                                <option key={ind} value={t.value}>{t.label}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="rowBlk">
                                <div className={`colBlk6 fldWrap ${errors.notifyEmail ? 'errFld' : ''}`}>
                                    <label className="withAction">
                                        Notify Email
                                        <span className="actionTxt" onClick={() => copyContactEmail()}>Same as Contact Email</span>
                                    </label>
                                    <input type="text" name="notifyEmail" {...register('notifyEmail', { pattern: /\S+@\S+\.\S+/, onChange: (e) => handleChange(e) })} />
                                    {errors.notifyEmail && <div className="fldMsg">Please enter a valid email</div>}
                                </div>
                                <div className="colBlk6 fldWrap">
                                    <label>OTP Medium</label>
                                    <div className="customControls">
                                        <div className="customRadio">
                                            <input type="radio" id="otpsms" name="otmMedium" value="SMS"
                                                checked={managerDetails?.otpMedium && (managerDetails?.otpMedium).toLowerCase() === 'sms'}
                                                readOnly />
                                            <label htmlFor="otpsms">SMS</label>
                                        </div>
                                        <div className="customRadio">
                                            <input type="radio" id="otpemail" name="otmMedium" value="Email"
                                                checked={managerDetails?.otpMedium && (managerDetails?.otpMedium).toLowerCase() === 'email'}
                                                readOnly />
                                            <label htmlFor="otpemail">Email</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="rowBlk">
                                <div className="colBlk6 fldWrap">
                                    <label>Will this manager be managing employees using web application?</label>
                                    <div className="customCheck yesNo">
                                        <input type="checkbox" id="usingWeb" checked={managerDetails?.webManage} readOnly />
                                        <label className="customCheckLabel" htmlFor="usingWeb">yesNo</label>
                                    </div>
                                </div>
                                <div className="colBlk6 fldWrap">
                                    <label>Will this manager be tracked for time and location?</label>
                                    <div className="customCheck yesNo">
                                        <input type="checkbox" id="trackedTime" {...register('trackManager')} checked={checkboxs.trackManager} onChange={() => handleCheckBox(2)} />
                                        <label className="customCheckLabel" htmlFor="trackedTime">yesNo</label>
                                    </div>
                                </div>
                            </div>
                            <div className="rowBlk">
                                <div className="colBlk6 fldWrap">
                                    <label>Will this manager be managing employees using mobile application?</label>
                                    <div className="customCheck yesNo">
                                        <input type="checkbox" id="usingMobile" checked={managerDetails?.mobileManage} readOnly />
                                        <label className="customCheckLabel" htmlFor="usingMobile">yesNo</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <h3>Group Authorization Settings</h3>
                        <div className="supportedTxt">Select the groups that the manager is authorized to supervise here.</div>
                        <div className="innerWrap">
                            <div className="fldWrap">
                                <label>Select {groupLabel}</label>
                                <div className="customControls capsules">
                                    <div className="customRadio">
                                        <input type="radio" id="All" name="group"
                                            checked={
                                                groupAuthorization === 'all'
                                            }
                                            onClick={() => {
                                                if (groupAuthorization !== 'all') {
                                                    changeGroupAuthList()
                                                }
                                            }} />
                                        <label htmlFor="All">All</label>
                                    </div>
                                    <div className="customRadio">
                                        <input type="radio" id="Specific" name="group"
                                            checked={
                                                groupAuthorization !== 'all'
                                            }
                                            onClick={() => {
                                                if (groupAuthorization !== 'specific') {
                                                    setGroupAuthorization('specific')
                                                }
                                            }} />
                                        <label htmlFor="Specific">Specific</label>
                                    </div>
                                </div>
                            </div>
                            {
                                groupAuthorization !== 'all' &&
                                <ul className="blockCheckbox">
                                    {managerDetails?.groupList && managerDetails?.groupList.map((ele, ind) => (
                                        ele.groupId !== "all" &&
                                        <li key={ele.groupId}>
                                            <div className="customCheck">
                                                <input type="checkbox" id={`name${ind}`} checked={managerDetails?.authorizedGroups.includes(ele.groupId)} onChange={(e) => changeGroupList(ind)} />
                                                <label className="customCheckLabel" htmlFor={`name${ind}`}><span><em className="groupColor"
                                                    style={{ background: ele.colour }}>G</em>{ele.groupName === undefined || ele.groupName === "" ? ele.groupId : ele.groupName}</span></label>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            }
                        </div>
                    </section>
                    <section>
                        <JobsitesAuthorizationSettings managerDetails={managerDetails} setManagersDetails={setManagersDetails} changeJobsitesAuthList={changeJobsitesAuthList} setJobsitesAuthorization={setJobsitesAuthorization} jobsitesAuthorization={jobsitesAuthorization}/>
                    </section>
                    <section>
                        <h3>Login Page Settings</h3>
                        <div className="supportedTxt">Set your first landing page after login here.</div>
                        <div className="innerWrap">
                            <label>First Login Page</label>
                            <div className="fldWrap">
                                <div className="customControls controlWithIcon">
                                    {managerDetails?.updateLoginPageList && managerDetails?.updateLoginPageList.map((ele, ind) => (
                                        <div className="customRadio" key={ind}>
                                            <input type="radio"
                                                id={`${ele.label}Login`}
                                                name="LoginSettings"
                                                checked={ele.value === managerDetails?.firstLoginPage}
                                                onChange={(e) => changeFirstLogin(e)}
                                                value={ele.value} />
                                            <label className={`icon${ele.label}`} htmlFor={`${ele.label}Login`}>{ele.label}</label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <h3>Access Level Settings</h3>
                        <div className="innerWrap">
                            <div className="rowBlk">
                                <div className="colBlk6 fldWrap">
                                    <label>Maximum Access Level</label>
                                    <select name="maxAcl"
                                        defaultValue={managerDetails?.updatedMaxAclList && managerDetails?.updatedMaxAclList[managerDetails?.maxAcl]}
                                        {...register('maxAcl', { onChange: (e) => handleChange(e) })}>
                                        {managerDetails?.updatedMaxAclList && managerDetails?.updatedMaxAclList.map((ele, ind) => (
                                            <option value={ele.value} key={ind}>{ele.label}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="colBlk3 fldWrap">
                                    <label>Hide Device ID</label>
                                    <div className="customCheck yesNo">
                                        <input type="checkbox" id="HideDevice" {...register('hideDeviceID')} checked={checkboxs.hideDeviceID} onChange={() => handleCheckBox(3)} />
                                        <label className="customCheckLabel" htmlFor="HideDevice">yesNo</label>
                                    </div>
                                </div>
                                {
                                    (localStorage.getItem('userName')).toLowerCase() === 'admin' && <div className="colBlk3 fldWrap">
                                        <label>Unlock Manager Account</label>
                                        <div className="customCheck yesNo">
                                            <input type="checkbox" id="UnlockManager" {...register('locaked')} checked={checkboxs.managerAccountLocked} onChange={() => handleCheckBox(4)} />
                                            <label className="customCheckLabel" htmlFor="UnlockManager">yesNo</label>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </section>
                    <section>
                        <h3>User Access Control</h3>
                        <div className="supportedTxt">Choose the appropriate permission levels for various modules here.</div>
                        <div className="innerWrap">
                            <div className="rowBlk">
                                <div className="colBlk6">
                                    <div className="sectionSubHeading">Web Access Control</div>
                                    <table cellPadding="0" cellSpacing="0" className="tblStyle accessTbl">
                                        <tbody>
                                            <tr>
                                                <th>Entity</th>
                                                <th>View</th>
                                                <th>Edit</th>
                                                <th>Add/Delete</th>
                                            </tr>
                                            {managerDetails?.aclList && managerDetails?.aclList.map((ele, ind) => (
                                                <tr key={`web_${ind}`}>
                                                    <td>{ele.desc}</td>
                                                    {ele.max >= 1 &&
                                                        <td>
                                                            <div className="customCheck noTxt">
                                                                <input type="checkbox" id={`Web${ele.desc}View`} checked={ele.assigned >= 1} onChange={() => changeAclList('web', ind, 1)} />
                                                                <label className="customCheckLabel" htmlFor={`Web${ele.desc}View`}>noTxt</label>
                                                            </div>
                                                        </td>
                                                    }
                                                    {ele.max >= 2 &&
                                                        <td>
                                                            <div className="customCheck noTxt">
                                                                <input type="checkbox" id={`Web${ele.desc}Edit`} checked={ele.assigned >= 2} onChange={() => changeAclList('web', ind, 2)} />
                                                                <label className="customCheckLabel" htmlFor={`Web${ele.desc}Edit`}>noTxt</label>
                                                            </div>
                                                        </td>
                                                    }
                                                    {ele.max === 3 &&
                                                        <td>
                                                            <div className="customCheck noTxt">
                                                                <input type="checkbox" id={`Web${ele.desc}Add`} checked={ele.assigned === 3} onChange={() => changeAclList('web', ind, 3)} />
                                                                <label className="customCheckLabel" htmlFor={`Web${ele.desc}Add`}>noTxt</label>
                                                            </div>
                                                        </td>
                                                    }
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="colBlk6">
                                    <div className="sectionSubHeading">API Access Control</div>
                                    <table cellPadding="0" cellSpacing="0" className="tblStyle accessTbl">
                                        <tbody>
                                            <tr>
                                                <th>Entity</th>
                                                <th>View</th>
                                                <th>Edit</th>
                                                <th>Add/Delete</th>
                                            </tr>
                                            {managerDetails?.aclList && managerDetails?.aclList.map((ele, ind) => (
                                                <tr key={`api_${ind}`}>
                                                    <td>{ele.desc}</td>
                                                    {ele.api_max >= 1 &&
                                                        <td>
                                                            <div className="customCheck noTxt">
                                                                <input type="checkbox" id={`Api${ele.desc}View`} checked={ele.api_assigned >= 1} onChange={() => changeAclList('api', ind, 1)} />
                                                                <label className="customCheckLabel" htmlFor={`Api${ele.desc}View`}>noTxt</label>
                                                            </div>
                                                        </td>
                                                    }
                                                    {ele.api_max >= 2 &&
                                                        <td>
                                                            <div className="customCheck noTxt">
                                                                <input type="checkbox" id={`Api${ele.desc}Edit`} checked={ele.api_assigned >= 2} onChange={() => changeAclList('api', ind, 2)} />
                                                                <label className="customCheckLabel" htmlFor={`Api${ele.desc}Edit`}>noTxt</label>
                                                            </div>
                                                        </td>
                                                    }
                                                    {ele.api_max === 3 &&
                                                        <td>
                                                            <div className="customCheck noTxt">
                                                                <input type="checkbox" id={`Api${ele.desc}Add`} checked={ele.api_assigned === 3} onChange={() => changeAclList('api', ind, 3)} />
                                                                <label className="customCheckLabel" htmlFor={`Api${ele.desc}Add`}>noTxt</label>
                                                            </div>
                                                        </td>
                                                    }
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="mainBtnWrap">
                        <a href={void (0)} onClick={editFormCancelEvent}>Cancel</a>
                        <button disabled={!isValid || !managerDetails?.isChanged} className="btnStyle">SAVE</button>
                    </div>
                </Fragment>
            </form>
            <ConfirmPopup
                showDialog={showDialog}
                message={"The changes are still unsaved. Are you sure you want to leave?"}
                confirmNavigation={onSuccess}
                cancelNavigation={onFaliure}
            />
        </Fragment>
    )
}

export default EditManager