import { apiProvider } from "../../services/api/provider";

export const getGroupsData = async () => {
    try {
        const grpResult = await apiProvider.getAll('track/mobile/v1/allgeo/getGroups?includeAll=true')        
        let grp = (grpResult.groups)
        .sort((a, b) => (a.groupDesc).toLowerCase() > (b.groupDesc).toLowerCase() ? 1 : -1)
        .filter(grp => grp.groupDesc)
        .map(grp => ({ label: grp.groupDesc === 'all' ? 'All' : grp.groupDesc, value: grp.groupID }))
        
        return grp

    } catch (error) {
        console.log(error);
    }
}

export const getEmployeesData = async () => {
    try {
        const empResult = await apiProvider.getAll('track/mobile/v1/allgeo/devices?includeAll=true&operationType=mobile')
        let emp = (empResult.employees)
            .filter((ele, index) => {
                if (index > 0) {
                    return (ele?.isActive).toLowerCase() === 'active' && ele?.name.trim().length > 0
                } else {
                    return ele
                }
            })
            .sort((a, b) => (a.name).toLowerCase() > (b.name).toLowerCase() ? 1 : -1)
            .map(emp => ({ value: emp.id, label: emp.name === 'all' ? 'All' : emp.name }))
        return { employees: emp }
    } catch (error) {
        console.log(error);
    }
}