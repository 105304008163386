import React, { Fragment } from "react";
import { Link } from "react-router-dom";
const ResetPasswordSuccess = (props) => {
    return (
        <Fragment>
            <h2 className="successIcon">{ props.title }</h2>
                {props.children}
            <Link to="/login">Return to Login</Link>
        </Fragment>
        
    );
}
export default ResetPasswordSuccess;