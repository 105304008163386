export const sanDataVisits = [
    {
        title: `DeviceID`,
        field: "deviceId",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `Date`,
        field: "date",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `Entry ID`,
        field: "entryId",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `Visit Data`,
        field: "visitData",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `Last Successfull Operation`,
        field: "lastSuccessfulOperation",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `Last Successfull Time`,
        field: "lastSuccessfulTime",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `Last Failed Operation`,
        field: "lastFailedOperation",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `Last Failed Time`,
        field: "lastFailedTime",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `Comments`,
        field: "comments",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `Retry Required`,
        field: "retryRequired",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `Retry Attempt`,
        field: "retryAttempt",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
]

export const ADPSupplementalPaycode = [
    {
        title: `DeviceID`,
        field: "deviceId",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `Date`,
        field: "date",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `Entry ID`,
        field: "entryId",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `Supplemental Data`,
        field: "supplementalData",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `Last Successfull Operation`,
        field: "lastSuccessfulOperation",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `Last Successfull Time`,
        field: "lastSuccessfulTime",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `Last Failed Operation`,
        field: "lastFailedOperation",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `Last Failed Time`,
        field: "lastFailedTime",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `Comments`,
        field: "comments",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `Retry Required`,
        field: "retryRequired",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `Retry Attempt`,
        field: "retryAttempt",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
]

export const ADPTimeCard = [
    {
        title: `DeviceID`,
        field: "deviceId",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `Date`,
        field: "date",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `Entry ID`,
        field: "entryId",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `Time Card`,
        field: "timeCard",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `Last Successfull Operation`,
        field: "lastSuccessfulOperation",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `Last Successfull Time`,
        field: "lastSuccessfulTime",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `Last Failed Operation`,
        field: "lastFailedOperation",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `Last Failed Time`,
        field: "lastFailedTime",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `Comments`,
        field: "comments",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `Retry Required`,
        field: "retryRequired",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `Retry Attempt`,
        field: "retryAttempt",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
]

export const AUDIT_REPORTS_FOR_API_DROPDOWN = [
    { label: "ADP Timecard", value: "TIMECARDS" },
    { label: "ADP Supplemental Paycode", value: "SUPPLEMENTAL_PAYCODE" },
    { label: "Sandata Visits", value: "VISITS" },
]

export const SAN_DATA_VISITS_DESC = "DeviceID,Date,EntryID,Visit Data,Last Successful Operation,Last Successful Time,Last Failed Operation,Last Failed Time,Comments,Retry Required	,Retry Attempt"

export const ADP_TIME_CARD = 'DeviceID,Date,EntryID,TimeCard,Pay Code,Last Successful Operation,Last Successful Time,Last Failed Operation,Last Failed Time,Comments,Retry Required,Retry Attempt'

export const ADP_SUPPLEMENT_PAYLOAD = "DeviceID,Date,EntryID,Supplemental Data,Last Successful Operation,Last Successful Time,Last Failed Operation,Last Failed Time,Comments,Retry Required,Retry Attempt"

export const DEBUG_DROPDOWN_DESCRIPTION = {
    "TIMECARDS": ADP_TIME_CARD,
    "SUPPLEMENTAL_PAYCODE": ADP_SUPPLEMENT_PAYLOAD,
    "VISITS": SAN_DATA_VISITS_DESC,
};

export const DEBUG_DROPDOWN_COLUMN_MAPPING = {
    "TIMECARDS": {
        column: ADPTimeCard,
    },
    "SUPPLEMENTAL_PAYCODE": {
        column: ADPSupplementalPaycode,
    },
    "VISITS": {
        column: sanDataVisits,
    },
};



export const editHistoryReportColumnsName = [
    {
        title: `Account ID`,
        field: "accountID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `User`,
        field: "userID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `Entity ID`,
        field: "entityID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `Entity Type`,
        field: "entityType",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `Action`,
        field: "transactionName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `Field`,
        field: "fieldName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `Updated Value`,
        field: "updatedValue",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `Previous Value`,
        field: "previousValue",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `Creation Time`,
        field: "creationTime",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
]


export const EDIT_HISTORY_REPORT_DESCRIPTION = "Account ID, User, User Id, Entity Type, Action, Field, Updated Value, Previous Value, Creation Time"
