import React, { useContext, Fragment, useState } from "react";
import ReactDOM from "react-dom";
import { Switch, Route, Redirect, BrowserRouter } from "react-router-dom";
import CreatePassword from "./components/createPassword/CreatePassword";
import ResetPassword from "./components/resetPassword/ResetPassword";
import MainLayout from "./components/layout/MainLayout";
import PageNotFound from "./components/404";
import AuthContext from "./store/auth-context";
import "@progress/kendo-theme-default/dist/all.css";
import "./assets/css/custom.css";
import "./assets/css/AccountsReport.css";
import "./assets/css/Error-detail.css";
import "./assets/css/build-report.css";
import { AdminLogin, AfterLogin, AfterLogout, Login, TrialAccountExpired } from './components/login';
// import "./assets/css/build-report.css";
import { MapView } from './components/company';
import Routes from "./Routes/Routes";
import Unauthorized from "./components/Unauthorized";
import { Dashboard } from "./pages/index";
import { TwoFactorLogin } from "./components/login/TwoFactorLogin/TwoFactorLogin";
import { Monitor as MonitorV2 } from "./pages/Monitor";
import { MapViewData } from "./pages/Monitor/MileageAndTracking/MapAndLocation/MapViewData";
import { EmployeeAppDataNewtab } from "./pages/Monitor/MileageAndTracking/EmployeeAndAppData/EmployeeAppDataNewtab/EmployeeAppDataNewtab";
import { MapAndLocationNewtab } from "./pages/Monitor/MileageAndTracking/MapAndLocation/MapAndLocationNewtab/MapAndLocationNewtab";
import { MilesDataNewtab } from "./pages/Monitor/MileageAndTracking/MilesData/MilesDataNewtab/MilesDataNewtab";
import { NotificationAndAlertsNewtab } from "./pages/Monitor/common/NotificationAndAlerts/NotificationAndAlertsNewtab/NotificationAndAlertsNewtab";
import { MonitorWrapper } from "./components/monitor";

function App() {
  const authCtx = useContext(AuthContext);

  const getConfirm = (message, callback) => {
    const container = document.createElement("div");

    const handleConfirm = (callbackState) => {
      ReactDOM.unmountComponentAtNode(container);
      callback(callbackState);
      localStorage.removeItem("formIsDirty");
    };

    const handleCancel = (_callbackState) => {
      ReactDOM.unmountComponentAtNode(container);
      callback();
    };

    let ele = document.getElementById("mainContent");

    ele.appendChild(container);

    ReactDOM.render(
      <Fragment>
        <div className={`popupWrap msgPopup active`}>
          <div className="msgPopupCont">
            <div className="msgIcon">Info</div>
            <div className="msgContent">
              <div>{message}</div>
            </div>
          </div>
          <div className="popupMsgBtn">
            <a href={void 0} className="okBtn" onClick={handleCancel}>
              Cancel
            </a>
            <button className="btnStyle okBtn" onClick={handleConfirm}>
              Yes
            </button>
          </div>
        </div>
        <div className={`overlay active`} />
      </Fragment>,

      container
    );
  };

  const [is2FAChecked, setis2FAChecked] = useState(false);
  const [is2FAEnabled, setIs2FAEnabled] = useState(false);
  const [accountID, setAccountID] = useState();
  const [contactPhoneNumber, setContactPhoneNumber] = useState();
  const [userID, setUserID] = useState({
    userName: "",
    userID: "",
  });
 
  const getRoutingPath = () => {
    const landingPage = localStorage.getItem("landingPage");
    if (landingPage) {
      if (landingPage == "menu.rpt.devDetail") {
        return "/reports";
      } else if (landingPage == "dev.info") {
        return "/company/employees";
      } else if (landingPage == "homePage") {
        return "/dashboard";
      } else if (landingPage == "home") {
        return "/home";
      }else if (landingPage == 'mastertrip.info') {
        return "/schedule/work-order"
      }
      else if (landingPage == "phonelocate"&&JSON.parse(localStorage.getItem('isNewMonitorPage'))) {
        return `/monitor/new-monitor-employees`;
      }
      else if (landingPage == "phonelocate"&&!JSON.parse(localStorage.getItem('isNewMonitorPage'))) {
        return "/monitor/monitor-employees";
      }
    } else{
       return JSON.parse(localStorage.getItem('isNewMonitorPage'))?`/monitor/new-monitor-employees`:"/monitor/monitor-employees";
      }
    // else return "/monitor/monitor-employees";
  };

  const homepagePath = getRoutingPath();
  const loginToken = localStorage.getItem("token");
  const isNewMonitorAccess=JSON.parse(localStorage.getItem('isNewMonitorPage'))
  const isMileageAndTrackingEnable=JSON.parse(localStorage.getItem('isNewMonitorPage'))

  const isSysAdmin = accountID === "sysadmin";
  console.log(homepagePath,"homepagePath")
  return (
    <BrowserRouter
      basename={process.env.REACT_APP_BASE_NAME}
      getUserConfirmation={getConfirm}
    >
      
      <Switch>
        <Route path="/" exact>
          {!authCtx.isLoggedIn && <Redirect to="/login" />}
          {authCtx.isLoggedIn && isSysAdmin && <Redirect to="/after-login" />}
          {authCtx.isLoggedIn &&
            (is2FAChecked || !is2FAEnabled) &&
            !isSysAdmin && <Redirect to={homepagePath} />}
          {authCtx.isLoggedIn &&
            is2FAEnabled &&
            !is2FAChecked &&
            !isSysAdmin && <Redirect to="/track" />}
          {authCtx.isLoggedIn &&
            !is2FAEnabled &&
            !is2FAChecked &&
            !isSysAdmin && (
              <Redirect
                to={JSON.parse(localStorage.getItem('isNewMonitorPage')) ?  "/monitor/new-monitor-employees" : "/monitor/monitor-employees"}
              />
            )}
        </Route>

        <Route path="/login">
          {authCtx.isLoggedIn && isSysAdmin && <Redirect to="/after-login" />}
          {!authCtx.isLoggedIn && (
            <Login
              is2FAChecked={is2FAChecked}
              is2FAEnabled={is2FAChecked}
              setAccountID={setAccountID}
              setContactPhoneNumber={setContactPhoneNumber}
              setIs2FAEnabled={setIs2FAEnabled}
              setis2FAChecked={setis2FAChecked}
              setUserID={setUserID}
            />
          )}
          {authCtx.isLoggedIn &&
            (is2FAChecked || !is2FAEnabled) &&
            !isSysAdmin && <Redirect to={homepagePath} />}
        </Route>

        <Route path="/create-password">
          <CreatePassword />
        </Route>

        <Route path="/trial-account-expired">
          <TrialAccountExpired />
        </Route>

        <Route path="/logout">
          <AfterLogout />
        </Route>
        {authCtx.isLoggedIn && loginToken && (
          <Route path="/dashboard">
            <Dashboard />
          </Route>
        )}

{authCtx.isLoggedIn && loginToken && !isSysAdmin&&isNewMonitorAccess &&JSON.parse(localStorage.getItem('employeeAppData'))&& (
          <Route path="/monitor/employee-app-data">
            <EmployeeAppDataNewtab />
          </Route>
        )}
        {authCtx.isLoggedIn && loginToken && !isSysAdmin&&isNewMonitorAccess &&JSON.parse(localStorage.getItem('notificationsAndAlerts'))&& (
          <Route path="/monitor/notification-alerts">
            <NotificationAndAlertsNewtab />
          </Route>
        )}
        {authCtx.isLoggedIn && loginToken && !isSysAdmin &&isNewMonitorAccess&&isMileageAndTrackingEnable&&JSON.parse(localStorage.getItem('milesData'))&& (
          <Route path="/monitor/miles-data">
            <MilesDataNewtab />
          </Route>
        )}
        {authCtx.isLoggedIn && loginToken && !isSysAdmin &&isNewMonitorAccess&&isMileageAndTrackingEnable&&JSON.parse(localStorage.getItem('mapAndLocation'))&& (
          <Route path="/monitor/map-location">
            <MapAndLocationNewtab />
          </Route>
        )}
        {/* {authCtx.isLoggedIn && loginToken && !isSysAdmin && (
          <Route path="/monitor/monitor-employees">
            <MonitorWrapper />
          </Route>
        )} */}

        {/* {authCtx.isLoggedIn && loginToken && (
          <Route path="/monitorv2/monitor-employees">
            <MonitorV2 />
          </Route>
        )} */}
        <Route path='/track' exact>
          <TwoFactorLogin contactPhoneNumber={contactPhoneNumber} setis2FAChecked={setis2FAChecked} userID={userID}/>
        </Route>

        <Route path="/admin-login">
          {!authCtx.isLoggedIn && <AdminLogin />}
          {authCtx.isLoggedIn && <Redirect to="/after-login" />}
        </Route>

        {authCtx.isLoggedIn && (
          <Route path={"/after-login"}>
            <AfterLogin />
          </Route>
        )}

        {!authCtx.isLoggedIn && (
          <Route path="/reset-password">
            <ResetPassword />
          </Route>
        )}

        {authCtx.isLoggedIn && (
          <Route path="/company/jobsites/map-view" exact>
            <MapView />
          </Route>
        )}

        {authCtx.isLoggedIn && (
          <MainLayout>
            <Routes />
            <Route path={"/unauthorized"}>
              <Unauthorized />
            </Route>
          </MainLayout>
        )}
        <Route path="/*">
          <PageNotFound />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
