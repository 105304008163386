import * as React from 'react';

import { Popup } from '@progress/kendo-react-popup';

const CustomPopup = (props) => {
    return (
        <Popup
            {...props}
            anchorAlign={{
                horizontal: 'center',
                vertical: 'bottom',
            }}
            popupAlign={{
                horizontal: 'right',
                vertical: 'bottom',
            }}
        />
    );
};

export default CustomPopup;
