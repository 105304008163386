import React, { useEffect, useState } from "react";
import "./employeeAppDataNewtab.css";
import { EmployeesAppDataTable } from "../EmployeesAppDataTable";
import { LocalizationProvider } from "@progress/kendo-react-intl";
import { Pager } from "@progress/kendo-react-data-tools";
import PinIcon from "../../../../../assets/images/pin-icon.svg";
import SearchIcon from "../../../../../assets/images/search-icon.svg";
import DownArrow from "../../../../../assets/images/downarrow.svg";
import DeleteIcon from "../../../../../assets/images/delete-icon.svg";
import GreySuspend from "../../../../../assets/images/greySuspend.svg";
import GreyDelete from "../../../../../assets/images/greyDelete.svg";
import AddIconMonitor from "../../../../../assets/images/add-icon-monitor.svg";
import NotAllowed from "../../../../../assets/images/not-allowed-icon.svg";
import AddIconMonitorDisable from "../../../../../assets/images/add-icon-disable.svg";
import DeleteSuspendModal from "../../../common/NotificationAndAlerts/DeleteSuspendModal";
import {
  handleDeleteEmployee,
  handleSuspendEmployee,
  handleFetchTimeclockSettings,
  handleFetchEmployeeAppData,
  defaultMode,
} from "../../../utils";
import ToastMessage from "../../../../../components/common/ToastMessage";
import SiteLoader from "../../../../../components/common/SiteLoader";
import AddNewEmployeeModal from "../Modals/AddNewEmployeeModal.jsx";
import useGeneralSettings from "../../../../../hooks/useGeneralSettings.js";
import FileUploadFail from "../../../../../components/common/FileUploadFail/FileUploadFail.jsx";
import { ChangeStatusModal } from "../Modals/ChangeStatusModal.jsx";
import { getAccountLabel } from "../../../../../utils/index.js";
import { getAccessLevel } from "../../../../../components/common/functions.js";
import { TooltipKendo } from "../../../common/Tooltip/KendoTooltip.jsx";
import { isDisabled } from "../../../../../components/common/kendo/kendoUtils.js";
import ActiveIcon from "../../../../../assets/images/kendo-activate.svg";
import { handleActiveEmployee } from "../../../utils/handleActiveEmployees/handleActiveEmployees.js";
import AddNewPopup from "../../../../../components/company/Employees/AddNewPopup";
import RefreshButton from "../../../../../assets/images/refresh-button.svg";
import ActionPopup from "../../../../../components/company/Employees/ActionPopup.jsx";
import greyActiveIcon from "../../../../../assets/images/grey-check.svg";

const queryParams = {
  searchBy: "all",
  searchValue: "all",
  parentAcctID: JSON.parse(localStorage.getItem("parent"))?.accountId || "",
  childAccountID:
    localStorage.getItem("childAccountId") || localStorage.getItem("accountId"),
};

export const EmployeeAppDataNewtab = () => {
  const [page, setPage] = React.useState({
    skip: 0,
    take: 25,
  });
  const { timezone, nomenclature } = useGeneralSettings();
  const [mode, setMode] = useState(defaultMode);
  const [showChangeStatusModal, setShowChangeStatusModal] = useState(false);
  const [showAddNewPopup, setShowAddNewPopup] = useState(false);
  const [employeeData, setEmployeeData] = useState([]);
  const [jobStage, setJobStage] = useState([]);
  const [groups, setGroups] = useState([]);
  const [accessLevel, setAccessLevel] = useState({});
  const [employeeBackupData, setEmployeeBackupData] = useState([]);
  const [search, setSearch] = useState("");
  const [actionPopup, setActionPopup] = useState({
    show: false,
    data: null,
    msg: "",
  });
  const [dropdowndata, setDropdowndata] = useState([]);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState([]);
  const [timeclockStage, setTimeclockStage] = useState([]);
  const [currentTimeAndDate, setCurrentTimeAndDate] = useState();
  const [deletePopup, setDeletePopup] = useState({
    open: false,
    case: "single",
  });
  const [suspendPopup, setSuspendPopup] = useState({
    open: false,
    case: "single",
  });
  const [activePopup, setActivePopup] = useState({
    open: false,
    case: "multiple",
  });

  const [showLoader, setShowLoader] = useState(false);
  const [toastMsg, setToastMsg] = useState({
    msg: "",
    type: "msgSuccess",
  });

  const [employeePaginateMetaData, setEmployeePaginateMetaData] = useState({
    pageNo: 1,
    pageSize: 50,
    totalDataCount: 0,
    queryParams: queryParams,
    expandView:true
  });

  const handlePageChange = (event) => {
    const { skip, take } = event;
    console.log(event, "pager.pagepager.page");
    setPage({
      skip,
      take,
    });
    // setMilesData(employeeData?.data.slice(skip, skip + take));
    const pageNumber = Math.floor(event.skip / event.take) + 1;
    setEmployeePaginateMetaData({ page: pageNumber, perPage: take });
    console.log(pageNumber, take, "pageNumber");
  };

  const handleDeleteEmployees = async () => {
    handleDeleteEmployee(
      deletePopup,
      selectedDeleteItem,
      closeDeleteSuspendPopup,
      setDeletePopup,
      employeePaginateMetaData,
      setEmployeeData,
      setShowLoader,
      setToastMsg,
      setCurrentTimeAndDate,
      setSelectedDeleteItem
    );
  };

  const handleSearch = (evt, prevSearch = "") => {
    const searchText =
      prevSearch.trim().length > 0 ? prevSearch : evt.target.value;

    setSearch(searchText);
    // if (otherData?.setSearchText) otherData?.setSearchText(searchText);
    const newData = employeeData?.data
      ?.map((d, ind) => {
        return {
          ...d,
          id: ind + 1,
        };
      })
      .filter((item) => {
        let match = false;
        for (const property in item) {
          if (
            item[property] &&
            item[property]
              .toString()
              .toLocaleLowerCase()
              .indexOf(searchText.toLocaleLowerCase()) >= 0
          ) {
            match = true;
          }
        }
        return match;
      });

    console.log(newData, "newData");
    setEmployeeBackupData(newData);
    // setEmployeeData(newData)
    // setData(newData);
    // let clearedPagerDataState = { ...dataState, take: dataState.take, skip: 0 };
    // const newDataState = processWithGroups(newData, clearedPagerDataState);
    // setResultState(newDataState);
    // setDataState(clearedPagerDataState);
  };

  const handleSuspendEmployees = async () => {
    handleSuspendEmployee(
      selectedDeleteItem,
      closeDeleteSuspendPopup,
      employeePaginateMetaData,
      setEmployeeData,
      setShowLoader,
      setToastMsg,
      setCurrentTimeAndDate,
      setSelectedDeleteItem
    );
  };

  const handleActiveEmployees = async () => {
    handleActiveEmployee(
      selectedDeleteItem,
      closeDeleteSuspendPopup,
      employeePaginateMetaData,
      setEmployeeData,
      setShowLoader,
      setToastMsg,
      setCurrentTimeAndDate,
      setSelectedDeleteItem
    );
  };

  const openDeletePopup = (type) => {
    setDeletePopup({ open: true, case: type });
  };

  const openSuspendPopup = (type) => {
    setSuspendPopup({ open: true, case: type });
  };
  const openActivePopup = (type) => {
    setActivePopup({ open: true, case: type });
  };

  const closeDeleteSuspendPopup = () => {
    setSuspendPopup({ open: false, case: "single" });
    setDeletePopup({ open: false, case: "single" });
    setActivePopup({ open: false, case: "multiple" });

    setSelectedDeleteItem([]);
  };

  const handleInputChange = (event) => {
    setEmployeePaginateMetaData((prevState) => ({
      ...prevState,
      queryParams: {
        ...prevState.queryParams,
        searchValue: event.target.value,
      },
    }));
  };

  const handleOptionChange = (event) => {
    const [firstParam, secondParam] = event.target.value.split(":");
    setSearch("");
    setEmployeePaginateMetaData((prevState) => ({
      ...prevState,
      queryParams: {
        ...prevState.queryParams,
        searchBy: firstParam,
        searchValue: secondParam,
      },
    }));
  };

  const fetchEmployeeAppData = () => {
    setSearch("")
    handleFetchEmployeeAppData(
      employeePaginateMetaData,
      setEmployeeData,
      setShowLoader,
      setCurrentTimeAndDate
    );
  };

  useEffect(() => {
    fetchEmployeeAppData();
  }, [employeePaginateMetaData]);

  useEffect(() => {
    handleFetchTimeclockSettings(
      setJobStage,
      setTimeclockStage,
      setGroups,
      setDropdowndata
    );
  }, []);

  useEffect(() => {
    getAccessLevel("monitor", "").then((response) => {
      // if (childAccountID === 'all') {
      //   response.childMenu = 1
      // } else {
      // }
      response.childMenu = response.parentMenu;
      setAccessLevel(response);
    });
    // handleGetTimeClockReport()
  }, []);

  useEffect(() => {
    setEmployeeBackupData(employeeData?.data);
  }, [employeeData]);

  const employeeLabel = getAccountLabel("employee");
  const localTime = currentTimeAndDate?.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  });
  const localDate = currentTimeAndDate?.getDate();
  const localMonth = currentTimeAndDate?.getMonth() + 1;
  const localYear = currentTimeAndDate?.getFullYear();
  return (
    <div className="employeeAndDataNewTab">
      <div className="employeeHeadingContainer">
        <p className="employeeheadtag">
        {employeeLabel} App Status
          <span className="refreshtext">
            Last Refreshed:
            <span>{`${localMonth}/${localDate}/${localYear}`}</span>
            <span>{localTime}</span>
          </span>
        </p>
      </div>

      <div className="employeeparentBox">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <div className="employeeiconflexbox">
            <div className="employeemodalsearch">
              <label htmlFor="">Search</label>
              <div style={{ position: "relative" }}>
                <img
                  style={{ position: "absolute", top: 10, left: 10 }}
                  src={SearchIcon}
                  alt=""
                />
                <input
                  type="text"
                  name=""
                  id=""
                  placeholder="Search"
                  value={search}
                  onChange={handleSearch}
                />
              </div>
            </div>
            <div className="employeemodalsearch">
              <label htmlFor="">Filter</label>
              <div className="employeeinnerBox">
                <select
                  value={
                    employeePaginateMetaData?.queryParams?.searchBy +
                    ":" +
                    employeePaginateMetaData?.queryParams?.searchValue
                  }
                  onChange={handleOptionChange}
                >
                  {dropdowndata.map((option, index) => (
                    <option
                      disabled={option.disabled}
                      key={index}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </select>
                <img src={DownArrow} className="arrowicon2" />
              </div>
            </div>
            <div className="" style={{ marginTop: 23.5 }}>
              <div className="">
                <TooltipKendo>
                  <img
                    title="Refresh"
                    style={{ cursor: "pointer", width: 31 }}
                    src={RefreshButton}
                    alt="Refresh Button"
                    role="button"
                    onClick={fetchEmployeeAppData}
                  />
                </TooltipKendo>
              </div>
            </div>
          </div>
          <div className="employeeiconflexbox2">
                {employeePaginateMetaData?.queryParams?.searchValue ===
                  "Suspended" && (
                  <TooltipKendo>
                    <button
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                        backgroundColor: "transparent",
                        border:
                          isDisabled("edit", accessLevel) ||
                          selectedDeleteItem.length <= 1
                            ? "none"
                            : "1px solid #00BD9D",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "4px",
                      }}
                      disabled={
                        isDisabled("edit", accessLevel) ||
                        selectedDeleteItem.length <= 1 ||
                        !Array.isArray(selectedDeleteItem)
                      }
                      onClick={() => openActivePopup()}
                    >
                      <img
                        className={
                          isDisabled("edit", accessLevel) ||
                          selectedDeleteItem.length <= 1 ||
                          !Array.isArray(selectedDeleteItem)
                            ? "disabled_field"
                            : ""
                        }
                        title="Bulk Activate"
                        style={{ cursor: "pointer", width: 30.5, padding: 2 }}
                        src={
                          isDisabled("edit", accessLevel) ||
                          selectedDeleteItem.length <= 1 ||
                          !Array.isArray(selectedDeleteItem)
                            ? greyActiveIcon
                            : ActiveIcon
                        }
                        alt=""
                      />
                    </button>
                  </TooltipKendo>
                )}
                <TooltipKendo>
                  <button
                    style={{ border: "none", backgroundColor: "transparent" }}
                    disabled={isDisabled("add", accessLevel)}
                    onClick={() => setShowAddNewPopup(true)}
                  >
                    <img
                      className={
                        isDisabled("add", accessLevel) ? "disabled_field" : ""
                      }
                      title="Add Employee"
                      style={{
                        height: "32px !important",
                        width: "32px !important",
                        cursor: "pointer",
                      }}
                      src={
                        isDisabled("add", accessLevel)
                          ? AddIconMonitorDisable
                          : AddIconMonitor
                      }
                      alt=""
                    />
                  </button>
                </TooltipKendo>
                {employeePaginateMetaData?.queryParams?.searchValue !==
                  "Suspended" && (
                  <TooltipKendo>
                    <button
                      style={{ border: "none", backgroundColor: "transparent" }}
                      disabled={
                        isDisabled("edit", accessLevel) ||
                        selectedDeleteItem.length <= 1 ||
                        !Array.isArray(selectedDeleteItem)
                      }
                      onClick={() => openSuspendPopup("multiple")}
                    >
                      <img
                        className={
                          isDisabled("edit", accessLevel) ||
                          selectedDeleteItem.length <= 1 ||
                          !Array.isArray(selectedDeleteItem)
                            ? "disabled_field"
                            : ""
                        }
                        title="Bulk Suspend"
                        style={{ cursor: "pointer" }}
                        // onClick={() => openSuspendPopup("multiple")}
                        src={
                          isDisabled("edit", accessLevel) ||
                          selectedDeleteItem.length <= 1 ||
                          !Array.isArray(selectedDeleteItem)
                            ? GreySuspend
                            : NotAllowed
                        }
                        alt=""
                      />
                    </button>
                  </TooltipKendo>
                )}

                <TooltipKendo>
                  <button
                    style={{ border: "none", backgroundColor: "transparent" }}
                    disabled={
                      isDisabled("add", accessLevel) ||
                      selectedDeleteItem.length <= 1 ||
                      !Array.isArray(selectedDeleteItem)
                    }
                    onClick={() => openDeletePopup("multiple")}
                  >
                    <img
                      className={
                        isDisabled("add", accessLevel) ||
                        selectedDeleteItem.length <= 1 ||
                        !Array.isArray(selectedDeleteItem)
                          ? "disabled_field"
                          : ""
                      }
                      title="Bulk Delete"
                      style={{ cursor: "pointer" }}
                      // onClick={() => openDeletePopup("multiple")}
                      src={
                        isDisabled("add", accessLevel) ||
                        selectedDeleteItem.length <= 1 ||
                        !Array.isArray(selectedDeleteItem)
                          ? GreyDelete
                          : DeleteIcon
                      }
                      alt=""
                    />
                  </button>
                </TooltipKendo>
              </div>
        </div>
        <EmployeesAppDataTable
          setShowChangeStatusModal={setShowChangeStatusModal}
          employeeData={employeeData}
          deletePopup={deletePopup}
          setDeletePopup={setDeletePopup}
          handleSuccess={handleDeleteEmployees}
          closeDeletePopup={closeDeleteSuspendPopup}
          setSelectedDeleteItem={setSelectedDeleteItem}
          openDeletePopup={openDeletePopup}
          openSuspendPopup={openSuspendPopup}
          accessLevel={accessLevel}
          employeePaginateMetaData={employeePaginateMetaData}
          selectedDeleteItem={selectedDeleteItem}
          setEmployeePaginateMetaData={setEmployeePaginateMetaData}
          setEmployeeData={setEmployeeData}
          fromPinned={true}
          searchField={search}
          openActivePopup={openActivePopup}
          employeeBackupData={employeeBackupData}
        />
        {!!showChangeStatusModal && (
          <ChangeStatusModal
            setShowLoader={setShowLoader}
            employeeData={employeeData}
            selectedDeleteItem={selectedDeleteItem}
            closePopup={() => setShowChangeStatusModal(false)}
            timeclockStage={timeclockStage}
            refreshEmployeeData={fetchEmployeeAppData}
            setToastMsg={setToastMsg}
          />
        )}
        {/* <div className="employeemodalpagination">
          <LocalizationProvider language="en">
            <Pager
              skip={page.skip}
              take={page.take}
              total={employeeData?.totalRecordCount}
              buttonCount={3}
              info={true}
              type="numeric"
              pageSizes={[25, 50, 100]}
              previousNext={true}
              onPageChange={handlePageChange}
            />
          </LocalizationProvider>
        </div> */}
        {deletePopup.open && (
          <DeleteSuspendModal
            heading={"Delete"}
            msg={
              deletePopup.case === "single"
                ? "Are you sure you want to delete this record?"
                : "Are you sure you want to delete the selected records?"
            }
            closePopup={closeDeleteSuspendPopup}
            onSuccess={() => handleDeleteEmployees()}
          />
        )}

        {suspendPopup.open && (
          <DeleteSuspendModal
            heading={"Suspend"}
            msg={
              suspendPopup.case === "single"
                ? "Are you sure you want to suspend this record?"
                : "Are you sure you want to suspend the selected records?"
            }
            closePopup={closeDeleteSuspendPopup}
            onSuccess={() => handleSuspendEmployees()}
            selectedDeleteItem={selectedDeleteItem}
          />
        )}
        {activePopup.open && (
          <DeleteSuspendModal
            heading={"Activate"}
            msg={
              activePopup.case === "single"
                ? "Are you sure you want to active this record?"
                : "Are you sure you want to activate the selected records?"
            }
            closePopup={closeDeleteSuspendPopup}
            onSuccess={() => handleActiveEmployees()}
            selectedDeleteItem={selectedDeleteItem}
          />
        )}
        {/* {showAddNewPopup && (
          <AddNewEmployeeModal
            timeZone={timezone}
            setToastMsg={setToastMsg}
            setShowLoader={setShowLoader}
            closePopup={() => setShowAddNewPopup(false)}
            nomenclature={nomenclature}
            // setActionPopup={setActionPopup}
            setMode={setMode}
            fetchEmployeeAppData={fetchEmployeeAppData}
            showAddNewPopup={showAddNewPopup}
          />
        )} */}
        {showAddNewPopup && (
          <div className="mainContent">
            <AddNewPopup
              timeZone={timezone}
              setToastMsg={setToastMsg}
              setShowLoader={setShowLoader}
              closePopup={() => setShowAddNewPopup(false)}
              nomenclature={nomenclature}
              setActionPopup={setActionPopup}
              setMode={setMode}
              fetchEmployeeAppData={fetchEmployeeAppData}
              fromMonitorNew={true}
              showAddNewPopup={showAddNewPopup}
            />
          </div>
        )}
      {actionPopup.show && <ActionPopup actionPopup={actionPopup} setActionPopup={setActionPopup} setToastMsg={setToastMsg} setShowLoader={setShowLoader} fromNewMonitor={true} fetchEmployeeAppData={fetchEmployeeAppData} />}

        {mode.type === "uploadError" && (
          <FileUploadFail
            data={mode.data}
            closePopup={() =>
              setMode({ ...mode, type: "main", data: null, hasPrev: false })
            }
            setToastMsg={setToastMsg}
            setShowLoader={setShowLoader}
          />
        )}
      </div>
      {toastMsg.msg && (
        <ToastMessage
          cssClass={toastMsg.type}
          message={toastMsg.msg}
          onClose={() => setToastMsg({ msg: "", type: "" })}
        />
      )}
      <SiteLoader isActive={showLoader} />
    </div>
  );
};
