import React from "react";
import PinIcon from "../../../../assets/images/pin-icon.svg";
import PinFilledIcon from "../../../../assets/images/pin-fill-icon.svg";
import TabIcon from "../../../../assets/images/newtab-icon.svg";
import { TooltipKendo } from "../../common/Tooltip";
import MapLocationIcon from "../../../../assets/images/Map and Location.svg";

export const MapViewData = ({
  fromPinned = false,
  handlePinned,
  handleUnPinned,
  closePopup,
  breadcrumbData,
  tabToggle
}) => {
  const moment = window.moment;

  let iFrameUrl = `${
    process.env.REACT_APP_API_URL
  }/track/mgt?page=manageteaminfoV1${tabToggle===2?'&phoneList='+breadcrumbData.device:""
  }&datepicker_from1=${moment(breadcrumbData?.datepicker_from1).format(
    "YYYY/MM/DD"
  )}&timepicker_from=${moment(breadcrumbData.timepicker_from).format(
    "HH:mm:ss"
  )}&datepicker_to1=${moment(breadcrumbData.datepicker_to1).format(
    "YYYY/MM/DD"
  )}&timepicker_to=${moment(breadcrumbData.timepicker_to).format(
    "HH:mm:ss"
  )}&date_tz=${encodeURIComponent(breadcrumbData.date_tz)}&showLocationMap=yes`;

  let iFrameUrlOndemand = `${
    process.env.REACT_APP_API_URL
  }/track/mgt?page=manageteaminfoV1`;

  return (
    <div>
      <h2 className="headingtag">
        <p style={{display:"flex"}}>
       {!!fromPinned&&( <span style={{ marginRight: "6px" }}>
            <img 
             src={MapLocationIcon} alt="" />
          </span>)}
          <span>Map and Location</span>
          
        </p>
        <div>
          <TooltipKendo>
            <img
              style={{ cursor: "pointer" }}
              onClick={() => {
                // const groupId = headerDropdown.split(":")[1]
                // const phoneList = data.filter(d => selectedState[d.id]).map(d => d.deviceId).join(",")
                const form = document.getElementById("map_input_sub_form");
                 form.phoneList.value = tabToggle===2?breadcrumbData.device:""
                form.submit();
              }}
              className="tabicon"
              src={TabIcon}
              alt=""
              title="Open In New Tab"
            />
          </TooltipKendo>
          <TooltipKendo>
            <img
              onClick={() => {
                !fromPinned && closePopup();
                fromPinned
                  ? handleUnPinned()
                  : handlePinned("Map and Location");
              }}
              src={fromPinned ? PinFilledIcon : PinIcon}
              alt=""
              style={{ cursor: "pointer" }}
              title="Pin to Monitor Page"
            />
          </TooltipKendo>
        </div>
      </h2>
      
      <form
        id="map_input_sub_form"
        method="post"
        target="_blank"
        action={tabToggle===2?iFrameUrl:iFrameUrlOndemand}
        onSubmit={(e) => e.preventDefault()}
      >
        <input type="hidden" name="phoneList" value="" />
        <input type="hidden" name="datepicker_from1" value="" />
        <input type="hidden" name="timepicker_from" value="" />
        <input type="hidden" name="datepicker_to1" value="" />
        <input type="hidden" name="timepicker_to" value="" />
        <input type="hidden" name="date_tz" value="" />
        <input type="hidden" name="showLocationMap" value="" />
      </form>

      <iframe
        src={tabToggle===2?iFrameUrl:iFrameUrlOndemand}
        title="Map Input Sub Form"
        width="100%"
        height="400"
        frameBorder="0"
        loading="lazy"
      ></iframe>

    

      
    </div>
  );
};
