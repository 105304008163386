import React, { useState, useEffect, Fragment, useRef } from 'react';
import Header from '../../layout/Header';
import { useDocumentTitle } from '../../../hooks/setDocumentTitle';
import ToastMessage from '../../common/ToastMessage';
import KendoGrid from '../../common/kendo/KendoGrid';
import SiteLoader from '../../common/SiteLoader';
import DeletePopup from "../../common/PopUp/DeletePopup";
import AddNewPopup from './AddNewPopup';
import ViewLocateScheduler from './ViewLocateScheduler';
import EditLocateScheduler from './EditLocateScheduler';
import CopyLocateScheduler from './CopyLocateScheduler';
import { locateSchedulerColumns } from './locateScheduleUtils';
import { apiProvider } from '../../../services/api/provider';
import { getAccessLevel } from '../../common/functions';
import { getGroupsData, getEmployeesData } from '../../common/apiFunctions';

const moment = window.moment;

const LocateSchedulers = ({pathName}) => {
    useDocumentTitle("Monitor - allGeo");

    const [mode, setMode] = useState({
        title: "Monitor",
        type: 'main',
        data: null,
        hasPrev: false
    });

    const [showAddNewPopup, setShowAddNewPopup] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [toastMsg, setToastMsg] = useState({
        msg: "",
        type: "msgSuccess"
    });
    const [accessLevel, setAccessLevel] = useState({});
    const [accountInfo, setAccountInfo] = useState({});
    const [locateForData, setLocateForData] = useState({});
    const [schedules, setSchedules] = useState(null);
    const [activeTab] = useState('scheduler')
    const [activeList, setActiveList] = useState("active")

    const childRef = useRef()

    const getSchedules = async () => {
        try {
            setShowLoader(true)
            const response = await apiProvider.getAll('/track/mobile/v1/allgeo/schedule?type=Locate')
            setSchedules(response?.data?.map(d => {
                return {
                    ...d,
                    active: d.active ? "Active" : "Inactive",
                    nextRunDateTime: d.nextRunDateTime === "" ? "" : moment(d.nextRunDateTime).format(`${(response.dateFormat).replace(/[d]/gi, "D")} ${(response.timeFormat).replace(/[a]/gi, "A")}`)
                }
            }))
            setAccountInfo({
                'timezone': response.timezone,
                'dateFormat': response.dateFormat,
                'timeFormat': response.timeFormat
            })
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    const getLocateForData = async () => {
        try {
            const response = await Promise.all([getGroupsData(), getEmployeesData()])
            setLocateForData({ groups: response[0], employees: response[1].employees })
        } catch (error) {
            console.log(error);
        }
    }

    const actionHandler = (type, data) => {
        switch (type) {
            case "view":
                setShowLoader(true)
                setMode({
                    title: "Monitor",
                    type: 'view',
                    data: data,
                    hasPrev: false
                })
                break;
            case "edit":
                setShowLoader(true)
                setMode({
                    title: "Monitor",
                    type: 'edit',
                    data: data,
                    hasPrev: false
                })
                break;
            case "copy":
                setShowLoader(true)
                setMode({
                    title: "Monitor",
                    type: 'copy',
                    data: data,
                    hasPrev: false
                })
                break;
            default:
                break;
        }
    }

    const deleteSchedule = async data => {

        let flag = false;
        let res = { type: 'info' };

        if (data.length) {
            let uniqueRunningStatus = [...new Set(data.map(item => item.runningStatus))]
            let index = uniqueRunningStatus.findIndex(ele => ele.toLowerCase() === 'started');
            if (index >= 0) {
                flag = true
            }
        } else {
            if ((data.runningStatus).toLowerCase() === 'started') {
                flag = true;
            }
        }

        if (flag) {

            if (data.length)
                res.message = "One of the selected records has already started";
            else
                res.message = `${data.scheduleId} has already started`;

            return res;

        } else {

            const payload = data.length ? [...data.map(item => item.txnId)] : [data.txnId]

            try {
                setShowLoader(true)
                const response = await apiProvider.removeObject('track/mobile//v1/allgeo/schedule/delete', payload)
                if (data.length)
                    response.message = "The selected records have been deleted successfully";
                else
                    response.message = `${data.scheduleId} is deleted`

                return response;
            } catch (error) {
                console.log(error);
                throw new Error(error)
            } finally {
                setShowLoader(false)
            }
        }
    }

    const openPopup = (type, _data) => {
        switch (type) {
            case "addNew":
                setShowAddNewPopup(true)
                break;
            case "view":
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        getAccessLevel('Schedule', 'schedule_template').then((response) => {
            setAccessLevel(response);
        });

        getSchedules();
        getLocateForData();
    }, [])

    useEffect(() => {
        if (toastMsg.msg) {
            if (toastMsg.type === "msgSuccess" && childRef.current) {
                childRef.current.refreshData()
            }
            setShowLoader(false)
        }
    }, [toastMsg])

    return (
        <>
            <div className={pathName !== "locate-schedule"&&"mainContentWrap"}>
                {pathName !== "locate-schedule" && <Header title="Scheduler" /> }
                <div className={pathName !== "locate-schedule"&&"mainContent"} id="mainContent">
                    {
                        schedules &&
                        <KendoGrid
                            gridData={schedules}
                            setGridData={setSchedules}
                            columnData={locateSchedulerColumns}
                            accessLevel={accessLevel}
                            ref={childRef}
                            module={"locateScheduler"}
                            getAPiUrl={'track/mobile/v1/allgeo/schedule?type=Locate'}
                            requestParams={{ status: activeList }}
                            openPopup={openPopup}
                            deleteItem={deleteSchedule}
                            lockActionColumn={true}
                            actionColumnHandler={actionHandler}
                            bulkDelete={deleteSchedule}
                            otherData={{
                                hideSaveCancelChanges: true,
                                booleanField: "active",
                                exportFileName: "Locate Schedules",
                                actionColumnBtns: ['view', 'edit', 'copy', 'delete'],
                                dropDownData: activeList,
                                setDropDownData: setActiveList,
                            }}
                        />
                    }
                </div>
            </div>

            {showAddNewPopup && <AddNewPopup timeZone={accountInfo.timezone} dateFormat={accountInfo.dateFormat} timeFormat={accountInfo.timeFormat} setShowLoader={setShowLoader} setToastMsg={setToastMsg} locateForData={locateForData} closePopup={() => setShowAddNewPopup(false)} />}
            {mode.type === 'view' && <ViewLocateScheduler scheduleId={mode.data.txnId} dateFormat={(accountInfo.dateFormat).replace(/[d]/gi, "D")} timeFormat={(accountInfo.timeFormat).replace(/[a]/gi, "A")} setShowLoader={setShowLoader} setMode={setMode} mode={mode} locateForData={locateForData} accessLevel={accessLevel.childMenu} />}
            {mode.type === 'edit' && <EditLocateScheduler scheduleId={mode.data.txnId} dateFormat={accountInfo.dateFormat} timeFormat={accountInfo.timeFormat} timeZone={accountInfo.timezone} setShowLoader={setShowLoader} setMode={setMode} setToastMsg={setToastMsg} locateForData={locateForData} />}
            {mode.type === 'copy' && <CopyLocateScheduler scheduleId={mode.data.txnId} dateFormat={accountInfo.dateFormat} timeFormat={accountInfo.timeFormat} timeZone={accountInfo.timezone} setShowLoader={setShowLoader} setMode={setMode} setToastMsg={setToastMsg} locateForData={locateForData} closePopup={() => setMode({ ...mode, type: 'main' })} />}

            {mode.type === 'delete' &&
                <DeletePopup
                    onSuccess={async () => {
                        try {
                            if ((mode.data?.runningStatus).toLowerCase() !== 'started') {
                                const res = await deleteSchedule(mode.data)
                                if (res.status === 200) {
                                    setMode({
                                        title: "Monitor",
                                        type: 'main',
                                        data: null,
                                        hasPrev: false
                                    })
                                    setToastMsg({
                                        msg: res.message,
                                        type: "msgSuccess"
                                    })
                                }
                            } else {
                                setToastMsg({ msg: `${mode.data.scheduleId} has alredy started`, type: "" })
                            }
                        } catch (err) {

                        }
                    }}
                    closePopup={() => setMode({
                        title: "Monitor",
                        type: 'main',
                        data: null,
                        hasPrev: false
                    })}
                />}
            {toastMsg.msg && <ToastMessage
                cssClass={toastMsg.type}
                message={toastMsg.msg}
                onClose={() => setToastMsg({ msg: "", type: "" })}
            />}
            <div className={`overlay ${(showAddNewPopup || mode.type !== 'main') ? 'active' : ''}`} />
            <SiteLoader isActive={showLoader} />
        </>
    )
}

export default LocateSchedulers