import { EDIT_HISTORY_REPORT_UPDATED_COLUMN_MAPPING, SPECIAL_VALUE_MAPPING } from './titleMapping'

export const savingHistoryResponseModification = (apiResponse) => {
    let transformedData = [];

    // Parse updatedColumns and handle errors
    apiResponse.forEach(item => {
        try {
            item.updatedColumns = JSON.parse(item.updatedColumns);
        } catch (error) {
            console.warn(`Error parsing updatedColumns for entityID ${item.entityID}:`, error);
            item.updatedColumns = {}; // Fallback to an empty object in case of error
            item.previousValue = "NA";
            item.updatedValue = "NA";
            item.fieldName = "NA"; // Adding a default fieldName for error cases
            transformedData.push(item); // Ensure the row is added to the result
            return; // Skip further processing for this item
        }

    });

    // Helper function to assign value or "NA"
    const getValue = (key, value) => {
        if (value === "" || value === null) {
            return "NA";
        }

        // Converting string "true" or "false" to boolean
        if (value === "true") value = true;
        if (value === "false") value = false;

        if (SPECIAL_VALUE_MAPPING[key]) {
            if (typeof value === 'string' && value.includes(',')) {
                return value.split(',')
                    .map(v => SPECIAL_VALUE_MAPPING[key][v] || v.trim())
                    .filter(Boolean) // To remove any empty strings
                    .join(', ');
            }
            return SPECIAL_VALUE_MAPPING[key][value] || value;
        }
        return value;
    };

    // Transform data
    apiResponse.forEach(item => {
        const { updatedColumns, ...rest } = item;
        const originalKeys = Object.keys(updatedColumns).filter(key => key.startsWith("Original_"));
        const updatedKeys = Object.keys(updatedColumns).filter(key => key.startsWith("Updated_"));

        if (originalKeys.length === 1 && updatedKeys.length === 1) {

            const suffix = originalKeys[0].substring("Original_".length);

            transformedData.push({
                ...rest,
                updatedColumns,
                previousValue: getValue(suffix, updatedColumns[originalKeys[0]]),
                updatedValue: getValue(suffix, updatedColumns[updatedKeys[0]]),
                fieldName: EDIT_HISTORY_REPORT_UPDATED_COLUMN_MAPPING[suffix] || suffix
            });

        } else if (originalKeys.length > 1 && updatedKeys.length > 1) {
            originalKeys.forEach(originalKey => {
                const suffix = originalKey.substring("Original_".length);
                const updatedKey = `Updated_${suffix}`;

                if (updatedKeys.includes(updatedKey)) {
                    transformedData.push({
                        ...rest,
                        updatedColumns,
                        previousValue: getValue(suffix, updatedColumns[originalKey]),
                        updatedValue: getValue(suffix, updatedColumns[updatedKey]),
                        fieldName: EDIT_HISTORY_REPORT_UPDATED_COLUMN_MAPPING[suffix] || suffix
                    });
                }

            });
        }
    });

    return transformedData;
}
