import React, { useEffect, useState } from "react";
import "./milesDataNewtab.css";
import { MilesDataViewTable } from "../MilesDataViewTable";
import { getMilesData } from "../../../utils";

const queryParams = {
  searchBy: "all",
  searchValue: "",
  parentAcctID: JSON.parse(localStorage.getItem("parent"))?.accountId || "",
  childAccountID:
    localStorage.getItem("childAccountId") || localStorage.getItem("accountId"),
};
export const MilesDataNewtab = () => {
  const [milesPaginateMetaData, setMilesPaginateMetaData] = useState({
    page: 1,
    perPage: 25,
    totalDataCount: 0,
    queryParams: queryParams,
  });
  const [milesData, setMilesData] = useState();
  const [currentTimeAndDateMiles, setCurrentTimeAndDateMiles] = useState();
  const [milesBackupData,setMilesBackupData ] = useState([]);

  useEffect(() => {
    getMilesData(
      setCurrentTimeAndDateMiles,
      setMilesData,
      milesPaginateMetaData
    );
  }, [milesPaginateMetaData]);

  useEffect(()=>{
  setMilesBackupData(milesData?.data)
  },[milesData])
  return (
    <div style={{paddingTop:0}} className="milesDataNewTab">
      <MilesDataViewTable
        setMilesPaginateMetaData={setMilesPaginateMetaData}
        fromPinned={true}
        milesData={milesData}
        fromNewTab={true}
        setMilesBackupData={setMilesBackupData}
        milesBackupData={milesBackupData}
      />
    </div>
  );
};
