import React, { useEffect, useState, useRef } from 'react';
import { apiProvider } from '../../../../../services/api/provider';
import { advanceTimeClock, advancedReportTableAggregates, advancedReportTableAggregatesText } from "../../buildReportsutils";
import KendoGrid from '../../../../common/kendo/KendoGrid';
import SiteLoader from "../../../../common/SiteLoader";
import { PARAMS } from './constants';

function mergeObjects(columnKeys, defaultColumnNames) {
  const newColumns = {};
  for (let key in columnKeys) {
    if (columnKeys?.hasOwnProperty(key) && defaultColumnNames?.hasOwnProperty(key)) {
      newColumns[key] = defaultColumnNames[key];
    }
  }
  return newColumns;
}


export const AdvanceTimeClock = ({ genSetting, defaultColumnName }) => {
  const [timeClockReport, setTimeClockReport] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [params, setParams] = useState(PARAMS)
  const [mergedColumns, setColumns] = useState(advanceTimeClock);

  const childRef = useRef()

  useEffect(() => {
    const fetchAndMergeColumns = async () => {
      const response = await apiProvider.getAll(
        "track/mobile/v1/allgeo/getReportTemplate?templateName=Custom_Payroll_Header_Names"
      );
      const parseresponse = response && JSON.parse(response.templateData);
      const parseGridOptions = parseresponse && JSON.parse(parseresponse?.[0]?.gridOptions);
      const newColumns = mergeObjects(parseGridOptions, defaultColumnName);
      const mergedColumns = mergeColumns(advanceTimeClock, newColumns);
      setColumns(mergedColumns);
    };
    fetchAndMergeColumns();
  }, [timeClockReport]);

  console.log(mergedColumns, "mergedColumns");


  const mergeColumns = (existingColumns, newColumns) => {
    const existingFields = existingColumns.map((col) => col.field);
    const mergedColumns = [...existingColumns];
    console.log(defaultColumnName, 'default');
    for (const [field, title] of Object.entries(newColumns)) {
      if (!existingFields.includes(field)) {
        mergedColumns.push({
          title: title,
          field: field,
          editable: false,
          width: 200,
          editor: "text",
          columnMenu: false,
          show: true,
          filter: "text",
          mandatory: true,
        });
      }
    }

    return mergedColumns;
  };
    
  const getAdvanceTimeClockReport = async () => {
    try {
      setShowLoader(true);
      setParams({ ...PARAMS, device_tz_selected: genSetting?.report_timezone });
      const response = await apiProvider.getAll('/track/mobile/v1/allgeo/evvcustomtimeclockreport', PARAMS);
      setTimeClockReport(response);
    } catch (error) {
      console.error("API Error:", error);
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    getAdvanceTimeClockReport();
  }, [genSetting]);

  const totoalServiceHours = mergedColumns?.find(e => e.field == "totoalServiceHours").title
  const totalPunchServiceTime = mergedColumns?.find(e => e.field == "totalPunchServiceTime").title
  const regularServiceHours = mergedColumns?.find(e => e.field == "regularServiceHours").title
  const overTimeHours = mergedColumns?.find(e => e.field == "overTimeHours").title
  const doubleOverTimeHours = mergedColumns?.find(e => e.field == "doubleOverTimeHours").title
  


  return (
    <>
      {timeClockReport &&
        <KendoGrid
          gridData={timeClockReport}
          setGridData={setTimeClockReport}
          columnData={mergedColumns}
          module={"buildReports"}
          subModule='advanceTimeClockBuildReports'
          getAPiUrl={'/track/mobile/v1/allgeo/evvcustomtimeclockreport'}
          requestParams={PARAMS}
          accessLevel={{ parentMenu: 3, childMenu: 3 }}
          ref={childRef}
          otherData={{
            actionColumnBtns: [],
            showExportDropdown: true,
            actionColumnBtns: [],
            enableFooterDisplay: true,
            aggregates: advancedReportTableAggregates,
            aggregateText: advancedReportTableAggregatesText,
            minutesFormat: genSetting?.minutes,
            dateFormat: genSetting?.date_display_format,
            columnFormatMap: {
              [totoalServiceHours]: "hh:mm:ss",
              [totalPunchServiceTime]: "hh:mm:ss",
              [regularServiceHours]: "hh:mm:ss",
              [overTimeHours]: "hh:mm:ss",
              [doubleOverTimeHours]: "hh:mm:ss",
              
          }
          }}
        />
      }
      <SiteLoader isActive={showLoader} />
    </>
  )
}