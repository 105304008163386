import React from "react"
import QR from 'qrcode.react';
import logo from '../../../assets/images/allGeo-logo.svg'

const QRCodeSize = 120;

const QRCode = ({ value, codeID }) => {

    return (
        <QR
            id={codeID}
            value={value}
            size={QRCodeSize}
            bgColor='white'
            fgColor='black'
            level='H'
            style={{
                marginLeft: 16
            }}
            imageSettings={{
                src: logo,
                excavate: true,
                width: QRCodeSize * 0.2,
                height: QRCodeSize * 0.2
            }}
        />

    )
}

export default QRCode