import React, { useState, useEffect } from "react";

export const MultiLanguageSettings = ({
  register,
  getValues,
  languages,
  languageOptions,
  handleCheckboxChange,
  setValue,
}) => {
  const [showMobileLangOptions, setShowMobileLangOptions] = useState(
    getValues("mobileApp.allow_multi_language_app") || false
  );

  useEffect(() => {
    setShowMobileLangOptions(getValues("mobileApp.allow_multi_language_app") || false);
  }, [getValues("mobileApp.allow_multi_language_app")]);

  const toggleChecked = () => {
    const newValue = !getValues("mobileApp.allow_multi_language_app");
    setValue("mobileApp.allow_multi_language_app", newValue);
    setShowMobileLangOptions(newValue);
  };

  return (
    <div className="rowBlk">
      <div className="colBlk3 fldWrap">
        <label>Enable Language Selection</label>
        <div className="fldWIthYesNo">
          <div className="customCheck yesNo">
            <input
              type="checkbox"
              id="myLanguageSelection"
              {...register("mobileApp.allow_multi_language_app")}
              onChange={toggleChecked}
              checked={showMobileLangOptions}
            />
            <label className="customCheckLabel" htmlFor="myLanguageSelection">
              yesNo
            </label>
          </div>
        </div>
      </div>
      {showMobileLangOptions && (
        <>
          <div className="colBlk6 fldWrap">
            <label>Select Languages</label>
            <div className="fldWIthYesNo languageSec">
              {languageOptions.map((lang) => (
                <div className="customCheck selectLanguage" key={lang}>
                  {lang === "English" ? (
                    <input type="checkbox" id={lang} checked readOnly />
                  ) : (
                    <input
                      type="checkbox"
                      id={lang}
                      name={`languages.${lang}`}
                      {...register(`languages.${lang}`)}
                      checked={languages.includes(lang)}
                      onChange={handleCheckboxChange}
                    />
                  )}
                  <label className="customCheckLabel" htmlFor={lang}>
                    {lang}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="colBlk3 fldWrap">
            <label>Select a Default Language</label>
            <select {...register("mobileApp.default_app_language")}>
              {languages.map((e) => (
                <option value={e} key={e}>
                  {e}
                </option>
              ))}
            </select>
          </div>
        </>
      )}
    </div>
  );
};