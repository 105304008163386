import { useEffect, Fragment, useState } from "react";
import Select from 'react-select';
import { Prompt } from 'react-router';
import { useForm } from "react-hook-form";
import Map from '../../common/map/index';
import { apiProvider } from '../../../services/api/provider';
import { timeZones, units, colorArray, unitConversionRate, zoneColorCondition, getLatLngList } from './jobSitesUtils';
import { ConfirmPopup } from '../../common/PopUp';
import MultiSelectDropdown from "../../common/dropdown/MultiSelectDropdown";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { formatDate } from "@progress/kendo-intl";
import { getAccountLabel } from "../../../utils";
import home from "../../../assets/images/Home.png";
import medical from "../../../assets/images/Medical.png";
import office from "../../../assets/images/Office.png";
import Properties from "../../../assets/images/Properties.png";
import resturant from "../../../assets/images/Restaurant.png";
import truck from "../../../assets/images/Truck.png"

const dateFormat = 'yyyy-MM-dd';

const editJobSite = (props) => {

    const { register, handleSubmit, setValue, getValues, watch, reset, formState: { isDirty, isValid, errors } } = useForm({ mode: "all" });
    const [showEditMap, setShowEditMap] = useState(false);
    const [jobSiteData, setJobSiteData] = useState({});
    const [jobSiteDataForMap, setJobSiteDataForMap] = useState({});
    const [geoZone, setGeoZone] = useState();
    const [customerTypeOptions, setCustomerTypeOptions] = useState(null);
    const [payerData, setPayerData] = useState([]);
    const [earningData, setEarningData] = useState([]);
    const [skillsData, setSkillsData] = useState([]);
    const [jobSiteList, setJobSiteList] = useState([]);
    const [selectedJobSite, setSelectedJobSite] = useState([]);
    const [radius, setRadius] = useState(0);
    const [cursor, setCursor] = useState(null);
    const [addressType, setAddressType] = useState();
    const [payerInformation, setPayerInformation] = useState({
        modifier1: "",
        modifier2: "",
        modifier3: "",
        modifier4: "",
        payerID: "",
        payerProgram: "",
        procedureCode: "",
    });

    const [showDialog, setShowDialog] = useState(false);
    const [selectedEarningCodes, setSelectedEarningCodes] = useState([]);

    useEffect(() => {
        fetchPayerData();
        fetchEarningData();
        fetchSkillsData();
        fetchAllJobSites();
    }, []);

    useEffect(() => {
        if (!showEditMap) {
            reset()
            fetchData();
        }
    }, [showEditMap])

    useEffect(() => {
        if (Object.keys(jobSiteData).length > 0) {
            setValue('siteName', jobSiteData.name, { shouldDirty: false });
            setValue('geoZoneType', jobSiteData.geozoneType, { shouldDirty: false });
            setValue('address', jobSiteData.address, { shouldDirty: false });
            setValue('status', jobSiteData.active, { shouldDirty: false });
            setValue('longitude', Number(jobSiteData.additional_info.longitude).toFixed(5), { shouldDirty: false });
            setValue('latitude', Number(jobSiteData.additional_info.latitude).toFixed(5), { shouldDirty: false });
            setValue('siteId', jobSiteData.additional_info.siteId, { shouldDirty: false });
            setValue('description', jobSiteData.additional_info.siteName, { shouldDirty: false });
            setValue('sourceId', jobSiteData.additional_info.sourceId, { shouldDirty: false });
            setValue('customerType', jobSiteData.additional_info.customerType, { shouldDirty: false });
            setValue('referenceId', jobSiteData.additional_info.referenceId, { shouldDirty: false });
            setValue('email', jobSiteData.additional_info.email, { shouldDirty: false });
            setValue('phoneNumber', jobSiteData.additional_info.phoneNumber, { shouldDirty: false });
            setValue('landlineNumber', jobSiteData.additional_info.landLineNumber, { shouldDirty: false });
            setValue('claimFreqeuncy', jobSiteData.additional_info.claim_freqeuncy, { shouldDirty: false });
            setValue('userDescription', jobSiteData.additional_info.user_description, { shouldDirty: false });
            setValue('addressType', addressType !== undefined ? addressType : jobSiteData.additional_info.addressType ? jobSiteData.additional_info.addressType : "Postal Address", { shouldDirty: false });
            setValue('rateCard', jobSiteData.additional_info.rateCard, { shouldDirty: false });
            setValue('risk', (jobSiteData.additional_info.risk === "" || jobSiteData.additional_info.risk === null) ? 'Select' : jobSiteData.additional_info.risk, { shouldDirty: false });
            setValue('siteType', (jobSiteData.additional_info.siteType === "" || jobSiteData.additional_info.siteType === null) ? 'Select' : jobSiteData.additional_info.siteType, { shouldDirty: false });
            setValue('skills', (jobSiteData.additional_info.skills === "" || jobSiteData.additional_info.skills === null) ? 'Select' : jobSiteData.additional_info.skills, { shouldDirty: false });
            setValue('extSystem', jobSiteData.additional_info.extSystem, { shouldDirty: false });
            setValue('earningCode', (jobSiteData.additional_info.earningCode === "" || jobSiteData.additional_info.earningCode === null) ? 'Select' : jobSiteData.additional_info.earningCode, { shouldDirty: false });
            setValue('clientFirstName', jobSiteData.evv_info.client_first_name, { shouldDirty: false });
            setValue('clientLastName', jobSiteData.evv_info.client_last_name, { shouldDirty: false });
            setValue('serviceCode', jobSiteData.evv_info.service_code, { shouldDirty: false });
            setValue('stateId', jobSiteData.evv_info.stateId, { shouldDirty: false });
            setValue('jurisdictionId', jobSiteData.evv_info.jurisdictionId, { shouldDirty: false });
            setValue('authorization', jobSiteData.evv_info.authorization, { shouldDirty: false });
            setValue('startDate', jobSiteData.evv_info.startDate ? new Date(jobSiteData.evv_info.startDate) : "", { shouldDirty: false });
            setValue('endDate', jobSiteData.evv_info.endDate ? new Date(jobSiteData.evv_info.endDate) : "", { shouldDirty: false });
            setValue('medicaidId', jobSiteData.evv_info.client_medicaidId, { shouldDirty: false });
            setValue('payerId', jobSiteData.evv_info.client_payerID, { shouldDirty: false });
            setValue('clientIdentifier', jobSiteData.evv_info.client_idendifier, { shouldDirty: false });
            setValue('clientId', jobSiteData.evv_info.service_code, { shouldDirty: false });
            setValue('payerInformation', (jobSiteData.evv_info.payer_information === "" || jobSiteData.evv_info.payer_information === null || jobSiteData.evv_info.payer_information.toLowerCase() === "none") ? 'Select' : jobSiteData.evv_info.payer_information, { shouldDirty: false });
            setValue('timezone', (jobSiteData.evv_info.timezone === "" || jobSiteData.evv_info.timezone === null || jobSiteData.evv_info.timezone.toLowerCase() === "none") ? 'Select' : jobSiteData.evv_info.timezone, { shouldDirty: false });
            setValue('zoneRadius', jobSiteData.geozone.zoneRadius, { shouldDirty: false });
            setValue('reverseGeoCode', jobSiteData.geozone.reverseGeocode, { shouldDirty: false });
            setValue('departureZone', jobSiteData.geozone.departZone, { shouldDirty: false });
            setValue('arrivalZone', jobSiteData.geozone.arrivalZone, { shouldDirty: false });
            setValue('autoNotify', jobSiteData.geozone.autoNotify, { shouldDirty: false });
            setValue('overlapPriority', jobSiteData.geozone.priority, { shouldDirty: false });
            setValue('zoneColor', jobSiteData.geozone.zonecolor, { shouldDirty: false });
            setGeoZone(jobSiteData.geozoneType);
            setAddressType(addressType !== undefined ? addressType : jobSiteData.additional_info.addressType ? jobSiteData.additional_info.addressType : "Postal Address")
            setCursor(`${jobSiteData.geozone.latlanList[0].latValue}, ${jobSiteData.geozone.latlanList[0].lonValue}`);
        }

    }, [jobSiteData])

    useEffect(() => {
        const subscribe = watch(data => {

            if (payerData.length > 0 && (data.payerInformation !== null || data.payerInformation !== "" || data.payerInformation.toLowerCase() !== "none")) {

                let payerItem = payerData.filter((ele) => ele.payerServiceInfoId === data.payerInformation)[0]

                if (payerItem) {
                    setPayerInformation({
                        modifier1: payerItem.modifier1,
                        modifier2: payerItem.modifier2,
                        modifier3: payerItem.modifier3,
                        modifier4: payerItem.modifier4,
                        payerID: payerItem.payerID,
                        payerProgram: payerItem.payerProgram,
                        procedureCode: payerItem.procedureCode
                    })
                }
            }
        })

        return () => subscribe.unsubscribe()
    }, [watch, payerData])

    useEffect(() => {
        if (Object.keys(jobSiteData).length > 0) {
            setValue('earningCode', (jobSiteData.additional_info.earningCode === "" || jobSiteData.additional_info.earningCode === null) ? 'Select' : jobSiteData.additional_info.earningCode, { shouldDirty: false });
            setValue('skills', (jobSiteData.additional_info.skills === "" || jobSiteData.additional_info.skills === null) ? 'Select' : jobSiteData.additional_info.skills, { shouldDirty: false });
            setValue('earningCode', (jobSiteData.additional_info.earningCode === "" || jobSiteData.additional_info.earningCode === null) ? 'Select' : jobSiteData.additional_info.earningCode, { shouldDirty: false });
        }
    }, [payerData, earningData, skillsData])

    useEffect(() => {
        if (isDirty && showEditMap === false) {
            localStorage.setItem('formIsDirty', isDirty)
        }
    }, [isDirty])

    const handleRadiusUpdate = (value) => {
        let data = getValues()
        let input = value.replace(/[^0-9\s]/g, '')

        if (data.metric === "+ve") {
            setRadius(parseInt(value))
        } else {
            setRadius(parseInt(value) * -1)
        }
        setValue('radiusIncDec', input, { shouldDirty: false });

    }

    const editFormCancelEvent = () => {
        if (isDirty) {
            setShowDialog(true)
        } else {
            if (props.mode.hasPrev) {
                props.setMode({
                    title: "View Job Site Information",
                    type: 'view',
                    data: props.mode.data,
                    hasPrev: false
                })
            } else {
                props.setMode({
                    title: "Manage Job/Client Sites",
                    type: 'main',
                    data: null,
                    hasPrev: false
                })
            }
        }
    }

    const editOnMapFormCancelEvent = () => {
        if (isDirty === true || jobSiteDataForMap.isChanged === true) {
            setShowDialog(true)
        } else {
            setShowEditMap(false);
        }
    }

    const onSuccess = () => {
        setShowDialog(false)
        if (!showEditMap) {
            if (props.mode.hasPrev) {
                props.setMode({
                    title: "View Job Site Information",
                    type: 'view',
                    data: props.mode.data,
                    hasPrev: false
                })
            } else {
                props.setMode({
                    title: "Manage Job/Client Sites",
                    type: 'main',
                    data: null,
                    hasPrev: false
                })
            }
        } else {
            setShowEditMap(false);
        }
    }

    const onFaliure = () => {
        setShowDialog(false)
    }

    const fetchData = () => {
        props.setShowLoader(true);

        apiProvider.getSingle('track/mobile/v1/allgeo/getCustomer', props.siteId).then((response) => {
            if (response.status === 200 && response.message.toLowerCase() === 'success') {

                setJobSiteData(response.data)
                //earning code multi select dropdown preselected value
                const earningCodeData = response.data.additional_info.earningCode
                if (earningCodeData.length > 0) {
                    const earningCodeArray = response.data.additional_info.earningCode.split(',');
                    const earningCodeObj = earningCodeArray.map(value => {
                        return {
                            id: value.trim(),
                            text: value.trim()
                        };
                    });
                    setSelectedEarningCodes(earningCodeObj)
                }


                let updatedRes = { ...response.data };
                let updatedCoord = [...updatedRes.geozone.latlanList];
                if (updatedRes.geozone.zoneType !== "Circle") {
                    updatedCoord = updatedRes.geozone.latlanList.filter((ele, index) => {
                        if (index > 0) {
                            if (ele.latValue !== "0.0" || ele.lonValue !== "0.0") {
                                return ele
                            }
                        } else {
                            return ele
                        }
                    })
                }
                let updatedJobSiteData = {
                    ...updatedRes,
                    geozone: {
                        ...updatedRes.geozone,
                        latlanList: [
                            ...updatedCoord
                        ]
                    },
                    isChanged: false
                };
                setJobSiteDataForMap(updatedJobSiteData)
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            props.setShowLoader(false);
        });
    };

    const fetchPayerData = () => {
        apiProvider.getAll('track/mobile/v1/allgeo/evv/payer').then((response) => {
            setPayerData([{ payerID: 'Select', description: 'Select' }, ...response])
        }).catch((error) => {
            console.log(error);
        });
    };

    const fetchEarningData = () => {
        apiProvider.getAll('track/mobile/v1/allgeo/earning/codes').then((response) => {
            let updatedResponse = response.filter((ele) => (ele.source).toLowerCase() === 'earning_codes' || (ele.source).toLowerCase() === 'paydata')
            setEarningData([...updatedResponse])
        }).catch((error) => {
            console.log(error);
        });
    };

    const fetchSkillsData = () => {
        apiProvider.getAll('track/react/account/getEmployeeSkills').then((response) => {
            setSkillsData(['Select', ...response])
        }).catch((error) => {
            console.log(error);
        });
    };

    const fetchAllJobSites = () => {

        apiProvider.getAll('/track/mobile/v2/allgeo/getCustomers?active=Yes').then((response) => {
            setJobSiteList(response.data)
        }).catch((error) => {
            console.log(error);
        });

    };

    const onSubmitEditForm = async (data) => {
        const earningCode = Object.values(data.earningCode).map(item => item.id).join(',');

        const minValue = units[jobSiteData?.geozone?.radiusUnit?.toLowerCase()] === "m" ? 1 : 4;

        if (data.siteName === "") {
            props.setToastMsg({ msg: "Name should not be empty", type: "" });
        } else if (data.address === "") {
            props.setToastMsg({ msg: "Address should not be empty", type: "" });
        } else if ((data.clientFirstName === "" || data.clientLastName === "") && jobSiteData.sandataEnabled) {
            props.setToastMsg({ msg: "Client first name or last name should not be empty", type: "" });
        } else if (data.radiusIncDec > 99999) {
            props.setToastMsg({ msg: "Radius limit exceeded", type: "" });
        } else if (data.radiusIncDec < 4) {
            props.setToastMsg({ msg: "Invalid input. Please update radius by 4 feet", type: "" });
        } else {
            props.setShowLoader(true);
            let radValue = 0;

            if (data.geoZoneType === 'Circle') {
                if (data.metric === "+ve") {
                    radValue = jobSiteData.radius + parseInt(data.radiusIncDec);
                } else {
                    radValue = jobSiteData.radius - parseInt(data.radiusIncDec);
                }
            }

            if (radValue < minValue && data.geoZoneType === 'Circle') {
                props.setToastMsg({ msg: `The radius cannot be less than ${minValue} ${(jobSiteData?.geozone?.radiusUnit).toLowerCase()}. Please enter a significant value.`, type: "" });
            } else {
                try {
                    let payload = [{
                        "txnId": props.siteId,
                        "geozoneID": jobSiteData.geozone.geozoneId,
                        "name": data.siteName,
                        "address": selectedJobSite.length > 0 ? selectedJobSite[0].address : data.address,
                        "geozoneType": geoZone,
                        "radius": radValue,
                        "near_employees": "",
                        "modified": "",
                        "creation": jobSiteData.creation,
                        "geozone": { ...jobSiteData.geozone, zoneRadius: radValue },
                        "additional_info": {
                            "latitude": selectedJobSite.length > 0 ? selectedJobSite[0].additional_info.latitude : data.latitude,
                            "longitude": selectedJobSite.length > 0 ? selectedJobSite[0].additional_info.longitude : data.longitude,
                            "siteId": data.siteId,
                            "siteName": data.description,
                            "sourceId": data.sourceId,
                            "customerType": data.customerType,
                            "referenceId": data.referenceId,
                            "email": data.email,
                            "earningCode": earningCode,
                            "phoneNumber": data.phoneNumber,
                            "landLineNumber": data.landlineNumber,
                            "risk": data.risk === "Select" ? "" : data.risk,
                            "siteType": data.siteType === "Select" ? "" : data.siteType,
                            "claim_freqeuncy": data.claimFreqeuncy,
                            "user_description": data.userDescription,
                            "skills": data.skills === "Select" ? "" : data.skills,
                            "addressType": data.addressType,
                            "extSystem": data.extSystem,
                            "rateCard": data.rateCard,
                            "priority": ""
                        },
                        "evv_info": {
                            "client_first_name": data.clientFirstName,
                            "client_last_name": data.clientLastName,
                            "service_code": data.serviceCode,
                            "stateId": data.stateId,
                            "jurisdictionId": data.jurisdictionId,
                            "authorization": data.authorization,
                            "timezone": data.timezone,
                            "startDate": formatDate(data.startDate, dateFormat),
                            "endDate": formatDate(data.endDate, dateFormat),
                            "client_medicaidId": data.medicaidId,
                            "client_payerID": data.payerId,
                            "client_idendifier": data.clientIdentifier,
                            "payer_information": data.payerInformation === "Select" ? "" : data.payerInformation,
                            "clientid": data.clientId
                        },
                        "active": data.status
                    }];

                    const response = await apiProvider.put("track/mobile/v1/allgeo/updateCustomers", payload);
                    if (response.status === 200) {
                        props.setMode({
                            title: props.mode.hasPrev ? "View Job Site Information" : "Manage Job/Client Sites",
                            type: props.mode.hasPrev ? 'view' : 'main',
                            data: props.mode.hasPrev ? { txnId: props.siteId } : '',
                            hasPrev: false
                        });
                        props.setToastMsg({ msg: response.message, type: "msgSuccess" });
                    } else {
                        props.setToastMsg({ msg: response.message, type: "msgError" });
                    }
                } catch (error) {
                    console.log(error);
                } finally {
                    localStorage.removeItem('formIsDirty');
                    props.setShowLoader(false);
                }
            }
        }
    };

    const onSubmitEditOnMapForm = async (data) => {

        const minValue = units[jobSiteDataForMap?.geozone?.radiusUnit?.toLowerCase()] === "m" ? 1 : 4

        if (jobSiteDataForMap?.geozone?.zoneRadius < minValue && jobSiteDataForMap?.geozone?.zoneType === 'Circle') {

            props.setToastMsg({ msg: `The radius cannot be less than ${minValue} ${(jobSiteDataForMap?.geozone?.radiusUnit).toLowerCase()}. Please enter a significant value.`, type: "" });

        } else {

            props.setShowLoader(true)

            try {

                let payload = {
                    "hasRadius": jobSiteDataForMap.geozone.hasRadius,
                    "latlanList": jobSiteDataForMap.geozone.latlanList,
                    "reverseGeocode": data.reverseGeoCode,
                    "departZone": data.departureZone,
                    "arrivalZone": data.arrivalZone,
                    "zoneRadius": jobSiteDataForMap?.geozone?.zoneRadius,
                    "inputType": "text",
                    "autoNotify": data.autoNotify,
                    "priority": data.overlapPriority,
                    "zonecolor": data.zoneColor
                }

                const response = await apiProvider.put(`track/mobile/v1/allgeo/geozone/${jobSiteDataForMap.geozone.geozoneId}`, payload)
                if (response.status === 200) {
                    props.setToastMsg({ msg: response.message, type: "msgSuccess" });
                    setShowEditMap(false);

                    let updatedJobSiteData = {
                        ...jobSiteData,
                        geozone: {
                            ...jobSiteData.geozone,
                            latlanList: jobSiteDataForMap.geozone.latlanList
                        }
                    };
                    setJobSiteData(updatedJobSiteData)
                }

            } catch (error) {
                console.log(error);
            } finally {
                props.setShowLoader(false)
            }

        }

    }

    const addCoordinates = () => {

        let length = jobSiteDataForMap.geozone.latlanList.length
        let updatedJobSiteData = {
            ...jobSiteData,
            geozone: {
                ...jobSiteDataForMap.geozone,
                latlanList: [
                    ...jobSiteDataForMap.geozone.latlanList,
                    { latValue: "", lonValue: "", lon: `trackMapZoneLongitude_${length - 1}`, lat: `trackMapZoneLatitude_${length - 1}` }
                ]
            },
            isChanged: true
        };
        setJobSiteDataForMap(updatedJobSiteData)
    }

    const removeCoordinates = (index) => {

        let updatedJobSiteData = {
            ...jobSiteDataForMap,
            geozone: {
                ...jobSiteDataForMap.geozone,
                latlanList: [
                    ...jobSiteDataForMap.geozone.latlanList
                ]
            },
            isChanged: true
        };
        updatedJobSiteData.geozone.latlanList.splice(index, 1);
        updatedJobSiteData.geozone.latlanList.forEach((ele, ind) => {
            let latName = ele.lat.split('_');
            let lonName = ele.lon.split('_');
            ele.lat = `${latName[0]}_${ind}`;
            ele.lon = `${lonName[0]}_${ind}`
        })
        setJobSiteDataForMap(updatedJobSiteData)
    }

    const changeCoordinate = (e, type, ind) => {
        let updatedCoord = jobSiteDataForMap.geozone.latlanList.map((ele, index) => {
            if (index === ind) {
                return Object.assign({}, ele, { [type]: e.target.value })
            }
            return ele
        })
        let updatedJobSiteData = {
            ...jobSiteDataForMap,
            geozone: {
                ...jobSiteDataForMap.geozone,
                latlanList: [
                    ...updatedCoord
                ]
            },
            isChanged: true
        };
        setJobSiteDataForMap(updatedJobSiteData)
    }

    const changeZoneRadius = (rad) => {

        let updatedJobSiteData = {
            ...jobSiteDataForMap,
            geozone: {
                ...jobSiteDataForMap.geozone,
                zoneRadius: Math.abs(rad)
            },
            isChanged: true
        };
        setJobSiteDataForMap(updatedJobSiteData)
    }

    const changeZoneColor = (color) => {

        let updatedJobSiteData = {
            ...jobSiteDataForMap,
            geozone: {
                ...jobSiteDataForMap.geozone,
                zonecolor: color
            },
            isChanged: true
        };
        setJobSiteDataForMap(updatedJobSiteData)
    }

    const allowOnlyText = (value, fieldType) => {

        let updatedJobSiteData = {};

        if (fieldType === 0) {
            updatedJobSiteData = {
                ...jobSiteData,
                evv_info: {
                    ...jobSiteData.evv_info,
                    client_first_name: value.replace(/[^a-zA-Z\s]/g, '')
                }
            }
        } else {
            updatedJobSiteData = {
                ...jobSiteData,
                evv_info: {
                    ...jobSiteData.evv_info,
                    client_last_name: value.replace(/[^a-zA-Z\s]/g, '')
                }
            }
        }

        setJobSiteData(updatedJobSiteData)

    }

    const changeGeoZone = (id) => {
        let data = jobSiteList.filter((ele) => ele.txnId === id);
        if (data.length > 0) {
            setSelectedJobSite(data)
        }
    }

    const selectedItems = (e) => {
        const selectedValues = e.value;
        setSelectedEarningCodes(selectedValues);
        setValue('earningCode', selectedValues, { shouldDirty: true });
    };

    const jobsiteLabel = getAccountLabel('jobsite');

    // const jobsiteType = ['customer', 'deliveryAdrs', 'office', 'properties', 'restaurant', 'truck']

    const capitalizeFirstLetter = (string) => {
        if (string === 'deliveryAdrs') return 'Delivery Address';
        if (typeof string !== 'string' || string.length === 0) return '';
        return string.charAt(0).toUpperCase() + string.slice(1);
    };


    const options = [
        { value: 'Customer', label: 'Customer', icon: home },
        { value: 'Delivery Address', label: 'Delivery Address', icon: medical },
        { value: 'Office', label: 'Office', icon: office },
        { value: 'Properties', label: 'Properties', icon: Properties },
        { value: 'Restaurant', label: 'Restaurant', icon: resturant },
        { value: 'Truck', label: 'Truck', icon: truck }
    ];

    const CustomOption = ({ innerRef, innerProps, data, isSelected }) => (
        <div
            className={`customOptions ${isSelected ? 'selectedOption' : ''}`}
            ref={innerRef}
            {...innerProps}
            style={{ cursor: "pointer" }}
        >
            <img src={data.icon} alt={data.label} style={{ width: 13, marginRight: 10 }} />
            {data.label}
        </div>
    );

    const CustomSingleValue = ({ data }) => (
        <div className="defaultSelected" style={{ display: 'flex', alignItems: 'center' }}>
            <img src={data.icon} alt={data.label} style={{ width: 20, marginRight: 10 }} />
            {data.label}
        </div>
    );


    //at first we are setting the data here for default customer
    useEffect(() => {
        const defaultCustomerType = jobSiteData.additional_info?.customerType;
        if (defaultCustomerType) {
            const defaultOption = options.find(option => option.value === defaultCustomerType);
            if (defaultOption) {
                setCustomerTypeOptions(defaultOption);
                setValue('customerType', defaultOption.value, { shouldDirty: true });
            }
        }
    }, [jobSiteData, setValue]);

    return (
        <Fragment>
            <Prompt
                when={isDirty}
                message='The changes are still unsaved. Are you sure you want to leave?'
            />
            {showEditMap === false ?
                (
                    <form onSubmit={handleSubmit(onSubmitEditForm)} id="jobSiteForm">
                        <Fragment>
                            <section>
                                <div className="editSectionWrap">
                                    <div className="editSectionLeft">
                                        <h3>{jobsiteLabel} Information</h3>
                                        <div className="fldWrap">
                                            <label>Site Name</label>
                                            <input type="text" maxLength={100} {...register('siteName')} />
                                        </div>
                                        <div className="fldWrap">
                                            <label>Geozone Type</label>
                                            <div className="customControls capsules">
                                                <div className="customRadio">
                                                    <input type="radio" id="Circle" name="speed" value="Circle"
                                                        checked={geoZone === 'Circle'}
                                                    />
                                                    <label htmlFor="Circle">Circle</label>
                                                </div>
                                                <div className="customRadio">
                                                    <input type="radio" id="Polygon" name="speed" value="Polygon"
                                                        checked={geoZone === 'Polygon'}
                                                    />
                                                    <label htmlFor="Polygon">Polygon</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="fldWrap">
                                            <label>Address</label>
                                            <textarea maxLength={255} {...register("address")}></textarea>
                                        </div>
                                        {
                                            geoZone === 'Circle' &&
                                            <div className="rowBlk">
                                                <div className="colBlk6 fldWrap">
                                                    <label>Select</label>
                                                    <select {...register("metric")}>
                                                        <option value={'+ve'}>Increase Radius</option>
                                                        <option value={'-ve'}>Decrease Radius</option>
                                                    </select>
                                                </div>
                                                <div className="colBlk6 fldWrap">
                                                    <label>by</label>
                                                    <div className="withInputSub">
                                                        <input type="text"
                                                            {...register("radiusIncDec",
                                                                {
                                                                    value: 4,
                                                                    onChange: (e) => handleRadiusUpdate(e.target.value)
                                                                }
                                                            )} />
                                                        <span>{units[jobSiteData.geozone.radiusUnit?.toLowerCase()]}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div className="fldWrap">
                                            <label>Active</label>
                                            <div className="customCheck yesNo">
                                                <input type="checkbox" id="StatusActive" {...register("status")} />
                                                <label className="customCheckLabel" htmlFor="StatusActive">yesNo</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="editSectionRight">
                                        <h3>Map View</h3>
                                        {
                                            Object.keys(jobSiteData).length > 0 &&
                                            <Map
                                                mode={'view'}
                                                geoZoneType={jobSiteData.geozoneType}
                                                latLngArray={getLatLngList(jobSiteData?.geozone?.latlanList)}
                                                zoneRadius={(parseInt(jobSiteData?.geozone?.zoneRadius) + parseInt(radius)) * unitConversionRate[jobSiteData?.geozone?.radiusUnit?.toLowerCase()]}
                                                radiusUnit={jobSiteData?.geozone?.radiusUnit}
                                                mapHeight={320}
                                                zoneColor={zoneColorCondition(jobSiteData.geozone.zonecolor)}
                                                setCursor={setCursor}
                                                jobSiteData={jobSiteData}
                                                setJobSiteData={setJobSiteData}
                                            />
                                        }
                                        <div className="btnWrap flexJEnd topGap1">
                                            <button className="btnStyle" onClick={() => setShowEditMap(true)}>Edit on Map</button>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section>
                                <h3>Advanced Setup</h3>
                                <div className="supportedTxt">Update your account information including who should receive notification emails and your preferred timezone</div>
                                <div className="innerWrap">
                                    <div className="rowBlk">
                                        <div className="colBlk3 fldWrap">
                                            <label>Longitude</label>
                                            <input type="text" maxLength={10} {...register("longitude")} />
                                        </div>
                                        <div className="colBlk3 fldWrap">
                                            <label>Latitude</label>
                                            <input type="text" maxLength={10} {...register("latitude")} />
                                        </div>
                                        <div className="colBlk6 fldWrap">
                                            <label className="withHelpTxt">Site ID <span className="helpTip">? <span> This is the Client Medicaid ID for EVV setup </span></span></label>
                                            <input type="text" maxLength={100} {...register("siteId")} />
                                        </div>
                                    </div>
                                    <div className="rowBlk">
                                        <div className="colBlk6 fldWrap">
                                            <label>Site Description</label>
                                            <input type="text" maxLength={100} {...register("description")} />
                                        </div>
                                        <div className="colBlk6 fldWrap">
                                            <label className="withHelpTxt">Source ID <span className="helpTip">? <span>This is the Client Payer ID for EVV setup</span></span></label>
                                            <input type="text" maxLength={255} {...register("sourceId")} />
                                        </div>
                                    </div>
                                    <div className="rowBlk">
                                        <div className="colBlk6 fldWrap">
                                            <label>Customer Type</label>
                                            <Select
                                                options={options}
                                                value={customerTypeOptions}
                                                onChange={(selectedOption) => {
                                                    setCustomerTypeOptions(selectedOption);
                                                    setValue('customerType', selectedOption.value, { shouldDirty: true });
                                                }}
                                                placeholder="select"
                                                components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
                                            />
                                        </div>
                                        <div className="colBlk6 fldWrap">
                                            <label className="withHelpTxt">Reference ID <span className="helpTip">? <span>This is the Client Identifier for EVV setup</span></span></label>
                                            <input type="text" maxLength={50} {...register("referenceId")} />
                                        </div>
                                    </div>
                                    <div className="rowBlk">
                                        <div className={`colBlk6 fldWrap ${errors.email ? 'errFld' : ''}`}>
                                            <label>Contact Email</label>
                                            <input type="text" maxLength={255} {...register("email", { pattern: /\S+@\S+\.\S+/ })} />
                                            {errors.email && <div className="fldMsg">Please enter a valid email</div>}
                                        </div>
                                        <div className="colBlk6 fldWrap">
                                            <label>Phone Number</label>
                                            <input type="text"
                                                maxLength={255}
                                                {...register("phoneNumber", {
                                                    onChange: (e) => setValue('phoneNumber', (e.target.value).replace(/[a-zA-Z]/g, ''), { shouldDirty: false })
                                                })} />
                                        </div>
                                    </div>
                                    <div className="rowBlk">
                                        <div className="colBlk3 fldWrap">
                                            <label>Risk</label>
                                            <select {...register("risk")}>
                                                <option>Select</option>
                                                <option>Low</option>
                                                <option>Medium</option>
                                                <option>High</option>
                                            </select>
                                        </div>
                                        <div className="colBlk3 fldWrap">
                                            <label>Site Type</label>
                                            <select {...register("siteType")}>
                                                <option>Select</option>
                                                <option>Nursing_Home</option>
                                                <option>Community</option>
                                            </select>
                                        </div>
                                        <div className="colBlk6 fldWrap">
                                            <label>Landline Number</label>
                                            <input type="text"
                                                maxLength={255}
                                                {...register("landlineNumber", {
                                                    onChange: (e) => setValue('landlineNumber', (e.target.value).replace(/[a-zA-Z]/g, ''), { shouldDirty: false })
                                                })} />
                                        </div>
                                    </div>
                                    <div className="rowBlk">
                                        <div className="colBlk6 fldWrap">
                                            <label>Claim Frequency</label>
                                            <input type="text" maxLength={255} {...register("claimFreqeuncy")} />
                                        </div>
                                        <div className="colBlk6 fldWrap">
                                            <label>User Description</label>
                                            <input type="text" maxLength={255} {...register("userDescription")} />
                                        </div>
                                    </div>
                                    <div className="rowBlk">
                                        <div className="colBlk6 fldWrap">
                                            <label>Skills</label>
                                            <select {...register("skills")}>
                                                {
                                                    skillsData.map((t, ind) => (
                                                        <option key={ind} value={t} >{t}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className="colBlk3 fldWrap">
                                            <label>Address</label>
                                            <div className="customControls">
                                                <div className="customRadio">
                                                    <input type="radio" id="PostalAddress" name="address" value="Postal Address" {...register("addressType")}
                                                        checked={addressType === "Postal Address"}
                                                        onClick={() => {
                                                            if (addressType !== "Postal Address") {
                                                                setAddressType('Postal Address');
                                                                setSelectedJobSite([])
                                                            }
                                                        }} />
                                                    <label htmlFor="PostalAddress">Postal Address</label>
                                                </div>
                                                <div className="customRadio">
                                                    <input type="radio" id="Geozone" name="address" value="Geozone" {...register("addressType")}
                                                        checked={addressType === "Geozone"}
                                                        onClick={() => {
                                                            if (addressType !== 'Geozone') {
                                                                setAddressType('Geozone');
                                                            }
                                                        }} />
                                                    <label htmlFor="Geozone">Geozone</label>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            addressType === "Geozone" &&
                                            <div className="colBlk3 fldWrap">
                                                <label>Select Geozone</label>
                                                <select defaultValue={props.siteId} onChange={(e) => changeGeoZone(e.target.value)}>
                                                    {
                                                        jobSiteList.map((t, ind) => (
                                                            <option key={ind} value={t.txnId}>{t.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        }
                                    </div>
                                    <div className="rowBlk">
                                        <div className="colBlk6 fldWrap">
                                            <label>Ext System</label>
                                            <input type="text" maxLength={10} {...register("extSystem")} />
                                        </div>
                                        <div className="colBlk3 fldWrap">
                                            <label>Select Earning Codes</label>
                                            <MultiSelectDropdown
                                                data={earningData.map(t => ({ id: t.code, text: t.code }))}
                                                value={selectedEarningCodes}
                                                onChange={selectedItems}
                                            />
                                        </div>
                                        <div className="colBlk3 fldWrap">
                                            <label>Rate card</label>
                                            <input type="text" maxLength={45} {...register("rateCard")} />
                                        </div>
                                    </div>
                                </div>
                            </section>
                            {jobSiteData.sandataEnabled &&
                                <section>
                                    <h3>EVV Extension</h3>
                                    <div className="supportedTxt">Update your account information including who should receive notification emails and your preferred timezone</div>
                                    <div className="innerWrap">
                                        <div className="rowBlk">
                                            <div className="colBlk6 fldWrap">
                                                <label>Client First Name *</label>
                                                <input type="text" maxLength={255} {...register("clientFirstName", {
                                                    onChange: (e) => allowOnlyText(e.target.value, 0)
                                                })} />
                                            </div>
                                            <div className="colBlk6 fldWrap">
                                                <label>Client Last Name *</label>
                                                <input type="text" maxLength={255} {...register("clientLastName", {
                                                    onChange: (e) => allowOnlyText(e.target.value, 1)
                                                })} />
                                            </div>
                                        </div>
                                        <div className="rowBlk">
                                            <div className="colBlk6 fldWrap">
                                                <label className="withHelpTxt">Service Code <span className="helpTip">? <span> Also used as Client ID </span></span></label>
                                                <input type="text" maxLength={255} {...register("serviceCode")} />
                                            </div>
                                            <div className="colBlk6 fldWrap">
                                                <label>State ID</label>
                                                <input type="text" maxLength={255} {...register("stateId")} />
                                            </div>
                                        </div>
                                        <div className="rowBlk">
                                            <div className="colBlk6 fldWrap">
                                                <label>Jurisdiction ID</label>
                                                <input type="text" maxLength={32} {...register("jurisdictionId")} />
                                            </div>
                                            <div className="colBlk6 fldWrap">
                                                <label>Authorization</label>
                                                <input type="text" maxLength={255} {...register("authorization")} />
                                            </div>
                                        </div>
                                        <div className="rowBlk">
                                            <div className="colBlk6 fldWrap">
                                                <label>Timezone</label>
                                                <select {...register("timezone")}>
                                                    {
                                                        [{ label: 'Select', value: 'Select' }, ...timeZones].map((t, ind) => (
                                                            <option key={ind} value={t.value} >{t.label}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <div className="colBlk3 fldWrap">
                                                <label>Effective Start Date</label>
                                                <DatePicker
                                                    value={watch('startDate')}
                                                    onChange={e => setValue('startDate', e.value, { shouldDirty: true })}
                                                    format={dateFormat}
                                                    min={new Date()}
                                                />
                                            </div>
                                            <div className="colBlk3 fldWrap">
                                                <label>Effective End Date</label>
                                                <DatePicker
                                                    value={watch('endDate')}
                                                    onChange={e => setValue('endDate', e.value, { shouldDirty: true })}
                                                    format={dateFormat}
                                                    min={new Date()}
                                                />
                                            </div>
                                        </div>
                                        <div className="rowBlk">
                                            <div className="colBlk3 fldWrap">
                                                <label>Client Medicaid ID *</label>
                                                <input type="text" {...register("medicaidId")} readOnly />
                                            </div>
                                            <div className="colBlk3 fldWrap">
                                                <label>Client Payer ID</label>
                                                <input type="text" {...register("payerId")} readOnly />
                                            </div>
                                            <div className="colBlk6 fldWrap">
                                                <label>Client Identifier *</label>
                                                <input type="text" {...register("clientIdentifier")} readOnly />
                                            </div>
                                        </div>
                                        <div className="rowBlk">
                                            <div className="colBlk6 fldWrap">
                                                <label>Payer Information</label>
                                                <select {...register("payerInformation")}>
                                                    {
                                                        payerData.map((t, ind) => (
                                                            <option key={ind} value={t.payerServiceInfoId}>{t.description}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <div className="colBlk6 fldWrap">
                                                <label>Client ID</label>
                                                <input type="text" {...register("clientId")} readOnly />
                                            </div>
                                        </div>
                                        <div className="rowBlk">
                                            <div className="colBlk3 fldWrap">
                                                <label>Payer ID</label>
                                                <input type="text" value={payerInformation?.payerID} readOnly />
                                            </div>
                                            <div className="colBlk3 fldWrap">
                                                <label>Payer Program</label>
                                                <input type="text" value={payerInformation?.payerProgram} readOnly />
                                            </div>
                                            <div className="colBlk3 fldWrap">
                                                <label>Procedure Code</label>
                                                <input type="text" value={payerInformation?.procedureCode} readOnly />
                                            </div>
                                        </div>
                                        <div className="rowBlk">
                                            <div className="colBlk3 fldWrap">
                                                <label>Modifier 1</label>
                                                <input type="text" value={payerInformation?.modifier1} readOnly />
                                            </div>
                                            <div className="colBlk3 fldWrap">
                                                <label>Modifier 2</label>
                                                <input type="text" value={payerInformation?.modifier2} readOnly />
                                            </div>
                                            <div className="colBlk3 fldWrap">
                                                <label>Modifier 3</label>
                                                <input type="text" value={payerInformation?.modifier3} readOnly />
                                            </div>
                                            <div className="colBlk3 fldWrap">
                                                <label>Modifier 4</label>
                                                <input type="text" value={payerInformation?.modifier4} readOnly />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            }
                            <div className="mainBtnWrap">
                                <a href={void (0)} onClick={editFormCancelEvent}>Cancel</a>
                                <button disabled={!isDirty || !isValid} className="btnStyle">SAVE</button>
                            </div>
                        </Fragment>
                    </form>
                )
                :
                (
                    <div className="popupWrap fullPopup active">
                        <div className="closePopup" onClick={() => setShowEditMap(false)}>Close</div>
                        <div className="popupHeading">GeoZone</div>
                        <div className="mainContent" id="mainContent">
                            <form onSubmit={handleSubmit(onSubmitEditOnMapForm)} id="jobSiteEditOnMapForm">
                                <section>
                                    <div className="editSectionWrap">
                                        <div className="editSectionLeft">
                                            <h3>Edit Geozone</h3>
                                            <div className="rowBlk">
                                                <div className="colBlk6 fldWrap">
                                                    <label>Site Name</label>
                                                    <div className="highlightedValue">{jobSiteData.name}</div>
                                                </div>
                                                <div className="colBlk6 fldWrap">
                                                    <label>Geozone Type</label>
                                                    <div className="highlightedValue">{jobSiteData.geozoneType}</div>
                                                </div>
                                            </div>
                                            <div className="fldWrap">
                                                <label>Address</label>
                                                <textarea value={jobSiteData.address} maxLength={255} readOnly></textarea>
                                            </div>
                                            <div className="fldWrap">
                                                <label>Alerts</label>
                                                <ul className="twoColCheck">
                                                    <li>
                                                        <div className="customCheck">
                                                            <input type="checkbox" id="ReverseGeocode" {...register('reverseGeoCode')} />
                                                            <label className="customCheckLabel" htmlFor="ReverseGeocode">Reverse Geocode</label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="customCheck">
                                                            <input type="checkbox" id="DepartureZone" {...register('departureZone')} />
                                                            <label className="customCheckLabel" htmlFor="DepartureZone">Departure Zone</label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="customCheck">
                                                            <input type="checkbox" id="ArrivalZone" {...register('arrivalZone')} />
                                                            <label className="customCheckLabel" htmlFor="ArrivalZone">Arrival Zone</label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="customCheck">
                                                            <input type="checkbox" id="AutoNotify" {...register('autoNotify')} />
                                                            <label className="customCheckLabel" htmlFor="AutoNotify">Auto Notify</label>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <h3 className="topGap2">Geozone Attributes <span>Cursor: <strong>{cursor}</strong></span></h3>
                                            <div className="rowBlk">
                                                <div className="colBlk6 fldWrap">
                                                    <label>Overlap Priority</label>
                                                    <select {...register('overlapPriority')}>
                                                        {[0, 1, 2, 3, 4, 5].map((val, ind) =>
                                                            (<option key={ind} value={val}>{val}</option>)
                                                        )}
                                                    </select>
                                                </div>
                                                <div className="colBlk6 fldWrap">
                                                    <label>Zone Color</label>
                                                    <select {...register('zoneColor')} defaultValue={jobSiteDataForMap.geozone.zonecolor} onChange={(e) => changeZoneColor(e.target.value)}>
                                                        {colorArray.map((ele, ind) =>
                                                            (<option key={ind} value={ele.value}>{ele.label}</option>)
                                                        )}
                                                    </select>
                                                </div>
                                                <div className="colBlk6 fldWrap">
                                                    <label>Radius ({jobSiteDataForMap.geozone.radiusUnit})</label>
                                                    <input type="number" min={0} value={jobSiteDataForMap?.geozone?.zoneRadius} disabled={!jobSiteDataForMap.geozone.hasRadius} onChange={(e) => changeZoneRadius(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="sectionSubHeading">Coordinates</div>
                                            <div className="coordinateWrap">
                                                <div className="coordinateHeading">
                                                    <span>Latitude</span>
                                                    <span>Longitude</span>
                                                </div>
                                                <ul className="coordinateFlds">
                                                    {jobSiteDataForMap.geozone.latlanList.map((coords, ind) => (
                                                        <li
                                                            id={`key_${ind}`}
                                                            key={ind}
                                                            onMouseOver={() => document.getElementById(`key_${ind}`).classList.add('deleteActive')}
                                                            onMouseLeave={() => document.getElementById(`key_${ind}`).classList.remove('deleteActive')}>
                                                            <div><input type="text" value={Number(coords.latValue).toFixed(5)} onChange={(e) => { changeCoordinate(e, 'latValue', ind) }} /></div>
                                                            <div><input type="text" value={Number(coords.lonValue).toFixed(5)} onChange={(e) => { changeCoordinate(e, 'lonValue', ind) }} /></div>
                                                            {
                                                                (jobSiteDataForMap.geozone.latlanList.length > 3)
                                                                && <span className="deleteCoordinate" onClick={() => removeCoordinates(ind)}>Delete</span>
                                                            }
                                                        </li>

                                                    ))}
                                                </ul>
                                                {
                                                    (jobSiteDataForMap.geozoneType === 'Polygon' && jobSiteDataForMap.geozone.latlanList.length < 8)
                                                    && <div className="addCoordinate" onClick={() => addCoordinates()}>+ Add Coordinates</div>
                                                }
                                            </div>
                                        </div>
                                        <div className="editSectionRight">
                                            <Map
                                                mode={'edit'}
                                                geoZoneType={jobSiteDataForMap.geozoneType}
                                                latLngArray={getLatLngList(jobSiteDataForMap?.geozone?.latlanList)}
                                                zoneRadius={parseInt(jobSiteDataForMap?.geozone?.zoneRadius) * unitConversionRate[jobSiteData?.geozone?.radiusUnit?.toLowerCase()]}
                                                radiusUnit={jobSiteDataForMap?.geozone?.radiusUnit}
                                                mapHeight={"100%"}
                                                zoneColor={zoneColorCondition(jobSiteDataForMap.geozone.zonecolor)}
                                                setCursor={setCursor}
                                                jobSiteData={jobSiteDataForMap}
                                                setJobSiteData={setJobSiteDataForMap}
                                            />
                                        </div>
                                    </div>
                                </section>
                                <div className="mainBtnWrap">
                                    <a href={void (0)} onClick={editOnMapFormCancelEvent}>Cancel</a>
                                    <button className="btnStyle" disabled={!isDirty && jobSiteDataForMap.isChanged === false}>SAVE</button>
                                </div>
                            </form>
                        </div>
                    </div>
                )
            }
            <ConfirmPopup
                showDialog={showDialog}
                message={"The changes are still unsaved. Are you sure you want to leave?"}
                confirmNavigation={onSuccess}
                cancelNavigation={onFaliure}
            />
        </Fragment>
    );
}

export default editJobSite