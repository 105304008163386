const moment = window.moment;

export const timeZones = [
    {
        value: "Location",
        label: "Location TimeZone"
    },
    {
        value: "Device",
        label: "Device TimeZone"
    },
    {
        value: "US/Pacific",
        label: "US/Pacific"
    },
    {
        value: "US/Arizona",
        label: "US/Arizona"
    },
    {
        value: "US/Mountain",
        label: "US/Mountain"
    },
    {
        value: "US/Central",
        label: "US/Central"
    },
    {
        value: "US/Eastern",
        label: "US/Eastern"
    },
    {
        value: "Canada/Pacific",
        label: "Canada/Pacific"
    },
    {
        value: "Canada/Mountain",
        label: "Canada/Mountain"
    },
    {
        value: "Canada/Central",
        label: "Canada/Central"
    },
    {
        value: "Canada/Eastern",
        label: "Canada/Eastern"
    },
    {
        value: "Canada/Atlantic",
        label: "Canada/Atlantic"
    },
    {
        value: "Mexico/BajaNorte",
        label: "Mexico/BajaNorte"
    },
    {
        value: "Mexico/BajaSur",
        label: "Mexico/BajaSur"
    },
    {
        value: "Mexico/General",
        label: "Mexico/General"
    },
    {
        value: "Europe/Athens",
        label: "Europe/Athens"
    },
    {
        value: "Europe/Berlin",
        label: "Europe/Berlin"
    },
    {
        value: "Europe/Dublin",
        label: "Europe/Dublin"
    },
    {
        value: "Europe/Helsinki",
        label: "Europe/Helsinki"
    },
    {
        value: "Europe/Kiev",
        label: "Europe/Kiev"
    },
    {
        value: "Europe/Lisbon",
        label: "Europe/Lisbon"
    },
    {
        value: "Europe/London",
        label: "Europe/London"
    },
    {
        value: "Europe/Madrid",
        label: "Europe/Madrid"
    },
    {
        value: "Europe/Moscow",
        label: "Europe/Moscow"
    },
    {
        value: "Europe/Oslo",
        label: "Europe/Oslo"
    },
    {
        value: "Europe/Paris",
        label: "Europe/Paris"
    },
    {
        value: "Europe/Rome",
        label: "Europe/Rome"
    },
    {
        value: "Europe/Stockholm",
        label: "Europe/Stockholm"
    },
    {
        value: "Europe/Zurich",
        label: "Europe/Zurich"
    },
    {
        value: "Pacific/Auckland",
        label: "Pacific/Auckland"
    },
    {
        value: "Pacific/Chatham",
        label: "Pacific/Chatham"
    },
    {
        value: "GMT+00:00",
        label: "GMT+00:00"
    },
    {
        value: "GMT+01:00",
        label: "GMT+01:00"
    },
    {
        value: "GMT+02:00",
        label: "GMT+02:00"
    },
    {
        value: "GMT+03:00",
        label: "GMT+03:00"
    },
    {
        value: "GMT+04:00",
        label: "GMT+04:00"
    },
    {
        value: "GMT+05:00",
        label: "GMT+05:00"
    },
    {
        value: "GMT+05:30",
        label: "GMT+05:30"
    },
    {
        value: "GMT+06:00",
        label: "GMT+06:00"
    },
    {
        value: "GMT+07:00",
        label: "GMT+07:00"
    },
    {
        value: "GMT+08:00",
        label: "GMT+08:00"
    },
    {
        value: "GMT+09:00",
        label: "GMT+09:00"
    },
    {
        value: "GMT+10:00",
        label: "GMT+10:00"
    },
    {
        value: "GMT+11:00",
        label: "GMT+11:00"
    },
    {
        value: "GMT+12:00",
        label: "GMT+12:00"
    },
    {
        value: "GMT+13:00",
        label: "GMT+13:00"
    },
    {
        value: "GMT+14:00",
        label: "GMT+14:00"
    },
    {
        value: "GMT-01:00",
        label: "GMT-01:00"
    },
    {
        value: "GMT-02:00",
        label: "GMT-02:00"
    },
    {
        value: "GMT-03:00",
        label: "GMT-03:00"
    },
    {
        value: "GMT-04:00",
        label: "GMT-04:00"
    },
    {
        value: "GMT-05:00",
        label: "GMT-05:00"
    },
    {
        value: "GMT-06:00",
        label: "GMT-06:00"
    },
    {
        value: "GMT-07:00",
        label: "GMT-07:00"
    },
    {
        value: "GMT-08:00",
        label: "GMT-08:00"
    },
    {
        value: "GMT-09:00",
        label: "GMT-09:00"
    },
    {
        value: "GMT-10:00",
        label: "GMT-10:00"
    },
    {
        value: "GMT-11:00",
        label: "GMT-11:00"
    },
    {
        value: "GMT-12:00",
        label: "GMT-12:00"
    },

]

export const formatDate = (date, format = 'YYYY/MM/DD') => moment(date).format(format)

export const formatTime = (value, format = 'HH:mm:ss') => moment(value).format(format)

export const AddRecordformatTime = (value, format = 'HH:mm:ss') => moment(value, 'hh:mm:ss A').format(format)

export const getDateTimeFromTimestamp = timestamp => {
    const dateTimeParts = timestamp.split("T");

    const dateParts = dateTimeParts[0].split("-");
    const formattedDate = dateParts[0] + "/" + dateParts[1] + "/" + dateParts[2];
    const time = dateTimeParts[1].split(".")[0].slice(0, 5);

    return `${formattedDate}/${time}`
}

export const MIN_TIMESTAMP_FOR_NEW_ACCOUNT = 1683702000 //10 May 2023