import React, { useState, useEffect, Fragment } from 'react'
import { useForm } from 'react-hook-form'
import { apiProvider } from '../../../services/api/provider'
import FileUpload from '../../common/FileUpload'
import sampleXLSX from './task_details.xlsx';
import { getAccountLabel } from '../../../utils';

const IndividualMenu = ({ register, errors, earningCodes, setValue, payerData }) => {

    const [showAdvanceSection, setShowAdvanceSection] = useState(false)

    const validateInput = e => {
        const { value, name } = e.target;

        let inputValidation;

        if (name === "name") {
            inputValidation = /[^a-zA-Z0-9 _-]/gi
        } else {
            inputValidation = /[^a-zA-Z0-9_-]/gi
        }

        setValue(name, value.replace(inputValidation, ""));
    }

    const taskLabel = getAccountLabel('task');

    return (
        <div className="popupTabCont">
            <div className="fldWrap">
                <label>Active</label>
                <div className="customCheck yesNo">
                    <input type="checkbox" id="activeStatus" {...register('isActive')} defaultChecked />
                    <label className="customCheckLabel" htmlFor="activeStatus">yesNo</label>
                </div>
            </div>
            <div className="rowBlk">
                <div className={`colBlk6 fldWrap${errors.taskID ? ' errFld' : ''}`}>
                    <label>{taskLabel} ID</label>
                    <input type="text" maxLength={255} {...register('taskID', { onChange: validateInput })} />
                    {errors.taskID && <span className="fldMsg">{taskLabel} ID already exists</span>}
                </div>
                <div className={`colBlk6 fldWrap${errors.name ? ' errFld' : ''}`}>
                    <label>{taskLabel} Name</label>
                    <input type="text" maxLength={100} {...register('name')} />
                    {errors.name && <span className="fldMsg">{taskLabel} Name already exists</span>}
                </div>
            </div>
            <div className={`collapsibleSection${showAdvanceSection ? ' active' : ''}`}>
                <span onClick={() => setShowAdvanceSection(!showAdvanceSection)}>Advanced Setup</span>
            </div>
            {showAdvanceSection &&
                <Fragment>
                    <div className="rowBlk">
                        <div className="colBlk6 fldWrap">
                            <label>Reference ID</label>
                            <input type="text" maxLength={50} {...register('refID', { onChange: validateInput })} />
                        </div>
                        <div className="colBlk6 fldWrap">
                            <label>Source ID</label>
                            <input type="text" maxLength={255} {...register('srcID', { onChange: validateInput })} />
                        </div>
                    </div>
                    <div className="rowBlk">
                        <div className="colBlk6 fldWrap">
                            <label>Company ID</label>
                            <input type="text" maxLength={50} {...register('companyID', { onChange: validateInput })} />
                        </div>
                        <div className="colBlk6 fldWrap">
                            <label>Ext System</label>
                            <input type="text" maxLength={10} {...register('extSystem', { onChange: validateInput })} />
                        </div>
                    </div>
                    <div className="rowBlk">
                        <div className="colBlk6 fldWrap">
                            <label>Earning Code</label>
                            <select  {...register('earningCode')}>
                                {
                                    earningCodes.map((code, ind) => (
                                        <option key={ind} value={code.value} >{code.label}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="colBlk6 fldWrap">
                            <label>Billing Rate</label>
                            <input type="text" maxLength={50} {...register('billingRate')} />
                        </div>
                    </div>
                    <div className="rowBlk">
                        <div className="colBlk6 fldWrap">
                            <label>Payer/Insurer Information</label>
                            <select>
                                {
                                    payerData?.map((payer, ind) => (
                                        <option key={ind} value={payer.payerServiceInfoId}>{payer.description}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                </Fragment>
            }
        </div>
    )
}

const AddNewPopup = ({ closePopup, setToastMsg, setShowLoader, payerData, setMode, setShowG2Popup }) => {
    const { register, handleSubmit, watch, reset, setError, setValue, formState: { errors } } = useForm()
    const [activeMenu, setActiveMenu] = useState('single')
    const [isFormComplete, setIsFormComplete] = useState(false)
    const [earningCodes, setEarningCodes] = useState([])
    const [file, setFile] = useState(null);

    const toggleMenu = menu => {
        if (menu === "multiple") {
            reset()
        } else {
            if (file) setFile(null)
        }
        setIsFormComplete(false)
        setActiveMenu(menu)
    }

    const onSubmit = async (data) => {

        try {
            setShowLoader(true)

            if (activeMenu === 'single') {

                const payload = {
                    "refID": data.refID || '',
                    "srcID": data.srcID || '',
                    "taskID": data.taskID,
                    "companyID": data.companyID || '',
                    "name": data.name,
                    "extSystem": data.extSystem || '',
                    "earningCode": data.earningCode || '',
                    "isActive": data.isActive,
                    "billingRate": data.billingRate || ''
                }

                const response = await apiProvider.post('/track/mobile/v1/allgeo/task', payload)

                if (response.status === 200) {
                    setToastMsg({
                        msg: `${data.name} has been added successfully`,
                        type: "msgSuccess"
                    })
                    setTimeout(() => {
                        setShowG2Popup(true)
                    }, 2000)
                    closePopup()
                } else if (response.status === 409) {
                    setError(response.field, true)
                } else {
                    setToastMsg({
                        msg: response.message,
                        type: "msgError"
                    })
                }
            } else {

                const formData = new FormData()
                formData.append('file', file)

                const response = await apiProvider.formPost('/track/mobile/v1/allgeo/uploadTask', formData)

                if (response.status === 200) {
                    setToastMsg({
                        msg: 'Bulk addition of multiple tasks is completed successfully',
                        type: "msgSuccess"
                    })
                    closePopup()
                } else if (+response.status === 422) {
                    const { knownColumns, unknownColumn } = response;

                    setMode({
                        type: 'uploadError',
                        data: {
                            file,
                            validColumns: knownColumns,
                            misMatchedColumns: unknownColumn,
                            validationError: false,
                            uploadUrl: "/track/mobile/v1/allgeo/uploadTask"
                        },
                        hasPrev: false
                    })

                    closePopup()

                } else {
                    setToastMsg({
                        msg: response.message,
                        type: "msgError"
                    })
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    const getEarningCodeData = async () => {
        try {
            const response = await apiProvider.getAll('track/mobile/v1/allgeo/earningCode')
            setEarningCodes(response.earningCodeMap.map((ele) => {
                const label = Object.keys(ele).filter(el => el !== 'selected')[0] || 'None'
                return { 'selected': ele.selected, 'value': label, 'label': label }
            }))
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getEarningCodeData()
    }, [])

    useEffect(() => {
        if (errors.taskID) {
            setError('taskID', false)
        }
        if (errors.name) {
            setError('name', false)
        }
        const subscribe = watch(data => {
            const isEmpty = !data.taskID || !data.name;
            setIsFormComplete(!isEmpty)
        })

        return () => subscribe.unsubscribe()
    }, [watch])

    const taskLabel = getAccountLabel('task');

    return (
        <div className="popupWrap smlPopup active">
            <div className="closePopup" onClick={closePopup}>Close</div>
            <div className="popupHeading">Add {taskLabel}(s)</div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="popupContent">
                    <div className="popupTabMenu">
                        <ul>
                            <li className={`${activeMenu === 'single' ? 'active' : ''}`} onClick={() => toggleMenu("single")}>Individual</li>
                            <li className={`${activeMenu === 'multiple' ? 'active' : ''}`} onClick={() => toggleMenu("multiple")}>Multiple</li>
                        </ul>
                    </div>

                    {
                        activeMenu === 'single'
                            ?
                            <IndividualMenu
                                register={register}
                                errors={errors}
                                earningCodes={earningCodes}
                                setValue={setValue}
                                payerData={payerData}
                            />
                            :
                            <FileUpload
                                headerText={`Download ${taskLabel} details`}
                                getFile={uploadedFile => setFile(uploadedFile)}
                                setIsFormComplete={setIsFormComplete}
                                csvFile={sampleXLSX}
                                csvFileName={`${taskLabel} details`}
                            />
                    }
                </div>
                <div className="popupBtnWrap flexJCenter">
                    <button className="btnStyle okBtn" disabled={!isFormComplete}>Add</button>
                </div>
            </form>
        </div>
    )
}

export default AddNewPopup