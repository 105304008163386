import { getAccountLabel } from "../../../utils"

const groupLabel = getAccountLabel('group') || 'Group';
const employeeLabel = getAccountLabel('employee');

export const groupColumns = [
    {
        title: `${groupLabel} ID`,
        field: "groupID",
        editable: false,
        editor: "text",
        orderIndex: 1,
        show: true,
        filter: "text",
    },
    {
        title: `${groupLabel} Name`,
        field: "groupDesc",
        editable: false,
        editor: "text",
        orderIndex: 2,
        show: true,
        filter: "text"
    },
    {
        title: `${employeeLabel} Count`,
        field: "devCount",
        editable: false,
        editor: "text",
        orderIndex: 3,
        show: true,
        filter: "text"
    },
]