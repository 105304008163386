import React, { Fragment, useEffect, useState } from 'react';
import { useDocumentTitle } from '../../../hooks/setDocumentTitle';
import StandardTimeClock from './subReports/StandardTimeClock';
import { AdvanceTimeClock } from './subReports/AdvanceTimeClock';
import MessageReport from './subReports/MessageReport';
import WOPerformace from './subReports/WorkOrderPerfomance/WOPerformace';
import { apiProvider } from '../../../services/api/provider';
import { TrackingReport } from './subReports/TrackingReport';

const tabs = {
    STANDARD_TIME_CLOCK: 'etc',
    ADVANCE_TIME_CLOCK: "atc",
    MESSAGE_REPORT: 'mr',
    TRACKING_REPORT: "tr",
    WORKORDER_PERFORMANCE: "wp",
}

const BuildReports = () => {

    useDocumentTitle('Build Reports - allGeo')

    const [activeTab, setActiveTab] = useState(tabs.STANDARD_TIME_CLOCK)
    const [clickedTab, setClickedTab] = useState(tabs.STANDARD_TIME_CLOCK)
    const [genSetting, setGenSettings] = useState();
    const [defaultColumnName, setDefaultColumnName] = useState();

    const getDefaultHeaderName = async () => {
        try {
                const response = await apiProvider.getAll('track/mobile/v1/allgeo/getDefaultReportColumnNames');
                setDefaultColumnName(response);
            } catch (error) {
                console.error("defaultName Error:", error);
            } finally {
            }
        };

    const handleTabChange = tab => {
        setClickedTab(tab)

        const isFormDirty = localStorage.getItem('formIsDirty')

        if (isFormDirty) {
            setShowDialog(true)
            return
        }

        setActiveTab(tab)
    }

    const getGeneralSettingsData = async () => {
        try {
            const response = await await apiProvider.getAll('track/react/account/generalSetting')
            localStorage.setItem("secondsEnable", response.report_units.secondFormatOpted)
            localStorage.setItem("secondsEnable", response.report_units.secondFormatOpted)
            localStorage.setItem("secondsEnable", response.report_units.secondFormatOpted)
            setGenSettings(response.report_units)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getGeneralSettingsData();
        getDefaultHeaderName();
    }, [])

    return (
        <Fragment>
            <ul className="innerSubNav">

                <li>
                    <a className={`${activeTab === tabs.STANDARD_TIME_CLOCK ? 'active' : ''} editIconAnchor`} onClick={() => handleTabChange(tabs.STANDARD_TIME_CLOCK)}>
                        <span className={`editIcon ${activeTab === tabs.STANDARD_TIME_CLOCK ? 'active' : ''}`}></span>
                        Standard Time Clock
                    </a>
                </li>

                <li>
                    <a className={`${activeTab === tabs.ADVANCE_TIME_CLOCK ? 'active' : ''} editIconAnchor`} onClick={() => handleTabChange(tabs.ADVANCE_TIME_CLOCK)}>
                        <span className={`editIcon ${activeTab === tabs.ADVANCE_TIME_CLOCK ? 'active' : ''}`}></span>
                        Advance Time Clock
                    </a>
                </li>
                <li>
                    <a className={`${activeTab === tabs.MESSAGE_REPORT ? 'active' : ''} editIconAnchor`} onClick={() => handleTabChange(tabs.MESSAGE_REPORT)}>
                        <span className={`editIcon ${activeTab === tabs.MESSAGE_REPORT ? 'active' : ''}`}></span>
                        Message Report
                    </a>
                </li>
                <li>
                    <a className={`${activeTab === tabs.TRACKING_REPORT ? 'active' : ''} editIconAnchor`} onClick={() => handleTabChange(tabs.TRACKING_REPORT)}>
                        <span className={`editIcon ${activeTab === tabs.TRACKING_REPORT ? 'active' : ''}`}></span>
                        Tracking Report
                    </a>
                </li>
                <li>
                    <a className={`${activeTab === tabs.WORKORDER_PERFORMANCE ? 'active' : ''} editIconAnchor`} onClick={() => handleTabChange(tabs.WORKORDER_PERFORMANCE)}>
                        <span className={`editIcon ${activeTab === tabs.WORKORDER_PERFORMANCE ? 'active' : ''}`}></span>
                        WorkOrder Performance
                    </a>
                </li>
            </ul>

            <section>
                {activeTab === tabs.STANDARD_TIME_CLOCK && <StandardTimeClock genSetting={genSetting} />}
                {activeTab === tabs.ADVANCE_TIME_CLOCK && <AdvanceTimeClock genSetting={genSetting} defaultColumnName={defaultColumnName}/>}
                {activeTab === tabs.MESSAGE_REPORT && <MessageReport genSetting={genSetting} />}
                {activeTab === tabs.TRACKING_REPORT && <TrackingReport genSetting={genSetting} />}
                {activeTab === tabs.WORKORDER_PERFORMANCE && <WOPerformace genSetting={genSetting} />}
            </section>
        </Fragment>
    )
}

export default BuildReports