import React, { useState, useEffect, useRef, Fragment } from "react";
import KendoGrid from '../../common/kendo/KendoGrid';
import { apiProvider } from '../../../services/api/provider';
import ToastMessage from '../../common/ToastMessage';
import SiteLoader from "../../common/SiteLoader";
import Header from "../../layout/Header";
import { getAccessLevel } from '../../common/functions';
import RulesView from './RulesView';
import CopyRulesDetails from "./CopyRulesDetails";
import RulesEdit from "./RulesEdit";
import AddNewRule from "./AddRules";
import { rulesColumns } from "./rulesUtils";
import { useDocumentTitle } from '../../../hooks/setDocumentTitle';


const RulesList = (props) => {

    useDocumentTitle("Rules - allGeo");

    const [showAddNewPopup, setShowAddNewPopup] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [rulesList, setRulesList] = useState(null);
    const [accessLevel, setAccessLevel] = useState({});
    const [isUpdated, setIsUpdated] = useState(false);

    const [mode, setMode] = useState({
        title: "Rules",
        type: 'main',
        data: null,
        hasPrev: false
    });

    const [copyRulesPopup, setCopyRulesPopup] = useState({
        show: false,
        data: null
    });

    useEffect(() => {
        getAccessLevel("Schedule", "automation_rules").then((response) => {
            setAccessLevel(response);
        });
        getRulesList()

    }, []);

    const getRulesList = async () => {
        try {
            setShowLoader(true);
            const response = await apiProvider.getAll('/track/mobile/v1/allgeo/rule');
            if (response.rules) {
                setRulesList(response.rules)
            } else {
                setRulesList([])
            }

        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    const [toastMsg, setToastMsg] = useState({
        msg: "",
        type: "msgSuccess"
    });

    const actionHandler = (type, data) => {

        switch (type) {
            case "view":
                setMode({
                    title: "View Rules Information",
                    type: 'view',
                    data: data,
                    hasPrev: false
                })
                break;
            case "edit":
                setMode({
                    title: "Edit Rules Information",
                    type: 'edit',
                    data: data,
                    hasPrev: false
                })
                break;
            case "copy":
                setCopyRulesPopup({
                    show: true,
                    data
                })
                break;
            default:
                break;
        }

    }

    const deleteRules = async data => {
        const payload = data.length ? data : [data.ruleID]
        try {
            setShowLoader(true)
            const response = await apiProvider.removeObject('/track/mobile/v1/allgeo/rule', payload)
            if (data.length)
                response.message = "The selected records have been deleted successfully";

            return response;
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    useEffect(() => {
        if (toastMsg.msg && toastMsg.type === "msgSuccess") {
            if (childRef.current) {
                childRef.current.refreshData()
            } else if (!childRef.current) {
                setIsUpdated(true)
            }
            setShowLoader(false)
        }
    }, [toastMsg])

    const childRef = useRef()

    return (

        <div className="mainContentWrap">
            <Header title={mode.title} />
            <div className="mainContent">
                {mode.type === 'main' &&
                    <Fragment>
                        <div className="sectionSubHeading">Manage Alert Rules</div>
                        {
                            rulesList &&
                            <KendoGrid
                                gridData={rulesList}
                                columnData={rulesColumns}
                                accessLevel={accessLevel}
                                setGridData={setRulesList}
                                module="rulesScheduler"
                                actionColumnHandler={actionHandler}
                                ref={childRef}
                                getAPiUrl={"/track/mobile/v1/allgeo/rule"}
                                lockActionColumn={true}
                                deleteItem={deleteRules}
                                bulkDelete={deleteRules}
                                otherData={{
                                    defaultSortedColumns: [{ field: 'creationTime', dir: 'desc' }],
                                    hideSaveCancelChanges: true,
                                    actionColumnBtns: ['view', 'edit', 'copy', 'delete'],
                                }}
                                openPopup={() => setShowAddNewPopup(true)}
                            />
                        }
                    </Fragment>
                }
                {(mode.type === 'view') &&
                    <Fragment>
                        <RulesView rulesId={mode.data.ruleID} setShowLoader={setShowLoader} setMode={setMode} accessLevel={accessLevel.childMenu} setToastMsg={setToastMsg} />
                    </Fragment>
                }
                {(mode.type === 'edit') &&
                    <Fragment>
                        <RulesEdit rulesId={mode.data.ruleID} setShowLoader={setShowLoader} mode={mode} setMode={setMode} accessLevel={accessLevel.childMenu} setToastMsg={setToastMsg} />
                    </Fragment>
                }

                {copyRulesPopup.show && <CopyRulesDetails data={copyRulesPopup.data} setCopyRulesPopup={setCopyRulesPopup} setToastMsg={setToastMsg} setShowLoader={setShowLoader} />}
                {showAddNewPopup && <AddNewRule setToastMsg={setToastMsg} setShowLoader={setShowLoader} closePopup={() => setShowAddNewPopup(false)} />}

            </div>

            {toastMsg.msg && <ToastMessage
                cssClass={toastMsg.type}
                message={toastMsg.msg}
                onClose={() => setToastMsg({ msg: "", type: "" })}
            />}
            <SiteLoader isActive={showLoader} />
        </div>

    )
}

export default RulesList;