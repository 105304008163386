import { getAccountLabel } from "../../../utils"
import { secondsToHHMMSS } from "../../common/kendo/kendoUtils"
import { HeartBeatReportColumnName, HEART_BEAT_REPORT_DESCRIPTION, HEART_BEAT_REPORT_DROPDOWN, DEVICE_LIST_REPORT_DESCRIPTION, DeviceListReportColumnName } from './AccountUtils/AccountUtils'
import { AUDIT_REPORTS_FOR_API_DROPDOWN, ADP_TIME_CARD, ADPTimeCard,editHistoryReportColumnsName,EDIT_HISTORY_REPORT_DESCRIPTION } from './DebugLogsUtils/debugLogsUtils'
import { sosDetailedReportColumnName, ADPPaydataStandardColumnName, ADPEarningCodePayrollReportColumnName, SOS_DETAIL_REPORT_DESCRIPTION, EARNING_CODE_PAYROLL_DESCRIPTION, ActiveDeviceReportColumnName, ACTIVE_DEVICE_REPORT_DESCRIPTION, firstInLastOutColumnName, FIRSTIN_LASTOUT_REPORT_DESCRIPTION, ADP_PAYLOAD_DROPDOWN, ADMIN_ADP_PAYLOAD_DROPDOWN, FILTER_TYPE, ADPTimeCardReportColumnName, ADP_TIMECARD_REPORT_DESCRIPTION, PARC_REPORT_DESCRIPTION } from './MyReportsUtils/myReportUtils'





/* LOCATION REPORT COLUMNS */

const jobsiteLabel = getAccountLabel('jobsite');
const employeeLabel = getAccountLabel('employee');
const taskLabel = getAccountLabel('task');
const groupLabel = getAccountLabel('group');
const workorderLabel = getAccountLabel('workorder')


const advanceBreadcrumbReportColumns = [
    {
        title: "Device Name",
        field: "deviceName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Device ID",
        field: "deviceID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Date",
        field: "txnDate",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Time",
        field: "txnTime",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Lat/Lon",
        field: "latlong",
        editable: false,
        width: 200,
        editor: "text",
        customCell: true,
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Site Status",
        field: "status",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Address",
        field: "address",
        editable: false,
        width: 300,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "Accuracy Feet [+/-]",
        field: "accuracy",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Hours",
        field: "elapsedTimeSec",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        format: "hh:mm:ss",
        locked: true
    },
]

const breadcrumbReportColumns = [
    {
        title: "Device Name",
        field: "deviceName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Device ID",
        field: "deviceID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Date",
        field: "txnDate",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Time",
        field: "txnTime",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Lat/Lon",
        field: "latlong",
        editable: false,
        width: 200,
        editor: "text",
        customCell: true,
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Accuracy Feet [+/-]",
        field: "accuracy",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Site Status",
        field: "status",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Address",
        field: "address",
        editable: false,
        width: 300,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "Trip #",
        field: "tripNumber",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Device Method",
        field: "deviceMethod",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Event Type",
        field: "eventType",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Battery Level [%]",
        field: "batteryLevel",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
]

const distanceTravelledColumns = [
    {
        title: "Device",
        field: "deviceID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Device Name",
        field: "deviceName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Date",
        field: "tcSentDate",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Distance (Miles)",
        field: "googleDistance",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
]

/* TIME REPORT COLUMNS */

const advanceTimeClockColumns = [
    {
        title: "Device Name",
        field: "deviceName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Device-ID",
        field: "deviceId",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `Customer Site`,
        field: "customerSite",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Start Day Address",
        field: "startDayAddress",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "Start Date/Time",
        field: "startDayDateTime",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "End Day Address",
        field: "endDayAddress",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "End Date/Time",
        field: "endDayDateTime",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true
    },
    {
        title: "Service Time",
        field: "serviceSeconds", //serviceSeconds
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        format: "hh:mm:ss",
        locked: true
    },
    {
        title: "Service Rate",
        field: "serviceRate",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        locked: true
    },
    {
        title: "Segment Miles",
        field: "segmentMiles", //googledistance
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        locked: true
    },
    {
        title: "Manager Notes",
        field: "notesOnStartDay",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }
]

const basicAutomaticColumns = [
    {
        title: "Device Name",
        field: "deviceName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Device-ID",
        field: "employeeID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: `${jobsiteLabel} Name`,
        field: "customerName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Start Day Address",
        field: "startAddress",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "Start Date",
        field: "startTCSentDate",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Start Time",
        field: "startTCSentTime",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "End Day Address",
        field: "endAddress",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "End Date",
        field: "endTCSentDate",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true
    },
    {
        title: "End Time",
        field: "endTCSentTime",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Service Time",
        field: "serviceSeconds",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        format: "hh:mm:ss",
        locked: true
    },
    {
        title: "Service Rate",
        field: "overTimeServiceRate",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        locked: true
    },
    {
        title: "Service Hour Type",
        field: "serviceHoursType",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        locked: true
    },
    {
        title: "Segment Miles",
        field: "googleDistance",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        locked: true
    },
    {
        title: "Manager Notes",
        field: "managerNotes",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    }
]

// Same as Edit Timeclock columns
const standardManualColumns = [
    {
        title: "Name",
        field: "deviceName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Stage Name",
        field: "stageName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Date",
        field: "tcSentDate",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Time",
        field: "tcSentTime",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: `${jobsiteLabel} Name`,
        field: "customerName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: `${taskLabel} Name`,
        field: "jobName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Manager Notes",
        field: "managerNotes",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Distance",
        field: "distanceTravelled",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        locked: true
    },
    {
        title: "Message",
        field: "messageText",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "Image Attachment",
        field: "imgAttach",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "Barcode Attachment",
        field: "barCodeAttach",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "Form Attachment",
        field: "formAttach",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: `${employeeLabel}#`,
        field: "deviceID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: `${groupLabel} Name`,
        field: "groupName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Latitude/Longitude",
        field: "latlog",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "Address",
        field: "address",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "Source",
        field: "hosSource",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Company Code",
        field: "companyCode",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: `${employeeLabel} ID`,
        field: "employeeID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: `${workorderLabel} No`,
        field: "workorderNo",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Modified On",
        field: "modifiedOn",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Modified By",
        field: "modifiedBy",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Processed",
        field: "isProcessed",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text"
    },
    {
        title: "Service Time",
        field: "serviceSeconds",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        format: "hh:mm:ss",
        locked: true
    }
]

/* FIELD DATA REPORT COLUMNS*/

const messageReport = [
    {
        title: "Phone Number",
        field: "phoneNumber",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "To",
        field: "toDeviceID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: false,
        filter: "text",
    },
    {
        title: "Address",
        field: "locAddress",
        editable: false,
        width: 300,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "Name",
        field: "displayName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "TxN Type",
        field: "txnType",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Message",
        field: "v3Msg",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "Images",
        field: "v3Picture",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: `${taskLabel}`,
        field: "v3Task",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        customCell:true,
        filter: "text",
    },
    {
        title: "Notes",
        field: "notes",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Form Data",
        field: "v3FormData",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "Time",
        field: "creationTime",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Geo Tag",
        field: "geoTag",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Qr Scan",
        field: "v3QrScan",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        customCell: true,
        show: true,
        filter: "text",
    },
]

const barcodeMessageReport = [
    {
        title: "Carrier",
        field: "carrier",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Device ID",
        field: "deviceID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Device Name",
        field: "deviceName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "App Active",
        field: "appActive",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Barcode Scan",
        field: "barcodeScan",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Scans",
        field: "scans",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `${taskLabel}`,
        field: "task",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Start Time",
        field: "startTime",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Stop Time",
        field: "stopTime",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "GPS Address",
        field: "gpsaddress",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Message",
        field: "v3Message",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
]

const customFormDataReport = [
    {
        title: "Report DateTime",
        field: "reportDateTime",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Name",
        field: "name",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Device ID",
        field: "deviceID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Location",
        field: "location",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Service Hours",
        field: "serviceHours",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Attachments",
        field: "attachments",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "Picture",
        field: "picture",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        customCell: true
    },
]

/* WORK ORDER REPORT COLUMNS */

const workOrderPerformanceColumn = [
    {
        title: `${employeeLabel} Name`,
        field: "employeeName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `${workorderLabel} Assigned`,
        field: "workOrderNo",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Scheduled Site",
        field: "scheduledVisit",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Start Time",
        field: "woStartTime",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Start Date",
        field: "woDate",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "End Time",
        field: "woEndTime",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "End Date",
        field: "woEndDate",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Alloted Duration",
        field: "woServiceStr",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Message",
        field: "woMessage",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "HOS Message",
        field: "hosMessage",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true
    },
    {
        title: "Visited Site",
        field: "realVisit",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "CheckIn Time",
        field: "woStartTime",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "CheckOut Time",
        field: "woEndTime",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Service Duration",
        field: "woServiceStr",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Variance",
        field: "variance",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "% Variance +/-",
        field: "variancePer",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
]

const workOrderSummaryColumn = [
    {
        title: "Total Scheduled",
        field: "totalScheduled",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Total Visits",
        field: "totalVisits",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Missed Services",
        field: "missedServices",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
]

const payRollDeviceList = [
    {
        title: "AccountID",
        field: "AccountID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Phone Number",
        field: "Phone_Number",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Total Scheduled",
        field: "totalScheduled",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Description",
        field: "Description",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `${employeeLabel}`,
        field: "Employee#",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Company Code",
        field: "Company_Code",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Batch ID",
        field: "Batch_ID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `${employeeLabel} Wage Type`,
        field: "Employee_Wage_Type",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Pay Class",
        field: "Pay_Class",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Regular Earning Code",
        field: "Regular_Earning_Code",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "OT Earning Code",
        field: "OT_Earning_Code",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Double OT Earning Code",
        field: "Double_OT_Earning_Code",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Position",
        field: "Position",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Mileage Earning Code",
        field: "Mileage_Earning_Code",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Skills",
        field: "Skills",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "SSN",
        field: "SSN",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Associate ID",
        field: "Associate_ID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Payroll File",
        field: "Payroll_File#",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Earning Code",
        field: "Earning_Code",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Job Title",
        field: "Job_Title",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Hire Date",
        field: "Hire_Date",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Benefits Eligibility Class Code",
        field: "Benefits_Eligibility_Class_Code",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Benefits Eligibility Class Desc",
        field: "Benefits_Eligibility_Class_Desc",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Business Unit Code",
        field: "Business_Unit_Code",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Business Unit Desc",
        field: "Business_Unit_Desc",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Home Cost Number Code",
        field: "Home_Cost_Number_Code",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Manager ID",
        field: "Manager_ID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Manager Name",
        field: "Manager_Name",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Department Code",
        field: "Department_Code",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Department Desc",
        field: "Department_Desc",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Location Code",
        field: "Location_Code",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Location Desc",
        field: "Location_Desc",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Status Flag 7 Code",
        field: "Status_Flag_7_Code",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Status Flag 7 Desc",
        field: "Status_Flag_7_Desc",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Time And Attendance (ADP)",
        field: "Time_And_Attendance(_ADP_)",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "PayData/Payroll(ADP)",
        field: "PayData/Payroll(_ADP_)",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Contract Status",
        field: "Contract_Status",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Rate Type",
        field: "Rate_Type",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: "Hourly Rate",
        field: "Hourly_Rate",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
    {
        title: `Assigned ${jobsiteLabel}`,
        field: "Assigned_Jobsite",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
    },
]

// dropdown data for the reports

let heartBeatList = [
    { label: "Latest", value: "Latest" },
    { label: "History", value: "History" }
]

let ActionDropDown = [
    { label: "All", value: "all" },
    { label: "Device Created", value: "device_create" },
    { label: "Device Deleted", value: "device_delete" },
    { label: "Device Activated", value: "device_activated" },
    { label: "Device Deactivated", value: "device_deactivated" },
    { label: "Location Optin", value: "locn_optin" },
    { label: "Location Optout", value: "locn_optout" },
    { label: "Location Optin Failed", value: "locn_optin_failed" },
    { label: "SMS Optin", value: "sms_optin" },
    { label: "SMS Optout", value: "sms_optout" },
];

let AccountIDDropDown = [
    { label: localStorage.getItem("accountId"), value: localStorage.getItem("accountId") },
];

if (localStorage.getItem("isParent") === "true") {
    AccountIDDropDown.push({ label: "all", value: "all" });
}


/* REPORT MAP OBJECT */



export const reportMapping = {
    'location': {
        'type-1': {
            title: 'Advance Breadcrumb Report',
            type: 'type-1',
            api: 'track/mobile/v1/allgeo/trackingreport',
            column: advanceBreadcrumbReportColumns,
            headerText: 'This report shows the location tracking history for a specified time range.  Stops at a particular location are shown in one row with total duration',
            description: 'Device Name, Device ID, Date, Time, Lat/Lon, Site Status, Address, Accuracy Feet [+,-], Hours',
            reportParams: {
                advance: 'yes'
            },
            defaultGroupedColumns: [{ field: 'deviceName' }, { field: 'deviceID' }, { field: 'txnDate' }],
            aggregates: [
                {
                    field: "elapsedTimeSec",
                    aggregate: "sum",
                }
            ],
            aggregateText: {
                "elapsedTimeSec": "Total Hours"
            },
            columnFormatMap: {
                "Hours": 'hh:mm:ss'
            }
        },
        'type-2': {
            title: 'Breadcrumb Report',
            type: 'type-2',
            api: 'track/mobile/v1/allgeo/trackingreport',
            column: breadcrumbReportColumns,
            headerText: 'This report shows  all the location tracking history for the time range.',
            description: 'Device Name, Device ID, Date, Time, Lat/Lon, Accuracy Feet [+,-], Site Status, Address, Trip #, Device Method, Event Type, Battery Level [%]',
            reportParams: {
                advance: 'no'
            },
            defaultGroupedColumns: [{ field: 'deviceName' }, { field: 'deviceID' }, { field: 'txnDate' }],
            customRow: true,
            customGroupHeader: {
                field: 'deviceName'
            }
        },
        'type-3': {
            title: 'Distance Travelled Report',
            type: 'type-3',
            api: 'track/mobile/v1/allgeo/loadDistanceData',
            column: distanceTravelledColumns,
            headerText: `Shows distance travelled when the ${employeeLabel} was on shift. Uses Google to get accurate miles calculation.`,
            description: 'Device, Device Name, Date, Distance( Miles)',
            defaultGroupedColumns: [{ field: 'deviceName' }, { field: 'deviceID' }, { field: 'tcSentDate' }]
        },
    },
    'time': {
        'type-1': {
            title: 'Standard Manual',
            type: 'type-1',
            api: 'track/mobile/v1/allgeo/timeclockstandardreport',
            column: standardManualColumns,
            headerText: 'Shows all the manual clock-ins with time-stamp and geotag. Each row shows hours associate with a particular time-clock stage.',
            description: `Name, Stage Name, Date, Time, ${jobsiteLabel} Name, ${taskLabel} Name, Manager Notes, Distance, Message, ${employeeLabel}#, ${groupLabel} Name, Latitude/Longitude, Address, Source, Company Code, ${employeeLabel} ID, ${workorderLabel} No, Modified On, Modified By, Processed, Service Time`,
            subPage: "time",
            reportParams: {
                timeclockSource: 'manual',
                reportSource: 'runReport '
            },
            defaultGroupedColumns: [{ field: 'deviceName' }, { field: 'deviceID' }, { field: 'tcSentDate' }],
            aggregates: [
                {
                    field: "serviceSeconds",
                    aggregate: "sum",
                },
                {
                    field: "distanceTravelled",
                    aggregate: "sum",
                },
            ],
            aggregateText: {
                "serviceSeconds": "Time Total",
                "distanceTravelled": "Distance Total"
            },
            columnFormatMap: {
                "Service Time": 'hh:mm:ss'
            }
        },
        'type-2': {
            title: 'Basic Manual',
            type: 'type-2',
            api: '/track/mobile/v1/allgeo/basicManualReport',
            column: advanceTimeClockColumns,
            headerText: 'Shows the Start Day and End Day along with the Total Hours and Miles.',
            description: `Device-ID, ${jobsiteLabel} Name, Start Day Address, Start Date, Start Time, End Day Address, End Date, End Time, Manager Notes`,
            subPage: "time",
            reportParams: {
                isV3Report: true,
            },
            defaultGroupedColumns: [{ field: 'deviceName' }, { field: 'deviceId' }],
            aggregates: [
                {
                    field: "serviceSeconds",
                    aggregate: "sum",
                },
                {
                    field: "serviceRate",
                    aggregate: "sum",
                },
                {
                    field: "segmentMiles",
                    aggregate: "sum",
                },
            ],
            aggregateText: {
                "serviceSeconds": "Total Service Hours",
                "serviceRate": "Total Service Rate",
                "segmentMiles": "Total Segment Miles"
            },
            columnFormatMap: {
                "Service Time": 'hh:mm:ss'
            }
        },
        'type-3': {
            title: 'Basic Automatic',
            type: 'type-3',
            api: '/track/mobile/v1/allgeo/evvcustomtimeclockreport',
            column: basicAutomaticColumns,
            headerText: 'Shows all the automatic arrive and depart from the geofence with time-stamp and location.',
            description: `Device-ID, ${jobsiteLabel} Name, Start Day Address, Start Date, Start Time, End Day Address, End Date, End Time, Manager Notes`,
            subPage: "time",
            reportParams: {
                timeclockSource: 'auto',
                stageid: '99,99,false,none,auto'
            },
            defaultGroupedColumns: [{ field: 'deviceName' }, { field: 'deviceID' }],
            aggregates: [
                {
                    field: "serviceSeconds",
                    aggregate: "sum",
                },
                {
                    field: "overTimeServiceRate",
                    aggregate: "sum",
                },
                {
                    field: "googleDistance",
                    aggregate: "sum",
                },
            ],
            aggregateText: {
                "serviceSeconds": "Total Service Hours",
                "overTimeServiceRate": "Total Service Rate",
                "googleDistance": "Total Segment Miles"
            },
            columnFormatMap: {
                "Service Time": 'hh:mm:ss'
            }
        },
    },
    'fieldData': {
        'type-1': {
            title: 'Message Report',
            type: 'type-1',
            api: '/track/mobile/v1/allgeo/messagereport',
            column: messageReport,
            headerText: 'Report shows data recorded in the field alongside the address tag and time-stamp. Each of the data type is separately listed i.e. Pictures, Scans, Notes, Forms',
            description: 'Phone Number, To, Address, Name, TxN Type, Message, Notes, Form Data, Time, Geo Tag',
            defaultGroupedColumns: [{ field: 'displayName' }, { field: 'phoneNumber' }]
        },
        'type-2': {
            title: 'Barcode Message Report',
            type: 'type-2',
            api: '/track/mobile/v1/allgeo/barcodemessagereport',
            column: barcodeMessageReport,
            headerText: 'Shows Barcode scans, address where the scan took place and start time and end time (If scan is accompanied with a job-in/job-out).',
            description: `Carrier, Device ID, Device Name, App Active, Barcode Scan, Scans, ${taskLabel}, Start Time, Stop Time, GPS Address, Message`,
            reportParams: {
                reportFormat: 'html',
                groupID: "all",
                formOption: "all"
            },
            dynamicReportParams: true,
            defaultGroupedColumns: [{ field: 'deviceName' }, { field: 'deviceID' }],
        },
        'type-3': {
            title: 'Custom Form Data Report',
            type: 'type-3',
            api: '/track/mobile/v1/allgeo/customFormDataReport',
            column: customFormDataReport,
            headerText: 'Report shows data from a mobile form. All the form fields are shown in a table format. Report also shows the time between start-of-job and end-of-job.',
            description: 'Report DateTime, Name, Device ID, Location, Service Hours',
            reportParams: {
                reportFormat: 'html',
                groupID: "all",
                formOption: "all"
            },
            dynamicReportParams: true,
            disabled: true
        },
    },
    'workOrder': {
        'type-1': {
            title: `${workorderLabel} Performance`,
            type: 'type-1',
            api: '/track/mobile/v1/allgeo/workorderreport',
            column: workOrderPerformanceColumn,
            headerText: `This report shows data related to all  ${workorderLabel} and helps you analyze completion times, pinpoint efficiency gaps, and optimize scheduling.`,
            description: `${employeeLabel} Name, ${workorderLabel} Assigned, Scheduled Site, Start Time, End Time, Allotted Duration, Message, Visisted Site, CheckIn Time, CheckOut Time, Service Duration, Variance, % Variance +/-`,
            subPage: "workOrderPerformance",
            reportParams: {
                reportFormat: 'html',
                groupID: "all",
                formOption: "all",
                workorderType: "all"
            },
            dynamicReportParams: true,
            defaultGroupedColumns: [{ field: 'employeeName' }, { field: 'woDate' }],
        },
        'type-2': {
            title: `${workorderLabel} Performance (Per Shift)`,
            type: 'type-2',
            api: '/track/mobile/v1/allgeo/workorderPerformanceShift',
            column: workOrderPerformanceColumn,
            headerText: 'This report shows data related to all work orders per shift and helps you analyze completion times, pinpoint efficiency gaps, and optimize scheduling.',
            description: `${employeeLabel} Name, ${workorderLabel} Assigned, Scheduled Site, Start Time, End Time, Allotted Duration, Message, Visisted Site, CheckIn Time, CheckOut Time, Service Duration, Variance, % Variance +/-`,
            reportParams: {
                reportFormat: 'html',
                groupID: "all",
                formOption: "all"
            },
            dynamicReportParams: true,
            defaultGroupedColumns: [{ field: 'employeeName' }, { field: 'woDate' }],

        },
        'type-3': {
            title: `${workorderLabel} Summary`,
            type: 'type-3',
            api: '/track/mobile/v1/allgeo/workordersummery',
            column: workOrderSummaryColumn,
            headerText: 'This report shows you the summary of all your work orders and gives you an overview of missed and completed work orders among the total scheduled work orders.',
            description: 'Total Scheduled, Total Visits, Missed Services',
            reportParams: {
                reportFormat: 'html',
                groupID: "all",
                formOption: "all"
            },
            dynamicReportParams: true
        },
    },
    'accounts': {
        'type-1': {
            title: 'Device List Report',
            type: 'type-1',
            api: '/track/mobile/v1/allgeo/deviceListReport',
            column: DeviceListReportColumnName,
            description: DEVICE_LIST_REPORT_DESCRIPTION,
            subPage: "accounts",
            reportParams: {
                isV3Report: true
            },
            dynamicReportParams: true,
            filterType: FILTER_TYPE.GROUP_ONLY,
            groupList: true,
            dropdown: false,
            reportapikey: "default" // this key is used to map allowed response from api
        },
        'type-2': {
            title: 'HeartBeat Report',
            type: 'type-2',
            api: '/track/mobile/v1/allgeo/heartBeatReport',
            column: HeartBeatReportColumnName,
            description: HEART_BEAT_REPORT_DESCRIPTION,
            reportParams: {
                isV3Report: true
            },
            dynamicReportParams: true,
            filterType: FILTER_TYPE.GROUP_ONLY,
            groupList: true,
            dropdown: true,
            dropdowndata: HEART_BEAT_REPORT_DROPDOWN,
            reportapikey: "heartbeatreport",
            defaultGroupedColumns: [{ field: 'deviceName' }],
        },
        'type-3': {
            title: 'Payroll Device List Report',
            type: 'type-3',
            api: '/track/mobile/v1/allgeo/payRollDeviceListData',
            column: payRollDeviceList,
            description: "",
            reportParams: {
                reportFormat: 'html',
                groupID: "all",
                formOption: "all"
            },
            dynamicReportParams: true,
            filterType: FILTER_TYPE.GROUP_ONLY,
            groupList: true,
            reportapikey: "default" // this key is used to map allowed response from api
        },
        'type-4': {
            title: 'Device Audit Report',
            type: 'type-4',
            api: '/track/mobile/v1/allgeo/deviceAuditEmployeeBasedJSON',
            column: workOrderPerformanceColumn,
            description: 'DeviceAudit: Date,Begin Count,Created,Deleted,Net,End Count',
            description1: 'Device Audit Data: Account ID,Device ID,Name,Action,Terms & Condtions,Privacy,SMS Terms,Date',
            subPage: "",
            reportParams: {
                isV3Report: true
            },
            dynamicReportParams: true,
            filterType: FILTER_TYPE.GROUP_ONLY,
            groupList: true,
            dropdown: true,
            dropdown2: true,
            dropdowndata: ActionDropDown,
            dropdowndata2: AccountIDDropDown,
            dropdownName: 'Action',
            dropdownName1: 'AccountID',
            reportapikey: "default" // this key is used to map allowed response from api
        },
    },
    'debugLogs': {
        'type-1': {
            title: 'Audit Report for API (allGeo)',
            type: 'type-1',
            api: '/track/mobile/v1/allgeo/ADPTimeCardAuditReport',
            column: ADPTimeCard,
            description: ADP_TIME_CARD,
            subPage: "",
            reportParams: {
                isV3Report: true,
            },
            dynamicReportParams: true,
            dropdown: true,
            dropdowndata: AUDIT_REPORTS_FOR_API_DROPDOWN,
            filterType: FILTER_TYPE.BOTH,
            mainReportType: "debugLogs",
            reportapikey: "adptimecardaudit"
        },
        'type-2': {
            title: 'Error Detail Report',
            type: 'type-2',
            api: '/track/mobile/v1/allgeo/errorDetailReport',
            column: '',
            description: `Time,Service,${taskLabel},Status,Details,Comment`,
            subPage: "",
            reportParams: {
                isV3Report: true
            },
            dynamicReportParams: true,
            dropdown: false,
            filterType: FILTER_TYPE.BOTH,
            mainReportType: "debugLogs",
            reportapikey: "errordetail"
        },
        'type-3': {
            title: 'Edit History Report',
            type: 'type-3',
            api: '/track/mobile/v1/allgeo/getCrudTransactionAuditData',
            column: editHistoryReportColumnsName,
            description: EDIT_HISTORY_REPORT_DESCRIPTION,
            subPage: "",
            reportParams: {
                isV3Report: true
            },
            dynamicReportParams: true,
            dropdown: false,
            filterType: FILTER_TYPE.NONE,
            mainReportType: "debugLogs",
            reportapikey: "edit_history_report",
            isEditHistoryReport : true
        },
    },
    'customReport': {
        'type-1': {
            title: 'ADP Paydata Report',
            type: 'type-1',
            api: 'track/mobile/v1/allgeo/ADPPayrollReportJSON',
            column: ADPPaydataStandardColumnName,
            description: 'Company Code, Batch ID,File #, Payroll Name, Home State, Rate, Temp State Code, Total Hours, Reg Hours, O/T Hours, Memo Code, Memo Amount, Hours 3 Code, Hours 3 Amount,Earnings 3 Code,Earnings 3 Amount,Hours 3 Code,Hours 3 Amount,Hours 3 Code',
            subPage: "",
            reportParams: {
                isV3Report: true
            },
            dynamicReportParams: true,
            dropdown: true,
            dropdowndata: ADMIN_ADP_PAYLOAD_DROPDOWN,
            filterType: FILTER_TYPE.GROUP_ONLY,
            mainReportType: "customADP",
            reportapikey: "adppayroll" // this key is used to map allowed response from api
        },
        'type-2': {
            title: 'SOS Details Report',
            type: 'type-2',
            api: 'track/mobile/v1/allgeo/sosDetailsReportJSON',
            column: sosDetailedReportColumnName,
            description: SOS_DETAIL_REPORT_DESCRIPTION,
            subPage: "",
            reportParams: {
                isV3Report: true
            },
            dynamicReportParams: true,
            dropdown: false,
            filterType: FILTER_TYPE.BOTH,
            mainReportType: "customADP",
            reportapikey: "sosdetailsreport" // this key is used to map allowed response from api
        },
        'type-3': {
            title: 'ADP Earning Code Payroll',
            type: 'type-3',
            api: 'track/mobile/v1/allgeo/populateAdpEarningCodePayrollJson',
            column: ADPEarningCodePayrollReportColumnName,
            description: EARNING_CODE_PAYROLL_DESCRIPTION,
            subPage: "",
            reportParams: {
                isV3Report: true
            },
            dynamicReportParams: true,
            dropdown: false,
            filterType: FILTER_TYPE.GROUP_ONLY,
            mainReportType: "customADP",
            reportapikey: "adpearningcodepayroll" // this key is used to map allowed response from api
        },
        'type-4': {
            title: 'ADP Timecard Report',
            type: 'type-4',
            api: 'track/mobile/v1/allgeo/adpTimeCardReport',
            column: ADPTimeCardReportColumnName,
            description: ADP_TIMECARD_REPORT_DESCRIPTION,
            subPage: "",
            reportParams: {
                isV3Report: true
            },
            dynamicReportParams: true,
            dropdown: false,
            filterType: FILTER_TYPE.GROUP_ONLY,
            mainReportType: "customADP",
            reportapikey: "adptimecardsreport" // this key is used to map allowed response from api
        },
        'type-5': {
            title: 'Active Devices Report',
            type: 'type-5',
            api: 'track/mobile/v1/allgeo/activeDeviceJSON',
            column: ActiveDeviceReportColumnName,
            description: ACTIVE_DEVICE_REPORT_DESCRIPTION,
            subPage: "",
            reportParams: {
                radiusString: ""
            },
            dynamicReportParams: true,
            dropdown: false,
            filterType: FILTER_TYPE.GROUP_ONLY,
            mainReportType: "customADP",
            reportapikey: "default" // this key is used to map allowed response from api
        },
        'type-6': {
            title: 'First in - Last Out Site',
            type: 'type-6',
            api: 'track/mobile/v1/allgeo/firstInLastOutReport',
            column: firstInLastOutColumnName,
            description: FIRSTIN_LASTOUT_REPORT_DESCRIPTION,
            subPage: "",
            reportParams: {
                isV3Report: true
            },
            dynamicReportParams: true,
            filterType: FILTER_TYPE.BOTH,
            dropdown: false,
            mainReportType: "customADP",
            reportapikey: "firstinlastoutsite"
        },
        'type-7': {
            title: `Paydata with ${taskLabel} ( PHC )`,
            type: 'type-7',
            api: 'track/mobile/v1/allgeo/parcPaydataCustomReport',
            column: "",
            description: PARC_REPORT_DESCRIPTION,
            subPage: "",
            reportParams: {
                isV3Report: true
            },
            dynamicReportParams: true,
            filterType: FILTER_TYPE.GROUP_ONLY,
            dropdown: false,
            mainReportType: "customADP",
            reportapikey: "parcpaydatareport"
        },
    }

}

export const customExportFunction = (gridRef, exportRef, selectedReport) => {
    const { _data: gridData } = gridRef;
    const { workbookOptions, save } = exportRef;
    const options = workbookOptions();

    const groupFooterRowsMap = {};

    let { columnFormatMap, aggregateText, column } = selectedReport
    let parcHeaders = JSON.parse(sessionStorage.getItem("parcHeaders"));

    if (selectedReport.title === `Paydata with ${taskLabel} ( PHC )`) {
        column = parcHeaders
    }

    if (columnFormatMap) {
        const rows = options.sheets[0].rows;
        const headers = rows[0].cells;

        const fieldColumnIndexMap = {};
        let imgAttach;
        let barCodeAttach;
        let formAttach;
        let messageText;

        column.forEach(col => {
            const colInd = headers.findIndex(h => h.value === col.title);

            if (colInd > -1) {
                fieldColumnIndexMap[col.field] = colInd;
            }
            imgAttach = fieldColumnIndexMap.imgAttach;
            barCodeAttach = fieldColumnIndexMap.barCodeAttach;
            formAttach = fieldColumnIndexMap.formAttach;
            messageText = fieldColumnIndexMap.messageText;
        });

        const indexFormatMap = {};

        headers.forEach((cell, ind) => {
            if (columnFormatMap[cell.value]) {
                indexFormatMap[ind] = columnFormatMap[cell.value];
            }
        });

        if (Object.keys(indexFormatMap).length > 0) {
            rows.forEach((row) => {
                if (row.type === "data") {
                    row.cells.forEach((cell, ind) => {
                        if (indexFormatMap[ind] && indexFormatMap[ind] === 'hh:mm:ss') {
                            cell.value = secondsToHHMMSS(cell.value);
                        }
                        if (ind === imgAttach && cell.value) {
                            // Regular expression to match URLs
                            const urlRegex = /(https?:\/\/[^\s]+)/;
                            // Extract the URL using the regex
                            const match = cell.value.match(urlRegex);
                            // Check if a match is found and get the URL from the captured group
                            const url = match ? match[1] : null;
                            if (url) {
                                const formula = `=HYPERLINK("${url}", "image Link")`;
                                cell.formula = formula;
                                cell.color = "[Blue]";
                            }
                        }

                        if (ind === barCodeAttach && cell.value) {
                            // Regular expression to match URLs
                            const urlRegex = /(https?:\/\/[^\s]+)/;
                            // Extract the URL using the regex
                            const match = cell.value.match(urlRegex);
                            // Check if a match is found and get the URL from the captured group
                            const url = match ? match[1] : null;
                            if (url) {
                                const formula = `=HYPERLINK("${url}", "BarCode Link")`;
                                cell.formula = formula;
                                cell.color = "[Blue]";
                            }
                        }
                        if (ind === formAttach && cell.value) {
                            // Regular expression to match URLs
                            const urlRegex = /(https?:\/\/[^\s]+)/;
                            // Extract the URL using the regex
                            const match = cell.value.match(urlRegex);
                            // Check if a match is found and get the URL from the captured group
                            const url = match ? match[1] : null;
                            if (url) {
                                const formula = `=HYPERLINK("${url}", "formImage Link")`;
                                cell.formula = formula;
                                cell.color = "[Blue]";
                            }
                        }
                        // Add this block to modify the messageText
                        if (ind === messageText && cell.value) {
                            cell.value = cell.value.replace('#', ',');
                        }
                    });
                }
            });
        }

        gridData.forEach((row, ind) => {
            if (row.rowType === 'groupFooter') {
                const footerRowData = { type: 'group-footer', cells: headers.map(() => ({ value: '' })) };

                Object.keys(row.dataItem.aggregates).forEach(aggregate => {
                    let cellValue = row.dataItem.aggregates[aggregate].sum;

                    if (indexFormatMap[fieldColumnIndexMap[aggregate]] && indexFormatMap[fieldColumnIndexMap[aggregate]] === 'hh:mm:ss') {
                        cellValue = secondsToHHMMSS(cellValue);
                    }

                    footerRowData.cells[fieldColumnIndexMap[aggregate]] = {
                        background: "#dfdfdf",
                        color: "#333",
                        value: aggregateText[aggregate] + ": " + cellValue
                    };
                });

                groupFooterRowsMap[ind + 1] = footerRowData;
            }
        });
    } else {
        const rows = options.sheets[0].rows;
        const headers = rows[0].cells

        const fieldColumnIndexMap = {}
        let imageIndex;
        let QRindex;
        let formDataIndex;
        let qrAttachedlinksIndex;
        let qrAttachmentIndexArrayStart = []
        const insertedStartColumns = [];

        column.forEach(col => {
            const colInd = headers.findIndex(h => h.value === col.title);
            if (colInd > -1) {
                fieldColumnIndexMap[col.field] = colInd;
            }
            imageIndex = fieldColumnIndexMap.picture
            QRindex = fieldColumnIndexMap.qrScan
            formDataIndex = fieldColumnIndexMap.formData
            qrAttachedlinksIndex = fieldColumnIndexMap.qrScanLink
        })

        rows?.forEach((row) => {
            let colInd

            if (row.type === "data") {
                row.cells.forEach((cell, ind) => {

                    if (ind === qrAttachedlinksIndex && cell.value) {
                        const urlArray = cell.value.split("#") || []
                        const urlArrayLength = urlArray.length;

                        if (urlArrayLength > 1) {
                            urlArray.forEach((inner_url, index) => {

                                if (index == 0) {
                                    const formula = `=HYPERLINK("${inner_url}", "Attachment Link")`;
                                    cell.formula = formula;
                                    cell.color = "[Blue]";

                                } else {

                                    // Check if the column has already been inserted in this row
                                    if (!insertedStartColumns.includes(`QrScan Attachment Link ${index}`)) {
                                        rows[0].cells.push({
                                            background: "#7a7a7a",
                                            colSpan: 1,
                                            color: "#fff",
                                            firstCell: true,
                                            rowSpan: 1,
                                            value: `QrScan Attachment Link ${index}`
                                        });

                                        insertedStartColumns.push(`QrScan Attachment Link ${index}`);
                                    }

                                    colInd = rows[0].cells.findIndex(
                                        (h) => h.value === `QrScan Attachment Link ${index}`
                                    );

                                    // Check if colInd is not already in attachmentIndexArrayEnd
                                    if (qrAttachmentIndexArrayStart.indexOf(colInd) === -1) {
                                        qrAttachmentIndexArrayStart.push(colInd)
                                    }

                                    row.cells[colInd] = {
                                        value: inner_url
                                    }
                                }

                            })

                        } else {
                            const formula = `=HYPERLINK("${cell.value}", "Attachment Link")`;
                            cell.formula = formula;
                            cell.color = "[Blue]";

                        }
                    }

                })
            }
        });

        rows?.forEach((row) => {
            if (row.type === "data") {
                row.cells.forEach((cell, ind) => {

                    if (qrAttachmentIndexArrayStart.includes(ind)) {
                        const formula = `=HYPERLINK("${cell.value}", "Attachment Link")`;
                        cell.formula = formula;
                        cell.color = "[Blue]";
                    }
                })
            }
        });

        rows?.forEach((row) => {
            if (row.type === "data") {
                row.cells.forEach((cell, ind) => {
                    if (ind === imageIndex && cell.value) {
                        // Regular expression to match URLs
                        const urlRegex = /href="([^"]+)"/;
                        // Extract the URL using the regex
                        const match = cell.value.match(urlRegex);
                        // Check if a match is found and get the URL from the captured group
                        const url = match ? match[1] : null;
                        if (url) {
                            const formula = `=HYPERLINK("${url}", "Image Link")`;
                            cell.formula = formula;
                            cell.color = "[Blue]";
                        }
                    }

                    if (ind === QRindex && cell.value) {
                        // Regular expression to match URLs
                        const urlRegex = /(https?:\/\/[^\s]+)/;
                        // Extract the URL using the regex
                        const match = cell.value.match(urlRegex);
                        // Check if a match is found and get the URL from the captured group
                        const url = match ? match[1] : null;
                        if (url) {
                            const formula = `=HYPERLINK("${url}", "QR Link")`;
                            cell.formula = formula;
                            cell.color = "[Blue]";
                        }
                    }
                    if (ind === formDataIndex && cell.value) {
                        const urlRegex = /href="([^"]+)"/;
                        // Extract the URL using the regex
                        const match = cell.value.match(urlRegex);
                        const url = match ? match[1] : null;

                        if (url) {
                            const formula = `=HYPERLINK("${url}", "FormData Link")`;
                            cell.formula = formula;
                            cell.color = "[Blue]";
                        }
                    }
                });
            }
        });
    }

    // Adding Group footer row
    const modifiedRows = [];

    if (Object.keys(groupFooterRowsMap).length > 0) {
        const tempRows = [...options.sheets[0].rows];

        let i = 0;

        while (modifiedRows.length !== tempRows.length + Object.keys(groupFooterRowsMap).length) {
            if (groupFooterRowsMap[modifiedRows.length]) {
                modifiedRows.push(groupFooterRowsMap[modifiedRows.length]);
                continue;
            } else {
                modifiedRows.push(tempRows[i]);
                i++;
            }
        }
    }

    if (modifiedRows.length > 0) {
        options.sheets[0].rows = modifiedRows;
    }

    save(options)
}


export const exportToCSVforPARC = (headers = [], data = [], filename) => {
    const csvRows = [];

    // Check if headers are provided
    if (headers.length > 0) {
        // Adding headers
        const headerTitles = headers.map(header => header.title);
        csvRows.push(headerTitles.join(','));
    } else {
        csvRows.push('No headers available');
    }

    // Checking if data is provided
    if (data.length > 0) {
        // Looping over the rows for mapping
        data.forEach(row => {
            const values = headers.map(header => {
                const value = row[header.field] || '';
                const escaped = ('' + value).replace(/"/g, '""');
                return `"${escaped}"`;
            });
            csvRows.push(values.join(','));
        });
    } else if (headers.length > 0) {
        // If headers are available but no data
        csvRows.push('No data available');
    }

    const csvString = csvRows.join('\n');

    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${filename}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const exportToEXCELforPARC = (exportRef, headers, apiResponse) => {
    const { workbookOptions, save } = exportRef;
    const options = workbookOptions();

    const excelRows = [];

    if (headers && headers.length > 0) {
        // Adding headers of excel sheet here 
        const headerRow = {
            cells: headers.map(header => ({ value: header.title, background: "#dfdfdf", color: "#333" }))
        };
        excelRows.push(headerRow);

        // Adding data rows from API response
        if (apiResponse && apiResponse.length > 0) {
            apiResponse.map(dataItem => {
                const dataRow = {
                    cells: headers.map(header => ({ value: dataItem[header.field] || '' }))
                };
                excelRows.push(dataRow);
            });
        } else {
            // No data rows, just add headers in excel sheet
            console.log("No data rows available in the API response");
        }

    } else {
        // No headers provided, created an empty sheet
        console.log("No headers available, creating an empty sheet");
    }

    options.sheets[0].rows = excelRows;

    save(options);
}
