import React, { useEffect, useState } from 'react'
import { apiProvider } from '../../../services/api/provider'
import FileUploadErrorMsg from './FileUploadErrorMsg'
import { getFileData } from '../functions'

const FileUploadFail = ({ data: { file, validColumns = [], misMatchedColumns = [], uploadUrl = '', successMsg, errorMsg, validationError,selectedSample }, closePopup, setToastMsg, setShowLoader, isUploadSuccessfull, fileType }) => {
    var XLSX = require("xlsx")
    const [fileData, setFileData] = useState([])
    const [headers, setHeaders] = useState([])
    const [showError, setShowError] = useState(validationError)
    const [mismatchHeader,setMismatchHeader]=useState({})
    const [isDisabled,setIsDisabled]=useState(false)


useEffect(()=>{
    let  mistmachCol={}
    misMatchedColumns?.map((val)=>(mistmachCol[val]=""))

    setMismatchHeader(mistmachCol)
},[misMatchedColumns])

console.log(mismatchHeader,"mistmachCol")

    const uploadFile = async () => {
        if (file.name.includes('csv')) {
            try {
                setShowLoader(true)
                const fileCsv = selectedSample === "sample2"?file:prepareCsvFile()


                if (fileCsv === null) {
                    setShowError(true)
                    return
                }

                const formData = new FormData()
                formData.append('file', fileCsv, 'task_details.csv')
                if(selectedSample==="sample2"&&Object.keys(mismatchHeader).length>0&&!Array.isArray(mismatchHeader) ){
                    const blob = new Blob([JSON.stringify(mismatchHeader, null, 2)], {
                        type: "application/json",
                      });
                    formData.append("headersMapping",blob)
                }
                const response = await apiProvider.formPost(uploadUrl, formData);
                console.log('response', response)
                if (selectedSample === "sample2" && response?.status === '422'&& response?.unknownColumn?.length>0) {
                    if (isUploadSuccessfull) isUploadSuccessfull(false)
                    setToastMsg({ msg: 'column mismatch', type: "msgError" })
                } 
                 else if (response?.failed?.length === 0 && selectedSample === "sample2" ) {
                     setToastMsg({ msg: 'All data is uploaded', type: "msgSuccess" });
                     if (isUploadSuccessfull) isUploadSuccessfull(true)
                     closePopup()
                } 
                else if (response?.failed?.length > 0 && selectedSample === "sample2" ) {
                    setToastMsg({ msg: response.failed[0], type: "msgError" })
                    if (isUploadSuccessfull) isUploadSuccessfull(false)
                }      
                else if (response.status === 200) {
                    setToastMsg({ msg: successMsg || response.message, type: "msgSuccess" })
                    if (isUploadSuccessfull) isUploadSuccessfull(true)
                        closePopup()
                } else {

                    setToastMsg({ msg: response.message ?? response.ErrorMsg, type: "msgError" })
                    if (isUploadSuccessfull) isUploadSuccessfull(false)
                }
            } catch (error) {
                console.log(error);
            } finally {
                setShowLoader(false)
            }
        }
        else {
            try {
                setShowLoader(true)
                
                const fileXlsx =selectedSample === "sample2"?file: prepareXlsxFile(fileData)
                // const fileXlsx= prepareXlsxFile(fileData)
                if (fileXlsx === null) {
                    setShowError(true)
                    return
                }

                const formData = new FormData()
                formData.append('file', fileXlsx, 'task_harsh_details.xlsx')
                if(selectedSample==="sample2"&&Object.keys(mismatchHeader).length>0&&!Array.isArray(mismatchHeader) ){
                    const blob = new Blob([JSON.stringify(mismatchHeader, null, 2)], {
                        type: "application/json",
                      });
                    formData.append("headersMapping",blob)
                }
                const response = await apiProvider.formPost(uploadUrl, formData);
                if (selectedSample === "sample2" && response?.status === '422'&& response?.unknownColumn?.length>0) {
                    if (isUploadSuccessfull) isUploadSuccessfull(false)
                    setToastMsg({ msg: 'column mismatch', type: "msgError" })
                } 
                 else if (response?.failed?.length === 0 && selectedSample === "sample2" ) {
                     setToastMsg({ msg: 'All data is uploaded', type: "msgSuccess" });
                     if (isUploadSuccessfull) isUploadSuccessfull(true)
                     closePopup()
                }
                else if (response?.failed?.length > 0 && selectedSample === "sample2" ) {
                    setToastMsg({ msg: response.failed[0], type: "msgError" })
                    if (isUploadSuccessfull) isUploadSuccessfull(false)
                }     
                else if (response.status === 200) {
                    setToastMsg({ msg: successMsg || response.message, type: "msgSuccess" })
                    if (isUploadSuccessfull) isUploadSuccessfull(true)
                    closePopup()
                } else {
                    setToastMsg({ msg: response.message ?? response.ErrorMsg, type: "msgError" })
                    if (isUploadSuccessfull) isUploadSuccessfull(false)
                }
            } catch (error) {
                console.log(error);
            } finally {
                setShowLoader(false)
            }
        }

    }

    const updateMismatchedColumns = (e, misMatchedCol) => {
        if (file.name.includes('csv')) {
            try {
                const { value } = e.target;
                setMismatchHeader(prevMismatchHeader => ({
                    ...prevMismatchHeader,
                    [misMatchedCol]: value
                }));
                const isOptionAlreadySelected = Object.values(mismatchHeader).includes(value);
                if (!!isOptionAlreadySelected) {
                    setToastMsg({ msg: "Option already selected for another column", type: "msgError" })
                    setIsDisabled(true)
                    return;
                }
                const tempData = [...fileData]
                const regex = /[^a-zA-Z0-9\s]/g;
                const ind = headers.findIndex(col => col === misMatchedCol.replace(regex, ''))
                setIsDisabled(false)
                if (ind > -1) {
                    tempData[0][ind] = value
                    setFileData(tempData)
                }
            } catch (err) {
                console.log(err);
            }
        }
        else {
            try {
                const { value } = e.target;
                setMismatchHeader({...mismatchHeader,[misMatchedCol]:value})
                let tempData = [...fileData]
                const regex = /[^a-zA-Z0-9\s]/g;
                let newTempData = tempData[0]
                const mainHeader = headers[0]
                const ind = mainHeader.findIndex(col => col === misMatchedCol.replace(regex, ''))
                console.log(newTempData,"newTempData")
                if (ind > -1) {
                    newTempData[0][ind] = value
                    setFileData(newTempData)
                }
            } catch (err) {
                console.log(err);
            }

        }

    }

    const prepareCsvFile = () => {
        const csvContent = "text/csv"
        let csv = '';

        for (let i = 0; i < fileData.length; i++) {
            const rowData = fileData[i];

            const dataString = rowData.join(",");
            csv += i < fileData.length ? dataString + "\n" : dataString
        }

        return new Blob([csv], { type: csvContent })
    }
    const prepareXlsxFile = (fileData) => {
       
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(fileData);
    
        
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    
       
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        return blob;
    };

    const closeErrorPopup = () => {
        setShowError(false)
        closePopup()
    }

    useEffect(() => {
        getFileData(file).then(res => {
            setHeaders([...res[0]])
            setFileData(res)
        })
    }, [])



    return (
        <>
            {
                !showError
                    ?
                    <div className="popupWrap smlMedPopup active">
                        <div className="closePopup" onClick={closePopup} >Close</div>
                        <div className="popupHeading">Bulk Upload</div>
                        <div className="popupContent">
                            <label className="alignCenter">The CSV upload has unmatched columns. Match them below:</label>
                            <div className="empSelectBlk">
                                <div className="empListTbl tbl2">
                                    <table cellPadding="0" cellSpacing="0">
                                        <tbody>
                                            <tr>
                                                <th>Unmatched Columns</th>
                                                <th>Select Data Fields</th>
                                            </tr>
                                            {
                                                misMatchedColumns.map((misMatchedCol, ind) => (
                                                    <tr key={ind} >
                                                        <td>{misMatchedCol}</td>
                                                        <td>
                                                            <select onChange={e => updateMismatchedColumns(e, misMatchedCol)} >
                                                                <option disabled selected hidden>Select</option>
                                                                {
                                                                    validColumns.map((col, ind) => (
                                                                        <option key={ind} value={col} >{col}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="popupBtnWrap flexJCenter">
                            <button className="btnStyle okBtn" disabled={isDisabled} onClick={uploadFile}  >Submit</button>
                        </div>
                    </div>
                    :
                    <FileUploadErrorMsg errorMsg={errorMsg} closeErrorPopup={closeErrorPopup} />
            }

        </>
    )
}

export default FileUploadFail