import { GoogleLogin } from "react-google-login";
import React, { useState, useContext, Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import AuthContext from "../../store/auth-context";
import ToastMessage from "../common/ToastMessage";

import axios from "axios";

const GoogleLoginComponent = () => {
  const [isLoginError, setIsLoginError] = useState(false);
  const [loginErrorMsg, setLoginErrorMsg] = useState("");

  const authCtx = useContext(AuthContext);
  const history = useHistory();
  const handleLogin = async (googleData) => {
    let tokenId = decodeURIComponent(googleData.code) || "";
    if (tokenId && tokenId !== "") {
      let data = {
        authToken: tokenId,
        source:"REACT_SSO"
      };
      axios
        .post(process.env.REACT_APP_GOOGLE_LOGIN_API, data, { headers: { "Content-Type": "application/json" } })
        .then((response) => {
          console.log(response);
          switch (response.data.StatusCode) {
            case 200:   
              localStorage.setItem('isNewMonitorPage',response?.data?.isNewMonitorPage)

              localStorage.setItem('employeeAppData',response?.data?.monitorCards?.employeeAppData)
              localStorage.setItem('mapAndLocation',response?.data?.monitorCards?.mapAndLocation)
              localStorage.setItem('milesData',response?.data?.monitorCards?.milesData)
              localStorage.setItem('notificationsAndAlerts',response?.data?.monitorCards?.notificationsAndAlerts)
              localStorage.setItem('timeClockData',response?.data?.monitorCards?.timeClockData)
              localStorage.setItem('timeClockReports',response?.data?.monitorCards?.timeClockReports)
              
              
              const expirationTime = new Date(
                new Date().getTime() + +response.data.expiry
              );
              authCtx.login(
                response.data.token,
                response.data.token_en,
                expirationTime.toISOString(),
                response.data.is_parent,
                response.data.accountId,
                response.data.userName,
                response.data.isBannerEnabled, 
                response.data.bannerText,
                response.data.userSecurityType, 
                response.data.actSecurityType,
                response.data.landingPage,
                response.data.hasG2Reviewed,
              );
              localStorage.setItem('isOnBoarding',response.isOnBoarding);
              history.replace("/");
              break;
            case 401:
              handelAuthErrorMsg(response.data.SucessMsg);
              break;
            default:
              handelAuthErrorMsg(response.data.SucessMsg);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response.data);
          handelAuthErrorMsg("Unable to login.");
        });
    }
  };
  const handleFailure = async (data) => {
    console.log(data);
  }

  const handelAuthErrorMsg = (serverMessage) => {
    let msg = <>{serverMessage}</>;
    setIsLoginError(true);
    if (serverMessage == "Invalid Credentials") {
      msg = (
        <>
          Credentials are invalid. If you don't remember your password,{" "}
          <Link to="/reset-password">reset it now.</Link>
        </>
      );
    }
    if (serverMessage == "Trial Account Expired" || serverMessage == "Account associated with the email is Expired") {

      const line1Style = { 'marginBottom': '0', 'lineHeight': '10px' };
      const line2Style = { 'marginBottom': '0' };
      msg = <><p style={line1Style}>Your free trial account has expired. <a href="../pricing">Upgrade now</a>.</p> <p style={line2Style}>Feel free to reach us at <a href="mailto:support@abaq.us">support@abaq.us</a> for product or trial related queries.</p></>;
    }
    setLoginErrorMsg(msg);
  };

  const scriptFailed = () => {
    console.log('script failed');
  }

  return (
    <Fragment>
      {<GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        render={(renderProps) => (
          <button
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
            className="btnStyle btn2 iconBtn googleLogin"
          >
            Login with Google
          </button>
        )}
        buttonText="Login with Google"
        onSuccess={handleLogin}
        onFailure={handleFailure}
        onScriptLoadFailure={scriptFailed}
        accessType="offline"
        responseType="code"
        cookiePolicy={"single_host_origin"}
      />}

      {isLoginError && (
        <ToastMessage
          cssClass="msgError"
          message={loginErrorMsg}
          onClose={() => {
            setIsLoginError(false);
            setLoginErrorMsg("");
          }}
        />
      )}
    </Fragment>
  );
};
export default GoogleLoginComponent;
