import { Fragment, useState, useEffect } from 'react';
import { useForm } from "react-hook-form";

import { apiProvider } from '../../../services/api/provider';
import { ConfirmPopup2nd } from '../../common/PopUp';
import { getAccountLabel } from '../../../utils';

const fieldMapping = {
    "refID": "refId",
    "srcID": "srcId",
    "taskID": "taskId",
    "companyID": "companyId",
    "name": "taskName",
    "extSystem": "extSystem",
    "billingRate": "billingRate",
    "earningCode": "earningCode"
}

const EditTask = (props) => {

    const { register, handleSubmit, setValue, setError, reset, formState: { isDirty, errors, isValid } } = useForm({ mode: "all" });
    const [advanceSetupExpand, setAdvanceSetupExpand] = useState(false);
    const [taskDetails, setTaskDetails] = useState({});
    const [earningCode, setEarningCode] = useState([]);
    const [showDialog, setShowDialog] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (Object.keys(taskDetails).length > 0) {
            setValue('extSystem', taskDetails?.extSystem, { shouldDirty: false });
            setValue('companyId', taskDetails?.companyID, { shouldDirty: false });
            setValue('srcId', taskDetails?.srcID, { shouldDirty: false });
            setValue('refId', taskDetails?.refID, { shouldDirty: false });
            setValue('taskName', taskDetails?.name, { shouldDirty: false });
            setValue('taskId', taskDetails?.taskID, { shouldDirty: false });
            setValue('status', taskDetails?.isActive, { shouldDirty: false });
            setValue('earningCode', taskDetails?.earningCode === "" ? "None" : taskDetails?.earningCode, { shouldDirty: false });
            setValue('billingRate', taskDetails?.billingRate, { shouldDirty: false });
            setValue('payerServiceInfoId', taskDetails?.payerServiceInfoId, { shouldDirty: false });

            let earningCodeArr = taskDetails?.earningCodeMap.map((ele) => {
                return { 'selected': ele.selected, 'value': Object.keys(ele).filter(el => el !== 'selected')[0] || 'None' }
            })

            setEarningCode(earningCodeArr)
        }
    }, [taskDetails])

    const fetchData = () => {
        props.setShowLoader(true);

        apiProvider.getSingle('track/mobile/v1/allgeo/task', props.taskId).then((response) => {
            if (response.status === 200 && response.message.toLowerCase() === 'success') {
                if (response.tasks.length > 0) {
                    setTaskDetails(response.tasks[0])
                }
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            props.setShowLoader(false);
        });

    };

    const onSubmit = async (data) => {

        props.setShowLoader(true);

        try {

            let payload = [{
                "ID": props.taskId,
                "refID": data.refId,
                "srcID": data.srcId,
                "taskID": data.taskId,
                "companyID": data.companyId,
                "name": data.taskName,
                "extSystem": data.extSystem,
                "billingRate": data.billingRate,
                "isActive": data.status,
                "earningCode": data.earningCode,
                "payerServiceInfoId": data.payerServiceInfoId
            }];

            const response = await apiProvider.put("track/mobile/v1/allgeo/task", payload)
            if (response.status === 200) {
                props.setToastMsg({ msg: `${taskLabel} updated successfully`, type: "msgSuccess" });
                props.setMode({
                    title: "Manage Tasks",
                    type: "main",
                    data: null,
                    hasPrev: false
                })
            } else if (response.status === 409) {
                setError(fieldMapping[response.field], true)
            } else {
                props.setToastMsg({ msg: response.message, type: "msgError" });
            }

        } catch (err) {
            console.log(error);
        } finally {
            props.setShowLoader(false)
        }

    }

    const onSuccess = () => {
        setShowDialog(false)
        props.setMode({
            title: "Manage Tasks",
            type: 'main',
            data: null,
            hasPrev: false
        })
    }

    const onFaliure = () => {
        setShowDialog(false);
    }

    const validateInput = e => {
        const { value, name } = e.target;

        let inputValidation;

        if (name === "taskName") {
            inputValidation = /[^a-zA-Z0-9 _-]/gi
        } else {
            inputValidation = /[^a-zA-Z0-9_-]/gi
        }

        setValue(name, value.replace(inputValidation, ""));
    }

    const taskLabel = getAccountLabel('task');

    return (
        <Fragment>
            <div className="popupWrap smlPopup active">
                <div className="closePopup" onClick={() => {
                    if (isDirty) {
                        setShowDialog(true);
                    } else {
                        props.setMode({
                            title: "Manage Tasks",
                            type: 'main',
                            data: null,
                            hasPrev: false
                        })
                    }
                }}>Close</div>
                <div className="popupHeading">Edit {taskLabel}</div>
                <form onSubmit={handleSubmit(onSubmit)} id="taskForm">
                    <div className="popupContent">
                        <div className="popupTabCont">
                            <div className="fldWrap">
                                <label>Active</label>
                                <div className="customCheck yesNo">
                                    <input type="checkbox" id="activeStatus" {...register('status')} />
                                    <label className="customCheckLabel" htmlFor="activeStatus">yesNo</label>
                                </div>
                            </div>
                            <div className="rowBlk">
                                <div className={`colBlk6 fldWrap${errors.taskId ? ' errFld' : ''}`}>
                                    <label>{taskLabel} ID</label>
                                    <input type="text" maxLength={255} {...register('taskId', {
                                        onChange: validateInput
                                    })} />
                                    {errors.taskId && <span className="fldMsg">{taskLabel} ID already exists</span>}
                                </div>
                                <div className={`colBlk6 fldWrap${errors.taskName ? ' errFld' : ''}`}>
                                    <label>{taskLabel} Name</label>
                                    <input type="text" maxLength={100} {...register('taskName')} />
                                    {errors.taskName && <span className="fldMsg">{taskLabel} Name already exists</span>}
                                </div>
                            </div>
                            <div className={`collapsibleSection ${advanceSetupExpand && 'active'}`}>
                                <span onClick={() => setAdvanceSetupExpand(!advanceSetupExpand)}>Advanced Setup</span>
                            </div>
                            {advanceSetupExpand &&
                                <Fragment>
                                    <div className="rowBlk">
                                        <div className="colBlk6 fldWrap">
                                            <label>Reference ID</label>
                                            <input type="text" maxLength={50} {...register('refId', {
                                                onChange: validateInput
                                            })} />
                                        </div>
                                        <div className="colBlk6 fldWrap">
                                            <label>Source ID</label>
                                            <input type="text" maxLength={255} {...register('srcId', {
                                                onChange: validateInput
                                            })} />
                                        </div>
                                    </div>
                                    <div className="rowBlk">
                                        <div className="colBlk6 fldWrap">
                                            <label>Company ID</label>
                                            <input type="text" maxLength={50} {...register('companyId', {
                                                onChange: validateInput
                                            })} />
                                        </div>
                                        <div className="colBlk6 fldWrap">
                                            <label>Ext System</label>
                                            <input type="text" maxLength={10} {...register('extSystem', {
                                                onChange: validateInput
                                            })} />
                                        </div>
                                    </div>
                                    <div className="rowBlk">
                                        <div className="colBlk6 fldWrap">
                                            <label>Earning Code</label>
                                            <select {...register('earningCode')}>
                                                {
                                                    earningCode.map((t, ind) => (
                                                        <option key={ind} value={t.value}>{t.value}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className="colBlk6 fldWrap">
                                            <label>Billing Rate</label>
                                            <input type="text" maxLength={50} {...register('billingRate')} />
                                        </div>
                                    </div>
                                    <div className="rowBlk">
                                        <div className="colBlk6 fldWrap">
                                            <label>Payer/Insurer Information</label>
                                            <select {...register('payerServiceInfoId')} >
                                                {
                                                    props.payerData?.map((payer, ind) => (
                                                        <option key={ind} value={payer.payerServiceInfoId}>{payer.description}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </Fragment>
                            }
                        </div>
                    </div>
                    <div className="popupBtnWrap flexJCenter">
                        <button className="btnStyle okBtn" disabled={!isDirty || !isValid}>SAVE</button>
                    </div>
                </form>
            </div>
            <ConfirmPopup2nd
                showDialog={showDialog}
                message={"The changes are still unsaved. Are you sure you want to leave?"}
                confirmNavigation={onSuccess}
                cancelNavigation={onFaliure}
            />
        </Fragment>
    )
}

export default EditTask