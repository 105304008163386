import React, { useRef, useState } from "react";
import "./employeeAppData.css";
import ExpandIcon from "../../../../assets/images/expand-more.svg";
import EmployeeAppDataIcon from "../../../../assets/images/Employee and App Data.svg";

import RefreshButton from "../../../../assets/images/refresh-button.svg";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { ExpandEmployeesAppDataModal } from "./Modals/ExpandEmployeesAppDataModal";
import { handleFetchEmployeeAppData } from "../../utils/";
import SiteLoader from "../../../../components/common/SiteLoader";
import { getAccountLabel } from "../../../../utils";
import { TooltipKendo } from "../../common/Tooltip";


export const EmployeeAppData = ({
  employeeData,
  setEmployeePaginateMetaData,
  employeePaginateMetaData,
  currentTimeAndDate,
  setCurrentTimeAndDate,
  setShowExpanMore,
  setEmployeeTilesData,
}) => {
  // const [showExpanMore, setShowExpanMore] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const anchorRefs = useRef([]);

  const employeeLabel = getAccountLabel("employee");
  const grid = (
    <Grid className="employeetablediv" data={employeeData?.data}>
      <Column field="employeeName" title={`${employeeLabel} Name`} />
      <Column
        field="trackingStatus"
        title="App Status"
        cell={(props) => <StatusChip {...props} />}
      />
    </Grid>
  );

  const StatusChip = ({ dataItem, dataIndex }) => {
    const getStatusClass = (status) => {
      switch (status) {
        case "Active":
          return "table-active"; // Assuming "yes" corresponds to "Active"
        case "Inactive":
          return "table-inactive"; // Assuming "no" corresponds to "Inactive"
        case "App Not Installed":
          return "table-notinstall";
        default:
          return "table-chip-all";
      }
    };
    const [isOpen, setIsOpen] = useState(false);

    const togglePopover = (isHoverIn, index) => {
      setIsOpen(isHoverIn && (index === 0 ? true : index));
    };

    const statusClass = getStatusClass(dataItem?.appDataStatus?.appStatus);
    return (
      <td
        onMouseLeave={() => togglePopover(false, dataIndex)}
        style={{ overflow: "unset" }}
      >
        <div style={{ position: "relative", width: "fit-content" }}>
          <span
            className={`status-chip statusbtn ${statusClass}`}
            ref={(el) => (anchorRefs.current[dataIndex] = el)}
          >
            {dataItem?.appDataStatus?.appStatus !== "" ? dataItem?.appDataStatus?.appStatus : `App Active: ${dataItem?.appDataStatus?.appStatusDuration}`}
          </span>
          {dataItem?.appDataStatus?.appStatusContent && (
            <span
              onMouseEnter={() => togglePopover(true, dataIndex)}
              style={{
                fontSize: 10,
                background: "#ccc",
                borderRadius: "50%",
                padding: 4,
                marginLeft: 4,
                height: "14px",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              ?
            </span>
          )}
          <div
            id="openPopover"
            style={{
              width: 200,
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              top: 20,
              background: "white",
              zIndex: 99,
              borderRadius: 5,
              boxShadow: "0px 0px 10px lightgray",
              display: isOpen ? "block" : "none",
            }}
          >
            <div
              // onMouseLeave={() => togglePopover(false, dataIndex)}
              style={{ padding: "10px" }}
              dangerouslySetInnerHTML={{
                __html: dataItem?.appDataStatus?.appStatusContent,
              }}
            />
          </div>
        </div>
      </td>
    );
  };

  const refreshEmployeeData = () => {
    handleFetchEmployeeAppData(
      {
        ...employeePaginateMetaData,
        queryParams: {
          ...employeePaginateMetaData.queryParams,
          searchBy: "all",
          searchValue: "all",
        },
      },
      setEmployeeTilesData,
      setShowLoader,
      setCurrentTimeAndDate
    );
  };
  const localTime = currentTimeAndDate?.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  });
  const localDate = currentTimeAndDate?.getDate();
  const localMonth = currentTimeAndDate?.getMonth() + 1;
  const localYear = currentTimeAndDate?.getFullYear();
  return (
    <div className="employeeParentContainer card-padding kendoTblWrap">
      {showLoader && <SiteLoader isActive={showLoader} />}
      <div className="employeeHeadingContainer">
        <p className="employeeheadtag">
          <div style={{display:"flex"}}>
            <span style={{marginRight:"6px"}}>
              <img src={EmployeeAppDataIcon} alt="" />
            </span>
            <span style={{whiteSpace:"pre"}}>{employeeLabel} App Status</span>
          </div>
          <p  className="refreshtext borderNone">
            Last Refreshed:
            <span>{`${localMonth}/${localDate}/${localYear}`}</span>
            <span>{localTime}</span>
          </p>
        </p>
        <div className="employee-head-box">
          <TooltipKendo>
            <img
              title="Expand View"
              onClick={() => {
                setEmployeePaginateMetaData((prevState) => ({
                  ...prevState,
                  queryParams: {
                    ...prevState.queryParams,
                    searchBy: "all",
                    searchValue: "all",
                  },
                }));
                setShowExpanMore(true);
              }}
              style={{ cursor: "pointer" }}
              src={ExpandIcon}
              alt="Expand Icon"
            />
          </TooltipKendo>
          <TooltipKendo>
            <img
              title="Refresh"
              style={{ cursor: "pointer" }}
              src={RefreshButton}
              alt="Refresh Button"
              role="button"
              onClick={refreshEmployeeData}
            />
          </TooltipKendo>
        </div>
      </div>
      <div className="employeeCalculate">
        <div>
          <p>
            <span className="bigcount">{employeeData?.activeCount ?? 0}</span>
          </p>
          <p className="calculatetext">
            <span className="activebtn"></span> Active
          </p>
        </div>
        <div>
          <p>
            <span className="bigcount">{employeeData?.inActiveCount ?? 0}</span>
          </p>
          <p className="calculatetext">
            <span className="inactivebtn"></span> Not Active
          </p>
        </div>
        <div>
          <p>
            <span className="bigcount">
              {employeeData?.appNotInstalled ?? 0}
            </span>
          </p>
          <p className="calculatetext">
            <span className="notinstallbtn"></span> App not installed
          </p>
        </div>
      </div>
      <div>{grid}</div>
      {/* {!!showExpanMore && (
        <ExpandEmployeesAppDataModal
          employeeData={employeeData}
          handlePinned={handlePinned}
          handleUnPinned={handleUnPinned}
          sampleProducts={sampleProducts}
          closePopup={() => setShowExpanMore(false)}
          setEmployeePaginateMetaData={setEmployeePaginateMetaData}
          employeePaginateMetaData={employeePaginateMetaData}
          dropdowndata={dropdowndata}
          deletePopup={deletePopup}
          setDeletePopup={setDeletePopup}
          handleSuccess={handleSuccess}
          closeDeletePopup={closeDeletePopup}
          setSelectedDeleteItem={setSelectedDeleteItem}
          openDeletePopup={openDeletePopup}
          openSuspendPopup={openSuspendPopup}
          suspendPopup={suspendPopup}
          closeSuspendPopup={closeSuspendPopup}
          handleSuspendEmployees={handleSuspendEmployees}
          timeclockStage={timeclockStage}
          selectedDeleteItem={selectedDeleteItem}
          refreshEmployeeData={refreshEmployeeData}
          accessLevel={accessLevel}
          setEmployeeData={setEmployeeData}
          fetchEmployeeAppData={fetchEmployeeAppData}
          showChangeStatusModal={showChangeStatusModal}
          setShowChangeStatusModal={setShowChangeStatusModal}
          openActivePopup={openActivePopup}
          activePopup={activePopup}
          handleActiveEmployees={handleActiveEmployees}
          employeeBackupData={employeeBackupData}
          setEmployeeBackupData={setEmployeeBackupData}
        />
      )} */}
    </div>
  );
};
