import React from 'react'
import { Circle, Marker } from '@react-google-maps/api'

const CircleGeozone = ({ center, radius, options, icon, custName, geozoneID, address }) => {
  return (
    <>
      <Circle
        center={center}
        radius={+radius}
        options={options}
      />
      <Marker
        icon={icon}
        position={center}
        title={`CustomerName: ${custName}, Geozone ID: ${geozoneID}, Latitude: ${center.lat}, Longitude: ${center.lng}, Radius: ${radius}, Address: ${address}`}
      />
    </>
  )
}

export default CircleGeozone