export const EDIT_HISTORY_REPORT_UPDATED_COLUMN_MAPPING = {
    //GENERAL TAB OF SETTINGS ( Account Information)
    "contactEmail": "Primary Contact Email",
    "contactName": "Account Primary Contact",
    "contactPhone": "Primary Contact Phone",
    "notifyEmail": "Notifications Email",
    "timeZone": "Account Timezone",
    "description": "Organization Name",
    "defaultUser": "Default Login User ID",

    //GENERAL TAB OF SETTINGS (Reporting Units)
    "dateTimeUnits": "Date Time",
    "displayDateFormat": "Date Format",
    "elapsedTimeUnits": "Minute",
    "temperatureUnits": "Temperature",
    "latLonFormat": "Lat/Lon Format",
    "distanceUnits": "Distance",
    "speedUnits": "Speed",
    "reportsTimezone": "Report Time Zone",

    //GENERAL TAB OF SETTINGS (EVV)
    "sandataStateCode": "State",
    "BusinessEntityMedicaidID": "Business Entity Medicaid ID",
    "businessEntityId": "Business Entity ID",
    "sandataUserId": "User ID",

    //SECURITY AND PRIVACY TAB OF SETTINGS 
    "smsOptinStatus": "SMS Opt-in",
    "twoFAEnabled": "Enable Two-Factor Authentication",
    "passwordSecurityType": "Password Security Type",

    //PAYRoll TAB OF SETTINGS ( Reporting)
    "includeMultiDaySpanHour": "Include Multi Day Span Hrs",
    "roundOffMinutes": "Round Off Minutes",
    "insertMidnightEvent": "Insert Midnight Event",
    "includeOffShifthour": "Include Off Shift Hrs",

    //PAYRoll TAB OF SETTINGS ( Payroll Hours and Codes)
    "adpMileageEarningCode": "Mileage Earning code",
    "adpDoubleOTEarningCode": "Double OT Earning code",
    "serviceHoursSource": "Service Hours Source",
    "overtimeMultiplier": "OT Multiplier",
    "doubleOvertimeMultiplier": "Double OT Multiplier",
    "regularHour": "Regular Hours (Daily)",
    "adpRegularEarningCode": "Regular Earning code",
    "weeklyRegularHour": "Regular Hours (Weekly)",
    "weeklyOverTime": "Overtime Hours (Weekly)",
    "overTime": "Overtime Hours (Daily)",
    "serviceHourType": "Hours Interval",

    //PAYRoll TAB OF SETTINGS (Payroll Profile)
    "companyCode": "Company Code",
    "payrollId": "Payroll ID",
    "serviceHourType": "Hours Interval",
    "employeeSkills": "Employee Skills",
    "employeeWageType": "Employee Wage Types",
    "payrollStartDate": "Payroll Start Date",
    "payrollSettlementDays": "Payroll Settlement Duration",
    "payrollInterval": "Payroll Interval",
    "weeklyPayPeriod": "Weekly Pay Period",

    //MOBILE TAB OF SETTINGS (Customers, Task & myTeam)
    "myTeamEmployee": "Enable myTeam for Employee",
    "customerSelectionType": "Display to Employee",
    "allowedAppLanguages": "Select Languages",
    "custMandatory": "Customer Mandatory Selection",
    "allowMultiLanguageApp": "Enable Language Selection",
    "defaultAppLanguage": "Select a Default Language",
    "myTeamManager": "Enable myTeam for Managers",
    "passAllDeviceToMyTeam": "Pass All Device to my Team",
    "myTeamEmployee": "Enable myTeam for Employee",
    "showLastSelectedCustomerAndTask": "Show Last Selected Customer & Task",

    //MOBILE TAB OF SETTINGS (Location Tracking)
    "defaultOptin": "Default Opt-in",
    "maxAutoTrackDays": "Default Auto Track",
    "locnPrivacySettings": "Tracking Frequency Control",
    "radius": "Default Zone Radius",
    "minStoppageDuration": "Minimum Stoppage",
    "snapRouteCutoffDistance": "Snap Route (Cutoff)",
    "smsGeoTag": "SMS Geo Tag",
    "nonTrackPushpinId": "Non-Track Pushpin",
    "onPremisesPushpinId": "On Premises Pushpin",
    "autoTrackFrequency": "Frequency",

    //MOBILE TAB OF SETTINGS (Time-Clock Stages)
    "customizedStageName": "Customized Stage Name",
    "cmdID": "SMS Command",
    "attachments": "Required Attachments",
    "stageVisible": "Mobile Visibility",
    "includeHours": "Include Service Hrs. & Miles",

    // ######----------------------------------------------------- ---------------------##############
    // #########------------  COMPANY -> EMPLOYEE MAPPING START FROM HERE --------------##############
    // ######----------------------------------------------------- ---------------------##############

    "employeeID": "Employee ID",
    "firstName": "Employee First Name",
    "lastName": "Employee Last Name",
    "isActive": "Site Status",
    "timeZone": "Time Zone",
    "employee_description": "Employee Description",
    "displayName": "Display Name",

    //   GENERAL ( Healthcare Information)
    "maxWorkOrders": "Max Caseload",
    "maxCommunityWorkOrders": "Max Community Home Members",
    "maxNursingHomeWorkOrders": "Max Nursing Home Members",

    //   GENERAL ( Employee Information)
    "homeSiteId": "Home Site",
    "officeSiteId": "Office Site",
    "homeToOfficeDistance": "Distance",
    "allowNotify": "Enable Notification",
    "employee_isActive": "Is this an active Employee",
    "smsEmail": "SMS Email Address",
    "empNotifyEmail": "Email Notified",
    "vehicleID": "Vehicle ID",

    //   DEVICE SECTION
    "address": "Landline Address",
    "landLine": "Landline",
    "equipmentType": "Employee Type",
    "pushpinID": "Employee Color",
    "phoneNumPrefix": "SIM Employee Prefix",

    //   ORGANISATION (Phone Group Membership)
    "assigned_groups": "Phone Group Membership",

    //   PAYROLL ( Payroll Information)
    "licensePlate": "Company Code",
    "employeeWageType": "Employee Wage Type",
    "payrollId": "Batch ID",
    "payClass": "Pay Class",
    "actualStartDate": "Job Start Date",
    "terminationDate": "Job Termination Date",
    "weeklyPayPeriod": "Weekly Pay Period",

    //   PAYROLL ( Payroll Hours and Codes)
    "adpMileageEarningCode": "Mileage Earning code",
    "adpDoubleOTEarningCode": "Double OT Earning code",
    "adpOTEarningCode": "OT Earning code",
    "adpRegularEarningCode": "Regular Earning code",
    "adpOTEarningCode": "OT Earning code",

    //   PAYROLL (OTHERS INFORMATION)
    "associateID": "Associate ID",
    "SSNumber": "SSN",
    "position": "Position ID",
    "hireDate": "Hire Date",
    "skills": "Skill",
    "jobTitle": "Job Title",
    "employee_earningCode": "Earning Code",

    "workerGroupCodeDesc": "Benefits Eligibility Class Description",
    "payrollFileNumber": "Payroll File#",
    "eTimePersonID": "Worker ID/ETime ID",
    "workerGroupCode": "Benefits Eligibility Class Code",
    "managerID": "Manager ID",
    "managerName": "Manager Name",
    "businessUnitCode": "Business Unit Code",
    "businessUnitCodeDesc": "Business Unit Description",
    "costNumber": "Home Cost Number Code",
    "costNumberDesc": "Home Cost Number Description",

    "statusFlag7CodeDesc": "Status Flag 7 Description",
    "locationCodeDesc": "Location Description",
    "departmentCodeDesc": "Department Description",
    "departmentCode": "Department Code",
    "locationCode": "Location Code",
    "statusFlag7Code": "Status Flag 7 Code",

    "employmentType": "Time & Attendance (ADP)",
    "workAssignmentID": "Work Assignment ID",
    "payrollGroupCode": "Payroll Group Code",
    "rateType": "Rate Type",
    "hourlyrate": "Hourly Rate",
    "contractStatus": "Contract Status",

    // ######----------------------------------------------------- ---------------------##############
    // #########------------  COMPANY -> MANAGER MAPPING START FROM HERE --------------##############
    // ######----------------------------------------------------- ---------------------##############

    // EDIT MANAGER ( Manager Information)
    "manager_description": "Manager Description",
    "manager_contactName": "Contact Name",
    "manager_isActive": "Is this an active manager",
    "manager_contactEmail": "Contact Email",
    "manager_notifyEmail": "Notify Email",
    "manager_contactPhone": "Phone",
    // EDIT MANAGER (Group Authorization Settings)
    "authorizedGroups": "Select Group",
    // EDIT MANAGER (Group Authorization Settings)
    "authorizedCustomers": "Select Jobsites",
    // EDIT MANAGER (Login Page Settings)
    "firstLoginPageID": "First Login Page",
    // EDIT MANAGER (Access Level Settings)
    "hideDeviceList": "Hide Device ID",
    "maxAccessLevel": "Maximum Access Level",
    "OtpPreference": "Select OTP Medium",
    "trackManager": "Will this manager be tracked for time and location",
    "manager_timeZone": "Time Zone",
    "manager_isActive": "Active",

    // ######----------------------------------------------------- ---------------------##############
    // #########------------  COMPANY -> TASK MAPPING START FROM HERE --------------##############
    // ######----------------------------------------------------- ---------------------##############

    "name": "Name",
    "taskID": "Task ID",
    "earningCode": "Earning Code",
    "payerServiceInfoId": "Payer/Insurer Information",
    "companyID": "Company ID",
    "billingRate": "Billing Rate",
    "refID": "Reference ID",
    "srcID": "Source ID",
    "extSystem": "Ext System",
    "task_isActive": "Task Status",

    // ######----------------------------------------------------- ---------------------##############
    // #########------------  COMPANY -> GROUP MAPPING START FROM HERE --------------##############
    // ######----------------------------------------------------- ---------------------##############
    "groupDescription": "Employee Group Name",
    "groupColour": "Assign a Color",
    "device_assigned": "Assign EMP",

    // ######----------------------------------------------------- ---------------------##############
    // #########------------  COMPANY -> FORM MAPPING START FROM HERE --------------##############
    // ######----------------------------------------------------- ---------------------##############
    "formName": "Form Name",
    "form_isActive": "Form Status",

    // ######----------------------------------------------------- ---------------------##############
    // #########------------  COMPANY -> Active Schedule MAPPING START FROM HERE --------------##############
    // ######----------------------------------------------------- ---------------------##############
    "scheduleStopped": "Schedule Stopped",
    "scheduleDeletedName": "Schedule Deleted",
    "scheduleStarted": "Schedule Started",

    // ######----------------------------------------------------- ---------------------##############
    // #########------------  COMPANY -> Automation Rule MAPPING START FROM HERE --------------##############
    // ######----------------------------------------------------- ---------------------##############
    "ivr_phoneNo": "IVR Phone Number",
    "ivr_message": "IVR Message",
    "ivr_ivrRecording": "Record IVR Audio",
    "ivr_messageType": "Message Script Type",

    "gcm_phoneNo": "GCM Phone Number",
    "gcm_message": "GCM Message",
    "gcm_messageTitle": "Message Title",
    "gcm_notificationType": "Notification Type",

    "webhookUrl": "Webhook URL",
    "webhookData": "Webhook Data",

    "inAppphoneNo": "InApp Phone Number",
    "inAppmessage": "InApp Message",

    "sms_phoneNo": "SMS Phone Number",
    "sms_message": "SMS Message",

    "empEmailAddre": "Notification Email",
    "empEmailSub": "Email Subject",
    "empEmailText": "Email Message",

    "rule_isActive": "Rule Status",
    "rule_description": "Description",
    "scheduleStarted": "Schedule Started",
    "isSMSNotify": "SMS",
    "isGCMNotify": "GCM",
    "isIVRNotify": "IVR",
    "isInAppNotify": "InApp",
    "isWebhookNotify": "Webhooks",
    "isEmpEmailNotify": "Employee Email",

    "emailSubject": "Email Subject",
    "emailText": "Email Message",
    "isCronRule": "Time Based Rule",

    // ######----------------------------------------------------- ---------------------##############
    // #########------------  COMPANY -> JOB SITES  MAPPING START FROM HERE --------------##############
    // ######----------------------------------------------------- ---------------------##############

    // EDIT JOB SITES ( EVV Extension )
    "clientFirstName": "Client First Name",
    "clientLastName": "Client Last Name",
    // EDIT JOB SITES ( Task Information )
    "address": "Address",
    "name": "Site Name",
    "jobsite_isActive": "Jobsite Status",
    "effectiveEndDate": "Effective End Date",
    "effectiveStartDate": "Effective Start Date",
    "timezone": "Timezone",
    "jurisdictionID": "Jurisdiction ID",
    "serviceCode": "Authorization",
    "stateID": "State ID",
    // EDIT JOB SITES ( Advanced Setup )
    "refID": "Reference ID",
    "srcID": "Source ID",
    "siteID": "Site ID",
    "jobsite_pushpinID": "Customer Type",
    "siteName": "Site Description",
    "phoneNumber": "Phone Number",
    "priority": "Risk",
    "siteType": "Site Type",
    "email": "Contact Email",
    "jobsite_description": "User Description",
    "flsa": "Claim Frequency",
    "billingRate": "Rate card",
    "extSystem": "Ext System",
    "flsa": "Claim Frequency",
    "landlineNumber": "Landline Number",
    "jobsite_radius": "Jobsite Radius",
}


export const SPECIAL_VALUE_MAPPING = {
    "payrollInterval": { 1: "Every 2 Weeks", 0: "Weekly", 2: "Monthly", 3: "Annual" },
    "defaultOptin": { "locn_optin_sms": "Optin using SMS", "locn_optin_inapp": "Optin using App Msg", "locn_optin_ivr": "Optin using Voice" },
    "attachments": { 2: "Task", 3: "Notes", 4: "Camera", 5: "Gallery", 6: "Scan", 7: "e-Sign", 8: "Form" },
    "distanceUnits": { 0: "Miles", 1: "Km", 2: "Nm" },
    "temperatureUnits": { 0: "F", 1: "C" },
    "latLonFormat": { 0: "Degrees", 1: "Deg:Min", 2: "Deg:Min:Sec" },
    "speedUnits": { 0: "mph", 1: "km/h", 2: "knots" },
    "weeklyPayPeriod": {
        0: "Sunday",
        1: "Monday",
        2: "Tuesday",
        3: "Wednesday",
        4: "Thrusday",
        5: "Friday",
        6: "Saturday",
    }
};