export const timeZones = [
    {
        value: "US/Hawaii",
        label: "US/Hawaii"
    },
    {
        value: "US/Alaska",
        label: "US/Alaska"
    },
    {
        value: "US/Pacific",
        label: "US/Pacific"
    },
    {
        value: "US/Arizona",
        label: "US/Arizona"
    },
    {
        value: "US/Mountain",
        label: "US/Mountain"
    },
    {
        value: "US/Central",
        label: "US/Central"
    },
    {
        value: "US/Eastern",
        label: "US/Eastern"
    },
    {
        value: "Canada/Pacific",
        label: "Canada/Pacific"
    },
    {
        value: "Canada/Mountain",
        label: "Canada/Mountain"
    },
    {
        value: "Canada/Central",
        label: "Canada/Central"
    },
    {
        value: "Canada/Eastern",
        label: "Canada/Eastern"
    },
    {
        value: "Canada/Atlantic",
        label: "Canada/Atlantic"
    },
    {
        value: "Mexico/BajaNorte",
        label: "Mexico/BajaNorte"
    },
    {
        value: "Mexico/BajaSur",
        label: "Mexico/BajaSur"
    },
    {
        value: "Mexico/General",
        label: "Mexico/General"
    },
    {
        value: "Europe/Athens",
        label: "Europe/Athens"
    },
    {
        value: "Europe/Berlin",
        label: "Europe/Berlin"
    },
    {
        value: "Europe/Dublin",
        label: "Europe/Dublin"
    },
    {
        value: "Europe/Helsinki",
        label: "Europe/Helsinki"
    },
    {
        value: "Europe/Kiev",
        label: "Europe/Kiev"
    },
    {
        value: "Europe/Lisbon",
        label: "Europe/Lisbon"
    },
    {
        value: "Europe/London",
        label: "Europe/London"
    },
    {
        value: "Europe/Madrid",
        label: "Europe/Madrid"
    },
    {
        value: "Europe/Moscow",
        label: "Europe/Moscow"
    },
    {
        value: "Europe/Oslo",
        label: "Europe/Oslo"
    },
    {
        value: "Europe/Paris",
        label: "Europe/Paris"
    },
    {
        value: "Europe/Rome",
        label: "Europe/Rome"
    },
    {
        value: "Europe/Stockholm",
        label: "Europe/Stockholm"
    },
    {
        value: "Europe/Zurich",
        label: "Europe/Zurich"
    },
    {
        value: "Pacific/Auckland",
        label: "Pacific/Auckland"
    },
    {
        value: "Pacific/Chatham",
        label: "Pacific/Chatham"
    },
    {
        value: "GMT",
        label: "GMT"
    },
    {
        value: "GMT+00:00",
        label: "GMT+00:00"
    },
    {
        value: "GMT+01:00",
        label: "GMT+01:00"
    },
    {
        value: "GMT+02:00",
        label: "GMT+02:00"
    },
    {
        value: "GMT+03:00",
        label: "GMT+03:00"
    },
    {
        value: "GMT+04:00",
        label: "GMT+04:00"
    },
    {
        value: "GMT+05:00",
        label: "GMT+05:00"
    },
    {
        value: "GMT+05:30",
        label: "GMT+05:30"
    },
    {
        value: "GMT+06:00",
        label: "GMT+06:00"
    },
    {
        value: "GMT+07:00",
        label: "GMT+07:00"
    },
    {
        value: "GMT+08:00",
        label: "GMT+08:00"
    },
    {
        value: "GMT+09:00",
        label: "GMT+09:00"
    },
    {
        value: "GMT+10:00",
        label: "GMT+10:00"
    },
    {
        value: "GMT+11:00",
        label: "GMT+11:00"
    },
    {
        value: "GMT+12:00",
        label: "GMT+12:00"
    },
    {
        value: "GMT+13:00",
        label: "GMT+13:00"
    },
    {
        value: "GMT+14:00",
        label: "GMT+14:00"
    },
    {
        value: "GMT-01:00",
        label: "GMT-01:00"
    },
    {
        value: "GMT-02:00",
        label: "GMT-02:00"
    },
    {
        value: "GMT-03:00",
        label: "GMT-03:00"
    },
    {
        value: "GMT-04:00",
        label: "GMT-04:00"
    },
    {
        value: "GMT-05:00",
        label: "GMT-05:00"
    },
    {
        value: "GMT-06:00",
        label: "GMT-06:00"
    },
    {
        value: "GMT-07:00",
        label: "GMT-07:00"
    },
    {
        value: "GMT-08:00",
        label: "GMT-08:00"
    },
    {
        value: "GMT-09:00",
        label: "GMT-09:00"
    },
    {
        value: "GMT-10:00",
        label: "GMT-10:00"
    },
    {
        value: "GMT-11:00",
        label: "GMT-11:00"
    },
    {
        value: "GMT-12:00",
        label: "GMT-12:00"
    },

]

export const locateSchedulerColumns = [
    {
        title: "Schedule ID",
        field: "scheduleId",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Repeats On",
        field: "repeatType",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Next Run",
        field: "nextRunDateTime",
        editable: false,
        width: 220,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Track For",
        field: "assignTo",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Status",
        field: "active",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: "Running Status",
        field: "runningStatus",
        editable: false,
        width: 180,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text"
    },
]

export const weekConfig = [
    { label: 'S', day: 'Sun', selected: false },
    { label: 'M', day: 'Mon', selected: false },
    { label: 'T', day: 'Tue', selected: false },
    { label: 'W', day: 'Wed', selected: false },
    { label: 'T', day: 'Thu', selected: false },
    { label: 'F', day: 'Fri', selected: false },
    { label: 'S', day: 'Sat', selected: false },
]

export const dateTimeExtractor = (timestamp) => {
    const moment = window.moment;
    return {
        date: moment(timestamp).format(`YYYY/MM/DD`),
        time: moment(timestamp).format('HH:mm:ss')
    }
}

export const generateWeekRepeatString = (weeksConfig) => {
    let returnText = '';

    weeksConfig.forEach((ele) => {
        if (ele.selected) {
            returnText += `${ele.day}|`
        }
    })

    return returnText.trim().slice(0, -1)
}

export const getWeekDayString = (str) => {
    if (!str) return "";
    let returnText = "";
    let weeksData = str.split('|');

    const weeksConfig = [
        { label: 'Sun', value: 'Sunday' },
        { label: 'Mon', value: 'Monday' },
        { label: 'Tue', value: 'Tuesday' },
        { label: 'Wed', value: 'Wednesday' },
        { label: 'Thu', value: 'Thursday' },
        { label: 'Fri', value: 'Friday' },
        { label: 'Sat', value: 'Saturday' },
    ];

    weeksConfig.forEach((ele) => {
        if (weeksData.includes(ele.label)) {
            returnText += `${ele.value}, `
        }
    })

    return returnText.trim().slice(0, -1)
}

export const getDefaultDateTimeForNonRecurringSchedule = (type) => {
    if (type === 0) {
        const today = new Date()
        today.setHours(9)
        today.setMinutes(0)
        today.setSeconds(0)
        return today
    } else {
        const today = new Date()
        const tomorrow = new Date(today)
        tomorrow.setHours(17)
        tomorrow.setMinutes(0)
        tomorrow.setSeconds(0)
        return tomorrow
    }
}

export const getMinDateLimit = () => {
    let d = new Date()
    return new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0)
}

export const checkKeyDown = (e) => {
    if (e.code === 'Enter') e.preventDefault();
};

export const getDefaultDateTimeForRecurringSchedule = (type) => {
    if (type === 0) {
        const today = new Date()
        today.setHours(9)
        today.setMinutes(0)
        today.setSeconds(0)
        return today
    } else {
        const today = new Date()
        const tomorrow = new Date(today)
        tomorrow.setFullYear(today.getFullYear() + 1);
        tomorrow.setHours(17)
        tomorrow.setMinutes(0)
        tomorrow.setSeconds(0)
        return tomorrow
    }
}