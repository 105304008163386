import React, { useState, useEffect } from "react";
import { G2_PARTNER_NAME } from "../G2Utils/constants";
import SiteLoader from "../../SiteLoader";
import { apiProvider } from "../../../../services/api/provider";

export const G2Review = ({ userEmail, state }) => {
  const [iframeLoaded, setIframeLoaded] = useState(false);


  const iframeSrc = `https://www.g2.com/partnerships/${G2_PARTNER_NAME}/users/login.embed?state=${state}&email=${userEmail}`;


  useEffect(() => {
    const handleIframeLoad = () => {
      setIframeLoaded(true);
    };

    const iframe = document.getElementById("g2Iframe");
    if (iframe) {
      iframe.addEventListener("load", handleIframeLoad);

      return () => {
        iframe.removeEventListener("load", handleIframeLoad);
      };
    }
  }, []);

  return (
    <>
      {!iframeLoaded && (
        <div className="loader">
          <div style={{ height: "70vh", width: "70vw", borderRadius: '8px' }}>
            <SiteLoader isActive={true} />
          </div>
        </div>
      )}
      <iframe
        id="g2Iframe"
        style={{
          height: "70vh",
          width: "70vw",
          display: iframeLoaded ? "block" : "none",
        }}
        src={iframeSrc}
        title="Login"
      ></iframe>
    </>
  );
};
