import React from 'react'
import './ViewScheduleData.css'

export const ViewScheduleData = ({ scheduleData, columns }) => {
    const columnNames = Object.keys(columns);
    return (
        <>
            {
                scheduleData.length > 0 ?
                    <div className="tbl_style_mdl">
                        <table>
                            <thead>
                                <tr>
                                    {columnNames.map((columnName) => (
                                        <th key={columnName}>{columns[columnName]}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {scheduleData.map((rowData, index) => (
                                    <tr key={index}>
                                        {columnNames.map((columnName) => (
                                            <td key={columnName}>{rowData[columnName]}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    :
                    <div>
                        No Data Found for this Schedule
                    </div>
            }
        </>
    )
}