import React, { useEffect, useState, useRef } from 'react';
import { apiProvider } from '../../../../services/api/provider';
import { messageReport } from "../buildReportsutils";
import { formatDate } from '@progress/kendo-intl';
import SiteLoader from "../../../common/SiteLoader";
import KendoGrid from '../../../common/kendo/KendoGrid';

const MessageReport = ({ genSetting }) => {

    const [columns, setColumns] = useState(messageReport);
    const [Data, setData] = useState();
    const [showLoader, setShowLoader] = useState(false);
    const [params, setParams] = useState(
        {
            date_fr: formatDate(new Date(), 'yyyy/MM/dd'),
            date_to: formatDate(new Date(), 'yyyy/MM/dd'),
            reportFor: "",
            offsetStr: "",
            tzStr: "",
            limit: "",
            device_tz_selected: "",
        }
    )

    const childRef = useRef()

    const getReport = async () => {
        try {
            setShowLoader(true);
            setParams({ ...params, device_tz_selected: genSetting?.report_timezone });
            const response = await apiProvider.getAll('track/mobile/v1/allgeo/messagereport', params);
            setData(response);
        } catch (error) {
            console.error("API Error:", error);
        } finally {
            setShowLoader(false);
        }
    };

    useEffect(() => {
        getReport();
    }, [genSetting]);


    return (
        <>
            {Data &&
                <KendoGrid
                    gridData={Data}
                    setGridData={setData}
                    columnData={columns}
                    module={"buildReports"}
                    subModule={'messageReport'}
                    getAPiUrl={'track/mobile/v1/allgeo/messagereport'}
                    requestParams={params}
                    supportParams={params.stageid}
                    accessLevel={{ childMenu: 1 }}
                    ref={childRef}
                    otherData={{
                        actionColumnBtns: [],
                        showExportDropdown: true,
                        enableFooterDisplay: true,
                        minutesFormat: genSetting?.minutes,
                        dateFormat: genSetting?.date_display_format,
                    }}
                />
            }
            <SiteLoader isActive={showLoader} />
        </>
    )
}

export default MessageReport