import React, { useState } from 'react';
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import { DropdownList } from '../../../common/dropdown';
import { RefreshBtn } from "../../../common/kendo/HeaderComponents";
import Download from "../../RunReports/Popups/Download";
import Email from '../Popups/Email';
import ToastMessage from '../../../common/ToastMessage';

const ErrorDetailReportHeader = ({ generalSettingsData, params, setParams, handleSearchChange, searchTerm, refreshFunc, errorDetails, ErrorReportToExcel, handleExportToPDF, setShowLoader }) => {
  const [showDownloadPopup, setShowDownloadPopup] = useState(false);
  const [showEmailPopup, setShowEmailPopup] = useState(false)
  const [toastMsg, setToastMsg] = useState({
    msg: "",
    type: "msgSuccess"
  });
  const openDownloadPopup = () => {
    setShowDownloadPopup(true);
  };
  const closeDownloadPopup = () => {
    setShowDownloadPopup(false);
  };

  return (
    <>
      <div className='audit-header'>
        <div className='row'>
          <div className='col'>
            <div class="date-time-container">
              <label>Start Date & Time</label>
              <div class="date-time-picker" style={{ marginLeft: "-10px" }}>
                <DateTimePicker
                  placeholder={"Start Date & Time"}
                  value={params.date_fr}
                  onChange={(e) => {
                    if (e.value) {
                      if (e.value.getTime() > params.date_to.getTime()) return;

                      setParams({
                        ...params,
                        date_fr: e.value
                      });
                    }
                  }}
                  format={`${generalSettingsData.dateDisplayFormat || 'MM/dd/yyyy'} ${generalSettingsData.timeFormat || 'hh:mm:ss a'}`}
                />
              </div>
              <label style={{ marginLeft: "-10px"}}>End Date & Time</label>
              <div class="date-time-picker-end" style={{ marginLeft: "-10px" }}>
                <DateTimePicker
                  placeholder={"End Date & Time"}
                  value={params.date_to}
                  onChange={(e) => {
                    if (e.value) {
                      if (e.value.getTime() < params.date_fr.getTime()) return;

                      setParams({
                        ...params,
                        date_to: e.value
                      });
                    }
                  }}
                  format={`${generalSettingsData.dateDisplayFormat || 'MM/dd/yyyy'} ${generalSettingsData.timeFormat || 'hh:mm:ss a'}`}
                />
              </div>
              <span className='refresh-btn-2' style={{ marginLeft: "-10px"}}> <RefreshBtn accessLevel={1} onClick={refreshFunc} /></span>
            </div>
          </div>
          <div className='col'>
            <div className="audit_search_field active">
              <input placeholder="search"
                value={searchTerm} onChange={handleSearchChange}
              />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
          </div>
          <div className='col'>
            <div className="kHeaderRBot">
              <button
                style={{ marginTop: "16px", fontSize: "0.75rem",padding: "3px 8px 2px 24px" }}
                title="Download"
                className="btnStyle btn2 btnDownload"
                onClick={openDownloadPopup}
              >
                Download
              </button>
              <button
                style={{fontSize: "0.75rem",padding: "3px 8px 2px 24px"}}
                title="Email"
                className="btnStyle btn2 btnEmail"
                onClick={() => setShowEmailPopup(true)}
              >
                Email
              </button>
            </div>
          </div>
        </div>
      </div>
      {showDownloadPopup && <Download
        closePopup={closeDownloadPopup}
        report={"errorReport"}
        ErrorReportToExcel={ErrorReportToExcel}
        handleExportToPDF={handleExportToPDF}
      />}
      {showEmailPopup &&
        <Email
          errorDetails={errorDetails}
          type={"errorDetailReport"}
          setShowLoader={setShowLoader}
          closePopup={() => setShowEmailPopup(false)}
          setToastMsg={setToastMsg}
        />
      }
      {toastMsg.msg && <ToastMessage
        cssClass={toastMsg.type}
        message={toastMsg.msg}
        onClose={() => setToastMsg({ msg: "", type: "" })}
      />}

      <div className={`overlay ${showDownloadPopup || showEmailPopup || showDownloadPopup ? 'active' : ''}`} />
    </>
  )
}

export default ErrorDetailReportHeader