import React, { Fragment, useEffect, useState } from "react";
import { apiProvider } from '../../../services/api/provider';
import { Controller, useForm } from "react-hook-form";
import SiteLoader from '../../common/SiteLoader';
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { Prompt } from "react-router-dom";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { formatDate } from "@progress/kendo-intl";

export default function EmployeePayroll(props) {

    const { control, register, handleSubmit, watch, getValues, setValue, reset, formState: { errors, isDirty, isValid } } = useForm({ mode: "all" });
    const [employeePayrollValue, setEmployeePayrollValue] = useState({});

    const [showLoader, setShowLoader] = useState(false);
    const [hoursState, setHoursState] = useState("")

    useEffect(() => {
        if (Object.keys(props.data).length > 0) {
            setEmployeePayrollValue(props.data);
        }
    }, [props.data]);

    useEffect(() => {
        if (Object.keys(employeePayrollValue).length > 0) {
            reset({
                ...employeePayrollValue,
                weeklyPayPeriodFrom: employeePayrollValue.weeklyPayPeriodFrom ? employeePayrollValue.weeklyPayPeriodFrom : "-1",
                weeklyPayPeriodTo: employeePayrollValue.weeklyPayPeriodTo ? employeePayrollValue.weeklyPayPeriodTo : "-1",
                payDataPayrollADPEnabled: employeePayrollValue.payDataPayrollADPEnabled === "Yes",
                timeAndAttendanceADPEnabled: employeePayrollValue.timeAndAttendanceADPEnabled === "Yes",
                skills: employeePayrollValue.skills.split(",").filter(d => d.length > 0).map(d => ({ id: d, text: d })),
                hireDate: employeePayrollValue.hireDate ? new Date(employeePayrollValue.hireDate) : new Date(),
                jobStartDate: employeePayrollValue.jobStartDate ? new Date(employeePayrollValue.jobStartDate) : "",
                terminationDate: employeePayrollValue.terminationDate ? new Date(employeePayrollValue.terminationDate) : ""
            })

            setHoursState(employeePayrollValue.hoursInterval)
        }
    }, [employeePayrollValue]);

    const onSubmit = (data) => {
        setShowLoader(true);
        data.payDataPayrollADPEnabled = data.payDataPayrollADPEnabled ? "Yes" : "No"
        data.timeAndAttendanceADPEnabled = data.timeAndAttendanceADPEnabled ? "Yes" : "No"
        data.skills = data.skills.map(d => d.id).toString()
        data.jobStartDate = formatDate(data.jobStartDate, 'yyyy-MM-dd'),
        data.terminationDate = formatDate(data.terminationDate, 'yyyy-MM-dd')
        apiProvider.put('track/mobile/v1/allgeo/updateEmpPayrollTab/' + props.employeeId, data).then((response) => {

            if (response.status && response.status == 200) {
                props.refreshAllTabdata()
                props.setToastMsg({ msg: response.message, type: 'msgSuccess' })
            } else {
                props.setToastMsg({ msg: response.message, type: 'msgError' })
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setShowLoader(false);
            props.formState(false);
            localStorage.removeItem('formIsDirty')
        });
    }

    const checkWeeklyPayPeriod = (startDate, endDate) => {

        if (startDate === -1 || endDate === -1) return true;

        const msg = 'Please set a 7-day pay period duration.(Eg: Sun-Sat, Mon-Sun)'

        if (startDate < endDate) {
            if (startDate - endDate === -6) return true
            else {
                alert(msg)
                return false
            }
        } else if (startDate > endDate) {
            if (startDate - endDate === 1) return true
            else {
                alert(msg)
                return false
            }
        }

        alert(msg)
        return false

    }

    const tagRender = (tagData, li) => {
        if (tagData.text != 'Select All') {
            return React.cloneElement(li, li.props, [
                <li key={Math.random()} className="custom-tag" style={{ "listStyleType": "none" }}>
                    {tagData.text == 'Select All' ? 'Clear All' : tagData.text}
                </li>, li.props.children,
            ])
        }
    };

    const itemRender = (li, itemProps) => {
        const itemChildren = (
            <div className="customMultiSelect">
                <label>{li.props.children}</label>
                <div className="customCheck noTxt">
                    <input
                        type="checkbox"
                        name={itemProps.dataItem}
                        checked={itemProps.selected}
                        onChange={(e) => itemProps.onClick(itemProps.index, e)}
                    />
                    <label className="customCheckLabel" htmlFor={li.props.children}>{li.props.children}</label>
                </div>
            </div>
        );
        return React.cloneElement(li, li.props, itemChildren);
    };

    useEffect(() => {
        props.formState(isDirty);
        if (isDirty) {
            localStorage.setItem('formIsDirty', isDirty)
        }
    }, [isDirty]);

    return (
        <Fragment>
            <Prompt
                when={isDirty}
                message='The changes are still unsaved. Are you sure you want to leave?'
            />
            <form onSubmit={handleSubmit(onSubmit)} id="settingsForm">
                <section>
                    <h3>Payroll Information</h3>
                    <div className="supportedTxt">
                        You can check and update the employee’s payroll information here.
                    </div>
                    <div className="innerWrap">
                        <div className="rowBlk">
                            <div className="colBlk6 fldWrap">
                                <label>Company Code</label>
                                <input type="text" {...register('companyCode')} />
                            </div>
                            <div className="colBlk6 fldWrap">
                                <label>Batch ID</label>
                                <input type="text" {...register('batchId')} />
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className="colBlk6 fldWrap">
                                <label>{props.nomenclature.device_title} Wage Type</label>
                                <select {...register('employeeWageTypes')}>
                                    {
                                        employeePayrollValue.employeeWageTypesOptions && employeePayrollValue.employeeWageTypesOptions.length > 0 ?
                                            employeePayrollValue.employeeWageTypesOptions.map((item) => {
                                                if (item.wageTypeValue != '' && item.wageTypeKey != '') {
                                                    return <option key={item.wageTypeValue} value={item.wageTypeValue}>{item.wageTypeKey}</option>
                                                } else {
                                                    return <option key="111" value="">Select Wage Type</option>
                                                }
                                            }) : ''
                                    }
                                </select>
                            </div>
                            <div className="colBlk6 fldWrap">
                                <label>Pay Class</label>
                                <input type="text"  {...register('payClass')} />
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className="colBlk3">
                                <div className="fldWrap">
                                    <label>Weekly Pay Period</label>
                                    <select
                                        {...register('weeklyPayPeriodFrom')}
                                        onChange={e => {
                                            const { value } = e.target;
                                            if (checkWeeklyPayPeriod(+value, +getValues("weeklyPayPeriodTo"))) {
                                                setValue("weeklyPayPeriodFrom", value)
                                            } else {
                                                setValue("weeklyPayPeriodFrom", getValues("weeklyPayPeriodFrom"))
                                            }
                                        }}
                                    >
                                        <option value="-1">Select</option>
                                        <option value="0">Sunday</option>
                                        <option value="1">Monday</option>
                                        <option value="2">Tuesday</option>
                                        <option value="3">Wednesday</option>
                                        <option value="4">Thursday</option>
                                        <option value="5">Friday</option>
                                        <option value="6">Saturday</option>
                                    </select>
                                </div>
                            </div>
                            <div className="colBlk3">
                                <div className="fldWrap">
                                    <label>To</label>
                                    <select
                                        {...register('weeklyPayPeriodTo')}
                                        onChange={e => {
                                            const { value } = e.target;
                                            if (checkWeeklyPayPeriod(+getValues("weeklyPayPeriodFrom"), +value)) {
                                                setValue("weeklyPayPeriodTo", value)
                                            } else {
                                                setValue("weeklyPayPeriodTo", getValues("weeklyPayPeriodTo"))
                                            }
                                        }}
                                    >
                                        <option value="-1">Select</option>
                                        <option value="0">Sunday</option>
                                        <option value="1">Monday</option>
                                        <option value="2">Tuesday</option>
                                        <option value="3">Wednesday</option>
                                        <option value="4">Thursday</option>
                                        <option value="5">Friday</option>
                                        <option value="6">Saturday</option>
                                    </select>
                                </div>
                            </div>
                            <div className="colBlk3 fldWrap">
                                <label>Job Start Date</label>
                                <DatePicker
                                    value={watch('jobStartDate')}
                                    onChange={e => {
                                        if (e.value <= watch('terminationDate') || !watch('jobStartDate'))
                                            setValue('jobStartDate', e.value, { shouldDirty: true })
                                    }}
                                />
                            </div>
                            <div className="colBlk3 fldWrap">
                                <label>Job Termination Date</label>
                                <DatePicker
                                    value={watch('terminationDate')}
                                    onChange={e => {
                                        if (e.value > watch('jobStartDate') || !watch('terminationDate'))
                                            setValue('terminationDate', e.value, { shouldDirty: true })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <h3>Payroll Hours and Codes</h3>
                    <div className="supportedTxt">
                        Customize your account to use terminology relevant to your industry or
                        business
                    </div>
                    <div className="innerWrap">
                        <label>Hours Interval</label>
                        <div className="customControls capsules bottomGap1">
                            <div className="customRadio">
                                <input
                                    type="radio"
                                    id="dailyInterval"
                                    name="HoursInterval"
                                    value="Daily"
                                    {...register('hoursInterval')}
                                    onClick={() => setHoursState("Daily")}
                                />
                                <label htmlFor="dailyInterval">Daily</label>
                            </div>
                            <div className="customRadio">
                                <input type="radio" value="Weekly" id="weeklyInterval" {...register('hoursInterval')} onClick={() => setHoursState("Weekly")} />
                                <label htmlFor="weeklyInterval">Weekly</label>
                            </div>
                            <div className="customRadio">
                                <input type="radio" value="None" id="noInterval" {...register('hoursInterval')} onClick={() => setHoursState("None")} />
                                <label htmlFor="noInterval">None</label>
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className="colBlk6">
                                <div className="sectionSubHeading">Regular Hours</div>
                                <div className="rowBlk">
                                    <div className={`colBlk6 ${hoursState === 'Daily' ? '' : 'blkInactive'} ${errors.dailyRegularHrs ? 'errFld' : errors.dailyRegularMinutes ? 'errFld' : ''}`}>
                                        <div className="fldWrap">
                                            <label>Daily</label>
                                            <div className="numberFldWrap">
                                                <input type="number" {...register('dailyRegularHrs', { min: 0, max: 24 })} max="24" min="0" step="1" />
                                                <span>hrs</span>
                                                <span className="timeSep">:</span>
                                                <input type="number" {...register('dailyRegularMinutes', { min: 0, max: 59 })} max="59" min="0" step="1" />
                                                <span>mins</span>
                                            </div>
                                            {errors.dailyRegularHrs && <div className="fldMsg">Please enter a valid Daily regular hours</div>}
                                            {errors.dailyRegularMinutes && <div className="fldMsg">Please enter a valid Daily regular minutes</div>}
                                        </div>
                                    </div>
                                    <div className={`colBlk6 ${hoursState === 'Weekly' ? '' : 'blkInactive'} ${errors.weeklyRegularHrs ? 'errFld' : ''}`}>
                                        <div className="fldWrap">
                                            <label>Weekly</label>
                                            <div className="numberFldWrap smlFld">
                                                <input type="number" {...register('weeklyRegularHrs', { min: 0, max: 100 })} max="84" min="0" step="1" />
                                                <span>hrs</span>
                                            </div>
                                            {errors.weeklyRegularHrs && <div className="fldMsg">Please enter a valid Weekly regular hours</div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="rowBlk">
                                    <div className="colBlk6">
                                        <div className="fldWrap">
                                            <label>Regular Earning code</label>
                                            <input type="text" {...register('reguerEarningCode')} defaultValue="" className="smlFld" />
                                        </div>
                                    </div>
                                    <div className="colBlk6">
                                        <div className="fldWrap">
                                            <label>Mileage Earning code</label>
                                            <input type="text" {...register('mileageEarningcode')} className="smlFld" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`colBlk6 ${errors.dailyOverTimeHrs ? 'errFld' : errors.dailyOverTimeMinutes ? 'errFld' : ''}`}>
                                <div className="sectionSubHeading">Overtime Hours</div>
                                <div className="rowBlk">
                                    <div className={`colBlk6 ${hoursState === 'Daily' ? '' : 'blkInactive'}`}>
                                        <div className="fldWrap">
                                            <label>Daily</label>
                                            <div className="numberFldWrap">
                                                <input type="number" {...register('dailyOverTimeHrs', { min: 0, max: 24 })} max="24" min="0" step="1" />
                                                <span>hrs</span>
                                                <span className="timeSep">:</span>
                                                <input type="number" {...register('dailyOverTimeMinutes', { min: 0, max: 59 })} max="59" min="0" step="1" />
                                                <span>mins</span>
                                            </div>
                                            {errors.dailyOverTimeHrs && <div className="fldMsg">Please enter a valid Daily overtime hours</div>}
                                            {errors.dailyOverTimeMinutes && <div className="fldMsg">Please enter a valid Daily overtime minutes</div>}
                                        </div>
                                    </div>
                                    <div className={`colBlk6 ${hoursState === 'Weekly' ? '' : 'blkInactive'} ${errors.weeklyOverTimeHrs ? 'errFld' : ''}`}>
                                        <div className="fldWrap">
                                            <label>Weekly</label>
                                            <div className="numberFldWrap smlFld">
                                                <input type="number" {...register('weeklyOverTimeHrs', { min: 0, max: 100 })} max="84" min="0" step="1" />
                                                <span>hrs</span>
                                            </div>
                                            {errors.weeklyOverTimeHrs && <div className="fldMsg">Please enter a valid Weekly overtime hours</div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="rowBlk">
                                    <div className="colBlk6">
                                        <div className="fldWrap">
                                            <label>OT Earning code</label>
                                            <input type="text" {...register('otEarningCode')} className="smlFld" />
                                        </div>
                                    </div>
                                    <div className="colBlk6">
                                        <div className="fldWrap">
                                            <label>Double OT Earning code</label>
                                            <input type="text" {...register('doubleOTEarningCode')} className="smlFld" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <h3>Other Information</h3>
                    <div className="supportedTxt">
                        Customize your account to use terminology relevant to your industry or
                        business
                    </div>
                    <div className="innerWrap">
                        <div className="rowBlk">
                            <div className="colBlk6 fldWrap">
                                <label>Position ID</label>
                                <input type="text" {...register('positionId')} />
                            </div>
                            <div className="colBlk6 fldWrap">
                                <label>Associate ID</label>
                                <input type="text" {...register('associateID')} />
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className="colBlk6 fldWrap">
                                <label>SSN</label>
                                <input type="text" {...register('ssnNumber')} />
                            </div>
                            <div className="colBlk3 fldWrap">
                                <label>Earning Code</label>
                                <select {...register('earningCode')}>
                                    {
                                        employeePayrollValue.earningCodeOptions && employeePayrollValue.earningCodeOptions.length > 0 ?
                                            employeePayrollValue.earningCodeOptions.map((item, ind) => {
                                                return <option key={ind} value={item.CodeId}>{item.CodeName}</option>
                                            }) : ''
                                    }
                                </select>
                            </div>
                            <div className="colBlk3 fldWrap">
                                <label>Hire Date</label>
                                <DatePicker
                                    value={watch('hireDate')}
                                    onChange={e => {
                                        setValue('hireDate', e.value, { shouldDirty: true })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className="colBlk6 fldWrap">
                                <label className="withHelpTxt">Skill</label>
                                <div className="attachmentWrap">
                                    <Controller
                                        control={control}
                                        name={"skills"}
                                        defaultValue={[]}
                                        render={({ field: { value, onChange } }) => (
                                            <MultiSelect
                                                data={employeePayrollValue.skillsOptions?.filter(d => d.id.length > 0).map(d => ({ id: d.id, text: d.value }))}
                                                textField="text"
                                                dataItemKey="id"
                                                onChange={({ value }) => {
                                                    setValue(`skills`, value, { shouldDirty: true })
                                                }}
                                                itemRender={itemRender}
                                                value={value}
                                                placeholder="+ Select item"
                                                tagRender={tagRender}
                                                autoClose={false}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className="colBlk6 fldWrap">
                                <label>Payroll File# </label>
                                <input type="text" {...register('payrollFileNumber')} />
                            </div>
                            <div className="colBlk6 fldWrap">
                                <label>Job Title</label>
                                <input type="text" defaultValue="" {...register('jobTitle')} />
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className="colBlk6 fldWrap">
                                <label>Worker ID/ETime ID </label>
                                <input type="text" {...register('eTimeID')} />
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className="colBlk6 fldWrap">
                                <label>Benefits Eligibility Class Description</label>
                                <input type="text" {...register('benefitsEligibilityClassCodeDesc')} />
                            </div>
                            <div className="colBlk3 fldWrap">
                                <label>Benefits Eligibility Class Code</label>
                                <input type="text" {...register('benefitsEligibilityClassCode')} />
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className="colBlk6 fldWrap">
                                <label>Business Unit Description</label>
                                <input type="text" {...register('businessUnitCodeDesc')} />
                            </div>
                            <div className="colBlk3 fldWrap">
                                <label>Business Unit Code</label>
                                <input type="text" {...register('businessUnitCode')} />
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className="colBlk6 fldWrap">
                                <label>Home Cost Number Description</label>
                                <input type="text" {...register('homecostNumberCodeDesc')} />
                            </div>
                            <div className="colBlk3 fldWrap">
                                <label>Home Cost Number Code</label>
                                <input type="text" {...register('homeCostNumberCode')} />
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className="colBlk6 fldWrap">
                                <label>Manager Name</label>
                                <input type="text" {...register('managerName')} />
                            </div>
                            <div className="colBlk3 fldWrap">
                                <label>Manager ID</label>
                                <input type="text" {...register('managerId')} />
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className="colBlk6 fldWrap">
                                <label>Department Description</label>
                                <input type="text" {...register('departmentCodeDesc')} />
                            </div>
                            <div className="colBlk3 fldWrap">
                                <label>Department Code</label>
                                <input type="text" {...register('departmentCode')} />
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className="colBlk6 fldWrap">
                                <label>Location Description</label>
                                <input type="text" {...register('locationCodeDesc')} />
                            </div>
                            <div className="colBlk3 fldWrap">
                                <label>Location Code</label>
                                <input type="text" {...register('locationCode')} />
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className="colBlk6 fldWrap">
                                <label>Status Flag 7 Description</label>
                                <input type="text" {...register('statusFlagCodeDesc')} />
                            </div>
                            <div className="colBlk3 fldWrap">
                                <label>Status Flag 7 Code</label>
                                <input type="text" {...register('statusFlagCode')} />
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className="colBlk3 fldWrap">
                                <label>PayData/Payroll (ADP)</label>
                                <div className="customCheck yesNo">
                                    <input type="checkbox" id="PayData" {...register('payDataPayrollADPEnabled')} />
                                    <label className="customCheckLabel" htmlFor="PayData">
                                        yesNo
                                    </label>
                                </div>
                            </div>
                            <div className="colBlk3 fldWrap">
                                <label>Time & Attendance (ADP)</label>
                                <div className="customCheck yesNo">
                                    <input type="checkbox" id="Attendance" {...register('timeAndAttendanceADPEnabled')} />
                                    <label className="customCheckLabel" htmlFor="Attendance">
                                        yesNo
                                    </label>
                                </div>
                            </div>
                            <div className="colBlk3 fldWrap">
                                <label>Rate Type</label>
                                <input type="text" {...register("rateType")} />
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className="colBlk3 fldWrap">
                                <label>Contract Status</label>
                                <input type="text" {...register('contractStatus')} />
                            </div>
                            <div className="colBlk3 fldWrap">
                                <label>Hourly Rate</label>
                                <input type="text" {...register('hourlyrate')} />
                            </div>
                            <div className="colBlk3 fldWrap">
                                <label>Work Assignment ID</label>
                                <input type="text" {...register('workAssignmentID')} />
                            </div>
                            <div className="colBlk3 fldWrap">
                                <label>Payroll Group Code</label>
                                <input type="text" {...register('payrollGroupCode')} />
                            </div>
                        </div>
                    </div>
                </section>
                {
                    props.accessLevel.childMenu && props.accessLevel.childMenu > 1 && <div className="mainBtnWrap">
                        <a className="cursorPointer" onClick={props.formCancel}>Cancel</a>
                        <button disabled={!isDirty || !isValid} className="btnStyle">SAVE</button>
                    </div>
                }
            </form>
            <SiteLoader isActive={showLoader} />
        </Fragment>
    )
}