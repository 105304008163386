import { useState, useEffect } from "react";

const usePasswordValidation = ({

    firstPassword = "",
    secondPassword = "",
    requiredLength = 8,
    standard = true,
    accountId = "",
    userId = ""
}) => {
    const [validLength, setValidLength] = useState(null);
    const [hasNumber, setHasNumber] = useState(null);
    const [upperCase, setUpperCase] = useState(null);
    const [lowerCase, setLowerCase] = useState(null);
    const [specialChar, setSpecialChar] = useState(null);
    const [match, setMatch] = useState(null);
    const [accountIdMatch, setAccountIdMatch] = useState(null);
    const [userIdMatch, setUserIdMatch] = useState(null);


    useEffect(() => {

        setValidLength(firstPassword.length >= requiredLength ? true : false);
        setHasNumber(/\d/.test(firstPassword));
        setSpecialChar(/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(firstPassword));
        setMatch(firstPassword && firstPassword === secondPassword);
        
        if (!standard) {
            setUpperCase(firstPassword.toLowerCase() !== firstPassword);
            setLowerCase(firstPassword.toUpperCase() !== firstPassword);
            if (accountId) {
                setAccountIdMatch(!firstPassword.toLowerCase().includes(accountId.toLowerCase()))
            } else {
               setAccountIdMatch(true);
            }

            if (userId) {
                setUserIdMatch(!firstPassword.toLowerCase().includes(userId.toLowerCase()))
            } else {
                setUserIdMatch(true);
            }
        } else {
            setUpperCase(true);
            setLowerCase(true);
            setAccountIdMatch(true);
            setUserIdMatch(true);
        }

        

    }, [firstPassword, secondPassword, requiredLength]);

    return [validLength, hasNumber, upperCase, lowerCase, match, specialChar, accountIdMatch, userIdMatch];
};
export default usePasswordValidation;