import { apiProvider } from "../../../../services/api/provider";

export const handleFetchTimeClockReport = async (
  setTimeclockReportsData,
  params,
  setShowLoader
) => {
  try {
  const childAccountID = localStorage.getItem("childAccountId");

    setShowLoader(true);
    const resp = await apiProvider.getAll(
      `/track/mobile/v1/allgeo/monitor/timeClockReport?isAllAccount=${childAccountID === "all"}`,
      params
    );
    const result = resp.data?.data?.map((record, i) => {
        return { ...record, _id: i };
    });
    // console.log({...resp.data,["data"]:result},"{...resp.data,[data]:result}")

    // return resp.data; // Return the fetched data
    setTimeclockReportsData({...resp.data,["data"]:result});
   
  } catch (err) {
    console.error("Error fetching Miles Data", err);
    throw err; // Re-throw the error to be caught by the caller
  } finally {
    setShowLoader(false);
  }
};
