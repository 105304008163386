import React from 'react';


export const NoData = () => {
    return (
        <div className="no-data" style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
        }}>No records available</div>
    )
} 
