import React, { useState } from 'react';
import { apiProvider } from "../../../services/api/provider";
import ToastMessage from '../ToastMessage';
import alert from "../../../assets/images/alert.svg"

const PasswordValidationPopup = (props) => {

    const [passwordPopUp, setPasswordPopUp] = useState(props.show);
    const [password, setPassword] = useState('');
    const [reenteredPassword, setReenteredPassword] = useState('');
    const [oldPassword, setOldPassword] = useState('')
    const [passwordFieldType, setPasswordFieldType] = useState('password');
    const [passwordFieldTypeReType, setPasswordFieldTypeReType] = useState('password');
    const [passwordFieldOldPass, setPasswordFieldoldPass] = useState('text');

    const [errors, setErrors] = useState({
        minLength: false,
        hasNumber: false,
        hasSpecialChar: false,
        hasLowerCase: false,
        hasUpperCase: false,
        hasCompanyName: false,
        hasUserName: false
    });
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userName");
    const accountId = localStorage.getItem("accountId")
    const [toastMsg, setToastMsg] = useState({
        msg: "",
        type: "msgSuccess"
    });

    const handlePasswordChange = (event) => {
        const newPassword = event.target.value;

        setPassword(newPassword);

        const hasNumber = /\d/.test(newPassword);
        const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(newPassword);
        const hasLowerCase = /[a-z]/.test(newPassword);
        const hasUpperCase = /[A-Z]/.test(newPassword);
        const hasCompanyName = newPassword.toLowerCase().includes(localStorage.getItem('accountId')); //accountId
        const hasUserName = newPassword.toLowerCase().includes(localStorage.getItem('userName')); // managerId
        const passwordsMatch = newPassword === reenteredPassword;

        setErrors({
            minLength: newPassword.length >= 8,
            hasNumber,
            hasSpecialChar,
            hasLowerCase,
            hasUpperCase,
            hasCompanyName: !hasCompanyName,
            hasUserName: !hasUserName,
            match: passwordsMatch
        });
    };

    const handleReenteredPasswordChange = (event) => {
        const newReenteredPassword = event.target.value;
        setReenteredPassword(newReenteredPassword);
    };

    const passwordsMatch = password === reenteredPassword;


    const handleProceedClick = async () => {

        if (passwordsMatch) {

            try {
                const resetPasswordForm = {
                    accountID: accountId,
                    userID: userId,
                    newPassword: password,
                    oldPassword: oldPassword,
                    emailID: "",
                    loginToken: token
                };

                let response = await apiProvider.post("/track/react/v1/changepassword", resetPasswordForm)
                if (response.status === 200) {
                    setToastMsg({ msg: response.message, type: "msgSuccess" })
                    setPasswordPopUp(false)
                    localStorage.removeItem("actSecurityType");
                    localStorage.removeItem('userSecurityType')

                } else {
                    setToastMsg({ msg: response.message, type: "msgError" })
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    const changeFieldType = () => {
        passwordFieldType === 'password' ? setPasswordFieldType('text') : setPasswordFieldType('password')
    };

    const changeFieldTypeReType = () => {
        passwordFieldTypeReType === 'password' ? setPasswordFieldTypeReType('text') : setPasswordFieldTypeReType('password')
    };

    const changeFieldOldPass = () => {
        passwordFieldOldPass === 'password' ? setPasswordFieldoldPass('text') : setPasswordFieldoldPass('password')
    };


    return (
        <>
            {passwordPopUp &&
                <div>
                    <div className="popupWrap medPopup active">
                        <div className="popupHeading">Alert!</div>
                        <div className="popupContent">
                            <div className="popupTabCont">
                                <div className="alertMessageBox">
                                    <img src={alert} />
                                    <h4>Change Password</h4>
                                    <p>Your account security has been changed from standard to high<br />Please change your password to proceed.
                                    </p></div>
                                <div className="rowBlk">
                                    <div className="colBlk2 fldWrap labelPaddingTop">
                                        <label>Old Password</label>
                                    </div>
                                    <div className="colBlk4 fldWrap">
                                        <div className="fldWrap withShowPassword passwordFld">
                                            <input type={passwordFieldOldPass === 'password' ? "password" : "text"} placeholder="Password" fdprocessedid="h6d6y8" onChange={(e) => { let oldPass = e.target.value; setOldPassword(oldPass) }} />
                                            {
                                                passwordFieldOldPass === 'password' ? <span onClick={changeFieldOldPass} className="showPassword">Show</span> : passwordFieldOldPass === 'text' ? <span onClick={changeFieldOldPass} className="hidePassword">Show</span> : null
                                            }  
                                        </div>
                                    </div>
                                </div>

                                <div className="rowBlk">
                                    <div className="colBlk2 fldWrap labelPaddingTop">
                                        <label>New Password</label>
                                    </div>
                                    <div className="colBlk4 fldWrap">
                                        <div className="fldWrap withShowPassword passwordFld">
                                            <input
                                                type={passwordFieldType === 'password' ? "password" : ""}
                                                value={password}
                                                onChange={handlePasswordChange}
                                                placeholder="Password"
                                                fdprocessedid="nkcykr"
                                            />
                                            {
                                                passwordFieldType === 'password' ? <span onClick={changeFieldType} className="showPassword">Show</span> : passwordFieldType === 'text' ? <span onClick={changeFieldType} className="hidePassword">Show</span> : null
                                            }
                                        </div>
                                        <ul className="checkRoundListing">
                                            <li className={errors.minLength ? 'active' : ''}>8 characters long</li>
                                            <li className={errors.hasNumber ? 'active' : ''}>Minimum of one number</li>
                                            <li className={errors.hasSpecialChar ? 'active' : ''}>Minimum of one special character</li>
                                            <li className={errors.hasLowerCase ? 'active' : ''}>Minimum of one lower case character</li>
                                            <li className={errors.hasUpperCase ? 'active' : ''}>At least one upper case character</li>
                                            <li className={errors.hasCompanyName ? 'active' : ''}>Should not contain company name</li>
                                            <li className={errors.hasUserName ? 'active' : ''}>Should not contain user's name</li>
                                        </ul>
                                    </div>
                                    <div className="colBlk2 fldWrap">
                                        <label>Re-enter<br /> New Password</label>
                                    </div>
                                    <div className="colBlk4 fldWrap">
                                        <div className="fldWrap withShowPassword passwordFld">
                                            <input
                                                type={passwordFieldTypeReType === 'password' ? "password" : ""}
                                                value={reenteredPassword}
                                                onChange={handleReenteredPasswordChange}
                                                placeholder="Password"
                                                fdprocessedid="i2c3rk"
                                            />
                                            {
                                                passwordFieldTypeReType === 'password' ? <span onClick={changeFieldTypeReType} className="showPassword">Show</span> : passwordFieldTypeReType === 'text' ? <span onClick={changeFieldTypeReType} className="hidePassword">Show</span> : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="popupBtnWrap flexJCenter">
                            <button
                                className="btnStyle okBtn"
                                disabled={!passwordsMatch}
                                onClick={handleProceedClick}
                            >
                                Proceed
                            </button>

                        </div>
                    </div>
                </div>
            }
            {passwordPopUp && <div className='overlay active' />}

            {toastMsg.msg && <ToastMessage cssClass={toastMsg.type} message={toastMsg.msg} onClose={() => setToastMsg({ msg: '', type: '' })} />}
        </>

    )
}

export default PasswordValidationPopup