import React, { useEffect, useState, useRef, Fragment } from 'react'
import { apiProvider } from '../../../services/api/provider'
import AssignedUnassignedTable from './AssignedUnassignedTable'
import $ from "jquery";
import { Prompt } from 'react-router-dom';
import { ConfirmPopup } from '../../common/PopUp';
import { getAccountLabel } from '../../../utils';

const employeeType = {
    UNASSIGNED_EMP: "getUnassignedEmployees",
    ASSIGNED_EMP: "getAssignedEmployees"
}

const EditGroup = ({ groupData, mode, setMode, setToastMsg, setShowLoader }) => {

    let showPropmtConditon;

    const [payload, setPayload] = useState({
        groupId: groupData.groupID,
        groupName: groupData.groupDesc,
        colour: groupData.colour,
        unassignEmployees: [],
        assignEmployees: []
    })
    const childRef = useRef()
    const [isDirty, setIsDirty] = useState(false)
    const [showDialog, setShowDialog] = useState(false);


    if (childRef.current) {
        showPropmtConditon = isDirty || childRef.current.isTableDataDirty()
    } else {
        showPropmtConditon = isDirty
    }

    const fetchData = async type => {
        try {
            setShowLoader(true)
            return await apiProvider.getAll(`/track/mobile/v1/allgeo/${type}?groupId=${encodeURIComponent(groupData.groupID)}`);
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    const checkFormState = () => {

        return (
            payload.groupName !== groupData.groupDesc ||
            payload.colour !== groupData.colour
        )

    }

    const onSuccess = () => {
        setMode({
            ...mode,
            type: mode.hasPrev ? 'view' : '',
            hasPrev: false
        })
    }

    const onFaliure = () => {
        setShowDialog(false)
    }

    useEffect(() => {
        if (payload) {
            setIsDirty(checkFormState())
            localStorage.setItem('formIsDirty', checkFormState())
        }
    }, [payload])

    useEffect(async () => {
        const res = await Promise.all([fetchData(employeeType.UNASSIGNED_EMP), fetchData(employeeType.ASSIGNED_EMP)])
        setPayload({
            ...payload,
            unassignEmployees: res[0].unassignedEmployee,
            assignEmployees: res[1].assignedEmployees
        })
    }, [])

    const copyToClipBoard = (value) => {
        navigator.clipboard.writeText(value);
    }

    $(".copyIcon").on("click", function () {
        $(this).siblings('.copyClipboard').show().delay(4000).fadeOut();;
    });

    const groupLabel = getAccountLabel('group');
    const employeeLabel = getAccountLabel('employee');

    return (
        <Fragment>
            <Prompt
                when={showPropmtConditon}
                message='The changes are still unsaved. Are you sure you want to leave?'
            />
            <div className="mainContent" id="mainContent">
                <section>
                    <h3>Edit {groupLabel}</h3>
                    <div className="supportedTxt">Update {groupLabel} related information like {groupLabel} Name, Assigned {employeeLabel} etc here.</div>
                    <div className="innerWrap">
                        <div className="highlightedFldWrap">
                            <div className="fldWrap">
                                <label>Phone {groupLabel} ID</label>
                                <div className="highlightedValue">
                                    <span>{payload.groupId}</span>
                                    <span className="copyIcon" onClick={() => copyToClipBoard(payload.groupId)}>Copy</span>
                                    <span className="copyClipboard">Copied to the clipboard!</span>
                                </div>
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className="colBlk6 fldWrap">
                                <label>{employeeLabel} {groupLabel} Name</label>
                                <input
                                    type="text"
                                    value={payload.groupName}
                                    onChange={e => setPayload({
                                        ...payload,
                                        groupName: e.target.value
                                    })}
                                />
                            </div>
                            <div className="colBlk6 fldWrap">
                                <label>Assign a Color</label>
                                <div className="chooseColor">
                                    <div className="selectedColor" style={{ backgroundColor: payload.colour }}>Color</div>
                                    <input
                                        type="color"
                                        className="colorInput"
                                        id="editColor"
                                        onChange={e => setPayload({ ...payload, colour: e.target.value })}
                                        value={payload.colour}
                                    />
                                    <label htmlFor="editColor" className="editColor">Edit Color</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="employeeColWrap">
                        <AssignedUnassignedTable
                            formData={{
                                groupId: groupData.groupID,
                                groupName: groupData.groupDesc,
                                colour: groupData.colour
                            }}
                            updatedData={payload}
                            ref={childRef}
                            unassgnData={payload.unassignEmployees}
                            assgnData={payload.assignEmployees}
                            setToastMsg={setToastMsg}
                        />
                    </div>
                </section>
                <div className="mainBtnWrap">
                    <a
                        className='cursorPointer'
                        onClick={() => {
                            if (isDirty || childRef.current.isTableDataDirty()) {
                                setShowDialog(true);
                            } else {
                                setMode({
                                    data: {
                                        ...mode.data,
                                        groupDesc: payload.groupName,
                                        colour: payload.colour
                                    },
                                    type: mode.hasPrev ? 'view' : '',
                                    hasPrev: false
                                })
                            }
                        }} >Cancel</a>
                    <button className="btnStyle" onClick={() => {
                        setShowLoader(true)
                        localStorage.removeItem('formIsDirty')
                        childRef.current.updateData().then(res => {
                            if (res.status === 200) {
                                setIsDirty(false)
                                setMode({
                                    data: null,
                                    type: '',
                                    hasPrev: false
                                })
                            }
                            setToastMsg(res.message)
                        })
                    }}>SAVE</button>
                </div>
            </div>
            <ConfirmPopup
                showDialog={showDialog}
                message={"The changes are still unsaved. Are you sure you want to leave?"}
                confirmNavigation={onSuccess}
                cancelNavigation={onFaliure}
            />
        </Fragment>
    )
}

export default EditGroup