import { useEffect, useState } from "react";
import { getAccountLabel } from "../../../../utils";
import { Tooltip } from "@progress/kendo-react-tooltip";



const employeeLabel = getAccountLabel('employee');
const groupLabel = getAccountLabel('group');



export const titleMap = {
    "devicedesc": `${employeeLabel} Name and ${groupLabel}`,
    "deviceId": `${employeeLabel} Number`
}

const CustomHeaderCell = ({ title, tooltipText, field }) => {

    const [employeeLabel, setEmployeeLabel] = useState(getAccountLabel('employee'));
    const [groupLabel, setGroupLabel] = useState(getAccountLabel('group'));

    useEffect(() => {
        setEmployeeLabel(getAccountLabel('employee'));
        setGroupLabel(getAccountLabel('group'));
    }, []);

    let customTitle = titleMap[field] || title;

    const getToolTipText = () => {
        if(field === 'devicedesc') return `This column displays the ${employeeLabel} name and the ${groupLabel} he/she is assigned to. Click on the name to edit.`
        if(field === 'lastLocation') return `This column displays the last reported location of the ${employeeLabel}. Click on the address to open the same in Map view`
        if(field === 'jobStatus') return `This column displays the current Job-Status of the ${employeeLabel}`
        if(field === 'deviceId') return `This column displays the ${employeeLabel}'s phone number. Right click to display the current device details.(e.g. Battery Level, GPS/WiFi status, etc.)`
        else return tooltipText
    }

    const toolTipText = getToolTipText();
    return (
        <div className="displayFlex">
            <div>
                {customTitle}
            </div>
            <Tooltip position='top'>
                <span title={toolTipText} style={{ top: 0 }} className="helpTip">?</span>
            </Tooltip>
        </div>
    )
}


export const monitorEmployeesAppDataColumns = [
    // {
    //     title: "AccountID",
    //     field: "deviceDetails.accountID",
    //     editable: false,
    //     width: 130,
    //     editor: "text",
    //     columnMenu: false,
    //     show: true,
    //     filter: "text",
    //   },
    {
        title: `${employeeLabel} Name`,
        field: "employeeName",
        editable: false,
        width: "75%",
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true,    
    },
    {
        title: `${employeeLabel} Id`,
        field: "employeeID",
        editable: false,
        width: "75%",
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true,
        headerCell: <CustomHeaderCell
            title={`${employeeLabel} Id`}
            tooltipText={`This column displays the ${employeeLabel}'s phone number. Right click to display the current device details.(e.g. Battery Level, GPS/WiFi status, etc.)`}
            field={"employeeID"}
        />
    },
    {
        title: "Device Status",
        field: "appDataStatus.appStatus",
        editable: false,
        width: "100%",
        editor: "text",
        columnMenu: false,
        // statusChip:true,
        show: true,
        filter: "text",
        customCell: true,
        headerCell: <CustomHeaderCell
            title={"Device Status"}
            tooltipText={"This Column displays current device & schedule status. You can hover for troubleshooting tips."}
            field={"appDataStatus"}
        />
    },
    {
        title: "Location Tracking",
        field: "locationStatus.trackingStatus",
        editable: false,
        width: "78%",
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true,
        headerCell: <CustomHeaderCell
        title={"Location Tracking"}
        // tooltipText={`This column displays the last reported Location of the ${employeeLabel}. Click on the address to open the same in Map view`}
        />
    },
    // {
    //     title: "Job Status",
    //     field: "timeClockStatus.lastTimeclockStageName",
    //     editable: false,
    //     width: "115px",
    //     editor: "text",
    //     columnMenu: false,
    //     show: true,
    //     filter: "text",
    //     customCell: true
    // },
    {
        title: "Job Status",
        field: "timeClockStatus.lastTimeclockStageName",
        editable: false,
        width: "125px",
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        customCell: true,
        headerCell: <CustomHeaderCell
            title={"Job Status"}
            tooltipText={`This column displays the current Job-Status of the ${employeeLabel}`}
            field={"timeClockStatus.lastTimeclockStageName"}
            
        />
    },
    // {
    //     title: "Job Status",
    //     field: "jobStatus",
    //     editable: false,
    //     width: 230,
    //     editor: "text",
    //     columnMenu: false,
    //     show: true,
    //     filter: "text",
    //     customCell: true,
    //     headerCell: <CustomHeaderCell
    //         title={"Job Status"}
    //         tooltipText={`This column displays the current Job-Status of the ${employeeLabel}`}
    //         field={"jobStatus"}
            
    //     />
    // },
]