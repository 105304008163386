import React, { useMemo, useState, useEffect } from 'react'
import { apiProvider } from '../../../services/api/provider';
import { getAccountLabel } from '../../../utils';

const AssignForm = ({ forms, closePopup, setShowLoader, setToastMsg }) => {

    const [assignedTo, setAssignedTo] = useState("groups")
    const [groups, setGroups] = useState([])
    const [employeeCount, setEmployeeCount] = useState(0)

    const formIds = useMemo(() => forms.map(form => form.formId), [forms])
    const groupsSelection = useMemo(() => {
        const selectedGroups = groups.filter(grp => grp.selected);

        return {
            isAllSelected: selectedGroups.length === groups.length,
            count: selectedGroups.length
        }
    }, [groups])

    const toggleAssignment = e => setAssignedTo(e.target.name)


    const assignGroups = e => {
        const { id } = e.target;

        if (id === "all") {
            setGroups(groups.map(grp => ({
                ...grp,
                selected: !groupsSelection.isAllSelected
            })))
        } else {
            const tempArr = [...groups]
            tempArr[id].selected = !tempArr[id].selected;

            setGroups(tempArr)
        }

    }

    const fetchData = async () => {
        try {
            const response = await Promise.all([apiProvider.getAll(`/track/mobile/v1/allgeo/assignforms?ID=${formIds}`), apiProvider.getAll('/track/mobile/v1/allgeo/getEmployeeList?operationType=Desktop')])
            
            if (response[0].status === 200) {
                setGroups(response[0].groups)
            }

            if (response[1].status === 200) {
                setEmployeeCount(response[1].data.length)
            }

        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    const assignForms = async () => {
        
        try {
            setShowLoader(true)

            const payload = {
                formIDs: formIds,
                groupIDs: []
            }

            if (assignedTo === "groups") {
                payload.groupIDs = groups.filter(grp => grp.selected).map(grp => grp.groupID)
            } else {
                payload.groupIDs = ["all"]
            }

            const response = await apiProvider.put('/track/mobile/v1/allgeo/assignforms?cmd=assign', payload)

            if (response.status === 200) {
                setToastMsg({
                    msg: response.message,
                    type: "msgSuccess"
                })
                closePopup()
            }
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    const employeeLabel = getAccountLabel('employee');
    const groupLabel = getAccountLabel('group');

    return (
        <div className="popupWrap smlMedPopup active">
            <div className="closePopup" onClick={closePopup}>Close</div>
            <div className="popupHeading">
                <span>Assign Form</span>
            </div>
            <div className="popupContent viewPopup">
                <div className="viewLeft">
                    <label>Selected Form(s)</label>
                    <ul className="selectedList">
                        {
                            forms.map((form, ind) => (
                                <li key={ind} >{form.formName}</li>
                            ))
                        }
                    </ul>
                </div>
                <div className="viewRight">
                    <div className="fldWrap">
                        <label>Assign to</label>
                        <div className="customControls">
                            <div className="customRadio">
                                <input type="radio" id="Groups" name="groups" checked={assignedTo === 'groups'} onChange={toggleAssignment} />
                                <label htmlFor="Groups">{groupLabel}</label>
                            </div>
                            <div className="customRadio">
                                <input type="radio" id="AllEmployees" name="address" checked={assignedTo !== 'groups'} onChange={toggleAssignment} />
                                <label htmlFor="AllEmployees">All {employeeLabel}</label>
                            </div>
                        </div>
                    </div>
                    {
                        assignedTo === 'groups'
                            ?
                            <>
                                <label>{groupLabel} Assignment</label>
                                <div className="empSelectBlk">
                                    <div className="empListTbl tbl2">
                                        <table cellPadding="0" cellSpacing="0">
                                            <tbody>
                                                <tr>
                                                    <th className="checkboxCol">
                                                        <div className="customCheck noTxt">
                                                            <input type="checkbox" id="all" checked={groupsSelection.isAllSelected} onChange={assignGroups} />
                                                            <label className="customCheckLabel" htmlFor="all">All</label>
                                                        </div>
                                                    </th>
                                                    <th>{groupLabel} ID</th>
                                                    <th>{groupLabel} Desc</th>
                                                    <th>Device Count</th>
                                                </tr>
                                                {
                                                    groups.map((group, ind) => (
                                                        <tr key={ind} >
                                                            <td>
                                                                <div className="customCheck noTxt">
                                                                    <input type="checkbox" id={ind} checked={group.selected} onChange={assignGroups} />
                                                                    <label className="customCheckLabel" htmlFor={ind}>All</label>
                                                                </div>
                                                            </td>
                                                            <td>{group.groupID}</td>
                                                            <td>{group.groupDesc}</td>
                                                            <td>{group.devCount}</td>
                                                        </tr>
                                                    ))
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="empSelectFoot">
                                        <span>There are a total of <strong>{groupsSelection.count}</strong> selected.</span>
                                    </div>
                                </div>
                            </>
                            :
                            <div
                                className="highlightedSection inlineBlk">
                                All <strong>{employeeCount} {employeeLabel}</strong> can access this form.
                            </div>
                    }

                </div>
            </div>
            <div className="popupBtnWrap">
                <a className="btnStyle doneStep" onClick={assignForms}>Assign</a>
            </div>
        </div>
    )
}

export default AssignForm