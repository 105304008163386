export const scheduleColumnsName = [
    {
        title: "Schedule Name",
        field: "tripNumber",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true
    },
    {
        title: "Master ID",
        field: "mastertripNumber",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true
    },
    {
        title: "Assignee",
        field: "driverName",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true
    },
    {
        title: "Employee No.",
        field: "deviceID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true
    },
    {
        title: "Start Time",
        field: "fromTime",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true
    },
    {
        title: "Stop Time",
        field: "toTime",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true
    },
    {
        title: "Freq(mins)",
        field: "frequency",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true
    },
    {
        title: "Status",
        field: "status",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true
    },
    {
        title: "Type",
        field: "serviceType",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true
    },
    {
        title: "Pro #",
        field: "proNumber",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true
    },
    {
        title: "Profile #",
        field: "profileID",
        editable: false,
        width: 200,
        editor: "text",
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true
    },

]

export const SCHEDULE_TYPE = [
    {
        label: "Completed",
        value: "completed"
    },
    {
        label: "Uncompleted",
        value: "uncompleted"
    }
]

export const DEFAULT_SCHEDULE_TYPE = {
    label: "Select Schedule Type",
    value: "default"
}

export const DEFAULT_MODE = {
    title: "Active Schedule",
    type: "main",
    data: null,
    hasPrev: false,
}

export const DEFAULT_TOAST_MESSAGE = {
    msg: "",
    type: "msgSuccess"
}

export const ERROR_MESSAGE = {
    msg: "Please Select Type",
    type: "msgError"
}