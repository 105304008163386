import React from 'react'
import { getAccountLabel } from '../../../../../utils'

export const ViewWorkOrderData = ({ WOdata }) => {
    const jobsiteLabel = getAccountLabel('jobsite');
    const workOrderLabel = getAccountLabel('workorder');
    const employeeLabel = getAccountLabel('employee');
    return (
        <div className="modal_content_sec">
            <div className="row_wrk_blk">
                <div className="col_blk_wrk">
                    <div className="col_left">
                        <label>{workOrderLabel} No.</label>
                        <p>{WOdata.OwnerID}</p>
                    </div>
                    <div className="col_right">
                        <label>{employeeLabel}</label>
                        <p>{WOdata.DeviceName}</p>
                    </div>
                </div>
            </div>

            <div className="row_wrk_blk">
                <div className="col_blk_wrk">
                    <div className="col_left">
                        <label>Locate</label>
                        <p>{WOdata.Locatable}</p>
                    </div>
                    <div className="col_right">
                        <label>All Day Event</label>
                        <p>{WOdata.IsAllDay ? "Yes" : "No"}</p>
                    </div>
                </div>
            </div>

            <div className="row_wrk_blk">
                <div className="col_blk_wrk">
                    <div className="col_left">
                        <label>Start Day & Time</label>
                        <p>{WOdata.Start}</p>
                    </div>
                    <div className="col_right">
                        <label>End Day & Time</label>
                        <p>{WOdata.End}</p>
                    </div>
                </div>
            </div>

            <div className="hr_line"></div>

            <div className="row_wrk_blk">
                <div className="col_blk_wrk">
                    <div className="col_left">
                        <label>Repeat</label>
                        <p>Daily</p>
                    </div>
                    <div className="col_right">
                        <label>Ends</label>
                        <p>On {WOdata.End.split(" ")[0]}</p>
                    </div>
                </div>
            </div>

            <div className="hr_line"></div>

            <div className="row_wrk_blk">
                <div className="col_blk_wrk">
                    <div className="col_left">
                        <label>{jobsiteLabel}</label>
                        <p>India</p>
                    </div>
                    <div className="col_right">
                        <label>Job Name</label>
                        <p>{WOdata.JobName}</p>
                    </div>
                </div>
            </div>

            <div className="row_wrk_blk">
                <div className="col_blk_wrk">
                    <div className="col_left">
                        <label>Message</label>
                        <p>{WOdata.Description}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}