import React, { useState, useEffect } from 'react';
import { WorkOrderEdit } from '../EditWorkOrder';
import { DEFAULT_MODE } from '../utils/workOrderUtils'

export const WorkOrderConfirmPopUp = (props) => {
    const { WOdata, setToastMsg, setMode, mode, setEditConfirm } = props;
    const [confirmShow, setConfirmShow] = useState(false);
    const [showEditComponent, setShowEditComponent] = useState(false);

    useEffect(() => {
        setConfirmShow(!!WOdata.TaskID);
    }, [WOdata]);

    const handleEdit = () => {
        setShowEditComponent(true);
        setEditConfirm(true);
    };

    const closePopup = () => {
        setConfirmShow(false);
        setEditConfirm(false);
        setMode(DEFAULT_MODE);
    };

    return (
        <>
            {confirmShow &&
                <div className="popup_wrapper msg_popup confirm_msg active">
                    <div className="sm_modal">
                        <div className="msg_confirm_icon">Edit</div>
                        <div className="msg_content">
                            <div><strong>Edit Recurring?</strong>
                                <p>Do you want to edit only this event occurrence or the whole series?</p>
                            </div>
                            <button className="close_msg_btn"  onClick={closePopup}></button>
                        </div>
                    </div>
                    <div className="sm_popup_btn">
                        <button className="button_style btn2" onClick={handleEdit}>Edit Series</button>
                        <button className="button_style okBtn" onClick={handleEdit}>Edit Current Occurrence</button>
                    </div>
                </div>
            }
            {showEditComponent && <WorkOrderEdit WOdata={mode.data} mode={mode} setMode={setMode} setToastMsg={setToastMsg} />}
            {confirmShow && <div className="overlay active"></div>}
        </>
    );
};
