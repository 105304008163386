export function handleResponse(response) {
  if (response?.results?.status === 403 || response?.data?.status === 403) {
    window.location.replace(`${process.env.REACT_APP_API_URL}/logout`);
  } else {
    if (response.results) {
      return response.results;
    }
    if (response.data) {
      return response.data;
    }
    return response;
  }
}

export function handleError(error) {
  console.error('handleError2', error);
  if (error.data) {
    return error.data;
  }
  return error;
}