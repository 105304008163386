import React from "react";

export default function Unauthorized() {

  return (
    <div className="userPageWrapper notFound">
      <div className="rightContent">
        <h2>Unauthorized</h2>
        <p>Unauthorized Page Requested.</p>
        <a href={`${process.env.REACT_APP_API_URL}/track/mgt?page=phonelocate.v1`}>
          Back
        </a>
      </div>
    </div>
  );
}
