import React, { useEffect, useState, useRef } from 'react'
import ErrorDetailReportHeader from './ErrorDetailReportHeader'
import { apiProvider } from '../../../../services/api/provider';
import { formatDate } from '../../../common/utils';
const dateFormat = 'YYYY/MM/DD';
import SiteLoader from '../../../common/SiteLoader';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import { getAccountLabel } from "../../../../utils"

const taskLabel = getAccountLabel('task')

const ErrorDetailReport = ({ errorDetail, orgParams }) => {
  const tableRef = useRef(null)
  const tableData = errorDetail && errorDetail.dataList
  const [errorDetails, setErrorDetails] = useState(tableData);
  const [searchTerm, setSearchTerm] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const startDate = new Date(orgParams.date_fr)
  const endDate = new Date(orgParams.date_to)

  startDate.setHours(0, 0, 0)
  endDate.setHours(23, 59, 0)

  const [generalSettingsData, setGeneralSettingsData] = useState({
    dateDisplayFormat: 'dd/MM/yyyy',
    timeFormat: 'hh:mm:ss a',
    timezone: 'GMT+05:30'
  })

  const [params, setParams] = useState({
    date_fr: startDate,
    date_to: endDate,
    reportFor: 'all',
    tzStr: 'GMT+05:30',
    device_tz_selected: "",
    offsetStr: "",
    limit: "",
    advance: "no",
    action: 'all',
    isV3Report: true,
  })

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  }

  const filteredReportData = errorDetails ? errorDetails.filter(item =>
    Object.values(item).some(value =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  ) : [];

  const refreshFunc = async () => {
    setShowLoader(true);
    try {
      const queryParams = {
        ...params,
        date_fr: `${formatDate(params.date_fr, dateFormat)}/${params.date_fr.toTimeString().slice(0, 5)}`,
        date_to: `${formatDate(params.date_to, dateFormat)}/${params.date_to.toTimeString().slice(0, 5)}`,
      };
      const response = await apiProvider.getAll(`/track/mobile/v1/allgeo/errorDetailReport`, queryParams);
      setErrorDetails(response.dataList);
    } catch (error) {
      console.log(error);
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    refreshFunc();
  }, []);

  const ErrorReportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.table_to_sheet(tableRef.current); // Convert table to worksheet
    XLSX.utils.book_append_sheet(wb, ws, 'Error Detail Report'); // Add worksheet to workbook
    XLSX.writeFile(wb, 'error_detail_report.xlsx'); // Trigger file download
  };

  useEffect(() => {
    // checkForResult()
    setErrorDetails(tableData)
  }, [tableData])

  const handleExportToPDF = () => {
    const pdf = new jsPDF('p', 'pt', 'letter');
    const lineHeight = 10

    html2canvas(tableRef.current, { scale: 2 }).then(canvas => {
      const imgData = canvas.toDataURL('image/jpeg', 2.0);
      pdf.text('Error Detail Report', 10, 20);
      pdf.addImage(imgData, 'JPEG', 10, 10 + 3 * lineHeight, 592, 150);
      pdf.save('error_detail_report.pdf');
    });
  };

  return (
    <>
      <ErrorDetailReportHeader generalSettingsData={generalSettingsData} params={params} setParams={setParams} handleSearchChange={handleSearchChange} searchTerm={searchTerm} refreshFunc={refreshFunc} errorDetails={errorDetails} ErrorReportToExcel={ErrorReportToExcel} handleExportToPDF={handleExportToPDF} setShowLoader={setShowLoader} />
      <br />
      <div>
        <table table ref={tableRef}>
          <thead>
            <tr>
              <th>Time</th>
              <th>Service</th>
              <th>{taskLabel}</th>
              <th>Status</th>
              <th>Request</th>
              <th>Details</th>
              <th>Comment</th>
              <th>Correlation</th>
            </tr>
          </thead>
          <tbody>
            {filteredReportData && filteredReportData.length > 0 ? filteredReportData.map((data, index) => (
              <tr key={index}>
                <td style={{ width: '10%' }}>{data.time}</td>
                <td style={{ width: '10%' }}>{data.service}</td>
                <td style={{ width: '10%' }}>{data.task}</td>
                <td style={{ width: '10%' }}>{data.status}</td>
                <td style={{ width: '20%' }}>{data.request}</td>
                <td style={{ width: '20%' }}>{data.details}</td>
                <td style={{ width: '10%' }}>{data.comment}</td>
                <td style={{ width: '10%' }}>{data.correlation}</td>
              </tr>

              // ))}
            )) : (
              <tr>
                <td colSpan="8">
                  <p className='noData'>No data found</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <SiteLoader isActive={showLoader} />
    </>
  )
}

export default ErrorDetailReport