export const saveChanges = async (props) => {

    /*EDIT REPORT MODULE HAS DIFFERENT FLOW FOR INLINE EDITING OF MANDATORY FIELD, ELSE BLOCK CONTAINS LOGIC FOR ALL THE OTHER MODULES */
    if (props?.module === props?.moduleTypes.editReports) {

        if (props?.updatedData.length > 0) {
            let unfilledMandatoryCols = []

            const partialMandatoryFields = ['managerNotes']

            // Manager Notes needs to be replaced with new value if a single record is edited and Manager Notes field is not edited.
            // if (updatedData.length === 1 && updatedData[0]['managerNotes']) {

            //     const { id } = updatedData[0]
            //     const dirtyFields = dirty[id]

            //     const isManagerNotesEdited = dirtyFields['managerNotes']

            //     if (!isManagerNotesEdited) {
            //         props?.setMsgClass("")
            //         props?.setToastMsg("Please update the Manager Notes Field.")

            //         return false
            //     }

            // }

            props?.updatedData?.map(modifiedRecord => {
                unfilledMandatoryCols = props?.mandatoryCols.filter(fld => {

                    if (props?.updatedData.length > 1) {

                        return !partialMandatoryFields.includes(fld) && (modifiedRecord[fld] ? modifiedRecord[fld].trim().length === 0 : true)
                    }

                    return modifiedRecord[fld] ? modifiedRecord[fld].trim().length === 0 : true
                }
                ).map(fld => fld)
            })

            // if (unfilledMandatoryCols.length > 0) {
            //     props?.setMsgClass("")

            //     const fieldsText = unfilledMandatoryCols.map((field, index) => (
            //         <span key={field}>
            //             <strong>{getMandatoryColumnTitle(field)}</strong>
            //             {index < unfilledMandatoryCols.length - 1 && ", "}
            //         </span>
            //     ));

            //     props?.setToastMsg(
            //         <>
            //             Please enter the mandatory field(s): {fieldsText} to proceed.
            //         </>
            //     );

            //     return false;
            // }

        }
        if (props?.createdData.length > 0) {
            let unfilledMandatoryCols = []

            props?.createdData.map(modifiedRecord => {
                unfilledMandatoryCols = props?.mandatoryCols.filter(fld => modifiedRecord[fld].trim().length === 0).map(fld => fld)
            })

            if (unfilledMandatoryCols.length > 0) {
                props?.setMsgClass("")
                const fieldsText = unfilledMandatoryCols.map((field, index) => (
                    <span key={field}>
                        <strong>{props?.getMandatoryColumnTitle(field)}</strong>
                        {index < unfilledMandatoryCols.length - 1 && ", "}
                    </span>
                ));

                props?.setToastMsg(
                    <>
                        Please enter the mandatory field(s): {fieldsText} to proceed.
                    </>
                );
                return false;
            }

        }

    } else if (props?.data.length > 0) {
        const mandatoryFailCheck = props?.mandatoryCols.map(fld => props?.data[0][fld].trim().length).some(ln => ln === 0);
        if (mandatoryFailCheck) {
            const unfilledMandatoryCols = props?.mandatoryCols.filter(fld => props?.data[0][fld].trim().length === 0).map(fld => fld)
            props?.setMsgClass("")
            props?.setToastMsg(`Please enter the mandatory ${unfilledMandatoryCols.length === 1 ? 'field' : 'fields'} ` + unfilledMandatoryCols.map(field => {
                return " " + props?.getMandatoryColumnTitle(field)
            }).toString() + " to proceed.")
            return false;
        }

    }

    if (props?.createdData.length > 0) {
        props?.setShowLoader(true)
        const res = await props?.createItem(props?.createdData)

        if (res?.status && res?.status === 200) {
            props?.setShowLoader(false)
            props?.setMsgClass("msgSuccess")
            props?.setToastMsg(res.message)
        } else {
            props?.setShowLoader(false)
            props?.setMsgClass("msgError")
            props?.setToastMsg(res.message)
            return false
        }

        props?.setCreatedData([])
    }
    if (props?.updatedData.length > 0) {
        props?.setShowLoader(true)
        const res = await props?.updateItem(props?.updatedData)

        if (res?.status && res?.status === 200) {
            props?.setShowLoader(false)
            props?.setMsgClass("msgSuccess")
            props?.setToastMsg(res.message)
        } else {
            props?.setShowLoader(false)
            props?.setMsgClass("msgError")
            props?.setToastMsg(res.message)
            return false
        }

        props?.setUpdatedData([])
    }

    if (props?.createdData.length > 0 || props?.updatedData.length > 0) {
        props?.refreshList()
        props?.setSelectedState({})
        props?.setEditID(null)
        props?.setChanges(false);
    }

    return true
};
