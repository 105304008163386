import React, { useEffect } from 'react';
import { filterKeys } from "./helperFunctions/helperFunction";
import { DropdownList } from '../../common/dropdown'




export const ReportSelectorAccounts = ({ isAnyReportAllowed, reportMapping, reportConfig, setReportConfig, handleDropdownChange, availableReport, setParams, params }) => {

    useEffect(() => {
        // Set default report if the current one is not allowed
        const firstAllowedReport = filterKeys(reportMapping, reportConfig).find(key => {
            const report = reportMapping[reportConfig.type][key];
            return Array.isArray(availableReport) && availableReport.includes(report.reportapikey);
        });

        if (firstAllowedReport) {
            setReportConfig({ ...reportConfig, report: firstAllowedReport });
        }

    }, [reportConfig.type]);
    return (
        <>
            {!isAnyReportAllowed && (
                <div>No Reports Available For Your Account</div>
            )}

            {isAnyReportAllowed && (
                <ul className="boxBlkList">
                    {filterKeys(reportMapping, reportConfig).map(key => {

                        const report = reportMapping[reportConfig.type][key];
                        const isAllowed = Array.isArray(availableReport) && availableReport.includes(report.reportapikey);


                        if (isAllowed) {
                            return (
                                <li
                                    key={key}
                                    className={`${reportConfig.report === report.type && 'active'}`}
                                    onClick={() => { if (reportConfig.report !== report.type) setReportConfig({ ...reportConfig, report: report.type }) }}
                                >
                                    <div className="boxHeading">{report.title}</div>
                                    <div className="check-row">

                                    </div>
                                    {report.dropdownName}  {report.dropdown && <DropdownList style={{ width: "100px" }} list={report.dropdowndata} defaultValue={report.type === "type-2" ? params.reportType : report.dropdowndata[0].value} onChange={(e) => {
                                        if (report.type === "type-2") {
                                            handleDropdownChange(e)
                                        } else {
                                            setParams({ ...params, action: e.target.value.id });
                                        }

                                    }} />}
                                    <h7 style={{marginLeft:"10px"}}></h7>
                                    {report.dropdownName1} {report.dropdown2 && <DropdownList style={{ width: "100px !important" }} list={report.dropdowndata2} defaultValue={report.dropdowndata2[0].label} onChange={(e) => { setParams({ ...params, accountSelected: e.value.id }) }} />}
                                    <p>{report.headerText}</p>
                                    {report.description &&
                                        <div className="boxNote">
                                            <span>Report Description</span>
                                            <em>{report.description}</em><br />
                                            <em>{report.description1}</em>
                                        </div>
                                    }
                                </li>
                            );
                        }
                        else {
                            return null;
                        }
                    })}
                </ul>
            )}
        </>
    );
};