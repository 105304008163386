import React, { useState, useEffect } from "react";


export default function BillingPopUp(props) { 
    return (
        <div className="popupWrap fullPopup active">
                <div className="closePopup" onClick={() => props.closePopUp()}>Close</div>
            <div className="popupHeading">Invoice</div>
            <div className="mainContent2">
                <iframe style={{border: 'none'}} width="100%" height="100%" src={props.invoiceURL} title="description"></iframe>
            </div>
        </div>
       
        
    )
}