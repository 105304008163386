import { useState, useEffect } from "react";
import { apiProvider } from '../../../services/api/provider';
import { getWeekDayString, repeatType, reportRanges } from './reportSchedulerUtils';

const moment = window.moment;

const ViewReportScheduler = (props) => {

    const [schedulerDetails, setSchedulerDetails] = useState({});

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            props.setShowLoader(true)
            const response = await getScheduleData()
            if (response.status === 200 && response.message.toLowerCase() === 'success') {
                if (response.data.length > 0) {
                    setSchedulerDetails(response.data[0])
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            props.setShowLoader(false)
        }
    }

    const getScheduleData = async () => {
        try {
            return await apiProvider.getSingle(`track/mobile/v1/allgeo/schedule?type=Report&scheduleId=${props.scheduleId}`)
        } catch (error) {
            console.log(error);
        }
    }

    const getReportForData = (params) => {
        if (params) {
            if ((schedulerDetails?.scheduleFor).toLowerCase() === 'all') {
                return 'All'
            } else {

                return params === "Group"
                    ? props.reportForData.groups.filter((ele) => ele.value === schedulerDetails?.scheduleFor?.toLowerCase())[0]?.label
                    : props.reportForData.employees.filter((ele) => ele.value === schedulerDetails?.scheduleFor?.toLowerCase())[0]?.label

            }
        }
    }

    const getReportNames = (arr) => {
        if (arr && arr.length > 0) {
            let txt = '';

            arr = arr.filter((d, ind) => arr.indexOf(d) === ind)

            arr.forEach((element, index) => {
                if (index === arr.length - 1) {
                    txt += { ...schedulerDetails?.customReportMap, ...schedulerDetails?.standardReportMap }[element]
                } else {
                    txt += `${{ ...schedulerDetails?.customReportMap, ...schedulerDetails?.standardReportMap }[element]}, `
                }
            });

            return txt;
        }
        return '-'
    }

    const generateNotificationEmailText = () => {
        if (schedulerDetails?.accountNotificationEmail === true) {
            return `Account(${schedulerDetails?.notificationEmail})`
        } else if (schedulerDetails?.managerNotificationEmail === true) {
            return `Manager(${schedulerDetails?.notificationEmail})`
        } else {
            return `AccouDevicent(${schedulerDetails?.notificationEmail})`
        }
    }

    return (
        <div className="popupWrap smlMedPopup active">
            <div className="popupHeading noClose">
                <span>View Report Scheduler</span>
                <div className="headingCtrl">
                    <a href={void (0)} className={`editGrp${props.accessLevel < 2 ? ' disabled' : ''}`}
                        onClick={() => {
                            props.setShowLoader(true)
                            props.setMode({
                                title: "Report",
                                type: 'edit',
                                data: props.mode.data,
                                hasPrev: true
                            })
                        }}>Edit Scheduler</a>
                    <a href={void (0)} className={`deleteGrp${props.accessLevel < 3 ? ' disabled' : ''}`}
                        onClick={() => {
                            props.setMode({
                                title: "Report",
                                type: "delete",
                                data: props.mode.data,
                                hasPrev: true
                            })
                        }}>Delete Scheduler</a>
                </div>
            </div>
            <div className="popupContent">
                <div className="popupTabCont">
                    <div className="towColView">
                        <div className="fldWrap">
                            <label>Scheduler ID</label>
                            <p>{schedulerDetails?.scheduleId}</p>
                        </div>
                        <div className="fldWrap">
                            <label>Status</label>
                            <p className="txtActive">{schedulerDetails?.active ? "Active" : "Inactive"}</p>
                        </div>
                    </div>
                    <hr />
                    <div className="sectionSubHeading">Reports to Send</div>
                    <div className="towColView">
                        <div className="fldWrap">
                            <label>The scheduler will send</label>
                            <p>{getReportNames(schedulerDetails?.selectedReport)}</p>
                        </div>
                    </div>
                    <hr />
                    <div className="sectionSubHeading">The report will list</div>
                    <div className="towColView">
                        <div className="fldWrap">
                            <label>The report will list</label>
                            <p>{getReportForData(schedulerDetails?.assignType)}</p>
                        </div>
                    </div>
                    <hr />
                    <div className="sectionSubHeading">Range of the report</div>
                    <div className="towColView">
                        <div className="fldWrap">
                            <label>Report Range</label>
                            <p>{schedulerDetails?.reportRange}</p>
                        </div>
                        {
                            schedulerDetails?.reportRange === reportRanges.TIME_RANGE ?
                                <>
                                    <div className="fldWrap">
                                        <label>Start Time</label>
                                        <p>{moment(`2022-01-01 ${schedulerDetails.reportFromTime}`).format(`${props.timeFormat}`)}</p>
                                    </div>
                                    <div className="fldWrap">
                                        <label>End Time</label>
                                        <p>{moment(`2022-01-01 ${schedulerDetails.reportToTime}`).format(`${props.timeFormat}`)}</p>
                                    </div>
                                </>
                                :
                                <div className="fldWrap">
                                    <label>Last</label>
                                    <p>{schedulerDetails?.reportLastHrs}</p>
                                </div>
                        }
                    </div>
                    <hr />
                    <div className="sectionSubHeading">Scheduler Details</div>
                    <div className="towColView">
                        <div className="fldWrap">
                            <label>Start Date</label>
                            <p>
                                {
                                    schedulerDetails?.scheduleType === "recurring"
                                        ? moment(schedulerDetails.scheduleStartOn).format(`${props.dateFormat}`)
                                        : moment(schedulerDetails.startDate).format(`${props.dateFormat}`)
                                }
                            </p>
                        </div>
                        {
                            schedulerDetails?.scheduleType === "recurring" &&
                            <div className="fldWrap">
                                <label>Repeat</label>
                                <p>{schedulerDetails?.repeatType === repeatType.DAILY ? "Daily" : "Weekly"}</p>
                            </div>
                        }
                        {
                            schedulerDetails?.scheduleType === "recurring" && schedulerDetails?.repeatType !== repeatType.DAILY &&
                            <div className="fldWrap">
                                <label>Selected Days</label>
                                <p>{getWeekDayString(schedulerDetails?.trackingDays)}</p>
                            </div>
                        }
                        <div className="fldWrap">
                            <label>Ends</label>
                            {
                                schedulerDetails?.scheduleEndOn !== 'never'
                                    ? <p>On
                                        {
                                            ` ${schedulerDetails?.scheduleType === "recurring"
                                                ? moment(schedulerDetails.scheduleEndOn).format(`${props.dateFormat}`)
                                                : moment(schedulerDetails.endDate).format(`${props.dateFormat}`)}`
                                        }
                                    </p>
                                    : <p>Never</p>
                            }
                        </div>
                    </div>
                    <hr />
                    <div className="sectionSubHeading">When do you want to receive the report?</div>
                    <div className="towColView">
                        <div className="fldWrap">
                            <label>Time</label>
                            <p>{moment(`2022-01-01 ${schedulerDetails?.startTime}`).format(`${props.timeFormat}`)}</p>
                        </div>
                        <div className="fldWrap">
                            <label>Timezone</label>
                            <p>{schedulerDetails?.timezone}</p>
                        </div>
                    </div>
                    <hr />
                    <div className="sectionSubHeading">Report Recipients</div>
                    <div className="towColView">
                        <div className="fldWrap">
                            <label>Report Medium</label>
                            <p>{Object.keys(schedulerDetails).length > 0 && schedulerDetails?.reportOption ? schedulerDetails?.reportOption[0]?.toUpperCase() + schedulerDetails?.reportOption.substring(1) : "-"}</p>
                        </div>
                        <div className="fldWrap">
                            <label>Report Format</label>
                            <p>{schedulerDetails?.attachmentType}</p>
                        </div>
                        <div className="fldWrap">
                            <label>Notifications Email</label>
                            <p>{Object.keys(schedulerDetails).length > 0 && generateNotificationEmailText()}</p>
                        </div>
                        {schedulerDetails?.reportSendToEmails !== "" &&
                            <div className="fldWrap">
                                <label>Additional Email</label>
                                <p>{schedulerDetails?.reportSendToEmails}</p>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="popupBtnWrap flexJCenter">
                <a href={void (0)} className="btnStyle doneStep" onClick={() => {
                    props.setMode({
                        title: "Report",
                        type: "main",
                        data: null,
                        hasPrev: false
                    })
                }}>Done</a>
            </div>
        </div>
    )
}

export default ViewReportScheduler