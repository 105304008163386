import React, { useEffect, useRef, useState } from 'react'
import { reportMapping, customExportFunction, exportToCSVforPARC, exportToEXCELforPARC } from './runReportUtils'
import KendoGrid from '../../common/kendo/KendoGrid'
import { Download, Email } from './Popups'
import { formatDate } from '../../common/utils'
import { apiProvider } from '../../../services/api/provider'
import { useHistory, useLocation } from 'react-router-dom'
import SiteLoader from '../../common/SiteLoader'
import ToastMessage from '../../common/ToastMessage'
import { secondsToHHMMSS } from '../../common/kendo/kendoUtils'
import { DROPDOWN_COLUMN_MAPPING } from './MyReportsUtils/myReportUtils'
import { handleCustomReportOptions } from './helperFunctions/helperFunction'
import { DeviceAuditReport } from './DeviceAuditReport/DeviceAuditReport'
import { DEBUG_DROPDOWN_COLUMN_MAPPING } from './DebugLogsUtils/debugLogsUtils'
import ErrorDetailReport from './ErrorDetailReport/ErrorDetailReport'
const dateFormat = 'YYYY/MM/DD'
import { savingHistoryResponseModification } from './DebugLogsUtils/helperfunctions'

const RunReportTable = () => {

    const { selectedReport, reportForData, reportConfig, params, timeFormat, timezone, dateDisplayFormat, setParams } = JSON.parse(sessionStorage.getItem('runReports'))

    if (reportConfig.type === 'customReport' && reportConfig.report === 'type-1') {
        handleCustomReportOptions(params, selectedReport, DROPDOWN_COLUMN_MAPPING);
    }

    if (reportConfig.type === 'debugLogs' && reportConfig.report === 'type-1') {
        handleCustomReportOptions(params, selectedReport, DEBUG_DROPDOWN_COLUMN_MAPPING);
    }

    const location = useLocation()
    const history = useHistory()

    const childRef = useRef()
    const downloadRef = useRef()

    const [popup, setPopup] = useState(null)
    const [reportData, setReportData] = useState(null);
    const [showLoader, setShowLoader] = useState(false);
    const [toastMsg, setToastMsg] = useState({
        msg: "",
        type: "msgSuccess"
    });
    const [selectedReportColumns, setSelectedReportColumns] = useState(selectedReport.column)
    const [queryParams, setQueryParams] = useState(params)
    const [timeFormate, setTimeFormate] = useState('')
    const [auditData, setAuditData] = useState(null)
    const [errorDetail, setErrorDetail] = useState(null)

    const fetchReport = async () => {
        const title = selectedReport.title
        let reportParams = selectedReport.reportParams;

        if (selectedReport.dynamicReportParams) {
            if (reportConfig.option !== "group") {
                reportParams = {
                    ...reportParams,
                    deviceID: params.reportFor
                }
                delete reportParams.groupID
            } else {
                reportParams = {
                    ...reportParams,
                    groupID: params.reportFor
                }
            }
        }

        if (title === "Basic Automatic") {
            let basicAutoParams = await apiProvider.getAll('track/react/account/mobileApp')
            let { auto_arrive_stage, auto_depart_stage } = basicAutoParams
            reportParams = {
                ...reportParams,
                stageid: `${auto_arrive_stage},${auto_depart_stage},false,none,auto`

            }
        }

        params.date_fr = new Date(params.date_fr)
        params.date_to = new Date(params.date_to)

        const queryParams = {
            ...params,
            date_fr: `${formatDate(params.date_fr, dateFormat)}/${params.date_fr.toTimeString().slice(0, 5)}`,
            date_to: `${formatDate(params.date_to, dateFormat)}/${params.date_to.toTimeString().slice(0, 5)}`,
            ...reportParams
        }

        setQueryParams(queryParams)


        try {
            setShowLoader(true)

            if (reportConfig.type === 'customReport' && reportConfig.report === 'type-7') {
                const response = await apiProvider.getAll('track/mobile/v1/allgeo/parcPaydataCustomReportHeaders', queryParams)
                const colname = response.map((item) => {
                    return {
                        title: item.key,
                        field: item.title,
                        editable: false,
                        width: 200,
                        editor: "text",
                        columnMenu: false,
                        show: true,
                        filter: "text",
                    }
                })
                setSelectedReportColumns(colname)
                sessionStorage.setItem("parcHeaders", JSON.stringify(colname));
            }

            const response = await apiProvider.getAll(selectedReport.api, queryParams)

            selectedReport?.title === "Device Audit Report" ? setAuditData(response) : setAuditData(null)

            selectedReport?.title === 'Error Detail Report' ? setErrorDetail(response) : setErrorDetail(null)

            const timeclockSettings = await apiProvider.getAll('track/react/account/generalSetting');

            setTimeFormate(timeclockSettings.report_units.minutes)
            if (selectedReport.isEditHistoryReport) {
                const x = savingHistoryResponseModification(response)
                setReportData(x)
                return
            }
            if (typeof response === 'object' && response.length > 0) {
                setReportData(response)
            } else setReportData([])

        } catch (error) {
            console.log(error)
        } finally {
            setShowLoader(false)
        }
    }

    const openPopup = (type, data) => {
        if (type === 'latlong' || type === 'latlog' || type === 'address' || type === 'locAddress') {
            const invalidLinkTexts = ['n/a', 'Invalid GPS']
            const isLink = !invalidLinkTexts.includes(data[type])

            if (!isLink) return

            window.open(`https://maps.google.com/maps?q=${data[type]}`, "_blank")
        } else {
            setPopup(type)
        }

    }

    const closePopup = () => {
        setPopup(null)
    }

    const handleDownload = async reportType => {
        switch (reportType) {
            case 'XLSX':
                if (reportConfig.type === 'customReport' && reportConfig.report === 'type-7') {
                    const parcHeaders = JSON.parse(sessionStorage.getItem("parcHeaders"));
                    exportToEXCELforPARC(childRef.current?.exportToExcelRef.current, parcHeaders, reportData)
                } else {
                    customExportFunction(childRef.current?.gridRef?.current, childRef.current?.exportToExcelRef.current, selectedReport)
                }
                break;
            case 'PDF':
                childRef.current.exportToPdf();
                break;
            case 'CSV':
                if (reportConfig.type === 'customReport' && reportConfig.report === 'type-7') {
                    const parcHeaders = JSON.parse(sessionStorage.getItem("parcHeaders"));

                    exportToCSVforPARC(parcHeaders, reportData, selectedReport.title)
                } else {
                    const { workbookOptions } = childRef.current?.exportToExcelRef.current;
                    const { columnFormatMap } = selectedReport;

                    const options = workbookOptions();
                    const rows = options.sheets[0].rows;
                    const headers = rows[0].cells.filter(cell => typeof cell.value !== 'undefined');

                    const indexFormatMap = {};

                    headers.forEach((cell, ind) => {
                        const columnName = cell.value;
                        if (columnFormatMap && columnFormatMap[columnName]) {
                            indexFormatMap[ind] = columnFormatMap[columnName];
                        }
                    });

                    let dataRows = [];

                    rows.forEach((row) => {
                        const csvFormattedString = row.cells
                            .filter(cell => typeof cell.value !== 'undefined')
                            .map((cell, ind) => {
                                if (row.type === "data") {
                                    const format = indexFormatMap[ind];
                                    if (format === 'hh:mm:ss') {
                                        return secondsToHHMMSS(cell.value);
                                    }
                                }
                                return `"${cell.value}"`;
                            }).join(",");

                        dataRows.push(csvFormattedString);
                    });

                    dataRows = dataRows.join("\n");

                    const csvContent = [];
                    csvContent.push(dataRows);

                    const csvString = csvContent.join("\n");
                    const blob = new Blob([csvString], { type: 'text/csv' });
                    const downloadURL = window.URL.createObjectURL(blob);
                    const anchorElement = downloadRef.current;

                    anchorElement.href = downloadURL;
                    anchorElement.download = selectedReport.title + ".csv";
                    anchorElement.click();
                    window.URL.revokeObjectURL(downloadURL);
                }

                break;
            default:
                break;
        }
    }

    const goBack = () => {
        setReportData(null)
        localStorage.setItem("ShowG2PopupReport", true)
        history.push(`${location.pathname}?tab=runReports`)
    }

    useEffect(() => {
        fetchReport()
    }, [])


   

    return (
        <>
            <section>
                <div className="sectionSubHeading">{selectedReport.title}</div>
                {selectedReport.title === "Error Detail Report" && <ErrorDetailReport errorDetail={errorDetail} orgParams={params} />}
                {selectedReport.title === "Device Audit Report" && <DeviceAuditReport auditData={auditData} selectedReport={selectedReport} reportConfig={reportConfig} reportForData={reportForData} setShowLoader={setShowLoader} orgParams={params} />}
                {
                    reportData && selectedReport.title != "Error Detail Report" && selectedReport.title !== "Device Audit Report" &&
                    <KendoGrid
                        gridData={reportData}
                        setGridData={setReportData}
                        columnData={selectedReportColumns}
                        accessLevel={{ childMenu: 3 }}
                        ref={childRef}
                        module={"runNBuildReports"}
                        getAPiUrl={selectedReport.api}
                        requestParams={queryParams}
                        openPopup={openPopup}
                        otherData={{
                            hideSaveCancelChanges: true,
                            actionColumnBtns: [],
                            isCustomReport:selectedReport.isCustomReport,
                            reportName:selectedReport.title,
                            dropdownData: {
                                device: reportForData.employees,
                                groups: reportForData.groups,
                                selectedOption: reportConfig.option,
                                timezone,
                                dateDisplayFormat,
                                timeFormat,
                                filterType: selectedReport.filterType,
                                isEditHistoryReport: selectedReport.isEditHistoryReport,
                            },
                            minutesFormat: timeFormate,
                            customRow: selectedReport.customRow || false,
                            customGroupHeader: selectedReport.customGroupHeader || {},
                            subPage: selectedReport.subPage,
                            disableExportToExcel: true,
                            exportFileName: selectedReport.title,
                            defaultGroupedColumns: selectedReport.defaultGroupedColumns || null,
                            enableFooterDisplay: selectedReport.aggregates ? true : false,
                            aggregates: selectedReport.aggregates,
                            aggregateText: selectedReport.aggregateText,
                            filterDataState: selectedReport.dataState,
                            isCustomReport: selectedReport?.isCustomReport
                        }}
                    />
                }
                <div className="mainBtnWrap">
                    <button className="btnStyle" onClick={goBack} >Go Back</button>
                </div>
                {popup === 'download' && <Download handleDownload={handleDownload} closePopup={closePopup} />}
                {popup === 'email' && <Email selectedReport={selectedReport} gridRef={childRef.current} setShowLoader={setShowLoader} closePopup={closePopup} setToastMsg={setToastMsg} />}
            </section>
            <div className={`overlay ${popup ? 'active' : ''}`} />

            <a hidden ref={downloadRef} />

            {toastMsg.msg && <ToastMessage
                cssClass={toastMsg.type}
                message={toastMsg.msg}
                onClose={() => setToastMsg({ msg: "", type: "" })}
            />}
            <SiteLoader isActive={showLoader} />
        </>
    )
}

export default RunReportTable