export function isReportAllowed(reportConfig, reportMapping, availableReport) {

    if (!reportMapping.hasOwnProperty(reportConfig.type)) {
        // If reportConfig.type doesn't exist in reportMapping, return false
        return false;
    }

    const reportTypeMapping = reportMapping[reportConfig.type];

    if (reportConfig.type === 'customReport') {
        // For customReport type, check if any reportapikey is allowed
        return Array.isArray(availableReport) && Object.values(reportTypeMapping).some(report => availableReport.includes(report.reportapikey));
    } else if (reportConfig.type === 'accounts') {
        // For accounts report type, check if any reportapikey is allowed
        return Array.isArray(availableReport) && Object.values(reportTypeMapping).some(report => availableReport.includes(report.reportapikey));
    }
    else if (reportConfig.type === 'debugLogs') {
        // For debugLogs type, check if any reportapikey is allowed
        return Array.isArray(availableReport) && Object.values(reportTypeMapping).some(report => availableReport.includes(report.reportapikey));
    } else {
        // For other report types, return true
        return true;
    }
}


export const filterKeys = (reportMapping, reportConfig) => {
    return Object.keys(reportMapping[reportConfig.type])
        .filter(key => !reportMapping[reportConfig.type][key].disabled);
}

export const handleCustomReportOptions = (params, selectedReport, DROPDOWN_COLUMN_MAPPING) => {
    const option = params.adpOption;
    if (DROPDOWN_COLUMN_MAPPING.hasOwnProperty(option)) {
        const { column, api } = DROPDOWN_COLUMN_MAPPING[option];
        selectedReport.column = column;
        if (api) {
            selectedReport.api = api;
        }
    }
}

export const getCustomReportApiUrls = (moduleName) => {
    const apiUrls = {
        standardTime: 'track/mobile/v1/allgeo/timeclockstandardreport',
        advanceTimeClockBuildReports: 'track/mobile/v1/allgeo/evvcustomtimeclockreport',
        messageReport: 'track/mobile/v1/allgeo/messagereport',
        trackingReport: 'track/mobile/v1/allgeo/trackingreport',
        WOPerformaceReport: 'track/mobile/v1/allgeo/workorderreport',
    };

    return apiUrls[moduleName] || 'Invalid module name';
}

export const setColumnFormatMap = (selectedReport) => {
    switch (selectedReport.masterTemplateType) {
        case "trackingReport":
            return {
                "Hours": 'hh:mm:ss'
            };
        case "standardTime":
            return {
                "Total Service Hours": 'hh:mm:ss',
                "Service Time": 'hh:mm:ss'
            };
        case "advanceTimeClockBuildReports":
            return {
                "Total Service Hours": 'hh:mm:ss',
                "Total Hours Worked": 'hh:mm:ss',
                "Total Punch Hours": 'hh:mm:ss',
                "Regular Service Hours": 'hh:mm:ss',
                "Over Time hours": 'hh:mm:ss',
                "Double Over Time Hours": 'hh:mm:ss'
            };
        default:
            return {};
    }
};
