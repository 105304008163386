import React from 'react'
import './ViewCurrentSchedule.css'

export const ViewCurrentSchedule = ({ scheduleData }) => {
    return (
        <>
            <div className='vcs_main_div'>
                <div className='vcs_row'>
                    <div className='vcs_col_main'>
                        <div className='vcs_col'>  Schedule No. </div>
                        <div className='vcs_col_data'>  {scheduleData?.scheduleNo || ""}</div>
                    </div>
                    <div className='vcs_col_main'>
                        <div className='vcs_col'>  Pro Number. </div>
                        <div className='vcs_col_data'>  {scheduleData?.proNumber || ""}</div>
                    </div>
                    <div className='vcs_col_main'>
                        <div className='vcs_col'>  Schedule Name </div>
                        <div className='vcs_col_data'>  {scheduleData?.driverScheduleName || ""}</div>
                    </div>
                </div>

                <div className='vcs_row'>
                    <div className='vcs_col_main'>
                        <div className='vcs_col'>  Auto Track Max Count </div>
                        <div className='vcs_col_data'>  {scheduleData?.autoTrackMaxCount || ""}</div>
                    </div>
                    <div className='vcs_col_main'>
                        <div className='vcs_col'>  Device</div>
                        <div className='vcs_col_data'>  {scheduleData?.device || ""}</div>
                    </div>
                    <div className='vcs_col_main'>
                        <div className='vcs_col'>  Timezone </div>
                        <div className='vcs_col_data'>  {scheduleData?.timeZone || ""}</div>
                    </div>
                </div>


                <div className='vcs_row'>
                    <div className='vcs_col_main'>
                        <div className='vcs_col'>  Frequency Level </div>
                        <div className='vcs_col_data'>  {scheduleData?.frequencyLevel || ""}</div>
                    </div>
                    <div className='vcs_col_main'>
                        <div className='vcs_col'>  Frequency </div>
                        <div className='vcs_col_data'>  {scheduleData?.frequency || ""}</div>
                    </div>
                    <div className='vcs_col_main'>
                        <div className='vcs_col'>  Service Type </div>
                        <div className='vcs_col_data'>  {scheduleData?.serviceType || ""}</div>
                    </div>
                </div>



                <div className='vcs_row'>
                    <div className='vcs_col_main'>
                        <div className='vcs_col'> Rule Interval </div>
                        <div className='vcs_col_data'>  {scheduleData?.ruleInterval || ""}</div>
                    </div>
                    <div className='vcs_col_main'>
                        <div className='vcs_col'>  Messages </div>
                        <div className='vcs_col_data'>  {scheduleData?.message || ""}</div>
                    </div>
                    <div className='vcs_col_main'>
                        <div className='vcs_col'> Is USPS </div>
                        <div className='vcs_col_data'>  {scheduleData?.isUSPS || ""}</div>
                    </div>
                </div>

                <div className='vcs_row'>
                    <div className='vcs_col_main'>
                        <div className='vcs_col'> Contract No. </div>
                        <div className='vcs_col_data'>  {scheduleData?.contractNo || ""}</div>
                    </div>
                    <div className='vcs_col_main'>
                        <div className='vcs_col'>  USPS TripID </div>
                        <div className='vcs_col_data'>  {scheduleData?.uspsTripId || ""}</div>
                    </div>
                    <div className='vcs_col_main'>
                        <div className='vcs_col'>  Schedule Options </div>
                        <div className='vcs_col_data'>  {scheduleData?.scheduleOptions || ""}</div>
                    </div>
                </div>


                <div className='vcs_row'>
                    <div className='vcs_col_main'>
                        <div className='vcs_col'> Routes </div>
                        <div className='vcs_col_data'>  {scheduleData?.routes || ""}</div>
                    </div>
                    <div className='vcs_col_main'>
                        <div className='vcs_col'>  Track Always </div>
                        <div className='vcs_col_data'>  {scheduleData?.trackAlways || ""}</div>
                    </div>
                    <div className='vcs_col_main'>
                        <div className='vcs_col'>  Tracking Level </div>
                        <div className='vcs_col_data'>  {scheduleData?.trackingLevel || ""}</div>
                    </div>
                </div>

                <div className="hr_line"></div>

                <div className="vcs_row">
                    <h2>Daily Timings</h2>
                </div>

                <div className='vcs_row'>
                    <div className='vcs_col_main'>
                        <div className='vcs_col'> From </div>
                        <div className='vcs_col_data'>  {scheduleData?.dailyTimingsFrom || ""}</div>
                    </div>
                    <div className='vcs_col_main'>
                        <div className='vcs_col'>  To </div>
                        <div className='vcs_col_data'>  {scheduleData?.dailyTimingsTo || ""}</div>
                    </div>
                </div>

                <div className="hr_line"></div>

                <div className="vcs_row">
                    <h2>Daily Legs</h2>
                </div>

                <div className='vcs_row'>
                    <div className='vcs_col_main'>
                        <div className='vcs_col'> From </div>
                        <div className='vcs_col_data'>  {scheduleData?.dailyLegsFrom || ""}</div>
                    </div>
                    <div className='vcs_col_main'>
                        <div className='vcs_col'>  To </div>
                        <div className='vcs_col_data'>  {scheduleData?.dailyLegsTo || ""}</div>
                    </div>
                </div>
            </div>
        </>
    )
}