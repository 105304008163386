import React, { useEffect, useState } from "react";
import "./sendMessageModal.css";
import DownArrow from "../../../../assets/images/downarrow.svg";
import { TextArea } from "@progress/kendo-react-inputs";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { InputSuffix } from "@progress/kendo-react-inputs";
import { SvgIcon } from "@progress/kendo-react-common";
import { userIcon, warningCircleIcon } from "@progress/kendo-svg-icons";
import { Chip } from "@progress/kendo-react-buttons";
import { countries } from "../../utils/constants";
import { getAccountLabel } from "../../../../utils";
import { apiProvider } from "../../../../services/api/provider";
// import { filterBy } from '@progress/kendo-data-query';
export const SendMessageModal = ({
  closePopup,
  msgTemplates,
  employeeData,
  setShowMainLoader,
  setToastMsg,
  handleRefreshNotificationData,
}) => {
  const [valuess, setValue] = React.useState([]);
  const [employeesList, setEmployeesList] = React.useState([]);
  const [templateMsg, setTemplateMsg] = React.useState("");
  const [messageType, setMessageType] = React.useState("inApp");
  const [selectedTemplate, setSelectedTemplate] = React.useState("None");
  // const [values, setValues] = React.useState([]);
  const [isSelectedAll, setIsSelectedAll] = useState(false);

  let disabled = templateMsg?.trim().length === 0 || valuess.map((d) => d.id).length===0
  const employeeLabel = getAccountLabel("employee");



  const handleChanges = (event) => {
    const currentSelectAll = valuess.some((i) => i.text === "Select All");
    const nextSelectAll = event.value.some((i) => i.text === "Select All");
    setIsSelectedAll(nextSelectAll);
    let value = event.value;
    const currentCount = valuess.length;
    const nextCount = value.length;
    console.log("==========>", currentSelectAll, nextSelectAll);
    if (
      nextCount > currentCount &&
      !currentSelectAll &&
      !nextSelectAll &&
      employeesList.length - 1 === nextCount
    ) {
      value = employeesList;
    } else if (
      nextCount < currentCount &&
      currentCount === employeesList.length &&
      currentSelectAll &&
      nextSelectAll
    ) {
      value = value.filter((v) => v.text !== "Select All");
    } else if (!currentSelectAll && nextSelectAll) {
      value = employeesList;
    } else if (currentSelectAll && !nextSelectAll) {
      value = [];
    }
    console.log("value----->", value);

    setValue(value);
  };



  const selectTemplate = (e) => {
    const { value } = e.target;

    setSelectedTemplate(value);
    setTemplateMsg(value === "None" ? "" : value);
  };

  const sendMessage = async () => {
    try {
      setShowMainLoader(true);

      const deviceIds = valuess.map((d) => d.id);

      const payload = {
        devices: isSelectedAll ? deviceIds.slice(1) : deviceIds,
        type: messageType,
        message: templateMsg,
        messageTitle: msgTemplates[selectedTemplate],
      };

      const response = await apiProvider.post(
        `track/react/v1/allgeo/monitor/sendSMS`,
        payload
      );
      // alert(`Message sent to ${data.length} ${employeeLabel}.`);

      if (response.status === 200) {
        closePopup();
        setToastMsg({
          msg: response.message,
          type: "msgSuccess",
        });
        handleRefreshNotificationData();
      } else {
        setToastMsg({ msg: response.message, type: "msgError" });
        closePopup();
      }
    } catch (error) {
      // setToastMsg({ msg: error.message, type: "msgError" });
      console.log(error);
    } finally {
      setShowMainLoader(false);
    }
  };

  const itemRender = (li, itemProps) => {
    const index = itemProps.index;

    const itemChildren = (
      <>
        <input type="checkbox" checked={itemProps.selected} />
        <span
          style={{
            color: "#58595B",
            fontWeight: 500,
            fontSize: 12,
          }}
        >
          {li.props.children}
        </span>
      </>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  const tagRender = (selectedTagProps) => (
    <>
      {selectedTagProps.text !== "Select All" && (
        <span>
          {selectedTagProps?.text && (
            <Chip className="">{selectedTagProps.text}</Chip>
          )}
          {isSelectedAll
            ? valuess.length > 5 && (
                <span className="multiple-select-count">
                  +{valuess.length - 5?.toString().padStart(2, "0")}
                </span>
              )
            : valuess.length > 4 && (
                <span className="multiple-select-count">
                  +{valuess.length - 4?.toString().padStart(2, "0")}
                </span>
              )}
        </span>
      )}
    </>
  );

  useEffect(() => {
    const empList = employeeData?.map((emp) => {
      return { text: emp.employeeName, id: emp.phoneNumber  };
    });
    setEmployeesList([{ text: "Select All", id: "All" }, ...empList]);
  }, []);

  const filterBy = (allData, filter) => {
    return allData.filter((data) =>
      data.text.toLowerCase().includes(filter.value.toLowerCase())
    );
  };

  const filterChange = (event) => {
    const filter = event.filter;
    console.log(filter, "filtersss");

    const allData = employeeData?.map((emp) => {
      return { text: emp.employeeName, id: emp.phoneNumber  };
    });
    const newData =
      filter.value.length > 3
        ? filterBy(allData, filter)
        : employeeData?.map((emp) => {
            return { text: emp.employeeName, id: emp.phoneNumber  };
          });
    setEmployeesList(newData);
  };

  console.log(employeesList, "employeesList");

  // useEffect(() => {
  //   setDisabled(
  //     templateMsg?.trim().length === 0 || valuess.map((d) => d.id).length === 0
  //   );
  // }, [templateMsg, valuess]);

  return (
    <div>
      <div className="overlay3"></div>
      <div className="sendparentBox">
        <div>
          <h2
            style={{
              backgroundColor: "#F5FCFF",
              borderRadius: "8px 8px 0 0",
              color: "#25A3D6",
            }}
            className="sendheadingtag"
          >
            Send Message
          </h2>
          <div className="closePopup" onClick={closePopup}>
            Close
          </div>
        </div>
        <div className="sendinnerbox">
          <div>
            <label htmlFor="">Select {employeeLabel}</label>
            <MultiSelect
              // footer={header}
              data={employeesList}
              itemRender={itemRender}
              onChange={handleChanges}
              value={valuess}
              filterable={true}
              textField="text"
              dataItemKey="id"
              onFilterChange={filterChange}
              allowCustom={true}
              style={{
                width: "100%",
                position: "relative",
                height: "36px",
              }}
              placeholder="Please select ..."
              tagRender={tagRender}
              tags={isSelectedAll ? valuess.slice(0, 5) : valuess.slice(0, 4)}
            />
          </div>
          <div className="radiomainbox">
            <label htmlFor="">Channel</label>
            <div className="sendsubinnerbox radiosendbox">
              <span>
                <input
                  type="radio"
                  id="SMS"
                  name="Channel"
                  value={messageType}
                  onClick={() => setMessageType("sms")}
                />
                <label htmlFor="SMS">SMS</label>
              </span>
              <span>
                <input
                  type="radio"
                  id="InApp"
                  name="Channel"
                  defaultChecked
                  value={messageType}
                  onClick={() => setMessageType("inApp")}
                />
                <label htmlFor="InApp">In App</label>
              </span>
            </div>
          </div>

          <div className="fldWrap">
            <label>Template</label>
            <select onChange={selectTemplate}>
              <option key={"none"} value={"None"}>
                None
              </option>
              {Object.keys(msgTemplates).map((templateKey) => (
                <option key={templateKey} value={templateKey}>
                  {msgTemplates[templateKey]}
                </option>
              ))}
            </select>
          </div>

          {/* <div>
            <label htmlFor="">Templates</label>
            <div className="sendsubinnerbox">
              <select defaultValue={"Select"}>
                <option>asdfg</option>
                <option>asdfg</option>
                <option>asdfg</option>
              </select>
              <img src={DownArrow} className="arrowicon" />
            </div>
          </div> */}
          <div className="textareabox">
            <label htmlFor="">Message</label>
            {/* <TextArea  rows={4} cols={4} placeholder="Enter message" /> */}
            <textarea
              placeholder="Type Our Own Message"
              value={templateMsg}
              onChange={(e) => {
                setTemplateMsg(e.target.value);
              }}
            ></textarea>
          </div>
        </div>

        <div className="closebtn">
          <button
            disabled={disabled}
            onClick={sendMessage}
            className="btnStyle okBtn sendbuttondiv"
          >
            Send Message
          </button>
        </div>
      </div>
    </div>
  );
};
