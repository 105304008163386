import React, { Fragment } from 'react';
import Header from '../layout/Header';
import { useDocumentTitle } from '../../hooks/setDocumentTitle';
import { NavLink, useLocation } from 'react-router-dom'
import MonitorEmployees from './MonitorEmployees/MonitorEmployees';
import LocateSchedulers from './LocateSchedulers/LocateSchedulers';
import { getAccountLabel } from '../../utils';

const ReportModuleWrapper = () => {
    const location = useLocation()

    useDocumentTitle("Monitor - allGeo");

    const [_, baseUrl, pathName] = location.pathname.split('/')

    const isAdminUser = localStorage.getItem('userName')?.toLowerCase() === 'admin'

    const employeeLabel = getAccountLabel('employee');

    return (
        <Fragment>
            <div className='mainContentWrap'>
                <Header title={pathName === "monitor-employees" ? "Monitor" : "Scheduler"} />
                <div className='mainContent' id='mainContent'>
                    <ul className="rightInnerNav">
                        <li>
                            {
                                isAdminUser
                                    ?
                                    <NavLink to="/monitor/monitor-employees" activeclassname="active">Monitor {employeeLabel}</NavLink>
                                    :
                                    <a href={`${process.env.REACT_APP_API_URL}/track/mgt?page=phonelocate.v1`} >Monitor {employeeLabel}</a>
                            }
                        </li>
                        <li>
                            <NavLink to="/monitor/locate-schedule" activeclassname="active">Tracking Scheduler</NavLink>
                        </li>
                    </ul>
                    {
                        pathName === "monitor-employees" && <MonitorEmployees />
                    }
                    {
                        pathName === "locate-schedule" && <LocateSchedulers pathName={pathName} />
                    }
                </div>
            </div>
        </Fragment>
    )
}

export default ReportModuleWrapper