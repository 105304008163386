import { getAccountLabel } from "../../../utils";

const jobsiteLabel = getAccountLabel('jobsite');
const employeeLabel = getAccountLabel('employee');
const groupLabel = getAccountLabel('group');


export const employeeListColumns = [
    {
        title: "Phone Number",
        field: "phoneNumber",
        editable: false,
        width: 200,
        editor: "text",
        orderIndex: 1,
        columnMenu: false,
        show: true,
        filter: "text"
    },
    {
        title: `${employeeLabel} ID`,
        field: "employeeId",
        editable: true,
        width: 200,
        editor: "text",
        orderIndex: 2,
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: true
    },
    {
        title: `${employeeLabel} First Name`,
        field: "firstName",
        editable: true,
        width: 200,
        editor: "text",
        orderIndex: 3,
        show: true,
        filter: "text",
        mandatory: true
    },
    {
        title: `${employeeLabel} Last Name`,
        field: "lastName",
        editable: true,
        width: 200,
        editor: "text",
        orderIndex: 3,
        show: true,
        filter: "text",
        mandatory: false
    },
    {
        title: `${employeeLabel} Description`,
        field: "employeeDescription",
        editable: true,
        width: 200,
        editor: "text",
        orderIndex: 4,
        show: true,
        filter: "text"
    },
    {
        title: `Assigned ${jobsiteLabel}`,
        field: "assignedCustomers",
        editable: false,
        width: 200,
        editor: "text",
        orderIndex: 5,
        columnMenu: false,
        show: true,
        filter: "text",
        mandatory: false
    },
    {
        title: `${groupLabel}`,
        field: "groups",
        editable: false,
        width: 400,
        editor: "text",
        orderIndex: 6,
        show: true,
        filter: "text"
    },
    {
        title: "Time Zone",
        field: "timeZone",
        editable: true,
        width: 200,
        editor: "text",
        orderIndex: 7,
        show: true,
        filter: "text",
        dropdownCell: true
    },
    {
        title: `${employeeLabel} Status`,
        field: "status",
        editable: false,
        width: 200,
        editor: "text",
        orderIndex: 8,
        show: true,
        filter: "text",
        dropdownCell: true
    },
    {
        title: "Payroll File#",
        field: "payrollFileNumber",
        editable: false,
        width: 200,
        editor: "text",
        orderIndex: 8,
        show: true,
        filter: "text"
    },
    {
        title: "Created Date & Time",
        field: "creationDateTime",
        editable: false,
        width: 200,
        editor: "text",
        orderIndex: 9,
        show: true,
        filter: "text"
    },
    {
        title: "Landline",
        field: "landLine",
        editable: false,
        width: 200,
        editor: "text",
        orderIndex: 10,
        show: false,
        filter: "text"
    },
    {
        title: "Landline Address",
        field: "landlineAddress",
        editable: false,
        width: 200,
        editor: "text",
        orderIndex: 11,
        show: false,
        filter: "text"
    }
]

export const timeZones = [
    {
        value: "US/Hawaii",
        label: "US/Hawaii"
    },
    {
        value: "US/Alaska",
        label: "US/Alaska"
    },
    {
        value: "US/Pacific",
        label: "US/Pacific"
    },
    {
        value: "US/Arizona",
        label: "US/Arizona"
    },
    {
        value: "US/Mountain",
        label: "US/Mountain"
    },
    {
        value: "US/Central",
        label: "US/Central"
    },
    {
        value: "US/Eastern",
        label: "US/Eastern"
    },
    {
        value: "Canada/Pacific",
        label: "Canada/Pacific"
    },
    {
        value: "Canada/Mountain",
        label: "Canada/Mountain"
    },
    {
        value: "Canada/Central",
        label: "Canada/Central"
    },
    {
        value: "Canada/Eastern",
        label: "Canada/Eastern"
    },
    {
        value: "Canada/Atlantic",
        label: "Canada/Atlantic"
    },
    {
        value: "Mexico/BajaNorte",
        label: "Mexico/BajaNorte"
    },
    {
        value: "Mexico/BajaSur",
        label: "Mexico/BajaSur"
    },
    {
        value: "Mexico/General",
        label: "Mexico/General"
    },
    {
        value: "Europe/Athens",
        label: "Europe/Athens"
    },
    {
        value: "Europe/Berlin",
        label: "Europe/Berlin"
    },
    {
        value: "Europe/Dublin",
        label: "Europe/Dublin"
    },
    {
        value: "Europe/Helsinki",
        label: "Europe/Helsinki"
    },
    {
        value: "Europe/Kiev",
        label: "Europe/Kiev"
    },
    {
        value: "Europe/Lisbon",
        label: "Europe/Lisbon"
    },
    {
        value: "Europe/London",
        label: "Europe/London"
    },
    {
        value: "Europe/Madrid",
        label: "Europe/Madrid"
    },
    {
        value: "Europe/Moscow",
        label: "Europe/Moscow"
    },
    {
        value: "Europe/Oslo",
        label: "Europe/Oslo"
    },
    {
        value: "Europe/Paris",
        label: "Europe/Paris"
    },
    {
        value: "Europe/Rome",
        label: "Europe/Rome"
    },
    {
        value: "Europe/Stockholm",
        label: "Europe/Stockholm"
    },
    {
        value: "Europe/Zurich",
        label: "Europe/Zurich"
    },
    {
        value: "Pacific/Auckland",
        label: "Pacific/Auckland"
    },
    {
        value: "Pacific/Chatham",
        label: "Pacific/Chatham"
    },
    {
        value: "GMT+00:00",
        label: "GMT+00:00"
    },
    {
        value: "GMT+01:00",
        label: "GMT+01:00"
    },
    {
        value: "GMT+02:00",
        label: "GMT+02:00"
    },
    {
        value: "GMT+03:00",
        label: "GMT+03:00"
    },
    {
        value: "GMT+04:00",
        label: "GMT+04:00"
    },
    {
        value: "GMT+05:00",
        label: "GMT+05:00"
    },
    {
        value: "GMT+05:30",
        label: "GMT+05:30"
    },
    {
        value: "GMT+06:00",
        label: "GMT+06:00"
    },
    {
        value: "GMT+07:00",
        label: "GMT+07:00"
    },
    {
        value: "GMT+08:00",
        label: "GMT+08:00"
    },
    {
        value: "GMT+09:00",
        label: "GMT+09:00"
    },
    {
        value: "GMT+10:00",
        label: "GMT+10:00"
    },
    {
        value: "GMT+11:00",
        label: "GMT+11:00"
    },
    {
        value: "GMT+12:00",
        label: "GMT+12:00"
    },
    {
        value: "GMT+13:00",
        label: "GMT+13:00"
    },
    {
        value: "GMT+14:00",
        label: "GMT+14:00"
    },
    {
        value: "GMT-01:00",
        label: "GMT-01:00"
    },
    {
        value: "GMT-02:00",
        label: "GMT-02:00"
    },
    {
        value: "GMT-03:00",
        label: "GMT-03:00"
    },
    {
        value: "GMT-04:00",
        label: "GMT-04:00"
    },
    {
        value: "GMT-05:00",
        label: "GMT-05:00"
    },
    {
        value: "GMT-06:00",
        label: "GMT-06:00"
    },
    {
        value: "GMT-07:00",
        label: "GMT-07:00"
    },
    {
        value: "GMT-08:00",
        label: "GMT-08:00"
    },
    {
        value: "GMT-09:00",
        label: "GMT-09:00"
    },
    {
        value: "GMT-10:00",
        label: "GMT-10:00"
    },
    {
        value: "GMT-11:00",
        label: "GMT-11:00"
    },
    {
        value: "GMT-12:00",
        label: "GMT-12:00"
    },

]

export const days = [
    {
        value: -1,
        label: "Select"
    },
    {
        value: 0,
        label: "Sunday"
    },
    {
        value: 1,
        label: "Monday"
    },
    {
        value: 2,
        label: "Tuesday"
    },
    {
        value: 3,
        label: "Wednesday"
    },
    {
        value: 4,
        label: "Thursday"
    },
    {
        value: 5,
        label: "Friday"
    },
    {
        value: 6,
        label: "Saturday"
    },
];
