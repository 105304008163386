import { apiProvider } from "../../../../../services/api/provider";

export const getEmp = async (props) => {
    try {
      props.setShowLoader(true);
      const response = await apiProvider.getAll(
        "/track/mobile/v1/allgeo/getEmployeeGroup"
      );
      if (response.status === 200) {
        props.setEmp(response.data);
        // setRefreshKey((prevKey) => prevKey + 1);
      }
    } catch (error) {
      console.log(error);
    } finally {
      props.setShowLoader(false);
    }
  };