import React, { Fragment, useEffect, useState } from "react";
import SiteLogo from "../site-logo";
import { useLocation, NavLink, useHistory } from "react-router-dom";
import axios from 'axios';
import { apiProvider } from '../../services/api/provider';
import useRoute from "../../hooks/useRoute";
import { Tooltip } from '@progress/kendo-react-tooltip';
import { getAccountLabel } from "../../utils";

export const helpCenterLinks = {
  settings: "http://help.allgeo.com/en/articles/6340835-company-account-settings",
  groups: "http://help.allgeo.com/en/articles/6353860-company-groups",
  employees: "http://help.allgeo.com/en/articles/6379839-company-employees",
  managers: "http://help.allgeo.com/en/articles/6419611-company-managers",
  jobsites: "http://help.allgeo.com/en/articles/6401240-company-job-site",
  tasks: "http://help.allgeo.com/en/articles/6583542-company-tasks",
  forms: "http://help.allgeo.com/en/articles/6627423-company-forms",
  "locate-schedule": "https://help.allgeo.com/en/articles/6748239-monitor-locate-scheduler",
  "monitor-employees": "https://help.allgeo.com/en/articles/7935968-monitor-employees",
  defaultLink: "https://help.allgeo.com/en/collections/2510470-managers-getting-started-with-allgeo-web-application-4-0"
}

const SiteNavigation = (_props) => {

  const isAdminUser = localStorage.getItem('userName')?.toLowerCase() === 'admin'
  const isNewUser = localStorage.getItem('isNewUser') === 'true'
  const isAdminLogin = localStorage.getItem('isAdminLogin');
  const monitorEndPoint = `${JSON.parse(localStorage.getItem('isNewMonitorPage')) ?  "/monitor/new-monitor-employees" : "/monitor/monitor-employees"}`
  
  const { setMenuItems } = useRoute()
  let location = useLocation();
  const BASE_URL = process.env.REACT_APP_API_URL;
  const history = useHistory();
  const menuAPIResource = '/track/react/account/getMenuList';

  const [baseModule, setBaseModule] = useState('');
  const [page, setPage] = useState('');
  const [menuList, setMenuList] = useState([]);
  const [companySubMenu, setCompanySubMenu] = useState([]);
  const [reportSubMenu, setReportSubMenu] = useState([]);
  const [scheduleSubMenu, setScheduleSubMenu] = useState([]);

  useEffect(() => {
    let fullPath = location.pathname.substring(1);
    let pathIntoArray = fullPath.split('/');
    let pageName=pathIntoArray[pathIntoArray.length - 1]==="scheduler"?"monitor":pathIntoArray[pathIntoArray.length - 1]
    setBaseModule(pathIntoArray[pathIntoArray.length - 2]);
    setPage(pageName);
    }, [location]);
    
  useEffect(async () => {
    if (!menuList || menuList.length == 0) {
      let menuString = localStorage.getItem('menuItems');
      const updateMenuItems = localStorage.getItem('updateMenuItems')

      if (!menuString || menuString == 'undefined' || updateMenuItems === 'true') {
        const config = {
          headers: {
            'Authorization': 'Bearer' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          }
        }
        axios.get(`${BASE_URL}/${menuAPIResource}`, config).then((response) => {
          setMenuItems(response.data.data)
          localStorage.setItem('menuItems', JSON.stringify(response.data.data));
          localStorage.setItem('updateMenuItems', null)
          setMenuList(menuBuilder(response.data.data));
        }).catch((error) => {
          console.log(error);
        });
      } else {
        try {
          setMenuItems(JSON.parse(menuString))
          setMenuList(menuBuilder(JSON.parse(menuString)));
        } catch (error) {
          console.log(error);
          await apiProvider.getAll('track/react/v1/logout').then((res) => {
            localStorage.clear();
            if (res.status === 200) {
              history.replace('/logout');
            }
          });
        }

      }
    }

  }, [menuList]);

  useEffect(() => {
    if (page) {
      const ind = menuList.findIndex(navMenu => navMenu.name === "help_center")

      if (ind > -1) {
        const tempList = [...menuList]
        tempList[ind].link = helpCenterLinks[page] || helpCenterLinks.defaultLink

        setMenuList(tempList)
      }

    }
  }, [page])



  const menuBuilder = (menu) => {
    return menu?.map((item) => {
      const employeeLabel = getAccountLabel('employee');
      if (item.name.toLowerCase() == 'dashboard') {
        item.displayName = 'Dashboard';
        item.cssClass = 'iconDashboard';
        item.helpText = 'At-a-glance view of your allGeo account';
        item.link = `${process.env.REACT_APP_API_URL}/dashboard`;
        return item;
      }
      if (item.name.toLowerCase() == 'schedule' && checkSubMenuACL(item.submenu)) {
        item.displayName = 'Schedule';
        item.cssClass = 'iconSchedule';
        item.helpText = 'Setup schedules for location tracking, exception alerts, reports, dispatches, connectors';
        // written the code for the WO scheduler and hardcoded v2 links
        if (item.submenu.some(submenu => submenu.name.toLowerCase() === 'work_orders' && submenu.accessLevel > 0)) {
          item.link = `${process.env.REACT_APP_API_URL}/schedule/work-order`;
        }
        else {
          let woMenuItem = item.submenu.find(item => item.accessLevel > 0).name.split('_').join('-');
          if (woMenuItem === "schedule-template") {
            item.link = `${process.env.REACT_APP_API_URL}/track/mgt?page=mastertrip.info.v1&q=${Math.floor(Math.random() * 9999999)}`
          }
          if (woMenuItem === "active-schedules") {
            isAdminLogin || isAdminUser ? item.link = `${process.env.REACT_APP_API_URL}/schedule/active-schedule`
              : item.link = `${process.env.REACT_APP_API_URL}/track/mgt?page=trip.info.v1&q=${Math.floor(Math.random() * 9999999)}`
          }
          if (woMenuItem === "route-planner") {
            item.link = `${process.env.REACT_APP_API_URL}/track/mgt?page=optimizedRoute.info.v1&q=${Math.floor(Math.random() * 9999999)}`
          }
          if (woMenuItem === "automation-rules") {
            item.link = `${process.env.REACT_APP_API_URL}/schedule/automation-rules`;
          }
        }

        setScheduleSubMenu(item.submenu);
        return item;
      }
      //check added for report cuz if excess level is 0 still we get 3 in build and edit report
      if (item.name.toLowerCase() == 'reports' && item.accessLevel > 0) {
        item.displayName = 'Reports';
        item.cssClass = 'iconReport';
        item.helpText = 'Download, email timeClock, history, field data reports';
        item.link = isNewUser ? `${process.env.REACT_APP_API_URL}/reports` : `${process.env.REACT_APP_API_URL}/track/mgt?page=menu.rpt.grpDetail.v1`;
        setReportSubMenu(item.submenu);
        return item;
      }

      if (item.name.toLowerCase() == 'company' && item.accessLevel > 0) {
        item.displayName = 'Company';
        item.cssClass = 'iconCompany';
        item.helpText = `Setup account including default company settings, mobile app, ${employeeLabel}, sites, field data, forms`;
        // if the access level of employee is >0 then /employess will come else next first ACL element will come
        if (item.submenu.some(submenu => submenu.name.toLowerCase() === 'employees' && submenu.accessLevel > 0)) {
          item.link = `${process.env.REACT_APP_API_URL}/company/employees`;
        } else {
          item.link = `${process.env.REACT_APP_API_URL}/company/${item.submenu.find(item => item.accessLevel > 0).name.split('_').join('')}`;
        }
        setCompanySubMenu(item.submenu.filter(companyMenuItems => companyMenuItems.accessLevel > 0));
        return item;
      }

      if (item.name.toLowerCase() == 'monitor') {
        item.displayName = 'Monitor';
        item.cssClass = 'iconMonitor';
        item.helpText = `Track & visualize ${employeeLabel}, ${jobsiteLabel} presence, monitor exceptions, replay history, display live communication from the field`;
        item.link = `${process.env.REACT_APP_API_URL}${monitorEndPoint} `;
        return item;
      }

      if (item.name.toLowerCase() == 'support_panel') {
        item.displayName = 'Support';
        item.cssClass = 'iconSupport';
        item.helpText = 'Support tooltip text will goes here.';
        item.link = `${process.env.REACT_APP_API_URL}/track/mgt?page=dev.alerts.v1&q=${Math.floor(Math.random() * 9999999)}`;
        return item;
      }

      if (item.name.toLowerCase() == 'help_center') {
        item.displayName = 'Help Center';
        item.cssClass = 'iconHelp';
        item.helpText = 'A comprehensive list of help articles.';
        item.link = 'https://help.allgeo.com/en/collections/2510470-managers-getting-started-with-allgeo-web-application-4-0';
        return item;
      }

    }).filter((ele) => ele !== undefined)
  }

  const checkSubMenu = (topMenu, subMenu) => {
    if (topMenu.toLowerCase() == 'company') {
      return companySubMenu?.find((item) => { return item.name.toLowerCase() == subMenu.toLowerCase() && item.accessLevel > 0 }) !== undefined ? true : false
    }
    if (topMenu.toLowerCase() == 'schedule') {
      return scheduleSubMenu?.find((item) => {
        return item.name.toLowerCase() == subMenu.toLowerCase()
      }) !== undefined ? true : false
    }
    if (topMenu.toLowerCase() == 'reports') {
      return reportSubMenu?.find((item) => { return item.name.toLowerCase() == subMenu.toLowerCase() }) !== undefined ? true : false
    }
  }

  const checkSubMenuACL = (subMenu) => {
    return subMenu.some((ele) => ele.accessLevel > 0)
  }

  let companySubMenuClass = baseModule === 'company' ? 'active' : '';
  let reportsSubMenuClass = baseModule === 'reports' ? 'active' : '';
  let scheduleSubMenuClass = baseModule === 'schedule' ? 'active' : '';

  const menuCloseHandler = (module) => {
    switch (module) {
      case 'company':
        companySubMenuClass = '';
        setBaseModule('');
        break;
      case 'reports':
        reportsSubMenuClass = '';
        setBaseModule('');
        break;
      case 'schedule':
        scheduleSubMenuClass = '';
        setBaseModule('');
        break;
      default:
        break;
    }
  }

  const employeeLabel = getAccountLabel('employee');
  const groupLabel = getAccountLabel('group');
  const jobsiteLabel = getAccountLabel('jobsite');
  const taskLabel = getAccountLabel('task');
  const workorderLabel = getAccountLabel('workorder')

  return (
    <Fragment>
      <div className="mainNavWrap">
        <SiteLogo />
        <ul>
          {
            menuList?.map((item, index) => {
              return <li key={`menu-${index}`} className={item.name == 'help_center' ? 'flexTopGap' : ''}>
                {/* <NavLink to={{pathname: item.link}} target="_blank" className={ item.name.toLowerCase() == baseModule ? `active ${item.cssClass}` : item.cssClass }>{item.displayName}</NavLink> */}
                <Tooltip position='right'>
                  <a target={item.name == 'help_center' ? '_blank' : '_self'} href={item.link} className={(item.name.toLowerCase() === baseModule || item.name.toLowerCase() === page) ? `active ${item.cssClass}` : item.cssClass} title={item.helpText} >
                    {item.displayName}
                  </a>
                </Tooltip>
              </li>
            })
          }
        </ul>
      </div>
      {
        (baseModule === 'company' && companySubMenu?.length > 0) &&
        <div className={`subNavWrap ${companySubMenuClass}`}>
          <span className="closeNav" onClick={() => menuCloseHandler('company')}>Close</span>
          <h2>Company</h2>
          <ul>
            {
              checkSubMenu('company', 'settings') === true ?
                <li>
                  <NavLink to="/company/settings" className="iconSettings" activeclassname="active">Settings</NavLink>
                </li> : ''
            }
            {
              checkSubMenu('company', 'onboarding') === true ?
                <li>
                  <a href={`${process.env.REACT_APP_API_URL}/track/mgt?page=mysetup.info.v1&q=${Math.floor(Math.random() * 9999999)}`} className="iconOnboarding">
                    My Onboarding
                  </a>
                </li> : ''
            }
            <li className="divider" />
            {
              checkSubMenu('company', 'employees') === true ?
                <li>
                  <NavLink to="/company/employees" className="iconEmployee" activeclassname="active">{employeeLabel}</NavLink>
                </li> : ''
            }
            {
              checkSubMenu('company', 'groups') === true ?
                <li>
                  <NavLink to="/company/groups" className="iconGroup" activeclassname="active">{groupLabel}</NavLink>
                </li> : ''
            }

            {
              checkSubMenu('company', 'managers') === true ?
                <li>
                  <NavLink to="/company/managers" className="iconManager" activeclassname="active">Managers</NavLink>
                </li> : ''
            }
            <li className="divider" />
            {
              checkSubMenu('company', 'job_sites') === true ?
                <li>
                  <NavLink to="/company/jobsites" className="iconSite" activeclassname="active">{jobsiteLabel}</NavLink>
                </li> : ''
            }
            {
              checkSubMenu('company', 'tasks') === true ?
                <li>
                  <NavLink to="/company/tasks" className="iconTask" activeclassname="active">{taskLabel}</NavLink>
                </li> : ''
            }
            {
              checkSubMenu('company', 'Message') === true ?
                <li>
                  <a href={`${process.env.REACT_APP_API_URL}/track/mgt?page=phonevalidation.v1&q=${Math.floor(Math.random() * 9999999)}`} className="iconMessage">
                    Messages
                  </a>
                </li> : ''
            }
            {
              checkSubMenu('company', 'Forms') === true ?
                <li>
                  <NavLink to="/company/forms" className="iconForms" activeclassname="active">Forms</NavLink>
                </li> : ''
            }
            {
              checkSubMenu('company', 'tools') === true ?
                <li>
                  <NavLink to="/company/tools" className="iconTool" activeclassname="active">Tools</NavLink>
                </li>
                : ''
            }
            <li className="divider" />
            {
              checkSubMenu('company', 'connector') === true ?
                // <li>
                //   <a href={`${process.env.REACT_APP_API_URL}/track/mgt?page=connector.info.v1&q=${Math.floor(Math.random() * 9999999)}`} className="iconConnector">
                //     Connectors
                //   </a>
                // </li> 
                <li>
                  <NavLink to="/company/connectors" className="iconTool" activeclassname="active">Connectors</NavLink>
                </li>
                :
                ''
            }
            <li className="divider" />
            {
              checkSubMenu('company', 'billing') === true ?
                <li>
                  {/* <a href={`${process.env.REACT_APP_API_URL}/track/mgt?page=profile.info.v1&q=${Math.floor(Math.random() * 9999999)}`} className="iconBilling">
                    Billing
                  </a> */}
                  <NavLink to="/company/billing" className="iconBilling" activeclassname="active">Billing</NavLink>
                </li> : ''
            }
            <li className="divider" />
          </ul>
        </div>
      }
      {
        (baseModule === 'schedule' && scheduleSubMenu?.length > 0) &&
        <div className={`subNavWrap ${scheduleSubMenuClass}`}>
          <span className="closeNav" onClick={() => menuCloseHandler('schedule')}>Close</span>
          <h2>Scheduling</h2>
          <ul>
            {
              checkSubMenu('schedule', 'schedule_template') === true ?
                <li>
                  <a href={`${process.env.REACT_APP_API_URL}/track/mgt?page=mastertrip.info.v1&q=${Math.floor(Math.random() * 9999999)}`} className="iconScheduleTemplate">Schedule Template</a>
                </li> : ''
            }
            {
              (checkSubMenu('schedule', 'active_schedules') === true && (isAdminUser || isAdminLogin)) ?
                <li>
                  <NavLink to="/schedule/active-schedule" className="iconActiveTemplate" activeclassname="active">Active Schedules</NavLink>
                </li>
                : <li><a href={`${process.env.REACT_APP_API_URL}/track/mgt?page=trip.info.v1&q=${Math.floor(Math.random() * 9999999)}`} className="iconActiveTemplate">Active Schedules</a></li>
            }
            {
              checkSubMenu('schedule', 'work_orders') === true ?
                <li>
                  <NavLink to="/schedule/work-order" className="iconWorkOrder" activeclassname="active">{workorderLabel}</NavLink>

                </li>
                : ''
            }
            {
              checkSubMenu('schedule', 'automation_rules') === true ?
                <li>
                  <NavLink to="/schedule/automation-rules" className="iconAutomationRules" activeclassname="active">Automation Rules</NavLink>
                </li> : ''
            }
            {
              checkSubMenu('schedule', 'route_planner') === true ?
                <li><a href={`${process.env.REACT_APP_API_URL}/track/mgt?page=optimizedRoute.info.v1&q=${Math.floor(Math.random() * 9999999)}`} className="iconRoutePlanner">Route Planner</a></li> : ''
            }
          </ul>
        </div>
      }

    </Fragment >
  );
}

export default SiteNavigation;