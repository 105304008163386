import React, { useState, useEffect } from 'react'
import UploadIcon from '../../assets/images/icon-upload.svg'

const FileUpload = ({ headerText, getFile, csvFile, csvFile2, csvFileName, setIsFormComplete, setSelectedSample,selectedSample }) => {

    const [file, setFile] = useState(null)
    const [error, setError] = useState(false)

    useEffect(() => {
        getFile(file)
    }, [file])

    const handleFileSize = () => {

        if (file) {
            let unit = 'b';
            let size = file.size;

            if (size >= 1024 * 1024) {
                size = size / Math.pow(1024, 2);
                unit = 'mb';
            } else if (size >= 1024) {
                size = size / 1024;
                unit = 'kb';
            }

            return `${(Math.round(size * 100) / 100)} ${unit}`;
        }
    }

    const handleFileUpload = e => {
        setError(false)
        const allowedFiletypes = ['.csv', '.xlsx', '.xls', 'text/csv', 'text/xlsx', 'application/csv', 'text/comma-separated-values', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']

        if (e.dataTransfer) {
            const uploadedFile = e.dataTransfer.files[0];

            if (allowedFiletypes.includes(uploadedFile.type)) {
                setIsFormComplete(true)
                setFile(uploadedFile)
            } else {
                setError(true)
            }
        } else {
            const uploadedFile = e.target.files[0];

            if (allowedFiletypes.includes(uploadedFile.type)) {
                setIsFormComplete(true)
                setFile(uploadedFile)
            } else {
                setError(true)
            }
        }
    }
console.log(selectedSample,"selectedSample")
    return (
        <div className="popupTabCont alignCenter">
            <div className="downloadBlk">{headerText} <a className='cursorPointer' href={csvFile} download={csvFileName}>{csvFile2 ? "sample file 1" : "sample file"} </a></div>
            {csvFile2 ? <div className="downloadBlk">{headerText} <a className='cursorPointer' href={csvFile2} download={csvFileName}>sample file 2</a></div> : ""}
            {csvFile2 ? <div class="bulk-container">
                <div class="file-upload">
                    <p style={{textAlign:'center'}}>File type uploaded</p>
                    <div class="radio-label" style={{display:'flex', justifyContent:'center', marginTop:'.5rem'}}>
                        <input type="radio" checked={selectedSample==="sample1"} name="sampleFile" value="sample1" onClick={() => { setSelectedSample("sample1") }} style={{cursor:'pointer'}}/>
                            Sample File 1
                        <span style={{ width: "10px" }}></span>
                        <input checked={selectedSample==="sample2"} type="radio" name="sampleFile" value="sample2" onClick={() => { setSelectedSample("sample2") }} style={{cursor:'pointer'}} />
                            Sample File 2
                    </div>
                </div>
            </div> : ""}
            {!file ? <div className="dragDrop"
                onDragOver={e => {
                    e.preventDefault()
                }}
                onDrop={e => {
                    e.preventDefault()
                    handleFileUpload(e)
                }}
            >
                <p><img src={UploadIcon} alt="Upload" /></p>
                <div>Drag and drop or browse to choose a file</div>
                <p>Only CSV and XLSX file extension is allowed.</p>
                {error && <div className="errMsg">File type unsupported.</div>}
                <input type="file" onChange={handleFileUpload} accept=".csv" />
            </div>
                :
                <div className="fileDetail">
                    <div className="fileName">{file.name}</div>
                    <span>{handleFileSize()}</span>
                    <em className="fileDelete" onClick={() => { setFile(null); setIsFormComplete(false) }} >Delete</em>
                </div>
            }
        </div>
    )
}

export default FileUpload