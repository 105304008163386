import React, { useState, useEffect, useMemo, useRef } from "react";
import { DatePicker, TimePicker } from "@progress/kendo-react-dateinputs";
import { formatTime, getDateTimeFromTimestamp } from "../utils";
import { formatDate } from "@progress/kendo-intl";
import { secondsToHHMMSS, secondsToHoursAndHundredths } from "./kendoUtils";
import iconClock from '../../../assets/images/icon-clock.svg';
import iconGroup from '../../../assets/images/icon-group-small.svg';
import { GridColumnMenuWrapper } from "@progress/kendo-react-grid";
import MultiSelectDropdown from "../dropdown/MultiSelectDropdown";
import { DropdownList } from '../../common/dropdown';
import { apiProvider } from "../../../services/api/provider";
import DOMPurify from 'dompurify';


export const PhoneNumberCell = (props) => {
    const isVerified = props.dataItem.phoneVerified;

    return (
        <td
            style={props.style}
            className={props.className}
            onClick={() => props.onClick(null)}
        >
            <div className={`phoneVerification${isVerified ? ' phoneVerified' : ''}`}>
                <span>{props.dataItem.contactPhone}</span>
                <span className="sendOtp" onClick={() => props.openPopup("otp", props.dataItem)}>OTP</span>
            </div>
        </td>
    )
}

export const IdCell = (props) => {

    if (props.rowType === "groupHeader") {
        return null;
    }

    if (props.rowType === "groupFooter") {
        return <td style={props.style} ></td>
    }

    return (
        <td>
            {props.dataIndex + 1}
        </td>
    )
}

export const EditCell = props => {

    if (props.rowType === "groupHeader") {
        return null;
    }
    if (props.rowType === "groupFooter") {

        if (!Object.keys(props.dataItem.aggregates).includes(props.field)) {
            return <td style={props.style}></td>
        }


        const { aggregateText } = props.otherData;

        let dataSum = Math.round(props.dataItem.aggregates[props.field].sum * 100) / 100

        if (props.columnData.format && props.columnData.format === "hh:mm:ss") {
            dataSum = secondsToHHMMSS(props.dataItem.aggregates[props.field].sum)
        }

        if (props.columnData.field === "totoalServiceHours" || props.columnData.field === "woServiceSeconds" || props.columnData.field === "serviceTimeSeconds" || props.columnData.field === "regularServiceHours" || props.columnData.field === "overTimeHours" || props.columnData.field === "doubleOverTimeHours") {
            if (props.otherData.minutesFormat && props.otherData.minutesFormat === "minutes") {
                dataSum = secondsToHHMMSS(props.dataItem.aggregates[props.field].sum)
            }

            if (props.otherData.minutesFormat && props.otherData.minutesFormat === "hundredths") {
                dataSum = secondsToHoursAndHundredths(props.dataItem.aggregates[props.field].sum)
            }
        }

        if (props.columnData.field === "serviceSeconds" || props.columnData.field === "totalServiceSeconds" || props.columnData.field === "elapsedTimeSec" || props.columnData.field === 'totalPunchServiceTime') {
            if (props.otherData.minutesFormat && props.otherData.minutesFormat === "minutes") {
                dataSum = secondsToHHMMSS(props.dataItem.aggregates[props.field].sum)
            }

            if (props.otherData.minutesFormat && props.otherData.minutesFormat === "hundredths") {
                dataSum = secondsToHoursAndHundredths(props.dataItem.aggregates[props.field].sum)
            }
        }


        return (
            <td style={props.style} className={props.className} aria-colindex={props.columnIndex + 1} role={"gridcell"}>
                {`${aggregateText[props.field]} : ${dataSum}`}
            </td>
        )
    }

    const { field, dataItem, editable, editID, editField, onClick, onBlur, onSave, validationMapping: { lengthMap }, cellValidationValue, columnData: { roundOff, format } } = props;

    let nestedFields = null;

    if (field.includes('.')) {
        nestedFields = field.split(".")
    }

    const [dataValue, setDataValue] = useState(
        nestedFields
            ?
            dataItem[nestedFields[0]] ? dataItem[nestedFields[0]][nestedFields[1]] : ""
            :
            dataItem[field]
    )

    const handleTextChange = e => {
        const { value } = e.target;
        if (value.trim().length > lengthMap[field]) return

        setDataValue(value)
    }

    const handleKeyPress = e => {
        if (e.keyCode === 13) {
            const val = nestedFields
                ?
                dataItem[nestedFields[0]] ? dataItem[nestedFields[0]][nestedFields[1]] : ""
                :
                dataItem[field]
            if (dataValue !== val) {
                const value = roundOff ? Math.round(dataValue * 100) / 100 : dataValue;

                onSave(value + "", field, dataItem)
            }
        }
    }

    const handleBlur = e => {
        let value = roundOff ? Math.round(dataValue * 100) / 100 : dataValue;
        if (!value) value = ""
        onBlur(e, value + "", field, dataItem)
    }

    const setEditField = () => {
        if (editable)
            onClick(field)
        else {
            onClick(null)
        }
    }

    const getValue = value => {
        if (typeof value === 'boolean') {
            return value ? 'Active' : 'Inactive'
        }

        //only for total service hours and service time
        if (field === "totoalServiceHours" || field === "totalServiceSeconds") {
            if (props.otherData.minutesFormat && props.otherData.minutesFormat === "minutes") {
                return secondsToHHMMSS(+value)
            }

            if (props.otherData.minutesFormat && props.otherData.minutesFormat === "hundredths") {
                return secondsToHoursAndHundredths(+value)
            }

        }
        if (field === "serviceSeconds" || field === "woServiceSeconds" || field === "serviceTimeSeconds" || field === "elapsedTimeSec" || field === "totalPunchServiceTime" || field === "regularServiceHours" || field === "overTimeHours" || field === "doubleOverTimeHours") {
            if (props.otherData.minutesFormat && props.otherData.minutesFormat === "minutes") {
                return secondsToHHMMSS(+value)
            }

            if (props.otherData.minutesFormat && props.otherData.minutesFormat === "hundredths") {
                return secondsToHoursAndHundredths(+value)
            }
        }
        if (format && format === 'hh:mm:ss') {
            return secondsToHHMMSS(+value)
        }
        return value?.toString()
    }
    const className = dataItem.dirty ? dataItem.dirty[field] ? ' dirty' : '' : ''

    useEffect(() => {
        if (cellValidationValue && editID === dataItem.id) {
            if (cellValidationValue[field]) {
                setDataValue(cellValidationValue[field])
            }
        }
    }, [cellValidationValue])

    return (
        <td onClick={setEditField} style={props.style} className={props.className + className}>
            {
                editID === dataItem.id && field === editField
                    ?
                    <input
                        type={'text'}
                        value={dataValue == null ? "" : dataValue}
                        onChange={handleTextChange}
                        onBlur={handleBlur}
                        onKeyDown={e => handleKeyPress(e)}
                        disabled={!editable}
                        autoFocus={true}
                    />
                    :
                    getValue(dataValue)
            }
        </td>
    )
}


export const Link = (props) => {

    if (props.rowType === "groupHeader") {
        return null;
    }

    const invalidLinkTexts = ['n/a', 'Invalid GPS']

    const isLink = !invalidLinkTexts.includes(props.text)
    const linkLabel = props.dataItem.dynamic ? 'Run' : 'Preview';

    return (
        <td
            onClick={() => props.onClick(null)}
            style={props.style}
            className={props.className}
        >
            {
                isLink
                    ?
                    <a className="linkStyle" target="_blank" href={props.dataItem.link}>{linkLabel}</a>
                    :
                    props.text
            }
        </td>
    )
}

export const EarningCodeDropDown = (props) => {

    if (props.rowType === "groupHeader") {
        return null;
    }
    const { dataItem, field, editField, editID, onClick, onSave, otherData: { earningCodeDropDown, taskNameDropDown, defaultEarningCodes } } = props

    const [className, setClassName] = useState('');
    const cellRef = useRef(null);
    useEffect(() => {
        if (dataItem.dirty && dataItem.dirty[field]) {
            setClassName('dirty');
        } else {
            setClassName('');
        }
    }, [dataItem, field]);
    useEffect(() => {
        const clearButton = document.querySelector('.k-clear-value');
        if (clearButton && (editID === dataItem.id)) {
            const handleClick = () => {
                setClassName('dirty');
                onSave("", field, dataItem);
            };
            clearButton.addEventListener('click', handleClick);
            return () => {
                clearButton.removeEventListener('click', handleClick);
            };
        }
    }, [dataItem, field, onSave]);

    const selectedEarningCodes = useMemo(() => dataItem.earningCode?.split(",").filter(val => val).map((code => ({ id: code, text: code }))) || [], [])
    const [selectedOption, setSelectedOption] = useState(selectedEarningCodes);
    const data = useMemo(() => {
        if (earningCodeDropDown.hasOwnProperty(dataItem.customerName)) {
            const options = earningCodeDropDown[dataItem.customerName]

            if (options?.trim().length > 0)
                return options.split(",").map(value => value)
        }
        return defaultEarningCodes || []
    }, [earningCodeDropDown[dataItem.customerName], defaultEarningCodes])

    const selectedItems = (e) => {
        const selectedValues = e?.value;
        setSelectedOption(selectedValues)
    }

    const onBlur = () => {
        const commaSepValue = selectedOption.map(e => e.id).join(',');
        onSave(commaSepValue, field, dataItem);
    };

    return (
        <td
            ref={cellRef}
            onClick={() => onClick(field)}
            style={props.style}
            className={props.className + className}
        >
            {
                editID === dataItem.id && field === editField
                    ?
                    <MultiSelectDropdown
                        data={data?.map((e) => ({ id: e, text: e }))}
                        value={selectedOption}
                        onChange={selectedItems}
                        onBlur={onBlur}
                    />
                    :
                    selectedEarningCodes.map(code => code.text).join(",")
            }
        </td>
    )
}

// export const EarningCodeDropDown = (props) => {

//     if (props.rowType === "groupHeader") {
//         return null;
//     }

//     const { dataItem, field, editField, editID, onClick, onSave, otherData: { earningCodeDropDown, taskNameDropDown, defaultEarningCodes } } = props
//     const selectedEarningCodes = useMemo(() => dataItem.earningCode?.split(",").filter(val => val).map((code => ({ id: code, text: code }))) || [], [])
//     const [selectedOption, setSelectedOption] = useState(selectedEarningCodes)

//     const data = useMemo(() => {
//         if (earningCodeDropDown.hasOwnProperty(dataItem.customerName)) {
//             const options = earningCodeDropDown[dataItem.customerName]

//             if (options?.trim().length > 0)
//                 return options.split(",").map(value => value)
//         }
//         return defaultEarningCodes || []
//     }, [earningCodeDropDown[dataItem.customerName], defaultEarningCodes])


//     const className = dataItem.dirty ? dataItem.dirty[field] ? ' dirty' : '' : ''

//     const selectedItems = (e) => {
//         const selectedValues = e.value;
//         setSelectedOption(selectedValues)
//     }

//     const onBlur = () => {
//         const commaSepValue = selectedOption.map(e => e.id).join(',');
//         onSave(commaSepValue, field, dataItem);
//     };

//     return (
//         <td
//             onClick={() => onClick(field)}
//             style={props.style}
//             className={props.className + className}
//         >
//             {
//                 editID === dataItem.id && field === editField
//                     ?
//                     <MultiSelectDropdown
//                         data={data?.map((e) => ({ id: e, text: e }))}
//                         value={selectedOption}
//                         onChange={selectedItems}
//                         onBlur={onBlur}
//                     />
//                     :
//                     selectedEarningCodes.map(code => code.text).join(",")
//             }
//         </td>
//     )
// }

export const TaskNameDropDown = (props) => {
    if (props.rowType === "groupHeader") {
        return null;
    }

    const {
        dataItem,
        field,
        editField,
        editID,
        onClick,
        onSave,
        otherData: { taskNameDropDown },
    } = props;

    const [taskOptions, setTaskOptions] = useState([]);
    const isMounted = useRef(true); // Create a ref to track component mount status

    useEffect(() => {
        // Cleanup function to set isMounted to false when the component unmounts
        return () => {
            isMounted.current = false;
        };
    }, []);

    const getTask = async () => {
        const custID = taskNameDropDown[dataItem.customerName];

        try {

            const response = await apiProvider.getAll(`/track/mobile/v1/allgeo/getTasks?extCustIds=${custID}`);
            if (localStorage.getItem("selectedTaskName") && localStorage.getItem("selectedTaskID")) {
                localStorage.removeItem("selectedTaskName")
                localStorage.removeItem("selectedTaskID")
            }
            let taskOptions = [];

            if (response.data) {
                response.data.splice(1, 0, { selected: "yes", taskName: 'Select', taskID: 'Select' })
                const selectedTaskID = localStorage.getItem("selectedTaskID")
                if (response.data[0].selected === "yes") {
                    if (selectedTaskID) {
                        taskOptions = response.data.slice(1).filter((e) => e.taskID === selectedTaskID).map((e) => ({ label: e.taskName, value: e.taskName }));
                    }
                    else {
                        taskOptions = response.data.slice(1).filter((e) => e.taskName).map((e) => ({ label: e.taskName, value: e.taskName }));
                    }
                } else {
                    localStorage.setItem('taskMap', JSON.stringify(response.data.slice(1).filter(item => item.selected === "yes")))
                    const allTasks = selectedTaskID
                        ? response.data.filter(item => item.selected === "yes" && item.taskID === selectedTaskID)
                        : response.data.filter(item => item.selected === "yes");
                    taskOptions = Object.values(allTasks)?.map((e) => ({ label: e.taskName, value: e.taskName }));
                }

                if (taskOptions.length > 0) {
                    onClick(field);
                }

                // Check if the component is still mounted before updating state
                if (isMounted.current) {
                    setTaskOptions(taskOptions);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const className = dataItem.dirty ? dataItem.dirty[field] ? ' dirty' : '' : '';

    return (
        <td onClick={getTask} style={props.style} className={props.className + className}>
            {editID === dataItem.id && field === editField ? (
                <DropdownList
                    filterable={true}
                    list={taskOptions}
                    defaultValue={dataItem[field]}
                    onChange={(e) => {
                        localStorage.setItem("selectedTaskName", e.value.id)
                        localStorage.setItem("taskName", true)
                        onSave(e.value.id, field, dataItem);
                    }}
                />
            ) : (
                dataItem[field]
            )}
        </td>
    );
};

export const TaskIDDropDown = (props) => {
    if (props.rowType === "groupHeader") {
        return null;
    }

    const {
        dataItem,
        field,
        editField,
        editID,
        onClick,
        onSave,
        otherData: { taskNameDropDown },
    } = props;

    const [taskOptions, setTaskOptions] = useState([]);

    const isMounted = useRef(true);

    useEffect(() => {
        // Cleanup function to set isMounted to false when the component unmounts
        return () => {
            isMounted.current = false;
        };
    }, []);

    const getTask = async () => {
        const custID = taskNameDropDown[dataItem.customerName];
        try {

            const response = await apiProvider.getAll(`/track/mobile/v1/allgeo/getTasks?extCustIds=${custID}`);
            if (localStorage.getItem("selectedTaskName") && localStorage.getItem("selectedTaskID")) {
                localStorage.removeItem("selectedTaskName")
                localStorage.removeItem("selectedTaskID")
            }
            let taskOptions = [];

            if (response.data) {
                response.data.splice(1, 0, { selected: "yes", taskName: 'Select', taskID: 'Select' })
                const taskIdMap = response.data.map((e) => ({ Name: e.taskName, Id: e.taskID }))
                localStorage.setItem("taskIdMap", JSON.stringify(taskIdMap))

                const selectedTaskName = localStorage.getItem("selectedTaskName")
                if (response.data[0].selected === "yes") {
                    if (selectedTaskName) {
                        taskOptions = response.data?.slice(1).filter((e) => e.taskName === selectedTaskName).map((e) => ({ label: e.taskID, value: e.taskID }));
                    } else {
                        taskOptions = response.data?.slice(1).filter((e) => e.taskID).map((e) => ({ label: e.taskID, value: e.taskID }));
                    }

                } else {
                    const allTasks = selectedTaskName
                        ? response.data.filter(item => item.selected === "yes" && item.taskName === selectedTaskName)
                        : response.data.filter(item => item.selected === "yes");
                    taskOptions = Object.values(allTasks)?.map((e) => ({ label: e.taskID, value: e.taskID }));
                }

                if (taskOptions.length > 0) {
                    onClick(field);
                }

                // Check if the component is still mounted before updating state
                if (isMounted.current) {
                    setTaskOptions(taskOptions);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const className = dataItem.dirty ? dataItem.dirty[field] ? ' dirty' : '' : '';

    return (
        <td onClick={getTask} style={props.style} className={props.className + className}>
            {editID === dataItem.id && field === editField ? (
                <DropdownList
                    filterable={true}
                    list={taskOptions}
                    defaultValue={dataItem[field]}
                    onChange={(e) => {
                        localStorage.setItem("selectedTaskID", e.value.id)
                        localStorage.setItem("taskID", true)
                        onSave(e.value.id, field, dataItem);
                    }}
                />
            ) : (
                dataItem[field]
            )}
        </td>
    );
};


export const FormType = (props) => {
    if (props.rowType === "groupHeader") {
        return null;
    }

    const linkLabel = props.dataItem.dynamic ? 'Dynamic' : 'Static';

    return (
        <td
            onClick={() => props.onClick(null)}
            style={props.style}
            className={props.className}
        >
            <p>{linkLabel}</p>
        </td>
    )

}

export const TextLink = (props) => {

    if (props.rowType === "groupHeader") {
        return null;
    }

    const { dataItem, field } = props

    const dataArray = dataItem[field]?.split("#") || []
    return (
        <td
            onClick={() => props.onClick(null)}
            style={props.style}
            className={props.className}
        >
            {
                dataItem[field]?.includes("#")
                    ?
                    dataArray.map((item, ind) => {
                        return (
                            <div key={ind} >
                                {
                                    item.includes("http")
                                        ?
                                        <a className="linkStyle" target="_blank" href={item} >Attachment Link {ind > 0 ? ind : ''}</a>
                                        :
                                        <p>{item},</p>
                                }
                            </div>
                        )
                    })
                    :
                    dataArray.map((item, ind) => {
                        return (
                            <div key={ind} >
                                {
                                    item.includes("http")
                                        ?
                                        <a className="linkStyle" target="_blank" href={item} >Attachment Link {ind > 0 ? ind : ''}</a>
                                        :
                                        <p>{item}</p>
                                }
                            </div>
                        )
                    })
            }
        </td>
    )
}

export const TextHyperLink = (props) => {

    if (props.rowType === "groupHeader") {
        return null;
    }

    const { dataItem, field } = props

    const dataArray = dataItem[field]?.split("#") || []

    return (
        <td
            onClick={() => props.onClick(null)}
            style={props.style}
            className={props.className}
        >
            {
                dataArray.map((item, ind) => {
                    return (
                        <div key={ind} >
                            {

                                item ? <a className="linkStyle" target="_blank" href={item} >Attachment Link {ind > 0 ? ind : ''}</a> : ""

                            }
                        </div>
                    )
                })

            }
        </td>
    )
}

export const LatLogLink = (props) => {

    if (props.rowType === "groupHeader") {
        return null;
    }

    const { dataItem, field } = props;

    const latlog = dataItem[field];

    const handleClick = () => {
        if (latlog) {
            const [latitude, longitude] = latlog.split(',');
            const mapUrl = `https://maps.google.com/maps?q=${latitude},${longitude}`;
            window.open(mapUrl, '_blank');
        }
    };

    return (
        <td
            onClick={handleClick}
            style={props.style}
            className={props.className}
        >
            {
                latlog ? (
                    <div>
                        <a className="linkStyle" target="_blank">{latlog}</a>
                    </div>
                ) : null
            }
        </td>
    );
};

export const ValueOptions = (props) => {

    if (props.rowType === "groupHeader") {
        return null;
    }

    const { dataItem, field, editField, editID, onClick, onSave } = props;
    const Options = dataItem.tradeOptions?.split(",")
    const tradeOptions = Options?.map((e) => ({ label: e, value: e }))

    const className = dataItem.dirty ? dataItem.dirty[field] ? ' dirty' : '' : ''

    return (

        <td
            onClick={() => onClick(field)}
            style={props.style}
            className={props.className + className}
        >
            {
                editID === dataItem.id && field === editField
                    ?
                    <DropdownList
                        filterable={true}
                        list={tradeOptions}
                        defaultValue={dataItem[field]}
                        onChange={(e) => onSave(e.value.id, field, dataItem)}

                    />
                    : dataItem[field]
            }

        </td>

    )

}
const FormDataMessageCell = (props) => {
    if (props.rowType === "groupHeader") {
        return null;
    }

    const { dataItem, field } = props;
    const dataArray = dataItem[field]?.split("#");
    if (dataItem[field]?.includes("https://staging.allgeo.com/download")) {
        return (
            <td
                onClick={() => props.onClick(null)}
                style={props.style}
                className={props.className}
            >
                <div dangerouslySetInnerHTML={{ __html: dataItem[field] }} />
            </td>
        );
    } else {
        return (
            <td
                onClick={() => props.onClick(null)}
                style={props.style}
                className={props.className}
            >
                {dataItem[field]?.includes("#")
                    ? dataArray?.map((item, ind) => {
                        return (
                            <div key={ind}>
                                {item.includes("http") ? (
                                    <a className="linkStyle" target="_blank" href={item}>
                                        Attachment Link {ind > 0 ? ind : ""}
                                    </a>
                                ) : (
                                    <p>{item}</p>
                                )}
                            </div>
                        );
                    })
                    : dataArray?.map((item, ind) => {
                        return (
                            <div key={ind}>
                                {item.includes("http") ? (
                                    <a className="linkStyle" target="_blank" href={item}>
                                        Attachment Link {ind > 0 ? ind : ""}
                                    </a>
                                ) : (
                                    <p>{item}</p>
                                )}
                            </div>
                        );
                    })}
            </td>
        );
    }
};

const AttachMessageCell = (props) => {
    if (props.rowType === "groupHeader") {
        return null;
    }

    const { dataItem, field } = props;
    const dataArray = dataItem[field]?.split("#");
    // if (dataItem[field]?.includes("https://staging.allgeo.com/download")) {
    //     return (
    //         <td
    //             onClick={() => props.onClick(null)}
    //             style={props.style}
    //             className={props.className}
    //         >
    //             <div dangerouslySetInnerHTML={{ __html: dataItem[field] }} />
    //         </td>
    //     );
    // } else {
    return (
        <td
            onClick={() => props.onClick(null)}
            style={props.style}
            className={props.className}
        >
            {
                // dataItem[field]?.includes("#")
                //     ? dataArray?.map((item, ind) => {
                //         return (
                //             <div key={ind}>
                //                 {item.includes("http") ? (
                //                     <a className="linkStyle" target="_blank" href={item}>
                //                         Attachment Link {ind > 0 ? ind : ""}
                //                     </a>
                //                 ) : (
                //                     <p>{item}</p>
                //                 )}
                //             </div>
                //         );
                //     })
                //     : 
                dataArray?.map((item, ind) => {
                    return (
                        <div key={ind}>
                            {item.includes("http") ? (
                                <a className="linkStyle" target="_blank" href={item}>
                                    Attachment Link {ind > 0 ? ind : ""}
                                </a>
                            ) : (
                                <p>{item}</p>
                            )}
                        </div>
                    );
                })}
        </td>
    );
    // }
};

const FormDataPictureCell = (props) => {
    if (props.rowType === "groupHeader") {
        return null;
    }

    const { dataItem, field } = props;

    return (
        <td
            onClick={() => props.onClick(null)}
            style={props.style}
            className={props.className}
        >
            <div dangerouslySetInnerHTML={{ __html: dataItem[field] }} />
        </td>
    );
};

const FormDataCell = props => {
    if (props.rowType === "groupHeader") {
        return null;
    }

    const { dataItem, field } = props;

    return (
        <td
            onClick={() => props.onClick(null)}
            style={props.style}
            className={props.className}
        >
            {dataItem.message?.includes("https") ?
                <div>
                    <p>
                        Picture:
                        <a href={dataItem.message} style={{ color: "#0180b4" }} target="_blank">
                            ImageLink
                        </a>
                    </p>
                </div>
                :
                <div dangerouslySetInnerHTML={{ __html: dataItem[field] }} />
            }

        </td>
    )

}

const QRDataCell = props => {
    if (props.rowType === "groupHeader") {
        return null;
    }

    const { dataItem, field } = props;

    // Regular expression to match URLs
    const urlRegex = /(https?:\/\/[^\s]+)/;

    // Extract the URL using the regex
    const match = dataItem.qrScan.match(urlRegex);

    // Check if a match is found and get the URL from the captured group
    const url = match ? match[1] : null;
    return (
        <td
            onClick={() => props.onClick(null)}
            style={props.style}
            className={props.className}
        >
            {url ?
                <div>
                    <p>
                        Scan:
                        <a href={url} style={{ color: "#0180b4" }} target="_blank">
                            QR Link
                        </a>
                    </p>
                </div>
                :
                ""
            }
        </td>
    )

}


const FormDataList = props => {
    if (props.rowType === "groupHeader") {
        return null;
    }


    const { dataItem } = props;
    const sanitizedHTML = DOMPurify.sanitize(dataItem?.formData);

    const handleLinks = (htmlString) => {
        const doc = new DOMParser().parseFromString(htmlString, 'text/html');
        const links = doc.querySelectorAll('a');
        links.forEach((link) => {
            link.setAttribute('target', '_blank');
            link.style.color = '#0180b4';
        });
        return doc.body.innerHTML;
    };

    const processedHTML = handleLinks(sanitizedHTML);

    return (
        <td
            onClick={() => props.onClick(null)}
            style={props.style}
            className={props.className}
        >
            <div>
                <p>
                    <div dangerouslySetInnerHTML={{ __html: processedHTML }}></div>
                </p>
            </div>
        </td>
    )
}


export const GoogleMapLink = (props) => {

    if (props.rowType === "groupHeader") {
        return null;
    }

    const { text, customValue } = props

    const invalidLinkTexts = ['n/a', 'Invalid GPS']

    const isLink = !invalidLinkTexts.includes(text)

    return (
        <td
            onClick={() => props.onClick(null)}
            style={props.style}
            className={props.className}
        >
            {
                isLink ?
                    <a className="linkStyle" target="_blank" href={`https://maps.google.com/maps?q=${customValue !== undefined ? customValue : text}`}>{text}</a>
                    :
                    text
            }
        </td>
    )
}

const DateCell = props => {
    if (props.rowType === "groupHeader") {
        return null;
    }
    if (props.rowType === "groupFooter") {
        return <td style={props.style}></td>
    }

    const { dataItem, field, editID, editField, onClick, onSave, editable, otherData: { dateFormat, report } } = props;

    const setEditField = () => {
        const DateDataMap = JSON.parse(localStorage.getItem("Map")) || []
        if (editable) {
            if (report == "evv" || report == "evvcode" && field === "endTCSentDate") {
                const existingIndex = DateDataMap.findIndex(item => item.id === dataItem.txnID);

                if (existingIndex !== -1) {
                    // If id already exists, update the existing entry
                    DateDataMap[existingIndex].endDateClicked = "true";
                } else {
                    // If id doesn't exist, add a new entry
                    DateDataMap.push({
                        endDateClicked: "true",
                        id: dataItem.txnID
                    });
                }

                localStorage.setItem("Map", JSON.stringify(DateDataMap));
            }
            onClick(field);
        }
        else {
            onClick(null)
        }
    }

    const className = dataItem.dirty ? dataItem.dirty[field] ? ' dirty' : '' : ''

    let formattedDate;

    if (dateFormat.toLowerCase() === 'dd/mm/yyyy' || dateFormat.toLowerCase() === 'dd-mm-yyyy') {

        const splitBy = dateFormat.includes('/') ? "/" : "-"
        const splittedDate = dataItem[field]?.split(splitBy).map(d => {
            return Number(d)
        })

        if (splittedDate?.length === 3) {
            formattedDate = new Date(splittedDate[2], splittedDate[1] - 1, splittedDate[0])
        }
    } else {
        formattedDate = new Date(dataItem[field])
    }

    return (
        <td
            style={props.style}
            className={props.className + className}
            onClick={setEditField}
        >
            {
                editID === dataItem.id && field === editField
                    ?
                    <DatePicker
                        defaultValue={dataItem[field] ? formattedDate : new Date()}
                        onChange={e => {
                            onSave(formatDate(e.value, dateFormat), field, dataItem)
                        }}
                        format={dateFormat}
                    />
                    :
                    dataItem[field]
            }
        </td>
    )
}


const TimeCell = props => {
    if (props.rowType === "groupFooter") {
        return <td style={props.style}></td>
    }

    const { dataItem, field, editID, editField, onClick, onSave, editable, otherData } = props;

    const { timeFormat, timezone, report } = otherData

    const accountTimeFormat = timeFormat?.toLowerCase() === 'standard' ? 'hh:mm:ss a' : 'HH:mm:ss'

    let time = null, timeZone = '';

    const timeStamp = dataItem[field]?.split(" ")

    if (timeStamp?.length > 2) {
        time = timeStamp[0] + " " + timeStamp[1]
        timeZone = timeStamp[2]
    } else if (timeStamp?.length > 1) {
        time = timeStamp[0]
        timeZone = timeStamp[1]
    }

    const setEditField = () => {
        const TimeDataMap = JSON.parse(localStorage.getItem("Map")) || []
        if (editable) {
            if (report == "evv" || report == "evvcode" && field === "endTCSentTime") {
                const existingIndex = TimeDataMap.findIndex(item => item.id === dataItem.txnID);

                if (existingIndex !== -1) {
                    // If id already exists, update the existing entry
                    TimeDataMap[existingIndex].endTimeClicked = "true";
                } else {
                    // If id doesn't exist, add a new entry
                    TimeDataMap.push({
                        endTimeClicked: "true",
                        id: dataItem.txnID
                    });
                }

                localStorage.setItem("Map", JSON.stringify(TimeDataMap));
            }
            onClick(field);
        }
        else {
            onClick(null)
        }
    }

    const className = dataItem.dirty ? dataItem.dirty[field] ? ' dirty' : '' : ''

    return (
        <td
            style={props.style}
            className={props.className + className}
            onClick={setEditField}
        >
            {
                editID === dataItem.id && field === editField
                    ?
                    <TimePicker
                        defaultValue={dataItem[field] ? new Date(`2023/01/01 ${time ? time : dataItem[field]}`) : new Date('2023/01/01 12:00:00')}
                        onChange={e => {
                            if (!e.value) return

                            onSave(`${formatTime(e.value, accountTimeFormat.replace('a', 'A'))} ${timeZone || timezone}`, field, dataItem)
                        }}
                        format={accountTimeFormat}
                    />
                    :
                    dataItem[field]
            }
        </td>
    )
}


export const cellRender = (tdElement, cellProps) => {
    if (cellProps.rowType === "groupFooter") {
        return <td style={cellProps.style} className={cellProps.className} ></td>
    }
    return tdElement;
};

export const customRowRender = (tr, props, otherData) => {

    if (props.rowType === 'groupHeader') {
        const customGroupHeader = otherData.customGroupHeader
        if (props.dataItem.field === customGroupHeader.field) {
            const { ariaRowIndex, dataItem: { value }, isSelected } = props

            let reportData = sessionStorage.getItem('runReports')

            let date_fr = '', date_to = '', deviceID = null
            if (reportData) {
                const { dataItem: { items } } = props

                if (items && items.length > 0) {
                    if (items[0].field === 'deviceID') {
                        deviceID = items[0].value
                    }
                }

                reportData = JSON.parse(reportData)
                date_fr = getDateTimeFromTimestamp(reportData.params.date_fr)
                date_to = getDateTimeFromTimestamp(reportData.params.date_to)
            }

            return (
                <tr className="k-grouping-row" role="row" aria-rowindex={ariaRowIndex}>
                    <td className="k-group-cell" role="presentation" style={{ left: 0, right: 0 }}></td>
                    <td colSpan="16" role="gridcell" aria-selected={isSelected} aria-expanded="true" aria-colindex={0} style={{ left: 0, right: 0 }}>
                        <p className="k-reset">
                            {/* <a tabIndex="-1" aria-label="Collapse Group" className="k-i-caret-alt-down k-icon"></a> */}
                            {value}

                            <a
                                style={{ marginLeft: 20, textDecoration: 'underline', color: deviceID ? "#0180b4" : 'darkgrey', pointerEvents: deviceID ? 'auto' : 'none' }}
                                onClick={() => {
                                    if (deviceID) {
                                        let parameters = 'location=1,width=800,height=650';
                                        parameters += ',left=' + (window.screen.width - 800) / 2 + ',top=' + (window.screen.height - 750) / 2;
                                        const mapUrl = `${process.env.REACT_APP_API_URL}/track/mgt?date_fr=${date_fr}&dateto=${date_to}&device=${deviceID}&page=menu.rpt.devDetail&r_limit=2000&fmt=map&r_report=Breadcrumb_Report&page_cmd=rptsel&r_menu=menu.rpt.devDetail`

                                        window.open(mapUrl, '_blank', parameters)
                                    }
                                }}
                            >
                                Map for {value}
                            </a>
                        </p>
                    </td>
                </tr>
            )
        }

        return tr
    }

    return <RowRender
        originalProps={props}
        tr={tr}
    />
};

const RowRender = (props) => {
    const trProps = {
        ...props.tr.props,
    };
    const childNodes = props.tr.props.children;
    return React.cloneElement(
        props.tr,
        {
            ...trProps,
        },
        childNodes
    );
};

/* Monitor Page Custom Cells */

export const CustomHeaderCell = (props, children, sortedColumns) => {

    const sortedColumnInd = sortedColumns?.findIndex(col => col.field === props.field)


    return (
        <>
            {!!Object.keys(children).length &&
                <span className="k-cell-inner overflowVisible">
                    <span className="k-link" onClick={props.onClick}>
                        <span className="k-column-title">{children}</span>
                        {
                            sortedColumnInd > -1 &&
                            <>
                                <span className={`k-icon k-i-sort-${sortedColumns[sortedColumnInd]?.dir}-small`}></span>
                                {
                                    sortedColumns.length > 1 &&
                                    <span className="k-sort-order">{sortedColumnInd + 1}</span>
                                }
                            </>
                        }
                    </span>
                    <GridColumnMenuWrapper {...props.columnMenuWrapperProps} />
                </span>
            }
        </>
    )
}

const DeviceDesc = props => {
    if (props.rowType === "groupHeader") {
        return null;
    }

    const { dataItem, field, openPopup } = props;
    const { devGrp } = dataItem

    return (
        <td
            style={props.style}
            className={props.className + " overflowVisible"}
        >
            <a onClick={() => openPopup('renameDevice', dataItem)} >{dataItem[field]}</a>
            {
                devGrp.length > 0 &&
                <>
                    <div>
                        <div className="txtWithIcon">
                            <img src={iconGroup} alt="time" />
                            <p>{devGrp[0]}</p>
                        </div>
                    </div>
                    {
                        devGrp.length > 1 &&
                        <div className="haveTooltip">
                            <div className="mainTxt">See More</div>
                            <div className="tooltipBlk">
                                {
                                    devGrp.map((item, ind) => {

                                        return (
                                            <div key={ind} className="txtWithIcon">
                                                <img src={iconGroup} alt="time" />
                                                <p>{item}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    }
                </>
            }
        </td>
    )
}

const DeviceId = props => {
    if (props.rowType === "groupHeader") {
        return null;
    }

    const { dataItem, field, openPopup } = props;
    const { optinStyle } = dataItem

    const [showDetails, setShowDetails] = useState(false)

    return (
        <td
            style={props.style}
            className={props.className}
        >
            <div
                onContextMenu={e => {
                    e.preventDefault()
                    setShowDetails(true)
                }}
                className="cursorPointer"
                style={{ color: optinStyle }}>{dataItem[field]}</div>
            {
                optinStyle &&
                <a onClick={() => openPopup('optIn', dataItem)} style={{ color: 'lightseagreen' }} >Opt-in</a>
            }
            {showDetails && <DeviceDetails data={dataItem} closePopup={() => setShowDetails(false)} />}
            <div className={`overlay ${showDetails ? 'active' : ''}`} />

        </td>
    )
}

const DeviceDetails = ({ data, closePopup }) => (
    <>
        <div className="popupWrap smlPopup active">
            <div className="closePopup" onClick={closePopup} >Close</div>
            <div className="popupHeading">Device Details</div>
            <div className="popupContent">
                <div className="popupTabCont">
                    <div className="fldWrap">
                        <label>DeviceID</label>
                        <p>{data.deviceId}</p>
                    </div>
                    <div className="fldWrap">
                        <label>Device Description</label>
                        <p>{data.devicedesc}</p>
                    </div>
                    <hr />
                    <div className="sectionSubHeading">About App</div>
                    <div className="fldWrap">
                        <label>App Version</label>
                        <p>{data.appVersion}</p>
                    </div>
                    <div className="fldWrap">
                        <label>Last Activity</label>
                        <p>{data.time}</p>
                    </div>
                    <hr />
                    <div className="sectionSubHeading">Connectivity</div>
                    <div className="fldWrap">
                        <label>Cell Network</label>
                        <p>{data.cellNetworkAvail}</p>
                    </div>
                    <div className="fldWrap">
                        <label>Carrier</label>
                        <p>{data.carrier}</p>
                    </div>
                    <div className="fldWrap">
                        <label>Location Services</label>
                        <p>{data.GPSStatus}</p>
                    </div>
                    <div className="fldWrap">
                        <label>Wifi Status</label>
                        <p>{data.wifiStatus}</p>
                    </div>
                    <hr />
                    <div className="sectionSubHeading">About Phone</div>
                    <div className="fldWrap">
                        <label>Model</label>
                        <p>{data.model}</p>
                    </div>
                    <div className="fldWrap">
                        <label>Manufacturer</label>
                        <p>{data.manufacturer}</p>
                    </div>
                    <div className="fldWrap">
                        <label>OS Version</label>
                        <p>{data.softwareVers}</p>
                    </div>
                    <div className="fldWrap">
                        <label>Battery</label>
                        <p><div className={`batteryIndicator ${data.batteryLvl <= 20 ? 'lowBattery' : ''}`}><span><em style={{ width: data.batteryLvl + "%" }}>Battery</em></span>{data.batteryLvl}%</div></p>
                    </div>
                </div>
            </div>
        </div>
    </>
)



const EmployeeId = props => {
    if (props.rowType === "groupHeader") {
        return null;
    }

    const { dataItem, field, openPopup } = props;
    const { optinStyle } = dataItem

    const [showDetails, setShowDetails] = useState(false)

    return (
        <td
            style={props.style}
            className={props.className}
        >
            <div
                onContextMenu={e => {
                    e.preventDefault()
                    setShowDetails(true)
                }}
                className="cursorPointer"
                style={{ color: optinStyle }}>{dataItem[field]}</div>
            {
                optinStyle &&
                <a onClick={() => openPopup('optIn', dataItem)} style={{ color: 'lightseagreen' }} >Opt-in</a>
            }
            {showDetails && <EmployeeDetails data={dataItem} closePopup={() => setShowDetails(false)} />}
            <div className={`overlay ${showDetails ? 'active' : ''}`} />

        </td>
    )
}

const EmployeeDetails = ({ data, closePopup }) => (
    <>
        <div className="popupWrap smlPopup active">
            <div className="closePopup" onClick={closePopup} >Close</div>
            <div className="popupHeading">Device Details</div>
            <div className="popupContent">
                <div className="popupTabCont">
                    <div className="fldWrap">
                        <label>DeviceID</label>
                        <p>{data.deviceDetails.employeeID}</p>
                    </div>
                    <div className="fldWrap">
                        <label>Device Description</label>
                        <p>{data.deviceDetails.employeeName}</p>
                    </div>
                    <hr />
                    <div className="sectionSubHeading">About App</div>
                    <div className="fldWrap">
                        <label>App Version</label>
                        <p>{data.deviceDetails.appVersion}</p>
                    </div>
                    <div className="fldWrap">
                        <label>Last Activity</label>
                        <p>{data.deviceDetails.lastActivity}</p>
                    </div>
                    <hr />
                    <div className="sectionSubHeading">Connectivity</div>
                    <div className="fldWrap">
                        <label>Cell Network</label>
                        <p>{data.deviceDetails.cellNetwork}</p>
                    </div>
                    <div className="fldWrap">
                        <label>Carrier</label>
                        <p>{data.deviceDetails.carrier}</p>
                    </div>
                    <div className="fldWrap">
                        <label>Location Services</label>
                        <p>{data.deviceDetails.locationService}</p>
                    </div>
                    <div className="fldWrap">
                        <label>Wifi Status</label>
                        <p>{data.deviceDetails.wifiStatus}</p>
                    </div>
                    <hr />
                    <div className="sectionSubHeading">About Phone</div>
                    <div className="fldWrap">
                        <label>Model</label>
                        <p>{data.deviceDetails.model}</p>
                    </div>
                    <div className="fldWrap">
                        <label>Manufacturer</label>
                        <p>{data.deviceDetails.manufacturer}</p>
                    </div>
                    <div className="fldWrap">
                        <label>OS Version</label>
                        <p>{data.deviceDetails.osVersion}</p>
                    </div>
                    <div className="fldWrap">
                        <label>Battery</label>
                        <p><div className={`batteryIndicator ${data.deviceDetails.batteryLvl <= 20 ? 'lowBattery' : ''}`}><span><em style={{ width: data.deviceDetails.batteryLvl + "%" }}>Battery</em></span>{data.deviceDetails.batteryLvl}%</div></p>
                    </div>
                </div>
            </div>
        </div>
    </>
)


const DeviceStatus = props => {
    if (props.rowType === "groupHeader") {
        return null;
    }

    const { dataItem: { timeago, lastLocationDateTime, locatableStatus, locatable } } = props;

    return (
        <td
            style={props.style}
            className={props.className + " overflowVisible"}
        >
            {
                timeago ? <div>App Active: <strong dangerouslySetInnerHTML={{ __html: timeago }}></strong></div> : null
            }

            {lastLocationDateTime && <div className="txtWithIcon" ><img src={iconClock} alt="time" /> <strong >{lastLocationDateTime}</strong></div>}
            {
                locatable ?
                    <div className="haveTooltip">
                        <label className="withHelpTxt">{locatableStatus} <span className="helpTip">?</span></label>
                        <div className="tooltipBlk" dangerouslySetInnerHTML={{ __html: locatable }} />
                    </div>
                    :
                    locatableStatus
            }
        </td>
    )
}

const ScheduleStatus = props => {
    if (props.rowType === "groupHeader") {
        return null;
    }

    const { dataItem, fromMonitorv2 } = props;
    // const { trackingStatus, scheduleTime } = fromMonitorv2 ? dataItem?.locationStatus:dataItem;
    let trackingStatus, scheduleTime;

    if (fromMonitorv2 && dataItem && dataItem.locationStatus) {
        trackingStatus = dataItem.locationStatus.trackingStatus;
        scheduleTime = dataItem.locationStatus.scheduleTime;
    } else if (dataItem) {
        trackingStatus = dataItem.trackingStatus;
        scheduleTime = dataItem.scheduleTime;
    } else {
        trackingStatus = []
        scheduleTime: ""
    }
    return (
        <td
            style={props.style}
            className={props.className}
        >
            {
                trackingStatus?.toLowerCase() === 'yes'
                    ?
                    <>
                        <div className="txtWithIcon" >
                            <span className="roundIcon active" ></span>
                            <p >
                                <b>Active</b></p>
                        </div>
                        <div className="txtWithIcon">
                            <img src={iconClock} alt="time" />
                            <p>{scheduleTime}</p>
                        </div>
                    </>
                    :
                    <div className="txtWithIcon" >
                        <span className="roundIcon" ></span>
                        <p ><b>Inactive</b></p>
                    </div>

            }
        </td>
    )
}



const LastLocation = props => {
    if (props.rowType === "groupHeader") {
        return null;
    }

    const { dataItem: { address, lastEvtTime, deviceId } } = props;

    return (
        <td
            style={props.style}
            className={props.className}
        >
            {
                address?.toLowerCase() !== 'not found' ?
                    <>
                        <div>
                            <a className="linkStyle" target={'_blank'} href={`${process.env.REACT_APP_API_URL}/track/mgt?page=manageteaminfoV1&groupId=all&phoneList=${deviceId}`} >{address}</a>
                            <div className="txtWithIcon" >
                                <img src={iconClock} alt="time" />
                                <p >{lastEvtTime}</p>
                            </div>
                        </div>
                    </>
                    :
                    address
            }
        </td>
    )
}

const JobStatus = props => {
    if (props.rowType === "groupHeader") {
        return null;
    }

    const { dataItem } = props;
    const { jobsiteList } = dataItem;

    return (
        <td
            style={props.style}
            className={props.className + " overflowVisible"}
        >
            {
                jobsiteList.length > 0 ?
                    <>
                        <div>
                            <strong>{jobsiteList[0].jobstage}</strong>
                            <div>{jobsiteList[0].jobStat}</div>
                            <div>Duration: {jobsiteList[0].hosDuration}</div>
                            <div className="txtWithIcon">
                                <img src={iconClock} alt="time" />
                                <p>{jobsiteList[0].timeStr}</p>
                            </div>
                        </div>
                        {
                            jobsiteList.length > 1 &&
                            <div className="haveTooltip">
                                <div className="mainTxt">See More</div>
                                <div className="tooltipBlk">
                                    {
                                        jobsiteList.map((item, ind) => {

                                            if (ind === 0) return null

                                            return (
                                                <div key={ind} >
                                                    <strong>{item.jobstage}</strong>
                                                    <div>{item.jobStat}</div>
                                                    <div>Duration: {item.hosDuration}</div>
                                                    <div className="txtWithIcon">
                                                        <img src={iconClock} alt="time" />
                                                        <p>{item.timeStr}</p>
                                                    </div>
                                                    {ind < jobsiteList.length - 1 && <hr />}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        }
                    </>
                    :
                    "Day Not Started"
            }
        </td>
    )
}

//regaridng the url links issues

const TaskCell = props => {
    if (props.rowType === "groupHeader") {
        return null;
    }
    const { dataItem } = props;
    const tasksList = dataItem?.v3Task;

    const renderContent = () => {
        if (tasksList && tasksList.includes(";")) {
            const parts = tasksList.split(";").filter(part => part.trim() !== ""); // Remove empty strings
            const content = [];

            for (let i = 0; i < parts.length; i++) {
                const part = parts[i];
                content.push(
                    <div key={i}>
                        <span>{part}</span>
                    </div>
                );
            }

            return <div>{content}</div>;
        } else {
            return <div>{tasksList}</div>;
        }
    };
    return (
        <td
            onClick={() => props.onClick(null)}
            style={props.style}
            className={props.className}
        >
            {renderContent()}
        </td>
    );
};

const FormData = props => {
    if (props.rowType === "groupHeader") {
        return null;
    }
    const { dataItem } = props;
    const tasksList = dataItem?.v3FormData;

    const renderContent = () => {
        if (tasksList && tasksList.includes(";")) {
            const parts = tasksList.split(";").filter(part => part.trim() !== ""); // Remove empty strings
            const content = [];

            for (let i = 0; i < parts.length; i++) {
                const part = parts[i];

                if (part.startsWith("https://") || part.startsWith("http://")) {
                    content.push(
                        <div key={i}>
                            <span>Form: </span>
                            <a href={part} target="_blank" rel="noopener noreferrer" style={{ color: "#25a3d6" }}>
                                Attachment Link
                            </a>
                        </div>
                    );
                } else {
                    content.push(
                        <div key={i}>
                            <span>{part}</span>
                        </div>
                    );
                }
            }

            return <div>{content}</div>;
        } else {
            return <div>{tasksList}</div>;
        }
    };

    return (
        <td
            onClick={() => props.onClick(null)}
            style={props.style}
            className={props.className}
        >
            {renderContent()}
        </td>
    );
};

const PictureData = props => {
    if (props.rowType === "groupHeader") {
        return null;
    }
    const { dataItem } = props;
    const tasksList = dataItem?.v3Picture;

    const renderContent = () => {
        if (tasksList && tasksList.includes(";")) {
            const parts = tasksList.split(";").filter(part => part.trim() !== ""); // Remove empty strings
            const content = [];

            for (let i = 0; i < parts.length; i++) {
                const part = parts[i];

                if (part.startsWith("https://") || part.startsWith("http://")) {
                    content.push(
                        <div key={i}>
                            <span>Picture: </span>
                            <a href={part} target="_blank" rel="noopener noreferrer" style={{ color: "#25a3d6" }}>
                                Picture Link {i + 1}
                            </a>
                        </div>
                    );
                } else {
                    content.push(
                        <div key={i}>
                            <span>{part}</span>
                        </div>
                    );
                }
            }

            return <div>{content}</div>;
        } else {
            return <div>{tasksList}</div>;
        }
    };

    return (
        <td
            onClick={() => props.onClick(null)}
            style={props.style}
            className={props.className}
        >
            {renderContent()}
        </td>
    );
};

const QrScanData = props => {
    if (props.rowType === "groupHeader") {
        return null;
    }
    const { dataItem } = props;
    const tasksList = dataItem?.v3QrScan;

    const renderContent = () => {
        if (tasksList && tasksList.includes(";")) {
            const parts = tasksList.split(";").filter(part => part.trim() !== ""); // Remove empty strings
            const content = [];

            for (let i = 0; i < parts.length; i++) {
                const part = parts[i];

                if (part.startsWith("https://") || part.startsWith("http://")) {
                    content.push(
                        <div key={i}>
                            <span>Qr Scan: </span>
                            <a href={part} target="_blank" rel="noopener noreferrer" style={{ color: "#25a3d6" }}>
                                Link
                            </a>
                        </div>
                    );
                }
                else {
                    content.push(
                        <div key={i}>
                            <span>{part}</span>
                        </div>
                    );
                }
            }

            return <div>{content}</div>;
        } else {
            return <div>{tasksList}</div>;
        }
    };

    return (
        <td
            onClick={() => props.onClick(null)}
            style={props.style}
            className={props.className}
        >
            {renderContent()}
        </td>
    );
};

const MessageData = props => {
    if (props.rowType === "groupHeader") {
        return null;
    }
    const { dataItem } = props;
    const tasksList = dataItem?.messageV3;

    const renderContent = () => {
        const isUrl = (str) => str.startsWith("https://") || str.startsWith("http://");

        if (tasksList) {
            if (tasksList.includes(";")) {
                const parts = tasksList.split(";").filter(part => part.trim() !== "");
                const content = [];

                for (let i = 0; i < parts.length; i++) {
                    const part = parts[i];

                    if (isUrl(part)) {
                        content.push(
                            <div key={i}>
                                <a href={part} target="_blank" rel="noopener noreferrer" style={{ color: "#25a3d6" }}>
                                    Attachment Link
                                </a>
                            </div>
                        );
                    } else {
                        content.push(
                            <div key={i}>
                                <span>{part}</span>
                            </div>
                        );
                    }
                }

                return <div>{content}</div>;
            } else {
                if (isUrl(tasksList)) {
                    return (
                        <div>
                            <a href={tasksList} target="_blank" rel="noopener noreferrer" style={{ color: "#25a3d6" }}>
                                Attachment Link
                            </a>
                        </div>
                    );
                } else {
                    return <div>{tasksList}</div>;
                }
            }
        } else {
            return null;
        }
    };

    return (
        <td
            onClick={() => props.onClick(null)}
            style={props.style}
            className={props.className}
        >
            {renderContent()}
        </td>
    );
};

const V3MsgData = props => {
    if (props.rowType === "groupHeader") {
        return null;
    }
    const { dataItem } = props;
    const tasksList = dataItem?.v3Msg;

    const renderContent = () => {
        if (tasksList && tasksList.includes(";")) {
            const parts = tasksList.split(";").filter(part => part.trim() !== ""); // Remove empty strings
            const content = [];

            for (let i = 0; i < parts.length; i++) {
                const part = parts[i];

                if (part.startsWith("https://") || part.startsWith("http://")) {
                    if ((part.includes("http://mobile.allgeo.com"))) {
                        content.push(
                            <div key={i}>
                                <span>{part}</span>
                            </div>
                        );
                    }
                    else {
                        content.push(
                            <div key={i}>

                                <a href={part} target="_blank" rel="noopener noreferrer" style={{ color: "#25a3d6" }}>
                                    Attachment Link
                                </a>
                            </div>
                        );
                    }
                }
                else {
                    content.push(
                        <div key={i}>
                            <span>{part}</span>
                        </div>
                    );
                }
            }

            return <div>{content}</div>;
        } else {
            return <div>{tasksList}</div>;
        }
    };

    return (
        <td
            onClick={() => props.onClick(null)}
            style={props.style}
            className={props.className}
        >
            {renderContent()}
        </td>
    );
};



const MonitorNewJobStatus = props => {



    if (props.rowType === "groupHeader") {
        return null;
    }

    const { dataItem, fromMonitorv2 } = props;
    const { timeClockStatus } = fromMonitorv2 ? dataItem.locationStatus : dataItem;
    return (
        <td
            style={props.style}
            className={props.className + " overflowVisible"}
        >
            {
                (!!timeClockStatus?.[0]?.lastTimeclockStageName || !!timeClockStatus?.[0]?.lastJobsiteName || !!timeClockStatus?.[0]?.hosDuration || !!timeClockStatus?.[0]?.lastTimeclockDuration) ?
                    <>
                        <div>
                            <strong>{timeClockStatus?.[0]?.lastTimeclockStageName}</strong>
                            <div>From Site:{!!timeClockStatus?.[0]?.lastJobsiteName ? timeClockStatus?.[0]?.lastJobsiteName : "None"}</div>
                            <div>Duration: {timeClockStatus?.[0]?.lastTimeclockDuration}</div>
                            <div className="txtWithIcon">
                                <img src={iconClock} alt="time" />
                                <p>{timeClockStatus?.[0]?.lastTimeclockTime}</p>
                            </div>
                        </div>
                        {
                            timeClockStatus?.length > 1 &&
                            <div className="haveTooltip">
                                <div className="mainTxt">See More</div>
                                <div className="tooltipBlk">
                                    {
                                        timeClockStatus.map((item, ind) => {

                                            if (ind === 0) return null

                                            return (
                                                <div key={ind} >
                                                    <strong>{item.lastTimeclockStageName}</strong>
                                                    <div>{item.jobStat}  From Site:{!!item.lastJobsiteName ? item.lastJobsiteName : "None"}</div>
                                                    <div>Duration: {item.lastTimeclockDuration}</div>
                                                    <div className="txtWithIcon">
                                                        <img src={iconClock} alt="time" />
                                                        <p>{item.lastTimeclockTime}</p>
                                                    </div>
                                                    {ind < timeClockStatus.length - 1 && <hr />}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        }
                    </>
                    :
                    "Day Not Started"
            }
        </td>
    )
}



const StatusChip = ({ dataItem, dataIndex }) => {
    const getStatusClass = (status) => {
        switch (status) {
            case "Active":
                return "table-active";
            case "Inactive":
                return "table-inactive";
            case "App Not Installed":
                return "table-notinstall";
            default:
                return "table-chip-all";
        }
    };

    const [isOpen, setIsOpen] = useState(false);
    const anchorRefs = useRef([]);
    const togglePopover = (isHoverIn, index) => {
        setIsOpen(isHoverIn && (index === 0 ? true : index));
    };
    const statusClass = getStatusClass(dataItem?.appDataStatus?.appStatus);
    return (
        <td
            onMouseLeave={() => togglePopover(false, dataIndex)}
            style={{ overflow: "unset" }}
        >
            <div style={{ position: "relative", width: "fit-content" }}>
                {dataItem?.appDataStatus?.showAppActiveFlag === "true" && (<div>

                    {dataItem?.appDataStatus?.appStatusDuration && (<span>
                        App Active : {dataItem?.appDataStatus?.appStatusDuration}
                    </span>)}
                    {dataItem?.appDataStatus?.lastAppActiveTime && <div className="txtWithIcon" ><img src={iconClock} alt="time" /> <strong >{dataItem?.appDataStatus?.lastAppActiveTime}</strong></div>}
                </div>)}


                {dataItem?.appDataStatus?.showAppActiveFlag === "true" &&
                    dataItem?.appDataStatus?.appStatus !== "Okay" && !!dataItem?.appDataStatus?.appStatus && (
                        <span
                            className={`employeemodaltable status-chip statusbtn ${statusClass}`}
                            ref={(el) => (anchorRefs.current[dataIndex] = el)}
                        >
                            {dataItem?.appDataStatus?.appStatus}
                        </span>)}


                {dataItem?.appDataStatus?.showAppActiveFlag !== "true" && !!dataItem?.appDataStatus?.appStatus && (<span
                    className={`employeemodaltable status-chip statusbtn ${statusClass}`}
                    ref={(el) => (anchorRefs.current[dataIndex] = el)}
                >
                    {dataItem?.appDataStatus?.appStatus}
                </span>)}
                {dataItem?.appDataStatus?.appStatusContent && (
                    <span
                        onMouseEnter={() => togglePopover(true, dataIndex)}
                        style={{
                            fontSize: 10,
                            background: "#ccc",
                            borderRadius: "50%",
                            padding: 4,
                            marginLeft: 4,
                            height: "14px",
                            display: "inline-flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                    >
                        ?
                    </span>
                )}
                <div
                    id="openPopover"
                    style={{
                        width: 200,
                        position: "absolute",
                        left: "50%",
                        transform: "translateX(-50%)",
                        top: 20,
                        background: "white",
                        zIndex: 99,
                        borderRadius: 5,
                        boxShadow: "0px 0px 10px lightgray",
                        display: isOpen ? "block" : "none",
                    }}
                >
                    <div
                        // onMouseLeave={() => togglePopover(false, dataIndex)}
                        style={{ padding: "10px" }}
                        dangerouslySetInnerHTML={{
                            __html: dataItem?.appDataStatus?.appStatusContent,
                        }}
                    />
                </div>
            </div>
        </td>
    );
};

export const handleCustomCells = (fieldName, openPopup, handleCellFieldSelection, editID, editField, onSave, editable, otherData, togglePopover) => {
    switch (fieldName) {
        case "contactPhone":
            return (props) => <PhoneNumberCell openPopup={openPopup} {...props} onClick={handleCellFieldSelection} />
        case "link":
            return (props) => <Link {...props} onClick={handleCellFieldSelection} />
        case "earningCode":
            return (props) => <EarningCodeDropDown {...props} editID={editID} editField={editField} onClick={handleCellFieldSelection} onSave={onSave} editable={editable} otherData={otherData} />
        case "creationTime":
            return props => <TimeCell {...props} editID={editID} editField={editField} onClick={handleCellFieldSelection} onSave={onSave} editable={editable} otherData={otherData} />

        case "v3Message":
            return (props) => <FormDataMessageCell {...props} onClick={handleCellFieldSelection} />
        case "messageV3":
            return props => <MessageData {...props} editID={editID} editField={editField} onClick={handleCellFieldSelection} onSave={onSave} editable={editable} otherData={otherData} />
        case "v3Msg":
            return props => <V3MsgData {...props} editID={editID} editField={editField} onClick={handleCellFieldSelection} onSave={onSave} editable={editable} otherData={otherData} />

        // case "message":
        //     return (props) => <FormDataMessageCell {...props} onClick={handleCellFieldSelection} />
        case "message":
            return (props) => <FormDataMessageCell {...props} onClick={handleCellFieldSelection} />
        case "latlog":
            return (props) => <GoogleMapLink text={props.dataItem[fieldName]} {...props} />
        case "chargeDesc":
            return (props) => {
                return props.dataItem.invoiceURL ? <Link text={props.dataItem.chargeDesc} {...props} onClick={() => openPopup(props.dataItem)} />
                    : props.rowType === "groupHeader" ? null : <td>{props.dataItem.chargeDesc}</td>
            }
        case 'trade':
            return (props) => <ValueOptions {...props} editID={editID} editField={editField} onClick={handleCellFieldSelection} onSave={onSave} editable={editable} otherData={otherData} />
        case 'jobName':
            return (props) => <TaskNameDropDown {...props} editID={editID} editField={editField} onClick={handleCellFieldSelection} onSave={onSave} editable={editable} otherData={otherData} />
        case 'jobID':
            return (props) => <TaskIDDropDown {...props} editID={editID} editField={editField} onClick={handleCellFieldSelection} onSave={onSave} editable={editable} otherData={otherData} />
        case "tcSentDate":
            return props => <DateCell {...props} editID={editID} editField={editField} onClick={handleCellFieldSelection} onSave={onSave} editable={editable} otherData={otherData} />
        case "startTCSentDate":
            return props => <DateCell {...props} editID={editID} editField={editField} onClick={handleCellFieldSelection} onSave={onSave} editable={editable} otherData={otherData} />
        case "endTCSentDate":
            return props => <DateCell {...props} editID={editID} editField={editField} onClick={handleCellFieldSelection} onSave={onSave} editable={editable} otherData={otherData} />
        case "tcSentTime":
            return props => <TimeCell {...props} editID={editID} editField={editField} onClick={handleCellFieldSelection} onSave={onSave} editable={editable} otherData={otherData} />
        case "startTCSentTime":
            return props => <TimeCell {...props} editID={editID} editField={editField} onClick={handleCellFieldSelection} onSave={onSave} editable={editable} otherData={otherData} />
        case "endTCSentTime":
            return props => <TimeCell {...props} editID={editID} editField={editField} onClick={handleCellFieldSelection} onSave={onSave} editable={editable} otherData={otherData} />
        case 'v3FormData':
            return props => <FormData {...props} editID={editID} editField={editField} onClick={handleCellFieldSelection} onSave={onSave} editable={editable} otherData={otherData} />
        case 'formData':
            return props => <FormDataList {...props} editID={editID} editField={editField} onClick={handleCellFieldSelection} onSave={onSave} editable={editable} otherData={otherData} />
        case 'qrScan':
            return props => <QRDataCell {...props} editID={editID} editField={editField} onClick={handleCellFieldSelection} onSave={onSave} editable={editable} otherData={otherData} />
        case 'picture':
            return props => <FormDataPictureCell {...props} editID={editID} editField={editField} onClick={handleCellFieldSelection} onSave={onSave} editable={editable} otherData={otherData} />
        case 'imgAttach':
            return (props) => <AttachMessageCell {...props} onClick={handleCellFieldSelection} />
        case 'barCodeAttach':
            return props => <AttachMessageCell {...props} editID={editID} editField={editField} onClick={handleCellFieldSelection} onSave={onSave} editable={editable} otherData={otherData} />
        case 'formAttach':
            return props => <AttachMessageCell {...props} editID={editID} editField={editField} onClick={handleCellFieldSelection} onSave={onSave} editable={editable} otherData={otherData} />
        // case "startMessage":
        //     return (props) => <TextLink {...props} onClick={handleCellFieldSelection} />
        // case 'endMessage':
        //     return (props) => <TextLink {...props} onClick={handleCellFieldSelection} />
        case 'qrScanLink':
            return props => <TextLink {...props} onClick={handleCellFieldSelection} />
        case 'qrScanText':
            return props => <TextLink {...props} onClick={handleCellFieldSelection} />
        case 'v3Picture':
            return props => <PictureData {...props} editID={editID} editField={editField} onClick={handleCellFieldSelection} onSave={onSave} editable={editable} otherData={otherData} />
        case 'v3QrScan':
            return props => <QrScanData {...props} editID={editID} editField={editField} onClick={handleCellFieldSelection} onSave={onSave} editable={editable} otherData={otherData} />
        case 'v3Task':
            return props => <TaskCell {...props} editID={editID} editField={editField} onClick={handleCellFieldSelection} onSave={onSave} editable={editable} otherData={otherData} />
        case "latlong":
            return (props) => <GoogleMapLink text={props.dataItem[fieldName]} {...props} />
        case "address":
            return (props) => <GoogleMapLink text={props.dataItem[fieldName]} {...props} />
        case "startAddress":
            return (props) => <GoogleMapLink text={props.dataItem[fieldName]} customValue={props.dataItem['startGeoLocation']} {...props} />
        case "endAddress":
            return (props) => <GoogleMapLink text={props.dataItem[fieldName]} customValue={props.dataItem['endGeoLocation']} {...props} />
        case "locAddress":
            return (props) => <GoogleMapLink text={props.dataItem[fieldName]} {...props} />
        case "attachments":
            return (props) => <FormDataCell text={props.dataItem[fieldName]} {...props} onClick={() => { }} />
        case "woMessage":
            return (props) => <TextLink {...props} onClick={handleCellFieldSelection} />
        // case "message":
        //     return (props) => <TextLink {...props} onClick={handleCellFieldSelection} />
        case "startMessageAttachment":
            return (props) => <TextHyperLink {...props} onClick={handleCellFieldSelection} />
        case 'endMessageAttachment':
            return (props) => <TextHyperLink {...props} onClick={handleCellFieldSelection} />
        case "hosMessage":
            return (props) => <FormDataCell text={props.dataItem[fieldName]} {...props} onClick={() => { }} />
        case "devicedesc":
            return props => <DeviceDesc {...props} openPopup={openPopup} />
        case "deviceId":
            return props => <DeviceId {...props} openPopup={openPopup} />
        case "formType":
            return props => <FormType {...props} openPopup={openPopup} onClick={handleCellFieldSelection} />
        case "deviceStatusTimeStamp":
            return props => <DeviceStatus {...props} />
        case "scheduleStatus":
            return props => <ScheduleStatus {...props} />
        case "lastLocationTimeStamp":
            return props => <LastLocation {...props} />
        case "jobStatus":
            return props => <JobStatus {...props} />
        case "timeClockStatus.lastTimeclockStageName":
            return props => <MonitorNewJobStatus {...props} />
        case "appDataStatus.appStatus":
            return props => <StatusChip {...props} togglePopover={togglePopover} />
        case "employeeID":
            return props => <EmployeeId {...props} openPopup={openPopup} />
        case "locationStatus.trackingStatus":
            return props => <ScheduleStatus {...props} fromMonitorv2={true} />
        default:
            return null
    }
}
