import React, { useEffect } from 'react';
import { filterKeys } from "./helperFunctions/helperFunction";
import { DropdownList } from '../../common/dropdown';


export const ReportSelector = ({ isAnyReportAllowed, reportMapping, reportConfig, setReportConfig, handleDropdownChange, availableReport,reportsList, setDeletePopUp, isBlairReportAllowed,isAssistLLCReportAllowed }) => {

    useEffect(() => {
        // Set default report if the current one is not allowed

        if (reportConfig.type === 'customReport' || reportConfig.type === 'debugLogs') {
            const firstAllowedReport = filterKeys(reportMapping, reportConfig).find(key => {
                const report = reportMapping[reportConfig.type][key];
                return Array.isArray(availableReport) && availableReport.includes(report.reportapikey);
            });

            if (firstAllowedReport) {
                setReportConfig({ ...reportConfig, report: firstAllowedReport });
            }
        }

    }, [reportConfig.type]);

    const DeletePopUp = () => {
        setDeletePopUp(true)
    }

    return (
        <>
            {!isAnyReportAllowed && (
                <div>No Reports Available For Your Account</div>
            )}

            {isAnyReportAllowed && (
                <ul className="boxBlkList">
                    {filterKeys(reportMapping, reportConfig).map(key => {
                        const report = reportMapping[reportConfig.type][key];
                        let isAllowed = false;
                        // Check if 'Paydata Blair Duron' is already in the dropdowndata
                        const blairDuronExists = report.dropdowndata?.some(data => data.value === "blairduronpaydata");
                        const asistLLCExists = report.dropdowndata?.some(data => data.value === "assistservicepaydatareport");

                        if (report.reportapikey === "adppayroll" && isBlairReportAllowed && !blairDuronExists) {
                            report.dropdowndata.push({ label: "Paydata Blair Duron", value: "blairduronpaydata" });
                        }
                        if (report.reportapikey === "adppayroll" && isAssistLLCReportAllowed && !asistLLCExists) {
                            report.dropdowndata.push({ label: "Assist Services LLC", value: "assistservicepaydatareport" });
                        }

                        if (reportConfig.type === 'customReport') {
                            isAllowed = Array.isArray(availableReport) && availableReport.includes(report.reportapikey);
                        } else if (reportConfig.type === 'debugLogs') {
                            // Check if debugLogs are allowed
                            isAllowed = Array.isArray(availableReport) && availableReport.includes(report.reportapikey);
                        } else {
                            // For other types, all reports are allowed
                            isAllowed = true;
                        }

                        if (isAllowed) {
                            if (report.isCustomReport) {
                                return (
                                    <li
                                        key={key}
                                        className={`flex-delete ${reportConfig.report === report.type ? 'active' : ''}`}
                                        onClick={() => { if (reportConfig.report !== report.type) setReportConfig({ ...reportConfig, report: report.type, customReportsName: "", }) }}
                                    >
                                        <div className='custom-title'>
                                            <div className="boxHeading">{report.title}</div>
                                            <div style={{fontSize : "0.625rem"}}>
                                                <em>(Custom Report)</em>
                                            </div>
                                        </div>
                                        <button className='row-end-delete btnDelete' onClick={DeletePopUp}></button>
                                    </li>
                                );
                            } else {
                                return (
                                    <li
                                        key={key}
                                        className={`${reportConfig.report === report.type ? 'active' : ''}`}
                                        onClick={() => { if (reportConfig.report !== report.type) setReportConfig({ ...reportConfig, report: report.type, customReportsName: "", }) }}
                                    >
                                        <div className="boxHeading">{report.title}</div>
                                        {report.dropdown && (
                                            <DropdownList
                                                style={{ width: "300px" }}
                                                list={report.dropdowndata}
                                                defaultValue={report.dropdowndata[0].value}
                                                onChange={handleDropdownChange}
                                            />
                                        )}
                                        <p>{report.headerText}</p>
                                        {report.description && (
                                            <div className="boxNote">
                                                <span>Report Description</span>
                                                <em>{report.description}</em>
                                            </div>
                                        )}
                                    </li>
                                );
                            }
                        } else {
                            return null;
                        }
                    })}

                </ul>
            )}
        </>
    );
};