import { formatDate } from '@progress/kendo-intl';

export const PARAMS = {
    date_fr: formatDate(new Date(), 'yyyy/MM/dd'),
    date_to: formatDate(new Date(), 'yyyy/MM/dd'),
    reportFor: "",
    offsetStr: "",
    tzStr: "",
    limit: "",
    device_tz_selected: "Account",
    stageid: "99,99,false,none,none",
}