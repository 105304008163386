import React, { useEffect, useState, useRef } from 'react';
import { apiProvider } from '../../../../../services/api/provider';
import KendoGrid from '../../../../common/kendo/KendoGrid';
import SiteLoader from "../../../../common/SiteLoader";
import { trackingReportColumns, PARAMS, trackingReportTableAggregates, trackingReportTableAggregatesText } from './utils'

export const TrackingReport = ({genSetting}) => {
    const [trackingReport, setTrackingReport] = useState();
    const [showLoader, setShowLoader] = useState(false);
    const [params,setParams] = useState(PARAMS)
    const childRef = useRef()

    const getAdvanceTimeClockReport = async () => {
        try {
            setShowLoader(true);
            setParams({ ...PARAMS, device_tz_selected: genSetting?.report_timezone });
            const response = await apiProvider.getAll('/track/mobile/v1/allgeo/trackingreport', params);
            setTrackingReport(response);
        } catch (error) {
            console.error("API Error:", error);
        } finally {
            setShowLoader(false);
        }
    };

    useEffect(() => {
        getAdvanceTimeClockReport();
    }, [genSetting]);

    
    const HOURS = trackingReportColumns?.find(e => e.field == "elapsedTimeSec").title

    return (
        <>
            {trackingReport &&
                <KendoGrid
                    gridData={trackingReport}
                    setGridData={setTrackingReport}
                    columnData={trackingReportColumns}
                    module={"buildReports"}
                    subModule='trackingReport'
                    getAPiUrl={'/track/mobile/v1/allgeo/trackingreport'}
                    requestParams={PARAMS}
                    accessLevel={{ parentMenu: 3, childMenu: 3 }}
                    ref={childRef}
                    otherData={{
                        showExportDropdown: true,
                        actionColumnBtns: [],
                        enableFooterDisplay: true,
                        aggregates: trackingReportTableAggregates,
                        aggregateText: trackingReportTableAggregatesText,
                        minutesFormat: genSetting?.minutes,
                        dateFormat: genSetting?.date_display_format,
                        columnFormatMap: {
                            [HOURS]: "hh:mm:ss",
                        }
                    }}
                />
            }
            <SiteLoader isActive={showLoader} />
        </>
    )
}