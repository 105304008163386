export const maskPhoneNumber = (phoneNumber) => {
    // Check if the input is a valid phone number
    if (typeof phoneNumber !== "string" || !/^\d+$/.test(phoneNumber)) {
      console.error("Invalid phone number format");
      return null;
    }
  
    let firstTwoDigits = phoneNumber.slice(0, 2);
    let lastTwoDigits = phoneNumber.slice(-2);
  
    let middleDigitsLength = phoneNumber.length - 4;
    let maskedMiddleDigits = "*".repeat(middleDigitsLength);
  
    let maskedPhoneNumber = firstTwoDigits + maskedMiddleDigits + lastTwoDigits;
  
    return maskedPhoneNumber;
  }