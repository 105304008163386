import React, { Fragment, useState, useEffect } from "react";
import { useLocation } from 'react-router-dom'
import { useDocumentTitle } from "../../../hooks/setDocumentTitle";
import { getAccessLevel } from '../../common/functions';
import Header from "../../layout/Header";
import SiteLoader from '../../common/SiteLoader';
import ToastMessage from '../../common/ToastMessage';
import UploadHub from "./UploadHub";
import QRGenerator from "./QRGenerator"

const tabs = {
    uploadHub: 'uploadHub',
    formBuilder: 'formBuilder',
    qrGenerator: 'qrGenerator'
}

const Tools = () => {

    useDocumentTitle("Tools - allGeo");
    const { search } = useLocation();

    let queryParams = new URLSearchParams(search);
    let selectedTab = queryParams.get('tab');

    const [activeTab, setActiveTab] = useState(selectedTab ? selectedTab : tabs.uploadHub);
    const [toastMsg, setToastMsg] = useState({
        msg: "",
        type: "msgSuccess"
    });
    const [showLoader, setShowLoader] = useState(false);
    const [accessLevel, setAccessLevel] = useState({});

    const handleTabChange = tab => {
        setActiveTab(tab);
    }

    useEffect(() => {
        setShowLoader(true)
        getAccessLevel("company", "tools").then((response) => {
            setAccessLevel(response);
        });
        setTimeout(() => {
            setShowLoader(false)
        }, 1000);
    }, []);

    return (
        <Fragment>
            <div className="mainContentWrap">
                <Header title={'Tools'} />
                <div className="mainContent">
                    <ul className="rightInnerNav">
                        <li><a className={`${activeTab === tabs.uploadHub ? 'active' : ''}`} onClick={() => handleTabChange(tabs.uploadHub)}>Upload Hub</a></li>
                        <li><a className={`${activeTab === tabs.formBuilder ? 'active' : ''}`} onClick={() => window.location.href = `${process.env.REACT_APP_API_URL}/track/mgt?page=uploadadminpage.v1&tab=formBuilder&q=${Math.floor(Math.random() * 9999999)}`}>Form Builder</a></li>
                        <li><a className={`${activeTab === tabs.qrGenerator ? 'active' : ''}`} onClick={() => handleTabChange(tabs.qrGenerator)}>QR Generator</a></li>
                    </ul>
                    {activeTab === tabs.uploadHub && <UploadHub setShowLoader={setShowLoader} setToastMsg={setToastMsg} accessLevel={accessLevel.childMenu} />}
                    {activeTab === tabs.qrGenerator && <QRGenerator accessLevel={accessLevel.childMenu} />}
                </div>
            </div>
            {toastMsg.msg && <ToastMessage
                cssClass={toastMsg.type}
                message={toastMsg.msg}
                onClose={() => setToastMsg({ msg: "", type: "" })}
            />}
            <SiteLoader isActive={showLoader} />
        </Fragment>

    )
}

export default Tools