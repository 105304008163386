import { getAccountLabel } from '../../../utils';

// Helper Funcionts
export { handleDeleteEmployee } from './handleDeleteEmployee';
export { handleFetchEmployeeAppData } from './handleFetchEmployeeAppData';
export { handleFetchTimeclockSettings } from './handleFetchTimeclockSettings';
export { handleSuspendEmployee } from './handleSuspendEmployee';
export { getMilesData } from './getMilesData';
export { getAlertsAndNotificationData } from './getAlertsAndNotificationData';


// Constants
export { TAB_INDEX, PINNED_BOX } from './constants';


const employeeLabel = getAccountLabel('employee');
export const defaultMode = {
  title: `Monitor ${employeeLabel}`,
  type: "main",
  data: null,
  hasPrev: false,
};

export const dummyEmployeesAppData = [
  {
    "App Status": "Active",
    "Employee Name": "Chai",
    "Last Active": "10/10/2024 00:09:30 AM",
    "Employee ID": 2190112,
    "Job Status": "Arrive",
    Actions: "",
  },
  {
    "App Status": "Inactive",
    "Employee Name": "Chang",
    "Last Active": "10/10/2024 00:09:30 AM",
    "Employee ID": 2190112,
    "Job Status": "Arrive",
    Actions: "",
  },
  {
    "App Status": "App not installed",
    "Employee Name": "Aniseed Syrup",
    "Last Active": "10/10/2024 00:09:30 AM",
    "Employee ID": 2190112,
    "Job Status": "Arrive",
    Actions: "",
  },
  {
    "App Status": "Active",
    "Employee Name": "Chai",
    "Last Active": "10/10/2024 00:09:30 AM",
    "Employee ID": 2190112,
    "Job Status": "Arrive",
    Actions: "",
  },
  {
    "App Status": "Inactive",
    "Employee Name": "Chang",
    "Last Active": "10/10/2024 00:09:30 AM",
    "Employee ID": 2190112,
    "Job Status": "Arrive",
    Actions: "",
  },
  {
    "App Status": "App not installed",
    "Employee Name": "Aniseed Syrup",
    "Last Active": "10/10/2024 00:09:30 AM",
    "Employee ID": 2190112,
    "Job Status": "Arrive",
    Actions: "",
  },
  {
    "App Status": "Active",
    "Employee Name": "Chai",
    "Last Active": "10/10/2024 00:09:30 AM",
    "Employee ID": 2190112,
    "Job Status": "Arrive",
    Actions: "",
  },
  {
    "App Status": "Inactive",
    "Employee Name": "Chang",
    "Last Active": "10/10/2024 00:09:30 AM",
    "Employee ID": 2190112,
    "Job Status": "Arrive",
    Actions: "",
  },
  {
    "App Status": "App not installed",
    "Employee Name": "Aniseed Syrup",
    "Last Active": "10/10/2024 00:09:30 AM",
    "Employee ID": 2190112,
    "Job Status": "Arrive",
    Actions: "",
  },
];


export const notificationData = [
  {
    id: 1,
    names: ["Sam Kahn"],
    status: "SOS",
    time: "1:30 PM",
    incoming: false,
    break: false,
  },
  {
    id: 2,
    names: [
      "Marvin Nguyen",
      "Sam Kahn",
      "Marvin Nguyen",
      "Marvin Nguyen",
      "Marvin Nguyen",
    ],
    status: "IN",
    time: "1:30 PM",
    incoming: false,
    break: false,
  },
  {
    id: 3,
    names: [
      "Sam Kahn’s",
      "Marvin Nguyen",
      "Sam Kahn",
      "Marvin Nguyen",
      "Marvin Nguyen",
      "Marvin Nguyen",
    ],
    status: "LUNCH",
    time: "1:30 PM",
    incoming: false,
    break: false,
  },
  {
    id: 4,
    names: ["Time for lunch Break"],
    time: "12:25 PM",
    incoming: true,
    break: true,
  },
  {
    id: 5,
    names: ["Sam Kahn’s"],
    status: "IN",
    time: "1:30 PM",
    incoming: false,
    break: false,
  },
  {
    id: 6,
    names: [
      "Sam Kahn’s",
      "Marvin Nguyen",
      "Sam Kahn",
      "Marvin Nguyen",
      "Marvin Nguyen",
      "Marvin Nguyen",
    ],
    status: "LUNCH",
    time: "1:30 PM",
    incoming: false,
    break: false,
  },
  {
    id: 7,
    names: ["Time for lunch Break"],
    time: "12:25 PM",
    incoming: true,
    break: true,
  },
  {
    id: 8,
    names: ["Sam Kahn’s"],
    status: "IN",
    time: "1:30 PM",
    incoming: false,
    break: false,
  },
];