import React, { useState, useEffect, Fragment, useRef } from 'react'
import Header from '../../layout/Header';
import KendoGrid from '../../common/kendo/KendoGrid';
import { useDocumentTitle } from '../../../hooks/setDocumentTitle';
import { getAccessLevel } from '../../common/functions';
import { apiProvider } from '../../../services/api/provider';
import { formColumns } from './formsUtils';
import SiteLoader from '../../common/SiteLoader';
import ToastMessage from '../../common/ToastMessage';
import AddNewPopup from './AddNewPopup';
import EditForm from './EditForm';
import ViewForm from './ViewForms';
import { DeletePopup } from '../../common/PopUp';
import AssignForm from './AssignForm';

const Forms = () => {

    useDocumentTitle('Forms - allGeo')

    const [mode, setMode] = useState({
        title: "Manage Forms",
        type: 'main',
        data: null,
        hasPrev: false
    });

    const [accessLevel, setAccessLevel] = useState({});
    const [showAddNewPopup, setShowAddNewPopup] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [toastMsg, setToastMsg] = useState({
        msg: "",
        type: "msgSuccess"
    });

    const [forms, setForms] = useState(null)
    const [assignFormModal, setAssignFormModal] = useState({
        show: false,
        data: null
    })

    const childRef = useRef(null)

    const getFormList = async () => {
        try {
            setShowLoader(true)
            const response = await apiProvider.getAll('/track/mobile/v1/allgeo/forms?active=true')

            if (response.status === 200) {
                setForms(response.forms)
            }

        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    const actionHandler = (type, data) => {
        switch (type) {
            case "view":
                setShowLoader(true)
                setMode({
                    title: "Manage Forms",
                    type: 'view',
                    data: data,
                    hasPrev: false
                })
                break;
            case "edit":
                setShowLoader(true)
                setMode({
                    title: "Manage Forms",
                    type: 'edit',
                    data: data,
                    hasPrev: false
                })
                break;
            default:
                break;
        }
    }

    const updateForms = async data => {

        const payload = data.map(d => ({
            ID: d.formId,
            formName: d.formName,
            active: d.active === "Active"
        }))

        try {
            localStorage.removeItem('formIsDirty')
            const response = await apiProvider.put('/track/mobile/v1/allgeo/forms', payload)
            
            if (response.status === 200) response.message = "Form info updated successfully"

            return response;
        } catch (error) {
            console.log(error);
        }
    }

    const deleteForms = async data => {

        const payload = data.length ? data : [data.formId]

        try {
            setShowLoader(true)
            const response = await apiProvider.removeObject('/track/mobile/v1/allgeo/forms', payload)
            if (data.length)
                response.message = "The selected records have been deleted successfully";
            else
                response.message = `${data.formName} is deleted`

            return response;
        } catch (error) {
            console.log(error);
            throw new Error(error)
        } finally {
            setShowLoader(false)
        }
    }

    const openPopup = (type, data) => {
        switch (type) {
            case "addNew":
                setShowAddNewPopup(true)
                break;
            case "assignTask":
                setShowLoader(true)
                setAssignFormModal({
                    show: true,
                    data
                })
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        getAccessLevel("company", "Forms").then((response) => {
            setAccessLevel(response);
        });
        getFormList()
    }, []);

    useEffect(() => {
        if (toastMsg.msg && toastMsg.type === "msgSuccess") {
            if (childRef.current) {
                childRef.current.refreshData()
            }
            setShowLoader(false)
        }
    }, [toastMsg])


    return (
        <Fragment>
            <div className='mainContentWrap'>
                <Header title={mode.title} />
                <div className='mainContent' id='mainContent'>
                    <Fragment>
                        {
                            forms &&
                            <KendoGrid
                                gridData={forms}
                                setGridData={setForms}
                                columnData={formColumns}
                                accessLevel={accessLevel}
                                ref={childRef}
                                module={"forms"}
                                getAPiUrl={'/track/mobile/v1/allgeo/forms'}
                                requestParams={{ active: true }}
                                openPopup={openPopup}
                                updateItem={updateForms}
                                deleteItem={deleteForms}
                                lockActionColumn={true}
                                actionColumnHandler={actionHandler}
                                bulkDelete={deleteForms}
                                otherData={{
                                    disableExportToExcel: true
                                }}
                            />
                        }
                    </Fragment>
                    {assignFormModal.show && <AssignForm forms={assignFormModal.data} setShowLoader={setShowLoader} setToastMsg={setToastMsg} closePopup={() => setAssignFormModal({ show: false, data: null })} />}
                    {showAddNewPopup && <AddNewPopup setShowLoader={setShowLoader} setToastMsg={setToastMsg} closePopup={() => setShowAddNewPopup(false)} />}
                    {mode.type === 'edit' && <EditForm formId={mode.data?.formId} setShowLoader={setShowLoader} setMode={setMode} mode={mode} setToastMsg={setToastMsg} />}
                    {mode.type === 'view' && <ViewForm formId={mode.data?.formId} setShowLoader={setShowLoader} setMode={setMode} mode={mode} setToastMsg={setToastMsg} accessLevel={accessLevel.childMenu} />}
                </div>
            </div>
            {mode.type === 'delete' &&
                <DeletePopup
                    onSuccess={async () => {
                        try {
                            const res = await deleteForms(mode.data)
                            if (res.status === 200) {
                                setMode({
                                    title: "Manage Forms",
                                    type: 'main',
                                    data: null,
                                    hasPrev: false
                                })
                                setToastMsg({
                                    msg: res.message,
                                    type: "msgSuccess"
                                })
                            }
                        } catch (err) {

                        }
                    }}
                    closePopup={() => setMode({
                        title: "Manage Forms",
                        type: 'main',
                        data: null,
                        hasPrev: false
                    })}
                />
            }
            {
                toastMsg.msg && <ToastMessage
                    cssClass={toastMsg.type}
                    message={toastMsg.msg}
                    onClose={() => setToastMsg({ msg: "", type: "" })}
                />
            }
            <div className={`overlay ${(showAddNewPopup || mode.type !== 'main' || assignFormModal.show) ? 'active' : ''}`} />
            <SiteLoader isActive={showLoader} />
        </Fragment >
    )
}

export default Forms