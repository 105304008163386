import React, { Fragment, useRef, useEffect, useState } from 'react'
import { apiProvider } from '../../../../services/api/provider'
import KendoGrid from '../../../common/kendo/KendoGrid'
import { approvalAuditcolumns, customDateFormat, NO_RECORD_TEXT } from '../editReportUtils';
import { formatDate } from '@progress/kendo-intl';

const params = {
    EditTabValue: 'approval_audit',
    date_fr: formatDate(new Date(), 'yyyy/MM/dd'),
    date_to: formatDate(new Date(), 'yyyy/MM/dd'),
    reportFor: "all",
    actual_date: false
}

const AuditReport = ({ columnName, setShowLoader, accessLevel, dropdownData, toastMsg, setToastMsg, setColumnList, columnList, setKendoDataState }) => {
    const globalStartDate = sessionStorage.getItem('EditReport:startDate')
    const globalEndDate = sessionStorage.getItem('EditReport:endDate')
    const reportForValues = sessionStorage.getItem('EditReport:reportFor')

    if (globalStartDate && globalEndDate) {
        params.date_fr = globalStartDate
        params.date_to = globalEndDate
        params.reportFor = reportForValues || ''
    }

    const [approvalAuditData, setApprovalAuditData] = useState(null)

    const childRef = useRef()

    const getApprovalAuditReports = async () => {
        try {
            setShowLoader(true)
            const response = await apiProvider.getAll('/track/mobile/v1/allgeo/getTimeClockApprovalAuditData', params)

            setApprovalAuditData(response)
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    const updateReport = async data => {
        const payload = [...data].filter(d => d.txnID).map(fields => {


            let startTime = fields.startTCSentTime.split(" ")
            let endTime = fields.endTCSentTime.split(" ")

            if (startTime.length > 2) {
                startTime = startTime[0] + " " + startTime[1]
            } else {
                startTime = startTime[0]
            }

            if (endTime.length > 2) {
                endTime = endTime[0] + " " + endTime[1]
            } else {
                endTime = endTime[0]
            }

            return {
                "accountID": fields.accountID,
                "deviceID": fields.deviceID,
                "deviceName": fields.deviceName,
                "txnID": fields.txnID,
                "startTxnID": fields.startTxnID,
                "endTxnID": fields.endTxnID,
                "startStageName": fields.startStageName,
                "endStageName": fields.endStageName,
                "startTCSentDate": fields.startTCSentDate,
                "startTCSentTime": fields.startTCSentTime,
                "endTCSentDate": fields.endTCSentDate,
                "endTCSentTime": fields.endTCSentTime,
                "recRegularHours": fields.recRegularHours,
                "recOTHours": fields.recOTHours,
                "recDOTHours": fields.recDOTHours,
                "customerName": fields.customerName,
                "jobName": fields.jobName,
                "jobID": fields.jobID,
                "hosSource": fields.hosSource,
                "remarks": fields.remarks,
                "message": fields.message,
                "modifiedOn": fields.modifiedOn,
                "modifiedBy": fields.modifiedBy,
                "startTempCol": customDateFormat(fields.startTCSentDate, "yyyy/MM/dd", dropdownData.dateFormat) + " " + startTime,
                "endTempCol": customDateFormat(fields.endTCSentDate, "yyyy/MM/dd", dropdownData.dateFormat) + " " + endTime,
            }
        })

        try {

            const response = await apiProvider.put('/track/mobile/v1/allgeo/updateTimeClockApprovalAuditData', payload)

            return {
                status: response.StatusCode,
                message: "Audit Report Updated Successfully"
            }
        } catch (error) {
            console.log(error);
        }
    }

    const deleteReport = async (data, action = 'delete') => {

        const payload = data.length ? data.map(d => ({
            "accountID": d.accountID,
            "deviceID": d.deviceID,
            "deviceName": d.deviceName,
            "txnID": d.txnID,
        }))
            :
            [{
                "accountID": data.accountID,
                "deviceID": data.deviceID,
                "deviceName": data.deviceName,
                "txnID": data.txnID,
            }]

        try {
            setShowLoader(true)
            const response = await apiProvider.removeObject(`/track/mobile/v1/allgeo/deleteTimeClockApprovalAuditData?action=${action}`, payload)
            if (data.length)
                response.message = `The selected records have been ${action === 'delete' ? 'deleted' : 'rejected'} successfully`;
            else
                response.message = `Selected Report is ${action === 'delete' ? 'deleted' : 'rejected'}`

            return {
                status: response.StatusCode,
                message: response.message,
            };
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false)
        }
    }

    const openPopup = async (type, data) => {

        if (type === 'reject') {
            const response = await deleteReport(data, type)

            if (response.status === 200) {
                setToastMsg({ msg: response.message, type: "msgSuccess" })
            } else {
                setToastMsg({ msg: response.message, type: "msgError" })
            }
        }
    }

    useEffect(() => {
        getApprovalAuditReports()
        // approvalAuditcolumns.forEach(item => {
        //     if(Object.keys(columnName).includes(item.field)){
        //         item.title = columnName[item.field] || item.title
        //     }
        // })

        const updatedEditTimeClockColumns = approvalAuditcolumns.map(item => {
            if (Object.keys(columnName).includes(item.field)) {
                return {
                    ...item,
                    title: columnName[item.field] || item.title
                };
            }
            return item;
        });

        setColumnList(updatedEditTimeClockColumns);

    }, [])

    useEffect(() => {
        if (toastMsg.msg && toastMsg.type === "msgSuccess") {
            if (childRef.current) {
                childRef.current.refreshData()
            }
            setShowLoader(false)
        }
    }, [toastMsg])

    return (
        <Fragment>
            {
                approvalAuditData &&
                <KendoGrid
                    gridData={approvalAuditData}
                    setGridData={setApprovalAuditData}
                    columnData={columnList}
                    accessLevel={accessLevel}
                    ref={childRef}
                    module={"editReports"}
                    subModule={"auditReports"}
                    getAPiUrl={'/track/mobile/v1/allgeo/getTimeClockApprovalAuditData'}
                    requestParams={params}
                    deleteItem={deleteReport}
                    updateItem={updateReport}
                    openPopup={openPopup}
                    lockActionColumn={true}
                    filterable={false}
                    otherData={{
                        actionColumnBtns: ['delete'],
                        subPage: "auditReport",
                        enableExportToPDF: true,
                        ...dropdownData,
                        dropdownDependency: {
                            "jobName": {
                                fieldToBeMapped: "jobID",
                                data: dropdownData.taskAll?.map(emp => ({
                                    key: emp.name,
                                    value: emp.taskID
                                }))
                            },
                            "jobID": {
                                fieldToBeMapped: "jobName",
                                data: dropdownData.taskAll?.map(emp => ({
                                    key: emp.taskID,
                                    value: emp.name
                                }))
                            }
                        },
                        noRecordText: NO_RECORD_TEXT,
                        showExportDropdown: true,
                        hideSaveCancelChanges: true,
                        setColumnList: setColumnList,
                        columnList: columnList,
                        setKendoDataState: setKendoDataState,
                        hideMenuCloumns: true
                    }}
                />
            }
        </Fragment>
    )
}

export default AuditReport