import React, { useState, useEffect } from 'react'

const Banner = () => {
    const [isBannerEnabled, setIsBannerEnabled] = useState();
    const [bannerText, setBannerText] = useState("")

    useEffect(() => {
        try {
            let isBanner = JSON.parse(localStorage.getItem("isBannerEnabled"));
            setIsBannerEnabled(isBanner)
            let banner = localStorage.getItem("bannerText");
            setBannerText(banner)
        }
        catch (error) {
            console.log(error)
        }
    }, [])

    if (!isBannerEnabled || !bannerText) {
        return null
    }
    const removeBanner = () => {
        setBannerText('');
        setIsBannerEnabled(false)
    }


    return (
        <>
            <div className='allGeo_msg'>
                <span dangerouslySetInnerHTML={{ __html: bannerText }}></span>
                <span className="closeMsg" onClick={removeBanner} >
                </span>

            </div>
        </>
    )
}

export default Banner

