import React, { Fragment, useEffect, useState } from "react";
import { apiProvider } from '../../../services/api/provider';
import { useForm, Controller } from "react-hook-form";
import { Prompt } from 'react-router';
import ToastMessage from '../../common/ToastMessage';
import SiteLoader from '../../common/SiteLoader';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import $ from "jquery";
import { ConfirmPopup } from "../../common/PopUp";
import { MultiLanguageSettings } from "./MultiLanguageSettings/MultiLanguageSettings";
import { getAccountLabel } from "../../../utils";

export default function SettingsMobileApp(props) {

    const [isUpdateSuccessful, setIsUpdateSuccessful] = useState(null);
    const [mobileAppSettingsValue, setMobileAppSettingsValue] = useState({ mobileApp: {}, timeClock: [] });
    const { control, register, handleSubmit, reset, getValues, setValue, formState: { errors, isDirty } } = useForm();
    const [accountUpdateMsg, setAccountUpdateMsg] = useState('');
    const [msgClass, setMsgClass] = useState('msgError');
    const [showLoader, setShowLoader] = useState(false);
    const [pushPin, setPushPin] = useState([]);
    const [onPremisesPushpinImage, setOnPremisesPushpinImage] = useState('');
    const [nonTrackPushpinImage, setNonTrackPushpinImage] = useState('');
    const [showDialog, setShowDialog] = useState(false);
    const [languages, setLanguages] = useState([]);
    const languageOptions = ['English', 'French', 'Spanish', 'Russian'];

    const unit =  mobileAppSettingsValue.mobileApp.distanceUnit === 0 ? <span>ft</span> : mobileAppSettingsValue.mobileApp.distanceUnit === 1 || mobileAppSettingsValue.mobileApp.distanceUnit === 2 ? <span>m</span>: ""
    const taskLabel = getAccountLabel('task');
    
    let attachments = [
        { id: 0, text: "Select All" },
        { id: 2, text: `${taskLabel}` },
        { id: 3, text: "Notes" },
        { id: 4, text: "Camera" },
        { id: 5, text: "Gallery" },
        { id: 6, text: "Scan" },
        { id: 7, text: "e-Sign" },
        { id: 8, text: "Form" }
    ]

    useEffect(() => {
        if (isUpdateSuccessful === true || isUpdateSuccessful === null) {
            fetchData();
        }

    }, [isUpdateSuccessful]);

    const fetchData = () => {
        setShowLoader(true);
        let promises = [];
        promises.push(apiProvider.getAll('track/react/account/mobileApp'));
        promises.push(apiProvider.getAll('track/react/account/timeclockSettings'));
        promises.push(apiProvider.getAll('track/react/account/getPushpins'));
        Promise.all(promises).then((result) => {

            let responseObj = { mobileApp: {}, timeClock: [] };
            if (result[0]) {
                result[0].auto_arrive_stage = result[0].auto_arrive_stage.toString();
                result[0].auto_depart_stage = result[0].auto_depart_stage.toString();

                if (!result[0].settings_level) {
                    result[0].settings_level = "Device"
                }

                responseObj.mobileApp = result[0];
            }
            if (result[1]) {
                result[1].map((item) => {
                    if (item.attachments) {
                        return item.attachments = item.attachments.split(',').map((id, index) => {
                            let attach = attachments.find(obj => {
                                return obj.id == id
                            })
                            if (attach) {
                                return attach;
                            } else {
                                // return {id: '', name: ''}
                                return []
                            }
                        })
                    } else {
                        return item.attachments = []
                    }

                });
                responseObj.timeClock = result[1];
            }
            if (result[2]) {
                if (result[2].data && result[2].data.length > 0) {
                    setPushPin(result[2].data);
                }
            }
            // setLangArray(responseObj.mobileApp.allowed_app_languages.split(','))
            setLanguages(responseObj.mobileApp.allowed_app_languages.split(','))
            setMobileAppSettingsValue(responseObj);
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setShowLoader(false);
        });

    }

    const onSubmit = (data) => {
        console.log(data.mobileApp);
        if (!languages.includes(data.mobileApp.default_app_language)) {
            data.mobileApp.default_app_language = "English";
        }
        data.mobileApp.allowed_app_languages = languages.join(',')
        data.mobileApp.employee_team = parseInt(data.mobileApp.employee_team);
        data.mobileApp.manager_team = parseInt(data.mobileApp.manager_team);
        data.mobileApp.auto_arrive_stage = parseInt(data.mobileApp.auto_arrive_stage);
        data.mobileApp.auto_depart_stage = parseInt(data.mobileApp.auto_depart_stage);
        //setShowLoader(true);
        data.timeClock.map(item => {
            if (item.attachments) {
                item.attachments = item.attachments.map((attachment, index) => {
                    if (attachment.id) {
                        return attachment.id;
                    } else {
                        return null;
                    }
                });
            }
        });


        data.timeClock.map(item => {
            if (item.attachments) {
                let a = item.attachments.filter((el) => {
                    return el != '' || el != null;
                });
                item.attachments = a.join();
            }
        });
        apiProvider.put('track/react/account/updateMobileApp', data.mobileApp).then((response) => {
            //console.log(response);
            if (response.status === 200) {
                apiProvider.put('track/react/account/updateTimeclockSettings', data.timeClock).then((response) => {
                    if (response.status === 200) {
                        setMsgClass('msgSuccess');
                        setAccountUpdateMsg(response.message);
                        setIsUpdateSuccessful(true);
                    } else {
                        setAccountUpdateMsg('Unable to update time clock settings.');
                        setIsUpdateSuccessful(true);
                    }
                }).catch((err) => {
                    console.log(err);
                    setAccountUpdateMsg('Network error! unable to update account');
                    setIsUpdateSuccessful(true);
                }).finally(() => {
                    localStorage.removeItem('formIsDirty')
                    setShowLoader(true);
                })
            } else {
                setAccountUpdateMsg('Unable to update app settings.');
                setIsUpdateSuccessful(true);
            }
        }).catch((err) => {
            console.log(err);
            setAccountUpdateMsg('Network error! unable to update account');
            setIsUpdateSuccessful(true);
        }).finally(() => {
            localStorage.removeItem('formIsDirty')
            setShowLoader(true)
        });
    }

    useEffect(() => {
        // reset form with user data
        reset(mobileAppSettingsValue);

        if (mobileAppSettingsValue.mobileApp.non_track_pushpin) {
            setNonTrackPushpinImage(getPushPinImageByName(mobileAppSettingsValue.mobileApp.non_track_pushpin));
        } else {
            setNonTrackPushpinImage(getPushPinImageByName('blue'));
        }

        if (mobileAppSettingsValue.mobileApp.on_premises_pushpin) {
            setOnPremisesPushpinImage(getPushPinImageByName(mobileAppSettingsValue.mobileApp.on_premises_pushpin));
        } else {
            setOnPremisesPushpinImage(getPushPinImageByName('yellow'));
        }
    }, [mobileAppSettingsValue]);

    useEffect(() => {
        // reset form with user data
        if (props.accessLevel.childMenu && props.accessLevel.childMenu > 1) {
            props.formState(isDirty)
        }
        if (isDirty) {
            localStorage.setItem('formIsDirty', isDirty)
        }
    }, [isDirty]);

    const changePushPinName = (pushPinName, type) => {
        if (type === 'premises') {
            setValue('mobileApp.on_premises_pushpin', pushPinName, { shouldDirty: true });
            setOnPremisesPushpinImage(getPushPinImageByName(pushPinName));
        }
        if (type === 'non-track') {
            setValue('mobileApp.non_track_pushpin', pushPinName, { shouldDirty: true });
            setNonTrackPushpinImage(getPushPinImageByName(pushPinName));
        }
        $('.pushpinControl').removeClass("active");
    }

    const getPushPinImageByName = (pushPinName) => {

        let pin = pushPin.find(obj => {
            return obj.name == pushPinName
        });

        if (pin) {
            return process.env.REACT_APP_API_URL + '/track/' + pin.image;
        } else {
            return '';
        }
    }

    const formCancelEvent = () => {
        if (isDirty) {
            setShowDialog(true)
        }
    }

    const onSuccess = () => {
        setShowDialog(false);
        fetchData();
    }

    const onFaliure = () => {
        setShowDialog(false);
    }

    const handleChange = (event, index) => {
        let svalue = getValues(`timeClock.${index}.attachments`);
        const currentSelectAll = svalue.some((i) => i.id === 0);
        const nextSelectAll = event.value.some((i) => i.id === 0);
        let value = event.value;
        const currentCount = svalue.length;
        const nextCount = value.length;
        if (
            nextCount > currentCount &&
            !currentSelectAll &&
            !nextSelectAll &&
            attachments.length - 1 === nextCount
        ) {
            value = attachments;
        } else if (
            nextCount < currentCount &&
            currentCount === attachments.length &&
            currentSelectAll &&
            nextSelectAll
        ) {
            value = value.filter((v) => v.id !== 0);
        } else if (!currentSelectAll && nextSelectAll) {
            value = attachments;
        } else if (currentSelectAll && !nextSelectAll) {
            value = [];
        }
        setValue(`timeClock.${index}.attachments`, value, { shouldDirty: true });
    };


    const itemRender = (li, itemProps) => {
        const itemChildren = (
            <div className="customMultiSelect">
                <label>{li.props.children}</label>
                <div className="customCheck noTxt">
                    <input
                        type="checkbox"
                        name={itemProps.dataItem}
                        checked={itemProps.selected}
                        onChange={(e) => itemProps.onClick(itemProps.index, e)}
                    />
                    <label className="customCheckLabel" htmlFor={li.props.children}>{li.props.children}</label>
                </div>
            </div>
        );
        return React.cloneElement(li, li.props, itemChildren);
    };

    const tagRender = (tagData, li) => {
        if (tagData.text != 'Select All') {
            return React.cloneElement(li, li.props, [
                <li key={Math.random} className="custom-tag" style={{ "listStyleType": "none" }}>
                    {tagData.text == 'Select All' ? 'Clear All' : tagData.text}
                </li>, li.props.children,
            ])
        }
    };

    $(".changePushpin").on("click", function () {
        $('.pushpinControl').removeClass("active");
        $(this).parent('.pushpinControl').addClass("active");
    });

    $(document).on("click", function (e) {
        let container = $('.pushpinControl');
        if (!container.is(e.target) && container.has(e.target).length === 0) {
            $('.pushpinControl').removeClass("active");
        }
    });

    const handleCheckboxChange = (e) => {
        const { id, checked, } = e.target;
        console.log(id,checked)
        
        if(checked){
            setLanguages((prev)=>[...prev,id])
        }else{
            setLanguages((prev)=>prev.filter((item)=>item!==id))
        }
        console.log(languages)
      };

    // // Handle checkbox changes
    // const handleLanguageChange = (event) => {
    //     const language = event.target.id;

    //     if (language === 'English') {
    //         // Make sure "English" is always checked
    //         return;
    //     }

    //     // Toggle the selected state for the language
    //     setSelectedLanguages((prevSelected) => {
    //         if (prevSelected.includes(language)) {
    //             // If it's already selected, remove it
    //             return prevSelected.filter((lang) => lang !== language);
    //         } else {
    //             // If it's not selected, add it
    //             return [...prevSelected, language];
    //         }
    //     });
    // };
    // console.log(selectedLanguages.map((e) => e))

    const employeeLabel = getAccountLabel('employee');
    const groupLabel = getAccountLabel('group');


    return (
        <Fragment>
            <Prompt
                when={isDirty}
                message='The changes are still unsaved. Are you sure you want to leave?'
            />
            <form onSubmit={handleSubmit(onSubmit)} id="mobileAppSettingsForm">
                <section>
                    <h3>Location Tracking</h3>
                    <div className="supportedTxt">Manage and update aspects related to Location tracking for your allGeo mobile application here</div>
                    <div className="innerWrap">
                        <div className="rowBlk">
                            <div className="colBlk6 fldWrap">
                                <label>Tracking Frequency Control</label>
                                <select {...register("mobileApp.settings_level")}>
                                    <option>Account</option>
                                    <option>Device</option>
                                </select>
                            </div>
                            <div className="colBlk6 fldWrap">
                                <label>Default Opt-in</label>
                                <select {...register("mobileApp.default_optin")}>
                                    <option value="locn_optin_sms">Optin using SMS</option>
                                    <option value="locn_optin_inapp">Optin using App Msg</option>
                                    <option value="locn_optin_ivr">Optin using Voice</option>
                                </select>
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className="colBlk6 fldWrap">
                                <label>Auto Track</label>
                                <select {...register("mobileApp.autotrack")}>
                                    <option value="0">Disable All</option>
                                    <option value="1">Enable All</option>
                                    <option value="2">Specific Devices</option>
                                </select>
                            </div>
                            <div className="colBlk3 fldWrap">
                                <label>Default Auto Track</label>
                                <div className="withInputSub">
                                    <input type="text" {...register("mobileApp.default_autotrack")} />
                                    <span>days</span>
                                </div>
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className="colBlk6">
                                <label className="withAction">Frequency</label>
                                <div className="rowBlk">
                                    <div className="colBlk6 fldWrap">
                                        <div className="withInputSub">
                                            <input type="text" {...register("mobileApp.frequency_hours")} />
                                            <span>hrs</span>
                                        </div>
                                    </div>
                                    <div className="colBlk6 fldWrap">
                                        <div className="withInputSub">
                                            <input type="text" {...register("mobileApp.frequency_minutes")} />
                                            <span>mins</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className="colBlk3 fldWrap">
                                <label>Minimum Stoppage</label>
                                <div className="withInputSub">
                                    <input type="text" {...register("mobileApp.minimum_stoppage")} />
                                    <span>mins</span>
                                </div>
                            </div>
                            <div className="colBlk3 fldWrap">
                                <label>Snap Route (Cutoff)</label>
                                <select {...register("mobileApp.snap_route_cutoff")}>
                                    <option value="0">No Cutoff</option>
                                    <option value="100">100</option>
                                    <option value="200">200</option>
                                    <option value="1000">1000</option>
                                    <option value="-1">Speed Cutoff</option>
                                </select>
                            </div>
                            <div className="colBlk3 fldWrap">
                                <label>Default Zone Radius</label>
                                <div className="withInputSub">
                                    <input type="text" {...register("mobileApp.default_zone_radius")} />
                                     {unit}
                                </div>
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className="colBlk3 fldWrap">
                                <label>On Premises Pushpin</label>
                                <input type="hidden" {...register("mobileApp.on_premises_pushpin")} />
                                <div className="selectedPushpin"><img src={onPremisesPushpinImage} alt="" /></div>
                                <div className="pushpinControl">
                                    <a className="changePushpin">Change</a>
                                    <div className="pushpinWrap">
                                        <ul>
                                            {
                                                pushPin.map((pin, index) => {
                                                    return <li key={`pushpin${index}`} onClick={() => { changePushPinName(pin.name, 'premises') }}>
                                                        <img key={`pushpin-image-${index}`} src={process.env.REACT_APP_API_URL + '/track/' + pin.image} alt="" />
                                                        <span>{pin.name}</span>
                                                    </li>
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="colBlk3 fldWrap">
                                <label>Non-Track Pushpin</label>
                                <div className="selectedPushpin"><img src={nonTrackPushpinImage} alt="" /></div>
                                <input type="hidden" {...register("mobileApp.non_track_pushpin")} />
                                <div className="pushpinControl">
                                    <a className="changePushpin">Change</a>
                                    <div className="pushpinWrap">
                                        <ul>
                                            {
                                                pushPin.map((pin, index) => {
                                                    return <li key={`pushpin${index}`} onClick={() => { changePushPinName(pin.name, 'non-track') }}>
                                                        <img key={`pushpin-image-${index}`} src={process.env.REACT_APP_API_URL + '/track/' + pin.image} alt="" />
                                                        <span>{pin.name}</span>
                                                    </li>
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="colBlk3 fldWrap">
                                <label>SMS Geo Tag</label>
                                <div className="customCheck yesNo">
                                    <input type="checkbox" id="SMSGeoTag" {...register("mobileApp.sms_geo_tag")} />
                                    <label className="customCheckLabel" htmlFor="SMSGeoTag">yesNo</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <h3>Customers, {taskLabel} & myTeam</h3>
                    <div className="supportedTxt">Manage display of myTeam module & Customers assignment from here</div>
                    <div className="innerWrap">
                        <div className="rowBlk">
                            <div className="colBlk6 fldWrap">
                                <label>Display to {employeeLabel}</label>
                                <select {...register("mobileApp.customer_assignment")}>
                                    <option value="All">All</option>
                                    <option value="Specific">Specific</option>
                                </select>
                            </div>
                            <div className="colBlk6 fldWrap">
                                <label>Customer Mandatory Selection</label>
                                <div className="customCheck yesNo">
                                    <input type="checkbox" id="mandatorySelection" {...register("mobileApp.customer_mandatory")} />
                                    <label className="customCheckLabel" htmlFor="mandatorySelection">yesNo</label>
                                </div>
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className="colBlk6 fldWrap">
                                <label>Enable myTeam for Managers</label>
                                <div className="fldWIthYesNo">
                                    <div className="customCheck yesNo">
                                        <input type="checkbox" id="myTeamManagers" {...register("mobileApp.manager_my_team")} />
                                        <label className="customCheckLabel" htmlFor="myTeamManagers">yesNo</label>
                                    </div>
                                    <select style={{ display: getValues("mobileApp.manager_my_team") ? 'block' : 'none' }} {...register("mobileApp.manager_team")}>
                                        <option value="0">All Devices</option>
                                        <option value="1">Assigned {groupLabel}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="colBlk6 fldWrap">
                                <label>Show Last Selected Customer & {taskLabel}</label>
                                <div className="customCheck yesNo">
                                    <input type="checkbox" id="app_persist" {...register("mobileApp.app_persist")} />
                                    <label className="customCheckLabel" htmlFor="app_persist">yesNo</label>
                                </div>
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className="colBlk6 fldWrap">
                                <label>Enable myTeam for {employeeLabel}</label>
                                <div className="fldWIthYesNo">
                                    <div className="customCheck yesNo">
                                        <input type="checkbox" id="myTeamEmployees" {...register("mobileApp.employee_my_team")} />
                                        <label className="customCheckLabel" htmlFor="myTeamEmployees">yesNo</label>
                                    </div>
                                    <select style={{ display: getValues("mobileApp.employee_my_team") ? 'block' : 'none' }} {...register("mobileApp.employee_team")}>
                                        <option value="0">Assigned {groupLabel}</option>
                                        <option value="1">Self</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <MultiLanguageSettings 
                          handleCheckboxChange={handleCheckboxChange} 
                          register={register} 
                          getValues={getValues} 
                          languageOptions={languageOptions} 
                          languages={languages} 
                          setValue={setValue}
                        />
                    </div>
                </section>

                <section>
                    <h3>Time-Clock Stages</h3>
                    <div className="supportedTxt">Customize the time clock stages for allGeo mobile application. to use relevant terminology according your industry or business.</div>
                    <div className="innerWrap2">
                        <table cellPadding="0" cellSpacing="0" className="tblStyle">
                            <thead>
                                <tr>
                                    <th className="nameCol">Stage Name</th>
                                    <th>Customized Stage Name</th>
                                    <th>Mobile Visibility</th>
                                    <th className="serviceCol">Include Service Hrs. & Miles</th>
                                    <th className="attachmentCol">Required Attachments</th>
                                    <th>SMS Command</th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    mobileAppSettingsValue.timeClock.map((stages, index) => {
                                        return <tr key={index}>
                                            <td
                                                className={
                                                    (stages.stage_label == 'Start' ?
                                                        "txtStart" : stages.stage_label == 'Stop' ?
                                                            "txtStop" : stages.stage_label == 'Emergency' ?
                                                                "txtStop" : "")
                                                }
                                            >
                                                {stages.stage_label}
                                            </td>
                                            <td><input key={index} type="text" {...register(`timeClock.${index}.stage_name`)} /></td>
                                            <td>
                                                <div className="customCheck yesNo">
                                                    <input type="checkbox" key={`visibility${index}`} id={`visibility${index}`} {...register(`timeClock.${index}.visibility`)} />
                                                    <label className="customCheckLabel" htmlFor={`visibility${index}`}>yesNo</label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="customCheck noTxt">
                                                    <input type="checkbox" key={`include_hours${index}`} id={`include_hours${index}`} {...register(`timeClock.${index}.include_hours`)} />
                                                    <label className="customCheckLabel" htmlFor={`include_hours${index}`}>noTxt</label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="attachmentWrap">
                                                    {<Controller
                                                        control={control}
                                                        name={`timeClock.${index}.attachments`}
                                                        defaultValue={[]}
                                                        render={({ field: { value, onChange } }) => (
                                                            <MultiSelect
                                                                data={attachments}
                                                                textField="text"
                                                                dataItemKey="id"
                                                                onChange={(value) => {
                                                                    handleChange(value, index);
                                                                }}
                                                                itemRender={itemRender}
                                                                value={value}
                                                                placeholder="+ Select item"
                                                                tagRender={tagRender}
                                                                autoClose={false}
                                                                disabled={stages.stage_label === "Emergency"}
                                                            />
                                                        )}
                                                    />
                                                    }
                                                </div>
                                            </td>
                                            <td><input type="text" key={`sms_cmd${index}`} id={`sms_cmd${index}`} {...register(`timeClock.${index}.sms_cmd`)} /></td>
                                        </tr>
                                    })
                                }

                            </tbody>

                        </table>
                    </div>
                    <div className="innerWrap">
                        <div className="sectionSubHeading">Automatic Time-clock Stages</div>
                        <div className="rowBlk">
                            <div className="colBlk3 fldWrap">
                                <label>Auto Arrive Stage</label>
                                <select {...register("mobileApp.auto_arrive_stage")}>
                                    {
                                        mobileAppSettingsValue.timeClock.map(
                                            ({ stage_id, stage_name }, index) => { return stage_name ? <option value={stage_id} key={index}>{stage_name}</option> : '' })
                                    }

                                </select>
                            </div>
                            <div className="colBlk3 fldWrap">
                                <label>Auto Depart Stage</label>
                                <select {...register("mobileApp.auto_depart_stage")}>
                                    {
                                        mobileAppSettingsValue.timeClock.map(({ stage_id, stage_name }, index) => { return stage_name ? <option value={stage_id} key={index}>{stage_name}</option> : '' })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                </section>

                {props.accessLevel.childMenu && props.accessLevel.childMenu > 1 && <div className="mainBtnWrap">
                    <a onClick={formCancelEvent}>Cancel</a>
                    <button className="btnStyle">SAVE</button>
                </div>}
            </form>
            {
                isUpdateSuccessful &&
                <ToastMessage cssClass={msgClass} message={accountUpdateMsg}
                    onClose={() => { setIsUpdateSuccessful(false); }}
                />
            }
            <SiteLoader isActive={showLoader} />
            <ConfirmPopup
                showDialog={showDialog}
                message={"The changes are still unsaved. Are you sure you want to leave?"}
                confirmNavigation={onSuccess}
                cancelNavigation={onFaliure}
            />
        </Fragment>
    )
}