import React, { useEffect, useLayoutEffect, useState } from "react";
import "./monitor.css";
import MainLayout from "../../components/layout/MainLayout";
import Header from "../../components/layout/Header";
// import { MonitorTabs } from "./MonitorTabs";
import { NotificationAlert } from "./common/NotificationAndAlerts";
import { MilesData } from "./MileageAndTracking/MilesData/MilesData";
import { EmployeeAppData } from "./MileageAndTracking/EmployeeAndAppData";
import { PinnedComponent } from "./MileageAndTracking/EmployeeAndAppData";
import { TimeClockData, TimeClockReport } from "./TimeClock";
import { MapLocationPinned } from "./MileageAndTracking/MapAndLocation/MapLocationPinned";
import { MilesDataPinned } from "./MileageAndTracking/MilesData/MilesDataPinned";
import { NotificationAlertPinnedView } from "./common/NotificationAndAlerts/NotificationAlertPinnedView";
import AddNewPopup from "../../components/company/Employees/AddNewPopup";

import {
  PINNED_BOX,
  handleDeleteEmployee,
  handleFetchEmployeeAppData,
  handleFetchTimeclockSettings,
  handleSuspendEmployee,
  TAB_INDEX,
  getMilesData,
  getAlertsAndNotificationData,
  defaultMode,
} from "./utils";
import { ChangePageLayout } from "./ChangePageLayout/ChangePageLayout";
import SiteLoader from "../../components/common/SiteLoader";
import ToastMessage from "../../components/common/ToastMessage";
import { MonitorMapLocation } from "./MileageAndTracking/MapAndLocation";
import { MonitorTabs } from "./MonitorTabs/MonitorTabs";
import { MileageAndTracking } from "./MileageAndTracking";
import { handleGetMsgTemplates } from "./utils/handleSendMessage";
import { handleFetchTimeClockReport } from "./utils/handleFetchTimeClockReport/handleFetchTimeClockReport";
import { LocateSchedulers } from "../../components/monitor";
import { useHistory, useLocation } from "react-router-dom";
import { getAccessLevel } from "../../components/common/functions";
import { ChangeStatusModal } from "./MileageAndTracking/EmployeeAndAppData/Modals/ChangeStatusModal";
import { handleFetchAllEmployeeList } from "./utils/handleFetchAllEmployeeList/handleFetchAllEmployeeList";
import { useDocumentTitle } from "../../hooks";
import { handleActiveEmployee } from "./utils/handleActiveEmployees";
import useGeneralSettings from "../../hooks/useGeneralSettings";
import { ExpandEmployeesAppDataModal } from "./MileageAndTracking/EmployeeAndAppData/Modals/ExpandEmployeesAppDataModal";
import { getAccountLabel } from "../../utils";
import ActionPopup from "../../components/company/Employees/ActionPopup";
import TextPopup from "../../components/monitor/MonitorEmployees/TextPopup";

const tabs = [
  { id: 0, key: "/monitor/new-monitor-employees", label: "Mileage and Tracking" },
  { id: 1, key: "time-clock", label: "Time Clock" },
  // { id: 2, key: "schedule", label: "Scheduler" },
];
const queryParams = {
  searchBy: "all",
  searchValue: "",
  parentAcctID: JSON.parse(localStorage.getItem("parent"))?.accountId || "",
  childAccountID:
    localStorage.getItem("childAccountId") || localStorage.getItem("accountId"),
};
const timeClockQueryParams = {
  pageNo: 1,
  pageSize: 50,
};

const NotiAlertqueryParams = {
  searchBy: "all",
  searchValue: "all",
  parentAcctID: JSON.parse(localStorage.getItem("parent"))?.accountId || "",
  childAccountID:
    localStorage.getItem("childAccountId") || localStorage.getItem("accountId"),
};

export const Monitor = () => {
  useDocumentTitle("Monitor - allGeo");

  const moment = window.moment;
  const { timezone, nomenclature } = useGeneralSettings();

  const last1Day = moment().subtract(1, "days");
  const history = useHistory();
  const location = useLocation();

  const previousTab = location.pathname;

  console.log(previousTab,"locationlocation")
  const [accessLevel, setAccessLevel] = useState({});

  const [tabIndex, setTabIndex] = useState(
    previousTab || "/monitor/new-monitor-employees"
  );
  const [pinnedBlock, setPinnedBlock] = useState(
    previousTab === "/monitor/new-monitor-employees"
      ? localStorage.getItem("PinndComponentMileage") ?? ""
      : previousTab === "time-clock"
      ? localStorage.getItem("pinndComponentTimeClock") ?? ""
      : ""
  );

  const [allEmployeeList, setAllEmployeeList] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [employeeTilesData, setEmployeeTilesData] = useState([]);
  const [employeeBackupData, setEmployeeBackupData] = useState([]);
  const [milesBackupData, setMilesBackupData] = useState([]);

  const [showExpanMore, setShowExpanMore] = useState(false);
  const employeeLabel = getAccountLabel("employee");

  const [jobStage, setJobStage] = useState([]);
  const [groups, setGroups] = useState([]);
  const [dropdowndata, setDropdowndata] = useState([]);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState([]);
  const [timeclockStage, setTimeclockStage] = useState([]);
  const [currentTimeAndDateAppEmp, setCurrentTimeAndDateAppEmp] = useState();
  const [currentTimeAndDateMiles, setCurrentTimeAndDateMiles] = useState();
  const [currentTimeAndDateAlertsNoti, setCurrentTimeAndDateAlertsNoti] =
    useState();
  const [currentTimeAndDateTimeClock, setCurrentTimeAndDateTimeClock] =
    useState();
  const [search, setSearch] = useState("");
  const [breadcrumbData, setBreadcrumbData] = useState({
    device: allEmployeeList[0]?.employeeId,
    date_tz: timezone,
  });
  const [alertNotificationData, setAlertNotificationData] = useState([]);
  const [deletePopup, setDeletePopup] = useState({
    open: false,
    case: "single",
  });
  const [suspendPopup, setSuspendPopup] = useState({
    open: false,
    case: "single",
  });
  const [activePopup, setActivePopup] = useState({
    open: false,
    case: "multiple",
  });
  const [showLoader, setShowLoader] = useState(false);
  const [showLoaderNotification, setShowLoaderNotification] = useState(false);

  const [toastMsg, setToastMsg] = useState({
    msg: "",
    type: "msgSuccess",
  });
  const [showChangeStatusModal, setShowChangeStatusModal] = useState(false);

  const [employeePaginateMetaData, setEmployeePaginateMetaData] = useState({
    pageNo: 1,
    pageSize: 50,
    totalDataCount: 0,
    queryParams: queryParams,
    expandView:false
  });

  const [
    employeePaginateMetaDataPrevious,
    setEmployeePaginateMetaDataPrevious,
  ] = useState({
    pageNo: 1,
    pageSize: 50,
    totalDataCount: 0,
    queryParams: queryParams,
  });
  const [notificationAndAlertMetaData, setNotificationAndAlertMetaData] =
    useState({
      page: 1,
      perPage: 25,
      totalDataCount: 0,
      fromDate: last1Day.format("YYYY/MM/DD"),
      toDate: moment().format("YYYY/MM/DD"),
      queryParams: NotiAlertqueryParams,
      totalrecords: 0,
      paginatedRecords: 0,
    });
  const [milesPaginateMetaData, setMilesPaginateMetaData] = useState({
    page: 1,
    perPage: 25,
    totalDataCount: 0,
    queryParams: queryParams,
    paginationFlag:false
  });

  const [milesData, setMilesData] = useState();
  const [msgTemplates, setMsgTemplates] = useState([]);

  const [timeClockData, setTimeClockData] = useState(null);
  const [params, setParams] = useState({
    ...timeClockQueryParams,
    date_fr: last1Day.format("YYYY/MM/DD"),
    date_to: moment().format("YYYY/MM/DD"),
  });
  const [showAddNewPopup, setShowAddNewPopup] = useState(false);
  const [showG2Popup, setShowG2Popup] = useState(false);

  const [actionPopup, setActionPopup] = useState({
    show: false,
    data: null,
    msg: "",
  });
  const [mode, setMode] = useState(defaultMode);
  const [showtextPopup, setShowTextPopup] = useState(false);

  const handleTabChange = (tab) => {
    setTabIndex(tab);
    history.replace(`${location.pathname}?tab=${tab}`);
  };

  const handleUnPinned = () => {
    setPinnedBlock("");

    if (previousTab === "/monitor/new-monitor-employees") {
      localStorage.setItem("PinndComponentMileage", "");
    } 

    localStorage.setItem("PinndComponent", "");
  };

  const handlePinned = (val) => {
    setPinnedBlock(val);
    if (previousTab === "/monitor/new-monitor-employees") {
      localStorage.setItem("PinndComponentMileage", val);
    }
  };

  const [layoutItems, setLayoutItems] = useState(() => {
    const savedItems = localStorage.getItem("items");
    return savedItems
      ? JSON.parse(savedItems)
      : [
        { id: 1, text: `${employeeLabel} App Status`,show:JSON.parse(localStorage.getItem('employeeAppData')) },
        { id: 2, text: "Map and Location",show:JSON.parse(localStorage.getItem('mapAndLocation')) },
        { id: 3, text: "Miles Data",show:JSON.parse(localStorage.getItem('milesData')) },
        { id: 4, text: "Notifications and Alerts",show:JSON.parse(localStorage.getItem('notificationsAndAlerts')) },
        { id: 5, text: "Time Clock Data",show:JSON.parse(localStorage.getItem('timeClockData')) },
        { id: 6, text: "Time Clock",show:JSON.parse(localStorage.getItem('timeClockReports')) },
        ];
  });

  const handleDeleteEmployees = async () => {
    handleDeleteEmployee(
      deletePopup,
      selectedDeleteItem,
      closeDeleteSuspendPopup,
      setDeletePopup,
      employeePaginateMetaData,
      setEmployeeData,
      setShowLoader,
      setToastMsg,
      setCurrentTimeAndDateAppEmp,
      setSelectedDeleteItem
    );
  };

  const handleSuspendEmployees = async () => {
    handleSuspendEmployee(
      selectedDeleteItem,
      closeDeleteSuspendPopup,
      employeePaginateMetaData,
      setEmployeeData,
      setShowLoader,
      setToastMsg,
      setCurrentTimeAndDateAppEmp,
      setSelectedDeleteItem
    );
  };

  const handleActiveEmployees = async () => {
    handleActiveEmployee(
      selectedDeleteItem,
      closeDeleteSuspendPopup,
      employeePaginateMetaData,
      setEmployeeData,
      setShowLoader,
      setToastMsg,
      setCurrentTimeAndDateAppEmp,
      setSelectedDeleteItem
    );
  };

  const openDeletePopup = (type) => {
    setDeletePopup({ open: true, case: type });
  };

  const openSuspendPopup = (type) => {
    setSuspendPopup({ open: true, case: type });
  };

  const openActivePopup = (type) => {
    setActivePopup({ open: true, case: type });
  };

  console.log(dropdowndata, "dropdowndata");

  const closeDeleteSuspendPopup = () => {
    // (suspendPopup.case === "multiple" || deletePopup.case === "multiple") &&
    //   setSelectedDeleteItem([]);
    setSuspendPopup({ open: false, case: "single" });
    setDeletePopup({ open: false, case: "single" });
    setActivePopup({ open: false, case: "multiple" });
  };


  const handleGetTimeClockReport = (param) => {
    handleFetchTimeClockReport(
      setTimeClockData,
      params,
      param ? param : setShowLoader
    );
  };
  console.log(showLoader, "showLoader");
  useEffect(() => {
    handleFetchEmployeeAppData(
      employeePaginateMetaData,
      setEmployeeTilesData,
      setShowLoader,
      setCurrentTimeAndDateAppEmp
    );
  }, []);
  useEffect(() => {
    handleGetTimeClockReport();
  }, [params]);

  const fetchEmployeeAppData = (firstParam, secondParam,thirdParam) => {
    if (!!firstParam && !!secondParam) {
      handleFetchEmployeeAppData(
        {
          ...employeePaginateMetaData,
          queryParams: {
            ...employeePaginateMetaData.queryParams,
            searchBy: firstParam,
            searchValue: secondParam,
          },
          expandView:thirdParam
        },
        setEmployeeData,
        setShowLoader,
        setCurrentTimeAndDateAppEmp
      );
    }else if(!!thirdParam){
      handleFetchEmployeeAppData(
        {
          ...employeePaginateMetaData,
          expandView:thirdParam
        },
        setEmployeeData,
        setShowLoader,
        setCurrentTimeAndDateAppEmp
      );
    }
    else {
      handleFetchEmployeeAppData(
        employeePaginateMetaData,
        setEmployeeData,
        setShowLoader,
        setCurrentTimeAndDateAppEmp
      );
    }
  };

  useEffect(() => {
    handleFetchAllEmployeeList(setAllEmployeeList);
    getAlertsAndNotificationData(
      setCurrentTimeAndDateAlertsNoti,
      setNotificationAndAlertMetaData,
      notificationAndAlertMetaData,
      setAlertNotificationData,
      setShowLoaderNotification,
      true
    );
  }, []);
  useEffect(() => {
    getMilesData(
      setCurrentTimeAndDateMiles,
      setMilesData,
      milesPaginateMetaData
    );
  }, [milesPaginateMetaData]);

  useEffect(() => {
    handleFetchTimeclockSettings(
      setJobStage,
      setTimeclockStage,
      setGroups,
      setDropdowndata
    );
    handleGetMsgTemplates(setMsgTemplates);
  }, []);

  useEffect(() => {
    getAccessLevel("monitor", "").then((response) => {
      // if (childAccountID === 'all') {
      //   response.childMenu = 1
      // } else {
      // }
      response.childMenu = response.parentMenu;
      setAccessLevel(response);
    });
    // handleGetTimeClockReport()
  }, []);

  useEffect(() => {
    setEmployeeBackupData(employeeData?.data);
  }, [employeeData]);

  useEffect(()=>{
    setMilesBackupData(milesData?.data)
  }, [milesData]);

  const refreshfetch = () => {
    setSearch("")

    handleFetchEmployeeAppData(
      {
        ...employeePaginateMetaData,
        queryParams: {
          ...employeePaginateMetaData.queryParams,
        },
        // pageNo:1
      },
      setEmployeeData,
      setShowLoader,
      setCurrentTimeAndDateAppEmp
    );
  };

  const handleGetEmpData = () => {
    let updateBothcardData = true;
    handleFetchEmployeeAppData(
      {
        ...employeePaginateMetaData,
        queryParams: {
          ...employeePaginateMetaData.queryParams,
        },
      },
      setEmployeeData,
      setShowLoader,
      setCurrentTimeAndDateAppEmp,
      updateBothcardData,
      setEmployeeTilesData
    );
  };


  useEffect(() => {

    if (
      employeeTilesData?.isSuspendedDevicePresent === false &&
      employeeTilesData?.totalRecordCountAll === 0
    ) {
      setShowTextPopup(true);
    } else {
      setShowTextPopup(false);
    }
  }, [setEmployeeData,employeeTilesData]);

  return (
    <div style={{ width: "100%" }}>
      <MainLayout style={{ width: "100%" }}>
        <div
          className="mainContentWrap"
          style={{ overflow: "hidden", width: "100%", paddingBottom: 24 }}
        >
          <Header title="Monitor" />
          <div class="mainContent" id="mainContent" style={{ padding: "0" }}>
            <div className="monitorparentbox">
              {/* <MonitorTabs
                tabs={tabs}
                tabIndex={tabIndex}
                handleTabChange={handleTabChange}
              /> */}
               
                <ChangePageLayout
                  layoutItems={layoutItems}
                  setLayoutItems={setLayoutItems}
                  employeeLabel={employeeLabel}
                />
              
            </div>
            {PINNED_BOX.ALERTS_AND_NOTIFICATIONS === pinnedBlock && (
              <div className="pinparentbox">
                <NotificationAlertPinnedView
                  handlePinned={handlePinned}
                  handleUnPinned={handleUnPinned}
                  notificationData={alertNotificationData}
                  setCurrentTimeAndDateAlertsNoti={
                    setCurrentTimeAndDateAlertsNoti
                  }
                  notificationAndAlertMetaData={notificationAndAlertMetaData}
                  setAlertNotificationData={setAlertNotificationData}
                  setNotificationAndAlertMetaData={
                    setNotificationAndAlertMetaData
                  }
                />
              </div>
            )}
            {PINNED_BOX.MILES_DATA === pinnedBlock && (
              <div className="pinparentbox">
                <MilesDataPinned
                  handlePinned={handlePinned}
                  handleUnPinned={handleUnPinned}
                  setMilesPaginateMetaData={setMilesPaginateMetaData}
                  milesData={milesData}
                  milesBackupData={milesBackupData}
                  setMilesBackupData={setMilesBackupData}
                />
              </div>
            )}

            {PINNED_BOX.MAP_AND_LOCATION === pinnedBlock && (
              <div className="pinparentbox">
                <MapLocationPinned
                  handlePinned={handlePinned}
                  handleUnPinned={handleUnPinned}
                  breadcrumbData={breadcrumbData}
                  setBreadcrumbData={breadcrumbData}
                />
              </div>
            )}

            {PINNED_BOX.EMPLOYEE_AND_APP_DATA === pinnedBlock && (
              <div className="pinparentbox">
                <PinnedComponent
                  employeeData={employeeData}
                  handlePinned={handlePinned}
                  handleUnPinned={handleUnPinned}
                  setEmployeePaginateMetaData={setEmployeePaginateMetaData}
                  employeePaginateMetaData={employeePaginateMetaData}
                  dropdowndata={dropdowndata}
                  deletePopup={deletePopup}
                  setDeletePopup={setDeletePopup}
                  handleDeleteEmployees={handleDeleteEmployees}
                  closeDeletePopup={closeDeleteSuspendPopup}
                  setSelectedDeleteItem={setSelectedDeleteItem}
                  openDeletePopup={openDeletePopup}
                  openSuspendPopup={openSuspendPopup}
                  suspendPopup={suspendPopup}
                  closeSuspendPopup={closeDeleteSuspendPopup}
                  handleSuspendEmployees={handleSuspendEmployees}
                  timeclockStage={timeclockStage}
                  currentTimeAndDate={currentTimeAndDateAppEmp}
                  setCurrentTimeAndDate={setCurrentTimeAndDateAppEmp}
                  accessLevel={accessLevel}
                  setEmployeeData={setEmployeeData}
                  fromPinned={true}
                  setShowLoader={setShowLoader}
                  fetchEmployeeAppData={refreshfetch}
                  showChangeStatusModal={showChangeStatusModal}
                  setShowChangeStatusModal={setShowChangeStatusModal}
                  activePopup={activePopup}
                  openActivePopup={openActivePopup}
                  handleActiveEmployees={handleActiveEmployees}
                  employeeBackupData={employeeBackupData}
                  setEmployeeBackupData={setEmployeeBackupData}
                  selectedDeleteItem={selectedDeleteItem}
                  search={search}
                  setSearch={setSearch}
                  employeePaginateMetaDataPrevious={
                    employeePaginateMetaDataPrevious
                  }
                  setEmployeePaginateMetaDataPrevious={
                    setEmployeePaginateMetaDataPrevious
                  }
                  setShowAddNewPopup={setShowAddNewPopup}
                  tabIndex={tabIndex}
                  TAB_INDEX={TAB_INDEX}
                  showLoader={showLoader}
                  // refreshData={refreshData}
                  // setRefreshData={setRefreshData}
                />
              </div>
            )}

            {tabIndex === TAB_INDEX.MILEAGE_AND_TRACKING && (
              <div className="customgridbox">
                <MileageAndTracking
                  layoutItems={layoutItems}
                  employeeData={employeeTilesData}
                  handlePinned={handlePinned}
                  handleUnPinned={handleUnPinned}
                  setEmployeePaginateMetaData={setEmployeePaginateMetaData}
                  employeePaginateMetaData={employeePaginateMetaData}
                  currentTimeAndDateAppEmp={currentTimeAndDateAppEmp}
                  setCurrentTimeAndDateAppEmp={setCurrentTimeAndDateAppEmp}
                  getMilesData={getMilesData}
                  milesData={milesData}
                  currentTimeAndDateAlertsNoti={currentTimeAndDateAlertsNoti}
                  setCurrentTimeAndDateAlertsNoti={
                    setCurrentTimeAndDateAlertsNoti
                  }
                  setMilesData={setMilesData}
                  currentTimeAndDateMiles={currentTimeAndDateMiles}
                  setCurrentTimeAndDateMiles={setCurrentTimeAndDateMiles}
                  setMilesPaginateMetaData={setMilesPaginateMetaData}
                  notificationAndAlertMetaData={notificationAndAlertMetaData}
                  alertNotificationData={alertNotificationData}
                  msgTemplates={msgTemplates}
                  setShowLoader={setShowLoader}
                  setAlertNotificationData={setAlertNotificationData}
                  setShowLoaderNotification={setAlertNotificationData}
                  showLoaderNotification={showLoaderNotification}
                  accessLevel={accessLevel}
                  fetchEmployeeAppData={fetchEmployeeAppData}
                  setToastMsg={setToastMsg}
                  allEmployeeList={allEmployeeList}
                  breadcrumbData={breadcrumbData}
                  setBreadcrumbData={setBreadcrumbData}
                  setShowExpanMore={setShowExpanMore}
                  milesBackupData={milesBackupData}
                  setMilesBackupData={setMilesBackupData}
                  setEmployeeTilesData={setEmployeeTilesData}
                  setNotificationAndAlertMetaData={
                    setNotificationAndAlertMetaData
                  }
                  milesPaginateMetaData={milesPaginateMetaData}
                  employeeLabel={employeeLabel}
                  currentTimeAndDateTimeClock={currentTimeAndDateTimeClock}
                  setCurrentTimeAndDateTimeClock={
                    setCurrentTimeAndDateTimeClock
                  }
                  timeClockData={timeClockData}
                  handleGetTimeClockReport={handleGetTimeClockReport}
               
                  showLoader={showLoader}
                />
              </div>
            )}

            {/* {tabIndex === TAB_INDEX.TIME_CLOCK && (
              <div className="customgridbox time-clock">
                <TimeClockData
                  currentTimeAndDateTimeClock={currentTimeAndDateTimeClock}
                  setCurrentTimeAndDateTimeClock={
                    setCurrentTimeAndDateTimeClock
                  }
                  setShowExpanMore={setShowExpanMore}
                  timeClockData={timeClockData}
                  handleGetTimeClockReport={handleGetTimeClockReport}
                  setShowLoader={setShowLoader}
                  showLoader={showLoader}
                />
                <NotificationAlert
                  // key={item.id}
                  handlePinned={handlePinned}
                  handleUnPinned={handleUnPinned}
                  currentTimeAndDateAlertsNoti={currentTimeAndDateAlertsNoti}
                  setCurrentTimeAndDateAlertsNoti={
                    setCurrentTimeAndDateAlertsNoti
                  }
                  notificationAndAlertMetaData={notificationAndAlertMetaData}
                  alertNotificationData={alertNotificationData}
                  msgTemplates={msgTemplates}
                  employeeData={allEmployeeList}
                  setShowMainLoader={setShowLoaderNotification}
                  setAlertNotificationData={setAlertNotificationData}
                  showLoaderNotification={false}
                  height={250}
                  setNotificationAndAlertMetaData={
                    setNotificationAndAlertMetaData
                  }
                  timeClockData={timeClockData}
                  fromTimeClock={true}
                />
              </div>
            )}

            {tabIndex === TAB_INDEX.TIME_CLOCK && (
              <div className="pinparentbox">
                <TimeClockReport
                  setShowLoader={setShowLoader}
                  showLoader={showLoader}
                  timeClockData={timeClockData}
                  setTimeClockData={setTimeClockData}
                  handleGetTimeClockReport={handleGetTimeClockReport}
                  setParams={setParams}
                  params={params}
                />
              </div>
            )} */}

            {/* {tabIndex === TAB_INDEX.SCHEDULE && (
              <div style={{ margin: "16px 24px" }}>
                <LocateSchedulers />
              </div>
            )} */}
          </div>
        </div>

        {!!showExpanMore && (
          <ExpandEmployeesAppDataModal
            employeeData={employeeData}
            handlePinned={handlePinned}
            handleUnPinned={handleUnPinned}
            closePopup={() => setShowExpanMore(false)}
            setEmployeePaginateMetaData={setEmployeePaginateMetaData}
            employeePaginateMetaData={employeePaginateMetaData}
            dropdowndata={dropdowndata}
            deletePopup={deletePopup}
            setDeletePopup={setDeletePopup}
            handleSuccess={handleDeleteEmployees}
            closeDeletePopup={closeDeleteSuspendPopup}
            setSelectedDeleteItem={setSelectedDeleteItem}
            openDeletePopup={openDeletePopup}
            openSuspendPopup={openSuspendPopup}
            suspendPopup={suspendPopup}
            closeSuspendPopup={closeDeleteSuspendPopup}
            handleSuspendEmployees={handleSuspendEmployees}
            timeclockStage={timeclockStage}
            selectedDeleteItem={selectedDeleteItem}
            refreshEmployeeData={refreshfetch}
            accessLevel={accessLevel}
            setEmployeeData={setEmployeeData}
            fetchEmployeeAppData={fetchEmployeeAppData}
            showChangeStatusModal={showChangeStatusModal}
            setShowChangeStatusModal={setShowChangeStatusModal}
            openActivePopup={openActivePopup}
            activePopup={activePopup}
            handleActiveEmployees={handleActiveEmployees}
            employeeBackupData={employeeBackupData}
            setEmployeeBackupData={setEmployeeBackupData}
            search={search}
            setSearch={setSearch}
            employeePaginateMetaDataPrevious={
              setEmployeePaginateMetaDataPrevious
            }
            setEmployeePaginateMetaDataPrevious={
              setEmployeePaginateMetaDataPrevious
            }
            setCurrentTimeAndDateAppEmp={setCurrentTimeAndDateAppEmp}
            setShowAddNewPopup={setShowAddNewPopup}
            showLoader={showLoader} 
            setShowLoader={setShowLoader}
            tabIndex={tabIndex}
            TAB_INDEX={TAB_INDEX}
            showExpanMore={showExpanMore}
            // refreshData={refreshData}
            // setRefreshData={setRefreshData}
          />
        )}
        {!!showChangeStatusModal && (
          <ChangeStatusModal
            employeeData={employeeData}
            selectedDeleteItem={selectedDeleteItem}
            closePopup={() => setShowChangeStatusModal(false)}
            timeclockStage={timeclockStage}
            refreshEmployeeData={fetchEmployeeAppData}
            setToastMsg={setToastMsg}
            setShowLoader={setShowLoader}
          />
        )}

        {showAddNewPopup && (
          <div className="mainContent">
            <AddNewPopup
              timeZone={timezone}
              setToastMsg={setToastMsg}
              setShowLoader={setShowLoader}
              closePopup={() => setShowAddNewPopup(false)}
              nomenclature={nomenclature}
              setActionPopup={setActionPopup}
              setMode={setMode}
              fetchEmployeeAppData={handleGetEmpData}
              fromMonitorNew={true}
              showAddNewPopup={showAddNewPopup}
            />
          </div>
        )}
        {actionPopup.show && (
          <ActionPopup
            actionPopup={actionPopup}
            setActionPopup={setActionPopup}
            setToastMsg={setToastMsg}
            setShowLoader={setShowLoader}
            fromNewMonitor={true}
            fetchEmployeeAppData={handleGetEmpData}
          />
        )}
        {showtextPopup && (
          <TextPopup
            fromMonitorNew={true}
            accountId={localStorage.getItem("childAccountId") || localStorage.getItem("accountId")}
            closePopup={() => setShowTextPopup(false)}
            openAddPopup={() => setShowAddNewPopup(true)}
          />
        )}

        {mode.type === "uploadError" && (
          <FileUploadFail
            data={mode.data}
            closePopup={() =>
              setMode({ ...mode, type: "main", data: null, hasPrev: false })
            }
            setToastMsg={setToastMsg}
            setShowLoader={setShowLoader}
          />
        )}
        {toastMsg.msg && (
          <ToastMessage
            cssClass={toastMsg.type}
            message={toastMsg.msg}
            onClose={() => setToastMsg({ msg: "", type: "" })}
          />
        )}
      
          <SiteLoader isActive={showLoader} />
    
      </MainLayout>
    </div>
  );
};
