import React from 'react'
import { TimePicker } from "@progress/kendo-react-dateinputs";
import { Checkbox, RadioButton } from '@progress/kendo-react-inputs'


export const Header = ({ radioFilter, handleChange }) => {
    return (
        <>
            <div class="vertical-line"></div>
            <div className="customRadio " style={{ display: "flex", alignItems: "center" }}>
                <RadioButton
                    name="group1"
                    value="99,99"
                    checked={radioFilter === "99,99"}
                    label="All"
                    onChange={handleChange}
                />
            </div>
            <div className="customRadio" style={{ display: "flex", alignItems: "center" }}>
                <RadioButton
                    name="group1"
                    value="3,0"
                    checked={radioFilter === "3,0"}
                    label="Start Shift-End Shift"
                    onChange={handleChange}
                />
            </div>
            <div className="customRadio" style={{ display: "flex", alignItems: "center" }}>
                <RadioButton
                    name="group1"
                    value="1,2"
                    checked={radioFilter === "1,2"}
                    label="Arrive Shipping"
                    onChange={handleChange}
                />
            </div>

            <Checkbox label="Only First Last" name="first_last" value="true" onChange={handleChange} />

            <div class="vertical-line"></div>

            <strong> First Punch</strong>

            <label>From time :</label>
            <div className='timePicker' style={{ maxWidth: "150px" }}>
                <TimePicker name='start_time' format="hh:mm:ss a" placeholder="Select From Time"
                    onChange={handleChange}
                />
            </div>

            <label>To Time :</label>
            <div className='timePicker' style={{ maxWidth: "150px" }}>
                <TimePicker name='end_time' format="hh:mm:ss a" placeholder="Select End Time"
                    onChange={handleChange}
                />
            </div>
        </>
    )
}