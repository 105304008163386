import React, { useRef } from 'react'

const Download = ({ closePopup, handleDownload, report, ErrorReportToExcel, handleExportToPDF,handleExportToExcel }) => {
   
    const inpRef = useRef(null)

    const downloadReport = () => {
        let DownloadType = inpRef.current.value

        if (report && report == "auditReport") {
            DownloadType === "XLSX" ? handleExportToExcel() : DownloadType === "PDF" ? handleExportToPDF() : null()
            closePopup()
        }

        if (report && report == "errorReport") {
            DownloadType === "XLSX" ? ErrorReportToExcel() : DownloadType === "PDF" ? handleExportToPDF() : null()
            closePopup()
        }

        else {
            handleDownload(inpRef.current.value)
        }
    }

    return (
        <div className="popupWrap smlPopup active">
            <div className="closePopup" onClick={closePopup} >Close</div>
            <div className="popupHeading">Download Report</div>
            <div className="popupContent">
                <div className="fldWrap">
                    <label>Select Format</label>
                    <select ref={inpRef} >
                        {report !== "auditReport" && report !== "errorReport" && <option>CSV</option>}
                        <option>XLSX</option>
                        <option>PDF</option>
                    </select >
                </div >
            </div >
            <div className="popupBtnWrap flexJCenter">
                <button className="btnStyle" onClick={downloadReport} >Download</button>
            </div>
        </div >
    )
}

export default Download